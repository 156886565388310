import React from 'react';
import {observer} from 'mobx-react';
import {Box, Typography} from '@mui/material';
import {PageHeader} from 'app/components/sharedReactComponents/PageHeader';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {useCurrentTeamStore} from 'app/store/hooks';
import {useCloudEntities} from 'app/components/entities/cloud';
import {AlertSettings} from 'app/components/Settings/Alerts/AlertSettings/AlertSettings';

export const Alerts = observer(() => {
  const currentTeamStore = useCurrentTeamStore();
  const {user} = useCloudEntities();

  const teamId = currentTeamStore.id;
  const teamName = currentTeamStore.name;
  const groupsAccess = currentTeamStore.getCapabilities().groups();

  return (
    <div>
      <PageHeader
        title={
          <Typography fontWeight={600} component="span" variant="h5">
            Manage alerts for <OverflowTooltip component="span">{teamName}</OverflowTooltip>
          </Typography>
        }
      />

      <Box my={2} className="container">
        <AlertSettings userEmail={user.email} teamId={teamId} groupsAccess={groupsAccess} />
      </Box>
    </div>
  );
});
