import React, {ReactNode} from 'react';
import {Box, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {FirmwareStatus} from 'app/components/DeviceDetails/DeviceInfoTab/FirmwareStatus';

export type UnitField = 'model' | 'sn' | 'network' | 'firmware' | 'host';

interface Props extends Sx {
  field: UnitField;
  device: AnyDeviceModelType;
}

export function DeviceField({field, device}: Props) {
  if (field === 'model') {
    return (
      <Field
        title="Model"
        content={<Typography data-id="system_info_model">{device.getModelName()}</Typography>}
      />
    );
  }

  if (field === 'sn') {
    return (
      <Field
        title="Serial number"
        content={
          <Typography data-id="system_info_serial_number">
            {device.getSerialNumber() || '-'}
          </Typography>
        }
      />
    );
  }

  if (field === 'host') {
    return (
      <Field
        title="Host"
        content={<Typography data-id="system_info_host">{device.getHostName()}</Typography>}
      />
    );
  }

  if (field === 'firmware') {
    return <Field title="Firmware" content={<FirmwareStatus device={device} />} />;
  }

  if (field === 'network') {
    return (
      <>
        <Field
          title="IP address"
          content={
            <Typography data-id="system_info_mac_address">
              {device.getIPAddress() ?? '-'}
            </Typography>
          }
        />

        <Field
          title="MAC address"
          content={
            <Typography data-id="system_info_mac_address">
              {device.getMACAddress() ?? '-'}
            </Typography>
          }
        />
      </>
    );
  }

  return null;
}

interface FieldProps {
  title: string;
  content: ReactNode;
}

function Field({title, content}: FieldProps) {
  return (
    <Box>
      <Typography fontWeight={600} fontSize={16} mb={0.5}>
        {title}
      </Typography>

      {content}
    </Box>
  );
}
