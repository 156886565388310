import {useQuery} from '@tanstack/react-query';
import {AnyDeviceModelType, createDeviceModel} from 'app/components/DeviceDetails/Models/Fabric';
import {Device} from 'app/components/DeviceDetails/Models/types';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';

interface Args {
  enabled: boolean;
  teamId: string;
  cacheTime?: number;
}

export function useProjectsQuery({enabled, teamId, cacheTime}: Args) {
  return useQuery({
    queryKey: ['projects', teamId],
    queryFn: async () => {
      const contract = await DeviceApiService.getProjects<Device>();
      return mapProjects(contract);
    },
    enabled: enabled,
    cacheTime,
  });
}

function mapProjects(data: Device[]): Map<string, AnyDeviceModelType> {
  const mapped = data.map((d) => createDeviceModel(d));
  return new Map(mapped.map((m) => [m.getId(), m]));
}
