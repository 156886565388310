import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {WS} from 'app/api/WebSocket/WS';
import {PreviewUpdateStrategy} from 'app/hooks/Device/Preview/types';
import {useUpdatePreviewByWebsocket} from 'app/hooks/Device/Preview/useUpdatePreviewByWebsocket';
import {DeviceCommandDispatcher} from 'app/util/CommandDispatcher';
import {parseMasterAndChannelIds} from 'app/components/DeviceDetails/utils';
import {useUpdatePreviewByInterval} from 'app/hooks/Device/Preview/useUpdatePreviewByInterval';
import {getPreviewUrl} from 'app/components/sharedReactComponents/Preview/utils';

interface ChannelPreviewParams {
  channelId: string;
  updateStrategy: PreviewUpdateStrategy;
  big?: boolean;
}

export function useChannelPreview({
  channelId,
  updateStrategy,
  big,
}: ChannelPreviewParams): string {
  const [baseSrc, setBaseSrc] = useState(() => getPreviewUrl(channelId, updateStrategy, big));
  const [imageSrc, setImageSrc] = useState(baseSrc);

  const command = useCallback(async () => {
    const [master] = parseMasterAndChannelIds(channelId);
    return DeviceCommandDispatcher.channelPreviews(master);
  }, [channelId]);

  useEffect(() => {
    setBaseSrc(getPreviewUrl(channelId, updateStrategy, big));
  }, [channelId, updateStrategy, big]);

  useUpdatePreviewByWebsocket({
    baseSrc,
    command,
    deviceId: channelId,
    disabled: updateStrategy !== PreviewUpdateStrategy.Ws,
    setSrc: setImageSrc,
    listener: WS.onDeviceChannelImage,
    disposer: WS.offDeviceChannelImage,
  });

  useUpdatePreviewByInterval({
    initialImageSrc: baseSrc,
    disabled: updateStrategy === PreviewUpdateStrategy.Ws,
    setImageSrc,
  });

  return imageSrc;
}
