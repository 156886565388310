import React, {Component} from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  error: boolean;
}
export class FormHelperText extends Component<Props> {
  render() {
    const {
      className,
      children,
      error,
    } = this.props;

    return (
      <div
        className={classNames('form-helper-text', className, {
          'form-helper-text--error': error,
        })}
      >
        {children}
      </div>
    );
  }
}
