import {Ws} from 'app/contracts/ws';
import {useEffect} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {PresetApiService} from 'app/services/api/preset/PresetApiService';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {Edge} from 'app/domain/edge';

interface Args {
  enabled: boolean;
  unitId: string;
}

export function useUnitPresets({enabled, unitId}: Args) {
  const client = useQueryClient();

  const query = useQuery({
    queryKey: ['unit-presets', unitId],
    queryFn: async () => {
      const result = await PresetApiService.getUnitPresets(unitId);
      return result ?? [];
    },
    refetchInterval: 1000 * 15,
    enabled,
  });

  useEffect(() => {
    const onPresetsChange = (message: Ws.LocalPresets) => {
      if (unitId !== message.Body.DeviceID) {
        return;
      }

      client.setQueryData<Edge.UnitPreset[]>(['unit-presets', unitId], (list) => {
        if (!list) {
          return list;
        }

        return message.Body.Payload.local;
      });
    };

    WS.on(EVENT_TYPE.LOCAL_PRESETS, onPresetsChange);

    return () => {
      WS.off(EVENT_TYPE.LOCAL_PRESETS, onPresetsChange);
    };
  }, [unitId, client]);

  return query;
}
