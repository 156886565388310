import React from 'react';
import classNames from 'classnames';
import {Container} from 'app/components/FleetManager/DeviceCard/ChildCard/Container';
import {Callback, ClassName} from 'app/types/common';
import {DeviceAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/DeviceAudioMeter';
import {isDisabledAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/utils';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

interface Props extends ClassName {
  device: AnyDeviceModelType;
  currentUserRole: AnyUserRoleModel;
  checkIsDeviceSelected: Callback;
}

export const TranscribeDeviceDetails = ({
  className,
  device,
  currentUserRole,
  checkIsDeviceSelected,
}: Props) => {
  return (
    <Container
      className={classNames('transcribe-device-details', className)}
      preview={
        <DeviceAudioMeter
          className="transcribe-device-details__audio"
          id={device.getId()}
          disabled={isDisabledAudioMeter(device)}
          segmentsCount={13}
          segmentsGap={3}
        />
      }
      device={device}
      currentUserRole={currentUserRole}
      selected={checkIsDeviceSelected(device.getId())}
      streamingDestinations={[]}
    />
  );
};
