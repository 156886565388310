import React from 'react';
import {Stack, Typography} from '@mui/material';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';

export function InitializationPlaceholder() {
  return (
    <Stack
      sx={{width: '400px', height: '400px'}}
      justifyContent="center"
      alignItems="center"
      gap={1}
    >
      <Typography variant="h6" textAlign="center">
        Preparing tiers
      </Typography>
      <LoadingPlaceholder />
    </Stack>
  );
}
