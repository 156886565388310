import React, {Component} from 'react';

export class DropdownSelectNoOptionsMessage extends Component {
  static defaultProps = {
    children: 'No options',
  };

  render() {
    const {
      children = 'No options',
    } = this.props;

    return (
      <div className="dropdown-select__no-options-message">
        {children}
      </div>
    );
  }
}
