import React from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import {LoadingButton} from '@mui/lab';

interface Props {
  onClick: Fn;
}

export function RefreshButton({onClick}: Props) {
  return (
    <LoadingButton
      data-id="refresh_schedule"
      variant="text"
      color="primary"
      startIcon={<CachedIcon />}
      disableRipple={false}
      onClick={onClick}
    >
      Refresh schedule
    </LoadingButton>
  );
}
