import React, {FormEvent, useCallback, useState} from 'react';
import {DeviceGroupSelector} from 'app/components/PairDevice/PairDeviceForm/DeviceGroupSelector';
import {PairDeviceSubmitButton} from 'app/components/PairDevice/PairDeviceForm/PairDeviceSubmitButton';
import {Callback} from 'app/types/common';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';

type DeviceToActionParam = {
  teamId: string;
  deviceGroupId?: string;
};

interface Props {
  teamId: string;
  hasGroups: boolean;
  showCancelButton: boolean;
  onCancel: Callback;
  onSubmit: Callback<Promise<unknown>, [DeviceToActionParam]>;
}

export function OneClickPairDeviceForm({
  teamId,
  hasGroups,
  showCancelButton,
  onCancel,
  onSubmit,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<Record<string, any>>({
    deviceGroupId: null,
  });

  const handleChange = useCallback(({target: {value, name}}) => {
    setFormData((formData) => ({
      ...formData,
      // Reset group selection on team change
      deviceGroupId: null,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);

      onSubmit({
        deviceGroupId: formData.deviceGroupId,
        teamId,
      }).finally(() => {
        setLoading(false);
      });
    },
    [formData, teamId, onSubmit],
  );

  return (
    <form className="one-click-pair-device-form" action="" onSubmit={handleSubmit}>
      {hasGroups && (
        <DeviceGroupSelector
          className="one-click-pair-device-form__form-control"
          value={formData.deviceGroupId}
          name="deviceGroupId"
          teamId={teamId}
          disabled={loading}
          onChange={handleChange}
        />
      )}

      <div className="one-click-pair-device-form__form-actions">
        <PairDeviceSubmitButton
          className="one-click-pair-device-form__form-button"
          loading={loading}
        />

        {showCancelButton && (
          <Button
            dataId="cancel_pair_device_button"
            className="one-click-pair-device-form__form-button"
            variant={VARIANT.OUTLINE}
            theme={THEME.SECONDARY}
            size={SIZE.L}
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
}
