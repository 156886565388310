import {noop} from 'app/util/noop';
import {OAuthCallback} from 'app/util/OAuthProvider/OAuthCallback';
import {Callback} from 'app/types/common';

const OAUTH_WINDOW_CLOSED_CHECKING_INTERVAL = 300;

export class ModalWindow {
  protected name: string;
  protected url: string;
  protected width: number;
  protected height: number;
  protected onOpen: Callback;
  protected onClose: Callback;
  protected oauthWindow;
  protected oauthInterval = -1;

  constructor(options) {
    // should not include space for IE
    this.name = options.name || 'OAuth';
    this.url = options.url;
    this.width = options.width || 600;
    this.height = options.height || 600;

    this.onOpen = options.onOpen || noop;
    this.onClose = options.onClose || noop;
  }

  public open() {
    this.onOpen();

    const oauthCallback = new OAuthCallback(this.onClose);

    this.oauthWindow = this.openWindow();

    this.oauthInterval = window.setInterval(() => {
      if (this.oauthWindow === null || this.oauthWindow.closed) {
        window.clearInterval(this.oauthInterval);
        oauthCallback.destroy();
      }
    }, OAUTH_WINDOW_CLOSED_CHECKING_INTERVAL);
  }

  private openWindow() {
    const {top, left} = this.calculateWindowPosition();

    const windowFeatures = 'toolbar=no, directories=no, status=no, menubar=no, scrollbars=no,' +
      `resizable=no, copyhistory=no, width=${this.width}, height=${this.height}, top=${top}, left=${left}`;

    return window.open(this.url, this.name, windowFeatures);
  }

  private calculateWindowPosition() {
    const windowLeft = window.screenLeft ? window.screenLeft : window.screenX;
    const windowTop = window.screenTop ? window.screenTop : window.screenY;

    const left = windowLeft + (window.innerWidth / 2) - (this.width / 2);
    const top = windowTop + (window.innerHeight / 2) - (this.height / 2);

    return {top, left};
  }
}
