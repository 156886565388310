import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {Icons} from 'app/util/icons';
import {Sx} from 'app/types/common';
import {Payment} from 'app/models/Payment';
import {palette} from 'app/themes/app';
import {PaymentBox} from 'app/components/Settings/BillingAndUsage/PaymentInformation/PaymentBox/PaymentBox';

interface Props extends Sx {
  payment: Payment;
  onEditPayment: Fn;
  onCancelPending: Fn;
}

export function PaymentMethod({sx, payment, onCancelPending, onEditPayment}: Props) {
  const {hasMethod, hasCard, ccPayment, invoicePayment, pendingInvoicePayment, card} = payment;
  const noCard = ccPayment && !hasCard;

  const noPayment = !hasMethod || noCard;

  const renderBody = () => {
    if (noPayment) {
      return <Typography data-id="payment-msg">No payment information</Typography>;
    }

    if (invoicePayment || pendingInvoicePayment) {
      return (
        <Typography data-id="payment-msg">
          Billing is invoice-based{' '}
          <Typography component="span" color={palette.red}>
            (pending approval)
          </Typography>
        </Typography>
      );
    }

    if (card) {
      return (
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography
            data-id="card-brand"
            maxWidth={80}
            fontWeight={600}
            component="strong"
            textTransform="uppercase"
            fontSize={16}
          >
            {card.brand}
          </Typography>

          <Box>
            <Typography data-id="card-number" component="strong" fontWeight={600}>
              **** **** **** {card.last4}
            </Typography>

            <Typography color={palette.badge}>
              Expires{' '}
              <strong data-id="card-expire-date">
                {card.expMonth}/{card.expYear}
              </strong>
            </Typography>
          </Box>
        </Stack>
      );
    }

    return null;
  };

  const renderAction = () => {
    if (pendingInvoicePayment) {
      return (
        <Button
          dataId="cancel-request"
          variant={VARIANT.OUTLINE}
          theme={THEME.DANGER}
          onClick={onCancelPending}
        >
          {Icons.close().label('Cancel').reactComponent()}
        </Button>
      );
    }

    return (
      <Button
        dataId="edit-payment"
        variant={VARIANT.OUTLINE}
        theme={THEME.INFO}
        onClick={onEditPayment}
      >
        {(noPayment
          ? Icons.creditCard().label('Add')
          : Icons.edit().label('Edit')
        ).reactComponent()}
      </Button>
    );
  };

  return (
    <PaymentBox sx={sx}>
      <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize={16} fontWeight={600}>
          Payment method
        </Typography>

        {renderAction()}
      </Stack>

      {renderBody()}
    </PaymentBox>
  );
}
