import React from 'react';
import {Sx} from 'app/types/common';
import {CSSObject, Divider, Stack} from '@mui/material';
import {packSx} from 'app/util/packSx/packSx';
import {FeatureBox} from 'app/components/BillingManager/dialogs/shared/FeatureBox/FeatureBox';
import {FeatureAccess} from 'app/components/BillingManager/dialogs/shared/FeaturesAccess/FeatureAccess/FeatureAccess';
import {Subscription} from 'app/components/BillingManager/dialogs/shared/features';

interface Props extends Sx {
  type: Subscription;
  list: App.Billing.Feature[];
  limit: number;
}

const cell: CSSObject = {justifyContent: 'center'};

export function FeaturesAccess({sx, type, list, limit}: Props) {
  return (
    <Stack
      sx={packSx(
        {
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        },
        sx,
      )}
      divider={<Divider sx={{borderStyle: 'dashed'}} flexItem />}
    >
      {list.map((f) => (
        <FeatureBox sx={cell} key={f.id}>
          <FeatureAccess feature={f} type={type} limit={limit} />
        </FeatureBox>
      ))}
    </Stack>
  );
}
