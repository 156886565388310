import React from 'react';
import {Stack} from '@mui/material';
import {Sx} from 'app/types/common';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {useEventMedia} from 'app/components/sharedReactComponents/Events/List/EventCard/hooks/useEventIndicators';
import {Schedule} from 'app/domain/schedule';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {
  RecordingIndicator,
  StreamingIndicator,
} from 'app/components/sharedReactComponents/DeviceIndicators/DeviceIndicators';

interface Props extends Sx {
  event: Schedule.Event;
  devices?: Map<string, PearlMasterDeviceModel>;
  offline: boolean;
}

export function EventMedia({sx, event, devices, offline}: Props) {
  const {streaming, recording} = useEventMedia({event, devices});

  const {media} = event;

  const showStreaming = media === 'all' || media === 'streaming';
  const showRecording = media === 'all' || media === 'recording';

  return (
    <Tooltip content={offline ? 'Device is offline' : undefined}>
      <Stack sx={sx} direction="row" alignItems="center" gap={0.25}>
        {showStreaming && (
          <Tooltip content={offline ? undefined : 'Streaming'}>
            <StreamingIndicator active={streaming} />
          </Tooltip>
        )}
        {showRecording && (
          <Tooltip content={offline ? undefined : 'Recording'}>
            <RecordingIndicator active={recording} />
          </Tooltip>
        )}
      </Stack>
    </Tooltip>
  );
}
