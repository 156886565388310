import React, {useState} from 'react';
import {ResendConfirmationEmailButton} from 'app/components/Login/LoginForm/ResendConfirmationEmailButton';

interface Props {
  username: string;
}

export const UserNotConfirmedExceptionMessage = ({username}: Props) => {
  const [sent, setSent] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const handleClick = () => {
    setError(null);
  };

  const handleSuccess = () => {
    setSent(true);
  };

  const handleError = (error) => {
    setError(error);
    setSent(false);
  };

  return (
    <div>
      User is not confirmed.<br/>
      Didn't receive a confirmation email?

      <ResendConfirmationEmailButton
        username={username}
        onClick={handleClick}
        onSuccess={handleSuccess}
        onError={handleError}
      />

      {sent && !error && (
        <div className="gla-form__error-info">
          Please check your email, follow the instructions to verify your email address
        </div>
      )}

      {error?.message && (
        <div>
          {error.message}
        </div>
      )}
    </div>
  );
};
