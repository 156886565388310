import React, {useEffect} from 'react';
import {Box, ClickAwayListener, Paper, Popper, PopperPlacementType} from '@mui/material';
import {isNil} from 'app/util/isNil';

interface Props {
  anchorEl: HTMLElement | null;
  placement: PopperPlacementType;
  setAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

export function EventPopper({
  anchorEl,
  children,
  placement,
  setAnchor,
}: React.PropsWithChildren<Props>) {
  const open = !isNil(anchorEl);

  const handleClickAway = (e: MouseEvent | TouchEvent) => {
    if (anchorEl && anchorEl.contains(e.currentTarget as HTMLElement)) {
      return;
    }

    setAnchor(null);
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setAnchor(null);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, setAnchor]);

  return (
    <Popper
      sx={{zIndex: ({zIndex}) => zIndex.modal}}
      open={open}
      anchorEl={anchorEl}
      transition={false}
      placement={placement}
      modifiers={[{name: 'offset', options: {offset: [2, 2]}}]}
    >
      <Paper elevation={16}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box position="relative">{children}</Box>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
}
