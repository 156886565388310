import React, {forwardRef, useCallback} from 'react';
import {noop} from 'app/util/noop';
import {Callback, ClassName, DataId} from 'app/types/common';

function processEventData({target: {name, checked}}) {
  return {
    target: {
      value: checked,
      name,
    },
  };
}

interface Props extends ClassName, DataId {
  name: string;
  value: boolean;
  tabIndex: number;
  autoFocus: boolean;
  disabled: boolean;
  onChange?: Callback;
  onBlur?: Callback;
}
// rework typing and default props
export const CheckboxBase = forwardRef<HTMLInputElement, any>(
  (
    {
      className,
      dataId,
      name,
      tabIndex,
      autoFocus,
      value,
      disabled,
      onChange,
      onBlur = noop,
      ...props
    }: Props,
    ref,
  ) => {
    const handleChange = useCallback(
      (e) => {
        onChange?.(processEventData(e));
      },
      [onChange],
    );

    const handleBlur = useCallback(
      (e) => {
        onBlur(processEventData(e));
      },
      [onBlur],
    );

    return (
      <input
        ref={ref}
        data-id={dataId}
        className={className}
        type="checkbox"
        name={name}
        tabIndex={tabIndex}
        autoFocus={autoFocus}
        checked={value === true}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
    );
  },
);
