import React from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {ClassName} from 'app/types/common';

export enum CARET_VARIANT {
  TRIANGLE = 'triangle',
  THIN = 'thin',
}

function getCaretIconByVariant(variant) {
  switch (variant) {
    case CARET_VARIANT.THIN:
      return Icons.caretThin();
    default:
      return Icons.caretTriangle();
  }
}

interface Props extends ClassName {
  variant?: CARET_VARIANT;
  size?: SIZE;
  opened?: boolean;
}

export const Caret: React.FC<Props> = ({
  className,
  variant = CARET_VARIANT.TRIANGLE,
  size,
  opened,
  ...elementProps
}) => {
  return (
    <div
      className={classNames('cr-caret', opened && 'cr-caret--opened', className)}
      {...elementProps}
    >
      {getCaretIconByVariant(variant).size(size).reactComponent()}
    </div>
  );
};
