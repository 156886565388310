import {OAuth2StreamingDestinationModel} from 'app/components/StreamingServices/StreamingDestinations/OAuth2StreamingDestinationModel';
import {
  STREAMING_SERVICE_SETTINGS_PROP_NAME,
  FACEBOOK_STREAMING_DESTINATION,
  FACEBOOK_STREAMING_PRIVACY,
} from 'app/components/StreamingServices/constants';

class FacebookStreamingDestinationModel extends OAuth2StreamingDestinationModel {
  getDestinationSetting() {
    return this.getSettings().publishDestination || FACEBOOK_STREAMING_DESTINATION.TIMELINE;
  }

  getPrivacySetting() {
    return this.getSettings().privacy || FACEBOOK_STREAMING_PRIVACY.ONLY_ME;
  }

  isContinuous() {
    return this.getSettings().continuousStream || false;
  }

  isDeletingPostAfterStop() {
    return this.getSettings().deletePostAfterStreaming || false;
  }

  protected getSettingsPropName() {
    return STREAMING_SERVICE_SETTINGS_PROP_NAME.FACEBOOK;
  }

  protected processSettingsData(json) {
    const settings = json[this.getSettingsPropName()];

    if (!settings) {
      return {};
    }

    return {
      ...super.processSettingsData(json),
      continuousStream: settings.ContinuousStream,
      deletePostAfterStreaming: settings.DeletePostAfterStreaming,
    };
  }

  protected settingsToJSON({
    continuousStream,
    deletePostAfterStreaming,
    ...rest
  }: Record<string, any>) {
    return {
      ...super.settingsToJSON(rest),
      ContinuousStream: continuousStream,
      DeletePostAfterStreaming: deletePostAfterStreaming,
    };
  }
}

export {FacebookStreamingDestinationModel};
