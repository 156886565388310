import React, {useMemo} from 'react';
import {Box} from '@mui/material';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {router} from 'app/router/main';
import {groupDeviceAlertsByDate} from 'app/components/DevicesAlerts/utils';
import {DevicesAlertsSidebarDay} from 'app/components/DevicesAlerts/DevicesAlertsSidebar/DevicesAlertsSidebarDay';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Alerts} from 'app/components/entities/alerts';

interface Props {
  alerts: Alerts.Alert[];
  acknowledgeSince: (t: number) => Promise<void>;
}

export function DevicesAlertsSidebar({acknowledgeSince, alerts}: Props) {
  const alertGroups = useMemo(() => groupDeviceAlertsByDate(alerts), [alerts]);

  return (
    <Box maxHeight="85vh" width={462} p={1.5} className="devices-alerts-sidebar">
      <div className="devices-alerts-sidebar__header">
        <span>Latest Alerts</span>

        <a className="devices-alerts-sidebar__settings-link" href={router.url('alerts')}>
          {Icons.settings().size(SIZE.M).reactComponent()}
        </a>
      </div>

      <div className="devices-alerts-sidebar__days">
        {alertGroups.map((group) => (
          <DevicesAlertsSidebarDay
            key={group.date}
            group={group}
            onAcknowledgeAlerts={acknowledgeSince}
          />
        ))}
        {alertGroups.length === 0 && (
          <div className="devices-alerts-sidebar__no-alerts">No Alerts</div>
        )}
      </div>

      <div className="devices-alerts-sidebar__footer">
        <Button
          className="devices-alerts-sidebar__button"
          onClick={() => {
            router.go('devicesAlerts');
          }}
        >
          View all Alerts
        </Button>
      </div>
    </Box>
  );
}
