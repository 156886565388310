import React from 'react';
import {SelectMenuOption, SelectMenuOptionProps} from 'app/components/sharedReactComponents/SelectMenuOption';
import {useRadioGroup} from 'app/components/sharedReactComponents/RadioGroup/useRadioGroup';
import {Radio, RADIO_THEME} from 'app/components/sharedReactComponents/Radio';
import {SIZE} from 'app/constants';
import {isNil} from 'app/util/isNil';
import {stopPropagation} from 'app/util/stopPropagation';

interface Props extends SelectMenuOptionProps {
  value: string | number | boolean;
  uncheckable?: boolean;
}

export const SelectMenuOptionWithRadio: React.FC<Props> = ({
  name: nameProp,
  value,
  active,
  uncheckable: uncheckableProp,
  disabled,
  onClick,
  children,
  ...restProps
}) => {
  const radioGroup = useRadioGroup();

  let name = nameProp;
  let uncheckable = uncheckableProp;
  let checked = active;

  if (radioGroup) {
    if (isNil(checked)) {
      checked = radioGroup.value === value;
    }

    if (isNil(uncheckable)) {
      uncheckable = radioGroup.uncheckable;
    }

    if (isNil(name)) {
      name = radioGroup.name;
    }
  }

  const changeAction = () => {
    if (typeof onClick === 'function') {
      onClick({
        target: {
          name,
          value: checked ? undefined : value,
        },
      });
    }

    if (radioGroup?.onChange) {
      radioGroup.onChange({
        target: {
          name,
          value: checked ? undefined : value,
        },
      });
    }
  };

  const handleRadioClick = (e) => {
    stopPropagation(e);

    if (!radioGroup && typeof onClick === 'function') {
      onClick({
        target: {
          name,
          value: checked ? undefined : value,
        },
      });
    }
  };

  return (
    <SelectMenuOption
      {...restProps}
      controlComponent={(
        <Radio
          name={name}
          value={value as string}
          theme={RADIO_THEME.BLACK}
          size={SIZE.S}
          checked={active}
          focusable={false}
          uncheckable={uncheckable}
          disabled={disabled}
          onClick={handleRadioClick}
          // onChange={changeAction}
        />
      )}
      disabled={disabled}
      onClick={changeAction}
    >
      {children}
    </SelectMenuOption>
  );
};
