import React from 'react';
import {Link, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {ProductCard} from 'app/components/Settings/BillingAndUsage/cards/ProductCard/ProductCard';
import {UnitsCount} from 'app/components/Settings/BillingAndUsage/cards/shared/UnitsCount/UnitsCount';
import {palette} from 'app/themes/app';
import {PseudoLink} from 'app/components/sharedReactComponents/PseudoLink';

interface Props extends Sx {
  price: number;
  configured: boolean;
  total: number;
  onEditPayment: Fn;
}

export function LiveScryptCard({sx, total, configured, price, onEditPayment}: Props) {
  const disabled = !configured || total === 0;

  const renderBody = () => {
    if (disabled) {
      return (
        <React.Fragment>
          <Typography mb={0.5}>
            This plan will be enabled when you pair LiveScrypt device
            {!configured && (
              <>
                <br />
                and <PseudoLink onClick={onEditPayment}>add billing information</PseudoLink>.
              </>
            )}
          </Typography>

          <Link
            data-id="livescrypt_info_button"
            href="https://www.epiphan.com/products/livescrypt/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </Link>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Typography fontSize={20} fontWeight={600} color={palette.blue}>
          Active
        </Typography>
        <Typography data-id="plan-price"> ${price} per hour of transcription</Typography>
      </React.Fragment>
    );
  };

  return (
    <ProductCard
      sx={sx}
      dataId="livescrypt-card"
      disabled={disabled}
      title="LiveScrypt"
      body={<Stack flexGrow={1}>{renderBody()}</Stack>}
      footer={<UnitsCount count={total} type="lvs" disabled={disabled} />}
    />
  );
}
