import React from 'react';
import {Dialog, DialogContent} from '@mui/material';
import {Prompt} from 'app/types/common';
import {InitializationPlaceholder} from 'app/components/BillingManager/dialogs/shared/InitializationPlaceholder/InitializationPlaceholder';
import {UnifyTiers} from 'app/components/BillingManager/dialogs/UnifyTiersDialog/UnifyTiers/UnifyTiers';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';

type Props = Prompt &
  React.ComponentProps<typeof UnifyTiers> & {
    loading: boolean;
  };

export function UnifyTiersDialog({
  open,
  currentId,
  tiers,
  custom,
  expireDate,
  hasCustomer,
  loading,
  onSelectTier,
  onEditPayment,
  onClose,
}: Props) {
  return (
    <Dialog open={open} scroll="body" maxWidth="xl" onClose={onClose}>
      <DialogClose dataId="close" onClose={onClose} />
      <DialogContent sx={{p: loading ? 5 : 0}}>
        {loading ? (
          <InitializationPlaceholder />
        ) : (
          <UnifyTiers
            tiers={tiers}
            hasCustomer={hasCustomer}
            currentId={currentId}
            custom={custom}
            expireDate={expireDate}
            onSelectTier={onSelectTier}
            onEditPayment={onEditPayment}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
