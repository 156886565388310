import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogTitle, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';

interface Props extends Prompt {
  name: string;
  onDelete: () => Promise<void>;
}

export function RemoveMemberDialog({open, name, onDelete, onClose}: Props) {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await onDelete();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        Remove{' '}
        <Typography component="span" variant="inherit" color="primary.main">
          {name}
        </Typography>{' '}
        from the team
      </DialogTitle>

      <DialogActions>
        <LoadingButton
          variant="contained"
          color="error"
          loading={loading}
          autoFocus={true}
          onClick={handleDelete}
        >
          Delete
        </LoadingButton>

        <Button variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
