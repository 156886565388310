import React from 'react';
import {Box, Link, Stack} from '@mui/material';
import {DeviceAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/DeviceAudioMeter';
import {isDisabledAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/utils';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {EventMediaIcon} from 'app/components/sharedReactComponents/Events/shared/EventMediaIcon/EventMediaIcon';
import {getDeviceDetailsUrl} from 'app/components/sharedReactComponents/Events/utils';
import {Thumbnail} from 'app/components/sharedReactComponents/Preview/Thumbnail/Thumbnail';
import {getPreviewUpdateStrategy} from 'app/components/sharedReactComponents/Preview/utils';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {Sx, DataId} from 'app/types/common';
import {noop} from 'app/util/noop';
import {SIZE} from 'app/constants';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';

interface Props extends Sx, DataId {
  master: PearlMasterDeviceModel;
  channel: PearlSlotDeviceModel;
  streaming: boolean;
  recording: boolean;
  active: boolean;
  preview: boolean;
}

export const EventChannel = React.memo(
  ({sx, master, channel, streaming, recording, active, preview, dataId}: Props) => {
    const channelId = channel.getId();
    const detailsUrl = getDeviceDetailsUrl(channelId);
    const disabledMeter = isDisabledAudioMeter(master);

    return (
      <Stack sx={sx} direction="row" alignItems="center" data-id={dataId} gap={1}>
        <Stack direction={preview ? 'column' : 'row'} gap={1}>
          {streaming && <EventMediaIcon type="streaming" active={active} size={SIZE.S} />}

          {recording && <EventMediaIcon type="recording" active={active} size={SIZE.S} />}
        </Stack>

        {preview && (
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Thumbnail
              id={channel.getId()}
              detailsUrl={detailsUrl}
              updateStrategy={getPreviewUpdateStrategy(channel)}
              onClick={noop}
            />
            <Box
              sx={{
                '& .device-audio-meter': {
                  width: 5,
                  height: 46,
                },
              }}
            >
              <DeviceAudioMeter
                id={channelId}
                disabled={disabledMeter}
                vertical={true}
                segmentsCount={12}
                segmentsGap={2}
              />
            </Box>
          </Stack>
        )}

        <Link data-id="channel-link" href={detailsUrl} sx={{fontSize: 'inherit'}}>
          <OverflowTooltip rowsNumber={2}>{channel.getName()}</OverflowTooltip>
        </Link>
      </Stack>
    );
  },
);
