import {UserRole} from 'app/models/PermissionsModel/types';

interface Args {
  id: string;
  email: string;
  name: string;
  role: UserRole;
  teamId: string;
  teamName: string;
  privateTeam: string;
  picture: string;
  checks: {
    agreement: boolean;
    policy: boolean;
  };
}

export class TeamUser {
  id: string;
  email: string;
  name: string;
  role: UserRole;
  teamId: string;
  teamName: string;
  privateTeam: string;
  picture: string;
  checks: {
    agreement: boolean;
    policy: boolean;
  };
  constructor(args: Args) {
    this.id = args.id;
    this.email = args.email;
    this.name = args.name;
    this.teamId = args.teamId;
    this.teamName = args.teamName;
    this.privateTeam = args.privateTeam;
    this.picture = args.picture;
    this.checks = {...args.checks};
    this.role = args.role;
  }
}
