import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import {observer} from 'mobx-react';
import {router} from 'app/router/main';
import {PageHeader} from 'app/components/sharedReactComponents/PageHeader';
import {useMeeting} from 'app/components/pages/MeetingDetails/hooks/useMeeting';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {MeetingDetailsHeader} from 'app/components/pages/MeetingDetails/MeetingDetailsHeader/MeetingDetailsHeader';
import {useCurrentTeamStore} from 'app/store/hooks';
import MeetingLayout from 'app/components/pages/MeetingDetails/MeetingLayout/MeetingLayout';
import {useCloudEntities} from 'app/components/entities/cloud';

interface Props {
  meetingId: string;
}

export const MeetingDetailsPage = observer(({meetingId}: Props) => {
  const {meeting, loading} = useMeeting(meetingId);

  const {user} = useCloudEntities();
  const team = useCurrentTeamStore();

  // Don't show this page if meeting is not running or waiting permissions
  useEffect(() => {
    if (!loading) {
      if (meeting?.status !== 'running' || meeting?.permission === 'failed') {
        router.go('meetings');
      }
    }
  }, [loading, meeting?.status, meeting?.permission]);

  const showMeeting = meeting && !loading;

  return (
    <div className="meeting-details-page">
      <PageHeader backUrl={router.url('meetings')} backText="All meetings" />

      {showMeeting ? (
        <>
          <MeetingDetailsHeader className="meeting-details-page__header" meeting={meeting} />

          <Box sx={{mb: 2}} className="container">
            <MeetingLayout meeting={meeting} teamId={team.id} userId={user.id} />
          </Box>
        </>
      ) : (
        <IndeterminateProgressBar />
      )}
    </div>
  );
});
