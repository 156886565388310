import {Callback} from 'app/types/common';
import {FilterSwitch} from 'app/components/sharedReactComponents/FilterSelector/types';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DeviceByGroupMap} from 'app/components/FleetManager/DeviceGroups/DeviceByGroupMap';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {filterItemsByFilterSwitches} from 'app/components/sharedReactComponents/FilterSelector/utils';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {Schedule} from 'app/domain/schedule';
import {isOngoing} from 'app/domain/schedule/utils';
import {Edge} from 'app/domain/edge';

function filterDevices(
  devices: AnyDeviceModelType[],
  filters: FilterSwitch[],
): AnyDeviceModelType[] {
  return filterItemsByFilterSwitches(devices, filters);
}

function filterDeviceGroups(deviceGroups: Edge.Group[], filters: FilterSwitch[]): Edge.Group[] {
  return filterItemsByFilterSwitches(deviceGroups, filters, (filter) => filter?.callbackForGroup);
}

function filterPresets(presets: Edge.TeamPreset[], filters: FilterSwitch[]): Edge.TeamPreset[] {
  return filterItemsByFilterSwitches(presets, filters);
}

function collectSelectedDevices(
  devices: AnyDeviceModelType[],
  checkIsDeviceSelected: (deviceId: string) => boolean,
  collection: any[] = [],
): AnyDeviceModelType[] {
  devices.forEach((device) => {
    if (checkIsDeviceSelected(device.getId())) {
      collection.push(device);
    }

    if (ModelService.isMultiChannel(device.getModelName())) {
      return collectSelectedDevices(
        (device as PearlMasterDeviceModel).getChanelModels(),
        checkIsDeviceSelected,
        collection,
      );
    }
  });

  return collection;
}

function collectSelectedGroups(
  deviceGroups: Edge.Group[],
  checkIsDeviceGroupSelected: Callback,
): Edge.Group[] {
  return deviceGroups.filter(({id}) => checkIsDeviceGroupSelected(id));
}

function getDeviceModelNames(devices: AnyDeviceModelType[]): string[] {
  const resultSet = new Set<string>(devices.map((device) => device.getModelName()));
  return Array.from(resultSet);
}

function containsSearchString(value: string, search: string): boolean {
  return value.toLowerCase().includes(search.toLowerCase());
}

function filterByIdOrSerialNumberOrName(device: AnyDeviceModelType, search: string) {
  const name = device.getName();
  const id = device.getId();
  const serial = device.getSerialNumber() ?? '';
  return (
    containsSearchString(name, search) ||
    containsSearchString(id, search) ||
    containsSearchString(serial, search)
  );
}

function collectDevicesAndChannelsIds(devices: AnyDeviceModelType[]): string[] {
  const deviceIds: any[] = [];

  devices.forEach((device) => {
    deviceIds.push(...collectDeviceAndChannelIds(device));
  });

  return deviceIds;
}

function collectDeviceAndChannelIds(device: AnyDeviceModelType): string[] {
  const deviceId = device.getId();

  if (ModelService.isMultiChannel(device.getModelName()) === false) {
    return [deviceId];
  }

  const channelDevices = (device as PearlMasterDeviceModel).getChanelModels();
  const channelDevicesIds = channelDevices.map((channelDevice) => channelDevice.getId());

  return [deviceId, ...channelDevicesIds];
}

function collectGroupsDevices(
  groupIds: string[],
  deviceByGroupMap: DeviceByGroupMap,
): AnyDeviceModelType[] {
  const devices: AnyDeviceModelType[] = [];

  groupIds.forEach((groupId) => {
    devices.push(...collectGroupDevices(groupId, deviceByGroupMap));
  });

  return devices;
}

function collectGroupDevices(
  groupId: string,
  deviceByGroupMap: DeviceByGroupMap,
): AnyDeviceModelType[] {
  return deviceByGroupMap.getDeviceGroupById(groupId);
}

interface DeviceStats {
  online: number;
  offline: number;
  recording: number;
  streaming: number;
  active: number;
}

function collectDevicesStatisticsData(devices: AnyDeviceModelType[]): DeviceStats {
  let online = 0;
  let recording = 0;
  let streaming = 0;
  let active = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const device of devices) {
    if (device.isOnline()) {
      online += 1;
    }

    const deviceRecording = device.isRecording();
    if (deviceRecording) {
      recording += 1;
    }

    const deviceStreaming = device.isStreaming();
    if (deviceStreaming) {
      streaming += 1;
    }

    const deviceTranscribing = device.isTranscribing();

    if (deviceRecording || deviceStreaming || deviceTranscribing) {
      active += 1;
    }
  }

  const offline = devices.length - online;

  return {
    online,
    offline,
    recording,
    streaming,
    active,
  };
}

function getDevicesWithOngoingEvents(
  deviceIds: string[],
  actualEvents: Map<string, Schedule.Event>,
): string[] {
  const devices = new Set(deviceIds);

  const result = Array.from(actualEvents.entries()).reduce<string[]>((acc, p) => {
    const [deviceId, event] = p;

    if (isOngoing(event.status) && devices.has(deviceId)) {
      return [...acc, deviceId];
    }

    return acc;
  }, []);

  return result;
}

export {
  filterDevices,
  filterDeviceGroups,
  filterPresets,
  collectSelectedDevices,
  collectSelectedGroups as collectSelectedDeviceGroups,
  getDeviceModelNames,
  containsSearchString,
  collectDeviceAndChannelIds,
  collectDevicesAndChannelsIds,
  collectGroupDevices,
  collectGroupsDevices,
  collectDevicesStatisticsData,
  getDevicesWithOngoingEvents,
  filterByIdOrSerialNumberOrName,
};
