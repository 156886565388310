import {useQuery} from '@tanstack/react-query';
import {TeamApi} from 'app/components/entities/team/api/TeamApi';
import {TeamMapper} from 'app/components/entities/team/mapper';

interface Args {
  teamId: string;
  enabled: boolean;
}

export function useMembersQuery({teamId, enabled}: Args) {
  return useQuery({
    queryKey: ['team', 'members', teamId],
    queryFn: async () => {
      const res = await TeamApi.getMembers(teamId);
      const mapped = res.map(TeamMapper.mapMember);
      return new Map(mapped.map((m) => [m.id, m]));
    },
    select: (data) => Array.from(data.values()),
    enabled,
  });
}
