import React, {useMemo} from 'react';
import {filesize} from 'filesize';
import {Typography} from '@mui/material';
import {DataId, Sx} from 'app/types/common';
import {Files} from 'app/components/entities/files';
import {pluralizeN} from 'app/components/helpers/commonHelpers';

interface Props extends Sx, DataId {
  files: Files.Recording[];
}

export function TotalAndSize({dataId, sx, files}: Props) {
  const size = useMemo(() => files.reduce((acc, file) => acc + file.size, 0), [files]);

  return (
    <Typography data-id={dataId} sx={sx} fontWeight={600}>
      {files.length} {pluralizeN('file', files.length)} | {filesize(size)}
    </Typography>
  );
}
