import React from 'react';
import {Typography} from '@mui/material';
import {red} from '@mui/material/colors';
import {Sx} from 'app/types/common';

interface Props extends Sx {}

export function CmsAfuMessage({sx}: Props) {
  return (
    <Typography sx={sx} color="text.secondary" py={0.5} px={1} bgcolor={red[50]} borderRadius={1}>
      To enable uploading to Epiphan Edge File Management select Epiphan Edge as the Automatic File
      Upload destination in your device’s admin UI.
    </Typography>
  );
}
