import React, {useRef} from 'react';
import {ContextMenu} from 'app/components/sharedReactComponents/ContextMenu';
import {Button} from 'app/components/sharedReactComponents/Button';
import {
  THEME,
  VARIANT,
} from 'app/constants';
import {Icons} from 'app/util/icons';
import {StreamingServiceIcon} from 'app/components/StreamingServices/StreamingServiceIcon';
import {streamingServiceComparator} from 'app/util/comparators/streamingServiceComparator';
import {Callback, ClassName} from 'app/types/common';
import {streamingServicesRepository} from 'app/components/StreamingServices';

const getReactIcon = (type) => StreamingServiceIcon.getSmallIconByType(type).reactComponent();

/**
 * @param {BaseStreamingService[]} streamingServices
 * @param {Function} action
 * @return {Array}
 */
function streamingServicesToOptions(streamingServices, action) {
  return streamingServices.map(service => {
    const serviceType = service.getType();
    const disabled = service.isDisabled();

    return {
      label: service.getName(),
      icon: getReactIcon(serviceType),
      value: serviceType,
      itemDataId: `create_steaming_destination_${serviceType}`,
      title: disabled ? 'Coming soon' : undefined,
      disabled,
      visible: !disabled,
      action: () => action(serviceType),
    };
  });
}

interface Props extends ClassName {
  onStreamingDestinationCreate: Callback;
  disabled: boolean;
}

export const CreateStreamingDestinationButton: React.VFC<Props> = ({
  className,
  disabled,
  onStreamingDestinationCreate,
}) => {
  const options = useRef([]);

  if (options.current.length === 0) {
    const services = streamingServicesRepository.getAvailableToCreateServices().sort(streamingServiceComparator);

    options.current = streamingServicesToOptions(services, onStreamingDestinationCreate);
  }

  return (
    <ContextMenu
      items={options.current}
    >
      <Button
        className={className}
        dataId="device_streaming_panel_create_button"
        theme={THEME.INFO}
        variant={VARIANT.OUTLINE}
        disabled={disabled}
      >
        {Icons.plus().label('New destination').reactComponent()}
      </Button>
    </ContextMenu>
  );
};
