import React from 'react';
import {Stack, Typography} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import WarningIcon from '@mui/icons-material/Warning';
import {grey} from '@mui/material/colors';
import {Component, Sx} from 'app/types/common';
import {Icons} from 'app/util/icons';
import {LegacyIcon} from 'app/components/sharedReactComponents/LegacyIcon/LegacyIcon';
import {palette} from 'app/themes/app';

interface Props extends Sx, Component {
  name: string;
  platform: App.Connect.Platform;
  available: boolean;
}

export function PlatformAccount({dataId, sx, name, platform, available}: Props) {
  return (
    <Stack data-id={dataId} sx={sx} direction="row" alignItems="center" gap={0.5}>
      {available ? (
        <FiberManualRecordIcon sx={{color: palette.green, fontSize: 10}} />
      ) : (
        <WarningIcon sx={{color: palette.red, fontSize: 16}} />
      )}
      <LegacyIcon sx={{fontSize: 16}}>
        {(platform === 'teams' ? Icons.microsoftTeams() : Icons.zoom()).reactComponent()}
      </LegacyIcon>
      <Typography color={grey[500]}>{name}</Typography>
    </Stack>
  );
}
