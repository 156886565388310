import {useEffect, useState} from 'react';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';

interface Args {
  devices: AnyDeviceModelType[];
}

type State = {
  devices: number;
  channels: number;
};

export function useCountSelected({devices}: Args) {
  const [state, setState] = useState<State>({devices: 0, channels: 0});

  useEffect(() => {
    const counter: State = {devices: 0, channels: 0};

    const multiSlotDevices = new Set<string>();

    devices.forEach((device) => {
      if (ModelService.isChannel(device.getModelName())) {
        const masterId = (device as PearlSlotDeviceModel).getChannelDeviceMasterDeviceId();
        multiSlotDevices.add(masterId);
        counter.channels += 1;
        return;
      }

      if (ModelService.isMultiChannel(device.getModelName())) {
        const masterId = device.getId();
        multiSlotDevices.add(masterId);
        return;
      }

      counter.devices += 1;
    });

    counter.devices += multiSlotDevices.size;

    setState(counter);
  }, [devices]);

  return state;
}
