import React, {forwardRef} from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';

export enum PANEL_THEME {
  DEVICE_DETAILS_ACTIONS = 'device-details-actions',
  DEVICE_SETTINGS = 'device-settings',
}

interface Props extends ClassName {
  title: React.ReactNode;
  theme: PANEL_THEME;
  children?: React.ReactNode;
}

export const Panel = forwardRef<HTMLDivElement, Props>(({
  className,
  title,
  theme,
  children
}, ref) => (
  <div
    ref={ref}
    className={classNames('panel', `panel--theme-${theme}`, className)}
  >
    <div className="panel__title">
      {title}
    </div>

    <div className="panel__body">
      {children}
    </div>
  </div>
));

Panel.displayName = 'Panel';
