/* eslint-disable no-restricted-syntax */
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {DeviceIndicatorItem} from 'app/components/FleetManager/DeviceCard/StatusList/DeviceStatusIndicator/types';
import {deviceIndicators} from 'app/components/FleetManager/DeviceCard/StatusList/DeviceStatusIndicator/useActiveItems';
import {Schedule} from 'app/domain/schedule';
import {useEffect, useState} from 'react';

interface Args {
  event: Schedule.Event;
  devices?: Map<string, PearlMasterDeviceModel>;
}

export function useEventIndicators({devices, event}: Args): DeviceIndicatorItem[] {
  const [indicators, setIndicators] = useState<DeviceIndicatorItem[]>([]);

  useEffect(() => {
    if (!devices) {
      setIndicators([]);
      return;
    }

    let [streaming, recording] = [false, false];

    for (const pair of [...event.devices.entries()]) {
      if (streaming && recording) {
        break;
      }

      const [deviceId, deviceProfile] = pair;

      const device = devices.get(deviceId);

      if (device) {
        const channels = device.getChanelModels();

        for (const [channelIdx, profile] of [...deviceProfile.entries()]) {
          if (streaming && recording) {
            break;
          }

          const channel = channels.find((c) => c.getChannelDeviceIdIndex() === channelIdx);

          if (channel) {
            if (channel.isRecording() && profile.recording) {
              recording = true;
            }

            if (channel.isStreaming() && profile.streaming) {
              streaming = true;
            }
          }
        }
      }
    }

    setIndicators(deviceIndicators.filter((item) => item.isActive({streaming, recording})));
  }, [event, devices]);

  return indicators;
}

type MediaState = {
  streaming: boolean;
  recording: boolean;
};

export function useEventMedia({devices, event}: Args): MediaState {
  const [state, setState] = useState<MediaState>({streaming: false, recording: false});

  useEffect(() => {
    let [streaming, recording] = [false, false];

    if (!devices) {
      setState({streaming, recording});
      return;
    }

    for (const pair of [...event.devices.entries()]) {
      if (streaming && recording) {
        break;
      }

      const [deviceId, deviceProfile] = pair;

      const device = devices.get(deviceId);

      if (device) {
        const channels = device.getChanelModels();

        for (const [channelIdx, profile] of [...deviceProfile.entries()]) {
          if (streaming && recording) {
            break;
          }

          const channel = channels.find((c) => c.getChannelDeviceIdIndex() === channelIdx);

          if (channel) {
            if (channel.isRecording() && profile.recording) {
              recording = true;
            }

            if (channel.isStreaming() && profile.streaming) {
              streaming = true;
            }
          }
        }
      }
    }

    setState({streaming, recording});
  }, [event, devices]);

  return state;
}
