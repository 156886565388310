import {DeviceModel} from 'app/components/DeviceDetails/Models/DeviceModel';
import {front} from 'app/api/CirrusApi';
import {DeviceCapability, TranscribingStatus} from 'app/components/DeviceDetails/constants';
import {getTranscriberStatus} from 'app/components/DeviceDetails/utils';
import {TranscriptionConfiguration} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Transcription/TranscriptionConfiguration';
import {TranscribingInfo, TranscribingURL} from 'app/components/DeviceDetails/Models/types';
import {EdgeContract} from 'app/contracts/edge';
import {HardwareContract} from 'app/components/entities/hardware';

export class LiveScryptDeviceModel extends DeviceModel {
  /**
   * @deprecated in transcribing feature
   */
  async getTranscribingSettings(): Promise<Array<Record<string, any>>> {
    return TranscriptionConfiguration.processSettings(this.getTranscribingSettingsRaw() ?? {});
  }

  /**
   * @deprecated in transcribing feature
   */
  async updateTranscribingSetting(id: string, value: any): Promise<any> {
    const previousData = {...this.getTranscribingSettingsRaw()};
    const nextData = {
      ...previousData,
      [id]: value,
    };
    this.setTranscribingSettings(nextData as any);

    return (
      front
        .devices(this.getId())
        .transcribe()
        .configuration()
        .put(nextData)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((_) => {
          this.setTranscribingSettings(previousData as any);
        })
    );
  }

  /**
   * @deprecated in transcribing feature
   */
  getTranscribingInfo(): TranscribingInfo {
    const transcribing = this.getTranscribingState();
    const transcribingParams = transcribing?.Params;
    const active = this.isOnline() && Boolean(transcribingParams?.Enabled);
    const paused = Boolean(transcribingParams?.Paused);
    const status = getTranscriberStatus(active, paused);
    const url = transcribingParams?.ShortURL ?? null;
    const sessionId = transcribingParams?.SessionID;

    return {
      url,
      sessionId,
      status,
      paused,
      active,
    };
  }

  /**
   * @deprecated in transcribing feature
   */
  isTranscribing(): boolean {
    return this.getTranscribingInfo().active;
  }

  /**
   * @deprecated in transcribing feature
   */
  getTranscribingStatus(): TranscribingStatus {
    return this.getTranscribingInfo().status;
  }

  /**
   * @deprecated in transcribing feature
   */
  getTranscribingURL(): TranscribingURL {
    const data = this.device.TranscriberURL;

    return {
      base: data?.Base,
      userDefined: data?.UserDefined,
    };
  }

  /**
   * @deprecated in transcribing feature
   */
  async setTranscribingURLUserDefined(userDefined: string): Promise<any> {
    return front.devices(this.getId()).transcribe().id().post({id: userDefined});
  }

  /**
   * @deprecated in transcribing feature
   */
  async startTranscribing(): Promise<any> {
    return front.devices(this.getId()).transcribe().start().post();
  }

  /**
   * @deprecated in transcribing feature
   */
  async stopTranscribing(): Promise<any> {
    return front.devices(this.getId()).transcribe().stop().post();
  }

  /**
   * @deprecated in transcribing feature
   */
  async pauseTranscribing(): Promise<any> {
    return front.devices(this.getId()).transcribe().pause().post();
  }

  /**
   * @deprecated in transcribing feature
   */
  async resumeTranscribing(): Promise<any> {
    return front.devices(this.getId()).transcribe().resume().post();
  }

  /**
   * @deprecated in transcribing feature
   */
  async clearCurrentTranscribingSession(): Promise<any> {
    const {sessionId} = this.getTranscribingInfo();

    return front.transcribing(this.getId()).sessions(sessionId).clear().post();
  }

  /**
   * @deprecated in device model capabilities getter
   */
  getCapabilitiesRaw(): HardwareContract.Capability[] {
    return [...super.getCapabilitiesRaw(), DeviceCapability.AudioLevels];
  }

  private getTranscribingState(): EdgeContract.TranscribingState | undefined {
    return this.getDesiredState().find(
      (item): item is EdgeContract.TranscribingState => item.Type === 'transcribing',
    );
  }

  private getTranscribingSettingsRaw(): EdgeContract.TranscribeConfig | undefined {
    return this.getTranscribingState()?.Params?.Config;
  }

  private setTranscribingSettings(settings: EdgeContract.TranscribeConfig) {
    this.device.DesiredState = this.device.DesiredState?.map((item) => {
      if (item.Type === 'transcribing') {
        return {...item, Params: {...item.Params, Config: settings}};
      }

      return item;
    });
  }
}
