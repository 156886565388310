import React, {useState} from 'react';
import {grey} from '@mui/material/colors';
import {Box, Link, Stack, Typography} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import {LegacyIcon} from 'app/components/sharedReactComponents/LegacyIcon/LegacyIcon';
import {Sx} from 'app/types/common';
import {Icons} from 'app/util/icons';
import {palette} from 'app/themes/app';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {RemoveAccountDialog} from 'app/components/Settings/ConnectSettings/dialogs/RemoveAccountDialog/RemoveAccountDialog';
import {isErrorResponse} from 'app/api/types';
import {Notifications} from 'app/components/Notifications';

interface Props extends Sx {
  tenant: App.Connect.Auth.TeamsTenant;
  canManage: boolean;
  onUnpair: Fn;
}

export function TeamsTenant({sx, tenant, canManage, onUnpair}: Props) {
  const [deleteDialog, setDeleteDialog] = useState(false);

  const openDelete = () => {
    setDeleteDialog(true);
  };

  const closeDelete = () => {
    setDeleteDialog(false);
  };

  const handleUnpair = async () => {
    try {
      await onUnpair(tenant.id);
      closeDelete();
    } catch (e: unknown) {
      if (isErrorResponse(e)) {
        Notifications.addErrorNotification(e.data.Error ?? 'Cannot delete Microsoft Teams tenant');
      }
    }
  };

  return (
    <Stack
      sx={sx}
      direction="row"
      alignItems="flex-start"
      gap={2}
      p={4}
      borderRadius={1}
      border={1}
      bgcolor={grey[100]}
      borderColor={grey[400]}
    >
      <LegacyIcon fontSize={72}>{Icons.microsoftTeams().reactComponent()}</LegacyIcon>
      <Box>
        <Typography mb={2} fontWeight="bold" fontSize={20}>
          {tenant.name}
        </Typography>

        <Stack mb={2} direction="row" gap={0.5} alignItems="center">
          <CheckIcon sx={{color: palette.green}} />
          <Typography>Connected</Typography>
        </Stack>

        <Typography>
          <Link
            href="https://www.epiphan.com/userguides/connect/Content/Connect/Disconnect-Connect-from-Teams.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </Link>{' '}
          how to unpair Microsoft Teams organization from Epiphan Connect.
        </Typography>
      </Box>

      <RemoveAccountDialog
        open={deleteDialog}
        name={tenant.name}
        platform="teams"
        onRemove={handleUnpair}
        onClose={closeDelete}
      />

      {canManage && (
        <Box ml="auto">
          <Button
            dataId="unpair-btn"
            variant={VARIANT.OUTLINE}
            theme={THEME.DANGER}
            onClick={openDelete}
          >
            Unpair
          </Button>
        </Box>
      )}
    </Stack>
  );
}
