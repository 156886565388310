import React from 'react';
import {useLocalStorage} from 'react-use';
import {Button, IconButton, Stack, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {Sx} from 'app/types/common';
import {router} from 'app/router/main';

interface Props extends Sx {
  userId: string;
  permitBilling: boolean;
}

export function EdgeAd({sx, userId, permitBilling}: Props) {
  const [ad, setAd] = useLocalStorage(`Ad.${userId}`, '');

  if (ad !== 'files') {
    return (
      <Stack
        data-id="edge-ad"
        sx={sx}
        p={2}
        bgcolor="#333333"
        color="#fff"
        direction="row"
        alignItems="center"
        gap={2}
        borderRadius={2}
      >
        <Typography flex={1}>
          Get your files faster and easier with automatic backups of your files to Epiphan Edge
        </Typography>

        {permitBilling && (
          <Button
            data-id="edit-billing-btn"
            variant="contained"
            color="secondary"
            onClick={() => router.go('settingBillingUsage')}
          >
            Get started for free
          </Button>
        )}

        <IconButton data-id="close-ad-btn" onClick={() => setAd('files')}>
          <CloseIcon color="info" />
        </IconButton>
      </Stack>
    );
  }

  return null;
}
