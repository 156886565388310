import {useEffect, useRef, useState} from 'react';
import {isNil} from 'app/util/isNil';
import {front} from 'app/api/CirrusApi';
import {noop} from 'app/util/noop';
import {intersectionOfSets} from 'app/util/intersectionOfSets';
import {isSetsEquals} from 'app/util/isSetsEquals';
import {useIsMounted} from 'app/hooks/useIsMounted';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

// /**
//  * @param device
//  * @param {AnyStreamingDestinationModelType[]} streamingDestinations
//  * @return {{handleStreamingDestinationSelect: function, selectingStreamingDestinations: boolean, selectedStreamingDestinations: Set<string>}}
//  */

interface Args {
  device: AnyDeviceModelType;
  streamingDestinations: AnyStreamingDestinationModelType[];
}

export function useDeviceStreamingDestinationSelection({device, streamingDestinations}: Args) {
  const [selected, setSelected] = useState(new Set());
  const [selecting, setSelecting] = useState(false);
  const mountedRef = useIsMounted();

  const selectionManager = useRef<SelectionManager | null>(null);

  if (selectionManager.current === null) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    selectionManager.current = new SelectionManager({
      device,
      streamingDestinations,
      onUpdate: (selected) => setSelected(selected),
      onStartSelecting: () => setSelecting(true),
      onStopSelecting: () => {
        if (mountedRef.current) {
          setSelecting(false);
        }
      },
    });
  }

  useEffect(() => () => selectionManager.current?.unmount(), []);

  useEffect(() => {
    selectionManager.current?.setDevice(device);
  }, [device]);

  useEffect(() => {
    selectionManager.current?.setStreamingDestinations(streamingDestinations);
  }, [streamingDestinations]);

  const handleSelect = ({target: {name, value}}) => {
    const checked = isNil(value) === false;
    return selectionManager.current?.updateAction(name, checked);
  };

  return {
    selectedStreamingDestinations: selected,
    selectingStreamingDestinations: selecting,
    handleStreamingDestinationSelect: handleSelect,
  };
}

class SelectionManager {
  /**
   * @type {AnyDeviceModelType}
   */
  #device: any = null;
  #deviceId = null;
  #streamingDestinationsIds = new Set();
  #selectedIds = new Set();
  #selectedInDeviceIds = new Set();
  #touched = false;
  #previousSelectedIds = new Set();
  #updateTimeout: any = null;
  #command = {execute: noop};
  #updatePromiseResolve = noop;
  #updateTimeoutMs = 1000;
  #onUpdate;
  #onStartSelecting;
  #onStopSelecting;

  /**
   * @param {AnyDeviceModelType} device
   * @param {AnyStreamingDestinationModelType[]} streamingDestinations
   * @param {Function} onStartSelecting
   * @param {Function} onStopSelecting
   * @param {Function} onUpdate
   */
  constructor({device, streamingDestinations, onStartSelecting, onStopSelecting, onUpdate}) {
    this.#onUpdate = onUpdate;
    this.#onStartSelecting = onStartSelecting;
    this.#onStopSelecting = onStopSelecting;

    this.setDevice(device);
    this.setStreamingDestinations(streamingDestinations);

    this.#updateTimeout = null;
  }

  unmount() {
    this.#stopTimer();
  }

  /**
   * @return {Set<string>}
   */
  getSelectedIds() {
    let result = this.#selectedInDeviceIds;

    if (this.#touched && isSetsEquals(this.#selectedInDeviceIds, this.#selectedIds)) {
      this.#touched = false;
      this.#selectedIds = new Set();
      this.#previousSelectedIds = new Set();
    }

    if (this.#touched) {
      result = this.#selectedIds;
    }

    return intersectionOfSets(result, this.#streamingDestinationsIds);
  }

  /**
   * @param {AnyDeviceModelType} device
   */
  setDevice(device) {
    this.#device = device;

    if (this.#device) {
      this.#deviceId = this.#device?.getId();
      this.#selectedInDeviceIds = new Set([this.#device.getSelectedStreamingDestinationId()]);
    }

    this.#triggerUpdate();
  }

  /**
   * @param {AnyStreamingDestinationModelType[]} streamingDestinations
   */
  setStreamingDestinations(streamingDestinations) {
    this.#streamingDestinationsIds = new Set(
      streamingDestinations.map((streamingDestination) => {
        return streamingDestination.getId();
      }),
    );

    this.#triggerUpdate();
  }

  /**
   * @param {string} id Streaming destination ID
   * @param {boolean} value If `true` select streaming destination
   */
  async updateAction(id, value) {
    this.#touched = true;
    this.#stopTimer();
    this.#savePrevious();
    this.#selectedIds = new Set(value ? [id] : []);
    this.#triggerUpdate();
    this.#command.execute = () => {
      this.#onStartSelecting();
      front
        .streams(id)
        .attach()
        .post({DeviceID: value ? this.#deviceId : null})
        .catch(() => this.#restorePrevious())
        .finally(() => {
          this.#onStopSelecting();
          this.#updatePromiseResolve();
        });
    };

    this.#startTimer();

    return new Promise((resolve: any) => {
      this.#updatePromiseResolve = resolve;
    });
  }

  #triggerUpdate = () => {
    this.#onUpdate(this.getSelectedIds());
  };

  #startTimer = () => {
    this.#updateTimeout = setTimeout(() => {
      this.#command.execute();
    }, this.#updateTimeoutMs);
  };

  #stopTimer = () => {
    clearTimeout(this.#updateTimeout);
  };

  #savePrevious = () => {
    this.#previousSelectedIds = this.#selectedIds;
  };

  #restorePrevious = () => {
    this.#selectedIds = this.#previousSelectedIds;
    this.#triggerUpdate();
  };
}
