import React from 'react';
import {formatAbbreviatedDateWithYear} from 'app/util/time';
import {Callback, Sx} from 'app/types/common';
import {Stack, Typography} from '@mui/material';
import {Button} from 'app/components/sharedReactComponents/Button';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {THEME, VARIANT} from 'app/constants';
import {palette} from 'app/themes/app';

interface Props extends Sx {
  endDate: TimeStampSeconds;
  onSubmit: Callback;
  onCancel: Callback;
}

export const UnsubscribeFromConnect = ({sx, endDate, onSubmit, onCancel}: Props) => {
  return (
    <Stack sx={sx} alignItems="center">
      <Typography variant="h5" fontWeight={600} component="span" mb={9} textAlign="center">
        You are about to unsubscribe from Epiphan Connect
      </Typography>

      <Typography fontSize={18} fontWeight={600}>
        Recurring monthly payments will be stopped.
      </Typography>

      <Typography fontSize={18} fontWeight={600} sx={{color: palette.blue}}>
        You will get the last invoice on {formatAbbreviatedDateWithYear(endDate)}.
      </Typography>

      <Typography color={palette.darkerGray} textAlign="center" mt={8} mb={5}>
        You can keep using Epiphan Connect until {formatAbbreviatedDateWithYear(endDate)}. <br />
        The meeting hours used by that date will be billed in the last invoice.
      </Typography>

      <Stack sx={{'& > button': {minWidth: 150}}} direction="row" alignItems="center" gap={2}>
        <ProgressButton
          dataId="accept-confirm-btn"
          variant={VARIANT.SOLID}
          theme={THEME.DANGER}
          onClick={onSubmit}
          autoFocus={true}
        >
          Stop Connect subscription
        </ProgressButton>

        <Button
          dataId="cancel-confirm-btn"
          theme={THEME.SECONDARY}
          variant={VARIANT.OUTLINE}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
