import {Callback} from 'app/types/common';
import {RefObject, useCallback, useLayoutEffect} from 'react';

export function useResizeObserver<R extends HTMLElement>(
  ref: RefObject<R>,
  callback: Callback<void, [ResizeObserverEntry]>,
) {
  const resizeHandler = useCallback(
    (entries: ResizeObserverEntry[]) => {
      const entry = entries[0];

      if (entry) {
        callback(entry);
      }
    },
    [callback],
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const Observer = new ResizeObserver(resizeHandler);

    Observer.observe(ref.current);

    return () => {
      Observer.disconnect();
    };
  }, [resizeHandler, ref]);
}
