import React, {useState} from 'react';
import {Dialog} from '@mui/material';
import {Prompt} from 'app/types/common';
import {Edge} from 'app/domain/edge';
import {MoveToGroup} from 'app/components/dialogs/MoveToGroupDialog/MoveToGroup';
import {useMounted} from 'app/hooks/useIsMounted';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

interface Props extends Prompt {
  devices: AnyDeviceModelType[];
  groups: Edge.Group[];
  showRemove?: boolean;
  onMove: (groupId: string) => Promise<void>;
  onRemove?: () => Promise<void>;
}

export function MoveToGroupDialog({
  open,
  devices,
  groups,
  showRemove,
  onMove,
  onRemove,
  onClose,
}: Props) {
  const [loading, setLoading] = useState(false);

  const mounted = useMounted();

  const handleMove = async (groupId: string) => {
    try {
      setLoading(true);
      await onMove(groupId);
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  const handleRemove = async () => {
    try {
      setLoading(true);
      await onRemove?.();
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="xs" onClose={onClose}>
      <MoveToGroup
        devices={devices}
        groups={groups}
        loading={loading}
        showRemove={showRemove}
        onMove={handleMove}
        onRemove={handleRemove}
        onCancel={onClose}
      />
    </Dialog>
  );
}
