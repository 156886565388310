import React, {forwardRef, Fragment, memo} from 'react';
import classNames from 'classnames';
import {DropdownSelectButton} from 'app/components/sharedReactComponents/DropdownSelect/DropdownSelectButton';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {Icons} from 'app/util/icons';

interface Props {
  count: number;
  offline: boolean;
  opened?: boolean;
  failed: boolean;
  started: boolean;
}

export const StreamingDestinationsSelectorButton: React.FC<Props> = memo(forwardRef(({
  count,
  offline,
  opened,
  failed,
  started,
}, ref) => {
  return (
    <DropdownSelectButton
      ref={ref}
      className={classNames('streaming-destinations-selector-button', {
        'streaming-destinations-selector-button--offline': offline,
        'streaming-destinations-selector-button--started': started,
      })}
      opened={opened}
      data-id="streaming_destinations_selector_button"
    >
      {count === 0 ? (
        <span className="streaming-destinations-selector-button__placeholder-text">
          Streaming destination
        </span>
      ) : (
        <Fragment>
          {failed && (
            <div className="streaming-destinations-selector-button__error-icon">
              {Icons.warning().reactComponent()}
            </div>
          )}

          <span className="streaming-destinations-selector-button__count">
            {count}
          </span>

          <span className="streaming-destinations-selector-button__selected-text">
            {pluralizeN('Streaming destination', count)}
          </span>
        </Fragment>
      )}
    </DropdownSelectButton>
  );
}));

StreamingDestinationsSelectorButton.displayName = 'StreamingDestinationsSelectorButton';
