import {BaseProvider} from 'app/util/OAuthProvider/BaseProvider';
import {OAuthProviderOptions} from 'app/util/OAuthProvider/types';

class TwitchProvider extends BaseProvider {
  constructor(options: OAuthProviderOptions) {
    super(options);

    this.name = 'Twitch';
    this.windowWidth = 520;
    this.windowHeight = 750;
  }
}

export {TwitchProvider};
