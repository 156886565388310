import {useQuery} from '@tanstack/react-query';
import {BillingApiService} from 'app/services/api/billing/BillingApiService';
import {BillingMapper} from 'app/services/mappers/BillingMapper';
import {stringComparator} from 'app/util/Sort';

interface Args {
  enabled: boolean;
  teamId: string;
}

export function useUnifyTiers({enabled, teamId}: Args) {
  return useQuery({
    queryKey: ['unify-tiers', teamId],
    enabled,
    queryFn: () => BillingApiService.getUnifyTiers(),
    select: mapTiers,
  });
}

function mapTiers(c: Contracts.Billing.Unify.Tier[]) {
  return c.map(BillingMapper.mapUnifyTier).sort((a, b) => stringComparator(b.id, a.id));
}
