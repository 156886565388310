import React from 'react';
import {Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';

interface Props extends Sx {
  text: string;
  rows?: number;
  dataId?: string;
}

export function EventTitle({sx, text, rows = 1, dataId}: Props) {
  return (
    <Typography sx={sx} component="span">
      <OverflowTooltip rowsNumber={rows} dataId={dataId}>
        {text}
      </OverflowTooltip>
    </Typography>
  );
}
