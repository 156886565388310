import React from 'react';
import {Box} from '@mui/material';
import {Sx, SxObj} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';

const root: SxObj = {
  display: 'inline-block',
  fontSize: '12px',
  color: '#979797',

  a: {
    color: '#979797',
    textDecoration: 'underline',
  },
};

export function MoreDetails({sx}: Sx) {
  return (
    <Box sx={packSx(root, sx)}>
      <b>Need more info?</b> Please check our{' '}
      <a
        data-id="video-center-link"
        href="https://fast.wistia.com/embed/channel/vbcnielsxo?wchannelid=vbcnielsxo"
        target="_blank"
        rel="noreferrer"
      >
        video resource center
      </a>{' '}
      or our{' '}
      <a
        data-id="user-guide-link"
        href="https://www.epiphan.com/userguides/connect/Content/Home-Connect.htm"
        target="_blank"
        rel="noreferrer"
      >
        user guide
      </a>
    </Box>
  );
}
