import {Files, FilesContracts} from '../model';

export function mapRecording(c: FilesContracts.Recording): Files.Recording {
  const channelId = c.channel_id ? `${c.device_id}-${c.channel_id}` : undefined;

  const {urls} = c;

  return {
    id: c.id,
    name: c.display_filename,
    deviceId: c.device_id,
    startAt: c.start_ts,
    endAt: c.end_ts,
    duration: c.end_ts - c.start_ts,
    size: c.size,
    uploaded: c.uploaded,
    channelId,
    downloadUrl: urls?.download,
    thumbnailUrl: urls?.thumbnail,
    filmstripUrl: urls?.filmstrip,
  };
}
