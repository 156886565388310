import React from 'react';
import {useClipboard} from 'app/components/shared';

interface Props {
  value: string;
  timeout?: number;
  children: (payload: {copied: boolean; copy: () => void}) => React.ReactNode;
}

export function CopyButton({value, timeout, children}: Props) {
  const clipboard = useClipboard({timeout});
  const copy = () => clipboard.copy(value);

  return <>{children({copy, copied: clipboard.copied})}</>;
}
