import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {Stack} from '@mui/material';
import {PageHeader} from 'app/components/sharedReactComponents/PageHeader';
import {router} from 'app/router/main';
import {PairDeviceButton} from 'app/components/libs/buttons/PairDeviceButton';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {DevicesAlertsCounter} from 'app/components/DevicesAlerts/DevicesAlertsCounter';
import {useCurrentTeamStore} from 'app/store/hooks';
import {isNil} from 'app/util/isNil';
import {useDevice} from 'app/components/DeviceDetails/hooks/useDevice';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {DeviceDetails} from 'app/components/DeviceDetails/DeviceDetails/DeviceDetails';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {DetailsTab} from 'app/components/DeviceDetails/DeviceInfoTab/types';
import {useDetailsTabs} from 'app/components/DeviceDetails/hooks/useDetailsTabs';
import {useFeature} from 'app/util/ApplicationFeatures';
import {useEdgeGroups} from 'app/components/features/edge';

interface Props {
  deviceId: string;
  activeTab?: DetailsTab | (string & {});
}

export const DeviceDetailsPage = observer(({deviceId, activeTab = 'status'}: Props) => {
  const team = useCurrentTeamStore();
  const filesFeature = useFeature('files');

  const teamId = team.id;

  const {loading, master, channel, channelIdx} = useDevice({deviceId});

  const {tab, tabs} = useDetailsTabs({device: master, urlTab: activeTab, filesFeature});

  const groupsAccess = master?.capabilities.groups ?? false;
  const transcribeAccess = master?.capabilities.transcribing ?? false;

  const {groups} = useEdgeGroups({teamId, enabled: groupsAccess});

  useEffect(() => {
    if (!master || !tabs.length) {
      return;
    }

    const allowedTabs = new Set<string>(tabs.map((t) => t));

    if (!allowedTabs.has(tab)) {
      router.go(undefined, {deviceId: master.getId()}, {activeTab: 'status'});
      return;
    }

    if (channelIdx) {
      const supportChannels = master instanceof PearlMasterDeviceModel;
      const channel = supportChannels ? master.getChannelByIndex(channelIdx) : undefined;

      if (!supportChannels || !channel) {
        router.go(undefined, {deviceId: master.getId()}, {activeTab: tab});
        return;
      }

      if (tab !== 'channels') {
        router.go(undefined, {deviceId: channel.getId()}, {activeTab: 'channels'});
      }
    }
  }, [channelIdx, tab, tabs, master]);

  if (!loading && isNil(master)) {
    router.go('devices');
  }

  const renderPairingButton = () => {
    if (!master) {
      return null;
    }

    return ModelService.isUnify(master.getModelName()) ? null : <PairDeviceButton />;
  };

  return (
    <Stack flex={1}>
      <PageHeader
        buttons={
          <>
            {renderPairingButton()}
            <DevicesAlertsCounter />
          </>
        }
      />

      {master && !loading ? (
        <DeviceDetails
          sx={{flex: 1}}
          teamId={teamId}
          device={master}
          groups={groups}
          channel={channel}
          tab={activeTab}
          tabs={tabs}
          canTranscribe={transcribeAccess}
        />
      ) : (
        <IndeterminateProgressBar />
      )}
    </Stack>
  );
});
