import React, {useMemo} from 'react';
import {Box, Stack} from '@mui/material';
import {grey} from '@mui/material/colors';
import {DayTimeline} from 'app/components/sharedReactComponents/Events/Calendar/DayTimeline/DayTimeline';
import {Week} from 'app/components/sharedReactComponents/Events/Calendar/Week/Week';
import {EventSchedule} from 'app/components/sharedReactComponents/Events/Calendar/EventsSchedule/EventSchedule';
import {getDailySummary} from 'app/components/sharedReactComponents/Events/Calendar/utils';
import {StyleSx, Sx} from 'app/types/common';
import {Schedule} from 'app/domain/schedule';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {useCalendarLayout} from 'app/components/sharedReactComponents/Events/hooks/useScheduleLayout';
import {HoursToView} from 'app/components/sharedReactComponents/Events/Calendar/HoursToView/HoursToView';

const hoursSx: StyleSx = {mb: 2};
const timelineSx: StyleSx = {mt: 10};

type Props = Sx & {
  period: Schedule.Period;
  events: Schedule.Event[];
  devices?: Map<string, PearlMasterDeviceModel>;
};

export function Calendar({sx, period, events, devices}: Props) {
  const {days, range, selected, displayed, setRange, setSelected} = useCalendarLayout(period);

  const {map, statistic} = useCalendar({days, events});

  return (
    <Box sx={sx} bgcolor={grey[50]} borderRadius={1} mb={3} py={2} px={2}>
      <HoursToView sx={hoursSx} range={range} onChange={setRange} />

      <Stack direction="row" bgcolor="background.paper" borderRadius={1}>
        <DayTimeline sx={timelineSx} range={range} />

        <Box flexGrow={1} borderLeft={1} borderColor="divider">
          <Week stats={statistic} selected={selected} onSelect={setSelected} />

          <EventSchedule
            schedule={map}
            devices={devices}
            period={period}
            displayed={displayed}
            selected={selected}
            range={range}
          />
        </Box>
      </Stack>
    </Box>
  );
}

interface Args {
  days: TimeStampSeconds[];
  events: Schedule.Event[];
}

type Return = {
  map: Map<TimeStampSeconds, Schedule.Event[]>;
  statistic: Map<TimeStampSeconds, number>;
};

function useCalendar({days, events}: Args): Return {
  return useMemo(() => {
    const map = getDailySummary(days, events);
    const statistic = new Map(Array.from(map.entries()).map(([key, list]) => [key, list.length]));
    return {map, statistic};
  }, [days, events]);
}
