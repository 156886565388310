import React from 'react';
import {Callback, Component} from 'app/types/common';
import {TierCard} from 'app/components/BillingManager/dialogs/CloudTiersDialog/TierCard/TierCard';
import {Box} from '@mui/material';
import {palette} from 'app/themes/app';
import {Icons} from 'app/util/icons';
import {THEME, VARIANT} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';
import {CurrentTierMark} from 'app/components/sharedReactComponents/CurrentTierMark/CurrentTierMark';

interface Props extends Component {
  current: boolean;
  tier: App.Billing.Cloud.Tier;
  interval: string;
  onCancelPremium: Callback;
}

export function ArchiveTier({current, tier, interval, onCancelPremium}: Props) {
  return (
    <TierCard current={current} sx={{minWidth: 250, position: 'relative', overflow: 'hidden'}}>
      <Box
        data-id="archived-badge"
        sx={{
          position: 'absolute',
          fontWeight: 'bold',
          fontSize: '14px',
          right: '-30px',
          padding: '4px 30px',
          backgroundColor: palette.obscure,
          color: 'white',
          transform: 'rotate(45deg)',
        }}
      >
        Archived
      </Box>
      <TierCard.Title>{tier.name}</TierCard.Title>

      <TierCard.Price value={tier.rate} interval={interval} />

      <TierCard.Description>
        {Icons.deviceCircuit().theme(THEME.SECONDARY).label(tier.description).reactComponent()}
      </TierCard.Description>

      <TierCard.Actions>
        {current ? (
          <CurrentTierMark />
        ) : (
          <Button
            dataId="cancel_premium_button"
            theme={THEME.INFO}
            variant={VARIANT.OUTLINE}
            onClick={onCancelPremium}
          >
            Cancel premium
          </Button>
        )}
      </TierCard.Actions>
    </TierCard>
  );
}
