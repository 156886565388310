import React from 'react';
import {MenuItem, Select} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import {Sx} from 'app/types/common';
import {SortType, sortOptions} from 'app/components/entities/files/model/types';

interface Props extends Sx {
  sort: SortType;
  setSort: (sort: SortType) => void;
}

export function SortSelector({sx, sort, setSort}: Props) {
  return (
    <Select
      data-id="sort-select"
      sx={sx}
      variant="outlined"
      color="info"
      startAdornment={<SortIcon />}
      value={sort}
    >
      {sortOptions.map((option) => (
        <MenuItem
          data-id={`sort-option-${option.value}`}
          key={option.value}
          value={option.value}
          onClick={() => setSort(option.value)}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}
