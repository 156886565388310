import React from 'react';
import {Component, Sx} from 'app/types/common';
import {BillingApiService} from 'app/services/api/billing/BillingApiService';
import {Box, Link} from '@mui/material';

interface Props extends Sx, Component {
  id: string;
  teamId: string;
  number: string;
  available: boolean;
}

export function InvoiceNumber({id, dataId, sx, available, number, teamId}: Props) {
  if (available) {
    return (
      <Link
        data-id={dataId}
        sx={sx}
        href={BillingApiService.getInvoicePdfUrl(teamId, id)}
        target="_blank"
      >
        {number}
      </Link>
    );
  }

  return (
    <Box data-id={dataId} sx={sx} component="span">
      {number}
    </Box>
  );
}
