import React, {useCallback} from 'react';
import {observer} from 'mobx-react';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {Icons} from 'app/util/icons';
import {router} from 'app/router/main';
import {useCurrentTeamStore} from 'app/store/hooks';
import {useCloudEntities} from 'app/components/entities/cloud';

export const AddPaymentMethodMessage: React.VFC = observer(() => {
  const {user} = useCloudEntities();
  const currentTeamStore = useCurrentTeamStore();

  const handleClick = useCallback(() => {
    router.go('settingBillingUsage');
  }, []);

  //! todo: should pe passed as props
  if (!user.role.canEditBilling() || currentTeamStore.getCapabilities().transcribing()) {
    return null;
  }

  return (
    <div className="add-payment-method-message">
      <Button
        className="add-payment-method-message__button"
        theme={THEME.DANGER}
        variant={VARIANT.TEXT}
        onClick={handleClick}
      >
        {Icons.addPaymentMethod()
          .label('Add a payment method')
          .class('add-payment-method-message__icon')
          .reactComponent()}
      </Button>

      {' to enable transcription'}
    </div>
  );
});
