import React from 'react';
import classNames from 'classnames';
import {formatStartedAtDate} from 'app/util/time';
import {THEME} from 'app/constants';
import {ClassName, DataId} from 'app/types/common';

interface Props extends ClassName, DataId {
  timestamp: number;
  theme?: THEME;
}

export const StartedAtNote: React.VFC<Props> = ({className, timestamp, theme, dataId}) => {
  return (
    <span
      className={classNames(
        'started-at-note',
        {
          [`started-at-note--theme-${theme}`]: Boolean(theme),
        },
        className,
      )}
      data-id={dataId}
    >
      Started at {formatStartedAtDate(timestamp)}
    </span>
  );
};
