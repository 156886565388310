import {useCallback, useEffect, useRef} from 'react';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';

const emptyMap = new Map<string, AnyDeviceModelType>();

export function useGetDeviceById(devices: AnyDeviceModelType | AnyDeviceModelType[]) {
  const devicesMap = useRef(emptyMap);

  useEffect(() => {
    devicesMap.current = new Map<string, AnyDeviceModelType>();

    function process(device: AnyDeviceModelType) {
      devicesMap.current.set(device.getId(), device);

      if (ModelService.isMultiChannel(device.getModelName())) {
        (device as PearlMasterDeviceModel).getChanelModels().forEach((channelDevice) => {
          devicesMap.current.set(channelDevice.getId(), channelDevice);
        });
      }
    }

    if (Array.isArray(devices)) {
      devices.forEach(process);
    } else if (devices) {
      process(devices);
    }
  }, [devices]);

  const getDeviceById = useCallback((deviceId: string) => {
    return devicesMap.current.get(deviceId);
  }, []);

  return getDeviceById;
}
