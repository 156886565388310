import React from 'react';
import {Box, Divider, Stack, Typography} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {MediaAction} from 'app/components/pages/MeetingDetails/hooks/usePermissionIssue';
import {Sx} from 'app/types/common';
import {VARIANT} from 'app/constants';
import {palette} from 'app/themes/app';
import {Button} from 'app/components/sharedReactComponents/Button';
import {useToggle} from 'app/hooks/useToggle';
import {PermissionNote} from 'app/components/pages/MeetingDetails/MeetingLayout/PermissionNote';
import {LocalRecordGuide} from 'app/components/pages/MeetingDetails/MeetingLayout/LocalRecordGuide/LocalRecordGuide';

interface Props extends Sx {
  id?: string;
  action: MediaAction;
}

export function MediaActionIssue({sx, id, action}: Props) {
  const [expanded, toggle] = useToggle(false);

  return (
    <Box sx={sx}>
      <Stack sx={{mb: 1}} direction="row" alignItems="center" gap={1}>
        <ErrorIcon sx={{color: palette.orange3}} />

        <Typography sx={{fontSize: '20px'}}>
          Meeting host needs to approve the {action} permission
        </Typography>
      </Stack>

      <Box sx={{fontSize: '16px', color: palette.gray2, mb: 1.5}}>
        Connect has joined the Zoom meeting but it needs {action} permission from the meeting host
        to start. <br />
        Please ask the Zoom meeting host to approve the pop-up request in the Zoom application. The
        meeting host should be running the latest version of Zoom.
      </Box>

      {id && (
        <Typography mb={3} fontSize={12} color="#a1a7af">
          Meeting ID {id}
        </Typography>
      )}

      <Box sx={{mb: 3}}>
        <img
          src="/assets/img/connect/permission-request.png"
          srcSet="assets/img/connect/permission-request@2x.png 2x"
          alt="zoom recoding modal"
          width={390}
          height={178}
        />
      </Box>

      {action === 'recording' && <PermissionNote sx={{mb: 3}} />}

      <Divider sx={{mb: 3}} />

      <Box sx={{mb: 0.5, '& button': {p: 0, boxShadow: 'none', color: palette.darkerGray}}}>
        <Button variant={VARIANT.TEXT} onClick={toggle}>
          <Typography fontWeight={600} mr={1}>
            If the meeting host hasn't received a pop-up, try a different permission request
          </Typography>

          <KeyboardArrowDownIcon sx={expanded ? {transform: 'rotate(180deg)'} : {}} />
        </Button>
      </Box>

      {expanded && (
        <Box sx={{mt: 4}}>
          <LocalRecordGuide variant="media" />
        </Box>
      )}
    </Box>
  );
}
