import {BaseStreamingService} from 'app/components/StreamingServices/BaseStreamingService';
import {OAuth2StreamingDestinationModel} from 'app/components/StreamingServices/StreamingDestinations/OAuth2StreamingDestinationModel';
import {TwitchProvider} from 'app/util/OAuthProvider/TwitchProvider';
import {front} from 'app/api/CirrusApi';
import {STREAMING_SERVICE_TYPE} from 'app/components/StreamingServices/constants';
import {noop} from 'app/util/noop';
import {StreamingDestinationOptions} from 'app/components/StreamingServices/StreamingDestinations/types';

class TwitchStreamingService extends BaseStreamingService {
  protected type = STREAMING_SERVICE_TYPE.TWITCH;
  protected name = 'Twitch';

  async createService(name: string): Promise<string> {
    // TODO: Update handlers
    return new Promise((resolve, reject) => {
      new TwitchProvider({
        url: front.connections().connect().twitch().stream(name).url(),
        onOpen: noop,
        onClose: (response) => {
          if (response.error || response.loginCallbackIsNotFired) {
            console.log('Error', response);
            reject(response.error);
            return;
          }

          resolve(name);
        },
      }).open();
    });
  }

  createStreamingDestinationModel(json: StreamingDestinationOptions) {
    return new OAuth2StreamingDestinationModel(json);
  }

  isDisabled() {
    return true;
  }

  isAvailableToCreate(): boolean {
    return false;
  }
}

export {TwitchStreamingService};
