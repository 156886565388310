import React from 'react';
import classNames from 'classnames';
import {ClassName, DataId} from 'app/types/common';

interface Props extends ClassName, DataId {
  type: App.Connect.MemberType;
  active?: boolean;
}

export const ParticipantMessage = ({className, type, active, dataId}: Props) => {
  if (type === 'participant') {
    return null;
  }

  if (type === 'primary-speaker') {
    return (
      <div className={classNames('participant-message', className)} data-id={dataId}>
        Available stream
      </div>
    );
  }

  if (type === 'return-feed') {
    return (
      <div
        className={classNames(
          'participant-message participant-message__share--inactive',
          className,
        )}
        data-id={dataId}
      >
        When started, this stream will appear in the meeting on behalf of Epiphan Connect.
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'participant-message',
        'participant-message__share',
        !active && 'participant-message__share--inactive',
        className,
      )}
      data-id={dataId}
    >
      {active ? 'One participant is sharing the screen' : 'Nobody is sharing the screen'}
    </div>
  );
};
