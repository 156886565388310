import React, {useCallback, useEffect, useState} from 'react';
import {TranscribingStartStopButton} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton/TranscribingStartStopButton';
import {DEVICE_START_STOP_BUTTON_STATUS} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton/constants';
import {useDeviceTranscriptionTime} from 'app/components/sharedReactComponents/TranscribeDuration/useDeviceTranscriptionTime';
import {TranscribingStatus} from 'app/components/DeviceDetails/constants';

function getButtonStatusByTranscribeStatus(transcribeStatus: TranscribingStatus): DEVICE_START_STOP_BUTTON_STATUS {
  switch (transcribeStatus) {
    case TranscribingStatus.Started:
      return DEVICE_START_STOP_BUTTON_STATUS.STARTED;
    case TranscribingStatus.Paused:
      return DEVICE_START_STOP_BUTTON_STATUS.PAUSED;
    case TranscribingStatus.Stopped:
    default:
      return DEVICE_START_STOP_BUTTON_STATUS.STOPPED;
  }
}

interface Props {
  device: any;
}

export const TranscribingButton: React.FC<Props> = ({
  device,
}) => {
  const [buttonStatus, setButtonStatus] = useState(DEVICE_START_STOP_BUTTON_STATUS.STOPPED);

  useEffect(() => {
    const buttonStatus = getButtonStatusByTranscribeStatus(device.getTranscribingStatus());
    setButtonStatus(buttonStatus);
  }, [device]);

  const transcribingInfo = device.getTranscribingInfo();

  const {
    startTime,
    receivedTime,
  } = useDeviceTranscriptionTime({
    deviceId: device.getId(),
    sessionId: transcribingInfo.sessionId,
    status: transcribingInfo.status,
  });

  const startStopAction = useCallback(() => {
    const status = device.getTranscribingStatus();

    if (status === TranscribingStatus.Started) {
      return device.stopTranscribing();
    }

    setButtonStatus(DEVICE_START_STOP_BUTTON_STATUS.LOADING);

    if (status === TranscribingStatus.Paused) {
      return device.resumeTranscribing();
    }

    return device.startTranscribing();
  }, [device]);

  return (
    <TranscribingStartStopButton
      status={buttonStatus}
      startTime={startTime}
      receivedTime={receivedTime}
      startAction={startStopAction}
      resumeAction={startStopAction}
      stopAction={startStopAction}
    />
  );
};
