import React from 'react';
import {Sx} from 'app/types/common';
import {Box, Link, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {LegacyIcon} from 'app/components/sharedReactComponents/LegacyIcon/LegacyIcon';
import {THEME, VARIANT} from 'app/constants';
import {Icons} from 'app/util/icons';
import {Button} from 'app/components/sharedReactComponents/Button';

interface Props extends Sx {
  canManage: boolean;
  onPair: Fn;
}

export function PairTeamsTenant({sx, canManage, onPair}: Props) {
  return (
    <Stack
      sx={sx}
      direction="row"
      alignItems="flex-start"
      gap={2}
      p={4}
      borderRadius={1}
      border={1}
      bgcolor={grey[100]}
      borderColor={grey[400]}
    >
      <LegacyIcon fontSize={72}>{Icons.microsoftTeams().reactComponent()}</LegacyIcon>
      <Box>
        <Typography mb={2} fontWeight="bold" fontSize={20}>
          Pair your Microsoft Teams organization
        </Typography>

        <Typography mb={1}>
          To integrate Epiphan Connect with Microsoft Teams you need to get permissions from
          Microsoft Teams organization.
          <br />
          You must also have administrator access to your Microsoft Teams organization.
        </Typography>

        <Typography>
          <Link
            href="https://www.epiphan.com/userguides/connect/Content/Connect/Authorize-MS-Teams-to-use-Connect.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </Link>{' '}
          how to pair Microsoft Teams organization to Epiphan Connect.
        </Typography>

        {canManage && (
          <Box sx={{mt: 2, '& button ': {width: '100px'}}}>
            <Button
              dataId="connect_account_button"
              theme={THEME.INFO}
              variant={VARIANT.SOLID}
              onClick={onPair}
            >
              Pair
            </Button>
          </Box>
        )}
      </Box>
    </Stack>
  );
}
