import React from 'react';
import {LegalInformation} from 'app/components/LegalInformation';
import {UserApiService} from 'app/services/api/user/UserApiService';

async function getAgreement(): Promise<string> {
  const result = await UserApiService.getAgreement();
  return result.Agreement;
}

export function Agreement() {
  return <LegalInformation title="Epiphan Terms of Service" getContent={getAgreement} />;
}
