import React, {useState} from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';

interface Props extends Prompt {
  teamName: string;
  userName: string;
  hasPayment: boolean;
  onTransfer: () => Promise<void>;
}

export function TransferOwnershipDialog({
  open,
  teamName,
  userName,
  hasPayment,
  onTransfer,
  onClose,
}: Props) {
  const [loading, setLoading] = useState(false);

  const handleTransfer = async () => {
    try {
      setLoading(true);
      await onTransfer();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        Transfer ownership of{' '}
        <Typography component="span" variant="inherit" color="primary.main">
          {teamName}
        </Typography>
        <br />
        to{' '}
        <Typography component="span" variant="inherit" color="primary.main">
          {userName}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {hasPayment && (
          <Stack alignItems="center">
            <FormControlLabel
              control={<Checkbox checked={true} disabled={true} />}
              label="Transfer billing information"
            />
          </Stack>
        )}

        <Typography color="text.secondary" textAlign="center">
          If ownership transfer is accepted, your role will change to <strong>Administrator</strong>
        </Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          variant="contained"
          color="secondary"
          loading={loading}
          autoFocus={true}
          onClick={handleTransfer}
        >
          Send transfer request
        </LoadingButton>

        <Button variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
