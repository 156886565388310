import React from 'react';
import {AUTH_STATE} from 'app/components/Login/LoginForm/constants';
import {PseudoLink} from 'app/components/sharedReactComponents/PseudoLink';
import {Callback} from 'app/types/common';

interface Props {
  authState: AUTH_STATE;
  authData: any;
  switchStateToSignUp: Callback;
  switchStateToSignIn: Callback;
}

const FooterMessage: React.FC<Props> = ({
  authState,
  authData,
  switchStateToSignUp,
  switchStateToSignIn,
}) => {
  const {
    username,
  } = authData;

  switch (authState) {
    case AUTH_STATE.SIGN_IN:
      return (
        <div>
          <span>No account? </span>
          <PseudoLink
            data-id="switch_to_sign_up"
            onClick={() => switchStateToSignUp()}
          >
            Sign up for FREE
          </PseudoLink>
        </div>
      );
    case AUTH_STATE.SIGN_UP:
      return (
        <div>
          <span>Already have an account? </span>
          <PseudoLink
            data-id="switch_to_sign_in"
            onClick={() => switchStateToSignIn()}
          >
            Log in
          </PseudoLink>
        </div>
      );
    case AUTH_STATE.CONFIRM_SIGN_UP:
    case AUTH_STATE.FORGOT_PASSWORD:
    case AUTH_STATE.RESET_PASSWORD:
      return (
        <div>
          <PseudoLink
            data-id="switch_to_sign_in"
            onClick={() => switchStateToSignIn(username)}
          >
            Back to Sign In
          </PseudoLink>
        </div>
      );
    default:
      return null;
  }
};

export const Footer = (props: Props) => (
  <div className="login-form__footer">
    <FooterMessage {...props}/>
  </div>
);
