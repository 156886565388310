import React, {useState} from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';
import {Edge} from 'app/domain/edge';

interface Props extends Prompt {
  preset: Edge.Preset;
  deviceName: string;
  onApply: () => Promise<any>;
}

export function ApplyPresetDialog({open, preset, deviceName, onApply, onClose}: Props) {
  const [loading, setLoading] = useState(false);

  const hasNetwork = preset.sections.some((s) => s === 'network');
  const needReboot = hasNetwork || preset.sections.some((s) => s === 'system');

  const handleApply = async () => {
    try {
      setLoading(true);
      await onApply();
    } finally {
      setLoading(false);
    }
  };

  const hasContent = needReboot || hasNetwork;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Apply{' '}
        <Typography color="primary.main" variant="inherit" component="span">
          {preset.name}
        </Typography>{' '}
        to{' '}
        <Typography color="primary.main" variant="inherit" component="span">
          {deviceName}
        </Typography>
      </DialogTitle>

      {hasContent && (
        <DialogContent sx={{textAlign: 'center'}}>
          {needReboot && (
            <Box>Device will be rebooted and temporarily go offline while applying the preset.</Box>
          )}
          {hasNetwork && (
            <Typography color="warning.main" mt={2}>
              This preset includes Network settings, make sure these settings are correct.
              <br />
              If the settings are incorrect, device may remain offline after the reboot.
            </Typography>
          )}
        </DialogContent>
      )}

      <DialogActions>
        <LoadingButton
          data-id="confirm-apply-btn"
          variant="contained"
          color="secondary"
          loading={loading}
          disableRipple={false}
          onClick={handleApply}
        >
          Apply
        </LoadingButton>

        <Button
          data-id="cancel-apply-btn"
          color="info"
          variant="outlined"
          disableRipple={false}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
