interface Event {
  stopPropagation: () => void;
}

function stopPropagation(event: Event) {
  event.stopPropagation();
}

export {
  stopPropagation,
};
