import React, {Fragment} from 'react';
import {DeletePrompt} from 'app/components/sharedReactComponents/Modal/DeletePrompt';
import {ItemsEnumeration} from 'app/components/sharedReactComponents/ItemsEnumeration';
import {THEME} from 'app/constants';
import {Callback} from 'app/types/common';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';

export const DeleteStreamingDestinationPrompt = {
  show({
    streamingDestination,
    onDone,
  }: {
    streamingDestination: AnyStreamingDestinationModelType;
    onDone: Callback;
  }) {
    DeletePrompt.show({
      title: (
        <Fragment>
          {'Are you sure you want to delete '}

          <ItemsEnumeration
            items={[streamingDestination]}
            theme={THEME.INFO}
          />

          {' streaming destination?'}
        </Fragment>
      ),
      action: async () => {
        return streamingDestination.deleteAction()
          .then(() => onDone());
      },
    });
  }
};
