import React, {Fragment} from 'react';
import {TranscribingStatus} from 'app/components/DeviceDetails/constants';
import {Duration} from 'app/components/sharedReactComponents/DurationTimer/Duration';
import {DurationTimer} from 'app/components/sharedReactComponents/DurationTimer';
import {useDeviceTranscriptionTime} from 'app/components/sharedReactComponents/TranscribeDuration/useDeviceTranscriptionTime';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  deviceId: string;
  sessionId: string;
  status: TranscribingStatus;
}

const TranscribeDurationStarted: React.FC<Props> = ({
  className,
  deviceId,
  sessionId,
  status,
}) => {
  const {
    startTime,
    receivedTime,
  } = useDeviceTranscriptionTime({
    deviceId,
    sessionId,
    status,
  });

  return (
    <DurationTimer
      className={className}
      data-id="transcribe_duration_started"
      startTime={startTime}
      receivedTime={receivedTime}
      paused={status === TranscribingStatus.Paused}
    />
  );
};

export const TranscribeDuration: React.FC<Props> = ({
  className,
  deviceId,
  sessionId,
  status,
}) => {
  return (
    <Fragment>
      {status === TranscribingStatus.Stopped ? (
        <Duration
          className={className}
          data-id="transcribe_duration_stopped"
          value={0}
        />
      ) : (
        <TranscribeDurationStarted
          className={className}
          deviceId={deviceId}
          sessionId={sessionId}
          status={status}
        />
      )}
    </Fragment>
  );
};
