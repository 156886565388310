import React, {useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {NamesList} from 'app/components/sharedReactComponents/ItemsEnumeration';
import {useMounted} from 'app/hooks/useIsMounted';
import {Prompt} from 'app/types/common';

type Props = {
  names: string[];
  loading: boolean;
  onDelete: () => Promise<void>;
  onClose: () => void;
};

function DeleteGroups({names, loading, onDelete, onClose}: Props) {
  return (
    <Box>
      <DialogTitle>
        Delete <NamesList max={3} variant="inherit" color="primary.main" names={names} />{' '}
        {pluralizeN('group', names.length)}
      </DialogTitle>

      <DialogContent>
        <Typography>
          Devices currently assigned to this group will be moved out of the group
        </Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="confirm-delete-btn"
          variant="contained"
          color="error"
          loading={loading}
          disableRipple={false}
          onClick={onDelete}
        >
          Delete
        </LoadingButton>

        <Button
          data-id="cancel-delete-btn"
          variant="outlined"
          color="info"
          disableRipple={false}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
}

type PromptProps = Prompt & Omit<Props, 'loading'>;

export function DeleteGroupsDialog({open, names, onDelete, onClose}: PromptProps) {
  const mounted = useMounted();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await onDelete();
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DeleteGroups loading={loading} names={names} onDelete={handleDelete} onClose={onClose} />
    </Dialog>
  );
}
