import React, {useRef} from 'react';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {useSettingChange} from 'app/components/sharedReactComponents/SettingsList/hooks';
import {CopyButton} from 'app/components/sharedReactComponents/CopyButton';
import {QRCode} from 'app/components/sharedReactComponents/QRCode';
import {Url} from 'app/api/util/Url';
import {Callback} from 'app/types/common';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';

interface Props {
  name: string;
  label: string;
  value: string;
  baseURL: string;
  disabled: boolean;
  updateAction: Callback;
}

export const StreamURLSettingsItem: React.VFC<Props> = ({
  name,
  value,
  baseURL,
  label,
  disabled,
  updateAction,
}) => {
  const [inputValue, loading, handleChange] = useSettingChange(name, value, updateAction);

  const qrCodeContainerRef = useRef(null);
  const qrCodeCanvasRef = useRef(null);

  if (!baseURL) {
    return <LoadingPlaceholder/>;
  }

  let resultValue = baseURL;

  if (inputValue) {
    resultValue = Url.join(resultValue, encodeURIComponent(inputValue));
  }

  // Add protocol to base URL
  if (baseURL.includes('//') === false) {
    resultValue = `https://${resultValue}`;
  }

  let startAdornment = baseURL;
  // Add last slash to base URL
  if (!startAdornment.endsWith('/')) {
    startAdornment += '/';
  }

  return (
    <div className="stream-url-setting">
      <div className="stream-url-setting__title">
        {label}
      </div>

      <div className="stream-url-setting__content">
        <FormControl
          name="stream-url"
          data-id="stream_url"
          value={inputValue}
          startAdornment={startAdornment}
          immediateChange={false}
          loading={loading}
          disabled={disabled}
          onChange={handleChange}
        />

        <CopyButton
          className="stream-url-setting__copy-button"
          data-id="copy_stream_url_button"
          value={resultValue}
        />
      </div>

      <div
        ref={qrCodeContainerRef}
        className="stream-url-setting__qr-code"
      >
        <QRCode
          data-id="stream_url_qr_code"
          canvasRef={qrCodeCanvasRef}
          value={resultValue}
        />

        <CopyButton
          className="stream-url-setting__copy-button"
          data-id="copy_qr_code_button"
          valueCanvas={qrCodeCanvasRef}
        />
      </div>
    </div>
  );
};
