import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {EventsList} from 'app/components/sharedReactComponents/Events/List/EventsList/EventsList';
import {
  EventDayGroup,
  useScheduleDayGroups,
} from 'app/components/sharedReactComponents/Events/hooks/useScheduleDayGroups';
import {Schedule} from 'app/domain/schedule';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {useScheduleCategories} from 'app/components/sharedReactComponents/Events/hooks/useScheduleCategories';

type ListActions = Pick<
  React.ComponentProps<typeof EventsList>,
  'onStop' | 'onDelete' | 'onEdit' | 'onStart' | 'onExtend' | 'onResume' | 'onPause' | 'onConfirm'
>;

interface Props extends Sx, ListActions {
  events: Schedule.Event[];
  devices?: Map<string, PearlMasterDeviceModel>;
  actualEventIds?: Set<string>;
}

export function TeamEvents({sx, devices, events, actualEventIds, ...props}: Props) {
  const {ongoing, scheduled, completed} = useScheduleCategories(events);
  const groups = useScheduleDayGroups(scheduled, completed);

  const renderOngoing = (events: Schedule.Event[]) => (
    <Box mb={2}>
      <Typography fontSize={16} mb={1} fontWeight={600}>
        Ongoing
      </Typography>

      <EventsList devices={devices} events={events} showTitle={false} {...props} />
    </Box>
  );

  const renderScheduled = (scheduledGroups: EventDayGroup[]) => (
    <Box mb={2}>
      <Typography fontSize={16} mb={1} fontWeight={600}>
        Scheduled
      </Typography>

      <Stack gap={1}>
        {scheduledGroups.map((group) => {
          return (
            <EventsList
              key={group.title}
              showTitle={true}
              events={group.events}
              devices={devices}
              title={group.title}
              actualEventIds={actualEventIds}
              {...props}
            />
          );
        })}
      </Stack>
    </Box>
  );

  const renderCompleted = (completedGroups: EventDayGroup[]) => {
    return (
      <Box>
        <Typography fontSize={16} mb={1} fontWeight={600}>
          Completed
        </Typography>

        <Stack gap={1}>
          {completedGroups.map((group) => (
            <EventsList
              key={group.title}
              events={group.events}
              devices={devices}
              showTitle={true}
              title={group.title}
              {...props}
            />
          ))}
        </Stack>
      </Box>
    );
  };

  const hasOngoing = ongoing.length > 0;
  const hasScheduled = groups.scheduled.length > 0;
  const hasCompleted = groups.completed.length > 0;

  return (
    <Box sx={sx}>
      {hasOngoing && renderOngoing(ongoing)}
      {hasScheduled && renderScheduled(groups.scheduled)}
      {hasCompleted && renderCompleted(groups.completed)}
    </Box>
  );
}
