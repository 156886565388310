const stringComparator = (a?: string, b?: string): number => {
  if (typeof a === 'string') {
    if (typeof b === 'string') {
      return a.localeCompare(b, undefined, {
        sensitivity: 'base',
        numeric: true,
      });
    }

    return -1;
  }

  if (typeof b === 'string') {
    return 1;
  }

  return 0;
};

const negativeStringComparator = (a?: string, b?: string): number => stringComparator(b, a);

type Data = Record<string, any>;
type PropGetter = (a: Data) => string;

const createStringComparator = (propName: string | PropGetter): ((a: Data, b: Data) => number) => {
  const propGetter: PropGetter = typeof propName === 'function' ? propName : item => item[propName];
  return (a, b) => stringComparator(propGetter(a), propGetter(b));
};

const curriedSortBy = (propName: string | PropGetter) => (list: any[]) => {
  const comparator = createStringComparator(propName);
  return list.sort(comparator);
};

export {
  stringComparator,
  negativeStringComparator,
  createStringComparator,
  curriedSortBy,
};
