import React from 'react';
import classNames from 'classnames';
import {Component} from 'app/types/common';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';

interface Props extends Component {}

export const EncryptionMessage = ({className, dataId}: Props) => {
  return (
    <div data-id={dataId} className={classNames('encryption-message', className)}>
      <div className="encryption-message__title">
        {Icons.lock().size(SIZE.S).reactComponent()}{' '}
        <span className="stream-settings__encryption-title">Secure connection</span>
      </div>

      <div>This connection will be automatically encrypted using AES-128 key.</div>
      <a
        className="encryption-message__link"
        href="https://www.epiphan.com/download/TE-Security-and-data-privacy-in-Epiphan-Connect_01.pdf"
        target="_blank"
        rel="noopener noreferrer"
        data-id="policy_link"
      >
        Security and data privacy policy
      </a>
    </div>
  );
};
