import React, {Component} from 'react';
import classNames from 'classnames';
import {TokensPanelListItem} from 'app/components/Settings/ApiTokens/TokensPanel/TokensPanelList/TokensPanelListItem';
import {Callback, ClassName} from 'app/types/common';
import {Token} from 'app/components/Settings/ApiTokens/TokensPanel/types';

interface Props extends ClassName {
  tokens: Token[];
  endpoint: Callback;
  isUniqueTokenName: Callback;
  onUpdate: Callback;
  onDelete: Callback;
}

export class TokensPanelList extends Component<Props> {
  render() {
    const {
      className,
      tokens,
      endpoint,
      isUniqueTokenName,
      onUpdate,
      onDelete,
    } = this.props;

    if (tokens.length === 0) {
      return null;
    }

    return (
      <div className={classNames('tokens-panel-list', className)}>
        <div className="tokens-panel-list__title">
          Your API tokens
        </div>

        <div className="tokens-panel-list__list">
          {tokens.map(token => (
            <TokensPanelListItem
              key={token.ID}
              className="tokens-panel-list__list-item"
              token={token}
              endpoint={endpoint}
              isUniqueTokenName={isUniqueTokenName}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />
          ))}
        </div>
      </div>
    );
  }
}
