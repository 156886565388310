import React from 'react';
import {Card, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {UnifyConnection} from 'app/components/sharedReactComponents/UnifyConnection/UnifyConnection';

interface Props extends Sx {
  deviceName: string;
  status: string;
  progress: number;
}

export function UnifyStarting({sx, deviceName, progress, status}: Props) {
  return (
    <Card sx={packSx({py: 5, px: 4}, sx)} variant="elevation" elevation={3}>
      <UnifyConnection sx={{width: 360, mb: 3}} dataId="unify_status_progress" value={progress} />

      <Typography sx={{wordBreak: 'break-word'}} variant="h6" fontWeight={600}>
        {deviceName} is {status}
      </Typography>

      <Typography>This process may take 3-5 minutes to complete.</Typography>
    </Card>
  );
}
