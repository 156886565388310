import {Store} from 'app/util/Store';

interface Params {
  storeKey: string;
  columnIds?: string[];
  activeColumnIds?: string[];
}

export class ColumnArrangementManager {
  _store: Store;
  _columnIds: string[];
  _columnIdsSet: Set<string>;
  _activeColumnIds: string[];
  _activeColumnIdsSet: Set<string>;
  _items: string[];
  _activeArrangedIds: string[];
  constructor({
    storeKey,
    columnIds = [],
    activeColumnIds = [],
  }: Params) {
    const _columnIds = [...columnIds];

    this._store = new Store({
      storeKey: `${storeKey}.Arrangement`,
      defaultValue: _columnIds,
    });

    this._columnIds = _columnIds;
    this._columnIdsSet = new Set(this._columnIds);
    this._activeColumnIds = [...activeColumnIds];
    this._activeColumnIdsSet = new Set(this._activeColumnIds);

    const {
      all,
      active,
    } = this._load();
    this._items = all;
    this._activeArrangedIds = active;
  }

  _load(): {all: string[]; active: string[]} {
    const saved = this._store.load<string[]>();

    const result = [...saved];

    // Add new columns
    this._columnIds.forEach(id => {
      if (result.includes(id) === false) {
        result.push(id);
      }
    });

    // Remove old columns that already not used
    const actualIds = result.filter(id => this._columnIdsSet.has(id));

    return {
      all: actualIds,
      active: actualIds.filter(id => this._activeColumnIdsSet.has(id)),
    };
  }

  _save() {
    this._store.save(this._items);
  }

  move(id: string, index: number) {
    const currentIndexInActive = this._activeArrangedIds.indexOf(id);
    const currentIndexInSaved = this._items.indexOf(id);

    // Index not changed
    if (currentIndexInActive === index) {
      return;
    }

    if (currentIndexInSaved > -1) {
      this._items.splice(currentIndexInSaved, 1);
      this._activeArrangedIds.splice(currentIndexInActive, 1);
    }

    // If `0` then set as first
    let indexInSaved = index;
    if (index > 0) {
      const nextLeftChildren = this._activeArrangedIds[index - 1];
      // Set as next of left children in saved list
      indexInSaved = this._items.indexOf(nextLeftChildren!) + 1;
    }

    this._activeArrangedIds.splice(index, 0, id);
    this._items.splice(indexInSaved, 0, id);
    this._save();
  }

  getArrangement() {
    return this._load().active;
  }
}
