import {useQuery} from '@tanstack/react-query';
import {FilesApi} from '../api/FilesApi';
import {FilesMapper} from '../mapper';

type Args = {
  enabled: boolean;
  teamId: string;
  deviceId: string;
};

export function useUnitFilesQuery({teamId, enabled, deviceId}: Args) {
  return useQuery({
    queryKey: ['unit', 'files', teamId, deviceId],
    queryFn: async () => {
      const res = await FilesApi.getUnitRecordings(deviceId);
      const mapped = res.files.map(FilesMapper.mapRecording);

      return new Map(mapped.map((rec) => [rec.id, rec]));
    },
    select: (data) => Array.from(data.values()),
    enabled,
  });
}
