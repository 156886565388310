import React, {useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {useMounted} from 'app/hooks/useIsMounted';
import {Prompt} from 'app/types/common';
import {NamesList} from 'app/components/sharedReactComponents/ItemsEnumeration';

type Props = {
  loading: boolean;
  recordings: string[];
  onDelete: () => Promise<void>;
  onCancel: () => void;
};

function DeleteRecordings({loading, recordings, onDelete, onCancel}: Props) {
  return (
    <Box>
      <DialogTitle>
        Delete <NamesList variant="inherit" color="primary.main" names={recordings} max={3} />{' '}
        {pluralizeN('recording', recordings.length)}
      </DialogTitle>

      <DialogContent>
        <Typography textAlign="center" color="text.secondary">
          This action cannot be undone
        </Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="confirm-delete-btn"
          loading={loading}
          color="error"
          variant="contained"
          onClick={onDelete}
        >
          Delete
        </LoadingButton>

        <Button data-id="cancel-delete-btn" variant="outlined" color="info" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
}

type DialogProps = Prompt & Omit<Props, 'onCancel' | 'loading'>;

export function DeleteRecordingsDialog({open, recordings, onDelete, onClose}: DialogProps) {
  const mounted = useMounted();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await onDelete();
      onClose();
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" onClose={onClose}>
      <DeleteRecordings
        recordings={recordings}
        loading={loading}
        onDelete={handleDelete}
        onCancel={onClose}
      />
    </Dialog>
  );
}
