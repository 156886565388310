/**
 * Add or remove item from Set
 *
 * ```typescript
 * toggleInSet(new Set([1, 2, 3]), 2)
 * //=> Set(2) {1, 3}
 *
 * toggleInSet(new Set([1, 2, 3]), 4)
 * //=> Set(4) {1, 2, 3, 4}
 * ```
 */
function toggleInSet(set: Set<any>, value: any): Set<any> {
  const result = new Set(set);

  if (result.has(value)) {
    result.delete(value);
  } else {
    result.add(value);
  }

  return result;
}

export {
  toggleInSet,
};
