import React from 'react';
import {Icons} from 'app/util/icons';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';

interface Props {
  url: string;
  text: string;
}

export const GoBackButton = ({
  url,
  text,
}: Props) => {
  return (
    <Button
      className="cr-header__go-back-button"
      dataId="go_back_button"
      theme={THEME.SECONDARY}
      variant={VARIANT.LINK}
      href={url}
    >
      {Icons.urlGoBackArrow().size(SIZE.S).label(text).reactComponent()}
    </Button>
  );
};
