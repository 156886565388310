import React from 'react';
import {observer} from 'mobx-react';
import {STORAGE_TYPE} from 'app/components/UploadLocations/constants';
import {BoxStorageConfiguration} from 'app/components/UploadLocations/CloudStorageConfiguration/BoxStorageConfiguration';
import {DropboxStorageConfiguration} from 'app/components/UploadLocations/CloudStorageConfiguration/DropboxStorageConfiguration';
import {GoogleDriveStorageConfiguration} from 'app/components/UploadLocations/CloudStorageConfiguration/GoogleDriveStorageConfiguration';
import {OAuth2LocationModel} from 'app/components/UploadLocations/Models/OAuth2LocationModel';
import {Callback} from 'app/types/common';

interface Props {
  currentLocation: OAuth2LocationModel;
  onClickReconnect: Callback;
}

function CloudStorageConfigurationComponent({
  currentLocation,
  onClickReconnect,
}: Props) {
  const props = {
    model: currentLocation,
    onClickReconnect,
  };

  switch (currentLocation.type) {
    case STORAGE_TYPE.BOX:
      return <BoxStorageConfiguration {...props}/>;
    case STORAGE_TYPE.DROPBOX:
      return <DropboxStorageConfiguration {...props}/>;
    case STORAGE_TYPE.GOOGLE_DRIVE:
      return <GoogleDriveStorageConfiguration {...props}/>;
    default:
      return null;
  }
}

const CloudStorageConfiguration = observer(CloudStorageConfigurationComponent);

export {CloudStorageConfiguration};
