import React from 'react';
import {Alert, AlertTitle, ButtonBase, Typography} from '@mui/material';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {Maintenance, formatMaintenanceTimestamp} from 'app/components/entities/maintenance';

interface Props extends Sx {
  maintenance: Maintenance.Entity;
  onExpand: () => void;
}

const rootSx: StyleSx = {textAlign: 'left'};
const alertSx: StyleSx = {px: 1, py: 0, width: '100%'};
const alertTitle: StyleSx = {fontWeight: 600, mb: 0};

export function NavigationMaintenance({sx, maintenance, onExpand}: Props) {
  const formatted = formatMaintenanceTimestamp(maintenance.start);

  return (
    <ButtonBase sx={packSx(rootSx, sx)} onClick={onExpand}>
      <Alert sx={alertSx} severity="warning" icon={<BuildCircleIcon />}>
        <AlertTitle sx={alertTitle}>{maintenance.summary} </AlertTitle>

        <Typography>{formatted}</Typography>
      </Alert>
    </ButtonBase>
  );
}
