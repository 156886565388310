import React from 'react';
import {observer} from 'mobx-react';
import {Box, Stack, Typography} from '@mui/material';
import {ChangeEvent, ClassName} from 'app/types/common';
import {useParticipants} from 'app/components/pages/MeetingDetails/ParticipantsList/useParticipants';
import {ParticipantCard} from 'app/components/pages/MeetingDetails/ParticipantCard/ParticipantCard';
import {Meeting} from 'app/store/models/connect/Meeting/Meeting';
import {DropdownSelect} from 'app/components/sharedReactComponents/DropdownSelect';
import {SIZE} from 'app/constants';
import {DROPDOWN_SELECT_THEME} from 'app/components/sharedReactComponents/DropdownSelect/constants';
import {Tooltip, TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';
import {noop} from 'app/util/noop';
import {FilterSelector} from 'app/components/sharedReactComponents/FilterSelector';
import {
  FilterSwitch,
  FilterSwitchGroup,
} from 'app/components/sharedReactComponents/FilterSelector/types';
import {useFilterFeature} from 'app/hooks/useFilterFeature';
import {Participant} from 'app/store/models/connect/Participant/Participant';
import {ActiveFilters} from 'app/components/FleetManager/SubHeader/ActiveFilters';
import {EmptyFilterMessage} from 'app/components/sharedReactComponents/EmptyFilterMessage/EmptyFilterMessage';
import {Icons} from 'app/util/icons';
import {useStorageValue} from 'app/components/pages/MeetingDetails/ParticipantsList/useStorageValue';
import {ReturnFeedMessage} from 'app/components/pages/MeetingDetails/ReturnFeedOwner/ReturnFeedOwner';
import {ReturnFeed} from 'app/store/models/connect/ReturnFeed/ReturnFeed';
import {palette} from 'app/themes/app';
import {isNil} from 'app/util/isNil';

export type ParticipantSort = 'name' | 'status';

const groups: FilterSwitchGroup[] = [
  {
    id: 'extractions',
    label: 'Extraction',
  },
];

const emptySet = new Set<string>();

const options: FilterSwitch[] = [
  {
    id: 'active',
    label: 'Only active',
    disabled: false,
    group: 'extractions',
    isVisible: () => true,
    callback: (p: Participant | ReturnFeed) => p.stream.active,
  },
];

interface Props extends ClassName {
  meeting: Meeting;
  userId: string;
  teamId: string;
}

const ParticipantsListComponent = ({meeting, teamId, userId}: Props) => {
  const [sort, setSort] = useStorageValue<ParticipantSort>({
    defaultValue: 'name',
    key: 'Connect.Participants.Sorter',
    teamId,
    userId,
  });

  const handleSelect = (e: ChangeEvent<ParticipantSort>) => {
    setSort(e.target.value);
  };

  const {filters, active, toggle, clear} = useFilterFeature({
    options,
    storage: {
      key: 'Connect.Participants.Filter',
      teamId,
      userId,
    },
  });

  const {users, mainstreams, feed} = useParticipants(meeting, sort, active);

  const filtered = active.length > 0;
  const hasUsers = users.length > 0;
  const hasMainstreams = mainstreams.length > 0;
  const hasParticipants = hasUsers || hasMainstreams || !isNil(feed);
  const showNoFiltered = !hasParticipants && filtered;

  return (
    <div>
      <Stack direction="row" mb={1} gap={1} alignItems="center">
        <ActiveFilters
          filterSwitches={options}
          activeFilterSwitches={filters}
          onClickFilterSwitcher={toggle}
          onClickClearAll={clear}
        />

        <Stack direction="row" sx={{ml: 'auto'}} alignItems="center">
          <Typography fontSize="12px">Sort by:</Typography>

          <DropdownSelect
            data-id="meeting_participants_sort_selector"
            size={SIZE.S}
            theme={DROPDOWN_SELECT_THEME.TRANSPARENT}
            options={[
              {
                label: 'Name',
                value: 'name',
              },
              {
                label: 'Status',
                value: 'status',
              },
            ]}
            value={sort}
            fullWidth={false}
            onChange={handleSelect}
          />
        </Stack>

        <FilterSelector
          filterSwitches={options}
          filterSwitchGroups={groups}
          activeFilterSwitches={filters}
          searchableFilterSwitchGroups={emptySet}
          collapsibleFilterSwitchGroups={emptySet}
          collapsedFilterSwitchGroups={emptySet}
          placement={TOOLTIP_PLACEMENT.BOTTOM_END}
          onClickFilterSwitcher={toggle}
          onToggleFilterSwitchGroupCollapse={noop}
        />
      </Stack>

      {showNoFiltered ? (
        <EmptyFilterMessage
          message="Filter applied, but no participants found."
          buttonText="Reset filter"
          handleClear={clear}
        />
      ) : (
        <Stack gap={4}>
          {hasUsers && (
            <Box>
              <Typography fontWeight={600} variant="h5" mb={2}>
                Participants
              </Typography>

              <Stack gap={2}>
                {users.map((p) => (
                  <ParticipantCard
                    key={p.id}
                    id={p.id}
                    meeting={meeting}
                    name={p.name}
                    type={p.type}
                    state={p.state}
                    audio={p.audio}
                    video={p.video}
                    individualAudio={p.individualAudio}
                    canStart={meeting.checkLimit()}
                    share={p.share}
                    stream={p.stream}
                  />
                ))}
              </Stack>
            </Box>
          )}

          {hasMainstreams && (
            <Box>
              <Typography fontWeight={600} variant="h5" mb={2}>
                Main streams
              </Typography>
              <Stack gap={2}>
                {mainstreams.map((p) => (
                  <ParticipantCard
                    key={p.id}
                    id={p.id}
                    meeting={meeting}
                    name={p.name}
                    type={p.type}
                    state={p.state}
                    audio={p.audio}
                    video={p.video}
                    individualAudio={p.individualAudio}
                    canStart={meeting.checkLimit()}
                    share={p.share}
                    stream={p.stream}
                  />
                ))}
              </Stack>
            </Box>
          )}

          {feed && (
            <Box>
              <Typography fontWeight={600} variant="h5" mb={1}>
                Return feed
              </Typography>

              <Stack sx={{color: palette.gray}} direction="row" gap={1} alignItems="center" mb={2}>
                <Typography sx={{fontSize: '12px'}}>
                  Show the video feed back to your meeting participants
                </Typography>{' '}
                <Tooltip content="The sender of the video must act as SRT transmitter">
                  {Icons.info().size(SIZE.S).reactComponent()}
                </Tooltip>
              </Stack>

              {feed.enabled ? (
                <ParticipantCard
                  id=""
                  meeting={meeting}
                  canStart={true}
                  name="Return feed"
                  type="return-feed"
                  state="joined"
                  audio={true}
                  video={true}
                  individualAudio={true}
                  share={false}
                  stream={feed.stream}
                />
              ) : (
                <ReturnFeedMessage
                  dataId="return_feed_owner"
                  platform={meeting.platform}
                  owner={feed.owner}
                />
              )}
            </Box>
          )}
        </Stack>
      )}
    </div>
  );
};

export const ParticipantsList = observer(ParticipantsListComponent);
