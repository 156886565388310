import React from 'react';
import classNames from 'classnames';
import {useInView} from 'react-intersection-observer';
import {ClassName} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {FleetManagerColumn} from 'app/components/FleetManager/types';
import {FleetManagerColumnId} from 'app/components/FleetManager/constants';
import {intersectionOfSets} from 'app/util/intersectionOfSets';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';
import {useSystemInfo} from 'app/components/DeviceDetails/DeviceInfoTab/SystemInformation/useSystemInfo';
import {useDeviceStorage} from 'app/components/DeviceDetails/DeviceInfoTab/SystemInformation/useStorageInfo';
import {useUnitAfu} from 'app/components/entities/afu';

interface Props extends ClassName {
  device: AnyDeviceModelType;
  role: AnyUserRoleModel;
  columns: FleetManagerColumn[];
  progress?: number;
}

const columnsSystem = new Set([
  FleetManagerColumnId.SystemLoad,
  FleetManagerColumnId.Temperature,
  FleetManagerColumnId.Uptime,
]);

const storageColumns = new Set([FleetManagerColumnId.Storage]);

const afuColumns = new Set([FleetManagerColumnId.TransferQueue]);

const isColumnUsed = (
  featureColumnIds: Set<FleetManagerColumnId>,
  columnIds: Set<FleetManagerColumnId>,
) => {
  return intersectionOfSets(featureColumnIds, columnIds).size > 0;
};

export const StatusList = React.memo(({className, columns, device, role, progress}: Props) => {
  const {ref, inView} = useInView();

  const columnIds = new Set(columns.map((column) => column.dataKey));
  const disabled = device.isOffline() || device.isUnpaired() || !inView;

  const deviceId = device.getId();

  const {data: system} = useSystemInfo({
    deviceId,
    enabled: !disabled && isColumnUsed(columnsSystem, columnIds),
    refetchInterval: 60 * 1000,
  });

  const {data: storage} = useDeviceStorage({
    deviceId,
    enabled: !disabled && isColumnUsed(storageColumns, columnIds),
    refetchInterval: 60 * 1000,
  });

  const {data: afu} = useUnitAfu({
    deviceId,
    enabled: !disabled && isColumnUsed(afuColumns, columnIds),
    refetchInterval: 60 * 1000,
  });

  return (
    <div ref={ref} className={classNames('device-card-status-list', className)}>
      {columns.map((column) => (
        <div
          key={column.dataKey}
          className="device-card-status-list__item"
          data-id={`device_card_${column.dataKey}`}
          style={{
            width: column.width,
          }}
        >
          {column.cellRenderer({
            device,
            system: system ?? undefined,
            storage: storage ?? undefined,
            afu: afu ?? undefined,
            progress,
            role,
          }) || '—'}
        </div>
      ))}
    </div>
  );
});
