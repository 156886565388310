import {useQuery} from '@tanstack/react-query';
import {ConnectMapper} from 'app/util/mappers/ConnectMapper/ConnectMapper';
import {SrtApiService} from 'app/services/api/meetings/SrtApiService';
import {secondsToMilliseconds} from 'app/util/timeConverter';

interface Return {
  loading: boolean;
  status?: App.Connect.StreamingStatus;
}

interface Props {
  meetingId: string;
  participantId: string;
  disabled: boolean;
}

type Args = {
  meetingId: string;
  participantId: string;
};

export function useStreamStatus({meetingId, participantId, disabled}: Props): Return {
  const {isLoading, data} = useQuery<
    Contracts.Connect.StreamingStatus,
    Error,
    App.Connect.StreamingStatus,
    [string, Args]
  >(
    ['status', {meetingId, participantId}],
    ({queryKey}) => {
      const [, {meetingId, participantId}] = queryKey;

      if (participantId) {
        return SrtApiService.getParticipantStreamStatus(meetingId, participantId);
      }

      return SrtApiService.getReturnFeedStreamStatus(meetingId);
    },
    {
      select: ConnectMapper.mapStreamingStatus,
      refetchInterval: secondsToMilliseconds(10),
      enabled: !disabled,
    },
  );

  return {
    loading: isLoading,
    status: data,
  };
}
