import React, {Component, createRef, ReactNode} from 'react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import {Instance} from 'tippy.js';
import maxSize from 'popper-max-size-modifier';
import {KeyboardCode} from 'app/constants';
import {noop} from 'app/util/noop';
import {TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';
import {Callback} from 'app/types/common';
import {Options, ModifierArguments} from '@popperjs/core/lib/types';
import {Icons} from 'app/util/icons';
import {Button} from 'app/components/sharedReactComponents/Button';

function createPopperOptions(maxHeight?: number) {
  const popperOptions: Partial<Options> = {
    modifiers: [
      maxSize,
      {
        name: 'applyMaxSize',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['maxSize'],
        fn({state}: ModifierArguments<Options>) {
          const {height} = state.modifiersData.maxSize;
          if (state.styles.popper) {
            let resultHeight = height;
            if (typeof maxHeight === 'number' && maxHeight < height) {
              resultHeight = maxHeight;
            }

            state.elements.popper.style.setProperty('--popper-max-height', `${resultHeight}px`);
            state.styles.popper.display = 'flex';
          }
        },
      },
    ],
  };

  return popperOptions;
}

interface Props {
  content?: any;
  children: any;
  buttonOpenedClassName?: string;
  trigger?: string;
  placement?: TOOLTIP_PLACEMENT;
  maxWidth?: number | 'none';
  maxHeight?: number;
  fullWidth?: boolean;
  disabled?: boolean;
  interactive?: boolean;
  appendToBody?: boolean;
  showClose?: boolean;
  onBlur?: Callback;
  onOpen?: Callback;
}

interface State {
  opened: boolean;
  disabled: boolean;
}

export class Dropdown extends Component<Props, State> {
  static defaultProps = {
    trigger: 'click',
    placement: TOOLTIP_PLACEMENT.TOP,
    maxWidth: 350,
    disabled: false,
    interactive: false,
    appendToBody: false,
    showClose: false,
  };

  public tooltipRef = createRef<Instance>();
  public buttonRef = createRef<any>();
  public forceClose = false;

  private readonly popperOptions;

  constructor(props: Props) {
    super(props);

    this.popperOptions = createPopperOptions(props.maxHeight);

    this.state = {
      opened: false,
      disabled: props.disabled === true,
    };
  }

  componentDidUpdate(prevProps) {
    const {disabled} = this.props;

    if (disabled !== prevProps.disabled) {
      if (disabled) {
        this.close(() => this.setState({disabled: true}));
      } else {
        this.setState({disabled: false});
      }
    }
  }

  componentWillUnmount() {
    this.forceClose = true;
    document.removeEventListener('keyup', this.handleKeyDown);
  }

  handleTooltipCreate = (ref: Instance) => {
    (this.tooltipRef as any).current = ref;
  };

  setAppendTo = (ref) => {
    const {appendToBody} = this.props;

    if (appendToBody) {
      return document.body;
    }

    return ref.parentNode;
  };

  /**
   * @public
   */
  open() {
    if (this.state.opened === false && this.tooltipRef.current) {
      this.tooltipRef.current.show();
    }
  }

  /**
   * @public
   */
  close(cb = noop) {
    if (this.state.opened && this.tooltipRef.current) {
      this.forceClose = true;
      this.tooltipRef.current.hide();
      this.setState({opened: false}, () => cb());
    } else {
      cb();
    }
  }

  /**
   * @public
   */
  focusButton() {
    this.buttonRef.current.focus();
  }

  clickButton() {
    this.buttonRef.current.click();
  }

  handleTooltipShown = () => {
    this.setState({opened: true});
    document.addEventListener('keyup', this.handleKeyDown);

    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  // To support child dropdown
  handleTooltipHide = (tip) => {
    if (this.forceClose) {
      // After this callback will fired `handleTooltipHidden`
      return;
    }

    // `setTimeout` and `forceClose` because child tippy instance flags changes async
    setTimeout(() => {
      const anchors = tip.popper.querySelectorAll('[aria-haspopup="true"]');
      let hasOpenedChildDropdown = false;

      for (const anchor of anchors) {
        if (anchor?._tippy?.state?.isVisible) {
          hasOpenedChildDropdown = true;
          break;
        }
      }

      if (!hasOpenedChildDropdown) {
        this.forceClose = true;
        this.tooltipRef.current?.hide();
      }
    }, 200);

    return false;
  };

  handleTooltipHidden = () => {
    this.forceClose = false;
    this.setState({opened: false});
    document.removeEventListener('keyup', this.handleKeyDown);

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === KeyboardCode.Escape) {
      if (!e.defaultPrevented) {
        this.tooltipRef.current?.hide();

        // Close opened menu and still focus on dropdown button
        this.buttonRef.current.focus();
      }
    } else if (e.code === KeyboardCode.Tab) {
      // Close opened menu on focus lost
      if (!this.tooltipRef.current?.popper.contains(e.target as Node)) {
        this.tooltipRef.current?.hide();
      }
    }
  };

  handleCloseClick = () => {
    this.tooltipRef.current?.hide();

    // Close opened menu and still focus on dropdown button
    this.buttonRef.current.focus();
  };

  renderButton() {
    const {children, buttonOpenedClassName, fullWidth} = this.props;
    const {opened} = this.state;

    const additionalProps = {
      ref: this.buttonRef,
      fullWidth,
      opened,
    };

    const ariaAttributes = {
      'aria-haspopup': true,
      'aria-expanded': opened,
    };

    if (typeof children === 'function') {
      return children({
        ...ariaAttributes,
        ...additionalProps,
      });
    }

    return React.cloneElement(children, {
      ...additionalProps,
      ...ariaAttributes,
      className: classNames(children.props?.className, opened && buttonOpenedClassName),
    });
  }

  contentWrapper(content: ReactNode) {
    return (
      <div className="dropdown__content-wrapper">
        {content}

        <Button className="dropdown__close" onClick={this.handleCloseClick}>
          {Icons.cross().reactComponent()}
        </Button>
      </div>
    );
  }

  renderContent() {
    const {opened} = this.state;
    const {content, showClose} = this.props;

    if (opened) {
      const renderedContent = typeof content === 'function' ? content() : content;
      return showClose ? this.contentWrapper(renderedContent) : renderedContent;
    }

    return '';
  }

  render() {
    const {trigger, placement, maxWidth, interactive} = this.props;
    const {disabled} = this.state;

    return (
      <Tippy
        content={this.renderContent()}
        theme="dropdown"
        animation="shift-away"
        duration={200}
        trigger={trigger}
        placement={placement}
        maxWidth={maxWidth}
        disabled={disabled}
        interactive={interactive}
        arrow={false}
        ignoreAttributes={true}
        popperOptions={this.popperOptions}
        appendTo={this.setAppendTo}
        onShow={this.handleTooltipShown}
        onHide={this.handleTooltipHide}
        onHidden={this.handleTooltipHidden}
        onCreate={this.handleTooltipCreate}
      >
        {this.renderButton()}
      </Tippy>
    );
  }
}
