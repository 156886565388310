import React, {Component} from 'react';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {SelectMenuOption} from 'app/components/sharedReactComponents/SelectMenuOption';
import {Callback} from 'app/types/common';

export interface DropdownSelectOptionProps {
  label: number | string | React.ReactNode;
  value: number | string | boolean;
  icon?: React.ReactNode;
  title?: string;
  withIcon?: boolean;
  active?: boolean;
  disabled?: boolean;
  onClick: Callback;
}

export class DropdownSelectOption extends Component<DropdownSelectOptionProps> {
  handleClick = () => {
    const {
      value,
      disabled,
      onClick,
    } = this.props;

    if (!disabled) {
      onClick(value);
    }
  };

  render() {
    const {
      label,
      icon,
      title,
      withIcon,
      active,
      disabled,
    } = this.props;

    const hasIcon = Boolean(icon);

    return (
      <SelectMenuOption
        className="dropdown-select__option"
        data-id="dropdown_select_option"
        title={title}
        icon={icon}
        withIcon={withIcon || hasIcon}
        active={active}
        disabled={disabled}
        onClick={this.handleClick}
      >
        <OverflowTooltip
          component="span"
          async={true}
        >
          {label}
        </OverflowTooltip>
      </SelectMenuOption>
    );
  }
}
