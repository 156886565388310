import React from 'react';
import {Component} from 'app/types/common';
import classNames from 'classnames';
import {router} from 'app/router/main';

interface Props extends Component {
  platform: App.Connect.Platform;
  owner?: App.Connect.ReturnFeedOwner;
}

export const ReturnFeedMessage = ({className, dataId, owner, platform}: Props) => {
  const renderOwner = (owner: App.Connect.ReturnFeedOwner) => {
    const {id, internal, name} = owner;
    if (internal) {
      return (
        <>
          meeting <a href={router.url('meetingDetails', {meetingId: id})}>{name}</a>
        </>
      );
    }
    return 'a different Epiphan Cloud team';
  };

  const renderContent = () => {
    if (platform === 'zoom') {
      return 'Coming soon';
    }

    if (owner) {
      return (
        <>
          The return feed is currently active in {renderOwner(owner)}. Only one active return feed
          is possible per meeting.
        </>
      );
    }

    return 'Return feed is not available. Please reconnect the current meeting or connect a new one to enable this feature.';
  };

  return (
    <div data-id={dataId} className={classNames('return-feed-owner', className)}>
      {renderContent()}
    </div>
  );
};
