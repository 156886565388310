import React from 'react';
import {Box, Dialog, DialogContent, Divider, Stack} from '@mui/material';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {Prompt} from 'app/types/common';
import {CloudState} from 'app/components/BillingManager/dialogs/CloudTiersDialog/CloudState/CloudState';
import {EdgeFeatures} from 'app/components/BillingManager/dialogs/CloudTiersDialog/CloudFeatures/CloudFeatures';
import {ArchiveTier} from 'app/components/BillingManager/dialogs/CloudTiersDialog/tiers/ArchiveTier';
import {freeTier} from 'app/util/billing/utils';
import {FreeTier} from 'app/components/BillingManager/dialogs/CloudTiersDialog/tiers/FreeTier';
import {PremiumTier} from 'app/components/BillingManager/dialogs/CloudTiersDialog/tiers/PremiumTier';

type PremiumProps = Omit<
  React.ComponentProps<typeof PremiumTier>,
  'current' | 'currentFree' | 'tier'
>;

interface Props extends Prompt, PremiumProps {
  currentTier: string;
  tiers: App.Billing.Cloud.Tier[];
}

export function EdgeTiersDialog({
  dataId,
  open,
  tiers,
  currentTier,
  interval,
  seatPrice,
  seatCount,
  configured,
  hasMethod,
  paired,
  onEditPayment,
  onEnterCoupon,
  onSelectTier,
  onConfigureSeats,
  onClose,
}: Props) {
  const renderTier = (tier: App.Billing.Cloud.Tier) => {
    const current = tier.id === currentTier;
    const handleCancelPremium = () => onSelectTier('');

    if (tier.isDeleted) {
      return (
        <ArchiveTier
          key={tier.id}
          tier={tier}
          current={current}
          interval={interval}
          onCancelPremium={handleCancelPremium}
        />
      );
    }

    if (freeTier(tier.id)) {
      return (
        <FreeTier
          key={tier.id}
          tier={tier}
          current={current}
          interval={interval}
          onCancelPremium={handleCancelPremium}
        />
      );
    }

    return (
      <PremiumTier
        key={tier.id}
        current={current}
        currentFree={freeTier(currentTier)}
        tier={tier}
        configured={configured}
        seatCount={seatCount}
        seatPrice={seatPrice}
        interval={interval}
        hasMethod={hasMethod}
        paired={paired}
        onEditPayment={onEditPayment}
        onEnterCoupon={onEnterCoupon}
        onSelectTier={onSelectTier}
        onConfigureSeats={onConfigureSeats}
      />
    );
  };

  return (
    <Dialog data-id={dataId} open={open} maxWidth="xl" scroll="body" onClose={onClose}>
      <DialogClose onClose={onClose} />
      <DialogContent sx={{p: 0}}>
        <Box sx={{display: 'inline-block', px: 8, py: 5}}>
          <Stack
            direction="row"
            gap={1}
            divider={<Divider sx={{borderStyle: 'dashed'}} orientation="vertical" flexItem />}
          >
            <CloudState paired={paired} />

            {tiers.map(renderTier)}
          </Stack>

          <EdgeFeatures tiers={tiers} currentTier={currentTier} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
