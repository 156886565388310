import React from 'react';
import {Sx} from 'app/types/common';
import {Stack} from '@mui/material';
import ContactlessIcon from '@mui/icons-material/Contactless';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Edge} from 'app/domain/edge';
import {formatStartedAtDate} from 'app/util/time';
import {EdgeDeviceStatus} from 'app/components/features/edge/EdgeDevice/StatusIndicator/EdgeDeviceStatus/EdgeDeviceStatus';

type Props = Sx & {
  status: Edge.DeviceStatus;
  streaming: boolean;
  recording: boolean;
  transcribing: boolean;
  stateTime?: number;
};

export function StatusIndicator({
  sx,
  status,
  recording,
  streaming,
  transcribing,
  stateTime,
}: Props) {
  const showIndicators = streaming || recording || transcribing;

  return (
    <Stack sx={sx} direction="row" alignItems="center" justifyContent="center" gap={0.5}>
      {showIndicators ? (
        <>
          {streaming && (
            <Tooltip content="Streaming">
              <ContactlessIcon
                data-id="device_status_icon_streaming"
                fontSize="large"
                color="primary"
              />
            </Tooltip>
          )}
          {recording && (
            <Tooltip content="Recording">
              <ModeStandbyIcon
                data-id="device_status_icon_recording"
                fontSize="large"
                color="error"
              />
            </Tooltip>
          )}
          {transcribing && (
            <Tooltip content="Transcribing">
              <VoiceChatIcon
                data-id="device_status_icon_transcribing"
                fontSize="large"
                color="secondary"
              />
            </Tooltip>
          )}
        </>
      ) : (
        <Tooltip content={getDeviceStatusTooltip(status, stateTime)}>
          <span>
            <EdgeDeviceStatus dataId="device-status" status={status} />
          </span>
        </Tooltip>
      )}
    </Stack>
  );
}

function getDeviceStatusTooltip(status: Edge.DeviceStatus, stateTime?: number): string {
  switch (status) {
    case 'online':
      return '';
    case 'holding_legacy':
      return 'Incompatible firmware. Update your device firmware for Epiphan Edge compatibility.';
    default:
      return stateTime ? `Last time online: ${formatStartedAtDate(stateTime)}` : '';
  }
}
