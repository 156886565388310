import {ModalWindow} from 'app/util/OAuthProvider/ModalWindow';
import {OAuthProviderOptions} from 'app/util/OAuthProvider/types';

class BaseProvider {
  name;
  url;
  onOpen;
  onClose;

  windowWidth: null | number = null;
  windowHeight: null | number = null;
  oauthWindow: null | ModalWindow = null;

  constructor({
    name,
    url,
    onOpen,
    onClose,
  }: OAuthProviderOptions) {
    this.name = name;
    this.url = url;
    this.onOpen = onOpen;
    this.onClose = onClose;
  }

  public open() {
    this.oauthWindow = new ModalWindow({
      name: this.name,
      url: this.url,
      width: this.windowWidth,
      height: this.windowHeight,
      onOpen: this.onOpen,
      onClose: this.onClose,
    });

    this.oauthWindow.open();
  }
}

export {BaseProvider};
