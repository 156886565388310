import React, {useCallback, useState} from 'react';
import classNames from 'classnames';
import {Checkbox} from 'app/components/sharedReactComponents/Checkbox';
import {useComponentDidMount} from 'app/hooks/useComponentDidMount';
import {Callback, ClassName} from 'app/types/common';
import {SIZE} from 'app/constants';

type CheckList = Record<string, boolean>;

function areAllChecked(checks: CheckList) {
  const hasUnchecked = Object.values(checks).some((check) => check === false);

  return !hasUnchecked;
}

export interface Check {
  name: string;
  label: React.ReactNode;
}

interface Props extends ClassName {
  checks: Check[];
  size?: SIZE;
  onChange: Callback;
}

export const CheckboxesListLock = ({className, checks, size, onChange}: Props) => {
  const [formData, setFormData] = useState<CheckList>({});

  useComponentDidMount(() => {
    setFormData(
      checks.reduce((acc, {name}) => {
        acc[name] = false;

        return acc;
      }, {}),
    );
  });

  const handleChangeCheckbox = useCallback(
    ({target: {name, value}}) => {
      const newFormData = {
        ...formData,
        [name]: value,
      };

      const formValue = areAllChecked(newFormData);

      if (areAllChecked(formData) !== formValue) {
        onChange(formValue);
      }

      setFormData(newFormData);
    },
    [formData, onChange],
  );

  return (
    <div className={classNames('checkboxes-list-lock', className)}>
      {checks.map(({name, label}) => (
        <div key={name} className="checkboxes-list-lock__item">
          <Checkbox
            name={name}
            value={formData[name]}
            label={label}
            size={size}
            onChange={handleChangeCheckbox}
          />
        </div>
      ))}
    </div>
  );
};
