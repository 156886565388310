import React, {Fragment} from 'react';
import {Stack, Typography} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {SelectMenuOptionWithRadio} from 'app/components/sharedReactComponents/SelectMenuOption/SelectMenuOptionWithRadio';
import {StreamingServiceIcon} from 'app/components/StreamingServices/StreamingServiceIcon';
import {Button} from 'app/components/sharedReactComponents/Button';
import {VARIANT, SIZE} from 'app/constants';
import {router} from 'app/router/main';
import {Icons} from 'app/util/icons';
import {StreamingFromDeviceNote} from 'app/components/sharedReactComponents/ActionButtons/StreamingDestinationsSelector/StreamingFromDeviceNote';
import {AlreadySelectedOnDeviceNote} from 'app/components/sharedReactComponents/ActionButtons/StreamingDestinationsSelector/AlreadySelectedOnDeviceNote';
import {stopPropagation} from 'app/util/stopPropagation';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {TooltipMultipleMessages} from 'app/components/sharedReactComponents/Tooltip/TooltipMultipleMessages';
import {noop} from 'app/util/noop';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {DeviceWarning} from 'app/components/DeviceDetails/Models/types';

interface Props {
  streamingDestination: AnyStreamingDestinationModelType;
  isUnify: boolean;
  device: AnyDeviceModelType;
  warnings: DeviceWarning[];
  disabled: boolean;
  editable: boolean;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
}

export function StreamingDestinationsSelectorMenuItem({
  streamingDestination,
  isUnify,
  device,
  warnings,
  disabled,
  editable,
  getDeviceById,
}: Props) {
  const streaming = streamingDestination.isStreaming();
  const selectedOnDeviceId = streamingDestination.getSelectedOnDeviceId();
  const availableOnThisDevice = device.checkStreamingDestinationAvailableToUse(
    streamingDestination.getType(),
  );
  const id = streamingDestination.getId() as string;

  const hasWarnings = warnings.length > 0;

  return (
    <SelectMenuOptionWithRadio
      // rework onClick is requred
      className="streaming-destinations-selector-menu-item"
      icon={StreamingServiceIcon.getSmallIconByType(
        streamingDestination.getType(),
      ).reactComponent()}
      name={id}
      value={id}
      disabled={availableOnThisDevice === false || disabled || streaming}
      disabledReasonMessage={
        availableOnThisDevice === false ? `Unavailable for ${device.getModelName()} devices` : ''
      }
      onClick={noop}
    >
      <div className="streaming-destinations-selector-menu-item__label">
        <div className="streaming-destinations-selector-menu-item__name">
          <Stack direction="row" alignItems="flex-start" gap={1}>
            <Typography component="span" sx={hasWarnings ? {color: 'error.main'} : {}}>
              <OverflowTooltip rowsNumber={2} async={true}>
                {streamingDestination.getName()}
              </OverflowTooltip>
            </Typography>

            {hasWarnings && (
              <TooltipMultipleMessages messages={warnings}>
                <WarningIcon color="error" />
              </TooltipMultipleMessages>
            )}
          </Stack>

          {disabled !== true && (
            <Fragment>
              {streaming ? (
                <StreamingFromDeviceNote
                  className="streaming-destinations-selector-menu-item__streaming-from"
                  currentDevice={device}
                  device={getDeviceById(streamingDestination.getStreamingDeviceId())}
                />
              ) : (
                <Fragment>
                  {selectedOnDeviceId && selectedOnDeviceId !== device.getId() && (
                    <AlreadySelectedOnDeviceNote
                      className="streaming-destinations-selector-menu-item__selected-on"
                      currentDevice={device}
                      device={getDeviceById(selectedOnDeviceId)}
                    />
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </div>

        {disabled !== true && editable && (
          <Button
            className="streaming-destinations-selector-menu-item__edit"
            title="Edit Destination"
            variant={VARIANT.TEXT}
            size={SIZE.S}
            onClick={(e) => {
              stopPropagation(e);
              router.go(
                isUnify ? 'projectDetails' : 'deviceDetails',
                {deviceId: device.getId()},
                {activeTab: 'channels', scrollTo: `streamingDestination=${id}`},
              );
            }}
          >
            {Icons.edit().size(SIZE.S).reactComponent()}
          </Button>
        )}
      </div>
    </SelectMenuOptionWithRadio>
  );
}
