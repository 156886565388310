import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {DataId, Sx} from 'app/types/common';
import {Edge} from 'app/domain/edge';
import {SourceThumbnail} from 'app/components/sharedReactComponents/SourceThumbnail';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {VideoDetails} from 'app/components/DeviceDetails/Inputs/InputsPanel/VideoInput/VideoDetails';

interface Props extends Sx, DataId {
  device: PearlMasterDeviceModel;
  input: Edge.VideoSource;
}

export function VideoInput({sx, dataId, device, input}: Props) {
  const showProps = input.isActive && input.hasSignal;

  return (
    <Stack
      data-id={dataId}
      sx={sx}
      borderRadius={1}
      bgcolor={grey[100]}
      direction="row"
      gap={3}
      p={3}
      height={166}
    >
      <Box alignSelf="center" flexShrink={0}>
        <SourceThumbnail sx={{width: 208, height: 118}} device={device} source={input} />
      </Box>

      <Stack>
        <Typography component="span" fontWeight={600}>
          <OverflowTooltip rowsNumber={2} dataId="video_input_name">
            {input.name}
          </OverflowTooltip>
        </Typography>

        {showProps && <VideoDetails sx={{mt: 'auto'}} input={input} />}
      </Stack>
    </Stack>
  );
}
