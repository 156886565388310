import {useQuery} from '@tanstack/react-query';
import {EdgePlan} from 'app/models/EdgePlan/EdgePlan';
import {BillingApiService} from 'app/services/api/billing/BillingApiService';
import {BillingMapper} from 'app/services/mappers/BillingMapper';
import {secondsToMilliseconds} from 'app/util/timeConverter';

export function useCloudPlan(teamId: string, enabled: boolean) {
  return useQuery<Contracts.Billing.Cloud.Plan, any, EdgePlan>({
    queryKey: ['cloud-plan', teamId],
    enabled,
    queryFn: () => BillingApiService.getCloudPlan(teamId),
    select: BillingMapper.mapEdgePlan,
    refetchInterval: secondsToMilliseconds(30),
  });
}
