import React from 'react';
import {makeImgUrl} from 'app/components/UploadLocations/UploadLocationIcons/utils';

export const FtpSftpIcon = () => (
  <img
    src={makeImgUrl('ftp-sftp-icon.svg')}
    width={70}
    height={70}
    alt=""
  />
);

export const BoxIcon = () => (
  <img
    src={makeImgUrl('box-icon-70x70.png')}
    srcSet={`${makeImgUrl('box-icon-70x70@x2.png')} 2x, ${makeImgUrl('box-icon-70x70@x3.png')} 3x`}
    width={70}
    height={70}
    alt="Box logo"
  />
);

export const DropboxIcon = () => (
  <img
    src={makeImgUrl('dropbox-icon-70x70.png')}
    srcSet={`${makeImgUrl('dropbox-icon-70x70@x2.png')} 2x, ${makeImgUrl('dropbox-icon-70x70@x3.png')} 3x`}
    width={70}
    height={70}
    alt="Dropbox logo"
  />
);

export const GoogleDriveIcon = () => (
  <img
    src={makeImgUrl('google-drive-icon-70x70.png')}
    srcSet={`${makeImgUrl('google-drive-icon-70x70@x2.png')} 2x, ${makeImgUrl('google-drive-icon-70x70@x3.png')} 3x`}
    width={70}
    height={70}
    alt="Google Drive™ logo"
  />
);
