import {freeTier, proTier} from 'app/util/billing/utils';
import {valueComparator} from 'app/util/comparators/valueComparator';

interface Args {
  id: App.Billing.Cloud.Id;
  name: string;
  tier: App.Billing.Cloud.Tier;
  tiers: App.Billing.Cloud.Tier[];
  prices: App.Billing.Cloud.Pricing;
  interval: App.Billing.Interval;
  period: App.Billing.Period;
  trial?: App.Billing.Cloud.Trial;
}
export class EdgePlan {
  id: App.Billing.Cloud.Id;
  name: string;
  tier: App.Billing.Cloud.Tier;
  tiers: App.Billing.Cloud.Tier[];
  period: App.Billing.Period;
  interval: App.Billing.Interval;
  prices: App.Billing.Cloud.Pricing;
  trial?: App.Billing.Cloud.Trial;

  constructor({id, name, tier, prices, tiers, interval, trial, period}: Args) {
    this.id = id;
    this.name = name;
    this.tier = tier;
    this.tiers = tiers;
    this.trial = trial;
    this.prices = prices;
    this.interval = interval;
    this.period = period;
  }

  /* Checks if cloud subscription is default - free */
  get configured() {
    return this.id !== 'free';
  }

  /** checks if selected tier subscription is not required payment */
  get free() {
    return freeTier(this.tier.id);
  }

  get premium() {
    return !this.free;
  }

  get list() {
    return this.tiers
      .filter((t) => proTier(t.id) || freeTier(t.id) || t.id === this.tier.id)
      .sort((a, b) => valueComparator(a.isDeleted, b.isDeleted));
  }

  get unlimited() {
    return !this.free && this.tier.seats === -1;
  }
}
