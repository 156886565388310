import React, {Fragment, useCallback, useRef, useState} from 'react';
import classNames from 'classnames';
import {DEVICE_START_STOP_BUTTON_STATUS} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton/constants';
import {ListItem} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStreamingPanel/DeviceStreamingDestinationList/ListItem';
import {
  SelectInput,
  COMMON_STREAM_SELECT_INPUT_TYPE,
} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStreamingPanel/DeviceStreamingDestinationList/SelectInput';
import {Name} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStreamingPanel/DeviceStreamingDestinationList/Name';
import {WarningsIndicator} from 'app/components/sharedReactComponents/WarningsIndicator';
import {SIZE, THEME} from 'app/constants';
import {StartedAtNote} from 'app/components/sharedReactComponents/StartedAtNote';
import {AlreadySelectedOnDeviceNote} from 'app/components/sharedReactComponents/ActionButtons/StreamingDestinationsSelector/AlreadySelectedOnDeviceNote';
import {StreamingFromDeviceNote} from 'app/components/sharedReactComponents/ActionButtons/StreamingDestinationsSelector/StreamingFromDeviceNote';
import {DetailsToggler} from 'app/components/sharedReactComponents/DetailsToggler';
import {isNil} from 'app/util/isNil';
import {noop} from 'app/util/noop';
import {useScrollToAnchor} from 'app/components/sharedReactComponents/ScrollToAnchor/useScrollToAnchor';
import {Callback, ClassName} from 'app/types/common';
import {StreamingButton} from 'app/components/sharedReactComponents/ActionButtons/StreamingButton';
import {DeviceWarning} from 'app/components/DeviceDetails/Models/types';

interface Props extends ClassName {
  deviceId?: string;
  selectedOnDeviceId?: string;
  id: string;
  name: string;
  icon?: React.ReactNode;
  disabled: boolean;
  disabledReasonMessage?: string;
  offline: boolean;
  selected: boolean;
  started: boolean;
  streaming: boolean;
  hasError: boolean;
  selecting: boolean;
  startTime: number;
  warnings: DeviceWarning[];
  selectInputType: COMMON_STREAM_SELECT_INPUT_TYPE;
  detailsComponent?: React.ReactNode;
  startStopAction: Callback;
  getDeviceById?: Callback;
  onSelect: Callback;
  onNameChange?: Callback;
}

export function CommonStreamListItem({
  className,
  deviceId,
  selectedOnDeviceId,
  id,
  name,
  icon,
  disabled,
  disabledReasonMessage,
  offline,
  selected,
  started,
  hasError,
  streaming,
  selecting,
  startTime,
  warnings,
  selectInputType,
  detailsComponent,
  startStopAction: startAction,
  getDeviceById = noop,
  onSelect,
  onNameChange,
}: Props) {
  const containerRef = useRef<any>();

  const [openedTouched, setOpenedTouched] = useState(false);
  const [opened, setOpened] = useState(false);

  const activeScrollTo = useScrollToAnchor({
    ref: containerRef,
    name: `streamingDestination=${id}`,
    // rework prop below isn't used
    // offsetTop: 20,
  });

  const handleClickToggleDetails = () => {
    setOpenedTouched(true);
    setOpened(!isOpened());
  };

  const online = !offline;

  const getStatus = useCallback(() => {
    if (hasError) {
      return DEVICE_START_STOP_BUTTON_STATUS.ERROR;
    }

    if (online) {
      if (streaming) {
        return DEVICE_START_STOP_BUTTON_STATUS.STARTED;
      }

      if (started) {
        return DEVICE_START_STOP_BUTTON_STATUS.LOADING;
      }
    }

    return DEVICE_START_STOP_BUTTON_STATUS.STOPPED;
  }, [hasError, online, streaming, started]);

  function isOpened() {
    if (!detailsComponent) {
      return false;
    }

    return openedTouched ? opened : activeScrollTo;
  }

  const detailsOpened = isOpened();

  const selectedOnAnotherDevice = isNil(selectedOnDeviceId)
    ? false
    : selectedOnDeviceId !== deviceId;

  const streamingOnAnotherDevice = selectedOnAnotherDevice && streaming;

  const currentDevice = getDeviceById(deviceId);
  const device = getDeviceById(selectedOnDeviceId);

  return (
    <ListItem
      ref={containerRef}
      className={classNames(
        {
          'device-streaming-destination-list-item--started-on-another-device':
            streamingOnAnotherDevice,
          'device-streaming-destination-list-item--details-opened': detailsOpened,
        },
        className,
      )}
      selected={selected}
      disabled={disabled || offline}
    >
      <div className="device-streaming-destination-list-item__content">
        <SelectInput
          id={id}
          type={selectInputType}
          disabled={disabled || offline}
          disabledReasonMessage={disabledReasonMessage}
          started={started}
          selected={selected}
          loading={selecting}
          onSelect={onSelect}
        />

        {icon && <div className="device-streaming-destination-list-item__icon">{icon}</div>}

        <Name
          name={name}
          started={started}
          disabled={streamingOnAnotherDevice}
          onChange={onNameChange}
        />

        {warnings.length > 0 && (
          <WarningsIndicator
            className="device-streaming-destination-list-item__warnings"
            data-id="streaming_destination_warning_indicator"
            warnings={warnings}
            showFirstMessage={streaming === false}
          />
        )}

        {streamingOnAnotherDevice ? (
          <StreamingFromDeviceNote
            className="device-streaming-destination-list-item__streaming-on"
            currentDevice={currentDevice}
            device={device}
          />
        ) : (
          <Fragment>
            {streaming && online && (
              <StartedAtNote
                className="device-streaming-destination-list-item__started-at"
                dataId="streaming_destination_started_at"
                theme={THEME.INFO}
                timestamp={startTime}
              />
            )}

            {selectedOnAnotherDevice && (
              <AlreadySelectedOnDeviceNote
                className="device-streaming-destination-list-item__selected-on"
                currentDevice={currentDevice}
                device={device}
              />
            )}

            <StreamingButton
              className="device-streaming-destination-list-item__action-button"
              startStopAction={startAction}
              disabled={selected !== true || selecting || offline}
              stoppedLabel="Start"
              startTime={startTime}
              statusGetter={getStatus}
            />
          </Fragment>
        )}

        {detailsComponent && (
          <DetailsToggler
            className="device-streaming-destination-list-item__details-toggler"
            data-id="streaming_destination_details_toggler"
            size={SIZE.S}
            opened={detailsOpened}
            onClick={handleClickToggleDetails}
          />
        )}
      </div>

      {detailsOpened && (
        <div className="device-streaming-destination-list-item__details">{detailsComponent}</div>
      )}
    </ListItem>
  );
}
