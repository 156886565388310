import {BaseProvider} from 'app/util/OAuthProvider/BaseProvider';
import {OAuthProviderOptions} from 'app/util/OAuthProvider/types';

class FacebookProvider extends BaseProvider {
  constructor(options: OAuthProviderOptions) {
    super(options);

    this.name = 'Facebook';
    this.windowWidth = 600;
    this.windowHeight = 679;
  }
}

export {FacebookProvider};
