import {useCallback, useEffect, useRef, useState} from 'react';
import {FilterStoreManager} from 'app/components/FleetManager/FilterStoreManager';
import {Schedule} from 'app/domain/schedule';
import {isCompleted, isOngoing, isScheduled, isSkipped} from 'app/domain/schedule/utils';
import {filterByName, getActiveFilters} from 'app/components/sharedReactComponents/Events/utils';
import {toggleInSet} from 'app/util/toggleInSet';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {containsSearchString} from 'app/components/FleetManager/utils';
import {filterItemsByFilterSwitches} from 'app/components/sharedReactComponents/FilterSelector/utils';
import {FilterSwitchGroup} from 'app/components/sharedReactComponents/FilterSelector/types';
import {
  ScheduleFilter,
  ScheduleFilterSwitch,
} from 'app/components/sharedReactComponents/Events/types';
import {useDebounce} from 'react-use';

type Args = {
  teamId: string;
  userId: string;
  storeKey: string;
  events: Schedule.Event[];
  devices?: Map<string, PearlMasterDeviceModel>;
};

type Return = {
  filtered: Schedule.Event[];
  search: string;
  filters: ScheduleFilterSwitch[];
  activeFilters: ScheduleFilterSwitch[];
  activeSet: Set<string>;
  setSearch: (value: string) => void;
  toggleFilter: (id: string) => void;
  clearFilters: () => void;
};

const STORE_KEY = 'Schedule.Filters';

export function useScheduleFilters({teamId, userId, storeKey, events, devices}: Args): Return {
  const [filtered, setFiltered] = useState<Schedule.Event[]>(events);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<ScheduleFilterSwitch[]>([]);
  const [activeFilters, setActiveFilters] = useState<ScheduleFilterSwitch[]>([]);

  const [activeSet, setActiveSet] = useState<Set<string>>(new Set());

  const storeManager = useRef<FilterStoreManager>();

  useEffect(() => {
    storeManager.current = new FilterStoreManager({
      teamId,
      userId,
      storeKey: `${STORE_KEY}.${storeKey}`,
    });

    const storedSwitches = storeManager.current.loadActiveFiltersSet();
    setActiveSet(storedSwitches);
  }, [teamId, userId, storeKey]);

  useEffect(() => {
    const newFilters = [
      createTypeFilter({
        id: 'vod',
        label: 'VOD',
        callback: (event: Schedule.Event) => event.media === 'recording',
      }),
      createTypeFilter({
        id: 'stream',
        label: 'Live stream',
        callback: (event: Schedule.Event) => event.media === 'streaming',
      }),
      createTypeFilter({
        id: 'all',
        label: 'VOD + Live stream',
        callback: (event: Schedule.Event) => event.media === 'all',
      }),
      createStatusFilter({
        id: 'ongoing',
        label: 'Ongoing',
        callback: (event: Schedule.Event) => isOngoing(event.status),
      }),
      createStatusFilter({
        id: 'scheduled',
        label: 'Scheduled',
        callback: (event: Schedule.Event) => isScheduled(event.status),
      }),
      createStatusFilter({
        id: 'completed',
        label: 'Completed',
        callback: (event: Schedule.Event) => isCompleted(event.status),
      }),
      createStatusFilter({
        id: 'skipped',
        label: 'Skipped',
        callback: (event: Schedule.Event) => isSkipped(event.status),
      }),
    ];

    setFilters(newFilters);
  }, []);

  useEffect(() => {
    storeManager.current?.saveActiveFiltersSet(activeSet);
  }, [activeSet]);

  useEffect(() => {
    setActiveFilters(getActiveFilters(filters, activeSet));
  }, [activeSet, filters]);

  const trimmedSearch = search.trim();

  useDebounce(
    () => {
      const hasSearch = trimmedSearch.length > 0;
      const hasFilters = activeFilters.length > 0;

      let result = [...events];

      if (hasSearch) {
        const filteredUnits = (devices ? [...devices.values()] : [])
          .filter((d) => containsSearchString(d.getName(), search))
          .map((d) => d.getId());

        const filterByDevice = (event: Schedule.Event) => {
          return filteredUnits.some((deviceId) => event.devices.has(deviceId));
        };

        result = result.filter((e) => filterByName(e, search) || filterByDevice(e));
      }

      if (hasFilters) {
        result = filterItemsByFilterSwitches(result, activeFilters);
      }

      setFiltered(result);
    },
    300,
    [trimmedSearch, activeFilters, events],
  );

  const clearFilters = useCallback(() => {
    setActiveSet(new Set());
  }, []);

  const toggleFilter = useCallback((id: string) => {
    setActiveSet((prev) => {
      const copied = new Set(prev);
      return toggleInSet(copied, id);
    });
  }, []);

  return {
    filtered,
    search,
    filters,
    activeFilters,
    activeSet,
    clearFilters,
    toggleFilter,
    setSearch,
  };
}

function createTypeFilter(props: ScheduleFilterSwitch): ScheduleFilterSwitch {
  return {
    ...props,
    group: 'type',
  };
}

function createStatusFilter(props: ScheduleFilterSwitch): ScheduleFilterSwitch {
  return {
    ...props,
    group: 'status',
  };
}

export const filterGroups: Array<FilterSwitchGroup<ScheduleFilter>> = [
  {
    id: 'type',
    label: 'Event type',
  },
  {
    id: 'status',
    label: 'Event status',
  },
];
