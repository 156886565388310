import React, {useCallback, useState} from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Icons} from 'app/util/icons';
import {SIZE, VARIANT} from 'app/constants';
import {Callback, ClassName} from 'app/types/common';

enum ModalComponentScroll {
  Body = 'body',
  Paper = 'paper',
}

const ModalCloseButton: React.VFC<{onClose: Callback}> = ({onClose}) => {
  return (
    <Button
      className="cr-modal-new__close-button"
      dataId="modal_close_cross_button"
      variant={VARIANT.TEXT}
      onClick={onClose}
    >
      {Icons.close().reactComponent()}
    </Button>
  );
};

interface Props extends ClassName {
  fullWidth?: boolean;
  maxWidth?: SIZE;
  scroll?: ModalComponentScroll;
  showCloseButton?: boolean;
  shouldCloseOnEsc: boolean;
  shouldCloseOnOverlayClick: boolean;
  onClose: Callback;
}

const ModalComponent: React.FC<Props> = ({
  className,
  children,
  scroll = ModalComponentScroll.Body,
  fullWidth = false,
  maxWidth = SIZE.M,
  showCloseButton = true,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  onClose,
}) => {
  const [opened, setOpened] = useState(true);

  const handleRequestClose = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <ReactModal
      className={classNames('cr-modal-new__content', className)}
      portalClassName={classNames(
        'cr-modal-new',
        fullWidth && 'cr-modal-new--full-width',
        `cr-modal-new--max-width-${maxWidth}`,
        `cr-modal-new--scroll-${scroll}`
      )}
      overlayClassName="cr-modal-new__overlay"
      bodyOpenClassName="cr-modal-new__body--opened"
      data={{
        id: 'modal_content',
      }}
      isOpen={opened}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      ariaHideApp={false}
      onRequestClose={handleRequestClose}
      onAfterClose={onClose}
    >
      {children}

      {showCloseButton && (
        <ModalCloseButton onClose={handleRequestClose}/>
      )}
    </ReactModal>
  );
};

export {
  ModalComponent,
  ModalComponentScroll,
};
