import React, {useCallback, useRef, useState} from 'react';
import classNames from 'classnames';

// qr.js doesn't handle error level of zero (M) so we need to do it right,
// thus the deep require.
import QRCodeImpl from 'qr.js/lib/QRCode';
import ErrorCorrectLevel from 'qr.js/lib/ErrorCorrectLevel';

import {QR_CODE_LEVEL} from 'app/components/sharedReactComponents/QRCode/constants';
import {convertStr, excavateModules, getImageSettings} from 'app/components/sharedReactComponents/QRCode/utils';
import {isNil} from 'app/util/isNil';
import {Canvas} from 'app/components/sharedReactComponents/Canvas';
import {ClassName} from 'app/types/common';
import {Image, ImageParams} from 'app/components/sharedReactComponents/QRCode/types';

// See: https://github.com/zpao/qrcode.react
interface Props extends ClassName, Omit<ImageParams, 'size'> {
  canvasRef?: any;
  value: string;
  level?: QR_CODE_LEVEL;
  bgColor?: string;
  fgColor?: string;
  imageSettings?: Image;
}

export const QRCode: React.VFC<Props> = ({
  className,
  canvasRef,
  value,
  level = QR_CODE_LEVEL.Q,
  bgColor = '#ffffff',
  fgColor = '#000000',
  includeMargin = false,
  imageSettings = {
    src: '/assets/img/qr-code/qr-epiphan-logo.png',
    width: 16,
    height: 16,
    excavate: true,
  },
  ...elementProps
}) => {
  const imageRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = useCallback(() => {
    setImageLoaded(true);
  }, []);

  // We'll use type===-1 to force QRCode to automatically pick the best type
  const qrcode = new QRCodeImpl(-1, ErrorCorrectLevel[level]);
  qrcode.addData(convertStr(value));
  qrcode.make();

  let cells = qrcode.modules;

  if (cells === null) {
    return null;
  }

  const numCells = cells.length;
  const cellSize = 2;
  const size = numCells * cellSize;

  // rework underscore
  function draw(context: any, _width: number, _height: number) {
    const calculatedImageSettings = getImageSettings({size, includeMargin, imageSettings}, cells);

    if (!isNil(imageSettings) && !isNil(calculatedImageSettings)) {
      if (!isNil(calculatedImageSettings?.excavation)) {
        cells = excavateModules(cells, calculatedImageSettings.excavation);
      }
    }

    // Draw solid background, only paint dark modules.
    context.fillStyle = bgColor;
    context.fillRect(0, 0, numCells * cellSize, numCells * cellSize);

    context.fillStyle = fgColor;
    cells.forEach((row, rdx) => {
      row.forEach((cell, cdx) => {
        if (cell) {
          context.fillRect(cdx * cellSize, rdx * cellSize, cellSize, cellSize);
        }
      });
    });

    if (
      imageLoaded &&
      !isNil(calculatedImageSettings)
    ) {
      context.drawImage(
        imageRef.current,
        calculatedImageSettings.x * cellSize,
        calculatedImageSettings.y * cellSize,
        calculatedImageSettings.w * cellSize,
        calculatedImageSettings.h * cellSize
      );
    }
  }

  return (
    <div
      className={classNames('', className)}
      {...elementProps}
    >
      <Canvas
        ref={canvasRef}
        width={size}
        height={size}
      >
        {(context, width: number, height: number) => draw(context, width, height)}
      </Canvas>

      {imageSettings && (
        <img
          ref={imageRef}
          alt=""
          src={imageSettings.src}
          style={{display: 'none'}}
          onLoad={handleImageLoad}
        />
      )}
    </div>
  );
};
