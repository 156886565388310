import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {Box, Stack, Typography} from '@mui/material';
import {router} from 'app/router/main';
import {useCurrentTeamStore} from 'app/store/hooks';
import {DevicesAlertsCounter} from 'app/components/DevicesAlerts/DevicesAlertsCounter';
import {PageHeader} from 'app/components/sharedReactComponents/PageHeader';
import {useTeamTenants} from 'app/hooks/useTenantsQuery';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {useTeamsConnectionUrlHandlers} from 'app/hooks/Meeting/useTeamsConnectionUrlHandlers';
import {TABS_THEME, Tabs} from 'app/components/sharedReactComponents/Tabs/Tabs';
import {Tab} from 'app/components/sharedReactComponents/Tabs/Tab';
import {ChangeEvent} from 'app/types/common';
import {TabPanels} from 'app/components/sharedReactComponents/Tabs/TabPanels';
import {TabPanel} from 'app/components/sharedReactComponents/Tabs/TabPanel';
import TeamsAccounts from 'app/components/Settings/ConnectSettings/TeamsTenants/TeamsAccounts';
import {ZoomAccounts} from 'app/components/Settings/ConnectSettings/ZoomAccounts/ZoomAccounts';
import {useZoomAccounts} from 'app/hooks/useZoomAccounts';
import {useCloudEntities} from 'app/components/entities/cloud';
import {useConnectIntegration} from 'app/components/ConnectPlatformsIntegration/ConnectPlatformsIntegration';

interface Props {
  error?: string;
  state?: string;
  tab?: string;
}

type ProductTab = 'zoom' | 'teams';

function isTabType(t: string): t is ProductTab {
  const tab = t as ProductTab;
  return tab === 'teams' || tab === 'zoom';
}

function getTab(tab?: string): ProductTab {
  if (!tab || !isTabType(tab)) {
    return 'teams';
  }

  return tab;
}

const ConnectAccountsRoot = ({error, state, tab}: Props) => {
  const currentTeamStore = useCurrentTeamStore();
  const {user} = useCloudEntities();

  const teamId = currentTeamStore.id;
  const teamName = currentTeamStore.name;

  const [activeTab, setActiveTab] = useState<ProductTab>(getTab(tab));

  const teamsQuery = useTeamTenants({enabled: true, teamId});
  const zoomQuery = useZoomAccounts({enabled: true, teamId});

  useEffect(() => {
    if (!tab) {
      router.go(undefined, undefined, {tab: 'teams'});
    }

    setActiveTab(getTab(tab));
  }, [tab]);

  const {startTeams, startZoom} = useConnectIntegration();

  useTeamsConnectionUrlHandlers({
    error,
    state,
    teamName,
  });

  const handleTeamsPair = () => {
    const returnUrl = `/${teamId}${router.url('connectAccountSettings')}`;
    startTeams(returnUrl);
  };

  const handleZoomPair = () => {
    const returnUrl = `/${teamId}${router.url('connectAccountSettings', undefined, {tab: 'zoom'})}`;
    startZoom(returnUrl);
  };

  const loading = teamsQuery.isInitialLoading || zoomQuery.isInitialLoading;

  const canManage = user.role.canPairConnectAuth();

  const handleTabChange = ({target}: ChangeEvent<ProductTab>) => {
    router.go(undefined, undefined, {tab: target.value});
  };

  return (
    <Stack flexGrow={1}>
      <PageHeader
        buttons={
          <>
            <DevicesAlertsCounter />
          </>
        }
      />

      {loading ? (
        <IndeterminateProgressBar />
      ) : (
        <Stack sx={{py: 3, flexGrow: 1}} className="container">
          <Typography mb={2} variant="h5" fontWeight="bold">
            Epiphan Connect settings
          </Typography>

          <Tabs value={activeTab} theme={TABS_THEME.SETTINGS} onChange={handleTabChange}>
            <Tab label="Microsoft Teams" value="teams" />
            <Tab label="Zoom" value="zoom" />
          </Tabs>

          <TabPanels value={activeTab}>
            <TabPanel value="teams">
              <TeamsAccounts
                tenants={teamsQuery.data ?? []}
                canManage={canManage}
                reload={teamsQuery.refetch}
                onPair={handleTeamsPair}
              />
            </TabPanel>

            <TabPanel value="zoom">
              <ZoomAccounts
                userId={user.id}
                teamName={teamName}
                accounts={zoomQuery.data ?? []}
                canManage={canManage}
                reload={zoomQuery.refetch}
                onPair={handleZoomPair}
              />
            </TabPanel>
          </TabPanels>

          <Box sx={{mt: 'auto'}}>
            <Typography sx={{mb: 0.5, fontWeight: 'bold'}}>Support</Typography>
            Need help? Check out our <SupportLink>user guide.</SupportLink>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export const ConnectAccounts = observer(ConnectAccountsRoot);

function SupportLink({children}: React.PropsWithChildren<{}>) {
  return (
    <a
      href="https://www.epiphan.com/userguides/connect/Content/Connect/Connect-QuickStart-Guide.htm"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}
