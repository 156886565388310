import {noop} from 'app/util/noop';
import {secondsToMilliseconds} from 'app/util/timeConverter';

// Events: https://support.google.com/analytics/answer/1033068
// Custom dimensions & metrics: https://support.google.com/analytics/answer/2709828
// Field Reference: https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference

const GOOGLE_ANALYTICS_LOADED_CHECK_TIMEOUT = secondsToMilliseconds(10);

const getGoogleAnalyticsObjectName = () => window.GoogleAnalyticsObject || 'ga';
const getGoogleAnalyticsObject = () => window[getGoogleAnalyticsObjectName()];

class AnalyticsTracker {
  protected router;
  private path = null;

  constructor({router}) {
    this.router = router;

    this.handleRouterChange = this.handleRouterChange.bind(this);

    this.initialize();
  }

  private initialize() {
    // See: https://developers.google.com/analytics/devguides/collection/analyticsjs/tracking-snippet-reference
    // See: https://github.com/googleanalytics/autotrack
    const gaAlias = getGoogleAnalyticsObjectName();
    window[gaAlias] = window[gaAlias] || function (...args) {
      return (window[gaAlias].q = window[gaAlias].q || []).push(args);
    };

    this.router.onChange(this.handleRouterChange);
    this.trackCurrentPage();

    this.unmountIfDisabled();
  }

  // Stop tracking if Google Analytics is disabled (development environment for example)
  private unmountIfDisabled() {
    setTimeout(() => {
      if (getGoogleAnalyticsObject().loaded) {
        return;
      }

      this.router.offChange(this.handleRouterChange);

      this.viewPage = noop;
    }, GOOGLE_ANALYTICS_LOADED_CHECK_TIMEOUT);
  }

  private handleRouterChange() {
    this.trackCurrentPage();
  }

  private trackCurrentPage() {
    this.viewPage(this.router.state.name);
  }

  private viewPage(pageName) {
    if (pageName === this.path) {
      return;
    }

    this.path = pageName;

    getGoogleAnalyticsObject()('set', 'page', pageName);
    getGoogleAnalyticsObject()('send', 'pageview');
  }
}

export {AnalyticsTracker};
