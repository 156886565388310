import {useEffect, useRef, useState} from 'react';
import {
  getNowPosition,
  showNowLine,
  buildDayInterval,
} from 'app/components/sharedReactComponents/Events/Calendar/utils';
import {isNil} from 'app/util/isNil';
import {Schedule} from 'app/domain/schedule';

const UPDATE_EVERY_MS = 10000;

interface Args {
  period: Schedule.Period;
  range: TimeRange;
  selectedDay: number;
}

interface Return {
  enabled: boolean;
  position: number;
}

export function useTodayPosition({period, range, selectedDay}: Args): Return {
  const [position, setPosition] = useState(0);
  const [enabled, setEnabled] = useState(false);

  const raf = useRef<number>(0);

  useEffect(() => {
    let lastCallTimestamp: number | undefined;

    const step = (t: number) => {
      if (isNil(lastCallTimestamp) || t - lastCallTimestamp > UPDATE_EVERY_MS) {
        lastCallTimestamp = t;

        const [offset, isOut] = calc(period, range, selectedDay);
        setPosition(offset);
        setEnabled(!isOut);

        if (isOut && raf.current !== 0) {
          cancelAnimationFrame(raf.current);
          raf.current = 0;
          return;
        }
      }

      raf.current = requestAnimationFrame(step);
    };

    raf.current = requestAnimationFrame(step);

    return () => {
      cancelAnimationFrame(raf.current);
      raf.current = 0;
    };
  }, [period, selectedDay, range]);

  return {
    position,
    enabled,
  };
}

function calc(
  period: Schedule.Period,
  range: TimeRange,
  selected: TimeStampSeconds,
): [offset: number, isOut: boolean] {
  if (showNowLine(period, range, selected)) {
    const borders = buildDayInterval(range);
    const offset = getNowPosition(borders.from);
    return [offset, false];
  }

  return [0, true];
}
