import {
  computed,
  makeObservable,
} from 'mobx';
import {ValidatedInputModel} from 'app/components/UploadLocations/Models/ValidatedInputModel';
import {STORAGE_PROP_NAME} from 'app/components/UploadLocations/constants';
import {Callback} from 'app/types/common';

interface Params {
  Name: string;
  StorageID: string;
  ExternalID: string;
  Service: string;
  Type: string;
}

export class OAuth2LocationModel {
  id: string;
  name: ValidatedInputModel;
  externalId: ValidatedInputModel;
  service: ValidatedInputModel;
  type: string;
  save: Callback;

  constructor({
    Name = '',
    StorageID = '',
    ExternalID = '',
    Service = '',
    Type = '',
  }: Params, saveLocationApi: Callback) {
    this.id = StorageID;
    this.name = new ValidatedInputModel('name', Name);
    this.externalId = new ValidatedInputModel('externalId', ExternalID);
    this.service = new ValidatedInputModel('service', Service);
    this.type = Type;

    this.save = () => saveLocationApi(this.id, this.json);

    makeObservable(this, {
      json: computed
    });
  }

  get json() {
    return {
      [STORAGE_PROP_NAME.OAUTH2]: {
        ExternalID: this.externalId.value,
        Service: this.service.value,
      },
      StorageID: this.id,
      Name: this.name.value,
      Type: this.type,
    };
  }
}
