import React from 'react';
import classNames from 'classnames';
import {SettingsListItem} from 'app/components/sharedReactComponents/SettingsList/SettingsListItem';
import {SettingsListGroup} from 'app/components/sharedReactComponents/SettingsList/SettingsListGroup';
import {AudioSourceSettingsItem} from 'app/components/sharedReactComponents/SettingsList/AudioSourceSettingsItem';
import {StreamURLSettingsItem} from 'app/components/sharedReactComponents/SettingsList/StreamURLSettingsItem';
import {
  defaultGetSettingName,
  defaultGetSettingLabel,
  defaultGetSettingValue,
  defaultGetSettingDescription,
  defaultGetSettingTitle,
  defaultGetSettingType,
  defaultGetSettingTypeOptions,
  defaultGetSettingGroup,
} from 'app/components/sharedReactComponents/SettingsList/utils';
import {DeviceSettingType} from 'app/models/Device/Settings/constants';
import {DROPDOWN_SELECT_THEME} from 'app/components/sharedReactComponents/DropdownSelect/constants';
import {SETTINGS_LIST_THEME} from 'app/components/sharedReactComponents/SettingsList/constants';
import {Callback, ClassName} from 'app/types/common';
import {SettingDevices} from 'app/components/sharedReactComponents/SettingsList/types';

interface Props extends ClassName {
  settings: any[];
  theme?: SETTINGS_LIST_THEME;
  selectTheme?: DROPDOWN_SELECT_THEME;
  devices?: SettingDevices;
  disabled: boolean;

  updateSettingAction: Callback;

  getSettingName?: Callback;
  getSettingLabel?: Callback;
  getSettingValue?: Callback;
  getSettingTitle?: Callback;
  getSettingDescription?: Callback;
  getSettingType?: Callback;
  getSettingTypeOptions?: Callback;
  getSettingGroup?: Callback;
}

export const SettingsList: React.VFC<Props> = ({
  className,
  settings,
  theme = SETTINGS_LIST_THEME.DEFAULT,
  selectTheme,
  devices,
  disabled,
  updateSettingAction,

  getSettingName = defaultGetSettingName,
  getSettingLabel = defaultGetSettingLabel,
  getSettingValue = defaultGetSettingValue,
  getSettingTitle = defaultGetSettingTitle,
  getSettingDescription = defaultGetSettingDescription,
  getSettingType = defaultGetSettingType,
  getSettingTypeOptions = defaultGetSettingTypeOptions,
  getSettingGroup = defaultGetSettingGroup,
}) => {
  selectTheme = theme === SETTINGS_LIST_THEME.DEVICE_SETTINGS ? DROPDOWN_SELECT_THEME.DEFAULT : selectTheme;

  return (
    <div className={classNames('settings-list', `settings-list--theme-${theme}`, className)}>
      {settings.map(setting => {
        const type = getSettingType(setting);
        const id = getSettingName(setting);
        const label = getSettingLabel(setting);
        const value = getSettingValue(setting);
        const description = getSettingDescription(setting);
        const settingTypeOptions = getSettingTypeOptions(setting);

        if (type !== DeviceSettingType.Group) {
          if (type === DeviceSettingType.AudioSource && devices) {
            return (
              <AudioSourceSettingsItem
                key={id}
                source={id}
                channels={settingTypeOptions.channels}
                label={label}
                settings={getSettingGroup(setting)}
                disabled={disabled}
                selectTheme={selectTheme}
                devices={devices}
                updateAction={updateSettingAction}
              />
            );
          }

          if (type === DeviceSettingType.StreamUrl) {
            return (
              <StreamURLSettingsItem
                key={id}
                name={id}
                label={label}
                value={value}
                baseURL={setting.type.options.base}
                disabled={disabled}
                updateAction={updateSettingAction}
              />
            );
          }

          return (
            <SettingsListItem
              key={id}
              disabled={disabled}
              name={id}
              label={label}
              value={value}
              description={description}
              type={type}
              options={settingTypeOptions}
              selectTheme={selectTheme}
              getSettingTitle={getSettingTitle}
              updateAction={updateSettingAction}
            />
          );
        }

        return (
          <SettingsListGroup
            key={id}
            disabled={disabled}
            setting={setting}
            theme={theme}
            selectTheme={selectTheme}
            devices={devices}
            updateSettingAction={updateSettingAction}
            getSettingName={getSettingName}
            getSettingLabel={getSettingLabel}
            getSettingDescription={getSettingDescription}
            getSettingValue={getSettingValue}
            getSettingTitle={getSettingTitle}
            getSettingType={getSettingType}
            getSettingTypeOptions={getSettingTypeOptions}
            getSettingGroup={getSettingGroup}
          />
        );
      })}
    </div>
  );
};
