import EventEmitter from 'eventemitter3';
import {front} from 'app/api/CirrusApi';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {
  checkEventsRegister,
  createDeviceALSEventName,
  createDeviceChannelImageEventName,
  createDeviceSourceImageEventName,
  createDeviceChangeEventName,
} from 'app/api/WebSocket/utils';
import {secondsToMilliseconds} from 'app/util/timeConverter';
import {Callback} from 'app/types/common';
import {
  WSDeviceAlsDto,
  WSDeviceChangeDto,
  WSDeviceChannelImageDto,
  WSDeviceSourceImageDto,
  WSMeetingChangedDto,
  WSParticipantUpdated,
  WSReturnFeedUpdated,
  WSTeamPresetChangedDto,
  WSTeamsCallUpdated,
} from 'app/api/WebSocket/types';
import {parseMasterAndChannelIds} from 'app/components/DeviceDetails/utils';
import {Ws} from 'app/contracts/ws';

/**
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent#Status_codes}
 */
enum WebsocketCloseCodeNumber {
  NormalClosure = 1000,
}

/**
 * {@link https://github.com/websockets/ws/wiki/Websocket-client-implementation-for-auto-reconnect}
 */
class WS {
  private ws: WebSocket | null = null;
  private readonly emitter: EventEmitter = new EventEmitter();

  private readonly reconnectInitialTimeoutMs: number = secondsToMilliseconds(1);
  private readonly reconnectMaxTimeoutMs: number = secondsToMilliseconds(5);
  private reconnectTimeoutMs: number = this.reconnectInitialTimeoutMs;

  private reconnectTimeout;

  constructor() {
    this.onDeviceChannelImage = this.onDeviceChannelImage.bind(this);
    this.offDeviceChannelImage = this.offDeviceChannelImage.bind(this);
    this.wsOnClose = this.wsOnClose.bind(this);
  }

  on(type: string, listener: Callback) {
    if (this.ws === null) {
      this.init();
    }

    this.emitter.on(type, listener);
  }

  off(type: string, listener: Callback) {
    this.emitter.removeListener(type, listener);

    if (this.emitter.eventNames().length === 0) {
      this.reset();
    }
  }

  onDeviceALS(listener: Callback<void, [WSDeviceAlsDto]>, deviceId: string) {
    this.on(createDeviceALSEventName(deviceId), listener);
  }

  offDeviceALS(listener: Callback<void, [WSDeviceAlsDto]>, deviceId: string) {
    this.off(createDeviceALSEventName(deviceId), listener);
  }

  onDeviceChannelImage(
    listener: Callback<void, [WSDeviceChannelImageDto]>,
    channelDeviceId: string,
  ) {
    this.on(createDeviceChannelImageEventName(channelDeviceId), listener);
  }

  offDeviceChannelImage(
    listener: Callback<void, [WSDeviceChannelImageDto]>,
    channelDeviceId: string,
  ) {
    this.off(createDeviceChannelImageEventName(channelDeviceId), listener);
  }

  onDeviceSourceImage(
    listener: Callback<void, [WSDeviceSourceImageDto]>,
    deviceId: string,
    sourceId: string,
  ) {
    this.on(createDeviceSourceImageEventName(deviceId, sourceId), listener);
  }

  offDeviceSourceImage(
    listener: Callback<void, [WSDeviceSourceImageDto]>,
    deviceId: string,
    sourceId: string,
  ) {
    this.off(createDeviceSourceImageEventName(deviceId, sourceId), listener);
  }

  onDeviceGroupChange(listener: (message: Ws.GroupChange) => void) {
    this.on(EVENT_TYPE.DEVICE_GROUP_CHANGE, listener);
  }

  offDeviceGroupChange(listener: (message: Ws.GroupChange) => void) {
    this.off(EVENT_TYPE.DEVICE_GROUP_CHANGE, listener);
  }

  onDeviceChange(listener: Callback<void, [WSDeviceChangeDto]>, deviceId: string) {
    this.on(createDeviceChangeEventName(deviceId), listener);
  }

  offDeviceChange(listener: Callback<void, [WSDeviceChangeDto]>, deviceId: string) {
    this.off(createDeviceChangeEventName(deviceId), listener);
  }

  onTeamPresetChange(listener: Callback<void, [WSTeamPresetChangedDto]>) {
    this.on(EVENT_TYPE.TEAM_PRESET_CHANGED, listener);
  }

  offTeamPresetChange(listener: Callback<void, [WSTeamPresetChangedDto]>) {
    this.off(EVENT_TYPE.TEAM_PRESET_CHANGED, listener);
  }

  onMeetingChange(listener: Callback<void, [WSMeetingChangedDto]>) {
    this.on(EVENT_TYPE.MEETING_CHANGED, listener);
  }

  offMeetingChange(listener: Callback<void, [WSMeetingChangedDto]>) {
    this.off(EVENT_TYPE.MEETING_CHANGED, listener);
  }

  onParticipantUpdate(listener: Callback<void, [WSParticipantUpdated]>) {
    this.on(EVENT_TYPE.PARTICIPANT_UPDATED, listener);
  }

  offParticipantUpdate(listener: Callback<void, [WSParticipantUpdated]>) {
    this.off(EVENT_TYPE.PARTICIPANT_UPDATED, listener);
  }

  onReturnFeedUpdate(listener: Callback<void, [WSReturnFeedUpdated]>) {
    this.on(EVENT_TYPE.RETURN_FEED_UPDATED, listener);
  }

  offReturnFeedUpdate(listener: Callback<void, [WSReturnFeedUpdated]>) {
    this.off(EVENT_TYPE.RETURN_FEED_UPDATED, listener);
  }

  onTeamsCallUpdate(listener: Callback<void, [WSTeamsCallUpdated]>) {
    this.on(EVENT_TYPE.TEAMS_CALL_UPDATED, listener);
  }

  offTeamsCallUpdate(listener: Callback<void, [WSTeamsCallUpdated]>) {
    this.off(EVENT_TYPE.TEAMS_CALL_UPDATED, listener);
  }

  reset() {
    if (this.ws === null) {
      return;
    }

    this.resetReconnectTimeout();
    this.emitter.removeAllListeners();
    this.ws.removeEventListener('close', this.wsOnClose);
    this.ws.close(WebsocketCloseCodeNumber.NormalClosure);
    this.ws = null;
  }

  private init() {
    this.ws = new WebSocket(front.ws().url());

    this.ws.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      const eventType = data.Kind;

      checkEventsRegister(eventType);

      if (eventType === EVENT_TYPE.DEVICE_ALS) {
        this.emitter.emit(createDeviceALSEventName(data.Body.DeviceID), data);
      } else if (eventType === EVENT_TYPE.DEVICE_CHANNEL_IMAGE) {
        this.emitter.emit(createDeviceChannelImageEventName(data.Body.DeviceID), data);
      } else if (eventType === EVENT_TYPE.DEVICE_SOURCE_IMAGE) {
        this.emitter.emit(
          createDeviceSourceImageEventName(data.Body.DeviceID, data.Body.SourceID),
          data,
        );
      } else if (eventType === EVENT_TYPE.DEVICE_CHANGE) {
        const [masterId] = parseMasterAndChannelIds(data.Body.DeviceID);
        this.emitter.emit(createDeviceChangeEventName(masterId), data);
        this.emitter.emit(eventType, data);
      } else {
        this.emitter.emit(eventType, data);
      }

      this.resetReconnectTimeout();
    });

    this.ws.addEventListener('close', this.wsOnClose);
  }

  private wsOnClose() {
    // Always try to reconnect the websocket
    this.reconnect();
  }

  private reconnect() {
    if (this.emitter.eventNames().length === 0) {
      return;
    }

    this.reconnectTimeout = setTimeout(() => this.init(), this.reconnectTimeoutMs);
    this.increaseTimeout();
  }

  private increaseTimeout() {
    this.reconnectTimeoutMs = Math.min(this.reconnectMaxTimeoutMs, this.reconnectTimeoutMs * 2);
  }

  private resetReconnectTimeout() {
    this.reconnectTimeoutMs = this.reconnectInitialTimeoutMs;
    window.clearTimeout(this.reconnectTimeout);
  }
}

const ws = new WS();

export {ws as WS};
