import React from 'react';
import {Sx} from 'app/types/common';
import {Divider, Stack, Typography} from '@mui/material';
import {FeatureBox} from 'app/components/BillingManager/dialogs/shared/FeatureBox/FeatureBox';

type Props = Sx & {
  list: App.Billing.Feature[];
};

export function FeaturesNames({sx, list}: Props) {
  return (
    <Stack sx={sx} pt={1} pr={2} divider={<Divider sx={{borderStyle: 'dashed'}} flexItem />}>
      {list.map((f) => (
        <FeatureBox key={f.id}>
          <Typography>{f.name}</Typography>
        </FeatureBox>
      ))}
    </Stack>
  );
}
