// TODO: Maybe refactor settings models to reduce this util functions

/**
 * @param {BaseDeviceSettingModel} setting
 * @return {*|undefined}
 */
function getDeviceSettingValue(setting) {
  return setting ? setting.getValue() : undefined;
}

/**
 * @param {BaseDeviceSettingModel} setting
 * @return {string|undefined}
 */
function getDeviceSettingValueFormatted(setting) {
  return setting ? setting.getValueFormatted() : undefined;
}

export {
  getDeviceSettingValue,
  getDeviceSettingValueFormatted,
};
