import React from 'react';
import {observer} from 'mobx-react';
import {Divider, Stack} from '@mui/material';
import {grey} from '@mui/material/colors';
import {DashboardListHeader} from 'app/components/Dashboard/DashboardListHeader';
import {MeetingsHeader} from 'app/components/MeetingsManager/MeetingsHeader/MeetingsHeader';
import {useSearch} from 'app/components/DeviceDetails/hooks/useSearch';
import {MeetingsList} from 'app/components/MeetingsManager/MeetingsList/MeetingsList';
import {MeetingsWelcome} from 'app/components/MeetingsManager/MeetingsWelcome/MeetingsWelcome';
import {useMeetings} from 'app/components/MeetingsManager/hooks/useMeetings';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {ReadyToConnect} from 'app/components/MeetingsManager/ReadyToConnect/ReadyToConnect';
import {useFilter} from 'app/components/MeetingsManager/hooks/useFilter';
import {useCurrentTeamStore} from 'app/store/hooks';
import {useTeamTenants} from 'app/hooks/useTenantsQuery';
import {useTeamsConnectionUrlHandlers} from 'app/hooks/Meeting/useTeamsConnectionUrlHandlers';
import {ConnectManager} from 'app/components/MeetingsManager/ConnectManager';
import {router} from 'app/router/main';
import {MoreDetails} from 'app/components/MeetingsManager/MoreDetails/MoreDetails';
import {useTeamBilling} from 'app/components/BillingManager/hooks/useTeamBilling';
import {SecureMessage} from 'app/components/MeetingsManager/SecureMessage/SecureMessage';
import {useConnectIntegration} from 'app/components/ConnectPlatformsIntegration/ConnectPlatformsIntegration';
import {useCloudEntities} from 'app/components/entities/cloud';
import {useZoomAccounts} from 'app/hooks/useZoomAccounts';

const Content = ({children}) => {
  return (
    <Stack className="meetings-manager__content">
      <Stack flexGrow={1} className="container">
        {children}
        <SecureMessage sx={{mt: 'auto', mb: 2}} />
      </Stack>
    </Stack>
  );
};

interface Props {
  error?: string;
  state?: string;
}

const MeetingsManagerComponent = ({error, state}: Props) => {
  const currentTeamStore = useCurrentTeamStore();
  const {user} = useCloudEntities();
  const teamId = currentTeamStore.id;

  const canSubscribe = user.role.canEditBilling();

  const enabled = currentTeamStore.getCapabilities().connect();
  const canManageAccounts = user.role.canPairConnectAuth();

  const {search, setSearch, clearSearch} = useSearch('');

  const teamsQuery = useTeamTenants({enabled, teamId});
  const zoomQuery = useZoomAccounts({enabled, teamId});
  const tenants = teamsQuery.data ?? [];
  const accounts = zoomQuery.data ?? [];

  const {items, callsQuery, meetingsQuery} = useMeetings({enabled});

  const filteredItems = useFilter(items, search);

  const {actions} = useTeamBilling();

  const teamName = currentTeamStore.name;
  const userId = user.id;

  useTeamsConnectionUrlHandlers({
    error,
    state,
    teamName,
  });

  const {startTeams} = useConnectIntegration();

  const handlePair = () => {
    const backUrl = `/${teamId}${router.url('meetings')}`;
    startTeams(backUrl);
  };

  if (
    callsQuery.isInitialLoading ||
    meetingsQuery.isInitialLoading ||
    teamsQuery.isInitialLoading ||
    zoomQuery.isInitialLoading
  ) {
    return (
      <>
        <DashboardListHeader
          showSearch={enabled}
          filterValue={search}
          buttonsComponent={null}
          onFilter={setSearch}
        />
        <IndeterminateProgressBar />
      </>
    );
  }

  const hasEntities = items.length > 0;
  const hasFilter = search.length > 0;

  const renderMainContent = () => (
    <>
      <DashboardListHeader
        showSearch={enabled}
        filterValue={search}
        buttonsComponent={null}
        onFilter={setSearch}
      />

      <Content>
        <MeetingsHeader
          sx={{mt: 3, mb: 2}}
          accounts={accounts}
          tenants={tenants}
          canEditTenants={canManageAccounts}
        />

        <Divider sx={{borderWidth: 2, borderColor: grey[100], mb: 5}} />

        {hasEntities ? (
          <MeetingsList
            className="meetings-manager__list"
            items={filteredItems}
            hasFilter={hasFilter}
            onClearFilter={clearSearch}
          />
        ) : (
          <>
            <ReadyToConnect />
            <MoreDetails sx={{my: 2}} />
          </>
        )}
      </Content>
    </>
  );

  const isAvailable = enabled;

  return (
    <ConnectManager
      teamId={teamId}
      userId={userId}
      canEdit={canManageAccounts}
      hasTenants={tenants.length > 0}
      reloadHosted={callsQuery.refetch}
      reloadMeetings={meetingsQuery.refetch}
      onPair={handlePair}
    >
      <div className="meetings-manager">
        {isAvailable ? (
          renderMainContent()
        ) : (
          <Content>
            <MeetingsWelcome
              sx={{mt: 2}}
              canSubscribe={canSubscribe}
              onManage={() => actions.manageConnect()}
            />
          </Content>
        )}
      </div>
    </ConnectManager>
  );
};

export const MeetingsManager = observer(MeetingsManagerComponent);
