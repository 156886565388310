import React from 'react';
import {SIZE} from 'app/constants';
import {Icons} from 'app/util/icons';

interface Props {
  message?: string;
}

export const InitializationPage = ({message}: Props) => {
  return (
    <div className="initialization-page">
      {Icons.spinner().size(SIZE.XL).reactComponent()} {message}
    </div>
  );
};
