import React, {useEffect, useRef, useState} from 'react';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {Controller, useForm} from 'react-hook-form';
import {Box, ButtonBase, TextField, Typography} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import {DataId, Sx} from 'app/types/common';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {packSx} from 'app/util/packSx/packSx';
import {isNil} from 'app/util/isNil';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';

const scheme = z.object({
  name: z.string().trim().min(1, {message: 'Name is required'}),
});

type Form = z.infer<typeof scheme>;

type EditableProps = Sx &
  DataId & {
    name: string;
    onSave: (name: string) => void;
  };

// TODO: to shared components
export function EditableName({sx, name, onSave}: EditableProps) {
  const [edit, setEdit] = useState(false);

  const [hovered, setHovered] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const {control, reset, handleSubmit} = useForm<Form>({
    defaultValues: {name},
    resolver: zodResolver(scheme),
    mode: 'onChange',
  });

  const handleCancel = () => {
    reset();
    setEdit(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      handleCancel();
    }
  };

  const handleSave = ({name}: Form) => {
    onSave(name);
    handleCancel();
  };

  const startEdit = () => {
    setEdit(true);
  };

  useEffect(() => {
    if (edit) {
      inputRef.current?.focus();
    }
  }, [edit]);

  if (edit) {
    return (
      <Box sx={sx} component="form" onSubmit={handleSubmit(handleSave)}>
        <Controller
          control={control}
          name="name"
          render={({field: {onBlur, ...rest}, fieldState: {error}}) => {
            const hasError = !isNil(error);

            return (
              <TextField
                {...rest}
                inputRef={inputRef}
                fullWidth={true}
                error={hasError}
                InputProps={{
                  endAdornment: hasError ? (
                    <Tooltip content={error.message}>
                      <WarningIcon color="error" fontSize="small" />
                    </Tooltip>
                  ) : null,
                }}
                inputProps={{'data-id': 'name-input'}}
                onKeyDown={handleKeyDown}
                onBlur={() => {
                  onBlur();
                  handleCancel();
                }}
              />
            );
          }}
        />
      </Box>
    );
  }

  return (
    <ButtonBase
      sx={packSx({display: 'flex', alignItems: 'center', gap: 0.5, minWidth: 0}, sx)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={startEdit}
    >
      <Typography component="span" flex={1} minWidth={0}>
        <OverflowTooltip rowsNumber={1} dataId="recording-name">
          {name}
        </OverflowTooltip>
      </Typography>

      <EditIcon
        data-id="edit-btn"
        sx={{visibility: hovered ? 'visible' : 'hidden'}}
        fontSize="small"
      />
    </ButtonBase>
  );
}

type ReadonlyProps = Sx & {
  name: string;
};

function ReadonlyName({sx, name}: ReadonlyProps) {
  return (
    <Typography sx={sx} component="span">
      <OverflowTooltip rowsNumber={1} dataId="recording-name">
        {name}
      </OverflowTooltip>
    </Typography>
  );
}

type Props = EditableProps & {
  editable: boolean;
};

export function RecordingName({sx, name, editable, onSave}: Props) {
  if (editable) {
    return <EditableName sx={sx} name={name} onSave={onSave} />;
  }

  return <ReadonlyName sx={sx} name={name} />;
}
