import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react';
import {Icons} from 'app/util/icons';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {
  THEME,
  VARIANT,
  SIZE,
} from 'app/constants';
import {OAuth2LocationModel} from 'app/components/UploadLocations/Models/OAuth2LocationModel';
import {StorageIcon} from 'app/components/UploadLocations/UploadLocationIcons/StorageIcon';
import {Callback} from 'app/types/common';

interface Props {
  name: string;
  model: OAuth2LocationModel;
  children: React.ReactNode;
  onClickReconnect: Callback;
}

function BaseStorageConfigurationComponent({
  name,
  model,
  children,
  onClickReconnect,
}: Props) {
  const [isSavingConfiguration, setIsSavingConfiguration] = useState(false);

  const handleClickReconnect = useCallback(() => {
    onClickReconnect(model.type, model.id);
  }, [model.id, model.type, onClickReconnect]);

  const handleClickSaveConfiguration = useCallback(() => {
    setIsSavingConfiguration(true);
    model.save().then(() => setIsSavingConfiguration(false));
  }, [model]);

  const renderTitle = () => (
    <h3 className="cloud-storage-configuration__title">
      <div className="cloud-storage-configuration__title-icon">
        {/** rework as any */}
        <StorageIcon type={model.type as any}/>
      </div>

      <div className="cloud-storage-configuration__title-text">
        {`${name} integration`}
      </div>
    </h3>
  );

  return (
    <div className="cloud-storage-configuration">
      {renderTitle()}

      <div className="cloud-storage-configuration__form">
        {children}
      </div>

      <div className="cloud-storage-configuration__form-footer">
        <button
          className="cr-btn-primary cloud-storage-configuration__reconnect-btn"
          type="button"
          onClick={handleClickReconnect}
        >
          {Icons.retry().size(SIZE.S).label('Reconnect').reactComponent()}
        </button>

        <ProgressButton
          className="cloud-storage-configuration__save-btn"
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          disabled={isSavingConfiguration}
          loading={isSavingConfiguration}
          onClick={handleClickSaveConfiguration}
        >
          {Icons.save().size(SIZE.S).label('Save').reactComponent()}
        </ProgressButton>
      </div>
    </div>
  );
}

const BaseStorageConfiguration = observer(BaseStorageConfigurationComponent);

export {BaseStorageConfiguration};
