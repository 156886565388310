import React, {useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';
import {useMounted} from 'app/hooks/useIsMounted';
import {grey} from '@mui/material/colors';
import {formatBillingPeriod, formatPrice} from 'app/components/entities/billing/base';
import {FilesBilling} from 'app/components/entities/billing/files';

interface Props extends Prompt {
  price: FilesBilling.Price;
  onConfirm: () => Promise<void>;
}

export function SubscribeDialog({open, price, onConfirm, onClose}: Props) {
  const [loading, setLoading] = useState(false);

  const mounted = useMounted();

  const period = formatBillingPeriod(price.periodConfig);

  const handleConfirm = async () => {
    setLoading(true);

    try {
      await onConfirm();
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle variant="h5" align="center" mb={3}>
        Subscribe to Epiphan Edge File management
      </DialogTitle>

      <DialogContent>
        <Typography textAlign="center" color="text.secondary" mb={2}>
          Epiphan Edge File Management includes {price.free.storage} GB of storage <br />
          and {price.free.transfer} GB of download of free usage {period}
          <br /> with additional usage billed {period}.
        </Typography>

        <Box px={5} py={4} bgcolor={grey[50]}>
          <Typography>How is additional usage calculated?</Typography>

          <Typography color="text.secondary">
            &bull; {formatPrice(price.rates.storage, 2)} per GB {period} for storage
          </Typography>

          <Typography color="text.secondary">
            &bull; {formatPrice(price.rates.transfer, 2)} per GB for download
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="subscribe-btn"
          variant="contained"
          color="secondary"
          loading={loading}
          onClick={handleConfirm}
        >
          Subscribe
        </LoadingButton>

        <Button
          data-id="cancel-btn"
          variant="outlined"
          color="info"
          disabled={loading}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
