import React from 'react';
import {Icons} from 'app/util/icons';
import {THEME, VARIANT, SIZE} from 'app/constants';
import {router} from 'app/router/main';
import {Button} from 'app/components/sharedReactComponents/Button';

interface Props {
  canEdit: boolean;
}

export const UpgradeToProTooltip = ({canEdit}: Props) => (
  <div className="upgrade-to-pro-tooltip">
    <div className="upgrade-to-pro-tooltip__title">
      Supercharge your experience and upgrade to Premium
    </div>

    <div className="upgrade-to-pro-tooltip__list-item">
      {Icons.batchForStreamingUpgradeToPro()
        .class('upgrade-to-pro-tooltip__icon')
        .size(SIZE.L)
        .reactComponent()}

      <div className="upgrade-to-pro-tooltip__description">
        Batch control streaming, recording and transcription, fleet maintenance, remote login and
        more.
      </div>
    </div>

    <div className="upgrade-to-pro-tooltip__list-item">
      {Icons.deviceGroupingUpgradeToPro()
        .class('upgrade-to-pro-tooltip__icon')
        .size(SIZE.L)
        .reactComponent()}

      <div className="upgrade-to-pro-tooltip__description">
        Device grouping to enhance your device organization during Live Events.
      </div>
    </div>

    <div className="upgrade-to-pro-tooltip__list-item">
      {Icons.preset().class('upgrade-to-pro-tooltip__icon').size(SIZE.L).reactComponent()}

      <div className="upgrade-to-pro-tooltip__description">Configuration presets support.</div>
    </div>

    <div className="upgrade-to-pro-tooltip__list-item">
      {Icons.highDefinitionVideoUpgradeToPro()
        .class('upgrade-to-pro-tooltip__icon')
        .size(SIZE.L)
        .reactComponent()}

      <div className="upgrade-to-pro-tooltip__description">High-definition video playback.</div>
    </div>

    {canEdit && (
      <Button
        className="upgrade-to-pro-tooltip__button"
        theme={THEME.PRIMARY}
        variant={VARIANT.SOLID}
        href={router.url('settingBillingUsage')}
      >
        Upgrade to Premium
      </Button>
    )}
  </div>
);
