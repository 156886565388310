import React from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';

export enum METER_THEME {
  DEFAULT = 'default',
  GREEN = 'green',
  RED = 'red',
}

interface Props extends ClassName {
  vertical?: boolean;
  value: number;
  theme: METER_THEME;
  disabled: boolean;
}

export const Meter: React.VFC<Props> = ({
  className,
  vertical,
  value,
  theme,
  disabled,
}) => {
  const valuePercentage = `${value}%`;
  const valueStyle = vertical ? {height: valuePercentage} : {width: valuePercentage};

  return (
    <div
      className={classNames('meter', {
        'meter--disabled': Boolean(disabled),
        'meter--vertical': Boolean(vertical),
        'meter--theme-green': theme === METER_THEME.GREEN,
        'meter--theme-red': theme === METER_THEME.RED,
      }, className)}
    >
      <div className="meter__value" style={valueStyle}/>
    </div>
  );
};
