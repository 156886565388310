import React from 'react';
import classNames from 'classnames';
import {Countdown} from 'app/components/sharedReactComponents/Countdown';
import {ClassName, TimeStampSeconds} from 'app/types/common';
import {counterFormat} from 'app/util/time';
import {calcTime} from 'app/components/sharedReactComponents/Countdown/utils';

interface Props extends ClassName {
  timestamp: TimeStampSeconds;
}

export const MeetingUpTime = ({className, timestamp}: Props) => {
  return (
    <Countdown
      className={classNames('meeting-uptime', className)}
      data-id="meeting_up_time"
      timestamp={timestamp}
      calculate={calcTime}
      format={counterFormat}
    />
  );
};
