import React from 'react';
import {Box, Button, Card, Typography} from '@mui/material';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {router} from 'app/router/main';

const rootSx: StyleSx = {
  p: 3,
  display: 'flex',
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
};

interface Props extends Sx {
  permitEdit: boolean;
}

export function FilesWelcome({sx, permitEdit}: Props) {
  return (
    <Card sx={packSx(rootSx, sx)} variant="outlined">
      <Box>
        <Typography variant="h5" fontWeight={600} mb={2}>
          Epiphan Edge File Management
        </Typography>

        <Typography>Get files faster and easier with automatic backups</Typography>
      </Box>

      {permitEdit && (
        <Button variant="contained" color="secondary" href={router.url('settingBillingUsage')}>
          Get started for free
        </Button>
      )}
    </Card>
  );
}
