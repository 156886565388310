import React from 'react';
import {Box, Stack} from '@mui/material';
import {Component, Sx} from 'app/types/common';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {palette} from 'app/themes/app';
import {CopyUrl, JoinMeeting} from 'app/components/MeetingsManager/buttons';
import {packSx} from 'app/util/packSx/packSx';

interface Props extends Component, Sx {
  value: string;
  url: string;
  selfHosted: boolean;
  tenant?: string;
  disabled?: boolean;
}

export function EntityName({sx, value, disabled, url, selfHosted, tenant}: Props) {
  const handleClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <Box sx={packSx({minWidth: 0}, sx)}>
      <Box component="span" sx={{color: disabled ? 'grey' : 'black', minWidth: 0}}>
        <OverflowTooltip dataId="meeting_name">{value}</OverflowTooltip>
      </Box>

      <Stack direction="row" gap={0.5} alignItems="baseline" sx={{fontSize: '12px'}}>
        {!selfHosted && tenant && <Box sx={{color: palette.darkerGray}}>{tenant},</Box>}

        <Stack direction="row" alignItems="center" gap={1} sx={{'& .cr-btn': {p: 0}}}>
          <CopyUrl url={url} onClick={handleClick} />
          <JoinMeeting url={url} onClick={handleClick} />
        </Stack>
      </Stack>
    </Box>
  );
}
