import {useEffect, useState} from 'react';
import {DeviceSettingsCollection} from 'app/models/Device/Settings/DeviceSettingsCollection';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

export const useDeviceSettingsCollection = (device: AnyDeviceModelType) => {
  const [deviceSettingsCollection, setDeviceSettingsCollection] = useState<DeviceSettingsCollection>();

  useEffect(() => {
    device.getSettingsCollection()
      .then((settings) => {
        setDeviceSettingsCollection(settings);
      });
  }, [device]);

  return deviceSettingsCollection;
};
