type Params = {
  capabilities?: string[];
};

class BaseCapabilities {
  private readonly capabilities: Set<string>;

  constructor({
    capabilities = [],
  }: Params) {
    this.capabilities = new Set(capabilities);
  }

  protected check(capability: string) {
    return this.capabilities.has(capability);
  }
}

export {BaseCapabilities};
