import React from 'react';
import {Box, Typography} from '@mui/material';
import {observer} from 'mobx-react';
import {PageHeader} from 'app/components/sharedReactComponents/PageHeader';
import {DevicesAlertsCounter} from 'app/components/DevicesAlerts/DevicesAlertsCounter';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {LongNameBreakAllWrapper} from 'app/components/sharedReactComponents/LongNameWrapper';
import {useCurrentTeamStore} from 'app/store/hooks';
import {useTeamBilling} from 'app/components/BillingManager/hooks/useTeamBilling';
import {usePairedQuery} from 'app/hooks/usePairedQuery';
import {isNil} from 'app/util/isNil';
import {EdgeCard} from 'app/components/Settings/BillingAndUsage/cards/EdgeCard/EdgeCard';
import {LiveScryptCard} from 'app/components/Settings/BillingAndUsage/cards/LiveScryptCard/LiveScryptCard';
import {ConnectCard} from 'app/components/Settings/BillingAndUsage/cards/ConnectCard/ConnectCard';
import {UnifyCard} from 'app/components/Settings/BillingAndUsage/cards/UnifyCard/UnifyCard';
import {UsageAndInvoices} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/UsageAndInvoices';
import {PaymentInformation} from 'app/components/Settings/BillingAndUsage/PaymentInformation/PaymentInformation';
import {StyleSx} from 'app/types/common';
import {useFeature} from 'app/util/ApplicationFeatures';
import {FilesSubscription} from 'app/components/widgets/billing';

const gridSx: StyleSx = {display: 'grid', mb: 4, gap: 4, gridTemplateColumns: '1fr 1fr'};

export const BillingAndUsage = observer(() => {
  const team = useCurrentTeamStore();
  const filesFeature = useFeature('files');
  const connectFeature = useFeature('connect');
  const unifyFeature = useFeature('unify');

  const teamId = team.id;
  const teamName = team.getName();

  const {cloud, connect, payment, actions, unify} = useTeamBilling();

  const pairedQuery = usePairedQuery({teamId});
  const {data: counts} = pairedQuery;

  const hasPayment = payment?.hasCustomer ?? false;

  const isLoading = pairedQuery.isInitialLoading || isNil(payment) || isNil(cloud) || isNil(counts);

  return (
    <Box>
      <PageHeader
        buttons={
          <>
            <DevicesAlertsCounter />
          </>
        }
      />

      {isLoading ? (
        <IndeterminateProgressBar />
      ) : (
        <Box className="container" sx={{py: 4}}>
          <Typography sx={{opacity: 0.5, mb: 0.5}}>Settings</Typography>

          <Typography variant="h5" fontWeight={600} mb={5}>
            Billing and Usage for <LongNameBreakAllWrapper>{teamName}</LongNameBreakAllWrapper>
          </Typography>

          <Box sx={gridSx}>
            <EdgeCard plan={cloud} total={counts.devices} onChange={() => actions.manageCloud()} />

            {filesFeature && (
              <FilesSubscription
                teamId={teamId}
                hasPayment={hasPayment}
                onEditPayment={() => actions.managePayment()}
              />
            )}

            {unifyFeature && (
              <UnifyCard
                plan={unify}
                total={counts.projects}
                onManage={() => actions.manageUnify()}
              />
            )}

            {connectFeature && (
              <ConnectCard
                plan={connect}
                pending={payment?.pendingInvoicePayment}
                onManage={() => actions.manageConnect()}
              />
            )}

            <LiveScryptCard
              total={counts.lvs}
              configured={cloud.configured}
              price={cloud.prices.transcriber}
              onEditPayment={() => actions.managePayment()}
            />
          </Box>

          <PaymentInformation
            sx={{mb: 5}}
            payment={payment}
            onEnterCoupon={() => actions.enterCoupon()}
            onEditPayment={() => actions.managePayment()}
            onCancelRequest={() => actions.cancelInvoiceRequest()}
          />

          <UsageAndInvoices teamId={teamId} cloud={cloud} connect={connect} />
        </Box>
      )}
    </Box>
  );
});
