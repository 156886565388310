import {FirmwareFeature} from 'app/store/models/features/firmware/FirmwareFeature';

const TEMPERATURE_METER_SCALE_FACTOR = 1.12;

export function cpuTempMeterValueByCpuTemperature(cpuTemperature: number): number {
  return Math.floor(cpuTemperature * TEMPERATURE_METER_SCALE_FACTOR);
}

export function updateFirmwareButtonText(firmware: FirmwareFeature): string {
  if (firmware.updateAvailable) {
    return 'Install firmware update';
  }

  if (firmware.updateInProgress) {
    return 'Upgrading firmware...';
  }

  return '';
}
