import {NameValidator} from 'app/util/validators/NameValidator';
import {EmptyStringValidator} from 'app/util/validators/EmptyStringValidator';
import {EmailValidator} from 'app/util/validators/EmailValidator';
import {DevicePresetSectionsValidator} from 'app/util/validators/DevicePresetSectionsValidator';
import {DevicePresetNameValidator} from 'app/util/validators/DevicePresetNameValidator';

const emailValidator = new EmailValidator();
const nameValidator = new NameValidator();
const emptyStringValidator = new EmptyStringValidator();
const devicePresetSectionsValidator = new DevicePresetSectionsValidator();
const devicePresetNameValidator = new DevicePresetNameValidator();

export {
  emailValidator,
  nameValidator,
  emptyStringValidator,
  devicePresetSectionsValidator,
  devicePresetNameValidator,
};
