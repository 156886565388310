import React from 'react';
import {Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Sx} from 'app/types/common';
import {LegacyIcon} from 'app/components/sharedReactComponents/LegacyIcon/LegacyIcon';

const tooltip =
  'Streams created using Epiphan Cloud appear here and are available to all paired encoders. These common streams can only be started one at a time.';

interface Props extends Sx {}

export function StreamingDestinationListTitle({sx}: Props) {
  return (
    <Stack sx={sx} direction="row" alignItems="center" gap={1} color={grey[600]}>
      <Typography fontWeight="bold" fontSize={12}>
        Epiphan Cloud - Common streaming destinations
      </Typography>

      <Tooltip content={tooltip}>
        <LegacyIcon>{Icons.helpCircle().size(SIZE.S).reactComponent()}</LegacyIcon>
      </Tooltip>
    </Stack>
  );
}
