import React, {useMemo} from 'react';
import {
  InvoiceCard,
  Row,
} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/shared/InvoiceCard/InvoiceCard';
import {Component, Sx} from 'app/types/common';
import {formatPrice} from 'app/util/prices';

interface Props extends Sx, Component {
  name: string;
  invoice: App.Billing.Connect.Invoice;
  period: App.Billing.Period;
}

export function ConnectInvoice({sx, dataId, name, invoice, period}: Props) {
  const rows = useMemo<Row[]>(() => {
    const result: Row[] = [];

    result.push({
      name: 'Subscription fee',
      total: invoice.subscription,
      description: `${name} plan.`,
    });

    result.push({
      name: 'Usage fee',
      total: invoice.usage,
      description: `${invoice.hours > 0 ? invoice.hours : 'There is no'} meeting hours used.`,
    });

    if (invoice.discounts.length > 0) {
      invoice.discounts.forEach((discount) => {
        const {value, duration} = fmtCoupon(discount.coupon);

        result.push({
          name: `Coupon: ${value}`,
          total: -discount.amount,
          description: (
            <>
              Duration: <strong>{duration}</strong>.
            </>
          ),
        });
      });
    }

    return result;
  }, [invoice, name]);

  return (
    <InvoiceCard
      dataId={dataId}
      sx={sx}
      issueDate={invoice.date}
      period={period}
      total={invoice.total}
      rows={rows}
    />
  );
}

function fmtCoupon(coupon: App.Billing.Coupon) {
  let [value, duration] = ['', ''];

  if (coupon.amountOff) {
    value = `$${formatPrice(coupon.amountOff, 2)}`;
  } else if (coupon.percentOff) {
    value = `${coupon.percentOff}%`;
  }

  switch (coupon.duration) {
    case 'once':
      duration = 'Applied only once';
      break;
    case 'forever':
      duration = 'Applied forever';
      break;

    case 'repeating':
      duration = `Applied for ${coupon.month} month(s)`;
      break;
    default:
      break;
  }

  return {
    value,
    duration,
  };
}
