const enum FleetManagerColumnId {
  Model = 'model',
  Status = 'status',
  IpAddress = 'ipAddress',
  SerialNumber = 'serialNumber',
  HostName = 'host',
  FirmwareVersion = 'firmwareVersion',
  SystemLoad = 'systemLoad',
  Temperature = 'temperature',
  Uptime = 'uptime',
  Storage = 'storage',
  TransferQueue = 'transferQueue',
  Health = 'health',
  Access = 'access',
}

const FLEET_MANAGER_FIXED_COLUMNS = [
  FleetManagerColumnId.Model,
  FleetManagerColumnId.Access,
];

const MAX_OPENED_DEVICE_CARDS = 50;

export {
  FleetManagerColumnId,
  FLEET_MANAGER_FIXED_COLUMNS,
  MAX_OPENED_DEVICE_CARDS,
};

