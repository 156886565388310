import React from 'react';
import {Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {FilesWelcome, useUnitFiles} from 'app/components/features/files';
import {UnitFiles} from 'app/components/widgets/files';

interface Props extends Sx {
  teamId: string;
  enabled: boolean;
  deviceId: string;
  online: boolean;
  permitSubscribe: boolean;
}

export function UnitFileManagement({
  sx,
  enabled,
  deviceId,
  teamId,
  online,
  permitSubscribe,
}: Props) {
  const {data: recordings, isSuccess} = useUnitFiles({enabled, deviceId, teamId});

  if (!enabled) {
    return <FilesWelcome sx={sx} permitEdit={permitSubscribe} />;
  }

  return (
    <UnitFiles
      sx={packSx({flex: 1}, sx)}
      deviceId={deviceId}
      loading={!isSuccess}
      recordings={recordings ?? []}
      online={online}
    />
  );
}
