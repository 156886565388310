import React, {useEffect, useRef, useState} from 'react';
import {front} from 'app/api/CirrusApi';
import {DropdownSelect} from 'app/components/sharedReactComponents/DropdownSelect';
import {noop} from 'app/util/noop';
import {AbortCallback} from 'app/api/types';
import {useComponentWillUnmount} from 'app/hooks/useComponentWillUnmount';

const getOptionLabel = ({Name}) => Name;
const getOptionValue = ({ID}) => ID;

interface Props {
  streamingDestinationId: string;
  publishDestination: string;
  value: string;
}

export const DestinationSelect = ({
  streamingDestinationId,
  publishDestination,
  value,
  ...rest
}: Props) => {
  const abortRequest = useRef<AbortCallback>(noop);
  const [loading, setLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    fetchDestinations();
  }, []);

  useEffect(() => {
    if (publishDestination) {
      fetchDestinations();
    }
  }, [publishDestination]);

  useComponentWillUnmount(() => abortRequest.current());

  const fetchDestinations = () => {
    setLoading(true);

    front.streams(streamingDestinationId).destinations(publishDestination.toLowerCase()).get(undefined, {getAbort: abort => abortRequest.current = abort})
      .then((resp) => {
        if (Array.isArray(resp)) {
          setOptions(resp);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleOpen = () => {
    fetchDestinations();
  };

  const valueInOptions = options.some(option => getOptionValue(option) === value);

  return (
    <DropdownSelect
      {...rest}
      options={options}
      value={valueInOptions ? value : undefined}
      quietLoading={loading}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onOpen={handleOpen}
      onChange={noop}
    />
  );
};
