import {useQuery} from '@tanstack/react-query';
import {MeetingsApiService} from 'app/services/api/meetings/MeetingsApiService';
import {ConnectMapper} from 'app/util/mappers/ConnectMapper/ConnectMapper';

interface Args {
  teamId: string;
  enabled: boolean;
}

function mapper(c: Contracts.Connect.Auth.ZoomAccount[]): App.Connect.Auth.ZoomAccount[] {
  return c.map(ConnectMapper.mapZoomAccount);
}

export function useZoomAccounts({enabled, teamId}: Args) {
  return useQuery({
    queryKey: ['zoom-accounts', teamId],
    queryFn: MeetingsApiService.getZoomAccounts,
    select: mapper,
    enabled,
  });
}
