import {ModalNotification} from 'app/components/sharedReactComponents/Modal/ModalNotification';
import {Icons} from 'app/util/icons';
import {SIZE, THEME} from 'app/constants';

export const LoggingErrorModalNotification = {
  show() {
    ModalNotification.show({
      message: Icons.exclamation()
        .label('There was an error while logging you in. Please try again.')
        .theme(THEME.DANGER)
        .size(SIZE.XL)
        .reactComponent()
    });
  }
};
