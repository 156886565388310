import {CurrentTeamStore} from 'app/store/Team/CurrentTeamStore';
import {useRootStore} from 'app/store/StoreContext';
import {MeetingsStore} from 'app/store/Meetings/MeetingsStore';

export function useCurrentTeamStore(): CurrentTeamStore {
  return useRootStore().currentTeam;
}

export function useMeetingsStore(): MeetingsStore {
  return useRootStore().meetings;
}
