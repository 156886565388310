import React from 'react';
import {Box} from '@mui/material';
import {Sx} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {TeamPresets} from 'app/components/DeviceDetails/PresetsTab/TeamPresets/TeamPresets';
import {useTeamPresets} from 'app/hooks/Device/Preset/usePresets';
import {UnitPresets} from 'app/components/DeviceDetails/PresetsTab/UnitPresets/UnitPresets';

interface Props extends Sx {
  userName: string;
  device: AnyDeviceModelType;
  permitApply: boolean;
  permitDelete: boolean;
}

export function PresetsTab({sx, userName, permitApply, permitDelete, device}: Props) {
  const deviceId = device.getId();
  const deviceName = device.getName();
  const deviceModel = device.getModelName();
  const offline = device.isOffline();

  const presetAccess = device.capabilities.presets;

  const {data: presets, isSuccess, refetch: refetchPresets} = useTeamPresets({enabled: true});

  return (
    <Box sx={sx}>
      <TeamPresets
        sx={{mb: 3}}
        loading={!isSuccess}
        presets={presets ?? []}
        deviceId={deviceId}
        deviceName={deviceName}
        model={deviceModel}
        offline={offline}
        permitApply={permitApply}
        permitDelete={permitDelete}
        available={presetAccess}
        onRefetch={refetchPresets}
      />

      <UnitPresets
        deviceId={deviceId}
        deviceName={deviceName}
        permitApply={permitApply}
        permitDelete={permitDelete}
        available={presetAccess}
        teamPresets={presets ?? []}
        model={deviceModel}
        offline={offline}
        userName={userName}
        reloadTeamPresets={refetchPresets}
      />
    </Box>
  );
}
