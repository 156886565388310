import dayjs from 'dayjs';
import {TimeStampSeconds} from 'app/types/common';
import {Schedule} from 'app/domain/schedule';

export function getOverMessage(
  variant: Schedule.EventVariant,
  start: number,
  scheduledLabel = 'Scheduled for',
): string {
  if (variant === 'ongoing') {
    return '00:00:00';
  }

  if (variant === 'scheduled') {
    return `${scheduledLabel} ${dayjs.unix(start).format('LT MMM D')}`;
  }

  return '';
}

export function isSoon(timestamp: TimeStampSeconds): boolean {
  const minutes = dayjs.unix(timestamp).diff(dayjs(), 'minutes');
  return minutes > 0 && minutes < 60;
}
