import React from 'react';
import {Divider, Stack} from '@mui/material';
import {UnitAlert} from 'app/components/Settings/Alerts/AlertSettings/AlertTable/AlertTableBody/UnitAlert';
import {Alerts} from 'app/components/entities/alerts';

interface Props {
  types: Alerts.AlertType[];
  settings: Alerts.Settings[];
  disabled: boolean;
  hoveredAlert?: Alerts.AlertType;
  onChange: (id: string, type: Alerts.AlertType, value: boolean) => void;
}

export function AlertTableBody({settings, types, hoveredAlert, disabled, onChange}: Props) {
  return (
    <Stack divider={<Divider flexItem={true} orientation="horizontal" />}>
      {settings.map((s) => (
        <UnitAlert
          key={s.id}
          types={types}
          settings={s}
          disabled={disabled}
          hoveredAlert={hoveredAlert}
          onChange={onChange}
        />
      ))}
    </Stack>
  );
}
