import React, {useMemo} from 'react';
import {Stack} from '@mui/material';
import {Sx} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {
  DeviceField,
  UnitField,
} from 'app/components/DeviceDetails/DeviceInfoTab/SystemInformation/DeviceFields/DeviceField/DeviceField';

interface Props extends Sx {
  device: AnyDeviceModelType;
}

export function DeviceFields({sx, device}: Props) {
  const fields = useFields(device);

  return (
    <Stack sx={sx} direction="row" alignItems="top" gap={3}>
      {fields.map((field) => (
        <DeviceField key={field} field={field} device={device} />
      ))}
    </Stack>
  );
}

function useFields(device: AnyDeviceModelType): UnitField[] {
  return useMemo(() => {
    const online = device.isOnline();
    const model = device.getModelName();

    const isUnify = ModelService.isUnify(model);
    const isVirtual = ModelService.isVirtual(model);
    const isLvs = ModelService.isLivescrypt(model);

    const dualNetwork = device.hardware.has('network-monitor');

    const showNetwork = online && !dualNetwork;
    const showFirmware = online && !isUnify && !isVirtual && !isLvs;

    const map = new Map<UnitField, boolean>([
      ['host', isUnify],
      ['model', !isUnify],
      ['sn', !isUnify && !isVirtual],
      ['network', showNetwork],
      ['firmware', showFirmware],
    ]);

    return Array.from(map.entries()).reduce<UnitField[]>(
      (acc, [field, show]) => (show ? [...acc, field] : acc),
      [],
    );
  }, [device]);
}
