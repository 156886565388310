import React, {ReactNode, useEffect, useState} from 'react';
import classNames from 'classnames';
import {ProgressButton, BUTTON_TYPE} from 'app/components/sharedReactComponents/ProgressButton';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {
  THEME,
  VARIANT,
} from 'app/constants';
import {mapObjIndexed} from 'ramda';
import {Callback, ClassName} from 'app/types/common';

const Row: React.FC = ({children}) => {
  return (
    <div className="gla-form__row">
      {children}
    </div>
  );
};

function trim(value) {
  return typeof value === 'string' ? value.trim() : value;
}

function getDefaultValues(fields) {
  return fields.reduce((acc, field) => {
    const {defaultValue} = field;

    if (defaultValue) {
      acc[field.name] = defaultValue;
    }

    return acc;
  }, {});
}

// rework
interface Props extends ClassName {
  fields: any [];
  buttonText: string;
  errorProcessor: Callback;
  submitAction: Callback<Promise<any>>;
  onSubmit: Callback;
  error?: any;
  loading?: boolean;
  descriptionComponent?: ReactNode;
  hintComponent?: ReactNode;
}

export const Form = ({
  className,
  fields,
  buttonText,
  errorProcessor,
  submitAction,
  onSubmit,
  error,
  loading,
  descriptionComponent,
  hintComponent,
}: Props) => {
  const [errorState, setErrorState] = useState<any>(null);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>(getDefaultValues(fields));

  useEffect(() => {
    setErrorState(error);
    setFormData(getDefaultValues(fields));
  }, [error, fields]);

  const getFormData = () => {
    return mapObjIndexed(trim, formData);
  };

  const getValueByName = (name) => {
    return formData[name] || '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = getFormData();

    if (submitAction) {
      setLoadingState(true);

      submitAction(formData)
        .catch(error => {
          setErrorState(errorProcessor(error, formData.username));
        })
        .finally(() => setLoadingState(false));
    } else {
      onSubmit(formData);
    }
  };

  const handleChange = ({target: {name, value}}) => {
    setErrorState(null);
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const renderControl = (field, props) => {
    const hasError = errorState?.name === field.name;
    const formValue = getValueByName(field.name);

    return (
      <FormControl
        {...props}
        type={field.type}
        label={field.label}
        name={field.name}
        autoComplete={field.autoComplete}
        error={hasError}
        value={formValue}
        helperText={(hasError && errorState.message) || field.helperText}
        minLength={field.minLength}
        disabled={field.disabled || loadingState}
        required={field.required}
        fullWidth={true}
        onChange={handleChange}
      />
    );
  };

  let autoFocusUsed = false;
  const currentLoading = loading || loadingState;

  return (
    <form
      className={classNames('gla-form', className)}
      action=""
      onSubmit={handleSubmit}
    >
      {descriptionComponent && (
        <div className="gla-form__description">
          {descriptionComponent}
        </div>
      )}

      {errorState && errorState.name === 'common' && (
        <div className="gla-form__error">
          {errorState.message}
        </div>
      )}

      <div className="gla-form__content">
        {fields.map(field => {
          if (field.controls) {
            return (
              <Row key={field.name}>
                {field.controls.map(control => {
                  let autoFocus = false;

                  if (!autoFocusUsed && !control.disabled) {
                    autoFocusUsed = true;
                    autoFocus = true;
                  }

                  return renderControl(control, {
                    key: control.name,
                    autoFocus,
                  });
                })}
              </Row>
            );
          }

          let autoFocus = false;

          if (!autoFocusUsed && !field.disabled) {
            autoFocusUsed = true;
            autoFocus = true;
          }

          return (
            <Row key={field.name}>
              {renderControl(field, {autoFocus})}
            </Row>
          );
        })}

        {hintComponent && (
          <div className="gla-form__hint">
            {hintComponent}
          </div>
        )}
      </div>

      <div className="gla-form__footer">
        <ProgressButton
          className="gla-form__submit-button"
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          type={BUTTON_TYPE.SUBMIT}
          loading={currentLoading}
        >
          {buttonText}
        </ProgressButton>
      </div>
    </form>
  );
};
