import React from 'react';
import {alpha, Box, Chip, LinearProgress, Stack, Typography} from '@mui/material';
import {ProductCard} from 'app/components/Settings/BillingAndUsage/cards/ProductCard/ProductCard';
import {UnitsCount} from 'app/components/Settings/BillingAndUsage/cards/shared/UnitsCount/UnitsCount';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {palette} from 'app/themes/app';
import {EdgePlan} from 'app/models/EdgePlan/EdgePlan';
import {formatDateTime} from 'app/util/time';

type Props = {
  plan: EdgePlan;
  total: number;
  onChange: Fn;
};

export function EdgeCard({plan, total, onChange}: Props) {
  const {tier} = plan;

  const limited = plan.configured && tier.seats > 0;

  const renderFooter = () => {
    return (
      <Box>
        <UnitsCount count={total} max={limited ? tier.seats : undefined} type="device" />

        {limited && (
          <LinearProgress
            sx={{
              mt: 1,
              width: 250,
              bgcolor: alpha(palette.gray, 0.12),
              '& > span': {bgcolor: palette.green},
            }}
            data-id="plan-capacity"
            variant="determinate"
            value={(total * 100) / tier.seats}
          />
        )}
      </Box>
    );
  };

  const renderTitle = () => {
    if (plan.free) {
      return 'Free plan active';
    }

    return `${tier.isDeleted ? tier.name : 'Premium'} plan active`;
  };

  const renderDescription = () => {
    if (plan.free) {
      return (
        <Typography data-id="plan-description">Unlimited devices, limited functionality</Typography>
      );
    }

    const trialTo = plan.trial?.active ? plan.trial.to : false;

    return (
      <React.Fragment>
        <Typography data-id="plan-price">
          ${tier.rate} per {plan.interval.dimension}
        </Typography>

        {trialTo && (
          <Typography data-id="plan-trial">
            Trial till {formatDateTime(trialTo, 'h:mm A, MMM D, YYYY')}
          </Typography>
        )}
      </React.Fragment>
    );
  };

  return (
    <ProductCard
      dataId="edge-card"
      title="Epiphan Edge"
      body={
        <Stack flexGrow={1}>
          <Typography
            data-id="plan-title"
            fontSize={20}
            fontWeight={600}
            component="span"
            color={palette.blue}
            mb={0.5}
          >
            {renderTitle()}

            {plan.tier.isDeleted && (
              <Chip sx={{ml: 1, fontWeight: 'normal'}} label="Archived" size="small" />
            )}
          </Typography>
          {renderDescription()}
        </Stack>
      }
      action={
        <Button
          dataId="manage-edge"
          variant={VARIANT.OUTLINE}
          theme={THEME.INFO}
          onClick={onChange}
        >
          {plan.free ? 'Go Premium' : 'Manage'}
        </Button>
      }
      footer={renderFooter()}
    />
  );
}
