import React, {useMemo} from 'react';
import {Stack} from '@mui/material';
import {Sx} from 'app/types/common';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {DeviceChannel} from 'app/components/features/edge/EdgeDevice/body/DeviceChannels/DeviceChannel/DeviceChannel';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {PreviewUpdateStrategy} from 'app/hooks/Device/Preview/types';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

type Props = Sx & {
  master: PearlMasterDeviceModel;
  premium: boolean;
  previewType: PreviewUpdateStrategy;
  billingAccess: boolean;
  streamsAccess: boolean;
  streamingDestinations: AnyStreamingDestinationModelType[];
  getDeviceById: (deviceId: string) => AnyDeviceModelType | undefined;
  checkSelection: (channelId: string) => boolean;
  toggleSelection: (channel: PearlSlotDeviceModel) => void;
};

export const DeviceChannels = React.memo(
  ({
    sx,
    master,
    premium,
    billingAccess,
    previewType,
    streamsAccess,
    streamingDestinations,
    getDeviceById,
    checkSelection,
    toggleSelection,
  }: Props) => {
    const channels = useMemo(() => master.getChanelModels(), [master]);

    return (
      <Stack sx={sx} gap={1}>
        {channels.map((channel) => (
          <DeviceChannel
            dataId={channel.getId()}
            key={channel.getId()}
            previewType={previewType}
            channel={channel}
            premium={premium}
            billingAccess={billingAccess}
            streamsAccess={streamsAccess}
            streamingDestinations={streamingDestinations}
            getDeviceById={getDeviceById}
            checkSelection={checkSelection}
            toggleSelection={toggleSelection}
          />
        ))}
      </Stack>
    );
  },
);
