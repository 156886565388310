import {DeviceModel} from 'app/components/DeviceDetails/constants';
import {Cloud} from 'app/domain/cloud';

export class ModelService {
  static isWebcaster(model: DeviceModel | Cloud.UnitModel): boolean {
    return model === DeviceModel.Webcaster;
  }

  static isLivescrypt(model: DeviceModel | Cloud.UnitModel): boolean {
    return model === DeviceModel.LiveScrypt;
  }

  static isChannel(model: DeviceModel | Cloud.UnitModel | string): boolean {
    return model === DeviceModel.Channel;
  }

  static isDemo(model: DeviceModel | Cloud.UnitModel | string): boolean {
    return model === DeviceModel.Demo;
  }

  static isReal(model: DeviceModel | Cloud.UnitModel | string): boolean {
    return !this.isDemo(model);
  }

  static isPearl2(model: DeviceModel | Cloud.UnitModel | string): boolean {
    return model === DeviceModel.Pearl2;
  }

  static isNano(model: DeviceModel | Cloud.UnitModel | string) {
    return model === DeviceModel.PearlNano;
  }

  static isMini(model: DeviceModel | Cloud.UnitModel | string): boolean {
    return model === DeviceModel.PearlMini;
  }

  static isVirtual(model: DeviceModel | Cloud.UnitModel | string): boolean {
    return model === DeviceModel.PearlVirtual;
  }

  static isNexus(model: DeviceModel | Cloud.UnitModel | string): boolean {
    return model === DeviceModel.PearlNexus;
  }

  static isUnify(model: DeviceModel | Cloud.UnitModel | string): boolean {
    return model === DeviceModel.Unify;
  }

  static isPearlFamily(model: DeviceModel | Cloud.UnitModel | string): boolean {
    return (
      ModelService.isPearl2(model) ||
      ModelService.isMini(model) ||
      ModelService.isNano(model) ||
      ModelService.isVirtual(model) ||
      ModelService.isNexus(model)
    );
  }
  static isMultiChannel(model: DeviceModel | Cloud.UnitModel | string): boolean {
    return ModelService.isUnify(model) || ModelService.isPearlFamily(model);
  }
}
