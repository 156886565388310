import React, {useState} from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';
import {useMounted} from 'app/hooks/useIsMounted';
import {NamesList} from 'app/components/sharedReactComponents/ItemsEnumeration';
import {pluralize, pluralizeN} from 'app/components/helpers/commonHelpers';

type ProjectCheck = 'recordings' | 'configuration' | 'alerts';

type Props = {
  loading: boolean;
  names: string[];
  onDelete: () => Promise<void>;
  onClose: () => void;
};

function DeleteProjects({loading, names, onDelete, onClose}: Props) {
  const plural = pluralizeN('project', names.length);

  const [checks, setChecks] = useState<Set<ProjectCheck>>(new Set());

  const toggleCheck = (check: ProjectCheck) => {
    const copy = new Set(checks);

    if (copy.has(check)) {
      copy.delete(check);
    } else {
      copy.add(check);
    }

    setChecks(copy);
  };

  return (
    <Box>
      <DialogTitle>
        Delete <NamesList max={3} variant="inherit" color="primary.main" names={names} />{' '}
        {pluralizeN('project', names.length)}
      </DialogTitle>

      <DialogContent>
        <Typography textAlign="center">Click the check boxes to confirm</Typography>

        <Stack alignItems="center">
          <Stack alignItems="flex-start">
            <FormControlLabel
              sx={{mb: 0}}
              control={
                <Checkbox
                  checked={checks.has('recordings')}
                  onChange={() => toggleCheck('recordings')}
                />
              }
              label={`Delete all content and recordings associated with ${pluralize(
                'this',
                names,
              )} ${plural}`}
            />
            <FormControlLabel
              sx={{mb: 0}}
              control={
                <Checkbox
                  checked={checks.has('configuration')}
                  onChange={() => toggleCheck('configuration')}
                />
              }
              label={`Delete ${plural} ${pluralize('configuration', names)}`}
            />
            <FormControlLabel
              sx={{mb: 0}}
              control={
                <Checkbox checked={checks.has('alerts')} onChange={() => toggleCheck('alerts')} />
              }
              label={`Stop receiving alerts from ${pluralize('this', names)} ${plural}`}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="confirm-delete-btn"
          variant="contained"
          color="error"
          loading={loading}
          disableRipple={false}
          disabled={checks.size !== 3}
          onClick={onDelete}
        >
          Delete
        </LoadingButton>
        <Button
          data-id="cancel-delete-btn"
          variant="outlined"
          color="info"
          disableRipple={false}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
}

type PromptProps = Prompt & Omit<Props, 'loading'> & Pick<DialogProps, 'maxWidth' | 'fullWidth'>;

export function DeleteProjectsDialog({open, names, onDelete, onClose, ...props}: PromptProps) {
  const mounted = useMounted();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await onDelete();
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} {...props}>
      <DeleteProjects loading={loading} names={names} onDelete={handleDelete} onClose={onClose} />
    </Dialog>
  );
}
