import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {useIsMounted} from 'app/hooks/useIsMounted';
import {ClassName, DataId} from 'app/types/common';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {
  LongNameBreakAllWrapper,
  LongNameBreakWordWrapper,
} from 'app/components/sharedReactComponents/LongNameWrapper';

type OverflowComponent = 'div' | 'span';

interface Props extends ClassName, DataId {
  component?: OverflowComponent;
  rowsNumber?: number;
  async?: boolean;
  wordBreak?: 'break-all' | 'break-word';
}

const safeRowNumber = (value: number) => Math.trunc(Math.abs(value));

export function OverflowTooltip(props: React.PropsWithChildren<Props>) {
  const {
    component = 'div',
    async = false,
    rowsNumber = 1,
    wordBreak = 'break-all',
    className,
    dataId,
    children,
  } = props;

  const [overflow, setOverflow] = useState(false);
  const [overflowRows, setOverflowRows] = useState(safeRowNumber(rowsNumber));

  const isMounted = useIsMounted();
  const elementRef = useRef<HTMLDivElement & HTMLSpanElement>(null);

  useEffect(() => {
    const overflowCheck = () => {
      if (!elementRef.current) {
        return;
      }

      let calculated = false;

      // If difference more than `1px` (fix Microsoft Edge)
      if (overflowRows > 1) {
        calculated =
          Math.abs(elementRef.current.clientHeight - elementRef.current.scrollHeight) > 1;
      } else {
        calculated = Math.abs(elementRef.current.clientWidth - elementRef.current.scrollWidth) > 1;
      }

      if (isMounted.current) {
        setOverflow((prev) => (calculated === prev ? prev : calculated));
      }
    };

    if (async) {
      setTimeout(() => overflowCheck());
    } else {
      overflowCheck();
    }
  });

  useEffect(() => {
    setOverflowRows(safeRowNumber(rowsNumber));
  }, [rowsNumber]);

  const renderChildren = () => {
    if (overflowRows > 1) {
      const WordBreakWrapper =
        wordBreak === 'break-word' ? LongNameBreakWordWrapper : LongNameBreakAllWrapper;

      return <WordBreakWrapper>{children}</WordBreakWrapper>;
    }

    return children;
  };

  const renderElement = () => {
    const classes = classNames(
      'overflow-tooltip',
      {
        'overflow-tooltip--multiple-rows': overflowRows > 1,
      },
      className,
    );

    const cssProperties: React.CSSProperties = {};
    cssProperties['--rows-number'] = overflowRows;

    const Tag = component;

    return (
      <Tag ref={elementRef} className={classes} style={cssProperties} data-id={dataId}>
        {renderChildren()}
      </Tag>
    );
  };

  if (overflow) {
    return <Tooltip content={children}>{renderElement()}</Tooltip>;
  }

  return renderElement();
}
