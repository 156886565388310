import {useQuery} from '@tanstack/react-query';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {EdgeMapper} from 'app/util/mappers/edge/EdgeMapper';

type Args = {
  deviceId: string;
  enabled: boolean;
  staleTime?: number;
  refetchInterval?: number;
};

export function useSystemInfo({deviceId, enabled, refetchInterval, staleTime}: Args) {
  return useQuery({
    queryKey: [`sys-info-${deviceId}`, deviceId],
    queryFn: async () => {
      const response = await DeviceApiService.getSystemStatus(deviceId);

      if (response) {
        return EdgeMapper.mapSystem(response);
      }

      return null;
    },
    refetchInterval,
    staleTime,
    enabled,
  });
}
