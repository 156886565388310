import React, {useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {
  THEME,
  VARIANT,
  SIZE,
} from 'app/constants';
import {Callback, ClassName} from 'app/types/common';
import {useIsMounted} from 'app/hooks/useIsMounted';

const ACTION_TIMEOUT = 2000;

export interface TranscriptionButtonProps extends ClassName {
  deviceId?: string;
  disabled: boolean;
  teamCanTranscribing: boolean;
  size?: SIZE;
  theme?: THEME;
  variant?: VARIANT;
  action: Callback<Promise<any>>;
}

export const TranscriptionButtonComponent: React.FC<TranscriptionButtonProps> = ({
  className,
  disabled,
  teamCanTranscribing,
  size,
  theme,
  variant,
  children,
  deviceId,
  action,
  ...elementProps
}) => {
  const [loading, setLoading] = useState(false);
  const startStopTimeout = useRef<any>();
  const mountedRef = useIsMounted();

  useEffect(() => {
    return () => {
      clearTimeout(startStopTimeout.current);
    };
  }, []);

  const handleClick = useCallback(() => {
    setLoading(true);

    action(deviceId)
      .finally(() => {
        if (mountedRef.current) {
          startStopTimeout.current = setTimeout(() => setLoading(false), ACTION_TIMEOUT);
        }
      });
  }, [action, deviceId]);

  return (
    <ProgressButton
      className={classNames('start-stop-transcription-button', className)}
      size={size}
      theme={theme}
      variant={variant}
      title={(teamCanTranscribing || disabled) ? undefined : 'Add payment method\nto enable transcription'}
      titlePreformatted={true}
      loading={loading}
      disabled={disabled || !teamCanTranscribing}
      onClick={handleClick}
      {...elementProps}
    >
      {children}
    </ProgressButton>
  );
};
