import React from 'react';
import classNames from 'classnames';
import {SIZE, VARIANT} from 'app/constants';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  online: boolean;
  variant?: VARIANT.OUTLINE | VARIANT.SOLID;
  size: SIZE;
}

export const OnlineIndicator: React.FC<Props> = ({
  className,
  online,
  variant = VARIANT.OUTLINE,
  size = SIZE.M,
}) => {
  return (
    <span
      className={classNames(
        'online-indicator',
        {
          [`online-indicator--size-${size}`]: Boolean(size),
          [`online-indicator--variant-${variant}`]: Boolean(variant),
          'online-indicator--online': online,
        },
        className
      )}
      data-id="online_indicator"
    />
  );
};
