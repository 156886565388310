import React from 'react';
import classNames from 'classnames';
import {ChannelsListItem} from 'app/components/DeviceDetails/ChannelsTab/ChannelsListItem';
import {ClassName} from 'app/types/common';
import {Schedule} from 'app/domain/schedule';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

interface Props extends ClassName {
  masterDevice: PearlMasterDeviceModel;
  channelDevices: PearlSlotDeviceModel[];
  streamingDestinations: AnyStreamingDestinationModelType[];
  actualEvent?: Schedule.Event;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  onChangeStreamingDestination: Fn;
}

export function ChannelsList({
  className,
  masterDevice,
  channelDevices,
  streamingDestinations,
  actualEvent,
  getDeviceById,
  onChangeStreamingDestination,
}: Props) {
  return (
    <div className={classNames('channels-list', className)}>
      {channelDevices.map((channelDevice) => (
        <ChannelsListItem
          key={channelDevice.getId()}
          className="channels-list__item"
          masterDevice={masterDevice}
          channelDevice={channelDevice}
          streamingDestinations={streamingDestinations}
          actualEvent={actualEvent}
          getDeviceById={getDeviceById}
          onChangeStreamingDestination={onChangeStreamingDestination}
        />
      ))}
    </div>
  );
}
