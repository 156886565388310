import React, {useState, useCallback, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useTranscribingLP} from 'app/hooks/Device/useTranscribingLP';
import {useIsMounted} from 'app/hooks/useIsMounted';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {TranscribingText} from 'app/components/DeviceDetails/TranscriberDevice/Text/TranscribingText';
import {TranscriberControls} from 'app/components/DeviceDetails/TranscriberDevice/Controls/TranscriberControls';
import {TranscriberStreamUrl} from 'app/components/DeviceDetails/TranscriberDevice/StreamUrl/TranscriberStreamUrl';
import {TranscribingStatus} from 'app/components/DeviceDetails/constants';
import {Separator, SeparatorVertical} from 'app/components/sharedReactComponents/Separator';
import {LiveScryptDeviceModel} from 'app/components/DeviceDetails/Models/LiveScryptDeviceModel';

interface Props {
  device: LiveScryptDeviceModel;
  canTranscribe: boolean;
}

export const Transcriber: React.VFC<Props> = observer(({canTranscribe, device}: Props) => {
  const [transcript, setTranscript] = useState<any[]>([]);

  const transcriptUpdate = useCallback((transcript) => {
    setTranscript(transcript);
  }, []);

  const lp = useTranscribingLP({deviceId: device.getId(), onUpdate: transcriptUpdate});

  const [clearing, setClearing] = useState(false);

  const transcriberInfo = device.getTranscribingInfo();
  const disabled = device.isOffline();

  const [info, setInfo] = useState(transcriberInfo);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!lp) {
      return;
    }

    if (info.status === TranscribingStatus.Started || info.status === TranscribingStatus.Paused) {
      lp.start();
    }

    if (info.status === TranscribingStatus.Stopped) {
      lp.stop();
      setTranscript([]);
      return;
    }

    return () => {
      lp.stop();
    };
  }, [lp, info.status]);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    const transcriberInfo = device.getTranscribingInfo();
    setInfo(transcriberInfo);
  }, [device]);

  const handleClickClear = useCallback(() => {
    setClearing(true);
    device.clearCurrentTranscribingSession().finally(() => {
      setClearing(false);
    });
  }, [device]);

  const {active, paused} = transcriberInfo;

  return (
    <div className="transcriber">
      <div className="container transcriber__container">
        <Separator className="transcriber__separator" />
        <div className="transcriber__body">
          <div className="transcriber__panel">
            <TranscriberControls device={device} teamCanTranscribing={canTranscribe} />

            <div className="transcriber__output">
              <TranscribingText
                className="transcriber__text"
                data-id="transcriber_output"
                transcript={transcript}
                paused={paused}
                disabled={disabled}
              />
              <div className="transcriber__footer">
                {active && (
                  <ProgressButton
                    dataId="clear_transcriber_output_button"
                    theme={THEME.SECONDARY}
                    variant={VARIANT.SOLID}
                    size={SIZE.S}
                    loading={clearing}
                    onClick={handleClickClear}
                  >
                    Clear
                  </ProgressButton>
                )}
              </div>
            </div>
          </div>
          <SeparatorVertical />
          <div className="transcriber__stream">
            <TranscriberStreamUrl url={transcriberInfo.url ?? ''} />
          </div>
        </div>
      </div>
    </div>
  );
});
