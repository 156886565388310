/**
 * @readonly
 * @enum {string}
 */
enum STREAMING_SERVICE_PROP_NAME {
  RTMP = 'RTMP',
  OAUTH2 = 'OAuth2',
}

/**
 * @readonly
 * @enum {string}
 */
enum STREAMING_SERVICE_SETTINGS_PROP_NAME {
  FACEBOOK = 'FBSettings',
  YOUTUBE = 'YTSettings',
}

/**
 * @readonly
 * @enum {string}
 */
enum STREAMING_SERVICE_TYPE {
  FACEBOOK = 'facebook',
  YOUTUBE = 'google',
  TWITCH = 'twitch',
  RTMP = 'rtmp',
  RTMPS = 'rtmps',
}

/**
 * @readonly
 * @enum {string}
 */
enum FACEBOOK_STREAMING_DESTINATION {
  TIMELINE = 'timeline',
  PAGES = 'pages',
  GROUPS = 'groups',
}

/**
 * @readonly
 * @enum {string}
 */
enum FACEBOOK_STREAMING_PRIVACY {
  ONLY_ME = 'SELF',
  FRIENDS = 'ALL_FRIENDS',
  PUBLIC = 'EVERYONE',
}

/**
 * @readonly
 * @enum {string}
 */
enum YOUTUBE_STREAMING_DESTINATION {
  /**
   * @deprecated
   */
  STREAM_NOW = 'StreamNow',
  EVENTS = 'Events',
}

/**
 * @readonly
 * @enum {string}
 */
enum YOUTUBE_STREAMING_PRIVACY {
  PRIVATE = 'Private',
  PUBLIC = 'Public',
  UNLISTED = 'Unlisted',
}

enum StreamingType {
  Facebook = 'facebook',
  Youtube = 'google',
  Twitch = 'twitch',
  Rtmp = 'rtmp',
  Rtmps = 'rtmps',
}

export {
  STREAMING_SERVICE_PROP_NAME,
  STREAMING_SERVICE_SETTINGS_PROP_NAME,
  STREAMING_SERVICE_TYPE,
  FACEBOOK_STREAMING_DESTINATION,
  FACEBOOK_STREAMING_PRIVACY,
  YOUTUBE_STREAMING_DESTINATION,
  YOUTUBE_STREAMING_PRIVACY,
  /** new enums */
  StreamingType,
};
