import {Coupon} from 'app/components/BillingManager/Coupon';
import {BillingApiService} from 'app/services/api/billing/BillingApiService';
import {BillingMapper} from 'app/services/mappers/BillingMapper';
import {Callback, ReducerAction, ReducerActionWithData} from 'app/types/common';
import {Reducer, useCallback, useReducer} from 'react';

export interface CouponState {
  value: string;
  instance?: Coupon;
  checking: boolean;
  invalid: boolean;
}

type InputAction = ReducerActionWithData<'input', string>;
type CouponAction = ReducerActionWithData<'coupon', Coupon | undefined>;
type CheckingAction = ReducerActionWithData<'checking', boolean>;
type InvalidAction = ReducerAction<'invalid'>;

type Action = InputAction | CouponAction | CheckingAction | InvalidAction;

function reducer(state: CouponState, action: Action): CouponState {
  switch (action.type) {
    case 'input': {
      return {value: action.payload, instance: undefined, invalid: false, checking: false};
    }

    case 'coupon': {
      const {payload} = action;
      return {...state, instance: payload, checking: false};
    }

    case 'checking': {
      const {payload} = action;
      return {...state, checking: payload};
    }

    case 'invalid': {
      return {...state, checking: false, invalid: true, instance: undefined};
    }

    default:
      return state;
  }
}

interface Props {
  teamId: string;
}

interface Return {
  coupon: CouponState;
  setCoupon: Callback<void, [value: string]>;
  validateCoupon: Callback<Promise<void>, [value: string]>;
}

export function useCoupon({teamId}: Props): Return {
  const [state, dispatch] = useReducer<Reducer<CouponState, Action>>(reducer, {
    checking: false,
    value: '',
    instance: undefined,
    invalid: false,
  });

  const validate = useCallback(
    async (value: string) => {
      try {
        dispatch({type: 'checking', payload: true});
        const result = await checkCoupon(value, teamId);
        dispatch({type: 'coupon', payload: result});
      } catch {
        dispatch({type: 'invalid'});
      }
    },
    [teamId],
  );

  const setCoupon = useCallback((value: string) => {
    dispatch({type: 'input', payload: value});
  }, []);

  return {coupon: state, setCoupon, validateCoupon: validate};
}

async function checkCoupon(value: string, teamId: string): Promise<Coupon> {
  try {
    const response = await BillingApiService.checkCoupon(value, teamId);

    return BillingMapper.mapCoupon(response);
  } catch {
    throw new Error('Not valid coupon');
  }
}
