/* eslint-disable no-restricted-syntax */
import dayjs from 'dayjs';
import {
  ChannelFields,
  EventConfig,
  EventFormFields,
  RecurrenceFormFields,
} from 'app/components/sharedReactComponents/Events/Editor/EventForm/types';
import {ScheduleContract} from 'app/contracts/schedule';
import {Schedule} from 'app/domain/schedule';
import {isNil} from 'app/util/isNil';

function mapDeviceConfig(
  p: Record<string, ScheduleContract.DeviceProfile>,
): Map<string, Map<string, ChannelFields>> {
  const result = new Map<string, Map<string, ChannelFields>>();

  Object.entries(p).forEach((pair) => {
    const [deviceId, p] = pair;
    const conf = new Map<string, ChannelFields>();

    Object.entries(p.channels).forEach((c) => {
      const [channelId, profile] = c;

      conf.set(channelId, {
        recording: profile.recording,
        streaming: profile.streaming,
        destination: profile.destination,
        publishers: profile.publishers,
      });
    });

    result.set(deviceId, conf);
  });

  return result;
}

type RecurrenceFields = EventFormFields['recurrence'];
type RecurrenceUntilFields = RecurrenceFields['until'];

function mapUntilSection(
  c: ScheduleContract.RecurrentEnd,
  end: TimeStampSeconds,
): RecurrenceUntilFields {
  if (c.occurrences) {
    return {
      type: 'occurrences',
      occurrences: c.occurrences ?? 1,
      date: end,
    };
  }

  if (c.timestamp) {
    return {
      type: 'date',
      date: c.timestamp,
      occurrences: 1,
    };
  }

  return {
    type: 'never',
    date: end,
    occurrences: 1,
  };
}

function mapRecurrentSection(
  c: ScheduleContract.RecurrenceRule,
): EventFormFields['recurrence']['type'] {
  switch (c.type) {
    case 'day':
      return 'daily';

    default:
      return 'weekly';
  }
}

function mapRecurrence(c: ScheduleContract.DetailedEvent): RecurrenceFields {
  const {finish, recurrence_info} = c;

  const defaultUntil: RecurrenceUntilFields = {
    type: 'never',
    date: finish,
    occurrences: 1,
  };

  if (recurrence_info) {
    const {by, until} = recurrence_info;
    return {
      type: mapRecurrentSection(by),
      until: until ? mapUntilSection(until, finish) : {...defaultUntil},
    };
  }

  return {
    type: 'daily',
    until: {...defaultUntil},
  };
}

function mapToEventConfig(c: ScheduleContract.DetailedEvent): EventConfig {
  const {recurrence_info, devices} = c;

  const recurrent = !isNil(recurrence_info);
  const recurrence = mapRecurrence(c);

  return {
    event: {
      title: c.title,
      start: c.start,
      end: c.finish,
      timezone: c.timezone,
      recurrent: recurrent,
      recurrence,
    },
    devices: mapDeviceConfig(devices),
  };
}

function determineMediaAction(
  devices: Record<string, ScheduleContract.DeviceProfile>,
): Schedule.EventMedia {
  let recording = false;
  let streaming = false;

  const profiles = Object.values(devices);

  for (const p of profiles) {
    const channels = Object.values(p.channels);

    if (recording && streaming) {
      break;
    }

    for (const c of channels) {
      if (c.recording) {
        recording = true;
      }

      if (c.streaming) {
        streaming = true;
      }
    }
  }

  if (streaming && recording) {
    return 'all';
  }

  if (streaming) {
    return 'streaming';
  }

  return 'recording';
}

function mapChannelProfiles(
  profiles: Record<string, ScheduleContract.ChannelProfile>,
): Map<string, Schedule.ChannelProfile> {
  const result = new Map<string, Schedule.ChannelProfile>();

  Object.entries(profiles).forEach((p) => {
    const [channelId, profile] = p;
    result.set(channelId, {...profile});
  });

  return result;
}

function mapDeviceProfiles(
  profiles: Record<string, ScheduleContract.DeviceProfile>,
): Map<string, Map<string, Schedule.ChannelProfile>> {
  const result = new Map<string, Map<string, Schedule.ChannelProfile>>();
  const pairs = Object.entries(profiles);

  for (const p of pairs) {
    const [deviceId, profile] = p;

    const config = mapChannelProfiles(profile.channels);
    result.set(deviceId, config);
  }

  return result;
}

function mapEvent(contract: ScheduleContract.Event): Schedule.Event {
  const profile = mapDeviceProfiles(contract.devices);
  const media = determineMediaAction(contract.devices);

  return {
    id: contract.id,
    teamId: contract.team_id,
    ruleId: contract.event_rule_id,
    title: contract.title,
    status: contract.status,
    start: contract.start,
    end: contract.finish,
    cms: contract.cms,
    confirmed: contract.confirmed,
    capabilities: contract.capabilities,
    devices: profile,
    media,
  };
}

function toUntilSection(
  section: RecurrenceFormFields['until'],
): ScheduleContract.RecurrentEnd | undefined {
  switch (section.type) {
    case 'date': {
      return {timestamp: section.date};
    }

    case 'occurrences': {
      const {occurrences} = section;
      return {occurrences};
    }

    default:
      return undefined;
  }
}

function toRecurrentSection(
  section: RecurrenceFormFields['type'],
  days: Schedule.DayOfWeek[],
): ScheduleContract.RecurrenceRule {
  switch (section) {
    case 'daily':
      return {
        type: 'day',
      };

    default:
      return {
        type: 'weekday',
        weekday: days,
      };
  }
}

function toRecurrentInfo(event: EventFormFields): ScheduleContract.Recurrence {
  const {start, timezone, recurrence} = event;
  const {until: end, type} = recurrence;

  const day = dayjs.unix(start).tz(timezone).format('dddd').toLowerCase() as Schedule.DayOfWeek;

  return {
    by: toRecurrentSection(type, [day]),
    until: toUntilSection(end),
  };
}

function toEventRule({event, devices}: EventConfig): ScheduleContract.EventRule {
  const {title, start, end, timezone, recurrent} = event;
  const recurrenceInfo = recurrent ? toRecurrentInfo(event) : undefined;

  const result: ScheduleContract.EventRule = {
    title,
    start,
    finish: end,
    timezone,
    recurrence_info: recurrenceInfo,
    devices: {},
  };

  Array.from(devices.entries()).forEach((pair) => {
    const [deviceId, state] = pair;

    const config: ScheduleContract.DeviceConfig = {channels: {}};

    Array.from(state.entries()).forEach((pair) => {
      const [channelId, state] = pair;

      if (state.recording || state.streaming) {
        config.channels[channelId] = {
          destination: state.destination,
          recording: state.recording,
          streaming: state.streaming,
          publishers: [...state.publishers],
        };
      }
    });

    result.devices[deviceId] = config;
  });

  return result;
}

function mapTeamConnections(r: Partial<Record<Schedule.Cms, number>>): Schedule.TeamConnection {
  let sum = 0;
  let hasExternal = false;
  let hasEdge = false;

  Object.entries(r).forEach((pair) => {
    const [cms, total] = pair as [Schedule.Cms, number];
    sum += total;

    if (cms !== 'chrono') {
      hasExternal = true;
    } else {
      hasEdge = true;
    }
  });

  return {hasEdge, hasExternal, total: sum};
}

function mapDeviceSchedule({
  cms,
  events,
}: ScheduleContract.DeviceSchedule): Schedule.DeviceSchedule {
  const mapped = new Map<string, Schedule.Event>(events.map(mapEvent).map((e) => [e.id, e]));

  return {
    cms: cms === 'none' ? undefined : cms,
    events: mapped,
  };
}

function mapTeamSchedule(c: ScheduleContract.TeamSchedule): Schedule.TeamSchedule {
  const connections = mapTeamConnections(c.cms_devices);

  const events = new Map<string, Schedule.Event>(c.events.map(mapEvent).map((e) => [e.id, e]));

  return {events, connection: connections};
}

export const ScheduleMapper = {
  mapToEventConfig,
  mapEvent,
  toEventRule,
  mapTeamConnections,
  mapDeviceSchedule,
  mapTeamSchedule,
};
