import React, {forwardRef} from 'react';
import {ListItemButton, SxProps, Theme, listItemButtonClasses} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {grey} from '@mui/material/colors';
import {NavText} from 'app/components/Navigation/shared/NavText';
import {palette} from 'app/themes/app';
import {DataId, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';

interface SectionProps extends DataId, Sx {
  expanded: boolean;
  icon?: React.ReactNode;
  compact?: boolean;
  label: string;
  onClick(): void;
}

const sectionButtonSx: SxProps<Theme> = {
  gap: 1.5,
  [`&:hover, &.${listItemButtonClasses.focusVisible}`]: {
    bgcolor: palette.dark1,
  },
};

export const SectionButton = forwardRef<HTMLDivElement | null, SectionProps>(
  ({dataId, sx, expanded, compact, label, icon, onClick}, ref) => {
    return (
      <ListItemButton ref={ref} data-id={dataId} sx={packSx(sectionButtonSx, sx)} onClick={onClick}>
        {icon ?? false}
        {!compact && (
          <>
            <NavText value={label} />
            {expanded ? (
              <ExpandLess sx={{color: grey[500]}} />
            ) : (
              <ExpandMore sx={{color: grey[500]}} />
            )}
          </>
        )}
      </ListItemButton>
    );
  },
);
