import {DeviceCapability} from 'app/components/DeviceDetails/constants';

class DeviceCapabilityService {
  canRecording(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.Recording);
  }

  canRecordViaState(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.StateRecording);
  }

  canUploading(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.Uploading);
  }

  canStreaming(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.StateStreaming);
  }

  canScheduling(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.StateScheduling);
  }

  canTranscribing(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.StateTranscribing);
  }

  canRemoteLogin(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.RemoteLogin);
  }

  canRebooting(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.Reboot);
  }

  supportsPresets(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.Presets);
  }

  supportsAudioLevels(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.AudioLevels);
  }

  supportsChannelPreviews(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.ChannelPreviews);
  }

  supportsCms(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.Cms);
  }

  supportsSourcePreviews(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.SourcePreviews);
  }

  supportsWsApi(capabilities: Set<DeviceCapability>) {
    return capabilities.has(DeviceCapability.WsApi);
  }
}

export const deviceCapabilityService = new DeviceCapabilityService();
