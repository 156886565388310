import {useQuery} from '@tanstack/react-query';
import {UserApiService} from 'app/services/api/user/UserApiService';
import {CloudMapper} from 'app/util/mappers/CloudMapper/CloudMapper';

export function useProfileQuery(enabled: boolean) {
  return useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const response = await UserApiService.getUser();
      return CloudMapper.mapApplicationUser(response);
    },
    enabled,
  });
}
