import React, {Component} from 'react';
import classNames from 'classnames';
import {front} from 'app/api/CirrusApi';
import {Icons} from 'app/util/icons';
import {CreateTokenForm} from 'app/components/Settings/ApiTokens/TokensPanel/CreateTokenForm';
import {TokensPanelList} from 'app/components/Settings/ApiTokens/TokensPanel/TokensPanelList';
import {
  descend,
  filter,
  find,
  map,
  prop,
  sort,
} from 'ramda';
import {ClassName} from 'app/types/common';

const byCreationTime = descend<any>(prop('CreationTime'));
const sortByCreationTime = sort(byCreationTime);

interface Props extends ClassName {
  teamId: string;
}

interface State {
  tokens: any[];
  loadingTokens: boolean;
}

export class TokensPanel extends Component<Props, State> {
  // rework any
  tokensEndpoint: any;
  constructor(props) {
    super(props);

    this.tokensEndpoint = front.teams(props.teamId).tokens;

    this.state = {
      tokens: [],
      loadingTokens: true,
    };
  }

  componentDidMount() {
    this.fetchTokens();
  }

  fetchTokens() {
    this.setState({loadingTokens: true});

    this.tokensEndpoint().get()
      .then(sortByCreationTime)
      .then(tokens => this.setState({tokens}))
      .finally(() => this.setState({loadingTokens: false}));
  }

  renderLoading() {
    return (
      <div className="tokens-panel__loading">
        {Icons.spinner().reactComponent()}
      </div>
    );
  }

  isUniqueTokenName = (tokenName, tokenId) => {
    const tokenWithSameName = find(({ID, Name}) => {
      if (
        Name === tokenName &&
        ID !== tokenId
      ) {
        return true;
      }

      return false;
    }, this.state.tokens);

    return Boolean(tokenWithSameName) === false;
  };

  renderTokensList() {
    return (
      <TokensPanelList
        tokens={this.state.tokens}
        endpoint={this.tokensEndpoint}
        isUniqueTokenName={this.isUniqueTokenName}
        onUpdate={this.handleUpdateToken}
        onDelete={this.handleDeleteToken}
      />
    );
  }

  handleCreateToken = (token) => {
    this.setState((state) => {
      return {
        tokens: [token].concat(state.tokens)
      };
    });
  };

  handleUpdateToken = (tokenId, tokenName) => {
    this.setState((state) => {
      return {
        tokens: map(token => {
          if (token.ID === tokenId) {
            return {
              ...token,
              Name: tokenName,
            };
          }

          return token;
        }, state.tokens)
      };
    });
  };

  handleDeleteToken = (tokenId) => {
    this.setState((state) => {
      return {
        tokens: filter(({ID}) => ID !== tokenId, state.tokens)
      };
    });
  };

  render() {
    const {
      className,
    } = this.props;
    const {
      loadingTokens,
    } = this.state;

    return (
      <div className={classNames('tokens-panel', className)}>
        <CreateTokenForm
          endpoint={this.tokensEndpoint}
          isUniqueTokenName={this.isUniqueTokenName}
          onCreate={this.handleCreateToken}
        />

        {loadingTokens ? this.renderLoading() : this.renderTokensList()}
      </div>
    );
  }
}
