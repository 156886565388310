import React from 'react';
import {Box} from '@mui/material';
import {
  ContextMenuItem,
  EllipsedContextMenu,
} from 'app/components/sharedReactComponents/ContextMenu';
import {SIZE} from 'app/constants';
import {Component, Sx, SxObj} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';

interface Props extends Component, Sx {
  items: ContextMenuItem[];
  disabled?: boolean;
}

const root: SxObj = {
  gridColumn: 'menu 1 / menu 2',
  '& .cr-btn': {width: '32px', height: '28px'},
};

export const EntityMenu = ({sx, items, disabled}: Props) => {
  return (
    <Box sx={packSx(root, sx)}>
      <EllipsedContextMenu
        size={SIZE.S}
        buttonDataId="meeting_card_context_menu"
        disabled={disabled}
        items={items}
      />
    </Box>
  );
};
