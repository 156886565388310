import React from 'react';
import {Skeleton} from '@mui/material';
import {Sx} from 'app/types/common';
import {DeviceStorage} from 'app/components/features/edge/EdgeDevice/StorageIndicator/DeviceStorage/DeviceStorage';
import {useDeviceStorage} from 'app/components/DeviceDetails/DeviceInfoTab/SystemInformation/useStorageInfo';

type Props = Sx & {
  deviceId: string;
  enabled: boolean;
};

export function StorageIndicator({sx, deviceId, enabled}: Props) {
  const query = useDeviceStorage({
    deviceId,
    enabled,
    staleTime: 30 * 1000,
    refetchInterval: 60 * 1000,
  });

  if (enabled && query.isLoading) {
    return <Skeleton sx={sx} variant="rounded" height={31} width={70} />;
  }

  if (query.isSuccess && query.data) {
    return <DeviceStorage sx={sx} storage={query.data} />;
  }

  return null;
}
