import {useEffect} from 'react';

interface Args {
  enabled: boolean;
}

export function useHubspotTracker({enabled}: Args) {
  useEffect(() => {
    if (enabled) {
      const script = document.createElement('script');

      script.src = '//js.hs-scripts.com/21530819.js';
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [enabled]);
}
