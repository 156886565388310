import {EdgeContract} from 'app/contracts/edge';
import {Edge} from 'app/domain/edge';
import {isNil} from 'app/util/isNil';
import dayjs from 'dayjs';

function mapSources(c: EdgeContract.Source[]): Edge.Sources {
  const video: Edge.VideoSource[] = [];
  const audio: Edge.AudioSource[] = [];

  c.forEach((c) => {
    const {id, name, status} = c;
    if (status.video) {
      const {state, resolution, vrr, actual_fps: fps, real_device_name} = status.video;

      const frameRate = vrr ? `${vrr} Hz` : fps ? `${fps} FPS` : '';

      video.push({
        id,
        name,
        resolution,
        frameRate,
        fps,
        hasSignal: !isNil(resolution) && resolution !== '0x0',
        isActive: state === 'active',
        realName: real_device_name ?? '',
      });
    }

    if (status.audio) {
      const {state, real_device_name} = status.audio;
      audio.push({
        id,
        name,
        isActive: state === 'active',
        realName: real_device_name ?? '',
      });
    }
  });

  return {video, audio};
}

function mapSystem(c: EdgeContract.System): Edge.System {
  return {
    load: c.cpu_load,
    temperature: c.cpu_temp,
    uptime: c.uptime_since,
  };
}

function mapStorage(c: EdgeContract.Storage): Edge.Storage {
  if (c.state !== 'ready') {
    return {total: 0, free: 0, used: 0};
  }

  const {free, total} = c;

  return {
    total,
    free,
    used: total - free,
  };
}

function mapTranscribeSection(c: EdgeContract.TranscribeSession): Edge.TranscribeSession {
  const {started_at, session_id, is_streaming, stopped_at} = c;

  return {
    id: session_id,
    name: buildSessionName(started_at, stopped_at, is_streaming),
    startedAt: started_at,
    stoppedAt: stopped_at,
    streaming: is_streaming,
  };
}

function buildSessionName(start: TimeStampSeconds, stop: TimeStampSeconds, isStreaming: boolean) {
  const startDate = dayjs.unix(start);

  if (isStreaming) {
    return startDate.format('YYYY-MM-DD_HH:mm');
  }

  const stopDate = dayjs.unix(stop);

  return `${startDate.format('YYYY-MM-DD_HH:mm')}-${stopDate.format(
    stopDate.isSame(startDate, 'd') ? 'HH:mm' : 'YYYY-MM-DD_HH:mm',
  )}`;
}

function mapTeamPreset(c: EdgeContract.TeamPreset): Edge.TeamPreset {
  const {id, name, sections, created_at, device_model, hash, description, size} = c;

  return {id, name, sections, hash, size, createdAt: created_at, device: device_model, description};
}

export const EdgeMapper = {
  mapSources,
  mapSystem,
  mapStorage,
  mapTranscribeSection,
  mapTeamPreset,
};
