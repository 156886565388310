import React, {useState} from 'react';
import {Box, CircularProgress, Divider, Link, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {ALL_ALERT_TYPES} from 'app/components/Settings/Alerts/AlertSettings/constants';
import {router} from 'app/router/main';
import {EmptyFilterMessage} from 'app/components/sharedReactComponents/EmptyFilterMessage/EmptyFilterMessage';
import {AlertTableBody} from 'app/components/Settings/Alerts/AlertSettings/AlertTable/AlertTableBody/AlertTableBody';
import {AlertTableHeader} from 'app/components/Settings/Alerts/AlertSettings/AlertTable/AlertTableHeader/AlertTableHeader';
import {Alerts} from 'app/components/entities/alerts';

interface Props extends Sx {
  settings: Alerts.Settings[];
  emptyFilterResults: boolean;
  loading: boolean;
  disabled?: boolean;
  getAlertGroup: (type: Alerts.AlertType) => Alerts.Group | undefined;
  onChange: (id: string, type: Alerts.AlertType, value: boolean) => void;
  onToggleAlert: (type: Alerts.AlertType, value: boolean) => void;
  onClearFilter: () => void;
}

export function AlertsTable({
  sx,
  settings,
  emptyFilterResults,
  loading = false,
  disabled = false,
  getAlertGroup,
  onChange,
  onClearFilter,
  onToggleAlert,
}: Props) {
  const [hoveredAlert, setHoveredAlert] = useState<Alerts.AlertType | undefined>(undefined);

  const renderBody = () => {
    if (loading) {
      return (
        <Stack p={2} direction="row" justifyContent="center" alignItems="center" gap={2}>
          <CircularProgress size={16} />
          <Typography>Loading device alerts</Typography>
        </Stack>
      );
    }

    if (settings.length > 0) {
      return (
        <AlertTableBody
          settings={settings}
          types={ALL_ALERT_TYPES}
          disabled={disabled}
          hoveredAlert={hoveredAlert}
          onChange={onChange}
        />
      );
    }

    if (emptyFilterResults) {
      return (
        <EmptyFilterMessage
          sx={{p: 2}}
          message="Filter applied, but no results found"
          buttonText="Reset filter"
          handleClear={onClearFilter}
        />
      );
    }

    return (
      <Typography p={2} textAlign="center">
        No devices have been paired with this team.{' '}
        <Link href={router.url('welcome')}>Pair a device</Link>
      </Typography>
    );
  };

  return (
    <Box sx={sx} className="alert-settings-table">
      <AlertTableHeader
        types={ALL_ALERT_TYPES}
        settings={settings}
        getAlertGroup={getAlertGroup}
        onToggleAlert={onToggleAlert}
        onHoverAlert={setHoveredAlert}
      />

      <Divider orientation="horizontal" flexItem={true} />

      {renderBody()}
    </Box>
  );
}
