import React, {PropsWithChildren} from 'react';
import {ClassName, DataId} from 'app/types/common';
import classNames from 'classnames';

interface Props extends PropsWithChildren<ClassName>, DataId {
  label?: string;
}

export const StreamSetting = ({className, dataId, label, children}: Props) => {
  return (
    <div className={classNames('stream-setting', className)} data-id={dataId}>
      {label && <div className="stream-setting__label">{label}</div>}

      {children}
    </div>
  );
};
