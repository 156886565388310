import React, {useMemo, useState} from 'react';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {grey} from '@mui/material/colors';
import {EventActions} from 'app/components/sharedReactComponents/Events/List/EventCard/EventActions/EventActions';
import {useToggle} from 'app/hooks/useToggle';
import {DataId, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {isNil} from 'app/util/isNil';
import {Schedule} from 'app/domain/schedule';
import {isCompleted, isOngoing, isRunningOrPaused, isScheduled} from 'app/domain/schedule/utils';
import {EventDevice} from 'app/components/sharedReactComponents/Events/List/EventCard/EventDevice/EventDevice';
import {DeviceLink} from 'app/components/sharedReactComponents/Events/List/EventCard/DeviceLink/DeviceLink';
import {UpdateMode} from 'app/components/sharedReactComponents/Events/Editor/EventForm/types';
import {DeleteEventDialog} from 'app/components/sharedReactComponents/Events/dialogs/DeleteEventDialog/DeleteEventDialog';
import {useEventMenuOptions} from 'app/components/sharedReactComponents/Events/List/EventCard/hooks/useEventMenuOptions';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {
  formatEventMedia,
  formatEventTime,
  formatEventDate,
} from 'app/components/sharedReactComponents/Events/utils';
import {EventMedia} from 'app/components/sharedReactComponents/Events/List/EventCard/EventMedia/EventMedia';
import {cardStyles} from 'app/components/sharedReactComponents/Events/styles';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {EventStatus} from 'app/components/sharedReactComponents/Events/shared/EventStatus/EventStatus';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';

interface Props extends Sx, DataId {
  event: Schedule.Event;
  devices?: Map<string, PearlMasterDeviceModel>;
  allowStart: boolean;
  onEdit: (id: string) => void;
  onDelete: (id: string, mode: UpdateMode) => Promise<void>;
  onStart(id: string): Promise<void>;
  onStop(id: string): Promise<void>;
  onPause(id: string): Promise<void>;
  onResume(id: string): Promise<void>;
  onExtend: (id: string) => Promise<void>;
  onConfirm: (id: string, deviceId: string) => Promise<void>;
}

export const EventCard = React.memo(
  ({
    dataId,
    sx,
    event,
    devices,
    allowStart,
    onPause,
    onResume,
    onStart,
    onStop,
    onExtend,
    onEdit,
    onDelete,
    onConfirm,
  }: Props) => {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

    const [expanded, toggleExpanded] = useToggle(false);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const openDeleteDialog = () => {
      setDeleteDialog(true);
    };

    const closeDeleteDialog = () => {
      setDeleteDialog(false);
    };

    const closeMenu = () => {
      setMenuAnchor(null);
    };

    const openMenu = (e: React.SyntheticEvent<HTMLElement>) => {
      setMenuAnchor(e.currentTarget);
    };

    const handleDelete = async (mode: UpdateMode) => {
      await onDelete(event.id, mode);
      closeDeleteDialog();
    };

    const editEvent = () => {
      onEdit(event.id);
      closeMenu();
    };

    const deleteEvent = () => {
      if (event.cms === 'chrono') {
        openDeleteDialog();
        closeMenu();
      }
    };

    const handleConfirm = async () => {
      const deviceId = [...event.devices.keys()][0];
      console.log('deviceId', deviceId);

      if (deviceId) {
        await onConfirm(event.id, deviceId);
      }
    };

    const offline = useMemo(() => {
      if (!devices) {
        return true;
      }

      return [...event.devices.keys()].some((deviceId) => {
        const instance = devices.get(deviceId);
        return instance ? instance.isOffline() : true;
      });
    }, [event, devices]);

    const handleExtend = async () => {
      await onExtend(event.id);
      closeMenu();
    };

    const options = useEventMenuOptions({
      event,
      offline,
      onDelete: deleteEvent,
      onEdit: editEvent,
      onExtend: handleExtend,
    });

    const showDevice = event.devices.size > 1;

    const ongoing = isOngoing(event.status);
    const scheduled = isScheduled(event.status);
    const completed = isCompleted(event.status);
    const runningOrPaused = isRunningOrPaused(event.status);

    const renderActions = () => {
      const hasMediaControls = ongoing || scheduled;

      return (
        <Stack direction="row" gap={0.5} alignItems="center">
          {runningOrPaused && <EventMedia event={event} devices={devices} offline={offline} />}

          {hasMediaControls && (
            <EventActions
              event={event}
              offline={offline}
              allowStart={allowStart}
              onStart={onStart}
              onStop={onStop}
              onResume={onResume}
              onPause={onPause}
              onConfirm={handleConfirm}
            />
          )}

          <IconButton
            data-id="more-actions"
            disabled={!options.length}
            disableRipple={false}
            onClick={openMenu}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu open={!isNil(menuAnchor)} anchorEl={menuAnchor} onClose={closeMenu}>
            {options.map((o) => (
              <MenuItem
                key={o.id}
                data-id={`${o.id}-button`}
                disabled={o.disabled}
                onClick={o.onClick}
              >
                <ListItemIcon>{o.icon}</ListItemIcon>
                <ListItemText>{o.name}</ListItemText>
              </MenuItem>
            ))}
          </Menu>

          <DeleteEventDialog
            open={deleteDialog}
            recurrent={true}
            name={event.title}
            onDelete={handleDelete}
            onClose={closeDeleteDialog}
          />

          <Tooltip content={expanded ? 'Collapse' : 'Expand'}>
            <IconButton data-id="details-toggler" disableRipple={false} onClick={toggleExpanded}>
              <ExpandMoreIcon
                sx={{transform: `rotate(${expanded ? 180 : 0}deg)`, transition: 'transform .3s'}}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    };

    return (
      <Box
        data-id={dataId}
        sx={packSx(
          cardStyles.root,
          ongoing && cardStyles.ongoing,
          scheduled && cardStyles.scheduled,
          completed && cardStyles.completed,
          sx,
        )}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          divider={<Divider flexItem={true} orientation="vertical" />}
        >
          <Box flexShrink={0} minWidth={0}>
            <Typography data-id="event-time" color={grey[700]} whiteSpace="pre" mb={0.5}>
              {formatEventTime(event.start, event.end)}
            </Typography>

            <Typography data-id="event-date" color={grey[700]} whiteSpace="pre">
              {formatEventDate(event.start, event.end)}
            </Typography>
          </Box>

          <Stack flexGrow={1} minWidth={0}>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              mb={0.5}
              divider={<Divider flexItem={true} orientation="vertical" />}
            >
              <Typography
                data-id="event-title"
                fontWeight={600}
                component="span"
                minWidth={0}
                maxWidth="100%"
              >
                <OverflowTooltip rowsNumber={1}>{event.title}</OverflowTooltip>
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              minHeight={22}
              divider={<Divider flexItem={true} orientation="vertical" />}
            >
              <EventStatus dataId="event-status" event={event} />

              <Typography data-id="event-type" whiteSpace="pre">
                {formatEventMedia(event.media)}
              </Typography>

              <DeviceLink sx={{minWidth: 0}} event={event} devices={devices} />
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
            {renderActions()}
          </Stack>
        </Stack>

        <Collapse in={expanded} unmountOnExit={true}>
          <Divider sx={{my: 0.5}} orientation="horizontal" flexItem={true} />

          <Stack
            direction="column"
            gap={1}
            divider={<Divider sx={{borderStyle: 'dashed'}} flexItem={true} />}
          >
            {Array.from(event.devices.entries()).map(([id, p]) => {
              const device = devices?.get(id);

              if (!device) {
                return <Skeleton key={id} variant="rounded" height={showDevice ? 40 : 22} />;
              }

              return (
                <EventDevice
                  key={id}
                  profile={p}
                  device={device}
                  showDevice={showDevice}
                  showPreview={ongoing}
                  active={ongoing}
                />
              );
            })}
          </Stack>
        </Collapse>
      </Box>
    );
  },
);
