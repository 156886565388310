import React, {useCallback} from 'react';
import classNames from 'classnames';
import {Callback, ClassName, DataId} from 'app/types/common';
import {KeyboardCode} from 'app/constants';

interface Props extends ClassName, DataId {
  title?: string;
  underlined?: boolean;
  onClick?: Callback;
}

export const PseudoLink: React.FC<Props> = ({
  className,
  dataId,
  title,
  underlined,
  children,
  onClick,
  ...elementProps
}) => {
  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent) => {
      if (onClick && event.code === KeyboardCode.Enter) {
        onClick();
      }
    },
    [onClick],
  );

  return (
    <span
      className={classNames(
        'pseudo-link',
        underlined && 'pseudo-link--variant-underlined',
        className,
      )}
      data-id={dataId}
      title={title}
      tabIndex={0}
      {...elementProps}
      onKeyUp={handleKeyUp}
      onClick={onClick}
    >
      {children}
    </span>
  );
};
