/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {observer} from 'mobx-react';
import {Component} from 'app/types/common';
import {SrtStream} from 'app/store/models/connect/SrtStream/SrtStream';
import {CopyButton} from 'app/components/sharedReactComponents/CopyButton';
import {THEME} from 'app/constants';
import {isNil} from 'app/util/isNil';
import classNames from 'classnames';
import {isParticipantStreamSettings} from 'app/types/guards/connect';
import {router} from 'app/router/main';

interface Props extends Component {
  stream: SrtStream;
}

export const SrtDestination = observer(({className, stream, dataId}: Props) => {
  const {settings} = stream;

  if (isNil(settings)) {
    return null;
  }

  if (isParticipantStreamSettings(settings) && settings.integration) {
    return (
      <div data-id={dataId} className={classNames('srt-destination', className)}>
        <CloudCDestination
          isConnected={stream.connected}
          isDevice={settings.cloud.type === 'device'}
          id={settings.cloud.deviceId}
          name={settings.cloud.deviceName}
        />
      </div>
    );
  }

  return (
    <div data-id={dataId} className={classNames('srt-destination', className)}>
      <CustomDestination
        mode={settings.mode}
        isConnected={stream.connected}
        port={`${settings.port}`}
        url={settings.url}
      />
    </div>
  );
});

interface DestinationProps {
  isConnected: boolean;
}

interface CustomDestinationProps extends DestinationProps {
  mode: App.Connect.SteamMode;
  port: string;
  url: string;
}

function CustomDestination({mode, isConnected, url, port}: CustomDestinationProps) {
  const isListener = mode === 'listener';

  return (
    <>
      <div data-id="srt_mode">{getModeText(mode === 'caller', isConnected)},</div>

      <CopyButton
        className="srt-destination__copy"
        dataId="copy_srt_url"
        placement="left"
        text="URL"
        value={url}
        theme={THEME.SUCCESS}
      />

      {isListener && (
        <CopyButton
          className="srt-destination__copy"
          dataId="copy_srt_port"
          placement="left"
          text={port}
          value={port}
          theme={THEME.SUCCESS}
        />
      )}
    </>
  );
}

function getModeText(isCaller: boolean, isConnected: boolean) {
  if (isCaller) {
    if (isConnected) {
      return 'Caller';
    }

    return 'Caller to';
  }

  return 'Listener';
}

interface CloudDestinationProps extends DestinationProps {
  isDevice: boolean;
  id: string;
  name: string;
}

function CloudCDestination({isConnected, isDevice, name, id}: CloudDestinationProps) {
  return (
    <span>
      <span data-id="destination_label">{isConnected ? 'Connected to' : 'Connecting to'} </span>
      <a
        target="_blank"
        data-id="destination_entity"
        href={router.url(isDevice ? 'deviceDetails' : 'projectDetails', {deviceId: id})}
      >
        {name}
      </a>
    </span>
  );
}
