/* eslint-disable react/no-danger */
import React, {useRef} from 'react';
import * as DOMPurify from 'dompurify';
import {Box} from '@mui/material';
import {useComponentDidMount} from 'app/hooks/useComponentDidMount';
import {Sx, Component} from 'app/types/common';

interface Props extends Sx, Component {
  autoFocus?: boolean;
  content: string;
}

export function DangerouslyHTML({sx, dataId, className, content, autoFocus}: Props) {
  const contentRef = useRef<HTMLDivElement>(null);

  useComponentDidMount(() => {
    if (autoFocus) {
      (contentRef.current as any).focus();
    }
  });

  return (
    <Box
      sx={sx}
      data-id={dataId}
      className={className}
      ref={contentRef}
      component="div"
      dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content)}}
      tabIndex={autoFocus ? 0 : undefined}
    />
  );
}
