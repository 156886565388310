import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useEffect, useMemo, useRef} from 'react';
import {WS} from 'app/api/WebSocket/WS';
import {Edge} from 'app/domain/edge';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {stringComparator} from 'app/util/Sort';
import {Ws} from 'app/contracts/ws';

interface Args {
  teamId: string;
  enabled: boolean;
}

export function useEdgeGroups({teamId, enabled}: Args) {
  const client = useQueryClient();

  const queueRef = useRef<Ws.GroupChange[]>([]);

  const {data, isSuccess, refetch} = useQuery({
    queryKey: ['edge', 'groups', teamId],
    queryFn: async () => {
      const res = await DeviceApiService.getGroups(teamId);
      return new Map(res.map((g) => [g.id, g]));
    },
    enabled,
  });

  useEffect(() => {
    const onGroupChange = (message: Ws.GroupChange) => {
      if (message.Body.Action === 'deleted') {
        queueRef.current.push(message);
        return;
      }

      client.setQueryData(
        ['edge', 'groups', teamId],
        (prev: Map<string, Edge.Group> | undefined) => {
          if (!prev) {
            return prev;
          }

          let copy = new Map(prev);
          copy = dispatchMessage(copy, message);
          return copy;
        },
      );
    };

    WS.onDeviceGroupChange(onGroupChange);

    return () => {
      WS.offDeviceGroupChange(onGroupChange);
    };
  }, [client, teamId]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    const id = window.setInterval(() => {
      if (!queueRef.current.length) {
        return;
      }

      client.setQueryData(
        ['edge', 'groups', teamId],
        (prev: Map<string, Edge.Group> | undefined) => {
          if (!prev) {
            return prev;
          }

          let copy = new Map(prev);

          queueRef.current.forEach((message) => {
            copy = dispatchMessage(copy, message);
          });

          queueRef.current = [];

          return copy;
        },
      );
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [client, isSuccess, teamId]);

  const groups = useMemo(
    () => (data ? [...data.values()] : []).sort((a, b) => stringComparator(a.name, b.name)),
    [data],
  );

  return {groups, ready: isSuccess, refetch};
}

function dispatchMessage(
  map: Map<string, Edge.Group>,
  message: Ws.GroupChange,
): Map<string, Edge.Group> {
  const {Action: action} = message.Body;

  switch (action) {
    case 'deleted': {
      const {GroupID: groupId} = message.Body;
      map.delete(groupId);
      break;
    }

    case 'created':
    case 'changed': {
      const {GroupID: groupId, GroupName: name} = message.Body;
      map.set(groupId, {id: groupId, name});
      break;
    }

    default:
      break;
  }

  return map;
}
