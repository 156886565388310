import React, {Component} from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {ProgressButton, BUTTON_TYPE} from 'app/components/sharedReactComponents/ProgressButton';
import {
  THEME,
  VARIANT,
} from 'app/constants';
import {TOKEN_ERROR} from 'app/components/Settings/ApiTokens/TokensPanel/constants';
import {
  getErrorTextByError,
  normalizeTokenName,
} from 'app/components/Settings/ApiTokens/TokensPanel/utils';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  endpoint: Callback;
  isUniqueTokenName: Callback;
  onCreate: Callback;
}

interface State {
  name: string;
  creating: boolean;
  error: any;
}

export class CreateTokenForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      creating: false,
      error: null,
    };
  }

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value,
      error: null,
    });
  };

  handleNameFocus = () => {
    this.setState({error: this.getNewNameError()});
  };

  getNewNameError() {
    const newName = normalizeTokenName(this.state.name);

    if (!this.props.isUniqueTokenName(newName)) {
      return {type: TOKEN_ERROR.UNIQUE_NAME};
    }

    return null;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const normalizedName = normalizeTokenName(this.state.name);

    const error = this.getNewNameError();

    if (error) {
      this.setState({error});
      return;
    }

    this.setState({creating: true});

    this.props.endpoint().post({
      Name: normalizedName,
    })
      .then(token => {
        this.props.onCreate(token);
        this.setState({name: ''});
      })
      .finally(() => {
        this.setState({creating: false});
      });
  };

  render() {
    const {
      className,
    } = this.props;
    const {
      name,
      creating,
      error,
    } = this.state;

    const hasError = Boolean(error);
    const normalizedName = normalizeTokenName(name);

    return (
      <form
        className={classNames('create-token-form', className)}
        action=""
        autoComplete="off"
        noValidate={true}
        onSubmit={this.handleSubmit}
      >
        <div className="create-token-form__title">
          Create API token
        </div>

        <p className="create-token-form__description">
          Developers! Easily integrate Epiphan&nbsp;Video's Pearl&nbsp;family of encoders into your AV&nbsp;solution using the Epiphan&nbsp;Cloud REST&nbsp;API.<br/>
          {' '}
          Remotely control recording, manage settings and more for any Pearl&nbsp;encoder that's paired to your Epiphan&nbsp;Cloud account.<br/>
          <a href="https://epiphan-video.github.io/avstudio_api/" target="_blank" rel="noopener noreferrer">Click</a>&nbsp;for&nbsp;details or go directly to the&nbsp;<a href="https://github.com/epiphan-video/avstudio_api" target="_blank" rel="noopener noreferrer">Epiphan&nbsp;Cloud&nbsp;API</a>.
        </p>

        <div className="create-token-form__row">
          <FormControl
            // rework required name
            name={name}
            className="create-token-form__input"
            label="Token name"
            value={name}
            helperText={hasError && getErrorTextByError(error)}
            error={hasError}
            disabled={creating}
            onChange={this.handleNameChange}
            onFocus={this.handleNameFocus}
          />
        </div>

        <ProgressButton
          type={BUTTON_TYPE.SUBMIT}
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          disabled={normalizedName === ''}
          loading={creating}
        >
          {Icons.share().label('Create API token').reactComponent()}
        </ProgressButton>
      </form>
    );
  }
}
