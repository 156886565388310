import React from 'react';
import {Component, Sx} from 'app/types/common';
import {Box, Stack, Typography} from '@mui/material';
import {packSx} from 'app/util/packSx/packSx';

interface Props extends Sx, Component {
  title: string;
  subtitle?: React.ReactNode;
  description?: React.ReactNode;
  action?: React.ReactNode;
}

export function TierCard({dataId, sx, title, subtitle, description, action: actions}: Props) {
  return (
    <Stack
      component="section"
      alignItems="center"
      gap={1}
      sx={packSx(
        {
          minWidth: 260,
          width: 260,
          height: 390,
          py: 4,
          px: 2,
          flexShrink: 0,
          flexGrow: 0,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        },
        sx,
      )}
      data-id={dataId}
    >
      <Stack component="header" gap={0.5} alignItems="center">
        <Typography data-id="tier-title" variant="h5" fontWeight={600}>
          {title.toUpperCase()}
        </Typography>
        {subtitle ?? false}
      </Stack>

      {description && <Box component="section">{description}</Box>}

      {actions && (
        <Box component="section" sx={{mt: 'auto'}}>
          {actions}
        </Box>
      )}
    </Stack>
  );
}
