import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import {ClassName, TimeStampSeconds} from 'app/types/common';
import {Progress} from 'app/components/sharedReactComponents/Progress';
import {isNil} from 'app/util/isNil';

interface Props extends ClassName, Args {}

export const ConnectionProgress = ({className, timestamp, zoomId, platform}: Props) => {
  const {value, estimated, status, state} = useConnectionProgress({platform, timestamp, zoomId});
  const normal = state === 'normal';

  return (
    <div className={classNames('connection-progress', className)}>
      <Progress
        dataId="connection_status_progress"
        className="connection-progress__progress"
        barClassName={classNames('connection-progress__bar', {
          'connection-progress__bar--normal': normal,
        })}
        value={value}
        max={105}
      />

      <div className="connection-progress__info">
        <span
          data-id="progress-text-desc"
          className={classNames('connection-progress__status', {
            'connection-progress__status--normal': normal,
          })}
        >
          {status}
        </span>
        {estimated && <span>{estimated}</span>}
      </div>
    </div>
  );
};

type State = 'normal' | 'problem';

interface Return {
  status: string;
  state: State;
  estimated: string;
  value: number;
}

const UPDATE_INTERVAL_IN_SEC = 2000;

interface Args {
  timestamp: TimeStampSeconds;
  zoomId?: string;
  platform: App.Connect.Platform;
}

function useConnectionProgress({platform, timestamp, zoomId}: Args): Return {
  const rafId = useRef<number>(0);
  const [estimated, setEstimated] = useState('');
  const [state, setState] = useState<State>('normal');
  const [status, setStatus] = useState('');
  const [value, setValue] = useState(0);

  useEffect(() => {
    let lastCallTimestamp: number | undefined;

    const isZoom = platform === 'zoom';
    const hasId = isZoom && !isNil(zoomId);

    const step = (time: number) => {
      if (isNil(lastCallTimestamp) || time - lastCallTimestamp < UPDATE_INTERVAL_IN_SEC) {
        const diff = moment().diff(moment.unix(timestamp), 'seconds');

        const meetingId = zoomId ? `meeting ID ${zoomId}` : '';

        if (diff <= 360) {
          setState('normal');
          setEstimated('Estimated time: 5 min');
          const next = diff / 4.5;
          setValue((prev) => (prev <= 80 ? next : 80));

          if (diff <= 150) {
            setStatus(`Initializing${hasId ? `: ${meetingId}` : '...'}`);
          } else if (diff <= 300) {
            setStatus(`Connecting${hasId ? `: ${meetingId}` : '...'}`);
          } else {
            setStatus(
              `Starting soon...${isZoom ? ' meeting host might receive a pop-up in Zoom' : ''}`,
            );
          }
        } else {
          if (diff > 720) {
            setValue(100);
          } else {
            const rest = diff - 360;
            const sum = rest / 18;
            setValue(80 + sum);
          }
          setState('problem');
          setEstimated('');
          setStatus(`Something is taking longer than expected${hasId ? `: ${meetingId}` : '...'}`);
        }
      }

      rafId.current = requestAnimationFrame(step);
    };

    rafId.current = requestAnimationFrame(step);

    return () => {
      cancelAnimationFrame(rafId.current);
      rafId.current = 0;
    };
  }, [timestamp, zoomId, platform]);

  return {
    estimated,
    state,
    status,
    value,
  };
}
