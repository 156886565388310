import {render} from 'react-dom';
import {createElement} from 'react';
import {
  ToastContainer,
  toast,
  ToastContent,
  ToastOptions,
} from 'react-toastify';

const SHOW_NOTIFICATION_TIMEOUT = 5000;

const Model = {
  _initialize() {
    this._render();
  },

  _render() {
    document.addEventListener('DOMContentLoaded', () => {
      render(createElement(ToastContainer, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: SHOW_NOTIFICATION_TIMEOUT,
        theme: 'colored',
        icon: false,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        // pauseOnVisibilityChange: true,
        draggable: false,
        pauseOnHover: true,
      }), document.querySelector('#notification-container'));
    });
  },
};

Model._initialize();

const Notifications = {
  addNotification(content: ToastContent, {
    className,
    progressClassName,
    autoClose,
    closeOnClick = true,
    ...rest
  }: ToastOptions) {
    toast(content, {
      className,
      progressClassName,
      autoClose,
      closeOnClick,
      ...rest,
    });
  },

  addInfoNotification(content: ToastContent) {
    toast.info(content);
  },

  addSuccessNotification(content: ToastContent) {
    toast.success(content);
  },

  addWarningNotification(content: ToastContent) {
    toast.warning(content);
  },

  addErrorNotification(content: ToastContent) {
    toast.error(content, {
      autoClose: false,
    });
  },
};

export {
  Notifications,
};
