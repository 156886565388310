import {front} from 'app/api/CirrusApi';
import {Afu} from 'app/components/entities/afu/model';

export class AfuApi {
  static async getAfu(deviceId: string): Promise<Afu.Configuration | null> {
    return front.devices(deviceId).afu().get();
  }

  static async setProtocol(deviceId: string, protocol: string) {
    return front.devices(deviceId).afu().post({protocol});
  }
}
