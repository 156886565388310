import React from 'react';
import {TableCell, tableCellClasses, TableCellProps} from '@mui/material';
import {packSx} from 'app/util/packSx/packSx';
import {StyleSx} from 'app/types/common';

const rootSx: StyleSx = {
  [`&.${tableCellClasses.head}`]: {py: 1.5, px: 1, fontSize: 14, fontWeight: 'bold'},
  [`&.${tableCellClasses.body}`]: {py: 1.5, px: 1, fontSize: 14},
};

export function InvoiceCell({sx, ...rest}: TableCellProps) {
  return <TableCell sx={packSx(rootSx, sx)} {...rest} />;
}
