import React from 'react';
import {CircularProgress, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';

type Props = Sx & {};

export function RecordingsSkeleton({sx}: Props) {
  return (
    <Stack sx={sx} direction="row" gap={1} alignItems="center" justifyContent="center">
      <CircularProgress />

      <Typography variant="h6">Loading recordings</Typography>
    </Stack>
  );
}
