import React, {useCallback, useEffect, useState} from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {Callback, Component, Sx, TimeStampSeconds} from 'app/types/common';
import {capitalize} from 'app/components/helpers/commonHelpers';
import {Countdown} from 'app/components/sharedReactComponents/Countdown';
import {counterFormat} from 'app/util/time';
import {calcTime} from 'app/components/sharedReactComponents/Countdown/utils';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {ConnectionProgress} from 'app/components/MeetingsManager/cards/MeetingCard/columns/MeetingStatusColumn/ConnectionProgress/ConnectionProgress';
import {PermissionStatus} from 'app/components/MeetingsManager/cards/MeetingCard/columns/MeetingStatusColumn/PermissionStatus/PermissionStatus';
import {palette} from 'app/themes/app';
import {isNil} from 'app/util/isNil';
import {Icons} from 'app/util/icons';
import {THEME} from 'app/constants';

type State = App.Connect.MeetingStatus | 'deleting';

interface Props extends Component, Sx {
  status: App.Connect.MeetingStatus;
  platform: App.Connect.Platform;
  permission?: App.Connect.Permission;
  error?: string;
  createdAt: TimeStampSeconds;
  startedAt?: TimeStampSeconds;
  disconnectedAt?: TimeStampSeconds;
  deleteTimeout?: number;
  zoomId?: string;
}

export function MeetingStatus({
  status,
  permission,
  platform,
  createdAt,
  deleteTimeout,
  startedAt,
  disconnectedAt,
  error,
  zoomId,
  sx,
}: Props) {
  const [state, setState] = useState<State>(status);

  useEffect(() => {
    setState(status);
  }, [status]);

  const onTimeIsOut = useCallback(() => setState('deleting'), []);

  if (state === 'error') {
    return (
      <Box sx={sx}>
        <Tooltip content={error}>
          <Stack color={palette.red} direction="row" alignItems="center" gap={0.5}>
            {Icons.warning().theme(THEME.DANGER).reactComponent()}
            <Typography>Connection failed</Typography>
          </Stack>
        </Tooltip>
      </Box>
    );
  }

  if (state === 'starting') {
    return (
      <Box sx={sx}>
        <ConnectionProgress platform={platform} zoomId={zoomId} timestamp={createdAt} />
      </Box>
    );
  }

  const permissionIssue = state === 'running' && !isNil(permission) && permission !== 'ok';

  const waiting =
    state === 'waiting-for-account' ||
    state === 'in-waiting-room' ||
    state === 'waiting-for-host' ||
    state === 'waiting-for-password';

  if ((permissionIssue || waiting) && startedAt) {
    return (
      <Box sx={sx}>
        <PermissionStatus
          id={zoomId}
          permission={permission}
          startedAt={startedAt}
          status={state}
        />
      </Box>
    );
  }

  const disconnected = state === 'disconnected';
  const deletionTimestamp =
    disconnectedAt && deleteTimeout ? disconnectedAt + deleteTimeout : undefined;

  const showCountdown = disconnected && !isNil(deletionTimestamp);

  return (
    <Box sx={sx}>
      <Typography
        data-id="meeting_status_text"
        sx={{color: statusColor(status), height: '24px'}}
        component="span"
      >
        {formatStatus(state)}
      </Typography>

      {!isNil(startedAt) && state === 'running' && renderUptime(startedAt)}
      {showCountdown && renderCountdown(deletionTimestamp, disconnected, onTimeIsOut)}
    </Box>
  );
}

function statusColor(status: App.Connect.MeetingStatus) {
  switch (status) {
    case 'connecting':
    case 'disconnecting': {
      return palette.blue;
    }
    case 'error': {
      return palette.red;
    }

    case 'running': {
      return palette.green;
    }
    default:
      return palette.darkerGray;
  }
}

function formatStatus(state: State) {
  let value: string = state;

  switch (state) {
    case 'connecting': {
      value = 'Joining...';
      break;
    }

    default: {
      break;
    }
  }

  return capitalize(value);
}

function renderUptime(startedAt: TimeStampSeconds) {
  return (
    <Box sx={{color: palette.blue, fontSize: '12px'}}>
      <Countdown
        dataId="meeting_uptime"
        timestamp={startedAt}
        calculate={calcTime}
        format={counterFormat}
      />
    </Box>
  );
}

function renderCountdown(deletedAt: TimeStampSeconds, showTip: boolean, onOut: Callback) {
  const tip = 'When time runs out, the meeting will be deleted.';
  return (
    <Box sx={{color: palette.red, fontSize: '12px'}}>
      <Countdown
        dataId="meeting_deletion_timer"
        timestamp={deletedAt}
        tooltip={showTip ? tip : undefined}
        onTimeIsOut={onOut}
      />
    </Box>
  );
}
