import {useQuery} from '@tanstack/react-query';
import {MeetingsApiService} from 'app/services/api/meetings/MeetingsApiService';
import {ConnectMapper} from 'app/util/mappers/ConnectMapper/ConnectMapper';

interface Args {
  enabled: boolean;
  teamId: string;
}

async function queryTenants(): Promise<Contracts.Connect.Auth.TeamsTenant[]> {
  const [list, states] = await Promise.all([
    MeetingsApiService.getTenants(),
    MeetingsApiService.getTenantHealth(),
  ]);

  return list.map((tenant) => {
    const state = states.find((s) => s.id === tenant.id);

    return {
      ...tenant,
      healthy: state?.healthy ?? false,
    };
  });
}

function mapTenants(c: Contracts.Connect.Auth.TeamsTenant[]): App.Connect.Auth.TeamsTenant[] {
  return c.map(ConnectMapper.mapTeamsTenant);
}

export function useTeamTenants({enabled, teamId}: Args) {
  return useQuery({
    queryKey: ['teams-tenants', teamId],
    queryFn: queryTenants,
    enabled,
    select: mapTenants,
  });
}
