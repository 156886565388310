import React, {useState} from 'react';
import {Box, Stack, SxProps, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import Joi from 'joi';
import {joiResolver} from '@hookform/resolvers/joi';
import {BUTTON_TYPE, ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {THEME, VARIANT} from 'app/constants';
import {Callback} from 'app/types/common';
import {palette} from 'app/themes/app';
import {isNil} from 'app/util/isNil';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {useMounted} from 'app/hooks/useIsMounted';
import {Notifications} from 'app/components/Notifications';

interface CreateData {
  name: string;
}

const scheme = Joi.object<CreateData>({
  name: Joi.string()
    .trim()
    .custom((value, helpers) => {
      if (typeof value === 'string') {
        const urlScheme = Joi.string().uri();

        const result = urlScheme.validate(value);

        if (!result.error) {
          return helpers.error('string.uri');
        }
      }

      return value;
    })
    .required(),
}).messages({
  'any.required': 'Required field',
  'string.empty': 'Cannot be empty',
  'string.uri': 'Meeting name cannot be a URL',
});

interface Props {
  sx?: SxProps;
  onCreate: Callback<Promise<any>, [name: string]>;
}

export function CreateTeamsCall({sx, onCreate}: Props) {
  const [confirming, setConfirming] = useState(false);
  const mounted = useMounted();

  const {
    control,
    formState: {isValid},
    handleSubmit,
  } = useForm<CreateData>({
    defaultValues: {name: ''},
    mode: 'onChange',
    resolver: joiResolver(scheme),
  });

  const handleCreate = async (data: CreateData) => {
    try {
      setConfirming(true);
      await onCreate(data.name);
    } catch {
      Notifications.addErrorNotification(`Could not create a meeting`);
    } finally {
      if (mounted()) {
        setConfirming(false);
      }
    }
  };

  return (
    <Box sx={sx}>
      <form onSubmit={handleSubmit(handleCreate)}>
        <Box
          mb={2}
          component="img"
          src="/assets/img/logo/teams-logo.png"
          width={173}
          height={28}
          srcSet="/assets/img/logo/teams-logo@2x.png 2x"
          alt=""
        />

        <Typography variant="h5" fontWeight={600}>
          Create a Microsoft Teams meeting
        </Typography>

        <Box sx={{color: palette.darkerGray, mt: 1, mb: 5}}>
          Create a meeting link to share with your participants and produce your
          <br /> live event. <strong>This will not join existing meetings</strong>.
        </Box>

        <Box sx={{fontSize: 16, fontWeight: 600, mb: 1}}>New meeting name:</Box>

        <Controller
          control={control}
          name="name"
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              autoFocus={true}
              inputProps={{'data-id': 'hosted-meeting-name'}}
              variant="standard"
              placeholder="Add your meeting name"
              fullWidth={true}
              error={!isNil(error)}
              helperText={error?.message ?? ' '}
            />
          )}
        />

        <Stack sx={{color: palette.gray, mb: 5}} direction="row" alignItems="center" gap={1}>
          <AccessTimeIcon fontSize="small" />
          The meeting link will only be available for the next 45 days
        </Stack>

        <Box sx={{color: palette.darkerGray, mb: 5.5}}>
          You won't be charged until you click on <i>Start Connect</i> button, after the meeting is
          created.
        </Box>

        <Box sx={{'& button': {width: 154}}}>
          <ProgressButton
            type={BUTTON_TYPE.SUBMIT}
            loading={confirming}
            dataId="call_create_button"
            theme={THEME.PRIMARY}
            variant={VARIANT.SOLID}
            disabled={!isValid}
          >
            Create
          </ProgressButton>
        </Box>
      </form>
    </Box>
  );
}
