import React, {useEffect, useState} from 'react';
import {SIZE} from 'app/constants';
import {ClassName, DataId} from 'app/types/common';
import {StatusImageLoading} from 'app/components/sharedReactComponents/StatusImage/StatusImageLoading';
import {PreviewUpdateStrategy} from 'app/hooks/Device/Preview/types';
import {getPreviewUrl} from 'app/components/sharedReactComponents/Preview/utils';
import {useUpdatePreviewByInterval} from 'app/hooks/Device/Preview/useUpdatePreviewByInterval';

interface Props extends ClassName, DataId {
  deviceId: string;
  updateStrategy: PreviewUpdateStrategy;
  size?: SIZE;
  alt?: string;
}

export const DevicePreview: React.VFC<Props> = ({
  className,
  deviceId,
  updateStrategy,
  size,
  dataId,
  alt = '',
}) => {
  const [imageSrc, setImageSrc] = useState<string>();
  const [baseSrc, setBaseSrc] = useState(() =>
    getPreviewUrl(deviceId, updateStrategy, size === SIZE.XXL),
  );

  useEffect(() => {
    setBaseSrc(getPreviewUrl(deviceId, updateStrategy, size === SIZE.XXL));
  }, [deviceId, updateStrategy, size]);

  useUpdatePreviewByInterval({
    initialImageSrc: baseSrc,
    setImageSrc,
    immediately: true,
  });

  if (imageSrc && size) {
    return (
      <div className={className}>
        <StatusImageLoading size={size} />
      </div>
    );
  }

  return <img className={className} src={imageSrc} data-id={dataId} alt={alt} />;
};
