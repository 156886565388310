import React from 'react';
import {Box, Paper} from '@mui/material';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {DeviceAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/DeviceAudioMeter';
import {LiveScryptDeviceModel} from 'app/components/DeviceDetails/Models/LiveScryptDeviceModel';
import {isDisabledAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/utils';
import {ActionButtons} from 'app/components/sharedReactComponents/ActionButtons/ActionButtons';
import {bodyStyles} from 'app/components/features/edge/EdgeDevice/body/body.styles';
import {noop} from 'app/util/noop';

type Props = Sx & {
  selected: boolean;
  device: LiveScryptDeviceModel;
};

const meterSx: StyleSx = {height: 20, width: 96};

const destinations = [];

export function LivescryptDetails({sx, device, selected}: Props) {
  return (
    <Paper sx={packSx(bodyStyles.root, selected && bodyStyles.selected, sx)} variant="outlined">
      <Box flex={1}>
        <DeviceAudioMeter
          sx={meterSx}
          id={device.getId()}
          disabled={isDisabledAudioMeter(device)}
          segmentsCount={13}
          segmentsGap={3}
        />
      </Box>

      <ActionButtons
        device={device}
        editable={false}
        streamingDestinations={destinations}
        getDeviceById={noop}
        onChangeStreamingDestination={noop}
      />
    </Paper>
  );
}
