import React from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import {Prompt} from 'app/types/common';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';

interface Props extends Prompt {
  state: boolean;
  setState: Fn<any, [state: boolean]>;
}

export function StartingZoomMeetingDialog({open, state, setState, onClose}: Props) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState(e.target.checked);
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle fontWeight={600} variant="h5">
        Connect is initializing...
        <br />
        Some permissions are needed
      </DialogTitle>
      <DialogContent>
        <Stack alignItems="center">
          <Typography textAlign="center">
            The Zoom meeting host might receive a pop-up asking to approve
            <br /> permissions for Epiphan Connect. These permissions must be <br />
            approved for Connect to work.
          </Typography>

          <Box sx={{mt: 2, mb: 5}}>
            <img
              src="/assets/img/connect/permission-request.png"
              srcSet="assets/img/connect/permission-request@2x.png 2x"
              alt="zoom recoding modal"
              width={390}
              height={178}
            />
          </Box>

          <FormControlLabel
            control={<Checkbox data-id="checkbox" checked={state} onChange={handleChange} />}
            label="Don't show this again"
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{'& > button': {minWidth: '170px'}}}>
        <Button
          dataId="confirm-button"
          variant={VARIANT.SOLID}
          theme={THEME.SUCCESS}
          onClick={onClose}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
