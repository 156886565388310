import React from 'react';
import classNames from 'classnames';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  available?: boolean;
  wrap?: boolean;
}

export function AvailableForProPlanTooltip({
  className,
  available,
  wrap = true,
  children,
}: React.PropsWithChildren<Props>) {
  if (available) {
    return <>{children}</>;
  }

  return (
    <Tooltip content="Available for Premium plan only">
      {wrap ? (
        <span className={classNames('available-for-pro-plan-tooltip', className)}>{children}</span>
      ) : (
        children
      )}
    </Tooltip>
  );
}
