import React from 'react';
import {Stack, Typography} from '@mui/material';
import {Component, Sx} from 'app/types/common';
import {palette} from 'app/themes/app';

interface Props extends Sx, Component {
  title: string;
  value: React.ReactNode;
  discount?: React.ReactNode;
  message?: React.ReactNode;
}

export function TierPrice({sx, title, value, message, discount}: Props) {
  return (
    <Stack sx={sx} component="section" alignItems="center">
      <Typography fontSize="12px" fontWeight={600} sx={{color: palette.darkerGray}}>
        {title}
      </Typography>
      {value}
      {discount ?? false}
      {message ?? false}
    </Stack>
  );
}
