import React, {useCallback} from 'react';
import {Button} from 'app/components/UploadLocations/NewLocationTypeSelector/Button';
import {STORAGE_TYPE} from 'app/components/UploadLocations/constants';
import {Callback} from 'app/types/common';

interface Props {
  onSelect: Callback;
}

export function NewLocationTypeSelector({
  onSelect,
}: Props) {
  const handleClick = useCallback((type) => {
    onSelect(type);
  }, [onSelect]);

  return (
    <div className="cr-new-location-selector">
      <h3 className="cr-new-location-selector__title">
        Choose shared network location type.
      </h3>

      <div className="cr-new-location-selector__content">
        <Button
          type={STORAGE_TYPE.FTP}
          name="FTP / SFTP"
          onClick={handleClick}
        />

        <Button
          type={STORAGE_TYPE.GOOGLE_DRIVE}
          name="Google Drive"
          onClick={handleClick}
        />

        <Button
          type={STORAGE_TYPE.BOX}
          name="Box"
          onClick={handleClick}
        />

        <Button
          type={STORAGE_TYPE.DROPBOX}
          name="Dropbox"
          onClick={handleClick}
        />
      </div>
    </div>
  );
}
