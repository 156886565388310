import React, {useState} from 'react';
import {Button, Popover, Tooltip, Typography} from '@mui/material';
import MemoryIcon from '@mui/icons-material/Memory';
import {packSx} from 'app/util/packSx/packSx';
import {StyleSx, Sx} from 'app/types/common';
import {FirmwareState} from 'app/components/features/edge';

const rootSx: StyleSx = {py: 0.5, px: 1, gap: 0.5};
const paperSx: StyleSx = {p: 1, minWidth: 132};

type Props = Sx & {
  deviceId: string;
  firmware: string;
  checkUpdates: boolean;
  onUpgrade: () => Promise<any>;
};

export function FirmwareIndicator({sx, deviceId, firmware, checkUpdates, onUpgrade}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <Tooltip title="Firmware">
        <Button
          data-id="firmware-btn"
          sx={packSx(rootSx, sx)}
          variant="outlined"
          color="info"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <MemoryIcon />

          <Typography data-id="device_card_firmwareVersion" noWrap={true}>
            {firmware}
          </Typography>
        </Button>
      </Tooltip>

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        slotProps={{paper: {sx: paperSx}}}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        onClose={() => setAnchorEl(null)}
      >
        <Typography mb={0.25} fontWeight={600}>
          Firmware
        </Typography>

        <Typography mb={0.25}>
          Version: <strong data-id="firmware-version">{firmware}</strong>
        </Typography>

        {checkUpdates && <FirmwareState deviceId={deviceId} onUpgrade={onUpgrade} />}
      </Popover>
    </>
  );
}
