import {isNil} from 'app/util/isNil';

const DIVIDER = '/';

function isDivider(character?: string): boolean {
  return character === DIVIDER;
}

function trimEndSlash(string: string): string {
  if (!isDivider(string[string.length - 1])) {
    return string;
  }

  return string.slice(0, -1);
}

function trimStartSlash(string: string): string {
  if (!isDivider(string[0])) {
    return string;
  }

  return string.substr(1);
}

function trimAllSlashes(string: string): string {
  return trimEndSlash(trimStartSlash(string));
}

function joinTwo(url1: string, url2: string): string {
  if (url1 === '') {
    return url2;
  }

  return trimEndSlash(url1) + '/' + trimAllSlashes(url2);
}

export const Url = {
  join(...urls: any[]): string {
    if (urls.length === 1) {
      return urls[0];
    }

    return urls.reduce((acc, url) => {
      if (!isNil(url)) {
        return joinTwo(acc, String(url));
      }

      return acc;
    }, '');
  },
};
