import React, {useState} from 'react';
import {red} from '@mui/material/colors';
import {Card, IconButton, Stack, Typography, Collapse, Box} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';

const rootSx: StyleSx = {bgcolor: red[50]};
const detailsSx: StyleSx = {maxHeight: 450, overflow: 'auto'};

interface Props extends Sx {
  title: string;
  message: string;
}

export function ErrorSection({sx, title, message}: Props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card variant="elevation" elevation={0} sx={packSx(rootSx, sx)}>
      <Stack p={1} direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" color="error" fontWeight={600}>
          Error: {title}
        </Typography>

        <IconButton onClick={() => setExpanded((prev) => !prev)}>
          <ExpandMoreIcon sx={getIconSx(expanded)} />
        </IconButton>
      </Stack>

      <Collapse in={expanded} sx={detailsSx}>
        <Box p={1}>{message}</Box>
      </Collapse>
    </Card>
  );
}

function getIconSx(expanded: boolean): StyleSx {
  return {transform: `rotate(${expanded ? 180 : 0}deg)`, transition: 'transform .3s'};
}
