import React, {PropsWithChildren} from 'react';
import {Box} from '@mui/material';
import classNames from 'classnames';
import {Component} from 'app/types/common';

interface Props extends PropsWithChildren<Component> {
  title: string;
}

export const PaymentSection: React.FC<Props> = ({className, title, dataId, children}: Props) => {
  return (
    <div className={classNames('payment-section', className)} data-id={dataId}>
      <Box sx={{fontSize: '16px', fontWeight: 'bold', mb: 2}}>{title}</Box>

      {children}
    </div>
  );
};
