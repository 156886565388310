import React, {useEffect} from 'react';
import classNames from 'classnames';
import {WAI_ARIA_ROLE} from 'app/constants/waiAriaRole';
import {isNil} from 'app/util/isNil';
import {
  Callback,
  ChangeEvent,
  ClassName,
} from 'app/types/common';

export enum TABS_THEME {
  WIDE = 'wide',
  MENU = 'menu',
  SETTINGS = 'settings',
}

function collectRegisteredValues(children) {
  const registeredValues = new Set();

  React.Children.forEach(children, child => {
    if (React.isValidElement(child) === false) {
      return;
    }

    const {value} = child.props;

    if (isNil(value)) {
      return;
    }

    registeredValues.add(value);
  });

  return registeredValues;
}

interface Props extends ClassName {
  value: string;
  onChange: Callback<void, [ChangeEvent<any>]>;
  'aria-label'?: string;
  defaultValue?: string;
  theme?: TABS_THEME;
  disabled?: boolean;
}

export const Tabs: React.FC<Props> = ({
  className,
  'aria-label': ariaLabel,
  value,
  defaultValue,
  children: childrenProp,
  theme = TABS_THEME.WIDE,
  disabled,
  onChange,
}) => {
  // Set default value as active if value is not defined or there is no tab with same value
  useEffect(() => {
    if (isNil(defaultValue)) {
      return;
    }

    const registeredValues = collectRegisteredValues(childrenProp);

    if (registeredValues.has(value)) {
      return;
    }

    onChange({
      target: {
        name: '',
        value: defaultValue
      }});
  }, [value, defaultValue, childrenProp, onChange]);

  const children = React.Children.map(childrenProp, (child: any) => {
    if (React.isValidElement(child) === false) {
      return null;
    }

    const selected = value === child.props.value;

    return React.cloneElement(child, {
      selected,
      disabled,
      onChange,
    });
  });

  return (
    <div
      className={classNames('tabs__tab-list', {
        [`tabs__tab-list--${theme}`]: Boolean(theme),
      }, className)}
      aria-label={ariaLabel}
      role={WAI_ARIA_ROLE.TAB_LIST}
    >
      {children}
    </div>
  );
};
