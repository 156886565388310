/* eslint-disable react/default-props-match-prop-types */
import React, {Component} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import {Duration} from 'app/components/sharedReactComponents/DurationTimer/Duration';
import {ClassName} from 'app/types/common';
import {isNil} from 'app/util/isNil';

const UPDATE_EVERY_MS = 1000;

interface Props extends ClassName {
  startTime: number;
  receivedTime: number;
}

interface State {
  duration: number;
}
export class DurationTimerComponent extends Component<Props, State> {
  static defaultProps = {
    receivedTime: 0,
  };

  public requestId: number | null = null;
  constructor(props: Props) {
    super(props);

    this.state = {
      duration: 0,
    };
  }

  componentDidMount() {
    this.updateState();
  }

  componentWillUnmount() {
    this.stop();
  }

  componentDidUpdate() {
    this.updateState();
  }

  updateState() {
    const {
      startTime,
    } = this.props;

    if (startTime) {
      if (this.requestId === null) {
        this.start();
      }
    } else if (this.requestId !== null) {
      this.stop();
    }
  }

  start() {
    let lastCallTimestamp: number | null = null;

    const step = timestamp => {
      if (lastCallTimestamp === null || timestamp - lastCallTimestamp > UPDATE_EVERY_MS) {
        lastCallTimestamp = timestamp;
        this.tick();
      }

      this.requestId = requestAnimationFrame(step);
    };

    this.requestId = requestAnimationFrame(step);
  }

  stop() {
    if (!isNil(this.requestId)) {
      cancelAnimationFrame(this.requestId);
      this.requestId = null;
    }
  }

  tick() {
    this.setState({duration: this.calculateDuration()});
  }

  calculateDuration() {
    const {
      startTime,
      receivedTime,
    } = this.props;

    let seconds = Math.floor(moment().unix() - startTime + receivedTime);

    if (seconds < 0) {
      seconds = 0;
    }

    return seconds;
  }

  render() {
    const {
      className,
      startTime,
      receivedTime,
      ...elementProps
    } = this.props;
    const {
      duration,
    } = this.state;

    const value = startTime === null ? 0 : duration;

    return (
      <Duration
        className={classNames('duration-timer', className)}
        // rework fix !-operator
        value={value}
        {...elementProps}
      />
    );
  }
}
