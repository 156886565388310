import React from 'react';
import classNames from 'classnames';
import {Component} from 'app/types/common';
import {Icons, SvgIcon} from 'app/util/icons';
import {SIZE, THEME} from 'app/constants';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';

export type SrtState = 'connected' | 'connecting' | 'error' | 'disconnected';

interface Props extends Component {
  state: SrtState;
  label?: string;
  tooltip?: string;
}

function getIcon(state: SrtState): SvgIcon {
  if (state === 'connecting') {
    return Icons.spinner().theme(THEME.SUCCESS);
  }

  if (state === 'error') {
    return Icons.warning().theme(THEME.DANGER);
  }

  if (state === 'disconnected') {
    return Icons.disconnectedSource().theme(THEME.DANGER);
  }

  return Icons.streaming().theme(THEME.PRIMARY);
}

export const SrtSummary = ({dataId, className, state, tooltip, label}: Props) => {
  return (
    <Tooltip content={tooltip}>
      <div className={classNames('srt-summary', className)} data-id={dataId}>
        {getIcon(state)
          .size(SIZE.S)
          .label(label ?? '')
          .reactComponent()}
      </div>
    </Tooltip>
  );
};
