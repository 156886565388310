import {useEffect} from 'react';
import {useUnreadAlertsQuery} from 'app/components/entities/alerts';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';

export function useUnreadAlerts() {
  const query = useUnreadAlertsQuery();

  const {refetch} = query;

  useEffect(() => {
    const onMessage = async () => refetch();

    WS.on(EVENT_TYPE.NOTIFICATIONS_UPDATED, onMessage);

    return () => {
      WS.off(EVENT_TYPE.NOTIFICATIONS_UPDATED, onMessage);
    };
  }, [refetch]);

  return query;
}
