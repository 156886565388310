import {useQuery} from '@tanstack/react-query';
import {getPeriodBorders} from 'app/components/sharedReactComponents/Events/utils';
import {Schedule} from 'app/domain/schedule';
import {ScheduleApiService} from 'app/services/api/schedule/ScheduleApiService';
import {ScheduleMapper} from 'app/util/mappers/ScheduleMapper';

interface Args {
  enabled: boolean;
  teamId: string;
  period: Schedule.Period;
}

export function useTeamScheduleQuery({enabled, teamId, period}: Args) {
  return useQuery({
    queryKey: ['team-schedule', teamId, period],
    queryFn: async () => {
      const {from, to} = getPeriodBorders(period);
      const response = await ScheduleApiService.getTeamSchedule(from, to);
      const mapped = ScheduleMapper.mapTeamSchedule(response);
      return mapped;
    },
    enabled,
    keepPreviousData: true,
  });
}
