import {useContext} from 'react';
import {BillingManagerContext} from 'app/components/BillingManager/context';
import {BillingState} from 'app/components/BillingManager/types/types';

export function useTeamBilling(): BillingState {
  const ctx = useContext(BillingManagerContext);

  if (!ctx) {
    throw new Error('Hook is used outside of BillingManagerContext');
  }

  return ctx;
}
