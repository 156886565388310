import React from 'react';
import classNames from 'classnames';
import {LoadingWrapper} from 'app/components/sharedReactComponents/LoadingWrapper';
import {DROPDOWN_SELECT_THEME} from 'app/components/sharedReactComponents/DropdownSelect/constants';
import {
  KeyboardCode,
  SIZE,
} from 'app/constants';
import {WAI_ARIA_ROLE} from 'app/constants/waiAriaRole';
import {Icons} from 'app/util/icons';
import {Caret} from 'app/components/sharedReactComponents/Caret';
import {
  Callback,
  ClassName
} from 'app/types/common';

function handleKeyPress(e: React.KeyboardEvent) {
  if (
    e.code === KeyboardCode.Enter ||
    e.code === KeyboardCode.Space
  ) {
    e.preventDefault();
    e.target.dispatchEvent(new Event('click'));
  }
}

interface Props extends ClassName {
  theme?: DROPDOWN_SELECT_THEME;
  size?: SIZE;
  fullWidth?: boolean;
  showClearButton?: boolean;
  opened?: boolean;
  disabled?: boolean;
  loading?: boolean;
  quietLoading?: boolean;
  adornment?: string;
  onClickClear?: Callback;
  children: React.ReactNode;
}

export const DropdownSelectButton = React.forwardRef<any, Props>((props, ref) => {
  const {
    className,
    children,
    theme = DROPDOWN_SELECT_THEME.DEFAULT,
    size,
    fullWidth = true,
    showClearButton,
    opened,
    disabled,
    loading,
    quietLoading,
    onClickClear,
    adornment,
    ...rest
  } = props;

  const focusable = !disabled && !loading;

  return (
    // rework
    <LoadingWrapper loading={loading}>
      <div
        ref={ref}
        className={classNames('dropdown-select-button',
          theme && `dropdown-select-button--theme-${theme}`,
          size && `dropdown-select-button--size-${size}`,
          {
            'dropdown-select-button--full-width': fullWidth,
            'dropdown-select-button--opened': opened,
            'dropdown-select-button--disabled': disabled || loading,
          },
          className)}
        role={WAI_ARIA_ROLE.BUTTON}
        tabIndex={focusable ? 0 : undefined}
        onKeyPress={handleKeyPress}
        {...rest}
      >
        <div className="dropdown-select-button__name">
          {children}
        </div>

        {adornment && (
          <span className="dropdown-select-button__adornment">
            {adornment}
          </span>
        )}

        {showClearButton && !disabled && (
          <span
            className="dropdown-select__clear-button"
            aria-hidden={true}
            onClick={onClickClear}
          >
            {Icons.cross().reactComponent()}
          </span>
        )}

        {quietLoading && !disabled && (
          <span
            className="dropdown-select__quiet-loading"
            aria-hidden={true}
          >
            {Icons.spinner().reactComponent()}
          </span>
        )}

        {!disabled && (
          <Caret
            size={size}
            opened={opened}
          />
        )}
      </div>
    </LoadingWrapper>
  );
});
