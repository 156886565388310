import React, {PropsWithChildren, useMemo} from 'react';
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack} from '@mui/material';
import {Callback, Component} from 'app/types/common';
import {text} from 'app/themes/app';
import {isNil} from 'app/util/isNil';
import {Icons} from 'app/util/icons';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {SIZE, THEME} from 'app/constants';

function Placeholder({children}: PropsWithChildren<{}>) {
  return <InputLabel sx={{m: 0, color: text.label}}>{children}</InputLabel>;
}

const replaceMessage =
  'The SRT input will be overwritten with a new configuration. You still can edit this input by logging into your device or Unify project and going to the input section. ';

interface Props extends Component {
  value: string;
  loading: boolean;
  entity?: App.Connect.CloudEntityType;
  error?: string;
  sources: App.Connect.EntitySource[];
  onChange: Callback;
}

export const SrtSourceSelector = ({
  value,
  dataId,
  sources,
  loading,
  entity,
  error,
  onChange,
}: Props) => {
  const areSourcesAvailable = useMemo(() => sources.some((s) => !s.active), [sources]);
  const noSources = sources.length === 0;

  const disabled = noSources || !areSourcesAvailable || isNil(entity) || loading;

  const renderHelper = () => {
    if (value) {
      return (
        <div>
          <Tooltip content={replaceMessage}>
            <Stack sx={{display: 'inline-flex'}} spacing={0.5} direction="row" alignItems="center">
              {Icons.info().theme(THEME.DANGER).size(SIZE.S).reactComponent()}
              <FormHelperText error={true}>This input will be replaced</FormHelperText>
            </Stack>
          </Tooltip>
        </div>
      );
    }

    if (error && !disabled) {
      return <FormHelperText>{error ?? ' '}</FormHelperText>;
    }

    if (!isNil(entity) && (!areSourcesAvailable || noSources)) {
      return (
        <FormHelperText error={false} disabled={false}>
          To create an input, admin log in your {entity === 'device' ? 'Device' : 'Unify project'}
        </FormHelperText>
      );
    }

    return null;
  };

  const renderLabel = () => {
    if (loading) {
      return <Placeholder>Loading</Placeholder>;
    }

    if (!isNil(entity)) {
      if (noSources) {
        return `This ${entity} has no input created`;
      }

      if (!areSourcesAvailable) {
        return 'All inputs are in use. Please create a new one';
      }
    }

    return 'Select input';
  };

  const renderValue = (value: string) => {
    if (!value) {
      return renderLabel();
    }

    return sources.find((s) => s.id === value)?.name ?? value;
  };

  return (
    <FormControl
      data-id={dataId}
      fullWidth={true}
      disabled={disabled}
      error={!disabled && Boolean(error)}
    >
      <Select value={value} renderValue={renderValue} displayEmpty={true} onChange={onChange}>
        {sources.map((src) => (
          <MenuItem key={src.id} value={src.id} disabled={src.active}>
            {src.name}

            {src.active && <Box sx={{ml: 'auto'}}>active</Box>}
          </MenuItem>
        ))}
      </Select>
      {renderHelper()}
    </FormControl>
  );
};
