import {useEffect} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {Network, NetworkMapper, useUnitInterfacesQuery} from 'app/components/entities/network';
import {Ws} from 'app/contracts/ws';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';

export function useUnitInterfaces(deviceId: string) {
  const query = useUnitInterfacesQuery(deviceId);
  const client = useQueryClient();

  useEffect(() => {
    const onChange = (m: Ws.NetworkChange) => {
      const {DeviceID, Payload} = m.Body;

      if (DeviceID !== deviceId) {
        return;
      }

      client.setQueryData<Network.Interface[]>(['unit', 'network', deviceId], (prev) => {
        if (!prev) {
          return prev;
        }

        return Payload.map(NetworkMapper.mapInterface);
      });
    };

    WS.on(EVENT_TYPE.NETWORK_CHANGE, onChange);

    return () => {
      WS.off(EVENT_TYPE.NETWORK_CHANGE, onChange);
    };
  }, [client, deviceId]);

  return query;
}
