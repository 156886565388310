import React from 'react';
import {
  UserRole,
  USER_ROLE_NAME_BY_TYPE,
} from 'app/constants';
import {DropdownSelect} from 'app/components/sharedReactComponents/DropdownSelect';
import {Callback} from 'app/types/common';

interface Props {
  name?: string;
  value?: UserRole;
  disabled: boolean;
  loading?: boolean;
  master?: boolean;
  onChange: Callback;
  onChangeOwner?: Callback;
}
export const UserRoleSelector = ({
  name,
  value,
  disabled,
  loading,
  master,
  onChange,
  onChangeOwner,
}: Props) => {
  const roleOptions = [
    {
      label: USER_ROLE_NAME_BY_TYPE[UserRole.Master],
      value: UserRole.Master,
      visible: Boolean(onChangeOwner),
    },
    {
      label: USER_ROLE_NAME_BY_TYPE[UserRole.Owner],
      value: UserRole.Owner,
    },
    {
      label: USER_ROLE_NAME_BY_TYPE[UserRole.Operator],
      value: UserRole.Operator,
    },
    {
      label: USER_ROLE_NAME_BY_TYPE[UserRole.Editor],
      value: UserRole.Editor,
      visible: false,
    },
  ];

  const handleChange = (e) => {
    const newRole = e.target.value;
    if (newRole === UserRole.Master) {
      // rework fix !-operator
      onChangeOwner?.();
    } else {
      onChange(e);
    }
  };

  let selectedRole = value;
  if (master && selectedRole === UserRole.Owner) {
    selectedRole = UserRole.Master;
  }

  return (
    <DropdownSelect
      name={name}
      value={selectedRole}
      data-id="user_role_selector"
      options={roleOptions}
      disabled={disabled}
      loading={loading}
      onChange={handleChange}
    />
  );
};
