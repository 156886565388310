import React, {ReactNode} from 'react';
import {
  Modal,
  ModalCancelButton,
  ModalContainer,
  ModalFooter,
  ModalTitle,
} from 'app/components/sharedReactComponents/Modal/Modal';
import {
  SIZE,
  THEME,
  VARIANT,
} from 'app/constants';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {Callback} from 'app/types/common';

type DeletePromptProps = {
  title: ReactNode;
  fullWidth?: boolean;
  maxWidth?: SIZE;
  action: Callback;
};

export const DeletePrompt = {
  show({
    title,
    fullWidth,
    maxWidth = SIZE.M,
    action,
  }: DeletePromptProps) {
    Modal.show({
      className: 'delete-prompt',
      fullWidth,
      maxWidth,
      children: (
        <ModalContainer>
          <ModalTitle>
            {title}
          </ModalTitle>

          <ModalFooter>
            <ProgressButton
              dataId="modal_confirm_button"
              theme={THEME.DANGER}
              variant={VARIANT.SOLID}
              autoFocus={true}
              onClick={async () => {
                return Promise.resolve(action())
                  .then(() => Modal.hide());
              }}
            >
              Yes
            </ProgressButton>

            <ModalCancelButton/>
          </ModalFooter>
        </ModalContainer>
      )
    });
  }
};
