import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';
import {NamesList} from 'app/components/sharedReactComponents/ItemsEnumeration';
import {pluralize} from 'app/components/helpers/commonHelpers';
import {useMounted} from 'app/hooks/useIsMounted';

interface Props extends Prompt {
  names: string[];
  onUpdate: () => Promise<void>;
}

export function BatchFirmwareDialog({open, names, onUpdate, onClose}: Props) {
  const [loading, setLoading] = useState(false);

  const mounted = useMounted();

  const handleUpdate = async () => {
    setLoading(true);

    try {
      await onUpdate();
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Update your <NamesList names={names} variant="inherit" color="primary.main" />{' '}
        {pluralize('device', names)} firmware
      </DialogTitle>

      <DialogContent>
        <Typography textAlign="center">
          During update process, your {pluralize('device', names)} will be offline.
          <br />
          Your {pluralize('device', names)} will reboot automatically after the firmware has been
          downloaded.
        </Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-i="confirm-upd-btn"
          variant="contained"
          color="secondary"
          loading={loading}
          onClick={handleUpdate}
        >
          Update
        </LoadingButton>

        <Button data-id="cancel-upd-btn" variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
