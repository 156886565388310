import React, {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {StreamGeneralSettings} from 'app/components/pages/MeetingDetails/StreamSettings/StreamGeneralSettings/StreamGeneralSettings';
import {Callback, ClassName} from 'app/types/common';
import {CollapsiblePanel} from 'app/components/pages/MeetingDetails/StreamSettings/CollapsiblePanel/CollapsiblePanel';
import {StreamVideoSettings} from 'app/components/pages/MeetingDetails/StreamSettings/StreamVideoSettings/StreamVideoSettings';
import {StreamConnectionSettings} from 'app/components/pages/MeetingDetails/StreamSettings/StreamConnectionSettings/StreamConnectionSettings';
import {StreamAdvancedSettings} from 'app/components/pages/MeetingDetails/StreamSettings/StreamAdvancedSettings/StreamAdvancedSettings';
import {StreamAudioSettings} from 'app/components/pages/MeetingDetails/StreamSettings/StreamAudioSettings/StreamAudioSettings';
import {Meeting} from 'app/store/models/connect/Meeting/Meeting';
import {useSrtSettings} from 'app/components/pages/MeetingDetails/StreamSettings/SrtSettingsFormContext';
import {ConnectMapper} from 'app/util/mappers/ConnectMapper/ConnectMapper';
import {ErrorResponse, getErrorContents, isErrorResponse} from 'app/api/types';
import {THEME, VARIANT} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';
import {SrtStream} from 'app/store/models/connect/SrtStream/SrtStream';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';
import {MeetingsApiService} from 'app/services/api/meetings/MeetingsApiService';
import {SrtApiService} from 'app/services/api/meetings/SrtApiService';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Notifications} from 'app/components/Notifications';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {EncryptionMessage} from 'app/components/pages/MeetingDetails/StreamSettings/EncryptionMessage/EncryptionMessage';
import {useMounted} from 'app/hooks/useIsMounted';
import {SrtSettingsForm} from 'app/components/pages/MeetingDetails/StreamSettings/SrtSettingsForm';
import {isParticipantStreamSettings} from 'app/types/guards/connect';

interface Props extends ClassName {
  meeting: Meeting;
  participantId: string;
  name: string;
  state: App.Connect.ParticipantState;
  share: boolean;
  video: boolean;
  audio: boolean;
  type: App.Connect.MemberType;
  stream: SrtStream;
  onClose: Callback;
}

const StreamSettingsComponent = ({
  className,
  meeting,
  participantId,
  name,
  type,
  state,
  audio,
  video,
  share,
  stream,
  onClose,
}: Props) => {
  const {form, setForm} = useSrtSettings();
  const [init, setInit] = useState(true);
  const [validationMessage, setValidationMessage] = useState('');

  const isMounted = useMounted();

  const initParticipantSettings = useCallback(async () => {
    try {
      const contract = await MeetingsApiService.getParticipant(meeting.id, participantId);

      if (isMounted()) {
        if (contract.stream.settings) {
          await SrtApiService.setParticipantSettings(
            meeting.id,
            participantId,
            contract.stream.settings,
          );
        }

        const participant = ConnectMapper.mapParticipant(contract);
        meeting.setParticipant(participant);

        if (participant.stream.settings) {
          setForm(new SrtSettingsForm(participant.stream.settings));
        }
      }
    } catch {
      throw new Error('Participant init');
    }
  }, [isMounted, meeting.id, participantId, setForm]);

  const initFeedSettings = useCallback(async () => {
    try {
      const contract = await MeetingsApiService.getReturnFeed(meeting.id);

      if (isMounted()) {
        if (contract.stream.settings) {
          await SrtApiService.setFeedSettings(meeting.id, contract.stream.settings);
        }

        const feed = ConnectMapper.mapReturnFeed(contract);
        meeting.setReturnFeed(feed);

        if (feed.stream.settings) {
          setForm(new SrtSettingsForm(feed.stream.settings));
        }
      }
    } catch {
      Notifications.addErrorNotification('Could not initialized Return feed settings, try again');
      throw new Error('RF init');
    }
  }, [meeting.id, isMounted, setForm]);

  const initializeSettings = useCallback(async () => {
    try {
      setInit(true);

      if (type === 'return-feed') {
        await initFeedSettings();
      } else {
        await initParticipantSettings();
      }
    } catch {
      onClose();
    } finally {
      setInit(false);
    }
  }, [type, initFeedSettings, initParticipantSettings, onClose]);

  useEffect(() => {
    void initializeSettings();
  }, [initializeSettings]);

  const handleSync = useCallback(async () => {
    const {settings} = form;

    form.setSynchronization(true);

    setValidationMessage('');

    try {
      if (isParticipantStreamSettings(settings) && participantId) {
        await SrtApiService.setParticipantSettings(
          meeting.id,
          participantId,
          ConnectMapper.toParticipantSettingsContract(settings),
        );
      } else {
        await SrtApiService.setFeedSettings(
          meeting.id,
          ConnectMapper.toFeedSettingsContract(settings),
        );
      }

      stream.setSettings(settings);
      form.setError(undefined);
      form.setInvalid(false);
    } catch (e: unknown) {
      if (isErrorResponse(e)) {
        const result = getFieldErrors(e);

        form.setError(result);
      }
      form.setInvalid(true);
    } finally {
      form.setSynchronization(false);
    }
  }, [meeting.id, participantId, form]);

  const handleStart = async () => {
    try {
      if (participantId) {
        await SrtApiService.start(meeting.id, participantId);
      } else {
        await SrtApiService.startFeed(meeting.id);
      }
      onClose();
    } catch (e: unknown) {
      const reason = isErrorResponse(e) && getErrorContents(e);
      Notifications.addErrorNotification(`Could not start srt${reason ? ': '.concat(reason) : ''}`);
    }
  };

  if (!meeting.settings) {
    return null;
  }

  if (init) {
    return (
      <div className={classNames('stream-settings', className)} data-id="srt_settings_panel">
        <div className="stream-settings__init">
          <h4>Synchronizing settings</h4>
          <LoadingPlaceholder />
        </div>
      </div>
    );
  }

  const isCloudSrt = isParticipantStreamSettings(form.settings) && form.settings.integration;

  return (
    <div className={classNames('stream-settings', className)} data-id="srt_settings_panel">
      <div className="stream-settings__body">
        <StreamGeneralSettings
          className="stream-settings__general"
          name={name}
          state={state}
          audio={audio}
          video={video}
          type={type}
          share={share}
          onSync={handleSync}
        />

        <CollapsiblePanel sx={{mb: 4}} title="Connection settings" collapsible={false}>
          <StreamConnectionSettings
            dns={meeting.settings.dns}
            range={meeting.settings.portRange}
            onSync={handleSync}
          />
        </CollapsiblePanel>

        {isParticipantStreamSettings(form.settings) && (
          <CollapsiblePanel
            sx={{ml: 2, mb: 3}}
            dataId="srt_video_settings"
            title="Video quality"
            initState={true}
          >
            <StreamVideoSettings
              className="stream-settings__section"
              settings={form.settings}
              onSync={handleSync}
            />
          </CollapsiblePanel>
        )}

        {isParticipantStreamSettings(form.settings) && (
          <CollapsiblePanel
            sx={{ml: 2, mb: 3}}
            dataId="srt_audio_settings"
            title="Audio quality"
            initState={true}
          >
            <StreamAudioSettings
              className="stream-settings__section"
              settings={form.settings}
              onSync={handleSync}
            />
          </CollapsiblePanel>
        )}

        <CollapsiblePanel
          sx={{ml: 2, mb: 3}}
          dataId="srt_advanced_settings"
          title={isCloudSrt ? 'Latency' : 'Advanced settings'}
          initState={true}
        >
          <StreamAdvancedSettings
            className="stream-settings__section"
            encryption={!isCloudSrt}
            onSync={handleSync}
          />
        </CollapsiblePanel>
        {isCloudSrt && <EncryptionMessage className="stream-settings__encryption" />}
      </div>

      <div className="stream-settings__footer">
        {form.synchronization && <IndeterminateProgressBar className="stream-settings__progress" />}

        <Tooltip content={validationMessage}>
          <span>
            <ProgressButton
              theme={THEME.PRIMARY}
              variant={VARIANT.SOLID}
              disabled={form.synchronization || form.invalid}
              dataId="start_srt"
              onClick={handleStart}
            >
              Start
            </ProgressButton>
          </span>
        </Tooltip>

        <Button
          theme={THEME.SECONDARY}
          variant={VARIANT.OUTLINE}
          dataId="close_settings"
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export const StreamSettings = observer(StreamSettingsComponent);

export function getFieldErrors(e: ErrorResponse): App.Connect.ValidationErrors | undefined {
  if (e.data.ErrorData) {
    return e.data.ErrorData as App.Connect.ValidationErrors;
  }
}
