import React, {useMemo} from 'react';
import {Box, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {useCloudAndUnifyReport} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/CloudAndUnifyInvoices/useCloudAndUnifyReport';
import {
  InvoiceCard,
  Row,
} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/shared/InvoiceCard/InvoiceCard';
import {isNil} from 'app/util/isNil';
import {CloudHistory} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/CloudAndUnifyInvoices/CloudHistory/CloudHistory';
import {palette} from 'app/themes/app';
import {InvoicesSkeleton} from 'app/components/entities/billing/base';
import {useFeature} from 'app/util/ApplicationFeatures';

interface Props extends Sx {
  teamId: string;
  period: App.Billing.Period;
}

export function CloudAndUnifyInvoices({sx, teamId, period}: Props) {
  const {isInitialLoading, data} = useCloudAndUnifyReport({teamId, enabled: true});

  const unifyFeature = useFeature('unify');

  const rows = useMemo<Row[]>(() => {
    const invoice = data?.invoice;

    const result: Row[] = [];

    if (!isNil(invoice)) {
      result.push(
        {
          name: 'Edge subscription',
          total: invoice.cloud.fee,
          description: 'Edge usage in period.',
        },
        {
          name: 'LiveScrypt',
          total: invoice.lvs.usage,
          description: `Total hours: ${invoice.lvs.hours}. Billed hours: ${invoice.lvs.billedHours}.`,
        },
      );

      if (unifyFeature) {
        result.push({
          name: 'Unify subscription',
          total: invoice.unify.usage,
          description: 'Unify usage in period.',
        });
      }
    }

    return result;
  }, [data, unifyFeature]);

  const loading = isInitialLoading || isNil(data);

  if (loading) {
    return <InvoicesSkeleton sx={sx} />;
  }

  const {invoice, history} = data;

  return (
    <Box sx={sx}>
      {invoice && (
        <InvoiceCard
          dataId="upcoming-invoice"
          sx={{mb: 3}}
          issueDate={invoice.date}
          total={invoice.total}
          period={period}
          rows={rows}
        />
      )}

      <Typography fontWeight={600} fontSize={16} mb={1}>
        Invoice history
      </Typography>

      {history.length > 0 ? (
        <CloudHistory history={history} teamId={teamId} showUnify={unifyFeature} />
      ) : (
        <Typography fontWeight={600} textAlign="center" color={palette.darkerGray} my={2}>
          No previous invoices
        </Typography>
      )}
    </Box>
  );
}
