import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  ReactNode,
  PropsWithChildren,
} from 'react';
import classNames from 'classnames';
import {THEME, VARIANT, SIZE} from 'app/constants';
import {WAI_ARIA_ROLE} from 'app/constants/waiAriaRole';
import {Button, BUTTON_TYPE} from 'app/components/sharedReactComponents/Button';
import {LoadingWrapper} from 'app/components/sharedReactComponents/LoadingWrapper';
import {isPromise} from 'app/util/isPromise';
import {useIsMounted} from 'app/hooks/useIsMounted';
import {Callback, ClassName, DataId} from 'app/types/common';

export {BUTTON_TYPE};

interface Props extends ClassName, DataId {
  type?: BUTTON_TYPE.BUTTON | BUTTON_TYPE.SUBMIT;
  title?: string;
  titlePreformatted?: boolean;
  role?: WAI_ARIA_ROLE;
  theme?: THEME;
  variant?: VARIANT;
  size?: SIZE;
  autoFocus?: boolean;
  disabled?: boolean;
  loading?: boolean;
  unsetLoadingAfterPromise?: boolean;
  href?: string;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
  onClick?: Callback<Promise<any> | any>;
}

export const ProgressButton = forwardRef<ReactNode, PropsWithChildren<Props>>(
  (
    {
      className,
      children,
      type,
      title,
      titlePreformatted,
      size,
      role,
      theme,
      variant,
      autoFocus,
      disabled,
      loading: _loading,
      unsetLoadingAfterPromise = true,
      dataId,
      onClick,
      ...restProps
    },
    ref,
  ) => {
    const [loading, setLoading] = useState(_loading);
    const mountedRef = useIsMounted();

    useEffect(() => {
      setLoading(_loading);
    }, [_loading]);

    const handleClick = useCallback(
      (e) => {
        if (!onClick) {
          return;
        }

        const callback = onClick(e);
        if (isPromise(callback)) {
          setLoading(true);

          callback.finally(() => {
            if (mountedRef.current && unsetLoadingAfterPromise) {
              setLoading(false);
            }
          });
        }
      },
      [onClick],
    );

    return (
      <LoadingWrapper loading={loading}>
        <Button
          ref={ref}
          className={classNames('progress-button', className)}
          theme={theme}
          variant={variant}
          size={size}
          type={type}
          role={role}
          title={title}
          titlePreformatted={titlePreformatted}
          autoFocus={autoFocus}
          disabled={disabled}
          dataId={dataId}
          onClick={handleClick}
          {...restProps}
        >
          {children}
        </Button>
      </LoadingWrapper>
    );
  },
);
