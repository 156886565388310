/* eslint-disable complexity */
import React, {useState, useEffect, useMemo} from 'react';
import {observer} from 'mobx-react';
import {enableMapSet} from 'immer';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {SnackbarOrigin, Stack, useMediaQuery, useTheme} from '@mui/material';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useComponentDidMount} from 'app/hooks/useComponentDidMount';
import {MainRoutes, router} from 'app/router/main';
import {Footer} from 'app/components/Footer';
import {Alerts} from 'app/components/Settings/Alerts';
import {FleetManagerPage} from 'app/components/FleetManager/FleetManagerPage';
import {DevicesAlertsPage} from 'app/components/DevicesAlerts/DevicesAlertsPage';
import {PresetsPage} from 'app/components/Presets/PresetsPage';
import {Agreement} from 'app/components/LegalInformation/Agreement';
import {AccountSettings} from 'app/components/Settings/AccountSettings/AccountSettings';
import {ApiTokens} from 'app/components/Settings/ApiTokens';
import {UsePolicy} from 'app/components/LegalInformation/UsePolicy';
import {OneClickDevicePairingPage} from 'app/components/OneClickDevicePairing/OneClickDevicePairingPage';
import {StreamingDestinations} from 'app/components/Settings/StreamingDestinations';
import {TranscribeDevices} from 'app/components/Dashboard/TranscribeDevices';
import {DeviceDetailsPage} from 'app/components/DeviceDetails/DeviceDetailsPage';
import {PairDevicePage} from 'app/components/PairDevice/PairDevicePage';
import {FileTransfer} from 'app/components/Settings/FileTransfer';
import {BillingAndUsage} from 'app/components/Settings/BillingAndUsage/BillingAndUsage';
import {PairingDeviceIdStorage} from 'app/components/OneClickDevicePairing/PairingDeviceIdStorage';
import {ScrollToAnchorController} from 'app/components/sharedReactComponents/ScrollToAnchor/ScrollToAnchorController';
import {RouterStateType} from 'app/router/types';
import {EventsPage} from 'app/components/pages/Events/EventsPage';
import {StoreContext} from 'app/store/StoreContext';
import {useCurrentTeamStore} from 'app/store/hooks';
import {UnifyProjects} from 'app/components/UnifyProjects/UnifyProjects';
import {ConnectAccounts} from 'app/components/Settings/ConnectSettings/ConnectSettings';
import {MeetingsManager} from 'app/components/MeetingsManager/MeetingsManager';
import {MeetingDetailsPage} from 'app/components/pages/MeetingDetails/MeetingDetailsPage';
import {ErrorBoundary} from 'app/components/ErrorBoundary/ErrorBoundary';
import {InitializationPage} from 'app/components/InitializationPage/InitializationPage';
import {BillingManager} from 'app/components/BillingManager/BillingManager';
import {Trackers} from 'app/components/Trackers/Trackers';
import {RootStore} from 'app/store/RootStore';
import {Navigation} from 'app/components/Navigation/Navigation';
import {useNavRoutes} from 'app/components/Navigation/hooks/useNavRoutes';
import {ConnectPlatformsIntegration} from 'app/components/ConnectPlatformsIntegration/ConnectPlatformsIntegration';
import {ApplicationFeatures, useFeature} from 'app/util/ApplicationFeatures';
import {MainTheme} from 'app/themes/MainTheme';
import {MaintenanceSnackbar, useMaintenance} from 'app/components/features/maintenance';
import {TeamFileManagement} from 'app/components/pages/files';
import {useCloudEntities} from 'app/components/entities/cloud';
import {CloudUser} from 'app/components/widgets/profile';

enableMapSet();
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);

const maintenanceOrigin: SnackbarOrigin = {vertical: 'bottom', horizontal: 'left'};

type RenderBody = {
  content: React.ReactNode;
  key: string;
};

type ContentProps = {
  routeState: RouterStateType<MainRoutes>;
};

const Content: React.VFC<ContentProps> = observer(({routeState}) => {
  const currentTeamStore = useCurrentTeamStore();

  const {user, teams} = useCloudEntities();

  const {instance, minimized, expand, setStored} = useMaintenance();

  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('lg'));

  const {initialized} = currentTeamStore;
  const routes = useNavRoutes({enabled: initialized, user, team: currentTeamStore});

  const filesFeature = useFeature('files');
  const connectFeature = useFeature('connect');
  const unifyFeature = useFeature('unify');

  const {role} = user;

  useEffect(() => {
    const {name, query} = routeState;

    if (!initialized && name === 'oneClickDevicePairing') {
      PairingDeviceIdStorage.save(query);
    }

    if (initialized) {
      if (query) {
        const {scrollTo} = query;

        if (scrollTo) {
          delete router.state.query?.scrollTo;
          ScrollToAnchorController.register(decodeURIComponent(scrollTo as string));
        }
      }

      // TODO test
      if (name !== 'oneClickDevicePairing' && PairingDeviceIdStorage.hasIncompletePairingDevice()) {
        router.go('oneClickDevicePairing', undefined, {
          ...query,
          deviceId: PairingDeviceIdStorage.load(),
        });
      }

      if (!role.canAccessRoute(name) || !currentTeamStore.canAccessRoute(name)) {
        router.go('dashboard');
        return;
      }

      if (!filesFeature && name === 'recordings') {
        router.go('dashboard');
      }

      if (!unifyFeature && (name === 'projects' || name === 'projectDetails')) {
        router.go('dashboard');
      }

      if (
        !connectFeature &&
        (name === 'meetings' || name === 'meetingDetails' || name === 'connectAccountSettings')
      ) {
        router.go('dashboard');
      }
    }
  }, [
    routeState,
    initialized,
    currentTeamStore.model,
    role,
    currentTeamStore,
    filesFeature,
    unifyFeature,
    connectFeature,
  ]);

  const bodyRender = (): RenderBody => {
    const {name, params, query} = routeState;

    if (!role.canAccessRoute(name) || !currentTeamStore.canAccessRoute(name)) {
      return {content: null, key: 'default'};
    }

    switch (name) {
      case 'devices': {
        return {content: <FleetManagerPage />, key: 'devices'};
      }

      case 'projects': {
        return {content: <UnifyProjects />, key: 'projects'};
      }

      case 'alerts': {
        return {content: <Alerts />, key: 'alerts'};
      }

      case 'devicesAlerts': {
        return {content: <DevicesAlertsPage />, key: 'devicesAlerts'};
      }

      case 'presets': {
        return {content: <PresetsPage />, key: 'presets'};
      }

      case 'agreement': {
        return {content: <Agreement />, key: 'agreement'};
      }

      case 'accountSettings': {
        return {content: <AccountSettings />, key: 'accountSettings'};
      }

      case 'apiTokens': {
        return {
          content: <ApiTokens teamId={currentTeamStore.id} />,
          key: 'apiTokens',
        };
      }

      case 'acceptanceUsePolicy': {
        return {content: <UsePolicy />, key: 'use-policy'};
      }

      case 'oneClickDevicePairing': {
        return {
          content: <OneClickDevicePairingPage deviceId={query?.deviceId} />,
          key: 'oneClickDevicePairing',
        };
      }

      case 'streamingDestinations': {
        return {
          content: <StreamingDestinations />,
          key: 'streamingDestinations',
        };
      }

      case 'transcribeDevices': {
        return {content: <TranscribeDevices />, key: 'transcribeDevices'};
      }

      case 'deviceDetails': {
        return {
          content: <DeviceDetailsPage deviceId={params.deviceId} activeTab={query?.activeTab} />,
          key: 'deviceDetails',
        };
      }

      case 'transcribeDeviceDetails': {
        return {
          content: <DeviceDetailsPage deviceId={params.deviceId} activeTab={query?.activeTab} />,
          key: 'transcribeDeviceDetails',
        };
      }

      case 'welcome': {
        return {content: <PairDevicePage />, key: 'welcome'};
      }

      case 'fileTransfer': {
        return {
          content: <FileTransfer locationId={query?.locationId} />,
          key: 'fileTransfer',
        };
      }

      case 'settingBillingUsage': {
        return {content: <BillingAndUsage />, key: 'settingBillingUsage'};
      }

      case 'events': {
        return {content: <EventsPage />, key: 'events'};
      }

      case 'projectDetails': {
        return {
          content: <DeviceDetailsPage deviceId={params.deviceId} activeTab={query?.activeTab} />,
          key: 'projectDetails',
        };
      }

      case 'meetings': {
        return {
          content: <MeetingsManager state={query?.state} error={query?.error} />,
          key: 'meetings',
        };
      }

      case 'meetingDetails': {
        return {
          content: <MeetingDetailsPage meetingId={params.meetingId} />,
          key: 'meetingDetails',
        };
      }

      case 'connectAccountSettings': {
        return {
          key: 'connectAccountSettings',
          content: <ConnectAccounts state={query?.state} error={query?.error} tab={query?.tab} />,
        };
      }

      case 'recordings': {
        return {
          key: 'recordings',
          content: <TeamFileManagement />,
        };
      }

      case 'dashboard':
      default: {
        if (user.role.canViewDevices()) {
          router.go('devices');
        }

        return {content: false, key: 'default'};
      }
    }
  };

  if (initialized === false) {
    return <InitializationPage />;
  }

  const {name} = routeState;
  const {content, key} = bodyRender();

  return (
    <BillingManager route={name} refetchTeam={currentTeamStore.reload}>
      <Trackers>
        <ConnectPlatformsIntegration
          teamName={currentTeamStore.getName()}
          enabled={user.role.canPairConnectAuth()}
        >
          {instance && (
            <MaintenanceSnackbar
              open={!minimized}
              maintenance={instance}
              anchorOrigin={maintenanceOrigin}
              onMinimize={() => setStored(instance.id)}
            />
          )}

          <Stack display="flex" direction="row" className="app-container">
            <Navigation
              sx={{position: 'sticky', top: 0, height: '100vh', overflowY: 'auto', flexShrink: 0}}
              user={user}
              route={name}
              teams={teams}
              compact={compact}
              routes={routes}
              maintenance={minimized ? instance : undefined}
              onExpandMaintenance={expand}
            />

            <Stack flexGrow={1}>
              <Stack key={key} flex={1}>
                {content}
              </Stack>

              <Footer align="left" />
            </Stack>
          </Stack>
        </ConnectPlatformsIntegration>
      </Trackers>
    </BillingManager>
  );
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function AppRoot() {
  const [routeState, setRouteState] = useState<RouterStateType<MainRoutes>>(router.defaultRoute);

  useComponentDidMount(() => {
    router.init((state) => {
      setRouteState(state);
    });
  });

  const store = useMemo(() => new RootStore(), []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ApplicationFeatures>
        <MainTheme>
          <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} position="top-right" />

              <StoreContext.Provider value={store}>
                <CloudUser>
                  <Content routeState={routeState} />
                </CloudUser>
              </StoreContext.Provider>
            </QueryClientProvider>
          </ErrorBoundary>
        </MainTheme>
      </ApplicationFeatures>
    </LocalizationProvider>
  );
}
