import {useQuery} from '@tanstack/react-query';
import {FilesBillingApi} from 'app/components/entities/billing/files/api/FilesBillingApi';
import {FilesBillingMapper} from 'app/components/entities/billing/files/mapper';

export function useFilesPricesQuery() {
  return useQuery({
    queryKey: ['billing', 'files', 'prices'],
    queryFn: async () => {
      const res = await FilesBillingApi.getPrices();
      return res.map(FilesBillingMapper.mapPrice);
    },
  });
}
