import React from 'react';
import {Stack} from '@mui/material';
import classNames from 'classnames';
import {noop} from 'app/util/noop';
import {StreamingDestinationsSelector} from 'app/components/sharedReactComponents/ActionButtons/StreamingDestinationsSelector';
import {DeviceStreamingButton} from 'app/components/sharedReactComponents/ActionButtons/DeviceStreamingButton';
import {useDevicePublisherSelection} from 'app/components/sharedReactComponents/ActionButtons/useDevicePublisherSelection';
import {useDeviceStreamingDestinationSelection} from 'app/components/sharedReactComponents/ActionButtons/useDeviceStreamingDestinationSelection';
import {useDeviceWarnings} from 'app/components/sharedReactComponents/ActionButtons/useDeviceWarnings';
import {Callback, ClassName} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';

interface Props extends ClassName {
  device: AnyDeviceModelType;
  streamingDestinations: AnyStreamingDestinationModelType[];
  editable: boolean;
  hasEvent?: boolean;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  onChange?: Callback;
}

export function StreamingDestinationControl({
  className,
  device,
  streamingDestinations,
  hasEvent,
  editable,
  getDeviceById,
  onChange = noop,
}: Props) {
  const {destinations: streamingDestinationWarnings, publisher: publisherWarnings} =
    useDeviceWarnings(device);

  const {
    selectedStreamingDestinations,
    selectingStreamingDestinations,
    handleStreamingDestinationSelect,
  } = useDeviceStreamingDestinationSelection({
    device,
    streamingDestinations,
  });

  const {selectedPublishers, selectingPublishers, handlePublisherSelect} =
    useDevicePublisherSelection({
      device,
      onChange,
    });

  const offline = device.isOffline();
  const selectedCount = selectedPublishers.size + selectedStreamingDestinations.size;
  const buttonDisabled =
    selectingStreamingDestinations || selectingPublishers || selectedCount === 0 || offline;

  return (
    <Stack
      direction="row"
      alignItems="center"
      className={classNames('streaming-destination-control', className)}
      gap={1}
    >
      <StreamingDestinationsSelector
        sx={{minWidth: 196}}
        className="streaming-destination-control__select"
        publishers={device.getPublishers()}
        selectedPublishers={selectedPublishers}
        selectedStreamingDestinations={selectedStreamingDestinations}
        device={device}
        streamingDestinations={streamingDestinations}
        publisherWarnings={publisherWarnings}
        streamingDestinationWarnings={streamingDestinationWarnings}
        offline={offline}
        started={device.isStreamingStarted()}
        editable={editable}
        getDeviceById={getDeviceById}
        onPublisherSelect={handlePublisherSelect}
        onStreamingDestinationSelect={handleStreamingDestinationSelect}
      />

      <DeviceStreamingButton device={device} hasEvent={hasEvent} disabled={buttonDisabled} />
    </Stack>
  );
}
