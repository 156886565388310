import {ERROR_TEXT} from 'app/components/Settings/ApiTokens/TokensPanel/constants';

const getErrorTextByError = ({type, data}) => {
  const name = ERROR_TEXT[type] || 'Unknown Error';

  if (data) {
    return `${name}: ${data}`;
  }

  return name;
};

const normalizeTokenName = (name) => name.trim();

export {
  getErrorTextByError,
  normalizeTokenName,
};
