import {front} from 'app/api/CirrusApi';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {ApiDevicePreviewSize} from 'app/components/sharedReactComponents/StatusImage/types';
import {PreviewUpdateStrategy} from 'app/hooks/Device/Preview/types';

export const getPreviewUrl = (
  id: string,
  updateStrategy: PreviewUpdateStrategy,
  big = false,
): string => {
  if (updateStrategy === PreviewUpdateStrategy.WsApi) {
    return front
      .devices(id)
      .preview({size: big ? ApiDevicePreviewSize.Big : undefined})
      .url();
  }

  return front.devices(id).snapshot().url();
};

export function getPreviewUpdateStrategy(device: AnyDeviceModelType): PreviewUpdateStrategy {
  const caps = device.getCapabilities();

  if (caps.supportsWsApi()) {
    return PreviewUpdateStrategy.WsApi;
  }

  if (caps.supportsChannelPreviews()) {
    return PreviewUpdateStrategy.Ws;
  }

  return PreviewUpdateStrategy.Interval;
}
