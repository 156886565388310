import React from 'react';
import {Virtuoso} from 'react-virtuoso';
import {DeviceAlertsTableHeader} from 'app/components/DevicesAlerts/DeviceAlertsTable/DeviceAlertsTableHeader';
import {DeviceAlert} from 'app/components/DevicesAlerts/DeviceAlertsTable/DeviceAlert';
import {Alerts} from 'app/components/entities/alerts';

interface Props {
  alerts: Alerts.Alert[];
  showHeader: boolean;
}

export function DeviceAlertsTable({alerts, showHeader}: Props) {
  return (
    <div>
      {showHeader && <DeviceAlertsTableHeader />}

      <Virtuoso
        data={alerts}
        useWindowScroll={true}
        itemContent={(_index, alert) => <DeviceAlert alert={alert} />}
      />
    </div>
  );
}
