import {Router} from 'app/router/Router';

const routes = {
  jarvis: {
    url: 'jarvis',
    title: '',
  },

  teams: {
    url: 'teams',
    title: 'Teams',
  },
  teamDetails: {
    url: 'teams/:teamId/details',
    title: 'Team Details',
  },

  users: {
    url: 'users',
    title: 'Users',
  },
  userDetails: {
    url: 'users/:userId/details',
    title: 'User Details',
  },

  devices: {
    url: 'devices',
    title: 'Paired Devices',
  },
  deviceDetails: {
    url: 'devices/:deviceId/details',
    title: 'Device Details',
  },

  devicesBySN: {
    url: 'devices_by_sn',
    title: 'Devices by s/n',
  },

  vtun: {
    url: 'vtun',
    title: 'VTUN',
  },

  presets: {
    url: 'presets',
    title: 'Presets',
  },

  livescryptSessions: {
    url: 'livescrypt_sessions',
    title: 'LiveScrypt sessions',
  },

  streamDestinations: {
    url: 'stream_destinations',
    title: 'Stream Destinations',
  },

  stats: {
    url: 'stats',
    title: 'Stats',
  },

  firmware: {
    url: 'firmware',
    title: 'Firmware',
  },

  loginSessions: {
    url: 'login_sessions',
    title: 'Login Sessions',
  },

  projects: {
    url: 'projects',
    title: 'Projects',
  },

  instances: {
    url: 'instances',
    title: 'EC2 instances',
  },

  meetings: {
    url: 'meetings',
    title: 'Meetings',
  },

  tenants: {
    url: 'tenants',
    title: 'Tenants',
  },

  cmsDevices: {
    url: 'cms_devices',
    title: 'CMS Devices'
  },
  
  fileManagement: {
    url: 'file_management',
    title: 'File Management'
  }
} as const;

export type AdminRoutes = keyof typeof routes;

const router = new Router<AdminRoutes>({
  routes,
  defaultRoute: {
    name: 'jarvis',
    params: {},
  },
});

export {
  router,
};
