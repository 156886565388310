import {useEffect} from 'react';
import {KeyboardCode, NODE_NAME} from 'app/constants';

interface Params {
  onSelect: () => void;
}

export function useSelectAllWithKeyboard({onSelect}: Params) {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (
        event.ctrlKey &&
        event.code === KeyboardCode.KeyA &&
        (event.target as HTMLElement).nodeName !== NODE_NAME.INPUT
      ) {
        onSelect();
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [onSelect]);
}
