import React from 'react';
import classNames from 'classnames';
import {Box, Stack} from '@mui/material';
import {router} from 'app/router/main';
import {ActionButtons} from 'app/components/sharedReactComponents/ActionButtons/ActionButtons';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {ClassName} from 'app/types/common';
import {DeviceAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/DeviceAudioMeter';
import {isDisabledAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/utils';
import {Thumbnail} from 'app/components/sharedReactComponents/Preview/Thumbnail/Thumbnail';
import {getPreviewUpdateStrategy} from 'app/components/sharedReactComponents/Preview/utils';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {Schedule} from 'app/domain/schedule';
import {EventInfo} from 'app/components/sharedReactComponents/Events/shared/EventInfo/EventInfo';
import {isNil} from 'app/util/isNil';
import {useCloudEntities} from 'app/components/entities/cloud';
import {isOngoing} from 'app/domain/schedule/utils';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {useChannelEvent} from 'app/components/DeviceDetails/hooks/useChannelEvent';

interface Props extends ClassName {
  masterDevice: PearlMasterDeviceModel;
  channelDevice: PearlSlotDeviceModel;
  streamingDestinations: AnyStreamingDestinationModelType[];
  actualEvent?: Schedule.Event;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  onChangeStreamingDestination: Fn;
}

export function ChannelsListItem({
  className,
  masterDevice,
  channelDevice,
  streamingDestinations,
  actualEvent,
  getDeviceById,
  onChangeStreamingDestination,
}: Props) {
  const {user} = useCloudEntities();
  const detailsUrl = router.url('deviceDetails', {deviceId: channelDevice.getId()});
  const channelId = channelDevice.getId();

  const event = useChannelEvent({channelId, event: actualEvent});

  const hasOngoingEvent = !isNil(event) && isOngoing(event.status);

  const canEditDestination = user.role.canEditStreamingDestinations();

  const onDetailsClickHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    router.go(undefined, {deviceId: channelId}, {activeTab: 'channels'});
  };

  return (
    <div className={classNames('channels-list-item', className)} data-id="channel_card">
      <div className="channels-list-item__preview">
        <Thumbnail
          className="channels-list-item__thumbnail"
          id={channelDevice.getId() ?? masterDevice.getId()}
          detailsUrl={detailsUrl}
          updateStrategy={getPreviewUpdateStrategy(masterDevice)}
          onClick={onDetailsClickHandler}
        />

        <DeviceAudioMeter
          className="channels-list-item__audio-meter"
          id={channelDevice.getId() ?? masterDevice.getId()}
          disabled={isDisabledAudioMeter(masterDevice)}
          segmentsCount={13}
          segmentsGap={5}
          vertical={true}
        />
      </div>

      <div className="channels-list-item__content">
        <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
          <Box className="channels-list-item__name">
            <a
              className="channels-list-item__link"
              data-id="channel_name"
              href={detailsUrl}
              onClick={onDetailsClickHandler}
            >
              <OverflowTooltip>{channelDevice.getName()}</OverflowTooltip>
            </a>
          </Box>

          {event && (
            <EventInfo sx={{maxWidth: 250}} deviceId={channelDevice.getId()} event={event} />
          )}
        </Stack>

        <div className="channels-list-item__actions">
          <ActionButtons
            device={channelDevice}
            streamingDestinations={streamingDestinations}
            editable={canEditDestination}
            hasEvent={hasOngoingEvent}
            getDeviceById={getDeviceById}
            onChangeStreamingDestination={onChangeStreamingDestination}
          />
        </div>
      </div>
    </div>
  );
}
