import React from 'react';
import {Link, ListItemButton, listItemButtonClasses} from '@mui/material';
import {palette} from 'app/themes/app';
import {packSx} from 'app/util/packSx/packSx';
import {DataId, Sx} from 'app/types/common';

interface Props extends Sx, DataId {
  selected?: boolean;
  href: string;
  onClick?: () => void;
}

export const NavButton = React.forwardRef<HTMLAnchorElement | null, React.PropsWithChildren<Props>>(
  ({sx, dataId, href, selected, children, onClick}, ref) => (
    <ListItemButton
      data-id={dataId}
      sx={packSx(
        {
          gap: 1.5,
          color: palette.white,

          [`&:hover, &:focus, &.${listItemButtonClasses.focusVisible}`]: {
            color: palette.white,
            bgcolor: palette.dark1,
            textDecoration: 'none',
            outline: 'none',
          },

          [`&.${listItemButtonClasses.selected}`]: {
            bgcolor: palette.dark1,
            color: palette.white,
            textDecoration: 'none',
            outline: 'none',

            [`&:hover, &:focus, &.${listItemButtonClasses.focusVisible}`]: {
              bgcolor: palette.dark1,
            },
          },
        },
        sx,
      )}
      ref={ref}
      LinkComponent={Link}
      selected={selected}
      href={href}
      onClick={onClick}
    >
      {children}
    </ListItemButton>
  ),
);
