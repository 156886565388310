import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogTitle} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';

interface Props extends Prompt {
  onDelete: () => Promise<void>;
}

export function DeleteTranscribeSessionDialog({open, onDelete, onClose}: Props) {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await onDelete();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Are you sure you want to delete this transcribe session?</DialogTitle>
      <DialogActions>
        <LoadingButton variant="contained" color="error" loading={loading} onClick={handleDelete}>
          Delete
        </LoadingButton>
        <Button variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
