import React, {Fragment} from 'react';
import {PropsList, PROPS_LIST_THEME} from 'app/components/sharedReactComponents/PropsList';
import {
  getDeviceSettingValue,
  getDeviceSettingValueFormatted,
} from 'app/models/Device/Settings/utils';
import {ClassName} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {useDeviceSettingsCollection} from 'app/hooks/Device/useDeviceSettingsCollection';

interface Props extends ClassName {
  device: AnyDeviceModelType;
}

export const ChannelDetailsPropsList: React.VFC<Props> = ({
  className,
  device,
}) => {
  const deviceSettingsCollection = useDeviceSettingsCollection(device);

  if (!deviceSettingsCollection) {
    return null;
  }

  return (
    <PropsList
      className={className}
      data-id="channel_encoder_properties"
      data={{
        videoCodec: getDeviceSettingValueFormatted(deviceSettingsCollection.getVideoCodecSetting()),
        videoResolution: getDeviceSettingValueFormatted(deviceSettingsCollection.getVideoResolutionSetting()),
        videoBitrate: getDeviceSettingValueFormatted(deviceSettingsCollection.getVideoBitrateSetting()),
        videoFramerate: getDeviceSettingValueFormatted(deviceSettingsCollection.getVideoFramerateSetting()),
        audioEnabled: getDeviceSettingValue(deviceSettingsCollection.getAudioEnabledSetting()),
        audioPreset: getDeviceSettingValueFormatted(deviceSettingsCollection.getAudioPresetSetting()),
        audioChannels: getDeviceSettingValueFormatted(deviceSettingsCollection.getAudioChannelsSetting()),
        audioBitrate: getDeviceSettingValueFormatted(deviceSettingsCollection.getAudioBitrateSetting()),
      }}
      rows={[
        {
          dataKey: 'videoCodec',
          label: 'Video',
        },
        {
          dataKey: 'videoResolution',
          label: 'Resolution',
        },
        {
          dataKey: 'videoBitrate',
          label: 'Bitrate',
        },
        {
          dataKey: 'videoFramerate',
          label: 'FPS',
        },
        {
          dataKey: 'audio',
          label: 'Audio',
          cellRenderer: ({rowData}) => (
            <Fragment>
              {rowData.audioEnabled ? (
                <Fragment>
                  {rowData.audioPreset && (
                    <Fragment>
                      {rowData.audioPreset}
                      <br/>
                    </Fragment>
                  )}
                  {rowData.audioChannels}
                  {' '}
                  {rowData.audioBitrate}
                </Fragment>
              ) : (
                <Fragment>
                  Disabled
                </Fragment>
              )}
            </Fragment>
          )
        },
      ]}
      theme={PROPS_LIST_THEME.SLIM_VERTICAL}
    />
  );
};
