import React from 'react';
import {Component, SxObj} from 'app/types/common';
import {Box} from '@mui/material';
import {palette} from 'app/themes/app';
import {styles} from 'app/components/MeetingsManager/styles';

const root: SxObj = {
  color: palette.darkerGray,
  fontWeight: 'bold',
  fontSize: '12px',
  px: 1,
  mb: 1,
};

export const MeetingCardHeader = ({dataId}: Component) => {
  return (
    <Box data-id={dataId} sx={[styles.grid, root]}>
      <span>&nbsp;</span>
      <span>Name</span>
      <span>Status</span>
      <span>Participants</span>
      <span>SRT Connections</span>
    </Box>
  );
};
