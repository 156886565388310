import React, {useEffect, useState} from 'react';
import {Form} from 'app/components/Login/LoginForm/Form';
import {
  AUTH_STATE,
  PASSWORD_REQUIREMENTS_MESSAGE,
  PASSWORD_MIN_LENGTH,
} from 'app/components/Login/LoginForm/constants';
import Auth from '@aws-amplify/auth';
import {FORM_CONTROL_AUTOCOMPLETE} from 'app/constants/formControlAutocomplete';
import {Callback} from 'app/types/common';

function errorProcessor(error) {
  if (typeof error === 'string') {
    return {
      name: 'common',
      message: error,
    };
  }

  switch (error.code) {
    case 'InvalidParameterException':
      if (/password/.test(error.message)) {
        return {
          name: 'password',
        };
      }

      if (/confirmationCode/.test(error.message)) {
        return {
          name: 'code',
          message: 'Invalid verification code provided, please try again',
        };
      }

      return {
        name: 'username',
      };
    case 'InvalidPasswordException':
      return {
        name: 'password',
      };
    case 'CodeMismatchException':
      return {
        name: 'code',
        message: 'Invalid verification code provided, please try again',
      };
    default:
      return {
        name: 'common',
        message: error.message,
      };
  }
}

interface Props {
  authState: AUTH_STATE;
  authData: any;
  switchStateToSignIn: Callback;
}

export const ResetPassword = ({
  authState,
  authData,
  switchStateToSignIn,
}: Props) => {
  const getFields = (authDataProp) => {
    return [
      {
        name: 'email',
        label: 'Your email',
        type: 'email',
        defaultValue: authDataProp.username,
        required: true,
        disabled: Boolean(authDataProp.username),
      },
      {
        name: 'code',
        label: 'Code',
        autoComplete: FORM_CONTROL_AUTOCOMPLETE.OFF,
        helperText: 'Paste code sent to your email',
        required: true,
      },
      {
        name: 'password',
        label: 'New password',
        autoComplete: FORM_CONTROL_AUTOCOMPLETE.NEW_PASSWORD,
        type: 'password',
        minLength: PASSWORD_MIN_LENGTH,
        helperText: PASSWORD_REQUIREMENTS_MESSAGE,
        required: true,
      },
    ];
  };

  const [fields, setFields] = useState(getFields(authData));

  useEffect(() => {
    setFields(getFields(authData));
  }, [authData]);

  const submitAction = async (data) => {
    return Auth.forgotPasswordSubmit(data.email, data.code, data.password)
      .then(() => {
        switchStateToSignIn(data.email);
      });
  };

  if (authState !== AUTH_STATE.RESET_PASSWORD) {
    return null;
  }

  return (
    <Form
      fields={fields}
      buttonText="Reset password"
      descriptionComponent="Check your email and enter the password reset code."
      errorProcessor={errorProcessor}
      submitAction={submitAction}
      // rework
      onSubmit={() => ({})}
    />
  );
};
