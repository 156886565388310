import React from 'react';
import {Typography} from '@mui/material';
import {DataId, Sx} from 'app/types/common';
import {isSoon} from 'app/components/sharedReactComponents/Events/shared/EventCountdown/utils';
import {getTimeToDate} from 'app/util/time';
import {Countdown} from 'app/components/sharedReactComponents/Countdown';
import {Schedule} from 'app/domain/schedule';

interface Props extends Sx, DataId {
  variant: Schedule.EventVariant;
  start: number;
  end: number;
  label?: React.ReactNode;
  overMessage?: string;
}

export function EventCountdown({sx, dataId, start, end, variant, label = '', overMessage}: Props) {
  const getTimestamp = () => {
    if (variant === 'ongoing') return end;

    if (variant === 'scheduled') return start;
  };

  const formatter = variant === 'scheduled' && !isSoon(start) ? getScheduleTime : undefined;

  const timestamp = getTimestamp();

  const message = <Typography color="error.main">{overMessage}</Typography>;

  return timestamp ? (
    <Countdown
      sx={sx}
      data-id={dataId}
      label={label}
      timestamp={timestamp}
      message={message}
      format={formatter}
    />
  ) : null;
}

function getScheduleTime(timestamp: TimeStampSeconds): string {
  return getTimeToDate(timestamp, {trim: 'both'});
}
