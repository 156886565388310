import {useCallback} from 'react';
import {useDeviceSelectable} from 'app/components/FleetManager/useDeviceSelectable';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {UnifySelectActions} from 'app/components/UnifyProjects/types';
import {Callback} from 'app/types/common';
import {
  collectSelectedDevices,
  getDevicesWithOngoingEvents,
} from 'app/components/FleetManager/utils';
import {areAllProjectsSelected} from 'app/components/UnifyProjects/utils';
import {Schedule} from 'app/domain/schedule';

type Return = UnifySelectActions & {
  selected: AnyDeviceModelType[];
  checkIsProjectSelected: Callback<boolean, [string]>;
  toggleSelection: Callback<void, [AnyDeviceModelType]>;
  resetDeviceSelection: Callback;
};

export function useUnifySelection(
  projects: AnyDeviceModelType[],
  actualEvents?: Map<string, Schedule.Event>,
): Return {
  const {
    checkIsDeviceSelected,
    resetDeviceSelection,
    toggleDeviceChannelsSelection,
    resetDeviceAndChannelsSelection,
  } = useDeviceSelectable();

  const selectAction = useCallback(
    (fn: Callback<boolean, [AnyDeviceModelType]>) => {
      resetDeviceAndChannelsSelection(projects.filter(fn));
    },
    [projects, resetDeviceAndChannelsSelection],
  );

  const onSelectOnline = useCallback(() => {
    selectAction((project) => project.isOnline());
  }, [selectAction]);

  const onSelectSuspended = useCallback(() => {
    selectAction((project) => project.isOffline());
  }, [selectAction]);

  const onSelectActive = useCallback(() => {
    selectAction((project) => project.isRecording() || project.isStreaming());
  }, [selectAction]);

  const onSelectRecording = useCallback(() => {
    selectAction((project) => project.isRecording());
  }, [selectAction]);

  const onSelectStreaming = useCallback(() => {
    selectAction((project) => project.isStreaming());
  }, [selectAction]);

  const onSelectEvents = useCallback(() => {
    if (!actualEvents) {
      return;
    }

    const filteredProjects = getDevicesWithOngoingEvents(
      projects.map((p) => p.getId()),
      actualEvents,
    );

    selectAction((project) => filteredProjects.includes(project.getId()));
  }, [selectAction, projects, actualEvents]);

  const onSelectAll = useCallback(() => {
    if (areAllProjectsSelected(projects, checkIsDeviceSelected)) {
      resetDeviceSelection();
    } else {
      resetDeviceAndChannelsSelection(projects);
    }
  }, [projects, resetDeviceAndChannelsSelection, checkIsDeviceSelected, resetDeviceSelection]);

  const selected = collectSelectedDevices(projects, checkIsDeviceSelected);

  return {
    selected,
    resetDeviceSelection,
    onSelectAll,
    onSelectOnline,
    onSelectSuspended,
    onSelectActive,
    onSelectEvents,
    onSelectRecording,
    onSelectStreaming,
    toggleSelection: toggleDeviceChannelsSelection,
    checkIsProjectSelected: checkIsDeviceSelected,
  };
}
