import {useQuery} from '@tanstack/react-query';
import {TeamApiService} from 'app/services/api/team/TeamApiService';
import {stringComparator} from 'app/util/Sort';
import {CloudMapper} from 'app/util/mappers/CloudMapper/CloudMapper';

export function useTeamsQuery() {
  return useQuery({
    queryKey: ['teams'],
    queryFn: async () => {
      const response = await TeamApiService.getTeams();
      return response.map(CloudMapper.mapTeam).sort((a, b) => stringComparator(a.name, b.name));
    },
  });
}
