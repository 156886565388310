import {FleetManagerColumn} from 'app/components/FleetManager/types';
import {
  statusCell,
  healthCell,
  storageCell,
  transferQueueCell,
  accessCell,
  hostNameCell,
} from 'app/components/FleetManager/cells';
import {FleetManagerColumnId} from 'app/components/FleetManager/constants';
import {useColumnActiveManager} from 'app/components/FleetManager/useColumnActiveManager';
import {useColumnArrangementManager} from 'app/components/FleetManager/useColumnArrangementManager';
import {Callback} from 'app/types/common';

const defaultActiveColumns: FleetManagerColumnId[] = [
  FleetManagerColumnId.Status,
  FleetManagerColumnId.HostName,
  FleetManagerColumnId.Storage,
  FleetManagerColumnId.TransferQueue,
  FleetManagerColumnId.Access,
];

const fixedColumns: FleetManagerColumnId[] = [
  FleetManagerColumnId.Status,
  FleetManagerColumnId.Access,
];

interface Return {
  columns: FleetManagerColumn[];
  arrangedColumns: FleetManagerColumn[];
  fixedColumns: FleetManagerColumnId[];
  isColumnActive: Callback<boolean, [string]>;
  onChange: Callback;
  onChangeArrangement: Callback;
}

const storeKey = 'UnifyManager.Columns';

const columns = [
  statusCell({
    width: 110,
  }),
  hostNameCell(),
  storageCell(),
  transferQueueCell(),
  healthCell({
    detailedLabel: 'Project health',
  }),
  accessCell(),
];

export function useUnifyMangerColumns(): Return {
  const {activeColumns, isColumnActive, handleColumnsSelectorChange} = useColumnActiveManager({
    storeKey,
    columns,
    fixedColumns,
    defaultActiveColumns,
  });

  const {arrangedColumns, handleColumnsChangeArrangement} = useColumnArrangementManager({
    storeKey,
    columns,
    activeColumns,
  });

  return {
    columns,
    arrangedColumns,
    fixedColumns,
    isColumnActive,
    onChange: handleColumnsSelectorChange,
    onChangeArrangement: handleColumnsChangeArrangement,
  };
}
