import React, {Component} from 'react';
import classNames from 'classnames';
import {TableRow} from 'app/components/Dashboard/Devices/TableView/TableRow';
import {ClassName} from 'app/types/common';
import {TableColumnType} from 'app/components/sharedReactComponents/Table/types';

interface Props extends ClassName {
  cells: TableColumnType[];
  list: any[];
  renderAsTable: boolean;
}
export class TableBody extends Component<Props> {
  render() {
    const {
      className,
      cells,
      list,
      renderAsTable,
    } = this.props;

    const BodyComponent = renderAsTable ? 'tbody' : 'div';

    return (
      <BodyComponent className={classNames('dashboard-table__body', className)}>
        {list.map(item => (
          <TableRow
            key={item.id}
            cells={cells}
            data={item}
            renderAsTable={renderAsTable}
          />
        ))}
      </BodyComponent>
    );
  }
}
