import {useEffect, useState} from 'react';
import {Participant} from 'app/store/models/connect/Participant/Participant';
import {stringComparator} from 'app/util/Sort';
import {ParticipantSort} from 'app/components/pages/MeetingDetails/ParticipantsList/ParticipantsList';
import {FilterSwitch} from 'app/components/sharedReactComponents/FilterSelector/types';
import {filterItemsByFilterSwitches} from 'app/components/sharedReactComponents/FilterSelector/utils';
import {ReturnFeed} from 'app/store/models/connect/ReturnFeed/ReturnFeed';
import {Meeting} from 'app/store/models/connect/Meeting/Meeting';

interface Return {
  users: Participant[];
  mainstreams: Participant[];
  feed?: ReturnFeed;
}

type SortPredicate = (a: Participant, b: Participant) => number;

export function useParticipants(
  meeting: Meeting,
  sortType: ParticipantSort = 'name',
  filters: FilterSwitch[] = [],
): Return {
  const [result, setResult] = useState<Return>({
    mainstreams: [],
    users: [],
    feed: undefined,
  });

  useEffect(() => {
    const res = meeting.participants.reduce<Return>(
      (acc, p) => {
        if (p.type === 'participant') {
          acc.users.push(p);
          return acc;
        }

        acc.mainstreams.push(p);
        return acc;
      },
      {mainstreams: [], users: []},
    );

    res.feed = meeting.feed;

    const sortPredicate = getSortPredicate(sortType);

    const users = filters.length > 0 ? filterItemsByFilterSwitches(res.users, filters) : res.users;
    const mainstreams =
      filters.length > 0 ? filterItemsByFilterSwitches(res.mainstreams, filters) : res.mainstreams;

    const feed =
      filters.length > 0 && res.feed
        ? filterItemsByFilterSwitches([res.feed], filters)
        : [res.feed];

    setResult({
      users: users.sort(sortPredicate),
      mainstreams: mainstreams.sort(sortByName),
      feed: feed?.[0],
    });
  }, [meeting.participants, meeting.feed, sortType, filters]);

  return result;
}

function getSortPredicate(sort: ParticipantSort): SortPredicate {
  if (sort === 'status') {
    return sortByStatus;
  }

  return sortByName;
}

function sortByName(a: Participant, b: Participant): number {
  return stringComparator(a.name, b.name);
}

type SortOrder = {[K in App.Connect.StreamStatus]: number};

const order: SortOrder = {
  stopped: 0,
  starting: 1,
  stopping: 1,
  started: 2,
  connected: 2,
  disconnected: 2,
  error: 3,
};

function sortByStatus(a: Participant, b: Participant): number {
  const res = order[b.stream.status] - order[a.stream.status];
  return res || sortByName(a, b);
}
