import React, {useState} from 'react';
import dayjs from 'dayjs';
import {useDebounce} from 'react-use';
import {Box, Button, Collapse, Slider, Stack, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Sx} from 'app/types/common';

interface Props extends Sx {
  range: TimeRange;
  onChange: (r: TimeRange) => void;
}

const MIN_TIME = 0;
const MAX_TIME = 86400;
const STEP = 3600;

export function HoursToView({sx, range, onChange}: Props) {
  const [expanded, setExpanded] = useState(false);

  const [state, setState] = useState<TimeRange>(() => ({...range}));

  useDebounce(
    () => {
      onChange({...state});
    },
    300,
    [state],
  );

  const handleExpand = () => {
    setExpanded((p) => !p);
  };

  const handleReset = () => {
    setState({from: MIN_TIME, to: MAX_TIME});
  };

  const {from, to} = state;
  const showReset = from !== MIN_TIME || to !== MAX_TIME;

  return (
    <Box sx={sx}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Button
          data-id="hours-view-btn"
          sx={{minWidth: 254}}
          variant="outlined"
          endIcon={
            <ExpandMoreIcon
              sx={{transform: `rotate(${expanded ? 180 : 0}deg)`, transition: 'transform .3s'}}
            />
          }
          disableRipple={false}
          onClick={handleExpand}
        >
          <Typography textAlign="left" flexGrow={1}>
            Hours to view: {getTimeText(state.from)} - {getTimeText(state.to)}
          </Typography>
        </Button>

        {showReset && (
          <Button data-id="reset-hours" variant="outlined" onClick={handleReset}>
            <Typography>Reset</Typography>
          </Button>
        )}
      </Stack>

      <Collapse sx={{px: 1.5}} in={expanded}>
        <Slider
          data-id="hours-range"
          min={MIN_TIME}
          max={MAX_TIME}
          step={STEP}
          marks={true}
          disableSwap={true}
          value={[state.from, state.to]}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => getTimeText(value)}
          onChange={(_e, value) => {
            if (Array.isArray(value)) {
              const [from, to] = value;

              setState((prev) => {
                if (from !== to) {
                  return {from: from ?? prev[0], to: to ?? prev[1]};
                }

                return prev;
              });
            }
          }}
        />
      </Collapse>
    </Box>
  );
}

const now = dayjs().startOf('date');

function getTimeText(seconds: number) {
  const formatted = now.add(seconds, 'seconds').format('h A');
  return formatted;
}
