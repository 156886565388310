import {OAuth2StreamingDestinationModel} from 'app/components/StreamingServices/StreamingDestinations/OAuth2StreamingDestinationModel';
import {
  STREAMING_SERVICE_SETTINGS_PROP_NAME,
  YOUTUBE_STREAMING_DESTINATION,
  YOUTUBE_STREAMING_PRIVACY,
} from 'app/components/StreamingServices/constants';

class YoutubeStreamingDestinationModel extends OAuth2StreamingDestinationModel {
  getPrivacySetting(): YOUTUBE_STREAMING_PRIVACY {
    return super.getPrivacySetting() || YOUTUBE_STREAMING_PRIVACY.PRIVATE;
  }

  getDestinationSetting(): YOUTUBE_STREAMING_DESTINATION {
    return super.getDestinationSetting() || YOUTUBE_STREAMING_DESTINATION.EVENTS;
  }

  isPreviewAfterPlaying(): boolean {
    return this.getSettings().previewBeforeStart;
  }

  protected processSettingsData(json) {
    const settings = json[this.getSettingsPropName()];

    if (!settings) {
      return {};
    }

    const result = super.processSettingsData(json);

    // Filter unsupported "StreamNow" value
    if (result.publishDestination === YOUTUBE_STREAMING_DESTINATION.STREAM_NOW) {
      result.publishDestination = YOUTUBE_STREAMING_DESTINATION.EVENTS;
    }

    result.previewBeforeStart = settings.YouTubePreview === true;

    return result;
  }

  protected getSettingsPropName() {
    return STREAMING_SERVICE_SETTINGS_PROP_NAME.YOUTUBE;
  }

  protected settingsToJSON({
    previewBeforeStart,
    ...rest
  }) {
    return {
      ...super.settingsToJSON(rest),
      YouTubePreview: previewBeforeStart,
    };
  }
}

export {YoutubeStreamingDestinationModel};
