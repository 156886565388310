import {useQuery} from '@tanstack/react-query';
import {FilesBillingApi} from 'app/components/entities/billing/files/api/FilesBillingApi';
import {FilesBillingMapper} from 'app/components/entities/billing/files/mapper';

type Args = {
  teamId: string;
};

export function useFilesInvoicesQuery({teamId}: Args) {
  return useQuery({
    queryKey: ['billing', 'files', 'invoices', teamId],
    queryFn: async () => {
      const res = await FilesBillingApi.getHistory(teamId);
      return FilesBillingMapper.mapHistory(res);
    },
  });
}
