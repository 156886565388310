import React from 'react';
import classNames from 'classnames';
import {ClassName, DataId} from 'app/types/common';
import {SIZE} from 'app/constants';
import {Icons, SvgIcon} from 'app/util/icons';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';

type InputType = 'audio' | 'video';

interface InputProps extends ClassName {
  muted: boolean;
  type: InputType;
  tooltip?: string;
}

function getIcon(type: InputType, muted: boolean): SvgIcon {
  const isVideo = type === 'video';

  if (isVideo) {
    if (muted) {
      return Icons.videoOff();
    }

    return Icons.videoOn();
  }

  return muted ? Icons.microOff() : Icons.microOn();
}

const ParticipantInputIcon = ({className, muted, type, tooltip}: InputProps) => {
  const icon = getIcon(type, muted);

  return (
    <Tooltip content={tooltip}>
      {icon
        .size(SIZE.S)
        .class(
          classNames('participant-input-icon', muted && 'participant-input-icon--muted', className),
        )
        .reactComponent()}
    </Tooltip>
  );
};

interface Props extends ClassName, DataId {
  video: boolean;
  audio: boolean;
  audioTip?: string;
}

export const ParticipantInput = ({className, audio, video, audioTip, dataId}: Props) => {
  return (
    <div className={classNames('participant-input', className)} data-id={dataId}>
      <ParticipantInputIcon className="participant-input__icon" type="video" muted={!video} />

      <ParticipantInputIcon
        className="participant-input__icon"
        type="audio"
        muted={!audio}
        tooltip={audioTip}
      />
    </div>
  );
};
