import React from 'react';
import {observer} from 'mobx-react';
import {useTrackers} from 'app/hooks/useTrackers';
import {useAppcuesTracker} from 'app/components/Trackers/useAppcuesTracker';
import {useHubspotTracker} from 'app/components/Trackers/useHubspotTracker';
import {useDriftTracker} from 'app/components/Trackers/useDriftTracker';

export const Trackers = observer(({children}: React.PropsWithChildren<{}>) => {
  const {appcues, hubspot} = useTrackers();

  useDriftTracker();
  useAppcuesTracker({enabled: appcues});
  useHubspotTracker({enabled: hubspot});

  return <>{children}</>;
});
