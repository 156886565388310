import {front} from 'app/api/CirrusApi';
import {FilesContracts} from 'app/components/entities/files/model';

export class FilesApi {
  static async getTeamRecordings(): Promise<{files: FilesContracts.Recording[]}> {
    return front.filemanager().v1().files().get();
  }

  static async getUnitRecordings(deviceId: string): Promise<{files: FilesContracts.Recording[]}> {
    return front.filemanager().v1().devices(deviceId).files().get();
  }

  static async batchDelete(ids: string[]): Promise<void> {
    return front.filemanager().v1().files().remove().post(ids);
  }

  static async deleteRecording(id: string): Promise<void> {
    return front.filemanager().v1().files(id).delete();
  }

  static getDownloadUrl(id: string): string {
    return front.filemanager().v1().files(id).download().url() as string;
  }
}
