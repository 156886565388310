import {BaseFTPLocationModel} from 'app/components/UploadLocations/Models/BaseFTPLocationModel';
import {
  computed,
  makeObservable,
  IReactionDisposer,
} from 'mobx';
import {lensPath} from 'ramda';
import {makeSaveLocationApiReaction} from 'app/components/UploadLocations/Models/utils';
import {ValidatedInputModel} from 'app/components/UploadLocations/Models/ValidatedInputModel';

import {
  STORAGE_PROP_NAME,
  STORAGE_TYPE,
} from 'app/components/UploadLocations/constants';

const passwordPathLens = lensPath([STORAGE_PROP_NAME.SFTP, 'Password']);

class SFTPLocationModel extends BaseFTPLocationModel {
  type: STORAGE_TYPE;
  privateKey: ValidatedInputModel;
  stopAutoSave: IReactionDisposer;

  constructor(json, saveLocationApi) {
    json.Path = json.Path || '';
    json.Port = json.Port || 22;

    super(json);

    this.type = STORAGE_TYPE.SFTP;

    const {PrivateKey = ''} = json;
    this.privateKey = new ValidatedInputModel('Private Key', PrivateKey);

    this.stopAutoSave = makeSaveLocationApiReaction(
      () => this.json,
      this.id,
      passwordPathLens,
      saveLocationApi,
    );

    makeObservable(this, {
      options: computed,
      json: computed
    });
  }

  get options() {
    return {
      Server: this.address.value,
      Username: this.username.value,
      Password: this.password.value,
      Path: this.path.value,
      Port: this.port.value,
      PrivateKey: this.privateKey.value,
    };
  }

  get json() {
    return {
      [STORAGE_PROP_NAME.SFTP]: this.options,
      StorageID: this.id,
      Name: this.name.value,
      Type: this.type
    };
  }
}

export {SFTPLocationModel};
