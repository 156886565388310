import React from 'react';
import {Dialog} from '@mui/material';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {Prompt} from 'app/types/common';
import {UnsubscribeFromConnect} from 'app/components/BillingManager/dialogs/ConnectConfirmDialog/ConnectConfirmation/UnsubscribeFromConnect';
import {SubscribeToConnect} from 'app/components/BillingManager/dialogs/ConnectConfirmDialog/ConnectConfirmation/SubscribeToConnect';
import {ConnectArgs} from 'app/components/BillingManager/types/types';

interface Props extends Prompt {
  args: ConnectArgs;
  endDate: TimeStampSeconds;
  onConfirm: Fn;
  onCancel: Fn;
}

export function ConnectConfirmDialog({open, endDate, args, onConfirm, onClose, onCancel}: Props) {
  return (
    <Dialog open={open} maxWidth="md" fullWidth={true} PaperProps={{sx: {p: 10}}} onClose={onClose}>
      <DialogClose onClose={onClose} />

      {args.action === 'unsubscribe' ? (
        <UnsubscribeFromConnect endDate={endDate} onSubmit={onConfirm} onCancel={onCancel} />
      ) : (
        <SubscribeToConnect tier={args.tier} onConfirm={onConfirm} onCancel={onCancel} />
      )}
    </Dialog>
  );
}
