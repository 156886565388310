import React, {useCallback} from 'react';
import {
  THEME,
  VARIANT,
} from 'app/constants';
import {Icons} from 'app/util/icons';
import {StopTranscriptionButton} from 'app/components/sharedReactComponents/StartStopTranscriptionButton/StopTranscriptionButton';
import {TranscribeDuration} from 'app/components/sharedReactComponents/TranscribeDuration';
import {PauseTranscriptionButton} from 'app/components/sharedReactComponents/StartStopTranscriptionButton/PauseTranscriptionButton';
import {StartTranscriptionButton} from 'app/components/sharedReactComponents/StartStopTranscriptionButton/StartTranscriptionButton';
import {LiveScryptDeviceModel} from 'app/components/DeviceDetails/Models/LiveScryptDeviceModel';
import {DeviceAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/DeviceAudioMeter';
import {isDisabledAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/utils';

interface Props {
  device: LiveScryptDeviceModel;
  teamCanTranscribing: boolean;
}

export const TranscriberControls: React.VFC<Props> = ({
  device,
  teamCanTranscribing,
}) => {
  const {active, paused, status, sessionId} = device.getTranscribingInfo();
  const disabled = device.isOffline();

  const startAction = useCallback(async () => device.startTranscribing(), [device]);
  const stopAction = useCallback(async () => device.stopTranscribing(), [device]);
  const pauseAction = useCallback(async () => device.pauseTranscribing(), [device]);
  const resumeAction = useCallback(async () => device.resumeTranscribing(), [device]);

  const renderStart = () => (
    <StartTranscriptionButton
      data-id="start_transcription_button"
      theme={THEME.PRIMARY}
      variant={VARIANT.SOLID}
      disabled={disabled}
      teamCanTranscribing={teamCanTranscribing}
      action={startAction}
    />
  );

  const renderPause = () => (
    <>
      <StopTranscriptionButton
        className="transcriber-controls__stop-button"
        data-id="stop_transcription_button"
        disabled={disabled}
        teamCanTranscribing={teamCanTranscribing}
        action={stopAction}
      >
        {Icons.stop().reactComponent()}
      </StopTranscriptionButton>

      <TranscribeDuration
        className="transcriber-controls__duration"
        deviceId={device.getId()}
        sessionId={sessionId!}
        status={status}
      />

      {paused ? (
        <StartTranscriptionButton
          className="transcriber-controls__resume-button"
          data-id="start_transcription_button"
          disabled={disabled}
          teamCanTranscribing={teamCanTranscribing}
          action={resumeAction}
        >
          {Icons.play().reactComponent()}
        </StartTranscriptionButton>
      ) : (
        <PauseTranscriptionButton
          className="transcriber-controls__pause-button"
          data-id="pause_transcription_button"
          disabled={disabled}
          teamCanTranscribing={teamCanTranscribing}
          action={pauseAction}
        />
      )}
    </>
  );

  return (
    <div className="transcriber-controls">
      <div className="transcriber-controls__buttons">
        {active ? renderPause() : renderStart()}
      </div>

      <DeviceAudioMeter
        id={device.getId()}
        disabled={isDisabledAudioMeter(device)}
      />
    </div>
  );
};
