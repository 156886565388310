/**
 * @readonly
 * @enum {string}
 */
enum DEVICE_START_STOP_BUTTON_STATUS {
  STOPPED = 'stopped',
  LOADING = 'loading',
  STARTED = 'started',
  PAUSED = 'paused',
  ERROR = 'error',
}

/**
 * @readonly
 * @number
 */
const STATUS_CHANGING_TIMEOUT = 10 * 1000;

export {DEVICE_START_STOP_BUTTON_STATUS, STATUS_CHANGING_TIMEOUT};
