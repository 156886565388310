import React from 'react';
import {Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {Sx} from 'app/types/common';
import {isAlertDisabled} from 'app/components/Settings/Alerts/AlertSettings/utils';
import {AlertValue} from 'app/components/Settings/Alerts/AlertSettings/AlertTable/AlertTableBody/AlertValue';
import {Alerts} from 'app/components/entities/alerts';

interface Props extends Sx {
  settings: Alerts.Settings;
  types: Alerts.AlertType[];
  hoveredAlert?: Alerts.AlertType;
  disabled: boolean;
  onChange: (id: string, type: Alerts.AlertType, value: boolean) => void;
}

export function UnitAlert({sx, settings, types, hoveredAlert, disabled, onChange}: Props) {
  return (
    <Stack sx={sx} direction="row" alignItems="center">
      <Typography flexGrow={1} fontWeight={600} minWidth={0} component="div">
        <OverflowTooltip rowsNumber={2}>{settings.deviceName}</OverflowTooltip>
      </Typography>

      <Stack direction="row">
        {types.map((type) => {
          const typeDisabled = disabled || isAlertDisabled(settings, type);

          const typeHovered = hoveredAlert === type && !typeDisabled;

          const handleChange = (type: Alerts.AlertType, value: boolean) => {
            onChange(settings.id, type, value);
          };

          return (
            <AlertValue
              sx={{'&:nth-of-type(odd)': {bgcolor: grey[100]}}}
              key={type}
              type={type}
              value={settings.alerts[type] ?? false}
              hovered={typeHovered}
              disabled={typeDisabled}
              onChange={handleChange}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
