import React from 'react';
import {DeviceSettingType} from 'app/models/Device/Settings/constants';
import {DROPDOWN_SELECT_THEME} from 'app/components/sharedReactComponents/DropdownSelect/constants';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {FORM_CONTROL_TYPE} from 'app/components/sharedReactComponents/FormControl/constants';
import {Callback} from 'app/types/common';
import type {SettingsItemTypeEnumOptions, SettingsItemTypeNumberOptions, SettingsItemValue} from 'app/components/sharedReactComponents/SettingsList/types';

const getOptionLabel = ({title}) => title;
const getOptionValue = ({value}) => value;

interface Props {
  label: string;
  type: DeviceSettingType;
  value: SettingsItemValue;
  description?: string;
  options: SettingsItemTypeNumberOptions | SettingsItemTypeEnumOptions;

  selectTheme?: DROPDOWN_SELECT_THEME;

  disabled: boolean;
  loading: boolean;
  getSettingTitle: Callback;
  onChange: Callback;
}

const SettingsControl: React.VFC<Props> = ({
  label,
  type,
  value,
  description,
  options,
  selectTheme = DROPDOWN_SELECT_THEME.TRANSPARENT,
  disabled,
  loading,
  getSettingTitle,
  onChange,
  ...elementProps
}) => {
  switch (type) {
    case DeviceSettingType.Enum:
      return (
        <FormControl
          name="settings-enum"
          className="settings-control settings-control_type_select"
          type={FORM_CONTROL_TYPE.SELECT}
          label={label}
          value={value}
          helperText={description}
          selectComponentProps={{
            options: (options as SettingsItemTypeEnumOptions).items,
            theme: selectTheme,
            getOptionLabel,
            getOptionValue,
            getOptionTitle: getSettingTitle,
          }}
          loading={loading}
          disabled={disabled}
          onChange={onChange}
          {...elementProps}
        />
      );
    case DeviceSettingType.NullableNumber:
    case DeviceSettingType.Number:
      return (
        <FormControl
          name="settings-number"
          className="settings-control settings-control_type_number"
          type={FORM_CONTROL_TYPE.NUMBER}
          label={label}
          value={value}
          helperText={description}
          loading={loading}
          disabled={disabled}
          placeholder={(options as SettingsItemTypeNumberOptions).placeholder}
          numberComponentProps={{
            min: (options as SettingsItemTypeNumberOptions).min,
            max: (options as SettingsItemTypeNumberOptions).max,
          }}
          onChange={onChange}
          {...elementProps}
        />
      );
    case DeviceSettingType.String:
      return (
        <FormControl
          name="settings-string"
          className="settings-control settings-control_type_text"
          label={label}
          value={value}
          helperText={description}
          loading={loading}
          disabled={disabled}
          fullWidth={true}
          immediateChange={false}
          onChange={onChange}
          {...elementProps}
        />
      );
    case DeviceSettingType.Bool:
      return (
        <FormControl
          name="settings-boolean"
          className="settings-control settings-control_type_checkbox"
          type={FORM_CONTROL_TYPE.SWITCHER}
          label={label}
          value={value}
          helperText={description}
          loading={loading}
          disabled={disabled}
          onChange={onChange}
          {...elementProps}
        />
      );
    case DeviceSettingType.Color:
      return (
        <FormControl
          name="settings-color"
          className="settings-control settings-control_type_color"
          type={FORM_CONTROL_TYPE.COLOR}
          label={label}
          value={value}
          helperText={description}
          loading={loading}
          disabled={disabled}
          onChange={onChange}
          {...elementProps}
        />
      );
    default:
      return null;
  }
};

export {
  SettingsControl,
};
