import React, {Fragment} from 'react';
import {DashboardListHeader} from 'app/components/Dashboard/DashboardListHeader';
import {PairDeviceButton} from 'app/components/libs/buttons/PairDeviceButton';
import {DevicesAlertsCounter} from 'app/components/DevicesAlerts/DevicesAlertsCounter';
import {Callback} from 'app/types/common';

export interface HeaderTools {
  pair: boolean;
}

const defaultHeaderTools: HeaderTools = {
  pair: true,
};

interface Props {
  searchValue: string;
  onSearch: Callback<void, [string]>;
  showSearch?: boolean;
  tools?: HeaderTools;
}

export const Header: React.FC<Props> = ({
  searchValue,
  onSearch,
  showSearch = true,
  tools = defaultHeaderTools,
}) => {
  return (
    <DashboardListHeader
      filterValue={searchValue}
      showSearch={showSearch}
      buttonsComponent={
        <Fragment>
          {tools.pair && <PairDeviceButton />}

          <DevicesAlertsCounter />
        </Fragment>
      }
      onFilter={onSearch}
    />
  );
};
