import {useEffect, useState, useMemo} from 'react';
import {daysToSeconds} from 'app/util/timeConverter';
import {Schedule} from 'app/domain/schedule';
import {getDaysOfPeriod} from 'app/components/sharedReactComponents/Events/Calendar/utils';

type Return = {
  range: TimeRange;
  selected: TimeStampSeconds;
  days: TimeStampSeconds[];
  displayed: TimeStampSeconds[];
  setRange: (r: TimeRange) => void;
  setSelected: (t: TimeStampSeconds) => void;
};

export function useCalendarLayout(period: Schedule.Period): Return {
  const [selected, setSelected] = useState(0);
  const [days, setDays] = useState(() => getDaysOfPeriod(period));
  const [range, setRange] = useState<TimeRange>({from: 0, to: daysToSeconds(1)});

  useEffect(() => {
    setDays(getDaysOfPeriod(period));
    setSelected(0);
  }, [period]);

  const displayed = useMemo(() => {
    return selected === 0 ? days : days.filter((day) => day === selected);
  }, [days, selected]);

  return {
    range,
    days,
    selected,
    displayed,
    setRange,
    setSelected,
  };
}
