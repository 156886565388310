import {useEffect} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {Team, useMembersQuery} from 'app/components/entities/team';
import {TeamMapper} from 'app/components/entities/team/mapper';
import {Ws} from 'app/contracts/ws';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';

interface Args {
  teamId: string;
  enabled: boolean;
}

export function useMembers({enabled, teamId}: Args) {
  const client = useQueryClient();
  const query = useMembersQuery({teamId, enabled});

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const onMemberMessage = (message: Ws.TeamMemberChange) => {
      client.setQueryData<Map<string, Team.Member>>(['team', 'members', teamId], (prev) => {
        if (!prev) {
          return prev;
        }

        const copy = new Map(prev);
        const payload = message.Body;

        switch (payload.Action) {
          case 'added':
          case 'changed': {
            const mapped = TeamMapper.mapMember(payload.Member);
            copy.set(mapped.id, mapped);
            break;
          }

          case 'deleted': {
            copy.delete(payload.ID);
            break;
          }

          default: {
            break;
          }
        }

        return copy;
      });
    };

    WS.on(EVENT_TYPE.TEAM_MEMBER_CHANGE, onMemberMessage);

    return () => {
      WS.off(EVENT_TYPE.TEAM_MEMBER_CHANGE, onMemberMessage);
    };
  }, [enabled, client, teamId]);

  return query;
}
