import React, {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {Callback, ClassName, DataId, Sx, TimeStampSeconds} from 'app/types/common';
import {isNil} from 'app/util/isNil';
import {useMounted} from 'app/hooks/useIsMounted';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {calcTimeToTimestamp} from 'app/components/sharedReactComponents/Countdown/utils';
import {getCountdownToTimestamp} from 'app/util/time';
import {Box, Stack, Typography} from '@mui/material';

interface Props extends ClassName, DataId, Sx {
  timestamp: TimeStampSeconds;
  label?: ReactNode;
  message?: ReactNode;
  tooltip?: ReactNode;
  calculate?: Fn<[value: number, isOver: boolean], [t: TimeStampSeconds]>;
  format?: Fn<string, [t: TimeStampSeconds]>;
  onTimeIsOut?: Callback;
}

const UPDATE_EVERY_MS = 1000;

export function Countdown({
  className,
  dataId,
  timestamp,
  label = '',
  message = '',
  tooltip = '',
  format = getCountdownToTimestamp,
  calculate = calcTimeToTimestamp,
  onTimeIsOut,
  ...elementProps
}: Props) {
  const requestId = useRef<number>(0);
  const mounted = useMounted();

  const [isExpired, setIsExpired] = useState(false);
  const [value, setValue] = useState('');

  const stop = useCallback(() => {
    cancelAnimationFrame(requestId.current);
    requestId.current = 0;
  }, []);

  const start = useCallback(() => {
    let lastCallTimestamp: number | undefined;

    const step = (rafTimestamp: number) => {
      if (isNil(lastCallTimestamp) || rafTimestamp - lastCallTimestamp > UPDATE_EVERY_MS) {
        lastCallTimestamp = rafTimestamp;
        if (mounted()) {
          const [time, isOver] = calculate(timestamp);
          setIsExpired(isOver);

          if (isOver) {
            stop();
            setValue('');
            onTimeIsOut?.();
            return;
          }

          setValue(format(time));
        }
      }

      requestId.current = requestAnimationFrame(step);
    };

    requestId.current = requestAnimationFrame(step);
  }, [timestamp, mounted, stop, calculate, format, onTimeIsOut]);

  useEffect(() => {
    start();
    return () => {
      stop();
    };
  }, [stop, start]);

  return (
    <Box className={classNames('countdown', className)} data-id={dataId} {...elementProps}>
      {isExpired ? (
        message
      ) : (
        <Tooltip content={tooltip}>
          <Stack
            display="inline-flex"
            direction="row"
            alignItems="center"
            className="countdown__content"
            gap={0.5}
          >
            {label}
            <Typography color="inherit">{value}</Typography>
          </Stack>
        </Tooltip>
      )}
    </Box>
  );
}
