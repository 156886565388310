import {AnyUserRoleModel, UserRole} from 'app/models/PermissionsModel/types';

interface Args {
  id: string;
  name: string;
  role: UserRole;
}

export class AppTeam {
  id: string;
  name: string;
  role: AnyUserRoleModel;

  constructor({id, name, role}: Args) {
    this.id = id;
    this.name = name;
    this.role = role;
  }
}
