import {makeAutoObservable} from 'mobx';
import {FirmwareUpdateStatus} from 'app/components/DeviceDetails/constants';
import {Edge} from 'app/domain/edge';

export type FirmwareUpdate = {
  progress?: number;
  status: FirmwareUpdateStatus;
};

export interface FirmwareData {
  current: string;
  available?: string;
  update?: FirmwareUpdate;
}

export class FirmwareFeature {
  private readonly firmware: Edge.Firmware;

  constructor(deviceFirmware: Edge.Firmware) {
    this.firmware = deviceFirmware;
    makeAutoObservable(this);
  }

  private get update() {
    return this.firmware.update;
  }

  get version(): string {
    return this.firmware.current;
  }

  get availableVersion(): string | undefined {
    return this.firmware.available;
  }

  get updateStatus() {
    return this.update?.status;
  }

  get updateAvailable(): boolean {
    if (this.updateStatus) {
      return this.updateStatus === FirmwareUpdateStatus.Available;
    }

    return false;
  }

  get updateInProgress() {
    return this.updateStatus
      ? this.updateStatus === 'progress' || this.updateStatus === 'downloading'
      : false;
  }
}
