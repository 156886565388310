import React from 'react';
import {Box, Chip, Divider} from '@mui/material';
import {Except} from 'type-fest';
import {EventCard} from 'app/components/sharedReactComponents/Events/List/EventCard/EventCard';
import {Component, Sx} from 'app/types/common';
import {Schedule} from 'app/domain/schedule';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {Virtuoso} from 'react-virtuoso';

type CardActions = Except<React.ComponentProps<typeof EventCard>, 'event' | 'sx' | 'allowStart'>;

interface Props extends Component, Sx, CardActions {
  events: Schedule.Event[];
  devices?: Map<string, PearlMasterDeviceModel>;
  showTitle?: boolean;
  title?: string;
  actualEventIds?: Set<string>;
}

export const EventsList = React.memo(
  ({sx, events, devices, showTitle, title, actualEventIds, ...actions}: Props) => {
    return (
      <Box sx={sx}>
        {showTitle && (
          <Divider sx={{mb: 1}} orientation="horizontal" flexItem={true}>
            <Chip label={title} size="small" />
          </Divider>
        )}

        <Virtuoso
          data={events}
          useWindowScroll={true}
          itemContent={(_, e) => {
            const allowStart = actualEventIds?.has(e.id) ?? false;

            return (
              <Box pb={1}>
                <EventCard
                  dataId={e.id}
                  key={e.id}
                  devices={devices}
                  event={e}
                  allowStart={allowStart}
                  {...actions}
                />
              </Box>
            );
          }}
        />
      </Box>
    );
  },
);
