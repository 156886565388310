import React, {useCallback} from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {OutputHDMI} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Output/OutputHDMI';
import {OutputWebStream} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Output/OutputWebStream';
import {OutputFrontScreen} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Output/OutputFrontScreen';
import {Sx} from 'app/types/common';
import {LiveScryptDeviceModel} from 'app/components/DeviceDetails/Models/LiveScryptDeviceModel';

interface Props extends Sx {
  device: LiveScryptDeviceModel;
}

export function Output({sx, device}: Props) {
  const updateSettingAction = useCallback(
    async (id: string, value: string) => {
      return device.sendCommand(`setprop:${id}=${value}`);
    },
    [device],
  );

  return (
    <Box sx={sx}>
      <Typography fontWeight={600} variant="h5" mb={3}>
        Output
      </Typography>

      <Stack direction="row" flexWrap="wrap" className="transcribe-output-settings">
        <OutputHDMI
          className="transcribe-output-settings__item"
          device={device}
          updateSettingAction={updateSettingAction}
        />

        <OutputWebStream
          className="transcribe-output-settings__item"
          device={device}
          updateSettingAction={updateSettingAction}
        />

        <OutputFrontScreen
          className="transcribe-output-settings__item"
          device={device}
          updateSettingAction={updateSettingAction}
        />
      </Stack>
    </Box>
  );
}
