import React from 'react';
import {Box, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {DeviceAlertsTable} from 'app/components/DevicesAlerts/DeviceAlertsTable';
import {DateAlerts} from 'app/components/DevicesAlerts/types';
import {timestampFromNow} from 'app/util/time';
import {capitalize} from 'app/components/helpers/commonHelpers';

interface Props extends Sx {
  group: DateAlerts;
  showHeader: boolean;
}

export function DevicesAlertsDay({sx, group, showHeader}: Props) {
  return (
    <Box sx={sx} data-id="devices_alerts_day">
      <Typography data-id="alerts_day_date" fontWeight={600} fontSize={16} mb={1}>
        {capitalize(timestampFromNow(group.date))}
      </Typography>

      <DeviceAlertsTable alerts={group.alerts} showHeader={showHeader} />
    </Box>
  );
}
