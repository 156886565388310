import React from 'react';

export const DropdownSelectGroupOption: React.FC<{label: React.ReactNode}> = ({
  label,
  children,
}) => (
  <li className="dropdown-select__group-option">
    <div className="dropdown-select__group-option-label">
      {label}
    </div>

    <ul className="dropdown-select__group-option-list">
      {children}
    </ul>
  </li>
);
