import React from 'react';
import classNames from 'classnames';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {EditableNameText} from 'app/components/sharedReactComponents/EditableNameText';
import {emptyStringValidator, nameValidator} from 'app/util/validators';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  name: string;
  disabled: boolean;
  started: boolean;
  onChange?: Callback;
}

export const Name: React.VFC<Props> = ({
  className,
  name,
  disabled,
  started,
  onChange,
}) => {
  return (
    <div
      className={classNames('device-streaming-destination-list-item__name', {
        'device-streaming-destination-list-item__name--disabled': disabled,
        'device-streaming-destination-list-item__name--started': started,
      }, className)}
    >
      {onChange ? (
        <EditableNameText
          dataId="streaming_destination_name"
          value={name}
          validators={[
            nameValidator,
            emptyStringValidator,
          ]}
          disabled={started}
          onChange={onChange}
        />
      ) : (
        <OverflowTooltip dataId="streaming_destination_name">
          {name}
        </OverflowTooltip>
      )}
    </div>
  );
};
