/**
 * Object is empty or not
 *
 * {@link https://stackoverflow.com/a/34491966/1284255}
 *
 * ```typescript
 * isEmptyObject({}); //=> true
 * isEmptyObject({foo: 1}); //=> false
 * ```
 */
export function isEmptyObject(object: Record<any, any>): boolean {
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      return false;
    }
  }

  return true;
}
