import {makeAutoObservable} from 'mobx';

export interface SrtStreamArgs {
  status: App.Connect.StreamStatus;
  error?: App.Connect.SteamError;
  settings?: App.Connect.StreamSettings;
  latency?: number;
}

export class SrtStream {
  status: App.Connect.StreamStatus;
  error?: App.Connect.SteamError = undefined;
  settings?: App.Connect.StreamSettings;
  latency?: number;

  constructor({status, error, settings, latency}: SrtStreamArgs) {
    this.status = status;
    this.error = error;
    this.settings = settings;
    this.latency = latency;
    makeAutoObservable(this);
  }

  setSettings(settings: App.Connect.StreamSettings) {
    this.settings = settings;
  }

  get stopped() {
    return this.status === 'stopped';
  }

  get stopping() {
    return this.status === 'stopping';
  }

  get started() {
    return this.status === 'started';
  }

  get starting() {
    return this.status === 'starting';
  }

  get connected() {
    return this.status === 'connected';
  }

  get disconnected() {
    return this.status === 'disconnected';
  }

  get running() {
    return this.started || this.connected || this.disconnected;
  }

  get failed() {
    return this.status === 'error';
  }

  get active() {
    return !this.stopped;
  }

  get mute() {
    return this.settings?.mute;
  }

  get listener() {
    return this.settings?.mode === 'listener';
  }

  getActualLatency() {
    if (this.connected) {
      return this.latency ?? this.settings?.latency;
    }

    return this.settings?.latency;
  }
}
