import React, {useMemo, useState} from 'react';
import {
  Box,
  Chip,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {grey} from '@mui/material/colors';
import {debounce} from 'throttle-debounce';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {DevicePicture} from 'app/components/sharedReactComponents/DevicePicture/DevicePicture';
import {containsSearchString} from 'app/components/FleetManager/utils';
import {Sx} from 'app/types/common';
import {Cloud} from 'app/domain/cloud';

interface Props extends Sx {
  devices: PearlMasterDeviceModel[];
  checkIsSelected(deviceId: string): boolean;
  onSelect(d: PearlMasterDeviceModel): void;
}

export function DevicePicker({sx, devices, checkIsSelected, onSelect}: Props) {
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState<PearlMasterDeviceModel[]>(() => devices);

  const debouncedFilter = useMemo(
    () =>
      debounce(300, (input: string) => {
        const result = devices.filter((d) => containsSearchString(d.getName(), input));
        setFiltered(result);
      }),
    [devices],
  );

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {value} = e.target;
    setSearch(value);
    debouncedFilter(value);
  };

  const hasFiltered = filtered.length > 0;

  return (
    <Stack sx={sx} maxHeight={400}>
      <Typography px={2} my={2} fontWeight={600}>
        Select from devices connected to Epiphan CMS
      </Typography>

      <TextField
        sx={{px: 2, mb: 1}}
        inputProps={{'data-id': 'device-search-input'}}
        fullWidth={true}
        autoFocus={true}
        value={search}
        onChange={handleSearchInput}
      />

      <Box sx={{pl: 2, pr: 1, overflow: 'auto', flexGrow: 1, mb: 1}}>
        {hasFiltered && (
          <List>
            {filtered.map((d) => {
              const deviceId = d.getId();
              const offline = d.isOffline();
              const selected = checkIsSelected(deviceId);

              const renderSuffix = () => {
                if (selected) {
                  return <Chip variant="outlined" label="selected" size="small" />;
                }

                if (offline) {
                  return <Chip label="offline" size="small" />;
                }

                return null;
              };

              return (
                <ListItemButton
                  key={deviceId}
                  sx={{gap: 1}}
                  data-id={`device-${deviceId}-button`}
                  disabled={selected}
                  onClick={() => onSelect(d)}
                >
                  <ListItemText primaryTypographyProps={{noWrap: true}}>{d.getName()}</ListItemText>

                  {renderSuffix()}

                  <DevicePicture sx={{width: 60}} model={d.getModelName() as Cloud.UnitModel} />
                </ListItemButton>
              );
            })}
          </List>
        )}
        {!hasFiltered && (
          <Typography color={grey[500]} p={2}>
            Not found
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
