import React, {useState} from 'react';
import {Box, DialogContent, DialogTitle} from '@mui/material';
import {Callback, Component} from 'app/types/common';
import {
  PaymentForm,
  PaymentPayload,
} from 'app/components/sharedReactComponents/PaymentForm/PaymentForm';
import {CouponState} from 'app/components/BillingManager/hooks/useCoupon';
import {isErrorResponse} from 'app/api/types';

interface Props extends Component {
  email: string;
  hasConnect: boolean;
  mode: 'create' | 'edit';
  showMessage: boolean;
  coupon: CouponState;
  setCoupon: Callback<void, [value: string]>;
  validateCoupon: Callback<Promise<void>, [value: string]>;
  onConfirm: Callback<Promise<void>, [p: PaymentPayload]>;
}

export function EditTeamPayment({
  dataId,
  email,
  showMessage,
  mode,
  hasConnect,
  coupon,
  setCoupon,
  validateCoupon,
  onConfirm,
}: Props) {
  const [error, setError] = useState('');

  const handleConfirm = async (data: PaymentPayload) => {
    try {
      setError('');
      await onConfirm(data);
    } catch (e: unknown) {
      if (isErrorResponse(e)) {
        setError(e.data.Error ?? '');
      } else {
        setError((e as Error).message);
      }
    }
  };

  return (
    <Box data-id={dataId}>
      <DialogTitle>
        {mode === 'create' ? 'Add billing information' : 'Edit billing information'}
      </DialogTitle>

      <DialogContent sx={{p: 5}}>
        {showMessage && (
          <p className="billing-information__message">
            To apply a voucher you need to fill in the billing information.
            <br />
            It will be used to issue invoices after the voucher credits are spent.
          </p>
        )}

        <PaymentForm
          hasConnect={hasConnect}
          email={email}
          externalError={error}
          coupon={coupon}
          setCoupon={setCoupon}
          validateCoupon={validateCoupon}
          onSubmit={handleConfirm}
        />
      </DialogContent>
    </Box>
  );
}
