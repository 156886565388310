import React from 'react';
import {Callback} from 'app/types/common';
import {ModalPrompt} from 'app/components/sharedReactComponents/Modal/ModalPrompt';
import {SIZE, THEME} from 'app/constants';
import {ModalTitleHighlightedItem} from 'app/components/sharedReactComponents/Modal/Modal';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';

interface Props {
  name: string;
  onDone: () => Promise<unknown>;
  onClose?: Callback;
}

export const DeleteMeetingPrompt = {
  show({
    name,
    onClose,
    onDone,
  }: Props) {
    ModalPrompt.show({
      className: 'delete-meeting-prompt',
      fullWidth: true,
      maxWidth: SIZE.S,
      title: (
        <>
          Delete&nbsp;
          <ModalTitleHighlightedItem>
            <OverflowTooltip className="delete-meeting-prompt__name">
              {name}
            </OverflowTooltip>
          </ModalTitleHighlightedItem>
          ?
        </>
      ),
      body: (
        <>
          This meeting will be deleted and all the meeting's data will<br/>
          be lost. Payment for this meeting will be stopped.
        </>
      ),
      confirmButtonLabel: 'Yes, delete',
      confirmButtonTheme: THEME.DANGER,
      onClose,
      onDone,
    });
  }
};
