import {DeviceSetting} from 'app/components/DeviceDetails/Models/types';
import {isNil} from 'app/util/isNil';
import {DeviceSettingId} from 'app/models/Device/Settings/constants';

export class BaseDeviceSettingModel {
  _setting: DeviceSetting;
  _valueFormattedCache: Record<string, string | number | boolean | undefined>;

  constructor(setting: DeviceSetting) {
    this._setting = setting;

    this._valueFormattedCache = {};
  }

  getId(): DeviceSettingId {
    return this._setting.id;
  }

  getLabel(): string {
    return this._setting.title;
  }

  getValue() {
    return this._setting.value;
  }

  getValueFormatted() {
    const value = this.getValue();

    if (isNil(this._valueFormattedCache[value as string])) {
      this._valueFormattedCache = {
        [value as string]: this._formatValue()
      };
    }

    return this._valueFormattedCache[value as string];
  }

  _formatValue() {
    return this.getValue();
  }

  getTypeName() {
    return this._setting.type.name;
  }

  getOptions() {
    return this._setting.type.options;
  }

  toJSON() {
    return this._setting;
  }
}
