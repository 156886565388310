import React, {useCallback} from 'react';
import classNames from 'classnames';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {StorageIcon} from 'app/components/UploadLocations/UploadLocationIcons/StorageIcon';
import {Callback} from 'app/types/common';
import {STORAGE_TYPE} from 'app/components/UploadLocations/constants';

interface Props {
  type: STORAGE_TYPE;
  name: string;
  onClick: Callback;
  disabled?: boolean;
}

export function Button({
  type,
  name,
  onClick,
  disabled,
}: Props) {
  const handleClick = useCallback(() => {
    if (disabled) {
      return;
    }

    onClick(type);
  }, [disabled, onClick, type]);

  const renderButton = () => {
    const className = classNames('cr-new-location-selector__item', {
      'cr-new-location-selector__item--disabled': disabled,
    });

    return (
      <div
        className={className}
        onClick={handleClick}
      >
        <div className="cr-new-location-selector__item-icon">
          <StorageIcon type={type}/>
        </div>

        <div className="cr-new-location-selector__item-name">
          {name}
        </div>
      </div>
    );
  };

  if (disabled) {
    return (
      <Tooltip content="Coming soon">
        {renderButton()}
      </Tooltip>
    );
  }

  return renderButton();
}
