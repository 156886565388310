import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {ChannelCard} from 'app/components/FleetManager/DeviceCard/ChildCard/ChannelCard';
import {Callback, ClassName} from 'app/types/common';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

interface Props extends ClassName {
  device: PearlMasterDeviceModel;
  streamingDestinations: AnyStreamingDestinationModelType[];
  selectable: boolean;
  currentUserRole: AnyUserRoleModel;
  hasEvent?: boolean;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  checkIsDeviceSelected: Callback;
  toggleDeviceSelection: Callback;
  onChangeStreamingDestination: Callback;
}

export const Channels = React.memo(
  ({
    className,
    device,
    streamingDestinations,
    selectable,
    currentUserRole,
    hasEvent,
    getDeviceById,
    checkIsDeviceSelected,
    toggleDeviceSelection,
    onChangeStreamingDestination,
  }: Props) => {
    const [channelDevices, setChannelDevices] = useState<PearlSlotDeviceModel[]>([]);

    useEffect(() => {
      setChannelDevices(device.getChanelModels());
    }, [device]);

    if (channelDevices.length === 0) {
      return null;
    }

    const isUnify = ModelService.isUnify(device.getModelName());

    return (
      <div className={classNames('device-card-channels', className)}>
        {channelDevices.map((channelDevice) => (
          <ChannelCard
            key={channelDevice.getId()}
            className="device-card-channels__item"
            device={channelDevice}
            masterDevice={device}
            selectable={selectable}
            streamingDestinations={streamingDestinations}
            currentUserRole={currentUserRole}
            isUnify={isUnify}
            hasEvent={hasEvent}
            getDeviceById={getDeviceById}
            checkIsDeviceSelected={checkIsDeviceSelected}
            toggleDeviceSelection={toggleDeviceSelection}
            onChangeStreamingDestination={onChangeStreamingDestination}
          />
        ))}
      </div>
    );
  },
);
