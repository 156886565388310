import React from 'react';
import {Icons} from 'app/util/icons';

export function LoadingSpinner() {
  return (
    <span className="loading-wrapper__spinner-wrapper">
      {Icons.spinner().class('loading-wrapper__spinner').reactComponent()}
    </span>
  );
}
