import {DriftComScripts} from 'app/util/DriftComTracker/DriftComScripts';

interface UserOptions {
  userId: string;
  userEmail: string;
  teamId: string;
}

export class DriftComTracker {
  static runApp(appKey: string, {teamId, userId, userEmail}: UserOptions) {
    DriftComScripts.initialize();
    DriftComScripts.configure();
    DriftComScripts.identify(userId, userEmail, teamId);
    DriftComScripts.run(appKey);
  }

  static useInMaintenance() {
    DriftComScripts.initialize();
    DriftComScripts.configure();
    DriftComScripts.resetUserData();
    DriftComScripts.start();
  }

  static useInLogin() {
    DriftComScripts.initialize();
    DriftComScripts.configure(true);
    DriftComScripts.resetUserData();
    DriftComScripts.start();
  }

  static logOut() {
    DriftComScripts.resetUserData();
  }
}
