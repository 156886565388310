import React from 'react';
import {OutputItem} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Output/OutputItem';
import {isNil} from 'app/util/isNil';
import {Callback, ClassName} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {useDeviceSettingsCollection} from 'app/hooks/Device/useDeviceSettingsCollection';

interface Props extends ClassName {
  device: AnyDeviceModelType;
  updateSettingAction: Callback;
}

export const OutputFrontScreen: React.VFC<Props> = ({
  className,
  device,
  updateSettingAction,
}) => {
  const deviceSettingsCollection = useDeviceSettingsCollection(device);

  if (!deviceSettingsCollection) {
    return null;
  }

  const frontScreenOutputSettings = deviceSettingsCollection.getFrontScreenOutputSettings();
  if (isNil(frontScreenOutputSettings)) {
    return null;
  }

  return (
    <OutputItem
      className={className}
      title={frontScreenOutputSettings.getLabel()}
      settings={frontScreenOutputSettings.getSettings()}
      disabled={device.isOffline()}
      updateSettingAction={updateSettingAction}
    />
  );
};
