import {useEffect, useState, useCallback, useMemo} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {produce} from 'immer';
import {throttle} from 'throttle-debounce';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {WS} from 'app/api/WebSocket/WS';
import {Ws} from 'app/contracts/ws';
import {createDeviceModel} from 'app/components/DeviceDetails/Models/Fabric';
import {useTeamScheduleQuery} from 'app/hooks/useTeamScheduleQuery';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {ScheduleMapper} from 'app/util/mappers/ScheduleMapper';
import {Schedule} from 'app/domain/schedule';
import {getCurrentPeriod, sortEvents} from 'app/components/sharedReactComponents/Events/utils';
import {useGetEventsDevices} from 'app/components/sharedReactComponents/Events/hooks/useGetEventsDevices';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {useWsMessageQueue} from 'app/hooks/useWsMessageQueue';
import {useDevicesState} from 'app/hooks/useDevicesState';
import {useActualEvents} from 'app/components/sharedReactComponents/Events/hooks/useActualEvents';
import {setDesiredState, setPublishers, setUnitStatus} from 'app/components/features/edge/utils';
import {parseMasterAndChannelIds} from 'app/components/DeviceDetails/utils';

const defaultConnection: Schedule.TeamConnection = {
  total: 0,
  hasEdge: false,
  hasExternal: false,
};

interface Args {
  enabled: boolean;
  teamId: string;
}

interface Return {
  ready: boolean;
  connection: Schedule.TeamConnection;
  events: Schedule.Event[];
  devices?: Map<string, PearlMasterDeviceModel>;
  period: Schedule.Period;
  fetching: boolean;
  actualEventIds: Set<string>;
  setPeriod: React.Dispatch<React.SetStateAction<Schedule.Period>>;
  refetch: () => Promise<any>;
}

const defaultDevices = new Map<string, PearlMasterDeviceModel>();

export function useTeamSchedule({enabled, teamId}: Args): Return {
  const queryClient = useQueryClient();

  const [period, setPeriod] = useState<Schedule.Period>(() => getCurrentPeriod());

  const [events, setEvents] = useState<Schedule.Event[]>([]);
  const [connection, setConnection] = useState<Schedule.TeamConnection>(() => defaultConnection);
  const {map: deviceMap, setMap: setDeviceMap, setDevice, get: getDevice} = useDevicesState();

  const [ready, setReady] = useState(!enabled);

  const scheduleQuery = useTeamScheduleQuery({teamId, enabled, period});

  const {queue: scheduleQueue, setQueue: setScheduleQueue} =
    useWsMessageQueue<Ws.ScheduleEventChange>();

  const actualEvents = useActualEvents({enabled, teamId});

  const refetchSchedule = scheduleQuery.refetch;

  const observedDevices = useGetEventsDevices(scheduleQuery.data?.events);

  const hasObservedDevices = enabled && observedDevices.length > 0;

  const devicesQuery = useQuery({
    queryKey: ['team-schedule-devices', teamId, ...observedDevices],
    queryFn: async () => {
      const response = await DeviceApiService.getUnits(observedDevices);
      const mapped = response.map((d) => createDeviceModel(d));
      return new Map(mapped.map((m) => [m.getId(), m]));
    },
    enabled: hasObservedDevices,
    keepPreviousData: true,
  });

  const captureScheduleWs = scheduleQuery.isFetching;

  useEffect(() => {
    if (!enabled || !scheduleQuery.isSuccess) {
      setEvents([]);
      setConnection(defaultConnection);
      return;
    }

    const list = Array.from(scheduleQuery.data.events.values()).sort(sortEvents);
    setEvents(list);
    setConnection(scheduleQuery.data.connection);
  }, [enabled, scheduleQuery.data, scheduleQuery.isSuccess]);

  useEffect(() => {
    if (!enabled || !hasObservedDevices) {
      setDeviceMap(new Map());
      return;
    }

    const devices = new Map<string, PearlMasterDeviceModel>();

    devicesQuery.data?.forEach((d, deviceId) => {
      if (d instanceof PearlMasterDeviceModel) {
        devices.set(deviceId, d);
      }
    });

    setDeviceMap(devices);
  }, [enabled, hasObservedDevices, devicesQuery.data, setDeviceMap]);

  const handleEventStatusChange = useCallback(
    (message: Ws.ScheduleEventChange) => {
      queryClient.setQueriesData<Schedule.TeamSchedule>(['team-schedule', teamId], (schedule) => {
        if (schedule) {
          const {events} = schedule;
          const mapped = ScheduleMapper.mapEvent(message.Body);

          if (events.has(mapped.id)) {
            return {
              ...schedule,
              events: produce(events, (map) => {
                map.set(mapped.id, mapped);
              }),
            };
          }

          return {...schedule, events};
        }

        return schedule;
      });
    },
    [queryClient, teamId],
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const onScheduleChange = async () => {
      await refetchSchedule();
    };

    const throttled = throttle(5000, onScheduleChange);

    WS.on(EVENT_TYPE.EVENT_SCHEDULE_CHANGED, throttled);

    return () => {
      WS.off(EVENT_TYPE.EVENT_SCHEDULE_CHANGED, throttled);
      throttled.cancel();
    };
  }, [enabled, refetchSchedule]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const onChronoEventChange = async (m: Ws.ScheduleEventChange) => {
      if (captureScheduleWs) {
        setScheduleQueue((p) => [...p, m]);
      } else {
        handleEventStatusChange(m);
      }
    };

    WS.on(EVENT_TYPE.CHRONO_EVENT_CHANGED, onChronoEventChange);

    return () => {
      WS.off(EVENT_TYPE.CHRONO_EVENT_CHANGED, onChronoEventChange);
    };
  }, [enabled, captureScheduleWs, setScheduleQueue, handleEventStatusChange]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (!captureScheduleWs && scheduleQueue.length > 0) {
      scheduleQueue.forEach((msg) => {
        handleEventStatusChange(msg);
      });
      setScheduleQueue([]);
    }
  }, [enabled, captureScheduleWs, scheduleQueue, handleEventStatusChange, setScheduleQueue]);

  useEffect(() => {
    if (!observedDevices.length) {
      return;
    }

    const observed = new Set(observedDevices);

    const onDeviceChange = (message: Ws.DeviceChange) => {
      const {DeviceID: deviceId} = message.Body;

      if (!observed.has(deviceId)) {
        return;
      }

      const instance = createDeviceModel(message.Body.Payload);
      setDevice(instance);
    };

    const onStatusChange = (message: Ws.DeviceStatusChange) => {
      const {DeviceID: deviceId} = message.Body;

      if (!observed.has(deviceId)) {
        return;
      }

      const instance = getDevice(deviceId);

      if (instance) {
        const copy = instance.getInnerModel();
        const updated = setUnitStatus(copy, message);
        setDevice(createDeviceModel(updated));
      }
    };

    const onStateChange = (message: Ws.DeviceDesiredState) => {
      const {DeviceID: deviceId} = message.Body;

      if (!observed.has(deviceId)) {
        return;
      }

      const instance = getDevice(deviceId);

      if (instance) {
        const copy = instance.getInnerModel();
        const updated = setDesiredState(copy, message.Body.Payload);
        setDevice(createDeviceModel(updated));
      }
    };

    const onPublishersChange = (message: Ws.DevicePublishers) => {
      const {DeviceID: deviceId} = message.Body;

      if (!observed.has(deviceId)) {
        return;
      }

      const instance = getDevice(deviceId);

      if (instance) {
        const copy = instance.getInnerModel();
        const updated = setPublishers(copy, message.Body.Payload);
        setDevice(createDeviceModel(updated));
      }
    };

    const onRenameDevice = (message: Ws.DeviceRename) => {
      const {DeviceID: deviceId} = message.Body;

      const [masterId, channelIdx] = parseMasterAndChannelIds(deviceId);

      if (!observed.has(masterId)) {
        return;
      }

      const instance = getDevice(masterId);

      if (!instance) {
        return;
      }

      const copy = instance.getInnerModel();

      if (channelIdx) {
        const channels = (copy.Child ?? []).map((channel) =>
          channel.Id === deviceId ? {...channel, Name: message.Body.Payload.Name} : channel,
        );

        setDevice(createDeviceModel({...copy, Child: channels}));
        return;
      }

      setDevice(createDeviceModel({...copy, Name: message.Body.Payload.Name}));
    };

    WS.on(EVENT_TYPE.DEVICE_CHANGE, onDeviceChange);
    WS.on(EVENT_TYPE.DEVICE_STATUS, onStatusChange);
    WS.on(EVENT_TYPE.DEVICE_DESIRED_STATE, onStateChange);
    WS.on(EVENT_TYPE.DEVICE_PUBLISHERS, onPublishersChange);
    WS.off(EVENT_TYPE.DEVICE_RENAME, onRenameDevice);

    return () => {
      WS.off(EVENT_TYPE.DEVICE_CHANGE, onDeviceChange);
      WS.off(EVENT_TYPE.DEVICE_STATUS, onStatusChange);
      WS.off(EVENT_TYPE.DEVICE_DESIRED_STATE, onStateChange);
      WS.off(EVENT_TYPE.DEVICE_PUBLISHERS, onPublishersChange);
      WS.off(EVENT_TYPE.DEVICE_RENAME, onRenameDevice);
    };
  }, [observedDevices, setDevice, getDevice]);

  useEffect(() => {
    if (scheduleQuery.isSuccess) {
      setReady(true);
    }
  }, [scheduleQuery.isSuccess]);

  const actualEventIds = useMemo(() => {
    const items = [...(actualEvents?.values() ?? [])].map((e) => e.id);
    return new Set(items);
  }, [actualEvents]);

  return {
    period,
    events,
    ready,
    connection,
    actualEventIds,
    fetching: scheduleQuery.isFetching,
    devices: deviceMap ? (deviceMap as Map<string, PearlMasterDeviceModel>) : defaultDevices,
    setPeriod,
    refetch: refetchSchedule,
  };
}
