import React, {ReactNode} from 'react';
import {Box, Link, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {ClassName, DataId, Sx} from 'app/types/common';

interface Props extends Sx, DataId, ClassName {
  product: string;
  title: ReactNode;
  image: ReactNode;
  description: ReactNode;
  link: string;
  action: ReactNode;
}

export function ProductCard({
  sx,
  className,
  dataId,
  product,
  title,
  image,
  description,
  link: more,
  action,
}: Props) {
  return (
    <Box
      sx={sx}
      data-id={dataId}
      className={className}
      color="white"
      width={285}
      borderRadius={2}
      px={2}
      py={4}
      gap={2}
      display="grid"
      justifyItems="center"
    >
      <Typography fontWeight={600}>{product}</Typography>

      <Typography height={82} fontSize={18} fontWeight={600} textAlign="center">
        {title}
      </Typography>

      <Box height={114}>{image}</Box>

      <Typography height={104} fontSize={16} textAlign="center">
        {description}
      </Typography>

      <Link
        sx={{
          fontWeight: 600,
          '&, &:hover, &:focus': {
            color: grey[300],
          },
        }}
        underline="hover"
        href={more}
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </Link>

      <div>{action}</div>
    </Box>
  );
}
