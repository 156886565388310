import {useReducer} from 'react';

/**
 * {@link https://usehooks.com/useToggle/}
 * @param {boolean} [initialValue=false]
 * @return {[boolean, function]}
 *
 * ```typescript
 * const [disabled, toggleDisabled] = useToggle();
 * ```
 */
export function useToggle(initialValue = false) {
  return useReducer((state) => !state, initialValue);
}
