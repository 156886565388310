import React from 'react';
import classNames from 'classnames';
import {SIZE} from 'app/constants';
import {Icons} from 'app/util/icons';
import {StartStopButton} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton';
import {DEVICE_START_STOP_BUTTON_STATUS} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton/constants';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  status: DEVICE_START_STOP_BUTTON_STATUS;
  startTime: number;
  receivedTime: number;
  size?: SIZE;
  disabled?: boolean;
  hovered?: boolean;
  startAction: Callback;
  resumeAction: Callback;
  stopAction: Callback;
}

export const TranscribingStartStopButton: React.FC<Props> = ({
  className,
  status,
  startTime,
  receivedTime,
  size,
  disabled,
  hovered,
  startAction,
  resumeAction,
  stopAction,
}) => {
  return (
    <StartStopButton
      data-id="transcribing-btn"
      className={classNames('transcribing-start-stop-button', className)}
      status={status}
      icon={Icons.liveScrypt().size(SIZE.S).reactComponent()}
      stoppedLabel="Start transcribing"
      startedLabel="Transcribing"
      pausedLabel="Resume transcribing"
      loadingLabel="Starting..."
      stopLoadingLabel="Stop transcribing"
      stopLabel="Stop transcribing"
      startTime={startTime}
      receivedTime={receivedTime}
      size={size}
      disabled={disabled}
      hovered={hovered}
      startAction={startAction}
      resumeAction={resumeAction}
      stopAction={stopAction}
    />
  );
};
