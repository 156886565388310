import React, {useCallback, useState} from 'react';
import classNames from 'classnames';
import {Box} from '@mui/material';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Icons} from 'app/util/icons';
import {SelectionIndicator} from 'app/components/FleetManager/BatchActionsPanel/SelectionIndicator';
import {
  StreamBatchActionControl,
  RecordBatchActionControl,
  TranscribeBatchActionControl,
} from 'app/components/FleetManager/BatchActionsPanel/BatchActionControl';
import {BatchActionContextMenu} from 'app/components/FleetManager/BatchActionsPanel/BatchActionContextMenu';
import {front} from 'app/api/CirrusApi';
import {BatchActionFirmwareUpdate} from 'app/components/FleetManager/BatchActionsPanel/BatchActionFirmwareUpdate';
import {ApiResponseStatus} from 'app/data/Status';
import {AjaxComplexSuccessfulResponseType} from 'app/api/types';
import {useBatchMediaState} from 'app/components/FleetManager/BatchActionsPanel/useDevicesStats';
import {useCountSelected} from 'app/components/FleetManager/BatchActionsPanel/useDevicesCounter';
import {BatchActionDeviceGroup} from 'app/components/FleetManager/BatchActionsPanel/BatchActionDeviceGroup';
import {Callback, ClassName, Sx} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {TeamCapabilities} from 'app/models/Capabilities/TeamCapabilities';
import {noop} from 'app/util/noop';
import {useBatchFirmwareStates} from 'app/components/FleetManager/BatchActionsPanel/hooks/useBatchFirmwareStates';
import {Edge} from 'app/domain/edge';
import {UserRole} from 'app/models/PermissionsModel/types';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';

/**
 * @enum {string}
 */
const BATCH_TASK_NAME = {
  RECORDING: 'recording',
  STREAMING: 'streaming',
  TRANSCRIBING: 'transcribing',
};

/**
 * @enum {string}
 */
const BATCH_TASK_STATE = {
  START: 'start',
  STOP: 'stop',
};

/**
 * @param {AnyDeviceModelType[]} devices
 * @returns {Object}
 */
function createDevicesIdMap(devices) {
  const devicesIdMap = {};

  devices.forEach((device) => {
    devicesIdMap[device.getId()] = device;
  });

  return devicesIdMap;
}

function createBatchTask(devices, taskName, taskState, onActionDone) {
  return front
    .devices()
    .batch_task()
    .post({
      Devices: devices.map((device) => device.getId()),
      Task: {
        cmd: `${taskName as string}.${taskState as string}`,
      },
    })
    .then((response) => {
      if (taskName === BATCH_TASK_NAME.RECORDING && BATCH_TASK_STATE.START) {
        devices.forEach((device) => device.showStorageFullWarningNotification());
      }

      const warnings: any[] = [];

      const notEmptyWarnings = response.filter((warning) => Boolean(warning.Error));

      if (notEmptyWarnings.length > 0) {
        const devicesIdMap = createDevicesIdMap(devices);

        notEmptyWarnings.forEach((warning) => {
          warnings.push({
            deviceName: devicesIdMap[warning.Device].getName(),
            message: warning.Error,
          });
        });
      }

      onActionDone(warnings, taskName, taskState);
    });
}

async function createTranscribingAction(devices, taskState, onActionDone) {
  return Promise.all(
    devices.map((device) => {
      let command;

      if (taskState === BATCH_TASK_STATE.START) {
        command = device.startTranscribing();
      } else {
        command = device.stopTranscribing();
      }

      return command.then((response: AjaxComplexSuccessfulResponseType) => {
        if (response.Status === ApiResponseStatus.Ok) {
          return null;
        }

        return {
          deviceName: device.getName(),
          // TODO: Check response errors and write message
          message: 'Unknown error',
        };
      });
    }),
  ).then((warnings) => {
    const notEmptyWarnings = warnings.filter((warning) => warning !== null);
    return onActionDone(notEmptyWarnings, 'transcribing', taskState);
  });
}

interface Props extends ClassName, Sx {
  devices: AnyDeviceModelType[];
  deviceGroups: Edge.Group[];
  role: UserRole;
  groups: Edge.Group[];
  presets: Edge.TeamPreset[];
  onApplyPreset: (preset: Edge.TeamPreset, devices: string[]) => Promise<void>;
  onMoveToGroup: (groupId: string, devices: string[]) => Promise<void>;
  onRemoveFromGroup: (devices: AnyDeviceModelType[]) => Promise<void>;
  onStartProjects?: (projectIds: string[]) => Promise<void>;
  onStopProjects?: (projectIds: string[]) => Promise<void>;
  onDelete: (deviceIds: string[]) => Promise<void>;
  onDeleteGroups: (groupsIds: string[]) => Promise<void>;

  teamCapabilities: TeamCapabilities;
  isUnify?: boolean;
  groupsAllowed?: boolean;
  unpairAction?: Callback;
  deselectAll: Callback;
  onActionDone: Callback;
}

export function BatchActionsPanel({
  sx,
  className,
  role,
  devices,
  deviceGroups,
  groups,
  presets,
  onApplyPreset,
  onMoveToGroup,
  onRemoveFromGroup,
  onStartProjects,
  onStopProjects,
  onDelete,
  onDeleteGroups,
  // retype
  teamCapabilities,
  isUnify = false,
  groupsAllowed = false,
  unpairAction = noop,
  deselectAll,
  onActionDone,
}: Props) {
  const [warnings, setWarnings] = useState({
    streaming: [],
    recording: [],
    transcribing: [],
  });

  const stats = useBatchMediaState(devices);
  const counter = useCountSelected({devices});

  const {toUpdate, firmwareLoading, refetchFirmware} = useBatchFirmwareStates(isUnify, devices);

  const handleActionDone = useCallback(
    (response, taskName) => {
      setWarnings((warnings) => ({
        ...warnings,
        [taskName]: [...response],
      }));
      onActionDone();
    },
    [onActionDone],
  );

  const startStreamingAction = useCallback(async () => {
    setWarnings((warnings) => ({
      ...warnings,
      streaming: [],
    }));
    // rework any
    return Promise.all(stats.streaming.stopped.map((device: any) => device.startStreaming()));
  }, [stats]);

  const stopStreamingAction = useCallback(async () => {
    return Promise.all(stats.streaming.started.map((device: any) => device.stopStreaming()));
  }, [stats]);

  const startRecordingAction = useCallback(() => {
    setWarnings((warnings) => ({
      ...warnings,
      recording: [],
    }));
    return createBatchTask(
      stats.recording.stopped,
      BATCH_TASK_NAME.RECORDING,
      BATCH_TASK_STATE.START,
      handleActionDone,
    );
  }, [handleActionDone, stats.recording.stopped]);

  const stopRecordingAction = useCallback(() => {
    return createBatchTask(
      stats.recording.started,
      BATCH_TASK_NAME.RECORDING,
      BATCH_TASK_STATE.STOP,
      handleActionDone,
    );
  }, [handleActionDone, stats.recording.started]);

  const startTranscribingAction = useCallback(async () => {
    setWarnings((warnings) => ({
      ...warnings,
      transcribing: [],
    }));
    return createTranscribingAction(
      stats.transcribing.stopped,
      BATCH_TASK_STATE.START,
      handleActionDone,
    );
  }, [handleActionDone, stats.transcribing.stopped]);

  const stopTranscribingAction = useCallback(async () => {
    return createTranscribingAction(
      stats.transcribing.started,
      BATCH_TASK_STATE.STOP,
      handleActionDone,
    );
  }, [handleActionDone, stats.transcribing.started]);

  const handleCloseButtonClick = useCallback(() => {
    deselectAll();
  }, [deselectAll]);

  const handleMoveToGroup = async (groupId: string) => {
    const masterIds = devices
      .filter((d) => !ModelService.isChannel(d.getModelName()))
      .map((d) => d.getId());

    await onMoveToGroup(groupId, masterIds);
  };

  const handleRemoveGroup = async () => {
    await onDeleteGroups(deviceGroups.map((g) => g.id));
  };

  return (
    <Box
      sx={sx}
      className={classNames('batch-actions-panel', className)}
      data-id="batch_actions_panel"
    >
      <div className="batch-actions-panel__selection">
        <SelectionIndicator
          selectedDeviceCount={counter.devices}
          selectedChannelDevicesCount={counter.channels}
          selectedDeviceGroupCount={deviceGroups.length}
          deselectAll={deselectAll}
        />
      </div>

      <div className="batch-actions-panel__actions">
        <StreamBatchActionControl
          dataId="stream_batch_action_control"
          warnings={warnings.streaming}
          canStart={stats.streaming.stopped.length > 0 && stats.streaming.selectedDestinations}
          canStop={stats.streaming.started.length > 0}
          startAction={startStreamingAction}
          stopAction={stopStreamingAction}
        />

        <RecordBatchActionControl
          dataId="record_batch_action_control"
          warnings={warnings.recording}
          canStart={stats.recording.stopped.length > 0}
          canStop={stats.recording.started.length > 0}
          startAction={startRecordingAction}
          stopAction={stopRecordingAction}
        />

        {teamCapabilities.transcribing() && !isUnify && (
          <TranscribeBatchActionControl
            dataId="transcribe_batch_action_control"
            warnings={warnings.transcribing}
            canStart={stats.transcribing.stopped.length > 0}
            canStop={stats.transcribing.started.length > 0}
            startAction={startTranscribingAction}
            stopAction={stopTranscribingAction}
          />
        )}
      </div>

      <div className="batch-actions-panel__contextual-actions">
        <BatchActionFirmwareUpdate
          devices={toUpdate}
          loading={firmwareLoading}
          refetch={refetchFirmware}
        />

        {groupsAllowed && (
          <BatchActionDeviceGroup
            selectedDevices={devices}
            selectedGroups={deviceGroups}
            groups={groups}
            onMoveToGroup={handleMoveToGroup}
            onRemoveFromGroup={onRemoveFromGroup}
            onDelete={handleRemoveGroup}
          />
        )}

        <BatchActionContextMenu
          devices={devices}
          isUnify={isUnify}
          presets={presets}
          permitReboot={role.canRebootDevices()}
          permitPreset={role.canApplyPresets()}
          onApplyPreset={onApplyPreset}
          onStartProjects={onStartProjects}
          onStopProjects={onStopProjects}
          onDelete={onDelete}
          unpairAction={unpairAction}
        />
      </div>

      <div className="batch-actions-panel__close">
        <Button
          dataId="actions_panel_close_button"
          title="Reset selection"
          onClick={handleCloseButtonClick}
        >
          {Icons.close().reactComponent()}
        </Button>
      </div>
    </Box>
  );
}
