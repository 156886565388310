import {useCallback, useState} from 'react';
import {Callback} from 'app/types/common';

interface Return {
  search: string;
  setSearch: Callback<void, [string]>;
  clearSearch: Callback;
}

export function useSearch(initialValue = ''): Return {
  const [search, setSearch] = useState(initialValue);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  return {
    search,
    setSearch,
    clearSearch,
  };
}
