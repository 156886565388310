import {useEffect, useRef, useState} from 'react';
import {TimeStampMilliseconds, TimeStampSeconds} from 'app/types/common';
import {isNil} from 'app/util/isNil';
import moment from 'moment';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DeviceStatus} from 'app/store/dto/device/Device';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';

function useStatusProgress(timestamp?: TimeStampSeconds): number {
  const [progress, setProgress] = useState(0);
  const raf = useRef(0);

  useEffect(() => {
    const clean = () => {
      if (raf.current) {
        cancelAnimationFrame(raf.current);
        raf.current = 0;
      }
    };

    if (!timestamp) {
      setProgress(0);
      return clean;
    }

    const stepInSec = 3;
    let lastCallTimestamp: number | undefined;

    const handleStep = (callTimestamp: TimeStampMilliseconds) => {
      if (isNil(lastCallTimestamp) || callTimestamp - lastCallTimestamp < 1000) {
        const diff = moment().diff(moment.unix(timestamp), 'seconds');

        if (diff > stepInSec * 98) {
          setProgress(98);
          return;
        }

        setProgress(diff / stepInSec);
        raf.current = requestAnimationFrame(handleStep);
      }
    };

    raf.current = requestAnimationFrame(handleStep);

    return clean;
  }, [timestamp]);

  return progress;
}

interface Status {
  status: DeviceStatus;
  showProgress: boolean;
  progress: number;
}

function getUnifyUpdateTimestamp(device: AnyDeviceModelType): TimeStampSeconds | undefined {
  if (device instanceof PearlMasterDeviceModel) {
    const status = device.getStatus();

    if (status === 'starting') {
      return device.createdAt;
    }

    if (status === 'resuming') {
      return device.updatedAt;
    }
  }
}

export function useDeviceStatus(device: AnyDeviceModelType): Status {
  const status = device.getStatus();
  const isUnify = ModelService.isUnify(device.getModelName());
  const showProgress = isUnify && (status === 'resuming' || status === 'starting');
  const timestamp = showProgress ? getUnifyUpdateTimestamp(device) : undefined;

  const progress = useStatusProgress(timestamp);

  return {
    status: status,
    showProgress,
    progress,
  };
}
