import {useQuery} from '@tanstack/react-query';
import {AlertsApi, AlertsMapper} from 'app/components/entities/alerts';

export function useAlertGroupsQuery() {
  const query = useQuery({
    queryKey: ['alerts', 'groups'],
    queryFn: async () => {
      const res = await AlertsApi.getGroups();
      const mapped = res.map(AlertsMapper.mapGroup);
      return new Map(mapped.map((group) => [group.type, group]));
    },
  });
  return query;
}
