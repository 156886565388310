import React from 'react';
import {observer} from 'mobx-react';
import {Divider, Stack} from '@mui/material';
import {Meeting} from 'app/store/models/connect/Meeting/Meeting';
import {MeetingParticipantsCount} from 'app/components/sharedReactComponents/MeetingParticipantsCount/MeetingParticipantsCount';
import {MeetingUpTime} from 'app/components/pages/MeetingDetails/MeetingDetailsHeader/MeetingDetailsInfo/MeetingUpTime';
import {MeetingAudioMode} from 'app/components/pages/MeetingDetails/MeetingDetailsHeader/MeetingDetailsInfo/MeetingAudioMode';
import {useSrtCount} from 'app/hooks/useSrtCount';
import {MeetingSrtSummary} from 'app/components/sharedReactComponents/MeetingSrtSummary/MeetingSrtSummary';
import {CopyUrl, JoinMeeting} from 'app/components/MeetingsManager/buttons';
import {Icons} from 'app/util/icons';
import {SxObj} from 'app/types/common';

interface Props {
  meeting: Meeting;
}

const buttonSx: SxObj = {
  fontSize: '12px',
  '& button': {p: 0},
};

export const MeetingDetailsInfo = observer(({meeting}: Props) => {
  const streams = useSrtCount(meeting.participants);
  const rf = useSrtCount(meeting.feed ? [meeting.feed] : []);

  const hasActiveSrt = Object.values(streams).some((v) => v.value > 0);
  const hasActiveRf = Object.values(rf).some((v) => v.value > 0);

  return (
    <div className="meeting-details-info">
      <Stack
        direction="row"
        gap={1.5}
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem={true} />}
      >
        <Stack>
          {(meeting.platform === 'teams' ? Icons.microsoftTeams() : Icons.zoom()).reactComponent()}
        </Stack>

        {meeting.startedAt && <MeetingUpTime timestamp={meeting.startedAt} />}

        <MeetingParticipantsCount count={meeting.guests.length} />
        <MeetingAudioMode audioIsolated={meeting.isolatedAudio} />

        <CopyUrl sx={buttonSx} url={meeting.url} />
        <JoinMeeting sx={buttonSx} url={meeting.url} />

        {hasActiveSrt && (
          <MeetingSrtSummary dataId="mainstreams_summary" label="SRT Stream" count={streams} />
        )}

        {hasActiveRf && <MeetingSrtSummary dataId="rf_summary" label="Return Feed" count={rf} />}
      </Stack>
    </div>
  );
});
