import React from 'react';
import classNames from 'classnames';
import {StreamingDestination} from 'app/components/Settings/StreamingDestinations/StreamingDestinationsPanel/StreamingDestination';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  streamingDestinations: any[];
  editable: boolean;
  onDelete: Callback;
}

export const StreamingDestinationsPanel: React.FC<Props> = ({
  className,
  streamingDestinations,
  editable,
  onDelete,
}) => {
  return (
    <div className={classNames('streaming-destinations-panel', className)}>
      <div className="streaming-destinations-panel__title">
        Streaming destinations
      </div>

      <div className="streaming-destinations-panel__list">
        {streamingDestinations.map(streamingDestination => (
          <StreamingDestination
            key={streamingDestination.getId()}
            streamingDestination={streamingDestination}
            editable={editable}
            onDelete={onDelete}
          />
        ))}
      </div>
    </div>
  );
};
