import React from 'react';
import {ModalPrompt} from 'app/components/sharedReactComponents/Modal/ModalPrompt';
import {Modal} from 'app/components/sharedReactComponents/Modal/Modal';
import {pluralize} from 'app/components/helpers/commonHelpers';
import {noop} from 'app/util/noop';
import {ItemsEnumeration} from 'app/components/sharedReactComponents/ItemsEnumeration';
import {SIZE, THEME} from 'app/constants';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Callback} from 'app/types/common';

type PromptShowProps = {
  devices: AnyDeviceModelType[];
  onDone?: Callback;
  onClose?: Callback;
  onConfirm?: Callback;
};

export const UpdateDeviceFirmwarePrompt = {
  show({
    devices,
    onDone = noop,
    onConfirm = noop,
    onClose,
  }: PromptShowProps) {
    ModalPrompt.show({
      className: 'update-device-firmware-prompt',
      fullWidth: true,
      maxWidth: SIZE.L,
      title: (
        <>
          {'Update your '}

          <ItemsEnumeration
            items={devices}
            theme={THEME.INFO}
          />

          {` ${pluralize('device', devices)} firmware?`}
        </>
      ),
      body: (
        <>
          During update process, your {pluralize('device', devices)} will be offline.
          <br/>
          Your {pluralize('device', devices)} will reboot automatically after the firmware has been downloaded.
        </>
      ),
      confirmButtonLabel: 'Yes, update',
      onDone: async () => {
        onConfirm();

        return Promise.all(
          devices.map(async (device) => device.sendUpdateFirmwareCommand())
        )
          .then(() => onDone())
          .finally(() => Modal.hide());
      },
      onClose,
    });
  }
};
