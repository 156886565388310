import {SxProps, Theme} from '@mui/material';

export const buttonSx: SxProps<Theme> = {
  fontSize: 14,
  minWidth: 130,
  color: 'white',
  borderColor: 'white',

  '&:hover, &:focus': {
    bgcolor: 'white',
    color: 'black',
    borderColor: 'white',
    outline: 'none',
    textDecoration: 'none',
  },
};
