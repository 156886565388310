import React from 'react';
import {Box, DialogTitle, Stack, Typography} from '@mui/material';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {SIZE} from 'app/constants';
import {Component} from 'app/types/common';
import {Icons} from 'app/util/icons';
import {palette} from 'app/themes/app';

interface Props extends Component {
  paired: number;
}

export const CloudState = ({paired}: Props) => {
  return (
    <Stack sx={{minWidth: '340px', flexGrow: 1}} justifyContent="space-between">
      <DialogTitle sx={{p: 0, fontWeight: 600, textAlign: 'left'}}>
        Choose your Edge plan
      </DialogTitle>

      <Box>
        Currently you have
        <Stack mt={2} gap={2}>
          <Count icon={Icons.deviceCircuit().size(SIZE.L).reactComponent()}>
            <span data-id="device-counts">{paired}</span> paired {pluralizeN('device', paired)}
          </Count>
        </Stack>
      </Box>

      <Typography fontWeight={600} fontSize="16px">
        Features
      </Typography>
    </Stack>
  );
};

interface CountProps extends React.PropsWithChildren<Component> {
  icon: React.ReactNode;
}

function Count({dataId, icon, children}: CountProps) {
  return (
    <Stack data-id={dataId} direction="row" alignItems="center" gap={1}>
      <Box color={palette.grayMedium}>{icon}</Box>

      <Typography component="span" variant="h6">
        {children}
      </Typography>
    </Stack>
  );
}
