import {useEffect, useRef, useState} from 'react';
import {CustomerStorage} from 'app/util/LocalStorage/CustomerStorage';

interface Args<V extends any = string> {
  teamId: string;
  userId: string;
  defaultValue: V;
  key: string;
}

export function useStorageValue<V extends any = string>({
  defaultValue,
  key,
  teamId,
  userId,
}: Args<V>) {
  const [value, setValue] = useState<V>(defaultValue);

  const storage = useRef<CustomerStorage<V> | undefined>(undefined);

  useEffect(() => {
    if (key && teamId && userId) {
      storage.current = new CustomerStorage<V>({
        defaultValue,
        key,
        teamId,
        userId,
      });

      const saved = storage.current.getValue();
      setValue(saved);
    }
  }, [key, teamId, userId, defaultValue]);

  useEffect(() => {
    if (storage.current) {
      storage.current.setValue(value);
    }
  }, [value]);

  return [value, setValue] as const;
}
