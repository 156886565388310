import {MutableRefObject, useCallback, useEffect, useRef} from 'react';
/**
 * @deprecated replace to useMounted
 * @returns
 */
export function useIsMounted(): MutableRefObject<boolean> {
  const mountedRef = useRef<boolean>(false);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return mountedRef;
}

export function useMounted() {
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}
