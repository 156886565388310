import React from 'react';
import {Box, Stack} from '@mui/material';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {Sx} from 'app/types/common';
import {isAlertDisabled} from 'app/components/Settings/Alerts/AlertSettings/utils';
import {AlertName} from 'app/components/Settings/Alerts/AlertSettings/AlertTable/AlertTableHeader/AlertName';
import {Alerts} from 'app/components/entities/alerts';

interface Props extends Sx {
  settings: Alerts.Settings[];
  types: Alerts.AlertType[];
  getAlertGroup: (type: Alerts.AlertType) => Alerts.Group | undefined;
  onHoverAlert: (type?: Alerts.AlertType) => void;
  onToggleAlert: (type: Alerts.AlertType, value: boolean) => void;
}

export function AlertTableHeader({
  sx,
  settings,
  types,
  onToggleAlert,
  onHoverAlert,
  getAlertGroup,
}: Props) {
  return (
    <Stack sx={sx} direction="row" alignItems="center">
      <Box sx={{flex: 1}}>
        <OverflowTooltip rowsNumber={2}>Devices</OverflowTooltip>
      </Box>

      <Stack direction="row">
        {types.map((type) => {
          const hasUnchecked = settings.some(
            (setting) => !setting.alerts[type] && !isAlertDisabled(setting, type),
          );

          const alert = getAlertGroup(type);

          if (alert) {
            return (
              <AlertName
                key={type}
                alertGroup={alert}
                hasUnchecked={hasUnchecked}
                onHover={onHoverAlert}
                onToggle={onToggleAlert}
              />
            );
          }
          return null;
        })}
      </Stack>
    </Stack>
  );
}
