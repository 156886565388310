import {BaseCapabilities} from 'app/models/Capabilities/BaseCapabilities';
import {DeviceCapability} from 'app/components/DeviceDetails/constants';

class DeviceCapabilities extends BaseCapabilities {
  supportsChannelPreviews() {
    return this.check(DeviceCapability.ChannelPreviews);
  }

  supportsSourcePreviews() {
    return this.check(DeviceCapability.SourcePreviews);
  }

  supportsWsApi() {
    return this.check(DeviceCapability.WsApi);
  }
}

export {DeviceCapabilities};
