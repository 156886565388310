import React, {useState} from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {FormControl, MenuItem, Select, SelectChangeEvent, TextField} from '@mui/material';
import {ClassName, SelectOption} from 'app/types/common';
import {StreamSetting} from 'app/components/pages/MeetingDetails/StreamSettings/common/StreamSetting/StreamSetting';
import {useStrSettingsForm} from 'app/components/pages/MeetingDetails/StreamSettings/SrtSettingsFormContext';
import {SrtSettingsCallback} from 'app/components/pages/MeetingDetails/StreamSettings/types';
import {getValidNumber} from 'app/components/pages/MeetingDetails/StreamSettings/utils';
import {FormControl as Checkbox} from 'app/components/sharedReactComponents/FormControl';
import {FORM_CONTROL_TYPE} from 'app/components/sharedReactComponents/FormControl/constants';
import {SIZE} from 'app/constants';
import {isNil} from 'app/util/isNil';

const Sizes: Array<SelectOption<App.Connect.EncryptionSize>> = [
  {label: 'AES-128', value: 16},
  {label: 'AES-192', value: 24},
  {label: 'AES-256', value: 32},
];

interface Props extends ClassName, SrtSettingsCallback {
  encryption: boolean;
}

const StreamAdvancedSettingsComponent = ({className, encryption, onSync}: Props) => {
  const {settings, errors, setSettings} = useStrSettingsForm();

  const [pwd, setPwd] = useState(settings.key);
  const [size, setSize] = useState<App.Connect.EncryptionSize>(settings.size ? settings.size : 16);
  const [encrypted, setEncrypted] = useState(settings.size !== 0);

  const handleLatencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const latency = e.target.value.replace(/[^0-9]/g, '');
    const insert = getValidNumber(latency, 8000, settings.latency);
    setSettings({latency: insert});
  };

  const handleSizeChange = async (e: SelectChangeEvent<App.Connect.EncryptionSize>) => {
    const size = e.target.value as App.Connect.EncryptionSize;
    setSettings({size});
    setSize(size);
    await onSync();
  };

  const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.value;
    setSettings({key});
    setPwd(key);
  };

  const toggleEncryption = async () => {
    const newState = !encrypted;
    const newSize = newState ? size : 0;
    const newkey = newState ? pwd : '';

    setEncrypted(newState);
    setSettings({
      key: newkey,
      size: newSize,
    });
    await onSync();
  };

  return (
    <div className={classNames('stream-advanced-settings', className)}>
      <StreamSetting className="stream-advanced-settings__field" label="Latency">
        <TextField
          data-id="srt_latency"
          className="stream-advanced-settings__latency"
          fullWidth={false}
          placeholder="Milliseconds"
          value={settings.latency ? settings.latency.toString() : ''}
          error={!isNil(errors?.Latency)}
          helperText={errors?.Latency}
          onChange={handleLatencyChange}
          onBlur={onSync}
        />
      </StreamSetting>

      {encryption && (
        <>
          <Checkbox
            dataId="srt_encryption"
            className="stream-advanced-settings__field"
            type={FORM_CONTROL_TYPE.CHECKBOX}
            value={encrypted}
            label="Encryption"
            size={SIZE.S}
            onChange={toggleEncryption}
          />

          {encrypted && (
            <>
              <StreamSetting className="stream-advanced-settings__field" label="Passphrase">
                <TextField
                  data-id="srt_encryption_key"
                  type="password"
                  placeholder="Passphrase"
                  fullWidth={true}
                  error={!isNil(errors?.Passphrase)}
                  helperText={errors?.Passphrase}
                  value={settings.key}
                  onChange={handleKeyChange}
                  onBlur={onSync}
                />
              </StreamSetting>

              <StreamSetting className="stream-advanced-settings__field" label="Key length">
                <FormControl fullWidth={true}>
                  <Select
                    data-id="srt_encryption_len"
                    value={settings.size}
                    onChange={handleSizeChange}
                  >
                    {Sizes.map((s) => (
                      <MenuItem key={s.value} value={s.value}>
                        {s.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </StreamSetting>
            </>
          )}
        </>
      )}
    </div>
  );
};

export const StreamAdvancedSettings = observer(StreamAdvancedSettingsComponent);
