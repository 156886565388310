import React from 'react';
import {Callback} from 'app/types/common';
import {ModalPrompt} from 'app/components/sharedReactComponents/Modal/ModalPrompt';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {SIZE, THEME} from 'app/constants';
import {ModalTitleHighlightedItem} from 'app/components/sharedReactComponents/Modal/Modal';

interface Args {
  name: string;
  onDone?: Callback;
  onClose?: Callback;
}

export const DisconnectMeetingPrompt = {
  show({
    name,
    onClose,
    onDone,
  }: Args) {
    ModalPrompt.show({
      className: 'disconnect-meeting-prompt',
      fullWidth: true,
      maxWidth: SIZE.S,
      title: (
        <>
          Disconnect from&nbsp;
          <ModalTitleHighlightedItem>
            <OverflowTooltip component="span">{name}</OverflowTooltip>
          </ModalTitleHighlightedItem>
          &nbsp;meeting?
        </>
      ),
      body: (
        <div className="disconnect-meeting-prompt__message">
          All active SRT connections will be stopped.<br/>
          <br/>
          Reconnection to the meeting will take the same amount of<br/>
          time as the initial connection did (up to several minutes).
        </div>
      ),
      confirmButtonLabel: 'Yes, disconnect',
      confirmButtonTheme: THEME.DANGER,
      onClose,
      onDone: async () => {
        return onDone?.();
      },
    });
  }
};
