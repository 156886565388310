import React, {useMemo, useState} from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {BatchActionButton} from 'app/components/FleetManager/BatchActionsPanel/BatchActionButton';
import {isBatchActionPanelNarrow} from 'app/components/FleetManager/BatchActionsPanel/utils';
import {ClassName} from 'app/types/common';
import {LoadingWrapper} from 'app/components/sharedReactComponents/LoadingWrapper';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {BatchFirmwareDialog} from 'app/components/FleetManager/BatchActionsPanel/dialogs/BatchFirmwareDialog/BatchFirmwareDialog';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';

interface Props extends ClassName {
  devices: AnyDeviceModelType[];
  loading: boolean;
  refetch: Fn<Promise<any>>;
}

export const BatchActionFirmwareUpdate = ({className, devices, loading, refetch}: Props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async () => {
    const ids = devices.map((d) => d.getId());

    await DeviceApiService.batchTask({
      Devices: ids,
      Task: {
        cmd: 'firmware.update',
      },
    });
    await refetch();
    handleClose();
  };

  const names = useMemo(() => devices.map((d) => d.getName()), [devices]);

  if (devices.length === 0) {
    return null;
  }

  return (
    <LoadingWrapper loading={loading}>
      <BatchFirmwareDialog
        open={open}
        names={names}
        onUpdate={handleUpdate}
        onClose={handleClose}
      />
      <BatchActionButton
        className={classNames('batch-action-firmware-update', className)}
        data-id="batch_action_firmware_update"
        title={isBatchActionPanelNarrow() && 'Update Firmware'}
        onClick={handleOpen}
      >
        {Icons.updateFirmware()
          .label(`Update Firmware (${devices.length})`)
          .size(SIZE.S)
          .reactComponent()}
      </BatchActionButton>
    </LoadingWrapper>
  );
};
