import React, {useCallback} from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {Stack, Typography} from '@mui/material';
import {ChangeEvent, ClassName} from 'app/types/common';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Meeting} from 'app/store/models/connect/Meeting/Meeting';
import {THEME, VARIANT} from 'app/constants';
import {DisconnectMeetingPrompt} from 'app/components/MeetingsManager/modals/DisconnectMeetingPrompt/DisconnectMeetingPrompt';
import {MeetingDetailsInfo} from 'app/components/pages/MeetingDetails/MeetingDetailsHeader/MeetingDetailsInfo';
import {Notifications} from 'app/components/Notifications';
import {EditableNameText} from 'app/components/sharedReactComponents/EditableNameText';
import {emptyStringValidator} from 'app/util/validators';
import {MeetingsApiService} from 'app/services/api/meetings/MeetingsApiService';
import {palette} from 'app/themes/app';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

interface Props extends ClassName {
  meeting: Meeting;
}

const MeetingDetailsHeaderComponent = ({className, meeting}: Props) => {
  const handleMeetingDisconnect = useCallback(() => {
    const onDisconnect = async () => {
      try {
        await MeetingsApiService.disconnect(meeting.id);
      } catch {
        Notifications.addErrorNotification('Could not disconnect meeting');
      }
    };

    DisconnectMeetingPrompt.show({
      name: meeting.name,
      onDone: onDisconnect,
    });
  }, [meeting]);

  const handleSaveName = async ({target: {value}}: ChangeEvent<string>) => {
    await MeetingsApiService.setName(meeting.id, value);
    meeting.setName(value);
  };

  const state = usePermissionText(meeting.permission);

  return (
    <div className={classNames('meeting-details-header', className)}>
      <div className="container meeting-details-header__container">
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <EditableNameText
            dataId="edit_meeting_name"
            className="meeting-details-header__name"
            value={meeting.name}
            ariaLabel="Meeting name"
            validators={[emptyStringValidator]}
            onChange={handleSaveName}
          />

          <Stack direction="row" alignItems="center" gap={3}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Stack direction="row" alignItems="center" gap={1}>
                <CheckCircleIcon sx={{color: palette.green}} />
                <span>Connected</span>
              </Stack>

              {state && (
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  sx={state.serious ? {color: palette.red} : {}}
                >
                  {state.serious ? (
                    <WarningIcon sx={{color: palette.red}} />
                  ) : (
                    <ErrorIcon sx={{color: palette.orange3}} />
                  )}
                  <Typography
                    data-id="permission-state"
                    component="span"
                    sx={{whiteSpace: 'nowrap'}}
                  >
                    {state.text}
                  </Typography>
                </Stack>
              )}
            </Stack>

            <Button
              data-id="disconnect_meeting_button"
              theme={THEME.INFO}
              variant={VARIANT.OUTLINE}
              onClick={handleMeetingDisconnect}
            >
              Disconnect
            </Button>
          </Stack>
        </Stack>

        <MeetingDetailsInfo meeting={meeting} />
      </div>
    </div>
  );
};

export const MeetingDetailsHeader = observer(MeetingDetailsHeaderComponent);

interface Permissions {
  text: string;
  serious: boolean;
}

function usePermissionText(permission?: App.Connect.Permission): Permissions | undefined {
  if (!permission) {
    return;
  }

  switch (permission) {
    case 'streaming-request-denied':
    case 'recording-request-denied': {
      return {
        text: 'Denied: permission request ',
        serious: true,
      };
    }

    case 'requesting-streaming':
    case 'streaming-request-timeout': {
      return {
        text: 'Requesting live streaming permission',
        serious: false,
      };
    }

    case 'requesting-recording':
    case 'recording-request-timeout': {
      return {
        text: 'Requesting recording permission',
        serious: false,
      };
    }

    case 'waiting-recording': {
      return {
        text: 'Requesting manual permission',
        serious: false,
      };
    }

    case 'failed':
    default:
  }
}
