import {useLocalStorage} from 'react-use';
import {useMaintenanceQuery} from 'app/components/entities/maintenance';
import {isNil} from 'app/util/isNil';

export function useMaintenance() {
  const {data} = useMaintenanceQuery();
  const [stored, setStored, remove] = useLocalStorage('Maintenance.Minimized', '');

  return {
    instance: data ?? undefined,
    stored,
    minimized: !isNil(data) ? data?.id === stored : false,
    setStored,
    expand: remove,
  };
}
