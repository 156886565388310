import React from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {ClassName, DataId} from 'app/types/common';
import {SIZE} from 'app/constants';

export const DisconnectedLabel = ({className, dataId}: ClassName & DataId) => {
  return (
    <div className={classNames('disconnected-label', className)} data-id={dataId}>
      {Icons.disconnectedSource()
        .size(SIZE.S)
        .class('disconnected-label')
        .label('Disconnected')
        .reactComponent()}
    </div>
  );
};
