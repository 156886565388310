import {DiscountCoupon, IbbCoupon} from 'app/components/BillingManager/Coupon';
import {EdgePlan} from 'app/models/EdgePlan/EdgePlan';
import {Payment} from 'app/models/Payment';
import {isNil} from 'app/util/isNil';

function mapCoupon(contract: Contracts.Billing.CouponValidation) {
  const {percent_off, is_econnect, amount, duration, is_ibb} = contract;
  const discount: App.Billing.DiscountType = isNil(percent_off) ? 'amount' : 'percent';
  const value = discount === 'percent' ? percent_off ?? 0 : amount ?? 0;

  if (is_ibb) {
    return new IbbCoupon(value);
  }

  return new DiscountCoupon({
    value,
    discount,
    domain: is_econnect ? 'connect' : 'cloud',
    duration,
  });
}

function mapMethod(method: Contracts.Billing.PaymentMethod): App.Billing.PaymentMethod {
  if (method === 'credit_card') {
    return 'credit card';
  }

  if (method === 'invoice_pending') {
    return 'pending invoice';
  }

  return 'invoice';
}

function mapCreditCard(card: Contracts.Billing.CreditCard): App.Billing.CreditCard {
  const {brand, exp_month, exp_year, last4} = card;
  return {
    brand,
    last4,
    expMonth: exp_month,
    expYear: exp_year,
  };
}

function mapPayment(contract: Contracts.Billing.Payment): Payment {
  const card = contract.payment_info?.card ? mapCreditCard(contract.payment_info?.card) : undefined;
  const paymentMethod = contract.payment_info?.payment_type
    ? mapMethod(contract.payment_info.payment_type)
    : undefined;

  return new Payment({
    customer: isNil(contract.customer) ? undefined : {balance: contract.customer.balance},
    paymentMethod,
    card,
  });
}

function mapCloudTier(c: Contracts.Billing.Cloud.Tier): App.Billing.Cloud.Tier {
  return {
    id: c.id,
    name: c.name,
    rate: c.rate,
    description: c.description,
    seats: c.max_devices,
    isAvailable: c.is_available,
    isDefault: c.is_default,
    isDeleted: c.is_deleted,
  };
}

const defaultTier: App.Billing.Cloud.Tier = {
  id: '',
  name: 'Free',
  description: '',
  isAvailable: true,
  isDefault: true,
  isDeleted: false,
  seats: -1,
  rate: 0,
};

function mapEdgePlan(c: Contracts.Billing.Cloud.Plan): EdgePlan {
  const {id, name, trial} = c;

  const tiers = c.tiers.map(mapCloudTier);
  const tier = tiers.find((t) => t.id === c.current_tier) ?? {...defaultTier};

  return new EdgePlan({
    name,
    id,
    tier,
    tiers,
    prices: {
      seat: c.seat_month_price,
      transcriber: c.transcribing_cost_hour,
      unify: c.vpearl_cost_hour,
    },
    interval: {
      dimension: c.interval,
      count: c.interval_count,
    },
    period: {
      start: c.current_period_start,
      end: c.current_period_end,
    },
    trial: trial ? {active: trial.is_active, from: trial.from, to: trial.to} : undefined,
  });
}

function mapConnectTier(contract: Contracts.Billing.Connect.Tier): App.Billing.Connect.Tier {
  return {
    id: contract.id,
    name: contract.name,
    subscriptionFee: contract.subscription_fee_usd ?? 0,
    hourlyRate: contract.hourly_rate ?? 0,
    interval: {
      count: contract.billing_period.count,
      dimension: contract.billing_period.interval,
    },
    custom: contract.is_custom,
    limit: contract.limitations.max_participants,
  };
}

function mapConnectPlan(contract: Contracts.Billing.Connect.Plan): App.Billing.Connect.Plan {
  const {is_custom} = contract.price_info;

  return {
    id: contract.price,
    enterprise: is_custom,
    period: {
      start: contract.billing_period.begin,
      end: contract.billing_period.end,
    },
    tier: mapConnectTier(contract.price_info),
    resumable: contract.resumable ?? false,
    expireAt: contract.active_until,
  };
}

function mapUnifyTier(c: Contracts.Billing.Unify.Tier): App.Billing.Unify.Tier {
  const {id, is_bundle, is_custom, name, subscription_fee_usd, hourly_rate, billing_period} = c;

  return {
    id,
    name,
    bundle: is_bundle,
    custom: is_custom,
    subscriptionFee: subscription_fee_usd ?? 0,
    hourlyRate: hourly_rate ?? 0,
    interval: {
      count: billing_period.count,
      dimension: billing_period.interval,
    },
  };
}

function mapUnifyPlan(c: Contracts.Billing.Unify.Plan): App.Billing.Unify.Plan {
  const {price, price_info, active_until, billing_period} = c;
  const tier = mapUnifyTier(price_info);

  return {
    id: price,
    tier,
    enterprise: tier.custom,
    period: {
      start: billing_period.begin,
      end: billing_period.end,
    },
    expireAt: active_until,
  };
}

function mapCloudInvoice(c: Contracts.Billing.Cloud.Invoice): App.Billing.Cloud.Invoice {
  return {
    date: c.issuance_at,
    total: c.total_period_amount_usd,
    cloud: {
      fee: c.tier_usage_total_cost_usd,
    },
    lvs: {
      usage: c.transcriber_total_cost_usd,
      billedHours: c.transcriber_billable_hours,
      hours: c.transcriber_hours,
    },
    unify: {
      usage: c.unify_total_cost_usd,
    },
  };
}

function mapCloudHistoryInvoice(
  c: Contracts.Billing.Cloud.HistoryInvoice,
): App.Billing.Cloud.HistoryInvoice {
  return {
    id: c.invoice_id,
    date: c.issued_at,
    available: c.available_for_download,
    number: c.invoice_number,
    cloud: c.tier_usage_paid_usd,
    unify: c.unify_paid_usd,
    lvs: c.transcriber_paid_usd,
    total: c.total_amount_usd,
  };
}

function mapDiscount(contract: Contracts.Billing.Discount): App.Billing.Connect.Discount {
  const {amount_usd, coupon} = contract;

  return {
    amount: amount_usd,
    coupon: mapInvoiceCoupon(coupon),
  };
}

function mapConnectInvoice(c: Contracts.Billing.Connect.Invoice): App.Billing.Connect.Invoice {
  return {
    date: c.issuance_at,
    hours: c.meeting_hours,
    subscription: c.subscription_fee_usd,
    usage: c.usage_fee_usd,
    total: c.total_period_amount_usd,
    discounts: c.discounts?.map(mapDiscount) ?? [],
  };
}

function mapConnectHistoryInvoice(
  c: Contracts.Billing.Connect.HistoryInvoice,
): App.Billing.Connect.HistoryInvoice {
  return {
    id: c.invoice_id,
    number: c.invoice_number,
    available: c.available_for_download,
    date: c.issued_at,
    subscriptionFee: c.subscription_fee_usd,
    usageFee: c.usage_fee_usd,
    usedHours: c.meeting_hours,
    total: c.total_amount_usd,
  };
}

function mapInvoiceCoupon(contract: Contracts.Billing.InvoiceCoupon): App.Billing.Coupon {
  const {id, amount_off_usd, percent_off} = contract;
  switch (contract.duration) {
    case 'repeating': {
      return {
        id,
        amountOff: amount_off_usd,
        percentOff: percent_off,
        duration: contract.duration,
        month: contract.duration_in_month,
      };
    }

    default:
      return {
        id,
        amountOff: amount_off_usd,
        percentOff: percent_off,
        duration: contract.duration,
      };
  }
}

function mapConnectReport(c?: Contracts.Billing.Connect.Report): App.Billing.Connect.Report {
  if (isNil(c)) {
    return {history: []};
  }

  return {
    invoice: c.upcoming_info ? mapConnectInvoice(c.upcoming_info) : undefined,
    history: c.history.map(mapConnectHistoryInvoice),
  };
}

function mapCloudAndUnifyReport(c: Contracts.Billing.Cloud.Report): App.Billing.Cloud.Report {
  return {
    invoice: c.upcoming_info ? mapCloudInvoice(c.upcoming_info) : undefined,
    history: c.history.map(mapCloudHistoryInvoice),
  };
}

export const BillingMapper = {
  mapCoupon,
  mapPayment,
  mapEdgePlan,
  mapConnectPlan,
  mapConnectTier,
  mapUnifyTier,
  mapUnifyPlan,
  mapCloudAndUnifyReport,
  mapConnectReport,
};
