import {RefObject, useEffect, useRef, useState} from 'react';
import {ScrollToAnchorController} from 'app/components/sharedReactComponents/ScrollToAnchor/ScrollToAnchorController';

interface Params {
  ref: RefObject<HTMLElement>;
  name: string;
  disabled?: boolean;
}

export function useScrollToAnchor({
  ref,
  name,
  disabled,
}: Params) {
  const doneRef = useRef(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (disabled || doneRef.current || !ref.current) {
      return;
    }

    if (ScrollToAnchorController.check(name)) {
      setActive(true);
    }

    doneRef.current = true;
    ScrollToAnchorController.mountElement(name, ref.current);
  }, [disabled, name, ref]);

  return active;
}
