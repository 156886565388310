import React from 'react';
import {Virtuoso} from 'react-virtuoso';
import {Button, Slide, Stack, TextField, Typography} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {grey} from '@mui/material/colors';
import {Sx} from 'app/types/common';
import {downloadFile} from 'app/util/download';
import {
  Files,
  FilesApi,
  ListHeader,
  NoFiltered,
  NoRecordings,
  RecordingsSkeleton,
  SortSelector,
} from 'app/components/entities/files';
import {
  BatchRecordings,
  EdgeAfuSwitcher,
  RecordingCard,
  TotalAndSize,
  useFilterAndSortFiles,
  useSelectRecordings,
} from 'app/components/features/files';
import {useUnitAfu} from 'app/components/entities/afu';
import {Notifications} from 'app/components/Notifications';

type Props = Sx & {
  deviceId: string;
  online: boolean;
  loading: boolean;
  recordings: Files.Recording[];
};

export function UnitFiles({sx, deviceId, online, loading, recordings}: Props) {
  const {filtered, search, sort, setSearch, setSort} = useFilterAndSortFiles({recordings});

  const {selected, selectable, allSelected, deselectAll, selectAll, isSelected, toggle} =
    useSelectRecordings(filtered);

  const {data: afu} = useUnitAfu({deviceId, enabled: online, refetchInterval: 1000 * 60});

  const handleSelectAll = () => {
    if (allSelected) {
      deselectAll();
    } else {
      selectAll();
    }
  };

  const handleClear = () => {
    deselectAll();
    setSearch('');
  };

  const totalSelected = selected.length;

  const hasRecordings = recordings.length > 0;
  const hasFiltered = filtered.length > 0;
  const hasSelectable = selectable.length > 0;
  const hasSelected = totalSelected > 0;

  const handleDelete = async (id: string) => {
    try {
      await FilesApi.deleteRecording(id);
    } catch (e) {
      Notifications.addErrorNotification('Failed to delete recording');
      throw e;
    }
  };

  const handleDownload = async (id: string, name: string) => {
    const url = FilesApi.getDownloadUrl(id);
    downloadFile(url, name);
  };

  const renderList = () => {
    if (loading) {
      return <RecordingsSkeleton sx={{height: 300, pt: 2}} />;
    }

    if (!hasRecordings) {
      return <NoRecordings sx={{flex: 1}} />;
    }

    if (!hasFiltered) {
      return <NoFiltered sx={{flex: 1}} onClear={handleClear} />;
    }

    return (
      <>
        <ListHeader sx={{mb: 0.5}} showUnit={false} />

        <Virtuoso
          data={filtered}
          useWindowScroll={true}
          itemContent={(_index, r) => {
            const {id, name} = r;
            return (
              <RecordingCard
                key={id}
                dataId={id}
                selected={isSelected(id)}
                recording={r}
                showUnit={false}
                onSelect={() => toggle(id)}
                onDelete={() => handleDelete(id)}
                onDownload={() => handleDownload(id, name)}
              />
            );
          }}
        />
      </>
    );
  };

  return (
    <Stack sx={sx}>
      <Typography mb={1} fontWeight={600} variant="h5">
        Recordings
      </Typography>

      {afu && <EdgeAfuSwitcher sx={{mb: 1}} deviceId={deviceId} afu={afu} />}

      {hasRecordings && (
        <Stack mb={1} alignItems="center" direction="row" gap={1} borderColor={grey[200]}>
          <Button
            data-id="select-all-btn"
            sx={{minWidth: 106}}
            variant="outlined"
            color="info"
            disabled={!hasSelectable}
            startIcon={
              allSelected ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />
            }
            onClick={handleSelectAll}
          >
            {allSelected ? 'Deselect' : 'Select all'}
          </Button>

          <TotalAndSize dataId="files-summary" files={recordings} />

          <TextField
            inputProps={{'data-id': 'search-input'}}
            sx={{ml: 'auto', width: 200}}
            value={search}
            InputProps={{startAdornment: <SearchIcon />, placeholder: 'Search'}}
            onChange={(event) => {
              setSearch(event.target.value);
              deselectAll();
            }}
          />

          <SortSelector sx={{minWidth: 148}} sort={sort} setSort={setSort} />
        </Stack>
      )}

      {renderList()}

      <Slide in={hasSelected} direction="up" mountOnEnter={true} unmountOnExit={true}>
        <BatchRecordings recordings={selected} onClose={deselectAll} />
      </Slide>
    </Stack>
  );
}
