import React, {useCallback, useEffect, useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {Box, Checkbox, FormControlLabel, Stack, Typography} from '@mui/material';
import WifiIcon from '@mui/icons-material/Wifi';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {grey} from '@mui/material/colors';
import {Sx} from 'app/types/common';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {
  ChannelAction,
  ChannelFields,
  Destination,
  DestinationType,
} from 'app/components/sharedReactComponents/Events/Editor/EventForm/types';
import {
  DestinationPicker,
  SelectArgs,
} from 'app/components/sharedReactComponents/Events/Editor/EventForm/ChannelForm/DestinationPicker/DestinationPicker';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {stringComparator} from 'app/util/Sort';

interface Props extends Sx {
  channel: PearlSlotDeviceModel;
  online: boolean;
  state: ChannelFields;
  destinations: Destination[];
  setMediaAction: (channelId: string, type: ChannelAction, value: boolean) => void;
  selectDestination: (channelId: string, type: DestinationType, value: string) => void;
}

export function ChannelForm({
  sx,
  channel,
  online,
  state,
  destinations,
  selectDestination,
  setMediaAction,
}: Props) {
  const id = channel.getId();
  const idx = channel.getChannelDeviceIdIndex();
  const name = channel.getName();
  const {recording, streaming} = state;

  const snapshotUrl = usePreviewSrc({id, disabled: !online});

  const selected = useMemo(
    () => [...state.publishers, state.destination].filter((s) => Boolean(s)),
    [state.publishers, state.destination],
  );

  const publishers = useMemo(
    () =>
      channel
        .getPublishers()
        .map<Destination>((p) => ({id: p.getId(), name: p.getName()}))
        .sort((a, b) => stringComparator(a.name, b.name)),
    [channel],
  );

  const handleSelectDestination = ({destinationId, type}: SelectArgs) => {
    selectDestination(idx, type, destinationId);
  };

  const handleStreamingChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMediaAction(idx, 'streaming', event.target.checked);
    },
    [setMediaAction, idx],
  );

  const handleRecordingChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMediaAction(idx, 'recording', event.target.checked);
    },
    [setMediaAction, idx],
  );

  const hasSelected = selected.length > 0;

  return (
    <Box sx={sx} display="grid" gridTemplateColumns="86px 1fr" alignItems="center">
      <Box data-id="channel-preview" component="img" src={snapshotUrl} width={70} height={36} />

      <Box minWidth={0}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Box minWidth={0} flex={1}>
            <OverflowTooltip dataId="channel-name" rowsNumber={2}>
              {name}
            </OverflowTooltip>
          </Box>

          <Stack minWidth={0} direction="row" alignItems="center" gap={1}>
            <DestinationPicker
              dataId="destination-picker"
              selected={selected}
              destinations={destinations}
              publishers={publishers}
              onSelect={handleSelectDestination}
            />

            <FormControlLabel
              sx={{
                m: 0,
                px: 1,
                height: 39,
                border: 1,
                borderColor: grey[500],
                borderRadius: 1,
                gap: 0.5,
                bgcolor: 'white',
              }}
              control={
                <Checkbox
                  sx={{p: 0}}
                  data-id="streaming-checkbox"
                  color="primary"
                  disabled={!hasSelected}
                  checked={streaming}
                  onChange={handleStreamingChange}
                />
              }
              label={
                <Stack direction="row" gap={0.5} alignItems="center">
                  <WifiIcon
                    sx={{transform: 'rotate(90deg)'}}
                    fontSize="small"
                    color={hasSelected ? 'primary' : 'disabled'}
                  />
                  <Typography>Stream</Typography>
                </Stack>
              }
            />
          </Stack>

          <FormControlLabel
            sx={{
              m: 0,
              px: 1,
              height: 39,
              border: 1,
              borderColor: grey[500],
              borderRadius: 1,
              gap: 0.5,
              bgcolor: 'white',
            }}
            control={
              <Checkbox
                data-id="recording-checkbox"
                sx={{p: 0}}
                color="primary"
                checked={recording}
                onChange={handleRecordingChange}
              />
            }
            label={
              <Stack direction="row" gap={0.5} alignItems="center">
                <FiberManualRecordIcon fontSize="small" color="error" />
                <Typography>Record</Typography>
              </Stack>
            }
          />
        </Stack>
      </Box>
    </Box>
  );
}

interface Args {
  id: string;
  disabled: boolean;
}

function usePreviewSrc({id, disabled}: Args) {
  const [snapshotUrl, setSnapshotUrl] = useState(() => getPreviewUrl(id, disabled));

  useEffect(() => {
    const interval = window.setInterval(() => {
      setSnapshotUrl(getPreviewUrl(id, disabled));
    }, 1000 * 30);

    return () => {
      window.clearInterval(interval);
    };
  }, [id, disabled]);

  return snapshotUrl;
}

function getPreviewUrl(id: string, disabled: boolean): string {
  const {origin} = window.location;

  if (disabled) {
    return `${origin}/assets/img/device-thumbnail-stub.png`;
  }

  const url = new URL(DeviceApiService.getSnapshotUrl(id), origin);
  url.searchParams.append('v', `${dayjs().unix()}`);

  return url.toString();
}
