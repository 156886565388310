import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {Component} from 'app/types/common';
import {Button} from 'app/components/sharedReactComponents/Button';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {Icons} from 'app/util/icons';
import {PseudoLink} from 'app/components/sharedReactComponents/PseudoLink';

interface Props extends Component {
  onEnterCoupon: Fn;
  onCancel: Fn;
}

export const AnnualInfo = ({onCancel, onEnterCoupon}: Props) => {
  return (
    <Stack sx={{flex: '1'}}>
      <Box component="header" sx={{mb: 1.5}}>
        <Box sx={{ml: -1.5}}>
          <Button dataId="back" theme={THEME.PRIMARY} variant={VARIANT.TEXT} onClick={onCancel}>
            {Icons.backLeft().size(SIZE.S).label('Back').reactComponent()}
          </Button>
        </Box>

        <Typography variant="h6" fontWeight={600}>
          Save up to 20% <br /> if you buy an annual plan
        </Typography>
      </Box>

      <div>
        <Stack component="ul" gap={1} sx={{pl: 2, lineHeight: 1.6}}>
          <li>Contact Epiphan or find a dealer closest to you</li>

          <li>Purchase a voucher for annual subscription and get a discount</li>

          <li>
            <PseudoLink onClick={() => onEnterCoupon()}>Enter the voucher code</PseudoLink> in the
            billing page and enjoy premium services
          </li>
        </Stack>
      </div>

      <Stack component="footer" sx={{mt: 'auto'}} direction="row" gap={2} justifyContent="center">
        <Button
          dataId="contact_us"
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          href="https://www.epiphan.com/company/contact-us/?interest=cloud"
          target="_blank"
        >
          Contact us
        </Button>

        <Button
          dataId="find_dealer"
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          href="https://www.epiphan.com/where-to-buy/"
          target="_blank"
        >
          Find a dealer
        </Button>
      </Stack>
    </Stack>
  );
};
