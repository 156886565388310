import React, {useMemo} from 'react';
import dayjs from 'dayjs';
import {Box, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {Sx} from 'app/types/common';
import {secondsToMinutes} from 'app/util/timeConverter';
import {getDayTimeline} from 'app/components/sharedReactComponents/Events/Calendar/utils';

interface Props extends Sx {
  range: TimeRange;
}

export function DayTimeline({sx, range}: Props) {
  const duration = range.to - range.from;

  const timeline = useMemo(() => {
    const duration = range.to - range.from;
    return getDayTimeline(range.from, duration);
  }, [range.from, range.to]);

  return (
    <Box sx={sx}>
      <Box pt={3} height={secondsToMinutes(duration)}>
        {timeline.map((timestamp) => (
          <Box key={timestamp} height={60}>
            <Typography
              px={1}
              textAlign="end"
              position="relative"
              fontSize={12}
              color={grey[500]}
              top={-9}
            >
              {dayjs.unix(timestamp).format('h A')}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
