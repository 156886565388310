import React, {ReactElement} from 'react';
import dayjs from 'dayjs';
import {Link, Stack, Typography, linkClasses} from '@mui/material';
import {grey} from '@mui/material/colors';
import {router} from 'app/router/main';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {useVersionQuery} from 'app/components/entities/maintenance';

const rootSx: StyleSx = {
  '&:after, &:before': {content: 'none'},
  [`& .${linkClasses.root}`]: {'&:hover, &:focus': {color: 'secondary.main'}},
};

type Props = Sx & {
  align?: 'left' | 'center';
  loggedIn?: boolean;
  showVersion?: boolean;
  showTermsLink?: boolean;
};

export function Footer({
  sx,
  align = 'center',
  loggedIn = true,
  showVersion = true,
  showTermsLink = true,
}: Props) {
  const {data: version} = useVersionQuery(showVersion);

  const renderTermsLink = (): ReactElement =>
    loggedIn ? (
      <Link variant="body2" color="secondary.main" underline="none" href={router.url('agreement')}>
        Terms
      </Link>
    ) : (
      <Link
        variant="body2"
        color="secondary.main"
        underline="none"
        href="/agreement.html"
        target="_blank"
        rel="noopener"
      >
        Terms
      </Link>
    );

  const year = dayjs().year();

  return (
    <Stack
      sx={packSx(rootSx, align === 'center' && {alignItems: 'center'}, sx)}
      component="footer"
      bgcolor={grey[900]}
    >
      <Stack
        py={3}
        px={2}
        width={1032}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body2" component="span" color="text.disabled">
          Epiphan Cloud
          {version && showVersion && (
            <Typography variant="inherit" component="span">
              {' '}
              version <span data-id="app-version">{version}</span>
            </Typography>
          )}
        </Typography>

        <Typography variant="body2" component="span" color="text.disabled">
          ™ and © {year} Epiphan Systems Inc. All Rights Reserved
        </Typography>

        {showTermsLink && renderTermsLink()}

        <Link
          data-id="cloud-link"
          variant="body2"
          color="secondary.main"
          underline="none"
          href="https://www.epiphan.com/cloud/"
          target="_blank"
          rel="noopener"
        >
          About Epiphan Cloud
        </Link>

        <Link
          data-id="user-guide-link"
          variant="body2"
          color="secondary.main"
          underline="none"
          href="https://www.epiphan.com/userguides/epiphan-cloud/"
          target="_blank"
          rel="noopener"
        >
          User Guide
        </Link>

        {loggedIn && (
          <Link
            data-id="acceptance-link"
            variant="body2"
            color="secondary.main"
            underline="none"
            href={router.url('acceptanceUsePolicy')}
            rel="noopener"
          >
            Acceptable use
          </Link>
        )}
      </Stack>
    </Stack>
  );
}
