import {front} from 'app/api/CirrusApi';
import {Callback} from 'app/types/common';
import {noop} from 'app/util/noop';
import {secondsToMilliseconds} from 'app/util/timeConverter';
import {ApiResponseNumeralStatus} from 'app/data/Status';
import {AbortCallback} from 'app/api/types';
import {VideoHlsController} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStatusPreview/VideoHlsController';

const KEEPALIVE_TIMEOUT = secondsToMilliseconds(15);

const WAIT_FOR_STREAM_TIMEOUT = secondsToMilliseconds(2);
const WAIT_FOR_STREAM_RETRIES = 5;

interface Params {
  deviceId: string;
  videoHlsController: any;
  onError?: Callback;
}

export class VideoStreamController {
  private deviceId: string;
  private controller: VideoHlsController;
  private onError: Callback;
  private streamEndpoint: any;
  private streamUrl: string;
  private tryGetHlsTimeout = 0;
  private abortRequest: AbortCallback = noop;
  private abortHlsRequest: AbortCallback = noop;
  private keepAliveTimeout = 0;
  private loadingPromise: any = null;
  private isLoaded = false;
  private isPlaying = false;

  constructor({deviceId, videoHlsController, onError = noop}: Params) {
    this.deviceId = deviceId;
    this.controller = videoHlsController;
    this.onError = onError;

    this.streamEndpoint = front.devices(deviceId).hls().playlist();
    this.streamUrl = this.streamEndpoint.url();
  }

  destroy() {
    this.stopKeepAliveRequest();
    this.abortRequest();
    this.abortHlsRequest();
    this.controller.destroy();
    clearTimeout(this.tryGetHlsTimeout);
  }

  play() {
    this.isPlaying = true;

    let loading;
    if (this.loadingPromise) {
      loading = this.loadingPromise;
    } else if (this.isLoaded) {
      loading = Promise.resolve();
    } else {
      loading = this.load();
    }

    return loading
      .then(() => {
        if (this.isPlaying) {
          this.controller.play(this.streamUrl);
        }
      })
      .catch((errorMessage) => {
        this.pause();

        this.onError(errorMessage);
      });
  }

  pause() {
    void this.controller.pause();
    this.isPlaying = false;
    this.stopKeepAliveRequest();
    this.isLoaded = false;
  }

  private load(): Promise<any> {
    this.loadingPromise = this.startLive()
      .then(() => {
        this.isLoaded = true;

        if (!this.keepAliveTimeout) {
          this.startKeepAliveRequest();
        }
      })
      .then(async () => this.waitForHlsUrl())
      .catch(async (error) => {
        this.isLoaded = false;

        let errorMessage;

        if (error.status === ApiResponseNumeralStatus.PaymentRequired) {
          errorMessage = 'Full frame rate and audio are not available for free teams.';
        }

        return Promise.reject(errorMessage);
      })
      .finally(() => {
        this.loadingPromise = null;
      });

    return this.loadingPromise;
  }

  private startLive(): Promise<void> {
    const getAbort = (abort) => {
      this.abortRequest = abort;
    };

    return front.devices(this.deviceId).task().live().start().post(undefined, {getAbort});
  }

  private waitForHlsUrl(): Promise<any> {
    let tryCounter = 0;

    const tryGetHls = (resolve, reject) => {
      const getAbort = (abort) => {
        this.abortHlsRequest = abort;
      };

      this.streamEndpoint
        .get(undefined, {
          contentType: false,
          getAbort,
        })
        .then((response) => {
          if (response) {
            return resolve();
          }

          return Promise.reject(response);
        })
        .catch((error) => {
          if (tryCounter < WAIT_FOR_STREAM_RETRIES) {
            tryCounter += 1;
            this.tryGetHlsTimeout = window.setTimeout(() => {
              tryGetHls(resolve, reject);
            }, WAIT_FOR_STREAM_TIMEOUT);
          } else {
            reject(error);
          }
        });
    };

    return new Promise(tryGetHls);
  }

  private startKeepAliveRequest() {
    this.keepAliveTimeout = window.setTimeout(async () => {
      await this.startLive();
      await this.startKeepAliveRequest();
    }, KEEPALIVE_TIMEOUT);
  }

  private stopKeepAliveRequest() {
    clearTimeout(this.keepAliveTimeout);
    this.keepAliveTimeout = 0;
  }
}
