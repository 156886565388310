import React, {useState} from 'react';
import {Box, Stack, Tab, Tabs} from '@mui/material';
import {TabPanel} from 'app/components/sharedReactComponents/TabPanel/TabPanel';
import {Callback} from 'app/types/common';
import {CreateTeamsCall} from 'app/components/dialogs/TeamsConnectDialog/TeamsConnect/CreateTeamsCall/CreateTeamsCall';
import {palette} from 'app/themes/app';
import {ConnectToTeamsCall} from 'app/components/dialogs/TeamsConnectDialog/TeamsConnect/ConnectToTeamsCall/ConnectToTeamsCall';

interface Props {
  hasTenants: boolean;
  canPair: boolean;
  onCreate: React.ComponentProps<typeof CreateTeamsCall>['onCreate'];
  onConnect: React.ComponentProps<typeof ConnectToTeamsCall>['onConnect'];
  onPair: Callback;
}
type TeamsTab = 'create' | 'connect';

export default function TeamsConnect({canPair, hasTenants, onConnect, onCreate, onPair}: Props) {
  const [tab, setTab] = useState<TeamsTab>('create');

  const handleTabChange = (_e: React.SyntheticEvent, value: TeamsTab) => {
    setTab(value);
  };

  return (
    <Stack direction="row" sx={{minHeight: 784}}>
      <Stack sx={{bgcolor: '#f1f1f1', width: 220}}>
        <Stack direction="row" gap={1} alignItems="center" px={3} my={4}>
          <Box
            component="img"
            width={130}
            height={20}
            src="/assets/img/logo/teams-logo.png"
            srcSet="/assets/img/logo/teams-logo@2x.png 2x"
            alt=""
          />
        </Stack>
        <Tabs
          sx={{
            '& .MuiTabs-indicator': {display: 'none'},
            '& .MuiTab-root': {
              color: palette.gray,
              alignItems: 'flex-start',
              px: 3,
              fontSize: 14,
              fontWeight: 'bold',
              '&:hover, &.Mui-focusVisible': {bgcolor: '#e8e8e880'},
              '&.Mui-selected': {color: '#333333', bgcolor: '#e8e8e8'},
            },
          }}
          value={tab}
          orientation="vertical"
          onChange={handleTabChange}
        >
          <Tab data-id="create-tab" value="create" label="Create a meeting" />
          <Tab data-id="connect-tab" value="connect" label="Connect to a meeting" />
        </Tabs>
      </Stack>

      <TabPanel sx={{flex: 1}} value="create" active={tab}>
        <CreateTeamsCall sx={{px: 9, py: 10}} onCreate={onCreate} />
      </TabPanel>
      <TabPanel sx={{flex: 1}} value="connect" active={tab}>
        <ConnectToTeamsCall
          sx={{px: 9, py: 10}}
          hasTenants={hasTenants}
          canPair={canPair}
          onConnect={onConnect}
          onCreate={() => setTab('create')}
          onPair={onPair}
        />
      </TabPanel>
    </Stack>
  );
}
