import React, {useMemo} from 'react';
import {Box, Divider, Stack, Typography} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {Icons} from 'app/util/icons';
import {router} from 'app/router/main';
import {StreamingDestinationsSelectorMenuItem} from 'app/components/sharedReactComponents/ActionButtons/StreamingDestinationsSelector/StreamingDestinationsSelectorMenuItem';
import {SelectMenuOptionWithCheckbox} from 'app/components/sharedReactComponents/SelectMenuOption/SelectMenuOptionWithCheckbox';
import {TooltipMultipleMessages} from 'app/components/sharedReactComponents/Tooltip/TooltipMultipleMessages';
import {RadioGroup} from 'app/components/sharedReactComponents/RadioGroup';
import {StreamingDestinationListTitle} from 'app/components/sharedReactComponents/StreamingDestinationListTitle';
import {Callback, Sx} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {DevicePublisherModel} from 'app/models/Device/Publisher/DevicePublisherModel';
import {usePublisherWarnings} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStreamingPanel/DeviceStreamingDestinationList/DevicePublisherList';
import {DeviceWarning} from 'app/components/DeviceDetails/Models/types';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {packSx} from 'app/util/packSx/packSx';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';

interface PublishersSelectMenuProps {
  publishers: DevicePublisherModel[];
  selectedPublishers: Set<string>;
  warnings: DeviceWarning[];
  disabled: boolean;
  onChange: Callback;
}

function PublishersSelectMenu({
  publishers,
  selectedPublishers,
  warnings,
  disabled,
  onChange,
}: PublishersSelectMenuProps) {
  const {getWarnings} = usePublisherWarnings(warnings);

  return (
    <Box>
      {publishers.map((publisher) => {
        const publisherId = publisher.getId();
        const name = publisher.getName();
        const isActive = selectedPublishers.has(publisherId);
        const publisherWarnings = getWarnings(publisherId);
        const hasWarnings = publisherWarnings.length > 0;

        return (
          <SelectMenuOptionWithCheckbox
            key={publisherId}
            name={publisherId}
            active={isActive}
            disabled={disabled}
            onClick={onChange}
          >
            <Stack direction="row" alignItems="flex-start" gap={1}>
              <Typography component="span" sx={hasWarnings ? {color: 'error.main'} : {}}>
                <OverflowTooltip rowsNumber={2} async={true}>
                  {name}
                </OverflowTooltip>
              </Typography>

              {hasWarnings && (
                <TooltipMultipleMessages messages={publisherWarnings}>
                  <WarningIcon color="error" />
                </TooltipMultipleMessages>
              )}
            </Stack>
          </SelectMenuOptionWithCheckbox>
        );
      })}
    </Box>
  );
}

function MenuFooter({children}: React.PropsWithChildren<{}>) {
  return (
    <Stack direction="row" component="footer" p={1}>
      {children}
    </Stack>
  );
}

interface Props extends Sx {
  publishers: DevicePublisherModel[];
  selectedPublishers: Set<string>;
  selectedStreamingDestinations: Set<any>;
  device: AnyDeviceModelType;
  streamingDestinations: AnyStreamingDestinationModelType[];
  publisherWarnings: DeviceWarning[];
  streamingDestinationWarnings: DeviceWarning[];
  offline: boolean;
  started: boolean;
  editable: boolean;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  onPublisherSelect: Callback;
  onStreamingDestinationSelect: Callback;
}

export function StreamingDestinationsSelectorMenu({
  sx,
  publishers,
  selectedPublishers,
  selectedStreamingDestinations,
  device,
  streamingDestinations,
  publisherWarnings,
  streamingDestinationWarnings,
  offline,
  started,
  editable,
  getDeviceById,
  onPublisherSelect,
  onStreamingDestinationSelect,
}: Props) {
  const [visiblePublishers, visibleStreamingDestinations] = useMemo(() => {
    if (started) {
      return [
        publishers.filter((p) => selectedPublishers.has(p.getId())),
        streamingDestinations.filter((d) => selectedStreamingDestinations.has(d.getId())),
      ];
    }

    return [publishers, streamingDestinations];
  }, [
    started,
    publishers,
    selectedPublishers,
    streamingDestinations,
    selectedStreamingDestinations,
  ]);

  const hasItems = visiblePublishers.length > 0 || visibleStreamingDestinations.length > 0;
  const selectedStreamingDestination = [...selectedStreamingDestinations][0];

  const isChannel = ModelService.isChannel(device.getModelName());

  const isUnify = ModelService.isUnify(
    isChannel ? (device as PearlSlotDeviceModel).getMasterModelName() : device.getModelName(),
  );

  const destinationsUrl = offline
    ? router.url('streamingDestinations')
    : router.url(
        isUnify ? 'projectDetails' : 'deviceDetails',
        {deviceId: device.getId()},
        {activeTab: 'channels', scrollTo: 'streamingDestinations'},
      );

  return (
    <Box
      sx={packSx({width: hasItems ? 365 : 400}, sx)}
      // TODO: remove
      className="streaming-destinations-selector-menu"
    >
      <Box p={0.5}>
        {hasItems ? (
          <Stack gap={1} divider={<Divider flexItem={true} />}>
            {visiblePublishers.length > 0 && (
              <PublishersSelectMenu
                publishers={visiblePublishers}
                selectedPublishers={selectedPublishers}
                warnings={publisherWarnings}
                disabled={started || offline}
                onChange={onPublisherSelect}
              />
            )}

            {visibleStreamingDestinations.length > 0 && (
              <div>
                {!started && <StreamingDestinationListTitle sx={{mb: 1, ml: 1}} />}

                <RadioGroup
                  uncheckable={true}
                  value={selectedStreamingDestination}
                  onChange={onStreamingDestinationSelect}
                >
                  {visibleStreamingDestinations.map((streamingDestination) => {
                    const destId = streamingDestination.getId();
                    return (
                      <StreamingDestinationsSelectorMenuItem
                        key={destId}
                        streamingDestination={streamingDestination}
                        isUnify={isUnify}
                        device={device}
                        disabled={started || offline}
                        editable={editable}
                        getDeviceById={getDeviceById}
                        warnings={
                          selectedStreamingDestination === destId
                            ? streamingDestinationWarnings
                            : []
                        }
                      />
                    );
                  })}
                </RadioGroup>
              </div>
            )}
          </Stack>
        ) : (
          <Box p={1}>
            <Typography fontWeight={600} color="primary.main" fontSize={16}>
              You don't have streaming destinations created.
            </Typography>
            <Typography>Create a streaming destination now and start streaming.</Typography>
          </Box>
        )}
      </Box>

      {editable && (
        <>
          {hasItems ? (
            <>
              {!started && (
                <MenuFooter>
                  <a
                    className="streaming-destinations-selector-menu__edit-destinations-button"
                    href={destinationsUrl}
                  >
                    Add and edit destinations {Icons.urlGoArrow().reactComponent()}
                  </a>
                </MenuFooter>
              )}
            </>
          ) : (
            <MenuFooter>
              <a
                className="streaming-destinations-selector-menu__add-destination-button"
                href={destinationsUrl}
              >
                New destination {Icons.urlGoArrow().reactComponent()}
              </a>
            </MenuFooter>
          )}
        </>
      )}
    </Box>
  );
}
