import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogTitle} from '@mui/material';
import {Prompt} from 'app/types/common';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {LoadingButton} from '@mui/lab';

interface Props extends Prompt {
  name: string;
  onStop(): Promise<void>;
}

export function StopEventDialog({open, name, onStop, onClose}: Props) {
  const [loading, setLoading] = useState(false);

  const handleStop = async () => {
    try {
      setLoading(true);
      await onStop();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true} onClose={onClose}>
      <DialogClose onClose={onClose} />
      <DialogTitle>
        Stop <b>{name}</b>
      </DialogTitle>

      <DialogActions>
        <LoadingButton
          data-id="confirm-stop-button"
          variant="contained"
          color="error"
          loading={loading}
          onClick={handleStop}
        >
          Stop
        </LoadingButton>
        <Button data-id="cancel-stop-button" variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
