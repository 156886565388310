import React, {useMemo} from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import {SortOption, Sx} from 'app/types/common';
import {FilterSelector} from 'app/components/sharedReactComponents/FilterSelector';
import {
  FilterSwitch,
  FilterSwitchGroup,
} from 'app/components/sharedReactComponents/FilterSelector/types';
import {TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';
import {ActiveFilters} from 'app/components/sharedReactComponents/ActiveFilters/ActiveFilters';

type Props = Sx & {
  hasDevices: boolean;
  groupsAccess: boolean;
  filters: FilterSwitch<string>[];
  sortField: string;
  sortOptions: SortOption[];
  groupFilters: FilterSwitchGroup<string>[];
  activeFilters: Set<string>;
  searchableGroupFilters?: Set<string>;
  collapsibleGroupFilters?: Set<string>;
  collapsedGroupFilters: Set<string>;
  detailed: boolean;
  onChangeSort: (value: string) => void;
  onToggleFilter: (id: string) => void;
  onToggleCollapse: (id: string) => void;
  onClearFilters: () => void;
  onToggleDetailed: () => void;
  onAddGroup: () => void;
};

export function EdgeHeader({
  sx,
  hasDevices,
  groupsAccess,
  sortField,
  sortOptions,
  filters,
  activeFilters,
  groupFilters,
  collapsibleGroupFilters,
  searchableGroupFilters,
  collapsedGroupFilters,
  detailed,
  onToggleFilter,
  onToggleCollapse,
  onClearFilters,
  onChangeSort,
  onToggleDetailed,
  onAddGroup,
}: Props) {
  const selected = useMemo(
    () => filters.filter((filter) => activeFilters.has(filter.id)),
    [filters, activeFilters],
  );

  return (
    <Box sx={sx}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Box flex={1}>
          <Typography variant="h6" fontWeight={600}>
            All devices
          </Typography>

          <Typography variant="body2" color="text.secondary">
            Devices that are currently paired to your team appear here.
          </Typography>
        </Box>

        {hasDevices && (
          <>
            <Tooltip title="Show additional information for online devices including model, serial number, ip, performance, file upload status, storage, firmware">
              <FormControlLabel
                sx={{m: 0}}
                control={
                  <Switch checked={detailed} color="secondary" onChange={onToggleDetailed} />
                }
                label="Detailed view"
                labelPlacement="start"
              />
            </Tooltip>

            <Typography>Sort by:</Typography>

            <Select
              data-id="fleet_manager_sort_selector"
              sx={{width: 84}}
              slotProps={{input: {sx: {py: 0.75}}}}
              value={sortField}
            >
              {sortOptions.map(({value, label}) => (
                <MenuItem
                  key={value}
                  data-id={`sort-option-${value}`}
                  value={value}
                  onClick={() => onChangeSort(value)}
                >
                  {label}
                </MenuItem>
              ))}
            </Select>

            <FilterSelector
              filterSwitches={filters}
              filterSwitchGroups={groupFilters}
              activeFilterSwitches={activeFilters}
              searchableFilterSwitchGroups={searchableGroupFilters}
              collapsibleFilterSwitchGroups={collapsibleGroupFilters}
              collapsedFilterSwitchGroups={collapsedGroupFilters}
              placement={TOOLTIP_PLACEMENT.BOTTOM_END}
              onClickFilterSwitcher={onToggleFilter}
              onToggleFilterSwitchGroupCollapse={onToggleCollapse}
            />
          </>
        )}

        {groupsAccess && (
          <Button
            data-id="add_new_group_button"
            variant="outlined"
            color="info"
            startIcon={<CreateNewFolderIcon />}
            onClick={onAddGroup}
          >
            New group
          </Button>
        )}
      </Stack>

      {hasDevices && (
        <ActiveFilters
          sx={{mt: 0.5}}
          filters={selected}
          onRemove={onToggleFilter}
          onRemoveAll={onClearFilters}
        />
      )}
    </Box>
  );
}
