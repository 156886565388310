import {useEffect, useState} from 'react';
import {containsSearchString} from 'app/components/FleetManager/utils';
import {Meeting} from 'app/store/models/connect/Meeting/Meeting';

type Entity = Meeting | App.Connect.Call;

function isMeeting(e: Entity): e is Meeting {
  return e instanceof Meeting === true;
}

function filterEntities<T extends Entity>(entities: T[], search: string): T[] {
  return entities.filter((e) => {
    if (isMeeting(e)) {
      return containsSearchString(e.name, search) || containsSearchString(e.tenantName, search);
    }

    return containsSearchString(e.name, search);
  });
}

export function useFilter(
  entities: Array<Meeting | App.Connect.Call>,
  search: string,
): Array<Meeting | App.Connect.Call> {
  const [filtered, setFiltered] = useState<Array<Meeting | App.Connect.Call>>([]);

  useEffect(() => {
    const result = search ? filterEntities(entities, search.trim()) : entities;

    setFiltered(result.sort(sortPredicate));
  }, [search, entities]);

  return filtered;
}

type SortOrder = {
  [K in App.Connect.MeetingStatus]: number;
};

const order: SortOrder = {
  disconnected: 0,
  disconnecting: 0,
  starting: 1,
  connecting: 1,
  'in-waiting-room': 2,
  'waiting-for-host': 2,
  'waiting-for-password': 2,
  'waiting-for-account': 2,
  running: 3,
  error: 3,
};

const prioritized: Array<App.Connect.MeetingStatus> = [
  'running',
  'in-waiting-room',
  'waiting-for-host',
];

function sortPredicate(a: Entity, b: Entity): number {
  if (isMeeting(a) && isMeeting(b)) {
    return order[b.status] - order[a.status] || b.createdAt - a.createdAt;
  }

  if (isMeeting(a)) {
    if (prioritized.includes(a.status)) {
      return -1;
    }

    return 1;
  }

  if (isMeeting(b)) {
    if (prioritized.includes(b.status)) {
      return -1;
    }

    return 1;
  }

  return b.createdAt - a.createdAt;
}
