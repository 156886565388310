/* eslint-disable jsx-a11y/media-has-caption */
import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {VideoHlsController} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStatusPreview/VideoHlsController';
import {VideoStreamController} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStatusPreview/VideoStreamController';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  deviceId: string;
  muted: boolean;
}

export const DeviceStatusHDPreview: React.VFC<Props> = ({className, deviceId, muted}) => {
  const [playing, setPlaying] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!videoRef.current) {
      throw Error('divRef is not assigned');
    }

    const onError = (error: any) => {
      if (typeof error === 'string') {
        setErrorMessage(error);
      } else {
        setErrorMessage('Could not load the video. Please try again later.');
      }

      setPlaying(false);
    };

    const videoHlsController = new VideoHlsController({
      element: videoRef.current,
      onError,
    });

    const videoStreamController = new VideoStreamController({
      deviceId,
      videoHlsController,
      onError,
    });

    videoStreamController.play();

    return () => {
      videoStreamController.destroy();
    };
  }, [deviceId]);

  const hasError = errorMessage !== '';

  return (
    <div
      className={classNames('device-status-hd-preview', className)}
      data-id="device_preview_video"
    >
      <video
        ref={videoRef}
        className="device-status-hd-preview__video"
        preload="true"
        muted={muted}
        onCanPlay={() => setPlaying(true)}
        onEnded={() => setPlaying(false)}
        onPause={() => setPlaying(false)}
      />

      {hasError && (
        <div
          className="device-status-hd-preview__error-message"
          data-id="device_preview_video_error"
        >
          {errorMessage}
        </div>
      )}

      {playing === false && hasError === false && (
        <div className="device-status-hd-preview__loading">
          {Icons.spinner().size(SIZE.XXL).reactComponent()}
        </div>
      )}
    </div>
  );
};
