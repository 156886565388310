import React from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';

enum SEPARATOR_DIRECTION {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

interface Props extends ClassName {
  direction?: SEPARATOR_DIRECTION;
}

export const Separator: React.FC<Props> = ({
  className,
  direction = SEPARATOR_DIRECTION.HORIZONTAL,
}) => {
  return (
    <div className={classNames('separator', `separator--${direction}`, className)}/>
  );
};

const SeparatorVertical: React.FC<Props> = (props) => (
  <Separator
    {...props}
    direction={SEPARATOR_DIRECTION.VERTICAL}
  />
);

export {
  SEPARATOR_DIRECTION,
  SeparatorVertical,
};
