/* eslint-disable react/default-props-match-prop-types */
import React, {Component} from 'react';
import classNames from 'classnames';
import {TableHeader} from 'app/components/Dashboard/Devices/TableView/TableHeader';
import {TableBody} from 'app/components/Dashboard/Devices/TableView/TableBody';
import {ColumnActiveManager} from 'app/components/Dashboard/Devices/TableView/ColumnManager/ColumnActiveManager';
import {ColumnsSelector} from 'app/components/FleetManager/ColumnsHeader/ColumnsSelector';
import {stringComparator} from 'app/util/Sort';
import {valueComparator} from 'app/util/comparators/valueComparator';
import {SORT_DIRECTION} from 'app/constants';
import {Callback, ClassName} from 'app/types/common';
import {TableColumnType} from 'app/components/sharedReactComponents/Table/types';

const NAME_CELL_DATA_KEY = 'name';

const nameGetter = value => {
  if (value === null || value === undefined) {
    return '';
  }

  return String(value.name);
};

function sortList({sortBy, sortDirection, list, comparator = valueComparator}) {
  const valueGetter = item => item[sortBy];

  const isAscSorting = sortDirection === SORT_DIRECTION.ASC;

  return list.sort((a, b) => {
    const aValue = valueGetter(a);
    const bValue = valueGetter(b);
    let sortRes = 0;

    const nameSortRes = stringComparator(nameGetter(a), nameGetter(b));

    if (sortBy === NAME_CELL_DATA_KEY) {
      sortRes = nameSortRes;
    } else {
      if (aValue === bValue) {
        return 0;
      }

      // Don't desc sorting by name in this case
      if (aValue === null || typeof aValue === 'undefined') {
        return 1;
      }

      // Don't desc sorting by name in this case
      if (bValue === null || typeof bValue === 'undefined') {
        return -1;
      }

      sortRes = comparator(aValue, bValue);

      // Sort by name by default. Don't desc sorting by name in this case
      if (sortRes === 0) {
        return nameSortRes;
      }
    }

    return isAscSorting ? sortRes : -1 * sortRes;
  });
}

interface Props extends ClassName {
  sortDirection: SORT_DIRECTION;
  cells: TableColumnType[];
  list: any[];
  sortBy: string;
  fixedColumnIds?: string[];
  defaultActiveColumns?: string[];
  storeKey: string;
  renderAsTable: boolean;
}

interface State {
  cells: TableColumnType[];
  sortBy: string;
  sortDirection: SORT_DIRECTION;
  sortedList: any[];
}

export class Table extends Component<Props, State> {
  static defaultProps = {
    sortBy: NAME_CELL_DATA_KEY,
    sortDirection: SORT_DIRECTION.ASC,
    renderAsTable: true,
  };

  columnActiveManager: ColumnActiveManager | null;
  cellSortComparators: Map<string, Callback>;

  constructor(props: Props) {
    super(props);

    const storeKey = props.storeKey + '.Columns';

    this.columnActiveManager = null;
    if (props.fixedColumnIds || props.defaultActiveColumns) {
      this.columnActiveManager = new ColumnActiveManager({
        storeKey,
        fixedColumns: props.fixedColumnIds,
        defaultActiveColumns: props.defaultActiveColumns,
      });
    }

    this.cellSortComparators = props.cells.reduce((acc, cell) => {
      if (cell.comparator) {
        acc.set(cell.dataKey, cell.comparator);
      }

      return acc;
    }, new Map());

    const {list} = props;
    const {sortBy} = props;
    const {sortDirection} = props;
    const sortedList = sortList({
      sortBy,
      sortDirection,
      list,
      comparator: this.cellSortComparators.get(sortBy),
    });

    this.state = {
      cells: this._processCells(),
      sortBy,
      sortDirection,
      sortedList,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      cells,
      list,
    } = this.props;

    if (cells !== prevProps.cells) {
      this.setState({cells: this._processCells()});
    }

    if (list !== prevProps.list) {
      const {
        sortBy,
        sortDirection,
      } = this.state;

      const sortedList = sortList({
        sortBy,
        sortDirection,
        list,
        comparator: this.cellSortComparators.get(sortBy),
      });

      this.setState({sortedList});
    }
  }

  _processCells() {
    const {
      cells,
    } = this.props;

    if (this.columnActiveManager === null) {
      return cells;
    }

    const activeCells = cells.filter(cell => this.columnActiveManager?.isActive(cell.dataKey));

    return activeCells;
  }

  handleColumnsSelectorChange = ({target: {name, value}}) => {
    this.columnActiveManager?.toggle(name, value);
    this.setState({cells: this._processCells()});
  };

  isColumnActive = (id) => {
    return this.columnActiveManager?.isActive(id);
  };

  handleSort = ({sortBy, sortDirection}) => {
    const {
      list,
    } = this.props;

    this.setState({
      sortedList: sortList({
        sortBy,
        sortDirection,
        list,
        comparator: this.cellSortComparators.get(sortBy),
      }),
      sortBy,
      sortDirection,
    });
  };

  render() {
    const {
      className,
      fixedColumnIds,
      renderAsTable,
    } = this.props;
    const {
      cells,
      sortBy,
      sortDirection,
      sortedList,
    } = this.state;

    // TODO: Refactor this
    const cellWithContextMenu = cells.find(cell => cell.dataKey === 'contextMenu');
    if (cellWithContextMenu) {
      cellWithContextMenu.headerRenderer = () => (
        <ColumnsSelector
          columns={this.props.cells}
          fixedColumnIds={fixedColumnIds}
          isColumnActive={this.isColumnActive}
          onChange={this.handleColumnsSelectorChange}
        />
      );
    }

    const Component = renderAsTable ? 'table' : 'div';

    return (
      <Component
        className={classNames('dashboard-table', {
          'dashboard-table--view-table': renderAsTable,
          'dashboard-table--view-list': !renderAsTable,
        }, className)}
      >
        <TableHeader
          cells={cells}
          sortBy={sortBy}
          sortDirection={sortDirection}
          renderAsTable={renderAsTable}
          onSort={this.handleSort}
        />

        <TableBody
          cells={cells}
          list={sortedList}
          renderAsTable={renderAsTable}
        />
      </Component>
    );
  }
}
