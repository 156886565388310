import {useEffect, useRef, useState} from 'react';
import {secondsToMilliseconds} from 'app/util/timeConverter';
import {useIsMounted} from 'app/hooks/useIsMounted';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Callback} from 'app/types/common';
import {noop} from 'app/util/noop';

const WAITING_MS = secondsToMilliseconds(1);

type Props = {
  device: AnyDeviceModelType;
  onChange?: Callback;
};

export function useDevicePublisherSelection({device, onChange = noop}: Props) {
  const [selectedPublishers, setSelectedPublishers] = useState<Set<string>>(new Set());
  const [selecting, setSelecting] = useState(false);
  const publishersLoading = useRef(0);
  const lastLoadingFinishTimestamp = useRef(0);
  const mountedRef = useIsMounted();

  useEffect(() => {
    if (publishersLoading.current > 0) {
      return;
    }

    // If new devices received after last finished request in 1000 ms. This response may be not actual
    if (Date.now() - lastLoadingFinishTimestamp.current < WAITING_MS) {
      return;
    }

    const selectedPublishers = device
      .getPublishers()
      .filter((publisher) => publisher.isSelected() || publisher.isStarted())
      .map((publisher) => publisher.getId());

    setSelectedPublishers(new Set(selectedPublishers));
  }, [device]);

  // TODO: simple type
  const handlePublisherSelect = async ({target: {name, value}}) => {
    setSelecting(true);
    publishersLoading.current += 1;

    const nextSelectedPublishers = new Set(selectedPublishers);

    if (value) {
      nextSelectedPublishers.add(name);
    } else {
      nextSelectedPublishers.delete(name);
    }

    setSelectedPublishers(nextSelectedPublishers);

    return device
      .selectPublisher(name, value)
      .catch(() => {
        if (mountedRef.current) {
          setSelectedPublishers(selectedPublishers);
        }
      })
      .finally(() => {
        if (mountedRef.current === false) {
          return;
        }

        publishersLoading.current -= 1;

        if (publishersLoading.current === 0) {
          setSelecting(false);
        }

        lastLoadingFinishTimestamp.current = Date.now();
      })
      .then(() => onChange());
  };

  return {
    selectedPublishers,
    selectingPublishers: selecting,
    handlePublisherSelect,
  };
}
