import {useCallback} from 'react';
import {useSelectable} from 'app/components/FleetManager/useSelectable';
import {
  collectDeviceAndChannelIds,
  collectDevicesAndChannelsIds,
} from 'app/components/FleetManager/utils';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';

export function useDeviceSelectable() {
  const {checkIsItemSelected, select, deselect, toggleSelection, resetSelection} = useSelectable();

  const toggleDeviceChannelsSelection = useCallback(
    (device: AnyDeviceModelType) => {
      const deviceId = device.getId();
      const devicesIds = collectDeviceAndChannelIds(device);

      if (checkIsItemSelected(deviceId)) {
        deselect(devicesIds);
      } else if (ModelService.isChannel(device.getModelName())) {
        select([deviceId, (device as PearlSlotDeviceModel).getChannelDeviceMasterDeviceId()]);
      } else {
        select(devicesIds);
      }
    },
    [checkIsItemSelected, select, deselect],
  );

  const selectDeviceAndChannels = useCallback(
    (device: AnyDeviceModelType) => {
      select(collectDeviceAndChannelIds(device));
    },
    [select],
  );

  const deselectDeviceAndChannels = useCallback(
    (device: AnyDeviceModelType) => {
      deselect(collectDeviceAndChannelIds(device));
    },
    [deselect],
  );

  const resetDeviceAndChannelsSelection = useCallback(
    (devices: AnyDeviceModelType[]) => {
      resetSelection(collectDevicesAndChannelsIds(devices));
    },
    [resetSelection],
  );

  return {
    checkIsDeviceSelected: checkIsItemSelected,
    selectDevice: select,
    deselectDevice: deselect,
    selectDeviceAndChannels,
    deselectDeviceAndChannels,
    toggleDeviceSelection: toggleSelection,
    resetDeviceSelection: resetSelection,
    toggleDeviceChannelsSelection,
    resetDeviceAndChannelsSelection,
  };
}
