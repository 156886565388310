import React, {FC, ReactElement, ReactNode, useState} from 'react';
import {
  Modal,
  ModalBody,
  ModalCancelButton,
  ModalContainer,
  ModalFooter,
  ModalTitle,
} from 'app/components/sharedReactComponents/Modal/Modal';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {
  SIZE,
  THEME,
  VARIANT,
} from 'app/constants';
import {useIsMounted} from 'app/hooks/useIsMounted';
import {Callback, ClassName} from 'app/types/common';

interface Props {
  title?: ReactNode;
  titleIcon?: ReactElement;
  body?: ReactNode;
  confirmButtonLabel?: ReactNode;
  confirmButtonTheme?: THEME;
  onDone?: Callback;
}

const Content: FC<Props> = ({
  title,
  titleIcon,
  body,
  confirmButtonLabel = 'Yes',
  confirmButtonTheme = THEME.PRIMARY,
  onDone,
}) => {
  const [applying, setApplying] = useState(false);
  const mountedRef = useIsMounted();

  return (
    <ModalContainer>
      <ModalTitle icon={titleIcon}>
        {title}
      </ModalTitle>

      {body && (
        <ModalBody>
          {body}
        </ModalBody>
      )}

      <ModalFooter>
        <ProgressButton
          dataId="modal_confirm_button"
          theme={confirmButtonTheme}
          variant={VARIANT.SOLID}
          autoFocus={true}
          onClick={async () => {
            setApplying(true);

            return Promise.resolve(onDone?.())
              .then(() => Modal.hide())
              .finally(() => {
                if (mountedRef.current) {
                  setApplying(false);
                }
              });
          }}
        >
          {confirmButtonLabel}
        </ProgressButton>

        <ModalCancelButton
          disabled={applying}
        />
      </ModalFooter>
    </ModalContainer>
  );
};

interface ModalPromptProps extends ClassName, Props {
  fullWidth?: boolean;
  maxWidth?: SIZE;
  onClose?: Callback;
}

export const ModalPrompt = {
  show({
    className,
    fullWidth,
    maxWidth,
    title,
    titleIcon,
    body,
    confirmButtonLabel,
    confirmButtonTheme,
    onDone,
    onClose,
  }: ModalPromptProps) {
    Modal.show({
      className,
      fullWidth,
      maxWidth,
      children: (
        <Content
          title={title}
          titleIcon={titleIcon}
          body={body}
          confirmButtonLabel={confirmButtonLabel}
          confirmButtonTheme={confirmButtonTheme}
          onDone={onDone}
        />
      ),
      onClose,
    });
  }
};
