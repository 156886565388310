import React, {VFC} from 'react';
import {FORM_CONTROL_TYPE} from 'app/components/sharedReactComponents/FormControl/constants';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {Callback, ClassName} from 'app/types/common';
import {AppTeam} from 'app/models/AppTeam/AppTeam';

const getOptionLabel = (team: AppTeam) => team.name;
const getOptionValue = (team: AppTeam) => team.id;

interface Props extends ClassName {
  selected: string;
  loading: boolean;
  onChange: Callback;
  teams: AppTeam[];
}

export const TeamSelector: VFC<Props> = ({className, selected, loading, teams, onChange}) => (
  <div className={className}>
    <FormControl
      data-id="team_selector"
      type={FORM_CONTROL_TYPE.SELECT}
      selectComponentProps={{
        options: teams,
        getOptionLabel,
        getOptionValue,
      }}
      value={selected}
      name="teamId"
      label="Your teams"
      fullWidth={true}
      autoFocus={true}
      loading={loading}
      onChange={onChange}
    />
  </div>
);
