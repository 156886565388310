import React from 'react';
import {Box, CircularProgress, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {useDeviceId} from 'app/hooks/Device/useDeviceId';
import {InputsPanel} from 'app/components/DeviceDetails/Inputs/InputsPanel/InputsPanel';
import {useDeviceSources} from 'app/components/DeviceDetails/hooks/useDeviceSources';

interface Props extends Sx {
  device: PearlMasterDeviceModel;
}

export function Inputs({sx, device}: Props) {
  const [masterId] = useDeviceId(device.getId());
  const {data: sources, isSuccess} = useDeviceSources({deviceId: masterId, enabled: true});

  return (
    <Box sx={sx}>
      <Typography fontWeight={600} variant="h5" mb={3}>
        Inputs
      </Typography>

      {isSuccess && <InputsPanel device={device} sources={sources} />}

      {!isSuccess && (
        <Stack flexGrow={1} direction="row" justifyContent="center" alignItems="center" gap={2}>
          <CircularProgress />
          <Typography variant="h6">Loading inputs</Typography>
        </Stack>
      )}
    </Box>
  );
}
