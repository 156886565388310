import React from 'react';
import {BaseStorageConfiguration} from 'app/components/UploadLocations/CloudStorageConfiguration/BaseStorageConfiguration';
import {
  LocationForm,
  LocationRow,
  LocationInput,
} from 'app/components/UploadLocations/LocationInput';
import {OAuth2LocationModel} from 'app/components/UploadLocations/Models/OAuth2LocationModel';
import {Callback} from 'app/types/common';

interface Props {
  model: OAuth2LocationModel;
  onClickReconnect: Callback;
}

function GoogleDriveStorageConfiguration({
  model,
  onClickReconnect,
}: Props) {
  return (
    <BaseStorageConfiguration
      name="Google Drive"
      model={model}
      onClickReconnect={onClickReconnect}
    >
      <LocationForm>
        <LocationRow>
          <LocationInput
            label="Nick name"
            placeholder="Location 1"
            model={model.name}
          />
        </LocationRow>
      </LocationForm>
    </BaseStorageConfiguration>
  );
}

export {GoogleDriveStorageConfiguration};
