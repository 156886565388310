import React from 'react';
import {Avatar, Box, Chip, Stack, Typography} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import {Sx} from 'app/types/common';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {Team} from 'app/components/entities/team';
import {MemberActions} from 'app/components/features/cloud';
import {capitalize} from 'app/components/helpers/commonHelpers';

interface Props extends Sx {
  member: Team.Member;
  current: boolean;
  permitEdit: boolean;
  permitTransfer: boolean;
  teamName: string;
  hasPayment: boolean;
  onSetRole: (userId: string, role: 'admin' | 'operator') => Promise<void>;
  onTransferOwnership: (userId: string) => Promise<void>;
  onDelete: (userId: string) => Promise<void>;
}

export function TeamMember({
  sx,
  member,
  current,
  permitEdit,
  permitTransfer,
  teamName,
  hasPayment,
  onSetRole,
  onTransferOwnership,
  onDelete,
}: Props) {
  const renderActions = () => {
    if (current) {
      return <Chip label={capitalize(member.role)} variant="outlined" />;
    }

    if (member.role === 'owner') {
      return <Chip label="Owner" variant="outlined" />;
    }

    return (
      <MemberActions
        member={member}
        current={current}
        permitEdit={permitEdit}
        permitTransfer={permitTransfer}
        teamName={teamName}
        hasPayment={hasPayment}
        onSetRole={onSetRole}
        onDelete={onDelete}
        onTransferOwnership={onTransferOwnership}
      />
    );
  };

  const name = member.pending ? 'Waiting accept from' : member.name;
  const email = member.email ? member.email : member.name;

  return (
    <Stack sx={sx} py={1} px={1} direction="row" alignItems="center" gap={1}>
      <Avatar src={member.picture}>
        <PersonIcon />
      </Avatar>

      <Box flex={1}>
        <Box>
          <Typography mb={0.25} fontWeight={600} component="span">
            <OverflowTooltip>{name}</OverflowTooltip>
          </Typography>
        </Box>

        <Box>
          <Typography variant="body2" component="span">
            <OverflowTooltip>{email}</OverflowTooltip>
          </Typography>
        </Box>
      </Box>

      {member.pending && <Chip label="Pending" variant="outlined" />}

      {current && <Chip label="You" variant="outlined" color="secondary" />}

      {renderActions()}
    </Stack>
  );
}
