import React, {useState} from 'react';
import {Prompt} from 'app/types/common';
import {Button, Dialog, DialogActions, DialogTitle} from '@mui/material';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {LoadingButton} from '@mui/lab';

interface Props extends Prompt {
  onDisconnect(): Promise<void>;
}

export function DisconnectChronoDialog({open, onDisconnect, onClose}: Props) {
  const [loading, setLoading] = useState(false);

  const handleDisconnect = async () => {
    try {
      setLoading(true);
      await onDisconnect();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true}>
      <DialogClose onClose={onClose} />

      <DialogTitle>Disconnect device from Epiphan Edge scheduling</DialogTitle>

      <DialogActions>
        <LoadingButton
          data-id="confirm-disconnect-button"
          variant="contained"
          color="error"
          loading={loading}
          onClick={handleDisconnect}
        >
          Disconnect
        </LoadingButton>

        <Button data-id="cancel-connect-button" variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
