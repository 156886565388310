import React, {Fragment, ReactElement, ReactNode} from 'react';
import classNames from 'classnames';
import {LoadingSpinner} from 'app/components/sharedReactComponents/LoadingWrapper/LoadingSpinner';

const ELEMENT_TYPE = {
  INPUT: 'input',
  TEXTAREA: 'textarea',
};

interface Props {
  loading?: boolean;
  children: ReactNode;
}

export function LoadingWrapper({children, loading = false}: Props) {
  if (React.Children.count(children) > 1) {
    return (
      <div className="loading-wrapper">
        {children}

        {loading && <LoadingSpinner />}
      </div>
    );
  }

  if (!React.isValidElement(children)) {
    return null;
  }

  const disabled = children.props.disabled || loading;

  if (children.type === ELEMENT_TYPE.INPUT || children.type === ELEMENT_TYPE.TEXTAREA) {
    return (
      <div className="loading-wrapper">
        {React.cloneElement(children as ReactElement<any>, {disabled})}

        {loading && <LoadingSpinner />}
      </div>
    );
  }

  return React.cloneElement(children as ReactElement<any>, {
    className: classNames('loading-wrapper', children.props.className),
    children: (
      <Fragment>
        {children.props.children}

        {loading && <LoadingSpinner />}
      </Fragment>
    ),
    disabled,
  });
}
