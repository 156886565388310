import React from 'react';
import {observer} from 'mobx-react';
import {useCurrentTeamStore} from 'app/store/hooks';
import {PairingFormData} from 'app/components/PairDevice/PairDeviceForm';
import {pairRealDevice} from 'app/components/PairDevice/actions';
import {PairOrCreateDeviceContent} from 'app/components/sharedReactComponents/PairOrCreateDeviceContent';
import {useCloudEntities} from 'app/components/entities/cloud';

export const PairDevicePage = observer(() => {
  const {
    user: {role},
  } = useCloudEntities();
  const team = useCurrentTeamStore();

  const teamId = team.id;
  const groupsAccess = team.getCapabilities().groups();

  const handleFormSubmit = async (form: PairingFormData) => {
    await pairRealDevice({
      currentTeamId: teamId,
      deviceId: form.code,
      deviceGroupId: form.groupId,
      deviceName: form.name,
      currentUserRole: role,
    });
  };

  return (
    <PairOrCreateDeviceContent
      teamId={teamId}
      canEditBilling={role.canEditBilling()}
      groupsAccess={groupsAccess}
      onSubmit={handleFormSubmit}
    />
  );
});
