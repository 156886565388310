import {isNil} from 'app/util/isNil';
import {BaseValidatorOptions} from 'app/util/validators/types';

class BaseValidator {
  protected messageProp: any;
  protected message = '';
  protected type = '';

  constructor({
    message,
  }: BaseValidatorOptions = {}) {
    if (isNil(message) === false) {
      this.messageProp = message;
    }
  }

  validate(_: any): boolean {
    throw new Error('Not implemented');
  }

  getErrorMessage(): string {
    return isNil(this.messageProp) ? this.message : this.messageProp;
  }

  getErrorType(): string {
    return this.type;
  }
}

export {BaseValidator};
