import {front} from 'app/api/CirrusApi';
import {AppNotificationsContract} from 'app/components/entities/notifications/model';

export class NotificationsApi {
  static async getNotifications(): Promise<AppNotificationsContract.Notification[]> {
    return front.users().me().notifications().get();
  }

  static async acceptOwnership(token: string): Promise<void> {
    return front.users().me().notifications(token).accept().post();
  }

  static async declineOwnership(token: string): Promise<void> {
    return front.users().me().notifications(token).decline().post();
  }
}
