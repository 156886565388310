import React from 'react';
import dayjs from 'dayjs';
import {Box, Link, Typography} from '@mui/material';
import {DataId, StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {gridSx} from 'app/components/entities/billing/files/ui/grid.styles';
import {FilesBilling, FilesBillingApi} from 'app/components/entities/billing/files';
import {formatPrice} from 'app/components/shared';

const rootSx: StyleSx = {px: 1, py: 1.5};

interface Props extends Sx, DataId {
  invoice: FilesBilling.ArchiveInvoice;
  teamId: string;
}

export function ArchiveInvoice({dataId, sx, invoice, teamId}: Props) {
  return (
    <Box data-id={dataId} sx={packSx(rootSx, gridSx, sx)}>
      <Typography data-id="invoice-date" variant="inherit">
        {dayjs.unix(invoice.date).format('LL')}
      </Typography>

      {invoice.ready ? (
        <Link data-id="invoice-number" href={FilesBillingApi.getInvoiceUrl(teamId, invoice.id)}>
          {invoice.number}
        </Link>
      ) : (
        <Typography data-id="invoice-number" variant="inherit">
          {invoice.number}
        </Typography>
      )}

      <Typography data-id="invoice-storage-size" variant="inherit" justifySelf="end">
        {invoice.storageSize.toLocaleString(undefined, {maximumFractionDigits: 2})} GB
      </Typography>

      <Typography data-id="invoice-storage-price" variant="inherit" justifySelf="end">
        ${formatPrice(invoice.storageFee)}
      </Typography>

      <Typography data-id="invoice-transfer-size" variant="inherit" justifySelf="end">
        {invoice.transferSize.toLocaleString(undefined, {maximumFractionDigits: 2})} GB
      </Typography>

      <Typography data-id="invoice-transfer-price" variant="inherit" justifySelf="end">
        ${formatPrice(invoice.transferSize)}
      </Typography>

      <Typography data-id="invoice-total" variant="inherit" justifySelf="end" fontWeight={600}>
        ${formatPrice(invoice.total)}
      </Typography>
    </Box>
  );
}
