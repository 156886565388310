import React, {PropsWithChildren, useContext, useMemo, useState} from 'react';
import {SrtSettingsForm} from 'app/components/pages/MeetingDetails/StreamSettings/SrtSettingsForm';
import {noop} from 'app/util/noop';
import {Settings} from 'app/components/pages/MeetingDetails/StreamSettings/StreamVideoSettings/StreamVideoSettings';

export const getDefaultConfiguration = () => {
  return new SrtSettingsForm({
    mode: 'listener',
    port: 14001,
    url: '',
    video: Settings.hd1080,
    audio: {
      bitrate: 96,
      format: 'AAC_48000',
      mode: 'stereo',
    },
    mute: {
      audio: false,
      video: false,
    },
    key: '',
    size: 0,
    latency: 24,
    protocol: 'SRT',
  });
};

interface SettingsFormState {
  form: SrtSettingsForm;
  setForm: (form: SrtSettingsForm) => void;
}

const SrtSettingsFormContext = React.createContext<SettingsFormState>({
  form: getDefaultConfiguration(),
  setForm: noop,
});

export const SrtSettingsFormProvider = ({children}: PropsWithChildren<{}>) => {
  const [form, setForm] = useState<SrtSettingsForm>(getDefaultConfiguration());

  const state = useMemo<SettingsFormState>(
    () => ({
      form,
      setForm,
    }),
    [form, setForm],
  );

  return (
    <SrtSettingsFormContext.Provider value={state}>{children}</SrtSettingsFormContext.Provider>
  );
};

export function useSrtSettings() {
  const state = useContext(SrtSettingsFormContext);

  if (!state) {
    throw new Error('Hook should be used inside StreamSettingsContext');
  }

  return state;
}

export function useStrSettingsForm() {
  const state = useSrtSettings();
  return state.form;
}

export function useParticipantSrtSettings() {}
