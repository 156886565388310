import React, {useState} from 'react';
import {Button, ButtonGroup, Popover, buttonGroupClasses} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import {
  DateCalendar,
  PickersDay,
  PickersDayProps,
  pickersMonthClasses,
  pickersYearClasses,
} from '@mui/x-date-pickers';
import dayjs, {Dayjs} from 'dayjs';
import {StyleSx, Sx} from 'app/types/common';
import {Schedule} from 'app/domain/schedule';
import {getCurrentPeriod} from 'app/components/sharedReactComponents/Events/utils';
import {usePeriodActions} from 'app/components/sharedReactComponents/Events/hooks/useSchedulePeriod';
import {
  getFirstDayOfPeriod,
  getLastDayOfPeriod,
} from 'app/components/sharedReactComponents/Events/Calendar/utils';
import {packSx} from 'app/util/packSx/packSx';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';

type DayProps = PickersDayProps<Dayjs> & {
  isSelected?: boolean;
  isHovered?: boolean;
};

function Day({day, isSelected, isHovered, ...props}: DayProps) {
  return (
    <PickersDay
      sx={(theme) => ({
        px: 2.5,
        borderRadius: 0,
        ...(isSelected && {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
          },
        }),
        ...(isHovered && {
          backgroundColor: theme.palette.primary[theme.palette.mode],
          '&:hover, &:focus': {
            backgroundColor: theme.palette.primary[theme.palette.mode],
          },
        }),
        ...(day.day() === 0 && {
          borderTopLeftRadius: '50%',
          borderBottomLeftRadius: '50%',
        }),
        ...(day.day() === 6 && {
          borderTopRightRadius: '50%',
          borderBottomRightRadius: '50%',
        }),
      })}
      day={day}
      {...props}
      selected={false}
      disableMargin={true}
      disableHighlightToday={true}
    />
  );
}

const calendarSx: StyleSx = {
  [`& .${pickersYearClasses.yearButton}, & .${pickersMonthClasses.monthButton}`]: {
    display: 'block',
  },
};

type PickerProps = Sx & {
  period: Schedule.Period;
  setPeriod: (period: Schedule.Period) => void;
};

const PeriodPicker = React.memo<PickerProps>(({sx, period, setPeriod}) => {
  const [hoveredDay, setHoveredDay] = React.useState<Dayjs | null>(null);
  const [selectedDay, setSelectedDay] = useState<Dayjs | null>(() =>
    dayjs().year(period.year).week(period.week).startOf('week'),
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const pickerOpen = Boolean(anchorEl);

  const handleSelect = (day: Dayjs | null) => {
    setSelectedDay(day);

    if (!day) {
      return;
    }

    setPeriod({week: day.week(), year: day.year()});
  };

  const firstDay = getFirstDayOfPeriod(period);
  const lastDay = getLastDayOfPeriod(period);

  const begin = firstDay.format('MMM D');
  const end = lastDay.format('MMM D');

  return (
    <>
      <Tooltip content="Select week">
        <Button
          data-id="pick-week-btn"
          sx={packSx({px: 1}, sx)}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {begin} - {end}, {lastDay.format('YYYY')}
        </Button>
      </Tooltip>

      <Popover
        open={pickerOpen}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <DateCalendar
          sx={calendarSx}
          showDaysOutsideCurrentMonth={true}
          value={selectedDay}
          views={['year', 'month', 'day']}
          minDate={dayjs().year(2022).startOf('year')}
          slots={{day: Day}}
          slotProps={{
            day: (props) => ({
              isSelected: props.day.isSame(selectedDay, 'week'),
              isHovered: props.day.isSame(hoveredDay, 'week'),
              onPointerEnter: () => setHoveredDay(props.day),
              onPointerLeave: () => setHoveredDay(null),
            }),
          }}
          onChange={(day, state) => {
            handleSelect(day);

            if (state === 'finish') {
              setAnchorEl(null);
            }
          }}
        />
      </Popover>
    </>
  );
});

type Props = Sx & {
  period: Schedule.Period;
  disabled?: boolean;
  setPeriod: React.Dispatch<React.SetStateAction<Schedule.Period>>;
};

export const PeriodSwitcher = React.memo<Props>(({sx, period, disabled, setPeriod}) => {
  const currentPeriod = getCurrentPeriod();
  const {goNext, goPrevious, goCurrent} = usePeriodActions(setPeriod);
  const isCurrent = currentPeriod.week === period.week && currentPeriod.year === period.year;

  return (
    <ButtonGroup sx={sx} variant="outlined" color="primary" disabled={disabled}>
      <Tooltip content="Previous week">
        <Button data-id="previous-week-btn" sx={{px: 1}}>
          <KeyboardArrowLeftIcon onClick={goPrevious} />
        </Button>
      </Tooltip>

      <PeriodPicker
        sx={{[`&.${buttonGroupClasses.grouped}`]: {minWidth: 150}}}
        period={period}
        setPeriod={setPeriod}
      />

      <Tooltip content="Next week">
        <Button data-id="previous-next-btn" sx={{px: 1}} onClick={goNext}>
          <KeyboardArrowRightIcon />
        </Button>
      </Tooltip>

      <Tooltip content="Return to current week">
        <Button
          data-id="current-week-btn"
          sx={{px: 1}}
          disabled={disabled || isCurrent}
          onClick={goCurrent}
        >
          <EventRepeatIcon />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
});
