import React, {Fragment} from 'react';
import classNames from 'classnames';
import {AlreadyUsedByDevice} from 'app/components/sharedReactComponents/ActionButtons/StreamingDestinationsSelector/AlreadyUsedByDevice';
import {ClassName} from 'app/types/common';

// rework any
interface Props extends ClassName {
  currentDevice: any;
  device: any;
}

export const AlreadySelectedOnDeviceNote: React.FC<Props> = ({
  className,
  currentDevice,
  device,
}) => {
  return (
    <div
      className={classNames('already-selected-on-device-note', className)}
      data-id="streaming_destination_already_selected_on"
    >
      {'Already selected on '}

      {device ? (
        <AlreadyUsedByDevice
          currentDevice={currentDevice}
          usedByDevice={device}
        />
      ) : (
        <Fragment> another device</Fragment>
      )}
    </div>
  );
};
