import {NumberTypeOptions} from 'app/components/DeviceDetails/Models/types';
import {BaseDeviceSettingModel} from 'app/models/Device/Settings/BaseDeviceSettingModel';

export class VideoBitrateDeviceSettingModel extends BaseDeviceSettingModel {
  _formatValue() {
    const value = this.getValue();

    if (value === '' || value === 0) {
      const options = this.getOptions() as NumberTypeOptions;

      return options?.placeholder;
    }

    return `${value} kbps`;
  }
}
