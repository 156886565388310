import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';
import {useMounted} from 'app/hooks/useIsMounted';
import {isNil} from 'app/util/isNil';

const scheme = z.object({
  name: z.string().trim().min(1, {message: 'Required field'}),
});

type Form = z.infer<typeof scheme>;

type Props = {
  name: string;
  loading: boolean;
  onRename: (name: string) => Promise<void>;
  onClose: () => void;
};

function RenameGroup({name, loading, onRename, onClose}: Props) {
  const {
    control,
    formState: {isValid},
    watch,
    handleSubmit,
  } = useForm<Form>({
    defaultValues: {name},
    mode: 'onChange',
    resolver: zodResolver(scheme),
  });

  const inputName = watch('name');

  const handleCreate = async ({name}: Form) => {
    await onRename(name);
  };

  return (
    <Box>
      <DialogTitle>Rename group</DialogTitle>

      <DialogContent>
        <Stack component="form" id="rename-group" onSubmit={handleSubmit(handleCreate)}>
          <Controller
            name="name"
            control={control}
            render={({field, fieldState: {error}}) => (
              <TextField
                {...field}
                autoFocus={true}
                inputProps={{'data-id': 'group-name-input'}}
                label="Name"
                variant="standard"
                fullWidth={true}
                error={!isNil(error)}
                helperText={error?.message ?? ' '}
              />
            )}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="confirm-rename-btn"
          type="submit"
          variant="contained"
          color="secondary"
          form="rename-group"
          disabled={inputName === name || !isValid}
          loading={loading}
        >
          Rename
        </LoadingButton>

        <Button data-id="cancel-rename-btn" variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
}

type PromptProps = Prompt & Omit<Props, 'loading'>;

export function RenameGroupDialog({name, open, onClose, onRename}: PromptProps) {
  const mounted = useMounted();
  const [loading, setLoading] = useState(false);

  const handleRename = async (name: string) => {
    try {
      setLoading(true);
      await onRename(name);
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog maxWidth="xs" fullWidth={true} open={open} onClose={onClose}>
      <RenameGroup name={name} loading={loading} onRename={handleRename} onClose={onClose} />
    </Dialog>
  );
}
