import React from 'react';
import {Box, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {useFilesInvoicesQuery} from 'app/components/entities/billing/files';
import {FilesInvoice, InvoicesArchive} from 'app/components/features/billing/files';
import {isNil} from 'app/util/isNil';
import {InvoicesSkeleton} from 'app/components/entities/billing/base';

interface Props extends Sx {
  teamId: string;
}

export function FilesUsage({sx, teamId}: Props) {
  const {data, isSuccess} = useFilesInvoicesQuery({teamId});

  if (!isSuccess) {
    return <InvoicesSkeleton sx={sx} />;
  }

  return (
    <Box sx={sx}>
      {!isNil(data.upcoming) && <FilesInvoice sx={{mb: 3}} invoice={data.upcoming} />}

      <Typography fontWeight={600} fontSize={16} mb={1}>
        Invoice history
      </Typography>

      {data.history.length === 0 ? (
        <Typography my={2} fontWeight={600} color="text.disabled" textAlign="center">
          No previous invoices
        </Typography>
      ) : (
        <InvoicesArchive teamId={teamId} invoices={data.history} />
      )}
    </Box>
  );
}
