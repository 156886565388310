import {render, unmountComponentAtNode} from 'react-dom';
import React, {createElement, FC, ReactElement, ReactNode, VFC} from 'react';
import {ModalComponent, ModalComponentScroll} from 'app/components/sharedReactComponents/Modal/ModalComponent';
import {Button} from 'app/components/sharedReactComponents/Button';
import {
  SIZE,
  THEME,
  VARIANT,
} from 'app/constants';
import {PORTAL_ELEMENT_ID} from 'app/constants/portalElementId';
import classNames from 'classnames';
import {Callback, ClassName} from 'app/types/common';

type ModalShowProps = {
  className?: string;
  children: ReactNode;
  fullWidth?: boolean;
  maxWidth?: SIZE;
  scroll?: ModalComponentScroll;
  showCloseButton?: boolean;
  shouldCloseOnEsc?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  onClose?: Callback;
};

const Modal = {
  show({
    className,
    children,
    fullWidth,
    maxWidth,
    scroll,
    showCloseButton,
    shouldCloseOnEsc = true,
    shouldCloseOnOverlayClick = true,
    onClose,
  }: ModalShowProps) {
    render(createElement(ModalComponent, {
      className,
      fullWidth,
      maxWidth,
      scroll,
      showCloseButton,
      shouldCloseOnEsc,
      shouldCloseOnOverlayClick,
      onClose: () => {
        if (onClose) {
          onClose();
        }

        Modal.hide();
      },
    }, children), document.querySelector(`#${PORTAL_ELEMENT_ID.MODAL}`));
  },

  hide() {
    const element = document.querySelector(`#${PORTAL_ELEMENT_ID.MODAL}`);
    if (element) {
      unmountComponentAtNode(element);
    }
  },
};

window.addEventListener('hashchange', Modal.hide);

const ModalContainer: FC<ClassName> = ({
  className,
  children,
}) => {
  return (
    <div className={classNames('cr-modal-new__container', className)}>
      {children}
    </div>
  );
};

type ModalTitleProps = ClassName & {
  icon?: ReactElement;
};

const ModalTitle: FC<ModalTitleProps> = ({
  className,
  children,
  icon,
}) => {
  return (
    <header className={classNames('cr-modal-new__title', className)}>
      {icon && (
        <div className="cr-modal-new__title-icon">
          {icon}
        </div>
      )}
      {children}
    </header>
  );
};

const ModalTitleHighlightedItem: FC = ({children}) => {
  return (
    <span className="cr-modal-new__title-highlighted-item">
      {children}
    </span>
  );
};

const ModalBody: FC<ClassName> = ({
  className,
  children,
}) => {
  return (
    <div className={classNames('cr-modal-new__body', className)}>
      {children}
    </div>
  );
};

const ModalScrollableContentWrapper: FC = ({children}) => {
  return (
    <div className="cr-modal-new__scrollable-content-wrapper">
      {children}
    </div>
  );
};

const ModalFooter: FC<ClassName> = ({
  className,
  children,
}) => {
  return (
    <footer className={classNames('cr-modal-new__footer', className)}>
      {children}
    </footer>
  );
};

interface ButtonProps {
  autoFocus?: boolean;
  disabled?: boolean;
}

const ModalCancelButton: VFC<ButtonProps> = ({
  autoFocus,
  disabled,
}) => {
  return (
    <Button
      dataId="modal_cancel_button"
      theme={THEME.SECONDARY}
      variant={VARIANT.OUTLINE}
      autoFocus={autoFocus}
      disabled={disabled}
      onClick={Modal.hide}
    >
      Cancel
    </Button>
  );
};

interface CloseButtonProps extends ButtonProps {
  theme?: THEME;
  variant?: VARIANT;
}

const ModalCloseButton: VFC<CloseButtonProps> = ({
  autoFocus,
  disabled,
  theme = THEME.PRIMARY,
  variant = VARIANT.SOLID,
}) => {
  return (
    <Button
      dataId="modal_close_button"
      theme={theme}
      variant={variant}
      autoFocus={autoFocus}
      disabled={disabled}
      onClick={Modal.hide}
    >
      Close
    </Button>
  );
};

export {
  Modal,
  ModalContainer,
  ModalTitle,
  ModalTitleHighlightedItem,
  ModalBody,
  ModalScrollableContentWrapper,
  ModalFooter,
  ModalCancelButton,
  ModalCloseButton,
};
