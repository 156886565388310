import React, {useState} from 'react';
import {Sx} from 'app/types/common';
import {
  Box,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {joiResolver} from '@hookform/resolvers/joi';
import {grey} from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import Joi from 'joi';
import {Controller, useForm} from 'react-hook-form';
import {router} from 'app/router/main';
import {palette} from 'app/themes/app';
import {BUTTON_TYPE, ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {THEME, VARIANT} from 'app/constants';
import {isNil} from 'app/util/isNil';
import {useMounted} from 'app/hooks/useIsMounted';

export interface ZoomIdentityArgs {
  id: string;
  identity: App.Connect.Auth.ZoomId;
}

interface FormData {
  identity: App.Connect.Auth.ZoomId;
}

const scheme = Joi.object<FormData>({
  identity: Joi.string().required().invalid('none'),
}).messages({
  'any.required': 'Required field',
  'string.empty': 'Required field',
});

interface Props extends Sx {
  args: ZoomIdentityArgs;
  accounts: App.Connect.Auth.ZoomAccount[];
  onApply: Fn<Promise<void>, [id: string, account: string]>;
}

export function ZoomIdentity({sx, args, accounts, onApply}: Props) {
  const {
    control,
    formState: {isValid},
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {identity: accounts.find((a) => a.id === args.identity)?.id ?? 'none'},
    resolver: joiResolver(scheme),
  });

  const isMounted = useMounted();

  const [handling, setHandling] = useState(false);

  const handleApply = async (data: FormData) => {
    setHandling(true);

    try {
      await onApply(args.id, data.identity);
    } finally {
      if (isMounted()) {
        setHandling(false);
      }
    }
  };

  return (
    <Box p={7} sx={sx}>
      <form onSubmit={handleSubmit(handleApply)}>
        <Box sx={{mb: 3}}>
          <img
            src="/assets/img/logo/zoom-logo.png"
            srcSet="/assets/img/logo/zoom-logo@2x.png 2x"
            alt=""
          />
        </Box>

        <Typography variant="h5" fontWeight="bold">
          This meeting only allows authenticated users.
        </Typography>

        <Box sx={{mt: 1, mb: 5, color: grey[500]}}>
          This meeting only allows authenticated users to join or your selected Zoom identity wasn't
          accepted. Either select a different Zoom identity or ask the Zoom meeting host to change
          meeting settings to allow anonymous participants. <Link href="ss">Read more</Link>
        </Box>

        <Stack my={1} direction="row" gap={0.5} alignItems="center">
          <Typography sx={{fontSize: 16, fontWeight: 'bold'}}>Zoom identity:</Typography>
        </Stack>

        <Controller
          name="identity"
          control={control}
          render={({field: {onChange, ...props}, fieldState: {error}}) => (
            <TextField
              {...props}
              variant="standard"
              autoFocus={true}
              select={true}
              fullWidth={true}
              helperText={error?.message ?? ' '}
              error={!isNil(error)}
              inputProps={{
                'data-id': 'zoom-account',
              }}
              InputProps={{sx: {width: 230}}}
              onChange={(e) => {
                const {value} = e.target;
                if (value === 'manage') {
                  router.go('connectAccountSettings', {}, {tab: 'zoom'});
                  return;
                }

                onChange(e);
              }}
            >
              <MenuItem value="none">
                <em>Select identity</em>
              </MenuItem>
              {accounts.map((acc) => (
                <MenuItem key={acc.id} value={acc.id}>
                  {acc.name}
                </MenuItem>
              ))}

              <MenuItem value="manage" sx={{color: palette.blue}}>
                <ListItemIcon sx={{color: 'inherit'}}>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText>Manage identities</ListItemText>
              </MenuItem>
            </TextField>
          )}
        />

        <Box mt={5} sx={{'& button': {width: 154}}}>
          <ProgressButton
            disabled={!isValid}
            loading={handling}
            type={BUTTON_TYPE.SUBMIT}
            dataId="apply-identity-btn"
            theme={THEME.PRIMARY}
            variant={VARIANT.SOLID}
          >
            Apply
          </ProgressButton>
        </Box>
      </form>
    </Box>
  );
}
