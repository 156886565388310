import React, {useCallback} from 'react';
import {OutputItem} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Output/OutputItem';
import {SettingsList} from 'app/components/sharedReactComponents/SettingsList';
import {isNil} from 'app/util/isNil';
import {Callback, ClassName} from 'app/types/common';
import {LiveScryptDeviceModel} from 'app/components/DeviceDetails/Models/LiveScryptDeviceModel';
import {useDeviceSettingsCollection} from 'app/hooks/Device/useDeviceSettingsCollection';

interface Props extends ClassName {
  device: LiveScryptDeviceModel;
  updateSettingAction: Callback;
}

export const OutputWebStream: React.VFC<Props> = ({
  className,
  device,
  updateSettingAction,
}) => {
  const updateTranscribingURLAction = useCallback(async (_id, value) => {
    return device.setTranscribingURLUserDefined(value);
  }, [device]);

  const deviceSettingsCollection = useDeviceSettingsCollection(device);

  if (!deviceSettingsCollection) {
    return null;
  }

  const webStreamOutputSettings = deviceSettingsCollection.getWebStreamOutputSettings();
  const transcribingURL = device.getTranscribingURL();
  const disabled = device.isOffline();

  return (
    <OutputItem
      className={className}
      title="Web stream"
      settings={isNil(webStreamOutputSettings) ? [] : webStreamOutputSettings.getSettings()}
      disabled={disabled}
      updateSettingAction={updateSettingAction}
    >
      <SettingsList
        settings={[
          {
            id: 'ws_url',
            title: 'Stream url',
            value: transcribingURL.userDefined,
            type: {
              name: 'stream_url',
              options: {
                base: transcribingURL.base,
              },
            },
          }
        ]}
        disabled={disabled}
        updateSettingAction={updateTranscribingURLAction}
      />
    </OutputItem>
  );
};
