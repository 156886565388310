import React from 'react';
import {Skeleton} from '@mui/material';
import {Sx} from 'app/types/common';
import {DevicePerformance} from 'app/components/features/edge/EdgeDevice/PerformanceIndicator/DevicePerformance/DevicePerformance';
import {useSystemInfo} from 'app/components/DeviceDetails/DeviceInfoTab/SystemInformation/useSystemInfo';

type Props = Sx & {
  deviceId: string;
  enabled: boolean;
};

export function PerformanceIndicator({sx, deviceId, enabled}: Props) {
  const query = useSystemInfo({
    deviceId,
    enabled,
    staleTime: 30 * 1000,
    refetchInterval: 60 * 1000,
  });

  if (enabled && query.isLoading) {
    return <Skeleton sx={sx} variant="rounded" height={31} width={132} />;
  }

  if (query.isSuccess && query.data) {
    return <DevicePerformance sx={sx} system={query.data} />;
  }

  return null;
}
