import React from 'react';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {ClassName} from 'app/types/common';
import {Stack} from '@mui/material';
import {grey} from '@mui/material/colors';

interface Props extends ClassName {
  type: 'streaming' | 'recording';
  active: boolean;
  size?: SIZE;
}

export function EventMediaIcon({active, type, size = SIZE.S}: Props) {
  const isStreaming = type === 'streaming';
  const icon = isStreaming ? Icons.streaming() : Icons.record();

  return (
    <Stack
      sx={{
        color: active ? (isStreaming ? 'primary.main' : 'error.main') : grey[400],
      }}
    >
      {icon.size(size).reactComponent()}
    </Stack>
  );
}
