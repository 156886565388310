import {useCallback, useEffect, useRef, useState} from 'react';
import {FilterSwitch} from 'app/components/sharedReactComponents/FilterSelector/types';
import {toggleInSet} from 'app/util/toggleInSet';
import {Callback} from 'app/types/common';
import {FilterStoreManager} from 'app/components/FleetManager/FilterStoreManager';

interface Storage {
  key: string;
  userId: string;
  teamId: string;
}

interface Args<T extends FilterSwitch> {
  options: T[];
  storage?: Storage;
}

interface Return<T extends FilterSwitch> {
  filters: Set<string>;
  active: T[];
  options: T[];
  clear: Callback;
  toggle: Callback<void, [string]>;
}

export function useFilterFeature<T extends FilterSwitch>({
  options,
  storage,
}: Args<T>): Return<T> {
  const [filters, setFilters] = useState(new Set<string>());
  const [active, setActive] = useState<T[]>([]);

  const store = useRef<FilterStoreManager>();

  useEffect(() => {
    if (storage?.key && storage.teamId && storage.userId) {
      store.current = new FilterStoreManager({
        storeKey: storage.key,
        teamId: storage.teamId,
        userId: storage.userId,
      });

      const persisted = store.current.loadActiveFiltersSet();
      setFilters(persisted);
      return;
    }

    store.current = undefined;
  }, [storage?.key, storage?.teamId, storage?.userId]);

  const toggle = useCallback(
    (id: string) => {
      setFilters(
        prev => {
          const next = new Set(prev);
          return toggleInSet(next, id);
        });
    },
    [],
  );

  useEffect(() => {
    setActive(getActiveFilters(options, filters));
  }, [filters, options]);

  useEffect(() => {
    if (store.current) {
      store.current.saveActiveFiltersSet(filters);
    }
  }, [filters]);

  const clear = useCallback(() => setFilters(new Set()), []);

  return {
    active,
    filters,
    options,
    clear,
    toggle,
  };
}

function getActiveFilters<T extends FilterSwitch>(
  options: T[],
  filters: Set<string>,
) {
  return options.filter(option => filters.has(option.id));
}
