import {BaseProvider} from 'app/util/OAuthProvider/BaseProvider';
import {OAuthProviderOptions} from 'app/util/OAuthProvider/types';

class BoxProvider extends BaseProvider {
  constructor(options: OAuthProviderOptions) {
    super(options);

    this.name = 'Box';
    this.windowWidth = 600;
    this.windowHeight = 640;
  }
}

export {BoxProvider};
