import {useReducer} from 'react';
import {produce} from 'immer';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {
  ChannelAction,
  Destination,
  DestinationType,
  ChannelFields,
  DeviceConfiguration,
} from 'app/components/sharedReactComponents/Events/Editor/EventForm/types';
import {ReducerActionWithData} from 'app/types/common';

type ActionTypes = 'add' | 'delete' | 'channel-action' | 'streaming-destination' | 'instance';
type DeviceActionWithData<T extends ActionTypes, P extends any> = ReducerActionWithData<T, P>;

type AddDeviceAction = DeviceActionWithData<'add', DeviceConfiguration>;
type DeleteDeviceAction = DeviceActionWithData<'delete', {id: string}>;
type SetChannelAction = DeviceActionWithData<
  'channel-action',
  {deviceId: string; channelIdx: string; type: ChannelAction; value: boolean}
>;
type SelectDestinationAction = DeviceActionWithData<
  'streaming-destination',
  {
    deviceId: string;
    channelIdx: string;
    type: DestinationType;
    value: string;
  }
>;

type Action = AddDeviceAction | DeleteDeviceAction | SetChannelAction | SelectDestinationAction;

interface Args {
  devicesConfig: Map<string, Map<string, ChannelFields>>;
  connectedDevices: Map<string, PearlMasterDeviceModel>;
  destinations: Destination[];
}

export function useDeviceConfiguration({devicesConfig, connectedDevices, destinations}: Args) {
  const state = useReducer(produce(producer), null, () => {
    const result = new Map<string, DeviceConfiguration>();

    Array.from(devicesConfig.entries()).forEach(([deviceId, channelProfiles]) => {
      const instance = connectedDevices.get(deviceId);

      if (instance) {
        const channels = instance.getChanelModels();
        const states = new Map<string, ChannelFields>();

        channels.forEach((c) => {
          const channelIdx = c.getChannelDeviceIdIndex();
          const profile = channelProfiles.get(channelIdx);

          if (profile) {
            const destination = destinations.find((d) => d.id === profile.destination);
            states.set(channelIdx, {
              recording: profile.recording,
              streaming: profile.streaming,
              publishers: [...profile.publishers],
              destination: destination?.id ?? '',
            });
          } else {
            states.set(channelIdx, {
              recording: false,
              streaming: false,
              publishers: [],
              destination: '',
            });
          }
        });

        const meta: DeviceConfiguration = {device: instance, state: states};
        result.set(deviceId, meta);
      }
    });

    return result;
  });

  return state;
}

function producer(draft: Map<string, DeviceConfiguration>, action: Action): void {
  switch (action.type) {
    case 'channel-action': {
      const {deviceId, channelIdx, type, value} = action.payload;
      const deviceMeta = draft.get(deviceId);

      if (deviceMeta) {
        const channelMeta = deviceMeta.state.get(channelIdx);

        if (channelMeta) {
          if (type === 'recording') {
            channelMeta.recording = value;
          } else {
            channelMeta.streaming = value;
          }
        }
      }
      break;
    }

    case 'delete': {
      const {id} = action.payload;
      draft.delete(id);
      break;
    }

    case 'add': {
      const {device} = action.payload;
      const channels = device.getChanelModels();

      const state = new Map<string, ChannelFields>();

      channels.forEach((c) => {
        state.set(c.getChannelDeviceIdIndex(), {
          streaming: false,
          recording: true,
          destination: '',
          publishers: [],
        });
      });

      draft.set(device.getId(), {device, state});
      break;
    }

    case 'streaming-destination': {
      const {deviceId, channelIdx, type, value} = action.payload;

      const deviceMeta = draft.get(deviceId);

      if (deviceMeta) {
        const channelMeta = deviceMeta.state.get(channelIdx);

        if (channelMeta) {
          switch (type) {
            case 'destination': {
              if (channelMeta.destination === value) {
                channelMeta.destination = '';
              } else {
                channelMeta.destination = value;
              }
              break;
            }

            case 'publisher': {
              if (channelMeta.publishers.includes(value)) {
                channelMeta.publishers = channelMeta.publishers.filter((p) => p !== value);
              } else {
                channelMeta.publishers.push(value);
              }
              break;
            }

            default:
              break;
          }

          const hasSelected = channelMeta.publishers.length > 0 || Boolean(channelMeta.destination);

          channelMeta.streaming = hasSelected;
        }
      }
      break;
    }

    default:
      break;
  }
}
