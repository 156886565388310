const TOKEN_ERROR = {
  EMPTY_NAME: 'empty_name',
  UNIQUE_NAME: 'unique_name',
  RENAME_FAIL: 'rename_fail',
};

const ERROR_TEXT = {
  [TOKEN_ERROR.EMPTY_NAME]: 'Please describe token',
  [TOKEN_ERROR.UNIQUE_NAME]: 'Token with this name already exist',
  [TOKEN_ERROR.RENAME_FAIL]: 'There was an error with your rename request',
};

export {
  TOKEN_ERROR,
  ERROR_TEXT,
};
