import {useEffect} from 'react';
import {WSBaseDto} from 'app/api/WebSocket/types';
import {createStampedImageSrc} from 'app/components/sharedReactComponents/StatusImage/utils';
import {Callback} from 'app/types/common';
import {useDeviceStatusStartCommand} from 'app/hooks/Device/useDeviceStatusStartCommand';

interface Params {
  baseSrc: string;
  disabled: boolean;
  deviceId: string;
  sourceId?: string;
  command: Callback<Promise<any>>;
  setSrc: Callback<void, [string]>;
  listener: Callback<void>;
  disposer: Callback<void>;
}

export function useUpdatePreviewByWebsocket({
  deviceId,
  sourceId,
  command,
  disabled,
  baseSrc,
  setSrc,
  listener,
  disposer,
}: Params) {
  useDeviceStatusStartCommand({
    command,
    disabled,
  });

  useEffect(() => {
    if (disabled) {
      return;
    }

    function socketHandler(data: WSBaseDto) {
      setSrc(createStampedImageSrc(baseSrc, data.Timestamp));
    }

    listener(socketHandler, deviceId, sourceId);

    return () => disposer(socketHandler, deviceId, sourceId);
  }, [disabled, baseSrc, deviceId, sourceId, listener, disposer, setSrc]);
}
