import React from 'react';
import {DevicesAlertsSidebarAlert} from 'app/components/DevicesAlerts/DevicesAlertsSidebar/DevicesAlertsSidebarAlert';
import {Button} from 'app/components/sharedReactComponents/Button';
import {VARIANT} from 'app/constants';
import {DateAlerts} from 'app/components/DevicesAlerts/types';
import {timestampFromNow} from 'app/util/time';
import {capitalize} from 'app/components/helpers/commonHelpers';

interface Props {
  group: DateAlerts;
  onAcknowledgeAlerts: (timestamp: number) => Promise<void>;
}

export function DevicesAlertsSidebarDay({group, onAcknowledgeAlerts}: Props) {
  const handleDismissAll = async () => {
    const {alerts} = group;
    const firsAlert = alerts[0];

    if (firsAlert) {
      await onAcknowledgeAlerts(firsAlert.timestamp);
    }
  };

  return (
    <div className="devices-alerts-sidebar-day">
      <div className="devices-alerts-sidebar-day__header">
        <div className="devices-alerts-sidebar-day__date">
          {capitalize(timestampFromNow(group.date))}
        </div>

        <Button
          className="devices-alerts-sidebar-day__dismiss_all"
          variant={VARIANT.TEXT}
          onClick={handleDismissAll}
        >
          Dismiss All
        </Button>
      </div>

      {group.alerts.map((alert, index) => (
        <DevicesAlertsSidebarAlert key={index} alert={alert} />
      ))}
    </div>
  );
}
