import {UserTeamStore} from 'app/util/UserTeamStore';

const STORE_KEY = 'FleetManagerPage.Filters.Active';

interface FilterStoreManagerProps {
  teamId: string;
  userId: string;
  storeKey: string;
}

export class FilterStoreManager {
  private readonly store: UserTeamStore;

  constructor({
    teamId,
    userId,
    storeKey = STORE_KEY,
  }: FilterStoreManagerProps) {
    this.store = new UserTeamStore({
      defaultValue: [],
      storeKey,
      teamId,
      userId,
    });
  }

  loadActiveFiltersSet(): Set<string> {
    return new Set(this.store.load());
  }

  saveActiveFiltersSet(activeFilterSwitches: Set<string>) {
    this.store.save(Array.from(activeFilterSwitches));
  }
}
