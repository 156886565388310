import React, {useEffect, useState} from 'react';
import {Button, ButtonProps, Divider, Popover, PopoverOrigin, Stack, Tooltip} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import ContactlessIcon from '@mui/icons-material/Contactless';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import TodayIcon from '@mui/icons-material/Today';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {StyleSx, Sx} from 'app/types/common';
import {OnlineIndicator} from 'app/components/sharedReactComponents/OnlineIndicator';
import {SIZE} from 'app/constants';
import {UpgradeToEdgePremium} from 'app/components/sharedReactComponents/UpgradeToEdgePremium/UpgradeToEdgePremium';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {useDevicesSummary} from 'app/components/FleetManager/hooks/useDevicesStats';
import {packSx} from 'app/util/packSx/packSx';
import {useSelectAllWithKeyboard} from 'app/components/FleetManager/useSelectAllWithKeyboard';
import {pluralizeN} from 'app/components/helpers/commonHelpers';

const selectAllSx: StyleSx = {ml: 'auto', minWidth: 94, justifyContent: 'flex-start'};
const iconButtonSx: StyleSx = {minWidth: 24};

type Props = Sx & {
  devices: AnyDeviceModelType[];
  premium: boolean;
  billingAccess: boolean;
  allSelected: boolean;
  eventsCount: number;
  groupsCount: number;
  onSelectDevice: (fn: (device: AnyDeviceModelType) => boolean) => void;
  onSelectGroups: () => void;
  onSelectWithEvents: () => void;
  onSelectAll: () => void;
};

export function SelectionToolbar({
  devices,
  sx,
  billingAccess,
  premium,
  allSelected,
  eventsCount,
  groupsCount,
  onSelectDevice,
  onSelectGroups,
  onSelectWithEvents,
  onSelectAll,
}: Props) {
  const stats = useDevicesSummary(devices);

  useSelectAllWithKeyboard({onSelect: onSelectAll});

  const handleSelectOnline = () => onSelectDevice((device) => device.isOnline());

  const handleSelectOffline = () => onSelectDevice((device) => device.isOffline());

  const handleSelectActive = () =>
    onSelectDevice(
      (device) => device.isRecording() || device.isStreaming() || device.isTranscribing(),
    );

  const handleSelectRecording = () => onSelectDevice((device) => device.isRecording());

  const handleSelectStreaming = () => onSelectDevice((device) => device.isStreaming());

  return (
    <Stack
      sx={sx}
      direction="row"
      alignItems="center"
      gap={1}
      divider={<Divider sx={{height: 14}} orientation="vertical" />}
    >
      {stats.online > 0 && (
        <SelectButton
          data-id="select_online_devices_button"
          billingAccess={billingAccess}
          premium={premium}
          startIcon={<OnlineIndicator online={true} size={SIZE.S} />}
          variant="text"
          color="info"
          size="small"
          disableRipple={false}
          tooltip="Select all online devices"
          onClick={handleSelectOnline}
        >
          {stats.online} {pluralizeN('device', stats.online)} online
        </SelectButton>
      )}

      {stats.offline > 0 && (
        <SelectButton
          data-id="select_offline_devices_button"
          billingAccess={billingAccess}
          premium={premium}
          startIcon={<OnlineIndicator online={false} size={SIZE.S} />}
          variant="text"
          color="info"
          size="small"
          disableRipple={false}
          tooltip="Select all offline devices"
          onClick={handleSelectOffline}
        >
          {stats.offline} {pluralizeN('device', stats.offline)} offline
        </SelectButton>
      )}

      {groupsCount > 0 && (
        <SelectButton
          data-id="select_all_groups_button"
          billingAccess={billingAccess}
          premium={premium}
          startIcon={<FolderIcon color="info" fontSize="small" />}
          variant="text"
          color="info"
          size="small"
          disableRipple={false}
          tooltip="Select all groups"
          onClick={onSelectGroups}
        >
          {groupsCount} {pluralizeN('group', groupsCount)}
        </SelectButton>
      )}

      {stats.active > 0 && (
        <SelectButton
          data-id="select_all_events_devices_button"
          billingAccess={billingAccess}
          premium={premium}
          variant="text"
          color="info"
          size="small"
          disableRipple={false}
          tooltip="Select all streaming, recording and transcribing devices"
          onClick={handleSelectActive}
        >
          {stats.active} active {pluralizeN('device', stats.active)}
        </SelectButton>
      )}

      {stats.recording > 0 && (
        <SelectButton
          data-id="select_all_recording_button"
          sx={iconButtonSx}
          billingAccess={billingAccess}
          premium={premium}
          startIcon={<ModeStandbyIcon color="error" fontSize="small" />}
          variant="text"
          color="info"
          size="small"
          disableRipple={false}
          tooltip="Select all recording devices"
          onClick={handleSelectRecording}
        >
          {stats.recording}
        </SelectButton>
      )}

      {stats.streaming > 0 && (
        <SelectButton
          data-id="select_all_streaming_button"
          sx={iconButtonSx}
          billingAccess={billingAccess}
          premium={premium}
          startIcon={<ContactlessIcon color="primary" fontSize="small" />}
          variant="text"
          color="info"
          size="small"
          disableRipple={false}
          tooltip="Select all streaming devices"
          onClick={handleSelectStreaming}
        >
          {stats.streaming}
        </SelectButton>
      )}

      {eventsCount > 0 && (
        <SelectButton
          data-id="select_devices_ongoing_events_button"
          sx={iconButtonSx}
          billingAccess={billingAccess}
          premium={premium}
          startIcon={<TodayIcon color="error" fontSize="small" />}
          variant="text"
          color="info"
          size="small"
          disableRipple={false}
          tooltip="Select all devices with ongoing events"
          onClick={onSelectWithEvents}
        >
          {eventsCount}
        </SelectButton>
      )}

      <Stack direction="row" alignItems="center" gap={0.5} ml="auto">
        <SelectButton
          data-id="select_all_devices"
          sx={selectAllSx}
          billingAccess={billingAccess}
          premium={premium}
          startIcon={
            allSelected ? (
              <CheckBoxIcon color="info" fontSize="small" />
            ) : (
              <CheckBoxOutlineBlankIcon color="info" fontSize="small" />
            )
          }
          variant="outlined"
          color="info"
          size="small"
          disableRipple={false}
          tooltip="Select all devices and groups"
          onClick={onSelectAll}
        >
          {allSelected ? 'Deselect' : 'Select all'}
        </SelectButton>
      </Stack>
    </Stack>
  );
}

type SelectButtonProps = ButtonProps & {
  premium: boolean;
  billingAccess: boolean;
  tooltip?: string;
};

const buttonSx: StyleSx = {fontSize: 12};
const paperSx: StyleSx = {p: 2};
const anchorOrigin: PopoverOrigin = {vertical: 'bottom', horizontal: 'center'};
const transformOrigin: PopoverOrigin = {vertical: 'top', horizontal: 'center'};

function SelectButton({sx, premium, billingAccess, tooltip, onClick, ...props}: SelectButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (premium) {
      setAnchorEl(null);
    }
  }, [premium]);

  return (
    <>
      <Tooltip title={tooltip}>
        <Button
          sx={packSx(buttonSx, sx)}
          {...props}
          onClick={(e) => {
            if (!premium) {
              setAnchorEl(e.currentTarget);
              return;
            }

            onClick?.(e);
          }}
        />
      </Tooltip>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        slotProps={{paper: {sx: paperSx}}}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={() => setAnchorEl(null)}
      >
        <UpgradeToEdgePremium billingAccess={billingAccess} />
      </Popover>
    </>
  );
}
