import {makeAutoObservable} from 'mobx';
import {Participant} from 'app/store/models/connect/Participant/Participant';
import {Callback, TimeStampSeconds} from 'app/types/common';
import {arrayToMap} from 'app/util/arrayToMap';
import {ReturnFeed} from 'app/store/models/connect/ReturnFeed/ReturnFeed';

export interface MeetingArgs {
  id: string;
  name: string;
  tenantName: string;
  tenantId: string;
  platform: App.Connect.Platform;
  permission?: App.Connect.Permission;
  url: string;
  host?: string;
  status: App.Connect.MeetingStatus;
  createdAt: TimeStampSeconds;
  startedAt?: TimeStampSeconds;
  disconnectedAt?: TimeStampSeconds;
  deletedAt?: TimeStampSeconds;
  isolatedAudio: boolean;
  error: string;
  version?: string;
  settings?: App.Connect.MeetingSettings;
  externalId?: string;
}

interface SrtEntity {
  id: string;
  name: string;
  port: number;
}

export type Entity = Participant | ReturnFeed;

export class Meeting implements MeetingArgs {
  id: string;
  name: string;
  url: string;
  host?: string;
  platform: App.Connect.Platform;
  status: App.Connect.MeetingStatus;
  permission?: App.Connect.Permission;
  createdAt: TimeStampSeconds;
  startedAt?: TimeStampSeconds;
  disconnectedAt?: TimeStampSeconds;
  deletedAt?: TimeStampSeconds;
  isolatedAudio: boolean;
  error: string;
  tenantId: string;
  tenantName: string;
  version?: string;
  settings?: App.Connect.MeetingSettings;
  feed?: ReturnFeed;
  externalId?: string;

  private members: Map<string, Participant> = new Map();

  constructor(data: MeetingArgs, participants: Participant[] = [], feed?: ReturnFeed) {
    this.id = data.id;
    this.name = data.name;
    this.permission = data.permission;
    this.status = data.status;
    this.host = data.host;
    this.url = data.url;
    this.platform = data.platform;
    this.isolatedAudio = data.isolatedAudio;
    this.createdAt = data.createdAt;
    this.startedAt = data.startedAt;
    this.disconnectedAt = data.disconnectedAt;
    this.deletedAt = data.deletedAt;
    this.error = data.error;
    this.tenantName = data.tenantName;
    this.tenantId = data.tenantId;
    this.members = arrayToMap(participants, 'id');
    this.version = data.version;
    this.settings = data.settings;
    this.feed = feed;
    this.externalId = data.externalId;
    makeAutoObservable(this, {}, {autoBind: true});
  }

  setVersion(v: string) {
    this.version = v;
  }

  get participants(): Participant[] {
    return [...this.members.values()].filter(
      (p) => p.state === 'joined' || p.state === 'left-with-stream',
    );
  }

  setParticipants(list: Participant[]) {
    this.members = arrayToMap(list, 'id');
  }

  getParticipants(predicate: Callback<boolean, [Participant, number, Participant[]]>) {
    return this.participants.filter(predicate);
  }

  get guests(): Participant[] {
    return this.getParticipants((p) => p.type === 'participant' && p.state === 'joined');
  }

  getParticipant(id: string): Participant | undefined {
    return this.members.get(id);
  }

  setParticipant(participant: Participant): void {
    this.members.set(participant.id, participant);
  }

  setReturnFeed(feed?: ReturnFeed) {
    this.feed = feed;
  }

  removeParticipant(id: string) {
    this.members.delete(id);
  }

  get listeners(): SrtEntity[] {
    return this.participants
      .filter((p) => p.stream.settings?.mode === 'listener')
      .map((p) => ({
        id: p.id,
        name: p.name,
        port: p.stream.settings?.port ?? 0,
      }));
  }

  checkLimit(): boolean {
    if (this.settings) {
      const active = this.participants.filter((p) => p.stream.active).length;
      return active < this.settings.extractionsLimit;
    }

    return false;
  }

  setName(name: string) {
    this.name = name;
  }

  get entities(): Entity[] {
    const all: Entity[] = [...this.participants];

    if (this.feed) {
      all.push(this.feed);
    }

    return all;
  }

  get tenant() {
    return this.tenantName;
  }

  get zoomId(): string {
    const id = this.externalId;
    const isZoom = this.platform === 'zoom';
    return id && isZoom ? `${id.slice(0, 3)} ${id.slice(3, 6)} ${id.slice(6)}` : '';
  }
}
