import React from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';
import {SummaryButton} from 'app/components/sharedReactComponents/SummaryButton/SummaryButton';
import {OnlineIndicator} from 'app/components/sharedReactComponents/OnlineIndicator';
import {SIZE} from 'app/constants';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {Icons, SvgIcon} from 'app/util/icons';
import {UnifySelectActions} from 'app/components/UnifyProjects/types';
import {SelectAllButton} from 'app/components/FleetManager/StatusPanel';
import {EventIcon} from 'app/components/sharedReactComponents/Events/shared/EventIcon/EventIcon';
import {useUnifySummary} from 'app/components/UnifyProjects/hooks/useUnifySummary';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Schedule} from 'app/domain/schedule';

function getTitle(text: string) {
  return `Click to select all ${text}`;
}

function getIcon(icon: SvgIcon) {
  return icon.size(SIZE.S).reactComponent();
}

interface Props extends ClassName, UnifySelectActions {
  projects: AnyDeviceModelType[];
  actualEvents?: Map<string, Schedule.Event>;
  allSelected: boolean;
}

export const UnifyManagerSummary = ({
  className,
  projects,
  actualEvents,
  allSelected,
  onSelectActive,
  onSelectOnline,
  onSelectSuspended,
  onSelectRecording,
  onSelectStreaming,
  onSelectEvents,
  onSelectAll,
}: Props) => {
  const {active, events, online, recording, streaming, suspended} = useUnifySummary({
    projects,
    actualEvents,
  });

  return (
    <div className={classNames('unify-manager-summary', className)}>
      <div className="unify-manager-summary__actions">
        <SummaryButton
          className="unify-manager-summary__button"
          title={getTitle('online projects')}
          icon={<OnlineIndicator online={true} size={SIZE.S} />}
          label={`${online} online ${pluralizeN('project', online)}`}
          summary={online}
          dataId="select_online_projects_button"
          onClick={onSelectOnline}
        />

        <SummaryButton
          className="unify-manager-summary__button"
          title={getTitle('suspended projects')}
          icon={<OnlineIndicator online={false} size={SIZE.S} />}
          label={`${suspended} suspended ${pluralizeN('project', suspended)}`}
          summary={suspended}
          dataId="select_suspended_projects_button"
          onClick={onSelectSuspended}
        />

        <SummaryButton
          className="unify-manager-summary__button"
          title={getTitle('recording, streaming projects')}
          label={`${active} active ${pluralizeN('project', active)}`}
          summary={active}
          dataId="select_active_projects_button"
          onClick={onSelectActive}
        />

        <SummaryButton
          className="unify-manager-summary__button unify-manager-summary__button--recording"
          title={getTitle('recording projects')}
          icon={getIcon(Icons.record())}
          label={recording}
          summary={recording}
          dataId="select_recording_projects_button"
          onClick={onSelectRecording}
        />

        <SummaryButton
          className="unify-manager-summary__button unify-manager-summary__button--streaming"
          title={getTitle('streaming projects')}
          icon={getIcon(Icons.streaming())}
          label={streaming}
          summary={streaming}
          dataId="select_streaming_projects_button"
          onClick={onSelectStreaming}
        />

        <SummaryButton
          className="unify-manager-summary__button unify-manager-summary__button--events"
          title={getTitle('projects with ongoing events')}
          icon={<EventIcon type="ongoing" />}
          label={`${events} ongoing ${pluralizeN('project', events)}`}
          summary={events}
          dataId="select_events_projects_button"
          onClick={onSelectEvents}
        />

        <SelectAllButton
          allItemsSelected={allSelected}
          isUnify={true}
          enabled={true}
          canEdit={true}
          deviceGroupsCount={0}
          onClickSelectAll={onSelectAll}
        />
      </div>
    </div>
  );
};
