import React from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  audioIsolated: boolean;
}

export const MeetingAudioMode = ({className, audioIsolated}: Props) => {
  const name = audioIsolated ? 'Isolated' : 'Mixed';

  return (
    <div className={classNames('meeting-audio-mode', className)}>
      <span className="meeting-audio-mode__mode">Audio mode:</span>
      <span className="meeting-audio-mode__mode-name" data-id="meeting_audio_mode_name">
        {name} audio
      </span>
    </div>
  );
};
