import {ApiResponseNumeralStatus, ApiResponseStatus} from 'app/data/Status';
import {noop} from 'app/util/noop';
import {Callback, TimeStampMilliseconds} from 'app/types/common';
import {AbortCallback, GetAbortCallback} from 'app/api/types';

const POLL_TIMEOUT = 1000;
const FAIL_POLL_TIMEOUT = 5000;

type LongPollCallType = ({getAbort}: {getAbort: GetAbortCallback}) => any;

interface LongPollOptions {
  call: LongPollCallType;
  pollTimeout?: TimeStampMilliseconds;
  failPollTimeout?: TimeStampMilliseconds;
  checkUpdated?: Callback;
  onUpdate?: Callback;
  onStart?: Callback;
  onStop?: Callback;
}

export class LongPoll {
  protected call: LongPollCallType;
  protected pollTimeout: TimeStampMilliseconds;
  protected failPollTimeout: TimeStampMilliseconds;
  protected checkUpdatedCb: Callback;
  protected onUpdateCb: Callback;
  protected onStartCb: Callback;
  protected onStopCb: Callback;
  protected abortRequest: AbortCallback = noop;

  protected stopped = true;
  protected failed = false;
  protected timeout = -1;

  constructor({
    call,
    pollTimeout = POLL_TIMEOUT,
    failPollTimeout = FAIL_POLL_TIMEOUT,
    checkUpdated = noop,
    onUpdate = noop,
    onStart = noop,
    onStop = noop,
  }: LongPollOptions) {
    this.call = call;

    this.pollTimeout = pollTimeout;
    this.failPollTimeout = failPollTimeout;

    this.checkUpdatedCb = checkUpdated;
    this.onUpdateCb = onUpdate;
    this.onStartCb = onStart;
    this.onStopCb = onStop;
  }

  start() {
    if (this.stopped === false) {
      return;
    }

    this._onStart();

    this.stopped = false;
    this._run(true);
  }

  stop() {
    if (this.stopped === true) {
      return;
    }

    this.stopped = true;
    this.abortRequest();
    window.clearTimeout(this.timeout);

    this._onStop();
  }

  _checkUpdated(response) {
    return this.checkUpdatedCb(response);
  }

  _onUpdate(response) {
    this.onUpdateCb(response);
  }

  _onStart() {
    this.onStartCb();
  }

  _onStop() {
    this.onStopCb();
  }

  _run(immediately = false) {
    if (immediately) {
      this._fetch();
    } else {
      const timeout = this.failed ? this.failPollTimeout : this.pollTimeout;
      this.timeout = window.setTimeout(() => this._fetch(), timeout);
    }
  }

  _fetch() {
    this.call({
      getAbort: (abort) => {
        this.abortRequest = abort;
      },
    })
      .then(response => {
        this.failed = false;

        if (this.stopped) {
          return;
        }

        if (
          response.status === ApiResponseStatus.Ok ||
          response.status === ApiResponseNumeralStatus.Ok ||
          response.status === ApiResponseNumeralStatus.NoContent
        ) {
          if (this._checkUpdated(response)) {
            this._onUpdate(response);
          }
        } else {
          this.failed = true;
        }

        this._run();
      })
      .catch(() => {
        if (this.stopped) {
          return;
        }

        this.failed = true;

        this._run();
      });
  }
}
