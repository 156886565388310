import React from 'react';
import classNames from 'classnames';
import {WAI_ARIA_ROLE} from 'app/constants/waiAriaRole';
import {ClassName, Sx} from 'app/types/common';
import {Box} from '@mui/material';

interface Props extends ClassName, Sx {
  // Used in <TabPanels>
  // eslint-disable-next-line react/no-unused-prop-types
  value?: string;
}

export const TabPanel: React.FC<Props> = ({sx, className, children}) => {
  if (!children) {
    return null;
  }

  return (
    <Box
      sx={sx}
      className={classNames('tabs__tab-panel', className)}
      role={WAI_ARIA_ROLE.TAB_PANEL}
    >
      {children}
    </Box>
  );
};
