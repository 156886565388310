import React from 'react';
import {Box, Button, Link, Typography} from '@mui/material';
import {router} from 'app/router/main';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';

interface Props extends Sx {
  permitEdit: boolean;
}

const rootSx: StyleSx = {
  position: 'relative',
  overflow: 'hidden',
  p: 6,
  border: 1,
  color: 'white',
  background:
    'linear-gradient(135deg, #0a0f25, #2e1746, #482565, #6b388a, #9652b5), url(/assets/img/background/files-bg.png)',
  borderRadius: 4,
};

const imageSx: StyleSx = {
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
};

export function TeamFilesWelcome({sx, permitEdit}: Props) {
  return (
    <Box sx={packSx(rootSx, sx)}>
      <Box
        sx={imageSx}
        component="img"
        src="/assets/img/background/files-bg.png"
        srcSet="/assets/img/background/files-bg.png 2x"
        height={350}
      />

      <Box
        component="img"
        src="/assets/img/logo/files-logo.png"
        srcSet="/assets/img/logo/files-logo@2x.png"
        height={28}
      />

      <Typography variant="body2" mb={2}>
        File Management
      </Typography>

      <Typography variant="h4" mb={2}>
        Get your files faster and
        <br /> easier with automatic
        <br /> backups to Epiphan Edge
      </Typography>

      <Box mb={3}>
        <Link
          color="#fff"
          href="https://www.epiphan.com/edge"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </Link>
      </Box>

      {permitEdit && (
        <Button
          data-id="edit-billing-btn"
          variant="contained"
          color="primary"
          href={router.url('settingBillingUsage')}
        >
          Get started for free
        </Button>
      )}
    </Box>
  );
}
