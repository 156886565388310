import React from 'react';
import {Box, Link, Typography} from '@mui/material';
import {palette} from 'app/themes/app';
import {Sx} from 'app/types/common';
import {PermissionNote} from 'app/components/pages/MeetingDetails/MeetingLayout/PermissionNote';

interface Props extends Sx {
  variant: 'local' | 'media';
}

export function LocalRecordGuide({sx, variant}: Props) {
  const media = variant === 'media';
  const title = media
    ? 'The meeting host can grant permissions manually'
    : 'The meeting host must request a manual permission';

  return (
    <Box sx={sx}>
      <Typography sx={{fontSize: '20px', mb: 0.5}}>{title}</Typography>

      <Typography fontSize={16} color={palette.gray3} mb={2}>
        <>
          {media ? 'The meeting host can grant permissions manually. ' : null}
          Please ask the <b>meeting host</b> to:
        </>
      </Typography>

      <Typography sx={{fontSize: '16px', mb: 4, color: palette.gray3}}>
        1. Access the Zoom meeting.
      </Typography>

      <Typography sx={{fontSize: '16px', mb: 1, color: palette.gray3}}>
        2. Locate the Epiphan Connect participant:
      </Typography>

      <Box sx={{mb: 4}}>
        <img
          src="/assets/img/connect/instructions-recording.png"
          srcSet="assets/img/connect/instructions-recording@2x.png 2x"
          width={520}
          height={286}
          alt=""
        />
      </Box>

      <Typography sx={{fontSize: '16px', mb: 1, color: palette.gray3}}>
        3. Access the menu of the Epiphan Connect participant and click on ”Allow to Record Local
        Files”. The <br /> participants of the meeting will hear a message telling the meeting will
        be recorded.
      </Typography>

      <Box sx={{mb: 2}}>
        <img
          src="/assets/img/connect/instructions-recording2.png"
          srcSet="assets/img/connect/instructions-recording2@2x.png 2x"
          width={520}
          height={286}
          alt=""
        />
      </Box>

      <PermissionNote sx={{mb: 4}} />

      <Typography sx={{fontSize: '16px', mb: 4, color: palette.gray3}}>
        4. Done. You're ready to start producing events with Epiphan Connect and Zoom. This page
        will automatically <br /> update once the permissions are enabled.
      </Typography>

      <Typography sx={{color: palette.gray3}}>
        <Link
          href="https://www.epiphan.com/userguides/connect/Content/Connect/Connect-to-a-Zoom-Meeting-Permissions.htm"
          target="_blank"
          rel="noreferrer"
        >
          Get the link for this guide
        </Link>{' '}
        and share it with the meeting host.
      </Typography>
    </Box>
  );
}
