const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MINUTE = 60;
const MILLISECONDS_IN_SECOND = 1000;

// Days
const daysToHours = (days: number): number => days * HOURS_IN_DAY;

const daysToSeconds = (days: number): number => minutesToSeconds(hoursToMinutes(daysToHours(days)));

// Hours
const hoursToMinutes = (hours: number): number => hours * MINUTES_IN_HOUR;

const hoursToSeconds = (hours: number) => hoursToMinutes(hours) * SECONDS_IN_MINUTE;

// Minutes
const minutesToHours = (minutes: number): number => minutes / MINUTES_IN_HOUR;

const minutesToSeconds = (minutes: number): number => minutes * SECONDS_IN_MINUTE;

const minutesToMilliseconds = (minutes: number): number => secondsToMilliseconds(minutesToSeconds(minutes));

// Seconds
const secondsToHours = (seconds: number): number => minutesToHours(secondsToMinutes(seconds));

const secondsToMinutes = (seconds: number): number => seconds / SECONDS_IN_MINUTE;

const secondsToMilliseconds = (seconds: number): number => seconds * MILLISECONDS_IN_SECOND;

// Milliseconds
const millisecondsToSeconds = (milliseconds: number): number => milliseconds / MILLISECONDS_IN_SECOND;

export {
  daysToHours,
  daysToSeconds,

  hoursToMinutes,
  hoursToSeconds,

  minutesToHours,
  minutesToSeconds,
  minutesToMilliseconds,

  secondsToHours,
  secondsToMinutes,
  secondsToMilliseconds,

  millisecondsToSeconds,
};
