const DEFAULT_ASPECT_RATIO = {
  w: 16,
  h: 9,
};

/**
 * @readonly
 * @enum {string}
 */
enum THEME {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  WHITE = 'white',
}

/**
 * @readonly
 * @enum {string}
 */
enum VARIANT {
  SOLID = 'solid',
  OUTLINE = 'outline',
  LINK = 'link',
  LINK_UNDERLINE = 'link-underline',
  TEXT = 'text',
}

/**
 * @readonly
 * @enum {string}
 */
enum SIZE {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl',
}

/**
 * @readonly
 * @enum {string}
 */
enum SORT_DIRECTION {
  /**
   * Sort items in ascending order.
   * This means arranging from the lowest value to the highest (e.g. a-z, 0-9).
   */
  ASC = 'ASC',

  /**
   * Sort items in descending order.
   * This means arranging from the highest value to the lowest (e.g. z-a, 9-0).
   */
  DESC = 'DESC',
}

enum UserRole {
  Master = 'master',
  Owner = 'owner',
  Operator = 'operator',
  Editor = 'editor',
}

/**
 * @readonly
 * @enum {string}
 */
const USER_ROLE_NAME_BY_TYPE = {
  [UserRole.Master]: 'Owner',
  [UserRole.Owner]: 'Admin',
  [UserRole.Operator]: 'Operator',
  [UserRole.Editor]: 'Editor',
};

enum UserLoginType {
  Facebook = 'fb',
  Google = 'google',
  Cognito = 'cognito',
  // Pending login type appears when you invite a new team member
  Pending = '',
}

/**
 * @readonly
 * @enum {string}
 */
const NODE_NAME = {
  INPUT: 'INPUT',
};

/**
 * KeyboardEvent.code
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code}
 */
const enum KeyboardCode {
  Enter = 'Enter',
  NumpadEnter = 'NumpadEnter',
  Tab = 'Tab',
  Escape = 'Escape',
  Space = 'Space',
  KeyA = 'KeyA',
}

export {
  DEFAULT_ASPECT_RATIO,
  THEME,
  VARIANT,
  SIZE,
  SORT_DIRECTION,
  UserRole,
  USER_ROLE_NAME_BY_TYPE,
  UserLoginType,
  NODE_NAME,
  KeyboardCode,
};
