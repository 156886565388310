import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {Box, Tab, Tabs, Typography} from '@mui/material';
import {EdgePlan} from 'app/models/EdgePlan/EdgePlan';
import {StyleSx} from 'app/types/common';
import {TabPanels} from 'app/components/sharedReactComponents/Tabs/TabPanels';
import {TabPanel} from 'app/components/sharedReactComponents/Tabs/TabPanel';
import {ConnectUsageAndInvoices} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/ConnectUsageAndInvoices/ConnectUsageAndInvoices';
import {CloudAndUnifyInvoices} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/CloudAndUnifyInvoices/CloudAndUnifyInvoices';
import {FilesUsage} from 'app/components/widgets/billing';
import {useFeature} from 'app/util/ApplicationFeatures';

const tabsSx: StyleSx = {
  mb: 3,
  borderWidth: 0,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: (t) => t.palette.divider,
};
const tabSx: StyleSx = {fontSize: 16};
const bodySx: StyleSx = {minHeight: 300};

interface Props {
  teamId: string;
  cloud: EdgePlan;
  connect?: App.Billing.Connect.Plan;
}

type UsageTab = 'cloud' | 'connect' | 'files';

export const UsageAndInvoices = observer(({teamId, cloud, connect}: Props) => {
  const [tab, setTab] = useState<UsageTab>('cloud');

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue as UsageTab);
  };

  const unifyFeature = useFeature('unify');
  const connectFeature = useFeature('connect');
  const filesFeature = useFeature('files');

  return (
    <Box>
      <Typography fontWeight={600} variant="h5" mb={3}>
        Usage and invoices
      </Typography>

      <Tabs sx={tabsSx} value={tab} textColor="inherit" onChange={handleTabChange}>
        <Tab
          sx={tabSx}
          data-id="edge-usage-tab"
          value="cloud"
          label={unifyFeature ? 'Edge & Unify' : 'Edge'}
        />
        {connectFeature && (
          <Tab sx={tabSx} data-id="connect-usage-tab" value="connect" label="Connect" />
        )}

        {filesFeature && (
          <Tab sx={tabSx} data-id="files-usage-tab" value="files" label="File management" />
        )}
      </Tabs>

      <TabPanels value={tab}>
        <TabPanel value="cloud">
          <CloudAndUnifyInvoices sx={bodySx} teamId={teamId} period={cloud.period} />
        </TabPanel>

        {connectFeature && (
          <TabPanel value="connect">
            <ConnectUsageAndInvoices sx={bodySx} subscription={connect} teamId={teamId} />
          </TabPanel>
        )}

        {filesFeature && (
          <TabPanel value="files">
            <FilesUsage sx={bodySx} teamId={teamId} />
          </TabPanel>
        )}
      </TabPanels>
    </Box>
  );
});
