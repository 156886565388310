import React, {forwardRef, PropsWithChildren} from 'react';
import classNames from 'classnames';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  opened?: boolean;
  title?: React.ReactNode;
  disabled?: boolean;
  onClick?: Callback;
}

export const BatchActionButton = forwardRef<any, PropsWithChildren<Props>>(
  ({className, opened, title, children, disabled, onClick, ...elementProps}, ref) => {
    return (
      <Button
        ref={ref}
        className={classNames(
          'batch-action-button',
          opened && 'batch-action-button--opened',
          className,
        )}
        title={title}
        disabled={disabled}
        onClick={onClick}
        {...elementProps}
      >
        {children}
      </Button>
    );
  },
);
