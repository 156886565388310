/* eslint-disable class-methods-use-this, max-classes-per-file */
interface Args {
  value: number;
  discount: App.Billing.DiscountType;
  domain: App.Billing.CouponDomain;
  duration?: App.Billing.CouponDuration;
}

interface Display {
  get formatted(): string;
  get domain(): string;
  get duration(): string;
}

export class DiscountCoupon implements Display {
  value: number;
  private _type: App.Billing.DiscountType;
  private _domain: App.Billing.CouponDomain;
  private _duration?: App.Billing.CouponDuration;

  constructor({value, discount: discountType, domain, duration}: Args) {
    this.value = value;
    this._domain = domain;
    this._type = discountType;
    this._duration = duration;
  }

  get isConnect() {
    return this._domain === 'connect';
  }

  get formatted() {
    return this._type === 'amount' ? `$${this.value}` : `${this.value}%`;
  }

  get domain(): App.Billing.CouponDomain {
    return this.isConnect ? 'connect' : 'cloud';
  }

  get duration(): string {
    return this._duration ?? '';
  }
}

export class IbbCoupon implements Display {
  value: number;

  constructor(value: number) {
    this.value = value;
  }

  get formatted() {
    return `$${this.value}`;
  }

  get domain(): App.Billing.CouponDomain {
    return 'cloud';
  }

  get duration(): string {
    return '';
  }
}

export type Coupon = DiscountCoupon | IbbCoupon;

export function isIbbCoupon(coupon: Coupon): coupon is IbbCoupon {
  return coupon instanceof IbbCoupon;
}

export function isDiscountCoupon(coupon: Coupon): coupon is DiscountCoupon {
  return coupon instanceof DiscountCoupon;
}
