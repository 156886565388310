import React from 'react';
import {Box, Dialog, DialogActions, DialogTitle} from '@mui/material';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {Prompt} from 'app/types/common';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {palette} from 'app/themes/app';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {THEME, VARIANT} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Notifications} from 'app/components/Notifications';

interface Props extends Prompt {
  name: string;
  onDelete(): Promise<void>;
}

export function DeleteCallDialog({open, name, onDelete, onClose}: Props) {
  const handleDelete = async () => {
    try {
      await onDelete();
    } catch {
      Notifications.addErrorNotification(`Could not delete meeting`);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth={true}>
      <DialogClose onClose={onClose} />
      <DialogTitle>
        Delete{' '}
        <Box sx={{color: palette.blue}} component="span">
          <OverflowTooltip>{name}</OverflowTooltip>
        </Box>
      </DialogTitle>
      <DialogActions>
        <ProgressButton
          dataId="modal_confirm_button"
          theme={THEME.DANGER}
          variant={VARIANT.SOLID}
          autoFocus={true}
          onClick={handleDelete}
        >
          Delete
        </ProgressButton>

        <Button
          dataId="modal_cancel_button"
          theme={THEME.SECONDARY}
          variant={VARIANT.OUTLINE}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
