import React from 'react';
import classNames from 'classnames';
import {Container} from 'app/components/FleetManager/DeviceCard/ChildCard/Container';
import {router} from 'app/router/main';
import {Callback, ClassName} from 'app/types/common';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

interface Props extends ClassName {
  device: AnyDeviceModelType;
  currentUserRole: AnyUserRoleModel;
  checkIsDeviceSelected: Callback;
}

export function DemoDeviceDetails({
  className,
  device,
  currentUserRole,
  checkIsDeviceSelected,
}: Props) {
  const detailsUrl = router.url('deviceDetails', {deviceId: device.getId()});

  return (
    <Container
      className={classNames('demo-device-details', className)}
      detailsUrl={detailsUrl}
      withThumbnail={true}
      selected={checkIsDeviceSelected(device.getId())}
      currentUserRole={currentUserRole}
      device={device}
      streamingDestinations={[]}
    />
  );
}
