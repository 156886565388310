import React from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {Callback, ClassName} from 'app/types/common';
import {StreamConnectionStatus} from 'app/components/pages/MeetingDetails/StreamConnectionStatus/StreamConnectionStatus';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {ParticipantInput} from 'app/components/pages/MeetingDetails/shared/ParticipantInput/ParticipantInput';
import {ParticipantAvatar} from 'app/components/pages/MeetingDetails/shared/ParticipantAvatar/ParticipantAvatar';
import {SrtStream} from 'app/store/models/connect/SrtStream/SrtStream';
import {StreamStatusActions} from 'app/components/pages/MeetingDetails/StreamStatus/StreamStatusActions/StreamStatusActions';
import {Box, Stack} from '@mui/material';
import {DisconnectedLabel} from 'app/components/pages/MeetingDetails/shared/DisconnectedLabel/DisconnectedLabel';

interface Props extends ClassName {
  callerUrl: string;
  name: string;
  type: App.Connect.MemberType;
  stream: SrtStream;
  share: boolean;
  video: boolean;
  audio: boolean;
  disconnected: boolean;
  onMute: Callback<Promise<void>, [App.Connect.StreamMute]>;
  onStop: Callback<Promise<void>>;
}

export const StreamStatusHeader = observer(
  ({
    className,
    callerUrl,
    stream,
    type,
    audio,
    video,
    name,
    share,
    disconnected,
    onMute,
    onStop,
  }: Props) => {
    const isShare = type === 'vbss';

    return (
      <div className={classNames('stream-status-header', className)}>
        <ParticipantAvatar
          className="stream-status-header__avatar"
          dataId="participant_avatar"
          name={name}
          type={type}
          sharing={share}
          cameraOff={!video}
          disconnected={disconnected}
          active={stream.active}
          size="big"
        />

        <div className="stream-status-header__state">
          <Box sx={{mb: 1}}>
            <Stack direction="row" alignItems="center">
              <span className="stream-status-header__name">
                <OverflowTooltip dataId="participant_name">{name}</OverflowTooltip>
              </span>

              {!isShare && (
                <ParticipantInput video={video} audio={audio} dataId="participant_input" />
              )}
            </Stack>

            {disconnected && <DisconnectedLabel />}
          </Box>

          <StreamConnectionStatus
            className="stream-status-header__target"
            domain={callerUrl}
            stream={stream}
          />

          <StreamStatusActions
            name={name}
            audio={stream.mute?.audio ?? false}
            video={stream.mute?.video ?? false}
            disabledMute={!stream?.running}
            disabledStop={false}
            onMute={onMute}
            onStop={onStop}
          />
        </div>
      </div>
    );
  },
);
