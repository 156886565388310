/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react';
import {CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js';
import {
  CreateTokenCardData,
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
} from '@stripe/stripe-js';
import {Box, FormHelperText, Stack, StackProps, TextField} from '@mui/material';
import classNames from 'classnames';
import {useForm, Controller} from 'react-hook-form';
import {joiResolver} from '@hookform/resolvers/joi';
import Joi from 'joi';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';
import {RadioGroup} from 'app/components/sharedReactComponents/RadioGroup';
import {Radio, RADIO_THEME} from 'app/components/sharedReactComponents/Radio';
import {Callback} from 'app/types/common';
import {useStripeConnection} from 'app/components/sharedReactComponents/PaymentForm/useStripeConnection';
import {isNil} from 'app/util/isNil';
import {PaymentSection} from 'app/components/sharedReactComponents/PaymentForm/PaymentSection';
import {PaymentFormCoupon} from 'app/components/sharedReactComponents/PaymentForm/PaymentFormCoupon/PaymentFormCoupon';
import {CouponState} from 'app/components/BillingManager/hooks/useCoupon';
import {isIbbCoupon} from 'app/components/BillingManager/Coupon';

const cardElementOptions: StripeCardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      fontWeight: 400,
      fontFamily: '"Open Sans", "Helvetica Neue", "Helvetica", sans-serif',
      fontSize: '14px',
      color: '#333',
    },
    invalid: {
      color: '#ff2e63',
      iconColor: '#ff2e63',
    },
  },
};

export type Method = 'card' | 'invoice' | 'invoice coupon';

interface PaymentData<M extends Method> {
  method: M;
}

export interface PaymentCardData extends PaymentData<'card'> {
  ccToken: string;
  billingEmail: string;
}

export interface PaymentInvoiceData extends PaymentData<'invoice'> {
  firstName: string;
  lastName: string;
  billingEmail: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  addressLineOne: string;
}

export interface PaymentInvoiceCouponData extends PaymentData<'invoice coupon'> {
  billingEmail: string;
}

export type PaymentPayload = PaymentCardData | PaymentInvoiceData | PaymentInvoiceCouponData;

type AvailablePayment = 'credit-card' | 'invoice';

interface BillingData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  address: string;
  method: AvailablePayment;
  cc: boolean;
}

const emptyData: BillingData = {
  address: '',
  city: '',
  country: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  postalCode: '',
  state: '',
  method: 'credit-card',
  cc: false,
};

const scheme = Joi.object<BillingData>({
  firstName: Joi.string().empty('').required(),
  lastName: Joi.string().empty('').required(),
  email: Joi.string()
    .empty('')
    .email({tlds: {allow: false}})
    .required(),
  address: Joi.string().empty('').required(),
  city: Joi.string().empty('').required(),
  country: Joi.string().empty('').required(),
  postalCode: Joi.string().empty('').required(),
  phone: Joi.string().empty(''),
  state: Joi.string().empty(''),
  method: Joi.string().valid('credit-card', 'invoice'),
  cc: Joi.boolean().when('method', {
    is: 'credit-card',
    then: Joi.valid(true),
  }),
}).messages({
  'any.required': 'Required field',
  'string.empty': 'Cannot be empty',
  'string.email': 'Must be valid email',
});

interface Props {
  hasConnect: boolean;
  email?: string;
  externalError: string;
  coupon: CouponState;
  setCoupon: Callback<void, [value: string]>;
  validateCoupon: Callback<Promise<void>, [value: string]>;
  onSubmit: Callback<Promise<void>, [data: PaymentPayload]>;
}

const PaymentFormRoot = ({
  email,
  externalError,
  hasConnect,
  coupon,
  setCoupon,
  validateCoupon,
  onSubmit,
}: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {control, formState, getFieldState, watch, getValues, trigger, setValue} =
    useForm<BillingData>({
      defaultValues: {...emptyData, email},
      mode: 'all',
      resolver: joiResolver(scheme),
    });

  const [stripeError, setStripeError] = useState('');

  const isCouponFilled = coupon.value.length > 0 && !isNil(coupon.instance);
  const isIbb = coupon.instance ? isIbbCoupon(coupon.instance) : false;
  const isRegularCoupon = isCouponFilled && !isIbb;

  useEffect(() => {
    if (isIbb) {
      setValue('method', 'invoice');
    }
  }, [isIbb, setValue]);

  const handleCardChange = (event: StripeCardElementChangeEvent) => {
    setValue('cc', event.complete, {shouldValidate: true});
    setStripeError(event.error?.message ?? '');
  };

  const isDisabled = isSubmitting || (coupon.value.length > 0 && isNil(coupon.instance));

  const method = watch('method');
  const cardError = getFieldState('cc', formState).error;

  const handleSubmit = async () => {
    if (isDisabled || !stripe || !elements) {
      return;
    }

    try {
      const validation = await trigger();
      if (!validation) {
        return;
      }
    } catch {
      return;
    }

    const fields = getValues();

    setIsSubmitting(true);
    setStripeError('');

    if (isIbb) {
      try {
        await onSubmit({
          method: 'invoice coupon',
          billingEmail: fields.email,
        });
      } finally {
        setIsSubmitting(false);
      }
      return;
    }

    if (fields.method === 'credit-card') {
      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        return;
      }

      const data: CreateTokenCardData = {
        address_line1: fields.address,
        address_city: fields.city,
        address_state: fields.state,
        address_zip: fields.postalCode,
        address_country: fields.country,
      };

      try {
        const result = await stripe.createToken(cardElement, data);

        if (result.token) {
          await onSubmit({
            method: 'card',
            ccToken: result.token.id,
            billingEmail: fields.email,
          });
        }

        if (result.error) {
          setStripeError(result.error.message ?? 'Stripe error');
        }
      } finally {
        setIsSubmitting(false);
      }

      return;
    }

    try {
      await onSubmit({
        method: 'invoice',
        firstName: fields.firstName,
        lastName: fields.lastName,
        billingEmail: fields.email,
        phone: fields.phone,
        country: fields.country,
        state: fields.state,
        city: fields.city,
        postalCode: fields.postalCode,
        addressLineOne: fields.address,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="payment-form" data-id="payment_info_form">
      <div>
        <Row>
          <Controller
            control={control}
            name="firstName"
            render={({field, fieldState: {error}}) => (
              <TextField
                {...field}
                inputProps={{'data-id': 'first-name'}}
                variant="standard"
                label="First name"
                error={!isNil(error)}
                helperText={error?.message ?? ' '}
                fullWidth={true}
              />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({field, fieldState: {error}}) => (
              <TextField
                {...field}
                inputProps={{'data-id': 'last-name'}}
                variant="standard"
                label="Last name"
                error={!isNil(error)}
                helperText={error?.message ?? ' '}
                fullWidth={true}
              />
            )}
          />
        </Row>

        <Row>
          <Controller
            control={control}
            name="email"
            render={({field, fieldState: {error}}) => (
              <TextField
                {...field}
                inputProps={{'data-id': 'email'}}
                label="Email"
                variant="standard"
                error={!isNil(error)}
                helperText={error?.message ?? ' '}
                fullWidth={true}
              />
            )}
          />

          <Controller
            control={control}
            name="phone"
            render={({field, fieldState: {error}}) => (
              <TextField
                {...field}
                inputProps={{'data-id': 'phone'}}
                error={!isNil(error)}
                helperText={error?.message ?? ' '}
                label="Phone (optional)"
                variant="standard"
                fullWidth={true}
              />
            )}
          />
        </Row>

        <Row>
          <Controller
            control={control}
            name="country"
            render={({field, fieldState: {error}}) => (
              <TextField
                {...field}
                inputProps={{'data-id': 'country'}}
                error={!isNil(error)}
                helperText={error?.message ?? ' '}
                variant="standard"
                label="Country"
                fullWidth={true}
              />
            )}
          />

          <Controller
            control={control}
            name="state"
            render={({field, fieldState: {error}}) => (
              <TextField
                {...field}
                inputProps={{'data-id': 'state'}}
                error={!isNil(error)}
                helperText={error?.message ?? ' '}
                label="State (optional)"
                variant="standard"
                fullWidth={true}
              />
            )}
          />
        </Row>

        <Row>
          <Controller
            control={control}
            name="city"
            render={({field, fieldState: {error}}) => (
              <TextField
                {...field}
                inputProps={{'data-id': 'city'}}
                error={!isNil(error)}
                helperText={error?.message ?? ' '}
                label="City"
                variant="standard"
                fullWidth={true}
              />
            )}
          />

          <Controller
            control={control}
            name="postalCode"
            render={({field, fieldState: {error}}) => (
              <TextField
                {...field}
                inputProps={{'data-id': 'postal-code'}}
                error={!isNil(error)}
                helperText={error?.message ?? ' '}
                label="Zip / Postal code"
                variant="standard"
                fullWidth={true}
              />
            )}
          />
        </Row>

        <Row>
          <Controller
            control={control}
            name="address"
            render={({field, fieldState: {error}}) => (
              <TextField
                {...field}
                inputProps={{'data-id': 'address'}}
                error={!isNil(error)}
                helperText={error?.message ?? ' '}
                label="Address"
                variant="standard"
                fullWidth={true}
              />
            )}
          />
        </Row>
      </div>

      {externalError && (
        <Row data-id="error_message" className="payment-form__error">
          <p>{externalError}</p>
        </Row>
      )}

      <Box sx={{my: 2}}>
        <PaymentFormCoupon
          hasConnect={hasConnect}
          coupon={coupon}
          setCoupon={setCoupon}
          validateCoupon={validateCoupon}
        />
      </Box>

      <Box sx={{my: 2}}>
        <PaymentSection title="Payment method">
          {isIbb ? (
            <div>Invoice-based billing.</div>
          ) : (
            <Controller
              name="method"
              control={control}
              render={({field}) => (
                <RadioGroup dataId="payment_info_method_selector" {...field}>
                  <Radio
                    dataId="card-radio"
                    className="payment-form__method"
                    label="Pay by credit or debit card"
                    value="credit-card"
                    theme={RADIO_THEME.BLACK}
                    size={SIZE.S}
                  />

                  <Radio
                    dataId="invoice-radio"
                    className="payment-form__method"
                    label="Pay by invoices"
                    value="invoice"
                    theme={RADIO_THEME.BLACK}
                    size={SIZE.S}
                  />
                </RadioGroup>
              )}
            />
          )}
        </PaymentSection>
      </Box>

      {!isIbb && (
        <>
          <Box
            className={classNames('payment-form__cc', {
              'payment-form__cc--hidden': method !== 'credit-card',
              'payment-form__cc--invalid': cardError,
            })}
          >
            <label>
              Credit or debit card information
              <CardElement
                data-id="card-input"
                id="card-element"
                options={cardElementOptions}
                onChange={handleCardChange}
              />
              {stripeError && <FormHelperText error={true}>{stripeError}</FormHelperText>}
            </label>
          </Box>

          {method === 'invoice' && (
            <div className="payment-form__invoice-msg" data-id="invoice_note">
              {(!isCouponFilled || isRegularCoupon) && (
                <>Your request will be reviewed in 3 business days.</>
              )}

              {isRegularCoupon && (
                <div className="payment-form__invoice-coupon">
                  <img
                    className="payment-form__invoice-icon"
                    src="/assets/img/billing-icons/coupon-not-ready-to-apply.svg"
                    alt=""
                  />
                  Your voucher cannot be activated now. Please come back to this page to activate
                  the voucher when your request is approved.
                </div>
              )}
            </div>
          )}
        </>
      )}

      <div className="payment-form__footer">
        <ProgressButton
          dataId="submit_button"
          className="payment-form__submit"
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          loading={isSubmitting}
          disabled={isDisabled}
          onClick={handleSubmit}
        >
          Confirm billing
          {isCouponFilled && method === 'credit-card' && ' & activate voucher'}
        </ProgressButton>
      </div>
    </div>
  );
};

export const PaymentForm = (props: Props) => {
  const stripe = useStripeConnection();

  if (!stripe) {
    return <LoadingPlaceholder className="payment-form__loading-placeholder" />;
  }

  return (
    <Elements stripe={stripe}>
      <PaymentFormRoot {...props} />
    </Elements>
  );
};

function Row({children, ...props}: StackProps) {
  return (
    <Stack direction="row" gap={3} {...props}>
      {children}
    </Stack>
  );
}
