import React, {useState} from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {DataId, Sx} from 'app/types/common';
import {
  DeleteRecordingsDialog,
  DownloadRecordingsDialog,
  Files,
} from 'app/components/entities/files';

export interface Props extends Sx, DataId {
  file: Files.Recording;
  onDelete: () => Promise<void>;
  onDownload: () => Promise<void>;
}

export function FileMenu({dataId, sx, file, onDelete, onDownload}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [downloadDialog, setDownloadDialog] = useState(false);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  const handleDelete = async () => {
    await onDelete();
    setDeleteDialog(false);
  };

  const handleDownload = async () => {
    await onDownload();
    setDownloadDialog(false);
  };

  return (
    <>
      <IconButton data-id={dataId} sx={sx} onClick={openMenu} disabled={!file.uploaded}>
        <MoreVertIcon />
      </IconButton>

      <Menu data-id="file-action-menu" anchorEl={anchorEl} open={open} onClose={closeMenu}>
        <MenuItem
          data-id="download-btn"
          onClick={() => {
            setDownloadDialog(true);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <CloudDownloadIcon />
          </ListItemIcon>

          <ListItemText>Download</ListItemText>
        </MenuItem>

        <MenuItem
          data-id="delete-btn"
          onClick={() => {
            setDeleteDialog(true);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>

          <ListItemText>Remove</ListItemText>
        </MenuItem>
      </Menu>

      <DeleteRecordingsDialog
        open={deleteDialog}
        recordings={[file.name]}
        onDelete={handleDelete}
        onClose={() => setDeleteDialog(false)}
      />

      <DownloadRecordingsDialog
        recordings={[file.name]}
        open={downloadDialog}
        onDownload={handleDownload}
        onClose={() => setDownloadDialog(false)}
      />
    </>
  );
}
