import React from 'react';
import classNames from 'classnames';
import {SelectCheckbox} from 'app/components/FleetManager/DeviceCard/SelectCheckbox';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  selectedDeviceCount?: number;
  selectedChannelDevicesCount?: number;
  selectedDeviceGroupCount?: number;
  deselectAll: Callback;
}

export const SelectionIndicator: React.FC<Props> = ({
  className,
  selectedDeviceCount = 0,
  selectedChannelDevicesCount = 0,
  selectedDeviceGroupCount = 0,
  deselectAll,
}) => {
  return (
    <div className={classNames('batch-actions-selection-indicator', className)}>
      <div className="batch-actions-selection-indicator__selector">
        <Tooltip
          content="Click to reset selection"
          delay={[500, 50]}
        >
          <SelectCheckbox
            // rework there is no used classname props in component
            className="batch-actions-selection-indicator__reset-button"
            data-id="batch_actions_selector"
            selected={true}
            onChange={deselectAll}
          />
        </Tooltip>
      </div>

      <div
        className="batch-actions-selection-indicator__counters"
        data-id="batch_actions_counters"
      >
        {selectedDeviceGroupCount > 0 && (
          <div
            className="batch-actions-selection-indicator__device-groups-count"
            data-id="batch_actions_device_groups_count"
          >
            {selectedDeviceGroupCount} {pluralizeN('Group', selectedDeviceGroupCount)}
          </div>
        )}

        <div
          className="batch-actions-selection-indicator__devices-count"
          data-id="batch_actions_devices_count"
        >
          {selectedDeviceCount} {pluralizeN('Device', selectedDeviceCount)}
        </div>

        <div
          className="batch-actions-selection-indicator__channels-count"
          data-id="batch_actions_channels_count"
        >
          {selectedChannelDevicesCount} {pluralizeN('Channel', selectedChannelDevicesCount)}
        </div>
      </div>
    </div>
  );
};
