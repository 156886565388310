/**
 * Team capabilities
 * See WCX-2609 for details
 */
enum TeamCapability {
  Cms = 'team_can_use_cms',
  Transcribing = 'team_can_use_transcribing',
  Unify = 'team_can_use_unify',
  Connect = 'team_can_use_epiphanconnect',
  Presets = 'team_can_use_preset',
  Groups = 'team_can_use_groups',
  Batch = 'team_can_use_batch',
  Files = 'team_can_use_filemanagement',
}

export {TeamCapability};
