export interface BaseTeamModelProps {
  id: string;
  name: string;
}

export class BaseTeamModel {
  readonly id: string;
  readonly name: string;

  constructor({
    id,
    name,
  }: BaseTeamModelProps) {
    this.id = id;
    this.name = name;
  }
}
