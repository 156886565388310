/**
 * ```typescript
 * differenceOfSets(new Set([1, 2]), new Set([2, 3])); //=> new Set([1])
 * ```
 */
export function differenceOfSets(set1: Set<any>, set2: Set<any>): Set<any> {
  const difference = new Set(set1);

  for (const item of set2) {
    if (set2.has(item)) {
      difference.delete(item);
    }
  }

  return difference;
}
