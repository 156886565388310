import React from 'react';
import classNames from 'classnames';
import {Avatar} from 'app/components/sharedReactComponents/Avatar';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  streamingDestination: any;
}

export const AccountInfo: React.FC<Props> = ({
  className,
  streamingDestination,
}) => {
  return (
    <div className={classNames('streaming-destination-settings-account-info', className)}>
      {streamingDestination.getUserPictureSetting() && (
        <Avatar
          className="streaming-destination-settings-account-info__avatar"
          src={streamingDestination.getUserPictureSetting()}
        />
      )}

      <OverflowTooltip className="streaming-destination-settings-account-info__name">
        {streamingDestination.getUserNameSetting()}
      </OverflowTooltip>
    </div>
  );
};
