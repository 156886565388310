enum DeviceSettingType {
  Enum = 'enum',
  Number = 'number',
  NullableNumber = 'nullable_number',
  String = 'string',
  Bool = 'bool',
  Group = 'group',
  Color = 'color',
  AudioSource = 'audio_source',
  StreamUrl = 'stream_url',
}

enum DeviceSettingId {
  Encoder = 'encoder',
  Resolution = 'resolution',
  VideoBitrate = 'vbitrate',
  VideoFramerate = 'framerate',
  VideoCodec = 'video_codec',
  AudioEnabled = 'audio_enabled',
  AudioPreset = 'audio_preset',
  AudioBitrate = 'audio_bitrate',
  AudioChannels = 'audio_channels',

  Recorder = 'recorder',
  FileFormat = 'file_format',
  TimeLimit = 'time_limit',
  SizeLimit = 'size_limit',
  AfuEnabled = 'afu_enabled',

  // LiveScrypt
  AudioSources = 'audio_sources',
  HdmiOutput = 'hdmi_output',
  FrontScreenOutput = 'front_screen',
  WebStreamOutput = 'web_stream_output',
}

export {
  DeviceSettingType,
  DeviceSettingId,
};
