import React, {Fragment, ReactNode, KeyboardEvent} from 'react';
import classNames from 'classnames';
import {Dropdown} from 'app/components/sharedReactComponents/Dropdown';
import {Icons} from 'app/util/icons';
import {
  VARIANT,
  SIZE,
  KeyboardCode,
} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Callback} from 'app/types/common';
import {LoadingWrapper} from 'app/components/sharedReactComponents/LoadingWrapper';

const Separator = () => <li className="context-menu__separator"/>;

export interface ContextMenuItem {
  label?: React.ReactNode;
  action?: Callback;
  icon?: React.ReactNode;
  title?: React.ReactChild;
  danger?: boolean;
  disabled?: boolean;
  visible?: boolean;
  separator?: boolean;
  itemDataId?: string;
  loading?: boolean;
}

type ItemProps = Omit<ContextMenuItem, 'visible' | 'separator'>;

const Item: React.FC<ItemProps> = ({
  label,
  action,
  icon,
  title,
  danger,
  disabled,
  itemDataId,
  loading = false,
}) => {
  const handleKeyUp = (event: KeyboardEvent) => {
    if (!disabled && action && event.code === KeyboardCode.Enter) {
      action();
    }
  };

  const handleClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    action?.();
  };

  return (
    <LoadingWrapper loading={loading}>
      <li
        className={classNames('context-menu__item', {
          'context-menu__item--danger': danger,
          'context-menu__item--disabled': disabled,
        })}
      >
        <span
          className="context-menu__action"
          data-id={itemDataId}
          tabIndex={0}
          onKeyUp={handleKeyUp}
          onClick={disabled ? undefined : handleClick}
        >
          {icon && (
            <span className="context-menu__icon">
              {icon}
            </span>
          )}

          <Tooltip content={title}>
            <span className="context-menu__name">
              {label}
            </span>
          </Tooltip>
        </span>
      </li>
    </LoadingWrapper>
  );
};

type ListProps = {
  items: ContextMenuItem[];
  headerComponent: ReactNode;
};

const List: React.VFC<ListProps> = ({
  items,
  headerComponent,
}) => {
  const withIcons = items.some(item => Boolean(item.icon));

  return (
    <ul
      className={classNames('context-menu__list', {'context-menu__list--with-icons': withIcons})}
      onClick={(e) => e.stopPropagation()}
    >
      {headerComponent && (
        <Fragment>
          <li className="context-menu__header">
            {headerComponent}
          </li>

          <Separator/>
        </Fragment>
      )}

      {items.map((item, index) => {
        if (item.separator) {
          return (
            <Separator key={index}/>
          );
        }

        return (
          <Item
            key={index}
            {...item}
          />
        );
      })}
    </ul>
  );
};

interface Props {
  items: ContextMenuItem[];
  buttonOpenedClassName?: string;
  headerComponent?: React.ReactNode;
  onOpen?: Callback;
  onClose?: Callback;
}

export const ContextMenu: React.FC<Props> = ({
  buttonOpenedClassName,
  items,
  children,
  headerComponent,
  onOpen,
  onClose,
}) => {
  const visibleItems = items.filter(item => item.visible !== false);
  const visibleItemsWithoutSeparators = visibleItems.filter(item => item.separator !== true);

  if (visibleItemsWithoutSeparators.length === 0) {
    return null;
  }

  return (
    <Dropdown
      buttonOpenedClassName={buttonOpenedClassName}
      content={(
        <List
          items={visibleItems}
          headerComponent={headerComponent}
        />
      )}
      onOpen={onOpen}
      onBlur={onClose}
    >
      {children}
    </Dropdown>
  );
};

interface EllipsedContextMenuProps extends Props {
  size?: SIZE;
  buttonDataId?: string;
  disabled?: boolean;
}

export const EllipsedContextMenu = ({
  buttonDataId,
  disabled,
  size,
  ...props
}: EllipsedContextMenuProps) => (
  <ContextMenu {...props}>
    <Button
      className="context-menu__ellipsis-button"
      dataId={buttonDataId}
      variant={VARIANT.TEXT}
      disabled={disabled}
      onClick={(e) => e.stopPropagation()}
    >
      {Icons.ellipsis().size(size).reactComponent()}
    </Button>
  </ContextMenu>
);
