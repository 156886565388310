import React from 'react';
import classNames from 'classnames';
import {Button} from 'app/components/sharedReactComponents/Button';
import {OnlineIndicator} from 'app/components/sharedReactComponents/OnlineIndicator';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {Icons} from 'app/util/icons';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {useSelectAllWithKeyboard} from 'app/components/FleetManager/useSelectAllWithKeyboard';
import {Callback, ClassName, Sx} from 'app/types/common';
import {SeparatorVertical} from 'app/components/sharedReactComponents/Separator';
import {UpgradeToProTooltip} from 'app/components/FleetManager/UpgradeToProTooltip';
import {Tooltip, TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';
import {noop} from 'app/util/noop';
import {EventIcon} from 'app/components/sharedReactComponents/Events/shared/EventIcon/EventIcon';
import {Box} from '@mui/material';

interface ItemProps extends ClassName {
  count: number;
  title: string;
  icon?: React.ReactNode;
  label: React.ReactNode;
  compact?: boolean;
  enabled: boolean;
  canEdit: boolean;
  onClick: Callback;
}

const Item: React.FC<ItemProps> = ({
  className,
  count,
  title,
  icon,
  label,
  compact,
  enabled,
  canEdit,
  onClick,
  ...elementProps
}) => {
  if (count === 0) {
    return null;
  }

  return (
    <Tooltip
      content={!enabled ? <UpgradeToProTooltip canEdit={canEdit} /> : undefined}
      interactive={true}
      trigger="click"
      placement={TOOLTIP_PLACEMENT.BOTTOM}
      theme={THEME.WHITE}
    >
      <Button
        className={classNames(
          'fleet-manager-status-panel__item',
          compact && 'fleet-manager-status-panel__item--compact',
          className,
        )}
        size={SIZE.S}
        title={title}
        onClick={enabled ? onClick : undefined}
        {...elementProps}
      >
        {icon && <span className="fleet-manager-status-panel__item-icon">{icon}</span>}

        <span className="fleet-manager-status-panel__item-label">{label}</span>
      </Button>
    </Tooltip>
  );
};

interface SelectAllProps {
  deviceGroupsCount: number;
  allItemsSelected: boolean;
  enabled: boolean;
  canEdit: boolean;
  isUnify?: boolean;
  onClickSelectAll: Callback;
}

interface IconAndLabel {
  icon: React.ReactNode;
  label: string;
}

function getIconAndLabel(allSelected: boolean): IconAndLabel {
  const icon = allSelected ? Icons.checkboxChecked() : Icons.checkboxUnchecked();
  const label = allSelected ? 'Deselect' : 'Select All';

  return {
    icon: icon.size(SIZE.S).reactComponent(),
    label,
  };
}

export const SelectAllButton = ({
  deviceGroupsCount,
  allItemsSelected,
  isUnify = false,
  enabled,
  canEdit,
  onClickSelectAll,
}: SelectAllProps) => {
  useSelectAllWithKeyboard({onSelect: enabled ? onClickSelectAll : noop});

  const {icon, label} = getIconAndLabel(allItemsSelected);

  const title = `${allItemsSelected ? 'Deselect' : 'Select'} all ${
    isUnify ? 'projects' : 'devices'
  } ${deviceGroupsCount > 0 ? 'and groups' : ''} (Ctrl + A)`;

  return (
    <Tooltip
      content={!enabled ? <UpgradeToProTooltip canEdit={canEdit} /> : undefined}
      interactive={true}
      trigger="click"
      placement={TOOLTIP_PLACEMENT.BOTTOM}
      theme={THEME.WHITE}
    >
      <Button
        className="fleet-manager-status-panel__item fleet-manager-status-panel__item--select-all"
        dataId="select_all_devices"
        size={SIZE.S}
        theme={THEME.SECONDARY}
        variant={VARIANT.OUTLINE}
        title={title}
        onClick={enabled ? onClickSelectAll : undefined}
      >
        <span className="fleet-manager-status-panel__item-icon">{icon}</span>

        <span className="fleet-manager-status-panel__item-label">{label}</span>
      </Button>
    </Tooltip>
  );
};

type Props = Sx & {
  onlineDevicesCount: number;
  offlineDevicesCount: number;
  deviceGroupsCount: number;
  recordingDevicesCount: number;
  streamingDevicesCount: number;
  activeDevicesCount: number;
  ongoingEventsCount: number;
  allItemsSelected: boolean;
  enabled: boolean;
  canEdit: boolean;
  onClickSelectOnlineDevices: () => void;
  onClickSelectOfflineDevices: () => void;
  onClickSelectDeviceGroups: () => void;
  onClickSelectAllActiveDevices: () => void;
  onClickSelectAllRecordingDevices: () => void;
  onClickSelectAllStreamingDevices: () => void;
  onClickSelectAllOngoingEventsDevices: () => void;
  onClickSelectAll: () => void;
};

export function StatusPanel({
  sx,
  onlineDevicesCount,
  offlineDevicesCount,
  deviceGroupsCount,
  recordingDevicesCount,
  streamingDevicesCount,
  activeDevicesCount,
  ongoingEventsCount,
  allItemsSelected,
  canEdit,
  enabled,
  onClickSelectOnlineDevices,
  onClickSelectOfflineDevices,
  onClickSelectDeviceGroups,
  onClickSelectAllActiveDevices,
  onClickSelectAllRecordingDevices,
  onClickSelectAllStreamingDevices,
  onClickSelectAllOngoingEventsDevices,
  onClickSelectAll,
}: Props) {
  // to parent
  if (onlineDevicesCount === 0 && offlineDevicesCount === 0 && deviceGroupsCount === 0) {
    return null;
  }

  return (
    <Box sx={sx} className={classNames('fleet-manager-status-panel')}>
      <div className="fleet-manager-status-panel__content">
        <Item
          className="fleet-manager-status-panel__item--online"
          data-id="select_online_devices_button"
          title="Click to select all online devices"
          canEdit={canEdit}
          enabled={enabled}
          count={onlineDevicesCount}
          icon={<OnlineIndicator online={true} size={SIZE.S} />}
          label={`${onlineDevicesCount} ${pluralizeN('device', onlineDevicesCount)} online`}
          onClick={onClickSelectOnlineDevices}
        />

        <Item
          className="fleet-manager-status-panel__item--offline"
          data-id="select_offline_devices_button"
          title="Click to select all offline devices"
          canEdit={canEdit}
          enabled={enabled}
          count={offlineDevicesCount}
          icon={<OnlineIndicator online={false} size={SIZE.S} />}
          label={`${offlineDevicesCount} ${pluralizeN('device', offlineDevicesCount)} offline`}
          onClick={onClickSelectOfflineDevices}
        />

        <Item
          count={deviceGroupsCount}
          title="Click to select all groups"
          data-id="select_all_groups_button"
          canEdit={canEdit}
          enabled={enabled}
          icon={Icons.groupFolder().size(SIZE.S).reactComponent()}
          label={`${deviceGroupsCount} ${pluralizeN('group', deviceGroupsCount)}`}
          onClick={onClickSelectDeviceGroups}
        />

        {activeDevicesCount > 0 && (
          <SeparatorVertical className="fleet-manager-status-panel__separator" />
        )}

        <Item
          count={activeDevicesCount}
          data-id="select_all_events_devices_button"
          title="Click to select all recording, streaming and transcribing devices"
          canEdit={canEdit}
          enabled={enabled}
          label={`${activeDevicesCount} active ${pluralizeN('device', activeDevicesCount)}`}
          onClick={onClickSelectAllActiveDevices}
        />

        <Item
          className="fleet-manager-status-panel__item--recording"
          data-id="select_all_recording_button"
          count={recordingDevicesCount}
          title="Click to select all recording devices"
          canEdit={canEdit}
          enabled={enabled}
          icon={Icons.record().size(SIZE.S).reactComponent()}
          label={recordingDevicesCount}
          compact={true}
          onClick={onClickSelectAllRecordingDevices}
        />

        <Item
          className="fleet-manager-status-panel__item--streaming"
          data-id="select_all_streaming_button"
          count={streamingDevicesCount}
          title="Click to select all streaming devices"
          canEdit={canEdit}
          enabled={enabled}
          icon={Icons.streaming().size(SIZE.S).reactComponent()}
          label={streamingDevicesCount}
          compact={true}
          onClick={onClickSelectAllStreamingDevices}
        />

        {ongoingEventsCount > 0 && (
          <SeparatorVertical className="fleet-manager-status-panel__separator" />
        )}

        <Item
          className="fleet-manager-status-panel__item--events"
          data-id="select_devices_ongoing_events_button"
          count={ongoingEventsCount}
          title="Click to select all devices with ongoing events"
          canEdit={canEdit}
          enabled={enabled}
          icon={<EventIcon type="ongoing" />}
          label={`${ongoingEventsCount} ongoing ${pluralizeN('event', ongoingEventsCount)}`}
          onClick={onClickSelectAllOngoingEventsDevices}
        />

        <SelectAllButton
          deviceGroupsCount={deviceGroupsCount}
          allItemsSelected={allItemsSelected}
          canEdit={canEdit}
          enabled={enabled}
          onClickSelectAll={onClickSelectAll}
        />
      </div>
    </Box>
  );
}
