import React from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';

export const PropsListItem: React.FC<ClassName> = ({
  className,
  children,
  ...elementProps
}) => {
  return (
    <li
      className={classNames('props-list__item', className)}
      {...elementProps}
    >
      {children}
    </li>
  );
};
