import React from 'react';
import {Box, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {EventsList} from 'app/components/sharedReactComponents/Events/List/EventsList/EventsList';
import {Schedule} from 'app/domain/schedule';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';

type ListActions = Pick<
  React.ComponentProps<typeof EventsList>,
  | 'onStop'
  | 'onDelete'
  | 'onEdit'
  | 'onStart'
  | 'onExtend'
  | 'onResume'
  | 'onPause'
  | 'actualEventIds'
  | 'onConfirm'
>;

interface Props extends Sx, ListActions {
  devices?: Map<string, PearlMasterDeviceModel>;
  ongoing: Schedule.Event[];
  scheduled: Schedule.Event[];
  completed: Schedule.Event[];
}

export function DeviceEvents({
  sx,
  ongoing,
  scheduled,
  completed,
  devices,
  actualEventIds,
  ...props
}: Props) {
  return (
    <Box sx={sx}>
      {ongoing.length > 0 && (
        <Box mb={2}>
          <Typography fontWeight={600} fontSize={16} mb={1}>
            Ongoing
          </Typography>

          <EventsList {...props} devices={devices} events={ongoing} showTitle={false} />
        </Box>
      )}

      {scheduled.length > 0 && (
        <Box mb={2}>
          <Typography mb={1} fontSize={16} fontWeight={600}>
            Scheduled
          </Typography>

          <EventsList
            {...props}
            events={scheduled}
            devices={devices}
            showTitle={false}
            actualEventIds={actualEventIds}
          />
        </Box>
      )}

      {completed.length > 0 && (
        <Box>
          <Typography mb={1} fontSize={16} fontWeight={600}>
            Completed
          </Typography>

          <EventsList {...props} devices={devices} events={completed} showTitle={false} />
        </Box>
      )}
    </Box>
  );
}
