import React from 'react';
import classNames from 'classnames';
import {PREVIEW_MODE} from 'app/components/DeviceDetails/ChannelDetailsTab/constants';
import {DeviceStatusPreviewControls} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStatusPreview/DeviceStatusPreviewControls';
import {DeviceStatusHDPreview} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStatusPreview/DeviceStatusHDPreview';
import {Callback, ClassName} from 'app/types/common';
import {Preview} from 'app/components/sharedReactComponents/Preview/Preview';
import {SIZE} from 'app/constants';
import {getPreviewUpdateStrategy} from 'app/components/sharedReactComponents/Preview/utils';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

interface Props extends ClassName {
  previewMode: PREVIEW_MODE;
  device: AnyDeviceModelType;
  channelDevice: any;
  muted: boolean;
  onMuteClick: Callback;
  onPreviewModeChange: Callback;
}

export const DeviceStatusPreview: React.VFC<Props> = ({
  className,
  device,
  channelDevice,
  muted,
  previewMode,
  onMuteClick,
  onPreviewModeChange,
}) => {
  const version = device.getFirmwareVersion() as string;
  const switcher = version !== '4.17.0';

  return (
    <div className={classNames('device-status-preview', className)}>
      {(previewMode === PREVIEW_MODE.LIVE_SD || previewMode === PREVIEW_MODE.OFFLINE) && (
        <Preview
          className="device-status-preview__sd"
          id={channelDevice.getId()}
          updateStrategy={getPreviewUpdateStrategy(device)}
          size={SIZE.XXL}
        />
      )}

      {previewMode === PREVIEW_MODE.LIVE_HD && (
        <DeviceStatusHDPreview
          deviceId={
            ModelService.isChannel(channelDevice?.getModelName())
              ? channelDevice.getId()
              : device.getId()
          }
          muted={muted}
        />
      )}

      <DeviceStatusPreviewControls
        className="device-status-preview__controls"
        muted={muted}
        previewMode={previewMode}
        switcher={switcher}
        available={device.capabilities.hls}
        switcherTip={
          !switcher
            ? `High definition preview is not available for devices running firmware version ${version}`
            : ''
        }
        onMuteClick={onMuteClick}
        onPreviewModeChange={onPreviewModeChange}
      />
    </div>
  );
};
