import React, {forwardRef, useMemo, useState} from 'react';
import {Button, Card, IconButton, ThemeProvider} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {downloadFile} from 'app/util/download';
import {
  Files,
  FilesApi,
  DeleteRecordingsDialog,
  DownloadRecordingsDialog,
} from 'app/components/entities/files';
import {darkTheme} from 'app/themes/app';
import {Notifications} from 'app/components/Notifications';
import {TotalAndSize} from '../TotalAndSize/TotalAndSize';

const rootStyles: StyleSx = {
  p: 2,
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  height: 64,
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: 2,
};

type Props = Sx & {
  recordings: Files.Recording[];
  onClose: () => void;
};

export const BatchRecordings = forwardRef<HTMLDivElement, Props>(
  ({sx, recordings, onClose}, ref) => {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [downloadDialog, setDownloadDialog] = useState(false);

    const handleDelete = async () => {
      try {
        await FilesApi.batchDelete(recordings.map((r) => r.id));
        setDeleteDialog(false);
      } catch {
        Notifications.addErrorNotification('Failed to delete recording(s)');
      }
    };

    const uploaded = useMemo(() => recordings.filter((r) => r.uploaded), [recordings]);

    const handleDownload = async () => {
      uploaded.forEach((r) => {
        downloadFile(FilesApi.getDownloadUrl(r.id), r.name);
      });
    };

    return (
      <>
        <DeleteRecordingsDialog
          open={deleteDialog}
          onDelete={handleDelete}
          recordings={recordings.map((r) => r.name)}
          onClose={() => setDeleteDialog(false)}
        />

        <DownloadRecordingsDialog
          open={downloadDialog}
          recordings={uploaded.map((r) => r.name)}
          onDownload={handleDownload}
          onClose={() => setDownloadDialog(false)}
        />

        <ThemeProvider theme={darkTheme}>
          <Card
            data-id="recordings-batch"
            ref={ref}
            sx={packSx(rootStyles, sx)}
            variant="elevation"
            elevation={8}
          >
            <TotalAndSize dataId="batch-summary" files={recordings} />

            {/* Disabled because WCX-8638 */}
            {/* <Button
              data-id="download-btn"
              sx={{ml: 'auto'}}
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadIcon />}
              onClick={() => setDownloadDialog(true)}
            >
              Download
            </Button> */}

            <Button
              data-id="delete-btn"
              sx={{ml: 'auto', minWidth: 0}}
              variant="outlined"
              color="info"
              startIcon={<DeleteIcon />}
              onClick={() => setDeleteDialog(true)}
            >
              Remove
            </Button>

            <IconButton data-id="close-batch-btn" onClick={onClose}>
              <ClearIcon />
            </IconButton>
          </Card>
        </ThemeProvider>
      </>
    );
  },
);
