import {ValidatedInputModel} from 'app/components/UploadLocations/Models/ValidatedInputModel';

interface Params {
  Name?: string;
  StorageID?: string;
  Server?: string;
  Username?: string;
  Password?: string;
  Path?: string;
  Port: string;
}

class BaseFTPLocationModel {
  id: string;
  name: ValidatedInputModel;
  address: ValidatedInputModel;
  username: ValidatedInputModel;
  password: ValidatedInputModel;
  path: ValidatedInputModel;
  port: ValidatedInputModel;

  constructor({
    Name = '',
    StorageID = '',
    Server = '',
    Username = '',
    Password = '',
    Path = '',
    Port,
  }: Params) {
    this.id = StorageID;
    this.name = new ValidatedInputModel('name', Name);
    this.address = new ValidatedInputModel('address', Server);
    this.username = new ValidatedInputModel('username', Username);
    this.password = new ValidatedInputModel('password', Password);
    this.path = new ValidatedInputModel('path', Path);
    this.port = new ValidatedInputModel('port', Port);
  }
}

export {BaseFTPLocationModel};
