/* eslint-disable @typescript-eslint/member-ordering */
import {front} from 'app/api/CirrusApi';
import {noop} from 'app/util/noop';
import {forEachObjIndexed} from 'ramda';

type BaseConfigurationItem = {
  id: string;
  title: string;
  description?: string;
};

type ConfigurationItemEnum = BaseConfigurationItem & {
  value: string;
  type: {
    name: 'enum';
    options: {
      items: Array<{value: string; title: string}>;
    };
  };
};

type ConfigurationItemBoolean = BaseConfigurationItem & {
  value: boolean;
  type: {
    name: 'bool';
  };
};

type ConfigurationItemString = BaseConfigurationItem & {
  value: string;
  type: {
    name: 'string';
  };
};

type ConfigurationItem = ConfigurationItemString | ConfigurationItemBoolean | ConfigurationItemEnum;

class TranscriptionConfigurationModel {
  private initialized = false;
  private configuration: ConfigurationItem[] = [];
  private readonly promise: Promise<unknown>;
  private resolve: any = noop;
  private reject: any = noop;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  private init() {
    this.initialized = true;

    return front.transcribing().configuration().get()
      .then((response: ConfigurationItem[]) => {
        this.configuration = [];

        forEachObjIndexed((option: any) => {
          delete option.config_key;
          this.configuration.push(option);
        })(response);

        this.resolve(this.configuration);
      })
      .catch(() => this.reject());
  }

  /**
   * @param {Object} configuration
   * @returns {Promise<Array>}
   */
  async processSettings(configuration: Record<string, any>) {
    if (this.initialized === false) {
      this.init();
    }

    return this.promise
      .then(() => {
        return this.configuration.map(option => {
          return {
            ...option,
            value: configuration[option.id],
          };
        });
      });
  }

  prepareSettings(settings) {
    return settings.reduce((acc, setting) => {
      acc[setting.id] = setting.value;
      return acc;
    }, {});
  }
}

export const TranscriptionConfiguration = new TranscriptionConfigurationModel();
