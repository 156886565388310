import React from 'react';
import {Link, Skeleton, Typography} from '@mui/material';
import {router} from 'app/router/main';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {DataId, Sx} from 'app/types/common';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';

type Props = Sx &
  DataId & {
    deviceId?: string;
    name?: string;
    loading?: boolean;
  };

export function RecordingDevice({dataId, sx, deviceId, name, loading}: Props) {
  if (!deviceId || !name) {
    if (loading) {
      return (
        <Tooltip content="Loading device">
          <Skeleton sx={sx} />
        </Tooltip>
      );
    }

    return (
      <Typography sx={sx} color="text.disabled">
        Deleted
      </Typography>
    );
  }

  return (
    <Link
      data-id={dataId}
      sx={sx}
      href={router.url('deviceDetails', {deviceId}, {activeTab: 'recordings'})}
    >
      <OverflowTooltip rowsNumber={1}>{name}</OverflowTooltip>
    </Link>
  );
}
