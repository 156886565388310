import {front} from 'app/api/CirrusApi';
import {GetAbortCallback} from 'app/api/types';

export class SrtApiService {
  static async mute(
    meetingId: string,
    participantId: string,
    mute: App.Connect.StreamMute,
  ): Promise<void> {
    return front
      .econnect()
      .meetings(meetingId)
      .participants(participantId)
      .stream()
      .settings()
      .mute()
      .put(mute);
  }

  static async muteFeed(meetingId: string, mute: App.Connect.StreamMute) {
    return front.econnect().meetings(meetingId).injection().stream().settings().mute().put(mute);
  }

  static async start(meetingId: string, participantId: string): Promise<void> {
    return front.econnect().meetings(meetingId).participants(participantId).stream().start().post();
  }

  static async startFeed(meetingId: string): Promise<void> {
    return front.econnect().meetings(meetingId).injection().stream().start().post();
  }

  static async setParticipantSettings(
    meetingId: string,
    participantId: string,
    contract: Contracts.Connect.StreamSettings<Contracts.Connect.ParticipantStreamSetting>,
  ): Promise<void> {
    return front
      .econnect()
      .meetings(meetingId)
      .participants(participantId)
      .stream()
      .settings()
      .put(contract);
  }

  static async setFeedSettings(
    meetingId: string,
    contract: Contracts.Connect.StreamSettings<Contracts.Connect.FeedStreamSetting>,
  ): Promise<void> {
    return front.econnect().meetings(meetingId).injection().stream().settings().put(contract);
  }

  static async stop(meetingId: string, participantId: string): Promise<void> {
    return front.econnect().meetings(meetingId).participants(participantId).stream().stop().post();
  }

  static async stopFeed(meetingId: string) {
    return front.econnect().meetings(meetingId).injection().stream().stop().post();
  }

  static async getParticipantStreamStatus(
    meetingId: string,
    participantId: string,
    getAbort?: GetAbortCallback,
  ): Promise<Contracts.Connect.StreamingStatus> {
    return front
      .econnect()
      .meetings(meetingId)
      .participants(participantId)
      .stream()
      .status()
      .get(undefined, {getAbort});
  }

  static async getReturnFeedStreamStatus(
    meetingId: string,
    getAbort?: GetAbortCallback,
  ): Promise<Contracts.Connect.StreamingStatus> {
    return front
      .econnect()
      .meetings(meetingId)
      .injection()
      .stream()
      .status()
      .get(undefined, {getAbort});
  }

  static async getOnlineCloudEntities(): Promise<Array<Contracts.Connect.CloudEntity>> {
    return front.devices().ssrt().get();
  }

  static async getEntitySources(id: string): Promise<Array<App.Connect.EntitySource>> {
    return front.devices().ssrt(id).inputs().get();
  }
}
