import {BaseValidator} from 'app/util/validators/BaseValidator';

const ALLOWED_CHARS = 'A-Za-z0-9_-+ #()[]';
export const ALLOWED_CHARS_REGEXP = /^[A-Za-z0-9_\-+ #()[\]]+$/;

const ERROR_MESSAGE = `Allowed characters: ${ALLOWED_CHARS}`;

const isAllCharsIsAllowed = (str: string) => ALLOWED_CHARS_REGEXP.test(str);

class DevicePresetNameValidator extends BaseValidator {
  message = ERROR_MESSAGE;
  type = 'device_preset_name';

  validate(value: string): boolean {
    return isAllCharsIsAllowed(value);
  }
}

export {DevicePresetNameValidator};
