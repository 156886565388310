import React, {Component} from 'react';
import classNames from 'classnames';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {Callback, ClassName} from 'app/types/common';
import {FORM_CONTROL_TYPE} from 'app/components/sharedReactComponents/FormControl/constants';

function isValid(formData, requiredFields) {
  const hasInvalidItem = requiredFields.some(name => {
    const item = formData[name];
    return typeof item === 'string' && item.trim() === '';
  });

  return hasInvalidItem === false;
}

interface FormData {
  name: string;
  url: string;
  key: string;
  valid: boolean;
}
interface Props extends ClassName {
  requiredFields: any[];
  onChange: Callback;
  formData: FormData;
}

export class Form extends Component<Props> {
  handleChange = ({target: {name, value}}) => {
    const {
      formData,
      requiredFields,
      onChange,
    } = this.props;

    const newFormData = {
      ...formData,
      [name]: value,
    };

    onChange({
      ...newFormData,
      valid: isValid(newFormData, requiredFields),
    });
  };

  render() {
    const {
      className,
      formData,
    } = this.props;

    return (
      <div className={classNames('create-rtmp-streaming-destination-prompt__form', className)}>
        <FormControl
          className="create-rtmp-streaming-destination-prompt__input"
          name="name"
          value={formData.name}
          label="Name"
          placeholder="RTMP destination 1"
          required={true}
          autoFocus={true}
          onChange={this.handleChange}
        />

        <FormControl
          className="create-rtmp-streaming-destination-prompt__input"
          name="url"
          value={formData.url}
          label="Server url"
          type={FORM_CONTROL_TYPE.URL}
          pattern="rtmp.?://.*"
          required={true}
          onChange={this.handleChange}
        />

        <FormControl
          className="create-rtmp-streaming-destination-prompt__input"
          name="key"
          value={formData.key}
          label="Streaming key"
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
