import React, {Component} from 'react';
import classNames from 'classnames';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {
  THEME,
  VARIANT,
  SIZE,
} from 'app/constants';

import {Icons} from 'app/util/icons';

import {FTPLocation} from 'app/components/UploadLocations/FTPLocation';
import {SFTPLocation} from 'app/components/UploadLocations/SFTPLocation';

import {STORAGE_TYPE} from 'app/components/UploadLocations/constants';
import {Callback} from 'app/types/common';

const CONNECTION_SUCCESS = 'Success';
const CONNECTION_ERROR = 'Error';

interface Props {
  currentLocation: any;
  model?: any;
  uploadTypes: any[];
  onChangeUploadType?: Callback;
  switchLocationType: Callback;
  testConnection: Callback;
}

const LocationConfiguration = observer(
  class LocationConfiguration extends Component<Props> {
    testConnectionResult: any;
    isTestingConnection: any;

    constructor(props) {
      super(props);

      this.testConnectionResult = observable.box('');
      this.isTestingConnection = observable.box(false);

      this.changeUploadType = this.changeUploadType.bind(this);
    }

    changeUploadType({target: {value}}) {
      const {
        currentLocation: {id},
        switchLocationType
      } = this.props;

      switchLocationType(id, value);
    }

    handleClickTestConnection = () => {
      this.testConnection();
    };

    testConnection() {
      const {
        testConnection,
        currentLocation: {id}
      } = this.props;

      this.isTestingConnection.set(true);
      this.testConnectionResult.set('');

      testConnection(id)
        .then(result => {
          if (result.Success) {
            this.testConnectionResult.set(CONNECTION_SUCCESS);
          } else {
            this.testConnectionResult.set(`${CONNECTION_ERROR}: ${result.Error as string}`);
          }
        })
        .finally(() => this.isTestingConnection.set(false));
    }

    renderLocationConfiguration(location, uploadTypes, onChangeUploadType) {
      const props = {
        model: location,
        uploadTypes,
        onChangeUploadType
      };

      switch (location.type) {
        case STORAGE_TYPE.FTP:
          return <FTPLocation {...props}/>;
        default:
          return <SFTPLocation {...props}/>;
      }
    }

    render() {
      const {
        uploadTypes,
        currentLocation,
      } = this.props;

      const isTestingConnectionFailed = this.testConnectionResult
        .get()
        .startsWith(CONNECTION_ERROR);

      if (!currentLocation) {
        return null;
      }

      return (
        <div className="upload-location-configuration">
          <h3 className="upload-location-configuration__title">
            Configure your shared network location.
          </h3>

          <div className="upload-location-configuration__form">
            {this.renderLocationConfiguration(
              currentLocation,
              uploadTypes,
              this.changeUploadType
            )}

            {this.isTestingConnection.get() && (
              <div className="upload-location-configuration__form-loading">
                <div className="upload-location-configuration__form-loading-spinner">
                  {Icons.spinner().label('Testing connection...').size(SIZE.L).reactComponent()}
                </div>
              </div>
            )}
          </div>

          <div className="upload-location-configuration__test-connection">
            <div
              className={classNames('upload-location-configuration__test-connection-result', {
                'upload-location-configuration__test-connection-result--failed': isTestingConnectionFailed,
              })}
            >
              {this.testConnectionResult.get()}
            </div>

            <ProgressButton
              className="upload-location-configuration__test-connection-btn"
              theme={THEME.PRIMARY}
              variant={VARIANT.SOLID}
              disabled={this.isTestingConnection.get()}
              loading={this.isTestingConnection.get()}
              onClick={this.handleClickTestConnection}
            >
              {Icons.lightning().size(SIZE.S).label('Connect').reactComponent()}
            </ProgressButton>
          </div>
        </div>
      );
    }
  }
);

export {LocationConfiguration};
