import {useQuery} from '@tanstack/react-query';
import {AlertsMapper} from 'app/components/entities/alerts';
import {AlertsApi} from 'app/components/entities/alerts/api/AlertsApi';

export function useAlertSettingsQuery() {
  return useQuery({
    queryKey: ['alerts', 'settings'],
    queryFn: async () => {
      const res = await AlertsApi.getSettings();
      return res.map(AlertsMapper.mapSettings);
    },
    refetchInterval: 30 * 1000,
  });
}
