import React, {Component} from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';

export class PropsListValue extends Component<ClassName> {
  render() {
    const {
      className,
      children,
    } = this.props;

    return (
      <div className={classNames('props-list__value', className)}>
        {children}
      </div>
    );
  }
}
