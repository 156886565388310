import React from 'react';
import {Sx} from 'app/types/common';
import {TierCard} from 'app/components/BillingManager/dialogs/shared/TierCard/TierCard';
import {Box, Typography} from '@mui/material';
import {CurrentTierMark} from 'app/components/sharedReactComponents/CurrentTierMark/CurrentTierMark';
import {THEME, VARIANT} from 'app/constants';
import {isNil} from 'app/util/isNil';
import {Button} from 'app/components/sharedReactComponents/Button';
import {backgrounds, palette} from 'app/themes/app';
import {formatAbbreviatedDateWithYear} from 'app/util/time';
import {packSx} from 'app/util/packSx/packSx';

interface Props extends Sx {
  current: boolean;
  expireDate?: TimeStampSeconds;
}

export function EnterpriseTier({current, expireDate, sx}: Props) {
  const renderSubtitle = () => {
    if (current) {
      return <CurrentTierMark />;
    }

    return <Typography>Annual plan</Typography>;
  };

  const renderAction = () => {
    if (current) {
      if (!isNil(expireDate)) {
        return (
          <Typography color={palette.blue}>
            Paid until{' '}
            <span data-id="enterprise-expire-date">
              {formatAbbreviatedDateWithYear(expireDate)}
            </span>
          </Typography>
        );
      }

      return null;
    }

    return (
      <Button
        dataId="tier-action"
        variant={VARIANT.OUTLINE}
        theme={THEME.SUCCESS}
        href="https://www.epiphan.com/company/contact-us/"
        target="_blank"
      >
        Let's talk
      </Button>
    );
  };

  return (
    <TierCard
      dataId="enterprise-tier"
      sx={packSx({bgcolor: current ? backgrounds.blue : undefined}, sx)}
      title="Reserved capacity"
      subtitle={renderSubtitle()}
      description={
        <Box>
          <Typography variant="h5" mt={3} mb={0.5} textAlign="center">
            Save up to 75%
          </Typography>
          <Typography mb={8} textAlign="center">
            with an annual contract
          </Typography>
          <Typography textAlign="center" sx={{color: palette.darkerGray}}>
            Our discounts grow as your business scales.
          </Typography>
        </Box>
      }
      action={renderAction()}
    />
  );
}
