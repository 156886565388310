import React from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  control: React.ReactNode;
  label: React.ReactNode;
  component?: React.ElementType;
}

export const FormControlLabel: React.FC<Props> = ({
  className,
  label,
  control,
  component = 'label',
}: Props) => {
  const LabelComponent = component;

  return (
    <LabelComponent className={classNames('form-control-label', className)}>
      <span className="form-control-label__label">
        {label}
      </span>

      {control}
    </LabelComponent>
  );
};
