import React, {useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useMounted} from 'app/hooks/useIsMounted';
import {Prompt} from 'app/types/common';

type Props = {
  loading: boolean;
  onUpgrade: () => Promise<void>;
  onClose: () => void;
};

function UpgradeFirmware({loading, onUpgrade, onClose}: Props) {
  return (
    <Box>
      <DialogTitle>Upgrade firmware</DialogTitle>

      <DialogContent>
        <Typography textAlign="center">
          During update process, device will be offline. <br />
          Device will reboot automatically after the firmware has been downloaded.
        </Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="confirm-upgrade-btn"
          sx={{minWidth: 70}}
          loading={loading}
          variant="contained"
          color="secondary"
          disableRipple={false}
          onClick={onUpgrade}
        >
          Upgrade
        </LoadingButton>

        <Button
          data-id="cancel-upgrade-btn"
          sx={{minWidth: 70}}
          variant="outlined"
          color="info"
          disableRipple={false}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
}

type PromptProps = Prompt & {
  onUpgrade: () => Promise<void>;
};

export function UpgradeFirmwareDialog({open, onUpgrade, onClose}: PromptProps) {
  const [loading, setLoading] = useState(false);

  const mounted = useMounted();

  const handleUpgrade = async () => {
    try {
      setLoading(true);
      await onUpgrade();
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <UpgradeFirmware loading={loading} onUpgrade={handleUpgrade} onClose={onClose} />
    </Dialog>
  );
}
