/* eslint-disable @typescript-eslint/member-ordering */
import {front} from 'app/api/CirrusApi';
import {formatUptime} from 'app/util/time';
import {
  DeviceCommand,
  DeviceModel as DeviceModelName,
  DeviceWarningId,
  HealthStatus,
} from 'app/components/DeviceDetails/constants';
import {DeviceCapabilities} from 'app/models/Capabilities/DeviceCapabilities';
import {Notifications} from 'app/components/Notifications';
import {isEmptyObject} from 'app/util/isEmptyObject';
import {createDevicePublisherModel} from 'app/models/Device/Publisher/Fabric';
import {getDeviceHealth} from 'app/components/DeviceDetails/Models/utils';
import {DeviceSettingsCollection} from 'app/models/Device/Settings/DeviceSettingsCollection';
import {STREAMING_SERVICE_TYPE} from 'app/components/StreamingServices/constants';
import {DeviceCommandDispatcher} from 'app/util/CommandDispatcher';
import {WarningSeverity} from 'app/constants/deviceWarningSeverity';
import type {
  CommandOptions,
  Device,
  DeviceWarning,
  DeviceActiveStreamingInfo,
} from 'app/components/DeviceDetails/Models/types';
import {DevicePublisherModel} from 'app/models/Device/Publisher/DevicePublisherModel';
import {TimeStampSeconds} from 'app/types/common';
import {createDeviceSettingsCollectionGetter} from 'app/store/models/utils';
import {DeviceStatus} from 'app/store/dto/device/Device';
import {getDeviceModel} from 'app/store/models/device/utils';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {Edge} from 'app/domain/edge';
import {isNil} from 'app/util/isNil';
import {EdgeContract} from 'app/contracts/edge';
import {CloudMapper} from 'app/util/mappers/CloudMapper/CloudMapper';
import {Cloud} from 'app/domain/cloud';
import {CloudContract} from 'app/contracts/cloud';
import {Hardware, HardwareMapper} from 'app/components/entities/hardware';

const defaultCapabilities: CloudContract.UnitCapabilities = {
  recording: {enabled: false},
  remote_login: {enabled: false},
  start_hls_stream: {enabled: false},
  streaming: {enabled: false},
  transcribing: {enabled: false},
  use_cms: {enabled: false},
  use_preset: {enabled: false},
  batch_selection: {enabled: false},
  use_groups: {enabled: false},
  audio_levels: {enabled: false},
  epiphan_cms: {enabled: false},
  file_management: {enabled: false},
  reboot: {enabled: false},
};

// rework
export class DeviceModel {
  /**
   * @deprecated channelPublishers in Channel
   */
  private devicePublisherModels: DevicePublisherModel[] | undefined;

  /**
   * @deprecated obsolete
   */
  protected cachedDeviceName = '';
  /**
   * @deprecated obsolete
   */
  protected device: Device;

  /**
   * @deprecated deviceSettings in Device
   */
  protected deviceSettingsCollection?: DeviceSettingsCollection;

  private deviceSettingsCollectionGetter?: () => Promise<DeviceSettingsCollection>;

  /**
   * @deprecated obsolete
   */
  protected pendingStatusRequests: any = {};
  /**
   * @deprecated id in Device
   */
  protected deviceId: string;

  capabilities: Cloud.UnitCapabilities;

  hardware = new Set<Hardware.Capability>();

  groupId: string;
  groupName: string;

  constructor(props: Device) {
    this.device = props;
    this.deviceId = this.device.Id;

    this.groupId = props.GroupId ?? '';
    this.groupName = props.GroupName ?? '';

    this.capabilities = CloudMapper.mapUnitCapabilities(
      props.UnitCapabilities ?? defaultCapabilities,
    );

    const list = props.Capabilities?.map((c) => HardwareMapper.mapCapability(c)) ?? [];
    this.hardware = new Set(list);

    this.saveCachedName();
  }

  /**
   * @deprecated moved to CommandService
   */
  async sendCommand(command: string, options: CommandOptions = {}): Promise<any> {
    return front
      .devices(this.getId())
      .task()
      .post({cmd: command, ...options});
  }

  /**
   * @deprecated reboot in DeviceCommandsFeature
   */
  async sendRebootCommand(): Promise<any> {
    return this.sendCommand(DeviceCommand.Reboot);
  }

  /**
   * @deprecated audioLevels in DeviceCommandsFeature
   */
  async sendLevelsStartCommand(): Promise<any> {
    return DeviceCommandDispatcher.audioLevels(this.getId());
  }

  /**
   * @deprecated channelPreviews in DeviceCommandsFeature
   */
  async sendChannelPreviewsStartCommand(): Promise<any> {
    return DeviceCommandDispatcher.channelPreviews(this.getId());
  }

  /**
   * @deprecated sourcePreviews in DeviceCommandsFeature
   */
  async sendSourcePreviewsStartCommand(): Promise<any> {
    return DeviceCommandDispatcher.sourcePreviews(this.getId());
  }

  /**
   * @deprecated clearFootage in DeviceCommandsFeature
   */
  async sendClearFootageCommand(): Promise<any> {
    return this.sendCommand(DeviceCommand.FootageClear);
  }

  /**
   * @deprecated updateFirmware in DeviceCommandsFeature
   */
  async sendUpdateFirmwareCommand(): Promise<any> {
    return this.sendCommand(DeviceCommand.FirmwareUpdate);
  }

  /**
   * @deprecated obsolete
   */
  protected async sendPublisherConfigureCommand(options: CommandOptions): Promise<any> {
    return this.sendCommand(DeviceCommand.PublisherConfigure, options);
  }

  /**
   * @deprecated selectPublisher in ChannelCommandsFeature
   */
  async selectPublisher(publisherId: string, value: boolean): Promise<any> {
    return this.sendPublisherConfigureCommand({
      params: {
        id: publisherId,
        config: {
          'single-touch': value,
        },
      },
    });
  }

  /**
   * @deprecated obsolete
   */
  async startStopCommand(commandName: DeviceCommand, started: boolean): Promise<any> {
    this.pendingStatus(commandName, true);

    return this.sendCommand(`${commandName}.${started ? 'stop' : 'start'}`);
  }

  /**
   * @deprecated startStop in RecordingFeature
   */
  async startStopRecordingCommand(): Promise<any> {
    return this.startStopCommand(DeviceCommand.Recording, this.isRecording()).finally(() => {
      this.showStorageFullWarningNotification();
    });
  }

  /**
   * @deprecated implemented in startStop in RecordingFeature
   */
  showStorageFullWarningNotification(): void {
    const storageFullWarning = this.getStorageFullWarning();

    if (storageFullWarning) {
      Notifications.addErrorNotification(
        `Device "${this.getName()}" recording fails: ${storageFullWarning.message}`,
      );
    }
  }

  /**
   * @deprecated obsolete
   */
  protected pendingStatus(command: string, status?: boolean | undefined) {
    if (arguments.length > 1) {
      this.pendingStatusRequests[command] = status;
    } else {
      return this.pendingStatusRequests[command];
    }
  }

  /**
   * @deprecated model in Device
   */
  getModelName(): DeviceModelName {
    return getDeviceModel(this.device);
  }

  /**
   * @deprecated online in Device
   */
  isOnline(): boolean {
    return this.device.Status === 'online';
  }

  /**
   * @deprecated offline in Device
   */
  isOffline(): boolean {
    return !this.isOnline();
  }

  getStatus(): DeviceStatus {
    return this.device.Status;
  }

  /**
   * @deprecated unpaired in Device
   */
  isUnpaired(): boolean {
    return this.device.IsUnpaired === true;
  }

  /**
   * @deprecated hasRecordingState in RecordingFeature
   */
  protected hasRecordingDesiredState(): boolean {
    const recordingDesiredState = this.getDesiredStateRecording();
    return !isNil(recordingDesiredState) && isEmptyObject(recordingDesiredState) === false;
  }

  /**
   * @deprecated desiredState.recording in Device
   */
  protected getDesiredStateRecording(): EdgeContract.RecordingState | undefined {
    return this.getDesiredState().find(
      (item): item is EdgeContract.RecordingState => item.Type === 'recording',
    );
  }

  /**
   * @deprecated active in RecordingFeature
   */
  isRecording(): boolean {
    if (this.isOffline()) {
      return false;
    }

    let stateRecording = false;

    if (this.hasRecordingDesiredState()) {
      const recordingParams = this.getDesiredStateRecording()?.Params;
      stateRecording = recordingParams?.Enabled ?? false;
      // stateRecording = recordingParams?.Enabled || recordingParams?.CMS?.EventID;
    }

    return Boolean(stateRecording);
  }

  /**
   * @deprecated startTime in RecordingFeature
   */
  getDeviceRecordingStartTime(): number | null {
    if (this.hasRecordingDesiredState()) {
      if (this.isRecording()) {
        return this.getDesiredStateRecording()?.StateTime ?? null;
      }
    }

    return null;
  }

  /**
   * @deprecated ip in Device
   */
  getIPAddress(): string | undefined {
    return this.device.IPAddress;
  }

  /**
   * @deprecated macAddress in Device
   */
  getMACAddress(): string | null {
    return this.device.MacAddress ?? null;
  }

  /**
   * @deprecated serialNumber in Device
   */
  getSerialNumber(): string {
    return this.device.SerialNumber ?? '';
  }

  getHostName(): string {
    if (ModelService.isUnify(this.getModelName())) {
      return this.device.PublicDNS ?? '';
    }

    return '';
  }

  /**
   * @deprecated uptime in Device
   */
  getUptimeFormatted(uptimeSince?: TimeStampSeconds): string {
    if (uptimeSince === undefined) {
      return '';
    }

    if (this.isOffline()) {
      return formatUptime(uptimeSince, this.getDeviceStateTime());
    }

    return formatUptime(uptimeSince);
  }

  /**
   * @deprecated stateTime in Device
   */
  getDeviceStateTime(): TimeStampSeconds | undefined {
    return this.device.StateTime;
  }

  /**
   * @deprecated version in FirmwareFeature
   */
  getFirmwareVersion(): string {
    return this.device.Firmware ?? '';
  }

  /**
   * @deprecated getSettingsCollection in Device
   */
  async getSettingsCollection(): Promise<DeviceSettingsCollection> {
    if (!this.deviceSettingsCollectionGetter) {
      this.deviceSettingsCollectionGetter = createDeviceSettingsCollectionGetter(this.getId());
    }

    return this.deviceSettingsCollectionGetter();
  }

  /**
   * @deprecated obsolete
   */
  private getWarningsRaw(): DeviceWarning[] {
    const warnings = this.device.Warnings;

    if (Array.isArray(warnings)) {
      return warnings;
    }

    return [];
  }

  /**
   * @deprecated warnings in WarningsFeature
   */
  getWarnings(): DeviceWarning[] {
    const warnings: DeviceWarning[] = [];

    this.getWarningsRaw().forEach((warning) => {
      if (warning.id === DeviceWarningId.PublisherError) {
        const streamId = warning.data?.stream_id;

        if (streamId) {
          warnings.push({
            ...warning,
            id: DeviceWarningId.StreamingDestinationError,
          });
        } else {
          warnings.push(warning);
        }
      } else {
        warnings.push(warning);
      }
    });

    return warnings;
  }

  /**
   * @deprecated obsolete
   */
  private getWarningById(warningId: string): DeviceWarning | undefined {
    return this.getWarnings().find((warning) => warning.id === warningId);
  }

  /**
   * @deprecated obsolete
   */
  private getWarningsById(warningId: DeviceWarningId | string): DeviceWarning[] {
    return this.getWarnings().filter((warning) => warning.id === warningId);
  }

  /**
   * @deprecated storageFull in WarningsFeature
   */
  getStorageFullWarning(): DeviceWarning | undefined {
    return this.getWarningById(DeviceWarningId.StorageFull);
  }

  /**
   * @deprecated publishers in WarningsFeature
   */
  private getPublisherWarnings(): DeviceWarning[] {
    return this.getWarningsById(DeviceWarningId.PublisherError);
  }

  /**
   * @deprecated selectedPublishers in WarningsFeature
   */
  getSelectedPublisherWarnings(): DeviceWarning[] {
    const selectedPublisherSet = new Set(this.getSelectedPublisherIds());

    return this.getPublisherWarnings().filter((warning) => {
      const publisherId = warning.data?.publisher_id;

      if (!publisherId) {
        return false;
      }

      return selectedPublisherSet.has(publisherId);
    });
  }

  /**
   * @deprecated streamingDestinations in WarningsFeature
   */
  getStreamingDestinationWarnings(): DeviceWarning[] {
    return this.getWarningsById(DeviceWarningId.StreamingDestinationError);
  }

  /**
   * @deprecated hasErrors in WarningsFeature
   */
  hasErrorWarnings(): boolean {
    return this.getWarnings().some((warning) => warning.severity === WarningSeverity.Error);
  }

  /**
   * @deprecated healthStatus in Device
   */
  getHealthStatus(): HealthStatus {
    return getDeviceHealth(this.getWarnings() as any);
  }

  /**
   * @deprecated name in Device
   */
  getName(): string {
    return this.device.Name;
  }

  /**
   * @deprecated name in Device
   */
  setName(name: string) {
    this.device.Name = name;
  }

  /**
   * @deprecated rename in Device
   */
  async rename(value: string): Promise<any> {
    const previousName = this.getName();

    this.setCachedName(value);
    this.setName(value);

    return (
      front
        .devices(this.getId())
        .rename()
        .post({Name: value})
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((_) => {
          this.setCachedName(previousName);
          this.setName(previousName);
        })
    );
  }

  /**
   * TODO: Save cached name in component
   * @deprecated obsolete
   */
  getCachedName(): string {
    return this.cachedDeviceName;
  }

  /**
   * @deprecated obsolete
   */
  saveCachedName() {
    this.setCachedName(this.getName());
  }

  /**
   * @deprecated obsolete
   */
  setCachedName(name: string) {
    this.cachedDeviceName = name;
  }

  /**
   * @deprecated id in Device
   */
  getId(): string {
    return this.deviceId;
  }

  /**
   * @deprecated groupId in Device
   */
  getGroupId(): string {
    return this.groupId;
  }

  /**
   * @deprecated desiredState in Device
   */
  protected getDesiredState(): EdgeContract.DesiredState[] {
    return this.device.DesiredState ?? [];
  }

  protected getStreamingState(): EdgeContract.StreamingState | undefined {
    return this.getDesiredState().find(
      (item): item is EdgeContract.StreamingState => item.Type === 'streaming',
    );
  }

  /**
   * @deprecated now in desiredState.streaming
   */
  getActiveStreamingInfo(): DeviceActiveStreamingInfo {
    const streaming = this.getStreamingState();
    const streamingParams = streaming?.Params;
    const active = streamingParams?.Enabled === true;

    return {
      id: streamingParams?.StreamID ?? null,
      url: streamingParams?.StreamAddr,
      startTime: active ? streaming?.StateTime ?? null : null,
      active,
    };
  }

  /**
   * @deprecated startTime in StreamingFeature
   */
  getStreamingStartTime(): number | null {
    if (this.isStreaming() === false) {
      return null;
    }

    const streamingInfo = this.getActiveStreamingInfo();
    let {startTime} = streamingInfo;

    this.getPublishers().forEach((publisher) => {
      const publisherStartTime = publisher.getStartTime();

      if (publisherStartTime === null) {
        return;
      }

      if (startTime === null || publisherStartTime < startTime) {
        startTime = publisherStartTime;
      }
    });

    return startTime;
  }

  /**
   * @deprecated selectedDestination in StreamingFeature
   */
  getSelectedStreamingDestinationId(): string | null {
    return this.getActiveStreamingInfo().id;
  }

  /**
   * @deprecated active in StreamingFeature
   */
  isStreaming(): boolean {
    return (
      this.isOnline() &&
      (this.isStreamingWithStreamingDestination() || this.isStreamingWithPublisher())
    );
  }

  hasStreamingError(): boolean {
    return this.isOnline() && this.hasFailedPublishers();
  }

  isStarting(): boolean {
    return this.device.Status === 'starting' || this.device.Status === 'resuming';
  }

  isStopping(): boolean {
    return this.device.Status === 'stopping';
  }

  isDown(): boolean {
    return this.device.Status === 'offline';
  }

  /**
   * @deprecated withDestination in StreamingFeature
   */
  private isStreamingWithStreamingDestination(): boolean {
    return this.getActiveStreamingInfo().active;
  }

  /**
   * @deprecated withStreamingPublisher in StreamingFeature
   */
  private isStreamingWithPublisher(): boolean {
    return this.getPublishers().some((publisher) => publisher.isStreaming());
  }

  /**
   * @deprecated started in StreamingFeature
   */
  isStreamingStarted(): boolean {
    return (
      this.isOnline() &&
      (this.isStreamingStartedWithStreamingDestination() || this.isStreamingStartedWithPublisher())
    );
  }

  /**
   * @deprecated withDestination in StreamingFeature
   */
  private isStreamingStartedWithStreamingDestination(): boolean {
    return this.isStreamingWithStreamingDestination();
  }

  /**
   * @deprecated withStartedPublisher in StreamingFeature
   */
  private isStreamingStartedWithPublisher(): boolean {
    return this.getPublishers().some((publisher) => publisher.isStarted());
  }

  /**
   * @deprecated activeCount in StreamingFeature
   * Device streaming destinations (publishers) and common streaming destinations in "streaming" state
   */
  getStreamingDestinationStreamingCount(): number {
    let result = 0;

    if (this.isOnline()) {
      if (this.isStreamingStartedWithStreamingDestination()) {
        result += 1;
      }

      result += this.getPublishers().filter((publisher) => publisher.isStreaming()).length;
    }

    return result;
  }

  /**
   * @deprecated start in StreamingFeature
   */
  async startStreaming(): Promise<any> {
    const requests: any[] = [];

    const selectedPublisherIds = this.getSelectedPublisherIds();

    if (selectedPublisherIds.length > 0) {
      requests.push(this.startPublishersStreaming(selectedPublisherIds));
    }

    const selectedStreamingDestinationId = this.getSelectedStreamingDestinationId();
    if (selectedStreamingDestinationId) {
      requests.push(
        front.streams(selectedStreamingDestinationId).start().post({DeviceID: this.getId()}),
      );
    }

    return Promise.all(requests);
  }

  /**
   * @deprecated stop in StreamingFeature
   */
  private async startPublisherStreaming(id: string): Promise<any> {
    return this.startPublishersStreaming([id]);
  }

  /**
   * @deprecated now in StreamingFeature
   */
  private async startPublishersStreaming(ids: string[]): Promise<any> {
    return this.sendCommand(DeviceCommand.PublisherStart, {
      params: {
        // eslint-disable-next-line camelcase
        publisher_ids: ids,
      },
    });
  }

  /**
   * @deprecated stop in StreamingFeature
   */
  async stopStreaming(): Promise<any> {
    const requests: any[] = [];

    const startedPublisherIds = this.getStartedPublisherIds();
    if (startedPublisherIds.length > 0) {
      requests.push(this.stopPublishersStreaming(startedPublisherIds));
    }

    const selectedStreamingDestinationId = this.getSelectedStreamingDestinationId();

    if (selectedStreamingDestinationId) {
      requests.push(
        front.streams(selectedStreamingDestinationId).stop().post({DeviceID: this.getId()}),
      );
    }

    return Promise.all(requests);
  }

  /**
   * @deprecated obsolete
   */
  private async stopPublisherStreaming(id: string): Promise<any> {
    return this.stopPublishersStreaming([id]);
  }

  /**
   * @deprecated obsolete
   */
  private async stopPublishersStreaming(ids: string[]): Promise<any> {
    return this.sendCommand(DeviceCommand.PublisherStop, {
      params: {
        // eslint-disable-next-line camelcase
        publisher_ids: ids,
      },
    });
  }

  /**
   * @deprecated publishers in DeviceChannel
   */
  getPublishers(): DevicePublisherModel[] {
    if (!this.devicePublisherModels) {
      this.devicePublisherModels = this.getPublishersRaw().map((json) => {
        return createDevicePublisherModel(json, {
          startStreamingAction: async (id: string): Promise<any> =>
            this.startPublisherStreaming(id),
          stopStreamingAction: async (id: string): Promise<any> => this.stopPublisherStreaming(id),
        });
      });
    }

    return this.devicePublisherModels;
  }

  hasFailedPublishers(): boolean {
    return this.getPublishers().some((publisher) => publisher.isFailed());
  }

  /**
   * @deprecated failedPublishersCount in StreamingFeature
   */
  getFailedPublishersCount(): number {
    return this.getPublishers().filter((publisher) => publisher.isFailed()).length;
  }

  /**
   * @deprecated obsolete
   */
  private getPublishersRaw(): Edge.Publisher[] {
    return this.device.Publishers ?? [];
  }

  /**
   * @deprecated selectedPublishers in StreamingFeature
   */
  private getSelectedPublishers(): DevicePublisherModel[] {
    return this.getPublishers().filter((publisher) => publisher.isSelected());
  }

  /**
   * @deprecated obsolete
   */
  getSelectedPublisherIds(): string[] {
    return this.getSelectedPublishers().map((publisher) => publisher.getId());
  }

  /**
   * @deprecated obsolete
   */
  private getStartedPublisherIds(): string[] {
    return this.getPublishers()
      .filter((publisher) => publisher.isStarted())
      .map((publisher) => publisher.getId());
  }

  /**
   * @deprecated active in TranscribingFeature
   */
  // eslint-disable-next-line class-methods-use-this
  isTranscribing(): boolean {
    return false;
  }

  /**
   * @deprecated capabilities in Device and DeviceChannel
   */
  getCapabilitiesRaw() {
    return this.device.Capabilities ?? [];
  }

  /**
   * @deprecated obsolete
   */
  getCapabilities(): DeviceCapabilities {
    return new DeviceCapabilities({capabilities: this.getCapabilitiesRaw()});
  }

  /**
   * @deprecated destinationIsAvailable in StreamingFeature
   */
  checkStreamingDestinationAvailableToUse(type: STREAMING_SERVICE_TYPE): boolean {
    if (ModelService.isWebcaster(this.getModelName()) === false) {
      return true;
    }

    return type !== STREAMING_SERVICE_TYPE.RTMP && type !== STREAMING_SERVICE_TYPE.RTMPS;
  }

  getInnerModel(): Device {
    return this.device;
  }
}
