import {DeviceModel} from 'app/components/DeviceDetails/constants';
import {ChannelDto} from 'app/store/dto/channel/Channel';
import {
  CommonDeviceDto,
  DeviceDetailsDto,
  DeviceDto,
  WarningDto,
} from 'app/store/dto/device/Device';
import {isCommonDeviceDto} from 'app/store/dto/device/typeGuards';
import {isNil} from 'app/util/isNil';
import {Channel} from 'app/store/models/channel/Channel';
import {DeviceGroupInfo, Warning} from 'app/store/models/types';
import {Device} from 'app/store/models/device/Device';
import {DeviceChannels} from 'app/store/models/device/types';
import {Device as DeviceType} from 'app/components/DeviceDetails/Models/types';
import {DeviceModelNameDto} from 'app/store/dto/common';

export function getDeviceModel(dto: CommonDeviceDto | DeviceDetailsDto | DeviceType): DeviceModel {
  return processDetectingDeviceModel(dto.Model);
}

export function processDetectingDeviceModel(
  modelName: DeviceModelNameDto | undefined,
): DeviceModel {
  if (!modelName) {
    return DeviceModel.Pearl2;
  }

  if (modelName.toLowerCase().startsWith('wcx2')) {
    return DeviceModel.Webcaster;
  }

  switch (modelName.toLowerCase()) {
    case 'demo':
      return DeviceModel.Demo;

    case 'webcaster':
      return DeviceModel.Webcaster;

    case 'pearl-livescrypt':
      return DeviceModel.LiveScrypt;

    case 'pearl-slot':
      return DeviceModel.Channel;

    case 'pearl-master':
    case 'pearl-2':
      return DeviceModel.Pearl2;

    case 'pearl virtual lite':
    case 'pearl virtual':
      return DeviceModel.PearlVirtual;

    case 'epiphan unify project':
      return DeviceModel.Unify;

    case 'pearl mini':
      return DeviceModel.PearlMini;

    case 'pearl nano es2':
    case 'pearl nano':
      return DeviceModel.PearlNano;

    case 'pearl nexus':
      return DeviceModel.PearlNexus;

    default:
      return DeviceModel.Pearl2;
  }
}

export function getGroupInfo(dto: DeviceDto): DeviceGroupInfo | undefined {
  if (isCommonDeviceDto(dto)) {
    if (dto.group_id) {
      return {
        id: dto.group_id,
      };
    }

    return undefined;
  }

  const {id, name} = dto.Group;
  if (id) {
    return {id, name};
  }

  return undefined;
}

export function mapChannelsDto(
  masterDevice: Device,
  dtos?: ChannelDto[],
): DeviceChannels | undefined {
  if (!isNil(dtos)) {
    return dtos.reduce<DeviceChannels>((acc, dto) => {
      const channel = new Channel(masterDevice, dto);
      acc[channel.index] = channel;
      return acc;
    }, {});
  }
}

export function mapDeviceWarnings(warnings: WarningDto[], channels?: DeviceChannels): Warning[] {
  const result: Warning[] = [];

  warnings.forEach((warning) => {
    const {channel_id} = warning;

    if (isNil(channel_id)) {
      const {channel_id, ...rest} = warning;
      result.push({...rest});
    } else {
      const {id, message} = warning;

      const channel = channels?.[channel_id];

      if (channel) {
        result.push({
          ...warning,
          id: `${id}_${channel_id}`,
          message: `Channel '${channel.name}': ${message}`,
        });
      }
    }
  });

  return result;
}
