import React, {useState} from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {Callback, ClassName} from 'app/types/common';
import {
  AudioMuteButton,
  StreamStopButton,
  VideoMuteButton,
} from 'app/components/pages/MeetingDetails/shared/StreamButtons/StreamButtons';
import {} from 'app/services/api/meetings/SrtApiService';
import {getErrorContents, isErrorResponse} from 'app/api/types';
import {Notifications} from 'app/components/Notifications';
import {Dialog} from '@mui/material';
import {
  ModalContainer,
  ModalFooter,
  ModalTitle,
  ModalTitleHighlightedItem,
} from 'app/components/sharedReactComponents/Modal/Modal';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {THEME, VARIANT} from 'app/constants';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {Button} from 'app/components/sharedReactComponents/Button';

interface Props extends ClassName {
  name: string;
  audio: boolean;
  video: boolean;
  disabledMute: boolean;
  disabledStop: boolean;
  onMute: Callback<Promise<void>, [App.Connect.StreamMute]>;
  onStop: Callback<Promise<void>>;
}

interface DialogProps {
  open: boolean;
  name: string;
  onStop: Callback<Promise<void>>;
  onClose: Callback;
}

function StopSrtDialog({open, name, onStop, onClose}: DialogProps) {
  const [applying, setApplying] = useState(false);

  const handleStop = async () => {
    setApplying(true);

    try {
      await onStop();
      onClose();
    } finally {
      setApplying(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <ModalContainer>
        <ModalTitle>
          Stop SRT stream for
          <br />
          <ModalTitleHighlightedItem>
            <OverflowTooltip>{name}</OverflowTooltip>
          </ModalTitleHighlightedItem>
          ?
        </ModalTitle>

        <ModalFooter>
          <ProgressButton
            dataId="confirm_stop_srt"
            theme={THEME.SUCCESS}
            variant={VARIANT.SOLID}
            autoFocus={true}
            onClick={handleStop}
          >
            Yes, stop
          </ProgressButton>

          <Button
            dataId="modal_cancel_button"
            theme={THEME.SECONDARY}
            variant={VARIANT.OUTLINE}
            disabled={applying}
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Dialog>
  );
}

export const StreamStatusActions = observer(
  ({className, audio, video, name, disabledMute, disabledStop, onStop, onMute}: Props) => {
    const [muting, setMuting] = useState({audio: false, video: false});
    const [open, setOpen] = useState(false);

    const handleMuteAudio = async () => {
      try {
        setMuting((prev) => ({...prev, audio: true}));
        const mute: App.Connect.StreamMute = {video, audio: !audio};
        await onMute(mute);
      } catch (e: unknown) {
        const reason = isErrorResponse(e) && getErrorContents(e);
        Notifications.addErrorNotification(
          `Could not mute srt${reason ? ': '.concat(reason) : ''}`,
        );
      } finally {
        setMuting((prev) => ({...prev, audio: false}));
      }
    };

    const handleMuteVideo = async () => {
      try {
        setMuting((prev) => ({...prev, video: true}));
        const mute: App.Connect.StreamMute = {video: !video, audio};
        await onMute(mute);
      } catch (e: unknown) {
        const reason = isErrorResponse(e) && getErrorContents(e);
        Notifications.addErrorNotification(
          `Could not mute srt${reason ? ': '.concat(reason) : ''}`,
        );
      } finally {
        setMuting((prev) => ({...prev, video: false}));
      }
    };

    const handleOpenDialog = () => setOpen(true);
    const handleCloseDialog = () => setOpen(false);

    return (
      <div className={classNames('stream-status-actions', className)}>
        <VideoMuteButton
          className="stream-status-actions__item"
          dataId="mute_video_stream_btn"
          muted={video}
          disabled={disabledMute || muting.video}
          onAction={handleMuteVideo}
        />

        <AudioMuteButton
          className="stream-status-actions__item"
          dataId="mute_audio_stream_btn"
          disabled={disabledMute || muting.video}
          muted={audio}
          onAction={handleMuteAudio}
        />

        <StreamStopButton
          className="participant-actions__item"
          dataId="stream_stop_btn"
          disabled={disabledStop}
          onAction={handleOpenDialog}
        />

        <StopSrtDialog name={name} open={open} onClose={handleCloseDialog} onStop={onStop} />
      </div>
    );
  },
);
