import {useEffect, useState} from 'react';
import {freeTier} from 'app/util/billing/utils';

interface Info {
  freeTier: boolean;
  seats: number;
}

const defaultInfo: Info = {
  freeTier: true,
  seats: 0,
};

export function usePairingTierInfo({id, seats}: App.Billing.Cloud.Tier): Info {
  const [info, setInfo] = useState<Info>(defaultInfo);

  useEffect(() => {
    if (seats >= 0) {
      setInfo({
        freeTier: freeTier(id),
        seats,
      });
    } else {
      setInfo(defaultInfo);
    }
  }, [seats, id]);

  return info;
}
