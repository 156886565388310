import React from 'react';
import {LegalInformation} from 'app/components/LegalInformation';
import {UserApiService} from 'app/services/api/user/UserApiService';

async function getContent(): Promise<string> {
  const result = await UserApiService.getPolicy();
  return result.Policy;
}

export function UsePolicy() {
  return <LegalInformation title="Epiphan Acceptable Use Policy" getContent={getContent} />;
}
