import React from 'react';
import {Box, Divider, Stack, Typography} from '@mui/material';
import {Countdown} from 'app/components/sharedReactComponents/Countdown';
import {palette} from 'app/themes/app';
import {getTimeToDate} from 'app/util/time';
import {calcTime} from 'app/components/sharedReactComponents/Countdown/utils';
import {Icons} from 'app/util/icons';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface Props {
  id?: string;
  status: App.Connect.MeetingStatus;
  permission?: App.Connect.Permission;
  startedAt: TimeStampSeconds;
}

export function PermissionStatus({id, startedAt, status, permission}: Props) {
  const {text, tooltip, serious} = usePermission(status, permission);

  return (
    <Box>
      <Stack
        sx={{color: serious ? palette.red : palette.orange2, height: '24px'}}
        direction="row"
        alignItems="center"
        gap={0.5}
      >
        <Tooltip content={tooltip}>
          <Stack data-id="meeting_status_text" direction="row" alignItems="center" gap={0.5}>
            {text}
          </Stack>
        </Tooltip>
      </Stack>

      {permission !== 'failed' && (
        <Stack
          sx={{color: palette.blue, fontSize: 12, mt: 0.5}}
          direction="row"
          alignItems="center"
          gap={2}
          divider={<Divider sx={{borderColor: palette.blue}} orientation="vertical" flexItem />}
        >
          <Countdown
            dataId="meeting_uptime"
            timestamp={startedAt}
            calculate={calcTime}
            format={getTimeToDate}
          />

          {id && (
            <Typography component="span" color="inherit" fontSize="inherit">
              Meeting ID {id}
            </Typography>
          )}
        </Stack>
      )}
    </Box>
  );
}

interface Return {
  text: React.ReactNode;
  tooltip?: string;
  serious: boolean;
}

function usePermission(
  status: App.Connect.MeetingStatus,
  permission?: App.Connect.Permission,
): Return {
  if (status === 'waiting-for-account') {
    return {
      text: (
        <>
          Action required: <u>meeting only allows authenticated users</u> <Arrow />
        </>
      ),
      serious: false,
    };
  }

  if (status === 'waiting-for-password') {
    return {
      text: (
        <>
          Action required: <u>invalid or missing passcode</u> <Arrow />
        </>
      ),
      serious: false,
    };
  }

  if (status === 'waiting-for-host') {
    return {
      text: 'Waiting for meeting start',
      tooltip: "Zoom Meeting hasn't started yet. The meeting host needs to join the meeting.",
      serious: false,
    };
  }

  if (status === 'in-waiting-room') {
    return {
      text: 'Waiting in the lobby',
      tooltip:
        'Epiphan Connect is waiting in the meeting lobby. The meeting host has to admit it to the meeting.',
      serious: false,
    };
  }

  if (permission === 'failed') {
    return {
      text: <>{Icons.warning().reactComponent()} Not enough permissions in Zoom</>,
      tooltip:
        "We weren't able to obtain the required permissions. There was a problem with the features supported in this meeting. Please change meeting permissions and try again.",
      serious: true,
    };
  }

  // Streaming
  if (permission === 'requesting-streaming') {
    return {
      text: (
        <>
          Action required: <u>approve live streaming in Zoom</u> <Arrow />
        </>
      ),
      tooltip: 'The meeting host needs to approve the live streaming request in Zoom',
      serious: false,
    };
  }

  if (permission === 'streaming-request-timeout') {
    return {
      text: (
        <>
          Action required: <u>permission request timed out</u> <Arrow />
        </>
      ),
      tooltip: "Meeting host hasn't approved or denied the streaming request in Zoom",
      serious: false,
    };
  }

  if (permission === 'streaming-request-denied') {
    return {
      text: (
        <>
          {Icons.warning().reactComponent()}
          Action required: <u>permission request denied</u> <Arrow />
        </>
      ),
      tooltip: 'Meeting host has denied the streaming request. Click to see next steps',
      serious: true,
    };
  }

  // Recording
  if (permission === 'requesting-recording') {
    return {
      text: (
        <>
          Action required: <u>requesting recording in Zoom</u> <Arrow />
        </>
      ),
      tooltip: 'The meeting host needs to approve the recording permissions in Zoom',
      serious: false,
    };
  }

  if (permission === 'recording-request-timeout') {
    return {
      text: (
        <>
          Action required: <u>permission request timed out</u> <Arrow />
        </>
      ),
      tooltip: "Meeting host hasn't approved or denied the recording request in Zoom",
      serious: false,
    };
  }

  if (permission === 'recording-request-denied') {
    return {
      text: (
        <>
          {Icons.warning().reactComponent()}
          Action required: <u>permission request denied</u> <Arrow />
        </>
      ),
      tooltip: 'Meeting host has denied the recording request. Click to see next steps',
      serious: true,
    };
  }

  if (permission === 'waiting-recording') {
    return {
      text: (
        <>
          Action required: <u>manual permissions are needed</u> <Arrow />
        </>
      ),
      tooltip: 'Meeting host needs to allow recording of local files. Click to see instructions',
      serious: false,
    };
  }

  return {
    text: '',
    tooltip: '',
    serious: false,
  };
}

function Arrow() {
  return <KeyboardArrowRightIcon sx={{fontSize: 16}} />;
}
