import React, {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {FirmwareUpdateButton} from 'app/components/DeviceDetails/DeviceInfoTab/FirmwareStatus/FirmwareUpdateButton';
import {updateFirmwareButtonText} from 'app/components/DeviceDetails/DeviceInfoTab/utils';
import {ClassName} from 'app/types/common';
import {UpdateDeviceFirmwarePrompt} from 'app/components/libs/dialogs/UpdateDeviceFirmwarePrompt';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {useDeviceFirmware} from 'app/components/DeviceDetails/hooks/useDeviceFirmware';
import {FirmwareFeature} from 'app/store/models/features/firmware/FirmwareFeature';

interface Props extends ClassName {
  device: AnyDeviceModelType;
}

export const FirmwareStatus: React.VFC<Props> = ({className, device}) => {
  const {firmware, reload} = useDeviceFirmware(device.getId());

  const upgrading = firmware ? firmware.updateInProgress : false;
  const [loading, setLoading] = useState(upgrading);

  useEffect(() => {
    setLoading(upgrading);
  }, [upgrading]);

  const onFirmwareUpdate = useCallback(() => {
    UpdateDeviceFirmwarePrompt.show({
      devices: [device],
      onDone: reload,
    });
  }, [reload, device]);

  if (!firmware) {
    return null;
  }

  const renderControls = (firmware: FirmwareFeature) => {
    if (firmware.updateInProgress || firmware.updateAvailable) {
      return (
        <FirmwareUpdateButton
          className="device-firmware-status__update-button"
          data-id="firmware_update_button"
          loading={loading}
          disabled={loading || device.isOffline()}
          onClick={onFirmwareUpdate}
        >
          {updateFirmwareButtonText(firmware)}
        </FirmwareUpdateButton>
      );
    }

    if (!firmware.updateStatus) {
      return (
        <div className="device-firmware-status__label" data-id="firmware_status_label">
          Up-to-date
        </div>
      );
    }

    return null;
  };

  const offline = device.isOffline();

  return (
    <div className={classNames('device-firmware-status', className)}>
      <div className="device-firmware-status__content">
        <div className="device-firmware-status__current-version" data-id="firmware_current_version">
          {firmware.version}
        </div>

        {offline ? null : renderControls(firmware)}
      </div>
    </div>
  );
};
