import React from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';
import {SIZE, THEME} from 'app/constants';
import {Icons} from 'app/util/icons';

interface Props extends ClassName {
  type: 'simple' | 'admin';
}

export const NoPairingPermission = ({className, type}: Props) => {
  const renderMessage = () => {
    if (type === 'simple') {
      return (
        <div className="no-pairing-permission__message">
          To continue you can either sign in with another Microsoft Teams account or ask your
          Microsoft Teams administrator to join Epiphan Cloud and go through the connection process.
          To invite them, go to the members page to invite a new team member.
        </div>
      );
    }

    return (
      <div className="no-pairing-permission__message">
        To continue you can either: <br />
        1) Restart this process using the link you received and sign
        <br /> in with another Microsoft Teams account. <br />
        2) Share the link provided with your Microsoft Organization
        <br /> administrator and ask them to go through the steps.
        <br />
        <br />
        For more details, please consult our{' '}
        <a
          href="https://www.epiphan.com/userguides/connect/Content/Connect/Connect-QuickStart-Guide.htm"
          target="__blank"
          rel="noopener noreferrer"
        >
          User Guide
        </a>
      </div>
    );
  };

  return (
    <div className={classNames('no-pairing-permission', className)}>
      <div>
        {Icons.microsoftTeams().reactComponent()}
        <span className="no-pairing-permission__teams">Microsoft Teams</span>
      </div>

      <div className="no-pairing-permission__title">
        {Icons.warning().theme(THEME.DANGER).size(SIZE.L).reactComponent()} Your Microsoft Teams
        account doesn't have
        <br />
        administrator privileges
      </div>

      <div className="no-pairing-permission__body">
        <div>
          In order to give consent to use Epiphan Connect you need to have one of the following
          roles in your Microsoft Teams tenant:
        </div>

        <ul>
          <li>Global Administrator</li>
          <li>Privileged Role Administrator</li>
        </ul>

        {renderMessage()}
      </div>
    </div>
  );
};
