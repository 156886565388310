import React, {useState} from 'react';
import {Checkbox, CHECKBOX_THEME} from 'app/components/sharedReactComponents/Checkbox';
import {SIZE} from 'app/constants';
import {stopPropagation} from 'app/util/stopPropagation';
import {
  SelectMenuOption,
  SelectMenuOptionProps,
} from 'app/components/sharedReactComponents/SelectMenuOption';
import {isPromise} from 'app/util/isPromise';
import {useIsMounted} from 'app/hooks/useIsMounted';

export function SelectMenuOptionWithCheckbox({
  name,
  active,
  disabled,
  onClick,
  children,
  ...restProps
}: SelectMenuOptionProps) {
  const [loading, setLoading] = useState(false);
  const mountedRef = useIsMounted();

  const changeAction = () => {
    if (!onClick) {
      return;
    }

    const callback = onClick({
      target: {
        name,
        value: !active,
      },
    });

    if (isPromise(callback)) {
      setLoading(true);

      callback.finally(() => {
        if (mountedRef.current) {
          setLoading(false);
        }
      });
    }
  };

  return (
    <SelectMenuOption
      {...restProps}
      controlComponent={
        <Checkbox
          name={name}
          value={active}
          theme={CHECKBOX_THEME.BLACK}
          size={SIZE.S}
          focusable={false}
          loading={loading}
          disabled={disabled}
          onClick={stopPropagation}
          onChange={changeAction}
        />
      }
      disabled={disabled}
      onClick={changeAction}
    >
      {children}
    </SelectMenuOption>
  );
}
