export const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const formatArray = (b: number, minThreshold: number, precision: number): [string, string] => {
  let unit;
  let bNext;

  for (unit of UNITS) {
    bNext = b / Math.pow(2, 10);

    if (bNext < minThreshold) {
      break;
    }

    b = bNext;
  }

  return [b.toFixed(precision), unit];
};

const format = (b: number, minThreshold: number, precision: number) =>
  formatArray(b, minThreshold, precision).join(' ');

export const bytes = {
  formatArray,
  format,
};
