import React, {Fragment} from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {AlreadyUsedByDevice} from 'app/components/sharedReactComponents/ActionButtons/StreamingDestinationsSelector/AlreadyUsedByDevice';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  currentDevice: any;
  device: any;
}

export const StreamingFromDeviceNote: React.FC<Props> = ({
  className,
  currentDevice,
  device,
}) => {
  return (
    <span
      className={classNames('streaming-from-device-note', className)}
      data-id="streaming_from_device_note"
    >
      {Icons.streaming().size(SIZE.S).reactComponent()}

      <span className="streaming-from-device-note__label">
        Streaming

        {device && (
          <Fragment> from </Fragment>
        )}

        {device && (
          <AlreadyUsedByDevice
            currentDevice={currentDevice}
            usedByDevice={device}
          />
        )}
      </span>
    </span>
  );
};
