import React, {memo} from 'react';
import classNames from 'classnames';
import {router} from 'app/router/main';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  url?: string;
}

export const PairDevicePageCloseButton: React.FC<Props> = memo(({
  className,
  url = router.url('dashboard'),
}) => {
  return (
    <Button
      dataId="close_pair_device_page_button"
      className={classNames('pair-device-page-close-button', className)}
      href={url}
    >
      {Icons.close().size(SIZE.L).reactComponent()}
    </Button>
  );
});

