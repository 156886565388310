import {useCallback, useEffect, useRef, useState} from 'react';
import {ColumnActiveManager} from 'app/components/Dashboard/Devices/TableView/ColumnManager/ColumnActiveManager';
import {FleetManagerColumn} from 'app/components/FleetManager/types';
import {FleetManagerColumnId} from 'app/components/FleetManager/constants';

interface Props {
  storeKey: string;
  columns: FleetManagerColumn[];
  fixedColumns: FleetManagerColumnId[];
  defaultActiveColumns: FleetManagerColumnId[];
}

export function useColumnActiveManager({
  storeKey,
  columns,
  fixedColumns,
  defaultActiveColumns,
}: Props) {
  const [activeColumns, setActiveColumns] = useState<FleetManagerColumn[]>([]);
  const columnActiveManager = useRef<ColumnActiveManager>();

  useEffect(() => {
    columnActiveManager.current = new ColumnActiveManager({
      storeKey,
      fixedColumns,
      defaultActiveColumns,
    });

    setActiveColumns(columns.filter(column => columnActiveManager.current?.isActive(column.dataKey)));
  }, [columns, defaultActiveColumns, fixedColumns, storeKey]);

  const handleColumnsSelectorChange = useCallback(({target: {name, value}}) => {
    columnActiveManager.current?.toggle(name, value);
    setActiveColumns(columns.filter(column => columnActiveManager.current?.isActive(column.dataKey)));
  }, [columns]);

  const isColumnActive = useCallback((id: string) => {
    if (!columnActiveManager.current) {
      return false;
    }

    return columnActiveManager.current.isActive(id);
  }, []);

  return {
    activeColumns,
    handleColumnsSelectorChange,
    isColumnActive,
  };
}
