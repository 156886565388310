import React from 'react';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ClearIcon from '@mui/icons-material/Clear';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import {Schedule} from 'app/domain/schedule';
import {isOngoing, isScheduled} from 'app/domain/schedule/utils';
import {canExtend} from 'app/components/sharedReactComponents/Events/utils';

interface Option {
  id: string;
  name: string;
  icon: React.ReactNode;
  disabled?: boolean;
  onClick: () => any;
}

interface Args {
  event: Schedule.Event;
  offline: boolean;
  onExtend: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export function useEventMenuOptions({event, offline, onExtend, onDelete, onEdit}: Args): Option[] {
  const ongoing = isOngoing(event.status);
  const scheduled = isScheduled(event.status);
  const isChrono = event.cms === 'chrono';

  const result: Option[] = [];

  if (ongoing && canExtend(event.cms)) {
    result.push({
      id: 'extend-event',
      name: 'Add 5 minutes',
      icon: <EditNoteIcon color="error" />,
      disabled: offline,
      onClick: onExtend,
    });
  }

  if (isChrono) {
    if (scheduled) {
      result.push({
        id: 'edit-time',
        name: 'Edit',
        icon: <MoreTimeIcon color="primary" />,
        onClick: onEdit,
      });

      result.push({
        id: 'delete-event',
        name: 'Delete',
        icon: <ClearIcon color="error" />,
        onClick: onDelete,
      });
    }
  }

  return result;
}
