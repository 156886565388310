import React from 'react';
import {Box} from '@mui/material';
import {grey} from '@mui/material/colors';
import {DataId, StyleSx, Sx} from 'app/types/common';
import {secondsToMinutes} from 'app/util/timeConverter';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {CalendarEvent} from 'app/components/sharedReactComponents/Events/Calendar/EventsSchedule/DayEvents/CalendarEvent/CalendarEvent';
import {CalendarGroup} from 'app/components/sharedReactComponents/Events/Calendar/EventsSchedule/DayEvents/CalendarGroup/CalendarGroup';
import {Schedule} from 'app/domain/schedule';
import {useDayEvents} from 'app/components/sharedReactComponents/Events/Calendar/EventsSchedule/DayEvents/useDayEvents';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {packSx} from 'app/util/packSx/packSx';

const MAX_HEIGHT_EVENT_CARD_FOR_DISPLAYING_TOOLTIP = 14;
const MAX_HEIGHT_EVENTS_GROUP_FOR_DISPLAYING_TOOLTIP = 30;

const eventSx: StyleSx = {position: 'absolute', left: 0, right: 0, zIndex: 1};

type Props = Sx &
  DataId & {
    range: TimeRange;
    events: Schedule.Event[];
    oneDay: boolean;
    devices?: Map<string, PearlMasterDeviceModel>;
    disabled?: boolean;
  };

export const DayEvents = React.memo(
  ({sx, dataId, range, events, devices, disabled, oneDay}: Props) => {
    const {groups, ungrouped} = useDayEvents({events, range});

    return (
      <Box
        data-id={dataId}
        sx={sx}
        position="relative"
        bgcolor={disabled ? grey[100] : 'transparent'}
      >
        {ungrouped.map((event) => {
          const {id} = event;

          const {top, height} = calculateDimensions({from: event.start, to: event.end}, range);

          const tooltipContent =
            height < MAX_HEIGHT_EVENT_CARD_FOR_DISPLAYING_TOOLTIP && event.title;

          return (
            <Tooltip key={id} content={tooltipContent}>
              <CalendarEvent
                sx={packSx({top: `${top}px`, height: `${height}px`}, eventSx)}
                event={event}
                devices={devices}
                placement={oneDay ? 'vertical' : 'horizontal'}
              />
            </Tooltip>
          );
        })}

        {groups.map((group) => {
          const {top, height} = calculateDimensions({from: group.start, to: group.end}, range);

          const tooltipContent =
            height < MAX_HEIGHT_EVENTS_GROUP_FOR_DISPLAYING_TOOLTIP &&
            `${group.events.length} events`;

          return (
            <Tooltip key={group.start} content={tooltipContent}>
              <CalendarGroup
                sx={packSx({top: `${top}px`, height: `${height}px`}, eventSx)}
                events={group.events}
                devices={devices}
                placement={oneDay ? 'vertical' : 'horizontal'}
              />
            </Tooltip>
          );
        })}
      </Box>
    );
  },
);

function calculateDimensions(event: TimeRange, day: TimeRange): {top: number; height: number} {
  const normalizedStart = Math.max(event.from, day.from);
  const normalizedFinish = Math.min(event.to, day.to);

  const top = secondsToMinutes(normalizedStart - day.from);
  const height = secondsToMinutes(normalizedFinish - normalizedStart);

  return {top, height};
}
