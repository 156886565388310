import {
  DeviceSettingId,
  DeviceSettingType,
} from 'app/models/Device/Settings/constants';
import {BaseDeviceSettingModel} from 'app/models/Device/Settings/BaseDeviceSettingModel';
import {EnumDeviceSettingModel} from 'app/models/Device/Settings/EnumDeviceSettingModel';
import {GroupDeviceSettingModel} from 'app/models/Device/Settings/GroupDeviceSettingModel';
import {VideoBitrateDeviceSettingModel} from 'app/models/Device/Settings/VideoBitrateDeviceSettingModel';

function createDeviceSettingModel(json) {
  if (
    json.id === DeviceSettingId.VideoBitrate &&
    (
      json.type.name === DeviceSettingType.Number ||
      json.type.name === DeviceSettingType.NullableNumber
    )
  ) {
    return new VideoBitrateDeviceSettingModel(json);
  }

  switch (json.type.name) {
    case DeviceSettingType.Enum:
      return new EnumDeviceSettingModel(json);
    case DeviceSettingType.Group:
      return new GroupDeviceSettingModel(json);
    default:
      return new BaseDeviceSettingModel(json);
  }
}

export {
  createDeviceSettingModel,
};
