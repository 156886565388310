import React from 'react';
import {CSSObject, Divider, Stack} from '@mui/material';
import {Sx} from 'app/types/common';
import {FeaturesNames} from 'app/components/BillingManager/dialogs/shared/FeaturesNames/FeaturesNames';
import {backgrounds} from 'app/themes/app';
import {FeaturesAccess} from 'app/components/BillingManager/dialogs/shared/FeaturesAccess/FeaturesAccess';
import {EnterpriseFeatures} from 'app/components/BillingManager/dialogs/shared/EnterpriseFeatures/EnterpriseFeatures';
import {features} from 'app/components/BillingManager/dialogs/shared/features';

const list: CSSObject = {
  px: 2,
  pt: 1,
  minWidth: 260,
  width: 260,
};

interface Props extends Sx {
  currentId?: string;
  custom: boolean;
  tiers: App.Billing.Connect.Tier[];
}

export default function ConnectFeatures({sx, currentId, custom, tiers}: Props) {
  return (
    <Stack
      sx={sx}
      direction="row"
      gap={1}
      divider={<Divider sx={{borderStyle: 'dashed'}} orientation="vertical" flexItem />}
    >
      <Stack
        direction="row"
        gap={1}
        divider={<Divider sx={{borderStyle: 'dashed'}} orientation="vertical" flexItem />}
      >
        <FeaturesNames sx={{minWidth: 340, width: 340}} list={features.connect} />

        {tiers.map((t) => (
          <FeaturesAccess
            sx={[list, {bgcolor: currentId === t.id ? backgrounds.blue : backgrounds.gray}]}
            key={t.id}
            type="connect"
            list={features.connect}
            limit={t.limit}
          />
        ))}
      </Stack>

      <EnterpriseFeatures custom={custom} />
    </Stack>
  );
}
