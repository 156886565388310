import {BaseFTPLocationModel} from 'app/components/UploadLocations/Models/BaseFTPLocationModel';
import {
  computed,
  makeObservable,
  IReactionDisposer,
} from 'mobx';
import {lensPath} from 'ramda';
import {ValidatedInputModel} from 'app/components/UploadLocations/Models/ValidatedInputModel';
import {makeSaveLocationApiReaction} from 'app/components/UploadLocations/Models/utils';

import {
  STORAGE_PROP_NAME,
  STORAGE_TYPE,
} from 'app/components/UploadLocations/constants';

const passwordPathLens = lensPath([STORAGE_PROP_NAME.FTP, 'Password']);

class FTPLocationModel extends BaseFTPLocationModel {
  type: STORAGE_TYPE;
  useTls: ValidatedInputModel;
  stopAutoSave: IReactionDisposer;

  constructor(json, saveLocationApi) {
    json.Path = json.Path || '/';
    json.Port = json.Port || 21;

    super(json);

    this.type = STORAGE_TYPE.FTP;

    const {InSecureTLS = false} = json;
    this.useTls = new ValidatedInputModel('Use TLS', InSecureTLS);

    this.stopAutoSave = makeSaveLocationApiReaction(
      () => this.json,
      this.id,
      passwordPathLens,
      saveLocationApi,
    );

    makeObservable(this, {
      options: computed,
      json: computed,
    });
  }

  get options() {
    return {
      Server: this.address.value,
      Username: this.username.value,
      Password: this.password.value,
      Path: this.path.value,
      Port: this.port.value,
      InSecureTLS: this.useTls.value
    };
  }

  get json() {
    return {
      [STORAGE_PROP_NAME.FTP]: this.options,
      StorageID: this.id,
      Name: this.name.value,
      Type: this.type
    };
  }
}

export {FTPLocationModel};
