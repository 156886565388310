import React, {useCallback, useState} from 'react';
import {
  Modal,
  ModalBody,
  ModalCancelButton,
  ModalContainer,
  ModalFooter,
  ModalTitle,
} from 'app/components/sharedReactComponents/Modal/Modal';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {THEME, VARIANT} from 'app/constants';
import {Form, FormData} from 'app/components/libs/dialogs/CreateGroupPrompt/Form';
import {emptyStringValidator, nameValidator} from 'app/util/validators';
import {front} from 'app/api/CirrusApi';
import {Callback} from 'app/types/common';
import {ApiResponseNumeralStatus} from 'app/data/Status';
import {Notifications} from 'app/components/Notifications';

const validators = {
  name: [
    nameValidator,
    emptyStringValidator,
  ],
};

interface Props {
  teamId?: string;
  onDone: Callback;
}

const Content: React.FC<Props> = ({
  teamId,
  onDone,
}) => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    touched: false,
    valid: false,
    errorMessage: '',
  });

  const handleChange = useCallback(formData => {
    setFormData(formData);
  }, []);

  const handleClickSubmit = useCallback(() => {
    const {name} = formData;

    const groupsEndpoint = teamId ? front.team(teamId).groups : front.groups;

    return groupsEndpoint().post({name})
      .then(response => {
        return onDone({
          id: response.id,
          name: response.name,
        });
      })
      .then(() => {
        Modal.hide();
      })
      .catch((error) => {
        if (error.status === ApiResponseNumeralStatus.Conflict) {
          Notifications.addErrorNotification('Group with this name already exists.');
        }
      });
  }, [teamId, formData, onDone]);

  return (
    <ModalContainer>
      <ModalTitle>
        New group
      </ModalTitle>

      <ModalBody>
        <Form
          className="create-group-prompt__form"
          inputClassName="create-group-prompt__input"
          formData={formData}
          validators={validators}
          onChange={handleChange}
        />
      </ModalBody>

      <ModalFooter>
        <ProgressButton
          dataId="modal_confirm_button"
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          disabled={formData.valid === false}
          onClick={handleClickSubmit}
        >
          Create Group
        </ProgressButton>

        <ModalCancelButton/>
      </ModalFooter>
    </ModalContainer>
  );
};

export const CreateGroupPrompt = {
  show({
    teamId,
    onDone,
  }: Props) {
    Modal.show({
      className: 'create-group-prompt',
      children: (
        <Content
          teamId={teamId}
          onDone={onDone}
        />
      ),
    });
  },
};
