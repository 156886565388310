import axios, {AxiosRequestConfig} from 'axios';
import {front} from 'app/api/CirrusApi';
import {EdgeContract} from 'app/contracts/edge';
import {Edge} from 'app/domain/edge';

export class PresetApiService {
  static async applyLocalPreset(
    deviceId: string,
    contract: EdgeContract.ApplyUnitPreset,
  ): Promise<void> {
    return front.devices(deviceId).presets().local().put(contract);
  }
  static async applyTeamPreset(
    deviceId: string,
    payload: EdgeContract.ApplyTeamPreset,
  ): Promise<void> {
    return front.devices(deviceId).presets().cloud().put(payload);
  }

  static async getTeamPresets(): Promise<EdgeContract.TeamPreset[]> {
    return front.presets().get();
  }

  static async getUnitPresets(id: string): Promise<Edge.UnitPreset[] | undefined> {
    return front.devices(id).presets().local().get();
  }

  static async uploadUnitPreset(unitId: string, name: string): Promise<void> {
    return front.devices(unitId).presets().local().push().post({name});
  }

  static async updateTeamPreset(id: string, p: EdgeContract.UpdatePreset) {
    return front.presets(id).put(p);
  }

  static async uploadPresetFile(
    data: FormData,
    config?: AxiosRequestConfig<FormData>,
  ): Promise<EdgeContract.TeamPreset> {
    const url: string = front.presets().url();
    return axios.post<FormData, EdgeContract.TeamPreset>(url, data, config);
  }

  static async createUnitPreset(deviceId: string, data: EdgeContract.CreateUnitPreset) {
    return front.devices(deviceId).presets().local().post(data);
  }

  static getDownloadUrl(id: string): string {
    return front.presets(id).url();
  }

  static deleteTeamPreset(id: string): Promise<void> {
    return front.presets(id).delete();
  }

  static deleteLocalPreset(deviceId: string, name: string): Promise<void> {
    return front.devices(deviceId).presets().local().remove().post({name});
  }
}
