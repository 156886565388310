import {
  AppNotifications,
  AppNotificationsContract,
} from 'app/components/entities/notifications/model';

function mapOwnershipNotification(
  c: AppNotificationsContract.OwnershipNotification,
): AppNotifications.OwnershipNotification {
  return {
    token: c.Token,
    type: c.Type,
    name: c.Name,
    time: c.Time,
    accepted: c.Accepted,
    ownerId: c.Data.FromUserID,
    ownerName: c.Data.FromUserName,
    teamId: c.Data.TeamID,
    teamName: c.Data.TeamName,
  };
}

function mapNotification(c: AppNotificationsContract.Notification): AppNotifications.Notification {
  return mapOwnershipNotification(c);
}

export const NotificationsMapper = {mapNotification};
