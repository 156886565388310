import Hls from 'hls.js';
import {secondsToMilliseconds} from 'app/util/timeConverter';
import {noop} from 'app/util/noop';
import {Callback} from 'app/types/common';
import {isNil} from 'app/util/isNil';

const FATAL_RETRY_TIMEOUT = secondsToMilliseconds(2);
const FATAL_RETRY_COUNT = 5;

interface Params {
  element: HTMLVideoElement;
  onError?: Callback;
}

export class VideoHlsController {
  private element: HTMLVideoElement;
  private hls?: Hls;
  private onError: Callback;
  private fatalRetriesLeft = FATAL_RETRY_COUNT;
  private retryTimeout: number = 0;
  private manifestParsed = false;
  private lastClickedStateIsPlay = false;
  private playPromise?: Promise<any>;

  constructor({element, onError = noop}: Params) {
    this.element = element;
    this.onError = onError;
  }

  play(url: string): void {
    this.lastClickedStateIsPlay = true;

    if (this.hls) {
      this.hls.destroy();
    }

    this.initialize(url, this.element);
    this.hls?.attachMedia(this.element);
  }

  async pause(): Promise<void> {
    this.lastClickedStateIsPlay = false;

    if (this.hls) {
      this.hls.stopLoad();
    }

    if (this.playPromise) {
      await this.playPromise;
      this.element.pause();
    }
  }

  destroy(): void {
    clearTimeout(this.retryTimeout);
    this.retryTimeout = 0;

    if (this.hls) {
      this.hls.destroy();
    }
  }

  private initialize(url: string, element: HTMLVideoElement) {
    this.hls = new Hls({
      manifestLoadingMaxRetry: 5,
      manifestLoadingTimeOut: secondsToMilliseconds(20),
      manifestLoadingRetryDelay: secondsToMilliseconds(4),
    });

    this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
      this.manifestParsed = false;
      this.resetFatalRetriesCounter();
      this.hls?.loadSource(url);
    });

    this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
      this.manifestParsed = true;

      if (this.lastClickedStateIsPlay) {
        this.playPromise = element.play();
        // TODO: Empty catch to prevent console logs?
        this.playPromise.catch(() => ({}));
      } else if (this.hls) {
        this.hls.stopLoad();
      }
    });

    this.hls.on(Hls.Events.FRAG_PARSED, () => {
      this.resetFatalRetriesCounter();
    });

    this.hls.on(Hls.Events.ERROR, (_event, data) => {
      if (!this.lastClickedStateIsPlay) {
        return;
      }

      if (!data.fatal) {
        return;
      }

      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          this.handleFatalError(data, () => {
            if (this.manifestParsed) {
              this.hls?.startLoad();
            } else {
              this.hls?.loadSource(url);
            }
          });
          break;
        case Hls.ErrorTypes.MEDIA_ERROR:
          this.handleFatalError(data, () => {
            this.hls?.recoverMediaError();
          });
          break;
        default:
          this.handleFatalError(data);
      }
    });
  }

  private resetFatalRetriesCounter() {
    this.fatalRetriesLeft = FATAL_RETRY_COUNT;
  }

  private handleFatalError(errorData: any, tryHandle?: Callback, onError = this.onError) {
    this.fatalRetriesLeft -= 1;

    if (!isNil(tryHandle) && this.fatalRetriesLeft > 0) {
      this.retryTimeout = window.setTimeout(tryHandle, FATAL_RETRY_TIMEOUT);
    } else if (onError) {
      onError(errorData);
    }
  }
}
