import React from 'react';
import {Paper, Stack, Typography} from '@mui/material';
import {useInView} from 'react-intersection-observer';
import {packSx} from 'app/util/packSx/packSx';
import {Thumbnail} from 'app/components/sharedReactComponents/Preview/Thumbnail/Thumbnail';
import {DeviceModel} from 'app/components/DeviceDetails/Models/DeviceModel';
import {Sx} from 'app/types/common';
import {DeviceAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/DeviceAudioMeter';
import {router} from 'app/router/main';
import {PreviewUpdateStrategy} from 'app/hooks/Device/Preview/types';
import {bodyStyles} from 'app/components/features/edge/EdgeDevice/body/body.styles';

type Props = Sx & {
  device: DeviceModel;
  selected: boolean;
  previewType: PreviewUpdateStrategy;
};

export function WebcasterDetails({sx, selected, device, previewType}: Props) {
  const {ref, inView} = useInView();

  const deviceId = device.getId();

  const detailsUrl = router.url('deviceDetails', {deviceId});

  return (
    <Paper sx={packSx(bodyStyles.root, selected && bodyStyles.selected, sx)} variant="outlined">
      <Stack direction="row" ref={ref} width={98} height={50} gap={0.5}>
        {inView && (
          <>
            <Thumbnail id={deviceId} detailsUrl={detailsUrl} updateStrategy={previewType} />

            <DeviceAudioMeter
              sx={{height: 50, width: 5}}
              id={deviceId}
              disabled={false}
              segmentsCount={13}
              segmentsGap={2}
              vertical={true}
            />
          </>
        )}
      </Stack>

      <Typography ml="auto" color="text.secondary">
        Controls are not available for Webcaster
      </Typography>
    </Paper>
  );
}
