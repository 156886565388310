const Location = {
  assign(url: string) {
    window.location.assign(url);
  },

  replace(url: string) {
    window.location.replace(url);
  },

  getHash() {
    return window.location.hash;
  },

  getOrigin() {
    return window.location.origin;
  },

  getHostName() {
    return window.location.hostname;
  },

  getSearch() {
    return window.location.search;
  },

  onChange(callback: () => void) {
    window.addEventListener('hashchange', callback);
  },
};

export {Location};
