import React from 'react';
import {Callback} from 'app/types/common';
import {ModalPrompt} from 'app/components/sharedReactComponents/Modal/ModalPrompt';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {SIZE, THEME} from 'app/constants';
import {ModalTitleHighlightedItem} from 'app/components/sharedReactComponents/Modal/Modal';

interface Args {
  name: string;
  onDone?: Callback;
  onClose?: Callback;
}

export const StopSrtPrompt = {
  show({
    name,
    onClose,
    onDone,
  }: Args) {
    ModalPrompt.show({
      className: 'stop-srt-prompt',
      fullWidth: true,
      maxWidth: SIZE.S,
      title: (
        <>
          Stop SRT stream for<br/>
          <ModalTitleHighlightedItem>
            <OverflowTooltip>{name}</OverflowTooltip>
          </ModalTitleHighlightedItem>?
        </>
      ),
      confirmButtonLabel: 'Yes, stop',
      confirmButtonTheme: THEME.SUCCESS,
      onClose,
      onDone,
    });
  }
};
