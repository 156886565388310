import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {grey} from '@mui/material/colors';
import {DangerouslyHTML} from 'app/components/sharedReactComponents/DangerouslyHTML';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {Sx} from 'app/types/common';

interface TitleProps extends Sx {}

interface Props extends Sx {
  title: string;
  titleProps?: TitleProps;
  getContent(): Promise<string>;
}

export function LegalInformation({sx, title, titleProps, getContent}: Props) {
  const {isSuccess, data} = useQuery({queryKey: ['legal-info'], queryFn: getContent});

  return (
    <Box sx={sx} component="article" className="legal">
      <Stack justifyContent="center" height={100} bgcolor={grey[200]}>
        <Typography sx={titleProps?.sx} width={1032} textAlign="center" variant="h3">
          {title}
        </Typography>
      </Stack>

      {isSuccess ? (
        <Box className="container">
          <DangerouslyHTML sx={{px: 0, py: 4}} content={data} />
        </Box>
      ) : (
        <IndeterminateProgressBar />
      )}
    </Box>
  );
}
