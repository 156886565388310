import {useCallback, useEffect, useRef, useState} from 'react';
import {ColumnArrangementManager} from 'app/components/Dashboard/Devices/TableView/ColumnManager/ColumnArrangementManager';
import {FleetManagerColumnId} from 'app/components/FleetManager/constants';
import {FleetManagerColumn} from 'app/components/FleetManager/types';

export function useColumnArrangementManager({
  storeKey,
  columns,
  activeColumns,
}: {
  storeKey: string;
  columns: FleetManagerColumn[];
  activeColumns: FleetManagerColumn[];
}) {
  const [arrangedColumns, setArrangedColumns] = useState<FleetManagerColumn[]>([]);
  const columnArrangementManager = useRef<ColumnArrangementManager>();
  const columnsMap = useRef<Map<FleetManagerColumnId, FleetManagerColumn>>(new Map());

  const upgrade = useCallback(() => {
    const arrangedColumns: FleetManagerColumn[] = [];

    const arrangement = columnArrangementManager.current?.getArrangement();
    arrangement?.forEach((columnId) => {
      // TODO: ColumnArrangementManager types
      const column = columnsMap.current.get(columnId as FleetManagerColumnId);

      if (column) {
        arrangedColumns.push(column);
      }
    });

    setArrangedColumns(arrangedColumns);
  }, []);

  useEffect(() => {
    const columnIds: FleetManagerColumnId[] = [];
    const activeColumnIds: FleetManagerColumnId[] = [];
    columnsMap.current = new Map();

    columns.forEach((column) => {
      columnIds.push(column.dataKey);
      columnsMap.current.set(column.dataKey, column);
    });

    activeColumns.forEach((column) => {
      activeColumnIds.push(column.dataKey);
    });

    columnArrangementManager.current = new ColumnArrangementManager({
      storeKey,
      columnIds,
      activeColumnIds,
    });

    upgrade();
  }, [columns, activeColumns, upgrade, storeKey]);

  const handleColumnsChangeArrangement = useCallback((columnId: string, nextIndex: number) => {
    columnArrangementManager.current?.move(columnId, nextIndex);
    upgrade();
  }, [upgrade]);

  return {
    arrangedColumns,
    handleColumnsChangeArrangement,
  };
}
