import React from 'react';
import {Box, Dialog, DialogContent, Stack, Typography} from '@mui/material';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {Callback} from 'app/types/common';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {PairingDetails} from 'app/components/sharedReactComponents/Pairing/PairingDetails/PairingDetails';

interface Props {
  open: boolean;
  team: string;
  onPair: Callback;
  onClose: Callback;
}

export function PairTeamsTenantDialog({open, team, onPair, onClose}: Props) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogClose onClose={onClose} />
      <DialogContent sx={{p: 7}}>
        <Box
          display="block"
          mb={2}
          component="img"
          src="/assets/img/logo/teams-logo.png"
          width={173}
          height={28}
          srcSet="/assets/img/logo/teams-logo@2x.png 2x"
          alt=""
        />
        <Typography mb={2} variant="h5" fontWeight="bold">
          Pair Microsoft Teams organization
        </Typography>
        <PairingDetails team={team} />
        <Stack mt={4} direction="row">
          <Button
            dataId="connect_teams_account_button"
            variant={VARIANT.SOLID}
            theme={THEME.INFO}
            onClick={onPair}
          >
            Pair as a Microsoft organization administrator
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
