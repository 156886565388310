import React from 'react';
import {Box, Typography} from '@mui/material';
import {SettingsList} from 'app/components/sharedReactComponents/SettingsList';
import {DROPDOWN_SELECT_THEME} from 'app/components/sharedReactComponents/DropdownSelect/constants';
import {isNil} from 'app/util/isNil';
import {Sx} from 'app/types/common';
import {useDeviceSettingsCollection} from 'app/hooks/Device/useDeviceSettingsCollection';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

interface Props extends Sx {
  device: AnyDeviceModelType;
}

export function Audio({sx, device}: Props) {
  const deviceSettingsCollection = useDeviceSettingsCollection(device);

  const updateSettingAction = async (id: string, value: string) => {
    return device.sendCommand(`setprop:${id}=${value}`);
  };

  if (!deviceSettingsCollection) {
    return null;
  }

  const audioSourcesSettings = deviceSettingsCollection.getAudioSourcesSettings();

  if (isNil(audioSourcesSettings) || audioSourcesSettings.hasSettings() === false) {
    return null;
  }

  const disabled = device.isOffline();

  return (
    <Box sx={sx}>
      <Typography fontWeight={600} variant="h5" mb={3}>
        Audio
      </Typography>

      <SettingsList
        settings={audioSourcesSettings.getSettings()}
        selectTheme={DROPDOWN_SELECT_THEME.DEFAULT}
        disabled={disabled}
        devices={{master: device}}
        updateSettingAction={updateSettingAction}
      />
    </Box>
  );
}
