import React from 'react';
import classNames from 'classnames';
import {ClassName, DataId} from 'app/types/common';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {SIZE, THEME} from 'app/constants';
import {Icons} from 'app/util/icons';

interface Props extends ClassName, DataId {
  name: string;
}

export const NoAudioLabel = ({className, name, dataId}: Props) => {
  const tooltip = `${name} is video only`;
  return (
    <Tooltip content={tooltip}>
      <div className={classNames('no-audio-label', className)} data-id={dataId}>
        {Icons.info()
          .theme(THEME.WARNING)
          .size(SIZE.S)
          .label('No audio available')
          .reactComponent()}
      </div>
    </Tooltip>
  );
};
