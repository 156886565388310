import React, {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {Controller, useForm, useWatch} from 'react-hook-form';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  capitalize,
} from '@mui/material';
import {DesktopDatePicker, DesktopDateTimePicker} from '@mui/x-date-pickers';
import RestoreIcon from '@mui/icons-material/Restore';
import AddIcon from '@mui/icons-material/Add';
import {zodResolver} from '@hookform/resolvers/zod';
import {getTimeZones} from '@vvo/tzdb';
import {DeviceForm} from 'app/components/sharedReactComponents/Events/Editor/EventForm/DeviceForm/DeviceForm';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {Sx} from 'app/types/common';
import {isNil} from 'app/util/isNil';
import {
  ChannelAction,
  Destination,
  DestinationType,
  ChannelFields,
  EventFormFields,
  EventConfig,
  DeviceConfiguration,
} from 'app/components/sharedReactComponents/Events/Editor/EventForm/types';
import {grey} from '@mui/material/colors';
import {useDeviceConfiguration} from 'app/components/sharedReactComponents/Events/hooks/useDevicesConfiguration';
import {renderDesktopDateTimeView} from 'app/components/sharedReactComponents/DateTimePicker/renderDesktopDateTimeView';
import {
  EventScheme,
  recurrenceType,
} from 'app/components/sharedReactComponents/Events/Editor/EventForm/scheme';
import {DevicePicker} from 'app/components/sharedReactComponents/Events/Editor/EventForm/DevicePicker/DevicePicker';
import {LoadingButton} from '@mui/lab';
import {useMounted} from 'app/hooks/useIsMounted';

interface Timezone {
  label: string;
  value: string;
}

const zones = new Map<string, Timezone>(
  getTimeZones().map((z) => [z.name, {label: `GMT${z.currentTimeFormat}`, value: z.name}]),
);

const timezones = Array.from(zones.values());

interface Props extends Sx {
  initConfig: EventConfig;
  mode: 'create' | 'edit';
  connectedDevices: Map<string, PearlMasterDeviceModel>;
  destinations: Destination[];
  configurable: boolean;
  onConfirm: (config: EventConfig) => Promise<void>;
  onClose: () => void;
}

export function EventForm({
  sx,
  mode,
  connectedDevices,
  initConfig,
  destinations,
  configurable,
  onConfirm,
  onClose,
}: Props) {
  const mounted = useMounted();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [loading, setLoading] = useState(false);

  const [devicesConf, dispatchConf] = useDeviceConfiguration({
    devicesConfig: initConfig.devices,
    connectedDevices,
    destinations,
  });

  const availableDevices = useMemo(() => Array.from(connectedDevices.values()), [connectedDevices]);

  const checkIsSelected = (id: string) => devicesConf.has(id);

  const {
    control,
    formState: {isValid},
    setValue,
    getValues,
    trigger,
  } = useForm<EventFormFields>({
    defaultValues: {...initConfig.event},
    mode: 'all',
    resolver: async (data, context, options) => {
      const result = await zodResolver(EventScheme)(data, context, options);

      return result;
    },
  });

  const {start, end, recurrent, recurrence} = useWatch({control});

  const userTimezone = dayjs.tz.guess();

  const [invalidDevices, setInvalidDevices] = useState<string[]>([]);

  useEffect(() => {
    void trigger();
  }, [trigger]);

  useEffect(() => {
    const result: string[] = [];

    if (devicesConf.size === 0) {
      result.push('No selected devices');
    }

    const usedDestinations = new Map<string, number>();

    Array.from(devicesConf.values()).forEach((meta) => {
      const {device, state} = meta;
      const name = device.getName();

      const channels = device.getChanelModels();

      if (!channels.length) {
        result.push(`${name}: no available channels`);
        return;
      }

      let [streaming, recording] = [0, 0];

      Array.from(state.values()).forEach((s) => {
        if (s.destination) {
          const count = usedDestinations.get(s.destination);
          usedDestinations.set(s.destination, isNil(count) ? 1 : count + 1);
        }

        if (s.streaming) {
          streaming += 1;
        }

        if (s.recording) {
          recording += 1;
        }
      });

      if (!streaming && !recording) {
        result.push(`${name}: no selected action at least on one channel`);
        return;
      }

      const duplicates = Array.from(usedDestinations.entries()).filter(([, value]) => value > 1);

      duplicates.forEach(([destId]) => {
        const name = destinations.find((d) => d.id === destId)?.name;
        result.push(`"${name ?? destId}" cannot be selected multiple times`);
      });
    });

    setInvalidDevices(result);
  }, [devicesConf, destinations]);

  const openDeviceMenu = (e: SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const closeDeviceMenu = () => {
    setAnchorEl(null);
  };

  const handleAddDevice = (i: PearlMasterDeviceModel) => {
    const channelMap = new Map<string, ChannelFields>();

    const channels = i.getChanelModels();

    channels.forEach((c) => {
      channelMap.set(c.getChannelDeviceIdIndex(), {
        destination: '',
        publishers: [],
        recording: true,
        streaming: false,
      });
    });

    dispatchConf({type: 'add', payload: {device: i, state: channelMap}});
    closeDeviceMenu();
  };

  const handleRemoveDevice = (id: string) => {
    dispatchConf({type: 'delete', payload: {id}});
  };

  const handleChannelDestination = (
    deviceId: string,
    channelIdx: string,
    type: DestinationType,
    value: string,
  ) => {
    dispatchConf({
      type: 'streaming-destination',
      payload: {
        deviceId,
        channelIdx,
        type,
        value,
      },
    });
  };

  const handleChannelAction = (
    deviceId: string,
    channelIdx: string,
    type: ChannelAction,
    value: boolean,
  ) => {
    dispatchConf({
      type: 'channel-action',
      payload: {
        deviceId,
        channelIdx,
        type,
        value,
      },
    });
  };

  const isDevicePickerOpen = Boolean(anchorEl);

  const hasDevices = devicesConf.size > 0;

  const now = dayjs();

  const resetTimezone = () => {
    setValue('timezone', dayjs.tz.guess(), {shouldValidate: true});
  };

  const handleConfirm = async () => {
    setLoading(true);
    const validation = await trigger();

    if (!validation) {
      setLoading(false);
      return;
    }

    const fields = getValues();
    const devices = getDevicesConf(devicesConf);
    try {
      await onConfirm({event: fields, devices});
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  const disabledConfirm = !isValid || invalidDevices.length > 0;

  const handleStartDateChange = (value: Dayjs | null, onChange: (v: number) => void) => {
    if (value?.isValid()) {
      const result = value;

      const end = getValues('end');
      const until = getValues('recurrence.until.date');

      if (end === 0 || result.isAfter(dayjs.unix(end), 'minutes')) {
        setValue('end', result.add(1, 'hour').unix(), {shouldValidate: true});
      }

      if (until === 0 || result.isAfter(dayjs.unix(end), 'days')) {
        setValue('recurrence.until.date', result.add(1, 'day').unix(), {shouldValidate: true});
      }

      onChange(result.unix());
    } else {
      setValue('end', 0, {shouldValidate: true});
      onChange(0);
    }
    void trigger(['end', 'recurrence.until.date']);
  };

  const handleEndDateChange = (value: Dayjs | null, onChange: (v: number) => void) => {
    if (value?.isValid()) {
      const until = getValues('recurrence.until.date');
      const result = value;

      if (until === 0 || result.isAfter(dayjs.unix(until), 'days')) {
        setValue('recurrence.until.date', result.add(1, 'day').unix(), {shouldValidate: true});
      }

      onChange(result.unix());
    } else {
      onChange(0);
    }
    void trigger('recurrence.until.date');
  };

  return (
    <Stack sx={sx} flexGrow={1} component="form" minHeight={0}>
      <Box p={3} flexGrow={1} sx={{overflowY: 'auto'}}>
        <FormLabel>Event name</FormLabel>
        <Controller
          name="title"
          control={control}
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              inputProps={{'data-id': 'event-name-input'}}
              variant="standard"
              error={!isNil(error)}
              fullWidth={true}
              helperText={error?.message ?? ' '}
            />
          )}
        />

        <Stack direction="row" gap={2}>
          <Box>
            <FormLabel sx={{display: 'block'}}>Start date and time</FormLabel>
            <Controller
              name="start"
              control={control}
              render={({field: {value: formValue, onChange, onBlur}, fieldState: {error}}) => {
                const instance = formValue > 0 ? dayjs.unix(formValue) : null;
                const hasError = !isNil(error?.message);
                const helperText = hasError ? error?.message ?? ' ' : ' ';

                return (
                  <DesktopDateTimePicker
                    value={instance}
                    minDate={now}
                    minTime={now}
                    timeSteps={{minutes: 15}}
                    disableIgnoringDatePartForTimeValidation={true}
                    skipDisabled={true}
                    viewRenderers={{
                      hours: renderDesktopDateTimeView,
                      minutes: renderDesktopDateTimeView,
                      seconds: renderDesktopDateTimeView,
                      meridiem: renderDesktopDateTimeView,
                      day: renderDesktopDateTimeView,
                      month: renderDesktopDateTimeView,
                      year: renderDesktopDateTimeView,
                    }}
                    slotProps={{
                      textField: {
                        inputProps: {'data-id': 'start-date-input'},
                        placeholder: 'Select date',
                        error: hasError,
                        helperText,
                        onBlur,
                      },
                    }}
                    onChange={(newValue) => handleStartDateChange(newValue, onChange)}
                  />
                );
              }}
            />
          </Box>

          <Box>
            <FormLabel sx={{display: 'block'}}>End date and time</FormLabel>

            <Controller
              name="end"
              control={control}
              render={({field: {value: formValue, onChange, onBlur}, fieldState: {error}}) => {
                const disabled = !start;
                const hasError = !disabled && Boolean(error?.message);
                const helperText = hasError ? error?.message ?? ' ' : ' ';

                const instance = formValue > 0 ? dayjs.unix(formValue) : null;

                const minDate = start ? dayjs.unix(start) : undefined;

                return (
                  <DesktopDateTimePicker
                    value={instance}
                    minDate={minDate}
                    minTime={minDate}
                    timeSteps={{minutes: 15}}
                    disabled={disabled}
                    disableIgnoringDatePartForTimeValidation={true}
                    skipDisabled={true}
                    viewRenderers={{
                      hours: renderDesktopDateTimeView,
                      minutes: renderDesktopDateTimeView,
                      seconds: renderDesktopDateTimeView,
                      meridiem: renderDesktopDateTimeView,
                      day: renderDesktopDateTimeView,
                      month: renderDesktopDateTimeView,
                      year: renderDesktopDateTimeView,
                    }}
                    slotProps={{
                      textField: {
                        inputProps: {'data-id': 'end-date-input'},
                        placeholder: 'Select date',
                        error: hasError,
                        helperText,
                        onBlur,
                      },
                    }}
                    onChange={(newValue) => handleEndDateChange(newValue, onChange)}
                  />
                );
              }}
            />
          </Box>

          <Box flex={1}>
            <FormLabel sx={{display: 'block'}}>Timezone</FormLabel>

            <Controller
              name="timezone"
              control={control}
              render={({field: {ref, value: formValue, onChange, onBlur}, fieldState: {error}}) => {
                return (
                  <Stack direction="row" gap={1} alignItems="start">
                    <Autocomplete
                      ref={ref}
                      fullWidth={true}
                      value={zones.get(formValue) ?? null}
                      options={timezones}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{...params.inputProps, 'data-id': 'timezone-select'}}
                          error={!isNil(error)}
                          helperText={error?.message ?? ' '}
                        />
                      )}
                      onChange={(_e, value) => {
                        onChange(value?.value ?? '');
                      }}
                      onBlur={onBlur}
                    />

                    <Tooltip title="Use current timezone">
                      <span>
                        <IconButton
                          data-id="default-tz-button"
                          disabled={userTimezone === formValue}
                          sx={{height: 36}}
                          onClick={resetTimezone}
                        >
                          <RestoreIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Stack>
                );
              }}
            />
          </Box>
        </Stack>

        <Controller
          control={control}
          name="recurrent"
          render={({field}) => (
            <FormControlLabel
              sx={{mb: 0, alignSelf: 'flex-start'}}
              label="Repeat event"
              control={
                <Checkbox
                  data-id="repeat-checkbox"
                  ref={field.ref}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  onBlur={field.onBlur}
                />
              }
            />
          )}
        />

        <Collapse in={recurrent}>
          <Box border={`1px solid ${grey[400]}`} borderRadius={1} px={4} py={3}>
            <FormLabel>Repeat</FormLabel>
            <Controller
              control={control}
              name="recurrence.type"
              render={({field}) => (
                <Select data-id="repeat-selector" sx={{display: 'block', width: 200}} {...field}>
                  {Object.keys(recurrenceType.enum).map((v) => (
                    <MenuItem data-id={`repeat-option-${v}`} key={v} value={v}>
                      {capitalize(v)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            <FormLabel sx={{mt: 2}}>End</FormLabel>
            <Controller
              control={control}
              name="recurrence.until.type"
              render={({field}) => (
                <RadioGroup
                  sx={{gap: 3, alignItems: 'start', '& label': {mb: 0, mr: 0}}}
                  ref={field.ref}
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={(e) => field.onChange(e.target.value)}
                  row={true}
                >
                  <FormControlLabel
                    value="never"
                    control={<Radio data-id="never-end-radio" />}
                    label="Never"
                  />

                  <Stack direction="row" alignItems="start" gap={1}>
                    <FormControlLabel
                      value="date"
                      control={<Radio data-id="until-date-radio" />}
                      label="On"
                    />
                    <Controller
                      control={control}
                      name="recurrence.until.date"
                      render={({
                        field: {value: formValue, onChange, onBlur},
                        fieldState: {error},
                      }) => {
                        const instance = formValue ? dayjs.unix(formValue) : null;
                        const disabled = recurrence?.until?.type !== 'date';

                        const minDate = !isNil(end) && end > 0 ? dayjs.unix(end) : now;

                        const hasError = !disabled && Boolean(error?.message);
                        const helperText = hasError ? error?.message ?? ' ' : ' ';

                        return (
                          <DesktopDatePicker
                            sx={{width: 150}}
                            value={instance}
                            disabled={disabled}
                            minDate={minDate}
                            slotProps={{
                              textField: {
                                id: 'until-date',
                                inputProps: {'data-id': 'occurrences-input'},
                                error: hasError,
                                helperText,
                                onBlur,
                              },
                            }}
                            onChange={(newValue) => {
                              if (newValue?.isValid()) {
                                onChange(newValue.unix());
                              } else {
                                onChange(0);
                              }
                            }}
                          />
                        );
                      }}
                    />
                  </Stack>

                  <Stack direction="row" alignItems="start" gap={1}>
                    <FormControlLabel
                      value="occurrences"
                      control={<Radio data-id="occurrences-radio" />}
                      label="After"
                    />
                    <Controller
                      control={control}
                      name="recurrence.until.occurrences"
                      render={({field, fieldState: {error}}) => {
                        const disabled = recurrence?.until?.type !== 'occurrences';
                        const hasError = !disabled && !isNil(error);
                        const helperText = hasError ? error.message ?? ' ' : ' ';

                        return (
                          <TextField
                            {...field}
                            value={field.value >= 0 ? field.value : ''}
                            sx={{width: 75}}
                            inputProps={{'data-id': 'occurrences-input'}}
                            disabled={disabled}
                            error={hasError}
                            helperText={helperText}
                            onChange={(e) => {
                              const input = e.target.value;
                              const parsed = Number.parseInt(input, 10);

                              if (input === '' || Number.isNaN(parsed)) {
                                field.onChange(Number.MIN_SAFE_INTEGER);
                                return;
                              }

                              if (/^[0-9]+$/.test(input)) {
                                field.onChange(parsed);
                              }
                            }}
                          />
                        );
                      }}
                    />
                    <Typography lineHeight="36px" color={grey[500]}>
                      occurrences
                    </Typography>
                  </Stack>
                </RadioGroup>
              )}
            />
          </Box>
        </Collapse>

        <Stack mt={1} direction="row" justifyContent="space-between" alignItems="center">
          <Typography fontWeight={600} fontSize={16}>
            Devices and Channels
          </Typography>

          {configurable && (
            <Button
              data-id="add-device-button"
              startIcon={<AddIcon />}
              variant="outlined"
              color="primary"
              disabled={devicesConf.size > 0}
              onClick={openDeviceMenu}
            >
              Add device
            </Button>
          )}

          <Popover
            open={isDevicePickerOpen}
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            onClose={closeDeviceMenu}
          >
            <DevicePicker
              sx={{width: 550}}
              checkIsSelected={checkIsSelected}
              devices={availableDevices}
              onSelect={handleAddDevice}
            />
          </Popover>
        </Stack>

        <Stack flexGrow={1} mt={2} gap={2}>
          {hasDevices &&
            Array.from(devicesConf.values()).map((s) => (
              <DeviceForm
                dataId={`device-${s.device.getId()}-form`}
                key={s.device.getId()}
                device={s.device}
                deviceState={s.state}
                destinations={destinations}
                removable={configurable}
                setChannelAction={handleChannelAction}
                selectChannelDestination={handleChannelDestination}
                onRemove={handleRemoveDevice}
              />
            ))}

          {!hasDevices && (
            <Stack
              sx={{
                p: 3,
                borderWidth: 1,
                bgcolor: grey[50],
                borderColor: grey[400],
                borderRadius: 1,
                borderStyle: 'dashed',
              }}
            >
              <Typography textAlign="center" color={grey[500]}>
                Selected devices and channels will be shown here
              </Typography>
            </Stack>
          )}
        </Stack>
      </Box>

      <Stack
        py={1}
        borderTop={1}
        borderColor={grey[400]}
        bgcolor={grey[100]}
        direction="row"
        justifyContent="center"
        gap={2}
      >
        <Tooltip disableFocusListener title={invalidDevices.join('\n')}>
          <span>
            <LoadingButton
              data-id="submit-button"
              variant="contained"
              color="secondary"
              disabled={disabledConfirm}
              loading={loading}
              onClick={handleConfirm}
            >
              {mode === 'create' ? 'Create' : 'Save'}
            </LoadingButton>
          </span>
        </Tooltip>

        <Button data-id="close-form-button" variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

function getDevicesConf(
  map: Map<string, DeviceConfiguration>,
): Map<string, Map<string, ChannelFields>> {
  const result = new Map<string, Map<string, ChannelFields>>();

  Array.from(map.entries()).forEach((d) => {
    const [deviceId, value] = d;

    const conf = new Map<string, ChannelFields>();

    Array.from(value.state.entries()).forEach((c) => {
      const [channelId, state] = c;
      conf.set(channelId, state);
    });

    result.set(deviceId, conf);
  });

  return result;
}
