import React from 'react';
import {Box, Card, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {METER_THEME, Meter} from 'app/components/sharedReactComponents/Meter';
import {Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';

interface Props extends Sx {
  value: number;
}

export function LoadPanel({sx, value}: Props) {
  const isWarning = value >= 70;

  return (
    <Card
      sx={packSx(
        {display: 'flex', flexDirection: 'row', gap: 2, p: 2},
        isWarning && {borderColor: 'error.main'},
        sx,
      )}
      variant="outlined"
    >
      <Box>
        <Meter
          value={value}
          theme={isWarning ? METER_THEME.RED : METER_THEME.DEFAULT}
          vertical={true}
          disabled={false}
        />
      </Box>

      <Box>
        <Typography variant="h6" color={grey[600]} mb={0.5}>
          System load
        </Typography>

        <Typography
          data-id="device_performance_system_load"
          fontSize={24}
          color={isWarning ? 'error.main' : 'text.primary'}
        >
          {value}%
        </Typography>
      </Box>
    </Card>
  );
}
