import {useQuery} from '@tanstack/react-query';
import {createDeviceModel} from 'app/components/DeviceDetails/Models/Fabric';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {ScheduleApiService} from 'app/services/api/schedule/ScheduleApiService';
import {stringComparator} from 'app/util/Sort';

interface Args {
  teamId: string;
  enabled: boolean;
}

export function useEpiphanCmsDevices({teamId, enabled}: Args) {
  const query = useQuery({
    queryKey: ['epiphan-cms-devices', teamId],
    queryFn: async () => {
      const contract = await ScheduleApiService.getEpiphanCmsDevices();
      const mapped = contract.map((c) => createDeviceModel(c));

      const filtered = mapped.filter(
        (d) => d instanceof PearlMasterDeviceModel,
      ) as PearlMasterDeviceModel[];

      const connectedDevices = new Map(
        filtered
          .sort((a, b) => stringComparator(a.getName(), b.getName()))
          .map((d) => [d.getId(), d]),
      );
      return connectedDevices;
    },
    enabled,
  });

  return query;
}
