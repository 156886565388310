import React from 'react';
import {ClassName, DataId} from 'app/types/common';
import {SIZE} from 'app/constants';
import {PreviewUpdateStrategy} from 'app/hooks/Device/Preview/types';
import {useDeviceId} from 'app/hooks/Device/useDeviceId';
import {ChannelPreview} from 'app/components/sharedReactComponents/Preview/ChannelPreview/ChannelPreview';
import {DevicePreview} from 'app/components/sharedReactComponents/Preview/DevicePreview/DevicePreview';
import classNames from 'classnames';

interface Props extends ClassName, DataId {
  id: string;
  updateStrategy: PreviewUpdateStrategy;
  size?: SIZE;
  alt?: string;
}

export const Preview: React.VFC<Props> = ({className, id, updateStrategy, dataId, size, alt}) => {
  const [, channel] = useDeviceId(id);

  const classes = classNames('preview', className);

  if (channel) {
    return (
      <ChannelPreview
        className={classes}
        channelId={id}
        updateStrategy={updateStrategy}
        big={size === SIZE.XXL}
        dataId={dataId}
        alt={alt}
      />
    );
  }

  return (
    <DevicePreview
      className={classes}
      deviceId={id}
      updateStrategy={updateStrategy}
      size={size}
      dataId={dataId}
      alt={alt}
    />
  );
};
