import {maintenance, front} from 'app/api/CirrusApi';
import {Maintenance, MaintenanceContracts} from 'app/components/entities/maintenance';

export class MaintenanceApi {
  static async getMaintenance(): Promise<Maintenance.Entity[]> {
    return maintenance.windows().get();
  }

  static async getVersion(): Promise<MaintenanceContracts.Version> {
    return front.versions().get();
  }
}
