import {parseLocationSearch} from 'app/util/OAuthProvider/utils';

export class OAuthCallback {
  protected callback;

  // Used in callback HTML
  protected globalCallbackName = 'loginCallback';
  protected fired = false;

  constructor(callback) {
    this.callback = callback;

    this.register();
  }

  public destroy() {
    this.unregister();
    if (!this.fired) {
      this.callback({loginCallbackIsNotFired: true});
    }
  }

  private register() {
    this.setGlobalCallback((search: string) => {
      this.fired = true;
      this.unregister();
      this.callback(parseLocationSearch(search));
    });
  }

  private unregister() {
    this.setGlobalCallback(undefined);
  }

  private setGlobalCallback(callback) {
    window[this.globalCallbackName] = callback;
  }
}
