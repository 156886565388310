import {ReducerAction, ReducerActionWithData} from 'app/types/common';
import {useReducer, Reducer} from 'react';

type View =
  | 'none'
  | 'payment'
  | 'cloud-tiers'
  | 'confirm-cloud'
  | 'enter-coupon'
  | 'connect-tiers'
  | 'confirm-connect'
  | 'unify-tiers'
  | 'confirm-unify'
  | 'cancel-pending-invoice';

type BillingProcess =
  | 'payment'
  | 'cloud'
  | 'unify'
  | 'connect'
  | 'coupon'
  | 'none'
  | 'pending-invoice';

type PaymentAction = 'edit-payment' | 'save-payment';
type CouponAction = 'enter-coupon';
type CloudAction = 'cloud-tiers' | 'confirm-cloud';
type ConnectAction = 'connect-tiers' | 'confirm-connect';
type UnifyAction = 'unify-tiers' | 'confirm-unify';

type ActionType =
  | 'run-process'
  | 'close'
  | 'custom'
  | PaymentAction
  | CouponAction
  | CloudAction
  | ConnectAction
  | UnifyAction
  | 'cancel-pending-invoice';

type BillingAction<T extends ActionType> = ReducerAction<T>;
type BillingActionWithData<T extends ActionType, P extends any> = ReducerActionWithData<T, P>;

type Acton =
  | BillingAction<'close'>
  | BillingActionWithData<'custom', Partial<State>>
  | BillingActionWithData<'run-process', State>
  | BillingAction<'save-payment'>
  | BillingAction<'edit-payment'>
  | BillingAction<'cloud-tiers'>
  | BillingAction<'confirm-cloud'>
  | BillingAction<'connect-tiers'>
  | BillingAction<'confirm-connect'>
  | BillingAction<'cancel-pending-invoice'>
  | BillingAction<'unify-tiers'>
  | BillingAction<'confirm-unify'>;

interface State {
  process: BillingProcess;
  view: View;
}

export function useBillingProcess() {
  return useReducer<Reducer<State, Acton>, State>(
    reducer,
    {process: 'none', view: 'none'},
    (state) => state,
  );
}

function reducer(state: State, action: Acton): State {
  switch (action.type) {
    case 'custom': {
      return {...state, ...action.payload};
    }
    case 'close': {
      return {process: 'none', view: 'none'};
    }

    case 'run-process': {
      return {...action.payload};
    }

    case 'edit-payment': {
      return {...state, view: 'payment'};
    }

    case 'save-payment': {
      return {...state, view: selectView(state.process, 'save-payment')};
    }

    case 'cloud-tiers': {
      return {...state, view: 'cloud-tiers'};
    }

    case 'confirm-cloud': {
      return {...state, view: 'confirm-cloud'};
    }

    case 'connect-tiers': {
      return {...state, view: 'connect-tiers'};
    }

    case 'confirm-connect': {
      return {...state, view: 'confirm-connect'};
    }

    case 'unify-tiers': {
      return {...state, view: 'unify-tiers'};
    }

    case 'confirm-unify': {
      return {...state, view: 'confirm-unify'};
    }

    default:
      return state;
  }
}

function selectView(process: BillingProcess, action: ActionType): View {
  switch (action) {
    case 'save-payment': {
      switch (process) {
        case 'coupon': {
          return 'enter-coupon';
        }

        case 'cloud': {
          return 'cloud-tiers';
        }

        case 'connect': {
          return 'connect-tiers';
        }

        case 'unify': {
          return 'unify-tiers';
        }

        default: {
          return 'none';
        }
      }
    }

    default: {
      return 'none';
    }
  }
}
