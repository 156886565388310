import React from 'react';
import classNames from 'classnames';
import {ClassName, WarningBaseType} from 'app/types/common';
import {TooltipMultipleMessages} from 'app/components/sharedReactComponents/Tooltip/TooltipMultipleMessages';
import {Icons} from 'app/util/icons';

interface Props extends ClassName {
  warnings: WarningBaseType[];
  showFirstMessage?: boolean;
}

const WarningsIndicator: React.VFC<Props> = ({
  className,
  warnings,
  showFirstMessage,
  ...elementProps
}) => {
  const firstWarning = warnings.length > 0 ? warnings[0] : undefined;

  if (!firstWarning) {
    return null;
  }

  return (
    <TooltipMultipleMessages messages={warnings}>
      <div
        className={classNames('warnings-indicator', className)}
        {...elementProps}
      >
        {Icons.warning().reactComponent()}

        {showFirstMessage && (
          <span className="warnings-indicator__label">
            {firstWarning.message}
          </span>
        )}
      </div>
    </TooltipMultipleMessages>
  );
};

export {WarningsIndicator};
