import React, {useCallback, useState} from 'react';
import {
  Modal,
  ModalBody,
  ModalCancelButton,
  ModalContainer,
  ModalFooter,
  ModalTitle,
} from 'app/components/sharedReactComponents/Modal/Modal';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {
  SIZE,
  THEME,
  VARIANT,
} from 'app/constants';
import {Form} from 'app/components/libs/dialogs/CreateRTMPStreamingDestinationPrompt/Form';
import {front} from 'app/api/CirrusApi';
import {Notifications} from 'app/components/Notifications';
import {getApiErrorMessage} from 'app/util/getApiErrorMessage';

/**
 * @type {string[]}
 */
const RTMP_REQUIRED_FIELDS = [
  'name',
  'url',
];

interface Props {
  onDone: (name: string) => void;
}

const Content: React.FC<Props> = ({onDone}) => {
  const [formData, setFormData] = useState({
    name: '',
    url: '',
    key: '',
    valid: false,
  });

  const handleChange = useCallback(formData => {
    setFormData(formData);
  }, []);

  const handleClickSubmit = useCallback(() => {
    const {
      name,
      url,
      key,
    } = formData;

    return front.streams().post({
      Name: name,
      RTMP: {
        DestName: name,
        StreamingKey: key,
        URL: url,
      }
    })
      .then(() => {
        onDone(name);
        Modal.hide();
      })
      .catch(async error => {
        const errorText = getApiErrorMessage(error);
        Notifications.addErrorNotification(`Create streaming destination request error${errorText ? `: "${errorText}"` : ''}`);

        return Promise.reject();
      });
  }, [formData, onDone]);

  return (
    <ModalContainer>
      <ModalTitle>
        New RTMP/RTMPS streaming destination
      </ModalTitle>

      <ModalBody>
        <Form
          formData={formData}
          requiredFields={RTMP_REQUIRED_FIELDS}
          onChange={handleChange}
        />
      </ModalBody>

      <ModalFooter>
        <ProgressButton
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          disabled={formData.valid === false}
          onClick={handleClickSubmit}
          dataId="modal_confirm_button"
        >
          Save
        </ProgressButton>

        <ModalCancelButton/>
      </ModalFooter>
    </ModalContainer>
  );
};

export const CreateRTMPStreamingDestinationPrompt = {
  show({
    onDone,
  }: Props) {
    Modal.show({
      className: 'create-rtmp-streaming-destination-prompt',
      fullWidth: true,
      maxWidth: SIZE.L,
      children: (
        <Content
          onDone={onDone}
        />
      )
    });
  }
};
