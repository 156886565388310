import React, {createContext, useMemo, useContext, useState} from 'react';
import {TeamsConnectDialog} from 'app/components/dialogs/TeamsConnectDialog/TeamsConnectDialog';
import {ConnectToHostedDialog} from 'app/components/dialogs/ConnectToHostedDialog/ConnectToHostedDialog';
import {ZoomConnectDialog} from 'app/components/dialogs/ZoomConnectDialog/ZoomConnectDialog';
import {MeetingsApiService} from 'app/services/api/meetings/MeetingsApiService';
import {ZoomPasscodeDialog} from 'app/components/dialogs/ZoomPasscodeDialog/ZoomPasscodeDialog';
import {useStorageValue} from 'app/components/pages/MeetingDetails/ParticipantsList/useStorageValue';
import {StartingZoomMeetingDialog} from 'app/components/dialogs/StartingZoomMeetingDialog/StartingZoomMeetingDialog';
import {useZoomAccounts} from 'app/hooks/useZoomAccounts';
import {ZoomIdentityArgs} from 'app/components/MeetingsManager/dialogs/ZoomIdentityDialog/ZoomIdentity/ZoomIdentity';
import {ZoomIdentityDialog} from 'app/components/MeetingsManager/dialogs/ZoomIdentityDialog/ZoomIdentityDialog';
import {DeleteCallDialog} from 'app/components/MeetingsManager/dialogs/DeleteCallDialog/DeleteCallDialog';

interface HostedArgs {
  id: string;
  url: string;
}

export type ConnectActions = {
  openTeams: Fn;
  openZoom: Fn;
  connectHosted: Fn<void, [args: HostedArgs]>;
  enterPasscode: Fn<void, [id: string]>;
  setIdentity: Fn<void, [args: ZoomIdentityArgs]>;
  deleteCall: Fn<void, [c: App.Connect.Call]>;
};

export const ConnectContext = createContext<ConnectActions | undefined>(undefined);

type View =
  | 'connect-zoom'
  | 'connect-teams'
  | 'connect-hosted'
  | 'zoom-pwd'
  | 'zoom-starting'
  | 'zoom-identity'
  | 'delete-call';

interface Props {
  teamId: string;
  userId: string;
  hasTenants: boolean;
  canEdit: boolean;
  reloadHosted: Fn<Promise<any>>;
  reloadMeetings: Fn<Promise<any>>;
  onPair: Fn;
}

export function useConnectAction() {
  const ctx = useContext(ConnectContext);

  if (!ctx) {
    throw new Error('Component should be in context');
  }

  return ctx;
}

export function ConnectManager({
  teamId,
  userId,
  children,
  hasTenants,
  canEdit,
  reloadHosted,
  reloadMeetings,
  onPair,
}: React.PropsWithChildren<Props>) {
  const [view, setView] = useState<View | undefined>();

  const [zoomIdentityArgs, setZoomIdentityArgs] = useState<ZoomIdentityArgs | undefined>(undefined);
  const [deleteCallArgs, setDeleteCallArgs] = useState<App.Connect.Call | undefined>(undefined);

  const [hostedArgs, setHostedArgs] = useState<HostedArgs | undefined>();
  const [zoomMeeting, setZoomMeeting] = useState('');

  const zoomQuery = useZoomAccounts({enabled: true, teamId});

  const handleClean = () => {
    setZoomIdentityArgs(undefined);
    setHostedArgs(undefined);
    setZoomMeeting('');
  };

  const handleClose = () => {
    setView(undefined);
    handleClean();
  };

  const handlePair = () => {
    handleClose();
    onPair();
  };

  const [skipStarting, setSkipStarting] = useStorageValue<boolean>({
    defaultValue: false,
    teamId,
    userId,
    key: 'Dialogs.StartingZoom',
  });

  const actions = useMemo<ConnectActions>(
    () => ({
      connectHosted: (data) => {
        setHostedArgs(data);
        setView('connect-hosted');
      },
      openTeams: () => {
        setView('connect-teams');
      },
      openZoom: () => {
        setView('connect-zoom');
      },
      enterPasscode: (id: string) => {
        setZoomMeeting(id);
        setView('zoom-pwd');
      },
      setIdentity: (p) => {
        setZoomIdentityArgs(p);
        setView('zoom-identity');
      },
      deleteCall: (p) => {
        setDeleteCallArgs(p);
        setView('delete-call');
      },
    }),
    [],
  );

  const handleZoomConnect = async (
    data: Parameters<React.ComponentProps<typeof ZoomConnectDialog>['onConnect']>[0],
  ) => {
    const {mode, code, url, account} = data;

    await MeetingsApiService.connectToMeeting({
      isolated_audio: mode === 'isolated',
      kind: 'zoom',
      meeting_url: url,
      meeting_password: code,
      account_id: account === 'guest' ? undefined : account,
    });

    if (!skipStarting) {
      handleClean();
      setView('zoom-starting');
    } else {
      handleClose();
    }
  };

  const handleTeamsConnect = async (
    data: Parameters<React.ComponentProps<typeof TeamsConnectDialog>['onConnect']>[0],
  ) => {
    const {mode, url} = data;

    await MeetingsApiService.connectToMeeting({
      kind: 'teams',
      isolated_audio: mode === 'isolated',
      meeting_url: url,
    });

    await reloadMeetings();
    handleClose();
  };

  const handleTeamsCreate = async (name: string) => {
    await MeetingsApiService.createHosted(name);
    await reloadHosted();
    handleClose();
  };

  const handleHostedConnect = async (mode: App.Connect.ConnectionMode) => {
    if (!hostedArgs) {
      return;
    }

    const {url} = hostedArgs;
    await MeetingsApiService.connectToMeeting({
      kind: 'teams',
      isolated_audio: mode === 'isolated',
      meeting_url: url,
    });

    await reloadMeetings();
    handleClose();
  };

  const handleZoomPasscode = async (pwd: string) => {
    if (!zoomMeeting) {
      return;
    }

    await MeetingsApiService.setPasscode(zoomMeeting, {meeting_password: pwd});
    handleClose();
  };

  const handleDeleteCall = async () => {
    if (!deleteCallArgs) {
      return;
    }

    await MeetingsApiService.deleteCall(deleteCallArgs.id);
    await reloadHosted();
    handleClose();
  };

  const handleApplyZoomAccount = async (meetingId: string, accountId: string) => {
    await MeetingsApiService.setZoomAccount(meetingId, {account_id: accountId});
    handleClose();
  };

  return (
    <ConnectContext.Provider value={actions}>
      <TeamsConnectDialog
        open={view === 'connect-teams'}
        canPair={canEdit}
        hasTenants={hasTenants}
        onConnect={handleTeamsConnect}
        onCreate={handleTeamsCreate}
        onClose={handleClose}
        onPair={handlePair}
      />

      <ZoomConnectDialog
        open={view === 'connect-zoom'}
        accounts={zoomQuery.data ?? []}
        onConnect={handleZoomConnect}
        onClose={handleClose}
      />

      {hostedArgs && (
        <ConnectToHostedDialog
          open={view === 'connect-hosted'}
          onConnect={handleHostedConnect}
          onClose={handleClose}
        />
      )}

      <ZoomPasscodeDialog
        open={view === 'zoom-pwd'}
        onSubmit={handleZoomPasscode}
        onClose={handleClose}
      />

      <StartingZoomMeetingDialog
        open={view === 'zoom-starting'}
        state={skipStarting}
        setState={setSkipStarting}
        onClose={handleClose}
      />

      {zoomIdentityArgs && (
        <ZoomIdentityDialog
          open={view === 'zoom-identity'}
          args={zoomIdentityArgs}
          accounts={zoomQuery.data ?? []}
          onApply={handleApplyZoomAccount}
          onClose={handleClose}
        />
      )}
      {deleteCallArgs && (
        <DeleteCallDialog
          name={deleteCallArgs.name}
          open={view === 'delete-call'}
          onDelete={handleDeleteCall}
          onClose={handleClose}
        />
      )}
      {children}
    </ConnectContext.Provider>
  );
}
