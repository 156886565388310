import React from 'react';
import {observer} from 'mobx-react';
import {Component} from 'app/types/common';
import {SIZE, THEME} from 'app/constants';
import {SrtStream} from 'app/store/models/connect/SrtStream/SrtStream';
import {Icons} from 'app/util/icons';
import classNames from 'classnames';

interface Props extends Component {
  stream: SrtStream;
}

export const SrtConnectionState = observer(({className, dataId, stream}: Props) => {
  const {icon, theme} = useIndicator(stream);

  const {stopping, starting, started, connected, disconnected, failed, error} = stream;

  const renderStatus = () => {
    if (connected) {
      return <Status>Connected</Status>;
    }

    if (started || starting || stopping) {
      return <Status className="srt-connection-state__status--connecting">Connecting</Status>;
    }

    if (disconnected) {
      return (
        <Status className="srt-connection-state__status--disconnected">Connection lost</Status>
      );
    }

    if (failed) {
      const message = error?.message;

      return (
        <Status className="srt-connection-state__status--error">
          Connection failed
          {message && (
            <div className="srt-connection-state__message" data-id="srt-error-message">
              {error?.message}
            </div>
          )}
        </Status>
      );
    }

    return null;
  };

  return (
    <div data-id={dataId} className={classNames('srt-connection-state', className)}>
      <div className="srt-connection-state__indicator">
        <Divider theme={theme} />

        <div data-id="status_icon">
          {icon.size(SIZE.M).class('srt-connection-state__icon').theme(theme).reactComponent()}
        </div>

        <Divider theme={theme} />
      </div>

      {renderStatus()}
    </div>
  );
});

interface DividerProps {
  theme: THEME;
}

function Divider({theme}: DividerProps) {
  return (
    <div className={`srt-connection-state__divider srt-connection-state__divider--${theme}`} />
  );
}

function Status({
  className,
  children,
  dataId = 'srt-status-text',
}: React.PropsWithChildren<Component>) {
  return (
    <div className={classNames('srt-connection-state__status', className)} data-id={dataId}>
      {children}
    </div>
  );
}

function useIndicator(stream: SrtStream) {
  const {started, starting, stopping, disconnected, failed} = stream;
  if (started || starting || stopping || disconnected) {
    return {
      theme: THEME.SUCCESS,
      icon: Icons.spinner(),
    };
  }

  if (failed) {
    return {
      theme: THEME.DANGER,
      icon: Icons.warning(),
    };
  }

  return {
    theme: THEME.PRIMARY,
    icon: Icons.checkCircle(),
  };
}
