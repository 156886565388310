import {useCallback} from 'react';
import {Callback} from 'app/types/common';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {useStreamingDestinations} from 'app/components/FleetManager/useStreamingDestinations';

interface Return {
  streamingDestinations: AnyStreamingDestinationModelType[];
  onChangeStreamingDestination: Callback;
}

export function useUnifyStreamingDestination(
  restartPolling: Callback,
): Return {
  const {
    streamingDestinations,
    reloadStreamingDestinations,
  } = useStreamingDestinations();

  const onChangeStreamingDestination = useCallback(
    () => {
      restartPolling(true);
      reloadStreamingDestinations();
    }, [restartPolling, reloadStreamingDestinations],
  );

  return {
    streamingDestinations,
    onChangeStreamingDestination,
  };
}
