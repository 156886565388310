import {useMemo, useState} from 'react';
import {useDebounce} from 'react-use';
import {containsSearchString} from 'app/components/FleetManager/utils';
import {
  Files,
  SortType,
  sortByDateAcs,
  sortByDateDesc,
  sortByNameAsc,
  sortByNameDesc,
  sortBySizeAsc,
  sortBySizeDesc,
} from 'app/components/entities/files';

interface Args {
  recordings: Files.Recording[];
}

export function useFilterAndSortFiles({recordings}: Args) {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<SortType>('name-asc');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const searchTrim = search.trim();

  useDebounce(
    () => {
      setDebouncedSearch(searchTrim);
    },
    500,
    [searchTrim],
  );

  const filtered = useMemo(() => {
    let copy = [...recordings];

    if (debouncedSearch) {
      copy = copy.filter((r) => containsSearchString(r.name, debouncedSearch));
    }

    return copy.sort(getSortPredicate(sort));
  }, [sort, recordings, debouncedSearch]);

  return {filtered, search, setSearch, sort, setSort};
}

function getSortPredicate(type: SortType) {
  switch (type) {
    case 'date-asc':
      return sortByDateAcs;

    case 'date-desc':
      return sortByDateDesc;

    case 'size-asc':
      return sortBySizeAsc;

    case 'size-desc':
      return sortBySizeDesc;

    case 'name-asc':
      return sortByNameAsc;

    default:
      return sortByNameDesc;
  }
}
