import React, {useCallback, useEffect, useRef, useState} from 'react';
import {IconButton, Stack, Typography} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {ChannelDetailsStatusList} from 'app/components/DeviceDetails/ChannelDetailsTab/ChannelDetailsStatusList';
import {ChannelDetailsPropsList} from 'app/components/DeviceDetails/ChannelDetailsTab/ChannelDetailsPropsList';
import {PREVIEW_MODE} from 'app/components/DeviceDetails/ChannelDetailsTab/constants';
import {DeviceStatusPreview} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStatusPreview';
import {DeviceStreamingPanelContainer} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStreamingPanel/DeviceStreamingPanelContainer';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {ChangeEvent} from 'app/types/common';
import {DeviceAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/DeviceAudioMeter';
import {isDisabledAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/utils';
import {Schedule} from 'app/domain/schedule';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DeviceRecordingPanel} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceRecordingPanel';
import {isNil} from 'app/util/isNil';
import {useChannelEvent} from 'app/components/DeviceDetails/hooks/useChannelEvent';

interface Props {
  device: AnyDeviceModelType;
  channelDevice: AnyDeviceModelType;
  actualEvent?: Schedule.Event;
  showTitle?: boolean;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  onGoBack?: () => void;
}

export function ChannelDetailsTab({
  device,
  channelDevice,
  showTitle,
  actualEvent,
  getDeviceById,
  onGoBack,
}: Props) {
  const [previewMode, setPreviewMode] = useState(() =>
    device.isOffline() ? PREVIEW_MODE.OFFLINE : PREVIEW_MODE.LIVE_SD,
  );
  const [muted, setMuted] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const event = useChannelEvent({channelId: channelDevice.getId(), event: actualEvent});

  useEffect(() => {
    if (containerRef.current && containerRef.current.getBoundingClientRect().top < 0) {
      containerRef.current.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (channelDevice.isOffline()) {
      setPreviewMode(PREVIEW_MODE.OFFLINE);
    }
  }, [channelDevice]);

  const handlePreviewModeChange = ({target: {value}}: ChangeEvent<PREVIEW_MODE>) => {
    setPreviewMode(value);
  };

  const handleMuteClick = useCallback(() => {
    setMuted(!muted);
  }, [muted]);

  return (
    <div ref={containerRef} className="channel-details-tab">
      {showTitle && (
        <Stack direction="row" alignItems="center" gap={1} mb={3}>
          <IconButton data-id="channel_details_go_back_button" onClick={onGoBack}>
            <KeyboardArrowLeftIcon />
          </IconButton>

          <Typography component="span" fontWeight={600} variant="h5">
            <OverflowTooltip className="channel-details-tab__device-name" dataId="channel_name">
              {channelDevice.getName()}
            </OverflowTooltip>
          </Typography>
        </Stack>
      )}

      <ChannelDetailsStatusList
        className="channel-details-tab__status"
        device={channelDevice}
        event={event}
      />

      <div className="channel-details-tab__info-container">
        <DeviceStatusPreview
          className="channel-details-tab__status-preview"
          device={device}
          channelDevice={channelDevice}
          muted={muted}
          previewMode={previewMode}
          onMuteClick={handleMuteClick}
          onPreviewModeChange={handlePreviewModeChange}
        />

        <DeviceAudioMeter
          className="channel-details-tab__audio-meter"
          id={channelDevice.getId() ?? device.getId()}
          disabled={isDisabledAudioMeter(device)}
          segmentsCount={18}
          segmentsGap={12}
          vertical={true}
        />

        <ChannelDetailsPropsList className="channel-details-tab__props" device={channelDevice} />
      </div>

      <DeviceRecordingPanel
        className="channel-details-tab__recording-panel"
        device={channelDevice}
        hasEvent={!isNil(event)}
      />

      <DeviceStreamingPanelContainer device={channelDevice} getDeviceById={getDeviceById} />
    </div>
  );
}
