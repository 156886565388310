import React from 'react';
import classNames from 'classnames';
import {ClassName, DataId, Sx} from 'app/types/common';
import {useDeviceAudioLevels} from 'app/components/sharedReactComponents/DeviceAudioMeter/useDeviceAudioLevels';
import {AudioMeterStereo} from 'app/components/sharedReactComponents/DeviceAudioMeter/AudioMeterStereo/AudioMeterStereo';
import {AudioMeterMono} from 'app/components/sharedReactComponents/DeviceAudioMeter/AudioMeterMono/AudioMeterMono';
import {Box} from '@mui/material';

type MODE = 'mono' | 'stereo';

const DEFAULT_MODE: MODE = 'mono';

interface Props extends ClassName, Sx, DataId {
  id: string;
  source?: string;
  mode?: MODE;
  segmentsCount?: number;
  segmentsGap?: number;
  vertical?: boolean;
  disabled?: boolean;
}

export const DeviceAudioMeter: React.VFC<Props> = ({
  sx,
  className,
  id,
  source,
  mode = DEFAULT_MODE,
  disabled = false,
  segmentsCount,
  segmentsGap,
  vertical,
}) => {
  const {valueL, valueR} = useDeviceAudioLevels({id, sourceId: source, disabled});

  return (
    <Box sx={sx} className={classNames('device-audio-meter', className)}>
      {mode === 'stereo' ? (
        <AudioMeterStereo
          valueL={valueL}
          valueR={valueR}
          segmentsCount={segmentsCount}
          segmentsGap={segmentsGap}
          vertical={vertical}
        />
      ) : (
        <AudioMeterMono
          value={valueL}
          segmentsCount={segmentsCount}
          segmentsGap={segmentsGap}
          vertical={vertical}
        />
      )}
    </Box>
  );
};
