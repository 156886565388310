import React from 'react';
import {Stack} from '@mui/material';
import {grey} from '@mui/material/colors';
import {StyleSx, Sx} from 'app/types/common';
import {Edge} from 'app/domain/edge';
import {LegacyIcon} from 'app/components/sharedReactComponents/LegacyIcon/LegacyIcon';
import {
  DEVICE_PRESET_SECTION_ICON,
  DEVICE_PRESET_SECTION_NAME,
} from 'app/constants/devicePresetSection';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';

const iconSx: StyleSx = {
  fontSize: 24,
  height: 32,
  width: 32,
  bgcolor: 'white',
  color: grey[600],
  border: 1,
  borderColor: grey[200],
  borderRadius: 1,
};

interface Props extends Sx {
  sections: Edge.PresetSection[];
}

export function PresetSections({sx, sections}: Props) {
  return (
    <Stack sx={sx} direction="row" alignItems="center" justifyContent="flex-start" gap={0.5}>
      {sections
        .filter((s) => Boolean(s))
        .map((s) => (
          <Tooltip key={s} content={DEVICE_PRESET_SECTION_NAME[s]}>
            <LegacyIcon dataId={s} key={s} sx={iconSx}>
              {DEVICE_PRESET_SECTION_ICON[s].reactComponent()}
            </LegacyIcon>
          </Tooltip>
        ))}
    </Stack>
  );
}
