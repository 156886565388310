import React from 'react';
import classNames from 'classnames';
import {TileItem} from 'app/components/Settings/StreamingDestinations/NewStreamingDestinationTypeSelector/Tiles/TileItem';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  services: any[];
  createServiceAction: Callback;
}

export const Tiles: React.FC<Props> = ({className, services, createServiceAction}) => {
  return (
    <section className={classNames('new-streaming-destination-type-selector-tiles', className)}>
      {services.map((service) => (
        <TileItem
          key={service.getType()}
          service={service}
          createServiceAction={createServiceAction}
        />
      ))}
    </section>
  );
};
