import React from 'react';
import classNames from 'classnames';
import {formatPlayback} from 'app/util/time';
import {ClassName} from 'app/types/common';

// rework typing
interface Props extends ClassName {
  value: number;
}

export const Duration: React.FC<Props> = ({
  className,
  value,
  ...elementProps
}) => {
  const formattedDuration = formatPlayback(value);

  return (
    <div
      className={classNames('duration', className)}
      {...elementProps}
    >
      {formattedDuration}
    </div>
  );
};
