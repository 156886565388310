import React from 'react';
import classNames from 'classnames';
import {SIZE} from 'app/constants';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  size?: SIZE;
  alt?: string;
  src?: string;
}

export const Avatar = ({
  className,
  src,
  size = SIZE.M,
  alt,
}: Props) => {
  return (
    <div className={classNames('cr-avatar', size && `cr-avatar--size-${size}`, className)}>
      <img
        className="cr-avatar__img"
        src={src}
        alt={alt}
      />
    </div>
  );
};
