import React from 'react';
import {AUTH_STATE} from 'app/components/Login/LoginForm/constants';

function getFormTitleText(authState) {
  switch (authState) {
    case AUTH_STATE.SIGN_IN:
      return 'Log in to Epiphan Cloud';
    case AUTH_STATE.SIGN_UP:
      return 'Sign up to Epiphan Cloud';
    case AUTH_STATE.CONFIRM_SIGN_UP:
      return 'Thanks for signing up!';
    case AUTH_STATE.FORGOT_PASSWORD:
    case AUTH_STATE.RESET_PASSWORD:
      return 'Reset your password';
    default:
      return null;
  }
}

interface Props {
  authState: AUTH_STATE;
}

export const Title = ({authState}: Props) => (
  <div className="login-form__title">
    {getFormTitleText(authState)}
  </div>
);
