/* eslint-disable no-restricted-syntax */
import dayjs, {Dayjs} from 'dayjs';
import {EventGroup, EventGroups} from 'app/components/sharedReactComponents/Events/types';
import {daysToSeconds, secondsToHours} from 'app/util/timeConverter';
import {Schedule} from 'app/domain/schedule';
import {
  fromPeriod,
  isCurrentPeriod,
  sortEvents,
} from 'app/components/sharedReactComponents/Events/utils';
import {createRange} from 'app/util/createRange';
import {isOngoing, isScheduled} from 'app/domain/schedule/utils';
import {stringComparator} from 'app/util/Sort';

export function isToday(timestamp: number): boolean {
  return dayjs.unix(timestamp).isSame(dayjs(), 'day');
}

export function getNowPosition(start: number) {
  return dayjs().diff(dayjs.unix(start), 'minutes');
}

export function getHoursInDuration(seconds: number) {
  return Math.trunc(secondsToHours(seconds));
}

export function isPassed(timestamp: number) {
  return dayjs.unix(timestamp).isBefore(dayjs(), 'day');
}

export function hasOverlap(a: TimeRange, b: TimeRange) {
  return a.from < b.to && a.to > b.from;
}

export function buildEventGroups(events: Schedule.Event[]): EventGroups {
  const overlapGroups: EventGroup[] = [];

  for (const event of events) {
    let inGroup = false;

    for (const group of overlapGroups) {
      if (hasOverlap({from: event.start, to: event.end}, {from: group.start, to: group.end})) {
        group.events.push(event);

        if (group.end < event.end) {
          group.end = event.end;
        }

        inGroup = true;
        break;
      }
    }

    if (!inGroup) {
      overlapGroups.push({start: event.start, end: event.end, events: [event]});
    }
  }

  const groups: EventGroup[] = [];
  const ungrouped: Schedule.Event[] = [];

  for (const group of overlapGroups) {
    if (group.events.length === 1) {
      ungrouped.push(...group.events);
    } else {
      groups.push(group);
    }
  }

  groups.forEach((group) => {
    group.events.sort(sortGroupEvents);
  });

  return {groups, ungrouped};
}

export function buildDayInterval(range: TimeRange, timestamp = dayjs().unix()): TimeRange {
  const base = dayjs.unix(timestamp).startOf('day');

  return {
    from: base.add(range.from, 'seconds').unix(),
    to: base.add(range.to, 'seconds').unix(),
  };
}

export function showNowLine(
  period: Schedule.Period,
  range: TimeRange,
  selected: TimeStampSeconds,
): boolean {
  if ((selected && !isToday(selected)) || !isCurrentPeriod(period)) {
    return false;
  }

  const interval = buildDayInterval(range);
  const now = dayjs();
  const from = dayjs.unix(interval.from);
  const to = dayjs.unix(interval.to);
  return now.isAfter(from, 'second') && now.isBefore(to, 'second');
}

export function getDailySummary(
  days: TimeStampSeconds[],
  events: Schedule.Event[],
): Map<TimeStampSeconds, Schedule.Event[]> {
  const result = new Map<TimeStampSeconds, Schedule.Event[]>(days.map((d) => [d, []]));

  for (const event of events) {
    const {start, end} = event;

    for (const dayStart of days) {
      const dayEnd = dayStart + daysToSeconds(1);

      if (start < dayEnd && end > dayStart) {
        result.get(dayStart)?.push(event);
      }
    }
  }

  return result;
}

export function getDayTimeline(start: number, duration: number): TimeStampSeconds[] {
  const base = dayjs().startOf('day').add(start, 'seconds');

  const hours = getHoursInDuration(duration);

  return createRange(hours).reduce<TimeStampSeconds[]>((acc, value) => {
    return [...acc, base.add(value, 'h').unix()];
  }, []);
}

export function getFirstDayOfPeriod(period: Schedule.Period): Dayjs {
  const date = fromPeriod(period);
  return date.day(0);
}

export function getLastDayOfPeriod(period: Schedule.Period): Dayjs {
  const date = fromPeriod(period);
  return date.day(6);
}

export function getDaysOfPeriod(period: Schedule.Period) {
  const date = fromPeriod(period);

  return createRange(7).map((day) => {
    const instance = date.day(day);
    return instance.unix();
  });
}

function sortGroupEvents(a: Schedule.Event, b: Schedule.Event): number {
  const timeSort = sortEvents(a, b);

  if (timeSort !== 0) {
    return timeSort;
  }

  const statusSort = statusComparator(a, b);

  if (statusSort !== 0) {
    return statusSort;
  }

  return stringComparator(a.title, b.title);
}

function statusComparator(a: Schedule.Event, b: Schedule.Event): number {
  return getStatusRatio(b.status) - getStatusRatio(a.status);
}

function getStatusRatio(status: Schedule.EventStatus): number {
  switch (true) {
    case isOngoing(status):
      return 2;

    case isScheduled(status):
      return 1;

    default:
      return 0;
  }
}
