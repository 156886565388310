import {useCallback} from 'react';
import {
  WSMeetingChangedDto,
  WSParticipantUpdated,
  WSReturnFeedUpdated,
} from 'app/api/WebSocket/types';
import {useMeetingsStore} from 'app/store/hooks';
import {ConnectMapper} from 'app/util/mappers/ConnectMapper/ConnectMapper';
import {MeetingsApiService} from 'app/services/api/meetings/MeetingsApiService';
import {Notifications} from 'app/components/Notifications';

interface Return {
  onMeetingChange: (data: WSMeetingChangedDto) => void;
  onParticipantChange: (data: WSParticipantUpdated) => void;
  onReturnFeedChange: (data: WSReturnFeedUpdated) => void;
}

export function useSocketHandlers(): Return {
  const {getMeeting, setMeeting, removeMeeting} = useMeetingsStore();

  const fetchMeeting = useCallback(
    async (id: string) => {
      try {
        const response = await MeetingsApiService.getMeeting(id);
        const participants = response?.participants
          ? response.participants.map(ConnectMapper.mapParticipant)
          : [];

        const feed = response.injection
          ? ConnectMapper.mapReturnFeed(response.injection)
          : undefined;

        const meeting = ConnectMapper.mapMeeting(response, participants, feed);

        if (meeting.deletedAt) {
          removeMeeting(meeting.id);
          return;
        }

        setMeeting(meeting);
      } catch {
        Notifications.addErrorNotification('Could not get meeting, please update the page');
      }
    },
    [setMeeting, removeMeeting],
  );

  const onMeetingChange = useCallback((data: WSMeetingChangedDto) => {
    const {metadata, ...dto} = data.Body;

    const targetId = dto.id;
    const meeting = getMeeting(targetId);

    if (meeting?.version && metadata.previous && meeting.version !== metadata.previous) {
      void fetchMeeting(meeting.id);
      return;
    }

    const next = ConnectMapper.mapMeeting(data.Body, meeting?.participants, meeting?.feed);

    if (metadata.current) {
      next.setVersion(metadata.current);
    }

    if (next.deletedAt) {
      removeMeeting(targetId);
      return;
    }

    if (next.status !== 'running') {
      next.setParticipants([]);
    }

    setMeeting(next);
  }, []);

  const onParticipantChange = useCallback((data: WSParticipantUpdated) => {
    const {metadata} = data.Body;
    const targetId = data.Body.meeting_id;
    const meeting = getMeeting(targetId);

    if (!meeting) {
      return;
    }

    // The metadata values apply to the whole meeting + participant list,
    // so if "meeting.version !== metadata.previous" we need to fetch the whole meeting
    if (meeting?.version && metadata.previous && meeting.version !== metadata.previous) {
      void fetchMeeting(meeting.id);
      return;
    }

    const next = ConnectMapper.mapParticipant(data.Body);

    if (next.state === 'in-lobby' || next.state === 'left') {
      meeting.removeParticipant(next.id);
      return;
    }

    meeting.setParticipant(next);

    if (metadata.current) {
      meeting.setVersion(metadata.current);
    }
  }, []);

  const onReturnFeedChange = useCallback((data: WSReturnFeedUpdated) => {
    const {metadata} = data.Body;
    const targetId = data.Body.meeting_id;
    const meeting = getMeeting(targetId);

    if (!meeting) {
      return;
    }

    if (meeting?.version && metadata.previous && meeting.version !== metadata.previous) {
      void fetchMeeting(meeting.id);
      return;
    }

    const next = ConnectMapper.mapReturnFeed(data.Body);

    meeting.setReturnFeed(next);

    if (metadata.current) {
      meeting.setVersion(metadata.current);
    }
  }, []);

  return {
    onMeetingChange,
    onParticipantChange,
    onReturnFeedChange,
  };
}
