import {createDeviceSettingModel} from 'app/models/Device/Settings/Fabric';
import {DeviceSettingId} from 'app/models/Device/Settings/constants';
import {DeviceSetting} from 'app/components/DeviceDetails/Models/types';
import {ValueOf} from 'app/types/common';

/**
 * TODO: Move specified methods to another collection
 */
interface Params {
  settings: DeviceSetting[];
}

export class DeviceSettingsCollection {
  #settings: DeviceSetting[];
  #settingsMap: Map<ValueOf<DeviceSettingId>, any>;

  constructor({settings}: Params) {
    this.#settings = settings;

    this.#settingsMap = new Map();
    this.#settings.forEach(setting => {
      this.#settingsMap.set(setting.id, createDeviceSettingModel(setting));
    });
  }

  /**
   * @param {string} id
   * @return {BaseDeviceSettingModel}
   */
  getSettingModelById(id: string): any {
    return this.#settingsMap.get(id);
  }

  getSettingsArray() {
    return this.#settings;
  }

  /**
   * @param {string} groupId
   * @param {string} id
   * @return {BaseDeviceSettingModel|undefined}
   */
  #getGroupSettingItemById = (groupId: string, id: string) => {
    const group = this.getSettingModelById(groupId);
    if (!group) {
      return;
    }

    return group.getSettingModelById(id);
  };

  /**
   * @param {string} id
   * @return {BaseDeviceSettingModel|undefined}
   */
  #getEncoderSettingById = (id: any) => {
    return this.#getGroupSettingItemById(DeviceSettingId.Encoder, id);
  };

  /**
   * @return {BaseDeviceSettingModel|undefined}
   */
  getVideoCodecSetting() {
    return this.#getEncoderSettingById(DeviceSettingId.VideoCodec);
  }

  /**
   * @return {BaseDeviceSettingModel|undefined}
   */
  getVideoResolutionSetting() {
    return this.#getEncoderSettingById(DeviceSettingId.Resolution);
  }

  /**
   * @return {VideoBitrateDeviceSettingModel|undefined}
   */
  getVideoBitrateSetting() {
    return this.#getEncoderSettingById(DeviceSettingId.VideoBitrate);
  }

  /**
   * @return {VideoBitrateDeviceSettingModel|undefined}
   */
  getVideoFramerateSetting() {
    return this.#getEncoderSettingById(DeviceSettingId.VideoFramerate);
  }

  /**
   * @return {BaseDeviceSettingModel|undefined}
   */
  getAudioEnabledSetting() {
    return this.#getEncoderSettingById(DeviceSettingId.AudioEnabled);
  }

  /**
   * @return {BaseDeviceSettingModel|undefined}
   */
  getAudioPresetSetting() {
    return this.#getEncoderSettingById(DeviceSettingId.AudioPreset);
  }

  /**
   * @return {BaseDeviceSettingModel|undefined}
   */
  getAudioChannelsSetting() {
    return this.#getEncoderSettingById(DeviceSettingId.AudioChannels);
  }

  /**
   * @return {BaseDeviceSettingModel|undefined}
   */
  getAudioBitrateSetting() {
    return this.#getEncoderSettingById(DeviceSettingId.AudioBitrate);
  }

  /**
   * @param {string} id
   * @return {BaseDeviceSettingModel|undefined}
   */
  #getRecorderSettingById = (id: any) => {
    return this.#getGroupSettingItemById(DeviceSettingId.Recorder, id);
  };

  /**
   * @return {BaseDeviceSettingModel|undefined}
   */
  getRecorderFileFormatSetting() {
    return this.#getRecorderSettingById(DeviceSettingId.FileFormat);
  }

  /**
   * @return {BaseDeviceSettingModel|undefined}
   */
  getRecorderTimeLimitSetting() {
    return this.#getRecorderSettingById(DeviceSettingId.TimeLimit);
  }

  /**
   * @return {BaseDeviceSettingModel|undefined}
   */
  getRecorderSizeLimitSetting() {
    return this.#getRecorderSettingById(DeviceSettingId.SizeLimit);
  }

  /**
   * @return {BaseDeviceSettingModel|undefined}
   */
  getRecorderAfuEnabledSetting() {
    return this.#getRecorderSettingById(DeviceSettingId.AfuEnabled);
  }

  /**
   * @return {GroupDeviceSettingModel|undefined}
   */
  getAudioSourcesSettings() {
    return this.getSettingModelById(DeviceSettingId.AudioSources);
  }

  /**
   * @return {GroupDeviceSettingModel|undefined}
   */
  getHDMIOutputSettings() {
    return this.getSettingModelById(DeviceSettingId.HdmiOutput);
  }

  /**
   * @return {GroupDeviceSettingModel|undefined}
   */
  getFrontScreenOutputSettings() {
    return this.getSettingModelById(DeviceSettingId.FrontScreenOutput);
  }

  /**
   * @return {GroupDeviceSettingModel|undefined}
   */
  getWebStreamOutputSettings() {
    return this.getSettingModelById(DeviceSettingId.WebStreamOutput);
  }
}
