import React, {
  forwardRef,
  useCallback,
} from 'react';
import classNames from 'classnames';
import {KeyboardCode} from 'app/constants';
import {
  Callback,
  ClassName,
} from 'app/types/common';

interface Props extends ClassName {
  selected: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  onChange?: Callback;
}

export const SelectCheckbox = forwardRef<any, Props>(({
  className,
  selected,
  indeterminate,
  disabled,
  onChange,
  ...elementProps
}, ref) => {
  const handleClick = useCallback(() => {
    onChange?.({target: {value: !selected}});
  }, [onChange, selected]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.code === KeyboardCode.Space) {
      e.preventDefault();
      onChange?.({target: {value: !selected}});
    }
  }, [onChange, selected]);

  if (disabled) {
    return (
      <span
        ref={ref}
        className="device-card-select-checkbox device-card-select-checkbox--disabled"
        {...elementProps}
      />
    );
  }

  return (
    <span
      ref={ref}
      className={classNames('device-card-select-checkbox device-card-select-checkbox--enabled', {
        'device-card-select-checkbox--checked': selected,
        'device-card-select-checkbox--indeterminate': indeterminate,
      })}
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      {...elementProps}
    />
  );
});

SelectCheckbox.displayName = 'SelectCheckbox';
