import React, {useState} from 'react';
import {Box, IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {Sx} from 'app/types/common';
import {FileExtension} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Archive/types';

interface Props extends Sx {
  onDownload: (ext: FileExtension) => void;
}

export function DownloadSession({sx, onDownload}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleDownload = (ext: FileExtension) => {
    onDownload(ext);
    handleClose();
  };

  return (
    <Box sx={sx}>
      <Tooltip title="Download">
        <IconButton data-id="download-brn" color="primary" onClick={handleOpen}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      <Menu sx={{minWidth: 80}} open={isOpen} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem data-id="srt-btn" onClick={() => handleDownload('srt')}>
          .srt
        </MenuItem>
        <MenuItem data-id="txt-btn" onClick={() => handleDownload('txt')}>
          .txt
        </MenuItem>
      </Menu>
    </Box>
  );
}
