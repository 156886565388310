import React from 'react';
import classNames from 'classnames';
import {
  THEME,
  SIZE,
} from 'app/constants';

interface Props {
  className?: string;
  size?: SIZE;
  theme?: THEME;
}

export const IndeterminateProgressBar: React.VFC<Props> = ({
  className,
  size,
  theme = THEME.PRIMARY,
}) => {
  return (
    <div
      className={classNames('cr-indeterminate-progress',
        theme && `cr-indeterminate-progress--${theme}`,
        size && `cr-indeterminate-progress--size-${size}`,
        className)}
    >
      <div className="cr-indeterminate-indicator"/>
    </div>
  );
};

