import {TeamDetailsDto} from 'app/models/Team/dto';
import {TeamDetailsModel} from 'app/models/Team/TeamDetailsModel';

function createTeamDetailsModel(props: TeamDetailsDto) {
  const {
    ID: id,
    Name: name,
    CurrentTierID: currentTierId,
    Caps: capabilities,
    BillingEmail: billingEmail,
  } = props;

  return new TeamDetailsModel({
    id,
    name,
    currentTierId,
    capabilities,
    billingEmail,
  });
}

export {createTeamDetailsModel};
