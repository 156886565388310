import React from 'react';
import {Button, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';

type Props = Sx & {
  onClear: () => void;
};

export function NoFiltered({sx, onClear}: Props) {
  return (
    <Stack
      data-id="no-filtered-card"
      sx={sx}
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
      <Typography variant="h6" color="text.disabled" fontWeight={600}>
        No filtered recordings
      </Typography>

      <Button
        data-id="clear-filter-btn"
        variant="outlined"
        color="info"
        disableRipple={false}
        onClick={onClear}
      >
        Reset
      </Button>
    </Stack>
  );
}
