import React from 'react';
import {Button} from '@mui/material';
import {deepPurple, indigo, pink, purple} from '@mui/material/colors';
import {Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {router} from 'app/router/main';
import {ProductCard} from 'app/components/FleetManager/EdgeWelcome/products/ProductCard/ProductCard';
import {buttonSx} from 'app/components/FleetManager/EdgeWelcome/products/buttonStyles';

interface Props extends Sx {}

export function UnifyProduct({sx}: Props) {
  return (
    <ProductCard
      sx={packSx(
        {
          backgroundImage: `linear-gradient(333deg, ${indigo[900]} -27%, ${deepPurple[900]} 9%, ${purple[700]} 43%, ${pink[500]} 92%)`,
        },
        sx,
      )}
      dataId="unify-card"
      product="Epiphan Unify"
      title={
        <>
          Build a better
          <br /> hybrid workflow
        </>
      }
      image={
        <img
          src="/assets/img/welcome/unify.png"
          srcSet="/assets/img/welcome/unify@2x.png 2x"
          height="112"
          width="184"
          alt=""
        />
      }
      description={
        <>
          The ultimate flexibility to
          <br /> record, switch, mix,
          <br /> and restream content from anywhere.
        </>
      }
      link="https://www.epiphan.com/unify/"
      action={
        <Button
          data-id="go-unify-button"
          sx={buttonSx}
          href={router.url('projects')}
          variant="outlined"
        >
          Start now
        </Button>
      }
    />
  );
}
