import React from 'react';
import {Dialog} from '@mui/material';
import {Prompt} from 'app/types/common';
import {UnifyArgs} from 'app/components/BillingManager/types/types';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {UnsubscribeFromUnify} from 'app/components/BillingManager/dialogs/UnifyConfirmDialog/UnsubscribeFromUnify';
import {SubscribeToUnify} from 'app/components/BillingManager/dialogs/UnifyConfirmDialog/SubscribeToUnify';

type Props = Prompt & {
  args: UnifyArgs;
  endDate?: TimeStampSeconds;
  onConfirm: Fn;
  onCancel: Fn;
};

export function UnifyConfirmDialog({args, open, endDate, onCancel, onConfirm, onClose}: Props) {
  return (
    <Dialog open={open} maxWidth="md" fullWidth={true} PaperProps={{sx: {p: 10}}} onClose={onClose}>
      <DialogClose onClose={onClose} />

      {args.action === 'subscribe' ? (
        <SubscribeToUnify tier={args.tier} onConfirm={onConfirm} onCancel={onCancel} />
      ) : (
        <UnsubscribeFromUnify onConfirm={onConfirm} onCancel={onCancel} endDate={endDate} />
      )}
    </Dialog>
  );
}
