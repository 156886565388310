import React from 'react';
import classNames from 'classnames';
import {VARIANT} from 'app/constants';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  deleteButtonText: string;
  deleting?: boolean;
  disabled: boolean;
  onClickDelete: Callback;
}
export const Footer: React.FC<Props> = ({
  className,
  deleteButtonText,
  deleting,
  disabled,
  onClickDelete,
}) => {
  return (
    <div className={classNames('streaming-destination-settings-footer', className)}>
      <ProgressButton
        className="streaming-destination-settings-footer__delete-button"
        dataId="delete_streaming_destination_button"
        variant={VARIANT.LINK_UNDERLINE}
        loading={deleting}
        disabled={disabled}
        onClick={onClickDelete}
      >
        {deleteButtonText}
      </ProgressButton>
    </div>
  );
};
