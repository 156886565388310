import React, {useMemo, useState} from 'react';
import {Stack, Tooltip} from '@mui/material';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import {BatchActionButton} from 'app/components/FleetManager/BatchActionsPanel/BatchActionButton';
import {Edge} from 'app/domain/edge';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {MoveToGroupDialog} from 'app/components/dialogs/MoveToGroupDialog/MoveToGroupDialog';
import {RemoveFromGroupDialog} from 'app/components/dialogs/RemoveFromGroupDialog/RemoveFromGroupDialog';
import {Sx} from 'app/types/common';
import {DeleteGroupsDialog} from 'app/components/dialogs/DeleteGroupsDialog/DeleteGroupsDialog';
import {useMounted} from 'app/hooks/useIsMounted';
import {DeviceGroupApiService} from 'app/services/api/deviceGroup/DeviceGroupApiService';

type Props = Sx & {
  devices: AnyDeviceModelType[];
  groups: Edge.Group[];
  allGroups: Edge.Group[];
};

export function BatchGroups({sx, devices, groups, allGroups}: Props) {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [moveInDialog, setMoveInDialog] = useState(false);
  const [moveOutDialog, setMoveOutDialog] = useState(false);

  const mounted = useMounted();
  const {grouped, availableGroups} = useActionGroups(devices, allGroups);

  const handleMoveToGroup = async (groupId: string) => {
    await DeviceGroupApiService.batchMoveToGroup(
      groupId,
      devices.map((d) => d.getId()),
    );

    if (mounted()) {
      setMoveInDialog(false);
    }
  };

  const handleRemoveFromGroup = async () => {
    await DeviceGroupApiService.batchRemoveDevices(grouped.map((d) => d.getId()));

    if (mounted()) {
      setMoveOutDialog(false);
    }
  };

  const handleDelete = async () => {
    await DeviceGroupApiService.batchDelete(groups.map((group) => group.id));

    if (mounted()) {
      setDeleteDialog(false);
    }
  };

  return (
    <>
      <Stack sx={sx} direction="row" alignItems="center" gap={1}>
        {groups.length > 0 && (
          <Tooltip title="Delete groups">
            <BatchActionButton data-id="remove-group-btn" onClick={() => setDeleteDialog(true)}>
              <FolderDeleteIcon />
            </BatchActionButton>
          </Tooltip>
        )}

        {devices.length > 0 && (
          <Tooltip title="Move to group">
            <BatchActionButton data-id="add-to-group-btn" onClick={() => setMoveInDialog(true)}>
              <CreateNewFolderIcon />
            </BatchActionButton>
          </Tooltip>
        )}

        {grouped.length > 0 && (
          <Tooltip title="Remove from group">
            <BatchActionButton
              data-id="remove-from-group-btn"
              onClick={() => setMoveOutDialog(true)}
            >
              <DriveFileMoveIcon />
            </BatchActionButton>
          </Tooltip>
        )}
      </Stack>

      <MoveToGroupDialog
        open={moveInDialog}
        devices={devices}
        groups={availableGroups}
        onMove={handleMoveToGroup}
        onClose={() => setMoveInDialog(false)}
      />

      <RemoveFromGroupDialog
        open={moveOutDialog}
        devices={grouped}
        onRemove={handleRemoveFromGroup}
        onClose={() => setMoveOutDialog(false)}
      />

      <DeleteGroupsDialog
        open={deleteDialog}
        names={groups.map((g) => g.name)}
        onDelete={handleDelete}
        onClose={() => setDeleteDialog(false)}
      />
    </>
  );
}

function useActionGroups(devices: AnyDeviceModelType[], allGroups: Edge.Group[]) {
  return useMemo(() => {
    const grouped = devices.filter((device) => Boolean(device.getGroupId()));
    const currentGroups = new Set(grouped.map((d) => d.getGroupId()));
    const availableGroups = allGroups.filter((g) => !currentGroups.has(g.id));

    return {grouped, availableGroups};
  }, [devices, allGroups]);
}
