import React from 'react';
import {ListItemText} from '@mui/material';

interface TextProps {
  value: string;
}

export function NavText({value}: TextProps) {
  return (
    <ListItemText
      primaryTypographyProps={{fontWeight: 'bold', color: 'currentcolor'}}
      primary={value}
    />
  );
}
