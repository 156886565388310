import {FTPLocationModel} from 'app/components/UploadLocations/Models/FTPLocationModel';
import {SFTPLocationModel} from 'app/components/UploadLocations/Models/SFTPLocationModel';
import {OAuth2LocationModel} from 'app/components/UploadLocations/Models/OAuth2LocationModel';

import {
  STORAGE_PROP_NAME,
  STORAGE_TYPE,
} from 'app/components/UploadLocations/constants';
import {AnyLocationModel, StorageResponseType} from 'app/components/UploadLocations/Models/types';

function createModel(type: STORAGE_TYPE, json: StorageResponseType, saveLocationFn: (id: string, json: StorageResponseType) => Promise<any>): AnyLocationModel | void {
  const baseModel = {
    Name: json.Name,
    StorageID: json.StorageID
  };

  switch (type) {
    case STORAGE_TYPE.FTP:
      return new FTPLocationModel(
        Object.assign(baseModel, json[STORAGE_PROP_NAME.FTP]),
        saveLocationFn
      );
    case STORAGE_TYPE.SFTP:
      return new SFTPLocationModel(
        Object.assign(baseModel, json[STORAGE_PROP_NAME.SFTP]),
        saveLocationFn
      );
    case STORAGE_TYPE.GOOGLE_DRIVE:
    case STORAGE_TYPE.DROPBOX:
    case STORAGE_TYPE.BOX:
      return new OAuth2LocationModel(
        Object.assign(baseModel, {Type: type}, json[STORAGE_PROP_NAME.OAUTH2]),
        saveLocationFn
      );
    default:
      console.error('Unregistered storage type', type);
  }
}

export {createModel};
