import React from 'react';
import classNames from 'classnames';
import {freeTier} from 'app/util/billing/utils';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {router} from 'app/router/main';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';
import {ClassName} from 'app/types/common';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {useTeamBilling} from 'app/components/BillingManager/hooks/useTeamBilling';
import {useCloudEntities} from 'app/components/entities/cloud';

interface Props extends ClassName {
  count: number;
  loading: boolean;
  teamTier: App.Billing.Cloud.Tier;
}

export function PairDeviceLimitsPanel({className, count, loading, teamTier}: Props) {
  const {user} = useCloudEntities();
  const {cloud} = useTeamBilling();

  if (loading) {
    return <LoadingPlaceholder className={classNames('pair-device-limits-panel', className)} />;
  }

  let maxNumberOfDevices: number | string = 0;
  if (freeTier(teamTier.id)) {
    maxNumberOfDevices = 'Unlimited with Limited functionality';
  } else if (teamTier.seats < 0) {
    maxNumberOfDevices = 'Unlimited';
  } else {
    maxNumberOfDevices = teamTier.seats;
  }

  const showLink = user.role.canEditBilling() && !cloud.unlimited && !cloud.free;

  return (
    <div
      data-id="pair_device_limits_panel"
      className={classNames('pair-device-limits-panel', className)}
    >
      <div className="pair-device-limits-panel__devices">
        {Icons.devices().size(SIZE.L).reactComponent()}

        <div className="pair-device-limits-panel__devices-info">
          {count} of {maxNumberOfDevices} Edge {pluralizeN('seat', count)} {pluralizeN('is', count)}{' '}
          occupied
        </div>
      </div>

      {showLink && (
        <div className="pair-device-limits-panel__change-plans">
          {'Need more devices? '}

          <a
            className="pair-device-limits-panel__change-plans-url"
            href={router.url('settingBillingUsage')}
          >
            Change plan
          </a>
        </div>
      )}
    </div>
  );
}
