/* eslint-disable */
import React, {Component, createRef} from 'react';
import classNames from 'classnames';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {FORM_CONTROL_TYPE} from 'app/components/sharedReactComponents/FormControl/constants';
import {KeyboardCode, SIZE, THEME} from 'app/constants';
import {Icons} from 'app/util/icons';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {Callback, ClassName, DataId} from 'app/types/common';

export enum EDITABLE_NAME_TEXT_THEME {
  DEFAULT = 'default',
  DASHBOARD = 'dashboard',
}

export type EDITABLE_NAME_TEXT_TYPE = FORM_CONTROL_TYPE.TEXT | FORM_CONTROL_TYPE.TEXTAREA;

interface Props extends ClassName, DataId {
  value: string;
  name?: string;
  ariaLabel?: string;
  placeholder?: string;
  validators: any[];
  disabled?: boolean;
  type: EDITABLE_NAME_TEXT_TYPE;
  theme: EDITABLE_NAME_TEXT_THEME;
  onChange?: Callback;
}

interface State {
  editing: boolean;
  value: string;
  valid: boolean;
  errorMessage: any;
}
// rework all any
export class EditableNameText extends Component<Props, State> {
  static defaultProps = {
    validators: [],
    theme: EDITABLE_NAME_TEXT_THEME.DEFAULT,
    type: FORM_CONTROL_TYPE.TEXT,
  };

  _saveFailed = false;
  inputRef = createRef<any>();
  divRef = createRef<any>();

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      value: props.value,
      valid: true,
      errorMessage: null,
    };
  }

  componentDidUpdate(prevProps) {
    const {value} = this.props;

    if (value !== prevProps.value) {
      this.setState({
        value,
        valid: true,
        errorMessage: null,
      });
    }
  }

  /*
   * Validate onChange if last save failed
   * Validate onSave everything
   */
  validate(force?: any) {
    if (!force && !this._saveFailed) {
      return;
    }

    const {valid, errorMessage} = this._validate();

    this.setState({
      valid,
      errorMessage,
    });

    return valid;
  }

  _validate() {
    const {validators} = this.props;
    const {value} = this.state;

    for (const validator of validators) {
      if (validator.validate(value) === false) {
        return {
          valid: false,
          errorMessage: validator.getErrorMessage(),
        };
      }
    }

    return {
      valid: true,
      errorMessage: null,
    };
  }

  handleChange = ({target: {value}}) => {
    this.setState(
      {
        value,
        editing: true,
      },
      () => this.validate(),
    );
  };

  handleBlur = () => {
    if (this.state.editing) {
      this.save();
    }
  };

  handleKeyUp = (e: React.KeyboardEvent) => {
    const {type} = this.props;

    if (e.code === KeyboardCode.Enter || e.code === KeyboardCode.NumpadEnter) {
      if (type === FORM_CONTROL_TYPE.TEXTAREA && e.shiftKey) {
        return;
      }

      this.save();
    }
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === KeyboardCode.Enter && !e.shiftKey) {
      e.preventDefault();
    }
  };

  handleInputKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === KeyboardCode.Enter) {
      event.preventDefault();
      this.setEditing();
    }
  };

  handleClickEdit = () => {
    this.setEditing();
  };

  setEditing() {
    if (this.props.disabled) {
      return;
    }

    this.setState({editing: true}, () => this.inputRef.current.focus());
  }

  save() {
    if (this.validate(true)) {
      this._save();

      this._saveFailed = false;
    } else {
      this._saveFailed = true;
    }
  }

  _save() {
    const {value} = this.state;
    const {name, value: previousValue, onChange} = this.props;

    const trimCurrentValue = value.trim();

    if (trimCurrentValue !== previousValue) {
      onChange?.({target: {name, value: trimCurrentValue}});
    }

    this.setState(
      {
        value: trimCurrentValue,
        editing: false,
      },
      () => this.divRef.current.focus(),
    );
  }

  render() {
    const {dataId, className, ariaLabel, placeholder, disabled, theme, type} = this.props;
    const {editing, value, valid, errorMessage} = this.state;

    const multiline = type === FORM_CONTROL_TYPE.TEXTAREA;

    return (
      <div
        data-id={dataId}
        className={classNames(
          'cr-text-input',
          `cr-text-input--theme-${theme}`,
          {
            'cr-text-input--multiline': multiline,
            'cr-text-input--single-line': multiline === false,
            'cr-text-input--invalid': !valid,
            'cr-text-input--editing': editing,
            'cr-text-input--enabled': !disabled,
          },
          className,
        )}
        aria-label={ariaLabel}
      >
        <div
          ref={this.divRef}
          className="cr-text-input__value-indicator"
          tabIndex={0}
          onKeyUp={this.handleInputKeyUp}
          onClick={this.handleClickEdit}
        >
          {value ? (
            <div className="cr-text-input__value-indicator-value">
              {multiline ? value : <OverflowTooltip>{value}</OverflowTooltip>}
            </div>
          ) : (
            <div className="cr-text-input__placeholder">{placeholder}</div>
          )}

          {!disabled && (
            <Tooltip content="Click to edit">
              {Icons.edit()
                .size(SIZE.S)
                .class('cr-text-input__value-indicator-icon')
                .reactComponent()}
            </Tooltip>
          )}
        </div>

        {!disabled && (
          <FormControl
            name="editable-text"
            inputRef={this.inputRef}
            className="cr-text-input__input"
            value={value}
            type={type}
            error={!valid}
            endAdornment={
              errorMessage && (
                <Tooltip content={errorMessage}>
                  {Icons.warning().theme(THEME.DANGER).reactComponent()}
                </Tooltip>
              )
            }
            fullWidth={true}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onBlur={this.handleBlur}
            onKeyUp={this.handleKeyUp}
          />
        )}
      </div>
    );
  }
}
