export type Subscription = 'connect' | 'unify';

const connect: Array<App.Billing.Feature> = [
  {
    id: 'extractions',
    name: 'Participant extractions per meeting',
  },
  {
    id: 'limit',
    name: 'Simultaneous meetings',
  },
  {
    id: 'first-quarter',
    name: 'First 15 minutes of every meeting',
  },
  {
    id: 'screen-share',
    name: 'Persistent screenshare extraction',
  },
  {
    id: 'primary-speaker',
    name: 'Primary speaker mode',
  },
  {
    id: 'isolated',
    name: 'Isolated audio',
  },
  {
    id: 'platforms',
    name: 'Zoom and Microsoft Teams enabled',
  },
  {
    id: 'return-feed',
    name: 'Return feed / Confidence monitor',
  },
  {
    id: 'easy-connect',
    name: 'Easy extraction to Epiphan devices',
  },
];

const unify: Array<App.Billing.Feature> = [
  {
    id: 'projects',
    name: 'Unify projects',
  },
  {
    id: 'srt',
    name: 'SRT Inputs',
  },
  {
    id: 'platform-input',
    name: 'Zoom and Teams inputs',
  },
  {
    id: 'channels',
    name: 'Channels',
  },
  {
    id: '4k',
    name: '4K streaming',
  },
  {
    id: 'remote',
    name: 'Remote switching',
  },
  {
    id: 'layouts',
    name: 'Custom layouts',
  },
  {
    id: 'playback',
    name: 'Media playback',
  },
  {
    id: 'web-source',
    name: 'Graphics and titling via web source',
  },
];

export const features = {connect, unify};
