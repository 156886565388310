import React from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {ClassName} from 'app/types/common';
import {SrtStream} from 'app/store/models/connect/SrtStream/SrtStream';
import {SrtConnectionState} from 'app/components/pages/MeetingDetails/StreamConnectionStatus/SrtConnectionState/SrtConnectionState';
import {SrtConnectionPoint} from 'app/components/pages/MeetingDetails/StreamConnectionStatus/SrtConnectionPoint/SrtConnectionPoint';

interface Props extends ClassName {
  stream: SrtStream;
  domain?: string;
}

const StreamConnectionStatusComponent = ({className, stream, domain = ''}: Props) => {
  const {stopped, settings} = stream;

  if (stopped) {
    return null;
  }

  if (!settings) {
    return null;
  }

  return (
    <div className={classNames('stream-connection-status', className)}>
      <SrtConnectionPoint
        className="stream-connection-status__item"
        dataId="srt-from"
        stream={stream}
        direction="from"
      />

      <SrtConnectionState className="stream-connection-status__item" stream={stream} />

      <SrtConnectionPoint
        className="stream-connection-status__item"
        dataId="srt-to"
        stream={stream}
        direction="to"
        domain={domain}
      />
    </div>
  );
};

export const StreamConnectionStatus = observer(StreamConnectionStatusComponent);
