import {ROUTE_NAME} from 'app/router/login';
import {
  AUTH_STATE,
  DEFAULT_AUTH_STATE,
} from 'app/components/Login/LoginForm/constants';

function getAuthStateByRouteName(routeName) {
  switch (routeName) {
    case ROUTE_NAME.SIGN_UP:
      return AUTH_STATE.SIGN_UP;
    case ROUTE_NAME.SIGN_IN:
      return AUTH_STATE.SIGN_IN;
    case ROUTE_NAME.RESET_PASSWORD:
      return AUTH_STATE.FORGOT_PASSWORD;
    default:
      return DEFAULT_AUTH_STATE;
  }
}

function getRouteNameByAuthState(authState) {
  switch (authState) {
    case AUTH_STATE.SIGN_UP:
    case AUTH_STATE.CONFIRM_SIGN_UP:
      return ROUTE_NAME.SIGN_UP;
    case AUTH_STATE.SIGN_IN:
      return ROUTE_NAME.SIGN_IN;
    case AUTH_STATE.FORGOT_PASSWORD:
    case AUTH_STATE.RESET_PASSWORD:
      return ROUTE_NAME.RESET_PASSWORD;
    default:
      return ROUTE_NAME.SIGN_IN;
  }
}

export {
  getAuthStateByRouteName,
  getRouteNameByAuthState,
};
