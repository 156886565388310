import React, {useCallback} from 'react';
import classNames from 'classnames';
import {SIZE} from 'app/constants';
import {Callback, ClassName} from 'app/types/common';

export enum SWITCHER_THEME {
  DEFAULT = 'default',
  SILVER_SAND = 'silver-sand',
}

interface Props extends ClassName {
  name?: string;
  value: boolean;
  showLabel?: boolean;
  labelOnText?: string;
  labelOffText?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  size?: SIZE;
  theme?: SWITCHER_THEME;
  onChange?: Callback;
}

export const Switcher: React.VFC<Props> = ({
  className,
  name,
  value,
  autoFocus,
  showLabel,
  labelOnText,
  labelOffText,
  disabled,
  size,
  theme,
  onChange,
  ...otherProps
}) => {
  const handleChange = useCallback(({target: {name, checked}}) => {
    onChange?.({
      target: {
        name,
        value: checked,
      },
    });
  }, [onChange]);

  return (
    <label
      className={classNames('switcher', {
        'switcher--theme-silver-sand': theme === SWITCHER_THEME.SILVER_SAND,
      }, size && `switcher--size-${size}`, className)}
      {...otherProps}
    >
      <input
        type="checkbox"
        name={name}
        autoFocus={autoFocus}
        checked={value}
        disabled={disabled}
        onChange={handleChange}
      />

      <span/>

      {showLabel && (
        <span className="switcher__label">
          {value ? labelOnText : labelOffText}
        </span>
      )}
    </label>
  );
};
