import {STREAMING_SERVICE_TYPE} from 'app/components/StreamingServices/constants';
import {StreamingDestinationOptions} from 'app/components/StreamingServices/StreamingDestinations/types';

class BaseStreamingService {
  protected type: STREAMING_SERVICE_TYPE | null = null;
  protected name: string | null = null;
  protected service: any = null;

  getType() {
    return this.type;
  }

  getName() {
    return this.name;
  }

  createStreamingDestinationModel(_json: StreamingDestinationOptions): any {
    throw new Error('Unregistered method');
  }

  async createService(_name?: string): Promise<string> {
    throw new Error('Unregistered method');
  }

  isDisabled() {
    return false;
  }

  isAvailableToCreate() {
    return true;
  }
}

export {
  BaseStreamingService,
};
