import {SxObj} from 'app/types/common';

const grid: SxObj = {
  display: 'grid',
  gridTemplateColumns:
    '[icon] 24px [icon name] 1fr [name col] 174px [col] 116px [col] 192px [col menu] 32px [menu]',
  columnGap: 1,
  alignItems: 'center',
};

export const styles = {grid};
