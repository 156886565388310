import React from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {ClassName} from 'app/types/common';
import {Separator} from 'app/components/sharedReactComponents/Separator';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {ParticipantInput} from 'app/components/pages/MeetingDetails/shared/ParticipantInput/ParticipantInput';
import {
  AudioMuteButton,
  VideoMuteButton,
} from 'app/components/pages/MeetingDetails/shared/StreamButtons/StreamButtons';
import {ParticipantMessage} from 'app/components/pages/MeetingDetails/shared/ParticipantMessage/ParticipantMessage';
import {ParticipantAvatar} from 'app/components/pages/MeetingDetails/shared/ParticipantAvatar/ParticipantAvatar';
import {useStrSettingsForm} from 'app/components/pages/MeetingDetails/StreamSettings/SrtSettingsFormContext';
import {SrtSettingsCallback} from 'app/components/pages/MeetingDetails/StreamSettings/types';

interface Props extends ClassName, SrtSettingsCallback {
  name: string;
  state: App.Connect.ParticipantState;
  type: App.Connect.MemberType;
  share: boolean;
  video: boolean;
  audio: boolean;
}

const StreamGeneralSettingsComponent = ({
  className,
  name,
  type,
  share,
  state,
  audio,
  video,
  onSync,
}: Props) => {
  const {synchronization, settings, setSettings} = useStrSettingsForm();

  const isShare = type === 'vbss';
  const isSpeaker = type === 'primary-speaker';

  const onAudioMute = async () => {
    const audio = !settings.mute.audio;

    setSettings({
      mute: {
        audio,
        video: settings.mute.video,
      },
    });
    await onSync();
  };

  const onVideoMute = async () => {
    const video = !settings.mute.video;

    setSettings({
      mute: {
        audio: settings.mute.audio,
        video,
      },
    });

    await onSync();
  };

  return (
    <div className={classNames('stream-general-settings', className)}>
      <ParticipantAvatar
        className="stream-general-settings__avatar"
        dataId="participant_avatar"
        type={type}
        name={name}
        cameraOff={!video}
        sharing={share}
        size="big"
        disconnected={state === 'left-with-stream'}
      />

      <div className="stream-general-settings__info">
        <div className="stream-general-settings__user">
          <div className="stream-general-settings__container">
            <span className="stream-general-settings__name">
              <OverflowTooltip dataId="participant_name">{name}</OverflowTooltip>
            </span>

            {!isShare && (
              <ParticipantInput audio={audio} video={video} dataId="participant_input" />
            )}
          </div>

          {isSpeaker && <ParticipantMessage type="primary-speaker" dataId="participant_message" />}

          {isShare && (
            <ParticipantMessage type="vbss" dataId="participant_message" active={share} />
          )}
        </div>

        <Separator className="stream-general-settings__separator" />

        <div className="stream-general-settings__mute">
          <AudioMuteButton
            className="stream-general-settings__btn"
            dataId="mute_srt_audio"
            muted={settings.mute.audio}
            disabled={synchronization}
            onAction={onAudioMute}
          />

          <span>Mute audio on start</span>
        </div>

        <div className="stream-general-settings__mute">
          <VideoMuteButton
            className="stream-general-settings__btn"
            dataId="mute_srt_video"
            muted={settings.mute.video}
            disabled={synchronization}
            onAction={onVideoMute}
          />

          <span>Mute video on start</span>
        </div>
      </div>
    </div>
  );
};

export const StreamGeneralSettings = observer(StreamGeneralSettingsComponent);
