import React from 'react';
import {CircularProgress, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';

interface Props extends Sx {}

export function InvoicesSkeleton({sx}: Props) {
  return (
    <Stack sx={sx} direction="row" alignItems="center" justifyContent="center" gap={2}>
      <CircularProgress />

      <Typography variant="h6" color="text.secondary" fontWeight={600}>
        Loading invoices
      </Typography>
    </Stack>
  );
}
