import {Role} from 'app/models/PermissionsModel/Role';

export class Admin extends Role {
  constructor() {
    super('admin');

    this.permissions.devices.view = true;
    this.permissions.devices.reboot = true;
    this.permissions.teams.edit = true;
    this.permissions.presets.apply = true;
    this.permissions.streamingDestinations.edit = true;
    this.permissions.unify.manage = true;
    this.permissions.connect.pairing = true;
    this.permissions.accountSettings.view = true;
  }
}
