import React from 'react';
import {Dialog} from '@mui/material';
import {Prompt} from 'app/types/common';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {CloudConfirm} from 'app/components/BillingManager/dialogs/CloudConfirmDialog/ConfirmCloudTier';

type Props = Prompt & React.ComponentProps<typeof CloudConfirm>;

export function CloudConfirmDialog({open, dataId, onClose, ...props}: Props) {
  return (
    <Dialog data-id={dataId} open={open} fullWidth={true} onClose={onClose}>
      <DialogClose onClose={onClose} />

      <CloudConfirm {...props} />
    </Dialog>
  );
}
