import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {Icons} from 'app/util/icons';

export function NoTeamIntegration() {
  return (
    <Stack
      px={5}
      py={4}
      borderRadius={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      bgcolor={grey[100]}
    >
      <Box>
        <Typography fontWeight={600} mb={2}>
          No devices connected to a CMS
        </Typography>

        <Typography color={grey[500]}>
          Connect a device to Epiphan Cloud CMS to create new events.
        </Typography>
      </Box>
      <Box sx={{'& .svg-icon': {width: 64, height: 64, color: grey[500]}}}>
        {Icons.eventCMSIsTurnedOff().reactComponent()}
      </Box>
    </Stack>
  );
}
