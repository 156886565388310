import {useQuery} from '@tanstack/react-query';
import {MeetingsApiService} from 'app/services/api/meetings/MeetingsApiService';
import {ConnectMapper} from 'app/util/mappers/ConnectMapper/ConnectMapper';

export function useMeetingsQuery(enabled: boolean) {
  return useQuery({
    queryKey: ['meetings'],
    queryFn: async () => {
      const result = await MeetingsApiService.getMeetings(true);
      return result.meetings.map(mapMeeting);
    },
    enabled,
  });
}

function mapMeeting(meeting: Contracts.Connect.Meeting) {
  const feed = meeting.injection ? ConnectMapper.mapReturnFeed(meeting.injection) : undefined;

  return ConnectMapper.mapMeeting(
    meeting,
    meeting.participants?.map(ConnectMapper.mapParticipant),
    feed,
  );
}

export function useCallsQuery(enabled: boolean) {
  return useQuery({
    queryKey: ['calls'],
    queryFn: async () => {
      const result = await MeetingsApiService.getCalls();
      return result.ms_teams_calls.map(ConnectMapper.mapCall);
    },
    enabled,
  });
}
