import React from 'react';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReorderIcon from '@mui/icons-material/Reorder';
import {Sx} from 'app/types/common';
import {ScheduleView} from 'app/components/sharedReactComponents/Events/types';
import {isNil} from 'app/util/isNil';
import {packSx} from 'app/util/packSx/packSx';

interface Props extends Sx {
  view: ScheduleView;
  onChange: (view: ScheduleView) => void;
}

export function ViewSwitcher({sx, view, onChange}: Props) {
  const handleView = (_e: any, value: ScheduleView | null) => {
    if (!isNil(value)) {
      onChange(value);
    }
  };

  return (
    <ToggleButtonGroup
      sx={packSx({height: 33}, sx)}
      color="primary"
      value={view}
      exclusive={true}
      onChange={handleView}
    >
      <ToggleButton data-id="list-view-btn" value="list" disableRipple={false}>
        <ReorderIcon />
      </ToggleButton>

      <ToggleButton data-id="calendar-view-btn" value="calendar" disableRipple={false}>
        <CalendarMonthIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
