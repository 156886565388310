import React from 'react';
import {palette} from 'app/themes/app';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import {Controller, useForm} from 'react-hook-form';
import Joi from 'joi';
import {joiResolver} from '@hookform/resolvers/joi';
import AddIcon from '@mui/icons-material/Add';
import {isNil} from 'app/util/isNil';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {THEME, VARIANT} from 'app/constants';
import {Callback} from 'app/types/common';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {router} from 'app/router/main';
import {isErrorResponse} from 'app/api/types';
import {Notifications} from 'app/components/Notifications';
import {capitalize} from 'app/components/helpers/commonHelpers';

type AudioMode = 'mixed' | 'isolated';

type ZoomIdentity = 'guest' | (string & {});

interface ZoomData {
  url: string;
  code: string;
  mode: AudioMode;
  account: ZoomIdentity;
}

const scheme = Joi.object<ZoomData>({
  url: Joi.string().trim().required(),
  code: Joi.string().trim().allow(''),
  mode: Joi.string().required(),
  account: Joi.string(),
}).messages({
  'any.required': 'Required field',
  'string.empty': 'Required field',
});

interface Props {
  accounts: App.Connect.Auth.ZoomAccount[];
  onConnect: Callback<Promise<void>, [data: ZoomData]>;
}

export function ZoomConnection({accounts, onConnect}: Props) {
  const {control, getValues, trigger, setError} = useForm<ZoomData>({
    defaultValues: {url: '', code: '', mode: 'isolated', account: 'guest'},
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(scheme),
  });

  const handleConnect = async () => {
    const validation = await trigger();

    if (validation) {
      try {
        const form = getValues();
        await onConnect(form);
      } catch (e: any) {
        if (!isErrorResponse(e)) {
          return;
        }

        const data = e.data.ErrorData;

        if (isValidationError(data)) {
          if (data.meeting_url) {
            setError('url', {type: 'custom', message: data.meeting_url});
          }

          if (data.meeting_password) {
            setError('code', {type: 'custom', message: data.meeting_password});
          }

          return;
        }

        if (isAccountError(data)) {
          Notifications.addErrorNotification(capitalize(data.account_id));
        }

        Notifications.addErrorNotification('Could not connect to a meeting');
      }
    }
  };

  return (
    <Box sx={{p: 7}}>
      <Box sx={{mb: 3}}>
        <img
          src="/assets/img/logo/zoom-logo.png"
          srcSet="/assets/img/logo/zoom-logo@2x.png 2x"
          alt=""
        />
      </Box>

      <Box sx={{mb: 5}}>
        <Typography variant="h5" fontWeight={600}>
          Connect to an existing Zoom meeting
        </Typography>
        <Box sx={{mt: 0.5, color: palette.darkerGray}}>
          Enter you meeting URL or ID and passcode (if required).
        </Box>
      </Box>

      <Stack mb={1} direction="row" gap={0.5} alignItems="center">
        <Typography sx={{fontSize: '16px', fontWeight: 'bold'}}>
          Zoom Meeting URL or meeting ID:
        </Typography>

        <Tooltip
          content="You can find it on your meeting invitation email or in your “upcoming meetings” area in
          Zoom"
        >
          <ErrorIcon sx={{color: palette.gray4, fontSize: '14px'}} />
        </Tooltip>
      </Stack>
      <Controller
        control={control}
        name="url"
        render={({field, fieldState: {error}}) => (
          <TextField
            {...field}
            autoFocus={true}
            variant="standard"
            placeholder="https://name.zoom.us/j/6522688015 or 6522688015"
            fullWidth={true}
            helperText={error?.message ?? ' '}
            inputProps={{'data-id': 'zoom-url'}}
            error={!isNil(error)}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/\s/g, '');
              field.onChange(e);
            }}
          />
        )}
      />

      <Stack my={1} direction="row" gap={0.5} alignItems="center">
        <Typography sx={{fontSize: 16, fontWeight: 'bold'}}>Passcode:</Typography>

        <Typography fontSize={16} color={palette.gray3}>
          (if required)
        </Typography>

        <Tooltip content="You can find it on your meeting invitation email">
          <ErrorIcon sx={{color: palette.gray4, fontSize: '14px'}} />
        </Tooltip>
      </Stack>
      <Controller
        control={control}
        name="code"
        render={({field, fieldState: {error}}) => (
          <TextField
            {...field}
            fullWidth={true}
            placeholder="1234567890"
            inputProps={{
              'data-id': 'zoom-passcode',
            }}
            InputProps={{sx: {width: 230}}}
            helperText={error?.message ?? ' '}
            error={!isNil(error)}
          />
        )}
      />

      <Stack my={1} direction="row" gap={0.5} alignItems="center">
        <Typography sx={{fontSize: 16, fontWeight: 'bold'}}>Zoom identity:</Typography>

        <Tooltip content="">
          <ErrorIcon sx={{color: palette.gray4, fontSize: '14px'}} />
        </Tooltip>
      </Stack>
      <Controller
        name="account"
        control={control}
        render={({field: {onChange, ...props}}) => (
          <TextField
            select={true}
            {...props}
            fullWidth={true}
            placeholder="1234567890"
            inputProps={{
              'data-id': 'zoom-passcode',
            }}
            InputProps={{sx: {width: 230}}}
            onChange={(e) => {
              const {value} = e.target;
              if (value === 'manage') {
                router.go('connectAccountSettings', {}, {tab: 'zoom'});
                return;
              }

              onChange(e);
            }}
          >
            <MenuItem value="guest">Join as a guest</MenuItem>
            {accounts.map((acc) => (
              <MenuItem key={acc.id} value={acc.id}>
                {acc.name}
              </MenuItem>
            ))}

            <MenuItem value="manage" sx={{color: palette.blue}}>
              <ListItemIcon sx={{color: 'inherit'}}>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>Manage identities</ListItemText>
            </MenuItem>
          </TextField>
        )}
      />

      <Controller
        name="mode"
        control={control}
        render={({field}) => (
          <FormControl>
            <Box sx={{mt: 2, mb: 1, fontWeight: 'bold'}}>Epiphan Connect audio mode:</Box>

            <RadioGroup
              sx={{display: 'flex', gap: 3, flexDirection: 'row', flexWrap: 'nowrap'}}
              {...field}
            >
              <Box sx={{flex: 1}}>
                <FormControlLabel
                  sx={{mb: 0}}
                  value="isolated"
                  control={<Radio data-id="isolated-mode-radio" />}
                  label="Isolated"
                />
                <FormHelperText sx={{ml: 3}}>
                  Isolate participant audio into separate tracks. Use this to control extracted
                  audio.
                </FormHelperText>
              </Box>

              <Box sx={{flex: 1}}>
                <FormControlLabel
                  sx={{mb: 0}}
                  value="mixed"
                  control={<Radio data-id="mixed-mode-radio" />}
                  label="Mixed"
                />
                <FormHelperText sx={{ml: 3}}>
                  Each participant's audio track will contain the mixed audio from the Zoom meeting.
                </FormHelperText>
              </Box>
            </RadioGroup>
          </FormControl>
        )}
      />

      <Typography sx={{color: palette.darkerGray, mt: 4, mb: 2}}>
        The Zoom meeting host will receive a pop-up in Zoom asking for streaming permissions.
      </Typography>

      <Typography sx={{color: palette.darkerGray, mb: 4}}>
        Connecting to a meeting can take several minutes. Give yourself sufficient time to get
        connected before the meeting start time.
      </Typography>
      <Box sx={{'& button': {width: 154}}}>
        <ProgressButton
          dataId="zoom_connect_button"
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          onClick={handleConnect}
        >
          Connect
        </ProgressButton>
      </Box>
    </Box>
  );
}

interface ValidationError {
  meeting_url?: string;
  meeting_password?: string;
}

interface AccountError {
  account_id: string;
}

function isValidationError(data: any): data is ValidationError {
  return 'meeting_url' in data || 'meeting_password' in data;
}

function isAccountError(data: any): data is AccountError {
  return 'meeting_url' in data;
}
