import React, {useState} from 'react';
import {AUTH_STATE} from 'app/components/Login/LoginForm/constants';
import {ResendConfirmationEmailButton} from 'app/components/Login/LoginForm/ResendConfirmationEmailButton';

interface Props {
  authData: any;
  authState: AUTH_STATE;
}

export const ConfirmSignUp = ({
  authState,
  authData,
}: Props) => {
  const [error, setError] = useState<any>(null);

  const handleClick = () => {
    setError(null);
  };

  const handleSuccess = () => ({});

  const handleError = (error) => {
    setError(error);
  };

  if (authState !== AUTH_STATE.CONFIRM_SIGN_UP) {
    return null;
  }

  return (
    <div className="confirm-sign-up">
      Please check your email for the confirmation link<br/>
      Didn't receive a confirmation email?

      <ResendConfirmationEmailButton
        username={authData.username}
        onClick={handleClick}
        onSuccess={handleSuccess}
        onError={handleError}
      />

      {error?.message && (
        <div className="confirm-sign-up__error">
          {error.message}
        </div>
      )}
    </div>
  );
};
