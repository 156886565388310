import React from 'react';
import {Dialog, DialogContent} from '@mui/material';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {Prompt} from 'app/types/common';
import {ConnectTiers} from 'app/components/BillingManager/dialogs/ConnectTiersDialog/ConnectTiers';
import {InitializationPlaceholder} from 'app/components/BillingManager/dialogs/shared/InitializationPlaceholder/InitializationPlaceholder';

type Props = Prompt &
  React.ComponentProps<typeof ConnectTiers> & {
    loading: boolean;
  };

export function ConnectTiersDialog({
  open,
  loading,
  tiers,
  currentId,
  custom,
  hasCustomer,
  resumable,
  expireDate,
  onSelectTier,
  onEditPayment,
  onClose,
}: Props) {
  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose}>
      <DialogClose dataId="close" onClose={onClose} />
      <DialogContent sx={{p: loading ? 5 : 0}}>
        {loading ? (
          <InitializationPlaceholder />
        ) : (
          <ConnectTiers
            currentId={currentId}
            custom={custom}
            hasCustomer={hasCustomer}
            resumable={resumable}
            tiers={tiers}
            expireDate={expireDate}
            onSelectTier={onSelectTier}
            onEditPayment={onEditPayment}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
