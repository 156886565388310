import React, {useState} from 'react';
import {Component, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {MainRoutes, router} from 'app/router/main';
import {
  Collapse,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  listItemButtonClasses,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import {palette} from 'app/themes/app';
import {TOOLTIP_PLACEMENT, Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {RouteDefinition} from 'app/components/Navigation/types';
import {NavSection} from 'app/components/Navigation/hooks/useNavRoutes';
import {SectionButton} from 'app/components/Navigation/shared/SectionButton/SectionButton';

interface Props extends Sx {
  open: boolean;
  route: MainRoutes;
  routes: RouteDefinition[];
  compact?: boolean;
  onSetSection: Fn<void, [section: NavSection]>;
}

export function SettingsNav({sx, compact, route, routes, open, onSetSection}: Props) {
  const anchorRef = React.useRef<any>(null);
  const [menu, setMenu] = useState(() => (compact ? false : open));

  const handleRoute = () => {
    if (compact) {
      setMenu(false);
    }
  };

  const renderList = () => {
    return (
      <List data-id="settings-routes" sx={{mb: compact ? 0 : 2}} disablePadding={true}>
        {routes.map((r) => {
          if (!r.access) {
            return false;
          }

          return (
            <SettingsItem
              sx={r.route === 'settingBillingUsage' ? {mt: 2} : {}}
              dataId={`route-${r.route}`}
              key={r.route}
              href={router.url(r.route)}
              label={r.label}
              selected={route === r.route}
              onClick={handleRoute}
            />
          );
        })}
      </List>
    );
  };

  const renderSection = () => {
    if (compact) {
      return (
        <Popover
          open={menu}
          PaperProps={{
            sx: {zIndex: 10000, py: 2, bgcolor: palette.dark1, maxWidth: 350, minWidth: 120},
          }}
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'center',
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'center',
          }}
          onClose={() => setMenu(false)}
        >
          {renderList()}
        </Popover>
      );
    }

    return (
      <Collapse in={open} unmountOnExit={true}>
        {renderList()}
      </Collapse>
    );
  };

  const toggleSection = () => {
    if (open) {
      onSetSection(undefined);
    } else {
      onSetSection('settings');
    }

    setMenu((o) => !o);
  };

  return (
    <List disablePadding={true} sx={packSx({bgcolor: open ? palette.dark1 : 'transparent'}, sx)}>
      <Tooltip placement={TOOLTIP_PLACEMENT.RIGHT} content={compact ? 'Settings' : undefined}>
        <SectionButton
          dataId="settings-section"
          ref={anchorRef}
          label="Settings"
          compact={compact}
          expanded={open}
          icon={<SettingsIcon sx={{color: palette.darkerGray}} />}
          onClick={toggleSection}
        />
      </Tooltip>

      {renderSection()}
    </List>
  );
}

interface ItemProps extends Component, Sx {
  href: string;
  label: string;
  selected?: boolean;
  onClick(): void;
}

function SettingsItem({sx, dataId, label, href, selected, onClick}: ItemProps) {
  return (
    <ListItemButton
      data-id={dataId}
      sx={packSx(
        {
          py: 0,
          gap: 1.5,
          color: palette.white,
          [`&:hover, &:focus, &.${listItemButtonClasses.focusVisible}`]: {
            color: palette.green,
            bgcolor: palette.dark1,
            textDecoration: 'none',
            outline: 'none',
          },

          [`&.${listItemButtonClasses.selected}`]: {
            color: palette.green,
            bgcolor: palette.dark1,
            textDecoration: 'none',
            outline: 'none',

            [`&:hover, &:focus, &.${listItemButtonClasses.focusVisible}`]: {
              color: palette.green,
              bgcolor: palette.dark1,
            },
          },
        },
        sx,
      )}
      href={href}
      LinkComponent={Link}
      selected={selected}
      onClick={onClick}
    >
      <SettingsText value={label} />
    </ListItemButton>
  );
}

interface TextProps {
  value: string;
}

function SettingsText({value}: TextProps) {
  return <ListItemText primaryTypographyProps={{color: 'currentcolor', py: 0}} primary={value} />;
}
