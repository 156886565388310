import {useQuery} from '@tanstack/react-query';
import {Payment} from 'app/models/Payment';
import {BillingApiService} from 'app/services/api/billing/BillingApiService';
import {BillingMapper} from 'app/services/mappers/BillingMapper';
import {secondsToMilliseconds} from 'app/util/timeConverter';

export function usePayment(teamId: string, enabled: boolean) {
  return useQuery<Contracts.Billing.Payment, any, Payment, [key: string, id: string]>({
    queryKey: ['payment-info', teamId],
    enabled,
    queryFn: async () => {
      return BillingApiService.getPaymentInfo(teamId);
    },
    select: BillingMapper.mapPayment,
    refetchInterval: secondsToMilliseconds(30),
  });
}
