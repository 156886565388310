import React from 'react';
import classNames from 'classnames';
import {IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {Callback, Component} from 'app/types/common';

interface Props extends Component {
  onClose: Callback;
}

export const DialogClose = ({className, dataId, onClose}: Props) => {
  return (
    <IconButton
      aria-label="close"
      data-id={dataId ?? 'close-dialog-btn'}
      className={classNames('dialog-close', className)}
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  );
};
