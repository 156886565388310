import {useQuery} from '@tanstack/react-query';
import {useEffect} from 'react';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {NotificationsApi, NotificationsMapper} from 'app/components/entities/notifications';

export function useNotifications() {
  const query = useQuery({
    queryKey: ['notifications'],
    queryFn: async () => {
      const c = await NotificationsApi.getNotifications();
      return c.map(NotificationsMapper.mapNotification);
    },
  });

  const {refetch} = query;

  useEffect(() => {
    WS.on(EVENT_TYPE.USER_NOTIFICATION, refetch);

    return () => {
      WS.off(EVENT_TYPE.USER_NOTIFICATION, refetch);
    };
  }, [refetch]);

  return query;
}
