/* eslint-disable no-restricted-syntax */
import React, {useEffect, useState} from 'react';
import {DeviceIndicatorItem} from 'app/components/FleetManager/DeviceCard/StatusList/DeviceStatusIndicator/types';
import {
  DeviceStatusRecordingIcon,
  DeviceStatusStreamingIcon,
  DeviceStatusTranscribingIcon,
} from 'app/components/sharedReactComponents/DeviceStatusIcons';

interface Params {
  streaming?: boolean;
  recording?: boolean;
  transcribing?: boolean;
}

export const deviceIndicators: DeviceIndicatorItem[] = [
  {
    id: 'streaming',
    label: 'Streaming',
    icon: <DeviceStatusStreamingIcon />,
    isActive: ({streaming}) => streaming,
  },
  {
    id: 'recording',
    label: 'Recording',
    icon: <DeviceStatusRecordingIcon />,
    isActive: ({recording}) => recording,
  },
  {
    id: 'transcribing',
    label: 'Transcribing',
    icon: <DeviceStatusTranscribingIcon />,
    isActive: ({transcribing}) => transcribing,
  },
];

export function useActiveItems({
  recording,
  streaming,
  transcribing,
}: Params): DeviceIndicatorItem[] {
  const [activeItems, setActiveItems] = useState<DeviceIndicatorItem[]>([]);

  useEffect(() => {
    setActiveItems(
      deviceIndicators.filter((item) => item.isActive({streaming, recording, transcribing})),
    );
  }, [streaming, recording, transcribing]);

  return activeItems;
}
