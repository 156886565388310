import React from 'react';
import classNames from 'classnames';
import {Container} from 'app/components/FleetManager/DeviceCard/ChildCard/Container';
import {router} from 'app/router/main';
import {Callback, ClassName} from 'app/types/common';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

interface Props extends ClassName {
  device: AnyDeviceModelType;
  streamingDestinations: any[];
  currentUserRole: AnyUserRoleModel;
  getDeviceById: Callback;
  checkIsDeviceSelected: Callback;
  onChangeStreamingDestination: Callback;
}

export const WebcasterDeviceDetails: React.FC<Props> = ({
  className,
  device,
  streamingDestinations,
  getDeviceById,

  currentUserRole,
  checkIsDeviceSelected,
  onChangeStreamingDestination,
}) => {
  const detailsUrl = router.url('deviceDetails', {deviceId: device.getId()});

  return (
    <Container
      className={classNames('webcaster-device-details', className)}
      detailsUrl={detailsUrl}
      withThumbnail={true}
      withAudioMeter={true}
      selected={checkIsDeviceSelected(device.getId())}
      device={device}
      currentUserRole={currentUserRole}
      getDeviceById={getDeviceById}
      streamingDestinations={streamingDestinations}
      onChangeStreamingDestination={onChangeStreamingDestination}
    />
  );
};
