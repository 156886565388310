import React from 'react';
import {Sx} from 'app/types/common';
import {Box, Stack, Typography} from '@mui/material';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {Icons} from 'app/util/icons';
import {palette} from 'app/themes/app';
import {isNil} from 'app/util/isNil';

interface Props extends Sx {
  count: number;
  max?: number;
  type: 'device' | 'unify' | 'lvs';
  disabled?: boolean;
}

export function UnitsCount({sx, count, max, type, disabled}: Props) {
  const renderMessage = () => {
    const capacity = getCapacity(count, max);

    switch (type) {
      case 'device':
        return `${capacity} ${pluralizeN('device', count)} paired`;

      case 'lvs':
        return `${capacity} LiveScrypt ${pluralizeN('device', count)} paired`;

      default:
        return `${capacity} ${pluralizeN('project', count)} created`;
    }
  };

  const renderIcon = () => {
    if (type === 'unify') {
      return Icons.unify();
    }

    return Icons.deviceCircuit();
  };

  return (
    <Stack sx={sx} direction="row" alignItems="center" gap={1}>
      <Box color={disabled ? palette.gray7 : palette.black}>{renderIcon().reactComponent()}</Box>
      <Typography data-id="units-count" color={palette.darkerGray}>
        {renderMessage()}
      </Typography>
    </Stack>
  );
}

function getCapacity(count: number, max?: number) {
  if (isNil(max)) {
    return `${count}`;
  }

  return `${count}/${max}`;
}
