import React from 'react';
import {Card, Grid, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {StoragePanel} from 'app/components/DeviceDetails/DeviceInfoTab/DeviceStorage/StoragePanel/StoragePanel';
import {AfuPanel} from 'app/components/DeviceDetails/DeviceInfoTab/DeviceStorage/AfuPanel/AfuPanel';
import {packSx} from 'app/util/packSx/packSx';
import {Edge} from 'app/domain/edge';

interface Props extends Sx {
  storage: Edge.Storage;
  afu?: Edge.Afu;
}

export function DeviceStorage({sx, storage, afu}: Props) {
  return (
    <Card data-id="storage-panel" sx={packSx({p: 2}, sx)} variant="elevation" elevation={3}>
      <Typography variant="h5" fontWeight={600} mb={2}>
        Storage
      </Typography>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={6}>
          <StoragePanel storage={storage} />
        </Grid>

        {afu && (
          <Grid item={true} xs={6}>
            <AfuPanel sx={{height: '100%'}} afu={afu} />
          </Grid>
        )}
      </Grid>
    </Card>
  );
}
