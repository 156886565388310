import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {Box, CircularProgress, Stack, Typography} from '@mui/material';
import dayjs from 'dayjs';
import {SortBySelector} from 'app/components/Presets/SortBySelector/SortBySelector';
import {Header} from 'app/components/FleetManager/Header';
import {Edge} from 'app/domain/edge';
import {useCurrentTeamStore} from 'app/store/hooks';
import {curriedSortBy} from 'app/util/Sort';
import {
  deviceModelFilterGroups,
  useTeamPresetsFilters,
} from 'app/components/Presets/usePresetsFilterSwitches';
import {useTeamPresets} from 'app/hooks/Device/Preset/usePresets';
import {isNil} from 'app/util/isNil';
import {DevicePresetUploader} from 'app/components/sharedReactComponents/PresetUploader/DevicePresetUploader';
import {PresetApiService} from 'app/services/api/preset/PresetApiService';
import {EdgeContract} from 'app/contracts/edge';
import {downloadPreset} from 'app/components/DeviceDetails/PresetsTab/utils';
import {PresetCard} from 'app/components/sharedReactComponents/DevicePresetCard/PresetCard/PresetCard';
import {SortKey, SortOptionsMap} from 'app/components/Presets/types';
import {FilterSelector} from 'app/components/sharedReactComponents/FilterSelector';
import {ActiveFilters} from 'app/components/sharedReactComponents/ActiveFilters/ActiveFilters';
import {useCloudEntities} from 'app/components/entities/cloud';
import {Notifications} from 'app/components/Notifications';

const sortByDeviceModel = curriedSortBy('device');
const sortByName = curriedSortBy('name');

export const sortOptionsMap: SortOptionsMap = {
  model: {
    value: 'model',
    label: 'Device model',
    sort: (presets: Edge.TeamPreset[]) => sortByDeviceModel(presets),
  },
  name: {
    value: 'name',
    label: 'Preset name',
    sort: (presets: Edge.TeamPreset[]) => sortByName(presets),
  },
  createdAt: {
    value: 'createdAt',
    label: 'Creation date',
    sort: (presets: Edge.TeamPreset[]) =>
      presets.sort((a, b) => dayjs.unix(b.createdAt).diff(dayjs.unix(a.createdAt))),
  },
};

const sortOptions = [sortOptionsMap.model, sortOptionsMap.name, sortOptionsMap.createdAt];

export const PresetsPage = observer(() => {
  const team = useCurrentTeamStore();
  const {user} = useCloudEntities();

  const capabilities = team.getCapabilities();

  const [sorted, setSorted] = useState<Edge.TeamPreset[]>([]);
  const [sortValue, setSortValue] = useState<SortKey>(sortOptionsMap.name.value);

  const presetAccess = capabilities.presets();

  const {data: presets, refetch: reload, isSuccess} = useTeamPresets({enabled: true});

  const hasPresets = !isNil(presets) && presets.length > 0;

  const permitManage = user.role.canApplyPresets();

  const {
    filters,
    activeFilters,
    activeSet,
    search,
    filtered,
    setSearch,
    toggleFilter,
    clearFilters,
  } = useTeamPresetsFilters(presets);

  useEffect(() => {
    const sorted = sortOptionsMap[sortValue].sort([...filtered]);
    setSorted(sorted);
  }, [filtered, sortValue]);

  const handleDownload = (preset: Edge.Preset) => {
    if (Edge.isTeamPreset(preset)) {
      downloadPreset(preset.id, `${preset.name}.preset`);
    }
  };

  const handleUpdate = async (id: string, c: EdgeContract.UpdatePreset) => {
    try {
      await PresetApiService.updateTeamPreset(id, c);
      await reload();
    } catch (e) {
      Notifications.addErrorNotification('Failed to update preset');
      throw e;
    }
  };

  const handleDelete = async (preset: Edge.Preset) => {
    if (!Edge.isTeamPreset(preset)) {
      return;
    }

    try {
      await PresetApiService.deleteTeamPreset(preset.id);
      await reload();
    } catch (e) {
      Notifications.addErrorNotification('Failed to delete preset');
      throw e;
    }
  };

  const handleUpload = async () => {
    await reload();
  };

  const renderContent = () => {
    if (!isSuccess) {
      return (
        <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
          <CircularProgress />
          <Typography variant="h6">Loading team presets</Typography>
        </Stack>
      );
    }

    return (
      <>
        {hasPresets && (
          <Stack gap={1} mb={2}>
            {sorted.map((preset) => (
              <PresetCard
                key={preset.id}
                preset={preset}
                showModel={true}
                permitApply={permitManage}
                permitDelete={permitManage}
                available={presetAccess}
                offline={false}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
                onDownload={handleDownload}
              />
            ))}
          </Stack>
        )}

        <DevicePresetUploader size={hasPresets ? 'small' : 'large'} onUpload={handleUpload} />
      </>
    );
  };

  return (
    <Box mb={2}>
      <Header searchValue={search} onSearch={setSearch} />

      <Box mt={2} className="container">
        <Box mb={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h5" fontWeight={600} mb={1}>
              Configuration presets
            </Typography>

            {hasPresets && (
              <Stack direction="row" alignItems="center" gap={1}>
                <SortBySelector value={sortValue} options={sortOptions} onChange={setSortValue} />

                <FilterSelector
                  filterSwitchGroups={deviceModelFilterGroups}
                  filterSwitches={filters}
                  activeFilterSwitches={activeSet}
                  onClickFilterSwitcher={toggleFilter}
                />
              </Stack>
            )}
          </Stack>

          <Typography>Configuration presets available for your team.</Typography>

          {hasPresets && (
            <ActiveFilters
              filters={activeFilters}
              onRemove={toggleFilter}
              onRemoveAll={clearFilters}
            />
          )}
        </Box>

        {renderContent()}
      </Box>
    </Box>
  );
});
