import React from 'react';
import {AppTeam} from 'app/models/AppTeam/AppTeam';
import {TeamUser} from 'app/models/User/TeamUser';

export type CloudState = {
  user: TeamUser;
  teams: AppTeam[];
};

export const CloudContext = React.createContext<CloudState | undefined>(undefined);
