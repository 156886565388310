/**
 * WAI-ARIA Roles
 *
 * {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles}
 * @enum {string}
 */
enum WAI_ARIA_ROLE {
  BUTTON = 'button',
  COLUMN_HEADER = 'columnheader',
  TABLE = 'table',
  CELL = 'cell',
  ROW = 'row',
  ROW_GROUP = 'rowgroup',
  MENU = 'menu',
  MENU_ITEM = 'menuitem',
  RADIO_GROUP = 'radiogroup',
  TAB = 'tab',
  TAB_LIST = 'tablist',
  TAB_PANEL = 'tabpanel',
}

export {
  WAI_ARIA_ROLE,
};
