const STORE_KEY_NAME = 'ScrollToAnchorController';

const ScrollToAnchorController = {
  register(name: string): void {
    window.sessionStorage.setItem(STORE_KEY_NAME, name);
  },

  check(name: string): boolean {
    return window.sessionStorage.getItem(STORE_KEY_NAME) === name;
  },

  _scrollTo(element: HTMLElement): void {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  },

  mountElement(name: string, element: HTMLElement): void {
    if (this.check(name)) {
      this._scrollTo(element);
      this.unregister();
    }
  },

  unregister(): void {
    window.sessionStorage.removeItem(STORE_KEY_NAME);
  },
};

ScrollToAnchorController.unregister();

export {ScrollToAnchorController};
