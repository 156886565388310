import {useEffect} from 'react';
import {useCloudEntities} from 'app/components/entities/cloud';
import {useCurrentTeamStore} from 'app/store/hooks';
import {DriftComTracker} from 'app/util/DriftComTracker/DriftComTracker';
import {Dispatcher} from 'app/data/Dispatcher';

export function useDriftTracker() {
  const team = useCurrentTeamStore();
  const {
    user: {id: userId, email: userEmail},
  } = useCloudEntities();

  const teamId = team.id;

  useEffect(() => {
    if (!CIRRUS_DRIFTCOM_TOKEN) {
      return;
    }

    DriftComTracker.runApp(CIRRUS_DRIFTCOM_TOKEN, {userId, userEmail, teamId});

    const handleLogout = () => {
      DriftComTracker.logOut();
    };

    Dispatcher.on('logout', handleLogout);

    return () => {
      Dispatcher.removeListener('logout', handleLogout);
    };
  }, [userId, userEmail, teamId]);
}
