import React, {Component, ForwardedRef, forwardRef} from 'react';
import {KeyboardCode} from 'app/constants';
import {FORM_CONTROL_AUTOCOMPLETE} from 'app/constants/formControlAutocomplete';
import {FORM_CONTROL_TYPE} from 'app/components/sharedReactComponents/FormControl/constants';
import {Callback, ClassName, DataId} from 'app/types/common';

export type BaseTextInputType =
  | FORM_CONTROL_TYPE.URL
  | FORM_CONTROL_TYPE.EMAIL
  | FORM_CONTROL_TYPE.PASSWORD
  | FORM_CONTROL_TYPE.TEXT;

interface Props extends ClassName, DataId {
  inputRef?: ForwardedRef<HTMLInputElement>;
  type: BaseTextInputType;
  value: string | number;
  name?: string;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  pattern?: string;

  // Flags
  disabled?: boolean;
  readOnly: boolean;
  required: boolean;

  // For type `text`
  /**
   * This property helps users to fill forms faster, especially on mobile devices.
   * The name can be confusing, as it's more like an autofill.
   * You can learn more about it here:
   * https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
   */
  autoComplete?: FORM_CONTROL_AUTOCOMPLETE;
  /**
   * If `true`, the input will be focused during the first mount.
   */
  autoFocus?: boolean;

  // Callbacks
  onChange?: Callback;
  onFocus?: Callback;
  onBlur?: Callback;
  onKeyDown?: Callback;
  onKeyUp?: Callback;
}

interface State {
  value: any;
  name: string;
}

class ControlledTextInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.value,
      name: '',
    };
  }

  componentDidUpdate(prevProps) {
    const {value} = this.props;

    if (value !== prevProps.value) {
      this.setState({value});
    }
  }

  handleChange = ({target: {value}}) => {
    this.setState({value});
  };

  handleBlur = (e) => {
    const {onBlur} = this.props;

    this.saveValue();

    if (onBlur) {
      e.persist();
      onBlur(e);
    }
  };

  handleKeyUp = (e: React.KeyboardEvent) => {
    const {onKeyUp} = this.props;

    if (e.code === KeyboardCode.Enter) {
      this.saveValue();
    }

    if (onKeyUp) {
      e.persist();
      onKeyUp(e);
    }
  };

  saveValue() {
    const {name, value} = this.state;
    const {value: valueProp, onChange} = this.props;

    if (value !== valueProp) {
      this.setState({value}, () => {
        onChange?.({
          target: {
            name,
            value,
          },
        });
      });
    }
  }

  render() {
    const {inputRef, dataId, ...otherProps} = this.props;

    return (
      <input
        ref={inputRef}
        data-id={dataId}
        {...otherProps}
        value={this.state.value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onKeyUp={this.handleKeyUp}
      />
    );
  }
}

type BaseTextProps = Props & {immediateChange?: boolean};

export const BaseTextInput = forwardRef<HTMLInputElement, BaseTextProps>((props, ref) => {
  const {immediateChange, dataId, ...otherProps} = props;

  if (immediateChange === false) {
    return <ControlledTextInput {...otherProps} inputRef={ref} />;
  }

  return <input {...otherProps} ref={ref} data-id={dataId} />;
});

BaseTextInput.displayName = 'BaseTextInput';
