import React from 'react';
import classNames from 'classnames';
import {SIZE} from 'app/constants';
import {Icons} from 'app/util/icons';
import {StartStopButton} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton';
import {DEVICE_START_STOP_BUTTON_STATUS} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton/constants';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  stoppedLabel?: string;
  status: DEVICE_START_STOP_BUTTON_STATUS;
  startTime?: number;
  size?: SIZE;
  disabled: boolean;
  hovered?: boolean;
  loading?: boolean;
  startAction: Callback;
  stopAction: Callback;
  hasEvent?: boolean;
}

export function StreamingStartStopButton({
  className,
  stoppedLabel = 'Start streaming',
  status,
  startTime,
  size,
  disabled,
  hovered,
  loading,
  startAction,
  stopAction,
  hasEvent = false,
}: Props) {
  const icon =
    hasEvent && status === DEVICE_START_STOP_BUTTON_STATUS.STARTED
      ? Icons.streamingEvent()
      : Icons.streaming();

  return (
    <StartStopButton
      className={classNames('streaming-start-stop-button', className)}
      data-id="streaming_start_stop_button"
      status={status}
      icon={icon.size(SIZE.S).reactComponent()}
      stoppedLabel={stoppedLabel}
      startedLabel="Streaming"
      loadingLabel="Connecting..."
      stopLoadingLabel="Stop connection"
      stopLabel="Stop streaming"
      errorLabel="Streaming error"
      startTime={startTime}
      size={size}
      disabled={disabled}
      hovered={hovered}
      loading={loading}
      startAction={startAction}
      stopAction={stopAction}
      unsetLoadingAfterPromise={false}
    />
  );
}
