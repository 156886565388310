/* eslint-disable no-restricted-syntax */
import {FilterSwitch} from 'app/components/sharedReactComponents/FilterSelector/types';
import {Callback} from 'app/types/common';
import {intersectionOfSets} from 'app/util/intersectionOfSets';

const defaultCallbackGetter = (filter: FilterSwitch) => filter.callback;

/**
 * @param items
 * @param filters Active filters
 * @param callbackGetter
 * @return filtered items
 */
function filterItemsByFilterSwitches<T>(
  items: T[],
  filters: FilterSwitch[],
  callbackGetter: Callback = defaultCallbackGetter,
): T[] {
  const callbacksByGroups: Record<string, Callback[]> = {};

  for (const filter of filters) {
    const {group} = filter;
    const callback = callbackGetter(filter);

    if (!callback || !group) {
      continue;
    }

    const callbacksByGroup = callbacksByGroups[group];

    if (callbacksByGroup) {
      callbacksByGroup.push(callback);
    } else {
      callbacksByGroups[group] = [callback];
    }
  }

  const filteredByGroup: Array<Set<T>> = [];

  for (const [, callbacks] of Object.entries(callbacksByGroups)) {
    const filtered = items.filter((item) => callbacks.some((callback) => callback(item)));
    filteredByGroup.push(new Set(filtered));
  }

  let intersection = new Set(items);
  for (const filter of filteredByGroup) {
    intersection = intersectionOfSets(intersection, filter);
  }

  return [...intersection];
}

export {filterItemsByFilterSwitches};
