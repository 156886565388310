import React from 'react';
import classNames from 'classnames';
import {ClassName, DataId} from 'app/types/common';

function getName(name: string): string {
  const [one, two] = name
    .replace(/\(.*?\)/, '')
    .trim()
    .split(/\s+/);

  const [first, second] = [one?.charAt(0), two?.charAt(0)];

  if (first && second) {
    return `${first}${second}`;
  }

  if (one) {
    return one.slice(0, 2);
  }

  return '';
}

interface Props extends ClassName, DataId {
  name: string;
  type: App.Connect.MemberType;
  size?: 'normal' | 'big';
  disconnected?: boolean;
  cameraOff?: boolean;
  sharing?: boolean;
  active?: boolean;
}

type Background = 'blue' | 'grey' | 'silvery' | 'green' | 'cerulean' | 'dark';

export const ParticipantAvatar = ({
  className,
  name,
  type,
  size = 'normal',
  cameraOff = false,
  sharing = false,
  active = false,
  disconnected = false,
  dataId,
}: Props) => {
  const isShare = type === 'vbss';
  const isUser = type === 'participant';
  const isFeed = type === 'return-feed';

  const getBackground = (): Background => {
    if (isShare) {
      if (!sharing && active) {
        return 'green';
      }

      if (!sharing) {
        return 'grey';
      }
    }

    if (isUser) {
      if (disconnected) {
        return 'cerulean';
      }

      if (cameraOff) {
        return 'silvery';
      }
    }

    if (isFeed && !active) {
      return 'grey';
    }

    return 'blue';
  };

  const renderContent = () => {
    if (isShare) {
      if (!sharing && active) {
        return (
          <span
            className={`participant-avatar__waiting-share participant-avatar__waiting-share--${size}`}
          >
            waiting for
            <br />
            screen sharing
          </span>
        );
      }

      return (
        <img
          className={`participant-avatar__${size}-icon`}
          src={`/assets/img/connect/screen-share-${size}.png`}
          srcSet={`/assets/img/connect/screen-share-${size}@2x.png 2x`}
          alt="SC"
        />
      );
    }

    if (isFeed) {
      return (
        <img
          className={`participant-avatar__${size}-icon`}
          src={`/assets/img/connect/return-feed-${size}.png`}
          srcSet={`/assets/img/connect/return-feed-${size}@2x.png 2x`}
          alt="SC"
        />
      );
    }

    if (isUser) {
      if (disconnected) {
        return (
          <span
            className={`participant-avatar__disconnected participant-avatar__disconnected--${size}`}
          >
            No signal
          </span>
        );
      }

      if (cameraOff) {
        return (
          <span
            className={`participant-avatar__camera-off participant-avatar__camera-off--${size}`}
          >
            Camera off
          </span>
        );
      }
    }

    return <span>{getName(name)}</span>;
  };

  return (
    <div
      className={classNames(
        'participant-avatar',
        `participant-avatar--${size}`,
        `participant-avatar--${getBackground()}`,
        className,
      )}
      data-id={dataId}
    >
      {renderContent()}
    </div>
  );
};
