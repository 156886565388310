import React, {useEffect, useMemo} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import dayjs from 'dayjs';
import {Box, Link, Stack, Typography} from '@mui/material';
import {DevicesAlertsDay} from 'app/components/DevicesAlerts/DevicesAlertsDay';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {PairDeviceButton} from 'app/components/libs/buttons/PairDeviceButton';
import {PageHeader} from 'app/components/sharedReactComponents/PageHeader';
import {groupDeviceAlertsByDate} from 'app/components/DevicesAlerts/utils';
import {DevicesAlertsCounter} from 'app/components/DevicesAlerts/DevicesAlertsCounter';
import {router} from 'app/router/main';
import {AlertsApi, useTeamAlertsQuery} from 'app/components/entities/alerts';

export function DevicesAlertsPage() {
  const query = useTeamAlertsQuery();
  const client = useQueryClient();

  useEffect(() => {
    const onAcknowledge = async () => {
      const now = dayjs().unix();
      await AlertsApi.acknowledgeSince(now);
      await client.refetchQueries({queryKey: ['alerts', 'unread']});
    };

    if (query.isSuccess) {
      void onAcknowledge();
    }
  }, [query.data, query.isSuccess, client]);

  const deviceAlertGroups = useMemo(() => groupDeviceAlertsByDate(query.data ?? []), [query.data]);

  return (
    <div>
      <PageHeader
        buttons={
          <>
            <PairDeviceButton />

            <DevicesAlertsCounter />
          </>
        }
      />

      {query.isLoading && <IndeterminateProgressBar />}

      {query.isSuccess && (
        <Box my={2} className="container">
          <Typography mb={1} variant="h5" fontWeight={600}>
            Alerts
          </Typography>

          <Typography>Alerts are device specific.</Typography>

          <Typography mb={1}>
            <Link href={router.url('alerts')}>Manage alerts</Link> to select what alerts you would
            like to receive.
          </Typography>

          <Stack gap={2}>
            {deviceAlertGroups.map((group, index) => (
              <DevicesAlertsDay key={group.date} group={group} showHeader={index === 0} />
            ))}
          </Stack>
        </Box>
      )}
    </div>
  );
}
