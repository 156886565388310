// rework
enum AUTH_STATE {
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_PASSWORD = 'resetPassword',
  CONFIRM_SIGN_UP = 'confirmSignUp',
}

// See: https://aws-amplify.github.io/docs/js/authentication#show-your-app-after-sign-in
const AWS_AUTH_STATE = {
  SIGN_IN: 'signIn',
  SIGN_UP: 'signUp',
  CONFIRM_SIGN_IN: 'confirmSignIn',
  CONFIRM_SIGN_UP: 'confirmSignUp',
  FORGOT_PASSWORD: 'forgotPassword',
  VERIFY_CONTACT: 'verifyContact',
  SIGNED_IN: 'signedIn',
};

const DEFAULT_AUTH_STATE = AUTH_STATE.SIGN_IN;

const PASSWORD_MIN_LENGTH = 8;

const PASSWORD_REQUIREMENTS_MESSAGE =
  'Password must include at least 1 number, ' +
  '1 special character and 1 uppercase letter. ' +
  `Minimum ${PASSWORD_MIN_LENGTH} characters.`;

export {
  AUTH_STATE,
  AWS_AUTH_STATE,
  DEFAULT_AUTH_STATE,
  PASSWORD_REQUIREMENTS_MESSAGE,
  PASSWORD_MIN_LENGTH,
};
