import {ActionTypes} from 'app/data/ActionTypes';
import {Dispatcher} from 'app/data/Dispatcher';
import React, {useEffect} from 'react';

interface Props {
  id: string;
}

export function GroupPortal({id}: Props) {
  useEffect(() => {
    Dispatcher.emit(ActionTypes.UI_PORTAL_ELEMENT_MOUNT, id);

    return () => {
      Dispatcher.emit(ActionTypes.UI_PORTAL_ELEMENT_UNMOUNT, id);
    };
  }, [id]);

  return <div id={id} />;
}
