import React from 'react';
import {Box, Button, Stack, Tooltip, Typography} from '@mui/material';
import AddLinkIcon from '@mui/icons-material/AddLink';
import {grey} from '@mui/material/colors';
import {Icons} from 'app/util/icons';
import {Sx} from 'app/types/common';

interface Props extends Sx {
  edge: boolean;
  offline: boolean;
  onConnect(): void;
}

export function NoDeviceIntegration({sx, edge, offline, onConnect}: Props) {
  return (
    <Stack
      sx={sx}
      px={5}
      py={4}
      borderRadius={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      bgcolor={grey[100]}
    >
      <Box>
        <Typography fontWeight={600} mb={2}>
          This device is not connected to a CMS
        </Typography>

        <Box>
          {edge ? (
            <>
              <Tooltip title={offline ? 'Device is offline' : undefined}>
                <span>
                  <Button
                    data-id="connect-chrono-button"
                    sx={{fontSize: 14, mb: 0.5}}
                    variant="text"
                    color="primary"
                    startIcon={<AddLinkIcon />}
                    disabled={offline}
                    onClick={onConnect}
                  >
                    Enable Epiphan Edge scheduling on your device
                  </Button>
                </span>
              </Tooltip>
              <Typography color={grey[500]}>
                or connect device to Panopto, Kaltura or YuJa CMS to view events schedule
              </Typography>
            </>
          ) : (
            <Typography color={grey[500]}>
              Connect device to Panopto, Kaltura or YuJa CMS to view events schedule
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={{'& .svg-icon': {width: 64, height: 64, color: grey[500]}}}>
        {Icons.eventCMSIsTurnedOff().reactComponent()}
      </Box>
    </Stack>
  );
}
