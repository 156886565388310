import {EVENT_TYPE} from 'app/api/WebSocket/constants';

const eventTypeSet: Set<string> = new Set(Object.values(EVENT_TYPE));
const unregisteredEventTypeSet: Set<string> = new Set();

function checkEventsRegister(type: string) {
  if (!eventTypeSet.has(type) && !unregisteredEventTypeSet.has(type)) {
    unregisteredEventTypeSet.add(type);
    console.warn('Unregistered WebSocket event', `"${type}"`);
  }
}

function createDeviceALSEventName(deviceId: string) {
  return `${EVENT_TYPE.DEVICE_ALS}.${deviceId}`;
}

function createDeviceChannelImageEventName(channelDeviceId: string) {
  return `${EVENT_TYPE.DEVICE_CHANNEL_IMAGE}.${channelDeviceId}`;
}

function createDeviceSourceImageEventName(deviceId: string, sourceId: string) {
  return `${EVENT_TYPE.DEVICE_SOURCE_IMAGE}.${deviceId}.${sourceId}`;
}

function createDeviceChangeEventName(deviceId: string) {
  return `${EVENT_TYPE.DEVICE_CHANGE}.${deviceId}`;
}

export {
  checkEventsRegister,
  createDeviceALSEventName,
  createDeviceChannelImageEventName,
  createDeviceSourceImageEventName,
  createDeviceChangeEventName,
};
