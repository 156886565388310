/* eslint-disable react/default-props-match-prop-types */
import React, {Component, forwardRef} from 'react';
import classNames from 'classnames';
import {FormHelperText} from 'app/components/sharedReactComponents/FormHelperText';
import {FormControlLabel} from 'app/components/sharedReactComponents/FormControlLabel';
import {Icons} from 'app/util/icons';
import {SIZE, THEME} from 'app/constants';
import {DropdownSelect, SelectProps} from 'app/components/sharedReactComponents/DropdownSelect';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {TextareaInput, TextareaProps} from 'app/components/sharedReactComponents/TextareaInput';
import {ColorInput} from 'app/components/sharedReactComponents/ColorInput';
import {LoadingSpinner} from 'app/components/sharedReactComponents/LoadingWrapper/LoadingSpinner';
import {BaseTextInput, BaseTextInputType} from 'app/components/sharedReactComponents/BaseTextInput';
import {NumberInput, NumberProps} from 'app/components/sharedReactComponents/NumberInput';
import {Checkbox} from 'app/components/sharedReactComponents/Checkbox';
import {isNil} from 'app/util/isNil';
import {Switcher} from 'app/components/sharedReactComponents/Switcher';
import {FORM_CONTROL_TYPE} from 'app/components/sharedReactComponents/FormControl/constants';
import {FORM_CONTROL_AUTOCOMPLETE} from 'app/constants/formControlAutocomplete';
import {Callback, ClassName, DataId} from 'app/types/common';

const defaultProps = {
  type: FORM_CONTROL_TYPE.TEXT,
  fullWidth: false,
  disabled: false,
  readOnly: false,
  required: false,
  error: false,
};

type DefaultProps = typeof defaultProps;
// rework
type Props = ClassName &
  DataId &
  DefaultProps & {
    value?: string | number | boolean;
    name?: string;

    placeholder?: string;
    helperText?: React.ReactNode;

    size?: SIZE;

    minLength?: number;
    maxLength?: number;
    pattern?: string;

    inputRef?: any;
    containerRef?: any;

    label?: React.ReactNode;
    labelComponent?: React.ElementType;

    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;

    // Flags
    loading?: boolean;

    /**
     * For <BaseTextInput>. Default is `true`
     */
    immediateChange?: boolean;

    // For type `text`
    /**
     * This property helps users to fill forms faster, especially on mobile devices.
     * The name can be confusing, as it's more like an autofill.
     * You can learn more about it here:
     * https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
     */
    autoComplete?: FORM_CONTROL_AUTOCOMPLETE;
    /**
     * If `true`, the input will be focused during the first mount.
     */
    autoFocus?: boolean;

    // Now for type `select` but may be for other types in future
    clearable?: boolean;
    indeterminate?: boolean;

    // For type `select`
    selectComponentProps?: SelectProps;

    // For type `number`
    numberComponentProps?: NumberProps;

    textareaComponentProps?: TextareaProps;

    // Callbacks
    onChange?: Callback;
    onFocus?: Callback;
    onBlur?: Callback;
    onKeyDown?: Callback;
    onKeyUp?: Callback;
  };

interface State {
  focused: boolean;
}

class FormControlComponent extends Component<Props, State> {
  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);

    this.state = {
      focused: false,
    };
  }

  getControl() {
    const {type} = this.props;

    switch (type) {
      case FORM_CONTROL_TYPE.SELECT:
        return this.renderSelectInput();
      case FORM_CONTROL_TYPE.TEXTAREA:
        return this.renderTextareaInput();
      case FORM_CONTROL_TYPE.COLOR:
        return this.renderColorInput();
      case FORM_CONTROL_TYPE.NUMBER:
        return this.renderNumberInput();
      case FORM_CONTROL_TYPE.CHECKBOX:
        return this.renderCheckboxInput();
      case FORM_CONTROL_TYPE.SWITCHER:
        return this.renderSwitcherInput();
      default:
        return this.renderTextInput();
    }
  }

  renderSelectInput() {
    const {
      name,
      value,
      placeholder,
      size,

      clearable,
      indeterminate,
      disabled,
      loading,

      onChange,
      onBlur,

      selectComponentProps,
      ...rest
    } = this.props;

    const {
      className,
      type,
      fullWidth,
      readOnly,
      required,
      error,
      helperText,
      minLength,
      maxLength,
      pattern,
      inputRef,
      containerRef,
      label,
      labelComponent,
      startAdornment,
      endAdornment,
      immediateChange,
      autoComplete,
      autoFocus,
      numberComponentProps,
      onFocus,
      onKeyDown,
      onKeyUp,
      ...elementProps
    } = rest;

    return (
      <DropdownSelect
        name={name}
        value={value}
        placeholder={placeholder}
        size={size}
        clearable={clearable}
        indeterminate={indeterminate}
        disabled={disabled}
        loading={loading}
        onChange={onChange}
        onBlur={onBlur}
        {...selectComponentProps}
        {...elementProps}
      />
    );
  }

  renderTextareaInput() {
    const {
      dataId,
      inputRef,
      name,
      placeholder,
      value,

      autoFocus,
      disabled,
      readOnly,
      required,
      textareaComponentProps,

      onChange,
      onBlur,
      onKeyDown,
      onKeyUp,

      ...rest
    } = this.props;

    const {
      className,
      type,
      fullWidth,
      error,
      helperText,
      size,
      minLength,
      maxLength,
      pattern,
      containerRef,
      label,
      labelComponent,
      startAdornment,
      endAdornment,
      loading,
      immediateChange,
      autoComplete,
      clearable,
      indeterminate,
      selectComponentProps,
      numberComponentProps,
      onFocus,
      ...elementProps
    } = rest;

    return (
      <TextareaInput
        inputRef={inputRef}
        dataId={dataId}
        placeholder={placeholder}
        name={name}
        value={value as string}
        autoFocus={autoFocus}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        loading={loading}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        {...textareaComponentProps}
        {...elementProps}
      />
    );
  }

  renderNumberInput() {
    const {
      name,
      value,
      numberComponentProps,
      placeholder,

      disabled,
      loading,

      onChange,
      onBlur,
      ...rest
    } = this.props;

    const {
      className,
      type,
      fullWidth,
      readOnly,
      required,
      error,
      helperText,
      size,
      minLength,
      maxLength,
      pattern,
      inputRef,
      containerRef,
      label,
      labelComponent,
      startAdornment,
      endAdornment,
      immediateChange,
      autoComplete,
      autoFocus,
      clearable,
      indeterminate,
      selectComponentProps,
      onFocus,
      onKeyDown,
      onKeyUp,
      ...elementProps
    } = rest;

    return (
      <NumberInput
        {...(numberComponentProps as any)}
        name={name}
        value={value as number}
        placeholder={placeholder}
        loading={loading}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        {...elementProps}
      />
    );
  }

  renderCheckboxInput() {
    const {
      name,
      value,
      label,
      size,

      autoFocus,
      disabled,
      loading,
      indeterminate,

      onChange,
      onBlur,

      ...rest
    } = this.props;

    const {
      dataId,
      className,
      type,
      fullWidth,
      readOnly,
      required,
      error,
      placeholder,
      helperText,
      minLength,
      maxLength,
      pattern,
      inputRef,
      containerRef,
      labelComponent,
      startAdornment,
      endAdornment,
      immediateChange,
      autoComplete,
      clearable,
      selectComponentProps,
      numberComponentProps,
      onFocus,
      onKeyDown,
      onKeyUp,
      ...elementProps
    } = rest;

    return (
      <Checkbox
        dataId={dataId}
        name={name}
        value={value as boolean}
        label={label}
        size={size}
        autoFocus={autoFocus}
        loading={loading}
        disabled={disabled}
        indeterminate={indeterminate}
        onChange={onChange}
        onBlur={onBlur}
        {...elementProps}
      />
    );
  }

  renderSwitcherInput() {
    const {
      name,
      value,
      size,

      autoFocus,
      disabled,
      loading,

      onChange,

      ...rest
    } = this.props;

    const {
      className,
      type,
      fullWidth,
      readOnly,
      required,
      error,
      placeholder,
      helperText,
      minLength,
      maxLength,
      pattern,
      inputRef,
      containerRef,
      label,
      labelComponent,
      startAdornment,
      endAdornment,
      immediateChange,
      autoComplete,
      clearable,
      indeterminate,
      selectComponentProps,
      numberComponentProps,
      onFocus,
      onBlur,
      onKeyDown,
      onKeyUp,
      ...elementProps
    } = rest;

    return (
      <Switcher
        name={name}
        value={value as boolean}
        size={size}
        showLabel={true}
        labelOnText="On"
        labelOffText="Off"
        autoFocus={autoFocus}
        disabled={disabled || loading}
        onChange={onChange}
        {...elementProps}
      />
    );
  }

  renderTextInput() {
    const {
      dataId,
      name,
      placeholder,
      type,
      value,
      minLength,
      maxLength,
      pattern,
      inputRef,

      autoComplete,
      immediateChange,

      startAdornment,
      endAdornment,

      autoFocus,
      disabled,
      loading,
      readOnly,
      required,

      onChange,
      onKeyDown,
      onKeyUp,

      ...rest
    } = this.props;

    const {
      className,
      fullWidth,
      error,
      helperText,
      size,
      containerRef,
      label,
      labelComponent,
      clearable,
      indeterminate,
      selectComponentProps,
      numberComponentProps,
      onFocus,
      onBlur,
      ...elementProps
    } = rest;

    const showInputStartAdornment = Boolean(startAdornment);
    const showInputEndAdornment = Boolean(required) || Boolean(endAdornment);

    return (
      <div className="cr-form-control__input-container loading-wrapper">
        {showInputStartAdornment && (
          <div className="cr-form-control__input-start-adornment">{startAdornment}</div>
        )}

        <BaseTextInput
          dataId={dataId}
          ref={inputRef}
          className="cr-form-control__input"
          type={type as BaseTextInputType}
          placeholder={placeholder}
          name={name}
          value={value as string | number}
          minLength={minLength}
          maxLength={maxLength}
          pattern={pattern}
          immediateChange={immediateChange}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          required={required}
          disabled={disabled || loading}
          readOnly={readOnly}
          onChange={onChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          {...elementProps}
        />

        {showInputEndAdornment && (
          <div className="cr-form-control__input-end-adornment">
            {endAdornment}

            {required && !disabled && (
              <Tooltip content="Required">
                {Icons.required().theme(THEME.SUCCESS).reactComponent()}
              </Tooltip>
            )}
          </div>
        )}

        {loading && <LoadingSpinner />}
      </div>
    );
  }

  renderColorInput() {
    const {
      name,
      value,
      placeholder,

      disabled,
      readOnly,
      loading,

      onChange,
      // onBlur,

      ...rest
    } = this.props;

    const {
      className,
      type,
      fullWidth,
      required,
      error,
      helperText,
      size,
      minLength,
      pattern,
      inputRef,
      containerRef,
      label,
      labelComponent,
      startAdornment,
      endAdornment,
      immediateChange,
      autoComplete,
      autoFocus,
      clearable,
      indeterminate,
      selectComponentProps,
      numberComponentProps,
      onFocus,
      onBlur,
      onKeyDown,
      onKeyUp,
      ...elementProps
    } = rest;

    return (
      <ColorInput
        placeholder={placeholder}
        name={name}
        value={value as string}
        disabled={disabled}
        readOnly={readOnly}
        loading={loading}
        onChange={onChange}
        // onBlur={onBlur}
        {...elementProps}
      />
    );
  }

  handleFocus = (e) => {
    this.setState((state) => (state.focused ? null : {focused: true}));

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  handleBlur = (e) => {
    this.setState((state) => (state.focused ? {focused: false} : null));

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  render() {
    const {
      className,
      helperText,
      label,
      type,
      containerRef,
      size,

      labelComponent,

      disabled,
      loading,
      readOnly,
      required,
      error,
      fullWidth,
    } = this.props;
    const {focused} = this.state;

    const control = this.getControl();
    const showLabel = isNil(label) === false && type !== FORM_CONTROL_TYPE.CHECKBOX;
    let component = labelComponent;

    if (!labelComponent) {
      if (type === FORM_CONTROL_TYPE.SELECT || type === FORM_CONTROL_TYPE.SWITCHER) {
        component = 'div';
      }
    }

    return (
      <div
        ref={containerRef}
        className={classNames(
          'cr-form-control',
          {
            [`cr-form-control--size-${size}`]: Boolean(size),
            'cr-form-control--focused': focused,
            'cr-form-control--disabled': disabled || loading,
            'cr-form-control--readonly': readOnly,
            'cr-form-control--required': required,
            'cr-form-control--error': error,
            'cr-form-control--full-width': fullWidth,
          },
          className,
        )}
      >
        {showLabel ? (
          <FormControlLabel label={label} control={control} component={component} />
        ) : (
          control
        )}

        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </div>
    );
  }
}

type ForwardedProps = Omit<Props, keyof DefaultProps> & Partial<DefaultProps>;

export const FormControl = forwardRef<any, ForwardedProps>((props, ref) => (
  <FormControlComponent {...defaultProps} {...props} containerRef={ref} />
));

FormControl.displayName = 'FormControl';
