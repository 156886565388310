import React from 'react';
import {Stack, Typography} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import {Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {palette} from 'app/themes/app';

interface Props extends Sx {
  message?: string;
}

export function CurrentTierMark({sx, message = 'Current plan'}: Props) {
  return (
    <Stack
      display="inline-flex"
      data-id="current-tier-label"
      direction="row"
      alignItems="center"
      gap={0.5}
      sx={packSx({color: palette.blue}, sx)}
    >
      <CheckIcon />
      <Typography fontWeight={600}>{message}</Typography>
    </Stack>
  );
}
