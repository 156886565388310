import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {packSx} from 'app/util/packSx/packSx';
import {palette} from 'app/themes/app';

export function PermissionNote({sx}: Sx) {
  return (
    <Stack sx={packSx({color: palette.gray3}, sx)} direction="row" gap={1} alignItems="center">
      <Box sx={{color: '#d2d2d3'}}> {Icons.exclamation().size(SIZE.S).reactComponent()}</Box>
      <Typography>
        Your meeting will not be recorded.
        <br />
        The new permission will only allow Epiphan to get access to the raw audio and video from the
        call.
      </Typography>
    </Stack>
  );
}
