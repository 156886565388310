import React from 'react';
import {Stack, Typography} from '@mui/material';
import {DataId, Sx} from 'app/types/common';
import {EventCategory} from 'app/components/sharedReactComponents/Events/types';
import {EventIcon} from 'app/components/sharedReactComponents/Events/shared/EventIcon/EventIcon';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {capitalize} from 'app/components/helpers/commonHelpers';

interface Props extends Sx, DataId {
  category: EventCategory;
  count: number;
}

export function EventsCount({dataId, sx, category, count}: Props) {
  return (
    <Tooltip content={capitalize(category)}>
      <Stack sx={sx} direction="row" alignItems="center" gap={0.25}>
        <EventIcon type={category} />
        <Typography data-id={dataId}>{count}</Typography>
      </Stack>
    </Tooltip>
  );
}
