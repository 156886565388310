import {useQuery} from '@tanstack/react-query';
import {MaintenanceApi} from 'app/components/entities/maintenance/api/MaintenanceApi';
import {MaintenanceMapper} from 'app/components/entities/maintenance/mapper';

export function useVersionQuery(enabled: boolean) {
  return useQuery({
    queryKey: ['cloud-version'],
    queryFn: async () => {
      const res = await MaintenanceApi.getVersion();
      return MaintenanceMapper.mapVersion(res);
    },
    enabled,
  });
}
