import {
  __,
  find,
  fromPairs,
  identity,
  lensIndex,
  map,
  over,
  partition,
  pipe,
  toPairs,
  zip,
} from 'ramda';
import {isNil} from 'app/util/isNil';
import {Url} from 'app/api/util/Url';
import {queryStringSorted} from 'app/api/util/queryStringSorted';
import {Callback} from 'app/types/common';
import {RouterStateType} from 'app/router/types';
import {Location} from 'app/router/Location';

type RouterRouteType = {
  url: string;
  title: string;
};

type RouterDefaultRouteType<T extends string> = {
  name: T;
  params: Record<string, any>;
};

interface RouterOptions<T extends string> {
  routes: Record<T, RouterRouteType>;
  defaultRoute: RouterDefaultRouteType<T>;
}

export class Router<T extends string> {
  routes: Record<T, RouterRouteType>;
  defaultRoute: RouterDefaultRouteType<T>;

  changeCallbacks: Set<Callback> = new Set();
  changeCurrentTeamCallbacks: Set<Callback> = new Set();

  state: RouterStateType<T>;

  protected onChangeCallback?: Callback;

  constructor({
    routes,
    defaultRoute,
  }: RouterOptions<T>) {
    this.routes = routes;
    this.defaultRoute = defaultRoute;

    this.state = {
      name: this.defaultRoute.name,
      params: this.defaultRoute.params,
    };

    this.updateState = this.updateState.bind(this);
  }

  /**
   * Callback fires on route change
   */
  init(onChange?: (state: RouterStateType<T>) => void) {
    this.onChangeCallback = onChange;

    Location.onChange(this.updateState);

    this.updateState();
  }

  updateState() {
    const {
      path,
      query,
    } = this.parseHash();
    const matchingRoute = this.findMatchingRoute(path);

    if (isNil(matchingRoute)) {
      this.state = {
        name: this.defaultRoute.name,
        params: this.defaultRoute.params,
      };
      this.go();

      return;
    }

    this.state = {
      ...matchingRoute,
      query,
    };

    this._fireChange();

    this.title(this.state.name);

    if (typeof this.onChangeCallback === 'function') {
      this.onChangeCallback(this.state);
    }

    return this.state;
  }

  onChange(callback: Callback) {
    this.changeCallbacks.add(callback);
  }

  offChange(callback: Callback) {
    this.changeCallbacks.delete(callback);
  }

  _fireChange() {
    this.changeCallbacks.forEach(callback => callback(this.state));
  }

  parseHash(): {path: string[]; query?: Record<string, any>} {
    // @ts-expect-error
    const [, pathStr, , queryStr] = /#?\/?([^?]*)(\?(.*))?/.exec(Location.getHash());

    const path = pathStr.split('/').filter(value => value);

    let query;
    if (!isNil(queryStr)) {
      query = queryStr.split('&').reduce((acc, param) => {
        const [key, value] = param.split('=');

        acc[key] = value;

        return acc;
      }, {});
    }

    return {
      path,
      query,
    };
  }

  // TODO: Refactor
  routeMatch(url: string, path: string[]) {
    const urlParts = url.split('/');

    if (urlParts.length !== path.length) {
      return null;
    }

    // @ts-expect-error
    const [paramsPairs, paths] = pipe(
      // @ts-expect-error
      zip(__, path),
      partition(([k]) => (isNil(k) ? null : k[0]) === ':'),
    )(urlParts);

    if (paths.some(([key, value]) => key !== value)) {
      return null;
    }

    return fromPairs(paramsPairs);
  }

  findMatchingRoute(path: string[]) {
    const matchingRoute = pipe(
      toPairs,
      // @ts-expect-error
      map(([name, {url}]) => [name, this.routeMatch(url, path)]),
      // @ts-expect-error
      find(([, v]) => identity(v)),
    )(this.routes);

    if (isNil(matchingRoute)) {
      return null;
    }

    // @ts-expect-error
    const [name, params] = over(
      // @ts-expect-error
      lensIndex(1),
      pipe(
        toPairs,
        // @ts-expect-error
        map(([k, v]) => [/:(.*)/.exec(k)[1], v]),
        // @ts-expect-error
        fromPairs,
      ),
    )(matchingRoute);

    return {
      name,
      params,
    };
  }

  title(name: string) {
    document.title = this.routes[name]?.title ?? '';
  }

  url(name?: T, params?: Record<string, any>, query?: Record<string, any>) {
    if (isNil(name)) {
      name = this.state.name;
    }

    if (isNil(params)) {
      params = this.state.params;
    }

    const path = this.routes[name].url
      .split('/')
      .map(part => {
        const partParts = /:(.+)/.exec(part);
        const paramName = partParts ? partParts[1] : null;

        if (paramName) {
          // @ts-expect-error
          return params[paramName];
        }

        return part;
      })
      .join('/');

    return `${Url.join('#', path)}${isNil(query) ? '' : `?${queryStringSorted(query)}`}`;
  }

  go(name?: T, params?: Record<string, any>, query?: Record<string, any>) {
    Location.assign(this.url(name, params, query));
    return {};
  }

  resetQuery() {
    Location.replace(this.url());
  }

  goToTeam(teamId: string, url?: string) {
    this.goToTeamRoute(teamId, url ?? this.url(this.defaultRoute.name));
  }

  urlToTeamRoute(teamId: string, url: string) {
    // TODO: WCX-1176: assuming application is in the root
    return Location.getOrigin() + '/' + teamId + url;
  }

  goToTeamRoute(teamId: string, url: string) {
    this.changeCurrentTeamCallbacks.forEach(callback => callback(teamId));
    Location.assign(this.urlToTeamRoute(teamId, url));
  }

  goToDefaultRoute() {
    this.go(this.defaultRoute.name, this.defaultRoute.params);
  }

  onChangeCurrentTeam(cb: Callback) {
    this.changeCurrentTeamCallbacks.add(cb);
  }

  offChangeCurrentTeam(cb: Callback) {
    this.changeCurrentTeamCallbacks.delete(cb);
  }

  is(name: T) {
    return this.state.name === name;
  }
}
