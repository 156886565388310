import {FilesBilling, FilesBillingContracts} from 'app/components/entities/billing/files/model';

export function mapPrice(contract: FilesBillingContracts.Price): FilesBilling.Price {
  return {
    id: contract.id,
    name: contract.name,
    free: {
      storage: contract.storage_free_gb,
      transfer: contract.transfer_free_gb,
    },
    rates: {
      storage: contract.storage_rate,
      transfer: contract.transfer_rate,
    },
    isCustom: contract.is_custom,
    periodConfig: {...contract.billing_period},
  };
}

export function mapPlan(contract: FilesBillingContracts.Plan): FilesBilling.Plan {
  return {
    priceId: contract.price,
    price: mapPrice(contract.price_info),
    period: {...contract.billing_period},
  };
}

export function mapInvoice(contract: FilesBillingContracts.Invoice): FilesBilling.Invoice {
  return {
    date: contract.issuance_at,
    storageSize: contract.storage_gb,
    storageFee: contract.storage_fee_usd,
    transferSize: contract.transfer_gb,
    transferFee: contract.transfer_fee_usd,
    periodStart: contract.period_start,
    periodEnd: contract.period_end,
    total: contract.total_period_amount_usd,
  };
}

export function mapArchiveInvoice(
  contract: FilesBillingContracts.ArchiveInvoice,
): FilesBilling.ArchiveInvoice {
  return {
    id: contract.invoice_id,
    number: contract.invoice_number,
    ready: contract.available_for_download,
    date: contract.issued_at,
    storageSize: contract.storage_gb,
    storageFee: contract.storage_fee_usd,
    transferSize: contract.transfer_gb,
    transferFee: contract.transfer_fee_usd,
    total: contract.total_amount_usd,
  };
}

export function mapHistory(contract: FilesBillingContracts.History): FilesBilling.History {
  return {
    history: contract.history.map(mapArchiveInvoice),
    upcoming: contract.upcoming_info ? mapInvoice(contract.upcoming_info) : undefined,
  };
}
