import React from 'react';
import {isNil} from 'app/util/isNil';
import {CompactMaintenance, NavigationMaintenance} from 'app/components/features/maintenance';
import {Sx} from 'app/types/common';
import {Maintenance} from 'app/components/entities/maintenance';

interface Props extends Sx {
  compact: boolean;
  instance: Maintenance.Entity;
  onExpand: () => void;
}

export function MaintenanceWidget({sx, compact, instance, onExpand}: Props) {
  if (isNil(instance)) {
    return null;
  }

  if (compact) {
    return <CompactMaintenance sx={sx} maintenance={instance} onExpand={onExpand} />;
  }

  return <NavigationMaintenance sx={sx} maintenance={instance} onExpand={onExpand} />;
}
