import {useMemo} from 'react';
import {Schedule} from 'app/domain/schedule';
import {isNil} from 'app/util/isNil';
import {stringComparator} from 'app/util/Sort';

export function useGetEventsDevices(events?: Map<string, Schedule.Event>): string[] {
  return useMemo(() => {
    if (isNil(events)) {
      return [];
    }

    return [...new Set(gatherDeviceIds(events))].sort(stringComparator);
  }, [events]);
}

function gatherDeviceIds(map: Map<string, Schedule.Event>): string[] {
  return Array.from(map.values()).reduce<string[]>((acc, e) => [...acc, ...e.devices.keys()], []);
}
