import React from 'react';
import {Stack, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {backgrounds, palette} from 'app/themes/app';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {Callback, Component} from 'app/types/common';
import {formatPrice} from 'app/util/prices';
import {TierCard} from 'app/components/BillingManager/dialogs/shared/TierCard/TierCard';
import {Price} from 'app/components/BillingManager/dialogs/shared/Price/Price';
import {TierPrice} from 'app/components/BillingManager/dialogs/shared/TierPrice/TierPrice';
import {PremiumSubtitle} from 'app/components/BillingManager/dialogs/shared/PremiumSubtitle/PremiumSubtitle';
import {ConnectArgs} from 'app/components/BillingManager/types/types';

interface Props extends Component {
  tier: App.Billing.Connect.Tier;
  current: boolean;
  hasCustomer: boolean;
  custom: boolean;
  resumable: boolean;
  onSelectTier: Callback<any, [ConnectArgs]>;
  onEditPayment: Callback;
}

export default function ConnectTier({
  dataId,
  tier,
  current,
  hasCustomer,
  custom,
  resumable,
  onSelectTier,
  onEditPayment,
}: Props) {
  const renderAction = () => {
    if (!hasCustomer) {
      return (
        <Button
          dataId="tier-action"
          variant={VARIANT.SOLID}
          theme={THEME.PRIMARY}
          onClick={onEditPayment}
        >
          Add billing details
        </Button>
      );
    }

    if (custom) {
      return (
        <Button
          dataId="tier-action"
          variant={VARIANT.OUTLINE}
          theme={THEME.SUCCESS}
          href="https://www.epiphan.com/company/contact-us/"
          target="_blank"
        >
          Contact us to switch
        </Button>
      );
    }

    if (current) {
      if (resumable) {
        return (
          <Button
            dataId="tier-action"
            variant={VARIANT.SOLID}
            theme={THEME.PRIMARY}
            onClick={() => onSelectTier({action: 'resume', tier})}
          >
            Resume subscription
          </Button>
        );
      }

      return (
        <Button
          dataId="tier-action"
          variant={VARIANT.OUTLINE}
          theme={THEME.INFO}
          onClick={() => onSelectTier({action: 'unsubscribe'})}
        >
          Stop subscription
        </Button>
      );
    }

    return (
      <Button
        dataId="tier-action"
        variant={VARIANT.SOLID}
        theme={THEME.PRIMARY}
        onClick={() => onSelectTier({action: 'subscribe', tier})}
      >
        Subscribe
      </Button>
    );
  };

  const renderPrice = () => {
    const {count, dimension} = tier.interval;

    const text =
      count > 1 ? (
        <>
          every{' '}
          <Typography data-id="interval-count" component="span" variant="h5" fontWeight={600}>
            {count}
          </Typography>
        </>
      ) : (
        '/'
      );

    return (
      <Price
        dataId="fee-value"
        value={`$${formatPrice(tier.subscriptionFee)}`}
        message={
          <>
            {text} <span data-id="interval-size">{pluralizeN(dimension, count)}</span>
          </>
        }
      />
    );
  };

  return (
    <TierCard
      dataId={dataId}
      sx={{bgcolor: current ? backgrounds.blue : backgrounds.gray}}
      title={tier.name}
      subtitle={
        <PremiumSubtitle
          data-id="tier-subtitle"
          current={current}
          timeUnit={tier.interval.dimension}
        />
      }
      description={
        <Stack gap={1} alignItems="center">
          <TierPrice title="Subscription fee" value={renderPrice()} />

          <AddIcon sx={{color: palette.darkerGray}} />

          <TierPrice
            title="Usage fee"
            value={
              <Price
                dataId="hour-rate-value"
                value={`$${formatPrice(tier.hourlyRate)}`}
                message="/ meeting hour"
              />
            }
          />
        </Stack>
      }
      action={renderAction()}
    />
  );
}
