import {SxProps, Theme} from '@mui/material';
import {grey} from '@mui/material/colors';

export const scrollbarSx: SxProps<Theme> = (t) => ({
  'html ::-webkit-scrollbar': {
    width: t.spacing(1),
    height: t.spacing(1),
  },
  'html ::-webkit-scrollbar-track': {
    bgcolor: 'transparent',
  },
  'html ::-webkit-scrollbar-thumb': {
    borderRadius: 4,
    bgcolor: grey[300],
  },
  'html ::-webkit-scrollbar-thumb:hover': {
    borderRadius: 4,
    bgcolor: grey[500],
  },
});
