import React, {Fragment} from 'react';
import classNames from 'classnames';
import {TableHeaderCell} from 'app/components/Dashboard/Devices/TableView/TableHeaderCell';
import {Callback, ClassName} from 'app/types/common';
import {TableColumnType} from 'app/components/sharedReactComponents/Table/types';
import {SORT_DIRECTION} from 'app/constants';

interface Props extends ClassName {
  sortDirection: SORT_DIRECTION;
  cells: TableColumnType[];
  sortBy: string;
  renderAsTable: boolean;
  onSort: Callback;
}

const TableHeader: React.VFC<Props> = ({
  className,
  cells,
  sortBy,
  sortDirection,
  renderAsTable,
  onSort,
}) => {
  const HeaderComponent = renderAsTable ? THeadComponent : 'div';

  return (
    <Fragment>
      {renderAsTable && (
        <colgroup>
          {cells.map(cell => (
            <col
              key={cell.dataKey}
              style={{
                width: cell.width,
              }}
            />
          ))}
        </colgroup>
      )}

      <HeaderComponent className={classNames('dashboard-table__header', className)}>
        {cells.map(cell => {
          const label = cell.headerRenderer ? cell.headerRenderer({sortBy, sortDirection}) : cell.label;

          return (
            <TableHeaderCell
              key={cell.dataKey}
              dataKey={cell.dataKey}
              disableSort={cell.disableSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              width={cell.width!}
              flexGrow={cell.flexGrow}
              renderAsTable={renderAsTable}
              onSort={onSort}
            >
              {label}
            </TableHeaderCell>
          );
        })}
      </HeaderComponent>
    </Fragment>
  );
};

const THeadComponent: React.FC<ClassName> = ({
  className,
  children,
}) => {
  return (
    <thead className={className}>
      <tr>
        {children}
      </tr>
    </thead>
  );
};

export {
  TableHeader,
};
