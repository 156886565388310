import React, {useEffect, useState} from 'react';
import {Checkbox, CheckboxProps, Popover} from '@mui/material';
import {UpgradeToEdgePremium} from 'app/components/sharedReactComponents/UpgradeToEdgePremium/UpgradeToEdgePremium';

type Props = CheckboxProps & {
  premium: boolean;
  billingAccess: boolean;
};

export function EdgeCheckbox({sx, premium, billingAccess, onClick, ...props}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (premium) {
      setAnchorEl(null);
    }
  }, [premium]);

  return (
    <>
      <Checkbox
        {...props}
        onClick={(e) => {
          if (!premium) {
            setAnchorEl(e.currentTarget);
            return;
          }

          onClick?.(e);
        }}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        slotProps={{paper: {sx: {p: 2}}}}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        onClose={() => setAnchorEl(null)}
      >
        <UpgradeToEdgePremium billingAccess={billingAccess} />
      </Popover>
    </>
  );
}
