import React, {FormEvent} from 'react';
import {capitalize} from '@mui/material';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {Callback} from 'app/types/common';
import {Button, SubmitButton} from 'app/components/sharedReactComponents/Button';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {FORM_CONTROL_AUTOCOMPLETE} from 'app/constants/formControlAutocomplete';
import {isNil} from 'app/util/isNil';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Icons} from 'app/util/icons';
import {PaymentSection} from 'app/components/sharedReactComponents/PaymentForm/PaymentSection';
import {CouponState} from 'app/components/BillingManager/hooks/useCoupon';
import {Coupon, IbbCoupon} from 'app/components/BillingManager/Coupon';

interface Props {
  hasConnect: boolean;
  coupon: CouponState;
  setCoupon: Callback<void, [value: string]>;
  validateCoupon: Callback<Promise<void>, [value: string]>;
}

export const PaymentFormCoupon = ({hasConnect, coupon, setCoupon, validateCoupon}: Props) => {
  const handleChangeCouponCode = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = value.trim();
    setCoupon(currentValue);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await validateCoupon(coupon.value);
  };

  const handleClickClear = () => {
    setCoupon('');
  };

  const renderNote = (coupon: Coupon) => {
    if (coupon instanceof IbbCoupon) {
      return <>This voucher assumes billing based on invoices.</>;
    }

    if (!hasConnect && coupon.isConnect) {
      return (
        <>
          By activating this Epiphan Connect coupon you will be subscribed to Epiphan Connect Pro
          plan.
        </>
      );
    }

    return <>The voucher will apply to your next invoice.</>;
  };

  const notValidatedCoupon =
    coupon.value.length > 0 && isNil(coupon.instance) && !coupon.invalid && !coupon.checking;

  return (
    <PaymentSection
      className="payment-form-coupon"
      data-id="payment_info_coupon_form"
      title="Voucher"
    >
      <form className="payment-form-coupon__content" action="" onSubmit={handleSubmit}>
        <FormControl
          dataId="voucher-input"
          className="payment-form-coupon__input"
          label="If you have a voucher code, enter it here"
          value={coupon.value}
          name="coupon"
          autoComplete={FORM_CONTROL_AUTOCOMPLETE.OFF}
          error={coupon.invalid}
          loading={coupon.checking}
          readOnly={!isNil(coupon.instance)}
          onChange={handleChangeCouponCode}
        />

        {coupon.instance || coupon.invalid ? (
          <Button
            dataId="clear_coupon_button"
            theme={THEME.INFO}
            variant={VARIANT.OUTLINE}
            onClick={handleClickClear}
          >
            Clear
          </Button>
        ) : (
          <SubmitButton
            dataId="validate_coupon_button"
            theme={THEME.INFO}
            variant={VARIANT.OUTLINE}
            disabled={coupon.value === '' || coupon.checking}
          >
            Validate
          </SubmitButton>
        )}

        {coupon.invalid && (
          <div
            className="payment-form-coupon__error-message"
            data-id="coupon_validation_error_message"
          >
            Validation failed. Make sure the voucher is correct.
          </div>
        )}

        {notValidatedCoupon && (
          <div className="payment-form-coupon__error-message">
            To apply voucher it must be validated
          </div>
        )}
      </form>

      {coupon.instance && (
        <div className="payment-form-coupon__description" data-id="coupon_description">
          <img
            className="payment-form-coupon__icon"
            src="/assets/img/billing-icons/coupon-ready-to-apply.svg"
            alt=""
          />

          <div className="payment-form-coupon__value" data-id="coupon_value_note">
            Voucher value for <strong>{capitalize(coupon.instance.domain)}</strong> is{' '}
            <strong>{coupon.instance.formatted}</strong>{' '}
            {coupon.instance.duration && (
              <Tooltip content={coupon.instance.duration}>
                {Icons.info().theme(THEME.PRIMARY).size(SIZE.S).reactComponent()}
              </Tooltip>
            )}
          </div>

          <div className="payment-form-coupon__note" data-id="coupon_note">
            {renderNote(coupon.instance)}
          </div>
        </div>
      )}
    </PaymentSection>
  );
};
