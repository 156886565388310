import React from 'react';
import {Box, Divider, Stack, Typography} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import {palette} from 'app/themes/app';
import {Sx} from 'app/types/common';
import {LocalRecordGuide} from 'app/components/pages/MeetingDetails/MeetingLayout/LocalRecordGuide/LocalRecordGuide';

interface Props extends Sx {
  id?: string;
}

export function LocalRecordIssue({sx, id}: Props) {
  return (
    <Box sx={sx}>
      <Stack sx={{mb: 1}} direction="row" alignItems="center" gap={1}>
        <ErrorIcon sx={{color: palette.orange3}} />
        <Typography sx={{fontSize: '20px'}}>
          Host needs to allow Epiphan Connect to record local files
        </Typography>
      </Stack>

      <Box sx={{fontSize: '16px', color: palette.gray2, mb: 1.5}}>
        Connect has joined the Zoom meeting but the meeting host needs to follow the instructions
        below.
      </Box>

      {id && (
        <Typography mb={3} fontSize={12} color="#a1a7af">
          Meeting ID {id}
        </Typography>
      )}

      <Divider sx={{mb: 4}} />

      <LocalRecordGuide variant="local" />
    </Box>
  );
}
