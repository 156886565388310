import React, {useState} from 'react';
import {Box, Button, Stack, Typography} from '@mui/material';
import {useFirmwareQuery} from 'app/components/features/edge/hooks/useFirmwareQuery';
import {Sx} from 'app/types/common';
import {UpgradeFirmwareDialog} from 'app/components/features/edge/dialogs/UpgradeFirmwareDialog/UpgradeFirmwareDialog';

interface Props extends Sx {
  deviceId: string;
  onUpgrade: () => Promise<void>;
}

export function FirmwareState({sx, deviceId, onUpgrade}: Props) {
  const [dialog, setDialog] = useState(false);
  const {data, isSuccess, refetch} = useFirmwareQuery({deviceId, enabled: true});

  const handleUpgrade = async () => {
    await onUpgrade();

    await refetch();
  };

  const renderContent = () => {
    if (!isSuccess) {
      return <Typography>Checking for updates</Typography>;
    }

    if (!data?.update) {
      return <Typography color="text.disabled">No updates available</Typography>;
    }

    const {status} = data.update;

    if (status === 'downloading' || status === 'progress') {
      return (
        <Typography data-id="upgrade-status" color="text.secondary">
          Upgrading firmware
        </Typography>
      );
    }

    if (status === 'available' && data.available) {
      return (
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography>
            New version <strong data-id="new-available-version">{data.available}</strong> is
            available
          </Typography>

          <Button
            data-id="upgrade-btn"
            variant="outlined"
            size="small"
            onClick={() => setDialog(true)}
          >
            Upgrade
          </Button>

          <UpgradeFirmwareDialog
            open={dialog}
            onUpgrade={handleUpgrade}
            onClose={() => setDialog(false)}
          />
        </Stack>
      );
    }
  };

  return <Box sx={sx}>{renderContent()}</Box>;
}
