import React from 'react';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Icons} from 'app/util/icons';
import {THEME} from 'app/constants';
import {Callback, ClassName} from 'app/types/common';

function isValid(formData, validators) {
  for (const fieldName in validators) {
    if (!Object.prototype.hasOwnProperty.call(validators, fieldName)) {
      continue;
    }

    for (const validator of validators[fieldName]) {
      if (validator.validate(formData[fieldName]) === false) {
        return {
          valid: false,
          errorMessage: validator.getErrorMessage()
        };
      }
    }
  }

  return {
    valid: true,
    errorMessage: null
  };
}

export interface FormData {
  name: string;
  touched: boolean;
  valid: boolean;
  errorMessage: string;
}

interface Props extends ClassName {
  formData: FormData;
  inputClassName: string;
  validators: any;
  onChange: Callback;
}

export const Form: React.FC<Props> = ({
  className,
  inputClassName,
  formData,
  validators,
  onChange,
}) => {
  const handleChange = ({target: {name, value}}) => {
    const newFormData = {
      ...formData,
      [name]: value,
    };

    const {valid, errorMessage} = isValid(newFormData, validators);

    onChange({
      ...newFormData,
      touched: true,
      valid,
      errorMessage,
    });
  };

  const handleBlur = () => {
    onChange({
      ...formData,
      touched: true
    });
  };

  return (
    <div className={className}>
      <FormControl
        className={inputClassName}
        name="name"
        value={formData.name}
        label="Group name"
        placeholder="Name"
        error={!formData.valid && formData.touched}
        endAdornment={formData.errorMessage && (
          <Tooltip content={formData.errorMessage}>
            {Icons.warning().theme(THEME.DANGER).reactComponent()}
          </Tooltip>
        )}
        fullWidth={true}
        autoFocus={true}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};
