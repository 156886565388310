import {useQuery} from '@tanstack/react-query';
import {BillingApiService} from 'app/services/api/billing/BillingApiService';
import {BillingMapper} from 'app/services/mappers/BillingMapper';
import {secondsToMilliseconds} from 'app/util/timeConverter';

function mapConnectPlan(c?: Contracts.Billing.Connect.Plan): App.Billing.Connect.Plan | undefined {
  if (!c) {
    return;
  }

  return BillingMapper.mapConnectPlan(c);
}

export function useConnectPlan(teamId: string, enabled: boolean) {
  return useQuery({
    queryKey: ['connect-plan', teamId],
    queryFn: () => BillingApiService.getConnectPlan(teamId),
    refetchInterval: secondsToMilliseconds(30),
    enabled,
    select: mapConnectPlan,
  });
}
