import React from 'react';
import {Divider, Stack} from '@mui/material';
import {Sx} from 'app/types/common';
import {ArchiveHeader, ArchiveInvoice, FilesBilling} from 'app/components/entities/billing/files';

interface Props extends Sx {
  invoices: FilesBilling.ArchiveInvoice[];
  teamId: string;
}

export function InvoicesArchive({sx, invoices, teamId}: Props) {
  return (
    <Stack divider={<Divider orientation="horizontal" />} sx={sx}>
      <ArchiveHeader />

      {invoices.map((invoice) => {
        return (
          <ArchiveInvoice
            dataId={`file-invoice-${invoice.id}`}
            key={invoice.id}
            invoice={invoice}
            teamId={teamId}
          />
        );
      })}
    </Stack>
  );
}
