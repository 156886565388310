import React from 'react';
import {Card, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {formatFullDate, formatUptime} from 'app/util/time';

interface Props extends Sx {
  uptime: number;
}

export function UptimePanel({sx, uptime}: Props) {
  return (
    <Card sx={packSx({p: 2}, sx)} variant="outlined">
      <Typography variant="h6" color={grey[600]} mb={0.5}>
        Uptime
      </Typography>

      <Typography data-id="device_performance_uptime" fontSize={24}>
        <Tooltip content={`Since: ${formatFullDate(uptime)}`} delay={250}>
          {formatUptime(uptime)}
        </Tooltip>
      </Typography>
    </Card>
  );
}
