import React, {
  forwardRef,
  KeyboardEvent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useRef,
} from 'react';
import classNames from 'classnames';
import {KeyboardCode, SIZE, THEME, VARIANT} from 'app/constants';
import {WAI_ARIA_ROLE} from 'app/constants/waiAriaRole';
import {useComponentDidMount} from 'app/hooks/useComponentDidMount';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {isNil} from 'app/util/isNil';
import {Callback, ClassName, DataId} from 'app/types/common';

export enum BUTTON_TYPE {
  SUBMIT = 'submit',
  BUTTON = 'button',
}

const defaultProps = {
  type: BUTTON_TYPE.BUTTON,
  tabIndex: 0,
};

interface Props extends ClassName, DataId {
  theme?: THEME;
  variant?: VARIANT;
  size?: SIZE;
  name?: string;
  type?: BUTTON_TYPE;
  role?: WAI_ARIA_ROLE;
  title?: ReactNode;
  titlePreformatted?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  buttonRef?: any;
  href?: string;
  target?: string;
  tabIndex?: number;
  onClick?: Callback;
  onMouseEnter?: Callback;
  onMouseOut?: Callback;
}

const ButtonComponent: React.FC<Props> = ({
  className,
  theme,
  variant,
  size,
  children,
  name,
  type = BUTTON_TYPE.BUTTON,
  role,
  title,
  titlePreformatted,
  autoFocus,
  disabled,
  buttonRef,
  href,
  target,
  tabIndex = 0,
  dataId,
  onClick,
  onMouseEnter,
  onMouseOut,
  ...other
}) => {
  const bRef = useRef<any>();

  useComponentDidMount(() => {
    if (autoFocus === true) {
      bRef.current?.focus();
    }
  });

  const setRef = useCallback(
    (ref) => {
      bRef.current = ref;

      if (buttonRef) {
        if (typeof buttonRef === 'function') {
          buttonRef(ref);
        } else {
          buttonRef.current = ref;
        }
      }
    },
    [buttonRef],
  );

  const isButton = isNil(href);
  const Component = isButton ? 'button' : 'a';
  let props = {};

  if (isButton) {
    props = {
      ...props,
      name,
      type,
      disabled,
    };
  } else {
    props = {
      ...props,
      href,
      target,
      'aria-disabled': disabled,
    };
  }

  const onKeyDown = (event: KeyboardEvent) => {
    if (!isButton && event.code === KeyboardCode.Space) {
      event.preventDefault();
      window.location.href = href;
    }
  };

  const {
    // @ts-expect-error
    fullWidth,
    // @ts-expect-error
    opened,
    ...elementProps
  } = other;

  return (
    <Tooltip content={title} delay={[500, 50]} preformatted={titlePreformatted}>
      <Component
        ref={setRef}
        className={classNames(
          'cr-btn',
          {
            'cr-btn--disabled': disabled,
            'cr-btn-unstyled': Boolean(theme) === false && Boolean(variant) === false,
            [`cr-btn--theme-${theme}`]: Boolean(theme),
            [`cr-btn--variant-${variant}`]: Boolean(variant),
            [`cr-btn--size-${size}`]: Boolean(size),
          },
          className,
        )}
        {...props}
        role={role}
        tabIndex={disabled ? -1 : tabIndex}
        data-id={dataId}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
        onKeyDown={onKeyDown}
        {...elementProps}
      >
        {children}
      </Component>
    </Tooltip>
  );
};

export const Button = forwardRef<any, PropsWithChildren<Props>>((props, ref) => {
  return <ButtonComponent {...defaultProps} {...props} buttonRef={ref} />;
});

Button.displayName = 'Button';

export const SubmitButton = (props) => <Button type={BUTTON_TYPE.SUBMIT} {...props} />;
