import React from 'react';
import classNames from 'classnames';
import {Button} from 'app/components/sharedReactComponents/Button';
import {
  THEME,
  VARIANT,
  SIZE,
} from 'app/constants';
import {Icons} from 'app/util/icons';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  loading: boolean;
  disabled: boolean;
  onClick: Callback;
}

export const FirmwareUpdateButton: React.FC<Props> = ({
  className,
  children,
  loading,
  disabled,
  onClick,
  ...elementProps
}) => {
  return (
    <Button
      className={classNames('device-firmware-status-update-button', {
        'device-firmware-status-update-button--loading': loading,
      }, className)}
      theme={THEME.PRIMARY}
      variant={VARIANT.SOLID}
      size={SIZE.S}
      disabled={disabled}
      onClick={onClick}
      {...elementProps}
    >
      {loading && (
        Icons.spinner().class('device-firmware-status-update-button__spinner').size(SIZE.S).reactComponent()
      )}

      {children}
    </Button>
  );
};
