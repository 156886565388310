import {BaseStreamingService} from 'app/components/StreamingServices/BaseStreamingService';
import {CreateRTMPStreamingDestinationPrompt} from 'app/components/libs/dialogs/CreateRTMPStreamingDestinationPrompt';
import {RTMPStreamingDestinationModel} from 'app/components/StreamingServices/StreamingDestinations/RTMPStreamingDestinationModel';
import {STREAMING_SERVICE_TYPE} from 'app/components/StreamingServices/constants';
import {StreamingDestinationOptions} from 'app/components/StreamingServices/StreamingDestinations/types';

class RTMPStreamingService extends BaseStreamingService {
  protected type = STREAMING_SERVICE_TYPE.RTMP;
  protected name = 'RTMP';

  async createService(): Promise<string> {
    return new Promise((resolve) => {
      CreateRTMPStreamingDestinationPrompt.show({
        onDone: resolve,
        // onClose: reject,
      });
    });
  }

  createStreamingDestinationModel(json: StreamingDestinationOptions) {
    return new RTMPStreamingDestinationModel(json);
  }

  isAvailableToCreate() {
    return true;
  }
}

export {
  RTMPStreamingService,
};
