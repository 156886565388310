import {
  computed,
  action,
  extendObservable,
  makeObservable,
} from 'mobx';

class ValidatedInputModel {
  name: string;
  value: unknown;
  required: boolean;

  constructor(name: string, value: unknown, required = false) {
    this.name = name;
    this.required = required;

    makeObservable(this, {
      setFromEvent: action.bound,
      isValid: computed,
      json: computed
    });

    extendObservable(this, {
      value,
    });
  }

  setFromEvent(e) {
    this.value = this._getInputValue(e.target);
  }

  _getInputValue(inputElement) {
    switch (inputElement.type) {
      case 'checkbox':
        return inputElement.checked;
      case 'number':
        return inputElement.value === '' ? null : parseInt(inputElement.value, 10);
      default:
        return inputElement.value;
    }
  }

  get isValid(): boolean {
    return this.required ? Boolean(this.value) : true;
  }

  get json(): any {
    return {
      [this.name]: this.value
    };
  }
}

export {ValidatedInputModel};
