import {useMemo} from 'react';
import {TranscribingLP} from 'app/components/DeviceDetails/TranscriberDevice/Model/TranscribingLP';

interface Args {
  deviceId: string;
  onUpdate: (data: any) => any;
}

export function useTranscribingLP({deviceId, onUpdate}: Args) {
  return useMemo(
    () =>
      new TranscribingLP({
        deviceId,
        onUpdate: (response) => {
          onUpdate(response.data.transcript);
        },
      }),
    [deviceId, onUpdate],
  );
}
