import {Hardware, HardwareContract} from '../model';

export function mapCapability(contract: HardwareContract.Capability): Hardware.Capability {
  switch (contract) {
    case 'remote_login':
      return 'remote-login';

    case 'state_teaminfo':
      return 'state-team-info';

    case 'audio_levels':
      return 'audio-levels';

    case 'source_previews':
      return 'source-previews';

    case 'channel_previews':
      return 'channel-previews';

    case 'wsapi':
      return 'ws-api';

    case 'wsstates':
      return 'ws-states';

    case 'state_recording':
      return 'state-recording';

    case 'state_streaming':
      return 'state-streaming';

    case 'state_uploading':
      return 'state-uploading';

    case 'state_scheduling':
      return 'state-scheduling';

    case 'has_action_button1':
      return 'display-button';

    case 'state_transcribing':
      return 'state-transcribing';

    case 'configurable_srt':
      return 'configurable-srt';

    case 'epiphan_cms':
      return 'edge-cms';

    case 'state_transcribing_conf':
      return 'state-transcribing-conf';

    case 'file_management':
      return 'files';

    case 'state_channels':
      return 'state-channels';

    case 'nolp':
      return 'no-lp';

    case 'network_monitor':
      return 'network-monitor';

    default:
      return contract;
  }
}
