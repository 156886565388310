import React from 'react';
import {Box, Link, SxProps, Typography} from '@mui/material';
import {Callback} from 'app/types/common';
import {palette} from 'app/themes/app';
import {ConnectForm} from 'app/components/dialogs/TeamsConnectDialog/TeamsConnect/ConnectToTeamsCall/ConnectForm/ConnectForm';
import {TenantMessage} from 'app/components/dialogs/TeamsConnectDialog/TeamsConnect/ConnectToTeamsCall/TenantMessage/TenantMessage';
import {router} from 'app/router/main';

interface Props {
  sx?: SxProps;
  hasTenants: boolean;
  canPair: boolean;
  onConnect: React.ComponentProps<typeof ConnectForm>['onConnect'];
  onCreate: Callback;
  onPair: Callback;
}

export function ConnectToTeamsCall({sx, hasTenants, canPair, onCreate, onConnect, onPair}: Props) {
  const renderMessage = () => {
    if (hasTenants) {
      return (
        <>
          You can only connect to an existing meeting from your paired Microsoft Organizations.{' '}
          <Link
            sx={{color: 'inherit', textDecorationColor: 'currentcolor'}}
            href={router.url('connectAccountSettings')}
          >
            View paired organizations.
          </Link>
        </>
      );
    }

    return <>You need to have a paired Microsoft organization to connect to an existing meeting.</>;
  };

  return (
    <Box sx={sx}>
      <Box
        mb={2}
        component="img"
        src="/assets/img/logo/teams-logo.png"
        width={173}
        height={28}
        srcSet="/assets/img/logo/teams-logo@2x.png 2x"
        alt=""
      />

      <Typography variant="h5" fontWeight={600}>
        Connect to an existing Teams meeting
      </Typography>

      <Box sx={{color: palette.darkerGray, mt: 1, mb: 5}}>{renderMessage()}</Box>

      {hasTenants ? (
        <ConnectForm onConnect={onConnect} />
      ) : (
        <TenantMessage canPair={canPair} onCreate={onCreate} onPair={onPair} />
      )}
    </Box>
  );
}
