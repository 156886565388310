import {Box, Typography} from '@mui/material';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {palette} from 'app/themes/app';
import {Callback} from 'app/types/common';
import React from 'react';

interface Props {
  canPair: boolean;
  onPair: Callback;
  onCreate: Callback;
}

export function TenantMessage({canPair, onCreate, onPair}: Props) {
  return (
    <Box sx={{borderRadius: 2, bgcolor: '#f3f3f3', px: 5, py: 6}}>
      <Typography variant="h6" fontWeight={600}>
        You don't have a Microsoft organization paired yet.
      </Typography>

      <Box mb={9}>
        {canPair ? (
          <>
            <Box sx={{color: palette.darkerGray, mb: 2}}>
              Your Microsoft Teams account administrator will need to authorize Epiphan Connect to
              join meetings.
            </Box>

            <Button
              dataId="pair_tenant_button"
              theme={THEME.SUCCESS}
              variant={VARIANT.SOLID}
              onClick={onPair}
            >
              Pair Microsoft Organization
            </Button>
          </>
        ) : (
          <>
            <Box sx={{color: palette.darkerGray}}>
              Please contact the Epiphan Cloud team owner or admin for configuration.
            </Box>
          </>
        )}
      </Box>

      <Typography sx={{fontWeight: 'bold', fontSize: '16px', mb: 0.5}}>
        Create a meeting without pairing.
      </Typography>

      <Box sx={{color: palette.darkerGray, mb: 2}}>
        You can also create a meeting without pairing your Microsoft Teams organization.
      </Box>

      <Button
        dataId="create_call_button"
        theme={THEME.SUCCESS}
        variant={VARIANT.OUTLINE}
        onClick={onCreate}
      >
        Create a meeting
      </Button>
    </Box>
  );
}
