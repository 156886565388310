import React from 'react';
import {Stack, Typography} from '@mui/material';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import {Sx} from 'app/types/common';

type Props = Sx & {};

export function NoRecordings({sx}: Props) {
  return (
    <Stack
      data-id="no-recordings-card"
      sx={sx}
      direction="row"
      gap={1}
      justifyContent="center"
      alignItems="center"
    >
      <VideoFileIcon color="info" fontSize="large" />

      <Typography variant="h6" color="text.disabled" fontWeight={600}>
        No uploaded files
      </Typography>
    </Stack>
  );
}
