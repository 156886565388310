import React, {useState} from 'react';
import classNames from 'classnames';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {Callback, Component} from 'app/types/common';
import {
  SrtEntityChangeCallback,
  SrtEntitySelector,
} from 'app/components/pages/MeetingDetails/StreamSettings/StreamConnectionSettings/CloudSrtConnection/SrtEntitySelector/SrtEntitySelector';
import {StreamSetting} from 'app/components/pages/MeetingDetails/StreamSettings/common/StreamSetting/StreamSetting';
import {SrtSourceSelector} from 'app/components/pages/MeetingDetails/StreamSettings/StreamConnectionSettings/CloudSrtConnection/SrtSourceSelector/SrtSourceSelector';
import {Box, SelectChangeEvent, Stack, Typography} from '@mui/material';
import {SrtSettingsCallback} from 'app/components/pages/MeetingDetails/StreamSettings/types';
import {router} from 'app/router/main';
import {palette} from 'app/themes/app';

interface Props extends Component, SrtSettingsCallback {
  config: App.Connect.CloudConnection;
  entities: App.Connect.CloudEntity[];
  sources: App.Connect.EntitySource[];
  errors?: App.Connect.ValidationErrors;
  isLoadingSources: boolean;
  supportUnify: boolean;
  setConfig: Callback<void, [Partial<App.Connect.CloudConnection>]>;
  onEntityChange: Callback<Promise<void>, [entity: App.Connect.CloudEntity | null]>;
}

export const CloudSrtConnection = ({
  className,
  dataId,
  config,
  entities,
  sources,
  isLoadingSources,
  supportUnify,
  errors,
  setConfig,
  onSync,
  onEntityChange,
}: Props) => {
  const [entity, setEntity] = useState<App.Connect.CloudEntity | null>(() => {
    if (config.deviceId) {
      const found = entities.find((e) => e.id === config.deviceId);
      return found ?? null;
    }

    return null;
  });
  const [sourceId, setSourceId] = useState(config.sourceId);

  const handleEntityChange: SrtEntityChangeCallback = async (...args) => {
    const [, value] = args;
    const deviceId = value?.id ?? '';

    setEntity(value ?? null);
    setSourceId('');

    setConfig({
      sourceId: '',
      sourceName: '',
      deviceId,
      deviceName: value?.name ?? '',
    });

    await onEntityChange(value);
  };

  const handleSourceChange = async (e: SelectChangeEvent<string>) => {
    const selectedId = e.target.value;
    setSourceId(e.target.value);
    const selectedSource = sources.find((s) => s.id === selectedId);

    if (selectedSource) {
      setConfig({sourceId: selectedSource.id, sourceName: selectedSource.name});
    } else {
      setConfig({sourceId: '', sourceName: ''});
    }
    await onSync();
  };

  const handlePairDevice = () => {
    router.go('welcome');
  };

  if (entities.length === 0) {
    return (
      <Stack data-id={dataId} gap={3} alignItems="start">
        <Box>
          <Box sx={{mb: 1}}>Epiphan devices and Unify projects are offline or not paired.</Box>

          <Box sx={{fontSize: '12px', color: palette.gray}}>
            Turn on or pair your Epiphan devices. Start or create your Unify projects.
          </Box>
        </Box>

        <Button
          dataId="cloud_srt_pair_device"
          variant={VARIANT.OUTLINE}
          theme={THEME.SUCCESS}
          href={router.url('welcome')}
          onClick={handlePairDevice}
        >
          Pair a new Epiphan device
        </Button>

        {supportUnify && (
          <Button
            dataId="cloud_srt_pair_unify_pair"
            variant={VARIANT.OUTLINE}
            theme={THEME.SUCCESS}
            href={router.url('projects')}
          >
            Open Unify
          </Button>
        )}
      </Stack>
    );
  }

  return (
    <div className={classNames('cloud-srt-connection', className)} data-id={dataId}>
      <Typography mb={2}>Send this feed to an Epiphan device or Unify project.</Typography>

      <StreamSetting className="cloud-srt-connection__item" label="Epiphan device or Unify project">
        <SrtEntitySelector
          dataId="entity_selector"
          value={entity}
          error={errors?.DeviceId}
          entities={entities}
          onChange={handleEntityChange}
        />
      </StreamSetting>

      <StreamSetting className="cloud-srt-connection__item" label="SRT input">
        <SrtSourceSelector
          dataId="source_selector"
          value={sourceId}
          error={errors?.SourceId}
          loading={isLoadingSources}
          sources={sources}
          entity={entity?.type}
          onChange={handleSourceChange}
        />
      </StreamSetting>
    </div>
  );
};
