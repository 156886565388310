import {useCallback} from 'react';
import {Schedule} from 'app/domain/schedule';
import {
  getCurrentPeriod,
  getNextPeriod,
  getPreviousPeriod,
} from 'app/components/sharedReactComponents/Events/utils';

type Return = {
  goNext: () => void;
  goPrevious: () => void;
  goCurrent: () => void;
};

export function usePeriodActions(
  setPeriod: React.Dispatch<React.SetStateAction<Schedule.Period>>,
): Return {
  const goNext = useCallback(() => {
    setPeriod((prev) => getNextPeriod(prev));
  }, [setPeriod]);

  const goPrevious = useCallback(() => {
    setPeriod((prev) => getPreviousPeriod(prev));
  }, [setPeriod]);

  const goCurrent = useCallback(() => {
    setPeriod(getCurrentPeriod());
  }, [setPeriod]);

  return {goPrevious, goNext, goCurrent};
}
