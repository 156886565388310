import React from 'react';
import {useInView} from 'react-intersection-observer';
import classNames from 'classnames';
import {SelectCheckbox} from 'app/components/FleetManager/DeviceCard/SelectCheckbox';
import {ActionButtons} from 'app/components/sharedReactComponents/ActionButtons/ActionButtons';
import {StackHorizontal} from 'app/components/sharedReactComponents/Stack';
import {ActionButtonsSeparator} from 'app/components/sharedReactComponents/ActionButtons/ActionButtonsSeparator';
import {DeviceAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/DeviceAudioMeter';
import {isDisabledAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/utils';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {Tooltip, TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';
import {UpgradeToProTooltip} from 'app/components/FleetManager/UpgradeToProTooltip';
import {THEME} from 'app/constants';
import {ClassName, Callback} from 'app/types/common';
import {Thumbnail} from 'app/components/sharedReactComponents/Preview/Thumbnail/Thumbnail';
import {getPreviewUpdateStrategy} from 'app/components/sharedReactComponents/Preview/utils';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';

interface Props extends ClassName {
  selectable?: boolean;
  selected: boolean;
  preview?: React.ReactNode;
  name?: React.ReactNode;
  detailsUrl?: string;
  actions?: React.ReactNode;
  device: AnyDeviceModelType;
  masterDevice?: AnyDeviceModelType;
  hasEvent?: boolean;
  streamingDestinations: AnyStreamingDestinationModelType[];
  withThumbnail?: boolean;
  withAudioMeter?: boolean;
  currentUserRole: AnyUserRoleModel;
  getDeviceById?: (id: string) => AnyDeviceModelType | undefined;
  onSelect?: Callback;
  onChangeStreamingDestination?: Callback;
}

export const Container: React.FC<Props> = React.memo(
  ({
    className,
    selectable,
    selected,
    preview,
    name,
    detailsUrl,
    withThumbnail,
    withAudioMeter,
    device,
    masterDevice,
    streamingDestinations,
    actions,
    currentUserRole,
    hasEvent,
    getDeviceById,
    onSelect,
    onChangeStreamingDestination,
  }) => {
    const {ref, inView} = useInView();
    const {batch} = device.capabilities;

    return (
      <div
        className={classNames(
          'device-card-child-container',
          {
            'device-card-child-container--selectable': selectable,
            'device-card-child-container--selected': selected,
          },
          className,
        )}
        data-id="device_card_child"
      >
        {selectable && (
          <div className="device-card-child-container__select">
            <Tooltip
              content={
                !batch ? (
                  <UpgradeToProTooltip canEdit={currentUserRole.canEditBilling()} />
                ) : undefined
              }
              interactive={true}
              trigger="click"
              placement={TOOLTIP_PLACEMENT.BOTTOM}
              theme={THEME.WHITE}
            >
              <SelectCheckbox
                data-id="device_card_child_checkbox"
                selected={selected}
                disabled={!batch}
                onChange={onSelect}
              />
            </Tooltip>
          </div>
        )}

        <div className="device-card-child-container__content">
          <div ref={ref} className="device-card-child-container__preview">
            {inView && (
              <>
                {withThumbnail && (
                  <Thumbnail
                    id={device.getId() ?? masterDevice?.getId()}
                    detailsUrl={detailsUrl}
                    updateStrategy={getPreviewUpdateStrategy(masterDevice ?? device)}
                  />
                )}

                {withAudioMeter && (
                  <DeviceAudioMeter
                    className="device-card-child-container__audio"
                    id={device.getId() ?? masterDevice?.getId()}
                    disabled={isDisabledAudioMeter(masterDevice ?? device)}
                    segmentsCount={13}
                    segmentsGap={2}
                    vertical={true}
                  />
                )}

                {preview}
              </>
            )}
          </div>

          {name && (
            <div className="device-card-child-container__name">
              {detailsUrl ? (
                <a
                  className="device-card-child-container__link"
                  href={detailsUrl}
                  data-id="device_card_child_name"
                >
                  <OverflowTooltip rowsNumber={1}>{name}</OverflowTooltip>
                </a>
              ) : (
                name
              )}
            </div>
          )}

          <div className="device-card-child-container__actions">
            <StackHorizontal separatorComponent={<ActionButtonsSeparator />}>
              <ActionButtons
                device={device}
                hasEvent={hasEvent}
                editable={currentUserRole.canEditStreamingDestinations()}
                // rework !-operator
                streamingDestinations={streamingDestinations!}
                getDeviceById={getDeviceById!}
                onChangeStreamingDestination={onChangeStreamingDestination!}
              />

              {actions}
            </StackHorizontal>
          </div>
        </div>
      </div>
    );
  },
);
