import {TimeStampSeconds} from 'app/types/common';
import {isPassedTimestamp} from 'app/util/time';

export const calcTimeToTimestamp = (
  timestamp: TimeStampSeconds,
): [value: number, isOver: boolean] => {
  if (isPassedTimestamp(timestamp)) {
    return [Number.MIN_VALUE, true];
  }

  return [timestamp, false];
};

export const calcTime = (
  timestamp: TimeStampSeconds
): [value: number, isOver: boolean] => {
  return [timestamp, false];
};
