import React, {ReactNode} from 'react';
import {
  Modal,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalTitle,
} from 'app/components/sharedReactComponents/Modal/Modal';
import {Button} from 'app/components/sharedReactComponents/Button';
import {
  THEME,
  VARIANT,
} from 'app/constants';
import {Callback} from 'app/types/common';

export const ErrorModal = {
  show({
    title,
    message,
    onClose,
  }: {
    title?: ReactNode;
    message: ReactNode;
    onClose?: Callback;
  }) {
    Modal.show({
      children: (
        <ModalContainer>
          <ModalTitle>
            {title}
          </ModalTitle>

          <ModalBody>
            {message}
          </ModalBody>

          <ModalFooter>
            <Button
              dataId="modal_confirm_button"
              theme={THEME.PRIMARY}
              variant={VARIANT.SOLID}
              autoFocus={true}
              onClick={Modal.hide}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContainer>
      ),
      onClose,
    });
  }
};
