import React, {useState} from 'react';
import {useLocalStorage} from 'react-use';
import {Box, Button, CircularProgress, Divider, Stack, TextField, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import SearchIcon from '@mui/icons-material/Search';
import {Sx} from 'app/types/common';
import {RefreshButton} from 'app/components/sharedReactComponents/Events/shared/RefreshButton/RefreshButton';
import {ViewSwitcher} from 'app/components/sharedReactComponents/Events/shared/ViewSwitcher/ViewSwitcher';
import {DeviceEvents} from 'app/components/sharedReactComponents/Events/List/DeviceEvents/DeviceEvents';
import {Calendar} from 'app/components/sharedReactComponents/Events/Calendar/Calendar';
import {EventEditor} from 'app/components/sharedReactComponents/Events/Editor/EventEditor/EventEditor';
import {isNil} from 'app/util/isNil';
import {NoDeviceIntegration} from 'app/components/sharedReactComponents/Events/shared/messages/NoDeviceIntegration/NoDeviceIntegration';
import {Schedule} from 'app/domain/schedule';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {formatCmsName} from 'app/components/sharedReactComponents/Events/utils';
import {useEventEditor} from 'app/components/sharedReactComponents/Events/hooks/useEventEditor';
import {ConnectChronoDialog} from 'app/components/sharedReactComponents/Events/dialogs/ConnectChronoDialog/ConnectChronoDialog';
import {DisconnectChronoDialog} from 'app/components/sharedReactComponents/Events/dialogs/DisconnectChronoDialog/DisconnectChronoDialog';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {ScheduleApiService} from 'app/services/api/schedule/ScheduleApiService';
import {Notifications} from 'app/components/Notifications';
import {UpdateMode} from 'app/components/sharedReactComponents/Events/Editor/EventForm/types';
import {ScheduleView} from 'app/components/sharedReactComponents/Events/types';
import {TOOLTIP_PLACEMENT, Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {useScheduleCategories} from 'app/components/sharedReactComponents/Events/hooks/useScheduleCategories';
import {CategoryCount} from 'app/components/sharedReactComponents/Events/Calendar/shared/CategoryCount/CategoryCount';
import {
  filterGroups,
  useScheduleFilters,
} from 'app/components/sharedReactComponents/Events/hooks/useScheduleFilters';
import {FilterSelector} from 'app/components/sharedReactComponents/FilterSelector';
import {ActiveFilters} from 'app/components/sharedReactComponents/ActiveFilters/ActiveFilters';
import {PeriodSwitcher} from 'app/components/sharedReactComponents/Events/shared/PeriodSwitcher/PeriodSwitcher';
import {FilterMessage} from 'app/components/sharedReactComponents/FilterMessage/FilterMessage';
import {NoEvents} from 'app/components/sharedReactComponents/Events/shared/messages/NoEvents/NoEvents';

interface Props extends Sx {
  teamId: string;
  userId: string;
  fetching: boolean;
  device: PearlMasterDeviceModel;
  devices?: Map<string, PearlMasterDeviceModel>;
  events: Schedule.Event[];
  cms?: Schedule.Cms;
  period: Schedule.Period;
  actualEvent?: Schedule.Event;
  setPeriod: React.Dispatch<React.SetStateAction<Schedule.Period>>;
}

export function DeviceSchedule({
  sx,
  teamId,
  userId,
  device,
  events,
  period,
  devices,
  cms,
  fetching,
  actualEvent,
  setPeriod,
}: Props) {
  const deviceId = device.getId();
  const [view = 'list', setView] = useLocalStorage<ScheduleView>(
    `DeviceSchedule.${teamId}.${userId}.${deviceId}`,
    'list',
  );

  const {eventToEdit, editEvent, createEvent, closeEditor} = useEventEditor();

  const [connectDialog, setConnectDialog] = useState(false);
  const [disconnectDialog, setDisconnectDialog] = useState(false);

  const {
    filtered,
    activeSet,
    activeFilters,
    filters,
    search,
    clearFilters,
    setSearch,
    toggleFilter,
  } = useScheduleFilters({
    teamId,
    userId,
    events,
    devices,
    storeKey: `${teamId}.Device.${deviceId}`,
  });

  const integrated = !isNil(cms);
  const hasEvents = events.length > 0;

  const supportEdge = device.capabilities.epiphanCms;

  const offline = device.isOffline();

  const isEditorOpen = !isNil(eventToEdit);
  const isEdge = cms === 'chrono';

  const {ongoing, scheduled, completed} = useScheduleCategories(filtered);

  const handleRefresh = async () => {
    try {
      await ScheduleApiService.refreshDeviceSchedule(deviceId);
      Notifications.addSuccessNotification('Refresh is initiated. Schedule will be updated soon');
    } catch {
      Notifications.addErrorNotification('Could not send refresh command');
    }
  };

  const handleConnectToChrono = async () => {
    await DeviceApiService.setDeviceCms(device.getId(), 'chrono');
    setConnectDialog(false);
  };

  const handleDisconnectChrono = async () => {
    await DeviceApiService.setDeviceCms(device.getId(), '');
    setDisconnectDialog(false);
  };

  const handleStart = async (id: string) => {
    try {
      await ScheduleApiService.startEvent(id);
    } catch (e: unknown) {
      Notifications.addErrorNotification('Failed to start an event');
      throw e;
    }
  };

  const handleStop = async (id: string) => {
    try {
      await ScheduleApiService.stopEvent(id);
    } catch (e: unknown) {
      Notifications.addErrorNotification('Failed to stop an event');
      throw e;
    }
  };

  const handlePause = async (id: string) => {
    try {
      await ScheduleApiService.pauseEvent(id);
    } catch (e: unknown) {
      Notifications.addErrorNotification('Failed to pause an event');
      throw e;
    }
  };

  const handleResume = async (id: string) => {
    try {
      await ScheduleApiService.resumeEvent(id);
    } catch (e: unknown) {
      Notifications.addErrorNotification('Failed to resume an event');
      throw e;
    }
  };

  const handleExtend = async (id: string) => {
    try {
      await ScheduleApiService.extendEvent(id);
    } catch (e: unknown) {
      Notifications.addErrorNotification('Failed to extend an event');
      throw e;
    }
  };

  const handleDelete = async (id: string, mode: UpdateMode) => {
    try {
      await ScheduleApiService.deleteEvent(id, mode);
    } catch (e: unknown) {
      Notifications.addErrorNotification('Failed to delete an event');
      throw e;
    }
  };

  const handleConfirm = async (id: string, deviceId: string) => {
    try {
      await ScheduleApiService.confirmEvent(id, deviceId);
    } catch (e: unknown) {
      Notifications.addErrorNotification('Failed to confirm an event');
      throw e;
    }
  };

  const renderContent = () => {
    if (!integrated) {
      return (
        <NoDeviceIntegration
          edge={supportEdge}
          offline={offline}
          onConnect={() => setConnectDialog(true)}
        />
      );
    }

    const noFiltered = activeSet.size > 0 || search.length > 0 ? filtered.length === 0 : false;

    if (noFiltered) {
      const handleClear = () => {
        setSearch('');
        clearFilters();
      };

      return (
        <FilterMessage
          sx={{flex: 1}}
          label="Reset"
          message="No filtered events"
          onClick={handleClear}
        />
      );
    }

    if (!hasEvents) {
      return <NoEvents period={period} />;
    }

    if (view === 'calendar') {
      return <Calendar events={filtered} devices={devices} period={period} />;
    }

    const actualEventIds = new Set<string>(actualEvent ? [actualEvent.id] : []);

    return (
      <DeviceEvents
        ongoing={ongoing}
        scheduled={scheduled}
        completed={completed}
        devices={devices}
        actualEventIds={actualEventIds}
        onEdit={editEvent}
        onStart={handleStart}
        onStop={handleStop}
        onExtend={handleExtend}
        onPause={handlePause}
        onResume={handleResume}
        onDelete={handleDelete}
        onConfirm={handleConfirm}
      />
    );
  };

  return (
    <Stack sx={sx}>
      <Box mb={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="h5" fontWeight="bold" alignSelf="flex-start">
            Events
          </Typography>

          {integrated && (
            <Stack ml="auto" direction="row" alignItems="center" gap={1}>
              <TextField
                sx={{width: 240}}
                value={search}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  placeholder: 'Search',
                }}
                inputProps={{'data-id': 'search-input'}}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(event.target.value);
                }}
              />

              {isEdge && (
                <>
                  <Button
                    data-id="create-event-btn"
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    disableRipple={false}
                    onClick={createEvent}
                  >
                    Add event
                  </Button>

                  <EventEditor
                    teamId={teamId}
                    open={isEditorOpen}
                    eventId={eventToEdit}
                    allowSelectDevices={false}
                    device={device}
                    onClose={closeEditor}
                  />
                </>
              )}

              <FilterSelector
                filterSwitches={filters}
                filterSwitchGroups={filterGroups}
                activeFilterSwitches={activeSet}
                placement={TOOLTIP_PLACEMENT.BOTTOM_END}
                onClickFilterSwitcher={toggleFilter}
              />

              <ViewSwitcher view={view} onChange={setView} />
            </Stack>
          )}
        </Stack>

        {integrated && (
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            divider={<Divider sx={{height: 16}} orientation="vertical" />}
          >
            <PeriodSwitcher period={period} disabled={fetching} setPeriod={setPeriod} />

            {hasEvents && (
              <Stack direction="row" alignItems="center" gap={1}>
                <CategoryCount
                  dataId="period-stats"
                  ongoing={ongoing.length}
                  scheduled={scheduled.length}
                  completed={completed.length}
                />
              </Stack>
            )}

            <Stack direction="row" alignItems="center" gap={1}>
              <Typography data-id="cms-name">{formatCmsName(cms)}</Typography>

              {isEdge ? (
                <Tooltip content={offline ? 'Device is offline' : undefined}>
                  <span>
                    <Button
                      data-id="disconnect-cms"
                      variant="text"
                      color="primary"
                      startIcon={<LinkOffIcon />}
                      disableRipple={false}
                      disabled={offline}
                      onClick={() => setDisconnectDialog(true)}
                    >
                      Disconnect
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <RefreshButton onClick={handleRefresh} />
              )}

              {fetching && <CircularProgress sx={{ml: 'auto'}} size={14} variant="indeterminate" />}
            </Stack>
          </Stack>
        )}

        {integrated && (
          <ActiveFilters
            sx={{mt: 0.5}}
            filters={activeFilters}
            onRemove={toggleFilter}
            onRemoveAll={clearFilters}
          />
        )}
      </Box>

      {renderContent()}

      {supportEdge && (
        <ConnectChronoDialog
          open={connectDialog}
          onClose={() => setConnectDialog(false)}
          onConnect={handleConnectToChrono}
        />
      )}

      {isEdge && (
        <DisconnectChronoDialog
          open={disconnectDialog}
          onDisconnect={handleDisconnectChrono}
          onClose={() => setDisconnectDialog(false)}
        />
      )}
    </Stack>
  );
}
