import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Button, Divider, Stack, Typography} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {debounce} from 'throttle-debounce';
import {ALL_ALERT_TYPES} from 'app/components/Settings/Alerts/AlertSettings/constants';
import {isAlertDisabled} from 'app/components/Settings/Alerts/AlertSettings/utils';
import {FilterSelector} from 'app/components/sharedReactComponents/FilterSelector';
import {filterItemsByFilterSwitches} from 'app/components/sharedReactComponents/FilterSelector/utils';
import {TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';
import {Sx} from 'app/types/common';
import {AlertsTable} from 'app/components/Settings/Alerts/AlertSettings/AlertTable/AlertsTable';
import {
  filterSwitchGroups,
  useFilterSwitches,
} from 'app/components/Settings/Alerts/AlertSettings/hooks/useFilterSwitches';
import {useEdgeGroups} from 'app/components/features/edge';
import {
  Alerts,
  AlertsApi,
  AlertsMapper,
  useAlertGroupsQuery,
  useAlertSettingsQuery,
} from 'app/components/entities/alerts';
import {createStringComparator} from 'app/util/Sort';

interface Props extends Sx {
  teamId: string;
  groupsAccess: boolean;
  userEmail: string;
}

export function AlertSettings({sx, teamId, groupsAccess, userEmail}: Props) {
  const queryClient = useQueryClient();

  const [settings, setSettings] = useState<Alerts.Settings[]>([]);

  const {groups} = useEdgeGroups({teamId, enabled: groupsAccess});

  const {filterSwitches, activeFilterSwitches, toggleFilterSwitcher, clearFilterSwitches} =
    useFilterSwitches({deviceGroups: groups});

  const settingsQuery = useAlertSettingsQuery();
  const groupsQuery = useAlertGroupsQuery();

  useEffect(() => {
    setSettings((prev) =>
      (settingsQuery.data ?? prev).sort(createStringComparator((s) => s.deviceName)),
    );
  }, [settingsQuery.data]);

  const filteredSettings = useMemo<Alerts.Settings[]>(() => {
    if (activeFilterSwitches.size > 0) {
      const activeFilters = filterSwitches.current.filter((filterSwitch) =>
        activeFilterSwitches.has(filterSwitch.id),
      );

      return filterItemsByFilterSwitches(settings, activeFilters);
    }

    return settings;
  }, [activeFilterSwitches, filterSwitches, settings]);

  const getGroupByType = (type: Alerts.AlertType) => groupsQuery.data?.get(type);

  const mutation = useMutation({
    mutationFn: async (settings: Alerts.Settings[]) => {
      const contract = settings.map(AlertsMapper.toConfig);
      await AlertsApi.updateSettings(contract);
    },

    onError: async () => {
      await queryClient.invalidateQueries({queryKey: ['alerts']});
    },
  });

  const handleDebouncedSave = useCallback(
    debounce(1000, async (settings: Alerts.Settings[]) => {
      mutation.mutate(settings);
    }),
    [],
  );

  const handleSave = (settings: Alerts.Settings[]) => {
    setSettings(settings);
    handleDebouncedSave(settings);
  };

  const updateSetting = (id: string, type: Alerts.AlertType, value: boolean) => {
    const newDeviceAlerts = settings.map((setting) =>
      setting.id === id ? {...setting, alerts: {...setting.alerts, [type]: value}} : setting,
    );

    handleSave(newDeviceAlerts);
  };

  const toggleAlertForAllDevices = (type: Alerts.AlertType, value: boolean) => {
    const filteredDeviceAlertIds = collectAlerts(filteredSettings);

    const newDeviceAlerts = settings.map((setting) => {
      const alerts = {...setting.alerts};

      if (filteredDeviceAlertIds.has(setting.id)) {
        alerts[type] = value && !isAlertDisabled(setting, type);
      }

      return {...setting, alerts};
    });

    handleSave(newDeviceAlerts);
  };

  const toggleAll = (value: boolean) => {
    const filteredDeviceAlertIds = collectAlerts(filteredSettings);

    const newDeviceAlerts = settings.map<Alerts.Settings>((setting) => {
      const updated = setting.alerts;

      if (filteredDeviceAlertIds.has(setting.id)) {
        ALL_ALERT_TYPES.forEach((alertType) => {
          updated[alertType] = value && !isAlertDisabled(setting, alertType);
        });
      }

      return {...setting, alerts: updated};
    });

    handleSave(newDeviceAlerts);
  };

  const selectAll = () => {
    void toggleAll(true);
  };

  const unselectAll = () => {
    void toggleAll(false);
  };

  const description =
    'Alerts are device specific. You can select the conditions that raise an alert in Epiphan Cloud'.concat(
      groupsAccess ? ' and trigger a notification email.' : '.',
    );

  return (
    <Box sx={sx}>
      <Typography variant="h6" fontWeight={600} mb={1}>
        Device alerts
      </Typography>

      <Typography>{description}</Typography>

      {/* //! no team capability that determines notifications */}
      {groupsAccess && userEmail && (
        <Typography>{`Alerts will be sent to ${userEmail}`}</Typography>
      )}

      <Stack mt={2} direction="row" alignItems="center">
        {groupsAccess && (
          <FilterSelector
            filterSwitches={filterSwitches.current}
            filterSwitchGroups={filterSwitchGroups}
            activeFilterSwitches={activeFilterSwitches}
            placement={TOOLTIP_PLACEMENT.BOTTOM_START}
            onClickFilterSwitcher={toggleFilterSwitcher}
          />
        )}

        <Stack
          ml="auto"
          direction="row"
          alignItems="center"
          gap={1}
          divider={<Divider orientation="vertical" flexItem={true} />}
        >
          <Button variant="text" size="small" onClick={selectAll}>
            Select all
          </Button>

          <Button variant="text" size="small" onClick={unselectAll}>
            Unselect all
          </Button>
        </Stack>
      </Stack>

      <AlertsTable
        sx={{mt: 1}}
        settings={filteredSettings}
        emptyFilterResults={filteredSettings.length === 0 && activeFilterSwitches.size > 0}
        loading={settingsQuery.isInitialLoading || groupsQuery.isInitialLoading}
        onChange={updateSetting}
        onToggleAlert={toggleAlertForAllDevices}
        getAlertGroup={getGroupByType}
        onClearFilter={clearFilterSwitches}
      />
    </Box>
  );
}

function collectAlerts(filtered: Alerts.Settings[]): Set<string> {
  return new Set(filtered.map(({id}) => id));
}
