import {useMemo} from 'react';
import {isNil} from 'app/util/isNil';

type Tracker = 'appcues' | 'hubspot';

type Trackers = {
  [K in Tracker]: boolean;
};

export function useTrackers(): Trackers {
  return useMemo<Trackers>(() => {
    if (isNil(CIRRUS_TRACKERS) || typeof CIRRUS_TRACKERS !== 'string') {
      return {appcues: false, hubspot: false};
    }

    const list = CIRRUS_TRACKERS.split(',');
    const appcues = (list as Tracker[]).includes('appcues');
    const hubspot = (list as Tracker[]).includes('hubspot');

    return {appcues, hubspot};
  }, []);
}
