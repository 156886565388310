import {useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {useProfileQuery} from 'app/components/entities/profile';
import {Ws} from 'app/contracts/ws';
import {TeamUser} from 'app/models/User/TeamUser';
import {CloudMapper} from 'app/util/mappers/CloudMapper/CloudMapper';

export function useProfile(enabled: boolean) {
  const client = useQueryClient();
  const {data} = useProfileQuery(enabled);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const onUserUpdate = (message: Ws.UserChange) => {
      client.setQueryData<TeamUser>(['profile'], (old) => {
        return old ? CloudMapper.mapApplicationUser(message.Body.User) : old;
      });
    };

    WS.on(EVENT_TYPE.USER_CHANGE, onUserUpdate);

    return () => {
      WS.off(EVENT_TYPE.USER_CHANGE, onUserUpdate);
    };
  }, [enabled, client]);

  return data;
}
