import {useEffect, useState} from 'react';
import {DeviceIdentifier, parseMasterAndChannelIds} from 'app/components/DeviceDetails/utils';

export function useDeviceId(id: string): DeviceIdentifier {
  const [pair, setPair] = useState<DeviceIdentifier>(() => parseMasterAndChannelIds(id));

  useEffect(() => {
    setPair(parseMasterAndChannelIds(id));
  }, [id]);

  return pair;
}
