import {Edge} from 'app/domain/edge';
import {
  DEVICE_PUBLISHER_SETTING_ID,
  DEVICE_PUBLISHER_STATE,
} from 'app/models/Device/Publisher/constants';
import {Callback} from 'app/types/common';

/**
 * @param {Object} setting
 * @param {*} setting.value
 * @param {*} defaultValue
 * @return {*}
 */
function getSettingOrDefault(setting, defaultValue) {
  return setting ? setting.value : defaultValue;
}

export interface PublisherOptions {
  startStreamingAction: Callback;
  stopStreamingAction: Callback;
}

export class DevicePublisherModel {
  #data: Edge.Publisher;
  #startStreamingAction: Callback;
  #stopStreamingAction: Callback;

  constructor(json: Edge.Publisher, {startStreamingAction, stopStreamingAction}: PublisherOptions) {
    this.#data = json;
    this.#startStreamingAction = startStreamingAction;
    this.#stopStreamingAction = stopStreamingAction;
  }

  getId() {
    return this.#data.id;
  }

  getType() {
    return this.#data.type;
  }

  getName(): string {
    return getSettingOrDefault(this.#getSettingById(DEVICE_PUBLISHER_SETTING_ID.NAME), '');
  }

  isSelected() {
    return getSettingOrDefault(
      this.#getSettingById(DEVICE_PUBLISHER_SETTING_ID.SINGLE_TOUCH),
      false,
    );
  }

  isStreaming() {
    return this.isStarted() && this.#getState() === DEVICE_PUBLISHER_STATE.STARTED;
  }

  isStarted() {
    return this.#getStatus()?.started === true;
  }

  isFailed() {
    return this.#getState() === DEVICE_PUBLISHER_STATE.ERROR;
  }

  getStartTime() {
    if (this.isStreaming() === false) {
      return null;
    }

    return this.#getStatus()?.since ?? null;
  }

  getErrorMessage() {
    if (this.isFailed()) {
      return this.#getStatus()?.description ?? '';
    }

    return '';
  }

  startStreaming() {
    return this.#startStreamingAction(this.getId());
  }

  stopStreaming() {
    return this.#stopStreamingAction(this.getId());
  }

  #getStatus = (): Edge.PublisherStatus | undefined => {
    return this.#data.status;
  };

  #getState = () => {
    return this.#getStatus()?.state ?? null;
  };

  #getSettings = () => {
    return this.#data.settings || [];
  };

  /**
   * TODO: Refactor to Map
   * @param {string} id
   * @return {*|null}
   */
  #getSettingById = (id) => {
    return this.#getSettings().find((setting) => setting.id === id) ?? null;
  };
}
