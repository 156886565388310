import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {DataId, Sx} from 'app/types/common';
import {Edge} from 'app/domain/edge';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {DeviceAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/DeviceAudioMeter';
import {isDisabledAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/utils';

interface Props extends Sx, DataId {
  device: PearlMasterDeviceModel;
  input: Edge.AudioSource;
}

export function AudioInput({sx, dataId, device, input}: Props) {
  return (
    <Stack data-id={dataId} sx={sx} p={3} height={166} bgcolor={grey[100]} borderRadius={1}>
      <Typography component="span" fontWeight={600}>
        <OverflowTooltip dataId="audio_input_name">{input.name}</OverflowTooltip>
      </Typography>

      <Box sx={{'& .device-audio-meter': {width: 324, height: 54}}} mt="auto">
        <DeviceAudioMeter
          id={device.getId()}
          disabled={isDisabledAudioMeter(device)}
          source={input.id}
          segmentsCount={22}
          segmentsGap={6}
          mode="stereo"
        />
      </Box>
    </Stack>
  );
}
