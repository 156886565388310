import React, {Component} from 'react';
import classNames from 'classnames';
import {SettingsList} from 'app/components/sharedReactComponents/SettingsList';
import {DROPDOWN_SELECT_THEME} from 'app/components/sharedReactComponents/DropdownSelect/constants';
import {SETTINGS_LIST_THEME} from 'app/components/sharedReactComponents/SettingsList/constants';
import {Callback, ClassName} from 'app/types/common';
import {Setting, SettingDevices} from 'app/components/sharedReactComponents/SettingsList/types';

interface Props extends ClassName {
  setting: Setting;
  theme: SETTINGS_LIST_THEME;
  selectTheme?: DROPDOWN_SELECT_THEME;
  disabled: boolean;
  devices?: SettingDevices;
  updateSettingAction: Callback;

  getSettingName: Callback;
  getSettingLabel: Callback;
  getSettingValue: Callback;
  getSettingTitle: Callback;
  getSettingDescription: Callback;
  getSettingType: Callback;
  getSettingTypeOptions: Callback;
  getSettingGroup: Callback;
}
export class SettingsListGroup extends Component<Props> {
  updateSettingAction = (id, value) => {
    const {
      updateSettingAction,
    } = this.props;

    return updateSettingAction(id, value);
  };

  render() {
    const {
      className,
      setting,
      theme,
      selectTheme,
      disabled,
      devices,

      getSettingName,
      getSettingLabel,
      getSettingValue,
      getSettingTitle,
      getSettingDescription,
      getSettingType,
      getSettingTypeOptions,
      getSettingGroup,
    } = this.props;

    return (
      <div className={classNames('settings-list__group', className)}>
        <div className="settings-list__group-title">
          {getSettingLabel(setting)}
        </div>

        <SettingsList
          className="settings-list__group-list"
          settings={getSettingGroup(setting)}
          theme={theme}
          selectTheme={selectTheme}
          disabled={disabled}
          devices={devices}
          updateSettingAction={this.updateSettingAction}
          getSettingName={getSettingName}
          getSettingLabel={getSettingLabel}
          getSettingValue={getSettingValue}
          getSettingTitle={getSettingTitle}
          getSettingDescription={getSettingDescription}
          getSettingType={getSettingType}
          getSettingTypeOptions={getSettingTypeOptions}
          getSettingGroup={getSettingGroup}
        />
      </div>
    );
  }
}
