import {Alerts} from 'app/components/entities/alerts';

export const ALL_ALERT_TYPES: Alerts.AlertType[] = [
  'alert_device_offline',
  'alert_storage',
  'alert_source_no_signal',
  'alert_no_signal',
  'alert_device_health',
  'alert_framerate',
  'alert_publisher_bandwidth',
  'alert_afu',
  'alert_system_load',
  'alert_publishers',
  'alert_no_storage',
  'alert_cms',
];

export const LVS_TYPES: Alerts.AlertType[] = ['alert_device_offline', 'alert_device_health'];
