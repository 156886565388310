import React from 'react';
import {Typography} from '@mui/material';
import {DataId, StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';

type Variant = 'processing' | 'running' | 'error' | 'info' | 'disabled';

function getRootSx(variant: Variant): StyleSx {
  return {
    color: getColor(variant),
  };
}

function getColor(variant: Variant): string {
  if (variant === 'running') return 'secondary.main';

  if (variant === 'error') return 'error.main';

  if (variant === 'processing') return 'primary.main';

  if (variant === 'info') return 'info.main';

  return 'text.disabled';
}

interface Props extends DataId, Sx {
  variant: Variant;
  value: string;
}

export const UnitStatus = React.forwardRef<HTMLElement, Props>(
  ({sx, value, dataId, variant}, ref) => {
    return (
      <Typography ref={ref} data-id={dataId} fontWeight={600} sx={packSx(getRootSx(variant), sx)}>
        {value}
      </Typography>
    );
  },
);
