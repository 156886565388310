import {isNil} from 'app/util/isNil';

interface Args {
  customer?: App.Billing.Customer;
  card?: App.Billing.CreditCard;
  paymentMethod?: App.Billing.PaymentMethod;
}

export class Payment {
  private customer?: App.Billing.Customer;

  card?: App.Billing.CreditCard;
  method?: App.Billing.PaymentMethod;

  constructor({customer, paymentMethod, card}: Args) {
    this.customer = customer;
    this.method = paymentMethod;
    this.card = card;
  }

  get balance() {
    return this.customer?.balance ?? 0;
  }

  get hasMethod() {
    return !isNil(this.method);
  }

  get hasCard() {
    return !isNil(this.card);
  }

  get hasCustomer() {
    return !isNil(this.customer);
  }

  get invoicePayment() {
    return this.method === 'invoice';
  }

  get pendingInvoicePayment() {
    return this.method === 'pending invoice';
  }

  get ccPayment() {
    return this.method === 'credit card';
  }
}
