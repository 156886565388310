import React from 'react';
import {Alert, AlertTitle, Button, Snackbar, SnackbarProps, Typography} from '@mui/material';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import {Maintenance, formatMaintenanceTimestamp} from 'app/components/entities/maintenance';
import {StyleSx} from 'app/types/common';

const rootSx: StyleSx = {width: '100%', boxShadow: (t) => t.shadows[3]};
const alertTitle: StyleSx = {fontWeight: 600};

interface Props extends Pick<SnackbarProps, 'open' | 'anchorOrigin'> {
  maintenance: Maintenance.Entity;
  onMinimize: () => void;
}

export function MaintenanceSnackbar({open, anchorOrigin, maintenance, onMinimize}: Props) {
  return (
    <Snackbar open={open} anchorOrigin={anchorOrigin}>
      <Alert
        sx={rootSx}
        severity="warning"
        icon={<BuildCircleIcon />}
        action={
          <Button color="warning" variant="outlined" size="small" onClick={onMinimize}>
            Minimize
          </Button>
        }
      >
        <AlertTitle sx={alertTitle}>
          {maintenance.summary} {formatMaintenanceTimestamp(maintenance.start)}
        </AlertTitle>

        <Typography>{maintenance.description}</Typography>
      </Alert>
    </Snackbar>
  );
}
