import React from 'react';
import classNames from 'classnames';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {Callback, ClassName, DataId} from 'app/types/common';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Icons, SvgIcon} from 'app/util/icons';

type InputType = 'video' | 'audio';

interface Props extends ClassName, DataId {
  disabled?: boolean;
  onAction: Callback;
}

interface MuteButtonProps extends Props {
  loading?: boolean;
  muted: boolean;
}

interface StreamMuteButtonProps extends MuteButtonProps {
  type: InputType;
}

const getIcon = (type: InputType, muted: boolean): SvgIcon => {
  const isVideo = type === 'video';

  if (muted) {
    return isVideo ? Icons.videoOff() : Icons.microOff();
  }

  return isVideo ? Icons.videoOn() : Icons.microOn();
};

const StreamMuteButton = ({
  className,
  type,
  muted,
  dataId,
  disabled = false,
  loading,
  onAction,
}: StreamMuteButtonProps) => {
  const icon = getIcon(type, muted);

  return (
    <ProgressButton
      className={classNames('meeting-stream-btn', className)}
      size={SIZE.S}
      variant={muted ? VARIANT.SOLID : VARIANT.OUTLINE}
      theme={muted ? THEME.DANGER : THEME.SUCCESS}
      dataId={dataId}
      disabled={disabled}
      loading={loading}
      onClick={onAction}
    >
      {icon.reactComponent()}
    </ProgressButton>
  );
};

const VideoMuteButton = ({
  className,
  muted,
  dataId,
  disabled,
  loading,
  onAction,
}: MuteButtonProps) => (
  <StreamMuteButton
    className={className}
    type="video"
    muted={muted}
    dataId={dataId}
    disabled={disabled}
    loading={loading}
    onAction={onAction}
  />
);

const AudioMuteButton = ({
  className,
  muted,
  dataId,
  disabled = false,
  loading,
  onAction,
}: MuteButtonProps) => (
  <StreamMuteButton
    className={className}
    type="audio"
    muted={muted}
    dataId={dataId}
    disabled={disabled}
    loading={loading}
    onAction={onAction}
  />
);

const StreamSettingsButton = ({className, dataId, disabled = false, onAction}: Props) => {
  return (
    <Button
      className={classNames('meeting-stream-btn', className)}
      size={SIZE.S}
      variant={VARIANT.OUTLINE}
      theme={THEME.SUCCESS}
      dataId={dataId}
      disabled={disabled}
      onClick={onAction}
    >
      {Icons.options().reactComponent()}
    </Button>
  );
};

const StreamStopButton = ({className, dataId, disabled = false, onAction}: Props) => {
  return (
    <>
      <ProgressButton
        className={classNames('meeting-stream-start-stop-btn', className)}
        size={SIZE.S}
        variant={VARIANT.SOLID}
        theme={THEME.SUCCESS}
        dataId={dataId}
        disabled={disabled}
        onClick={onAction}
      >
        {Icons.stop().label('Stop').reactComponent()}
      </ProgressButton>
    </>
  );
};

const StreamStartButton = ({className, dataId, disabled = false, onAction}: Props) => {
  return (
    <>
      <ProgressButton
        className={classNames('meeting-stream-start-stop-btn', className)}
        size={SIZE.S}
        variant={VARIANT.SOLID}
        theme={THEME.PRIMARY}
        dataId={dataId}
        disabled={disabled}
        onClick={onAction}
      >
        {Icons.play().label('Start').reactComponent()}
      </ProgressButton>
    </>
  );
};

export {
  VideoMuteButton,
  AudioMuteButton,
  StreamSettingsButton,
  StreamStartButton,
  StreamStopButton,
};
