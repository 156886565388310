import React from 'react';
import dayjs from 'dayjs';
import {Box, Card, Divider, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {StyleSx, Sx} from 'app/types/common';
import {FilesBilling} from 'app/components/entities/billing/files';
import {packSx} from 'app/util/packSx/packSx';
import {formatPrice} from 'app/components/shared';

const rootSx: StyleSx = {p: 5, bgcolor: grey[50]};

interface Props extends Sx {
  invoice: FilesBilling.Invoice;
}

export function FilesInvoice({sx, invoice}: Props) {
  return (
    <Card data-id="files-invoice" sx={packSx(rootSx, sx)} variant="outlined">
      <Stack mb={1} direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" fontWeight={600}>
          Upcoming invoice
        </Typography>

        <Typography data-id="invoice-period" color="text.secondary">
          {dayjs.unix(invoice.periodStart).format('ll')} -{' '}
          {dayjs.unix(invoice.periodEnd).format('ll')}
        </Typography>
      </Stack>

      <Typography data-id="invoice-date" mb={2} color="primary.main">
        Will be issued on: {dayjs.unix(invoice.date).format('LL')}
      </Typography>

      <Stack mb={1} gap={2} divider={<Divider flexItem orientation="horizontal" />}>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography fontSize={16}>Storage fee</Typography>

            <Typography>
              <b>{invoice.storageSize.toLocaleString(undefined, {maximumFractionDigits: 2})} GB</b>{' '}
              of storage used
            </Typography>
          </Box>

          <Typography data-id="storage-price" fontSize={16} fontWeight={600}>
            ${formatPrice(invoice.storageFee)}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography fontSize={16}>Transfer fee</Typography>

            <Typography>
              <b>{invoice.transferSize.toLocaleString(undefined, {maximumFractionDigits: 2})} GB</b>{' '}
              transferred
            </Typography>
          </Box>

          <Typography data-id="transfer-price" fontSize={16} fontWeight={600}>
            ${formatPrice(invoice.transferFee)}
          </Typography>
        </Stack>
      </Stack>

      <Typography
        data-id="invoice-total"
        fontSize={16}
        color="primary.main"
        textAlign="right"
        fontWeight={600}
      >
        Total period amount: ${formatPrice(invoice.total)}
      </Typography>
    </Card>
  );
}
