export interface StoreProps {
  defaultValue: any;
  storeKey: string;
}

class Store {
  protected defaultValue: any;
  protected storeKey: string;

  constructor({
    defaultValue,
    storeKey,
  }: StoreProps) {
    this.defaultValue = defaultValue;
    this.storeKey = storeKey;
  }

  load<T = any>(): T {
    const savedData = window.localStorage.getItem(this.storeKey);

    if (savedData) {
      try {
        return JSON.parse(savedData);
      } catch (_: unknown) {
      }
    }

    return this.defaultValue;
  }

  save<T = any>(data: T) {
    window.localStorage.setItem(this.storeKey, JSON.stringify(data));
  }
}

export {
  Store,
};
