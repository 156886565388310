import {Box} from '@mui/material';
import React from 'react';

type Props<T extends string | number = string> = React.ComponentProps<typeof Box> & {
  value: T;
  active: T;
};

export function TabPanel({value, active, children, ...props}: React.PropsWithChildren<Props>) {
  if (value !== active) {
    return null;
  }

  return <Box {...props}>{children}</Box>;
}
