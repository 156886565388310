import React, {useMemo} from 'react';
import {Card, Divider, IconButton, Stack, Tooltip} from '@mui/material';
import {grey} from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Edge} from 'app/domain/edge';
import {SelectionSummary} from 'app/components/features/edge/BatchDevices/SelectionSummary/SelectionSummary';
import {useCountBatched} from 'app/components/features/edge/BatchDevices/hooks/useCountBatched';
import {MultimediaControls} from 'app/components/features/edge/BatchDevices/MultimediaControls/MultimediaControls';
import {useBatchFirmware} from 'app/components/features/edge/BatchDevices/hooks/useBatchFirmware';
import {BatchFirmware} from 'app/components/features/edge/BatchDevices/BatchFirmware/BatchFirmware';
import {BatchGroups} from 'app/components/features/edge/BatchDevices/BatchGroups/BatchGroups';
import {BatchActions} from 'app/components/features/edge/BatchDevices/BatchActions/BatchActions';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';

const rootSx: StyleSx = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  color: 'white',
  bgcolor: grey[900],
  borderRadius: 0,
  pl: 3,
  pr: 2,
};

const summarySx: StyleSx = {minWidth: 120};
const mediaSx: StyleSx = {mr: 'auto'};

type Props = Sx & {
  devices: AnyDeviceModelType[];
  groups: Edge.Group[];
  allGroups: Edge.Group[];
  presets: Edge.TeamPreset[];
  permitReboot: boolean;
  permitPreset: boolean;
  onClose: () => void;
};

export const BatchDevices = React.forwardRef<HTMLDivElement, Props>(
  ({sx, devices, groups, allGroups, presets, permitPreset, permitReboot, onClose}, ref) => {
    const count = useCountBatched(devices);

    const masters = useMemo(
      () => devices.filter((d) => !ModelService.isChannel(d.getModelName())),
      [devices],
    );

    const {upgradeable, firmwareLoading, refetchFirmware} = useBatchFirmware(masters);

    return (
      <Card ref={ref} sx={packSx(rootSx, sx)} variant="elevation" elevation={8}>
        <SelectionSummary
          sx={summarySx}
          groups={groups.length}
          devices={count.devices}
          channels={count.channels}
        />

        <MultimediaControls sx={mediaSx} devices={devices} />

        <Stack
          direction="row"
          alignItems="center"
          mr={3}
          gap={1}
          divider={<Divider sx={{borderColor: grey[800]}} orientation="vertical" flexItem={true} />}
        >
          {upgradeable.length > 0 && (
            <BatchFirmware
              devices={upgradeable}
              loading={firmwareLoading}
              refetch={refetchFirmware}
            />
          )}

          {(masters.length > 0 || groups.length > 0) && (
            <BatchGroups devices={masters} groups={groups} allGroups={allGroups} />
          )}

          {masters.length > 0 && (
            <BatchActions
              devices={masters}
              presets={presets}
              permitPreset={permitPreset}
              permitReboot={permitReboot}
            />
          )}
        </Stack>

        <Tooltip title="Close and reset selection">
          <IconButton color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Card>
    );
  },
);
