import React from 'react';
import {ModalNotification} from 'app/components/sharedReactComponents/Modal/ModalNotification';
import {ModalContainer} from 'app/components/sharedReactComponents/Modal/Modal';

export const InvitedModalNotification = {
  show(invited: boolean) {
    if (invited) {
      ModalNotification.show({
        message: (
          <ModalContainer>
            You've been invited to Epiphan&nbsp;Cloud web&nbsp;application.<br/>

            {' '}

            Please&nbsp;click&nbsp;anywhere to&nbsp;proceed to&nbsp;login.
          </ModalContainer>
        ),
      });
    }
  }
};
