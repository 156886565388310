import {DeviceCapability} from 'app/components/DeviceDetails/constants';
import {getTranscriberStatus} from 'app/components/DeviceDetails/utils';
import {DeviceSettingsCollection} from 'app/models/Device/Settings/DeviceSettingsCollection';
import {
  DesiredState,
  StreamingState,
  RecordingState,
  TranscribingState,
} from 'app/store/models/types';
import {
  DesiredStateDto,
} from 'app/store/dto/common';
import {
  isDesiredStateCmsParams,
  isRecordingDesiredStateDto,
  isStreamingDesiredStateDto,
  isTranscribingDesiredStateDto,
} from 'app/store/dto/typeGuards';
import {deviceApiService} from 'app/services/api/device/DeviceApiService';

function getDeviceCapabilities(capabilities: DeviceCapability[]): Set<DeviceCapability> {
  return new Set([...capabilities]);
}

function mapDesiredState(array: DesiredStateDto[]): DesiredState {
  return array.reduce<DesiredState>((acc, dto) => {
    if (isStreamingDesiredStateDto(dto)) {
      const streamingState: StreamingState = {
        // type: dto.Type,
        time: dto.StateTime,
        active: dto.Params?.Enabled ?? false,
        streamId: dto.Params?.StreamID ?? '',
        url: dto.Params?.StreamAddr ?? '',
      };

      acc.streaming = streamingState;
      return acc;
    }

    if (isRecordingDesiredStateDto(dto)) {
      const param = dto.Params;
      const active = param ?
        isDesiredStateCmsParams(param) ?
          Boolean(param.CMS.EventID) :
          param.Enabled :
        false;

      const recodingState: RecordingState = {
        time: dto.StateTime,
        active,
        // type: dto.Type,
      };

      acc.recording = recodingState;
      return acc;
    }

    if (isTranscribingDesiredStateDto(dto)) {
      const {Params, StateTime} = dto;
      const [active, paused, url, sessionId] = [
        Params.Enabled, Params.Paused, Params.ShortURL, Params.SessionID
      ];

      const status = getTranscriberStatus(active, paused);

      const state: TranscribingState = {
        active,
        paused,
        url,
        status,
        sessionId,
        time: StateTime,
      };

      acc.transcribing = state;
      return acc;
    }

    return acc;
  }, {});
}

function createDeviceSettingsCollectionGetter(deviceId: string): () => Promise<DeviceSettingsCollection> {
  let promise: Promise<DeviceSettingsCollection> | undefined;

  return async (): Promise<DeviceSettingsCollection> => {
    if (!promise) {
      promise = deviceApiService.getDeviceSettings(deviceId)
        .then((settings) => {
          return new DeviceSettingsCollection({
            settings,
          });
        });
    }

    return promise;
  };
}

export {
  getDeviceCapabilities,
  mapDesiredState,
  createDeviceSettingsCollectionGetter,
};
