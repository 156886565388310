import React, {useMemo, useState} from 'react';
import {Stack, Tooltip} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import TuneIcon from '@mui/icons-material/Tune';
import {Sx} from 'app/types/common';
import {BatchActionButton} from 'app/components/FleetManager/BatchActionsPanel/BatchActionButton';
import {RebootDevicesDialog} from 'app/components/dialogs/RebootDevicesDialog/RebootDevicesDialog';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {DeleteDevicesDialog} from 'app/components/dialogs/DeleteDevicesDialog/DeleteDevicesDialog';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {PresetApiService} from 'app/services/api/preset/PresetApiService';
import {Edge} from 'app/domain/edge';
import {ApplyPresetToDevicesDialog} from 'app/components/dialogs/ApplyPresetToDevicesDialog/ApplyPresetToDevicesDialog';
import {Cloud} from 'app/domain/cloud';
import {useMounted} from 'app/hooks/useIsMounted';

type Props = Sx & {
  devices: AnyDeviceModelType[];
  presets: Edge.TeamPreset[];
  permitReboot: boolean;
  permitPreset: boolean;
};

export function BatchActions({sx, devices, presets, permitPreset, permitReboot}: Props) {
  const [rebootDialog, setRebootDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [presetDialog, setPresetDialog] = useState(false);

  const mounted = useMounted();
  const {rebootable, lvsOnly, configurable, presetModel} = useActionDevices(devices);

  const handleReboot = async () => {
    if (!permitReboot) {
      return;
    }

    await Promise.allSettled(rebootable.map((d) => d.sendRebootCommand()));

    if (mounted()) {
      setRebootDialog(false);
    }
  };

  const handleDelete = async () => {
    const promises = devices.map((device) => DeviceApiService.deleteDevice(device.getId()));
    await Promise.allSettled(promises);

    if (mounted()) {
      setDeleteDialog(false);
    }
  };

  const handleApplyPreset = async (preset: Edge.TeamPreset) => {
    if (!permitPreset) {
      return;
    }

    const promises = configurable.map((device) =>
      PresetApiService.applyTeamPreset(device.getId(), preset),
    );
    await Promise.allSettled(promises);

    if (mounted()) {
      setPresetDialog(false);
    }
  };

  const presetTooltip = presetModel
    ? 'Apply preset'
    : 'Select devices with the same model to apply preset';

  return (
    <>
      <Stack sx={sx} direction="row" alignItems="center" gap={1}>
        {permitPreset && configurable.length > 0 && (
          <Tooltip title={presetTooltip}>
            <span>
              <BatchActionButton
                data-id="preset-btn"
                disabled={!presetModel}
                onClick={() => setPresetDialog(true)}
              >
                <TuneIcon />
              </BatchActionButton>
            </span>
          </Tooltip>
        )}

        {permitReboot && rebootable.length > 0 && (
          <Tooltip title="Reboot">
            <BatchActionButton data-id="reboot-btn" onClick={() => setRebootDialog(true)}>
              <RestartAltIcon />
            </BatchActionButton>
          </Tooltip>
        )}

        {devices.length > 0 && (
          <Tooltip title="Delete">
            <BatchActionButton data-id="delete-btn" onClick={() => setDeleteDialog(true)}>
              <DeleteIcon />
            </BatchActionButton>
          </Tooltip>
        )}
      </Stack>

      <RebootDevicesDialog
        open={rebootDialog}
        names={rebootable.map((r) => r.getName())}
        onReboot={handleReboot}
        onClose={() => setRebootDialog(false)}
      />

      <DeleteDevicesDialog
        open={deleteDialog}
        names={devices.map((d) => d.getName())}
        lvsOnly={lvsOnly}
        onDelete={handleDelete}
        onClose={() => setDeleteDialog(false)}
      />

      {presetModel && (
        <ApplyPresetToDevicesDialog
          open={presetDialog}
          unitCount={configurable.length}
          model={presetModel}
          presets={presets}
          rebootWarning={true}
          onApply={handleApplyPreset}
          onClose={() => setPresetDialog(false)}
        />
      )}
    </>
  );
}

function useActionDevices(devices: AnyDeviceModelType[]) {
  return useMemo(() => {
    const online = devices.filter((d) => d.isOnline());

    const configurable = online.filter((device) => device.capabilities.presets);
    const rebootable = online.filter((device) => device.capabilities.reboot);

    const lvsOnly = devices.every((m) => ModelService.isLivescrypt(m.getModelName()));

    const models = new Set(configurable.map((d) => d.getModelName() as Cloud.UnitModel));

    const presetModel = models.size === 1 ? [...models][0] : undefined;

    return {online, configurable, rebootable, lvsOnly, presetModel};
  }, [devices]);
}
