import React from 'react';
import {Dialog} from '@mui/material';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {Callback} from 'app/types/common';
import TeamsConnect from 'app/components/dialogs/TeamsConnectDialog/TeamsConnect/TeamsConnect';

type Props = React.ComponentProps<typeof TeamsConnect> & {
  open: boolean;
  onClose: Callback;
};

export function TeamsConnectDialog({
  open,
  canPair: canEdit,
  hasTenants,
  onPair,
  onConnect,
  onCreate,
  onClose,
}: Props) {
  return (
    <Dialog open={open} fullWidth={true} maxWidth="md" onClose={onClose}>
      <DialogClose onClose={onClose} />

      <TeamsConnect
        hasTenants={hasTenants}
        canPair={canEdit}
        onConnect={onConnect}
        onCreate={onCreate}
        onPair={onPair}
      />
    </Dialog>
  );
}
