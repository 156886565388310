import React from 'react';
import {MediaAction} from 'app/components/pages/MeetingDetails/hooks/usePermissionIssue';
import {Box, Divider, Stack, Typography} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {palette} from 'app/themes/app';
import {Sx} from 'app/types/common';
import {LocalRecordGuide} from 'app/components/pages/MeetingDetails/MeetingLayout/LocalRecordGuide/LocalRecordGuide';

interface Props extends Sx {
  id?: string;
  action: MediaAction;
}

export function DeniedAccessIssue({sx, id, action}: Props) {
  return (
    <Box sx={sx}>
      <Stack sx={{mb: 1}} direction="row" alignItems="center" gap={1}>
        <WarningIcon sx={{color: palette.red}} />

        <Typography sx={{fontSize: '20px', color: palette.red}}>
          Denied: {action} permission request
        </Typography>
      </Stack>

      <Typography sx={{color: palette.gray2, fontSize: '16px', mb: 1.5}}>
        The {action} request was denied or the request pop-up in Zoom was closed by the meeting
        host.
      </Typography>

      {id && (
        <Typography mb={3} fontSize={12} color="#a1a7af">
          Meeting ID {id}
        </Typography>
      )}

      <Divider sx={{mb: 4}} />

      <Typography sx={{fontSize: '16px', color: palette.darkerGray, mb: 4}}>
        Suggested actions:
      </Typography>

      <LocalRecordGuide variant="media" />
    </Box>
  );
}
