import React from 'react';
import {Stack, StackProps} from '@mui/material';
import {packSx} from 'app/util/packSx/packSx';
import {DataId} from 'app/types/common';

export const LegacyIcon = React.forwardRef<any, StackProps & DataId>(
  ({sx, dataId, ...props}, ref) => {
    return (
      <Stack
        data-id={dataId}
        ref={ref}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={packSx(
          {
            '& > .svg-icon': {
              width: '1em',
              height: '1em',
            },
          },
          sx,
        )}
        {...props}
      />
    );
  },
);
