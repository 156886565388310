import {StyleSx} from 'app/types/common';

const root: StyleSx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  px: 1.5,
  py: 1,
  flex: 1,
  minWidth: 0,
  gap: 2,
};

const selected: StyleSx = {
  borderColor: 'primary.main',
  boxShadow: ({palette: {primary}}) => `0px 0px 0px 1px ${primary.main}`,
};

export const bodyStyles = {root, selected};
