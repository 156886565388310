import React, {useCallback, KeyboardEvent} from 'react';
import {RadioGroup} from 'app/components/sharedReactComponents/RadioGroup';
import {Radio, RADIO_THEME} from 'app/components/sharedReactComponents/Radio';
import {Checkbox, CHECKBOX_THEME} from 'app/components/sharedReactComponents/Checkbox';
import {KeyboardCode, SIZE} from 'app/constants';
import {Callback} from 'app/types/common';
import {FilterSwitch, FilterSwitchGroup, FilterSwitchGroupType} from 'app/components/sharedReactComponents/FilterSelector/types';
import {Caret, CARET_VARIANT} from 'app/components/sharedReactComponents/Caret';
import classNames from 'classnames';
import {FilterSelectorSearch} from 'app/components/sharedReactComponents/FilterSelector/FilterSelectorSearch';
import {useFilterSelectorGroupSearch} from 'app/components/sharedReactComponents/FilterSelector/useFilterSelectorGroupSearch';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';

interface LabelProps {
  collapsible: boolean;
  collapsed: boolean;
  groupId: string;
  onClick: Callback;
}

const Label: React.FC<LabelProps> = ({
  collapsible,
  collapsed,
  groupId,
  children,
  onClick,
}) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === KeyboardCode.Enter || event.code === KeyboardCode.Space) {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <div
      className={classNames('filter-selector__menu-group-label', collapsible && 'filter-selector__menu-group-label--collapsable')}
      tabIndex={collapsible ? 0 : undefined}
      data-id={`toggler_${groupId}`}
      onClick={collapsible ? onClick : undefined}
      onKeyDown={collapsible ? handleKeyDown : undefined}
    >
      {children}

      {collapsible && (
        <Caret
          data-id="filter_selector_caret"
          opened={collapsed === false}
          variant={CARET_VARIANT.THIN}
          size={SIZE.S}
        />
      )}
    </div>
  );
};

const Option: React.FC = ({children}) => {
  return (
    <div className="filter-selector__option">
      {children}
    </div>
  );
};

interface Props {
  filterSwitchGroup: FilterSwitchGroup;
  filterSwitches: FilterSwitch[];
  collapsible: boolean;
  collapsed: boolean;
  searchable: boolean;
  activeFilterSwitches: Set<string>;
  onClickFilterSwitcher: Callback;
  onToggleFilterSwitchGroupCollapse: Callback;
}

export const FilterSelectorGroup: React.FC<Props> = ({
  filterSwitchGroup,
  filterSwitches,
  collapsible,
  collapsed: collapsedProp,
  searchable,
  activeFilterSwitches,
  onClickFilterSwitcher,
  onToggleFilterSwitchGroupCollapse,
}) => {
  const {
    searchText,
    searchedFilterSwitches,
    onSearch,
  } = useFilterSelectorGroupSearch({
    filterSwitches,
    searchable,
  });

  const filterSwitchGroupId = filterSwitchGroup.id;

  const collapsed = collapsible && collapsedProp;

  const handleClickItem = useCallback(({target: {name}}) => {
    onClickFilterSwitcher(name);
  }, [onClickFilterSwitcher]);

  const handleChangeGroup = useCallback(({target: {value}}) => {
    onClickFilterSwitcher(value);
  }, [onClickFilterSwitcher]);

  const handleClickLabel = useCallback(() => {
    onToggleFilterSwitchGroupCollapse(filterSwitchGroupId);
  }, [filterSwitchGroupId, onToggleFilterSwitchGroupCollapse]);

  if (filterSwitchGroup.type === FilterSwitchGroupType.Radio) {
    const activeOption = filterSwitches.find(filterSwitch => activeFilterSwitches.has(filterSwitch.id));
    const value = activeOption ? activeOption.id : filterSwitchGroup.defaultValue;

    return (
      <RadioGroup
        key={filterSwitchGroupId}
        className="filter-selector__menu-group"
        dataId={`filter_selector_menu_group_${filterSwitchGroupId}`}
        name={filterSwitchGroupId}
        value={value}
        onChange={handleChangeGroup}
      >
        <Label
          collapsible={collapsible}
          collapsed={collapsed}
          groupId={filterSwitchGroupId}
          onClick={handleClickLabel}
        >
          {filterSwitchGroup.label}
        </Label>

        {!collapsed && (
          <>
            {searchable && (
              <div className="filter-selector__menu-group-search">
                <FilterSelectorSearch
                  value={searchText}
                  onChange={onSearch}
                />
              </div>
            )}

            {searchedFilterSwitches.map(filterSwitch => (
              <Option key={filterSwitch.id}>
                <Radio
                  value={filterSwitch.id}
                  label={filterSwitch.label}
                  theme={RADIO_THEME.BLACK}
                  size={SIZE.S}
                />
              </Option>
            ))}
          </>
        )}
      </RadioGroup>
    );
  }

  return (
    <div
      key={filterSwitchGroupId}
      className="filter-selector__menu-group"
      data-id={`filter_selector_menu_group_${filterSwitchGroupId}`}
    >
      <Label
        collapsible={collapsible}
        collapsed={collapsed}
        groupId={filterSwitchGroupId}
        onClick={handleClickLabel}
      >
        {filterSwitchGroup.label}
      </Label>

      {!collapsed && (
        <>
          {searchable && (
            <div className="filter-selector__menu-group-search">
              <FilterSelectorSearch
                value={searchText}
                onChange={onSearch}
              />
            </div>
          )}

          {searchedFilterSwitches.map(filterSwitch => (
            <Option key={filterSwitch.id}>
              <Checkbox
                label={(
                  <OverflowTooltip className="filter-selector__checkbox-label">
                    {filterSwitch.label}
                  </OverflowTooltip>
                )}
                name={filterSwitch.id}
                value={activeFilterSwitches.has(filterSwitch.id)}
                theme={CHECKBOX_THEME.BLACK}
                size={SIZE.S}
                disabled={filterSwitch.disabled}
                onChange={handleClickItem}
              />
            </Option>
          ))}
        </>
      )}
    </div>
  );
};
