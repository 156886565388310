import React from 'react';
import {Box} from '@mui/material';
import {Sx} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {SystemInformation} from 'app/components/DeviceDetails/DeviceInfoTab/SystemInformation/SystemInformation';
import {UnifyStarting} from 'app/components/DeviceDetails/DeviceInfoTab/UnifyStarting/UnifyStarting';

interface Props extends Sx {
  device: AnyDeviceModelType;
  progress: number;
}

export function InformationPanel({device, progress}: Props) {
  const isStarting = device.isStarting();

  return (
    <Box>
      {isStarting ? (
        <UnifyStarting
          deviceName={device.getName()}
          status={device.getStatus()}
          progress={progress}
        />
      ) : (
        <SystemInformation device={device} />
      )}
    </Box>
  );
}
