import {useQuery} from '@tanstack/react-query';
import {BillingApiService} from 'app/services/api/billing/BillingApiService';
import {BillingMapper} from 'app/services/mappers/BillingMapper';
import {stringComparator} from 'app/util/Sort';

export function useConnectTiers(enabled: boolean) {
  return useQuery({
    queryKey: ['connect-tiers'],
    enabled,
    queryFn: () => BillingApiService.getConnectTiers(),
    select: mapTiers,
  });
}

function mapTiers(c: Contracts.Billing.Connect.Tier[]) {
  return c.map(BillingMapper.mapConnectTier).sort((a, b) => stringComparator(b.id, a.id));
}
