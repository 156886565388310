import React, {useEffect, useState} from 'react';
import {Box, Stack} from '@mui/material';
import {Callback, Component} from 'app/types/common';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {AnnualInfo} from 'app/components/BillingManager/dialogs/CloudTiersDialog/AnnualInfo/AnnualInfo';
import {TierCard} from 'app/components/BillingManager/dialogs/CloudTiersDialog/TierCard/TierCard';
import {PseudoLink} from 'app/components/sharedReactComponents/PseudoLink';
import {palette} from 'app/themes/app';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {isNil} from 'app/util/isNil';
import {CurrentTierMark} from 'app/components/sharedReactComponents/CurrentTierMark/CurrentTierMark';
import {SeatsPicker} from 'app/components/BillingManager/dialogs/CloudTiersDialog/tiers/SeatsPicker/SeatsPicker';

interface Props extends Component {
  current: boolean;
  currentFree: boolean;
  tier: App.Billing.Cloud.Tier;
  interval: string;
  paired: number;
  hasMethod: boolean;
  seatPrice: number;
  seatCount?: number;
  configured: boolean;
  onEditPayment: Callback;
  onEnterCoupon: Callback;
  onSelectTier: Callback<any, [tier: string]>;
  onConfigureSeats: Callback<any, [total: number | undefined]>;
}

const MAX_PAIRED_DEVICES_COUNT = 999;

export const PremiumTier = ({
  current,
  tier,
  interval,
  paired,
  seatPrice,
  seatCount,
  hasMethod,
  configured,
  currentFree,
  onEditPayment,
  onEnterCoupon,
  onSelectTier,
  onConfigureSeats,
}: Props) => {
  const min = paired || 1;

  const [showAnnualInfo, setShowAnnualInfo] = useState(false);

  const [value, setValue] = useState<number | undefined>(() => {
    if (!isNil(seatCount)) {
      return seatCount;
    }

    if (current) {
      return tier.seats;
    }

    return tier.seats >= min ? tier.seats : min;
  });

  const noValue = isNil(value);
  const outOfBound = !noValue && (value < min || value > MAX_PAIRED_DEVICES_COUNT);

  const handleCancel = () => {
    setValue(tier.seats >= min ? tier.seats : min);
  };

  const handleSelect = () => {
    if (!outOfBound) {
      onSelectTier(tier.id);
    }
  };

  useEffect(() => {
    if (outOfBound) {
      return;
    }

    onConfigureSeats(value);
  }, [outOfBound, value, onConfigureSeats]);

  const renderAction = () => {
    if (!hasMethod) {
      return (
        <Button
          dataId="add_billing_info_button"
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          onClick={onEditPayment}
        >
          Add billing details
        </Button>
      );
    }

    if (!current) {
      return (
        <Tooltip content={configured ? undefined : ''}>
          <Button
            dataId="change_tier_button"
            theme={THEME.PRIMARY}
            variant={VARIANT.SOLID}
            disabled={!configured}
            onClick={handleSelect}
          >
            {currentFree ? 'Go premium' : 'Select'}
          </Button>
        </Tooltip>
      );
    }

    if (isNil(value) || value === tier.seats) {
      return <CurrentTierMark />;
    }

    return (
      <Stack direction="row" gap={1} justifyContent="center">
        <Button
          dataId="apply_devices_changes_button"
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          disabled={outOfBound}
          onClick={handleSelect}
        >
          Apply changes
        </Button>

        <Button
          dataId="cancel_devices_changes_button"
          theme={THEME.INFO}
          variant={VARIANT.OUTLINE}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Stack>
    );
  };

  const renderBody = () => {
    if (showAnnualInfo) {
      return <AnnualInfo onEnterCoupon={onEnterCoupon} onCancel={() => setShowAnnualInfo(false)} />;
    }

    const increase = () => {
      setValue((v) => (v ? v + 1 : v));
    };

    const decrease = () => {
      setValue((v) => (v ? v - 1 : v));
    };

    return (
      <>
        <TierCard.Title>Premium</TierCard.Title>

        <TierCard.Price value={seatPrice} interval={interval} perDevice={true} />

        <TierCard.Description>
          <SeatsPicker
            value={value}
            min={min}
            max={MAX_PAIRED_DEVICES_COUNT}
            seats={tier.seats}
            rate={seatPrice}
            interval={interval}
            showDiff={current}
            setValue={setValue}
            increase={increase}
            decrease={decrease}
          />
        </TierCard.Description>

        <TierCard.Actions>
          {renderAction()}

          <Box fontSize="12px" mt={2}>
            <PseudoLink
              data-id="annual_info_toggler"
              underlined={true}
              onClick={() => setShowAnnualInfo(true)}
            >
              Save up to 20% if you buy an annual plan
            </PseudoLink>
            <br />
            from Epiphan or from our trusted dealers
          </Box>
        </TierCard.Actions>
      </>
    );
  };

  return (
    <TierCard
      current={current}
      sx={{
        minWidth: 300,
        ...(showAnnualInfo
          ? {
              bgcolor: palette.white,
              borderColor: palette.green,
              borderStyle: 'solid',
              borderWidth: '1px',
            }
          : {}),
      }}
    >
      {renderBody()}
    </TierCard>
  );
};
