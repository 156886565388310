import {useQuery} from '@tanstack/react-query';
import {AfuApi} from 'app/components/entities/afu/api/AfuApi';

interface Args {
  deviceId: string;
  enabled: boolean;
  staleTime?: number;
  refetchInterval?: number;
}

export function useUnitAfuQuery({deviceId, enabled, refetchInterval, staleTime}: Args) {
  return useQuery({
    queryKey: ['unit', 'afu', deviceId],
    queryFn: async () => {
      const response = await AfuApi.getAfu(deviceId);
      return response;
    },

    refetchInterval,
    staleTime,
    enabled,
  });
}
