import React from 'react';
import {Typography, capitalize} from '@mui/material';
import {DataId, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {Schedule} from 'app/domain/schedule';
import {isWaitingConfirmation} from 'app/domain/schedule/utils';
import {EventCountdown} from 'app/components/sharedReactComponents/Events/shared/EventCountdown/EventCountdown';
import {getOverMessage} from 'app/components/sharedReactComponents/Events/shared/EventCountdown/utils';
import {getVariant} from 'app/components/sharedReactComponents/Events/utils';

type Props = Sx &
  DataId & {
    event: Schedule.Event;
  };

export function EventStatus({dataId, sx, event}: Props) {
  const variant = getVariant(event.status);

  const ongoing = variant === 'ongoing';
  const scheduled = variant === 'scheduled';
  const waiting = isWaitingConfirmation(event.status);

  if (waiting) {
    return (
      <Typography data-id={dataId} sx={sx} color="error.main">
        Waiting confirmation
      </Typography>
    );
  }

  if (ongoing || scheduled) {
    return (
      <EventCountdown
        dataId={dataId}
        sx={packSx({whiteSpace: 'pre', color: ongoing ? 'error.main' : 'primary.main'}, sx)}
        variant={variant}
        start={event.start}
        end={event.end}
        overMessage={getOverMessage(variant, event.start)}
        label={<Typography noWrap={true}>{ongoing ? 'Ends in' : 'Starts in'}</Typography>}
      />
    );
  }

  return (
    <Typography data-id={dataId} sx={sx} color="text.disabled">
      {formatStatus(event.status)}
    </Typography>
  );
}

function formatStatus(status: Schedule.EventStatus) {
  if (status === 'finished') {
    return 'Completed';
  }

  return capitalize(status);
}
