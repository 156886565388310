import React, {useEffect, useState} from 'react';
import {AUTH_STATE} from 'app/components/Login/LoginForm/constants';
import {Title} from 'app/components/Login/LoginForm/Title';
import {SignIn} from 'app/components/Login/LoginForm/SignIn';
import {SignUp} from 'app/components/Login/LoginForm/SignUp';
import {ConfirmSignUp} from 'app/components/Login/LoginForm/ConfirmSignUp';
import Auth from '@aws-amplify/auth';
import {AuthButtons} from 'app/components/Login/LoginForm/AuthButtons';
import {ForgotPassword} from 'app/components/Login/LoginForm/ForgotPassword';
import {ResetPassword} from 'app/components/Login/LoginForm/ResetPassword';
import {Footer} from 'app/components/Login/LoginForm/Footer';
import {Callback} from 'app/types/common';

interface Props {
  next: string;
  state: string;
  authState: AUTH_STATE;
  onSignedIn: Callback;
  onChangeState: Callback;
}

export const LoginForm = ({
  next,
  state,
  authState,
  onSignedIn,
  onChangeState,
}: Props) => {
  const [authStateComponent, setAuthStateComponent] = useState<AUTH_STATE>(authState);
  const [authData, setAuthData] = useState({});

  Auth.configure({
    Auth: {
      userPoolId: CIRRUS_AUTH_AWS_COGNITO_USER_POOL_ID,
      userPoolWebClientId: CIRRUS_AUTH_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    },
  });

  useEffect(() => {
    setAuthStateComponent(authState);
  }, [authState]);

  const handleSignIn = data => {
    onSignedIn(data);
  };

  const switchStateToForgotPassword = () => {
    updateAuthState({
      authState: AUTH_STATE.FORGOT_PASSWORD,
    });
  };

  const switchStateToResetPassword = (username) => {
    updateAuthState({
      authState: AUTH_STATE.RESET_PASSWORD,
      authData: {username},
    });
  };

  const switchStateToSignUp = () => {
    updateAuthState({
      authState: AUTH_STATE.SIGN_UP,
      authData: {},
    });
  };

  const switchStateToSignIn = (username) => {
    updateAuthState({
      authState: AUTH_STATE.SIGN_IN,
      authData: {username},
    });
  };

  const switchStateToConfirmSignUp = (username) => {
    updateAuthState({
      authState: AUTH_STATE.CONFIRM_SIGN_UP,
      authData: {username},
    });
  };

  const updateAuthState = ({authState, authData}: any) => {
    setAuthStateComponent(authState);

    if (authData) {
      setAuthData(authData);
    }

    onChangeState(authState);
  };

  return (
    <div className="login-form">
      <Title authState={authStateComponent}/>

      <AuthButtons
        authState={authStateComponent}
        next={next}
        state={state}
      />

      <SignIn
        authState={authStateComponent}
        authData={authData}
        switchStateToForgotPassword={switchStateToForgotPassword}
        switchStateToResetPassword={switchStateToResetPassword}
        // rework
        // switchStateToSignUp={this.switchStateToSignUp}
        onSignIn={handleSignIn}
      />

      <ForgotPassword
        authState={authStateComponent}
        // authData={authData} check that field
        switchStateToResetPassword={switchStateToResetPassword}
      />

      <ResetPassword
        authState={authStateComponent}
        authData={authData}
        switchStateToSignIn={switchStateToSignIn}
      />

      <SignUp
        authState={authStateComponent}
        switchStateToConfirmSignUp={switchStateToConfirmSignUp}
      />

      <ConfirmSignUp
        authState={authStateComponent}
        authData={authData}
      />

      <Footer
        authState={authStateComponent}
        authData={authData}
        switchStateToSignIn={switchStateToSignIn}
        switchStateToSignUp={switchStateToSignUp}
      />
    </div>
  );
};
