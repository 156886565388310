const streaming = new Set<App.Connect.Permission>([
  'requesting-streaming',
  'streaming-request-denied',
  'streaming-request-timeout',
]);

const denied = new Set<App.Connect.Permission>([
  'recording-request-denied',
  'streaming-request-denied',
]);

export type MediaAction = 'recording' | 'streaming';

type MediaPermission = {
  action: MediaAction;
  denied: boolean;
};

export type PermissionIssue = MediaPermission | 'wait-record';

export function usePermissionIssue(
  permission?: App.Connect.Permission,
): PermissionIssue | undefined {
  if (!permission || permission === 'ok') {
    return;
  }

  if (permission === 'waiting-recording') {
    return 'wait-record';
  }

  return {
    action: streaming.has(permission) ? 'streaming' : 'recording',
    denied: denied.has(permission),
  };
}
