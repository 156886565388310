import React from 'react';
import {capitalize} from 'app/components/helpers/commonHelpers';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {ClassName, Sx} from 'app/types/common';
import {formatStartedAtDate} from 'app/util/time';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {UnitStatus} from 'app/components/DeviceDetails/DeviceStatusText/UnitStatus/UnitStatus';
import {UnifyConnectionStatus} from 'app/components/DeviceDetails/DeviceStatusText/UnifyConnectionStatus/UnifyConnectionStatus';
import {DeviceStatus} from 'app/store/dto/device/Device';
import {DeviceModel} from 'app/components/DeviceDetails/constants';

interface Props extends ClassName, Sx {
  model: DeviceModel;
  status: DeviceStatus;
  stateTime?: number;
  progress?: number;
}

export function DeviceStatusText({sx, model, status, stateTime, progress = 0}: Props) {
  const dataId = `${status}_label`;

  const isUnify = ModelService.isUnify(model);
  const isStarting = status === 'starting';
  const isResuming = status === 'resuming';

  if (isUnify && (isStarting || isResuming)) {
    return (
      <UnifyConnectionStatus
        sx={sx}
        dataId="unify_progress_status"
        progress={progress}
        mode={isStarting ? 'starting' : 'resuming'}
      />
    );
  }

  const tooltipText = getDeviceStatusTooltip(status, stateTime)
  const renderStatus = () => {
    switch (status) {
      case 'starting':
      case 'resuming':
      case 'rebooting':
      case 'upgrading':
      case 'stopping': {
        return (
          <UnitStatus sx={sx} dataId={dataId} variant="processing" value={capitalize(status)} />
        );
      }

      case 'pairing': {
        return (
          <UnitStatus sx={sx} dataId={dataId} variant="disabled" value={capitalize(status)} />
        );
      }

      case 'online': {
        return (
          <UnitStatus
            sx={sx}
            dataId={dataId}
            variant="running"
            value={isUnify ? 'Running' : 'Online'}
          />
        );
      }

      case 'holding_legacy': {
        return (
          <UnitStatus sx={sx} dataId={dataId} variant="disabled" value="Incompatible" />
        );
      }

      default: {
        return (
          <UnitStatus
            sx={sx}
            dataId={dataId}
            variant="disabled"
            value={isUnify ? 'Suspended' : 'Offline'}
          />
        );
      }
    }
  };

  return <Tooltip content={tooltipText}>{renderStatus()}</Tooltip>;
}

// DRY: Same method in components/features/edge/EdgeDevice/StatusIndicator. Please remove after new shared components
// architecture is defined.
function getDeviceStatusTooltip(status: DeviceStatus, stateTime?: number): string {
  switch (status) {
    case 'online':
      return '';
    case 'holding_legacy':
      return 'Incompatible firmware. Update your device firmware for Epiphan Edge compatibility.';
    default:
      return stateTime ? `Last time online: ${formatStartedAtDate(stateTime)}` : '';
  }
}
