import React from 'react';
import {Prompt} from 'app/types/common';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {Dialog} from '@mui/material';
import {EnterCoupon} from 'app/components/BillingManager/dialogs/EnterCouponDialog/EnterCoupon/EnterCoupon';

type Props = Prompt & React.ComponentProps<typeof EnterCoupon>;

export function EnterCouponDialog({dataId, className, open, onClose, ...props}: Props) {
  return (
    <Dialog open={open} data-id={dataId} className={className} onClose={onClose}>
      <DialogClose onClose={onClose} />

      <EnterCoupon {...props} onClose={onClose} />
    </Dialog>
  );
}
