import React from 'react';
import {Box, Divider, Stack} from '@mui/material';
import {grey} from '@mui/material/colors';
import {StyleSx, Sx, TimeStampSeconds} from 'app/types/common';
import {buildDayInterval} from 'app/components/sharedReactComponents/Events/Calendar/utils';
import {DayEvents} from 'app/components/sharedReactComponents/Events/Calendar/EventsSchedule/DayEvents/DayEvents';
import {secondsToHours, secondsToMinutes} from 'app/util/timeConverter';
import {createRange} from 'app/util/createRange';
import {useTodayPosition} from 'app/components/sharedReactComponents/Events/Calendar/EventsSchedule/useTodayPosition';
import {Schedule} from 'app/domain/schedule';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';

const emptyDay: Schedule.Event[] = [];

const dayStyles: StyleSx = {flexBasis: 0, minWidth: 0, flexGrow: 1};

type Props = Sx & {
  schedule: Map<TimeStampSeconds, Schedule.Event[]>;
  devices?: Map<string, PearlMasterDeviceModel>;
  period: Schedule.Period;
  range: TimeRange;
  displayed: number[];
  selected: number;
};

export function EventSchedule({sx, schedule, devices, selected, displayed, period, range}: Props) {
  const {enabled, position} = useTodayPosition({period, selectedDay: selected, range});

  const oneDay = selected > 0;
  const duration = range.to - range.from;

  const sections = secondsToHours(duration);
  const height = secondsToMinutes(duration);

  return (
    <Box sx={sx} position="relative" height={height}>
      <Stack
        direction="row"
        height="100%"
        divider={<Divider orientation="vertical" flexItem={true} />}
      >
        {displayed.map((day) => {
          const dayEvents = schedule.get(day) ?? emptyDay;

          return (
            <DayEvents
              key={day}
              dataId={`schedule-day-${day}`}
              sx={dayStyles}
              devices={devices}
              range={buildDayInterval(range, day)}
              events={dayEvents}
              oneDay={oneDay}
            />
          );
        })}
      </Stack>

      {createRange(sections).map((rowIdx) => (
        <Box
          key={rowIdx}
          position="absolute"
          top={60 * rowIdx}
          borderTop={1}
          width="100%"
          borderColor={grey[300]}
        />
      ))}

      {enabled && (
        <Box
          data-id="now-line"
          top={position}
          position="absolute"
          width="100%"
          borderTop={1}
          borderColor="error.main"
          zIndex={2}
        />
      )}
    </Box>
  );
}
