import React, {forwardRef} from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  disabled: boolean;
  selected: boolean;
  children: React.ReactNode;
}

export const ListItem = forwardRef<HTMLDivElement, Props>(({
  className,
  disabled,
  selected,
  children,
}, ref) => (
  <div
    ref={ref}
    className={classNames('device-streaming-destination-list-item', {
      'device-streaming-destination-list-item--selected': selected,
      'device-streaming-destination-list-item--disabled': disabled,
    }, className)}
    data-id="streaming_destination_list_item"
  >
    {children}
  </div>
));

ListItem.displayName = 'ListItem';
