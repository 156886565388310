import React, {Component} from 'react';
import {PageHeader} from 'app/components/sharedReactComponents/PageHeader';
import {UploadLocations} from 'app/components/UploadLocations';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';

interface Props {
  locationId?: string;
}

interface State {
  loading: boolean;
}

export class FileTransfer extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  handleLoadEnd = () => {
    this.setState({loading: false});
  };

  render() {
    const {locationId} = this.props;
    const {loading} = this.state;

    return (
      <div>
        <PageHeader title="File transfer locations" />

        {loading && <IndeterminateProgressBar />}

        <div className="container">
          <UploadLocations locationId={locationId} onLoadEnd={this.handleLoadEnd} />
        </div>
      </div>
    );
  }
}
