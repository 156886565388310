import React from 'react';
import {Button, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';

type Props = Sx & {
  message: string;
  label: string;
  onClick: () => void;
};

export function FilterMessage({sx, label, message, onClick}: Props) {
  return (
    <Stack
      data-id="filter-message"
      sx={sx}
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
      <Typography data-id="message-text" variant="h6" color="text.disabled" fontWeight={600}>
        {message}
      </Typography>

      <Button
        data-id="message-btn"
        variant="outlined"
        color="info"
        disableRipple={false}
        onClick={onClick}
      >
        {label}
      </Button>
    </Stack>
  );
}
