import React, {useMemo, useState} from 'react';
import {Box, Button, Stack} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {blueGrey, grey} from '@mui/material/colors';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import {packSx} from 'app/util/packSx/packSx';
import {router} from 'app/router/main';
import {
  DeviceStatusRecordingIcon,
  DeviceStatusStreamingIcon,
  DeviceStatusTranscribingIcon,
} from 'app/components/sharedReactComponents/DeviceStatusIcons';
import {EventInfo} from 'app/components/sharedReactComponents/Events/shared/EventInfo/EventInfo';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DeviceStatusText} from 'app/components/DeviceDetails/DeviceStatusText/DeviceStatusText';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {Schedule} from 'app/domain/schedule';
import {isOngoing, isScheduled} from 'app/domain/schedule/utils';
import {EditableNameText} from 'app/components/sharedReactComponents/EditableNameText';
import {emptyStringValidator, nameValidator} from 'app/util/validators';
import {DevicePicture} from 'app/components/sharedReactComponents/DevicePicture/DevicePicture';
import {DeviceHealthIndicator} from 'app/components/sharedReactComponents/DeviceHealthIndicator/DeviceHealthIndicator';
import {MoveToGroupDialog} from 'app/components/dialogs/MoveToGroupDialog/MoveToGroupDialog';
import {Sx} from 'app/types/common';
import {Edge} from 'app/domain/edge';
import {DeviceGroupApiService} from 'app/services/api/deviceGroup/DeviceGroupApiService';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {Notifications} from 'app/components/Notifications';
import {RebootDevicesDialog} from 'app/components/dialogs/RebootDevicesDialog/RebootDevicesDialog';

interface Props extends Sx {
  teamId: string;
  device: AnyDeviceModelType;
  groups: Edge.Group[];
  permitReboot: boolean;
  groupsAccess: boolean;
  progress?: number;
  event?: Schedule.Event;
}

export function DeviceDetailsHeader({
  teamId,
  sx,
  device,
  progress,
  event,
  groups,
  groupsAccess,
  permitReboot,
}: Props) {
  const deviceId = device.getId();
  const model = device.getModelName();

  const [rebootDialog, setRebootDialog] = useState(false);
  const [moveToGroupDialog, setMoveToGroupDialog] = useState(false);

  const isLiveScrypt = ModelService.isLivescrypt(model);
  const isUnify = ModelService.isUnify(model);

  const showGroup = !isUnify && groupsAccess;

  const ongoing = event && isOngoing(event.status);
  const scheduled = event && isScheduled(event.status);

  const label = scheduled ? 'Starts in' : ongoing ? 'Finishes in' : '';
  const backButtonUrl = router.url(
    isUnify ? 'projects' : isLiveScrypt ? 'transcribeDevices' : 'devices',
  );

  const showHealthIndicator = !device.isStarting();

  const supportReboot = permitReboot && device.capabilities.reboot;
  const isOffline = device.isOffline();

  const groupId = device.getGroupId();

  const availableGroups = useMemo(() => {
    if (showGroup) {
      return groups.filter((g) => g.id !== groupId);
    }

    return [];
  }, [showGroup, groups, groupId]);

  const handleMoveToGroup = async (groupId: string) => {
    if (!groupsAccess) {
      return;
    }

    try {
      await DeviceGroupApiService.moveToGroup(teamId, groupId, deviceId);
      setMoveToGroupDialog(false);
    } catch {
      Notifications.addErrorNotification('Could not add device to group');
    }
  };

  const handleRemoveFromGroup = async () => {
    if (!groupId) {
      return;
    }

    try {
      await DeviceGroupApiService.removeFromGroup(teamId, groupId, deviceId);
      setMoveToGroupDialog(false);
    } catch {
      Notifications.addErrorNotification('Could not remove device from group');
    }
  };

  const handleDeviceNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await device.rename(e.target.value);
  };

  const handleReboot = async () => {
    try {
      await device.sendRebootCommand();
      setRebootDialog(false);
    } catch {
      Notifications.addErrorNotification('Could to reboot device');
    }
  };

  return (
    <Box sx={sx} borderTop={1} borderColor={grey[400]} bgcolor={blueGrey[50]}>
      <Box pt={2} className="container">
        <Stack direction="row" gap={1} alignItems="center">
          <Button
            data-id="go_back_button"
            sx={{fontSize: 14, mr: 'auto'}}
            color="info"
            startIcon={<KeyboardArrowLeftIcon />}
            variant="text"
            href={backButtonUrl}
          >
            All {isUnify ? 'projects' : 'devices'}
          </Button>

          {event && (
            <EventInfo
              sx={{maxWidth: 600}}
              event={event}
              showTime={true}
              label={label}
              deviceId={device.getId()}
            />
          )}

          {supportReboot && (
            <Button
              sx={{fontSize: 14}}
              data-id="reboot_device_button"
              color="info"
              variant="text"
              disabled={isOffline}
              onClick={() => setRebootDialog(true)}
              startIcon={<RestartAltIcon />}
            >
              Reboot
            </Button>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" gap={1}>
          <Stack
            width={120}
            height={120}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <DevicePicture
              sx={packSx(isOffline && {opacity: 0.5}, isUnify && {fontSize: 64})}
              model={model}
            />
          </Stack>

          <Box flexGrow={1} minWidth={0}>
            <DeviceStatusText
              sx={{fontSize: 12, mb: 0.1, display: 'inline-block'}}
              model={model}
              status={device.getStatus()}
              stateTime={device.getDeviceStateTime()}
              progress={progress}
            />

            <Box
              sx={{
                '.cr-text-input': {
                  fontSize: 36,
                  '.cr-form-control__input-container': {
                    fontSize: 28,
                  },
                },
              }}
            >
              <EditableNameText
                dataId="device_header_name"
                ariaLabel="Device name"
                value={device.getName()}
                validators={[nameValidator, emptyStringValidator]}
                disabled={isOffline}
                onChange={handleDeviceNameChange}
              />
            </Box>

            {showGroup && (
              <Button
                data-id="device_details_group"
                sx={{mt: 0.25}}
                variant="text"
                color="info"
                endIcon={<DriveFileMoveOutlinedIcon color="primary" />}
                onClick={() => setMoveToGroupDialog(true)}
              >
                <OverflowTooltip>{device.groupName || 'Ungrouped device'}</OverflowTooltip>
              </Button>
            )}
          </Box>

          <Stack direction="row" alignItems="center" flexShrink={0} gap={2}>
            {device.isStreaming() && <DeviceStatusStreamingIcon labeled={true} />}

            {device.isRecording() && <DeviceStatusRecordingIcon labeled={true} />}

            {device.isTranscribing() && <DeviceStatusTranscribingIcon labeled={true} />}

            {showHealthIndicator && (
              <DeviceHealthIndicator
                warnings={device.getWarnings()}
                offline={isOffline}
                labeled={true}
              />
            )}
          </Stack>
        </Stack>
      </Box>

      {showGroup && (
        <MoveToGroupDialog
          open={moveToGroupDialog}
          devices={[device]}
          groups={availableGroups}
          showRemove={Boolean(device.groupId)}
          onMove={handleMoveToGroup}
          onRemove={handleRemoveFromGroup}
          onClose={() => setMoveToGroupDialog(false)}
        />
      )}

      <RebootDevicesDialog
        names={[device.getName()]}
        open={rebootDialog}
        onReboot={handleReboot}
        onClose={() => setRebootDialog(false)}
      />
    </Box>
  );
}
