import React from 'react';
import {Box, Divider, Stack, Typography} from '@mui/material';
import {palette} from 'app/themes/app';
import {EnterpriseTier} from 'app/components/BillingManager/dialogs/shared/EnterpriseTier/EnterpriseTier';
import {UnifyFeatures} from 'app/components/BillingManager/dialogs/UnifyTiersDialog/UnifyFeatures/UnifyFeatures';
import {UnifyTier} from 'app/components/BillingManager/dialogs/UnifyTiersDialog/UnifyTier/UnifyTier';
import {grey} from '@mui/material/colors';

type ProProps = React.ComponentProps<typeof UnifyTier>;

type Props = Omit<ProProps, 'tier' | 'current'> & {
  currentId?: string;
  tiers: App.Billing.Unify.Tier[];
  expireDate?: TimeStampSeconds;
};

export function UnifyTiers({
  currentId,
  tiers,
  custom,
  expireDate,
  hasCustomer,
  onEditPayment,
  onSelectTier,
}: Props) {
  return (
    <Box sx={{display: 'inline-block', px: 8, py: 5}}>
      <Stack
        borderBottom={`1px dashed ${grey[300]}`}
        position="relative"
        direction="row"
        gap={1}
        divider={<Divider sx={{borderStyle: 'dashed'}} orientation="vertical" flexItem />}
      >
        <Stack sx={{minWidth: 340, flexGrow: 1}}>
          <Typography variant="h5" fontWeight={600} mb={2}>
            Choose your <br /> Epiphan Unify plan
          </Typography>

          {!hasCustomer && (
            <Typography color={palette.darkerGray}>
              You need to add your billing details
              <br /> before confirming the subscription.
            </Typography>
          )}

          <Typography mt="auto" mb={1.5} fontWeight={600}>
            Features
          </Typography>
        </Stack>

        {tiers.map((t) => (
          <UnifyTier
            dataId={t.id}
            key={t.id}
            tier={t}
            current={t.id === currentId}
            custom={custom}
            hasCustomer={hasCustomer}
            onEditPayment={onEditPayment}
            onSelectTier={onSelectTier}
          />
        ))}

        <EnterpriseTier current={custom} expireDate={expireDate} />
      </Stack>

      <UnifyFeatures currentId={currentId} tiers={tiers} custom={custom} />
    </Box>
  );
}
