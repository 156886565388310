import {isNil} from 'app/util/isNil';

const defaultGetOptionLabel = option => option.label;
const defaultGetOptionValue = option => option.value;
const defaultGetOptionTitle = option => option.title;
const defaultGetOptionOptions = option => option.options;

const curriedProcessOption = ({
  getOptionLabel,
  getOptionValue,
  getOptionTitle,
  checkIcon,
}) => (option) => {
  const processedOption: any = {
    label: getOptionLabel(option),
    value: getOptionValue(option),
  };

  if (option.icon) {
    checkIcon();
    processedOption.icon = option.icon;
  }

  const title = getOptionTitle(option);
  if (title) {
    processedOption.title = title;
  }

  if (typeof option.disabled === 'boolean') {
    processedOption.disabled = option.disabled;
  }

  if (typeof option.visible === 'boolean') {
    processedOption.visible = option.visible;
  }

  return processedOption;
};

const curriedProcessGroupedOption = (processOption, getOptionOptions) => (option, processOptions) => {
  return {
    label: processOption(option).label,
    options: processOptions(getOptionOptions(option))
  };
};

const curriedIsGroupedOption = (getOptionOptions) => (option) => Boolean(getOptionOptions(option));

const processOptionsList = (options, value, {
  getOptionLabel,
  getOptionValue,
  getOptionTitle,
  getOptionOptions,
}) => {
  let withIcons = false;

  const checkIcon = () => {
    withIcons = true;
  };

  const processOption = curriedProcessOption({
    getOptionLabel,
    getOptionValue,
    getOptionTitle,
    checkIcon,
  });
  const isGroupedOption = curriedIsGroupedOption(getOptionOptions);
  const processGroupedOption = curriedProcessGroupedOption(processOption, getOptionOptions);

  let valueInOptions = false;
  let selectedOption: any = null;

  const processing = (options) => {
    const result: any = [];

    options.forEach((option) => {
      if (isGroupedOption(option)) {
        result.push(processGroupedOption(option, processing));
      } else {
        const processedOption = processOption(option);

        result.push(processedOption);

        if (String(value) === String(processedOption.value)) {
          valueInOptions = true;
          selectedOption = processedOption;
        }
      }
    });

    return result;
  };

  const processedOptions = processing(options);

  // Add current value to list if it does not exists in options list
  if (
    valueInOptions === false &&
    !isNil(value)
  ) {
    selectedOption = {
      label: value,
      value,
    };

    processedOptions.unshift(selectedOption);
  }

  return {
    withIcons,
    processedOptions,
    selectedOption,
  };
};

const curriedProcessOptionsList = ({
  getOptionLabel,
  getOptionValue,
  getOptionTitle,
  getOptionOptions,
}) => {
  return (options, value) => {
    return processOptionsList(options, value, {
      getOptionLabel,
      getOptionValue,
      getOptionTitle,
      getOptionOptions,
    });
  };
};

export {
  processOptionsList,
  curriedProcessOptionsList,
  defaultGetOptionLabel,
  defaultGetOptionValue,
  defaultGetOptionTitle,
  defaultGetOptionOptions,
};
