import React, {useState} from 'react';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Callback, Component} from 'app/types/common';
import {freeTier, proTier} from 'app/util/billing/utils';
import {Box, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {THEME, VARIANT} from 'app/constants';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {palette} from 'app/themes/app';
import {useMounted} from 'app/hooks/useIsMounted';

type ConfirmAction = 'seats' | 'premium' | 'free';

interface Props {
  currentId: string;
  selectedId: string;
  seats?: number;
  capacity: number;
  seatPrice: number;
  interval: string;
  onSubmit: Callback<Promise<any>>;
  onCancel: Callback;
}

export function CloudConfirm({
  currentId,
  selectedId,
  seats,
  seatPrice,
  capacity,
  interval,
  onSubmit,
  onCancel,
}: Props) {
  const [handling, setHandling] = useState(false);
  const mounted = useMounted();

  const handleSubmit = async () => {
    try {
      setHandling(true);
      await onSubmit();
    } finally {
      if (mounted()) {
        setHandling(false);
      }
    }
  };

  const action = useConfirmAction(currentId, selectedId);

  if (action === 'free') {
    return <SwitchToFree onSubmit={handleSubmit} onCancel={onCancel} />;
  }

  if (!seats) {
    return null;
  }

  const priceText = (
    <>
      For{' '}
      <Accent>
        <span data-id="seats-count">{seats}</span> device {pluralizeN('unit', seats)}
      </Accent>{' '}
      you will be charged{' '}
      <Accent>
        $<span data-id="tier-amount">{seats * seatPrice}</span> per {interval}
      </Accent>
    </>
  );

  if (action === 'premium') {
    return (
      <ConfirmRoot
        title={
          <>
            You are about to change your plan to <br />
            <strong>PREMIUM</strong> with per device pricing
          </>
        }
        price={priceText}
        description={
          <>
            You will get all Premium features and will be able to customize <br />
            number of the devices according to your needs.
          </>
        }
        handling={handling}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    );
  }

  const diff = seats - capacity;
  const absDiff = Math.abs(diff);
  const increased = diff > 0;

  const title = (
    <>
      You are about to {increased ? 'add' : 'remove'} <strong>{absDiff}</strong> device{' '}
      {pluralizeN('unit', absDiff)}
    </>
  );
  const label = `Yes, ${increased ? 'add' : 'remove'} device ${pluralizeN('unit', absDiff)}`;

  return (
    <ConfirmRoot
      title={title}
      price={priceText}
      label={label}
      handling={handling}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  );
}

interface RootProps extends Component {
  title: React.ReactNode;
  price?: React.ReactNode;
  description?: React.ReactNode;
  label?: string;
  isDanger?: boolean;
  disabled?: boolean;
  handling?: boolean;
  onSubmit: Callback;
  onCancel: Callback;
}

function ConfirmRoot({
  title,
  price,
  description,
  label = 'Yes, change plan',
  isDanger = false,
  disabled = false,
  handling = false,
  onSubmit,
  onCancel,
}: RootProps) {
  return (
    <div className="confirmation-tier-change">
      <DialogTitle fontWeight={600}>{title}</DialogTitle>

      <DialogContent>
        {price && (
          <Box sx={{mt: 3, textAlign: 'center', fontWeight: 'bold', fontSize: '16px'}}>{price}</Box>
        )}

        {description && <Box sx={{mt: 3, textAlign: 'center'}}>{description}</Box>}
      </DialogContent>

      <DialogActions sx={{mt: 3, gap: 1}}>
        <ProgressButton
          className="confirmation-tier-change__button"
          dataId="confirmation_message_apply_button"
          theme={isDanger ? THEME.DANGER : THEME.PRIMARY}
          variant={VARIANT.SOLID}
          autoFocus={true}
          disabled={disabled || handling}
          onClick={onSubmit}
        >
          {label}
        </ProgressButton>

        <Button
          className="confirmation-tier-change__button"
          dataId="confirmation_message_cancel_button"
          theme={THEME.SECONDARY}
          variant={VARIANT.OUTLINE}
          disabled={handling}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </DialogActions>
    </div>
  );
}

function useConfirmAction(currentId: string, nextId: string): ConfirmAction {
  if (freeTier(nextId)) {
    return 'free';
  }

  if (proTier(currentId)) {
    return 'seats';
  }

  return 'premium';
}

interface SwitchToFreeProps {
  handling?: boolean;
  onSubmit: Callback;
  onCancel: Callback;
}

function SwitchToFree({handling, onSubmit, onCancel}: SwitchToFreeProps) {
  return (
    <ConfirmRoot
      title="Are you sure you want to cancel your Edge paid plan?"
      description={<Typography>You wil lose all your Edge premium features</Typography>}
      label="Yes, cancel plan"
      disabled={handling}
      isDanger={true}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
}

function Accent({dataId, children}: React.PropsWithChildren<Component>) {
  return (
    <Box component="span" data-id={dataId} sx={{color: palette.blue}}>
      {children}
    </Box>
  );
}
