import React from 'react';
import {Box, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {Callback, Component} from 'app/types/common';
import {palette} from 'app/themes/app';
import {Coupon, isDiscountCoupon} from 'app/components/BillingManager/Coupon';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';

interface Props {
  coupon: Coupon;
  onClose: Callback;
}

export function ActivatedCoupon({coupon, onClose}: Props) {
  return (
    <Box>
      <DialogTitle>
        You have got <Value dataId="voucher-value">{coupon.formatted}</Value>{' '}
        {isDiscountCoupon(coupon) && coupon.isConnect
          ? 'discount for Epiphan Connect'
          : 'on your account'}
        .
      </DialogTitle>

      <DialogContent>
        <Typography>
          {isDiscountCoupon(coupon) && coupon.isConnect
            ? 'The discount will be applied to the next Epiphan Connect payment.'
            : 'You can spend credits for the Premium plan or for using livescrypt devices.'}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          dataId="modal_close_button"
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          autoFocus={true}
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Box>
  );
}

function Value({dataId, children}: React.PropsWithChildren<Component>) {
  return (
    <Box data-id={dataId} component="span" sx={{color: palette.green}}>
      {children}
    </Box>
  );
}
