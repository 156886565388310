import React, {useState} from 'react';
import {Button} from '@mui/material';
import {ConfirmEventDialog} from 'app/components/entities/schedule';
import {Sx} from 'app/types/common';

interface Props extends Sx {
  name: string;
  offline: boolean;
  onConfirm(): Promise<void>;
}

export function ConfirmControls({sx, name, onConfirm, offline}: Props) {
  const [dialog, setDialog] = useState(false);

  const handleConfirm = async () => {
    await onConfirm();
    setDialog(false);
  };

  return (
    <>
      <Button
        sx={sx}
        data-id="confirm-button"
        color="primary"
        variant="contained"
        disabled={offline}
        onClick={() => setDialog(true)}
      >
        Confirm
      </Button>

      <ConfirmEventDialog
        open={dialog}
        name={name}
        onConfirm={handleConfirm}
        onClose={() => setDialog(false)}
      />
    </>
  );
}
