import React, {PropsWithChildren} from 'react';
import {TierCard} from 'app/components/BillingManager/dialogs/shared/TierCard/TierCard';
import {PremiumSubtitle} from 'app/components/BillingManager/dialogs/shared/PremiumSubtitle/PremiumSubtitle';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {Stack, Typography} from '@mui/material';
import {TierPrice} from 'app/components/BillingManager/dialogs/shared/TierPrice/TierPrice';
import AddIcon from '@mui/icons-material/Add';
import {Price} from 'app/components/BillingManager/dialogs/shared/Price/Price';
import {formatPrice} from 'app/util/prices';
import {backgrounds, palette} from 'app/themes/app';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {Component, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';

interface Props extends Component {
  current: boolean;
  tier: App.Billing.Unify.Tier;
  hasCustomer: boolean;
  custom: boolean;
  onSelectTier: Fn;
  onEditPayment: Fn;
}

export function UnifyTier({
  dataId,
  tier,
  current,
  custom,
  hasCustomer,
  onEditPayment,
  onSelectTier,
}: Props) {
  const {bundle} = tier;

  const renderAction = () => {
    if (!hasCustomer) {
      return (
        <Button
          dataId="tier-action"
          variant={VARIANT.SOLID}
          theme={THEME.PRIMARY}
          onClick={onEditPayment}
        >
          Add billing details
        </Button>
      );
    }

    if (custom) {
      return (
        <Button
          dataId="tier-action"
          variant={VARIANT.OUTLINE}
          theme={THEME.SUCCESS}
          href="https://www.epiphan.com/company/contact-us/"
          target="_blank"
        >
          Contact us to switch
        </Button>
      );
    }

    if (current) {
      return (
        <Button
          dataId="tier-action"
          variant={VARIANT.OUTLINE}
          theme={THEME.INFO}
          onClick={() => onSelectTier({action: 'unsubscribe'})}
        >
          Stop subscription
        </Button>
      );
    }

    return (
      <Button
        dataId="tier-action"
        variant={VARIANT.SOLID}
        theme={THEME.PRIMARY}
        onClick={() => onSelectTier({action: 'subscribe', tier})}
      >
        Subscribe
      </Button>
    );
  };

  const {count, dimension} = tier.interval;

  const renderHourlyDiscount = () => {
    if (bundle) {
      return <Discount>$100/hour</Discount>;
    }

    return false;
  };

  return (
    <TierCard
      dataId={dataId}
      title={tier.name}
      sx={{bgcolor: current ? backgrounds.blue : backgrounds.gray}}
      subtitle={<PremiumSubtitle data-id="tier-subtitle" current={current} timeUnit={dimension} />}
      description={
        <Stack gap={1} alignItems="center">
          <TierPrice
            title="Subscription fee"
            value={
              <Price
                dataId="fee-value"
                value={`$${formatPrice(tier.subscriptionFee, 0)}`}
                message={<span data-id="interval-size">/{pluralizeN(dimension, count)}</span>}
              />
            }
            discount={<Discount>$25/{pluralizeN(dimension, count)}</Discount>}
          />

          <AddIcon sx={{color: palette.darkerGray}} />

          <TierPrice
            title="Usage per project"
            value={
              <Price
                dataId="hour-rate-value"
                value={`$${formatPrice(tier.hourlyRate, 0)}`}
                message="/hour"
              />
            }
            discount={renderHourlyDiscount()}
          />

          <Message sx={{mt: 1}}>Special introductory rate</Message>
        </Stack>
      }
      action={renderAction()}
    />
  );
}

function Discount({sx, children}: PropsWithChildren<Sx>) {
  return (
    <Typography
      component="span"
      textAlign="center"
      sx={packSx(
        {
          textDecoration: 'line-through',
          fontSize: '12px',
          fontWeight: 'bold',
          color: palette.gray7,
        },
        sx,
      )}
    >
      {children}
    </Typography>
  );
}

function Message({sx, children}: PropsWithChildren<Sx>) {
  return (
    <Typography
      component="span"
      textAlign="center"
      sx={packSx({fontSize: '12px', fontWeight: 'bold', color: palette.blue}, sx)}
    >
      {children}
    </Typography>
  );
}
