import React from 'react';
import classNames from 'classnames';
import {ClassName, DataId} from 'app/types/common';

interface Props extends ClassName, DataId {
  value: number;
  max?: number;
  barClassName?: string;
}

export const Progress: React.FC<Props> = ({className, value, max = 100, barClassName, dataId}) => {
  const width = (value / max) * 100;

  return (
    <div data-id={dataId} className={classNames('progress', className)}>
      <div
        className={classNames('progress__bar', barClassName)}
        style={{
          width: `${width}%`,
        }}
      />
    </div>
  );
};
