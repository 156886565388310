import React from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {}

export const LoadingPlaceholder: React.VFC<Props> = ({
  className,
}) => {
  return (
    <span className={classNames('loading-placeholder', className)}>
      {Icons.spinner().class('loading-placeholder__spinner').reactComponent()}
    </span>
  );
};
