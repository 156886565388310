import React from 'react';
import {Table, TableBody, TableContainer, TableHead, TableRow} from '@mui/material';
import {Sx} from 'app/types/common';
import {formatAbbreviatedDateWithYear} from 'app/util/time';
import {formatPrice} from 'app/util/prices';
import {InvoiceCell} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/shared/InvoiceCell/InvoiceCell';
import {InvoiceNumber} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/shared/InvoiceNumber/InvoiceNumber';

interface Props extends Sx {
  teamId: string;
  history: App.Billing.Connect.HistoryInvoice[];
}

export function ConnectHistory({sx, history, teamId}: Props) {
  return (
    <TableContainer sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            <InvoiceCell>Date</InvoiceCell>
            <InvoiceCell>Number</InvoiceCell>
            <InvoiceCell align="right">Subscription fee</InvoiceCell>
            <InvoiceCell align="right">Meeting hours</InvoiceCell>
            <InvoiceCell align="right">Usage fee</InvoiceCell>
            <InvoiceCell align="right">Total</InvoiceCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((h) => (
            <TableRow key={h.id} data-id={h.id}>
              <InvoiceCell data-id="invoice-date">
                {formatAbbreviatedDateWithYear(h.date)}
              </InvoiceCell>
              <InvoiceCell>
                <InvoiceNumber
                  dataId="invoice-number"
                  available={h.available}
                  id={h.id}
                  number={h.number}
                  teamId={teamId}
                />
              </InvoiceCell>
              <InvoiceCell data-id="invoice-fee" align="right">
                ${formatPrice(h.subscriptionFee, 2)}
              </InvoiceCell>
              <InvoiceCell data-id="invoice-hours" align="right">
                {h.usedHours}
              </InvoiceCell>
              <InvoiceCell data-id="invoice-usage" align="right">
                ${formatPrice(h.usageFee, 2)}
              </InvoiceCell>
              <InvoiceCell data-id="invoice-total" sx={{fontWeight: 'bold'}} align="right">
                ${formatPrice(h.total, 2)}
              </InvoiceCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
