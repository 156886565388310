import React from 'react';
import {AUTH_STATE} from 'app/components/Login/LoginForm/constants';
import {GoogleButton} from 'app/components/Login/LoginForm/SignInButton/GoogleButton';

const showOn = [
  AUTH_STATE.SIGN_IN,
  AUTH_STATE.SIGN_UP,
];

interface Props {
  authState: AUTH_STATE;
  next: string;
  state: string;
}

export const AuthButtons = ({
  authState,
  next,
  state,
}: Props) => {
  if (!showOn.includes(authState)) {
    return null;
  }

  return (
    <div className="login-form__auth-buttons">
      <GoogleButton
        authState={authState}
        next={next}
        state={state}
      />
    </div>
  );
};
