import {
  join,
  pipe,
  prop,
  reduce,
  sortBy,
  toPairs,
} from 'ramda';

// ie ?,=,&,/ etc
function containsEncodedComponents(string: string) {
  return decodeURI(string) !== decodeURIComponent(string);
}

function processValue(value: string) {
  if (containsEncodedComponents(value)) {
    return value;
  }

  return encodeURIComponent(value);
}

export function queryStringSorted(json?: Record<string, any>): string {
  if (!json) {
    return '';
  }

  return pipe(
    toPairs,
    // @ts-expect-error
    sortBy(prop(0)),
    reduce((acc: string[], [key, value]) => {
      if (typeof value !== 'undefined') {
        acc.push(`${key}=${processValue(value)}`);
      }

      return acc;
    }, []),
    join('&'),
  )(json) as string;
}
