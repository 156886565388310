import {Meeting, MeetingArgs} from 'app/store/models/connect/Meeting/Meeting';
import {Participant, ParticipantArgs} from 'app/store/models/connect/Participant/Participant';
import {isNil} from 'app/util/isNil';
import {SrtStream} from 'app/store/models/connect/SrtStream/SrtStream';
import {ReturnFeed} from 'app/store/models/connect/ReturnFeed/ReturnFeed';
import {isParticipantStreamSetting} from 'app/contracts/connect/utils';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';

function mapMeeting(
  contract: Contracts.Connect.Meeting,
  participants?: Participant[],
  returnFeed?: ReturnFeed,
): Meeting {
  return new Meeting(mapMeetingContract(contract), participants, returnFeed);
}

function mapParticipant(contract: Contracts.Connect.Participant): Participant {
  const stream = mapStream(contract.stream);
  return new Participant(mapParticipantContract(contract), stream);
}

function mapReturnFeedOwner(
  contract?: Contracts.Connect.InjectionOwner,
): App.Connect.ReturnFeedOwner | undefined {
  if (!isNil(contract)) {
    return {
      id: contract.meeting_id,
      name: contract.meeting_name,
      internal: contract.owned_by_team,
    };
  }
}

function mapReturnFeed(contract: Contracts.Connect.ReturnFeed): ReturnFeed {
  const {meeting_id, enabled, stream, injection_owner} = contract;
  const owner = mapReturnFeedOwner(injection_owner);
  return new ReturnFeed({meetingId: meeting_id, enabled, owner}, mapStream(stream));
}

function mapStreamSettings(
  settings: Contracts.Connect.StreamSettings<Contracts.Connect.StreamSetting>,
): App.Connect.StreamSettings {
  const {mode, mute, setting} = settings;

  const {port, key_length, latency, protocol, stream_url, stream_key} = setting;

  const feed: App.Connect.FeedStreamSettings = {
    mode,
    mute,
    url: stream_url,
    key: stream_key,
    latency,
    size: key_length,
    port,
    protocol,
  };

  if (isParticipantStreamSetting(setting)) {
    const {video_setting, audio_setting} = setting;

    const participant: App.Connect.ParticipantStreamSettings = {
      ...feed,
      audio: {
        bitrate: audio_setting.bitrate,
        format: audio_setting.format,
        mode: audio_setting.channels === 2 ? 'stereo' : 'mono',
      },
      video: {
        bitrate: video_setting.bitrate,
        size: video_setting.frame_size,
        framerate: video_setting.framerate,
      },
      integration: setting.epiphan_device_configured,
      cloud: {
        deviceId: setting.stream_source.device_id,
        deviceName: setting.stream_source.device_name,
        sourceId: setting.stream_source.source_id,
        sourceName: setting.stream_source.source_name,
        type: setting.stream_source.device_type,
      },
    };

    return participant;
  }

  return feed;
}

function mapStream(contract: Contracts.Connect.Stream<Contracts.Connect.StreamSetting>) {
  const {status, error, settings, connection} = contract;

  return new SrtStream({
    status,
    error,
    latency: connection?.latency,
    settings: settings ? mapStreamSettings(settings) : undefined,
  });
}

function toFeedSettingsContract(
  settings: App.Connect.FeedStreamSettings,
): Contracts.Connect.StreamSettings<Contracts.Connect.FeedStreamSetting> {
  return {
    mode: settings.mode,
    mute: {...settings.mute},
    setting: {
      stream_url: settings.url,
      port: settings.port,
      stream_key: settings.key,
      key_length: settings.size,
      latency: settings.latency,
      protocol: settings.protocol,
    },
  };
}

function toParticipantSettingsContract(
  settings: App.Connect.ParticipantStreamSettings,
): Contracts.Connect.StreamSettings<Contracts.Connect.ParticipantStreamSetting> {
  return {
    mode: settings.mode,
    mute: {...settings.mute},
    setting: {
      audio_setting: {
        format: settings.audio.format,
        channels: settings.audio.mode === 'stereo' ? 2 : 1,
        bitrate: settings.audio.bitrate,
      },
      video_setting: {
        bitrate: settings.video.bitrate,
        framerate: settings.video.framerate,
        frame_size: settings.video.size,
        codec: 'H264',
      },
      stream_url: settings.url,
      port: settings.port,
      stream_key: settings.key,
      key_length: settings.size,
      latency: settings.latency,
      protocol: settings.protocol,
      epiphan_device_configured: settings.integration,
      stream_source: {
        device_id: settings.cloud.deviceId,
        device_name: settings.cloud.deviceName,
        source_id: settings.cloud.sourceId,
        source_name: settings.cloud.sourceName,
        device_type: settings.cloud.type,
      },
    },
  };
}

function mapMeetingContract(dto: Contracts.Connect.Meeting): MeetingArgs {
  const {
    id,
    name,
    kind,
    isolated_audio,
    created_at,
    started_at,
    permissions,
    disconnected_at,
    deleted_at,
    state,
    url,
    error,
    meeting_tenant_name,
    meeting_tenant_id,
    settings,
    hosted_call_id,
    external_id,
  } = dto;

  return {
    id,
    name,
    url,
    host: hosted_call_id,
    platform: kind,
    permission: permissions,
    createdAt: created_at,
    startedAt: started_at ?? undefined,
    disconnectedAt: disconnected_at ?? undefined,
    deletedAt: deleted_at ?? undefined,
    isolatedAudio: isolated_audio,
    error: error ?? '',
    status: state,
    tenantId: meeting_tenant_id,
    tenantName: meeting_tenant_name,
    settings: settings ? mapMeetingSettings(settings) : undefined,
    externalId: external_id,
  };
}

function mapMeetingSettings(
  contract: Contracts.Connect.MeetingSettings,
): App.Connect.MeetingSettings {
  const {
    srt_ports_range,
    max_simultaneous_extractions,
    extractor_dns,
    automatic_disconnect_after,
    automatic_delete_after,
  } = contract;

  const [start, end] = srt_ports_range.split('-');

  const from = start ? Number(start) : 14000;
  const to = end ? Number(end) : 14100;

  return {
    dns: extractor_dns,
    extractionsLimit: max_simultaneous_extractions,
    timeouts: {
      delete: automatic_delete_after,
      disconnect: automatic_disconnect_after,
    },
    portRange: {
      from,
      to,
    },
  };
}

function mapParticipantContract(dto: Contracts.Connect.Participant): ParticipantArgs {
  const {
    id,
    name,
    sharing_screen,
    state,
    resource_type: type,
    video,
    audio,
    has_individual_audio,
  } = dto;

  return {
    id,
    type,
    state,
    name,
    video,
    audio,
    individualAudio: has_individual_audio,
    share: sharing_screen,
  };
}

function mapStreamingStatus(
  contract: Contracts.Connect.StreamingStatus,
): App.Connect.StreamingStatus {
  const {audio_quality, video_quality, caller_address, streaming_parameters} = contract;

  return {
    callerAddress: caller_address,
    params: {
      latency: streaming_parameters.latency,
      packetsLost: streaming_parameters.packets_lost,
    },
    video: {
      receiving: {
        bitrate: video_quality.receiving_video_stream.bitrate,
        format: video_quality.receiving_video_stream.format,
        size: video_quality.receiving_video_stream.frame_size,
        framerate: video_quality.receiving_video_stream.framerate,
      },
      sending: {
        bitrate: video_quality.sending_video_stream.bitrate,
        format: video_quality.sending_video_stream.format,
        size: video_quality.sending_video_stream.frame_size,
        framerate: video_quality.sending_video_stream.framerate,
      },
    },
    audio: {
      receiving: {
        bitrate: audio_quality.receiving_audio_stream.bitrate,
        format: audio_quality.receiving_audio_stream.coding_format,
        mode: audio_quality.receiving_audio_stream.type,
      },
      sending: {
        bitrate: audio_quality.sending_audio_stream.bitrate,
        format: audio_quality.sending_audio_stream.coding_format,
        mode: audio_quality.sending_audio_stream.type,
      },
    },
  };
}

function mapCloudEntity(contract: Contracts.Connect.CloudEntity): App.Connect.CloudEntity {
  return {
    ...contract,
    type: ModelService.isUnify(contract.model) ? 'unify' : 'device',
  };
}

function mapCall(contract: Contracts.Connect.Call): App.Connect.Call {
  const {id, name, team_id, created_at, deleted_at, start_at, end_at, join_url} = contract;

  return {
    id,
    name,
    url: join_url,
    endAt: end_at,
    createdAt: created_at,
    deletedAt: deleted_at,
    startAt: start_at,
    teamId: team_id,
  };
}

function mapZoomAccount(c: Contracts.Connect.Auth.ZoomAccount): App.Connect.Auth.ZoomAccount {
  return {
    id: c.id,
    name: c.name,
    number: c.account_number,
    shared: c.team_shared,
    picture: c.picture_url,
    owner: c.user_id,
    ready: c.status === 'ok',
  };
}

function mapTeamsTenant(c: Contracts.Connect.Auth.TeamsTenant): App.Connect.Auth.TeamsTenant {
  return {
    id: c.id,
    name: c.name,
    teamId: c.team_id,
    available: c.healthy,
  };
}

export const ConnectMapper = {
  mapMeeting,
  mapStream,
  mapReturnFeed,
  mapParticipant,
  mapStreamingStatus,
  toFeedSettingsContract,
  toParticipantSettingsContract,
  mapCloudEntity,
  mapCall,
  mapZoomAccount,
  mapTeamsTenant,
};
