import React, {useState} from 'react';
import {Button, Tooltip} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import {StopEventDialog} from 'app/components/sharedReactComponents/Events/dialogs/StopEventDialog/StopEventDialog';
import {StyleSx} from 'app/types/common';

const pauseSx: StyleSx = {minWidth: 20, px: 1};
const offlineTooltip = 'Device is offline';

interface Props {
  eventName: string;
  offline: boolean;
  paused: boolean;
  pauseSupport: boolean;
  onStop(): Promise<void>;
  onPause(): Promise<void>;
  onResume(): Promise<void>;
}

export function OngoingControls({
  eventName,
  paused,
  offline,
  pauseSupport,
  onPause,
  onResume,
  onStop,
}: Props) {
  const [dialog, setDialog] = useState(false);

  const handleStop = async () => {
    await onStop();
    setDialog(false);
  };

  const handleResume = async () => {
    await onResume();
  };

  const handlePause = async () => {
    await onPause();
  };

  const renderPauseResume = () => {
    if (!pauseSupport) {
      return null;
    }

    if (paused) {
      return (
        <Tooltip title={offline ? offlineTooltip : undefined}>
          <span>
            <LoadingButton
              sx={pauseSx}
              data-id="resume-button"
              color="secondary"
              variant="contained"
              disabled={offline}
              disableRipple={false}
              onClick={handleResume}
            >
              <PlayArrowIcon />
            </LoadingButton>
          </span>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={offline ? offlineTooltip : undefined}>
        <span>
          <LoadingButton
            sx={pauseSx}
            data-id="pause-button"
            color="error"
            variant="contained"
            disabled={offline}
            disableRipple={false}
            onClick={handlePause}
          >
            <PauseIcon />
          </LoadingButton>
        </span>
      </Tooltip>
    );
  };

  return (
    <>
      <StopEventDialog
        open={dialog}
        name={eventName}
        onStop={handleStop}
        onClose={() => setDialog(false)}
      />

      {renderPauseResume()}

      <Tooltip title={offline ? offlineTooltip : undefined}>
        <span>
          <Button
            data-id="stop-button"
            color="error"
            variant="contained"
            disabled={offline}
            disableRipple={false}
            onClick={() => setDialog(true)}
          >
            Stop
          </Button>
        </span>
      </Tooltip>
    </>
  );
}
