import {useEffect, useState} from 'react';
import {AbortCallback} from 'app/api/types';
import {
  WSMeetingChangedDto,
  WSParticipantUpdated,
  WSReturnFeedUpdated,
} from 'app/api/WebSocket/types';
import {WS} from 'app/api/WebSocket/WS';
import {useMeetingsStore} from 'app/store/hooks';
import {Meeting} from 'app/store/models/connect/Meeting/Meeting';
import {ConnectMapper} from 'app/util/mappers/ConnectMapper/ConnectMapper';
import {noop} from 'app/util/noop';
import {useSocketHandlers} from 'app/hooks/Meeting/useSocketHandlers';
import {MeetingsApiService} from 'app/services/api/meetings/MeetingsApiService';

interface Return {
  loading: boolean;
  meeting?: Meeting;
}

function useMeetingQuery(id: string): Return {
  const store = useMeetingsStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let abortCb: AbortCallback = noop;

    const load = async () => {
      try {
        const response = await MeetingsApiService.getMeeting(id, (abort) => (abortCb = abort));

        const feed = response.injection
          ? ConnectMapper.mapReturnFeed(response.injection)
          : undefined;

        store.setMeeting(
          ConnectMapper.mapMeeting(
            response,
            response.participants?.map(ConnectMapper.mapParticipant),
            feed,
          ),
        );
      } catch {
        store.removeMeeting(id);
      } finally {
        setLoading(false);
      }
    };

    void load();

    return () => {
      abortCb();
    };
  }, [id]);

  const meeting = store.getMeeting(id);

  return {
    loading,
    meeting,
  };
}

function useMeetingSocket(id?: string) {
  const {onMeetingChange, onParticipantChange, onReturnFeedChange} = useSocketHandlers();

  useEffect(() => {
    if (!id) {
      return;
    }

    const handleMeetingMessage = (data: WSMeetingChangedDto) => {
      const targetId = data.Body.id;

      if (targetId !== id) {
        return;
      }

      onMeetingChange(data);
    };

    WS.onMeetingChange(handleMeetingMessage);

    return () => {
      WS.offMeetingChange(handleMeetingMessage);
    };
  }, [id, onMeetingChange]);

  useEffect(() => {
    if (!id) {
      return;
    }

    const handleParticipantUpdated = (data: WSParticipantUpdated) => {
      const targetId = data.Body.meeting_id;

      if (targetId !== id) {
        return;
      }

      onParticipantChange(data);
    };

    WS.onParticipantUpdate(handleParticipantUpdated);

    return () => {
      WS.offParticipantUpdate(handleParticipantUpdated);
    };
  }, [id, onParticipantChange]);

  useEffect(() => {
    if (!id) {
      return;
    }

    const handleReturnFeedMessage = (data: WSReturnFeedUpdated) => {
      const targetId = data.Body.meeting_id;

      if (targetId !== id) {
        return;
      }

      onReturnFeedChange(data);
    };

    WS.onReturnFeedUpdate(handleReturnFeedMessage);

    return () => {
      WS.offReturnFeedUpdate(handleReturnFeedMessage);
    };
  }, [id, onReturnFeedChange]);
}

export function useMeeting(id: string): Return {
  const {loading, meeting} = useMeetingQuery(id);

  const disabled = loading || !meeting;

  useMeetingSocket(disabled ? undefined : meeting.id);

  return {
    loading,
    meeting,
  };
}
