import {always} from 'ramda';
import {Dispatcher} from 'app/data/Dispatcher';
import {ActionTypes} from 'app/data/ActionTypes';
import {minutesToMilliseconds, secondsToMilliseconds} from 'app/util/timeConverter';
import {noop} from 'app/util/noop';
import {Callback, TimeStampMilliseconds} from 'app/types/common';
import {AbortCallback, GetAbortCallback} from 'app/api/types';

type RepeatedCallCallType = ({getAbort}: {getAbort: GetAbortCallback}) => Promise<any>;

interface RepeatedCallOptions {
  call: RepeatedCallCallType;
  onSuccess?: Callback;
  hasData?: (response: any) => boolean; // check function. If returns `false` call interval increases by *2
  initialTimeoutMs?: TimeStampMilliseconds; // call interval
  maxTimeoutMs?: TimeStampMilliseconds; // maximum increased call interval
}

type ResolveType = (value: unknown) => void;
type RejectType = (reason?: any) => void;

class RepeatedCall {
  protected call: RepeatedCallCallType;
  protected onSuccessCb;
  protected hasData;
  protected initialTimeoutMs;
  protected maxTimeoutMs: TimeStampMilliseconds;

  protected timeoutId = -1;
  protected resolve: ResolveType = noop;
  protected reject: RejectType = noop;
  protected stopped = false;
  protected timeoutMs: TimeStampMilliseconds;
  protected abortRequest: AbortCallback = noop;

  constructor({
    call,
    onSuccess,
    hasData = always(true),
    initialTimeoutMs = secondsToMilliseconds(1),
    maxTimeoutMs = minutesToMilliseconds(13), // Don't known why 13 minutes
  }: RepeatedCallOptions) {
    this.call = call;
    this.onSuccessCb = onSuccess;
    this.hasData = hasData;
    this.initialTimeoutMs = initialTimeoutMs;
    this.maxTimeoutMs = maxTimeoutMs;

    this.timeoutMs = this.initialTimeoutMs;

    this.handleComponentException = this.handleComponentException.bind(this);
  }

  async start(immediately?: boolean): Promise<any> {
    this.stopped = false;

    Dispatcher.on(ActionTypes.UNHANDLED_COMPONENT_EXCEPTION, this.handleComponentException);

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;

      this.task(immediately);
    });
  }

  stop() {
    this.stopped = true;
    this.abortRequest();

    window.clearTimeout(this.timeoutId);

    Dispatcher.removeListener(ActionTypes.UNHANDLED_COMPONENT_EXCEPTION, this.handleComponentException);
  }

  async restart(immediately?: boolean): Promise<any> {
    this.stop();
    this.timeoutMs = this.initialTimeoutMs;
    return this.start(immediately);
  }

  getTimeoutMs() {
    return this.timeoutMs;
  }

  private increaseTimeout() {
    this.timeoutMs = Math.min(this.maxTimeoutMs, this.timeoutMs * 2);
  }

  private onSuccess(result) {
    if (this.hasData(result)) {
      this.timeoutMs = this.initialTimeoutMs;
      this.resolve(result);
    } else {
      this.increaseTimeout();
    }

    if (typeof this.onSuccessCb === 'function') {
      this.onSuccessCb(result, () => this.stop());
    }

    if (this.stopped) {
      this.resolve(result);
    } else {
      this.task();
    }
  }

  private catch(err) {
    if (!this.stopped) {
      this.stop();
    }

    this.reject(err);
  }

  private async task(immediately = false) {
    if (immediately) {
      await this.runTask();
    } else {
      this.runTaskTimeout();
    }
  }

  private runTaskTimeout() {
    this.timeoutId = window.setTimeout(async () => {
      await this.runTask();
    }, this.timeoutMs);
  }

  private async runTask() {
    try {
      const result = await this.call({
        getAbort: abort => this.abortRequest = abort,
      });

      if (!this.stopped) {
        this.onSuccess(result);
      }
    } catch (err: unknown) {
      this.catch(err);
    }
  }

  private handleComponentException() {
    this.stop();
  }
}

export {RepeatedCall};
