import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {
  Box,
  Stack,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Typography,
  typographyClasses,
} from '@mui/material';
import {grey} from '@mui/material/colors';
import {Dispatcher} from 'app/data/Dispatcher';
import {ActionTypes} from 'app/data/ActionTypes';
import {FilterSwitchGroupId} from 'app/components/FleetManager/useFilterSwitches';
import {Sx} from 'app/types/common';
import {FilterSwitch} from 'app/components/sharedReactComponents/FilterSelector/types';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {palette} from 'app/themes/app';

interface PortalProps {
  portalId: string;
}

export function NavGroupPortal({portalId, children}: React.PropsWithChildren<PortalProps>) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (document.querySelector(`#${portalId}`)) {
      setVisible(true);
    }

    const mountHandler = (id: string) => {
      if (id === portalId) {
        setVisible(true);
      }
    };

    const unmountHandler = (id: string) => {
      if (id === portalId) {
        setVisible(false);
      }
    };

    Dispatcher.on(ActionTypes.UI_PORTAL_ELEMENT_MOUNT, mountHandler);
    Dispatcher.on(ActionTypes.UI_PORTAL_ELEMENT_UNMOUNT, unmountHandler);

    return () => {
      Dispatcher.removeListener(ActionTypes.UI_PORTAL_ELEMENT_MOUNT, mountHandler);
      Dispatcher.removeListener(ActionTypes.UI_PORTAL_ELEMENT_UNMOUNT, unmountHandler);
    };
  }, [portalId]);

  const portalElement = document.querySelector(`#${portalId}`);
  if (!visible || !portalElement) {
    return null;
  }

  return ReactDOM.createPortal(children, portalElement);
}

interface Props extends Sx {
  filterSwitches: FilterSwitch[];
  activeFilterSwitches: Set<string>;
  onFilter: Fn<any, [id: string]>;
  onCreateGroup: Fn;
}

export function DeviceGroupsList({
  sx,
  filterSwitches,
  activeFilterSwitches,
  onFilter,
  onCreateGroup,
}: Props) {
  const filters = filterSwitches.filter(({group}) => group === FilterSwitchGroupId.Group);

  const hasDeviceGroups = filters.length > 0;

  return (
    <Box pl={2} sx={sx}>
      <Typography pl={4} mb={1} fontSize={12} color={grey[500]}>
        Filter by groups
      </Typography>

      {hasDeviceGroups && (
        <Stack pl={4} mb={2} maxHeight={160} overflow="auto">
          {filters.map((f) => (
            <FormControlLabel
              key={f.id}
              disableTypography={true}
              sx={{
                color: 'inherit',
                mb: 0,
                '&:hover': {
                  color: palette.green,
                },
              }}
              control={
                <Checkbox
                  sx={{
                    color: grey[500],
                    [`&.Mui-checked, &.Mui-focusVisible + .${typographyClasses.root}`]: {
                      color: palette.green,
                    },
                    '& input': {
                      mt: 0,
                    },
                  }}
                  checked={activeFilterSwitches.has(f.id)}
                  onClick={() => onFilter(f.id)}
                />
              }
              label={
                <Typography color="inherit" component="span" minWidth={0}>
                  <OverflowTooltip async={true}>{f.label}</OverflowTooltip>
                </Typography>
              }
            />
          ))}
        </Stack>
      )}

      <ButtonBase
        sx={{
          display: 'block',
          color: 'inherit',
          pl: 4,
          '&:hover, &.Mui-focusVisible': {color: palette.green},
        }}
        data-id="submenu_new_group_button"
        onClick={onCreateGroup}
      >
        + New group
      </ButtonBase>
    </Box>
  );
}
