import {useCallback, useEffect, useRef, useState} from 'react';
import {RepeatedCall} from 'app/util/RepeatedCall';
import {minutesToMilliseconds, secondsToMilliseconds} from 'app/util/timeConverter';
import {stringComparator} from 'app/util/Sort';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {isNil} from 'app/util/isNil';
import {streamApiService} from 'app/services/api/stream/StreamApiService';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {Callback} from 'app/types/common';
import {streamingServicesRepository} from 'app/components/StreamingServices';
import {useQuery} from '@tanstack/react-query';

interface Params {
  device?: AnyDeviceModelType;
}

interface Return {
  streamingDestinations: AnyStreamingDestinationModelType[];
  streamingDestinationsLoading: boolean;
  reloadStreamingDestinations: Callback;
}
/** @deprecated */
export function useStreamingDestinations({device}: Params = {}): Return {
  const repeatedCall = useRef<RepeatedCall>();
  const [loading, setLoading] = useState(true);
  const [streamingDestinations, setStreamingDestinations] = useState<
    AnyStreamingDestinationModelType[]
  >([]);
  const [resultStreamingDestinations, setResultStreamingDestinations] = useState<
    AnyStreamingDestinationModelType[]
  >([]);

  useEffect(() => {
    repeatedCall.current = new RepeatedCall({
      call: async ({getAbort}) => {
        try {
          const destinationsDto = await streamApiService.getStreams(getAbort);
          const destinations = destinationsDto
            .map((d) => streamingServicesRepository.createStreamingDestinationModel(d))
            .filter((d): d is AnyStreamingDestinationModelType => !isNil(d))
            .sort((a, b) => stringComparator(a.getName(), b.getName()));

          setStreamingDestinations(destinations);
        } finally {
          setLoading(false);
        }
      },
      initialTimeoutMs: secondsToMilliseconds(10),
      maxTimeoutMs: minutesToMilliseconds(2),
    });

    (async function () {
      await repeatedCall.current?.start(true);
    })();

    return () => repeatedCall.current?.stop();
  }, []);

  useEffect(() => {
    if (device) {
      const activeStreamingInfo = device.getActiveStreamingInfo();
      if (activeStreamingInfo.active) {
        setResultStreamingDestinations(
          streamingDestinations.map((stream) => {
            if (activeStreamingInfo.id === stream.getId()) {
              stream.setStreamingInfo(activeStreamingInfo);
            }

            return stream;
          }),
        );

        return;
      }
    }

    setResultStreamingDestinations(streamingDestinations);
  }, [streamingDestinations, device]);

  const reload = useCallback(() => {
    repeatedCall.current?.restart(true);
  }, []);

  return {
    streamingDestinations: resultStreamingDestinations,
    streamingDestinationsLoading: loading,
    reloadStreamingDestinations: reload,
  };
}

export function useTeamDestinations() {
  return useQuery({
    queryKey: ['team-streaming-destinations'],
    queryFn: async () => {
      const response = await streamApiService.getStreams();

      return response
        .map((d) => streamingServicesRepository.createStreamingDestinationModel(d))
        .filter((d): d is AnyStreamingDestinationModelType => !isNil(d))
        .sort((a, b) => stringComparator(a.getName(), b.getName()));
    },
    refetchInterval: 10 * 1000,
  });
}
