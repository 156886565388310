import React, {useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import {Button, Popover, Stack, Tooltip, Typography} from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import {Gauge} from '@mui/x-charts';
import {StyleSx, Sx} from 'app/types/common';
import {isNil} from 'app/util/isNil';
import {packSx} from 'app/util/packSx/packSx';
import {Edge} from 'app/domain/edge';
import {formatUptime} from 'app/util/time';
import {getGaugeSx} from 'app/components/features/edge/EdgeDevice/indicators/indicators.types';

const rootSx: StyleSx = {py: 0.5, px: 1};
const paperSx: StyleSx = {p: 1};

type Props = Sx & {system: Edge.System};

export function DevicePerformance({sx, system}: Props) {
  const [menu, setMenu] = useState<HTMLElement | null>(null);
  const [uptime, setUptime] = useState(() => formatUptime(system.uptime));

  const {load, temperature} = system;

  const raf = useRef<number>(0);

  useEffect(() => {
    let lastCallTimestamp: number | undefined;

    const step = (t: number) => {
      if (isNil(lastCallTimestamp) || t - lastCallTimestamp > 1000) {
        lastCallTimestamp = t;

        setUptime(formatUptime(system.uptime));
      }

      raf.current = requestAnimationFrame(step);
    };

    raf.current = requestAnimationFrame(step);

    return () => {
      cancelAnimationFrame(raf.current);
      raf.current = 0;
    };
  }, [system.uptime]);

  return (
    <>
      <Tooltip title="Device performance">
        <Button
          sx={packSx(rootSx, sx)}
          data-id="performance-btn"
          variant="outlined"
          color="info"
          onClick={(e) => setMenu(e.currentTarget)}
        >
          <Typography data-id="uptime-text">Uptime: {uptime}</Typography>
        </Button>
      </Tooltip>

      <Popover
        anchorEl={menu}
        open={!isNil(menu)}
        slotProps={{paper: {sx: paperSx}}}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        onClose={() => setMenu(null)}
      >
        <Typography mb={0.25} fontWeight={600}>
          CPU performance
        </Typography>

        <Stack mb={1} direction="row" justifyContent="center" gap={1}>
          {!isNil(load) && (
            <Stack alignItems="center">
              <Gauge
                data-id="performance-gauge"
                sx={getGaugeStyles(load)}
                width={80}
                height={80}
                innerRadius="90%"
                outerRadius="125%"
                value={load}
                text={({value}) => `${(value ?? 0).toFixed(0)}%`}
              />

              <Stack direction="row" alignItems="center" gap={0.25}>
                <SpeedIcon color="info" />
                <Typography>System load</Typography>
              </Stack>
            </Stack>
          )}

          {!isNil(temperature) && (
            <Stack alignItems="center">
              <Gauge
                data-id="temperature-gauge"
                sx={getGaugeStyles(temperature)}
                width={80}
                height={80}
                innerRadius="90%"
                outerRadius="125%"
                value={temperature}
                text={({value}) => `${(value ?? 0).toFixed(0)}°C`}
              />

              <Stack direction="row" alignItems="center" gap={0.25}>
                <ThermostatIcon color="info" />
                <Typography>Temperature</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>

        <Typography mb={0.25} fontWeight={600}>
          Uptime
        </Typography>

        <Typography mb={0.25}>
          Online since{' '}
          <span data-id="online-since-text"> {dayjs.unix(system.uptime).format('lll')}</span>
        </Typography>
      </Popover>
    </>
  );
}

function getGaugeStyles(value: number): StyleSx {
  if (value > 95) return getGaugeSx('error');

  if (value > 80) return getGaugeSx('warning');

  return getGaugeSx('ok');
}
