/**
 * ```typescript
 * uniqueNewName("foo", []); //=> "foo-1"
 * uniqueNewName("foo", ["foo-1"]); //=> "foo-2"
 * ```
 */
function uniqueNewName(name: string, existingNames: string[] = []): string {
  let result = newName(name);

  while (existingNames.includes(result)) {
    result = newName(result);
  }

  return result;
}

function newName(name: string): string {
  const separator = '-';
  const separatorIndex = name.lastIndexOf(separator);
  const existingIndexStr = separatorIndex > -1 ? name.substr(separatorIndex + 1) : '';
  const existingIndex = existingIndexStr ? Number(existingIndexStr) : Number.NaN;

  const MAX_COUNTER = 99;

  if (
    Number.isNaN(existingIndex) ||
    existingIndex > MAX_COUNTER ||
    !Number.isInteger(existingIndex)
  ) {
    return `${name}-1`;
  }

  const suffixLength = existingIndexStr.length;

  return `${name.substr(0, separatorIndex)}-${String(existingIndex + 1).padStart(suffixLength, '0')}`;
}

export {
  newName,
  uniqueNewName,
};
