import {BaseValidator} from 'app/util/validators/BaseValidator';

class DevicePresetSectionsValidator extends BaseValidator {
  protected message = 'Select at least one configuration group';
  protected type = 'device_preset_sections';

  validate(sections: App.Presets.Section[]): boolean {
    return Array.isArray(sections) && sections.length > 0;
  }
}

export {DevicePresetSectionsValidator};
