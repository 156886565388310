import React from 'react';
import classNames from 'classnames';
import {
  DeviceStatusRecordingIcon,
  DeviceStatusStreamingIcon,
} from 'app/components/sharedReactComponents/DeviceStatusIcons';
import {DurationTimer} from 'app/components/sharedReactComponents/DurationTimer';
import {Callback, ClassName} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Schedule} from 'app/domain/schedule';
import {EventInfo} from 'app/components/sharedReactComponents/Events/shared/EventInfo/EventInfo';

const items = [
  {
    id: 'streaming',
    icon: <DeviceStatusStreamingIcon />,
    isActive: (device) => device.isStreaming(),
    getStartTime: (device) => device.getStreamingStartTime(),
  },
  {
    id: 'recording',
    icon: <DeviceStatusRecordingIcon />,
    isActive: (device) => device.isRecording(),
    getStartTime: (device) => device.getDeviceRecordingStartTime(),
  },
];
interface StatusProps {
  id: string;
  icon: React.ReactNode;
  isActive: Callback;
  getStartTime: Callback;
  device: any;
}

const Status: React.VFC<StatusProps> = ({id, icon, isActive, getStartTime, device}) => {
  if (isActive(device) === false) {
    return null;
  }

  return (
    <div
      className={classNames(
        'channel-details-status-list__item',
        `channel-details-status-list__item--${id}`,
      )}
      data-id={`device_status_${id}`}
    >
      {icon}

      <DurationTimer
        className="channel-details-status-list__duration"
        startTime={getStartTime(device)}
      />
    </div>
  );
};

interface Props extends ClassName {
  device: AnyDeviceModelType;
  event?: Schedule.Event;
}

export function ChannelDetailsStatusList({className, device, event}: Props) {
  return (
    <div className={classNames('channel-details-status-list', className)}>
      {items.map((item) => (
        <Status
          key={item.id}
          id={item.id}
          icon={item.icon}
          isActive={item.isActive}
          getStartTime={item.getStartTime}
          device={device}
        />
      ))}

      {event && <EventInfo deviceId={device.getId()} event={event} />}
    </div>
  );
}
