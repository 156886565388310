/**
 * Non zero values on top
 *
 * ```typescript
 * [1, 0, 3].sort(numberComparator); //=> [3, 1, 0]
 * ```
 */
export function numberComparator(a: any, b: any): number {
  if (Number.isNaN(a)) {
    return -1;
  }

  if (a > b) {
    return -1;
  }

  if (a < b) {
    return 1;
  }

  return 0;
}
