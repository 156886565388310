import React, {ComponentProps, memo, useMemo, useState} from 'react';
import {Icons} from 'app/util/icons';
import {ContextMenu, ContextMenuItem} from 'app/components/sharedReactComponents/ContextMenu';
import {SIZE, THEME} from 'app/constants';
import {BatchActionButton} from 'app/components/FleetManager/BatchActionsPanel/BatchActionButton';
import {Callback, ClassName} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {ApplyPresetToDevicesDialog} from 'app/components/dialogs/ApplyPresetToDevicesDialog/ApplyPresetToDevicesDialog';
import {Edge} from 'app/domain/edge';
import {RebootDevicesDialog} from 'app/components/dialogs/RebootDevicesDialog/RebootDevicesDialog';
import {ProjectsSwitcherDialog} from 'app/components/dialogs/ProjectsSwitcherDialog/ProjectsSwitcherDialog';
import {Cloud} from 'app/domain/cloud';
import {DeleteProjectsDialog} from 'app/components/dialogs/DeleteProjectsDialog/DeleteProjectsDialog';
import {DeleteDevicesDialog} from 'app/components/dialogs/DeleteDevicesDialog/DeleteDevicesDialog';

interface Props extends ClassName {
  devices: AnyDeviceModelType[];
  presets: Edge.TeamPreset[];
  isUnify?: boolean;
  permitPreset: boolean;
  permitReboot: boolean;
  onApplyPreset: (preset: Edge.TeamPreset, devices: string[]) => Promise<void>;
  onStartProjects?: (projectIds: string[]) => Promise<void>;
  onStopProjects?: (projectIds: string[]) => Promise<void>;
  onDelete: (deviceIds: string[]) => Promise<void>;
  unpairAction: Callback;
}

type ProjectAction = ComponentProps<typeof ProjectsSwitcherDialog>['action'];

export const BatchActionContextMenu = memo(
  ({
    className,
    devices,
    isUnify = false,
    presets,
    permitReboot,
    permitPreset,
    onApplyPreset,
    onStartProjects,
    onStopProjects,
    onDelete,
    unpairAction,
  }: Props) => {
    const [presetDialog, setPresetDialog] = useState(false);
    const [rebootDialog, setRebootDialog] = useState(false);
    const [projectDialog, setProjectDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [projectAction, setProjectAction] = useState<ProjectAction>('start');

    const masters = useMemo(
      () => devices.filter((d) => !ModelService.isChannel(d.getModelName())),
      [devices],
    );

    const onlineMasters = useMemo(
      () => masters.filter((d) => d.isOnline() && !ModelService.isChannel(d.getModelName())),
      [masters],
    );

    const presetUnits = useMemo(
      () => onlineMasters.filter((device) => device.capabilities.presets),
      [onlineMasters],
    );

    const presetModel = useMemo<Cloud.UnitModel | undefined>(() => {
      const models = new Set(presetUnits.map((d) => d.getModelName()));

      return models.size === 1 ? [...models.values()][0] : undefined;
    }, [presetUnits]);

    const rebootUnits = useMemo(
      () => onlineMasters.filter((device) => device.capabilities.reboot),
      [onlineMasters],
    );

    const hasOffline = presetUnits.some((device) => device.isOffline());

    const {online: onlineProjects, offline: offlineProjects} = useMemo(() => {
      const projects = devices.filter((device) => ModelService.isUnify(device.getModelName()));

      return projects.reduce<Record<'online' | 'offline', AnyDeviceModelType[]>>(
        (acc, project) => {
          if (project.isOnline()) {
            const {online} = acc;
            return {...acc, online: [...online, project]};
          }

          if (project.isDown()) {
            const {offline} = acc;
            return {...acc, offline: [...offline, project]};
          }

          return acc;
        },
        {online: [], offline: []},
      );
    }, [devices]);

    const projectNames = (projectAction === 'start' ? offlineProjects : onlineProjects).map((d) =>
      d.getName(),
    );

    const handleReboot = async () => {
      await Promise.allSettled(rebootUnits.map((d) => d.sendRebootCommand()));
      setRebootDialog(false);
    };

    const handleApply = async (preset: Edge.TeamPreset) => {
      await onApplyPreset(
        preset,
        presetUnits.map((d) => d.getId()),
      );
      setPresetDialog(false);
    };

    const handleSwitch = async (action: ProjectAction) => {
      if (action === 'start') {
        await onStartProjects?.(offlineProjects.map((p) => p.getId()));
      } else {
        await onStopProjects?.(onlineProjects.map((p) => p.getId()));
      }

      setProjectDialog(false);
    };

    const handleDelete = async () => {
      await onDelete(masters.map((d) => d.getId()));
      setDeleteDialog(false);
    };

    const hasProjects = onlineProjects.length > 0 || offlineProjects.length > 0;

    const projectOptions: ContextMenuItem[] =
      isUnify && hasProjects
        ? [
            {
              icon: Icons.play().theme(THEME.PRIMARY).reactComponent(),
              itemDataId: 'context_menu_start_virtual_device',
              label: `Start ${pluralizeN('project', offlineProjects.length)}`,
              visible: offlineProjects.length > 0,
              action: () => {
                setProjectAction('start');
                setProjectDialog(true);
              },
            },
            {
              icon: Icons.stop().theme(THEME.SUCCESS).reactComponent(),
              itemDataId: 'context_menu_start_virtual_stop',
              label: `Stop ${pluralizeN('project', onlineProjects.length)}`,
              visible: onlineProjects.length > 0,
              action: () => {
                setProjectAction('stop');
                setProjectDialog(true);
              },
            },
            {
              separator: true,
            },
          ]
        : [];

    return (
      <div className={className}>
        <ContextMenu
          items={[
            ...projectOptions,
            {
              label: 'Apply preset',
              itemDataId: 'context_menu_apply_preset_action',
              icon: Icons.selectPreset().reactComponent(),
              danger: true,
              disabled: hasOffline || !permitPreset || !presetModel,
              title: getTooltip(hasOffline, presetModel),
              action: () => setPresetDialog(true),
            },
            {
              label: 'Delete',
              itemDataId: 'context_menu_delete_action',
              icon: Icons.trash().reactComponent(),
              disabled: isDisabled(canDelete, devices),
              danger: true,
              action: () => {
                setDeleteDialog(true);
              },
            },
            ...(isUnify
              ? []
              : [
                  {
                    label: 'Unpair',
                    itemDataId: 'context_menu_unpair_action',
                    icon: Icons.unlink().reactComponent(),
                    disabled: isDisabled(canUnpair, devices),
                    danger: true,
                    action: () => unpairAction(filter(canUnpair, devices)),
                  },
                  {
                    label: 'Reboot',
                    itemDataId: 'context_menu_reboot_action',
                    icon: Icons.retry().reactComponent(),
                    danger: true,
                    disabled: !permitReboot || !rebootUnits.length,
                    action: () => setRebootDialog(true),
                  },
                ]),
          ]}
        >
          <BatchActionButton
            className="batch-action-context-menu__button"
            data-id="batch_action_context_menu_button"
          >
            {Icons.ellipsis().size(SIZE.S).reactComponent()}
          </BatchActionButton>
        </ContextMenu>

        {presetModel && (
          <ApplyPresetToDevicesDialog
            open={presetDialog}
            unitCount={presetUnits.length}
            model={presetModel}
            presets={presets}
            rebootWarning={!isUnify}
            onApply={handleApply}
            onClose={() => setPresetDialog(false)}
          />
        )}

        {isUnify ? (
          <>
            <ProjectsSwitcherDialog
              open={projectDialog}
              names={masters.map((d) => d.getName())}
              action={projectAction}
              onSwitch={handleSwitch}
              onClose={() => setProjectDialog(false)}
            />

            <DeleteProjectsDialog
              open={deleteDialog}
              names={projectNames}
              onDelete={handleDelete}
              onClose={() => setDeleteDialog(false)}
            />
          </>
        ) : (
          <>
            <RebootDevicesDialog
              open={rebootDialog}
              names={rebootUnits.map((r) => r.getName())}
              onReboot={handleReboot}
              onClose={() => setRebootDialog(false)}
            />

            <DeleteDevicesDialog
              open={deleteDialog}
              names={masters.map((d) => d.getName())}
              lvsOnly={masters.every((m) => ModelService.isLivescrypt(m.getModelName()))}
              onDelete={handleDelete}
              onClose={() => setDeleteDialog(false)}
            />
          </>
        )}
      </div>
    );
  },
);

function canDelete(device: AnyDeviceModelType) {
  return ModelService.isChannel(device.getModelName()) === false;
}

function canUnpair(device: AnyDeviceModelType) {
  return device.isUnpaired() === false && ModelService.isChannel(device.getModelName()) === false;
}

function isDisabled(checkCallback, devices: AnyDeviceModelType[]) {
  return devices.some((device) => checkCallback(device)) === false;
}

function filter(checkCallback, devices: AnyDeviceModelType[]) {
  return devices.filter((device) => checkCallback(device));
}

function getTooltip(hasOffline: boolean, model?: Cloud.UnitModel): string | undefined {
  if (hasOffline) {
    return "Can't be applied for offline devices";
  }

  if (!model) {
    return 'Please select devices of the same model';
  }
}
