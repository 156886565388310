import React from 'react';
import {Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {formatPrice} from 'app/util/prices';
import {formatTimeUnit} from 'app/components/BillingManager/dialogs/shared/utils';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {palette} from 'app/themes/app';
import {THEME, VARIANT} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';
import {grey} from '@mui/material/colors';

interface Props extends Sx {
  tier: App.Billing.Unify.Tier;
  onConfirm: Fn;
  onCancel: Fn;
}

export function SubscribeToUnify({sx, tier, onConfirm, onCancel}: Props) {
  return (
    <Stack sx={sx} alignItems="center">
      <Typography variant="h5" fontWeight={600} component="span" mb={7}>
        You are about to subscribe to Epiphan Unify
      </Typography>

      <Typography fontSize={16} mb={2}>
        You will be charged:
      </Typography>

      <Typography fontSize={18} fontWeight={600}>
        ${formatPrice(tier.subscriptionFee)} per {tier.interval.dimension} subscription fee
      </Typography>

      <Typography component="s" fontSize={16} color={grey[500]} mb={2}>
        $25/{tier.interval.dimension}
      </Typography>

      <Typography fontSize={18} fontWeight={600}>
        ${formatPrice(tier.hourlyRate)} for every hour of an active Unify Project
      </Typography>

      <Typography component="s" fontSize={16} color={grey[500]}>
        $100/hour
      </Typography>

      <Typography my={6} color={palette.darkerGray}>
        Usage fee is billed {formatTimeUnit(tier.interval.dimension)} in the beginning of the next
        payment period.
      </Typography>

      <Stack sx={{'& > button': {minWidth: 150}}} direction="row" alignItems="center" gap={2}>
        <ProgressButton
          dataId="accept-confirm-btn"
          variant={VARIANT.SOLID}
          theme={THEME.PRIMARY}
          autoFocus={true}
          onClick={onConfirm}
        >
          Confirm
        </ProgressButton>

        <Button
          dataId="cancel-confirm-btn"
          theme={THEME.SECONDARY}
          variant={VARIANT.OUTLINE}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}
