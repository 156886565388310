import React from 'react';
import {Card, Grid, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {Edge} from 'app/domain/edge';
import {packSx} from 'app/util/packSx/packSx';
import {LoadPanel} from 'app/components/DeviceDetails/DeviceInfoTab/DevicePerformance/LoadPanel/LoadPanel';
import {TemperaturePanel} from 'app/components/DeviceDetails/DeviceInfoTab/DevicePerformance/TemperaturePanel/TemperaturePanel';
import {UptimePanel} from 'app/components/DeviceDetails/DeviceInfoTab/DevicePerformance/UptimePanel/UptimePanel';
import {isNil} from 'app/util/isNil';

interface Props extends Sx {
  system: Edge.System;
  isUnify: boolean;
}

export function DevicePerformance({sx, isUnify, system}: Props) {
  const {load, temperature, uptime} = system;

  return (
    <Card data-id="performance-panel" sx={packSx({p: 2}, sx)} elevation={3}>
      <Typography variant="h5" fontWeight={600} mb={2}>
        Performance
      </Typography>

      <Grid container={true} spacing={1}>
        {!isNil(load) && (
          <Grid item={true} xs={6}>
            <LoadPanel value={load} />
          </Grid>
        )}

        {!isUnify && !isNil(temperature) && (
          <Grid item={true} xs={6}>
            <TemperaturePanel value={temperature} />
          </Grid>
        )}

        {!isNil(uptime) && (
          <Grid item={true} xs={6}>
            <UptimePanel uptime={uptime} />
          </Grid>
        )}
      </Grid>
    </Card>
  );
}
