import React, {useState} from 'react';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {Sx} from 'app/types/common';
import {DeleteGroupsDialog} from 'app/components/dialogs/DeleteGroupsDialog/DeleteGroupsDialog';
import {RenameGroupDialog} from 'app/components/dialogs/RenameGroupDialog/RenameGroupDialog';
import {useMounted} from 'app/hooks/useIsMounted';

type Props = Sx & {
  groupName: string;
  onRename: (name: string) => Promise<void>;
  onDelete: () => Promise<void>;
};

export function GroupMenu({sx, groupName, onRename, onDelete}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [renameDialog, setRenameDialog] = useState(false);

  const mounted = useMounted();

  const handleDelete = async () => {
    await onDelete();

    if (mounted()) {
      setDeleteDialog(false);
    }
  };

  const handleRename = async (name: string) => {
    await onRename(name);

    if (mounted()) {
      setRenameDialog(false);
    }
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton data-id="group-menu-button" sx={sx} disableRipple={false} onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>

      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          data-id="rename-option"
          onClick={() => {
            setRenameDialog(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <CreateIcon color="info" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>

        <MenuItem
          data-id="delete-option"
          onClick={() => {
            setDeleteDialog(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <DeleteIcon color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <DeleteGroupsDialog
        open={deleteDialog}
        names={[groupName]}
        onDelete={handleDelete}
        onClose={() => setDeleteDialog(false)}
      />

      <RenameGroupDialog
        open={renameDialog}
        name={groupName}
        onRename={handleRename}
        onClose={() => setRenameDialog(false)}
      />
    </>
  );
}
