/* eslint-disable no-restricted-syntax */
import {SxProps, Theme} from '@mui/material';
import {SxObj} from 'app/types/common';

type SxStyles<T extends Theme = Theme> = boolean | SxObj<T> | ((theme: T) => SxObj<T>);

export function composeSx<T extends Theme = Theme>(
  sx: SxProps<T> = [],
): ReadonlyArray<SxStyles<T>> {
  return Array.isArray(sx) ? sx : [sx];
}

type OnlySx<T extends Theme = Theme> = Exclude<SxProps<T>, ReadonlyArray<any>>;
type SxArray<T extends Theme = Theme> = Array<OnlySx<T>>;

export function packSx<T extends Theme = Theme>(
  ...styles: (SxProps<T> | false | undefined)[]
): SxProps<T> {
  const result: SxArray<T> = [];

  for (const sx of styles) {
    if (sx) {
      if (Array.isArray(sx)) {
        for (const sub of sx as SxArray<T>) {
          result.push(sub);
        }
      } else {
        result.push(sx as OnlySx<T>);
      }
    }
  }
  return result;
}
