import React from 'react';
import {Sx} from 'app/types/common';
import {Box, Link, Typography} from '@mui/material';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {palette} from 'app/themes/app';
import {packSx} from 'app/util/packSx/packSx';
import {grey} from '@mui/material/colors';

interface Props extends Sx {
  canManage: boolean;
  onManage: Fn;
}

export function UnifyWelcome({sx, canManage, onManage}: Props) {
  return (
    <Box
      sx={packSx(
        {
          backgroundImage: `image-set(url('/assets/img/background/unify-background.jpg') 1x, url('/assets/img/background/unify-background@2x.jpg') 2x)`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        },
        sx,
      )}
      borderRadius={2.5}
      px={9}
      pt={8}
      pb={10}
    >
      <Box
        mb={2}
        component="img"
        maxWidth={172}
        src="/assets/img/logo/unify-logo.png"
        srcSet="/assets/img/logo/unify-logo@2x.png 2x"
        alt=""
      />

      <Typography fontSize={32} color={palette.white} mb={2}>
        Make your people
        <br /> look like stars
      </Typography>

      <Typography color={palette.white} mb={2}>
        Epiphan Unify™ gives you a professional recording and live
        <br /> streaming studio in the cloud, with the convenience of
        <br /> Zoom or Microsoft Teams as inputs.
      </Typography>

      <Link
        sx={{display: 'block', mb: 8}}
        data-id="learn_more_button"
        href="https://www.epiphan.com/unify/"
        color={palette.white}
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn More
      </Link>

      <Box width={410} p={5} borderRadius={2.5} bgcolor={palette.white}>
        <Typography fontSize={24} fontWeight={600} mb={2} color={palette.blue}>
          Start producing with Unify
        </Typography>

        <Box p={2} bgcolor={grey[300]} borderRadius={1}>
          <Typography mb={3}>
            {canManage
              ? 'Subscribe now and experience limitless production.'
              : 'Please contact the Epiphan Cloud team owner for subscription.'}
          </Typography>

          {canManage && (
            <Button
              dataId="subscribe-btn"
              variant={VARIANT.SOLID}
              theme={THEME.INFO}
              onClick={onManage}
            >
              Subscribe
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
