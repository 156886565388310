import React from 'react';
import {Stack, Typography} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import {HealthStatus} from 'app/components/DeviceDetails/constants';
import {TooltipMultipleMessages} from 'app/components/sharedReactComponents/Tooltip/TooltipMultipleMessages';
import {getDeviceHealth} from 'app/components/DeviceDetails/Models/utils';
import {Sx} from 'app/types/common';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';
import {Edge} from 'app/domain/edge';

interface Props extends Sx {
  warnings: Edge.Warning[];
  labeled?: boolean;
  offline: boolean;
  loading?: boolean;
}

export function DeviceHealthIndicator({sx, warnings, labeled, offline, loading}: Props) {
  const count = warnings.length;
  const health = getDeviceHealth(warnings);
  const size = count > 0 ? 'small' : 'medium';

  const renderContent = () => {
    if (loading) {
      return <LoadingPlaceholder />;
    }

    return (
      <>
        {health === HealthStatus.Bad && <WarningIcon fontSize={size} color="inherit" />}
        {health === HealthStatus.Fair && <ErrorIcon fontSize={size} color="inherit" />}
        {health === HealthStatus.Good && <CheckIcon fontSize={size} color="inherit" />}

        {count > 0 && (
          <Typography component="span" fontWeight={600} variant="inherit">
            {count}
          </Typography>
        )}
      </>
    );
  };

  return (
    <Stack sx={sx} direction="row" alignItems="center" gap={0.5}>
      <TooltipMultipleMessages messages={warnings}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={0.5}
          px={0.5}
          height={25}
          minWidth={40}
          borderRadius={0.5}
          color={() => {
            if (offline) {
              return 'text.disabled';
            }

            switch (health) {
              case HealthStatus.Bad:
                return 'error.main';
              case HealthStatus.Fair:
                return 'warning.main';
              default:
                return 'secondary.main';
            }
          }}
          data-id="device_health_indicator_badge"
        >
          {renderContent()}
        </Stack>
      </TooltipMultipleMessages>

      {labeled && (
        <Typography component="span" color="text.secondary" data-id="device_health_indicator_label">
          {getLabel(health)}
        </Typography>
      )}
    </Stack>
  );
}

function getLabel(health: HealthStatus): string {
  switch (health) {
    case HealthStatus.Good:
      return 'Good';
    case HealthStatus.Bad:
      return 'Critical';
    case HealthStatus.Fair:
    default:
      return 'Fair';
  }
}
