import {useEffect, useState} from 'react';
import {AnyDeviceModelType, createDeviceModel} from 'app/components/DeviceDetails/Models/Fabric';
import {parseMasterAndChannelIds} from 'app/components/DeviceDetails/utils';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {useProjectsQuery} from 'app/components/UnifyProjects/hooks/useProjectsQuery';
import {useDevicesState} from 'app/hooks/useDevicesState';
import {Ws} from 'app/contracts/ws';
import {
  setChannelWarnings,
  setDesiredState,
  setPublishers,
  setUnitStatus,
} from 'app/components/features/edge/utils';

interface Args {
  teamId: string;
  enabled: boolean;
}

type Return = {
  loading: boolean;
  projects: AnyDeviceModelType[];
  refetchProjects: () => Promise<any>;
  getProject: (id: string) => AnyDeviceModelType | undefined;
};

export function useProjects({enabled, teamId}: Args): Return {
  const [loading, setLoading] = useState(true);
  const {
    list: projects,
    get: getProject,
    setMap,
    setDevice: setProject,
    deleteDevice: deleteProject,
  } = useDevicesState();

  const projectQuery = useProjectsQuery({enabled, teamId});
  const refetchProjects = projectQuery.refetch;

  useEffect(() => {
    const onStatusChange = (message: Ws.DeviceStatusChange) => {
      const {DeviceID: deviceId} = message.Body;

      const instance = getProject(deviceId);

      if (instance) {
        const copy = instance.getInnerModel();
        const updated = setUnitStatus(copy, message);
        setProject(createDeviceModel(updated));
      }
    };

    const onPublishersChange = (message: Ws.DevicePublishers) => {
      const {DeviceID: deviceId} = message.Body;
      const instance = getProject(deviceId);

      if (instance) {
        const copy = instance.getInnerModel();
        const updated = setPublishers(copy, message.Body.Payload);
        setProject(createDeviceModel(updated));
      }
    };

    const onStateChange = (message: Ws.DeviceDesiredState) => {
      const {DeviceID: deviceId} = message.Body;

      const instance = getProject(deviceId);

      if (instance) {
        const copy = instance.getInnerModel();
        const updated = setDesiredState(copy, message.Body.Payload);
        setProject(createDeviceModel(updated));
      }
    };

    const onWarningsChange = (message: Ws.DeviceWarnings) => {
      const {DeviceID: deviceId} = message.Body;

      const [masterId, channelIdx] = parseMasterAndChannelIds(deviceId);

      const instance = getProject(masterId);

      if (instance) {
        const copy = instance.getInnerModel();

        if (channelIdx) {
          const updated = setChannelWarnings(copy, deviceId, message.Body.Payload);
          setProject(createDeviceModel(updated));
        } else {
          const model = createDeviceModel({...copy, Warnings: message.Body.Payload});
          setProject(model);
        }
      }
    };

    const onRenameDevice = (message: Ws.DeviceRename) => {
      const {DeviceID: deviceId} = message.Body;

      const [masterId, channelIdx] = parseMasterAndChannelIds(deviceId);

      const instance = getProject(masterId);

      if (!instance) {
        return;
      }

      const copy = instance.getInnerModel();

      if (channelIdx) {
        const channels = (copy.Child ?? []).map((channel) =>
          channel.Id === deviceId ? {...channel, Name: message.Body.Payload.Name} : channel,
        );

        setProject(createDeviceModel({...copy, Child: channels}));
        return;
      }

      setProject(createDeviceModel({...copy, Name: message.Body.Payload.Name}));
    };

    const onDeviceAddOrChange = (message: Ws.DeviceChange) => {
      const instance = createDeviceModel(message.Body.Payload);

      if (!ModelService.isUnify(instance.getModelName())) {
        return;
      }

      setProject(instance);
    };

    const onDeleteDevice = (message: Ws.DeviceRemove) => {
      const {DeviceID: deviceId} = message.Body;
      deleteProject(deviceId);
    };

    WS.on(EVENT_TYPE.ADD_DEVICE, onDeviceAddOrChange);
    WS.on(EVENT_TYPE.UNPAIR_DEVICE, onDeleteDevice);
    WS.on(EVENT_TYPE.REMOVE_DEVICE, onDeleteDevice);
    WS.on(EVENT_TYPE.DEVICE_STATUS, onStatusChange);
    WS.on(EVENT_TYPE.DEVICE_DESIRED_STATE, onStateChange);
    WS.on(EVENT_TYPE.DEVICE_PUBLISHERS, onPublishersChange);
    WS.on(EVENT_TYPE.DEVICE_WARNINGS, onWarningsChange);
    WS.on(EVENT_TYPE.DEVICE_RENAME, onRenameDevice);

    return () => {
      WS.off(EVENT_TYPE.ADD_DEVICE, onDeviceAddOrChange);
      WS.off(EVENT_TYPE.UNPAIR_DEVICE, onDeleteDevice);
      WS.off(EVENT_TYPE.REMOVE_DEVICE, onDeleteDevice);
      WS.off(EVENT_TYPE.DEVICE_STATUS, onStatusChange);
      WS.off(EVENT_TYPE.DEVICE_DESIRED_STATE, onStateChange);
      WS.off(EVENT_TYPE.DEVICE_PUBLISHERS, onPublishersChange);
      WS.off(EVENT_TYPE.DEVICE_WARNINGS, onWarningsChange);
      WS.off(EVENT_TYPE.DEVICE_RENAME, onRenameDevice);
    };
  }, [setProject, getProject, deleteProject]);

  useEffect(() => {
    setMap(new Map(projectQuery.data));
  }, [projectQuery.data, setMap]);

  useEffect(() => {
    setLoading(projectQuery.isInitialLoading);
  }, [projectQuery.isInitialLoading]);

  return {loading, projects, refetchProjects, getProject};
}
