import React from 'react';
import {Dialog, DialogActions, DialogTitle} from '@mui/material';
import {Button} from 'app/components/sharedReactComponents/Button';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {THEME, VARIANT} from 'app/constants';
import {Callback} from 'app/types/common';

interface Props {
  open: boolean;
  onCancelRequest: Callback<Promise<any>>;
  onClose: Callback;
}

export function CancelInvoiceRequestDialog({open, onCancelRequest, onClose}: Props) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogClose onClose={onClose} />

      <DialogTitle>Do you want to cancel invoice payment request?</DialogTitle>
      <DialogActions sx={{'& button': {width: '60px'}}}>
        <ProgressButton
          dataId="confirm-cancel"
          variant={VARIANT.SOLID}
          theme={THEME.DANGER}
          onClick={onCancelRequest}
        >
          Yes
        </ProgressButton>

        <Button
          dataId="close-cancel-dialog"
          variant={VARIANT.OUTLINE}
          theme={THEME.SECONDARY}
          onClick={onClose}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
