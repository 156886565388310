import React, {Fragment} from 'react';
import classNames from 'classnames';
import {router} from 'app/router/main';
import {stopPropagation} from 'app/util/stopPropagation';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {ClassName} from 'app/types/common';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';

interface Props extends ClassName {
  currentDevice: any;
  usedByDevice: any;
}

export const AlreadyUsedByDevice: React.FC<Props> = ({className, currentDevice, usedByDevice}) => {
  // rework typing
  let device: any = null;
  let channelDevice: any = null;
  let sameParent = false;

  if (usedByDevice) {
    const deviceModel = usedByDevice;
    device = {
      id: deviceModel.getId(),
      name: deviceModel.getName(),
    };

    if (ModelService.isChannel(deviceModel.getModelName())) {
      const masterDeviceId = deviceModel.getChannelDeviceMasterDeviceId();
      channelDevice = {...device};
      device = {
        id: masterDeviceId,
        name: deviceModel.getChannelDeviceMasterDeviceName(),
      };

      if (
        ModelService.isChannel(currentDevice.getModelName()) &&
        currentDevice.getChannelDeviceMasterDeviceId() === masterDeviceId
      ) {
        sameParent = true;
      }
    }
  }

  const showDevice = device !== null && sameParent === false;

  return (
    <span className={classNames('already-used-by-device', className)}>
      {showDevice && (
        <Fragment>
          Device:{' '}
          <a href={router.url('deviceDetails', {deviceId: device.id})} onClick={stopPropagation}>
            <OverflowTooltip>{device.name}</OverflowTooltip>
          </a>
        </Fragment>
      )}

      {showDevice && channelDevice && <Fragment>, </Fragment>}

      {channelDevice && (
        <Fragment>
          Channel:{' '}
          <a
            href={router.url('deviceDetails', {deviceId: channelDevice.id})}
            onClick={stopPropagation}
          >
            <OverflowTooltip>{channelDevice.name}</OverflowTooltip>
          </a>
        </Fragment>
      )}
    </span>
  );
};
