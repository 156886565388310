import React, {useEffect, useState} from 'react';
import {Box, Dialog, DialogContent, DialogTitle, Stack} from '@mui/material';
import {Callback, SxObj} from 'app/types/common';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {palette} from 'app/themes/app';
import {CopyButton} from 'app/components/sharedReactComponents/CopyButton';
import {THEME, VARIANT} from 'app/constants';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';
import {useMounted} from 'app/hooks/useIsMounted';

interface Props {
  open: boolean;
  generateLink: Callback<Promise<{link: string}>>;
  onClose: Callback;
}

type Styles = 'box' | 'link' | 'expire' | 'message' | 'action';

const styles: Record<Styles, SxObj> = {
  message: {color: palette.darkerGray, textAlign: 'center', mb: 2},
  box: {borderRadius: 2, backgroundColor: '#f3f3f3', minHeight: '280px', p: 3},
  link: {textDecoration: 'underline', wordBreak: 'break-all', textAlign: 'start', mb: 2},
  expire: {color: palette.darkerGray},
  action: {mt: 'auto'},
};

export function GeneratePairingLinkDialog({open, generateLink, onClose}: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [link, setLink] = useState('');
  const isMounted = useMounted();

  useEffect(() => {
    const generate = async () => {
      try {
        setIsLoading(true);
        const {link} = await generateLink();
        if (isMounted()) {
          setLink(link);
        }
      } finally {
        if (isMounted()) {
          setIsLoading(false);
        }
      }
    };

    if (open) {
      void generate();
    }
  }, [open, isMounted, generateLink]);

  const handleCopy = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(link);
    } else {
      document.execCommand('copy', false, link);
    }
  };

  const renderLink = () => {
    if (isLoading) {
      return <LoadingPlaceholder />;
    }

    return (
      <>
        <CopyButton
          value={link}
          buttonContent={<Box sx={styles.link}>{link}</Box>}
          onClick={handleCopy}
        />
        <Box sx={styles.expire}>Link will expire after 14 days</Box>

        <Box sx={styles.action}>
          <CopyButton
            value={link}
            variant={VARIANT.SOLID}
            theme={THEME.SUCCESS}
            onClick={handleCopy}
          />
        </Box>
      </>
    );
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm" onClose={onClose}>
      <DialogClose onClose={onClose} />
      <DialogTitle>
        If you are not the administrator
        <br />
        of your Microsoft organization
      </DialogTitle>
      <DialogContent>
        <Box sx={styles.message}>
          Please send this link to your Microsoft organization administrator and <br />
          ask them to go through the connection steps showed in the link.
        </Box>

        <Stack sx={styles.box} alignItems="center" justifyContent="center">
          {renderLink()}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
