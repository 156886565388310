import {Team, TeamContracts} from '../models';

export function mapMember(c: TeamContracts.Member): Team.Member {
  return {
    id: c.ID,
    name: c.Name,
    email: c.Email,
    loginType: c.LoginType,
    role: mapMemberRole(c.UserRole, c.IsMaster),
    pending: c.Status === 'pending',
    picture: c.Picture,
  };
}

function mapMemberRole(role: TeamContracts.Role, master: boolean): Team.MemberRole {
  if (role === 'owner') {
    return master ? 'owner' : 'admin';
  }

  return 'operator';
}
