import React from 'react';
import {Autocomplete, TextField} from '@mui/material';
import {Callback, ClassName, DataId} from 'app/types/common';
import {isNil} from 'app/util/isNil';

interface Props extends ClassName, DataId {
  value: string;
  error?: string;
  onChange: Callback<void, [string]>;
  onBlur?: Callback;
}

export const FramerateInput = ({className, value, dataId, error, onChange, onBlur}: Props) => {
  const onInputChange = (_e, value: string) => onChange(value.replace(/[^0-9]/g, ''));

  return (
    <Autocomplete
      className={className}
      data-id={dataId}
      options={[
        {
          label: '15',
          value: 15,
        },
        {
          label: '24',
          value: 24,
        },
        {
          label: '25',
          value: 25,
        },
        {
          label: '30',
          value: 30,
        },
        {
          label: '45',
          value: 45,
        },
        {
          label: '50',
          value: 50,
        },
      ]}
      freeSolo={true}
      forcePopupIcon={true}
      disableClearable={true}
      disablePortal={true}
      renderInput={(args) => (
        <TextField
          {...args}
          error={!isNil(error)}
          helperText={error}
          placeholder="Framerate"
          fullWidth={true}
        />
      )}
      inputValue={value}
      onInputChange={onInputChange}
      onBlur={onBlur}
    />
  );
};
