import {useState, useCallback} from 'react';

interface Return {
  eventToEdit?: string;
  createEvent(): void;
  editEvent(eventId: string): void;
  closeEditor(): void;
}

export function useEventEditor(): Return {
  const [eventToEdit, setEventToEdit] = useState<string | undefined>();

  const createEvent = useCallback(() => {
    setEventToEdit('');
  }, []);

  const editEvent = useCallback((id: string) => {
    setEventToEdit(id);
  }, []);

  const closeEditor = useCallback(() => {
    setEventToEdit(undefined);
  }, []);

  return {
    eventToEdit,
    editEvent,
    createEvent,
    closeEditor,
  };
}
