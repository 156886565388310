/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {CopyText} from 'app/components/pages/MeetingDetails/shared/CopyText/CopyText';
import {router} from 'app/router/main';
import {SrtStream} from 'app/store/models/connect/SrtStream/SrtStream';
import {Component} from 'app/types/common';
import {isParticipantStreamSettings} from 'app/types/guards/connect';

interface Props extends Component {
  stream: SrtStream;
  direction: 'to' | 'from';
  domain?: string;
}

export const SrtConnectionPoint = observer(
  ({className, dataId, stream, direction, domain = ''}: Props) => {
    if (!stream.settings) {
      return null;
    }

    const {settings, listener, connected} = stream;

    const renderFrom = () => {
      if (isParticipantStreamSettings(settings) && settings.integration) {
        return <Title>Epiphan Connect</Title>;
      }

      if (listener) {
        return <Url title="SRT listener at" url={settings.url} port={settings.port} />;
      }

      if (connected) {
        return <Title>SRT caller</Title>;
      }

      return <Url title="SRT caller to" url={settings.url} port={settings.port} />;
    };

    const renderTo = () => {
      if (isParticipantStreamSettings(settings) && settings.integration) {
        return (
          <a
            href={router.url(
              settings.cloud.type === 'device' ? 'deviceDetails' : 'projectDetails',
              {deviceId: settings.cloud.deviceId},
            )}
            target="_blank"
          >
            {settings.cloud.deviceName}
          </a>
        );
      }

      if (listener) {
        if (connected) {
          return <CopyText value={domain} />;
        }

        return <Title>Waiting for SRT caller</Title>;
      }

      if (connected) {
        return <Url title="SRT listener at" url={settings.url} port={settings.port} />;
      }

      return <Title>Waiting for SRT listener</Title>;
    };

    return (
      <div className={classNames('', className)} data-id={dataId}>
        {direction === 'from' ? renderFrom() : renderTo()}
      </div>
    );
  },
);

interface UrlProps extends Component {
  url: string;
  title: string;
  port: number;
}

function Url({className, url, title, port}: UrlProps) {
  return (
    <div className={className}>
      <div className="srt-connection-point__host">
        <Title>{title}</Title>
        <CopyText dataId="copy_url" value={url} />
      </div>

      {port > 0 && (
        <div className="srt-connection-point__port">
          <Title>Port</Title>
          <CopyText dataId="copy_port" value={port} />
        </div>
      )}
    </div>
  );
}

function Title({children}: React.PropsWithChildren<{}>) {
  return <div className="srt-connection-point__title">{children}</div>;
}
