import React from 'react';
import classNames from 'classnames';
import {SettingsControl} from 'app/components/sharedReactComponents/SettingsList/SettingsControl';
import {DROPDOWN_SELECT_THEME} from 'app/components/sharedReactComponents/DropdownSelect/constants';
import {useSettingChange} from 'app/components/sharedReactComponents/SettingsList/hooks';
import {Callback, ClassName} from 'app/types/common';
import type {SettingsItemTypeOptions, SettingsItemValue} from 'app/components/sharedReactComponents/SettingsList/types';
import {DeviceSettingType} from 'app/models/Device/Settings/constants';

interface Props extends ClassName {
  value: SettingsItemValue;
  type: DeviceSettingType;
  options: SettingsItemTypeOptions;
  name: string;
  label: string;
  description?: string;
  selectTheme?: DROPDOWN_SELECT_THEME;
  disabled: boolean;
  getSettingTitle: Callback;
  updateAction: Callback;
}
export const SettingsListItem: React.VFC<Props> = ({
  className,
  name,
  value,
  label,
  description,
  type,
  options,
  disabled,
  selectTheme,
  getSettingTitle,
  updateAction,
}) => {
  const [inputValue, loading, handleChange] = useSettingChange(name, value, updateAction);

  return (
    <div className={classNames('settings-list__item', className)}>
      <SettingsControl
        data-id={`settings_item_${name}`}
        label={label}
        type={type}
        value={inputValue}
        description={description}
        options={options}
        selectTheme={selectTheme}
        loading={loading}
        disabled={disabled}
        getSettingTitle={getSettingTitle}
        onChange={handleChange}
      />
    </div>
  );
};
