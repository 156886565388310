import {front} from 'app/api/CirrusApi';

const THROTTLING_DELAY_MILLISECONDS = 300;

class CommandDispatcher {
  protected commands = new Map();

  // TODO: TS {DEVICE_COMMAND_NAME|string} command
  async sendCommand(deviceId: string, command: string): Promise<any> {
    return this._sendCommand(deviceId, command, ({command, deviceIds}) => {
      return front.devices().batch_task().post({
        Devices: deviceIds,
        Task: {
          cmd: command,
        },
      });
    });
  }

  async audioLevels(deviceId: string): Promise<any> {
    return this._sendCommand(deviceId, '__audio_levels__', ({deviceIds}) => {
      return front.devices().request().audio_levels().post({
        Devices: deviceIds,
      });
    });
  }

  async channelPreviews(deviceId: string): Promise<any> {
    return this._sendCommand(deviceId, '__channel_previews__', ({deviceIds}) => {
      return front.devices().request().channel_previews().post({
        Devices: deviceIds,
      });
    });
  }

  async sourcePreviews(deviceId: string): Promise<any> {
    return this._sendCommand(deviceId, '__source_previews__', ({deviceIds}) => {
      return front.devices().request().source_previews().post({
        Devices: deviceIds,
      });
    });
  }

  private async _sendCommand(deviceId: string, command: string, request: (arg0: any) => Promise<any>): Promise<any> {
    if (this.commands.has(command) === false) {
      this.commands.set(command, {
        deviceIds: new Set(),
        commandCall: new Promise((resolve, reject) => {
          setTimeout(() => {
            request({
              command,
              deviceIds: [...this.commands.get(command).deviceIds],
            })
              .then((result) => {
                resolve(result);
              })
              .catch(reject);

            this.commands.delete(command);
          }, THROTTLING_DELAY_MILLISECONDS);
        }),
      });
    }

    if (!this.commands.get(command).deviceIds.has(deviceId)) {
      this.commands.get(command).deviceIds.add(deviceId);
    }

    return this.commands.get(command).commandCall;
  }
}

const DeviceCommandDispatcher = new CommandDispatcher();

export {
  DeviceCommandDispatcher,
};
