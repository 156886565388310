import {
  TimeStampMilliseconds,
  TimeStampSeconds,
} from 'app/types/common';

function createStampedImageSrc(imageSrc: string, timeStamp?: TimeStampMilliseconds | TimeStampSeconds): string {
  return `${imageSrc}${imageSrc.includes('?') ? '&' : '?'}v=${timeStamp ?? Date.now()}`;
}

export {
  createStampedImageSrc,
};
