import React, {useEffect, useState} from 'react';
import {pairRealDevice} from 'app/components/PairDevice/actions';
import {cancelDevicePairingAction} from 'app/components/OneClickDevicePairing/utils';
import {OneClickDevicePairingLoading} from 'app/components/OneClickDevicePairing/OneClickDevicePairingLoading';
import {PairDeviceContainer} from 'app/components/sharedReactComponents/PairDeviceContainer';
import {TeamSelector} from 'app/components/OneClickDevicePairing/PairDeviceForm/TeamSelector';
import {OneClickPairDeviceForm} from 'app/components/OneClickDevicePairing/PairDeviceForm';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {AppTeam} from 'app/models/AppTeam/AppTeam';
import {useMounted} from 'app/hooks/useIsMounted';
import {EdgePlan} from 'app/models/EdgePlan/EdgePlan';
import {BillingApiService} from 'app/services/api/billing/BillingApiService';
import {BillingMapper} from 'app/services/mappers/BillingMapper';
import {useCloudEntities} from 'app/components/entities/cloud';

interface Props {
  teamId: string;
  deviceId: string;
  teams: AppTeam[];
}

export function MultipleTeams({deviceId, teamId, teams}: Props) {
  const {
    user: {role},
  } = useCloudEntities();

  const [loading, setLoading] = useState(true);
  const isMounted = useMounted();

  const [selectedTeamId, setSelectedTeamId] = useState(teamId);
  const [plan, setPlan] = useState<EdgePlan | undefined>();
  const [count, setCount] = useState(0);
  const [confirmedWarningMessage, setConfirmedWarningMessage] = useState(false);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    setLoading(true);
    setConfirmedWarningMessage(false);

    const load = async () => {
      try {
        const result = await Promise.all([
          BillingApiService.getCloudPlan(selectedTeamId),
          DeviceApiService.getTeamPairedCount(selectedTeamId),
        ]);

        const plan = BillingMapper.mapEdgePlan(result[0]);
        const total = result[1];

        if (!isMounted()) {
          return;
        }

        setPlan(plan);
        setCount(total.count);

        const currentTeam = teams.find((t) => t.id === selectedTeamId);
        setEditable(currentTeam?.role?.canEditBilling() ?? false);
      } finally {
        setLoading(false);
      }
    };

    void load();
  }, [selectedTeamId, teams, isMounted]);

  const handleFormSubmit = async ({
    teamId,
    deviceGroupId,
  }: {
    teamId: string;
    deviceGroupId?: string;
  }) => {
    if (!plan) {
      return;
    }

    if (confirmedWarningMessage) {
      await BillingApiService.setPayment(teamId, {
        plan: plan.id,
        seat_count: count + 1,
      });
    }

    return pairRealDevice({
      deviceId,
      deviceGroupId,
      teamId,
      currentTeamId: selectedTeamId,
      currentUserRole: role,
      onError: cancelDevicePairingAction,
    });
  };

  const handleChangeSelectedTeamId = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTeamId(value);
  };

  if (loading || !plan) {
    return <OneClickDevicePairingLoading />;
  }

  return (
    <PairDeviceContainer
      pairedCount={count}
      loading={loading}
      teamTier={plan.tier}
      seatMonthPrice={plan.prices.seat}
      isUnlimitedProPlan={plan.unlimited}
      canEditBilling={editable}
      teamId={selectedTeamId}
      confirmedWarningMessage={confirmedWarningMessage}
      subheaderComponent={
        <TeamSelector
          className="one-click-device-pairing-page__team-selector"
          selected={selectedTeamId}
          loading={loading}
          teams={teams}
          onChange={handleChangeSelectedTeamId}
        />
      }
      onConfirmMessage={() => setConfirmedWarningMessage(true)}
    >
      <OneClickPairDeviceForm
        teamId={selectedTeamId}
        hasGroups={plan.premium}
        showCancelButton={confirmedWarningMessage}
        onCancel={() => setConfirmedWarningMessage(false)}
        onSubmit={handleFormSubmit}
      />
    </PairDeviceContainer>
  );
}
