import {useEffect, useMemo, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {isNil} from 'app/util/isNil';
import {Edge} from 'app/domain/edge';

export function useBatchFirmware(devices: AnyDeviceModelType[]) {
  const [upgradeable, setUpgradeable] = useState<AnyDeviceModelType[]>([]);

  const ids = useMemo(() => {
    return devices
      .filter((d) => d.isOnline())
      .map((d) => d.getId())
      .sort();
  }, [devices]);

  const enabledCheck = ids.length > 0;

  const {data, isInitialLoading, refetch} = useQuery({
    queryKey: ['batch-firmware', ...ids],
    queryFn: async () => {
      const response = await DeviceApiService.getDevicesFirmware(ids);
      return mapFirmware(response);
    },
    enabled: enabledCheck,
  });

  useEffect(() => {
    if (isNil(data)) {
      setUpgradeable([]);
      return;
    }

    const result = devices.filter((d) => {
      const firmware = data.get(d.getId());
      return firmware ? firmware.update?.status === 'available' : false;
    });

    setUpgradeable(result);
  }, [data, devices]);

  return {upgradeable, firmwareLoading: isInitialLoading, refetchFirmware: refetch};
}

function mapFirmware(c: Record<string, Edge.Firmware>): Map<string, Edge.Firmware> {
  const entries = Object.entries(c).map(([k, v]) => [k, v] as const);
  return new Map(entries);
}
