/* eslint-disable no-restricted-syntax */
import {DeviceModel, HealthStatus} from 'app/components/DeviceDetails/constants';
import {WarningSeverity} from 'app/constants/deviceWarningSeverity';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {Edge} from 'app/domain/edge';

function getHealthFromSeverity(severity: Edge.WarningSeverity): HealthStatus {
  switch (severity) {
    case WarningSeverity.Error:
      return HealthStatus.Bad;
    case WarningSeverity.Warning:
      return HealthStatus.Fair;
    default:
      return HealthStatus.Good;
  }
}

function getDeviceHealth(warnings: Edge.Warning[]): HealthStatus {
  let health = HealthStatus.Good;

  for (const warning of warnings) {
    if (warning.severity) {
      health = getHealthFromSeverity(warning.severity);
    }

    if (health === HealthStatus.Bad) {
      break;
    }
  }

  return health;
}

function isMultiChannelDevice(deviceData: Record<string, any>, modelName: DeviceModel): boolean {
  return Array.isArray(deviceData.Child) || ModelService.isMultiChannel(modelName);
}

export {isMultiChannelDevice, getDeviceHealth};
