import React from 'react';
import {Box, Divider, Grid, Link, Skeleton, Stack} from '@mui/material';
import {DataId, Sx} from 'app/types/common';
import {Schedule} from 'app/domain/schedule';
import {EventDefinition} from 'app/components/sharedReactComponents/Events/Calendar/shared/EventDefinition/EventDefinition';
import {EventChannel} from 'app/components/sharedReactComponents/Events/List/EventCard/EventChannel/EventChannel';
import {isCompleted, isOngoing, isScheduled} from 'app/domain/schedule/utils';
import {grey} from '@mui/material/colors';
import {Icons} from 'app/util/icons';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {router} from 'app/router/main';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {packSx} from 'app/util/packSx/packSx';
import {cardStyles} from 'app/components/sharedReactComponents/Events/styles';

interface Props extends Sx, DataId {
  event: Schedule.Event;
  devices?: Map<string, PearlMasterDeviceModel>;
}

export const EventDetails = React.memo(({dataId, sx, event, devices}: Props) => {
  const ongoing = isOngoing(event.status);
  const scheduled = isScheduled(event.status);
  const completed = isCompleted(event.status);

  return (
    <Stack
      direction="row"
      data-id={dataId}
      sx={packSx(
        cardStyles.root,
        ongoing ? cardStyles.ongoing : {},
        scheduled ? cardStyles.scheduled : {},
        completed ? cardStyles.completed : {},
        sx,
      )}
      minWidth={400}
      maxWidth={550}
      maxHeight={500}
    >
      <Stack flexGrow={1} gap={0.5} minWidth={0} divider={<Divider flexItem={true} />}>
        <EventDefinition event={event} />

        <Stack
          sx={{scrollbarGutter: 'stable'}}
          pr={1}
          overflow="auto"
          minHeight={0}
          gap={0.5}
          divider={<Divider sx={{borderStyle: 'dashed'}} flexItem={true} />}
        >
          {Array.from(event.devices.entries()).map(([deviceId, profile]) => {
            const master = devices?.get(deviceId);

            if (!master) {
              return <Skeleton key={deviceId} width={80} variant="text" />;
            }

            return (
              <Box key={deviceId}>
                <Stack direction="row" alignItems="center" gap={1} mb={0.5}>
                  <Box color={grey[500]}>{Icons.deviceCircuit().reactComponent()}</Box>

                  <Link
                    data-id="device-link"
                    sx={{minWidth: 0}}
                    href={router.url('deviceDetails', {deviceId}, {activeTab: 'events'})}
                  >
                    <OverflowTooltip rowsNumber={2}>{master.getName()}</OverflowTooltip>
                  </Link>
                </Stack>

                <Grid container={true} spacing={0.5}>
                  {Array.from(profile.entries()).map(([channelIdx, c]) => {
                    const channel = master.getChannelByIndex(channelIdx);

                    if (!channel) {
                      return null;
                    }

                    return (
                      <Grid key={channelIdx} item={true} xs={4}>
                        <EventChannel
                          key={channelIdx}
                          master={master}
                          channel={channel}
                          preview={false}
                          active={ongoing}
                          recording={c.recording}
                          streaming={c.streaming}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
});
