import {Team} from 'app/components/entities/team/models';
import {stringComparator} from 'app/util/Sort';

const roleOrder = {owner: 0, admin: 1, operator: 2};

export function sortMembers(users: Team.Member[], currentId: string): Team.Member[] {
  return users.sort((a, b) => {
    if (a.id === currentId) return -1;
    if (b.id === currentId) return 1;

    const roleComparison = roleOrder[a.role] - roleOrder[b.role];

    if (roleComparison !== 0) return roleComparison;

    return stringComparator(a.name, b.name);
  });
}
