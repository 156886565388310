import React, {forwardRef, KeyboardEvent} from 'react';
import classNames from 'classnames';
import {isNil} from 'app/util/isNil';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Icons} from 'app/util/icons';
import {WAI_ARIA_ROLE} from 'app/constants/waiAriaRole';
import {Callback, ClassName} from 'app/types/common';
import {KeyboardCode} from 'app/constants';

export interface SelectMenuOptionProps extends ClassName {
  children: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: Callback;
  title?: string;
  controlComponent?: React.ReactNode;
  withIcon?: boolean;
  name?: string;
  active?: boolean;
  disabled?: boolean;
  disabledReasonMessage?: string;
}

export const SelectMenuOption = forwardRef<any, SelectMenuOptionProps>(({
  className,
  children,
  icon,
  title,
  name,
  controlComponent,
  withIcon,
  active,
  disabled,
  disabledReasonMessage,
  onClick,
  ...elementProps
}, ref) => {
  withIcon = withIcon || isNil(icon) === false;
  const withControl = isNil(controlComponent) === false;

  const chooseOption = () => {
    if (disabled || typeof onClick !== 'function') {
      return;
    }

    onClick({
      target: {
        name,
        value: !active,
      }
    });
  };

  const handleClick = () => {
    chooseOption();
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.code === KeyboardCode.Enter) {
      chooseOption();
    }
  };

  return (
    <div
      ref={ref}
      className={classNames('select-menu-option', {
        'select-menu-option--with-control': withControl,
        'select-menu-option--with-icon': withIcon,
        'select-menu-option--active': active,
        'select-menu-option--disabled': disabled,
      }, className)}
      role={WAI_ARIA_ROLE.MENU_ITEM}
      tabIndex={0}
      onClick={handleClick}
      onKeyUp={handleKeyUp}
      {...elementProps}
    >
      {withControl && (
        <Tooltip content={(disabled && disabledReasonMessage) || undefined}>
          <span className="select-menu-option__control">
            {controlComponent}
          </span>
        </Tooltip>
      )}

      {icon && (
        <span className="select-menu-option__icon">
          {icon}
        </span>
      )}

      <Tooltip content={title}>
        <span className="select-menu-option__label">
          {children}
        </span>
      </Tooltip>

      {active && (
        <span
          className="select-menu-option__icon-active"
          data-id="select_menu_option_active_checkmark"
        >
          {Icons.checkmark().reactComponent()}
        </span>
      )}
    </div>
  );
});

SelectMenuOption.displayName = 'SelectMenuOption';
