import {isUUID} from 'app/util/isUUID';

function getTeamIdFromCurrentUrl(): string | null {
  const teamIdFromURL = window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1);

  if (isUUID(teamIdFromURL)) {
    return teamIdFromURL;
  }

  return null;
}

export {
  getTeamIdFromCurrentUrl,
};
