import React, {useState} from 'react';
import {Box, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {joiResolver} from '@hookform/resolvers/joi';
import Joi from 'joi';
import {palette} from 'app/themes/app';
import {isNil} from 'app/util/isNil';
import {BUTTON_TYPE, ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {THEME, VARIANT} from 'app/constants';
import {useMounted} from 'app/hooks/useIsMounted';

interface Props {
  onSubmit: Fn<Promise<any>, [pwd: string]>;
}

interface FormData {
  passcode: string;
}

const scheme = Joi.object<FormData>({
  passcode: Joi.string().trim().required().messages({'string.empty': 'Cannot be empty'}),
});

export default function ZoomPasscode({onSubmit}: Props) {
  const [confirming, setConfirming] = useState(false);
  const mounted = useMounted();

  const {
    control,
    formState: {isValid},
    handleSubmit,
    setError,
    clearErrors,
  } = useForm<FormData>({
    defaultValues: {passcode: ''},
    resolver: joiResolver(scheme),
  });

  const handleApply = async (data: FormData) => {
    try {
      setConfirming(true);
      await onSubmit(data.passcode);
    } catch {
      setError('passcode', {type: 'manual', message: 'Incorrect passcode. Please try again.'});
    } finally {
      if (mounted()) {
        setConfirming(false);
      }
    }
  };

  return (
    <Box sx={{py: 7, px: 7}}>
      <form onSubmit={handleSubmit(handleApply)}>
        <Box sx={{mb: 3}}>
          <img
            src="/assets/img/logo/zoom-logo.png"
            srcSet="/assets/img/logo/zoom-logo@2x.png 2x"
            alt=""
          />
        </Box>

        <Typography variant="h5" fontWeight={600}>
          Invalid or missing passcode
        </Typography>

        <Box sx={{mt: 0.5, mb: 3, color: palette.darkerGray}}>
          Enter you passcode. It can be found in your meeting invitation or meeting details page.
        </Box>

        <Controller
          control={control}
          name="passcode"
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              inputProps={{'data-id': 'zoom-pwd'}}
              InputProps={{sx: {width: 170}}}
              autoFocus={true}
              variant="standard"
              placeholder="Passcode"
              helperText={error?.message ?? ' '}
              error={!isNil(error)}
              onChange={(e) => {
                clearErrors();
                field.onChange(e);
              }}
            />
          )}
        />

        <Box sx={{mt: 3, '& button': {width: 154}}}>
          <ProgressButton
            type={BUTTON_TYPE.SUBMIT}
            dataId="zoom_pwd_button"
            loading={confirming}
            theme={THEME.PRIMARY}
            variant={VARIANT.SOLID}
            disabled={!isValid}
          >
            Apply
          </ProgressButton>
        </Box>
      </form>
    </Box>
  );
}
