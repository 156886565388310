import React, {useState} from 'react';
import {Button, IconButton, Menu, MenuItem, Stack, Tooltip, capitalize} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {Sx} from 'app/types/common';
import {RemoveMemberDialog, Team, TransferOwnershipDialog} from 'app/components/entities/team';

interface Props extends Sx {
  member: Team.Member;
  current: boolean;
  teamName: string;
  permitEdit: boolean;
  permitTransfer: boolean;
  hasPayment: boolean;
  onSetRole: (userId: string, role: 'admin' | 'operator') => Promise<void>;
  onTransferOwnership: (userId: string) => Promise<void>;
  onDelete: (userId: string) => Promise<void>;
}

export function MemberActions({
  sx,
  member: user,
  current,
  permitEdit,
  permitTransfer,
  teamName,
  hasPayment,
  onSetRole,
  onDelete,
  onTransferOwnership,
}: Props) {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [transferDialog, setTransferDialog] = useState(false);

  const disabledDelete = !permitEdit || current || user.role === 'owner';
  const disableRoleChange = disabledDelete || user.pending;

  const open = Boolean(menuAnchor);
  const showTransfer = permitTransfer && !user.pending;

  const handleSetRole = async (role: 'admin' | 'operator') => {
    await onSetRole(user.id, role);
    setMenuAnchor(null);
  };

  const handleDelete = async () => {
    await onDelete(user.id);
    setDeleteDialog(false);
  };

  const handleTransfer = async () => {
    await onTransferOwnership(user.id);
    setTransferDialog(false);
  };

  const handleInitTransfer = () => {
    setTransferDialog(true);
    setMenuAnchor(null);
  };

  return (
    <>
      <Stack sx={sx} direction="row" alignItems="center" gap={1}>
        <Button
          variant="outlined"
          color="inherit"
          disabled={disableRoleChange}
          onClick={(e) => setMenuAnchor(e.currentTarget)}
        >
          {capitalize(user.role)}
        </Button>

        <Menu open={open} anchorEl={menuAnchor} onClose={() => setMenuAnchor(null)}>
          {showTransfer && (
            <MenuItem selected={user.role === 'owner'} onClick={() => handleInitTransfer()}>
              Owner
            </MenuItem>
          )}
          <MenuItem selected={user.role === 'admin'} onClick={() => handleSetRole('admin')}>
            Admin
          </MenuItem>
          <MenuItem selected={user.role === 'operator'} onClick={() => handleSetRole('operator')}>
            Operator
          </MenuItem>
        </Menu>

        <Tooltip title="Remove team member">
          <IconButton color="error" disabled={disabledDelete} onClick={() => setDeleteDialog(true)}>
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Stack>

      <RemoveMemberDialog
        open={deleteDialog}
        name={user.name}
        onDelete={handleDelete}
        onClose={() => setDeleteDialog(false)}
      />

      <TransferOwnershipDialog
        open={transferDialog}
        hasPayment={hasPayment}
        teamName={teamName}
        userName={user.name}
        onTransfer={handleTransfer}
        onClose={() => setTransferDialog(false)}
      />
    </>
  );
}
