import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {FORM_CONTROL_AUTOCOMPLETE} from 'app/constants/formControlAutocomplete';
import {Icons} from 'app/util/icons';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {DeviceGroupSelector} from 'app/components/PairDevice/PairDeviceForm/DeviceGroupSelector';
import {PairDeviceSubmitButton} from 'app/components/PairDevice/PairDeviceForm/PairDeviceSubmitButton';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Callback, Validation} from 'app/types/common';
import {EmptyStringValidator} from 'app/util/validators/EmptyStringValidator';
import {NameValidator} from 'app/util/validators/NameValidator';

const DEVICE_ID_HELP_MESSAGE =
  "Your device's pairing code shows on the " +
  "front screen of device when it's not " +
  "already paired. For Pearl, it's found on the " +
  'Epiphan Cloud status page in the Admin panel.';

export type PairingFormData = {
  name: string;
  groupId: string;
  code: string;
};

const getDefaultForm = (): PairingFormData => {
  return {
    name: '',
    groupId: '',
    code: '',
  };
};

interface Props {
  currentTeamId: string;
  hasGroups: boolean;
  showCancelButton: boolean;
  onSubmit: Callback<Promise<unknown>, [PairingFormData]>;
  onCancel: Callback;
}

const formValidators = {
  code: [new EmptyStringValidator({message: 'Enter device pairing code'})],
  name: [new EmptyStringValidator({message: 'Enter device name'}), new NameValidator()],
};

export function PairDeviceForm({
  currentTeamId,
  hasGroups,
  showCancelButton,
  onSubmit,
  onCancel,
}: Props) {
  const [formData, setFormData] = useState<PairingFormData>(() => getDefaultForm());
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState<Validation>({
    valid: false,
    errorMessage: '',
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      await onSubmit(formData);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    for (const [field, validators] of Object.entries(formValidators)) {
      for (const validator of validators) {
        if (!validator.validate(formData[field])) {
          setValidation({
            valid: false,
            errorMessage: validator.getErrorMessage(),
          });
          return;
        }
      }
    }

    setValidation({
      valid: true,
      errorMessage: '',
    });
  }, [formData]);

  const handleChange = useCallback(({target: {name, value}}) => {
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  }, []);

  return (
    <form className="pair-device-form" action="" onSubmit={handleSubmit}>
      <div className="pair-device-form__form-controls">
        <FormControl
          data-id="device_pairing_code_input"
          className="pair-device-form__form-control"
          value={formData.code}
          name="code"
          label={
            <Fragment>
              Pairing code
              <Tooltip content={DEVICE_ID_HELP_MESSAGE}>
                {Icons.helpCircle().size(SIZE.S).reactComponent()}
              </Tooltip>
            </Fragment>
          }
          placeholder="12345678"
          autoComplete={FORM_CONTROL_AUTOCOMPLETE.OFF}
          autoFocus={true}
          fullWidth={true}
          disabled={loading}
          onChange={handleChange}
        />

        <FormControl
          data-id="device_name_input"
          className="pair-device-form__form-control"
          value={formData.name}
          name="name"
          label="Device name"
          placeholder="Name your device"
          autoComplete={FORM_CONTROL_AUTOCOMPLETE.OFF}
          disabled={loading}
          fullWidth={true}
          onChange={handleChange}
        />

        {hasGroups && (
          <DeviceGroupSelector
            name="groupId"
            className="pair-device-form__form-control"
            value={formData.groupId || undefined}
            teamId={currentTeamId}
            disabled={loading}
            onChange={handleChange}
          />
        )}
      </div>

      <div className="pair-device-form__form-actions">
        <PairDeviceSubmitButton
          className="pair-device-form__form-button"
          loading={loading}
          disabled={!validation.valid}
          tooltip={validation.errorMessage}
        />

        {showCancelButton && (
          <Button
            dataId="cancel_pair_device_button"
            className="pair-device-form__form-button"
            variant={VARIANT.OUTLINE}
            theme={THEME.SECONDARY}
            size={SIZE.L}
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
}
