import React from 'react';
import {StartStopTranscriptionButton} from 'app/components/sharedReactComponents/StartStopTranscriptionButton';
import {TranscribeDuration} from 'app/components/sharedReactComponents/TranscribeDuration';
import {SIZE, VARIANT} from 'app/constants';
import {DeviceAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/DeviceAudioMeter';
import {isDisabledAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/utils';
import {OnlineIndicator} from 'app/components/sharedReactComponents/OnlineIndicator';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {TableColumnType} from 'app/components/sharedReactComponents/Table/types';

const nameCell = (): TableColumnType => ({
  dataKey: 'name',
  label: 'Device',
  width: 215,
  flexGrow: 1,
  cellRenderer: ({cellData, rowData}) => (
    <a href={rowData.detailsURL}>
      <OverflowTooltip
        rowsNumber={2}
        dataId="table_cell_name"
      >
        {cellData}
      </OverflowTooltip>
    </a>
  ),
});

const transcribingCell = ({
  teamCanTranscribing,
  startStopTranscribingAction,
}): TableColumnType => ({
  dataKey: 'transcribing',
  label: 'Transcription',
  width: 250,
  comparator: (a, b) => {
    if (a.active === b.active) {
      return 0;
    }

    if (a.active === true) {
      return -1;
    }

    return 1;
  },
  cellRenderer: ({cellData, rowData}) => (
    <div className="transcribe-devices-transcription-cell">
      <div className="transcribe-devices-transcription-cell__duration">
        <TranscribeDuration
          deviceId={rowData.id!}
          sessionId={cellData.sessionId}
          status={cellData.status}
        />
      </div>

      <StartStopTranscriptionButton
        className="transcribe-devices-transcription-cell__button"
        deviceId={rowData.id!}
        status={cellData.status}
        disabled={!rowData.online}
        teamCanTranscribing={teamCanTranscribing}
        size={SIZE.S}
        action={startStopTranscribingAction}
      />
    </div>
  )
});

const deviceAudioCell = (): TableColumnType => ({
  dataKey: 'audioLevel',
  disableSort: true,
  label: 'Audio',
  flexGrow: 1,
  cellRenderer: ({rowData}) => (
    <DeviceAudioMeter
      id={rowData.deviceModel.getId()}
      disabled={isDisabledAudioMeter(rowData.deviceModel)}
    />
  )
});

const statusCell = (): TableColumnType => ({
  dataKey: 'online',
  width: 90,
  label: 'Online',
  cellRenderer: ({cellData}) => (
    <OnlineIndicator
      online={cellData}
      variant={VARIANT.SOLID}
      size={SIZE.M}
    />
  ),
});

export {
  nameCell,
  transcribingCell,
  deviceAudioCell,
  statusCell,
};
