import React from 'react';
import {Component, Sx} from 'app/types/common';
import {capitalize, Stack} from '@mui/material';
import {CurrentTierMark} from 'app/components/sharedReactComponents/CurrentTierMark/CurrentTierMark';
import {formatTimeUnit} from 'app/components/BillingManager/dialogs/shared/utils';

interface Props extends Component, Sx {
  current: boolean;
  timeUnit: App.Billing.TimeUnit;
}

export function PremiumSubtitle({dataId, current, timeUnit}: Props) {
  return (
    <Stack justifyContent="center" data-id={dataId} sx={{height: '22px'}}>
      {current ? <CurrentTierMark /> : capitalize(formatTimeUnit(timeUnit))}
    </Stack>
  );
}
