import React from 'react';
import classNames from 'classnames';
import {AudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/AudioMeter/AudioMeter';
import {ClassName, DataId} from 'app/types/common';

interface Props extends ClassName, DataId {
  valueL?: number;
  valueR?: number;
  segmentsCount?: number;
  segmentsGap?: number;
  vertical?: boolean;
}

export const AudioMeterStereo: React.FC<Props> = React.memo(
  ({className, valueL, valueR, segmentsCount, segmentsGap, vertical}) => {
    return (
      <div
        className={classNames(
          'stereo-audio-meter',
          vertical && 'stereo-audio-meter--vertical',
          className,
        )}
      >
        <AudioMeter
          className="stereo-audio-meter__channel"
          data-id="audio_meter_stereo_left"
          value={valueL}
          segmentsCount={segmentsCount}
          segmentsGap={segmentsGap}
          vertical={vertical}
        />

        <AudioMeter
          className="stereo-audio-meter__channel"
          data-id="audio_meter_stereo_right"
          value={valueR}
          segmentsCount={segmentsCount}
          segmentsGap={segmentsGap}
          vertical={vertical}
        />
      </div>
    );
  },
);
