import React from 'react';
import classNames from 'classnames';
import {Panel, PANEL_THEME} from 'app/components/sharedReactComponents/Panel';
import {RecordingButton} from 'app/components/sharedReactComponents/ActionButtons/RecordingButton';
import {StartedAtNote} from 'app/components/sharedReactComponents/StartedAtNote';
import {THEME} from 'app/constants';
import {DeviceRecordingPropsList} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceRecordingPanel/DeviceRecordingPropsList';
import {ClassName} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

interface Props extends ClassName {
  device: AnyDeviceModelType;
  hasEvent?: boolean;
}

export const DeviceRecordingPanel: React.FC<Props> = ({className, device, hasEvent}) => {
  const recordingStartTime = device.getDeviceRecordingStartTime();

  return (
    <Panel
      className={classNames('device-recording-panel', className)}
      title="Recording"
      theme={PANEL_THEME.DEVICE_DETAILS_ACTIONS}
    >
      <div className="device-recording-panel__content">
        <DeviceRecordingPropsList device={device} />

        <div className="device-recording-panel__actions">
          {device.isRecording() && recordingStartTime && (
            <StartedAtNote
              className="device-recording-panel__started-at"
              dataId="device_recording_started_at"
              timestamp={recordingStartTime}
              theme={THEME.DANGER}
            />
          )}

          <RecordingButton device={device} hasEvent={hasEvent} />
        </div>
      </div>
    </Panel>
  );
};
