import React, {useCallback} from 'react';
import classNames from 'classnames';
import {KeyboardCode, SIZE} from 'app/constants';
import {useRadioGroup} from 'app/components/sharedReactComponents/RadioGroup/useRadioGroup';
import {isNil} from 'app/util/isNil';
import {stopPropagation} from 'app/util/stopPropagation';
import {noop} from 'app/util/noop';
import {LoadingWrapper} from 'app/components/sharedReactComponents/LoadingWrapper';
import {Callback, ClassName, DataId} from 'app/types/common';

export enum RADIO_THEME {
  DEFAULT = 'default',
  BLACK = 'black',
}

function processEventData({target: {name, value}}) {
  return {
    target: {
      value,
      name,
    },
  };
}

// rework typing
interface Props extends ClassName, DataId {
  name?: string;
  value: string;
  checked?: boolean;
  label?: React.ReactNode;
  helperText?: string;
  theme?: RADIO_THEME;
  size?: SIZE;
  focusable?: boolean;
  uncheckable?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onChange?: Callback;
  onBlur?: Callback;
  onClick?: Callback;
}

export const Radio: React.FC<Props> = ({
  className,
  name: nameProp,
  value,
  checked: checkedProp,
  label,
  helperText,
  theme,
  size,
  focusable,
  uncheckable: uncheckableProp,
  disabled: disabledProp,
  loading,
  onChange,
  onBlur,
  onClick,
  dataId,
}) => {
  const radioGroup = useRadioGroup();

  let checked = checkedProp;
  let uncheckable = uncheckableProp;
  let name = nameProp;
  let disabled = disabledProp;

  if (radioGroup) {
    if (isNil(checked)) {
      checked = radioGroup.value === value;
    }

    if (isNil(uncheckable)) {
      uncheckable = radioGroup.uncheckable;
    }

    if (isNil(name)) {
      name = radioGroup.name;
    }

    if (disabled !== true) {
      disabled = radioGroup.disabled || radioGroup.loading;
    }
  }

  const handleClick = (e) => {
    if (disabled) {
      return;
    }

    if (onClick) {
      onClick(e);
    }

    if (checked && uncheckable !== true) {
      return;
    }

    if (onChange) {
      onChange(createChangeEvent());
    }

    if (radioGroup?.onChange) {
      radioGroup.onChange(createChangeEvent());
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (disabled) {
      return;
    }

    if (checked && uncheckable !== true) {
      return;
    }

    if (e.code === KeyboardCode.Space) {
      if (onChange) {
        onChange(createChangeEvent());
      }

      if (radioGroup?.onChange) {
        radioGroup.onChange(createChangeEvent());
      }
    }
  };

  const handleBlur = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(processEventData(e));
      }
    },
    [onBlur],
  );

  function createChangeEvent() {
    return {
      target: {
        name,
        value: checked ? undefined : value,
      },
    };
  }

  const tabIndex = focusable === false ? -1 : undefined;

  return (
    <LoadingWrapper loading={loading}>
      <label
        data-id={dataId}
        className={classNames(
          'radio',
          {
            'radio--black': theme === RADIO_THEME.BLACK,
            [`radio--size-${size}`]: Boolean(size),
            'radio--labeled': Boolean(label),
          },
          className,
        )}
        tabIndex={tabIndex}
        onClick={handleClick}
        onKeyUp={handleKeyUp}
      >
        <input
          type="radio"
          name={name}
          checked={checked}
          value={value}
          tabIndex={tabIndex}
          disabled={disabled || loading}
          onChange={noop}
          onBlur={handleBlur}
          onClick={stopPropagation}
        />
        <span />

        {label && <span className="radio__label">{label}</span>}

        {label && helperText && <div className="radio__helper">{helperText}</div>}
      </label>
    </LoadingWrapper>
  );
};
