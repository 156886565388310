import {useCallback, useEffect, useMemo, useState} from 'react';
import {produce} from 'immer';
import {throttle} from 'throttle-debounce';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {createDeviceModel} from 'app/components/DeviceDetails/Models/Fabric';
import {Ws} from 'app/contracts/ws';
import {Schedule} from 'app/domain/schedule';
import {useDevicesState} from 'app/hooks/useDevicesState';
import {useDeviceScheduleQuery} from 'app/components/DeviceDetails/hooks/useDeviceScheduleQuery';
import {useGetEventsDevices} from 'app/components/sharedReactComponents/Events/hooks/useGetEventsDevices';
import {useScheduleSnapshot} from 'app/components/sharedReactComponents/Events/hooks/useScheduleSnapshot';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {parseMasterAndChannelIds} from 'app/components/DeviceDetails/utils';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {ScheduleMapper} from 'app/util/mappers/ScheduleMapper';
import {useWsMessageQueue} from 'app/hooks/useWsMessageQueue';
import {getCurrentPeriod, sortEvents} from 'app/components/sharedReactComponents/Events/utils';
import {useDeviceId} from 'app/hooks/Device/useDeviceId';
import {setDesiredState, setPublishers, setUnitStatus} from 'app/components/features/edge/utils';

interface Args {
  enabled: boolean;
  teamId: string;
  device: PearlMasterDeviceModel;
}

type Return = {
  cms?: Schedule.Cms;
  events: Schedule.Event[];
  devices: Map<string, PearlMasterDeviceModel>;
  period: Schedule.Period;
  ready: boolean;
  fetching: boolean;
  setPeriod: React.Dispatch<React.SetStateAction<Schedule.Period>>;
  getActualEvent(id: string): Schedule.Event | undefined;
  checkStartIsAllowed: (event: Schedule.Event) => boolean;
  refetchSchedule(): Promise<any>;
};

export function useDeviceSchedule({teamId, device, enabled}: Args): Return {
  const queryClient = useQueryClient();

  const [masterId] = useDeviceId(device.getId());

  const [ready, setReady] = useState(!enabled);

  const [events, setEvents] = useState<Schedule.Event[]>([]);

  const [period, setPeriod] = useState<Schedule.Period>(() => getCurrentPeriod());

  const {map: deviceMap, setMap: setDeviceMap, setDevice, get: getDevice} = useDevicesState();

  const scheduleQuery = useDeviceScheduleQuery({
    deviceId: masterId,
    enabled,
    period,
  });

  const {refetch: refetchSchedule} = scheduleQuery;

  const {getActualEvent, checkStartIsAllowed} = useScheduleSnapshot({events, enabled});

  const {queue: scheduleQueue, setQueue: setScheduleQueue} =
    useWsMessageQueue<Ws.ScheduleEventChange>();

  const participatedDevices = useGetEventsDevices(scheduleQuery.data?.events);

  const observedDevices = useMemo(() => {
    return participatedDevices.filter((id) => id !== masterId);
  }, [masterId, participatedDevices]);

  const hasObservedDevices = enabled && observedDevices.length > 0;

  const scheduleDevicesQuery = useQuery({
    queryKey: ['device-schedule-devices', teamId, ...observedDevices],
    queryFn: async () => {
      const response = await DeviceApiService.getUnits(observedDevices);
      const mapped = response.map((d) => createDeviceModel(d));
      return new Map(mapped.map((m) => [m.getId(), m]));
    },
    enabled: hasObservedDevices,
    keepPreviousData: true,
  });

  const captureScheduleWs = scheduleQuery.isFetching;

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const devices = new Map<string, PearlMasterDeviceModel>();

    scheduleDevicesQuery.data?.forEach((d, deviceId) => {
      if (d instanceof PearlMasterDeviceModel) {
        devices.set(deviceId, d);
      }
    });

    devices.set(device.getId(), device);

    setDeviceMap(devices);
  }, [enabled, device, scheduleDevicesQuery.data, setDeviceMap]);

  useEffect(() => {
    if (!observedDevices.length) {
      return;
    }

    const observed = new Set(observedDevices);

    const onDeviceChange = (message: Ws.DeviceChange) => {
      const {DeviceID: deviceId} = message.Body;

      if (!observed.has(deviceId)) {
        return;
      }

      const instance = createDeviceModel(message.Body.Payload);
      setDevice(instance);
    };

    const onStatusChange = (message: Ws.DeviceStatusChange) => {
      const {DeviceID: deviceId} = message.Body;

      if (!observed.has(deviceId)) {
        return;
      }

      const instance = getDevice(deviceId);

      if (instance) {
        const copy = instance.getInnerModel();
        const updated = setUnitStatus(copy, message);
        setDevice(createDeviceModel(updated));
      }
    };

    const onStateChange = (message: Ws.DeviceDesiredState) => {
      const {DeviceID: deviceId} = message.Body;

      if (!observed.has(deviceId)) {
        return;
      }

      const instance = getDevice(deviceId);

      if (instance) {
        const copy = instance.getInnerModel();
        const updated = setDesiredState(copy, message.Body.Payload);
        setDevice(createDeviceModel(updated));
      }
    };

    const onPublishersChange = (message: Ws.DevicePublishers) => {
      const {DeviceID: deviceId} = message.Body;

      if (!observed.has(deviceId)) {
        return;
      }

      const instance = getDevice(deviceId);

      if (instance) {
        const copy = instance.getInnerModel();
        const updated = setPublishers(copy, message.Body.Payload);
        setDevice(createDeviceModel(updated));
      }
    };

    const onRenameDevice = (message: Ws.DeviceRename) => {
      const {DeviceID: deviceId} = message.Body;

      const [masterId, channelIdx] = parseMasterAndChannelIds(deviceId);

      if (!observed.has(masterId)) {
        return;
      }

      const instance = getDevice(masterId);

      if (!instance) {
        return;
      }

      const copy = instance.getInnerModel();

      if (channelIdx) {
        const channels = (copy.Child ?? []).map((channel) =>
          channel.Id === deviceId ? {...channel, Name: message.Body.Payload.Name} : channel,
        );

        setDevice(createDeviceModel({...copy, Child: channels}));
        return;
      }

      setDevice(createDeviceModel({...copy, Name: message.Body.Payload.Name}));
    };

    WS.on(EVENT_TYPE.DEVICE_CHANGE, onDeviceChange);
    WS.on(EVENT_TYPE.DEVICE_STATUS, onStatusChange);
    WS.on(EVENT_TYPE.DEVICE_DESIRED_STATE, onStateChange);
    WS.on(EVENT_TYPE.DEVICE_PUBLISHERS, onPublishersChange);
    WS.off(EVENT_TYPE.DEVICE_RENAME, onRenameDevice);

    return () => {
      WS.off(EVENT_TYPE.DEVICE_CHANGE, onDeviceChange);
      WS.off(EVENT_TYPE.DEVICE_STATUS, onStatusChange);
      WS.off(EVENT_TYPE.DEVICE_DESIRED_STATE, onStateChange);
      WS.off(EVENT_TYPE.DEVICE_PUBLISHERS, onPublishersChange);
      WS.off(EVENT_TYPE.DEVICE_RENAME, onRenameDevice);
    };
  }, [observedDevices, setDevice, getDevice]);

  const handleEventStatusChange = useCallback(
    (message: Ws.ScheduleEventChange) => {
      queryClient.setQueriesData<Schedule.DeviceSchedule>(
        ['device-schedule', masterId],
        (schedule) => {
          if (schedule) {
            const {events, cms} = schedule;
            const mapped = ScheduleMapper.mapEvent(message.Body);

            if (events.has(mapped.id)) {
              return {
                cms,
                events: produce(events, (map) => {
                  map.set(mapped.id, mapped);
                }),
              };
            }

            return {cms, events};
          }

          return schedule;
        },
      );
    },
    [queryClient, masterId],
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const onScheduleChange = async (data: Ws.ScheduleChange) => {
      if (device.getId() === data.Body.DeviceID) {
        await refetchSchedule();
      }
    };

    const throttled = throttle(3000, onScheduleChange);

    WS.on(EVENT_TYPE.EVENT_SCHEDULE_CHANGED, throttled);

    return () => {
      WS.off(EVENT_TYPE.EVENT_SCHEDULE_CHANGED, throttled);
    };
  }, [enabled, device, refetchSchedule]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const onChronoEventChange = async (m: Ws.ScheduleEventChange) => {
      if (captureScheduleWs) {
        setScheduleQueue((p) => [...p, m]);
      } else {
        handleEventStatusChange(m);
      }
    };

    WS.on(EVENT_TYPE.CHRONO_EVENT_CHANGED, onChronoEventChange);

    return () => {
      WS.off(EVENT_TYPE.CHRONO_EVENT_CHANGED, onChronoEventChange);
    };
  }, [enabled, captureScheduleWs, handleEventStatusChange, setScheduleQueue]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (!captureScheduleWs && scheduleQueue.length > 0) {
      scheduleQueue.forEach((msg) => {
        handleEventStatusChange(msg);
      });
      setScheduleQueue([]);
    }
  }, [enabled, captureScheduleWs, scheduleQueue, handleEventStatusChange, setScheduleQueue]);

  useEffect(() => {
    const events = scheduleQuery.data?.events.values();
    const list = Array.from(events ?? []).sort(sortEvents);
    setEvents(list);
  }, [scheduleQuery.data]);

  useEffect(() => {
    if (scheduleQuery.isSuccess) {
      if (hasObservedDevices) {
        if (scheduleDevicesQuery.isSuccess) {
          setReady(true);
        }
      } else {
        setReady(true);
      }
    }
  }, [scheduleQuery.isSuccess, hasObservedDevices, scheduleDevicesQuery.isSuccess]);

  return {
    period,
    events,
    ready,
    cms: scheduleQuery.data?.cms,
    devices: deviceMap as Map<string, PearlMasterDeviceModel>,
    fetching: scheduleQuery.isFetching,
    setPeriod,
    refetchSchedule,
    getActualEvent,
    checkStartIsAllowed,
  };
}
