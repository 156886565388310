import React from 'react';
import {Sx} from 'app/types/common';
import {Box, Typography} from '@mui/material';
import {pluralizeN} from 'app/components/helpers/commonHelpers';

type Props = Sx & {
  groups: number;
  devices: number;
  channels: number;
};

export function SelectionSummary({sx, devices, channels, groups}: Props) {
  return (
    <Box sx={sx}>
      {groups > 0 && (
        <Typography fontWeight={600}>
          {groups} {pluralizeN('Group', groups)}
        </Typography>
      )}

      {devices > 0 && (
        <Typography fontWeight={600}>
          {devices} {pluralizeN('Device', devices)}
        </Typography>
      )}

      {channels > 0 && (
        <Typography>
          {channels} {pluralizeN('Channel', channels)}
        </Typography>
      )}
    </Box>
  );
}
