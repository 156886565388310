import React from 'react';
import classNames from 'classnames';
import {AccountInfo} from 'app/components/sharedReactComponents/StreamingDestinationSettings/AccountInfo';
import {Footer} from 'app/components/sharedReactComponents/StreamingDestinationSettings/Footer';
import {StreamingDestinationSettingsForm} from 'app/components/sharedReactComponents/StreamingDestinationSettings/StreamingDestinationSettingsForm';
import {DestinationSelect} from 'app/components/sharedReactComponents/StreamingDestinationSettings/DestinationSelect';
import {Callback, ClassName} from 'app/types/common';
import {StreamingDestinationControl} from 'app/components/sharedReactComponents/StreamingDestinationSettings/types';

/**
 * @param {YoutubeStreamingDestinationModel} streamingDestination
 * @returns {{publishDestination: YOUTUBE_STREAMING_DESTINATION, publishDestinationID: string, previewBeforeStart: boolean}}
 */
function getProcessedFormData(streamingDestination) {
  return {
    publishDestination: streamingDestination.getDestinationSetting(),
    publishDestinationID: streamingDestination.getDestinationIDSetting(),
    previewBeforeStart: streamingDestination.isPreviewAfterPlaying(),
  };
}

interface Props extends ClassName {
  streamingDestination: any;
  updating: boolean;
  deleting?: boolean;
  disabled: boolean;
  onChange: Callback;
  onClickDelete: Callback;
}

export const YoutubeStreamingDestinationSettings: React.FC<Props> = ({
  className,
  streamingDestination,
  updating,
  deleting,
  disabled,
  onChange,
  onClickDelete,
}) => {
  const formData = getProcessedFormData(streamingDestination);

  const controls: StreamingDestinationControl[] = [
    {
      label: 'Youtube account',
      value: streamingDestination,
      componentRenderer: ({value}) => <AccountInfo streamingDestination={value}/>
    },
    {
      label: 'YouTube streaming destination',
      name: 'publishDestinationID',
      componentRenderer: (props, {publishDestination}) => (
        <DestinationSelect
          {...props}
          data-id="streaming_destination_select_destination_input"
          publishDestination={publishDestination}
          streamingDestinationId={streamingDestination.getId()}
        />
      ),
    },
    {
      label: 'Use YouTube to preview before going live',
      name: 'previewBeforeStart',
      type: 'checkbox',
    },
  ];

  return (
    <div className={classNames('streaming-destination-settings', className)}>
      <StreamingDestinationSettingsForm
        formData={formData}
        controls={controls}
        disabled={disabled}
        loading={updating}
        onSave={onChange}
      />

      <Footer
        deleteButtonText="Forget this destination"
        deleting={deleting}
        disabled={disabled}
        onClickDelete={onClickDelete}
      />
    </div>
  );
};
