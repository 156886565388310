import {useQuery} from '@tanstack/react-query';
import {BillingApiService} from 'app/services/api/billing/BillingApiService';
import {BillingMapper} from 'app/services/mappers/BillingMapper';

interface Args {
  teamId: string;
  enabled: boolean;
}

export function useCloudAndUnifyReport({teamId, enabled}: Args) {
  return useQuery({
    queryKey: ['cloud-invoices', teamId],
    queryFn: () => BillingApiService.getCloudAndUnifyReport(teamId),
    select: BillingMapper.mapCloudAndUnifyReport,
    enabled,
  });
}
