import {getTeamIdFromCurrentUrl} from 'app/api/util/getTeamIdFromCurrentUrl';
import {isUUID} from 'app/util/isUUID';
import {ApiResponseNumeralStatus} from 'app/data/Status';
import {Router} from 'app/router/Router';
import {Api} from 'app/api/Api';
import {MainRoutes} from 'app/router/main';

const STORE_KEY_NAME = 'CurrentTeamTracker.LastSessionTeamId';

class CurrentTeamTracker {
  protected router: Router<MainRoutes>;
  protected api: Api;

  constructor({
    router,
    api,
  }: any = {}) {
    this.router = router;
    this.api = api;

    this.handleChangeCurrentTeam = this.handleChangeCurrentTeam.bind(this);
    this.handleAjaxError = this.handleAjaxError.bind(this);

    this.initialize();
  }

  save(teamId?: string | null) {
    if (typeof teamId === 'undefined') {
      // Get id from API setting
      if (this.api && typeof this.api.getCurrentTeamId === 'function') {
        teamId = this.api.getCurrentTeamId();
      }

      // Get Id from URL
      if (!teamId) {
        teamId = this.getCurrentTeamIdFromUrl();
      }

      // Get Id from store
      if (!teamId) {
        teamId = this.load();
      }

      // Default value
      if (!teamId) {
        teamId = null;
      }
    }

    this.setToStore(teamId);
  }

  load(): string | null {
    const teamId = this.getFromStore();

    if (teamId && isUUID(teamId)) {
      return teamId;
    }

    return null;
  }

  reset() {
    this.setToStore(null);
  }

  private initialize() {
    if (this.router) {
      this.router.onChangeCurrentTeam(this.handleChangeCurrentTeam);
    }

    if (this.api) {
      this.api.onAjaxError(this.handleAjaxError);
    }
  }

  private handleChangeCurrentTeam(teamId: string) {
    // Save Id on current team change
    this.save(teamId);
  }

  private handleAjaxError(res: any) {
    if (res.status === ApiResponseNumeralStatus.Unauthorized) {
      // Save Id before redirecting to login
      this.save();
    } else if (res.status === ApiResponseNumeralStatus.MethodNotAllowed) {
      // Save Id if error is `TeamChangeRequired`
      const newTeamId = (res.data?.ErrorData?.NewTeam as string) || null;
      this.save(newTeamId);
    }
  }

  private getCurrentTeamIdFromUrl(): string | null {
    return getTeamIdFromCurrentUrl();
  }

  private setToStore(teamId: string | null) {
    if (typeof teamId === 'string') {
      window.localStorage.setItem(STORE_KEY_NAME, teamId);
    } else {
      window.localStorage.removeItem(STORE_KEY_NAME);
    }
  }

  private getFromStore(): string | null {
    return window.localStorage.getItem(STORE_KEY_NAME);
  }
}

export {
  CurrentTeamTracker,
};
