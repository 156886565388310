import React, {useState} from 'react';
import {Paper, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {grey} from '@mui/material/colors';
import {StyleSx, Sx} from 'app/types/common';
import {AppNotifications, NotificationsApi} from 'app/components/entities/notifications';
import {packSx} from 'app/util/packSx/packSx';
import {Notifications} from 'app/components/Notifications';
import {useMounted} from 'app/hooks/useIsMounted';

const rootSx: StyleSx = {p: 2, bgcolor: grey[100]};

interface Props extends Sx {
  notification: AppNotifications.OwnershipNotification;
  onReload: () => Promise<void>;
}

export function OwnershipNotification({sx, notification, onReload}: Props) {
  const {token, teamName, ownerName} = notification;
  const [accepting, setAccepting] = useState(false);
  const [declining, setDeclining] = useState(false);

  const mounted = useMounted();

  const handleAccept = async () => {
    try {
      setAccepting(true);
      await NotificationsApi.acceptOwnership(notification.token);
      await onReload();
    } catch {
      Notifications.addErrorNotification('Failed to accept ownership');
    } finally {
      if (mounted()) {
        setAccepting(false);
      }
    }
  };

  const handleDecline = async () => {
    try {
      setDeclining(true);
      await NotificationsApi.declineOwnership(notification.token);
      await onReload();
    } catch {
      Notifications.addErrorNotification('Failed to decline ownership');
    } finally {
      if (mounted()) {
        setDeclining(false);
      }
    }
  };

  return (
    <Paper data-id={token} sx={packSx(sx, rootSx)} variant="outlined">
      <Typography>
        Would you like to accept ownership of{' '}
        <Typography component="span" variant="inherit" color="secondary.main" fontWeight={600}>
          {teamName}
        </Typography>{' '}
        sent to you by <strong>{ownerName}</strong>
      </Typography>

      <Stack mt={2} direction="row" justifyContent="center" alignItems="center" gap={1}>
        <LoadingButton
          data-id="accept-ownership-button"
          variant="contained"
          color="secondary"
          loading={accepting}
          disabled={declining}
          onClick={handleAccept}
        >
          Accept transfer ownership
        </LoadingButton>

        <LoadingButton
          data-id="decline-ownership-button"
          variant="contained"
          color="info"
          loading={declining}
          disabled={accepting}
          onClick={handleDecline}
        >
          Decline transfer ownership
        </LoadingButton>
      </Stack>

      <Typography mt={1} variant="body2" color="text.secondary">
        * If ownership transfer is accepted, your role will change to Owner
      </Typography>
    </Paper>
  );
}
