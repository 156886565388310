import {Router} from 'app/router/Router';

const ROUTE_NAME = {
  SIGN_UP: 'signUp',
  SIGN_IN: 'signIn',
  RESET_PASSWORD: 'resetPassword',
} as const;

const routes = {
  [ROUTE_NAME.SIGN_UP]: {
    url: 'signup',
    title: 'Sign Up',
  },
  [ROUTE_NAME.SIGN_IN]: {
    url: 'login',
    title: 'Log In',
  },
  [ROUTE_NAME.RESET_PASSWORD]: {
    url: 'reset_password',
    title: 'Reset Password',
  },
} as const;

export type LoginRoutes = keyof typeof routes;

const router = new Router<LoginRoutes>({
  routes,
  defaultRoute: {
    name: ROUTE_NAME.SIGN_IN,
    params: {},
  },
});

export {
  router,
  ROUTE_NAME,
};
