import {BaseCapabilities} from 'app/models/Capabilities/BaseCapabilities';
import {TeamCapability} from 'app/components/Team/constants';

export class TeamCapabilities extends BaseCapabilities {
  transcribing() {
    return this.check(TeamCapability.Transcribing);
  }

  cms() {
    return this.check(TeamCapability.Cms);
  }

  unify() {
    return this.check(TeamCapability.Unify);
  }

  connect() {
    return this.check(TeamCapability.Connect);
  }

  presets() {
    return this.check(TeamCapability.Presets);
  }

  groups() {
    return this.check(TeamCapability.Groups);
  }

  batch() {
    return this.check(TeamCapability.Batch);
  }

  files() {
    return this.check(TeamCapability.Files);
  }
}
