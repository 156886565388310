import React from 'react';
import {Box, Dialog, DialogContent, DialogTitle, Divider, Stack} from '@mui/material';
import {Callback, SxObj} from 'app/types/common';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {palette} from 'app/themes/app';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';

interface Props {
  open: boolean;
  onPair: Callback;
  onGenerateLink: Callback;
  onClose: Callback;
}

const box: SxObj = {flex: 1, padding: 4};
const title: SxObj = {fontSize: '16px', mb: 1, color: palette.badge};
const message: SxObj = {mb: 2, color: palette.badge};

export function PairActionSelectorDialog({open, onPair, onGenerateLink, onClose}: Props) {
  return (
    <Dialog open={open} fullWidth={true} maxWidth="md" onClose={onClose}>
      <DialogClose onClose={onClose} />
      <DialogTitle>
        Pair a Microsoft Teams organization
        <Box sx={{color: palette.blue, mt: 1, fontSize: '16px'}}>
          Do you have administrator access to your Microsoft Teams organization?
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack direction="row" gap={3} divider={<Divider orientation="vertical" flexItem />}>
          <Box sx={box}>
            <Box sx={title}>
              <strong>I am NOT the administrator</strong>
              <br />
              of my Microsoft organization
            </Box>
            <Box sx={message}>
              Please generate a URL and send it to the <br />
              administrator of the Microsoft organization
            </Box>

            <Button variant={VARIANT.OUTLINE} theme={THEME.SUCCESS} onClick={onGenerateLink}>
              Generate connection link
            </Button>
          </Box>
          <Box sx={box}>
            <Box sx={title}>
              <strong>I am the administrator</strong> <br />
              of my Microsoft organization
            </Box>
            <Box sx={message}>
              Follow the easy steps to connect and start <br />
              producing high-quality content right away
            </Box>

            <Button variant={VARIANT.OUTLINE} theme={THEME.SUCCESS} onClick={onPair}>
              Pair your Microsoft Teams organization
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
