import classNames from 'classnames';

/**
 * @deprecated use `classNames` instead
 * @param {object} classesObj
 * @return {string}
 */
function classesConditional(classesObj: Record<string, any>) {
  return classNames(classesObj);
}

/**
 * To provide mapping between single and plural forms for cases when cannot simply append 's' character at the end
 * where `key` is a single form, `value` - plural
 * @readonly
 * @enum {string}
 */
const pluralizeMap = {
  this: 'these',
  box: 'boxes',
  goes: 'go',
  is: 'are',
};

/**
 * @param {string} name
 * @param {array} objects
 * @return {string}
 *
 * @example
 * pluralize('device', [{id: 1}, {id: 2}]); //=> "devices"
 */
function pluralize(name: string, objects?: any[]): string {
  if (objects) {
    return pluralizeN(name, objects.length);
  }

  return name;
}

/**
 * @param {string} name
 * @param {number} length
 * @return {string}
 *
 * @example
 * pluralizeN('cat', 3); //=> "cats"
 * pluralizeN('cat', 1); //=> "cat"
 */
function pluralizeN(name: string, length: number): string {
  if (length !== 1) {
    return pluralizeMap[name] ? pluralizeMap[name] : `${name}s`;
  }

  return name;
}

/**
 * @param {string} name
 * @return {string}
 *
 * @example
 * capitalize('foo'); //=> "Foo"
 */
function capitalize(name: string) {
  return name.charAt(0).toUpperCase() + name.substr(1);
}

export {
  classesConditional,
  pluralize,
  pluralizeN,
  capitalize,
};
