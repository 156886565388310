import React from 'react';
import {
  THEME,
  VARIANT,
} from 'app/constants';
import {TranscriptionButtonComponent, TranscriptionButtonProps} from 'app/components/sharedReactComponents/StartStopTranscriptionButton/TranscriptionButtonComponent';
import {Icons} from 'app/util/icons';

export const PauseTranscriptionButton: React.FC<TranscriptionButtonProps> = ({
  theme = THEME.SECONDARY,
  variant = VARIANT.SOLID,
  children = Icons.pause().reactComponent(),
  ...props
}) => {
  return (
    <TranscriptionButtonComponent
      theme={theme}
      variant={variant}
      {...props}
    >
      {children}
    </TranscriptionButtonComponent>
  );
};
