import React, {useMemo} from 'react';
import {Stack, Tooltip, Typography} from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';
import DnsIcon from '@mui/icons-material/Dns';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import {DataId, Sx} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {getDeviceWarningsStats} from 'app/components/FleetManager/DeviceGroup/utils';
import {OnlineIndicator} from 'app/components/sharedReactComponents/OnlineIndicator';
import {SIZE, VARIANT} from 'app/constants';

type Props = Sx & {
  devices: AnyDeviceModelType[];
  total: number;
  countEvents: boolean;
  countOngoingEvents: (ids: string[]) => number;
};

export function GroupIndicators({sx, devices, total, countEvents, countOngoingEvents}: Props) {
  const stats = useGroupIndicators(devices, countEvents, countOngoingEvents);
  const {ongoingEvents, online, offline, withErrors, withWarnings} = stats;

  const displayedTotal = devices.length;
  const allDisplayed = displayedTotal === total;

  return (
    <Stack sx={sx} direction="row" alignItems="center" gap={1}>
      {ongoingEvents > 0 && (
        <IconIndicator
          dataId="device_group_ongoing_event_count"
          icon={<TodayIcon color="error" />}
          tooltip="Ongoing events"
          label={
            <Typography data-id="indicator-label" color="error">
              {ongoingEvents}
            </Typography>
          }
        />
      )}

      {withErrors > 0 && (
        <IconIndicator
          dataId="device_group_critical_alerts_count"
          icon={<WarningIcon color="error" fontSize="small" />}
          tooltip="Devices with critical warnings"
          label={
            <Typography data-id="indicator-label" color="error.main">
              {withErrors}
            </Typography>
          }
        />
      )}

      {withWarnings > 0 && (
        <IconIndicator
          dataId="device_group_warnings_count"
          icon={<ErrorIcon color="warning" fontSize="small" />}
          tooltip="Devices with warnings"
          label={
            <Typography data-id="indicator-label" color="warning.main">
              {withWarnings}
            </Typography>
          }
        />
      )}

      {online > 0 && (
        <IconIndicator
          dataId="device_group_online_count"
          icon={<OnlineIndicator online={true} variant={VARIANT.OUTLINE} size={SIZE.S} />}
          tooltip="Online devices"
          label={
            <Typography data-id="indicator-label" color="info">
              {online}
            </Typography>
          }
        />
      )}

      {offline > 0 && (
        <IconIndicator
          dataId="device_group_offline_count"
          icon={<OnlineIndicator online={false} variant={VARIANT.OUTLINE} size={SIZE.S} />}
          tooltip="Offline devices"
          label={<Typography color="info">{offline}</Typography>}
        />
      )}

      <IconIndicator
        dataId="device_group_devices_count"
        icon={<DnsIcon color="info" fontSize="small" />}
        tooltip={
          allDisplayed ? 'Devices in group' : `${displayedTotal} of ${total} visible devices`
        }
        label={
          <Typography data-id="indicator-label" color="info">
            {allDisplayed ? total : `${displayedTotal}/${total}`}
          </Typography>
        }
      />
    </Stack>
  );
}

function useGroupIndicators(
  devices: AnyDeviceModelType[],
  countEvents: boolean,
  countOngoingEvents: (ids: string[]) => number,
) {
  return useMemo(() => {
    const indicators: Indicators = {
      online: 0,
      offline: 0,
      withErrors: 0,
      withWarnings: 0,
      ongoingEvents: 20,
    };

    const deviceIds: string[] = [];

    devices.forEach((device) => {
      deviceIds.push(device.getId());

      if (device.isOnline()) {
        indicators.online += 1;
      } else {
        indicators.offline += 1;
      }

      const {withErrors, withWarnings} = getDeviceWarningsStats(device.getWarnings());
      if (withErrors) {
        indicators.withErrors += 1;
      }

      if (withWarnings) {
        indicators.withWarnings += 1;
      }
    });

    if (countEvents) {
      const ongoingEvents = countOngoingEvents(deviceIds);
      indicators.ongoingEvents = ongoingEvents;
    }

    return indicators;
  }, [devices, countEvents, countOngoingEvents]);
}

type Indicators = {
  online: number;
  offline: number;
  withErrors: number;
  withWarnings: number;
  ongoingEvents: number;
};

type IndicatorProps = Sx &
  DataId & {
    tooltip: string;
    icon: React.ReactNode;
    label: React.ReactNode;
  };

function IconIndicator({dataId, sx, icon, label, tooltip}: IndicatorProps) {
  return (
    <Tooltip title={tooltip}>
      <Stack data-id={dataId} sx={sx} direction="row" alignItems="center" gap={0.25}>
        {icon}
        {label}
      </Stack>
    </Tooltip>
  );
}
