type Contract = Contracts.Connect.ReturnFeed | Contracts.Connect.Participant;
export function isParticipantContract(
  contract: Contract,
): contract is Contracts.Connect.Participant {
  return 'name' in contract;
}

export function isParticipantStreamSetting(
  settings: Contracts.Connect.StreamSetting,
): settings is Contracts.Connect.ParticipantStreamSetting {
  return 'audio_setting' in settings;
}
