export const ActionTypes = {
  UI_PORTAL_ELEMENT_MOUNT: 'ui.portalElement.mount',
  UI_PORTAL_ELEMENT_UNMOUNT: 'ui.portalElement.unmount',

  ALERTS_COUNT: 'user.alerts.count',

  TEAMS_TEAM_RENAMED: 'teams.teamRenamed',
  TEAMS_TEAM_CREATED: 'teams.teamCreated',
  TEAMS_TEAM_PLAN_UPDATE: 'teams.teamPlanUpdate',

  UNHANDLED_COMPONENT_EXCEPTION: 'app.unhandledComponentException',
};
