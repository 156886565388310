import React from 'react';
import classNames from 'classnames';
import {SIZE} from 'app/constants';
import {Icons} from 'app/util/icons';
import {StartStopButton} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton';
import {DEVICE_START_STOP_BUTTON_STATUS} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton/constants';
import {
  Callback,
  ClassName,
} from 'app/types/common';

interface Props extends ClassName {
  status: DEVICE_START_STOP_BUTTON_STATUS;
  startTime?: number;
  size?: SIZE;
  disabled: boolean;
  hovered?: boolean;
  loading: boolean;
  startAction: Callback;
  stopAction: Callback;
  hasEvent?: boolean;
}

export const RecordingStartStopButton: React.FC<Props> = ({
  className,
  status,
  startTime,
  size,
  disabled,
  hovered,
  loading,
  startAction,
  stopAction,
  hasEvent,
}: Props) => {
  const icon = hasEvent && status === DEVICE_START_STOP_BUTTON_STATUS.STARTED ?
    Icons.recordEvent() :
    Icons.record();

  return (
    <StartStopButton
      className={classNames('recording-start-stop-button', className)}
      data-id="recording_start_stop_button"
      status={status}
      icon={icon.size(SIZE.S).reactComponent()}
      stoppedLabel="Start recording"
      startedLabel="Recording"
      loadingLabel="Starting..."
      stopLoadingLabel="Stop recording"
      stopLabel="Stop recording"
      startTime={startTime}
      size={size}
      disabled={disabled}
      hovered={hovered}
      loading={loading}
      startAction={startAction}
      stopAction={stopAction}
      unsetLoadingAfterPromise={false}
    />
  );
};
