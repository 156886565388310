// Truthy values on top
export function booleanComparator(a: any, b: any): number {
  if (a === b) {
    return 0;
  }

  if (a === true) {
    return -1;
  }

  return 1;
}
