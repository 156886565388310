import React from 'react';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';

interface Props {
  disabled: boolean;
}

export const DisabledButtonWrapper: React.FC<Props> = ({
  children,
  disabled,
}) => {
  if (!disabled) {
    return <>{children}</>;
  }

  return (
    <Tooltip content="Coming soon">
      {children}
    </Tooltip>
  );
};
