import {TranscribingStatus} from 'app/components/DeviceDetails/constants';

const FULL_DEVICE_ID_REGEX = /(^[0-9a-f]+)-?(\d+)?/;

// Channel ID: `{MASTER ID}-{CHANNEL ID}`
const CHANNEL_DEVICE_ID_INDEX_REGEX = /^[0-9a-f]+-(\d+)/;

/**
 * @param {string} channelId
 * @returns {string}
 */
const parseChannelDeviceShortId = (channelId) => channelId.match(CHANNEL_DEVICE_ID_INDEX_REGEX)[1];

export type DeviceIdentifier = [master: string, channelIdx: string];

const parseMasterAndChannelIds = (id: string): DeviceIdentifier => {
  const matches = FULL_DEVICE_ID_REGEX.exec(id);
  return [matches?.[1] ?? '', matches?.[2] ?? ''];
};

function getTranscriberStatus(started: boolean, paused: boolean): TranscribingStatus {
  if (!started) {
    return TranscribingStatus.Stopped;
  }

  if (paused) {
    return TranscribingStatus.Paused;
  }

  return TranscribingStatus.Started;
}

export {parseChannelDeviceShortId, parseMasterAndChannelIds, getTranscriberStatus};
