/* global ClipboardItem */
import React, {useRef} from 'react';
import classNames from 'classnames';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Icons, TextPlacement} from 'app/util/icons';
import {THEME, VARIANT, SIZE} from 'app/constants';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Callback, ClassName, DataId} from 'app/types/common';

const SHOW_COPIED_TOOLTIP_TIMEOUT = 1500;

interface CopyProps extends ClassName, DataId {
  text?: string;
  placement?: TextPlacement;
  theme?: THEME;
  variant?: VARIANT;
  disabled?: boolean;
  buttonContent?: React.ReactNode;
  onClick?: Callback;
}

interface CopyTextProps extends CopyProps {
  value?: string | number;
}

interface CopyCanvasProps extends CopyProps {
  valueCanvas?: any;
}

type Props = CopyTextProps | CopyCanvasProps;

const CopyButtonComponent = ({
  className,
  dataId,
  text,
  placement,
  buttonContent,
  theme,
  variant,
  disabled,
  children,
  onClick,
}: React.PropsWithChildren<CopyProps>) => {
  // rework any for refs
  const copiedTooltipRef = useRef<any>(null);
  const showCopiedTooltipTimeout = useRef<any>(null);

  const currentButtonText =
    buttonContent ??
    Icons.copy()
      .size(SIZE.S)
      .label(text ?? '', placement)
      .reactComponent();

  const handleClickCopy = (e: React.SyntheticEvent) => {
    onClick?.(e);

    copiedTooltipRef.current.show();

    clearTimeout(showCopiedTooltipTimeout.current);
    showCopiedTooltipTimeout.current = setTimeout(() => {
      if (copiedTooltipRef.current) {
        copiedTooltipRef.current.hide();
      }
    }, SHOW_COPIED_TOOLTIP_TIMEOUT);
  };

  return (
    <div className={classNames('copy-button', className)}>
      {children}

      <Tooltip ref={copiedTooltipRef} content="Copied!" trigger="manual">
        <Button
          className="copy-button__button"
          dataId={dataId}
          theme={theme}
          variant={variant}
          disabled={disabled}
          onClick={handleClickCopy}
        >
          {currentButtonText}
        </Button>
      </Tooltip>
    </div>
  );
};

const CopyTextButton = ({value, onClick, ...props}: CopyTextProps) => {
  // rework any type
  const hiddenInputRef = useRef<any>(null);

  const handleClickCopy = (e: React.SyntheticEvent) => {
    onClick?.(e);
    hiddenInputRef.current.select();
    document.execCommand('copy', false, undefined);
  };

  return (
    <CopyButtonComponent {...props} onClick={handleClickCopy}>
      <input
        ref={hiddenInputRef}
        className="copy-button__hidden-input"
        value={value}
        tabIndex={-1}
        readOnly={true}
      />
    </CopyButtonComponent>
  );
};

const CopyCanvasButton = ({valueCanvas, onClick, ...props}: CopyCanvasProps) => {
  /**
   * From version 63: this feature is behind the dom.events.asyncClipboard.dataTransfer preferences (needs to be set to true).
   * To change preferences in Firefox, visit about:config.
   * {@link https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/write}
   */
  const supportsClipboard = typeof window.navigator.clipboard?.write === 'function';

  const handleClickCopy = ({...args}) => {
    valueCanvas.current.toBlob((blob) => {
      const clipboardItems: ClipboardItem[] = [];
      const clipboardItem = new ClipboardItem({
        'image/png': blob,
      });
      clipboardItems.push(clipboardItem);

      if (supportsClipboard) {
        // @ts-expect-error
        void window.navigator.clipboard.write(clipboardItems);
      }
    });

    onClick?.(args);
  };

  if (!supportsClipboard) {
    return null;
  }

  return <CopyButtonComponent onClick={handleClickCopy} {...props} />;
};

export const CopyButton = ({text = 'Copy', variant = VARIANT.TEXT, ...rest}: Props) => {
  if ('valueCanvas' in rest) {
    return <CopyCanvasButton text={text} variant={variant} {...rest} />;
  }

  return <CopyTextButton text={text} variant={variant} {...rest} />;
};
