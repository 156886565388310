import React from 'react';
import {Modal} from 'app/components/sharedReactComponents/Modal/Modal';
import {NoPairingPermission} from 'app/components/sharedReactComponents/Pairing/NoPairingPermission/NoPairingPermission';

export const NotTenantAdminPrompt = {
  show() {
    Modal.show({
      children: <NoPairingPermission type="simple" />,
    });
  },
};
