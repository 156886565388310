import {gaugeClasses} from '@mui/x-charts';

export type GaugeVariant = 'ok' | 'warning' | 'error';

export function getGaugeSx(variant: GaugeVariant) {
  return {
    [`& .${gaugeClasses.valueArc}`]: {
      fill: ({palette}) => {
        if (variant === 'error') return palette.error.main;

        if (variant === 'warning') return palette.warning.main;

        return palette.primary.main;
      },
    },
  };
}
