import React from 'react';
import {Checkbox, CHECKBOX_THEME} from 'app/components/sharedReactComponents/Checkbox';
import {SIZE} from 'app/constants';
import {Radio, RADIO_THEME} from 'app/components/sharedReactComponents/Radio';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Callback} from 'app/types/common';

export enum COMMON_STREAM_SELECT_INPUT_TYPE {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

interface Props {
  id: string;
  type: COMMON_STREAM_SELECT_INPUT_TYPE;
  disabled: boolean;
  disabledReasonMessage?: string;
  selected: boolean;
  started: boolean;
  loading: boolean;
  onSelect: Callback;
}
export const SelectInput: React.VFC<Props> = ({
  id,
  type,
  disabled,
  disabledReasonMessage,
  selected,
  started,
  loading,
  onSelect,
}) => {
  const selectionDisabled = disabled || started;

  return (
    <Tooltip content={(disabled && disabledReasonMessage) || undefined}>
      <div className="device-streaming-destination-list-item__input">
        {type === COMMON_STREAM_SELECT_INPUT_TYPE.CHECKBOX && (
          <Checkbox
            data-id="streaming_destination_select_input"
            name={id}
            value={selected}
            disabled={selectionDisabled}
            loading={loading}
            size={SIZE.S}
            theme={CHECKBOX_THEME.BLACK}
            onChange={onSelect}
          />
        )}

        {type === COMMON_STREAM_SELECT_INPUT_TYPE.RADIO && (
          <Radio
            dataId="streaming_destination_select_input"
            name={id}
            value={id}
            checked={selected}
            disabled={selectionDisabled}
            loading={loading}
            uncheckable={true}
            size={SIZE.S}
            theme={RADIO_THEME.BLACK}
            onChange={onSelect}
          />
        )}
      </div>
    </Tooltip>
  );
};
