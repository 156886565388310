import React from 'react';
import classNames from 'classnames';
import {Dropdown} from 'app/components/sharedReactComponents/Dropdown';
import {StreamingDestinationsSelectorButton} from 'app/components/sharedReactComponents/ActionButtons/StreamingDestinationsSelector/StreamingDestinationsSelectorButton';
import {StreamingDestinationsSelectorMenu} from 'app/components/sharedReactComponents/ActionButtons/StreamingDestinationsSelector/StreamingDestinationsSelectorMenu';
import {Callback, ClassName, Sx} from 'app/types/common';
import {TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';
import {DevicePublisherModel} from 'app/models/Device/Publisher/DevicePublisherModel';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DeviceWarning} from 'app/components/DeviceDetails/Models/types';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {Box} from '@mui/material';

interface Props extends ClassName, Sx {
  publishers: DevicePublisherModel[];
  selectedPublishers: Set<string>;
  selectedStreamingDestinations: Set<any>;
  device: AnyDeviceModelType;
  streamingDestinations: AnyStreamingDestinationModelType[];
  publisherWarnings: DeviceWarning[];
  streamingDestinationWarnings: DeviceWarning[];
  started: boolean;
  disabled?: boolean;
  offline: boolean;
  editable: boolean;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  onPublisherSelect: Callback;
  onStreamingDestinationSelect: Callback;
}

export function StreamingDestinationsSelector({
  sx,
  className,
  publishers,
  selectedPublishers,
  selectedStreamingDestinations,
  device,
  streamingDestinations,
  publisherWarnings = [],
  streamingDestinationWarnings = [],
  started,
  disabled,
  offline,
  editable,
  getDeviceById,
  onPublisherSelect,
  onStreamingDestinationSelect,
}: Props) {
  return (
    <Box sx={sx} className={classNames('streaming-destinations-selector', className)}>
      <Dropdown
        content={
          <StreamingDestinationsSelectorMenu
            publishers={publishers}
            selectedPublishers={selectedPublishers}
            selectedStreamingDestinations={selectedStreamingDestinations}
            device={device}
            streamingDestinations={streamingDestinations}
            publisherWarnings={publisherWarnings}
            streamingDestinationWarnings={streamingDestinationWarnings}
            getDeviceById={getDeviceById}
            offline={offline}
            started={started}
            editable={editable}
            onPublisherSelect={onPublisherSelect}
            onStreamingDestinationSelect={onStreamingDestinationSelect}
          />
        }
        placement={TOOLTIP_PLACEMENT.TOP_START}
        maxWidth={414}
        disabled={disabled}
        interactive={true}
      >
        <StreamingDestinationsSelectorButton
          count={selectedPublishers.size + selectedStreamingDestinations.size}
          offline={offline}
          failed={
            started && (publisherWarnings.length > 0 || streamingDestinationWarnings.length > 0)
          }
          started={started}
        />
      </Dropdown>
    </Box>
  );
}
