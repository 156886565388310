import React from 'react';
import {PairDeviceWarningMessage} from 'app/components/sharedReactComponents/PairDeviceContainer/PairDeviceWarningMessage';
import {PairDeviceLimitsPanel} from 'app/components/sharedReactComponents/PairDeviceContainer/PairDeviceLimitsPanel';
import {Callback} from 'app/types/common';
import {usePairingTierInfo} from 'app/components/sharedReactComponents/PairDeviceContainer/usePairingTierInfo';

interface Props {
  loading: boolean;
  pairedCount: number;
  teamTier: App.Billing.Cloud.Tier;
  seatMonthPrice: number;
  isUnlimitedProPlan: boolean;
  confirmedWarningMessage: boolean;
  canEditBilling?: boolean;
  subheaderComponent?: React.ReactNode;
  title?: React.ReactNode;
  teamId?: string;
  onConfirmMessage: Callback;
}

export function PairDeviceContainer({
  pairedCount,
  loading,
  confirmedWarningMessage,
  teamTier,
  seatMonthPrice,
  isUnlimitedProPlan,
  canEditBilling = false,
  subheaderComponent,
  title,
  teamId,
  children,
  onConfirmMessage,
}: React.PropsWithChildren<Props>) {
  const currentTitle = title ?? (
    <>
      Pair your device to your
      <br />
      Epiphan Cloud team account
    </>
  );

  const {freeTier, seats} = usePairingTierInfo(teamTier);

  const showWarningMessage = !freeTier && !isUnlimitedProPlan && pairedCount >= seats;

  return (
    <div className="pair-device-container">
      <h1 className="pair-device-container__title">{currentTitle}</h1>

      {confirmedWarningMessage && showWarningMessage && (
        <div className="pair-device-container__warning-message">
          Once device is paired, a new device unit will be added to your team.
          <br />
          This will increase your monthly bill by ${seatMonthPrice}
        </div>
      )}

      {subheaderComponent}
      {!confirmedWarningMessage && showWarningMessage ? (
        <PairDeviceWarningMessage
          tierId={teamTier.id}
          teamId={teamId}
          pairedCount={pairedCount}
          maxDevices={seats}
          canEditBilling={canEditBilling}
          onConfirm={onConfirmMessage}
        />
      ) : (
        <div className="pair-device-container__form">{children}</div>
      )}

      <PairDeviceLimitsPanel
        className="pair-device-container__limits-panel"
        loading={loading}
        count={pairedCount}
        teamTier={teamTier}
      />
    </div>
  );
}
