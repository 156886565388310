import {useEffect, useState} from 'react';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {useDeviceIds} from 'app/components/FleetManager/hooks/useDeviceIds';
import {collectDevicesStatisticsData} from 'app/components/FleetManager/utils';
import {UnifySelectTypes} from 'app/components/UnifyProjects/types';
import {isOngoing} from 'app/domain/schedule/utils';
import {Schedule} from 'app/domain/schedule';

type Statistic = {
  [key in UnifySelectTypes]: number;
};

interface Args {
  projects: AnyDeviceModelType[];
  actualEvents?: Map<string, Schedule.Event>;
}

export function useUnifySummary({projects, actualEvents}: Args): Statistic {
  const [statistic, setStatistic] = useState<Statistic>(() => ({
    active: 0,
    events: 0,
    online: 0,
    streaming: 0,
    recording: 0,
    suspended: 0,
  }));
  const deviceIds = useDeviceIds(projects);

  useEffect(() => {
    setStatistic((prev) => {
      const stats = collectDevicesStatisticsData(projects);

      const events = deviceIds.reduce((acc, deviceId) => {
        const event = actualEvents?.get(deviceId);

        return event && isOngoing(event.status) ? acc + 1 : acc;
      }, 0);

      return {...prev, ...stats, events};
    });
  }, [projects, actualEvents, deviceIds]);

  return statistic;
}
