import React from 'react';
import classNames from 'classnames';
import {Duration} from 'app/components/sharedReactComponents/DurationTimer/Duration';
import {DurationTimerComponent} from 'app/components/sharedReactComponents/DurationTimer/DurationTimerComponent';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  startTime?: number;
  receivedTime?: number;
  paused?: boolean;
}

export const DurationTimer: React.FC<Props> = ({
  className,
  startTime = 0,
  receivedTime = 0,
  paused,
  ...elementProps
}) => {
  if (paused) {
    return (
      <Duration
        className={classNames('duration-timer', 'duration-timer--paused', className)}
        value={receivedTime}
        {...elementProps}
      />
    );
  }

  return (
    <DurationTimerComponent
      className={classNames('duration-timer', className)}
      startTime={startTime}
      receivedTime={receivedTime}
      {...elementProps}
    />
  );
};
