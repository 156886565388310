import {useEffect, useRef, useState} from 'react';
import {FirmwareFeature} from 'app/store/models/features/firmware/FirmwareFeature';
import {RepeatedCall} from 'app/util/RepeatedCall';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {secondsToMilliseconds, minutesToMilliseconds} from 'app/util/timeConverter';
import {Callback} from 'app/types/common';

interface Return {
  reload: Callback;
  firmware?: FirmwareFeature;
}

export function useDeviceFirmware(deviceId: string): Return {
  const [firmware, setFirmware] = useState<FirmwareFeature | undefined>(undefined);
  const repeatedCall = useRef<RepeatedCall>(
    new RepeatedCall({
      call: async () => {
        try {
          const response = await DeviceApiService.getFirmware(deviceId);

          if (!response) {
            setFirmware(undefined);
            return;
          }

          setFirmware(new FirmwareFeature(response));
        } catch {
          setFirmware(undefined);
        }
      },
      initialTimeoutMs: secondsToMilliseconds(60),
      maxTimeoutMs: minutesToMilliseconds(2),
    }),
  );

  useEffect(() => {
    const instance = repeatedCall.current;
    void instance.start(true);

    return () => {
      instance.stop();
    };
  }, [deviceId]);

  return {
    reload: async () => repeatedCall.current.restart(true),
    firmware,
  };
}
