import React, {useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {SettingsList} from 'app/components/sharedReactComponents/SettingsList';
import {LoadingWrapper} from 'app/components/sharedReactComponents/LoadingWrapper';
import {Sx} from 'app/types/common';
import {LiveScryptDeviceModel} from 'app/components/DeviceDetails/Models/LiveScryptDeviceModel';
import {useMounted} from 'app/hooks/useIsMounted';

interface Props extends Sx {
  device: LiveScryptDeviceModel;
}

export function Transcription({sx, device}: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState<Array<Record<string, any>>>([]);

  const mounted = useMounted();

  useEffect(() => {
    const load = async () => {
      const settings = await device.getTranscribingSettings();
      if (mounted()) {
        setSettings(settings);
        setIsLoading(false);
      }
    };

    void load();
  }, [mounted, device]);

  const updateSettings = async (id: string, value: any) => {
    await device.updateTranscribingSetting(id, value);
  };

  return (
    <Box sx={sx}>
      <Typography fontWeight={600} variant="h5" mb={3}>
        Transcription
      </Typography>

      {isLoading ? (
        <LoadingWrapper loading={true}>
          <Box />
        </LoadingWrapper>
      ) : (
        <SettingsList
          settings={settings}
          disabled={device.isOffline()}
          updateSettingAction={updateSettings}
        />
      )}
    </Box>
  );
}
