import React, {useState} from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {pluralize, pluralizeN} from 'app/components/helpers/commonHelpers';
import {NamesList} from 'app/components/sharedReactComponents/ItemsEnumeration';
import {useMounted} from 'app/hooks/useIsMounted';
import {Prompt} from 'app/types/common';

type DeviceCheck = 'dashboard' | 'content' | 'alerts';

type Props = {
  loading: boolean;
  names: string[];
  lvsOnly: boolean;
  onDelete: () => Promise<void>;
  onClose: () => void;
};

function DeleteDevices({loading, names, lvsOnly, onDelete, onClose}: Props) {
  const [checks, setChecks] = useState<Set<DeviceCheck>>(new Set());

  const toggleCheck = (check: DeviceCheck) => {
    const copy = new Set(checks);

    if (copy.has(check)) {
      copy.delete(check);
    } else {
      copy.add(check);
    }

    setChecks(copy);
  };

  const plural = pluralizeN('device', names.length);

  const disabled = lvsOnly ? checks.size !== 1 : checks.size !== 3;

  return (
    <Box>
      <DialogTitle>
        Delete <NamesList max={3} variant="inherit" color="primary.main" names={names} />{' '}
        {pluralizeN('device', names.length)}
      </DialogTitle>

      <DialogContent>
        <Typography textAlign="center">Click the check boxes to confirm</Typography>

        <Stack alignItems="center">
          <Stack alignItems="flex-start">
            {!lvsOnly && (
              <>
                <FormControlLabel
                  sx={{mb: 0}}
                  control={
                    <Checkbox
                      data-id="dashboard-checkbox"
                      checked={checks.has('dashboard')}
                      disableRipple={false}
                      onChange={() => toggleCheck('dashboard')}
                    />
                  }
                  label={`Remove the ${plural} from the Devices Dashboard and Remote login access`}
                />
                <FormControlLabel
                  sx={{mb: 0}}
                  control={
                    <Checkbox
                      data-id="content-checkbox"
                      checked={checks.has('content')}
                      disableRipple={false}
                      onChange={() => toggleCheck('content')}
                    />
                  }
                  label={`Delete all content stored in the cloud that is associated with ${pluralize(
                    'this',
                    names,
                  )} ${plural}`}
                />
              </>
            )}

            <FormControlLabel
              sx={{mb: 0}}
              control={
                <Checkbox
                  data-id="alerts-checkbox"
                  checked={checks.has('alerts')}
                  disableRipple={false}
                  onChange={() => toggleCheck('alerts')}
                />
              }
              label={`Stop receiving alerts from ${pluralize('this', names)} ${plural}`}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="confirm-delete-btn"
          variant="contained"
          color="error"
          loading={loading}
          disableRipple={false}
          disabled={disabled}
          onClick={onDelete}
        >
          Delete
        </LoadingButton>

        <Button
          data-id="cancel-delete-btn"
          variant="outlined"
          color="info"
          disableRipple={false}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
}

type PromptProps = Prompt & Omit<Props, 'loading'>;

export function DeleteDevicesDialog({open, names, lvsOnly, onDelete, onClose}: PromptProps) {
  const mounted = useMounted();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await onDelete();
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DeleteDevices
        names={names}
        loading={loading}
        lvsOnly={lvsOnly}
        onDelete={handleDelete}
        onClose={onClose}
      />
    </Dialog>
  );
}
