import React from 'react';
import {Dialog} from '@mui/material';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {Callback} from 'app/types/common';
import {ZoomConnection} from 'app/components/dialogs/ZoomConnectDialog/ZoomConnection';

interface Props extends React.ComponentProps<typeof ZoomConnection> {
  open: boolean;
  onClose: Callback;
}

export function ZoomConnectDialog({open, accounts, onConnect, onClose}: Props) {
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <DialogClose onClose={onClose} />

      <ZoomConnection accounts={accounts} onConnect={onConnect} />
    </Dialog>
  );
}
