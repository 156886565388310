import {NotTenantAdminPrompt} from 'app/components/MeetingsManager/modals/NotTenantAdminPrompt/NotTenantAdminPrompt';
import {UnexpectedErrorPrompt} from 'app/components/MeetingsManager/modals/UnexpectedErrorPrompt/UnexpectedErrorPrompt';
import {Notifications} from 'app/components/Notifications';
import {Location} from 'app/router/Location';

interface Args {
  teamName: string;
  error?: string;
  state?: string;
}

function cleanQuery() {
  const url = new URL(window.location.href);
  const {href} = url;

  const res = href.split('?');

  const query = res[1];

  if (query) {
    const params = new URLSearchParams(query);

    params.forEach((_v, key) => {
      if (key !== 'tab') {
        params.delete(key);
      }
    });

    const search = params.toString();

    url.href = `${res[0] ?? ''}?${search}`;
  }

  Location.replace(url.href);
}

export function useTeamsConnectionUrlHandlers({error, state, teamName}: Args) {
  if (error) {
    window.setTimeout(() => {
      const decoded = decodeURIComponent(error.replace(/\+/g, '%20'));
      if (decoded === 'the user is not an admin of the tenant') {
        NotTenantAdminPrompt.show();
        return;
      }

      UnexpectedErrorPrompt.show({text: decoded});
    }, 200);

    cleanQuery();
    return;
  }

  if (state) {
    Notifications.addSuccessNotification(
      `You have paired your identity to Epiphan Connect team: ${teamName} `,
    );
    cleanQuery();
  }
}
