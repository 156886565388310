import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {ClassName, Sx} from 'app/types/common';
import {Edge} from 'app/domain/edge';

interface Props extends Sx, ClassName {
  input: Edge.VideoSource;
}

export function VideoDetails({sx, input}: Props) {
  return (
    <Stack direction="row" gap={2} sx={sx}>
      <Box>
        <Typography>Frame Rate</Typography>

        <Typography data-id="input_rate_value">{input.frameRate}</Typography>
      </Box>

      <Box>
        <Typography>Resolution</Typography>

        <Typography data-id="input_resolution_value">{input.resolution}</Typography>
      </Box>
    </Stack>
  );
}
