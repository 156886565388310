import React from 'react';
import {Stack} from '@mui/material';
import {Sx} from 'app/types/common';
import {Schedule} from 'app/domain/schedule';
import {isPaused, isRunning, isScheduled} from 'app/domain/schedule/utils';
import {canStart, canPauseResume} from 'app/components/sharedReactComponents/Events/utils';
import {ScheduledControl} from 'app/components/sharedReactComponents/Events/List/EventCard/EventActions/ScheduledControl';
import {OngoingControls} from 'app/components/sharedReactComponents/Events/List/EventCard/EventActions/OngoingControls';
import {ConfirmControls} from 'app/components/sharedReactComponents/Events/List/EventCard/EventActions/ConfirmControls';

interface Props extends Sx {
  event: Schedule.Event;
  offline: boolean;
  allowStart: boolean;
  onStart(id: string): Promise<void>;
  onStop(id: string): Promise<void>;
  onPause(id: string): Promise<void>;
  onResume(id: string): Promise<void>;
  onConfirm: (id: string) => Promise<void>;
}

export function EventActions({
  sx,
  event,
  offline,
  allowStart,
  onPause,
  onStart,
  onStop,
  onResume,
  onConfirm,
}: Props) {
  const paused = isPaused(event.status);
  const running = isRunning(event.status);
  const scheduled = isScheduled(event.status);
  const ongoing = running || paused;

  const renderControls = () => {
    if (scheduled) {
      if (!event.confirmed && event.capabilities?.includes('confirm')) {
        return (
          <ConfirmControls
            name={event.title}
            offline={offline}
            onConfirm={async () => onConfirm(event.id)}
          />
        );
      }

      if (canStart(event.cms)) {
        return (
          <ScheduledControl
            nextInSchedule={allowStart}
            eventName={event.title}
            offline={offline}
            onStart={() => onStart(event.id)}
          />
        );
      }

      return null;
    }

    if (ongoing) {
      const pauseSupport = canPauseResume(event.cms);

      return (
        <OngoingControls
          eventName={event.title}
          offline={offline}
          paused={paused}
          pauseSupport={pauseSupport}
          onPause={() => onPause(event.id)}
          onResume={() => onResume(event.id)}
          onStop={() => onStop(event.id)}
        />
      );
    }

    return null;
  };

  return (
    <Stack sx={sx} direction="row" alignItems="center" gap={1}>
      {renderControls()}
    </Stack>
  );
}
