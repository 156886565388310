/* eslint-disable */
/**
 * Use in DriftComTracker only
 * {@link https://devdocs.drift.com/docs/installation}
 */
export const DriftComScripts = {
  load() {
    // Start of Async Drift Code
    var t = (window.driftt = window.drift = window.driftt || []);
    if (!t.init) {
      if (t.invoked)
        return void (
          window.console &&
          console.error &&
          console.error('Drift snippet included twice.')
        );
      (t.invoked = !0),
        (t.methods = [
          'identify',
          'config',
          'track',
          'reset',
          'debug',
          'show',
          'ping',
          'page',
          'hide',
          'off',
          'on',
        ]),
        (t.factory = function (e) {
          return function () {
            var n = Array.prototype.slice.call(arguments);
            return n.unshift(e), t.push(n), t;
          };
        }),
        t.methods.forEach(function (e) {
          t[e] = t.factory(e);
        }),
        (t.load = function (t) {
          var e = 3e5,
            n = Math.ceil(new Date() / e) * e,
            o = document.createElement('script');
          (o.type = 'text/javascript'),
            (o.async = !0),
            (o.crossorigin = 'anonymous'),
            (o.src = 'https://js.driftt.com/include/' + n + '/' + t + '.js');
          var i = document.getElementsByTagName('script')[0];
          i.parentNode.insertBefore(o, i);
        });
    }
    window.drift.SNIPPET_VERSION = '0.3.1';
    // End of Async Drift Code
  },

  // @legacy
  initialize() {
    if (!Boolean(CIRRUS_DRIFTCOM_TOKEN)) {
      return;
    }

    // Start of Async Drift Code
    var t = (window.driftt = window.drift = window.driftt || []);
    if (!t.init) {
      if (t.invoked)
        return void (
          window.console &&
          console.error &&
          console.error('Drift snippet included twice.')
        );
      (t.invoked = !0),
        (t.methods = [
          'identify',
          'config',
          'track',
          'reset',
          'debug',
          'show',
          'ping',
          'page',
          'hide',
          'off',
          'on',
        ]),
        (t.factory = function (e) {
          return function () {
            var n = Array.prototype.slice.call(arguments);
            return n.unshift(e), t.push(n), t;
          };
        }),
        t.methods.forEach(function (e) {
          t[e] = t.factory(e);
        }),
        (t.load = function (t) {
          var e = 3e5,
            n = Math.ceil(new Date() / e) * e,
            o = document.createElement('script');
          (o.type = 'text/javascript'),
            (o.async = !0),
            (o.crossorigin = 'anonymous'),
            (o.src = 'https://js.driftt.com/include/' + n + '/' + t + '.js');
          var i = document.getElementsByTagName('script')[0];
          i.parentNode.insertBefore(o, i);
        });
    }
    window.drift.SNIPPET_VERSION = '0.3.1';
    // End of Async Drift Code
  },

  configure(hidden = false) {
    let config = {
      verticalOffset: 58,
    };

    // Don't show chat. When you needed to reset user data only. On Login page for example
    if (hidden) {
      window.drift?.on('ready', function (api) {
        api.widget.hide();

        api.on('chatOpen', function () {
          api.widget.hide();
        });
      });
      config = {
        ...config,
        enableWelcomeMessage: false,
        enableCampaigns: false,
        enableChatTargeting: false,
        disableNewConversations: true,
      };
    }

    window.drift?.config(config);
  },

  /**
   * Identify user in Drift
   * @param {string} userId
   * @param {string} userEmail
   * @param {string} teamId
   */
  identify(userId, userEmail, teamId) {
    window.drift?.identify(userId, {
      userEmail,
      teamId,
    });
  },

  setUserAttributes(userId, userEmail, teamId) {
    window.drift?.on('ready', function (api) {
      api.setUserAttributes({userId, teamId, email: userEmail});
    });
  },

  resetUserData() {
    window.drift?.reset();
  },

  run(token) {
    window.drift?.load(token);
  },

  // @legacy
  start() {
    window.drift?.load(CIRRUS_DRIFTCOM_TOKEN);
  },
};
