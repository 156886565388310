import React, {useCallback} from 'react';
import classNames from 'classnames';
import {Container} from 'app/components/FleetManager/DeviceCard/ChildCard/Container';
import {router} from 'app/router/main';
import {Callback, ClassName} from 'app/types/common';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

interface Props extends ClassName {
  device: PearlSlotDeviceModel;
  masterDevice: PearlMasterDeviceModel;
  selectable: boolean;
  streamingDestinations: AnyStreamingDestinationModelType[];
  currentUserRole: AnyUserRoleModel;
  isUnify?: boolean;
  hasEvent?: boolean;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  checkIsDeviceSelected: Callback;
  toggleDeviceSelection: Callback;
  onChangeStreamingDestination: Callback;
}

export const ChannelCard: React.FC<Props> = React.memo(
  ({
    className,
    device,
    masterDevice,
    selectable,
    streamingDestinations,
    currentUserRole,
    isUnify = false,
    hasEvent,
    getDeviceById,
    checkIsDeviceSelected,
    toggleDeviceSelection,
    onChangeStreamingDestination,
  }) => {
    const deviceId = device.getId();

    const detailsUrl = router.url(isUnify ? 'projectDetails' : 'deviceDetails', {deviceId});

    const selected = selectable && checkIsDeviceSelected(deviceId);

    const handleSelect = useCallback(() => {
      toggleDeviceSelection(device);
    }, [device, toggleDeviceSelection]);

    return (
      <Container
        className={classNames('device-channel-card', className)}
        selected={selected}
        selectable={selectable}
        name={device.getName()}
        detailsUrl={detailsUrl}
        withThumbnail={true}
        withAudioMeter={true}
        device={device}
        masterDevice={masterDevice}
        currentUserRole={currentUserRole}
        streamingDestinations={streamingDestinations}
        hasEvent={hasEvent}
        getDeviceById={getDeviceById}
        onSelect={handleSelect}
        onChangeStreamingDestination={onChangeStreamingDestination}
      />
    );
  },
);
