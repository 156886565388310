import React, {ReactNode} from 'react';
import {Stack, Typography} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {StyleSx} from 'app/types/common';
import {ErrorSection} from 'app/components/ErrorBoundary/ErrorSection';

const iconSx: StyleSx = {fontSize: 72};
const errorSx: StyleSx = {mt: 5, width: 670};

type State = {
  hasError: boolean;
  title: string;
  message: string;
};

export class ErrorBoundary extends React.Component<{}, State> {
  state: Readonly<State> = {hasError: false, title: '', message: ''};

  static getDerivedStateFromError(error: any): State {
    return {
      hasError: true,
      title: error.message ?? 'Unknown',
      message: '',
    };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({message: info?.componentStack ?? error.stack ?? ''});
  }

  render(): ReactNode {
    const {hasError, title, message} = this.state;
    const {children} = this.props;

    if (hasError) {
      return (
        <Stack height="100vh" width="100vw" justifyContent="center" alignItems="center">
          <Stack direction="row" gap={3} alignItems="center">
            <WarningIcon sx={iconSx} color="warning" />

            <Typography variant="h4" fontWeight={600}>
              The app is unexpectedly down.
              <br /> Try to reload the page.
            </Typography>
          </Stack>

          <ErrorSection sx={errorSx} title={title} message={message} />
        </Stack>
      );
    }

    return children;
  }
}
