import React from 'react';
import {ClassName, DataId} from 'app/types/common';
import {PreviewUpdateStrategy} from 'app/hooks/Device/Preview/types';
import {useChannelPreview} from 'app/components/sharedReactComponents/Preview/ChannelPreview/useChannelPreview';

interface Props extends ClassName, DataId {
  channelId: string;
  updateStrategy: PreviewUpdateStrategy;
  big?: boolean;
  alt?: string;
}

export const ChannelPreview: React.VFC<Props> = ({
  className,
  channelId,
  updateStrategy,
  big,
  dataId,
  alt = '',
}) => {
  const src = useChannelPreview({
    channelId,
    big,
    updateStrategy,
  });

  return <img className={className} src={src} alt={alt} data-id={dataId} />;
};
