import React, {forwardRef, useMemo, useState} from 'react';
import {Box, ButtonBase, SxProps, Theme, Typography} from '@mui/material';
import {DataId, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {EventPopper} from 'app/components/sharedReactComponents/Events/Calendar/shared/EventPopper/EventPopper';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {GroupDetails} from 'app/components/sharedReactComponents/Events/Calendar/EventsSchedule/DayEvents/CalendarGroup/GroupDetails/GroupDetails';
import {Schedule} from 'app/domain/schedule';
import {isOngoing, isScheduled} from 'app/domain/schedule/utils';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {CategoryCount} from 'app/components/sharedReactComponents/Events/Calendar/shared/CategoryCount/CategoryCount';

const rootStyles: SxProps<Theme> = {
  borderRadius: 1,
  border: 1,
  alignItems: 'stretch',
  overflow: 'hidden',
  bgcolor: 'white',
};

interface Props extends Sx, DataId {
  events: Schedule.Event[];
  devices?: Map<string, PearlMasterDeviceModel>;
  placement?: 'vertical' | 'horizontal';
}

const CalendarGroupRef = forwardRef<HTMLButtonElement, Props>(
  ({sx, events, devices, placement = 'horizontal'}, ref) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const stats = useMemo(() => {
      return events.reduce<Record<Schedule.EventVariant, number>>(
        (acc, value) => {
          if (isOngoing(value.status)) {
            acc.ongoing += 1;
          } else if (isScheduled(value.status)) {
            acc.scheduled += 1;
          } else {
            acc.completed += 1;
          }

          return {...acc};
        },
        {
          completed: 0,
          ongoing: 0,
          scheduled: 0,
        },
      );
    }, [events]);

    const devicesCount = useMemo(() => {
      const set = events.reduce((acc, event) => {
        Array.from(event.devices.keys()).forEach((id) => acc.add(id));
        return acc;
      }, new Set<string>());
      return set.size;
    }, [events]);

    const total = events.length;

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    return (
      <ButtonBase
        ref={ref}
        data-id="group-events-card"
        sx={packSx(rootStyles, sx)}
        onClick={handleOpen}
      >
        <Box flex={1} pl={1} pr={0.5} py={0.5}>
          <Typography data-id="group-title" textAlign="left" fontWeight={600} mb={0.5}>
            {total} events
          </Typography>

          <CategoryCount
            data-id="group-stats"
            sx={{minWidth: 0}}
            ongoing={stats.ongoing}
            scheduled={stats.scheduled}
            completed={stats.completed}
          />

          <Typography textAlign="left">
            {devicesCount} {pluralizeN('device', devicesCount)}
          </Typography>
        </Box>

        <EventPopper
          anchorEl={anchorEl}
          placement={placement === 'horizontal' ? 'right' : 'top'}
          setAnchor={setAnchorEl}
        >
          <GroupDetails events={events} devices={devices} />
        </EventPopper>
      </ButtonBase>
    );
  },
);

export const CalendarGroup = React.memo(CalendarGroupRef);
