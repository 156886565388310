import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {RecordingStartStopButton} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton/RecordingStartStopButton';
import {DEVICE_START_STOP_BUTTON_STATUS} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton/constants';
import {SIZE} from 'app/constants';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

const getButtonStatus = (device: AnyDeviceModelType) =>
  device.isRecording()
    ? DEVICE_START_STOP_BUTTON_STATUS.STARTED
    : DEVICE_START_STOP_BUTTON_STATUS.STOPPED;

interface Props {
  device: AnyDeviceModelType;
  size?: SIZE;
  hasEvent?: boolean;
}

export const RecordingButton: React.FC<Props> = ({device, hasEvent = false, size}: Props) => {
  const [loading, setLoading] = useState(false);
  const fetching = useRef<boolean>(false);

  useEffect(() => {
    if (!fetching.current) {
      setLoading(false);
    }
  }, [device]);

  const action = useCallback(async () => {
    try {
      fetching.current = true;
      setLoading(true);
      await device.startStopRecordingCommand();
    } catch {
      setLoading(false);
    } finally {
      fetching.current = false;
    }
  }, [device]);

  const status = useMemo(() => getButtonStatus(device), [device]);

  return (
    <RecordingStartStopButton
      status={status}
      startTime={device.getDeviceRecordingStartTime() ?? undefined}
      size={size}
      startAction={action}
      stopAction={action}
      disabled={device.isOffline()}
      loading={loading}
      hasEvent={hasEvent}
    />
  );
};
