import {front} from 'app/api/CirrusApi';
import {AlertsContract} from 'app/components/entities/alerts';

export class AlertsApi {
  static async getTeamAlerts(): Promise<AlertsContract.Alert[]> {
    return front
      .users()
      .me()
      .alerts()
      .get()
      .then((response) => response || []);
  }

  static async getUnreadAlerts(): Promise<AlertsContract.Alert[]> {
    return front
      .users()
      .me()
      .alerts({'only-unread': true})
      .get()
      .then((alerts: AlertsContract.Alert[] | null) => (Array.isArray(alerts) ? alerts : []));
  }

  static async acknowledgeSince(timestamp: number): Promise<void> {
    return front.users().me().acknowledge_device_alerts().put({timestamp});
  }

  static async getGroups(): Promise<AlertsContract.Group[]> {
    return front.devices().alerts().dictionary().get();
  }

  static async updateSettings(configs: AlertsContract.Config[]): Promise<void> {
    await front.users().me().device_alerts().post(configs);
  }

  static async getSettings(): Promise<AlertsContract.Settings[]> {
    return front.users().me().device_alerts().get();
  }
}
