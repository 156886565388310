import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Callback} from 'app/types/common';

export function areAllProjectsSelected(
  projects: AnyDeviceModelType[],
  selectionPredicate: Callback<boolean, [string]>,
) {
  return !projects.some((p) => !selectionPredicate(p.getId()));
}

export const createRequestUnifyUrl = (teamId: string, userEmail: string) => {
  return `https://epiphan.com/unify/?email=${userEmail}&teamId=${teamId}#sign-up-preview`;
};
