import React from 'react';
import classNames from 'classnames';
import {CopyButton} from 'app/components/sharedReactComponents/CopyButton';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  url: string;
  emptyUrl: boolean;
}

export const TranscribeDeviceUrl: React.VFC<Props> = ({
  className,
  url,
  emptyUrl,
}) => {
  return (
    <div
      className={classNames('transcribe-device-url', {
        'transcribe-device-url--empty': emptyUrl,
      }, className)}
    >
      <span className="transcribe-device-url__label">
        Stream url
      </span>

      <div className="transcribe-device-url__container">
        <a
          className="transcribe-device-url__link"
          data-id="transcribe_device_link"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <OverflowTooltip>
            {url.split('://')[1]}
          </OverflowTooltip>
        </a>
        <CopyButton
          className="transcribe-device-url__copy-button"
          data-id="copy_link_button"
          value={url}
          text=""
        />
      </div>
    </div>
  );
};
