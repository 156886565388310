import React, {useCallback} from 'react';
import classNames from 'classnames';
import {Callback, ClassName} from 'app/types/common';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';

interface ClearButtonProps {
  hidden: boolean;
  onClick: Callback;
}

const ClearButton: React.VFC<ClearButtonProps> = ({
  hidden,
  onClick,
}) => (
  <span
    className={classNames('filter-selector-search__clear-button', hidden && 'filter-selector-search__clear-button--hidden')}
    data-id="filter_group_search_clear"
    aria-hidden={true}
    onClick={onClick}
  >
    {Icons.close().size(SIZE.S).reactComponent()}
  </span>
);

interface Props extends ClassName {
  value: string;
  onChange: Callback;
}

const FilterSelectorSearch: React.VFC<Props> = ({
  className,
  value,
  onChange,
}) => {
  const handleClear = useCallback(() => {
    onChange({target: {value: ''}});
  }, [onChange]);

  return (
    <FormControl
      className={classNames('filter-selector-search', className)}
      value={value}
      name="filter_name"
      placeholder="Find by name"
      size={SIZE.S}
      fullWidth={true}
      endAdornment={(
        <ClearButton
          hidden={value === ''}
          onClick={handleClear}
        />
      )}
      onChange={onChange}
    />
  );
};

export {FilterSelectorSearch};
