import React, {useState} from 'react';
import {Card, Collapse, IconButton, Stack, Typography, alpha} from '@mui/material';
import {blue, grey} from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import {DataId, Sx} from 'app/types/common';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {packSx} from 'app/util/packSx/packSx';
import {PresetSections} from 'app/components/sharedReactComponents/DevicePresetCard/PresetSections/PresetSections';
import {Edge} from 'app/domain/edge';
import {isNil} from 'app/util/isNil';

type CardProps = Sx &
  DataId & {
    selected: boolean;
    preset: Edge.TeamPreset;
    onSelect: () => void;
  };

export function SelectPresetCard({dataId, sx, selected, preset, onSelect}: CardProps) {
  const [expanded, setExpanded] = useState(false);

  const hasDescription = Boolean(preset.description);
  const hasWarning = !isNil(preset.sections.find((s) => s === 'network'));

  return (
    <Card
      data-id={dataId}
      tabIndex={0}
      sx={packSx(
        {bgcolor: grey[50], p: 1},
        selected
          ? ({palette: {primary}}) => ({
              bgcolor: alpha(blue[50], 0.7),
              borderColor: primary.main,
              boxShadow: `0 0 0 1px ${primary.main}`,
              transitionProperty: 'box-shadow, background-color',
              transitionDuration: 0.3,
              transitionTimingFunction: 'ease',
            })
          : false,
        sx,
      )}
      variant="outlined"
      role="button"
      onClick={() => {
        onSelect();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSelect();
        }
      }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography minWidth={0} flex={1} component="span">
          <OverflowTooltip>{preset.name}</OverflowTooltip>
        </Typography>

        <PresetSections sections={preset.sections} />

        <IconButton
          data-id="preset-toggler"
          disabled={!hasDescription}
          disableRipple={false}
          onClick={(e) => {
            setExpanded((p) => !p);
            e.stopPropagation();
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <ExpandMoreIcon
            sx={{transform: `rotate(${expanded ? 180 : 0}deg)`, transition: 'transform .3s'}}
          />
        </IconButton>
      </Stack>

      <Collapse in={expanded}>
        <Typography textAlign="left" mt={1}>
          {preset.description}
        </Typography>
      </Collapse>

      {hasWarning && (
        <Stack mt={1} direction="row" gap={0.5}>
          <WarningIcon color="warning" fontSize="small" />

          <Typography color="warning.main" variant="body2">
            This preset includes Network settings, make sure you know that these settings are
            correct
          </Typography>
        </Stack>
      )}
    </Card>
  );
}
