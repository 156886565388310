import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

const UNGROUPED_KEY = '__ungrouped__';

export class DeviceByGroupMap {
  private readonly map = new Map<string, AnyDeviceModelType[]>();

  constructor(devices: AnyDeviceModelType[], allowGroups: boolean) {
    const map = new Map<string, AnyDeviceModelType[]>();

    devices.forEach((device) => {
      const groupId = device.getGroupId();
      const mapKey = !allowGroups || groupId === '' ? UNGROUPED_KEY : groupId;

      const list = map.get(mapKey);

      if (!list) {
        map.set(mapKey, [device]);
      } else {
        list.push(device);
      }
    });

    this.map = map;
  }

  getUngroupedDevices(): AnyDeviceModelType[] {
    return this.map.get(UNGROUPED_KEY) ?? [];
  }

  getDeviceGroupById(groupId: string): AnyDeviceModelType[] {
    return this.map.get(groupId) ?? [];
  }
}
