import {CloudContext} from 'app/components/entities/cloud/CloudContext';
import {useContext} from 'react';

export function useCloudEntities() {
  const ctx = useContext(CloudContext);

  if (!ctx) {
    throw new Error('Not in user context');
  }

  return ctx;
}
