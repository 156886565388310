import React from 'react';
import classNames from 'classnames';
import {TableColumnType} from 'app/components/sharedReactComponents/Table/types';

interface Props {
  cell: TableColumnType;
  rowData: any;
  rowIndex: number;
  renderAsTable: boolean;
}

const TableRowCell: React.VFC<Props> = ({
  cell,
  rowData,
  rowIndex,
  renderAsTable,
}) => {
  const cellData = rowData[cell.dataKey];

  const CellComponent = renderAsTable ? 'td' : 'div';
  let style;
  if (!renderAsTable) {
    style = {
      flex: `${cell.flexGrow ?? 0} 1 ${cell.width ?? 100}px`
    };
  }

  return (
    <CellComponent
      className={classNames(
        'dashboard-table__row-cell',
        `dashboard-table__row-cell--${cell.dataKey}`,
        cell.className,
      )}
      style={style}
    >
      {cell.cellRenderer ? cell.cellRenderer({cellData, rowData, rowIndex}) : cellData}
    </CellComponent>
  );
};

export {
  TableRowCell,
};
