import React, {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {KeyboardCode} from 'app/constants';
import {LoadingWrapper} from 'app/components/sharedReactComponents/LoadingWrapper';
import {Callback, Component} from 'app/types/common';

const processValueToRender = (value: number | string | null): number | string =>
  value === null ? '' : value;

export interface NumberProps {
  min?: number;
  max?: number;
  step?: number;
  hideArrowButtons?: boolean;
}

interface Props extends Component, NumberProps {
  name?: string;
  value: number;
  loading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange?: Callback;
}

export const NumberInput = ({
  dataId,
  className,
  name,
  value,
  min,
  max,
  step,
  placeholder,
  loading,
  disabled,
  hideArrowButtons,
  onChange,
  ...otherProps
}: Props) => {
  const [inputValue, setInputValue] = useState<number | string>('');

  useEffect(() => {
    setInputValue(processValueToRender(value));
  }, [value]);

  const handleChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleBlur = () => {
    saveValue();
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.code === KeyboardCode.Enter) {
      saveValue();
    }
  };

  function saveValue() {
    let newValue = inputValue;

    if (inputValue !== '') {
      newValue = Number(inputValue);

      if (min !== undefined && newValue < min) {
        newValue = min;
      }

      if (max !== undefined && newValue > max) {
        newValue = max;
      }
    }

    setInputValue(newValue);

    if (value !== newValue) {
      onChange?.({
        target: {
          name,
          value: newValue,
        },
      });
    }
  }

  return (
    <LoadingWrapper loading={loading}>
      <input
        data-id={dataId}
        className={classNames(
          'cr-number-input',
          hideArrowButtons && 'cr-number-input--hide-arrow-buttons',
          className,
        )}
        type="number"
        name={name}
        value={inputValue}
        min={min}
        max={max}
        step={step}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        {...otherProps}
      />
    </LoadingWrapper>
  );
};
