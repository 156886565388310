import React from 'react';
import {Box, Stack} from '@mui/material';
import {Component} from 'app/types/common';
import {SrtSummary} from 'app/components/sharedReactComponents/MeetingSrtSummary/SrtSummary/SrtSummary';
import {SrtSummaryCounts} from 'app/hooks/useSrtCount';

interface Props extends Component {
  label: string;
  count: SrtSummaryCounts;
}

export const MeetingSrtSummary = ({dataId, className, count, label}: Props) => {
  return (
    <Stack className={className} data-id={dataId} sx={{fontSize: '12px'}} direction="row">
      <Box data-id="srt_summary_label" component="span" sx={{mr: 1, color: '#2d3546'}}>
        {label}
      </Box>

      <Stack sx={{gap: 1.5}} direction="row">
        {count.connected.value > 0 && (
          <SrtSummary
            state="connected"
            label={`${count.connected.value}`}
            tooltip="Connected"
            dataId="srt_connected_count"
          />
        )}
        {count.connecting.value > 0 && (
          <SrtSummary
            state="connecting"
            label={`${count.connecting.value}`}
            tooltip="Connecting"
            dataId="srt_connecting_count"
          />
        )}
        {count.disconnected.value > 0 && (
          <SrtSummary
            state="disconnected"
            label={`${count.disconnected.value}`}
            tooltip="Left with active stream"
            dataId="srt_disconnected_count"
          />
        )}
        {count.error.value > 0 && (
          <SrtSummary
            state="error"
            label={`${count.error.value}`}
            tooltip={count.error.tooltip}
            dataId="srt_error_count"
          />
        )}
      </Stack>
    </Stack>
  );
};
