/**
 * {@link https://www.iana.org/assignments/media-types/media-types.xhtml}
 */
enum MediaType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  XWwwFormUrlencoded = 'application/x-www-form-urlencoded',
}

export {
  MediaType,
};
