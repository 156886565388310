import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {HealthStatus} from 'app/components/DeviceDetails/constants';
import {FleetManagerColumnId} from 'app/components/FleetManager/constants';
import {FilterSwitchGroupId} from 'app/components/FleetManager/useFilterSwitches';
import {
  modelCell,
  statusCell,
  ipAddressCell,
  serialNumberCell,
  firmwareCell,
  systemLoadCell,
  temperatureCell,
  uptimeCell,
  storageCell,
  transferQueueCell,
  healthCell,
  accessCell,
} from 'app/components/FleetManager/cells';

export const sortOptionsMap = {
  status: {
    value: 'status',
    label: 'Status',
    callback: (device: AnyDeviceModelType) => device.isOnline(),
  },
  name: {
    value: 'name',
    label: 'Name',
    callback: (device: AnyDeviceModelType) => device.getName(),
  },
  health: {
    value: 'health',
    label: 'Health',
    callback: (device: AnyDeviceModelType) => {
      // Errors, Warnings and then No warnings
      switch (device.getHealthStatus()) {
        case HealthStatus.Good:
          return 1;
        case HealthStatus.Fair:
          return 2;
        default:
          return 3;
      }
    },
  },
  model: {
    value: 'model',
    label: 'Model',
    callback: (device: AnyDeviceModelType) => device.getModelName(),
  },
} as const;

export const sortOptions = [
  sortOptionsMap.status,
  sortOptionsMap.name,
  sortOptionsMap.health,
  sortOptionsMap.model,
];

export const defaultActiveColumns = [
  FleetManagerColumnId.Model,
  FleetManagerColumnId.Status,
  FleetManagerColumnId.IpAddress,
  FleetManagerColumnId.Temperature,
  FleetManagerColumnId.Storage,
  FleetManagerColumnId.Health,
  FleetManagerColumnId.Access,
];

export const collapsibleFilterSwitchGroups = new Set([
  FilterSwitchGroupId.View,
  FilterSwitchGroupId.Group,
  FilterSwitchGroupId.Status,
  FilterSwitchGroupId.Activity,
  FilterSwitchGroupId.Model,
  FilterSwitchGroupId.Health,
  FilterSwitchGroupId.Schedule,
  FilterSwitchGroupId.CMS,
]);

export const searchableFilterSwitchGroups = new Set([FilterSwitchGroupId.Group]);

export const columns = [
  modelCell(),
  statusCell(),
  ipAddressCell(),
  serialNumberCell(),
  firmwareCell(),
  temperatureCell(),
  storageCell(),
  transferQueueCell(),
  systemLoadCell(),
  uptimeCell(),
  healthCell(),
  accessCell(),
];
