type Nullish = undefined | null;

/**
 * Value is `undefined` or `null`
 *
 * ```typescript
 * isNil(undefined); //=> true
 * isNil(null); //=> true
 * isNil(false); //=> false
 * isNil(0); //=> false
 * isNil(''); //=> false
 * ```
 */
function isNil(value?: any): value is Nullish {
  return typeof value === 'undefined' || value === null;
}

export {isNil};
