import React from 'react';
import {Divider, Stack} from '@mui/material';
import {grey} from '@mui/material/colors';
import {Sx, TimeStampSeconds} from 'app/types/common';
import {Weekday} from 'app/components/sharedReactComponents/Events/Calendar/Week/Weekday/Weekday';

interface Props extends Sx {
  stats: Map<TimeStampSeconds, number>;
  selected: number;
  onSelect: (t: TimeStampSeconds) => void;
}

export function Week({sx, stats, selected, onSelect}: Props) {
  return (
    <Stack
      sx={sx}
      position="relative"
      height={104}
      justifyContent="stretch"
      boxShadow={(t) => `0px 1px 0px 0px ${selected > 0 ? grey[50] : t.palette.primary.main}`}
    >
      <Stack
        direction="row"
        flexGrow={1}
        divider={<Divider orientation="vertical" flexItem={true} />}
      >
        {Array.from(stats.entries()).map((p) => {
          const [day, count] = p;

          return (
            <Weekday
              sx={{flex: 1}}
              key={day}
              count={count}
              day={day}
              selected={day === selected}
              onClick={() => onSelect(day)}
              onClose={() => onSelect(0)}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
