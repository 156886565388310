import {useEffect} from 'react';
import {WS} from 'app/api/WebSocket/WS';
import {useSocketHandlers} from 'app/hooks/Meeting/useSocketHandlers';

export function useMeetingsSocket(enabled: boolean): void {
  const {onMeetingChange, onParticipantChange} = useSocketHandlers();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    WS.onMeetingChange(onMeetingChange);

    return () => {
      WS.offMeetingChange(onMeetingChange);
    };
  }, [enabled, onMeetingChange]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    WS.onParticipantUpdate(onParticipantChange);

    return () => {
      WS.offParticipantUpdate(onParticipantChange);
    };
  }, [enabled, onParticipantChange]);
}
