import React, {useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';
import {useMounted} from 'app/hooks/useIsMounted';
import {NamesList} from 'app/components/sharedReactComponents/ItemsEnumeration';
import {pluralizeN} from 'app/components/helpers/commonHelpers';

type Action = 'start' | 'stop';

type Props = {
  loading: boolean;
  names: string[];
  action: Action;
  onSwitch: (action: Action) => Promise<void>;
  onClose: () => void;
};

function ProjectsSwitcher({loading, names, action, onSwitch, onClose}: Props) {
  const isStart = action === 'start';

  return (
    <Box>
      <DialogTitle>
        {isStart ? 'Start' : 'Stop'}{' '}
        <NamesList max={3} variant="inherit" color="primary.main" names={names} />{' '}
        {pluralizeN('project', names.length)}
      </DialogTitle>

      <DialogContent>
        {isStart ? (
          <Typography textAlign="center">
            Starting your project may take several minutes.
          </Typography>
        ) : (
          <Typography textAlign="center">
            This will terminate all active streaming and recording sessions.
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="confirm-switch-btn"
          sx={{minWidth: 70}}
          loading={loading}
          variant="contained"
          color={isStart ? 'secondary' : 'error'}
          disableRipple={false}
          onClick={() => onSwitch(action)}
        >
          {isStart ? 'Start' : 'Stop'}
        </LoadingButton>

        <Button
          data-id="cancel-switch-btn"
          sx={{minWidth: 70}}
          variant="outlined"
          color="info"
          disableRipple={false}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
}

type PromptProps = Prompt &
  Omit<Props, 'loading'> &
  Pick<DialogProps, 'maxWidth' | 'fullWidth' | 'keepMounted'>;

export function ProjectsSwitcherDialog({
  open,
  names,
  action,
  onSwitch,
  onClose,
  ...props
}: PromptProps) {
  const mounted = useMounted();
  const [loading, setLoading] = useState(false);

  const handleSwitch = async (type: Action) => {
    try {
      setLoading(true);
      await onSwitch(type);
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog {...props} open={open} onClose={onClose}>
      <ProjectsSwitcher
        names={names}
        loading={loading}
        action={action}
        onSwitch={handleSwitch}
        onClose={onClose}
      />
    </Dialog>
  );
}
