import React, {Component} from 'react';
import classNames from 'classnames';
import {SketchPicker} from 'react-color';
import tinycolor from 'tinycolor2';
import {Dropdown} from 'app/components/sharedReactComponents/Dropdown';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {KeyboardCode} from 'app/constants';
import {
  Callback,
  ClassName,
} from 'app/types/common';

interface Props extends ClassName {
  value: string;
  name?: string;
  placeholder?: string;
  disabled: boolean;
  readOnly: boolean;
  loading?: boolean;
  onChange?: Callback;
}

interface State {
  value: string;
}
export class ColorInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      value,
    } = this.props;

    if (value !== prevProps.value) {
      this.setState({value});
    }
  }

  _createEventData(value) {
    return {
      target: {
        value,
        name: this.props.name,
      }
    };
  }

  handleColorPickerChange = (color) => {
    this.setState({
      value: color.hex,
    });
  };

  handleDropdownBlur = () => {
    this.saveValue();
  };

  saveValue() {
    const {
      value: prevValue,
      onChange,
    } = this.props;

    const color = tinycolor(this.state.value);

    if (color.isValid()) {
      const value = color.toString();

      if (value !== prevValue) {
        this.setState({value}, () => onChange?.(this._createEventData(value)));
      }
    } else {
      this.setState({value: prevValue});
    }
  }

  handleInputChange = ({target: {value}}) => {
    this.setState({value});
  };

  handleInputBlur = () => {
    this.saveValue();
  };

  handleInputKeyUp = (e: React.KeyboardEvent) => {
    if (e.code === KeyboardCode.Enter) {
      this.saveValue();
    }
  };

  render() {
    const {
      className,
      placeholder,
      disabled,
      readOnly,
      loading,
      name,
      value: valueProp,
      onChange,
      ...otherProps
    } = this.props;
    const {
      value,
    } = this.state;

    return (
      <FormControl
        className={classNames('color-input', {
          'color-input--disabled': disabled || readOnly,
        }, className)}
        value={value}
        name={name}
        placeholder={placeholder}
        startAdornment={(
          <Dropdown
            content={(
              <SketchPicker
                color={value}
                disableAlpha={true}
                styles={{
                  default: {
                    picker: {
                      borderRadius: 'none',
                      boxShadow: 'none',
                    }
                  }
                }}
                onChangeComplete={this.handleColorPickerChange}
              />
            )}
            disabled={disabled || readOnly}
            interactive={true}
            appendToBody={true}
            onBlur={this.handleDropdownBlur}
          >
            <div
              className="color-input__color"
              style={{
                backgroundColor: value,
              }}
            />
          </Dropdown>
        )}
        loading={loading}
        disabled={disabled}
        readOnly={readOnly}
        onChange={this.handleInputChange}
        onBlur={this.handleInputBlur}
        onKeyUp={this.handleInputKeyUp}
        {...otherProps}
      />
    );
  }
}
