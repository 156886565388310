import React, {Component} from 'react';
import classNames from 'classnames';
import {VARIANT} from 'app/constants';
import {Icons} from 'app/util/icons';
import {Button} from 'app/components/sharedReactComponents/Button';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {DeleteStreamingDestinationPrompt} from 'app/components/libs/dialogs/DeleteStreamingDestinationPrompt';
import {StreamingDestinationSettings} from 'app/components/sharedReactComponents/StreamingDestinationSettings';
import {StreamingServiceIcon} from 'app/components/StreamingServices/StreamingServiceIcon';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  streamingDestination: any;
  editable: boolean;
  onDelete: Callback;
}

interface State {
  settingsOpened: boolean;
  changingStreamingStatus: boolean;
  updating: boolean;
}
export class StreamingDestination extends Component<Props, State> {
  _mounted = true;
  constructor(props) {
    super(props);

    this.state = {
      settingsOpened: false,
      changingStreamingStatus: false,
      updating: false,
    };

    // this._mounted = true
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleClickSettingsToggle = () => {
    this.setState(prevState => ({...prevState, settingsOpened: !prevState.settingsOpened}));
  };

  handleChange = (data) => {
    this.setState({updating: true});

    this.props.streamingDestination.updateAction(data)
      .finally(() => this.setState({updating: false}));
  };

  handleClickDelete = () => {
    const {
      streamingDestination,
      onDelete,
    } = this.props;

    DeleteStreamingDestinationPrompt.show({
      streamingDestination,
      onDone: () => onDelete(streamingDestination),
    });
  };

  render() {
    const {
      className,
      streamingDestination,
      editable,
    } = this.props;
    const {
      settingsOpened,
      updating,
    } = this.state;

    const icon = StreamingServiceIcon.getSmallIconByType(streamingDestination.getType());

    return (
      <div className={classNames('streaming-destination', className)}>
        <div className="streaming-destination__content">
          <div className="streaming-destination__label">
            Streaming destination
          </div>

          <div className="streaming-destination__name-container">
            {icon?.class('streaming-destination__service-icon').reactComponent()}

            <div className="streaming-destination__name">
              <OverflowTooltip dataId="streaming_destination_name">
                {streamingDestination.getName()}
              </OverflowTooltip>
            </div>
          </div>

          <Button
            className={classNames('streaming-destination__settings-toggle', {
              'streaming-destination__settings-toggle--opened': settingsOpened,
            })}
            dataId="streaming_destination_settings_toggle"
            variant={VARIANT.LINK}
            onClick={this.handleClickSettingsToggle}
          >
            Settings

            {Icons.arrowRight().reactComponent()}
          </Button>

          <div className="streaming-destination__content-spacer"/>
        </div>

        {settingsOpened && (
          <div className="streaming-destination__settings">
            <StreamingDestinationSettings
              streamingDestination={streamingDestination}
              updating={updating}
              disabled={streamingDestination.isStreaming() || !editable}
              onChange={this.handleChange}
              onClickDelete={this.handleClickDelete}
            />
          </div>
        )}
      </div>
    );
  }
}
