import React from 'react';
import {Dialog} from '@mui/material';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {Callback} from 'app/types/common';
import {ConnectToHosted} from 'app/components/dialogs/ConnectToHostedDialog/ConnectToHosted';

type Props = React.ComponentProps<typeof ConnectToHosted> & {
  open: boolean;
  onClose: Callback;
};

export function ConnectToHostedDialog({open, onConnect, onClose}: Props) {
  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
      <DialogClose onClose={onClose} />

      <ConnectToHosted onConnect={onConnect} />
    </Dialog>
  );
}
