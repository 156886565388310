import {useEffect, useMemo, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {FirmwareFeature} from 'app/store/models/features/firmware/FirmwareFeature';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {isNil} from 'app/util/isNil';
import {Edge} from 'app/domain/edge';

interface Return {
  firmwareLoading: boolean;
  toUpdate: AnyDeviceModelType[];
  refetchFirmware: Fn<Promise<any>>;
}

export function useBatchFirmwareStates(disabled: boolean, devices: AnyDeviceModelType[]): Return {
  const [toUpdate, setToUpdate] = useState<AnyDeviceModelType[]>([]);

  const ids = useMemo<string>(() => {
    if (disabled) {
      return '';
    }

    const filtered = devices.filter(
      (d) => d.isOnline() && ModelService.isMultiChannel(d.getModelName()),
    );

    return filtered.map((d) => d.getId()).join();
  }, [disabled, devices]);

  const {
    data: firmwareMap,
    isInitialLoading,
    refetch,
  } = useQuery({
    queryKey: ['batch-firmware', ids],
    queryFn: async () => {
      const parsed = ids.split(',');
      return DeviceApiService.getDevicesFirmware(parsed);
    },
    select: mapFirmware,
    enabled: ids.length > 0,
  });

  useEffect(() => {
    if (isNil(firmwareMap)) {
      setToUpdate([]);
      return;
    }

    const result = devices.filter((d) => {
      const fw = firmwareMap.get(d.getId());
      if (fw) {
        return fw.updateAvailable;
      }

      return false;
    });
    setToUpdate(result);
  }, [firmwareMap, devices]);

  return {
    firmwareLoading: isInitialLoading,
    toUpdate,
    refetchFirmware: refetch,
  };
}

function mapFirmware(c: Record<string, Edge.Firmware>): Map<string, FirmwareFeature> {
  const entries = Object.entries(c).map(([k, v]) => [k, new FirmwareFeature(v)] as const);
  return new Map<string, FirmwareFeature>(entries);
}
