import {UNITS} from 'app/util/bytes';

function toNumber(value: string): number {
  const result = parseFloat(value);

  return isFinite(result) ? result : 0;
}

/**
 * Rounds given decimal `value` to a given `precision` making sure the result contain `precision` number of digits
 */
function withPrecision(value: number, precision = 1): string {
  if (Math.abs(value) >= 10) {
    precision++;
  }

  if (Math.abs(value) >= 100) {
    precision++;
  }

  return value.toPrecision(precision);
}

/**
 * Rounds given decimal `value` to a `precision` number of digits after delimiter
 */
function roundTo(value: number, precision = 0): number {
  const precisionMultiplier = Math.pow(10, precision);

  return Math.round(value * precisionMultiplier) / precisionMultiplier;
}

function formatArray(
  value: number,
  minThreshold: number,
  precision: number,
): [value: string, unit: string] {
  let current = value;
  let unit = '';

  for (let i = 0; i < UNITS.length; i += 1) {
    const nextUnit = UNITS[i];

    const next = current / 1000;

    if (nextUnit) {
      unit = nextUnit;
    }

    if (next < minThreshold) {
      break;
    }

    current = next;
  }

  return [current.toFixed(precision), unit];
}

function format(value: number, minThreshold: number, precision: number) {
  return formatArray(value, minThreshold, precision).join(' ');
}

export {toNumber, withPrecision, roundTo, format};
