import {useEffect} from 'react';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {usePrimitiveArray} from 'app/hooks/usePrimitiveArray';

export function useDeviceIds(devices: AnyDeviceModelType[]): string[] {
  const [ids, setIds] = usePrimitiveArray(() => devices.map(device => device.getId()));

  useEffect(() => {
    const current = devices.map(device => device.getId());
    setIds(current);
  }, [devices]);

  return ids;
}
