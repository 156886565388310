import {front} from 'app/api/CirrusApi';
import {CloudContract} from 'app/contracts/cloud';

export class UserApiService {
  static async getUser(): Promise<CloudContract.User> {
    return front.users().me().get();
  }

  static async getAgreement(): Promise<CloudContract.Agreement> {
    return front.users().agreement().get();
  }

  static async getPolicy(): Promise<CloudContract.Policy> {
    return front.users().policy().get();
  }

  static async acceptAgreement(id: string, time: number): Promise<void> {
    front.users().agreement().post({AgreementID: id, AgreementTime: time});
  }
}
