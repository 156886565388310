import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react';
import {useQuery} from '@tanstack/react-query';
import {PageHeader} from 'app/components/sharedReactComponents/PageHeader';
import {IndeterminateProgressBar} from 'app/components/sharedReactComponents/IndeterminateProgressBar';
import {PairDeviceButton} from 'app/components/libs/buttons/PairDeviceButton';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {processTableListItem} from 'app/components/Dashboard/TranscribeDevices/utils';
import {Table} from 'app/components/Dashboard/Devices/TableView/Table';
import {
  nameCell,
  deviceAudioCell,
  transcribingCell,
  statusCell,
} from 'app/components/Dashboard/TranscribeDevices/cells';
import {AddPaymentMethodMessage} from 'app/components/sharedReactComponents/AddPaymentMethodMessage';
import {TranscribingStatus} from 'app/components/DeviceDetails/constants';
import {LiveScryptDeviceModel} from 'app/components/DeviceDetails/Models/LiveScryptDeviceModel';
import {useCurrentTeamStore} from 'app/store/hooks';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {createDeviceModel} from 'app/components/DeviceDetails/Models/Fabric';

export const TranscribeDevices: React.VFC = observer(() => {
  const currentTeamStore = useCurrentTeamStore();

  const {data: devices, isSuccess} = useLiveScrypts();

  const startStopTranscribingAction = useCallback(
    (deviceId: string) => {
      const device = devices?.find((device) => device.getId() === deviceId);

      if (!device) {
        return;
      }

      const status = device.getTranscribingStatus();

      if (status === TranscribingStatus.Started) {
        return device.stopTranscribing();
      }

      if (status === TranscribingStatus.Paused) {
        return device.resumeTranscribing();
      }

      return device.startTranscribing();
    },
    [devices],
  );

  const devicesCount = devices?.length ?? 0;

  const list = useMemo(() => (devices ?? []).map(processTableListItem), [devices]);

  const renderBody = () => {
    if (!isSuccess) {
      return <IndeterminateProgressBar />;
    }

    return (
      <div className="container transcribe-devices__container">
        <Table
          list={list}
          cells={[
            nameCell(),
            deviceAudioCell(),
            transcribingCell({
              teamCanTranscribing: currentTeamStore.getCapabilities().transcribing(),
              startStopTranscribingAction,
            }),
            statusCell(),
          ]}
          storeKey="Dashboard.TranscribeDevices.TableView"
          renderAsTable={false}
        />
      </div>
    );
  };

  return (
    <div className="transcribe-devices">
      <PageHeader
        title={`${devicesCount} LiveScrypt ${pluralizeN('device', devicesCount)}`}
        centerMessage={<AddPaymentMethodMessage />}
        buttons={
          <>
            <PairDeviceButton />
          </>
        }
      />

      {renderBody()}
    </div>
  );
});

function useLiveScrypts() {
  return useQuery({
    queryKey: ['team', 'livescrypts'],
    queryFn: async () => {
      const all = await DeviceApiService.getUnits();
      const mapped = all
        .map((d) => createDeviceModel(d))
        .filter((d): d is LiveScryptDeviceModel => d instanceof LiveScryptDeviceModel);
      return mapped;
    },
    refetchInterval: 1000 * 5,
  });
}
