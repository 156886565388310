import React, {useContext} from 'react';
import {RootStore} from 'app/store/RootStore';

export const StoreContext = React.createContext<RootStore | undefined>(undefined);

export function useRootStore(): RootStore {
  const ctx = useContext(StoreContext);

  if (!ctx) {
    throw new Error('Out of root store');
  }

  return ctx;
}
