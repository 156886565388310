import React, {useMemo} from 'react';
import {Box, Divider, Stack, Typography} from '@mui/material';
import {InviteUserForm} from 'app/components/Teams/UsersPanel/InviteUserForm';
import {Sx} from 'app/types/common';
import {TeamUser} from 'app/models/User/TeamUser';
import {Notifications} from 'app/components/Notifications';
import {TeamMember} from 'app/components/widgets/team';
import {useMembers} from 'app/components/features/team';
import {Team, TeamApi, sortMembers} from 'app/components/entities/team';

interface Props extends Sx {
  teamId: string;
  teamName: string;
  user: TeamUser;
  hasPayment: boolean;
}

export function UsersPanel({sx, teamId, teamName, user, hasPayment}: Props) {
  const {data, isSuccess} = useMembers({teamId, enabled: true});

  const permitEdit = user.role.canEditTeams();
  const permitTransfer = user.role.canEditTeamOwner();

  const currentId = user.id;

  const sorted = useMemo(() => sortMembers(data ?? [], currentId), [data, currentId]);

  const handleSetRole = async (userId: string, role: Exclude<Team.MemberRole, 'owner'>) => {
    try {
      await TeamApi.setMemberRole(teamId, userId, role);
    } catch {
      Notifications.addErrorNotification('Could not update user role');
    }
  };

  const handleTransferOwnership = async (userId: string) => {
    try {
      await TeamApi.transferOwnership(teamId, userId, hasPayment);
      Notifications.addSuccessNotification('Transfer ownership request is sent');
    } catch {
      Notifications.addErrorNotification('Could not transfer ownership');
    }
  };

  const handleDelete = async (userId: string) => {
    try {
      await TeamApi.removeMember(teamId, userId);
    } catch {
      Notifications.addErrorNotification('Could not delete user');
    }
  };

  return (
    <Stack
      sx={sx}
      direction="row"
      gap={2}
      divider={<Divider orientation="vertical" flexItem={true} />}
    >
      <Box minWidth={0} flex={1}>
        <Typography variant="h5" mb={1}>
          Team members
        </Typography>

        {isSuccess ? (
          <Stack divider={<Divider orientation="horizontal" flexItem={true} />}>
            {sorted.map((user) => (
              <TeamMember
                key={user.id}
                member={user}
                current={user.id === currentId}
                permitEdit={permitEdit}
                permitTransfer={permitTransfer}
                teamName={teamName}
                hasPayment={hasPayment}
                onSetRole={handleSetRole}
                onTransferOwnership={handleTransferOwnership}
                onDelete={handleDelete}
              />
            ))}
          </Stack>
        ) : (
          <Typography my={2} textAlign="center">
            Loading team members
          </Typography>
        )}
      </Box>

      {permitEdit && (
        <Box data-id="invite_user_form" width={350}>
          <InviteUserForm teamId={teamId} />
        </Box>
      )}
    </Stack>
  );
}
