import React from 'react';
import {PREVIEW_MODE} from 'app/components/DeviceDetails/ChannelDetailsTab/constants';
import {Switcher, SWITCHER_THEME} from 'app/components/sharedReactComponents/Switcher';
import {SIZE} from 'app/constants';
import {Callback} from 'app/types/common';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';

interface Props {
  previewMode: PREVIEW_MODE;
  onPreviewModeChange: Callback;
  tooltip?: string;
  disabled?: boolean;
}

export const FrameRate = React.forwardRef<HTMLDivElement, Props>(
  ({previewMode, disabled, tooltip, onPreviewModeChange}, ref) => {
    const handlePreviewModeChange = ({target: {value}}) => {
      const mode = value ? PREVIEW_MODE.LIVE_HD : PREVIEW_MODE.LIVE_SD;
      onPreviewModeChange({target: {value: mode}});
    };

    return (
      <div ref={ref}>
        <Tooltip content={tooltip}>
          <div>
            <span className="device-status-preview-controls__label">Frame Rate:</span>

            <Switcher
              data-id="device_preview_mode_switcher"
              value={previewMode === PREVIEW_MODE.LIVE_HD}
              theme={SWITCHER_THEME.SILVER_SAND}
              showLabel={true}
              labelOnText="Full"
              labelOffText="Low"
              size={SIZE.S}
              disabled={disabled}
              onChange={handlePreviewModeChange}
            />
          </div>
        </Tooltip>
      </div>
    );
  },
);
