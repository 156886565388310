import {useQuery} from '@tanstack/react-query';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {EdgeMapper} from 'app/util/mappers/edge/EdgeMapper';

interface Args {
  deviceId: string;
  enabled: boolean;
}

export function useDeviceSources({deviceId, enabled}: Args) {
  return useQuery({
    queryKey: ['sources', deviceId],
    queryFn: async () => {
      const response = await DeviceApiService.getSources(deviceId);

      return EdgeMapper.mapSources(response);
    },
    refetchInterval: 10 * 1000,
    enabled,
  });
}
