/* eslint-disable @typescript-eslint/member-ordering */
import EventEmitter from 'eventemitter3';
import {Service} from 'app/api/Service';
import {Cache} from 'app/util/Cache';
import {Url} from 'app/api/util/Url';
import {AjaxErrorCallback, Endpoints} from 'app/api/types';

type Params = {
  baseUrl: string;
};

export class Api {
  baseUrl: string;
  _teamId: string;
  emitter: EventEmitter;

  private readonly ajaxErrorHandlers = new Set<AjaxErrorCallback>();

  constructor({baseUrl}: Params) {
    this.baseUrl = baseUrl;

    this._teamId = '';

    this.emitter = new EventEmitter();
  }

  /*
   * Adds new service configured as a js object. See CirrusApi.ts for example.
   * Each endpoint can be configured with following internal properties:
   * __url__ - use provided url part for endpoint instead of generating it from schema
   * __noteam__ - indicates that this endpoint doesn't need team id added to its url (see #setCurrentTeamId)
   */
  addService(name: string, version: string | undefined, endpoints: Endpoints) {
    this[name] = new Service({
      name,
      version,
      endpoints,
      parent: this,
      onAjaxError: (error) => {
        this.handleAjaxError(error);
      },
    });
  }

  /*
   * Same as #addService, but caches all queries results. Additional configuration:
   * __nocache__ - don't cache queries to this endpoint
   */
  addCachedService(
    name: string,
    version: string | undefined,
    endpoints: Endpoints,
  ) {
    this[name] = new Service({
      name,
      version,
      endpoints,
      parent: this,
      cache: new Cache(1000),
      onAjaxError: (error) => {
        this.handleAjaxError(error);
      },
    });
  }

  _getUrlPart({protocol}: {protocol?: string} = {}) {
    if (!protocol) {
      return Url.join(this.baseUrl);
    }

    if (this.baseUrl.includes('://')) {
      const [, host] = this.baseUrl.split('://');
      return Url.join(`${protocol}://${host}`);
    }

    if (this.baseUrl === '/') {
      return Url.join(`${protocol}://${window.location.host}${this.baseUrl}`);
    }

    // TODO: Throw error?
    return Url.join(this.baseUrl);
  }

  getCurrentTeamId() {
    return this._teamId;
  }

  /**
   * Cirrus API designed to have dynamic team id part in its url. Set
   * current team id here to make all queries contain this part.
   * @param {string} teamId
   */
  setCurrentTeamId(teamId: string) {
    this._teamId = teamId;
  }

  onAjaxError(listener: AjaxErrorCallback) {
    this.ajaxErrorHandlers.add(listener);
  }

  private handleAjaxError(error: any) {
    this.ajaxErrorHandlers.forEach((ajaxErrorHandler) =>
      ajaxErrorHandler(error),
    );
  }
}
