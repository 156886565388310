/* eslint-disable complexity */
import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {ClassName} from 'app/types/common';
import {ParticipantAvatar} from 'app/components/pages/MeetingDetails/shared/ParticipantAvatar/ParticipantAvatar';
import {ParticipantStream} from 'app/components/pages/MeetingDetails/ParticipantCard/ParticipantStream/ParticipantStream';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {ParticipantInput} from 'app/components/pages/MeetingDetails/shared/ParticipantInput/ParticipantInput';
import {ParticipantMessage} from 'app/components/pages/MeetingDetails/shared/ParticipantMessage/ParticipantMessage';
import {DisconnectedLabel} from 'app/components/pages/MeetingDetails/shared/DisconnectedLabel/DisconnectedLabel';
import {SrtSettingsFormProvider} from 'app/components/pages/MeetingDetails/StreamSettings/SrtSettingsFormContext';
import {Meeting} from 'app/store/models/connect/Meeting/Meeting';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {NoAudioLabel} from 'app/components/pages/MeetingDetails/shared/IsolatedAudioLabel/NoAudioLabel';
import {SrtStream} from 'app/store/models/connect/SrtStream/SrtStream';
import {Drawer} from '@mui/material';
import {useToggle} from 'app/hooks/useToggle';
import {StreamSettings} from 'app/components/pages/MeetingDetails/StreamSettings/StreamSettings';
import {ParticipantActions} from 'app/components/pages/MeetingDetails/shared/ParticipantActions/ParticipantActions';
import {SrtApiService} from 'app/services/api/meetings/SrtApiService';
import {StreamStatus} from 'app/components/pages/MeetingDetails/StreamStatus/StreamStatus';

interface Props extends ClassName {
  meeting: Meeting;
  id: string;
  name: string;
  state: App.Connect.ParticipantState;
  type: App.Connect.MemberType;
  individualAudio: boolean;
  stream: SrtStream;
  share: boolean;
  video: boolean;
  audio: boolean;
  canStart: boolean;
}

type View = 'settings' | 'status';

const ParticipantCardComponent = ({
  className,
  id,
  meeting,
  stream,
  name,
  type,
  state,
  share,
  video,
  audio,
  individualAudio,
  canStart,
}: Props) => {
  const [aside, toggleAside] = useToggle(false);
  const [view, setView] = useState<View>(stream.active ? 'status' : 'settings');

  const isParticipant = type === 'participant';
  const isSpeaker = type === 'primary-speaker';
  const isShare = type === 'vbss';
  const isFeed = type === 'return-feed';

  const {stopped, starting, started, connected, disconnected, stopping, active, failed, mute} =
    stream;

  const audioUnavailable = meeting.isolatedAudio && !individualAudio;
  const showInputIcons = isParticipant || (isSpeaker && active);
  const isLeftWithStream = isParticipant && state === 'left-with-stream';
  const showNoAudio = !isFeed && audioUnavailable && stopped;
  const showMessage = (isFeed || isShare || isSpeaker) && stopped;
  const showState = showInputIcons || isLeftWithStream || showNoAudio || showMessage;
  const connecting = starting || stopping || started || failed || disconnected;
  const audioTip = audioUnavailable ? '' : '';

  useEffect(() => {
    if (stream.active) {
      setView('status');
    } else {
      setView('settings');
    }
  }, [stream.active]);

  const handleMute = async (mute: App.Connect.StreamMute) => {
    if (id) {
      return SrtApiService.mute(meeting.id, id, mute);
    }

    return SrtApiService.muteFeed(meeting.id, mute);
  };

  const handleStop = async () => {
    if (id) {
      await SrtApiService.stop(meeting.id, id);
    } else {
      await SrtApiService.stopFeed(meeting.id);
    }

    if (aside) {
      toggleAside();
    }
  };

  return (
    <div
      className={classNames(
        'participant-card',
        connecting && 'participant-card--connecting',
        connected && 'participant-card--connected',
        disconnected && 'participant-card--disconnected',
        className,
      )}
      data-id={id}
    >
      <ParticipantAvatar
        className="participant-card__avatar"
        dataId="participant_avatar"
        name={name}
        type={type}
        active={active}
        cameraOff={!video}
        sharing={share}
        disconnected={state === 'left-with-stream'}
      />

      <div className="participant-card__user">
        <div className="participant-card__name">
          <OverflowTooltip dataId="participant_name">{name}</OverflowTooltip>

          {isSpeaker && (
            <Tooltip content="The current speaker in the meeting">
              {Icons.info().size(SIZE.S).class('participant-card__speaker-tip').reactComponent()}
            </Tooltip>
          )}
        </div>

        {showState && (
          <div className="participant-card__state">
            {showMessage && (
              <ParticipantMessage
                className="participant-card__state-item"
                dataId="participant_message"
                type={type}
                active={share}
              />
            )}

            {showInputIcons && (
              <ParticipantInput
                className="participant-card__state-item"
                dataId="participant_input"
                video={video}
                audio={audio}
                audioTip={audioTip}
              />
            )}

            {isLeftWithStream && (
              <DisconnectedLabel
                className="participant-card__state-item"
                dataId="participant_disconnected_label"
              />
            )}

            {showNoAudio && (
              <NoAudioLabel
                className="participant-card__state-item"
                name={name}
                dataId="participant_no_audio_label"
              />
            )}
          </div>
        )}
      </div>

      {active && <ParticipantStream className="participant-card__stream" stream={stream} />}

      <ParticipantActions
        className="participant-card__actions"
        name={name}
        view={stream?.active ? 'stop' : 'start'}
        audio={mute?.audio ?? false}
        video={mute?.video ?? false}
        disabledStart={!canStart}
        disabledMute={!stream?.running}
        disabledStop={!stream?.active}
        onStart={toggleAside}
        onStatus={toggleAside}
        onStop={handleStop}
        onMute={handleMute}
      />

      <Drawer anchor="right" open={aside} onClose={toggleAside} data-id="srt_side_panel">
        {view === 'settings' ? (
          <SrtSettingsFormProvider>
            <StreamSettings
              participantId={id}
              name={name}
              share={share}
              state={state}
              meeting={meeting}
              video={video}
              audio={audio}
              type={type}
              stream={stream}
              onClose={toggleAside}
            />
          </SrtSettingsFormProvider>
        ) : (
          <StreamStatus
            participantId={id}
            name={name}
            share={share}
            state={state}
            meeting={meeting}
            video={video}
            audio={audio}
            type={type}
            stream={stream}
            onMute={handleMute}
            onStop={handleStop}
          />
        )}
      </Drawer>
    </div>
  );
};

export const ParticipantCard = observer(ParticipantCardComponent);
