import React from 'react';
import {useInView} from 'react-intersection-observer';
import {Box, Link, Paper, Stack} from '@mui/material';
import {DataId, Sx} from 'app/types/common';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {packSx} from 'app/util/packSx/packSx';
import {Thumbnail} from 'app/components/sharedReactComponents/Preview/Thumbnail/Thumbnail';
import {DeviceAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/DeviceAudioMeter';
import {PreviewUpdateStrategy} from 'app/hooks/Device/Preview/types';
import {router} from 'app/router/main';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {ActionButtons} from 'app/components/sharedReactComponents/ActionButtons/ActionButtons';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {EdgeCheckbox} from 'app/components/features/edge/EdgeDevice/EdgeCheckbox/EdgeCheckbox';
import {bodyStyles} from 'app/components/features/edge/EdgeDevice/body/body.styles';

interface Props extends Sx, DataId {
  channel: PearlSlotDeviceModel;
  premium: boolean;
  billingAccess: boolean;
  streamsAccess: boolean;
  previewType: PreviewUpdateStrategy;
  streamingDestinations: AnyStreamingDestinationModelType[];
  getDeviceById: (deviceId: string) => AnyDeviceModelType | undefined;
  checkSelection: (channelId: string) => boolean;
  toggleSelection: (channel: PearlSlotDeviceModel) => void;
}

export function DeviceChannel({
  dataId,
  sx,
  channel,
  premium,
  previewType,
  billingAccess,
  streamsAccess,
  streamingDestinations,
  checkSelection,
  toggleSelection,
  getDeviceById,
}: Props) {
  const {ref, inView} = useInView();

  const channelId = channel.getId();
  const selected = checkSelection(channelId);

  const url = router.url('deviceDetails', {deviceId: channelId});
  const online = channel.isOnline();

  return (
    <Stack data-id={dataId} sx={sx} direction="row" alignItems="center" gap={0.5}>
      <EdgeCheckbox
        data-id="device_card_child_checkbox"
        premium={premium}
        billingAccess={billingAccess}
        checked={selected}
        disableRipple={false}
        onClick={() => toggleSelection(channel)}
      />

      <Paper sx={packSx(bodyStyles.root, selected && bodyStyles.selected)} variant="outlined">
        <Stack direction="row" ref={ref} width={98} height={50} gap={0.5}>
          {inView && (
            <>
              <Thumbnail id={channelId} detailsUrl={url} updateStrategy={previewType} />

              <DeviceAudioMeter
                sx={{height: 50, width: 5}}
                id={channelId}
                disabled={false}
                segmentsCount={13}
                segmentsGap={2}
                vertical={true}
              />
            </>
          )}
        </Stack>

        <Box minWidth={0} mr="auto">
          <Link
            color="inherit"
            sx={{color: online ? 'text.primary' : 'text.disabled'}}
            href={url}
            underline="none"
          >
            <OverflowTooltip rowsNumber={2}>{channel.getName()}</OverflowTooltip>
          </Link>
        </Box>

        <ActionButtons
          device={channel}
          editable={streamsAccess}
          streamingDestinations={streamingDestinations}
          getDeviceById={getDeviceById}
          onChangeStreamingDestination={() => {}}
        />
      </Paper>
    </Stack>
  );
}
