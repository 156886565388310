import {createDeviceModel} from 'app/components/DeviceDetails/Models/Fabric';
import {Alerts, AlertsContract} from 'app/components/entities/alerts/model';

function mapAlert(c: AlertsContract.Alert): Alerts.Alert {
  return {
    id: c.alert_id,
    deviceId: c.device_id,
    deviceName: c.device_name,
    deviceModel: c.device_model,
    acknowledged: c.is_read,
    message: c.message,
    severity: c.severity,
    timestamp: c.timestamp,
    remedy: c.remedy,
  };
}

function mapGroup(c: AlertsContract.Group): Alerts.Group {
  return {
    type: c.id,
    name: c.name,
    description: c.description,
    alerts: c.alert_ids,
  };
}

function mapSettings(c: AlertsContract.Settings): Alerts.Settings {
  const {DeviceID, Device, Settings} = c;
  const device = createDeviceModel(Device);

  return {
    id: DeviceID,
    deviceName: device.getName() ?? '',
    deviceModel: device.getModelName(),
    deviceGroupId: device.getGroupId(),
    alerts: Settings,
  };
}

function toConfig(settings: Alerts.Settings): AlertsContract.Config {
  return {
    DeviceID: settings.id,
    Settings: settings.alerts,
  };
}

export const AlertsMapper = {mapAlert, mapGroup, mapSettings, toConfig};
