import React from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {FormControlLabel} from 'app/components/sharedReactComponents/FormControlLabel';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {FORM_CONTROL_TYPE} from 'app/components/sharedReactComponents/FormControl/constants';
import {Checkbox} from 'app/components/sharedReactComponents/Checkbox';
import {NumberInput} from 'app/components/sharedReactComponents/NumberInput';
import {FORM_CONTROL_AUTOCOMPLETE} from 'app/constants/formControlAutocomplete';
import {Callback, ClassName} from 'app/types/common';

const noSubmit = e => e.preventDefault();

const prepareInputValueByType = (value, type) => {
  switch (type) {
    case 'number':
      return value === null ? undefined : value;
    default:
      return value;
  }
};

const LocationForm: React.FC = ({children}) => (
  <form
    action=""
    onSubmit={noSubmit}
  >
    {children}
  </form>
);

const LocationRow: React.FC = ({children}) => (
  <div className="cr-upload-location-config-row">
    {children}
  </div>
);

interface LocationProps extends ClassName {
  label?: string;
  placeholder?: string;
  model: any;
  min?: number;
  // TODO: Types constants
  type?: 'password' | 'text' | 'number' | 'checkbox' | 'textarea';
}

const LocationInput: React.FC<LocationProps> = observer(({
  className,
  label,
  placeholder,
  type = 'text',
  min,
  model,
  ...props
}) => {
  const inputProps = {
    value: prepareInputValueByType(model.value, type),
    onChange: model.setFromEvent,
    placeholder: placeholder ? placeholder : (type === 'password' ? '●●●●●●' : undefined),
    className: 'cr-transfer-location-config-input__el',
    min,
    ...props
  };

  const component = getControlComponentByType(type, inputProps);

  if (label) {
    return (
      <FormControlLabel
        className={classNames('cr-transfer-location-config-input', className)}
        label={label}
        control={component}
      />
    );
  }

  return (
    <div className={className}>
      {component}
    </div>
  );
});

const getControlComponentByType = (type, props) => {
  switch (type) {
    case 'checkbox':
      return <Checkbox {...props}/>;
    case 'number':
      return <NumberInput min={0} {...props}/>;
    case 'password':
      return (
        <FormControl
          type={type}
          autoComplete={FORM_CONTROL_AUTOCOMPLETE.OFF}
          {...props}
          fullWidth
        />
      );
    case 'textarea':
    case 'text':
    default:
      return <FormControl type={type} {...props} fullWidth/>;
  }
};

interface TypeChangeProps {
  types: any[];
  value: string | boolean | number;
  onChange: Callback;
}

const TypeChangeInput: React.VFC<TypeChangeProps> = ({
  types,
  value,
  onChange,
}) => {
  return (
    <FormControl
      name="type-input"
      className="cr-transfer-location-config-input"
      type={FORM_CONTROL_TYPE.SELECT}
      label="Type"
      value={value}
      selectComponentProps={{
        options: types.map(([value, label]) => ({label, value})),
      }}
      onChange={onChange}
    />
  );
};

export {
  LocationForm,
  LocationRow,
  LocationInput,
  TypeChangeInput,
};
