import React, {useCallback, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {debounce} from 'throttle-debounce';
import {Callback, ClassName} from 'app/types/common';
import {KeyboardCode} from 'app/constants';

interface Props extends ClassName {
  value: string;
  onChange: Callback;
  onClear: Callback;
  disabled?: boolean;
  placeholder?: string;
}

const ListSearchFilter = ({
  className,
  value: valueProp,
  placeholder = 'Search',
  disabled,
  onChange,
  onClear,
}: Props) => {
  const [value, setValue] = useState(valueProp);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const onChangeDebounced = useMemo(() => debounce(350, onChange), [onChange]);

  const handleChange = useCallback(({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
    setValue(value);
    onChangeDebounced({target: {value}});
  }, [onChangeDebounced]);

  const handleInputKeyUp = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === KeyboardCode.Escape) {
      if ((e.target as HTMLInputElement).value !== '') {
        onClear();
        e.preventDefault();
      }
    }
  }, [onClear]);

  const handleInputFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return (
    <label
      className={classNames('list-search-filter', className, {
        'list-search-filter--focused': focused,
      })}
      data-id="search_filter"
    >
      <div className="list-search-filter__search">
        <div className="list-search-filter__icon">
          {Icons.search().reactComponent()}
        </div>

        <input
          className="list-search-filter__input"
          data-id="search_filter_input"
          type="text"
          value={value}
          placeholder={placeholder}
          autoCapitalize="none"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          disabled={disabled}
          onChange={handleChange}
          onKeyUp={handleInputKeyUp}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
      </div>
    </label>
  );
};

export {ListSearchFilter};
