import React from 'react';
import {Component, Sx} from 'app/types/common';
import {ProductCard} from 'app/components/Settings/BillingAndUsage/cards/ProductCard/ProductCard';
import {isNil} from 'app/util/isNil';
import {Box, Link, Stack, Typography} from '@mui/material';
import {palette} from 'app/themes/app';
import {THEME, VARIANT} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {formatAbbreviatedDateWithYear} from 'app/util/time';
import {formatPrice} from 'app/util/prices';

interface Props extends Sx, Component {
  plan?: App.Billing.Connect.Plan;
  pending: boolean;
  onManage: Fn;
}

export function ConnectCard({sx, dataId, plan, pending, onManage}: Props) {
  const disabled = isNil(plan);

  const renderName = () => {
    if (disabled) {
      return false;
    }

    return (
      <Typography fontSize={20} fontWeight={600} component="span" color={palette.blue} mb={0.5}>
        {plan.enterprise ? 'Enterprise' : plan.tier.name} plan active
      </Typography>
    );
  };

  const renderDescription = () => {
    if (disabled) {
      return (
        <Box>
          <Typography mb={0.5}>
            Produce broadcast-quality content with Microsoft Teams and Zoom.
          </Typography>
          <Link
            data-id="connect_info_button"
            href="https://www.epiphan.com/connect"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </Link>
        </Box>
      );
    }

    if (plan.enterprise) {
      return <Typography mb={0.5}>Custom reserved capacity plan active</Typography>;
    }

    const {expireAt} = plan;

    const resumable = plan.resumable && expireAt;

    return (
      <Box>
        <Typography mb={0.5} color={resumable ? palette.red : palette.black}>
          {resumable ? (
            <>Subscription will be stopped on {formatAbbreviatedDateWithYear(expireAt)}</>
          ) : (
            <>
              ${formatPrice(plan.tier.subscriptionFee, 0)} per {plan.tier.interval.dimension}
            </>
          )}
        </Typography>

        <Typography mb={0.5}>
          ${formatPrice(plan.tier.hourlyRate, 0)} per active meeting hour
        </Typography>
      </Box>
    );
  };

  const renderAction = () => {
    const blocked = disabled && pending;
    const tooltip = blocked ? 'Not available for pending invoice billing request' : '';

    return (
      <Tooltip content={tooltip}>
        <span>
          <Button
            dataId="action-btn"
            theme={THEME.INFO}
            variant={VARIANT.OUTLINE}
            disabled={blocked}
            onClick={onManage}
          >
            {disabled ? 'Subscribe' : 'Manage'}
          </Button>
        </span>
      </Tooltip>
    );
  };

  const renderFooter = () => {
    if (disabled) {
      return <Typography color={palette.darkerGray}>Plan not active</Typography>;
    }

    return false;
  };

  return (
    <ProductCard
      sx={sx}
      dataId={dataId}
      title="Epiphan Connect"
      body={
        <Stack flexGrow={1}>
          {renderName()}
          {renderDescription()}
        </Stack>
      }
      action={renderAction()}
      footer={renderFooter()}
      disabled={disabled}
    />
  );
}
