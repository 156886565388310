import React from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  text?: string;
}

export const UnexpectedError = ({className, text}: Props) => {
  return (
    <div className={classNames('unexpected-error', className)}>
      <div className="unexpected-error__title">An error happened.</div>
      <div>Try to pair your organization again</div>
      {text && <div className="unexpected-error__message">Details: {text}</div>}
    </div>
  );
};
