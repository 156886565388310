import React from 'react';
import {Card, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import WarningIcon from '@mui/icons-material/Warning';
import {Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {Edge} from 'app/domain/edge';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {format} from 'app/util/decimal';

interface Props extends Sx {
  afu: Edge.Afu;
}

export function AfuPanel({sx, afu}: Props) {
  const isWarning = afu.state === 'error';
  const isDisabled = afu.state === 'disabled';

  const size = afu.queue?.size ?? 0;
  const files = afu.queue?.files ?? 0;
  const message = isWarning ? afu.error?.message ?? '' : '';

  return (
    <Card sx={packSx({p: 2}, sx)} variant="outlined">
      <Typography variant="h6" color={grey[600]} mb={0.5}>
        AFU queue
      </Typography>

      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          data-id="afu_queue"
          fontSize={24}
          color={isWarning ? 'error.main' : 'text.main'}
        >
          {isDisabled
            ? 'Not enabled'
            : `${format(size, 0.1, 1)} in ${files} ${pluralizeN('file', files)}`}
        </Typography>

        {message && (
          <Tooltip content={message}>
            <WarningIcon color="error" />
          </Tooltip>
        )}
      </Stack>
    </Card>
  );
}
