import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {PairDeviceButton} from 'app/components/libs/buttons/PairDeviceButton';
import {THEME, VARIANT} from 'app/constants';
import {Sx} from 'app/types/common';

type Props = Sx & {};

export function NoPairedDevices({sx}: Props) {
  return (
    <Stack sx={sx} direction="row" alignItems="center" p={5} bgcolor={grey[100]} borderRadius={1}>
      <div>
        <Typography fontSize={16} mb={2}>
          You have no paired devices
        </Typography>

        <PairDeviceButton theme={THEME.PRIMARY} variant={VARIANT.SOLID} />
      </div>

      <Box
        component="img"
        ml="auto"
        src="/assets/img/no-paired-devices-panel/devices.png"
        srcSet="/assets/img/no-paired-devices-panel/devices@2x.png 2x"
        alt=""
      />
    </Stack>
  );
}
