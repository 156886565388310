import React, {Fragment, useCallback, useState} from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {
  LocationRow,
  LocationInput,
} from 'app/components/UploadLocations/LocationInput';

interface Props {
  model: any;
}

function PrivateKeyComponent({
  model,
}: Props) {
  const [showPrivateKey, setShowPrivateKey] = useState(false);

  const handlePrivateKeyClick = useCallback(() => {
    setShowPrivateKey(showPrivateKey => !showPrivateKey);
  }, []);

  return (
    <Fragment>
      <LocationRow>
        <button
          className={classNames('cr-btn-default', 'cr-transfer-location-config-private-key-toggler', {
            'cr-btn-primary': Boolean(model.value),
          })}
          type="button"
          onClick={handlePrivateKeyClick}
        >
          {model.name}
        </button>
      </LocationRow>

      {showPrivateKey && (
        <LocationRow>
          <LocationInput
            className="cr-transfer-location-config-input--private-key cr-transfer-location-config-input--100-percent-width"
            type="textarea"
            model={model}
          />
        </LocationRow>
      )}
    </Fragment>
  );
}

const PrivateKey = observer(PrivateKeyComponent);

export {PrivateKey};
