import {AuthResponseDto} from 'app/components/Authorization/types';

function processAuthResponse(response: AuthResponseDto) {
  if (response.current_team_id) {
    window.location.assign('/' + response.current_team_id);
  } else {
    window.location.assign('/');
  }
}

export {
  processAuthResponse,
};
