import React from 'react';
import {CircularProgress, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {useDeviceSchedule} from 'app/components/DeviceDetails/hooks/useDeviceSchedule';
import {DeviceSchedule} from 'app/components/sharedReactComponents/Events/DeviceSchedule/DeviceSchedule';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {Schedule} from 'app/domain/schedule';

type Props = Sx & {
  teamId: string;
  userId: string;
  device: PearlMasterDeviceModel;
  actualEvent?: Schedule.Event;
};

export function ScheduleTab({sx, teamId, userId, device, actualEvent}: Props) {
  const {events, devices, period, cms, ready, fetching, setPeriod} = useDeviceSchedule({
    enabled: true,
    teamId,
    device,
  });

  return (
    <Stack sx={sx} flex={1}>
      {ready ? (
        <DeviceSchedule
          sx={{flex: 1}}
          device={device}
          devices={devices}
          events={events}
          cms={cms}
          teamId={teamId}
          userId={userId}
          period={period}
          fetching={fetching}
          actualEvent={actualEvent}
          setPeriod={setPeriod}
        />
      ) : (
        <Stack flex={1}>
          <Typography variant="h5" fontWeight="bold">
            Events
          </Typography>

          <Stack
            flex={1}
            mt={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <CircularProgress />
            <Typography variant="h6">Loading device schedule</Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
