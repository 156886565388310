import React from 'react';
import {Sx} from 'app/types/common';
import {Box, Stack, Typography} from '@mui/material';
import {Schedule} from 'app/domain/schedule';
import {formatEventFullTime} from 'app/components/sharedReactComponents/Events/utils';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {EventDetails} from 'app/components/sharedReactComponents/Events/Calendar/EventsSchedule/DayEvents/EventDetails/EventDetails';
import {Virtuoso} from 'react-virtuoso';

interface Props extends Sx {
  events: Schedule.Event[];
  devices?: Map<string, PearlMasterDeviceModel>;
}

export function GroupDetails({sx, events, devices}: Props) {
  const renderTime = () => {
    const first = events.at(0);
    const last = events.at(-1);

    if (!first || !last) {
      return false;
    }

    return <Typography>{formatEventFullTime(first.start, last.end)}</Typography>;
  };

  const listHeight = events.length * 102;

  return (
    <Box sx={sx} minWidth={420} maxWidth={580} maxHeight={600} pt={1}>
      <Stack px={1} direction="row" justifyContent="space-between" mb={1} flexShrink={0}>
        <Typography fontWeight={600} fontSize={16}>
          {events.length} events
        </Typography>

        {renderTime()}
      </Stack>

      <Box pl={1} pb={1} height={listHeight > 310 ? 310 : listHeight}>
        <Virtuoso
          data={events}
          itemContent={(_, e) => (
            <Box mb={1}>
              <EventDetails key={e.id} event={e} devices={devices} />
            </Box>
          )}
        />
      </Box>
    </Box>
  );
}
