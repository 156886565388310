import {Setting, SettingGroup} from 'app/components/sharedReactComponents/SettingsList/types';

const defaultGetSettingName = (setting: Setting) => setting.id;
const defaultGetSettingLabel = (setting: Setting) => setting.title;
const defaultGetSettingValue = (setting: Setting) => setting.value;
const defaultGetSettingDescription = (setting: Setting) => setting.description;
const defaultGetSettingTitle = () => undefined;
const defaultGetSettingType = (setting: Setting) => setting.type.name;
const defaultGetSettingTypeOptions = (setting: Setting) => setting.type.options ? setting.type.options : {};
const defaultGetSettingGroup = (setting: SettingGroup) => setting.items;

export {
  defaultGetSettingName,
  defaultGetSettingLabel,
  defaultGetSettingValue,
  defaultGetSettingDescription,
  defaultGetSettingTitle,
  defaultGetSettingType,
  defaultGetSettingTypeOptions,
  defaultGetSettingGroup,
};
