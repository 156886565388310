/**
 * ```typescript
 * intersectionOfSets(new Set([1, 2]), new Set([2, 3])); //=> new Set([2])
 * ```
 */
export function intersectionOfSets(set1: Set<any>, set2: Set<any>): Set<any> {
  const intersection = new Set();

  for (const item of set1) {
    if (set2.has(item)) {
      intersection.add(item);
    }
  }

  return intersection;
}
