import React, {PropsWithChildren} from 'react';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteValue,
  Box,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import {Callback, Component} from 'app/types/common';
import {text} from 'app/themes/app';
import {OnlineIndicator} from 'app/components/sharedReactComponents/OnlineIndicator';
import {SIZE} from 'app/constants';
import {isNil} from 'app/util/isNil';
import {DevicePicture} from 'app/components/sharedReactComponents/DevicePicture/DevicePicture';

interface Props extends Component {
  value: App.Connect.CloudEntity | null;
  error?: string;
  entities: App.Connect.CloudEntity[];
  onChange: SrtEntityChangeCallback;
}

export type SrtEntityChangeCallback = Callback<
  void,
  [
    event: React.SyntheticEvent,
    value: AutocompleteValue<App.Connect.CloudEntity, false, false, false>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<App.Connect.CloudEntity>,
  ]
>;

function GroupHeader({children}: PropsWithChildren<{}>) {
  return <Box sx={{px: 2, py: 1, color: text.group}}>{children}</Box>;
}

function formatGroupHeader(value: string) {
  if (value === 'device') {
    return 'Devices';
  }

  return 'Unify projects';
}

function GroupItem({children}: PropsWithChildren<{}>) {
  return <div>{children}</div>;
}

export const SrtEntitySelector = ({dataId, value, error, entities, onChange}: Props) => {
  const renderAdornment = () => {
    if (value) {
      return (
        <InputAdornment position="start">
          <EntityIcon model={value.model} />
        </InputAdornment>
      );
    }

    return null;
  };

  return (
    <Autocomplete
      data-id={dataId}
      value={value}
      fullWidth={true}
      isOptionEqualToValue={(o, v) => o.id === v?.id}
      groupBy={(o) => o.type}
      renderInput={(params) => (
        <TextField
          data-id="selector_input"
          helperText={error ?? 'Please make sure your projects and devices are online'}
          error={!isNil(error)}
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: renderAdornment(),
          }}
          placeholder="Select device or project"
        />
      )}
      renderGroup={(params) => (
        <div key={params.key}>
          <GroupHeader>{formatGroupHeader(params.group)}</GroupHeader>
          <GroupItem>{params.children}</GroupItem>
        </div>
      )}
      renderOption={(props, option) => (
        <MenuItem {...props}>
          <EntityIcon model={option.model} /> {option.name}
        </MenuItem>
      )}
      getOptionLabel={(o) => o.name}
      options={entities}
      onChange={onChange}
    />
  );
};

interface EntityIconProps {
  model: App.Connect.SrtEntityModel;
}

function EntityIcon({model}: EntityIconProps) {
  const renderIcon = () => {
    if (model === 'Epiphan Unify Project') {
      return <OnlineIndicator online={true} size={SIZE.S} />;
    }

    return <DevicePicture sx={{width: 34}} model={model as App.Cloud.DeviceModel} />;
  };

  return (
    <Stack sx={{height: '16px', mr: 0.5}} alignItems="center" direction="row">
      {renderIcon()}
    </Stack>
  );
}
