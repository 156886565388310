import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Slide} from '@mui/material';
import {Virtuoso} from 'react-virtuoso';
import {StyleSx, Sx} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {UserRole} from 'app/models/PermissionsModel/types';
import {TeamCapabilities} from 'app/models/Capabilities/TeamCapabilities';
import {DeviceByGroupMap} from 'app/components/FleetManager/DeviceGroups/DeviceByGroupMap';
import {filterSwitchGroups, useFilterSwitches} from 'app/components/FleetManager/useFilterSwitches';
import {useCollapseFilterSwitches} from 'app/components/FleetManager/useCollapseFilterSwitches';
import {valueComparator} from 'app/util/comparators/valueComparator';
import {useSelectedDevicesAndGroups} from 'app/components/FleetManager/hooks/useSelectedDevicesAndGroups';
import {useDeviceIds} from 'app/components/FleetManager/hooks/useDeviceIds';
import {NoPairedDevices} from 'app/components/FleetManager/NoPairedDevicesPanel/NoPairedDevicesPanel';
import {
  collapsibleFilterSwitchGroups,
  searchableFilterSwitchGroups,
  sortOptions,
  sortOptionsMap,
} from 'app/components/FleetManager/helpers';
import {useDeviceSelectable} from 'app/components/FleetManager/useDeviceSelectable';
import {useSelectable} from 'app/components/FleetManager/useSelectable';
import {
  collectDevicesAndChannelsIds,
  collectGroupsDevices,
  containsSearchString,
  filterByIdOrSerialNumberOrName,
  filterDeviceGroups,
  filterDevices,
} from 'app/components/FleetManager/utils';
import {PORTAL_ELEMENT_ID} from 'app/constants/portalElementId';
import {
  DeviceGroupsList,
  NavGroupPortal,
} from 'app/components/FleetManager/DeviceGroupsList/DeviceGroupsList';
import {EmptyFilterMessage} from 'app/components/sharedReactComponents/EmptyFilterMessage/EmptyFilterMessage';
import {isOngoing} from 'app/domain/schedule/utils';
import {Edge} from 'app/domain/edge';
import {Notifications} from 'app/components/Notifications';
import {PresetApiService} from 'app/services/api/preset/PresetApiService';
import {DeviceGroupApiService} from 'app/services/api/deviceGroup/DeviceGroupApiService';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {CreateGroupDialog} from 'app/components/dialogs/CreateGroupDialog/CreateGroupDialog';
import {useActualEvents} from 'app/components/sharedReactComponents/Events/hooks/useActualEvents';
import {useTeamPresets} from 'app/hooks/Device/Preset/usePresets';
import {useTeamDestinations} from 'app/components/FleetManager/useStreamingDestinations';
import {useGetDeviceById} from 'app/hooks/Device/useGetDeviceById';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {EdgeDevice} from 'app/components/features/edge/EdgeDevice/EdgeDevice';
import {EdgeGroup} from 'app/components/features/edge/EdgeGroup/EdgeGroup';
import {SelectionToolbar} from 'app/components/FleetManager/SelectionToolbar/SelectionToolbar';
import {EdgeHeader} from 'app/components/features/edge/EdgeHeader/EdgeHeader';
import {toggleInSet} from 'app/util/toggleInSet';
import {BatchDevices} from 'app/components/features/edge/BatchDevices/BatchDevices';
import {isError, isErrorResponse} from 'app/api/types';
import {Ws} from 'app/contracts/ws';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {EdgeAd} from 'app/components/widgets/edge';
import {capitalize} from 'app/components/helpers/commonHelpers';
import {isApiError} from 'app/components/shared';

const COLLAPSED_STORE_KEY = 'FleetManagerPage.Filters.Collapsed';
type SortBy = keyof typeof sortOptionsMap;

const defaultPresets: Edge.TeamPreset[] = [];
const defaultDestinations: AnyStreamingDestinationModelType[] = [];

const navGroupsSx: StyleSx = {color: 'white', mb: 1};
const selectorsSx: StyleSx = {mb: 1};
const batchSx: StyleSx = {position: 'fixed', height: 64, bottom: 0, left: 0, right: 0, zIndex: 1};

interface Props extends Sx {
  teamId: string;
  capabilities: TeamCapabilities;
  userId: string;
  role: UserRole;
  devices: AnyDeviceModelType[];
  groups: Edge.Group[];
  search: string;
  onClearSearch: () => void;
}

export function FleetManager({
  sx,
  teamId,
  userId,
  role,
  capabilities,
  devices,
  groups,
  search,
  onClearSearch,
}: Props) {
  const [filteredDevices, setFilteredDevices] = useState<AnyDeviceModelType[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<Edge.Group[]>([]);
  const [sortField, setSortField] = useState<SortBy>(sortOptionsMap.status.value);
  const [addGroupDialog, setAddGroupDialog] = useState(false);

  const groupsAccess = capabilities.groups();
  const presetsAccess = capabilities.presets();
  const scheduleAccess = capabilities.cms();
  const batchAccess = capabilities.batch();
  const filesAccess = capabilities.files();

  const permitBillingEdit = role.canEditBilling();

  const {detailed, toggleDetailed} = useDetailedView(`Edge.Indicators.${teamId}.${userId}`, false);
  const {getOpenState, toggleOpen} = useToggleState(devices, groups);

  const {groupMap, filteredGroupMap} = useGroupMaps({devices, filteredDevices, groupsAccess});

  const actualEvents = useActualEvents({enabled: scheduleAccess, teamId});

  const {data: presets = defaultPresets} = useTeamPresets({enabled: presetsAccess});

  const {data: destinations = defaultDestinations} = useTeamDestinations();

  const getDeviceById = useGetDeviceById(devices);

  const {
    checkIsDeviceSelected: checkDeviceSelection,
    selectDevice,
    deselectDevice,
    resetDeviceSelection,
    toggleDeviceChannelsSelection,
    resetDeviceAndChannelsSelection,
  } = useDeviceSelectable();

  const {
    checkIsItemSelected: checkGroupSelection,
    select: selectDeviceGroup,
    deselect: deselectDeviceGroup,
    resetSelection: resetDeviceGroupSelection,
  } = useSelectable();

  const toggleDeviceSelection = (device: AnyDeviceModelType) =>
    toggleDeviceChannelsSelection(device);

  const toggleGroupSelection = (groupId: string) => {
    const devices = groupMap.getDeviceGroupById(groupId);
    const deviceIds = collectDevicesAndChannelsIds(devices);

    if (checkGroupSelection(groupId)) {
      deselectDeviceGroup(groupId);
      deselectDevice(deviceIds);
    } else {
      selectDeviceGroup(groupId);
      selectDevice(deviceIds);
    }
  };

  const resetGroupAndItsDevicesSelection = (groupIds: string[]) => {
    const devices = collectGroupsDevices(groupIds, groupMap);

    resetDeviceGroupSelection(groupIds);
    resetDeviceAndChannelsSelection(devices);
  };

  const {filterSwitches, activeFilterSwitches, toggleFilterSwitcher, clearActiveFilterSwitches} =
    useFilterSwitches({
      teamId,
      userId,
      teamCapabilities: capabilities,
      devices,
      deviceGroups: groups,
    });

  const {collapsedFilterSwitchGroups, toggleFilterSwitchGroupCollapse} = useCollapseFilterSwitches({
    teamId,
    userId,
    storeKey: COLLAPSED_STORE_KEY,
  });

  const handleApplyPreset = async (deviceId: string, p: Edge.TeamPreset) => {
    if (!presetsAccess) {
      return;
    }

    try {
      await PresetApiService.applyTeamPreset(deviceId, p);
    } catch (e) {
      const msg =
        isErrorResponse(e) && isError(e.data) ? capitalize(e.data.Error) : 'Failed to apply preset';
      Notifications.addErrorNotification(msg);
      throw e;
    }
  };

  const handleDeleteDevice = async (deviceId: string) => {
    try {
      await DeviceApiService.deleteDevice(deviceId);
    } catch (e) {
      const msg =
        isErrorResponse(e) && isError(e.data)
          ? capitalize(e.data.Error)
          : 'Failed to delete preset';
      Notifications.addErrorNotification(msg);
      throw e;
    }
  };

  const handleCreateGroup = async (name: string) => {
    if (!groupsAccess) {
      return;
    }

    try {
      await DeviceGroupApiService.createGroup(teamId, name);
      setAddGroupDialog(false);
    } catch (e) {
      const msg = isApiError(e) ? capitalize(e.data.Error) : 'Failed to create group';
      Notifications.addErrorNotification(msg);
      throw e;
    }
  };

  const handleDeleteGroup = async (groupId: string) => {
    try {
      await DeviceGroupApiService.deleteGroup(teamId, groupId);
    } catch (e) {
      Notifications.addErrorNotification('Failed to delete group');
      throw e;
    }
  };

  const handleRenameGroup = async (groupId: string, name: string) => {
    try {
      await DeviceGroupApiService.renameGroup(teamId, groupId, name);
    } catch (e) {
      const msg = isErrorResponse(e) && isError(e.data) ? e.data.Error : 'Failed to rename group';
      Notifications.addErrorNotification(msg);
      throw e;
    }
  };

  const handleMoveToGroup = async (deviceId: string, groupId: string) => {
    if (!groupsAccess) {
      return;
    }

    try {
      await DeviceGroupApiService.moveToGroup(teamId, groupId, deviceId);
    } catch (e) {
      Notifications.addErrorNotification('Failed to move device to group');
      throw e;
    }
  };

  const handleMoveFromGroup = async (deviceId: string, groupId: string) => {
    if (!groupsAccess) {
      return;
    }

    try {
      await DeviceGroupApiService.removeFromGroup(teamId, groupId, deviceId);
    } catch (e) {
      Notifications.addErrorNotification('Failed to remove device from group');
      throw e;
    }
  };

  const activeFilters = useMemo(() => {
    return filterSwitches.filter((filterSwitch) => activeFilterSwitches.has(filterSwitch.id));
  }, [filterSwitches, activeFilterSwitches]);

  useEffect(() => {
    let devicesCopy = [...devices];
    let deviceGroupsCopy = [...groups];

    // Searching
    if (search !== '') {
      devicesCopy = devicesCopy.filter((device) => filterByIdOrSerialNumberOrName(device, search));
      const groupIdsMap = new Map<string | null, boolean>();

      devicesCopy.forEach((device) => groupIdsMap.set(device.getGroupId(), true));

      deviceGroupsCopy = deviceGroupsCopy.filter(
        (group) => containsSearchString(group.name, search) || groupIdsMap.has(group.id),
      );
    }

    // Filtering
    if (activeFilters.length > 0) {
      devicesCopy = filterDevices(devicesCopy, activeFilters);
      deviceGroupsCopy = filterDeviceGroups(deviceGroupsCopy, activeFilters);
    }

    // Sorting
    // TODO: Refactor and optimize sorting
    devicesCopy = devicesCopy.sort(
      (a, b) =>
        valueComparator(sortOptionsMap.name.callback(a), sortOptionsMap.name.callback(b)) ||
        valueComparator(a.getId(), b.getId()),
    );

    const activeSortBy = sortOptionsMap[sortField];

    if (activeSortBy.value !== sortOptionsMap.name.value) {
      devicesCopy = devicesCopy.sort((a, b) =>
        valueComparator(sortOptionsMap.status.callback(a), sortOptionsMap.status.callback(b)),
      );
    }

    if (activeSortBy.value !== sortOptionsMap.status.value) {
      devicesCopy = devicesCopy.sort((a, b) =>
        valueComparator(activeSortBy.callback(a), activeSortBy.callback(b)),
      );
    }

    setFilteredDevices(devicesCopy);
    setFilteredGroups(deviceGroupsCopy);
  }, [devices, groups, search, sortField, activeFilters]);

  useEffect(() => {
    resetDeviceSelection();
    resetDeviceGroupSelection();
  }, [search, resetDeviceSelection, resetDeviceGroupSelection]);

  const handleSortField = (field: string) => setSortField(field as SortBy);

  const handleFilterSwitcher = (filterSwitchId: string) => {
    resetDeviceSelection();
    resetDeviceGroupSelection();
    toggleFilterSwitcher(filterSwitchId);
  };

  const handleClearFilters = () => {
    resetDeviceSelection();
    resetDeviceGroupSelection();
    clearActiveFilterSwitches();
  };

  const handleClearAll = () => {
    onClearSearch();
    handleClearFilters();
  };

  const selectDevicesAction = (fn: (device: AnyDeviceModelType) => boolean) => {
    resetDeviceGroupSelection();
    resetDeviceAndChannelsSelection(filteredDevices.filter((device) => fn(device)));
  };

  const handleSelectGroups = () => resetGroupAndItsDevicesSelection(groups.map(({id}) => id));

  const handleSelectWithEvents = () =>
    selectDevicesAction((device) => {
      const event = actualEvents?.get(device.getId());

      return event ? isOngoing(event.status) : false;
    });

  const allSelected = useMemo(
    () =>
      checkAllSelected({
        hasGroups: groupsAccess,
        devices: filteredDevices,
        deviceGroups: filteredGroups,
        checkIsDeviceSelected: checkDeviceSelection,
        checkIsDeviceGroupSelected: checkGroupSelection,
      }),
    [groupsAccess, filteredDevices, filteredGroups, checkDeviceSelection, checkGroupSelection],
  );

  const selectAll = useCallback(() => {
    resetDeviceAndChannelsSelection(devices);
    resetDeviceGroupSelection(groups.map(({id}) => id));
  }, [devices, groups, resetDeviceAndChannelsSelection, resetDeviceGroupSelection]);

  const deselectAll = useCallback(() => {
    resetDeviceSelection();
    resetDeviceGroupSelection();
  }, [resetDeviceSelection, resetDeviceGroupSelection]);

  const handleSelectAll = useCallback(() => {
    if (!batchAccess) {
      return;
    }

    if (allSelected) {
      deselectAll();
      return;
    }

    selectAll();
  }, [batchAccess, allSelected, deselectAll, selectAll]);

  const {selectedDevices, selectedGroups} = useSelectedDevicesAndGroups({
    devices: filteredDevices,
    groups: filteredGroups,
    checkDevice: checkDeviceSelection,
    checkGroup: checkGroupSelection,
  });

  const deviceIds = useDeviceIds(filteredDevices);

  const getActualEvent = (deviceId: string) => actualEvents?.get(deviceId);

  const getEventsCount = useCallback(
    (deviceIds: string[]) => {
      return deviceIds.reduce((acc, deviceId) => {
        const event = actualEvents?.get(deviceId);

        return event && isOngoing(event.status) ? acc + 1 : acc;
      }, 0);
    },
    [actualEvents],
  );

  const eventsCount = useMemo(
    () => (scheduleAccess ? getEventsCount(deviceIds) : 0),
    [scheduleAccess, deviceIds, getEventsCount],
  );

  const hasActiveFilters = activeFilters.length > 0;
  const hasGroupFilters = hasActiveFilters
    ? activeFilters.some((filter) => filter.callbackForGroup)
    : false;

  const hasFilters = hasGroupFilters || hasActiveFilters || search.length > 0;

  const hasDevices = devices.length > 0;

  const hasFilteredDevices = filteredDevices.length > 0;
  const hasFilteredGroups = groupsAccess && filteredGroups.length > 0;

  const hasFiltered = hasFilteredDevices || hasFilteredGroups;

  const showBatch = selectedDevices.length > 0 || selectedGroups.length > 0;

  const ungrouped = filteredGroupMap.getUngroupedDevices();

  const renderBody = () => {
    if (!hasFiltered) {
      if (hasFilters) {
        return (
          <EmptyFilterMessage
            sx={{mt: 2}}
            message="Filter applied, but no results found."
            handleClear={handleClearAll}
          />
        );
      }

      return <NoPairedDevices sx={{mt: 2}} />;
    }

    const items = [...filteredGroups, ...ungrouped];

    return (
      <Virtuoso
        data={items}
        useWindowScroll={true}
        itemContent={(index, item) => {
          if (isDevice(item)) {
            const deviceId = item.getId();
            const event = getActualEvent(deviceId);

            return (
              <Box pb={2}>
                <EdgeDevice
                  dataId={deviceId}
                  key={deviceId}
                  device={item}
                  groups={groups}
                  event={event}
                  presets={presets}
                  role={role}
                  streamingDestinations={destinations}
                  detailed={detailed}
                  open={getOpenState(deviceId)}
                  getDeviceById={getDeviceById}
                  checkSelection={checkDeviceSelection}
                  toggleSelection={toggleDeviceSelection}
                  onApplyPreset={handleApplyPreset}
                  onDelete={handleDeleteDevice}
                  onMoveToGroup={handleMoveToGroup}
                  onMoveFromGroup={handleMoveFromGroup}
                  toggleOpen={toggleOpen}
                />
              </Box>
            );
          }

          const totalIngroup = groupMap.getDeviceGroupById(item.id).length;
          const filteredDevices = filteredGroupMap.getDeviceGroupById(item.id);

          return (
            <Box pb={2}>
              <EdgeGroup
                dataId={item.id}
                key={item.id}
                group={item}
                devices={filteredDevices}
                totalInGroup={totalIngroup}
                allGroups={groups}
                presets={presets}
                indicators={detailed}
                streamingDestinations={destinations}
                role={role}
                countEvents={scheduleAccess}
                onRenameGroup={handleRenameGroup}
                onDeleteGroup={handleDeleteGroup}
                getDeviceById={getDeviceById}
                checkGroupSelection={checkGroupSelection}
                toggleGroupSelection={toggleGroupSelection}
                checkDeviceSelection={checkDeviceSelection}
                toggleDeviceSelection={toggleDeviceSelection}
                getActualEvent={getActualEvent}
                onApplyPreset={handleApplyPreset}
                onDeleteDevice={handleDeleteDevice}
                onMoveToGroup={handleMoveToGroup}
                onMoveFromGroup={handleMoveFromGroup}
                countOngoingEvents={getEventsCount}
                getOpenState={getOpenState}
                toggleOpen={toggleOpen}
              />
            </Box>
          );
        }}
      />
    );
  };

  return (
    <Box sx={sx}>
      {groupsAccess && (
        <>
          <NavGroupPortal portalId={PORTAL_ELEMENT_ID.NAVBAR_DEVICE_GROUP_FILTER}>
            <DeviceGroupsList
              sx={navGroupsSx}
              filterSwitches={filterSwitches}
              activeFilterSwitches={activeFilterSwitches}
              onFilter={handleFilterSwitcher}
              onCreateGroup={() => setAddGroupDialog(true)}
            />
          </NavGroupPortal>

          <CreateGroupDialog
            open={addGroupDialog}
            onCreate={handleCreateGroup}
            onClose={() => setAddGroupDialog(false)}
          />
        </>
      )}

      {!filesAccess && (
        <EdgeAd userId={userId} permitBilling={permitBillingEdit} sx={{mb: 2}} />
      )}

      <EdgeHeader
        hasDevices={hasDevices}
        groupsAccess={groupsAccess}
        sortField={sortField}
        sortOptions={sortOptions}
        filters={filterSwitches}
        activeFilters={activeFilterSwitches}
        groupFilters={filterSwitchGroups}
        collapsibleGroupFilters={collapsibleFilterSwitchGroups}
        collapsedGroupFilters={collapsedFilterSwitchGroups}
        searchableGroupFilters={searchableFilterSwitchGroups}
        detailed={detailed}
        onToggleFilter={handleFilterSwitcher}
        onClearFilters={handleClearFilters}
        onToggleCollapse={toggleFilterSwitchGroupCollapse}
        onChangeSort={handleSortField}
        onToggleDetailed={toggleDetailed}
        onAddGroup={() => setAddGroupDialog(true)}
      />

      {hasFiltered && (
        <SelectionToolbar
          sx={selectorsSx}
          devices={filteredDevices}
          billingAccess={permitBillingEdit}
          premium={batchAccess}
          allSelected={allSelected}
          eventsCount={eventsCount}
          groupsCount={filteredGroups.length}
          onSelectDevice={selectDevicesAction}
          onSelectGroups={handleSelectGroups}
          onSelectWithEvents={handleSelectWithEvents}
          onSelectAll={handleSelectAll}
        />
      )}

      {renderBody()}

      {batchAccess && (
        <Slide in={showBatch} direction="up" unmountOnExit={true}>
          <BatchDevices
            sx={batchSx}
            devices={selectedDevices}
            groups={selectedGroups}
            allGroups={groups}
            presets={presets}
            permitReboot={role.canRebootDevices()}
            permitPreset={role.canApplyPresets()}
            onClose={deselectAll}
          />
        </Slide>
      )}
    </Box>
  );
}

type SelectionParams = {
  hasGroups: boolean;
  devices: AnyDeviceModelType[];
  deviceGroups: Edge.Group[];
  checkIsDeviceSelected: (id: string) => boolean;
  checkIsDeviceGroupSelected: (id: string) => boolean;
};

function checkAllSelected({
  hasGroups,
  devices,
  deviceGroups,
  checkIsDeviceSelected,
  checkIsDeviceGroupSelected,
}: SelectionParams): boolean {
  const hasNotSelected = devices.some((device) => !checkIsDeviceSelected(device.getId()));

  if (hasGroups) {
    return (
      !hasNotSelected &&
      !deviceGroups.some((deviceGroup) => !checkIsDeviceGroupSelected(deviceGroup.id))
    );
  }

  return !hasNotSelected;
}

type Args = {
  devices: AnyDeviceModelType[];
  filteredDevices: AnyDeviceModelType[];
  groupsAccess: boolean;
};

function useGroupMaps({groupsAccess, devices, filteredDevices}: Args) {
  return useMemo(
    () => ({
      groupMap: new DeviceByGroupMap(devices, groupsAccess),
      filteredGroupMap: new DeviceByGroupMap(filteredDevices, groupsAccess),
    }),
    [groupsAccess, devices, filteredDevices],
  );
}

export function useDetailedView(key: string, defaultValue: boolean) {
  const [detailed, setDetailed] = useState(() => {
    const saved = localStorage.getItem(key);
    const parsed = saved ? JSON.parse(saved) : undefined;
    return Boolean(parsed) || defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(detailed));
  }, [key, detailed]);

  const toggleDetailed = useCallback(() => {
    setDetailed((prev) => !prev);
  }, []);

  return {detailed, toggleDetailed};
}

function useToggleState(devices: AnyDeviceModelType[], groups: Edge.Group[]) {
  const [registry, setRegistry] = useState(() => {
    const devicesIds = devices.length < 100 ? devices.map((device) => device.getId()) : [];
    const groupsIds = groups.map((group) => group.id);

    return new Set<string>([...devicesIds, ...groupsIds]);
  });

  useEffect(() => {
    const onMessage = (message: Ws.AddDevice | Ws.GroupChange) => {
      if (message.Kind === 'DevicePatchPair') {
        setRegistry((prev) => toggleInSet(prev, message.Body.DeviceID));
      }

      if (message.Kind === 'DeviceGroupChange') {
        if (message.Body.Action === 'created') {
          const groupId = message.Body.GroupID;
          setRegistry((prev) => toggleInSet(prev, groupId));
        }
      }
    };

    WS.on(EVENT_TYPE.ADD_DEVICE, onMessage);
    WS.on(EVENT_TYPE.DEVICE_GROUP_CHANGE, onMessage);

    return () => {
      WS.off(EVENT_TYPE.ADD_DEVICE, onMessage);
      WS.off(EVENT_TYPE.DEVICE_GROUP_CHANGE, onMessage);
    };
  }, []);

  const getOpenState = useCallback(
    (id: string) => {
      return registry.has(id);
    },
    [registry],
  );

  const toggleOpen = useCallback((id: string) => {
    setRegistry((prev) => toggleInSet(prev, id));
  }, []);

  return {getOpenState, toggleOpen};
}

function isDevice(c: Edge.Group | AnyDeviceModelType): c is AnyDeviceModelType {
  return (c as AnyDeviceModelType).getId !== undefined;
}
