import {useMemo} from 'react';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {Edge} from 'app/domain/edge';
import {collectSelectedDeviceGroups} from 'app/components/FleetManager/utils';

type Params = {
  devices: AnyDeviceModelType[];
  groups: Edge.Group[];
  checkGroup: (id: string) => boolean;
  checkDevice: (id: string) => boolean;
};

export function useSelectedDevicesAndGroups({devices, groups, checkDevice, checkGroup}: Params) {
  const selectedDevices = useMemo(() => {
    const collection: AnyDeviceModelType[] = [];

    devices.forEach((device) => {
      const result = checkDevice(device.getId());

      if (result) {
        collection.push(device);
      }

      if (ModelService.isMultiChannel(device.getModelName())) {
        const channels = (device as PearlMasterDeviceModel).getChanelModels();

        channels.forEach((channel) => {
          if (checkDevice(channel.getId())) {
            collection.push(channel);
          }
        });
      }
    });

    return collection;
  }, [devices, checkDevice]);

  const selectedGroups = useMemo(
    () => collectSelectedDeviceGroups(groups, checkGroup),
    [groups, checkGroup],
  );

  return {selectedDevices, selectedGroups};
}
