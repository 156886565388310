type A = Record<string, string>;

export const parseLocationSearch = (search: string): A => {
  if (!search) {
    return {};
  }

  return search.substr(1).split('&').reduce((acc: A, current: string) => {
    const [key, value] = current.split('=');

    // @ts-expect-error
    acc[key] = value;

    return acc;
  }, {});
};
