import {DeviceModel as DeviceModelName} from 'app/components/DeviceDetails/constants';
import {DeviceModel} from 'app/components/DeviceDetails/Models/DeviceModel';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {
  PearlSlotDeviceModel,
  PearlSlotDeviceModelAdditionalProps,
} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {LiveScryptDeviceModel} from 'app/components/DeviceDetails/Models/LiveScryptDeviceModel';
import {Device} from 'app/components/DeviceDetails/Models/types';
import {getDeviceModel} from 'app/store/models/device/utils';
import {isMultiChannelDevice} from 'app/components/DeviceDetails/Models/utils';

export type AnyDeviceModelType =
  | PearlSlotDeviceModel
  | PearlMasterDeviceModel
  | LiveScryptDeviceModel
  | DeviceModel;

function createDeviceModel(
  json: Device,
  additionalProps?: PearlSlotDeviceModelAdditionalProps,
): AnyDeviceModelType {
  const model = getDeviceModel(json);

  if (isMultiChannelDevice(json, model)) {
    return new PearlMasterDeviceModel(json);
  }

  switch (model) {
    case DeviceModelName.Channel:
      return new PearlSlotDeviceModel(
        json,
        additionalProps ?? {
          masterDeviceName: '',
          masterCapabilities: [],
          masterModelName: DeviceModelName.Pearl2,
        },
      );
    case DeviceModelName.Pearl2:
      return new PearlMasterDeviceModel(json);
    case DeviceModelName.LiveScrypt:
      return new LiveScryptDeviceModel(json);
    default:
      return new DeviceModel(json);
  }
}

export {createDeviceModel};
