import React from 'react';
import {alpha, Divider, Stack, SxProps, Typography} from '@mui/material';
import {Component, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {backgrounds, palette} from 'app/themes/app';

interface Props extends Sx, Component {
  disabled?: boolean;
  title: string;
  body: React.ReactNode;
  action?: React.ReactNode;
  footer?: React.ReactNode;
}

const rootSx: SxProps = {
  pl: 4,
  pr: 4.5,
  pt: 3,
  pb: 2,
  width: 480,
  height: 290,
  bgcolor: alpha(backgrounds.lightGray, 0.5),
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: palette.grayMedium,
  borderRadius: 1.5,
};

export function ProductCard({sx, title, action, disabled, dataId, body, footer}: Props) {
  return (
    <Stack
      sx={packSx(
        rootSx,
        disabled
          ? {
              borderStyle: 'dashed',
              bgcolor: palette.white,
            }
          : {},
        sx,
      )}
      gap={2}
      data-id={dataId}
      divider={<Divider flexItem />}
    >
      <Stack sx={{flexGrow: 1}} component="section" gap={3}>
        <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
          <Typography
            variant="h5"
            fontWeight={600}
            color={disabled ? palette.gray7 : palette.black}
          >
            {title}
          </Typography>
          {action}
        </Stack>

        {body}
      </Stack>
      <Stack sx={{height: 58}} component="section" justifyContent="center">
        {footer}
      </Stack>
    </Stack>
  );
}
