import React, {useState} from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import {palette} from 'app/themes/app';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {THEME, VARIANT} from 'app/constants';
import {Callback} from 'app/types/common';
import {Notifications} from 'app/components/Notifications';

type AudioMode = 'mixed' | 'isolated';

interface Props {
  onConnect: Callback<Promise<void>, [mode: AudioMode]>;
}

export function ConnectToHosted({onConnect}: Props) {
  const [mode, setMode] = useState<AudioMode>('isolated');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMode(e.target.value as AudioMode);
  };

  const handleConnect = async () => {
    try {
      await onConnect(mode);
    } catch {
      Notifications.addErrorNotification(`Could not connect to an hosted meeting`);
    }
  };

  return (
    <Box sx={{p: 7}}>
      <Box
        mb={2}
        component="img"
        src="/assets/img/logo/teams-logo.png"
        width={173}
        height={28}
        srcSet="/assets/img/logo/teams-logo@2x.png 2x"
        alt=""
      />

      <Typography variant="h5" fontWeight={600}>
        Start Epiphan Connect
      </Typography>

      <Box sx={{color: palette.darkerGray, mt: 1}}>
        Start extracting your meeting participants’ video and audio signals by starting Epiphan
        Connect.
      </Box>

      <FormControl sx={{mt: 2}}>
        <Box sx={{mb: 1, fontWeight: 'bold'}}>Epiphan Connect audio mode:</Box>

        <RadioGroup
          sx={{display: 'flex', gap: 3, flexDirection: 'row', flexWrap: 'nowrap'}}
          value={mode}
          onChange={handleChange}
        >
          <Box sx={{flex: 1}}>
            <FormControlLabel
              sx={{mb: 0}}
              value="isolated"
              control={<Radio data-id="isolated-mode-radio" />}
              label="Isolated"
            />
            <FormHelperText sx={{ml: '28px'}}>
              Isolate participant audio into separate tracks. Use this to control extracted audio.
            </FormHelperText>
          </Box>

          <Box sx={{flex: 1}}>
            <FormControlLabel
              sx={{mb: 0}}
              value="mixed"
              control={<Radio data-id="mixed-mode-radio" />}
              label="Mixed"
            />
            <FormHelperText sx={{ml: '28px'}}>
              Each participant's audio track will contain the mixed audio from the Microsoft Teams
              meeting.
            </FormHelperText>
          </Box>
        </RadioGroup>
      </FormControl>

      <Box sx={{color: palette.darkerGray, my: 4}}>
        Connecting to a meeting can take several minutes. Give yourself sufficient time to get
        connected before the meeting start time.
      </Box>
      <Box sx={{'& button': {width: '154px'}}}>
        <ProgressButton
          dataId="hosted_connect_button"
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          onClick={handleConnect}
        >
          Connect
        </ProgressButton>
      </Box>
    </Box>
  );
}
