import React from 'react';
import {
  BoxIcon,
  DropboxIcon,
  GoogleDriveIcon,
  FtpSftpIcon,
} from 'app/components/UploadLocations/UploadLocationIcons';
import {STORAGE_TYPE} from 'app/components/UploadLocations/constants';

interface Props {
  type?: STORAGE_TYPE;
}

function StorageIcon({
  type,
}: Props) {
  switch (type) {
    case STORAGE_TYPE.FTP:
    case STORAGE_TYPE.SFTP:
      return <FtpSftpIcon/>;
    case STORAGE_TYPE.BOX:
      return <BoxIcon/>;
    case STORAGE_TYPE.DROPBOX:
      return <DropboxIcon/>;
    case STORAGE_TYPE.GOOGLE_DRIVE:
      return <GoogleDriveIcon/>;
    default:
      return null;
  }
}

export {StorageIcon};
