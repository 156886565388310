import React from 'react';
import {useLocalStorage} from 'react-use';
import {Box, Button, CircularProgress, Stack, TextField, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {Sx} from 'app/types/common';
import {ScheduleView} from 'app/components/sharedReactComponents/Events/types';
import {FilterSelector} from 'app/components/sharedReactComponents/FilterSelector';
import {TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';
import {TeamEvents} from 'app/components/sharedReactComponents/Events/List/TeamEvents/TeamEvents';
import {EventEditor} from 'app/components/sharedReactComponents/Events/Editor/EventEditor/EventEditor';
import {RefreshButton} from 'app/components/sharedReactComponents/Events/shared/RefreshButton/RefreshButton';
import {NoTeamIntegration} from 'app/components/sharedReactComponents/Events/shared/messages/NoTeamIntegration/NoTeamIntegration';
import {isNil} from 'app/util/isNil';
import {useEventEditor} from 'app/components/sharedReactComponents/Events/hooks/useEventEditor';
import {ScheduleApiService} from 'app/services/api/schedule/ScheduleApiService';
import {Notifications} from 'app/components/Notifications';
import {UpdateMode} from 'app/components/sharedReactComponents/Events/Editor/EventForm/types';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {Schedule} from 'app/domain/schedule';
import {Calendar} from 'app/components/sharedReactComponents/Events/Calendar/Calendar';
import {ViewSwitcher} from 'app/components/sharedReactComponents/Events/shared/ViewSwitcher/ViewSwitcher';
import {useScheduleCategories} from 'app/components/sharedReactComponents/Events/hooks/useScheduleCategories';
import {CategoryCount} from 'app/components/sharedReactComponents/Events/Calendar/shared/CategoryCount/CategoryCount';
import {
  useScheduleFilters,
  filterGroups,
} from 'app/components/sharedReactComponents/Events/hooks/useScheduleFilters';
import {ActiveFilters} from 'app/components/sharedReactComponents/ActiveFilters/ActiveFilters';
import {PeriodSwitcher} from 'app/components/sharedReactComponents/Events/shared/PeriodSwitcher/PeriodSwitcher';
import {NoEvents} from 'app/components/sharedReactComponents/Events/shared/messages/NoEvents/NoEvents';
import {FilterMessage} from 'app/components/sharedReactComponents/FilterMessage/FilterMessage';

interface Props extends Sx {
  teamId: string;
  userId: string;
  connection: Schedule.TeamConnection;
  period: Schedule.Period;
  events: Schedule.Event[];
  devices?: Map<string, PearlMasterDeviceModel>;
  fetching: boolean;
  actualEventIds?: Set<string>;
  setPeriod: React.Dispatch<React.SetStateAction<Schedule.Period>>;
}

export function TeamSchedule({
  sx,
  teamId,
  userId,
  events,
  devices,
  connection,
  period,
  fetching,
  actualEventIds,
  setPeriod,
}: Props) {
  const [view = 'list', setView] = useLocalStorage<ScheduleView>(
    `DeviceSchedule.${teamId}.${userId}`,
    'list',
  );

  const {eventToEdit, editEvent, createEvent, closeEditor} = useEventEditor();

  const eventCount = events.length;

  const {hasEdge, hasExternal} = connection;

  const hasConnections = connection.total > 0;
  const hasEvents = hasConnections && eventCount > 0;

  const {
    filtered,
    activeSet,
    activeFilters,
    filters,
    search,
    clearFilters,
    setSearch,
    toggleFilter,
  } = useScheduleFilters({
    teamId,
    userId,
    events,
    devices,
    storeKey: `${teamId}`,
  });

  const {ongoing, scheduled, completed} = useScheduleCategories(filtered);

  const renderContent = () => {
    if (!hasConnections) {
      return <NoTeamIntegration />;
    }

    const noFiltered = activeSet.size > 0 || search.length > 0 ? filtered.length === 0 : false;

    if (noFiltered) {
      const handleClear = () => {
        setSearch('');
        clearFilters();
      };

      return (
        <FilterMessage
          sx={{flex: 1}}
          label="Reset"
          message="No filtered events"
          onClick={handleClear}
        />
      );
    }

    if (!eventCount) {
      return <NoEvents period={period} />;
    }

    if (view === 'calendar') {
      return <Calendar events={filtered} devices={devices} period={period} />;
    }

    return (
      <TeamEvents
        devices={devices}
        events={filtered}
        actualEventIds={actualEventIds}
        onEdit={editEvent}
        onStart={startEvent}
        onStop={stopEvent}
        onPause={pauseEvent}
        onResume={resumeEvent}
        onExtend={extendEvent}
        onDelete={deleteEvent}
        onConfirm={confirmEvent}
      />
    );
  };

  const isEditorOpen = !isNil(eventToEdit);

  return (
    <Stack className="container" sx={sx}>
      <Box mb={1}>
        <Stack direction="row" alignItems="center">
          <Typography fontWeight={600} variant="h5">
            Events
          </Typography>

          {hasConnections && (
            <Stack ml="auto" gap={1} direction="row" alignItems="center">
              <TextField
                sx={{width: 240}}
                value={search}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  placeholder: 'Search',
                }}
                inputProps={{'data-id': 'search-input'}}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(event.target.value);
                }}
              />

              {hasEdge && (
                <>
                  <EventEditor
                    teamId={teamId}
                    allowSelectDevices={true}
                    open={isEditorOpen}
                    eventId={eventToEdit}
                    onClose={closeEditor}
                  />

                  <Button
                    data-id="add-event-button"
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    disableRipple={false}
                    onClick={createEvent}
                  >
                    Add event
                  </Button>
                </>
              )}

              <FilterSelector
                filterSwitches={filters}
                filterSwitchGroups={filterGroups}
                activeFilterSwitches={activeSet}
                placement={TOOLTIP_PLACEMENT.BOTTOM_END}
                onClickFilterSwitcher={toggleFilter}
              />

              <ViewSwitcher view={view} onChange={setView} />
            </Stack>
          )}
        </Stack>

        {hasConnections && (
          <Stack mt={1} direction="row" alignItems="center" gap={1}>
            <PeriodSwitcher period={period} disabled={fetching} setPeriod={setPeriod} />

            {hasEvents && (
              <CategoryCount
                dataId="period-stats"
                ongoing={ongoing.length}
                scheduled={scheduled.length}
                completed={completed.length}
              />
            )}

            {hasExternal && <RefreshButton onClick={refreshTeamSchedule} />}

            {fetching && <CircularProgress sx={{ml: 'auto'}} size={14} variant="indeterminate" />}
          </Stack>
        )}

        {hasConnections && (
          <ActiveFilters
            sx={{mt: 0.5}}
            filters={activeFilters}
            onRemove={toggleFilter}
            onRemoveAll={clearFilters}
          />
        )}
      </Box>

      {renderContent()}
    </Stack>
  );
}

async function startEvent(eventId: string) {
  try {
    await ScheduleApiService.startEvent(eventId);
  } catch (e: unknown) {
    Notifications.addErrorNotification('Failed to start an event');
    throw e;
  }
}
async function stopEvent(eventId: string) {
  try {
    await ScheduleApiService.stopEvent(eventId);
  } catch (e: unknown) {
    Notifications.addErrorNotification('Failed to stop an event');
    throw e;
  }
}

async function pauseEvent(eventId: string) {
  try {
    await ScheduleApiService.pauseEvent(eventId);
  } catch (e: unknown) {
    Notifications.addErrorNotification('Failed to pause an event');
    throw e;
  }
}

async function resumeEvent(eventId: string) {
  try {
    await ScheduleApiService.resumeEvent(eventId);
  } catch (e: unknown) {
    Notifications.addErrorNotification('Failed to resume an event');
    throw e;
  }
}

async function extendEvent(eventId: string) {
  try {
    await ScheduleApiService.extendEvent(eventId);
  } catch (e: unknown) {
    Notifications.addErrorNotification('Failed to extend an event');
    throw e;
  }
}

async function deleteEvent(eventId: string, mode: UpdateMode) {
  try {
    await ScheduleApiService.deleteEvent(eventId, mode);
  } catch (e: unknown) {
    Notifications.addErrorNotification('Failed to delete an event');
    throw e;
  }
}

async function refreshTeamSchedule() {
  try {
    await ScheduleApiService.refreshTeamSchedule();

    Notifications.addSuccessNotification('Refresh is initiated. Schedule will be updated soon');
  } catch {
    Notifications.addErrorNotification('Could not send refresh command');
  }
}

async function confirmEvent(eventId: string, deviceId: string) {
  try {
    await ScheduleApiService.confirmEvent(eventId, deviceId);
  } catch {
    Notifications.addErrorNotification('Failed to confirm an event');
  }
}
