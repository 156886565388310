import React from 'react';
import {Typography} from '@mui/material';
import {DataId, StyleSx, Sx} from 'app/types/common';
import {Edge} from 'app/domain/edge';
import {capitalize} from 'app/components/helpers/commonHelpers';
import {packSx} from 'app/util/packSx/packSx';

type Props = Sx &
  DataId & {
    status: Edge.DeviceStatus;
  };

export function EdgeDeviceStatus({dataId, sx, status}: Props) {
  return (
    <Typography data-id={dataId} sx={packSx(getStyles(status), sx)} textAlign="center">
      {getNormalizedStatus(status)}
    </Typography>
  );
}

function getStyles(status: Edge.DeviceStatus): StyleSx {
  switch (status) {
    case 'online':
      return {color: 'secondary.main', fontWeight: 600};

    case 'rebooting':
    case 'upgrading': {
      return {color: 'primary.main', fontWeight: 600};
    }

    default:
      return {color: 'text.disabled'};
  }
}

function getNormalizedStatus(status: Edge.DeviceStatus): string {
  if (status === 'holding_legacy') {
    return 'Incompatible'
  }

  return capitalize(status)
}
