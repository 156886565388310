import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';

export function DeviceAlertsTableHeader() {
  return (
    <Stack
      px={1}
      direction="row"
      alignItems="center"
      fontSize={12}
      fontWeight={600}
      color={grey[700]}
      gap={1}
    >
      <Typography fontSize="inherit" fontWeight="inherit" width={60}>
        Level
      </Typography>

      <Typography fontSize="inherit" fontWeight="inherit" width={78}>
        Time
      </Typography>

      <Typography fontSize="inherit" fontWeight="inherit" flex={1}>
        Device Name
      </Typography>

      <Typography fontSize="inherit" fontWeight="inherit" width={100}>
        Model
      </Typography>

      <Typography fontSize="inherit" fontWeight="inherit" flex={1}>
        Alert Message
      </Typography>

      <Box width={38} />
    </Stack>
  );
}
