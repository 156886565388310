import React from 'react';
import {observer} from 'mobx-react';
import {Component} from 'app/types/common';
import {MeetingStatus} from 'app/components/MeetingsManager/cards/MeetingCard/columns/MeetingStatusColumn/MeetingStatus';
import {MeetingParticipantsCount} from 'app/components/sharedReactComponents/MeetingParticipantsCount/MeetingParticipantsCount';
import {MeetingCountColumn} from 'app/components/MeetingsManager/cards/MeetingCard/columns/MeetingCountColumn/MeetingCountColumn';
import {Meeting} from 'app/store/models/connect/Meeting/Meeting';
import {EntityCard} from 'app/components/MeetingsManager/cards/EntityCard/EntityCard';
import {ContextMenuItem} from 'app/components/sharedReactComponents/ContextMenu';
import {THEME} from 'app/constants';
import {Icons} from 'app/util/icons';
import {DisconnectMeetingPrompt} from 'app/components/MeetingsManager/modals/DisconnectMeetingPrompt/DisconnectMeetingPrompt';
import {MeetingsApiService} from 'app/services/api/meetings/MeetingsApiService';
import {Notifications} from 'app/components/Notifications';
import {DeleteMeetingPrompt} from 'app/components/libs/dialogs/DeleteMeetingPrompt';
import {isNil} from 'app/util/isNil';

interface Props extends Component {
  meeting: Meeting;
  onClick: Fn<any, [meeting: Meeting]>;
}

const processingStates: App.Connect.MeetingStatus[] = [
  'starting',
  'error',
  'in-waiting-room',
  'waiting-for-host',
  'waiting-for-password',
  'waiting-for-account',
];

const disconnectable = new Set<App.Connect.MeetingStatus>([
  'running',
  'in-waiting-room',
  'waiting-for-host',
  'waiting-for-password',
]);

const MeetingCardComponent = ({meeting, dataId, onClick}: Props) => {
  const connected = meeting.status === 'running';
  const accessIssue = connected && !isNil(meeting.permission) && meeting.permission !== 'ok';
  const expanded = processingStates.includes(meeting.status) || accessIssue;

  const handleCardClick = () => onClick(meeting);

  const handleDisconnect = async () => {
    DisconnectMeetingPrompt.show({
      name: meeting.name,
      onDone: async () => {
        try {
          await MeetingsApiService.disconnect(meeting.id);
        } catch {
          Notifications.addErrorNotification('Cannot disconnect from meeting');
        }
      },
    });
  };

  const handleDelete = async () => {
    DeleteMeetingPrompt.show({
      name: meeting.name,
      onDone: async () => {
        try {
          await MeetingsApiService.deleteMeeting(meeting.id);
        } catch {
          Notifications.addErrorNotification('Cannot delete connect to meeting');
        }
      },
    });
  };

  const items: ContextMenuItem[] = [
    {
      label: 'Disconnect',
      icon: Icons.unlink().reactComponent(),
      danger: true,
      visible: disconnectable.has(meeting.status),
      action: handleDisconnect,
    },
    {
      label: 'Reconnect',
      icon: Icons.chain().theme(THEME.PRIMARY).reactComponent(),
      visible: meeting.status === 'disconnecting' || meeting.status === 'disconnected',
      action: async () => {
        try {
          await MeetingsApiService.connect(meeting.id);
        } catch {
          Notifications.addErrorNotification('Cannot reconnect to meeting');
        }
      },
    },
    {
      label: 'Delete',
      icon: Icons.trash().reactComponent(),
      danger: true,
      visible: meeting.status !== 'running',
      action: handleDelete,
    },
  ];

  const ready = connected && (meeting.platform === 'zoom' ? meeting.permission === 'ok' : true);
  const clickable =
    connected ||
    meeting.status === 'waiting-for-password' ||
    meeting.status === 'waiting-for-account';

  return (
    <EntityCard
      dataId={dataId}
      sx={{
        cursor: clickable ? 'pointer' : 'default',
      }}
      onClick={handleCardClick}
    >
      <EntityCard.Icon platform={meeting.platform} />

      <EntityCard.Name
        value={meeting.name}
        url={meeting.url}
        tenant={meeting.tenant}
        selfHosted={!isNil(meeting.host)}
        disabled={!connected}
      />

      <MeetingStatus
        sx={{gridColumn: expanded ? 'col 1 / col 4' : 'col 1 / col 2'}}
        status={meeting.status}
        permission={meeting.permission}
        createdAt={meeting.createdAt}
        startedAt={meeting.startedAt}
        disconnectedAt={meeting.disconnectedAt}
        error={meeting.error}
        deleteTimeout={meeting.settings?.timeouts.delete}
        platform={meeting.platform}
        zoomId={meeting.zoomId}
      />

      {ready && (
        <>
          <MeetingParticipantsCount count={meeting.guests.length} />
          <MeetingCountColumn entities={meeting.entities} />
        </>
      )}

      <EntityCard.Menu items={items} disabled={!isNil(meeting.deletedAt)} />
    </EntityCard>
  );
};

export const MeetingCard = observer(MeetingCardComponent);
