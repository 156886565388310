import {SxProps, Theme, alpha} from '@mui/material';
import {blue, grey, red} from '@mui/material/colors';
import {EventCategory} from 'app/components/sharedReactComponents/Events/types';

export const cardStyles: Record<EventCategory | 'root', SxProps<Theme>> = {
  root: {p: 1, border: 1, borderRadius: 1},
  ongoing: {
    borderLeftWidth: 4,
    borderStyle: 'solid',
    borderColor: 'error.main',
    bgcolor: alpha(red[50], 0.4),
  },
  scheduled: {
    borderLeftWidth: 4,
    borderStyle: 'solid',
    borderColor: 'primary.main',
    bgcolor: alpha(blue[50], 0.25),
  },
  completed: {
    borderLeftWidth: 4,
    borderStyle: 'solid',
    borderColor: grey[500],
    bgcolor: alpha(grey[50], 0.9),
  },
};
