import React from 'react';
import classNames from 'classnames';
import {StreamStatusRow} from 'app/components/pages/MeetingDetails/StreamStatus/StreamStatusDetails/types';
import {Icons} from 'app/util/icons';
import {THEME} from 'app/constants';
import {StreamStatusContainer} from 'app/components/pages/MeetingDetails/StreamStatus/StreamStatusDetails/StreamStatusContainer/StreamStatusContainer';

interface ItemProps {
  row: StreamStatusRow;
  running: boolean;
  joined: boolean;
  receiving: string | number;
  sending: string | number;
  noWarning?: boolean;
}

const Item = ({row, receiving, sending, running, joined, noWarning}: ItemProps) => {
  const sendingValue = row.cellRenderer ? row.cellRenderer({cellData: sending}) : sending;

  const getValue = (): React.ReactNode => {
    if (!running) {
      return Icons.spinner().theme(THEME.SUCCESS).reactComponent();
    }

    if (!joined) {
      return '--—';
    }

    if (row.cellRenderer) {
      return row.cellRenderer({cellData: receiving});
    }

    return receiving;
  };

  const receivingValue = getValue();

  const equalData = noWarning ? true : receiving === sending;
  const hasError = !equalData || !joined;

  return (
    <li
      className={classNames(
        'stream-status-table__item',
        hasError && 'stream-status-table__item--error',
      )}
      data-id={`srt_status_prop:${row.dataKey}`}
    >
      <div className="stream-status-table__item-label">{row.label}</div>
      <div className="stream-status-table__column" data-id="srt_status_receiving_value">
        {receivingValue}
      </div>
      <div className="stream-status-table__column" data-id="srt_status_sending_value">
        {sendingValue}
      </div>
    </li>
  );
};

interface Props {
  platform: App.Connect.Platform;
  title: string;
  rows: StreamStatusRow[];
  from: string;
  to: string;
  receiving: App.Connect.Quality;
  sending: App.Connect.Quality;
  running: boolean;
  joined: boolean;
}

export const StreamStatusTable = ({
  platform,
  title,
  rows,
  receiving,
  sending,
  joined,
  running,
  from,
  to,
}: Props) => {
  return (
    <StreamStatusContainer className="stream-status-table" title={title} warning={!joined}>
      <div className="stream-status-table__content">
        <div className="stream-status-table__columns-header">
          <div className="stream-status-table__column-label stream-status-table__column-label--empty">
            &nbsp;
          </div>

          <div className="stream-status-table__column-label">
            Receiving from
            <br />
            <span className="stream-status-table__label" data-id="srt_receiving">
              {from}
            </span>
          </div>

          <div className="stream-status-table__column-label">
            Sending to
            <br />
            <span className="stream-status-table__label" data-id="srt_sending">
              {to}
            </span>
          </div>
        </div>

        <ul className="stream-status-table__columns-body">
          {rows.map((row) => (
            <Item
              key={row.dataKey}
              row={row}
              running={running}
              joined={joined}
              noWarning={row.dataKey === 'bitrate' && platform === 'zoom'}
              sending={sending[row.dataKey]}
              receiving={receiving ? receiving[row.dataKey] : '—'}
            />
          ))}
        </ul>
      </div>
    </StreamStatusContainer>
  );
};
