import {useEffect, useState} from 'react';

/**
 * @param {string} name
 * @param {*} value
 * @param {function} updateAction
 * @return {[*, boolean, function]}
 */
function useSettingChange(name, value, updateAction) {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => setInputValue(value), [value]);

  function handleChange(e) {
    const newValue = e.target.value;

    setLoading(true);
    setInputValue(newValue);

    updateAction(name, newValue)
      .catch(() => setInputValue(value))
      .finally(() => setLoading(false));
  }

  return [inputValue, loading, handleChange];
}

export {
  useSettingChange,
};
