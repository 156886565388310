import React from 'react';
import {Meeting} from 'app/store/models/connect/Meeting/Meeting';
import {usePermissionIssue} from 'app/components/pages/MeetingDetails/hooks/usePermissionIssue';
import {LocalRecordIssue} from 'app/components/pages/MeetingDetails/MeetingLayout/LocalRecordIssue/LocalRecordIssue';
import {DeniedAccessIssue} from 'app/components/pages/MeetingDetails/MeetingLayout/DeniedAccessIssue/DeniedAccessIssue';
import {MediaActionIssue} from 'app/components/pages/MeetingDetails/MeetingLayout/MediaActionIssue/MediaActionIssue';
import {ParticipantsList} from 'app/components/pages/MeetingDetails/ParticipantsList/ParticipantsList';

interface Props {
  meeting: Meeting;
  userId: string;
  teamId: string;
}

export default function MeetingLayout({userId, teamId, meeting}: Props) {
  const issue = usePermissionIssue(meeting.permission);

  if (issue) {
    if (issue === 'wait-record') {
      return <LocalRecordIssue sx={{pl: 0.5, pr: 4, pt: 1.5, pb: 5}} id={meeting.zoomId} />;
    }

    if (issue.denied) {
      return (
        <DeniedAccessIssue
          sx={{pl: 0.5, pr: 4, pt: 1.5, pb: 5}}
          id={meeting.zoomId}
          action={issue.action}
        />
      );
    }

    return (
      <MediaActionIssue
        sx={{pl: 0.5, pr: 4, pt: 1.5, pb: 5}}
        action={issue.action}
        id={meeting.zoomId}
      />
    );
  }

  return <ParticipantsList meeting={meeting} userId={userId} teamId={teamId} />;
}
