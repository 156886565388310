/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/no-this-alias */
import axios from 'axios';
import {isEmpty, mergeRight, omit} from 'ramda';
import {Url} from 'app/api/util/Url';
import {queryStringSorted} from 'app/api/util/queryStringSorted';
import {MediaType} from 'app/api/constants';
import {Cache} from 'app/util/Cache';
import {Api} from 'app/api/Api';
import {AjaxErrorCallback, Endpoints, GetAbortCallback} from 'app/api/types';
import {isString} from 'app/util/typeGuards';

/**
 * HTTP request methods
 * {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods}
 */
enum RequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

type ChainParams = {
  parent: Api | Chain;
  endpoints: Endpoints;
  cache?: Cache;
  onAjaxError?: AjaxErrorCallback;
};

export class Chain {
  parent: Api | Chain | Endpoint;
  endpoints: Endpoints;
  cache?: Cache;
  protected readonly onAjaxError?: AjaxErrorCallback;

  constructor({parent, endpoints, cache, onAjaxError}: ChainParams) {
    this.parent = parent;
    this.endpoints = endpoints;
    this.cache = cache;
    this.onAjaxError = onAjaxError;

    this._generateEndpoints();
  }

  _generateEndpoints() {
    for (const [endpointName, endpointOptions] of Object.entries(this.endpoints)) {
      if (endpointName in this) {
        throw new Error(
          `Endpoint name "${endpointName}" conflicts with class method or property name`,
        );
      }

      this[endpointName] = (id, params) => {
        const childEndpoints = omit(['__url__'], endpointOptions);
        const customUrl = endpointOptions.__url__;
        const cache = endpointOptions.__nocache__ ? undefined : this.cache;
        const noTeam = endpointOptions.__noteam__ === true;
        const protocol = endpointOptions.__protocol__ as string;

        return new Endpoint({
          name: endpointName,
          id,
          params,
          endpoints: childEndpoints,
          parent: this,
          customUrl,
          cache,
          noTeam,
          protocol,
          onAjaxError: this.onAjaxError,
        });
      };
    }
  }

  traverseUp(fn) {
    let node: any = this;

    while (node) {
      fn(node);
      node = node.parent;
    }
  }
}

type RequestData = Record<string, any> | string;

type RequestOptions = {
  contentType?: string | false;
  headers?: Record<string, any>;
  getAbort?: GetAbortCallback;
};

interface EndpointParams {
  name: string;
  id: any;
  params: any;
  parent: any;
  endpoints: Endpoints;
  customUrl: any;
  cache?: Cache;
  noTeam?: boolean;
  protocol?: string;
  onAjaxError?: AjaxErrorCallback;
}

class Endpoint extends Chain {
  name: string;
  customUrl: string;
  redrawAfterRequest: boolean;
  protocol?: string;
  noTeam?: boolean;

  _id: any;
  params: any;

  constructor({
    name,
    id,
    params,
    parent,
    endpoints,
    customUrl,
    cache,
    noTeam,
    protocol,
    onAjaxError,
  }: EndpointParams) {
    super({
      parent,
      endpoints,
      cache,
      onAjaxError,
    });

    this.name = name;
    this.customUrl = customUrl;
    this.noTeam = noTeam;
    this.protocol = protocol;

    if (id instanceof Object) {
      params = id;
      id = undefined;
    }

    this._id = id;
    this.params = params;

    this.redrawAfterRequest = true;

    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
  }

  _getUrlPart(): string {
    return Url.join(this.customUrl || this.name, this._id);
  }

  url(): string {
    let url = '';
    let paramsAcc = {};

    this.traverseUp((node) => {
      url = Url.join(
        node._getUrlPart({
          noTeam: this.noTeam,
          protocol: this.protocol,
        }),
        url,
      );
      paramsAcc = mergeRight(paramsAcc, node.params || {});
    });

    if (!isEmpty(paramsAcc)) {
      const queryString = this.queryStringSorted(paramsAcc);
      if (queryString) {
        url += `?${queryString}`;
      }
    }

    return url;
  }

  private async fetchRequest(
    method: RequestMethod,
    data?: RequestData,
    options: RequestOptions = {},
  ): Promise<unknown> {
    const {contentType = MediaType.Json, headers = {}, getAbort} = options;

    let abortController;

    if (getAbort) {
      abortController = new AbortController();
      getAbort(() => {
        abortController.abort();

        if (method === RequestMethod.Get && this.cache) {
          // To prevent cache cancelled request
          this.cache.unset(`${this.url()}?${data as string}`);
        }
      });
    }

    let body;
    let url = this.url();
    if (method === RequestMethod.Get) {
      if (isString(data) && data !== '') {
        const joinSymbol = url.includes('?') ? '&' : '?';
        url = `${url}${joinSymbol}${data}`;
      }
    } else {
      body = isString(data) ? data : JSON.stringify(data);
    }

    return new Promise((resolve, reject) => {
      axios({
        method,
        url,
        data: body,
        headers: {
          ...headers,
          'Content-Type': contentType === false ? undefined : contentType,
        } as any,
        signal: getAbort ? abortController.signal : undefined,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return;
          }

          try {
            this.onAjaxError?.(error.response);
          } catch (err: unknown) {
            console.error('onAjaxError', err);
          }

          reject(error.response);
        });
    });
  }

  async get(data?: RequestData, options?: RequestOptions): Promise<any> {
    const queryString = typeof data === 'string' ? data : this.queryStringSorted(data);

    const getResponse = async () => this.fetchRequest(RequestMethod.Get, queryString, options);

    if (this.cache) {
      return this.cache.getOrElse(`${this.url()}?${queryString}`, getResponse);
    }

    return getResponse();
  }

  async post(data?: RequestData, options?: RequestOptions): Promise<any> {
    return this.fetchRequest(RequestMethod.Post, data, options);
  }

  async put(data?: RequestData, options?: RequestOptions): Promise<any> {
    return this.fetchRequest(RequestMethod.Put, data, options);
  }

  async delete(data?: RequestData, options?: RequestOptions): Promise<any> {
    return this.fetchRequest(RequestMethod.Delete, data, options);
  }

  async postForm(data: Record<string, any>): Promise<any> {
    return this.post(this.queryStringSorted(data), {
      contentType: MediaType.XWwwFormUrlencoded,
    });
  }

  queryStringSorted(data?: Record<string, any>): string {
    return queryStringSorted(data);
  }
}
