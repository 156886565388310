import React, {useEffect, useRef} from 'react';
import {Box} from '@mui/material';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Callback, Sx, SxObj} from 'app/types/common';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Icons} from 'app/util/icons';

interface Props extends Sx {
  url: string;
  onClick?: Callback;
}

const styles: Record<'title' | 'url' | 'copy' | 'join', SxObj> = {
  title: {textAlign: 'center'},
  url: {textAlign: 'center', fontSize: '10px', color: '#A4B0C0'},
  copy: {ml: 0.5, '& .cr-icon': {}},
  join: {ml: 0.5, '& .cr-icon': {rotate: '135deg'}},
};

export function CopyUrl({url, sx, onClick}: Props) {
  const copyRef = useRef<any>(null);
  const timeoutRef = useRef<number>(0);

  const renderContent = () => (
    <Box>
      <Box sx={styles.title}>Copy and share the URL with the meeting participants</Box>
      <Box sx={styles.url}>{url}</Box>
    </Box>
  );

  const handleCopy = async (e: React.SyntheticEvent) => {
    onClick?.(e);

    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(url);
    } else {
      document.execCommand('copy', false, url);
    }

    copyRef.current.show();
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      if (copyRef.current) {
        copyRef.current.hide();
      }
    }, 1500);
  };

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <Tooltip content={renderContent()}>
      <Box sx={sx}>
        <Tooltip ref={copyRef} content="Copied!" trigger="manual">
          <span>
            <Button
              variant={VARIANT.LINK_UNDERLINE}
              theme={THEME.SUCCESS}
              dataId="meeting_url"
              onClick={handleCopy}
            >
              Meeting URL
              <Box sx={styles.copy} component="span">
                {Icons.copy().size(SIZE.S).reactComponent()}
              </Box>
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Tooltip>
  );
}

export function JoinMeeting({sx, url, onClick}: Props) {
  const handleJoin = (e: React.SyntheticEvent) => {
    onClick?.(e);
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  return (
    <Tooltip content="Join as a participant">
      <Box sx={sx}>
        <Button
          variant={VARIANT.LINK_UNDERLINE}
          theme={THEME.SUCCESS}
          dataId="join_meeting"
          onClick={handleJoin}
        >
          Join meeting
          <Box sx={styles.join} component="span">
            {Icons.backLeft().size(SIZE.S).reactComponent()}
          </Box>
        </Button>
      </Box>
    </Tooltip>
  );
}
