import React from 'react';
import {Skeleton} from '@mui/material';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {isNil} from 'app/util/isNil';
import {Notifications} from 'app/components/Notifications';
import {
  FilesBillingApi,
  useFilesPlanQuery,
  useFilesPricesQuery,
} from 'app/components/entities/billing/files';
import {FilesCard} from 'app/components/features/billing/files';

const skeletonSx: StyleSx = {height: 290};

interface Props extends Sx {
  teamId: string;
  hasPayment: boolean;
  onEditPayment: () => void;
}

export function FilesSubscription({sx, teamId, hasPayment, onEditPayment}: Props) {
  const {data: prices, isSuccess: priceReady} = useFilesPricesQuery();
  const {data: plan, isSuccess: planReady, refetch: refetchPlan} = useFilesPlanQuery(teamId);

  const price = prices?.[0];

  if (!priceReady || !planReady || !price) {
    return <Skeleton sx={packSx(skeletonSx, sx)} variant="rectangular" height={290} />;
  }

  const handleSubscribe = async () => {
    try {
      await FilesBillingApi.subscribe(teamId, price.id);
      await refetchPlan();
    } catch (e) {
      Notifications.addErrorNotification('Failed to subscribe to files');
      throw e;
    }
  };

  const handleUnsubscribe = async () => {
    try {
      await FilesBillingApi.unsubscribe(teamId);
      await refetchPlan();
    } catch (e) {
      Notifications.addErrorNotification('Failed to subscribe to files');
      throw e;
    }
  };

  return (
    <FilesCard
      dataId="files-subscription"
      sx={sx}
      active={!isNil(plan)}
      hasPayment={hasPayment}
      price={price}
      onSubscribe={handleSubscribe}
      onUnsubscribe={handleUnsubscribe}
      onEditPayment={onEditPayment}
    />
  );
}
