import React from 'react';
import classNames from 'classnames';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {WarningBaseType} from 'app/types/common';

interface Props {
  messages: WarningBaseType[];
}

function Messages({messages}: Props) {
  return (
    <ul
      className={classNames(
        'tooltip-multiple-messages__messages',
        messages.length > 1 && 'tooltip-multiple-messages__messages--multiple',
      )}
    >
      {messages.map((message, index) => (
        <li key={`${message.message}-${index}`} className="tooltip-multiple-messages__message">
          {message.message}
        </li>
      ))}
    </ul>
  );
}

export function TooltipMultipleMessages({
  messages,
  children,
  ...props
}: Props & Record<string, any>) {
  let content: React.ReactChild = '';

  if (Array.isArray(messages) && messages.length > 0) {
    content = <Messages messages={messages} />;
  }

  return (
    <Tooltip content={content} {...props}>
      {children}
    </Tooltip>
  );
}
