import React, {useCallback, useEffect, useState} from 'react';
import {RadioGroupContext} from 'app/components/sharedReactComponents/RadioGroup/RadioGroupContext';
import {WAI_ARIA_ROLE} from 'app/constants/waiAriaRole';
import {LoadingWrapper} from 'app/components/sharedReactComponents/LoadingWrapper';
import {Callback, ClassName, DataId} from 'app/types/common';
import {isPromise} from 'app/util/isPromise';

// rework typing
interface Props extends ClassName, DataId {
  /**
   * The name used to reference the value of the control.
   * If you don't provide this prop, it falls back to a randomly generated name.
   */
  name?: string;
  /**
   * Value of the selected radio button. The DOM API casts this to a string.
   */
  value: any;
  uncheckable?: boolean;
  disabled?: boolean;
  loading?: boolean;
  // children: node;
  /**
   * Callback fired when a radio button is selected.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: Callback;
}

export type RadioGroupContextProps = Partial<{
  name: string;
  value: any;
  uncheckable: boolean;
  disabled: boolean;
  loading: boolean;
  onChange: Callback;
}>;

export const RadioGroup: React.FC<Props> = React.forwardRef<any, Props>(
  (
    {
      className,
      name,
      value,
      uncheckable,
      disabled,
      loading: loadingProp,
      children,
      dataId,
      onChange,
    },
    ref,
  ) => {
    const [loading, setLoading] = useState(loadingProp);

    const handleChange = useCallback(
      (event) => {
        const promise = onChange(event);

        if (isPromise(promise)) {
          setLoading(true);
          promise.finally(() => setLoading(false));
        }
      },
      [onChange],
    );

    useEffect(() => {
      setLoading(loadingProp);
    }, [loadingProp]);

    return (
      <RadioGroupContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          name,
          value,
          uncheckable,
          disabled,
          loading,
          onChange: handleChange,
        }}
      >
        <LoadingWrapper loading={loading}>
          <div ref={ref} className={className} role={WAI_ARIA_ROLE.RADIO_GROUP} data-id={dataId}>
            {children}
          </div>
        </LoadingWrapper>
      </RadioGroupContext.Provider>
    );
  },
);
