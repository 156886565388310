import {useCallback, useEffect, useRef, useState} from 'react';
import {FilterStoreManager} from 'app/components/FleetManager/FilterStoreManager';
import {HealthStatus, DEVICE_HEALTH_STATUS_NAME} from 'app/components/DeviceDetails/constants';
import {getDeviceModelNames} from 'app/components/FleetManager/utils';
import {stringComparator} from 'app/util/Sort';
import {toggleInSet} from 'app/util/toggleInSet';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {
  FilterSwitch,
  FilterSwitchGroup,
  FilterSwitchGroupType,
} from 'app/components/sharedReactComponents/FilterSelector/types';
import {TeamCapabilities} from 'app/models/Capabilities/TeamCapabilities';
import {Edge} from 'app/domain/edge';

const STORE_KEY = 'FleetManagerPage.Filters.Active';

enum FilterSwitchGroupId {
  View = 'view',
  Group = 'group',
  Status = 'status',
  Activity = 'activity',
  Model = 'model',
  Health = 'health',
  Schedule = 'schedule',
  CMS = 'cms',
}

const viewAllFilter: FilterSwitch = {
  id: 'view_all',
  group: FilterSwitchGroupId.View,
  label: 'All',
  callback: () => true,
};

const viewGroupsFilter: FilterSwitch = {
  id: 'view_groups',
  group: FilterSwitchGroupId.View,
  label: 'Groups only',
  callback: (device: AnyDeviceModelType) => device.getGroupId() !== '',
  callbackForGroup: () => true,
};

const viewUngroupedFilter: FilterSwitch = {
  id: 'view_ungrouped',
  group: FilterSwitchGroupId.View,
  label: 'Ungrouped devices only',
  callback: (device: AnyDeviceModelType) => device.getGroupId() === '',
  callbackForGroup: () => false,
};

const filterSwitchGroups: FilterSwitchGroup[] = [
  {
    id: FilterSwitchGroupId.Status,
    label: 'Device status',
  },
  {
    id: FilterSwitchGroupId.Activity,
    label: 'Device activity',
  },
  {
    id: FilterSwitchGroupId.Model,
    label: 'Device model',
  },
  {
    id: FilterSwitchGroupId.Health,
    label: 'Device health',
  },
  {
    id: FilterSwitchGroupId.Schedule,
    label: 'Scheduled events',
  },
  {
    id: FilterSwitchGroupId.CMS,
    label: 'CMS integration',
  },
  {
    id: FilterSwitchGroupId.View,
    label: 'View',
    type: FilterSwitchGroupType.Radio,
    defaultValue: viewAllFilter.id,
  },
  {
    id: FilterSwitchGroupId.Group,
    label: 'By Groups',
  },
];

function createDeviceStatusFilter(props: FilterSwitch): FilterSwitch {
  return {
    ...props,
    group: FilterSwitchGroupId.Status,
  };
}

function createDeviceActivityFilter(props: FilterSwitch): FilterSwitch {
  return {
    ...props,
    group: FilterSwitchGroupId.Activity,
  };
}

function createDeviceModelNameFilter(modelName: string): FilterSwitch {
  return {
    id: modelName,
    group: FilterSwitchGroupId.Model,
    label: modelName,
    callback: (device: AnyDeviceModelType) => device.getModelName() === modelName,
  };
}

function createDeviceGroupFilter(group: Edge.Group): FilterSwitch {
  const groupId = group.id;

  return {
    id: groupId,
    group: FilterSwitchGroupId.Group,
    label: group.name,
    callback: (device: AnyDeviceModelType) => device.getGroupId() === groupId,
    callbackForGroup: (group: FilterSwitchGroup) => group.id === groupId,
  };
}

function createDeviceHealthFilter(deviceHealthStatus: HealthStatus): FilterSwitch {
  return {
    id: deviceHealthStatus,
    group: FilterSwitchGroupId.Health,
    label: DEVICE_HEALTH_STATUS_NAME[deviceHealthStatus],
    callback: (device: AnyDeviceModelType) => device.getHealthStatus() === deviceHealthStatus,
  };
}

type FilterSwitchesParams = {
  devices: AnyDeviceModelType[];
  deviceGroups: Edge.Group[];
  teamId: string;
  teamCapabilities: TeamCapabilities;
  userId: string;
};

type FilterSwitchesReturn = {
  activeFilterSwitches: Set<string>;
  toggleFilterSwitcher: (filterSwitchId: string) => void;
  filterSwitches: FilterSwitch[];
  clearActiveFilterSwitches: () => void;
};

function useFilterSwitches({
  devices,
  deviceGroups,
  teamId,
  teamCapabilities,
  userId,
}: FilterSwitchesParams): FilterSwitchesReturn {
  const [activeFilterSwitches, setActiveFilterSwitches] = useState<Set<string>>(new Set());
  const [filterSwitches, setFilterSwitches] = useState<FilterSwitch[]>([]);
  const defaultValues = useRef<Set<string>>(new Set());

  const filterSwitchManager = useRef<FilterStoreManager>();

  // Load saved state
  useEffect(() => {
    filterSwitchManager.current = new FilterStoreManager({
      teamId,
      userId,
      storeKey: STORE_KEY,
    });
    const storedActiveFilterSwitches = filterSwitchManager.current.loadActiveFiltersSet();
    setActiveFilterSwitches(storedActiveFilterSwitches);
  }, [teamId, userId]);

  // Register filter switches
  useEffect(() => {
    const switches = [
      createDeviceStatusFilter({
        id: 'online',
        label: 'Online',
        callback: (device) => device.isOnline(),
      }),
      createDeviceStatusFilter({
        id: 'offline',
        label: 'Offline',
        callback: (device) => device.isOffline(),
      }),

      createDeviceActivityFilter({
        id: 'streaming',
        label: 'Streaming',
        callback: (device) => device.isStreaming(),
      }),
      createDeviceActivityFilter({
        id: 'recording',
        label: 'Recording',
        callback: (device) => device.isRecording(),
      }),
      createDeviceActivityFilter({
        id: 'transcribing',
        label: 'Transcribing',
        callback: (device) => device.isTranscribing(),
      }),
      createDeviceHealthFilter(HealthStatus.Good),
      createDeviceHealthFilter(HealthStatus.Fair),
      createDeviceHealthFilter(HealthStatus.Bad),
    ];

    const deviceModelNameFilters = getDeviceModelNames(devices)
      .filter((name) => name)
      .sort(stringComparator)
      .map((modelName) => createDeviceModelNameFilter(modelName));
    if (deviceModelNameFilters.length > 1) {
      switches.push(...deviceModelNameFilters);
    }

    if (deviceGroups.length > 0) {
      switches.push(...[viewAllFilter, viewGroupsFilter, viewUngroupedFilter]);
    }

    const deviceGroupFilters = deviceGroups.map((deviceGroup) =>
      createDeviceGroupFilter(deviceGroup),
    );

    if (deviceGroupFilters.length > 0) {
      switches.push(...deviceGroupFilters);
    }

    defaultValues.current = new Set();
    // eslint-disable-next-line no-restricted-syntax
    for (const {defaultValue} of filterSwitchGroups) {
      if (defaultValue) {
        defaultValues.current.add(defaultValue);
      }
    }

    setFilterSwitches(switches);
  }, [devices, deviceGroups, teamCapabilities]);

  useEffect(() => {
    filterSwitchManager.current?.saveActiveFiltersSet(activeFilterSwitches);
  }, [activeFilterSwitches]);

  const toggleFilterSwitcher = useCallback(
    (filterSwitchId: string) => {
      setActiveFilterSwitches((activeFilterSwitches) => {
        const nextActiveFilterSwitches = new Set(activeFilterSwitches);

        const filterSwitch = filterSwitches.find(({id}) => id === filterSwitchId);
        const groupId = filterSwitch?.group;

        if (groupId) {
          const group = filterSwitchGroups.find(({id}) => id === groupId);

          // Reset "View" to default value if click Group filter switch
          if (groupId === FilterSwitchGroupId.Group) {
            filterSwitches
              .filter(({group}) => group === FilterSwitchGroupId.View)
              .forEach(({id}) => nextActiveFilterSwitches.delete(id));
          } else if (groupId === FilterSwitchGroupId.View) {
            // Clear "By Groups" if change "View" filter switch
            filterSwitches
              .filter(({group}) => group === FilterSwitchGroupId.Group)
              .forEach(({id}) => nextActiveFilterSwitches.delete(id));
          }

          if (group?.type === FilterSwitchGroupType.Radio) {
            const currentlyActive = activeFilterSwitches.has(filterSwitchId);

            filterSwitches
              .filter(({group}) => group === groupId)
              .forEach(({id}) => nextActiveFilterSwitches.delete(id));

            // Don't activate default value to hide it from "Filtering by" list
            if (defaultValues.current.has(filterSwitchId) === false && currentlyActive === false) {
              nextActiveFilterSwitches.add(filterSwitchId);
            }

            return nextActiveFilterSwitches;
          }
        }

        return toggleInSet(nextActiveFilterSwitches, filterSwitchId);
      });
    },
    [filterSwitches],
  );

  const clearActiveFilterSwitches = useCallback(() => {
    setActiveFilterSwitches(new Set());
  }, []);

  return {
    filterSwitches,
    activeFilterSwitches,
    toggleFilterSwitcher,
    clearActiveFilterSwitches,
  };
}

export {useFilterSwitches, FilterSwitchGroupId, filterSwitchGroups};
