import React, {useRef, useState} from 'react';
import {StreamingStartStopButton} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton/StreamingStartStopButton';
import {
  DEVICE_START_STOP_BUTTON_STATUS,
  STATUS_CHANGING_TIMEOUT,
} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton/constants';
import {useComponentWillUnmount} from 'app/hooks/useComponentWillUnmount';
import {isNil} from 'app/util/isNil';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  disabled: boolean;
  stoppedLabel?: string;
  hasEvent?: boolean;
  startTime?: number;
  statusGetter: () => DEVICE_START_STOP_BUTTON_STATUS;
  startStopAction: () => Promise<void>;
}

export function StreamingButton({
  className,
  stoppedLabel,
  disabled: _disabled,
  startTime,
  hasEvent = false,
  statusGetter,
  startStopAction,
}: Props) {
  const status = statusGetter();

  let loading = false;
  let disabled = _disabled;

  const [previousStatus, setPreviousStatus] = useState<DEVICE_START_STOP_BUTTON_STATUS>();
  const statusChangingTimeoutRef = useRef<number>();

  if (!isNil(previousStatus)) {
    if (previousStatus === status) {
      loading = true;
      disabled = true;
    } else {
      loading = false;
      window.clearTimeout(statusChangingTimeoutRef.current);
      setPreviousStatus(undefined);
    }
  }

  const action = async () => {
    setPreviousStatus(status);
    statusChangingTimeoutRef.current = window.setTimeout(() => {
      setPreviousStatus(undefined);
    }, STATUS_CHANGING_TIMEOUT);

    try {
      await startStopAction();
    } catch {
      setPreviousStatus(undefined);
    }
  };

  useComponentWillUnmount(() => {
    window.clearTimeout(statusChangingTimeoutRef.current);
  });

  return (
    <StreamingStartStopButton
      className={className}
      stoppedLabel={stoppedLabel}
      disabled={disabled}
      status={status}
      startTime={startTime}
      loading={loading}
      startAction={action}
      stopAction={action}
      hasEvent={hasEvent}
    />
  );
}
