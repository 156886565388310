import {Icons} from 'app/util/icons';
import {STREAMING_SERVICE_TYPE} from 'app/components/StreamingServices/constants';

export const StreamingServiceIcon = {
  /**
   * @param {STREAMING_SERVICE_TYPE} type
   * @return {SvgIcon}
   */
  getIconByType(type) {
    switch (type) {
      case STREAMING_SERVICE_TYPE.FACEBOOK:
        return Icons.facebookStreamingService();
      case STREAMING_SERVICE_TYPE.YOUTUBE:
        return Icons.youtubeStreamingService();
      case STREAMING_SERVICE_TYPE.TWITCH:
        return Icons.twitchStreamingService();
      case STREAMING_SERVICE_TYPE.RTMP:
      case STREAMING_SERVICE_TYPE.RTMPS:
      default:
        return Icons.rtmpStreamingService();
    }
  },

  /**
   * @param {STREAMING_SERVICE_TYPE} type
   * @return {SvgIcon}
   */
  getSmallIconByType(type) {
    switch (type) {
      case STREAMING_SERVICE_TYPE.FACEBOOK:
        return Icons.facebookStreamingServiceSmall()
          .class('streaming-service-icon streaming-service-icon--facebook');
      case STREAMING_SERVICE_TYPE.YOUTUBE:
        return Icons.youtubeStreamingServiceSmall()
          .class('streaming-service-icon streaming-service-icon--youtube');
      case STREAMING_SERVICE_TYPE.TWITCH:
        return Icons.twitchStreamingServiceSmall()
          .class('streaming-service-icon streaming-service-icon--twitch');
      case STREAMING_SERVICE_TYPE.RTMP:
      case STREAMING_SERVICE_TYPE.RTMPS:
      default:
        return Icons.rtmpStreamingServiceSmall()
          .class('streaming-service-icon streaming-service-icon--rtmp');
    }
  },
};
