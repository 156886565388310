import {router} from 'app/router/main';
import {ErrorModal} from 'app/components/sharedReactComponents/Modal/ErrorModal';
import {PairingFailedBillingPlanErrorModal} from 'app/components/libs/dialogs/PairingFailedBillingPlanErrorModal';
import {ApiResponseNumeralStatus} from 'app/data/Status';
import {Callback} from 'app/types/common';
import {deviceApiService} from 'app/services/api/device/DeviceApiService';
import {DeviceGroupApiService} from 'app/services/api/deviceGroup/DeviceGroupApiService';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';

function goToDevicePage({
  deviceId,
  teamId,
  currentTeamId,
}: {
  currentTeamId: string;
  deviceId?: string;
  teamId?: string;
}) {
  if (deviceId) {
    goToDeviceDetailsPage({deviceId, teamId, currentTeamId});
  } else {
    // `ID` param does not exist for Demo-devices
    goToTeamDevicesPage(currentTeamId, teamId);
  }
}

function goToDeviceDetailsPage({
  deviceId,
  teamId,
  currentTeamId,
}: {
  deviceId: string;
  teamId?: string;
  currentTeamId: string;
}) {
  if (teamId === currentTeamId || !teamId) {
    router.go('deviceDetails', {deviceId});
  } else {
    router.goToTeam(teamId, router.url('deviceDetails', {deviceId}));
  }
}

function goToDevicesPage() {
  router.go('devices');
}

function goToTeamDevicesPage(currentTeamId: string, teamId?: string) {
  if (teamId === currentTeamId || !teamId) {
    goToDevicesPage();
  } else {
    router.goToTeam(teamId, router.url('devices'));
  }
}

export type DevicePairResponse = {
  deviceId: string;
  groupId?: string;
  teamId?: string;
};

function hasStatus(error: unknown): error is {status: number} {
  return 'status' in (error as any) && typeof (error as any).status === 'number';
}

function errorHandler(
  error: unknown,
  currentUserRole: AnyUserRoleModel,
  callback?: Callback,
): void {
  if (hasStatus(error)) {
    if (error.status === ApiResponseNumeralStatus.PaymentRequired) {
      PairingFailedBillingPlanErrorModal.show({
        currentUserRole,
        onClose: callback,
      });

      return;
    }

    const title = 'Pairing failed';
    const message = 'Device with this pairing code not found';

    ErrorModal.show({
      title,
      message: message,
      onClose: callback,
    });
  }
}

interface Params {
  deviceId: string;
  teamId?: string;
  deviceName?: string;
  deviceGroupId?: string;
  currentTeamId: string;
  currentUserRole: AnyUserRoleModel;
  onError?: Callback;
}

async function pairRealDevice({
  deviceId,
  deviceName,
  currentTeamId,
  teamId,
  deviceGroupId,
  currentUserRole,
  onError,
}: Params) {
  const dto: Payloads.Cloud.PairDevice = {DeviceID: deviceId};

  if (deviceName) {
    dto.Name = deviceName;
  }

  try {
    const {ID: createdDeviceId} = await deviceApiService.pairRealDevice(
      teamId ?? currentTeamId,
      dto,
    );

    if (deviceGroupId) {
      try {
        await DeviceGroupApiService.moveToGroup(
          teamId ?? currentTeamId,
          deviceGroupId,
          createdDeviceId,
        );
      } catch {}
    }

    goToDevicePage({
      deviceId: createdDeviceId,
      currentTeamId,
      teamId,
    });
  } catch (e: unknown) {
    errorHandler(e, currentUserRole, onError);
  }
}

export {goToDevicesPage, pairRealDevice};
