import {useEffect, useState} from 'react';
import {Schedule} from 'app/domain/schedule';
import {
  buildEventGroups,
  hasOverlap,
} from 'app/components/sharedReactComponents/Events/Calendar/utils';

interface Args {
  events: Schedule.Event[];
  range: TimeRange;
}

interface EventGroup {
  start: TimeStampSeconds;
  end: TimeStampSeconds;
  events: Schedule.Event[];
}

interface DayEvents {
  ungrouped: Schedule.Event[];
  groups: EventGroup[];
}

export function useDayEvents({range, events}: Args) {
  const [stats, setStats] = useState<DayEvents>({groups: [], ungrouped: []});

  useEffect(() => {
    const intervalEvents = events.filter((event) => {
      return hasOverlap({from: event.start, to: event.end}, range);
    });

    const result = buildEventGroups(intervalEvents);
    setStats(result);
  }, [range, events]);

  return stats;
}
