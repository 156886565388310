import {useQuery} from '@tanstack/react-query';
import {FilesBillingApi} from 'app/components/entities/billing/files/api/FilesBillingApi';
import {FilesBillingMapper} from 'app/components/entities/billing/files/mapper';

export function useFilesPlanQuery(teamId: string) {
  return useQuery({
    queryKey: ['billing', 'files', 'plan', teamId],
    queryFn: async () => {
      try {
        const res = await FilesBillingApi.getPlan(teamId);

        if (!res) {
          return null;
        }

        return FilesBillingMapper.mapPlan(res);
      } catch {
        return null;
      }
    },
  });
}
