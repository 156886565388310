import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import Joi from 'joi';
import {joiResolver} from '@hookform/resolvers/joi';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import {Callback} from 'app/types/common';
import {isNil} from 'ramda';
import {BUTTON_TYPE, ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {palette} from 'app/themes/app';
import {THEME, VARIANT} from 'app/constants';
import {Notifications} from 'app/components/Notifications';
import {useMounted} from 'app/hooks/useIsMounted';

const scheme = Joi.object<ConnectData>({
  url: Joi.string().trim().uri().required(),
  mode: Joi.string().required(),
}).messages({
  'any.required': 'Required field',
  'string.empty': 'Cannot be empty',
  'string.uri': 'Invalid url',
});

type AudioMode = 'mixed' | 'isolated';

interface ConnectData {
  url: string;
  mode: AudioMode;
}
interface ValidationErrors {
  meeting_url?: string;
}

interface Props {
  onConnect: Callback<any, [data: ConnectData]>;
}

const placeholder = 'https://teams.microsoft.com/l/meetup-join/meeting_id';

export function ConnectForm({onConnect}: Props) {
  const [confirming, setConfirming] = useState(false);
  const mounted = useMounted();

  const {
    control,
    formState: {isValid},
    handleSubmit,
    setError,
  } = useForm<ConnectData>({
    defaultValues: {url: '', mode: 'isolated'},
    mode: 'onChange',
    resolver: joiResolver(scheme),
  });

  const handleConnect = async (d: ConnectData) => {
    try {
      setConfirming(true);
      await onConnect(d);
    } catch (e: any) {
      if (e.data.ErrorData) {
        const {meeting_url} = e.data.ErrorData as ValidationErrors;

        if (meeting_url) {
          setError('url', {type: 'custom', message: meeting_url});
        }

        return;
      }

      const msg = e.data.Error;

      if (typeof msg === 'string') {
        Notifications.addErrorNotification(`Error: ${msg}`);
      }
    } finally {
      if (mounted()) {
        setConfirming(false);
      }
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(handleConnect)}>
        <Box sx={{mb: 0.5, fontSize: '16px', fontWeight: 'bold'}}>Teams Meeting URL:</Box>

        <Controller
          control={control}
          name="url"
          render={({field, fieldState: {error}}) => (
            <TextField
              {...field}
              autoFocus={true}
              inputProps={{'data-id': 'meeting-url'}}
              sx={{mb: 1, wordBreak: 'break-all'}}
              variant="standard"
              placeholder={placeholder}
              helperText={error?.message ?? ' '}
              fullWidth={true}
              multiline={true}
              minRows={5}
              error={!isNil(error)}
            />
          )}
        />

        <Controller
          name="mode"
          control={control}
          render={({field}) => (
            <FormControl>
              <Box sx={{mt: 2, mb: 1, fontWeight: 'bold'}}>Epiphan Connect audio mode:</Box>

              <RadioGroup
                sx={{display: 'flex', gap: 3, flexDirection: 'row', flexWrap: 'nowrap'}}
                {...field}
              >
                <Box sx={{flex: 1}}>
                  <FormControlLabel
                    sx={{mb: 0}}
                    value="isolated"
                    control={<Radio data-id="isolated-mode-radio" />}
                    label="Isolated"
                  />
                  <FormHelperText sx={{ml: '28px'}}>
                    Isolate participant audio into separate tracks. Use this to control extracted
                    audio.
                  </FormHelperText>
                </Box>

                <Box sx={{flex: 1}}>
                  <FormControlLabel
                    sx={{mb: 0}}
                    value="mixed"
                    control={<Radio data-id="mixed-mode-radio" />}
                    label="Mixed"
                  />
                  <FormHelperText sx={{ml: '28px'}}>
                    Each participant's audio track will contain the mixed audio from the Microsoft
                    Teams meeting.
                  </FormHelperText>
                </Box>
              </RadioGroup>
            </FormControl>
          )}
        />

        <Box sx={{color: palette.darkerGray, my: 4}}>
          Connecting to a meeting can take several minutes. Give yourself sufficient time to get
          connected before the meeting start time.
        </Box>
        <Box sx={{'& button': {width: '154px'}}}>
          <ProgressButton
            dataId="teams_connect_button"
            type={BUTTON_TYPE.SUBMIT}
            loading={confirming}
            theme={THEME.PRIMARY}
            variant={VARIANT.SOLID}
            disabled={!isValid}
          >
            Connect
          </ProgressButton>
        </Box>
      </form>
    </Box>
  );
}
