import {UserTeamStore} from 'app/util/UserTeamStore';

interface CollapsedFilterSwitchGroupStoreProps {
  teamId: string;
  userId: string;
  storeKey: string;
}

class CollapsedFilterSwitchGroupStore {
  private readonly store: UserTeamStore;

  constructor({
    teamId,
    userId,
    storeKey,
  }: CollapsedFilterSwitchGroupStoreProps) {
    this.store = new UserTeamStore({
      defaultValue: [],
      storeKey,
      teamId,
      userId,
    });
  }

  load(): Set<string> {
    return new Set(this.store.load());
  }

  save(filterSwitchGroupIds: Set<string>) {
    this.store.save(Array.from(filterSwitchGroupIds));
  }
}

export {
  CollapsedFilterSwitchGroupStore,
};
