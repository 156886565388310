import React from 'react';
import {
  LocationForm,
  LocationRow,
  LocationInput,
  TypeChangeInput
} from 'app/components/UploadLocations/LocationInput';
import {PrivateKey} from 'app/components/UploadLocations/SFTPLocation/PrivateKey';
import {Callback} from 'app/types/common';

interface Props {
  model: any;
  uploadTypes: any[];
  onChangeUploadType: Callback;
}

function SFTPLocation({
  model,
  uploadTypes,
  onChangeUploadType,
}: Props) {
  return (
    <LocationForm>
      <LocationRow>
        <TypeChangeInput
          types={uploadTypes}
          value={model.type}
          onChange={onChangeUploadType}
        />
      </LocationRow>

      <LocationRow>
        <LocationInput
          label="Nick name"
          placeholder="Location 1"
          model={model.name}
        />
      </LocationRow>

      <LocationRow>
        <LocationInput
          className="cr-transfer-location-config-input--100-percent-width"
          label="Address"
          placeholder="ftp.yourdomain.com"
          model={model.address}
        />
      </LocationRow>

      <LocationRow>
        <LocationInput
          className="cr-transfer-location-config-input--70-percent-width"
          label="Path"
          placeholder="/myvideos/epiphancloud"
          model={model.path}
        />

        <LocationInput
          className="cr-transfer-location-config-input--30-percent-width"
          label="Port"
          type="number"
          min={0}
          model={model.port}
        />
      </LocationRow>

      <LocationRow>
        <LocationInput
          label="Username"
          model={model.username}
        />

        <LocationInput
          label="Password"
          type="password"
          model={model.password}
        />
      </LocationRow>

      <PrivateKey
        model={model.privateKey}
      />
    </LocationForm>
  );
}

export {SFTPLocation};
