import {BaseValidator} from 'app/util/validators/BaseValidator';

class EmptyStringValidator extends BaseValidator {
  protected message = 'Name should be non empty';
  protected type = 'empty_string';

  validate(value: string): boolean {
    return value.trim() !== '';
  }
}

export {EmptyStringValidator};
