/* eslint-disable react/default-props-match-prop-types */
import React, {Component} from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {ClassName} from 'app/types/common';

function trimTranscript(transcript: Transcription[], itemsCount: number): Transcription[] {
  if (itemsCount > 0) {
    return transcript.slice(-(itemsCount));
  }

  return transcript;
}

const SHOW_MESSAGES_COUNT = 5;

interface Props extends ClassName {
  transcript: Transcription[];
  itemsCount: number;
  paused: boolean;
  disabled: boolean;
  placeholder: string;
}

interface State {
  transcript: any[];
}

// move to service
interface Transcription {
  // rework indexed
  [key: string]: any;
  id: string | number;
  value: string;
  timestamp: string;
  partial: boolean;
}

export class TranscribingText extends Component<Props, State> {
  static defaultProps = {
    transcript: [],
    placeholder: 'Your transcription will show up here',
    itemsCount: SHOW_MESSAGES_COUNT,
  };

  constructor(props) {
    super(props);

    this.state = {
      transcript: trimTranscript(props.transcript, props.itemsCount),
    };
  }

  componentDidUpdate(prevProps) {
    let {
      transcript,
      itemsCount,
    } = this.props;

    if (transcript === prevProps.transcript) {
      return;
    }

    const prevIds = prevProps.transcript.reduce((acc, b) => {
      if (!b.partial) {
        acc.add(b.id);
      }

      return acc;
    }, new Set());

    transcript = trimTranscript(transcript, itemsCount);

    transcript.forEach((item): any => {
      item.new = !prevIds.has(item.id);
    });

    this.setState({transcript});
  }

  render() {
    const {
      className,
      paused,
      disabled,
      placeholder,
      transcript: transcriptProps,
      itemsCount,
      ...rest
    } = this.props;
    const {
      transcript,
    } = this.state;

    if (disabled) {
      return null;
    }

    return (
      <div
        className={classNames('transcribing-text', className)}
        {...rest}
      >
        {transcript.length > 0 ? (
          <div className="transcribing-text__content">
            {transcript.map(item => (
              <div
                key={item.id || 'new'}
                className={classNames('transcribing-text__item', {
                  'transcribing-text__item--partial': item.partial && !paused,
                  'transcribing-text__item--new': item.new && !paused,
                })}
              >
                {item.value}

                {(item.partial && !paused) && (
                  <span className="transcribing-text__item-progress">
                    {Icons.loadingDots().size(SIZE.XL).reactComponent()}
                  </span>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="transcribing-text__placeholder">
            {placeholder}
          </div>
        )}
      </div>
    );
  }
}
