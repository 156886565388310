import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {
  BATCH_PANEL_NARROW_MAX_WIDTH,
} from 'app/components/FleetManager/BatchActionsPanel/constants';

function isBatchActionPanelNarrow(): boolean {
  return window.innerWidth < BATCH_PANEL_NARROW_MAX_WIDTH;
}

function isDevicesSameModel(devices: AnyDeviceModelType[]): boolean {
  if (!Array.isArray(devices) || devices.length === 0) {
    return false;
  }

  if (devices.length === 1) {
    return true;
  }

  const models = new Set(devices.map(device => device.getModelName()));

  if (models.size > 1) {
    return false;
  }

  return true;
}

export {
  isBatchActionPanelNarrow,
  isDevicesSameModel,
};
