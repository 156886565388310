import React, {useState} from 'react';
import {ButtonProps, Popover, Tooltip} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import ViewCompactAltIcon from '@mui/icons-material/ViewCompactAlt';
import {Sx} from 'app/types/common';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {ApiResponseNumeralStatus} from 'app/data/Status';
import {Notifications} from 'app/components/Notifications';
import {UpgradeToEdgePremium} from 'app/components/sharedReactComponents/UpgradeToEdgePremium/UpgradeToEdgePremium';

type RemoteType = 'admin' | 'switcher';

type Props = Sx & {
  deviceId: string;
  type: RemoteType;
  premium: boolean;
  paymentAccess: boolean;
  showIcon?: boolean;
  labeled?: boolean;
} & Pick<ButtonProps, 'size' | 'disabled'>;

export function RemoteLoginButton({
  sx,
  deviceId,
  type,
  size,
  premium,
  paymentAccess,
  disabled,
  labeled = true,
  showIcon = false,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isSwitcher = type === 'switcher';

  const handleLogin = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!premium) {
      setAnchorEl(e.currentTarget);
      return;
    }

    try {
      setLoading(true);
      const res = await DeviceApiService.getUrlRemoteLogin(deviceId);
      const url = isSwitcher ? res.URL.Switcher : res.URL.Admin;

      window.open(url, '_blank');
    } catch (e: unknown) {
      let errorMessage = 'Something went wrong. Try again later.';

      if ((e as any)?.status === ApiResponseNumeralStatus.PaymentRequired) {
        errorMessage = `Remote ${type} login is not available for free teams.`;
      }

      if ((e as any)?.status === ApiResponseNumeralStatus.NotFound) {
        errorMessage = 'Device is not connected to Remote login server.';
      }

      Notifications.addErrorNotification(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const label = isSwitcher ? 'Switcher' : 'Admin login';
  const icon = isSwitcher ? <ViewCompactAltIcon /> : <ScreenShareIcon />;

  return (
    <>
      <Tooltip title={!labeled ? label : undefined}>
        <LoadingButton
          data-id={isSwitcher ? 'switcher_button' : 'remote_login_button'}
          sx={sx}
          variant="outlined"
          color={isSwitcher ? 'secondary' : 'primary'}
          loading={loading}
          disabled={disabled}
          disableRipple={false}
          size={size}
          onClick={handleLogin}
        >
          {showIcon && icon}
          {labeled && label}
        </LoadingButton>
      </Tooltip>

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        slotProps={{paper: {sx: {p: 2}}}}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        onClose={() => setAnchorEl(null)}
      >
        <UpgradeToEdgePremium billingAccess={paymentAccess} />
      </Popover>
    </>
  );
}
