import React, {forwardRef, useCallback, useEffect, useMemo, useRef} from 'react';
import {getPixelRatio} from 'app/components/sharedReactComponents/Canvas/utils';

type CanvasChildrenFn = (
  context: CanvasRenderingContext2D,
  width: number,
  height: number,
) => void;

interface Props {
  width: number;
  height: number;
  children: CanvasChildrenFn;
  canvasRef?: any;
}

const CanvasComponent: React.FC<Props> = ({
  canvasRef,
  width,
  height,
  children,
}) => {
  const canvasElement = useRef<HTMLCanvasElement>();

  const setRef = useCallback((ref) => {
    canvasElement.current = ref;

    if (canvasRef) {
      canvasRef.current = ref;
    }
  }, [canvasRef]);

  useEffect(() => {
    const pixelRatio = getPixelRatio();

    const canvas = canvasElement.current;

    if (!canvas) {
      return;
    }

    canvas.width = width * pixelRatio;
    canvas.height = height * pixelRatio;

    const context = canvas.getContext('2d');

    if (!context) {
      return;
    }

    context.scale(pixelRatio, pixelRatio);
    children(context, width, height);
  }, [children, width, height]);

  const style = useMemo(() => ({
    width,
    height,
  }), [width, height]);

  return (
    <canvas
      ref={setRef}
      style={style}
    />
  );
};

export const Canvas = forwardRef<any, Props>((props, ref) => (
  <CanvasComponent
    {...props}
    canvasRef={ref}
  />
));

Canvas.displayName = 'Canvas';
