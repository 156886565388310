import {ALL_PRESET_SECTIONS, UNIFY_PRESET_SECTIONS} from 'app/constants/devicePresetSection';
import {Cloud} from 'app/domain/cloud';
import {PresetApiService} from 'app/services/api/preset/PresetApiService';
import {downloadFile} from 'app/util/download';

export function downloadPreset(id: string, name: string): void {
  const url = PresetApiService.getDownloadUrl(id);
  downloadFile(url, name);
}

export function getModelSections(model: Cloud.UnitModel): App.Presets.Section[] {
  if (model === 'Epiphan Unify Project') {
    return UNIFY_PRESET_SECTIONS;
  }

  if (model === 'Pearl Nexus') {
    return ALL_PRESET_SECTIONS.filter((s) => s !== 'frontscreen');
  }

  return ALL_PRESET_SECTIONS;
}
