import React from 'react';
import {OutlinedTextFieldProps, TextField} from '@mui/material';
import classNames from 'classnames';
import {DataId} from 'app/types/common';

export type TextInputProps = Omit<OutlinedTextFieldProps, 'variant'>;

export const TextInput = ({className, dataId, ...props}: TextInputProps & DataId) => {
  return (
    <TextField
      {...props}
      className={classNames('text-input', className)}
      variant="outlined"
      data-id={dataId}
    />
  );
};
