import React from 'react';
import {observer} from 'mobx-react';
import {Stack} from '@mui/material';
import {StyleSx, Sx} from 'app/types/common';
import {Header} from 'app/components/FleetManager/Header';
import {useCurrentTeamStore} from 'app/store/hooks';
import {useCloudEntities} from 'app/components/entities/cloud';
import {TeamFilesWelcome} from 'app/components/features/files';
import {TeamFiles} from 'app/components/widgets/files';

const rootStyles: StyleSx = {width: 1032, pl: 3, pr: 1.5, my: 2};

type Props = Sx & {};

export const TeamFileManagement = observer(({sx}: Props) => {
  const team = useCurrentTeamStore();
  const {user} = useCloudEntities();
  const teamId = team.id;

  const enabled = team.getCapabilities().files();
  const permitEditBilling = user.role.canEditBilling();

  return (
    <Stack sx={sx} flex={1}>
      <Header searchValue="" showSearch={false} onSearch={() => {}} />

      <Stack flex={1} sx={rootStyles}>
        {enabled ? (
          <TeamFiles sx={{flex: 1}} teamId={teamId} />
        ) : (
          <TeamFilesWelcome permitEdit={permitEditBilling} />
        )}
      </Stack>
    </Stack>
  );
});
