import {front} from 'app/api/CirrusApi';
import {GetAbortCallback} from 'app/api/types';

export class MeetingsApiService {
  static async getMeeting(
    id: string,
    getAbort?: GetAbortCallback,
  ): Promise<Contracts.Connect.Meeting> {
    return front.econnect().meetings(id).get(undefined, {getAbort});
  }

  static async getMeetings(
    active: boolean,
    getAbort?: GetAbortCallback,
  ): Promise<{meetings: Contracts.Connect.Meeting[]}> {
    return front.econnect().meetings().get({active}, {getAbort});
  }

  static async connectToMeeting(
    data: Payloads.Connect.Meeting,
  ): Promise<{meeting: Contracts.Connect.Meeting}> {
    return front.econnect().meetings().post(data);
  }

  static async createHosted(name: string): Promise<void> {
    return front.econnect().calls().teams().post({name});
  }

  static async deleteCall(id: string): Promise<void> {
    return front.econnect().calls().teams(id).delete();
  }

  static async deleteMeeting(id: string): Promise<void> {
    return front.econnect().meetings(id).delete();
  }

  static async connect(id: string): Promise<void> {
    return front.econnect().meetings(id).connect().post();
  }

  static async disconnect(id: string): Promise<void> {
    return front.econnect().meetings(id).disconnect().post();
  }

  static async getParticipant(
    meetingId: string,
    participantId: string,
    getAbort?: GetAbortCallback,
  ): Promise<Contracts.Connect.Participant> {
    return front
      .econnect()
      .meetings(meetingId)
      .participants(participantId)
      .get(undefined, {getAbort});
  }

  static async getReturnFeed(
    meetingId: string,
    getAbort?: GetAbortCallback,
  ): Promise<Contracts.Connect.ReturnFeed> {
    return front.econnect().meetings(meetingId).injection().get(undefined, {getAbort});
  }

  static async getTenants(
    getAbort?: GetAbortCallback,
  ): Promise<Contracts.Connect.Auth.TeamsTenant[]> {
    return front.econnect().ms_tenants().get(undefined, {getAbort});
  }

  static async getTenantHealth(
    getAbort?: GetAbortCallback,
  ): Promise<Contracts.Connect.Auth.TeamsTenant[]> {
    return front.econnect().ms_tenants().health().get(undefined, {getAbort});
  }

  static async disconnectTenant(tenantId: string) {
    return front.econnect().ms_tenants(tenantId).delete();
  }

  static async setName(id: string, name: string): Promise<void> {
    return front.econnect().meetings(id).put({name});
  }

  static async getLink(): Promise<{link: string}> {
    return front.econnect().shareable_link().get();
  }

  static async getCalls(): Promise<Contracts.Connect.Calls> {
    return front.econnect().calls().teams().get({active: true});
  }

  static async setPasscode(id: string, data: Payloads.Connect.Password): Promise<any> {
    return front.econnect().meetings(id).password().put(data);
  }

  static async getZoomAccounts(): Promise<Contracts.Connect.Auth.ZoomAccount[]> {
    return front.econnect().zoomAccounts().get();
  }

  static async shareZoom(id: string, body: Payloads.Connect.ShareZoom): Promise<void> {
    return front.econnect().zoomAccounts(id).share().put(body);
  }

  static async signOutZoom(id: string): Promise<any> {
    return front.econnect().zoomAccounts(id).delete();
  }

  static async setZoomAccount(id: string, data: Payloads.Connect.ZoomAccount) {
    return front.econnect().meetings(id).account().put(data);
  }
}
