import React, {useState} from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {InputAdornment, TextField, Typography} from '@mui/material';
import {Callback, ChangeEvent, Component} from 'app/types/common';
import {StreamSetting} from 'app/components/pages/MeetingDetails/StreamSettings/common/StreamSetting/StreamSetting';
import {RadioGroup} from 'app/components/sharedReactComponents/RadioGroup';
import {Radio} from 'app/components/sharedReactComponents/Radio';
import {SIZE} from 'app/constants';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {TextInput} from 'app/components/sharedReactComponents/TextInput/TextInput';
import {getValidNumber} from 'app/components/pages/MeetingDetails/StreamSettings/utils';
import {CopyText} from 'app/components/pages/MeetingDetails/shared/CopyText/CopyText';
import {Icons} from 'app/util/icons';
import {SrtSettingsCallback} from 'app/components/pages/MeetingDetails/StreamSettings/types';
import {isNil} from 'app/util/isNil';

interface CustomConnection {
  url: string;
  port: number;
}

function getInitialSettings(mode: App.Connect.SteamMode, settings: CustomConfig): CustomConnection {
  if (mode === settings.mode) {
    const {url, port} = settings;
    return {url, port};
  }

  return {
    port: 0,
    url: '',
  };
}

export interface CustomConfig {
  mode: App.Connect.SteamMode;
  url: string;
  port: number;
}

interface Props extends Component, SrtSettingsCallback {
  config: CustomConfig;
  range: App.Connect.PortRange;
  dns: string;
  errors?: App.Connect.ValidationErrors;
  setConfig: Callback<void, [Partial<CustomConfig>]>;
}

export const CustomSrtConnection = observer(
  ({className, dataId, config, range, dns, errors, onSync, setConfig}: Props) => {
    const [caller, setCaller] = useState<CustomConnection>(() =>
      getInitialSettings('caller', config),
    );

    const [listener, setListener] = useState<CustomConnection>(() =>
      getInitialSettings('listener', config),
    );

    const handleModeChange = async (e: ChangeEvent<App.Connect.SteamMode>) => {
      const mode = e.target.value;
      const connection = mode === 'listener' ? {...listener} : {...caller};
      setConfig({...connection, mode});
      await onSync();
    };

    const handleUrlChange = (e: ChangeEvent<string>) => {
      const url = e.target.value;
      setConfig({url});
      setCaller((prev) => ({...prev, url}));
    };

    const handlePortChange = async (e: ChangeEvent<string>) => {
      const input = e.target.value.replace(/[^0-9]/g, '');
      const port = getValidNumber(input, range.to, listener.port);

      setConfig({port});
      setListener((prev) => ({
        ...prev,
        port,
      }));
    };

    const renderControls = () => {
      const callerMode = config.mode === 'caller';

      if (callerMode) {
        return (
          <>
            <StreamSetting className="custom-srt-connection__setting" label="URL">
              <TextField
                data-id="srt_caller_url"
                placeholder="srt://decoder-url:port"
                value={caller.url}
                fullWidth={true}
                error={!isNil(errors?.StreamUrl)}
                helperText={errors?.StreamUrl}
                onChange={handleUrlChange}
                onBlur={onSync}
              />
            </StreamSetting>

            <StreamSetting
              className="custom-srt-connection__setting"
              label="Configure SRT listener at the specified URL to get the SRT stream"
            />
          </>
        );
      }

      const portWarning = `Make sure this port is in range ${range.from} - ${range.to}`;
      const listenerUrl = `srt://${dns}:${config.port}`;

      return (
        <>
          <StreamSetting className="custom-srt-connection__setting" label="Port">
            <div className="custom-srt-connection__port-wrapper">
              <TextInput
                className="custom-srt-connection__port"
                dataId="srt_listener_port"
                placeholder="Port"
                fullWidth={true}
                InputProps={{
                  sx: {width: 140},
                  endAdornment: (
                    <InputAdornment sx={{mr: 0.5, cursor: 'default'}} position="end">
                      <Tooltip content={portWarning}>{Icons.info().reactComponent()}</Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={listener.port ? listener.port.toString() : ''}
                error={!isNil(errors?.Port)}
                helperText={errors?.Port}
                onChange={handlePortChange}
                onBlur={onSync}
              />
            </div>
          </StreamSetting>

          <StreamSetting
            className="custom-srt-connection__setting"
            label="Your stream will be available at"
          >
            <CopyText value={listenerUrl} dataId="srt_listener_url" />
          </StreamSetting>
        </>
      );
    };

    return (
      <div data-id={dataId} className={classNames('custom-srt-connection', className)}>
        <Typography mb={2}>
          Manually configure your SRT connection to send it to your preferred video production tool.
        </Typography>

        <StreamSetting className="custom-srt-connection__mode" label="Connection mode">
          <RadioGroup
            className="custom-srt-connection__group"
            dataId="srt_mode_radio"
            value={config.mode}
            onChange={handleModeChange}
          >
            <Radio
              className="custom-srt-connection__radio"
              dataId="srt_listener_mode"
              size={SIZE.S}
              value="listener"
              label="Listener"
            />

            <Radio
              className="custom-srt-connection__radio"
              dataId="srt_caller_mode"
              size={SIZE.S}
              value="caller"
              label="Caller"
            />
          </RadioGroup>
        </StreamSetting>

        {renderControls()}
      </div>
    );
  },
);
