import React from 'react';
import {Box} from '@mui/material';
import {Sx} from 'app/types/common';
import {LegacyIcon} from 'app/components/sharedReactComponents/LegacyIcon/LegacyIcon';
import {Icons} from 'app/util/icons';
import {Cloud} from 'app/domain/cloud';

/** Part of pathname to an icon */
type IconName =
  | 'livescrypt'
  | 'pearl-2'
  | 'pearl-nano'
  | 'pearl-mini'
  | 'pearl-nexus'
  | 'webcaster'
  | 'unify';

interface Props extends Sx {
  model: Cloud.UnitModel;
}

export function DevicePicture({sx, model}: Props) {
  const iconName = getDeviceIcon(model);

  if (!iconName) {
    return null;
  }

  if (iconName === 'unify') {
    return <LegacyIcon sx={sx}>{Icons.projectIconColored().reactComponent()}</LegacyIcon>;
  }

  return (
    <Box
      sx={sx}
      component="img"
      alt=""
      src={`/assets/img/device-model/${iconName}.png`}
      srcSet={`/assets/img/device-model/${iconName}@2x.png 2x`}
    />
  );
}

function getDeviceIcon(model: Cloud.UnitModel): IconName | undefined {
  switch (model) {
    case 'Pearl-2':
      return 'pearl-2';

    case 'Pearl Nano':
      return 'pearl-nano';

    case 'Pearl Mini':
      return 'pearl-mini';

    case 'LiveScrypt':
      return 'livescrypt';

    case 'Webcaster':
      return 'webcaster';

    case 'Epiphan Unify Project':
      return 'unify';

    case 'Pearl Nexus':
      return 'pearl-nexus';

    default:
      break;
  }
}
