import React from 'react';
import classNames from 'classnames';
import {Autocomplete, TextField} from '@mui/material';
import {Callback, ClassName, DataId} from 'app/types/common';
import {isNil} from 'app/util/isNil';

interface Props extends ClassName, DataId {
  value: string;
  error?: string;
  onChange: Callback<void, [string]>;
  onBlur?: Callback;
}

export const BitrateInput = ({className, dataId, value, error, onChange, onBlur}: Props) => {
  const onInputChange = (_e, value: string) => {
    onChange(value.replace(/[^0-9]/g, ''));
  };

  return (
    <Autocomplete
      className={classNames('bitrate-input', className)}
      data-id={dataId}
      options={[
        {
          label: '1024',
          value: 1024,
        },
        {
          label: '2500',
          value: 2500,
        },
        {
          label: '3000',
          value: 3000,
        },
        {
          label: '6000',
          value: 6000,
        },
        {
          label: '8000',
          value: 8000,
        },
        {
          label: '16000',
          value: 16000,
        },
        {
          label: '32000',
          value: 32000,
        },
      ]}
      freeSolo={true}
      forcePopupIcon={true}
      disableClearable={true}
      disablePortal={true}
      renderInput={(args) => (
        <TextField
          {...args}
          placeholder="Select"
          fullWidth={true}
          error={!isNil(error)}
          helperText={error}
          InputProps={{
            ...args.InputProps,
            endAdornment: (
              <>
                {value ? <span className="bitrate-input__suffix">kbits</span> : null}
                {args.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      inputValue={value}
      onInputChange={onInputChange}
      onBlur={onBlur}
    />
  );
};
