import {LongPoll} from 'app/util/LongPoll';
import {front} from 'app/api/CirrusApi';
import {isNil} from 'app/util/isNil';
import {noop} from 'app/util/noop';
import {AbortCallback, GetAbortCallback} from 'app/api/types';

function processResponseData(response) {
  response = isNil(response) ? {} : response;
  response.data = isNil(response.data) ? {} : response.data;
  response.data.transcript = isNil(response.data.transcript) ? [] : response.data.transcript;

  return response;
}

export class TranscribingLP {
  public _version = 0;
  public _paused = false;
  public _deviceId: string;

  public _onUpdate: any;
  public _abortRequest: AbortCallback;
  public _call: ({getAbort}: {getAbort: GetAbortCallback}) => any;
  public _lp: any;

  constructor({
    deviceId,
    call,
    onUpdate,
    onStop,
  }: any) {
    this._deviceId = deviceId;
    this._onUpdate = onUpdate;

    this._abortRequest = noop;

    if (call) {
      this._call = ({getAbort}) => call({version: this._version}, {getAbort});
    } else {
      this._call = ({getAbort}) => front.transcribing(this._deviceId).result({version: this._version}).get(undefined, {getAbort});
    }

    this._lp = new LongPoll({
      call: this._call,
      onStart: () => {
        this._version = 0;
      },
      checkUpdated: (response) => {
        let version = 0;
        let paused = false;

        if (response.data) {
          version = response.data.version;
          paused = !isNil(response.data.stop_time);
        }

        if (
          (!isNil(version) && this._version !== version) ||
          paused !== this._paused
        ) {
          this._version = version;
          this._paused = paused;

          return true;
        }

        return false;
      },
      onUpdate: (response) => {
        this._onUpdate(processResponseData(response));
      },
      onStop,
    });
  }

  start() {
    this._lp.start();
  }

  stop() {
    this._lp.stop();
    this._abortRequest();
  }

  once() {
    this._call({getAbort: (abort) => {
      this._abortRequest = abort;
    }})
      .then((response) => {
        this._onUpdate(processResponseData(response));
      });
  }
}
