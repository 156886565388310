import {useEffect, useMemo, useState} from 'react';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {DetailsTab} from 'app/components/DeviceDetails/DeviceInfoTab/types';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';

type Args = {
  urlTab: string;
  device?: AnyDeviceModelType;
  filesFeature: boolean;
};

export function useDetailsTabs({urlTab, device, filesFeature}: Args) {
  const [tab, setTab] = useState(urlTab);

  useEffect(() => {
    setTab(urlTab);
  }, [urlTab]);

  const tabs = useMemo(() => {
    if (!device) {
      return [];
    }

    const model = device.getModelName();

    const isMaster = device instanceof PearlMasterDeviceModel;
    const isWebcaster = ModelService.isWebcaster(model);
    const isLvs = ModelService.isLivescrypt(model);
    const isUnify = ModelService.isUnify(model);

    const isOnline = device.isOnline();

    const {capabilities, hardware} = device;

    const map = new Map<DetailsTab, boolean>([
      ['status', true],
      ['alerts', true],
      ['network', isOnline && hardware.has('network-monitor')],
      ['channels', isMaster],
      ['channel', isWebcaster && capabilities.recording],
      ['inputs', isMaster && isOnline && !isUnify],
      ['output', isLvs && capabilities.transcribing],
      ['audio', isLvs && capabilities.transcribing],
      ['transcription', isLvs && capabilities.transcribing],
      ['session', isLvs && capabilities.transcribing],
      ['events', capabilities.schedule],
      ['presets', hardware.has('presets')],
      ['recordings', filesFeature && hardware.has('files')],
    ]);

    return Array.from(map.entries()).reduce<DetailsTab[]>(
      (res, [tab, active]) => (active ? [...res, tab] : res),
      [],
    );
  }, [device, filesFeature]);

  return {tabs, tab, setTab};
}
