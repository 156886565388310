import {useEffect} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {Files, FilesMapper, useTeamFilesQuery} from 'app/components/entities/files';
import {Ws} from 'app/contracts/ws';

type Args = {
  enabled: boolean;
  teamId: string;
};

export function useTeamFiles({enabled, teamId}: Args) {
  const client = useQueryClient();

  const query = useTeamFilesQuery({teamId, enabled});

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const handleAddOrUpdate = (message: Ws.FileChanged | Ws.FileAdded) => {
      client.setQueryData(
        ['team', 'files', teamId],
        (prev: Map<string, Files.Recording> | undefined) => {
          if (!prev) {
            return prev;
          }

          const copy = new Map(prev);
          copy.set(message.Body.id, FilesMapper.mapRecording(message.Body.file));
          return copy;
        },
      );
    };

    const handleDelete = (message: Ws.FileRemoved) => {
      client.setQueryData(
        ['team', 'files', teamId],
        (prev: Map<string, Files.Recording> | undefined) => {
          if (!prev) {
            return prev;
          }

          const copy = new Map(prev);
          copy.delete(message.Body.id);
          return copy;
        },
      );
    };

    WS.on(EVENT_TYPE.FILE_ADDED, handleAddOrUpdate);
    WS.on(EVENT_TYPE.FILE_CHANGED, handleAddOrUpdate);
    WS.on(EVENT_TYPE.FILE_REMOVED, handleDelete);

    return () => {
      WS.off(EVENT_TYPE.FILE_ADDED, handleAddOrUpdate);
      WS.off(EVENT_TYPE.FILE_CHANGED, handleAddOrUpdate);
      WS.off(EVENT_TYPE.FILE_REMOVED, handleDelete);
    };
  }, [enabled, client, teamId]);

  return query;
}
