import React from 'react';
import {Sx} from 'app/types/common';
import {MoreDetails} from 'app/components/MeetingsManager/MoreDetails/MoreDetails';
import {Box, Link, Stack, Typography} from '@mui/material';
import {palette} from 'app/themes/app';
import {grey} from '@mui/material/colors';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';

interface Props extends Sx {
  canSubscribe: boolean;
  onManage: Fn;
}

export const MeetingsWelcome = ({sx, canSubscribe, onManage}: Props) => {
  return (
    <Box sx={sx}>
      <Box
        sx={{
          backgroundImage: `url('/assets/img/meetings-welcome/epiphan-connect-welcome.jpeg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        borderRadius={2.5}
        px={9}
        pt={8}
        pb={10}
      >
        <Box
          mb={2}
          component="img"
          maxWidth={172}
          src="/assets/img/meetings-welcome/epiphan-connect-logo.png"
          srcSet="/assets/img/meetings-welcome/epiphan-connect-logo@2x.png 2x"
          alt=""
        />

        <Typography fontSize={32} color={palette.white} mb={2}>
          Turn any meeting into a <br />
          professional broadcast
        </Typography>

        <Typography color={palette.white} mb={2}>
          Compatible with a range of video production solutions, Epiphan
          <br />
          Connect gives you the power to extract Microsoft Teams and Zoom
          <br />
          participant feeds plus screen-sharing content, and transport them
          <br />
          into your production tools to deliver truly compelling content.
        </Typography>

        <Link
          href="https://www.epiphan.com/connect/"
          sx={{display: 'block', mb: 2}}
          data-id="learn_more_button"
          color={palette.white}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </Link>

        <Stack sx={{mb: 5}} direction="row" gap={3} alignItems="center">
          <Typography color={palette.white}>Works with:</Typography>

          <Box
            component="img"
            src="/assets/img/logo/teams-logo-white.png"
            srcSet="/assets/img/logo/teams-logo-white@2x.png 2x"
            width={173}
            height={28}
            alt=""
          />

          <Box
            component="img"
            src="/assets/img/logo/zoom-logo-white.png"
            srcSet="/assets/img/logo/zoom-logo-white@2x.png 2x"
            width={60}
            height={14}
            alt=""
          />
        </Stack>

        <Box width={410} p={5} borderRadius={2.5} bgcolor={palette.white}>
          <Typography fontSize={24} fontWeight={600} mb={2} color={palette.blue}>
            Get started with Epiphan Connect
          </Typography>

          <Box p={2} bgcolor={grey[300]} borderRadius={1}>
            <Typography mb={3}>
              {canSubscribe ? (
                <>Please subscribe to Epiphan Connect before setting up your first connection.</>
              ) : (
                <> Please contact the Epiphan Cloud team owner for subscription.</>
              )}
            </Typography>

            {canSubscribe && (
              <Button
                dataId="subscribe-btn"
                variant={VARIANT.SOLID}
                theme={THEME.INFO}
                onClick={onManage}
              >
                Subscribe
              </Button>
            )}
          </Box>
        </Box>
      </Box>

      <MoreDetails sx={{mt: 2}} />
    </Box>
  );
};
