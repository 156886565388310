const enum ApiResponseStatus {
  Ok = 'ok',
  Error = 'error',
}

/**
 * {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status}
 */
const enum ApiResponseNumeralStatus {
  Ok = 200,
  NoContent = 204,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  Conflict = 409,
  PreconditionFailed = 412,
}

enum TeamMemberStatus {
  Pending = 'pending',
  Member = 'member',
}

export {ApiResponseStatus, ApiResponseNumeralStatus, TeamMemberStatus};
