import {front} from 'app/api/CirrusApi';
import {GetAbortCallback} from 'app/api/types';

export class BillingApiService {
  static async getConnectSubscriptionReport(
    teamId: string,
    getAbort?: GetAbortCallback,
  ): Promise<Contracts.Billing.Connect.Report | undefined> {
    return front
      .teams(teamId)
      .billing()
      .v2()
      .econnect()
      .invoice()
      .history()
      .get(undefined, {getAbort});
  }

  static async getCloudAndUnifyReport(teamId: string): Promise<Contracts.Billing.Cloud.Report> {
    return front.teams(teamId).billing().v2().edge().invoice().history().get();
  }

  static getInvoicePdfUrl(teamId: string, invoiceId: string): string {
    return front.teams(teamId).billing().v2().invoice(invoiceId).pdf().url();
  }

  static async subscribeToConnect(teamId: string, planId: string, getAbort?: GetAbortCallback) {
    return front
      .teams(teamId)
      .billing()
      .v2()
      .econnect()
      .plan()
      .post({price_id: planId}, {getAbort});
  }

  static async unsubscribeFromConnect(teamId: string, getAbort?: GetAbortCallback) {
    return front.teams(teamId).billing().v2().econnect().plan().delete(undefined, {getAbort});
  }

  static async getConnectPlan(
    teamId: string,
    getAbort?: GetAbortCallback,
  ): Promise<Contracts.Billing.Connect.Plan | undefined> {
    return front.teams(teamId).billing().v2().econnect().plan().get(undefined, {getAbort});
  }

  static async resumeConnectSubscription(
    teamId: string,
    getAbort?: GetAbortCallback,
  ): Promise<void> {
    return front
      .teams(teamId)
      .billing()
      .v2()
      .econnect()
      .plan()
      .resume()
      .post(undefined, {getAbort});
  }

  static async getConnectTiers(): Promise<Contracts.Billing.Connect.Tier[]> {
    return front.billing().v2().econnect().plans().get();
  }

  static async getUnifyTiers(): Promise<Contracts.Billing.Unify.Tier[]> {
    return front.billing().v2().unify().prices().get();
  }

  static async subscribeToUnify(teamId: string) {
    return front.teams(teamId).billing().v2().unify().subscribe().post();
  }

  static async unsubscribeToUnify(teamId: string) {
    return front.teams(teamId).billing().v2().unify().unsubscribe().post();
  }

  static async getUnifyPlan(teamId: string): Promise<Contracts.Billing.Unify.Plan | undefined> {
    return front.teams(teamId).billing().v2().unify().plan().get();
  }

  static async setPayment(teamId: string, data: Contracts.Billing.SetPayment): Promise<void> {
    return front.teams(teamId).billing().plan().put(data);
  }

  static async switchToInvoice(teamId: string, data: Contracts.Billing.SwitchToInvoice) {
    return front.teams(teamId).billing().switch_to_ibb().post(data);
  }

  static async getPaymentInfo(teamId: string): Promise<Contracts.Billing.Payment> {
    return front.teams(teamId).billing().payment_info().get();
  }

  static async cancelInvoiceRequest(teamId: string): Promise<void> {
    return front.teams(teamId).billing().cancel_switch_to_ibb().post();
  }

  static async getCloudPlan(teamId: string): Promise<Contracts.Billing.Cloud.Plan> {
    return front.teams(teamId).billing().plan().get();
  }

  static async checkCoupon(
    couponCode: string,
    teamId: string,
  ): Promise<Contracts.Billing.CouponValidation> {
    return front.teams(teamId).billing().coupon().validate().post({
      id: couponCode,
    });
  }

  static async activateCloudCoupon(couponCode: string, teamId: string): Promise<void> {
    return front.teams(teamId).billing().coupon().activate().post({
      id: couponCode,
    });
  }

  static async activeConnectCoupon(coupon: string, teamId: string): Promise<void> {
    return front.teams(teamId).billing().v2().econnect().coupon().post({
      coupon_id: coupon,
    });
  }
}
