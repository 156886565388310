import {useCallback, useMemo, useReducer} from 'react';
import {produce} from 'immer';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {stringComparator} from 'app/util/Sort';
import {ReducerActionWithData} from 'app/types/common';
import {parseMasterAndChannelIds} from 'app/components/DeviceDetails/utils';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';

export function useDevicesState() {
  const [map, dispatch] = useReducer(produce(producer), new Map<string, AnyDeviceModelType>());

  const list = useMemo(
    () => Array.from(map.values()).sort((a, b) => stringComparator(a.getName(), b.getName())),
    [map],
  );

  const setDevice = useCallback((device: AnyDeviceModelType) => {
    dispatch({type: 'set-device', payload: {device}});
  }, []);

  const setMap = useCallback((map: Map<string, AnyDeviceModelType>) => {
    dispatch({type: 'set-map', payload: {map}});
  }, []);

  const getDevice = useCallback(
    (id: string) => {
      const [masterId, channelIdx] = parseMasterAndChannelIds(id);

      const master = map.get(masterId);

      if (channelIdx) {
        if (!master || !(master instanceof PearlMasterDeviceModel)) {
          return;
        }

        return master.getChanelModels().find((c) => c.getChannelDeviceIdIndex() === channelIdx);
      }

      return master;
    },
    [map],
  );

  const deleteDevice = useCallback((deviceId: string) => {
    dispatch({type: 'delete-device', payload: {deviceId}});
  }, []);

  return {list, map, get: getDevice, setDevice, setMap, deleteDevice};
}

type ActionType = 'set-device' | 'set-map' | 'delete-device';
type DeviceActionWithData<T extends ActionType, P extends any> = ReducerActionWithData<T, P>;

type SetDeviceAction = DeviceActionWithData<'set-device', {device: AnyDeviceModelType}>;
type SetMapAction = DeviceActionWithData<'set-map', {map: Map<string, AnyDeviceModelType>}>;
type DeleteDeviceAction = DeviceActionWithData<'delete-device', {deviceId: string}>;

type Action = SetDeviceAction | SetMapAction | DeleteDeviceAction;

function producer(
  draft: Map<string, AnyDeviceModelType>,
  action: Action,
): Map<string, AnyDeviceModelType> {
  switch (action.type) {
    case 'set-device': {
      const {device} = action.payload;
      const deviceId = device.getId();
      draft.set(deviceId, device);
      return draft;
    }

    case 'set-map': {
      const {map} = action.payload;
      return map;
    }

    case 'delete-device': {
      draft.delete(action.payload.deviceId);
      return draft;
    }

    default:
      return draft;
  }
}
