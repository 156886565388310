import React, {Fragment, useEffect, useState} from 'react';
import {Form} from 'app/components/Login/LoginForm/Form';
import Auth from '@aws-amplify/auth';
import {AUTH_STATE} from 'app/components/Login/LoginForm/constants';
import {Separator} from 'app/components/Login/LoginForm/Separator';
import {FORM_CONTROL_AUTOCOMPLETE} from 'app/constants/formControlAutocomplete';
import {UserNotConfirmedExceptionMessage} from 'app/components/Login/LoginForm/UserNotConfirmedExceptionMessage';
import {PseudoLink} from 'app/components/sharedReactComponents/PseudoLink';
import {Callback} from 'app/types/common';

function errorProcessor(error, username) {
  switch (error.code) {
    case 'UserNotFoundException':
      // The error happens when the supplied username/email does not exist in the Cognito user pool
      return {
        name: 'username',
        message: 'User not found',
      };
    case 'UnexpectedLambdaException':
    case 'NotAuthorizedException':
      // The error happens when the incorrect password is provided
      return {
        name: 'common',
        message: 'Incorrect username or password',
      };
    case 'PasswordResetRequiredException':
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
      return {
        name: 'common',
        message: 'Password reset required for the user',
      };
    case 'UserNotConfirmedException':
      return {
        name: 'common',
        message: <UserNotConfirmedExceptionMessage username={username}/>,
      };
    default:
      return {
        name: 'common',
        message: error.message,
      };
  }
}

function getFields(authDataProp) {
  return [
    {
      name: 'username',
      label: 'Email',
      autoComplete: FORM_CONTROL_AUTOCOMPLETE.USERNAME,
      type: 'email',
      defaultValue: authDataProp.username,
      required: true,
    },
    {
      name: 'password',
      label: 'Password',
      autoComplete: FORM_CONTROL_AUTOCOMPLETE.OFF,
      type: 'password',
      required: true,
    },
  ];
}

interface Props {
  authData: any;
  authState: AUTH_STATE;
  switchStateToForgotPassword: Callback;
  switchStateToResetPassword: Callback;
  // switchStateToSignUp: Callback;
  onSignIn: Callback;
}

export const SignIn = ({
  authState,
  authData,
  switchStateToResetPassword,
  switchStateToForgotPassword,
  onSignIn,
}: Props) => {
  const [fields, setFields] = useState(getFields(authData));

  useEffect(() => {
    setFields(getFields(authData));
  }, [authData]);

  const submitAction = async (data) => {
    return Auth.signIn(data.username, data.password)
      .then(user => {
        // The user directly signs in
        const userSession = user.getSignInUserSession();

        onSignIn({
          AccessToken: userSession.getAccessToken().getJwtToken(),
          IdToken: userSession.getIdToken().getJwtToken(),
          RefreshToken: userSession.getRefreshToken().getToken(),
        });
      })
      .catch(async error => {
        if (error.code === 'UserNotConfirmedException') {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part
        } else if (error.code === 'PasswordResetRequiredException') {
          switchStateToResetPassword(data.username);
        } else {
          console.log(error);
        }

        return Promise.reject(error);
      });
  };

  if (authState !== AUTH_STATE.SIGN_IN) {
    return null;
  }

  return (
    <Fragment>
      <Separator/>

      <Form
        fields={fields}
        buttonText="Log in"
        hintComponent={(
          <PseudoLink
            data-id="switch_to_password_reset"
            onClick={switchStateToForgotPassword}
          >
            Forgot password?
          </PseudoLink>
        )}
        errorProcessor={errorProcessor}
        submitAction={submitAction}
        // rework
        onSubmit={() => ({})}
      />
    </Fragment>
  );
};
