import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {Box, Stack, TextField, Typography} from '@mui/material';
import {Button} from 'app/components/sharedReactComponents/Button';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {palette} from 'app/themes/app';
import {Callback} from 'app/types/common';
import {isNil} from 'app/util/isNil';

interface Props {
  value?: number;
  seats: number;
  min: number;
  max: number;
  rate: number;
  interval: string;
  showDiff: boolean;
  setValue: Callback<void, [value?: number]>;
  decrease: Callback;
  increase: Callback;
}

export const SeatsPicker = ({
  value,
  seats,
  min,
  max,
  interval,
  rate,
  showDiff,
  setValue,
  increase,
  decrease,
}: Props) => {
  const noValue = isNil(value);
  const hasValue = !noValue;
  const inRange = hasValue && value >= min && value <= max;
  const disabledMinus = noValue || value <= min;
  const disabledPlus = noValue || value >= max;

  const [validValue, setValidValue] = useState(() => {
    if (inRange) {
      return value;
    }

    return seats < min ? min : seats;
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = parseInt(e.target.value, 10);

    if (Number.isNaN(input)) {
      setValue(undefined);
      return;
    }

    if (input > max) {
      return;
    }

    setValue(input);
  };

  useEffect(() => {
    if (inRange) {
      setValidValue(value);
    }
  }, [value, inRange]);

  const handleBlur = () => {
    if (!inRange) {
      setValue(validValue);
    }
  };

  const diff = value ? value - seats : 0;

  const showChange = showDiff && diff !== 0 && inRange;

  return (
    <Box className="seats-picker">
      <Typography fontSize="12px" mb={1}>
        Select number of device units
      </Typography>
      <Box sx={{height: '70px'}}>
        <Stack direction="row" alignItems="center" justifyContent="center" gap={0.5}>
          <Tooltip
            content={disabledMinus && min > 1 ? 'Unpair a device before decreasing' : undefined}
          >
            <span>
              <Button
                className={classNames(
                  'seats-picker__minus',
                  disabledMinus && 'seats-picker__minus--disabled',
                )}
                dataId="devices_picker_minus"
                disabled={disabledMinus}
                onClick={decrease}
              >
                −
              </Button>
            </span>
          </Tooltip>

          <TextField
            data-id="devices_picker_input"
            sx={{width: '60px', borderColor: palette.grayMedium, bgcolor: 'white'}}
            variant="standard"
            value={value ?? ''}
            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
            onChange={handleInput}
            onBlur={handleBlur}
          />

          <Button
            className={classNames(
              'seats-picker__plus',
              disabledPlus && 'seats-picker__plus--disabled',
            )}
            dataId="devices_picker_plus"
            disabled={disabledPlus}
            onClick={increase}
          >
            +
          </Button>
        </Stack>

        {showChange && (
          <Typography fontSize="12px">
            {diff > 0 && '+'}
            <span data-id="seats-diff">{diff}</span> device {pluralizeN('unit', Math.abs(diff))}
          </Typography>
        )}
      </Box>

      {inRange && (
        <Typography data-id="devices_picker_total" fontSize="16px">
          Total: <span className="seats-picker__total-price"> ${value * rate}</span> / {interval}
        </Typography>
      )}
    </Box>
  );
};
