import {useEffect} from 'react';
import {secondsToMilliseconds} from 'app/util/timeConverter';
import {Callback} from 'app/types/common';
import {createStampedImageSrc} from 'app/components/sharedReactComponents/StatusImage/utils';

const UPDATING_INTERVAL = secondsToMilliseconds(10);

interface Params {
  initialImageSrc: string;
  disabled?: boolean;
  immediately?: boolean;
  setImageSrc: Callback;
}

export function useUpdatePreviewByInterval({
  initialImageSrc,
  disabled,
  immediately,
  setImageSrc,
}: Params) {
  useEffect(() => {
    if (disabled) {
      return;
    }

    let imageUpdatingTimeout = 0;

    function action() {
      setImageSrc(createStampedImageSrc(initialImageSrc));
    }

    function start() {
      imageUpdatingTimeout = window.setTimeout(() => {
        action();
        start();
      }, UPDATING_INTERVAL);
    }

    if (immediately === true) {
      action();
    }

    start();

    return () => {
      window.clearTimeout(imageUpdatingTimeout);
    };
  }, [initialImageSrc, disabled, immediately, setImageSrc]);
}
