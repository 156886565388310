import React, {useState} from 'react';
import {ClassName, SelectOption} from 'app/types/common';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {StreamSetting} from 'app/components/pages/MeetingDetails/StreamSettings/common/StreamSetting/StreamSetting';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {BitrateInput} from 'app/components/pages/MeetingDetails/StreamSettings/StreamVideoSettings/BitrateInput/BitrateInput';
import {useStrSettingsForm} from 'app/components/pages/MeetingDetails/StreamSettings/SrtSettingsFormContext';
import {SrtSettingsCallback} from 'app/components/pages/MeetingDetails/StreamSettings/types';
import {FramerateInput} from 'app/components/pages/MeetingDetails/StreamSettings/StreamVideoSettings/FramerateInput/FramerateInput';
import {getValidNumber} from 'app/components/pages/MeetingDetails/StreamSettings/utils';

const presets = ['hd720', 'hd1080'] as const;
const configs = [...presets, 'custom'] as const;

type Config = typeof configs[number];

type Resolution = SelectOption<App.Connect.VideoSize>;
type Configuration = SelectOption<Config>;

type Presets = Exclude<Config, 'custom'>;
type PresetSettings = {[K in Presets]: App.Connect.VideoSettings};

const Resolutions: Resolution[] = [
  {
    value: 'HDTV_1080',
    label: '1920x1080',
  },
  {
    value: 'HDTV_720',
    label: '1280x720',
  },
];

export const Settings: PresetSettings = {
  hd1080: {
    size: 'HDTV_1080',
    framerate: 30,
    bitrate: 6000,
  },
  hd720: {
    size: 'HDTV_720',
    framerate: 30,
    bitrate: 2500,
  },
};

const Configs: Configuration[] = [
  {
    label: 'Full HD',
    value: 'hd1080',
  },
  {
    label: 'HD',
    value: 'hd720',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];

interface Props extends ClassName, SrtSettingsCallback {
  settings: App.Connect.ParticipantStreamSettings;
}

const StreamVideoSettingsComponent = ({className, settings, onSync: sync}: Props) => {
  const {errors, setSettings} = useStrSettingsForm();

  const [config, setConfig] = useState<Config>(() => getMatchedConfig(settings.video));

  const handleConfigChange = async (e: SelectChangeEvent<Config>) => {
    const config = e.target.value as Config;
    setConfig(config);

    if (config !== 'custom') {
      const settings = getPredefinedSettingsSet(config);
      setSettings({video: settings});
      await sync();
    }
  };

  const handleSizeChange = async (e: SelectChangeEvent<App.Connect.VideoSize>) => {
    const size = e.target.value as App.Connect.VideoSize;
    setSettings({
      video: {
        ...settings.video,
        size,
      },
    });
    await sync();
  };

  const handleFramerateChange = async (value: string) => {
    const framerate = getValidNumber(value, 60, settings.video.framerate);
    setSettings({
      video: {
        ...settings.video,
        framerate,
      },
    });
    await sync();
  };

  const handleBitrateChange = (value: string) => {
    const bitrate = getValidNumber(value, 100000, settings.video.bitrate);

    setSettings({
      video: {
        ...settings.video,
        bitrate,
      },
    });
  };

  const summary = formatSummary(settings.video);
  const configurable = config === 'custom';

  return (
    <div className={classNames('stream-video-settings', className)}>
      <StreamSetting className="stream-video-settings__quality" label="Video quality">
        <FormControl fullWidth={true}>
          <Select data-id="srt_video_selector" value={config} onChange={handleConfigChange}>
            {Configs.map((c) => (
              <MenuItem key={c.value} value={c.value}>
                {c.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StreamSetting>

      {configurable ? (
        <>
          <div className="stream-video-settings__custom">
            <StreamSetting label="Frame size">
              <FormControl fullWidth={true}>
                <Select
                  data-id="srt_video_frame_size"
                  value={settings.video.size}
                  onChange={handleSizeChange}
                >
                  {Resolutions.map((r) => (
                    <MenuItem key={r.value} value={r.value}>
                      {r.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StreamSetting>

            <StreamSetting label="Frame rate">
              <FramerateInput
                dataId="srt_video_framerate"
                value={settings.video.framerate ? settings.video.framerate.toString() : ''}
                error={errors?.VideoFramerate}
                onChange={handleFramerateChange}
                onBlur={sync}
              />
            </StreamSetting>

            <StreamSetting label="Video bitrate">
              <BitrateInput
                dataId="srt_video_bitrate"
                value={settings.video.bitrate ? settings.video.bitrate.toString() : ''}
                error={errors?.VideoBitrate}
                onChange={handleBitrateChange}
                onBlur={sync}
              />
            </StreamSetting>
          </div>
        </>
      ) : (
        <StreamSetting label={summary} dataId="srt_video_summary" />
      )}

      <div className="stream-video-settings__warning">
        {Icons.info().size(SIZE.S).reactComponent()}

        <span className="stream-video-settings__message">
          The video will be scaled if the source is of a different frame size
        </span>
      </div>
    </div>
  );
};

export const StreamVideoSettings = observer(StreamVideoSettingsComponent);

function getPredefinedSettingsSet(config: Presets): App.Connect.VideoSettings {
  return Settings[config];
}

function matchPredefinedSet(config: Presets, settings: App.Connect.VideoSettings): boolean {
  const {bitrate, framerate, size} = settings;

  const set = getPredefinedSettingsSet(config);
  return bitrate === set.bitrate && framerate === set.framerate && size === set.size;
}

function getMatchedConfig(settings: App.Connect.VideoSettings): Config {
  if (matchPredefinedSet('hd1080', settings)) {
    return 'hd1080';
  }

  if (matchPredefinedSet('hd720', settings)) {
    return 'hd720';
  }

  return 'custom';
}

function formatSummary(settings: App.Connect.VideoSettings) {
  const size = Resolutions.find((r) => settings.size === r.value)?.label ?? '';
  const fps = `${settings.framerate} fps`;
  const bitrate = `Video bitrate: ${settings.bitrate} kbits`;

  return `${size} | ${fps} | ${bitrate}`;
}
