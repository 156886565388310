import React from 'react';
import classNames from 'classnames';
import {AccountInfo} from 'app/components/sharedReactComponents/StreamingDestinationSettings/AccountInfo';
import {Footer} from 'app/components/sharedReactComponents/StreamingDestinationSettings/Footer';
import {
  FACEBOOK_STREAMING_DESTINATION,
  FACEBOOK_STREAMING_PRIVACY,
} from 'app/components/StreamingServices/constants';
import {StreamingDestinationSettingsForm} from 'app/components/sharedReactComponents/StreamingDestinationSettings/StreamingDestinationSettingsForm';
import {FORM_CONTROL_TYPE} from 'app/components/sharedReactComponents/FormControl/constants';
import {DestinationSelect} from 'app/components/sharedReactComponents/StreamingDestinationSettings/DestinationSelect';
import {Callback, ClassName} from 'app/types/common';
import {StreamingDestinationControl} from 'app/components/sharedReactComponents/StreamingDestinationSettings/types';

const destinationOptions = [
  {
    label: 'Timeline',
    value: FACEBOOK_STREAMING_DESTINATION.TIMELINE,
  },
  {
    label: 'Page',
    value: FACEBOOK_STREAMING_DESTINATION.PAGES,
  },
  {
    label: 'Group',
    value: FACEBOOK_STREAMING_DESTINATION.GROUPS,
  },
];

const privacyOptions = [
  {
    label: 'Public',
    value: FACEBOOK_STREAMING_PRIVACY.PUBLIC,
  },
  {
    label: 'Friends',
    value: FACEBOOK_STREAMING_PRIVACY.FRIENDS,
  },
  {
    label: 'Only Me',
    value: FACEBOOK_STREAMING_PRIVACY.ONLY_ME,
  },
];

interface Props extends ClassName {
  streamingDestination: any;
  updating: boolean;
  deleting?: boolean;
  disabled: boolean;
  onChange: Callback;
  onClickDelete: Callback;
}

export const FacebookStreamingDestinationSettings = ({
  className,
  streamingDestination,
  updating,
  deleting,
  disabled,
  onChange,
  onClickDelete,
}: Props) => {
  const getProcessedFormData = () => ({
    title: streamingDestination.getTitleSetting(),
    description: streamingDestination.getDescriptionSetting(),
    publishDestination: streamingDestination.getDestinationSetting(),
    publishDestinationID: streamingDestination.getDestinationIDSetting(),
    privacy: streamingDestination.getPrivacySetting(),
    continuousStream: streamingDestination.isContinuous(),
    deletePostAfterStreaming: streamingDestination.isDeletingPostAfterStop(),
  });

  const handleSaveForm = (formData) => {
    onChange(formData);
  };

  const formData = getProcessedFormData();

  const controls: StreamingDestinationControl[] = [
    {
      label: 'Facebook account',
      value: streamingDestination,
      componentRenderer: ({value}) => <AccountInfo streamingDestination={value}/>
    },
    {
      label: 'Facebook streaming destination',
      name: 'publishDestination',
      type: FORM_CONTROL_TYPE.SELECT,
      selectComponentProps: {
        options: destinationOptions,
      },
      resetOnChange: () => {
        return {
          publishDestinationID: undefined,
        };
      },
    },
    {
      name: 'publishDestinationID',
      componentRenderer: (props, {publishDestination}) => (
        <DestinationSelect
          {...props}
          data-id="streaming_destination_select_destination_input"
          publishDestination={publishDestination}
          streamingDestinationId={streamingDestination.getId()}
        />
      ),
      isVisible: ({publishDestination}) => (
        publishDestination === FACEBOOK_STREAMING_DESTINATION.PAGES ||
          publishDestination === FACEBOOK_STREAMING_DESTINATION.GROUPS
      ),
    },
    {
      label: 'Title',
      name: 'title',
    },
    {
      label: 'Description',
      name: 'description',
      type: FORM_CONTROL_TYPE.TEXTAREA,
    },
    {
      label: 'Privacy',
      name: 'privacy',
      type: FORM_CONTROL_TYPE.SELECT,
      selectComponentProps: {
        options: privacyOptions,
      },
      isVisible: ({publishDestination}) => publishDestination === FACEBOOK_STREAMING_DESTINATION.TIMELINE,
    },
    {
      label: 'Continuous stream',
      name: 'continuousStream',
      type: FORM_CONTROL_TYPE.CHECKBOX,
    },
    {
      label: 'Delete post after stream? (Continuous only)',
      name: 'deletePostAfterStreaming',
      type: FORM_CONTROL_TYPE.CHECKBOX,
    },
  ];

  return (
    <div className={classNames('streaming-destination-settings', className)}>
      <StreamingDestinationSettingsForm
        formData={formData}
        controls={controls}
        disabled={disabled}
        loading={updating}
        onSave={handleSaveForm}
      />

      <Footer
        deleteButtonText="Forget this destination"
        deleting={deleting}
        disabled={disabled}
        onClickDelete={onClickDelete}
      />
    </div>
  );
};
