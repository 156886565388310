import {z} from 'zod';
import dayjs from 'dayjs';

export const UntilScheme = z.object({
  type: z.enum(['never', 'date', 'occurrences']),
  date: z.number(),
  occurrences: z.number(),
});

export const recurrenceType = z.enum(['daily', 'weekly']);

export const RecurrenceScheme = z.object({
  type: recurrenceType,
  until: UntilScheme,
});

export const EventScheme = z
  .object({
    title: z.string().min(1, {message: 'Required field'}),
    start: z
      .number()
      .min(1, {message: 'Required field'})
      .refine(
        (value) => {
          return dayjs.unix(value).isAfter(dayjs());
        },
        {message: "Can't be past date or time"},
      ),
    end: z.number().min(1, {message: 'Required field'}),
    timezone: z.string().min(1, {message: 'Required field'}),
    recurrent: z.boolean(),
    recurrence: RecurrenceScheme,
  })
  .superRefine((val, ctx) => {
    const {
      start,
      end,
      recurrence: {until},
    } = val;

    if (dayjs.unix(end).subtract(1, 'minute').unix() < start) {
      ctx.addIssue({
        code: 'custom',
        message: "Can't be equal or less than start",
        path: ['end'],
      });
    }

    if (until.type === 'date') {
      if (until.date === 0) {
        ctx.addIssue({
          code: 'custom',
          message: 'Required field',
          path: ['recurrence.until.date'],
        });
      }

      if (dayjs.unix(until.date).isBefore(dayjs(), 'day')) {
        ctx.addIssue({
          code: 'custom',
          message: "Can't be past date",
          path: ['recurrence.until.date'],
        });
      }

      if (end > 0 && dayjs.unix(until.date).isBefore(dayjs.unix(end), 'day')) {
        ctx.addIssue({
          code: 'custom',
          message: "Can't be less that end",
          path: ['recurrence.until.date'],
        });
      }
    }

    if (until.type === 'occurrences') {
      if (until.occurrences < 0) {
        ctx.addIssue({
          code: 'custom',
          message: 'Min: 1',
          path: ['recurrence.until.occurrences'],
        });
      }
    }
  });
