import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt, StyleSx} from 'app/types/common';
import {useMounted} from 'app/hooks/useIsMounted';
import {DangerouslyHTML} from 'app/components/sharedReactComponents/DangerouslyHTML';
import {UserApiService} from 'app/services/api/user/UserApiService';

const contentSx: StyleSx = {maxHeight: '50vh', overflow: 'auto', px: 3, py: 1};

interface Props extends Prompt {
  id: string;
  time: number;
  content: string;
}

export function AgreementDialog({open, id, time, content, onClose}: Props) {
  const [loading, setLoading] = useState(false);

  const mounted = useMounted();

  const handleAccept = async () => {
    setLoading(true);

    await UserApiService.acceptAgreement(id, time);

    if (mounted()) {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle variant="h4">Epiphan Terms of Service</DialogTitle>

      <DialogContent>
        <DangerouslyHTML sx={contentSx} content={content} />
      </DialogContent>

      <DialogActions>
        <LoadingButton
          variant="contained"
          color="secondary"
          loading={loading}
          onClick={handleAccept}
        >
          Accept
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
