import React from 'react';
import TodayIcon from '@mui/icons-material/Today';
import {Sx} from 'app/types/common';
import {EventCategory} from 'app/components/sharedReactComponents/Events/types';

interface Props extends Sx {
  type?: EventCategory;
}

export function EventIcon({sx, type}: Props) {
  const color = getColor(type);
  return <TodayIcon sx={sx} color={color} />;
}

function getColor(category?: EventCategory) {
  if (category === 'ongoing') {
    return 'error';
  }

  if (category === 'scheduled') {
    return 'primary';
  }

  return 'info';
}
