import {BaseTeamModel, BaseTeamModelProps} from 'app/models/Team/BaseTeamModel';
import {TeamCapability} from 'app/components/Team/constants';
import {TeamCapabilities} from 'app/models/Capabilities/TeamCapabilities';

export interface TeamDetailsModelProps extends BaseTeamModelProps {
  capabilities: TeamCapability[];
  currentTierId: string;
  billingEmail: string;
}

export class TeamDetailsModel extends BaseTeamModel {
  readonly currentTierId: string;
  readonly billingEmail: string;
  readonly capabilitiesRaw: TeamCapability[];
  // TODO Add TeamCapabilities type after migration it to ts
  readonly capabilities: TeamCapabilities;

  constructor({id, name, capabilities, currentTierId, billingEmail}: TeamDetailsModelProps) {
    super({
      id,
      name,
    });

    this.currentTierId = currentTierId;
    this.billingEmail = billingEmail;
    this.capabilitiesRaw = capabilities;
    this.capabilities = new TeamCapabilities({
      capabilities,
    });
  }
}
