import {useEffect, useState} from 'react';
import {front} from 'app/api/CirrusApi';
import {TranscribingStatus} from 'app/components/DeviceDetails/constants';
import {noop} from 'app/util/noop';
import {AbortCallback} from 'app/api/types';

export function useDeviceTranscriptionTime({
  deviceId,
  sessionId,
  status,
}) {
  // rework typing
  const [startTime, setStartTime] = useState<any>(null);
  const [receivedTime, setReceivedTime] = useState(0);

  useEffect(() => {
    if (status === TranscribingStatus.Stopped) {
      setStartTime(null);
      setReceivedTime(0);

      return;
    }

    let abortRequest: AbortCallback = noop;

    front.transcribing(deviceId).sessions(sessionId).get(undefined, {getAbort: abort => abortRequest = abort})
      .then(response => {
        const {data} = response;

        if (!data) {
          setStartTime(null);
          setReceivedTime(0);

          return;
        }

        const secondsReceived = data.received.seconds || 0;

        if (status === TranscribingStatus.Paused) {
          setStartTime(null);
        } else {
          const currentTime = Date.now() / 1000;
          const responseTime = data.current_ts;
          const timeCorrection = currentTime - responseTime;
          setStartTime(currentTime + timeCorrection);
        }

        setReceivedTime(secondsReceived);
      });

    return () => abortRequest();
  }, [status, deviceId, sessionId]);

  return {
    startTime,
    receivedTime,
  };
}
