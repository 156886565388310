import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import {EditTeamPaymentDialog} from 'app/components/BillingManager/dialogs/EditTeamPaymentDialog/EditTeamPaymentDialog';
import {isDiscountCoupon} from 'app/components/BillingManager/Coupon';
import {CouponState, useCoupon} from 'app/components/BillingManager/hooks/useCoupon';
import {PaymentPayload} from 'app/components/sharedReactComponents/PaymentForm/PaymentForm';
import {BillingApiService} from 'app/services/api/billing/BillingApiService';
import {isNil} from 'app/util/isNil';
import {Callback} from 'app/types/common';
import {useCurrentTeamStore} from 'app/store/hooks';
import {InitializationPage} from 'app/components/InitializationPage/InitializationPage';
import {EnterCouponDialog} from 'app/components/BillingManager/dialogs/EnterCouponDialog/EnterCouponDialog';
import {EdgeTiersDialog} from 'app/components/BillingManager/dialogs/CloudTiersDialog/CloudTiersDialog';
import {usePairedQuery} from 'app/hooks/usePairedQuery';
import {CloudConfirmDialog} from 'app/components/BillingManager/dialogs/CloudConfirmDialog/CloudConfirmDialog';
import {ConnectTiersDialog} from 'app/components/BillingManager/dialogs/ConnectTiersDialog/ConnectTiersDialog';
import {ConnectConfirmDialog} from 'app/components/BillingManager/dialogs/ConnectConfirmDialog/ConnectConfirmDialog';
import {CancelInvoiceRequestDialog} from 'app/components/BillingManager/dialogs/CancelInvoiceRequestDialog/CancelInvoiceRequestDialog';
import {useBillingProcess} from 'app/components/BillingManager/hooks/useBillingProcess';
import {useConnectPlan} from 'app/components/BillingManager/hooks/useConnectPlan';
import {useCloudPlan} from 'app/components/BillingManager/hooks/useCloudPlan';
import {usePayment} from 'app/components/BillingManager/hooks/usePayment';
import {useConnectTiers} from 'app/components/BillingManager/hooks/useConnectTiers';
import {
  BillingState,
  ConnectArgs,
  ProcessActions,
  UnifyArgs,
} from 'app/components/BillingManager/types/types';
import {BillingManagerContext} from 'app/components/BillingManager/context';
import {UnifyTiersDialog} from 'app/components/BillingManager/dialogs/UnifyTiersDialog/UnifyTiersDialog';
import {useUnifyTiers} from 'app/components/BillingManager/hooks/useUnifyTiers';
import {useUnifyPlan} from 'app/components/BillingManager/hooks/useUnifyPlan';
import {UnifyConfirmDialog} from 'app/components/BillingManager/dialogs/UnifyConfirmDialog/UnifyConfirmDialog';
import {MainRoutes} from 'app/router/main';
import {useMounted} from 'app/hooks/useIsMounted';
import {Notifications} from 'app/components/Notifications';
import {useCloudEntities} from 'app/components/entities/cloud';

export interface SelectedCloudTier {
  id: string;
  seats: number;
}

interface Props {
  route: MainRoutes;
  refetchTeam: Callback<void>;
}

export const BillingManager = observer(
  ({children, route, refetchTeam}: React.PropsWithChildren<Props>) => {
    const mounted = useMounted();

    const team = useCurrentTeamStore();
    const teamId = team.id;

    const {user} = useCloudEntities();

    const [state, dispatch] = useBillingProcess();

    const {view} = state;

    const [selectedCloudId, setSelectedCloudId] = useState<string | undefined>();
    const [selectedSeats, setSelectedSeats] = useState<number | undefined>();

    const [connectArgs, setConnectArgs] = useState<ConnectArgs | undefined>();
    const [unifyArgs, setUnifyArgs] = useState<UnifyArgs | undefined>();

    const {coupon, setCoupon, validateCoupon} = useCoupon({teamId});

    const hasConnect = team.getCapabilities().connect();
    const canEditBilling = user.role.canEditBilling();

    const cloudQuery = useCloudPlan(teamId, true);
    const connectQuery = useConnectPlan(teamId, true);
    const unifyQuery = useUnifyPlan({teamId, enabled: true});

    const paymentQuery = usePayment(teamId, canEditBilling);

    const pairedQuery = usePairedQuery({teamId});

    const connectTiersQuery = useConnectTiers(view === 'connect-tiers');
    const unifyTiersQuery = useUnifyTiers({enabled: view === 'unify-tiers', teamId});

    const cloud = cloudQuery.data;
    const connect = connectQuery.data;
    const unify = unifyQuery.data;

    const payment = paymentQuery.data;

    const counts = pairedQuery.data;

    const isPaymentSet = payment?.hasMethod ?? false;

    const unifyTiers = useMemo(() => {
      const tiers = unifyTiersQuery.data ?? [];

      return tiers.filter((t) => (hasConnect ? t.bundle : !t.bundle));
    }, [unifyTiersQuery.data, hasConnect]);

    const handleClean = useCallback(() => {
      setCoupon('');
      setSelectedCloudId(undefined);
      setSelectedSeats(undefined);
      setConnectArgs(undefined);
      setUnifyArgs(undefined);
    }, [setCoupon]);

    const handleClose = useCallback(() => {
      dispatch({type: 'close'});
      handleClean();
    }, [handleClean, dispatch]);

    useEffect(() => {
      if (mounted()) {
        handleClose();
      }
    }, [route, mounted, handleClose]);

    const refetchCloud = cloudQuery.refetch;
    const refetchConnect = connectQuery.refetch;
    const refetchPayment = paymentQuery.refetch;
    const refetchUnify = unifyQuery.refetch;

    const editPayment = useCallback(() => {
      dispatch({type: 'edit-payment'});
    }, [dispatch]);

    const confirmPayment = useCallback(
      async (payload: PaymentPayload) => {
        if (!canEditBilling) {
          return;
        }

        try {
          const code = !isNil(coupon.instance) ? coupon.value : undefined;

          if (payload.method === 'card') {
            const {ccToken, billingEmail} = payload;

            await BillingApiService.setPayment(teamId, {
              plan: CIRRUS_STRIPE_DEFAULT_PLAN_ID,
              cc_token: ccToken,
              billing_email: billingEmail,
              coupon_code: code,
            });
          } else if (payload.method === 'invoice coupon') {
            const {billingEmail} = payload;
            await BillingApiService.setPayment(teamId, {
              plan: CIRRUS_STRIPE_DEFAULT_PLAN_ID,
              billing_email: billingEmail,
              coupon_code: code,
            });
          } else {
            await BillingApiService.switchToInvoice(teamId, payload);
          }
        } catch (e) {
          console.error('Could not save billing info', e);
          throw e;
        }

        await Promise.allSettled([
          refetchTeam(),
          refetchPayment(),
          refetchCloud(),
          coupon.instance?.domain === 'connect' ? refetchConnect() : Promise.resolve(),
        ]);

        dispatch({type: 'save-payment'});
        setCoupon('');
      },
      [
        coupon.instance,
        coupon.value,
        teamId,
        canEditBilling,
        refetchTeam,
        refetchPayment,
        refetchCloud,
        setCoupon,
        refetchConnect,
        dispatch,
      ],
    );

    // #region Cloud actions
    const selectCloudTier = useCallback(
      (tier: string) => {
        setSelectedCloudId(tier);
        dispatch({type: 'confirm-cloud'});
      },
      [dispatch],
    );

    const confirmCloudTier = useCallback(async () => {
      if (!canEditBilling || isNil(selectedCloudId) || !cloud) {
        return;
      }

      await BillingApiService.setPayment(teamId, {
        plan: cloud.id,
        tier_id: selectedCloudId,
        seat_count: selectedCloudId !== '' ? selectedSeats : undefined,
      });

      await Promise.allSettled([refetchCloud(), refetchTeam()]);

      handleClose();
    }, [
      canEditBilling,
      teamId,
      selectedCloudId,
      selectedSeats,
      cloud,
      handleClose,
      refetchTeam,
      refetchCloud,
    ]);

    const cancelCloudTier = useCallback(() => {
      dispatch({type: 'cloud-tiers'});
    }, [dispatch]);

    // #endregion

    // #region Connect actions

    const selectConnectTier = useCallback(
      (args: ConnectArgs) => {
        setConnectArgs(args);
        dispatch({type: 'confirm-connect'});
      },
      [dispatch],
    );

    const confirmConnectTier = useCallback(async () => {
      if (!canEditBilling || isNil(connectArgs)) {
        return;
      }

      try {
        if (connectArgs.action === 'unsubscribe') {
          await BillingApiService.unsubscribeFromConnect(teamId);
        } else if (connectArgs.action === 'resume') {
          await BillingApiService.resumeConnectSubscription(teamId);
        } else {
          await BillingApiService.subscribeToConnect(teamId, connectArgs.tier.id);
        }
      } catch (e) {
        console.error('Could not subscribe to connect', e);
        throw e;
      }
      await Promise.allSettled([refetchTeam(), refetchConnect(), refetchUnify()]);
      handleClose();
    }, [
      canEditBilling,
      connectArgs,
      teamId,
      refetchTeam,
      refetchConnect,
      refetchUnify,
      handleClose,
    ]);

    const cancelConnectTier = useCallback(() => {
      dispatch({type: 'connect-tiers'});
      setConnectArgs(undefined);
    }, [dispatch]);

    // #endregion

    // #region Unify actions

    const selectUnifyTier = useCallback(
      async (args: UnifyArgs) => {
        setUnifyArgs(args);
        dispatch({type: 'confirm-unify'});
      },
      [dispatch],
    );

    const confirmUnifyTier = useCallback(async () => {
      if (!canEditBilling || isNil(unifyArgs)) {
        return;
      }

      if (unifyArgs.action === 'subscribe') {
        await BillingApiService.subscribeToUnify(teamId);
        Notifications.addSuccessNotification(
          <>
            <b>All set.</b> The new Unify billing plan is set and active
          </>,
        );
      } else {
        await BillingApiService.unsubscribeToUnify(teamId);
      }

      await Promise.allSettled([refetchTeam(), refetchUnify()]);
      handleClose();
    }, [canEditBilling, unifyArgs, teamId, handleClose, refetchTeam, refetchUnify]);

    const cancelUnifyTier = useCallback(async () => {
      dispatch({type: 'unify-tiers'});
    }, [dispatch]);

    // #endregion

    const cancelPendingInvoice = useCallback(async () => {
      if (!canEditBilling) {
        return;
      }

      try {
        await BillingApiService.cancelInvoiceRequest(teamId);
      } catch (e) {
        console.error('Could not cancel pending invoice', e);
        throw e;
      }

      await Promise.allSettled([refetchTeam(), refetchPayment()]);
      handleClose();
    }, [canEditBilling, teamId, handleClose, refetchTeam, refetchPayment]);

    const activateCoupon = useCallback(async () => {
      if (!coupon.instance || !canEditBilling) {
        return;
      }

      try {
        await onActivateCoupon(coupon, teamId);
      } catch (e) {
        console.error('Could not activate voucher', e);
        throw e;
      }

      await Promise.allSettled([refetchTeam(), refetchPayment(), refetchCloud(), refetchConnect()]);
    }, [coupon, teamId, canEditBilling, refetchTeam, refetchPayment, refetchCloud, refetchConnect]);

    const loading = paymentQuery.isInitialLoading;

    // #region Process actions

    const manageCloud = useCallback(() => {
      if (!canEditBilling) {
        return;
      }

      dispatch({
        type: 'run-process',
        payload: {process: 'cloud', view: 'cloud-tiers'},
      });
    }, [canEditBilling, dispatch]);

    const manageConnect = useCallback(() => {
      if (!canEditBilling) {
        return;
      }

      dispatch({type: 'run-process', payload: {process: 'connect', view: 'connect-tiers'}});
    }, [canEditBilling, dispatch]);

    const manageUnify = useCallback(() => {
      if (!canEditBilling) {
        return;
      }

      dispatch({type: 'run-process', payload: {process: 'unify', view: 'unify-tiers'}});
    }, [canEditBilling, dispatch]);

    const managePayment = useCallback(
      (coupon: string = '') => {
        if (!canEditBilling) {
          return;
        }

        setCoupon(coupon);

        if (coupon) {
          void validateCoupon(coupon);
        }

        dispatch({type: 'run-process', payload: {process: 'payment', view: 'payment'}});
      },
      [canEditBilling, setCoupon, validateCoupon, dispatch],
    );

    const enterCoupon = useCallback(
      (coupon: string = '') => {
        if (!isPaymentSet) {
          managePayment(coupon);
          return;
        }

        if (!canEditBilling) {
          return;
        }

        setCoupon(coupon);

        if (coupon) {
          void validateCoupon(coupon);
        }

        dispatch({
          type: 'run-process',
          payload: {process: 'coupon', view: 'enter-coupon'},
        });
      },
      [canEditBilling, isPaymentSet, managePayment, validateCoupon, setCoupon, dispatch],
    );

    const cancelInvoiceRequest = useCallback(() => {
      if (!canEditBilling) {
        return;
      }

      dispatch({
        type: 'run-process',
        payload: {process: 'pending-invoice', view: 'cancel-pending-invoice'},
      });
    }, [canEditBilling, dispatch]);

    // #endregion

    // #region Context

    const actions = useMemo<ProcessActions>(
      () => ({
        managePayment,
        enterCoupon,
        manageCloud,
        manageConnect,
        manageUnify,
        cancelInvoiceRequest,
      }),
      [managePayment, enterCoupon, manageCloud, manageConnect, cancelInvoiceRequest, manageUnify],
    );

    const context = useMemo<BillingState | undefined>(() => {
      if (isNil(cloud)) {
        return undefined;
      }

      return {
        cloud,
        connect,
        unify,
        payment,
        actions,
      };
    }, [payment, actions, cloud, connect, unify]);

    // #endregion

    if (loading || isNil(context)) {
      return <InitializationPage />;
    }

    return (
      <BillingManagerContext.Provider value={context}>
        <EditTeamPaymentDialog
          open={view === 'payment'}
          coupon={coupon}
          email={team.getBillingEmail()}
          hasConnect={hasConnect}
          mode={isPaymentSet ? 'edit' : 'create'}
          showMessage={false}
          setCoupon={setCoupon}
          onConfirm={confirmPayment}
          validateCoupon={validateCoupon}
          onClose={handleClose}
        />

        {payment?.method && (
          <EnterCouponDialog
            open={view === 'enter-coupon'}
            coupon={coupon}
            hasConnect={hasConnect}
            method={payment.method}
            onCouponChange={setCoupon}
            onActivate={activateCoupon}
            onValidate={validateCoupon}
            onClose={handleClose}
          />
        )}

        {cloud && counts && (
          <EdgeTiersDialog
            open={view === 'cloud-tiers'}
            currentTier={cloud.tier.id}
            tiers={cloud.list}
            seatCount={selectedSeats}
            seatPrice={cloud.prices.seat}
            interval={cloud.interval.dimension}
            configured={cloud.configured}
            paired={counts.devices}
            hasMethod={payment?.hasMethod ?? false}
            onSelectTier={selectCloudTier}
            onConfigureSeats={setSelectedSeats}
            onEditPayment={editPayment}
            onEnterCoupon={enterCoupon}
            onClose={handleClose}
          />
        )}

        {cloud && !isNil(selectedCloudId) && (
          <CloudConfirmDialog
            open={view === 'confirm-cloud'}
            interval={cloud.interval.dimension}
            currentId={cloud.tier.id}
            selectedId={selectedCloudId}
            seats={selectedSeats}
            capacity={cloud.tier.seats}
            seatPrice={cloud.prices.seat}
            onSubmit={confirmCloudTier}
            onCancel={cancelCloudTier}
            onClose={handleClose}
          />
        )}

        <ConnectTiersDialog
          open={view === 'connect-tiers'}
          currentId={connect?.id}
          custom={connect?.enterprise ?? false}
          hasCustomer={payment?.hasCustomer ?? false}
          loading={connectTiersQuery.isInitialLoading}
          tiers={connectTiersQuery.data ?? []}
          resumable={connect?.resumable ?? false}
          onSelectTier={selectConnectTier}
          onEditPayment={editPayment}
          onClose={handleClose}
        />

        {connectArgs && (
          <ConnectConfirmDialog
            open={view === 'confirm-connect'}
            args={connectArgs}
            endDate={connect?.period.end ?? 0}
            onConfirm={confirmConnectTier}
            onCancel={cancelConnectTier}
            onClose={handleClose}
          />
        )}

        <UnifyTiersDialog
          open={view === 'unify-tiers'}
          currentId={unify?.id}
          custom={unify?.enterprise ?? false}
          hasCustomer={payment?.hasCustomer ?? false}
          tiers={unifyTiers}
          loading={unifyTiersQuery.isInitialLoading}
          onSelectTier={selectUnifyTier}
          onEditPayment={editPayment}
          onClose={handleClose}
        />

        {unifyArgs && (
          <UnifyConfirmDialog
            open={view === 'confirm-unify'}
            args={unifyArgs}
            endDate={unify?.period.end ?? 0}
            onClose={handleClose}
            onConfirm={confirmUnifyTier}
            onCancel={cancelUnifyTier}
          />
        )}

        <CancelInvoiceRequestDialog
          open={view === 'cancel-pending-invoice'}
          onCancelRequest={cancelPendingInvoice}
          onClose={handleClose}
        />

        {children}
      </BillingManagerContext.Provider>
    );
  },
);

export async function onActivateCoupon(state: CouponState, teamId: string) {
  if (state.instance) {
    if (isDiscountCoupon(state.instance) && state.instance.isConnect) {
      await BillingApiService.activeConnectCoupon(state.value, teamId);
    } else {
      await BillingApiService.activateCloudCoupon(state.value, teamId);
    }
  }
}
