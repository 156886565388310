import React, {useMemo, useState} from 'react';
import {Stack, Typography} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {BatchActionButton} from 'app/components/FleetManager/BatchActionsPanel/BatchActionButton';
import {BatchFirmwareDialog} from 'app/components/FleetManager/BatchActionsPanel/dialogs/BatchFirmwareDialog/BatchFirmwareDialog';
import {LoadingWrapper} from 'app/components/sharedReactComponents/LoadingWrapper';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';

type Props = {
  devices: AnyDeviceModelType[];
  loading: boolean;
  refetch: () => Promise<any>;
};

export function BatchFirmware({devices, loading, refetch}: Props) {
  const [dialog, setDialog] = useState(false);

  const names = useMemo(() => devices.map((d) => d.getName()), [devices]);

  const handleUpdate = async () => {
    const ids = devices.map((d) => d.getId());

    await DeviceApiService.sendBatchTask('firmware.update', ids);
    await refetch();

    setDialog(false);
  };

  return (
    <>
      <BatchFirmwareDialog
        open={dialog}
        names={names}
        onUpdate={handleUpdate}
        onClose={() => setDialog(false)}
      />

      <LoadingWrapper loading={loading}>
        <BatchActionButton
          data-id="batch_action_firmware_update"
          title="Update Firmware"
          onClick={() => setDialog(true)}
        >
          <Stack direction="row" alignItems="center">
            <AutorenewIcon />
            <Typography>{devices.length}</Typography>
          </Stack>
        </BatchActionButton>
      </LoadingWrapper>
    </>
  );
}
