import React, {Fragment} from 'react';
import classNames from 'classnames';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {THEME} from 'app/constants';
import {Callback, ClassName, Sx} from 'app/types/common';
import {Typography, TypographyProps} from '@mui/material';

interface Item {
  getName: Callback<string, any>;
}

function hasNameGetter(item: any): item is Item {
  return 'getName' in item;
}

function defaultGetName(item: any) {
  if (hasNameGetter(item)) {
    return item.getName();
  }

  throw new Error(
    'Default name getter cannot be applied due to item does not have "getName" method',
  );
}

interface Props<T extends object> extends ClassName {
  items: T[];
  maxNameCount?: number;
  theme?: THEME;
  getName?: Callback<string, [item: T]>;
}

export const ItemsEnumeration = <T extends object>({
  className,
  items,
  maxNameCount = 5,
  theme,
  getName = defaultGetName,
}: Props<T>) => {
  const itemsCount = items.length;

  return (
    <span
      className={classNames(
        'items-enumeration',
        theme && `items-enumeration--theme-${theme}`,
        className,
      )}
    >
      {itemsCount <= maxNameCount ? (
        items.map((item, index) => (
          <Fragment key={index}>
            <span className="items-enumeration__item">
              <OverflowTooltip>{getName(item)}</OverflowTooltip>
            </span>

            {index < itemsCount - 1 && ', '}
          </Fragment>
        ))
      ) : (
        <span className="items-enumeration__count">{itemsCount}</span>
      )}
    </span>
  );
};

type Args = Sx & {
  names: string[];
  max?: number;
  variant?: TypographyProps['variant'];
  color?: TypographyProps['color'];
};

export function NamesList({sx, names, max = 5, variant, color}: Args) {
  const count = names.length;
  const collapse = count > max;

  if (collapse) {
    return (
      <Typography sx={sx} component="span" variant={variant} color={color}>
        {count}
      </Typography>
    );
  }

  return (
    <Typography sx={sx} component="span" variant={variant}>
      {names.map((name, index) => (
        <Fragment key={name}>
          <Typography
            display="inline-block"
            maxWidth="70%"
            component="span"
            variant="inherit"
            color={color}
          >
            <OverflowTooltip>{name}</OverflowTooltip>
          </Typography>
          {index < count - 1 && ', '}
        </Fragment>
      ))}
    </Typography>
  );
}
