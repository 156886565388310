import React, {Fragment, useCallback} from 'react';
import classNames from 'classnames';
import {DEVICE_START_STOP_BUTTON_STATUS} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton/constants';
import {DurationTimer} from 'app/components/sharedReactComponents/DurationTimer';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {Callback, ClassName} from 'app/types/common';

const StopLabel: React.FC<{stopLabel: string}> = ({stopLabel}) => {
  return (
    <span className="device-start-stop-button__stop">
      {Icons.stop().size(SIZE.S).reactComponent()}

      {stopLabel}
    </span>
  );
};

interface Props extends ClassName {
  status?: DEVICE_START_STOP_BUTTON_STATUS;
  icon: React.ReactNode;
  stoppedLabel?: string;
  startedLabel?: string;
  errorLabel?: string;
  pausedLabel?: string;
  loadingLabel: string;
  stopLabel: string;
  stopLoadingLabel: string;
  startTime?: number;
  receivedTime?: number;
  size?: SIZE;
  disabled?: boolean;
  hovered?: boolean;
  loading?: boolean;
  unsetLoadingAfterPromise?: boolean;
  startAction: Callback;
  resumeAction?: Callback;
  stopAction: Callback;
}

export function StartStopButton({
  className,
  status = DEVICE_START_STOP_BUTTON_STATUS.STOPPED,
  icon,
  stoppedLabel,
  startedLabel,
  pausedLabel,
  errorLabel,
  loadingLabel,
  stopLabel,
  stopLoadingLabel,
  startTime,
  receivedTime,
  size,
  disabled,
  hovered,
  loading,
  unsetLoadingAfterPromise,
  startAction,
  resumeAction,
  stopAction,
  ...elementProps
}: Props) {
  const handleClick = useCallback(() => {
    switch (status) {
      case DEVICE_START_STOP_BUTTON_STATUS.STOPPED:
        return startAction();
      case DEVICE_START_STOP_BUTTON_STATUS.PAUSED:
        return resumeAction?.();
      case DEVICE_START_STOP_BUTTON_STATUS.LOADING:
      case DEVICE_START_STOP_BUTTON_STATUS.STARTED:
      case DEVICE_START_STOP_BUTTON_STATUS.ERROR:
      default:
        return stopAction();
    }
  }, [status, startAction, resumeAction, stopAction]);

  return (
    <ProgressButton
      className={classNames(
        'device-start-stop-button',
        {
          [`device-start-stop-button--${status}`]: Boolean(status),
          'device-start-stop-button--hovered': hovered === true,
        },
        size && `device-start-stop-button--size-${size}`,
        className,
      )}
      size={size}
      disabled={disabled}
      loading={loading}
      unsetLoadingAfterPromise={unsetLoadingAfterPromise}
      onClick={handleClick}
      {...elementProps}
    >
      {icon}

      {status === DEVICE_START_STOP_BUTTON_STATUS.STOPPED && stoppedLabel}

      {status === DEVICE_START_STOP_BUTTON_STATUS.LOADING && (
        <Fragment>
          {loadingLabel}

          <StopLabel stopLabel={stopLoadingLabel} />
        </Fragment>
      )}

      {status === DEVICE_START_STOP_BUTTON_STATUS.STARTED && (
        <Fragment>
          {startedLabel}

          <DurationTimer
            className="device-start-stop-button__duration"
            startTime={startTime}
            receivedTime={receivedTime}
          />

          <StopLabel stopLabel={stopLabel} />
        </Fragment>
      )}

      {status === DEVICE_START_STOP_BUTTON_STATUS.ERROR && (
        <Fragment>
          {errorLabel}
          <StopLabel stopLabel={stopLabel} />
        </Fragment>
      )}

      {status === DEVICE_START_STOP_BUTTON_STATUS.PAUSED && pausedLabel}
    </ProgressButton>
  );
}
