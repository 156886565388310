// Enabled services on top
export function streamingServiceComparator(a, b): number {
  const aDisabled = a.isDisabled();
  const bDisabled = b.isDisabled();

  if (aDisabled === bDisabled) {
    return 0;
  }

  if (aDisabled) {
    return 1;
  }

  return -1;
}
