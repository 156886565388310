import {useQuery} from '@tanstack/react-query';
import {BillingApiService} from 'app/services/api/billing/BillingApiService';
import {BillingMapper} from 'app/services/mappers/BillingMapper';
import {secondsToMilliseconds} from 'app/util/timeConverter';

interface Args {
  teamId: string;
  enabled: boolean;
}

export function useUnifyPlan({enabled, teamId}: Args) {
  return useQuery({
    queryKey: ['unify-plan', teamId],
    queryFn: () => BillingApiService.getUnifyPlan(teamId),
    refetchInterval: secondsToMilliseconds(30),
    enabled,
    select: mapUnifyPlan,
  });
}

function mapUnifyPlan(c?: Contracts.Billing.Unify.Plan): App.Billing.Unify.Plan | undefined {
  if (!c) {
    return;
  }

  return BillingMapper.mapUnifyPlan(c);
}
