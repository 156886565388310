import React, {ReactNode} from 'react';
import {Button, Stack, Typography} from '@mui/material';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import BallotIcon from '@mui/icons-material/Ballot';
import HdIcon from '@mui/icons-material/Hd';
import {router} from 'app/router/main';
import {Sx} from 'app/types/common';

type Props = Sx & {
  billingAccess: boolean;
};

export function UpgradeToEdgePremium({sx, billingAccess}: Props) {
  return (
    <Stack sx={sx} width={294} gap={2} className="upgrade-to-pro-tooltip">
      <Typography variant="h6" color="primary.main" fontWeight={600}>
        Supercharge your experience and upgrade to Premium
      </Typography>

      <Feature
        icon={<AdsClickIcon fontSize="large" />}
        description="Batch control streaming, recording and transcription, fleet maintenance, remote login and
        more."
      />

      <Feature
        icon={<FolderCopyIcon fontSize="large" />}
        description="Device grouping to enhance your device organization during Live Events."
      />

      <Feature
        icon={<BallotIcon fontSize="large" />}
        description="Configuration presets support."
      />

      <Feature icon={<HdIcon fontSize="large" />} description="High-definition video playback." />

      {billingAccess && (
        <Button
          sx={{alignSelf: 'center'}}
          variant="contained"
          color="secondary"
          onClick={() => router.go('settingBillingUsage')}
          disableRipple={false}
        >
          <Typography>Upgrade to Premium</Typography>
        </Button>
      )}
    </Stack>
  );
}

type FeatureProps = Sx & {
  icon: ReactNode;
  description: string;
};

function Feature({sx, icon, description}: FeatureProps) {
  return (
    <Stack sx={sx} direction="row" alignItems="flex-start" gap={1}>
      {icon}

      <Typography alignSelf="center">{description}</Typography>
    </Stack>
  );
}
