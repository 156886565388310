import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {
  difference,
  prop,
  path,
} from 'ramda';
import {router} from 'app/router/main';

import {front} from 'app/api/CirrusApi';
import {uniqueNewName} from 'app/util/newName';
import {ApiResponseStatus} from 'app/data/Status';
import {AjaxComplexSuccessfulResponseType} from 'app/api/types';

import {LocationsList, LocationsListItem} from 'app/components/UploadLocations/LocationsList';
import {LocationConfiguration} from 'app/components/UploadLocations/LocationConfiguration';

import {createModel} from 'app/components/UploadLocations/Models/Fabric';
import {
  STORAGE_PROP_NAME,
  STORAGE_TYPE,
  STORAGE_PROP_NAME_BY_TYPE,
} from 'app/components/UploadLocations/constants';

import {DeletePrompt} from 'app/components/sharedReactComponents/Modal/DeletePrompt';

import {NewLocationTypeSelector} from 'app/components/UploadLocations/NewLocationTypeSelector';

import {BoxProvider} from 'app/util/OAuthProvider/BoxProvider';
import {DropboxProvider} from 'app/util/OAuthProvider/DropboxProvider';
import {GoogleDriveProvider} from 'app/util/OAuthProvider/GoogleDriveProvider';
import {CloudStorageConfiguration} from 'app/components/UploadLocations/CloudStorageConfiguration';
import {Callback} from 'app/types/common';
import {AnyLocationModel, StorageResponseType, StoragesResponse} from 'app/components/UploadLocations/Models/types';

const NEW_LOCATION_ID = 'newLocation';

interface Props {
  locationId?: string;
  onLoadEnd: Callback;
}

// rework typing
class UploadLocationsComponent extends Component<Props> {
  uploadTypes = [[STORAGE_TYPE.FTP, 'FTP'], [STORAGE_TYPE.SFTP, 'SFTP']];
  locations: any;
  loading: any;
  newLocation: any;
  initialFetchFinished: any;
  currentId: any;

  constructor(props: Props) {
    super(props);

    this.locations = observable<any[]>([]);
    this.loading = observable.box(false);
    this.newLocation = observable.box(null);
    this.initialFetchFinished = observable.box(false);
    this.currentId = observable.box(props.locationId || null);

    this.createModelFromJson = this.createModelFromJson.bind(this);
    this.switchLocationType = this.switchLocationType.bind(this);

    this.saveLocation = this.saveLocation.bind(this);
    this.testConnection = this.testConnection.bind(this);
    this.fetchLocations = this.fetchLocations.bind(this);
    this.deleteLocation = this.deleteLocation.bind(this);
    this.selectLocation = this.selectLocation.bind(this);

    this.deleteLocationAction = this.deleteLocationAction.bind(this);

    this.startLoading = this.startLoading.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
  }

  componentDidMount() {
    this.fetchLocations()
      .finally(() => this.props.onLoadEnd());
  }

  componentDidUpdate(prevProps) {
    const {
      locationId
    } = this.props;

    if (locationId && locationId !== prevProps.locationId) {
      this.currentId.set(locationId);
    }
  }

  getNewLocationBasenameByType(type?: STORAGE_TYPE | string): string {
    switch (type) {
      case STORAGE_TYPE.GOOGLE_DRIVE:
        return 'Google Drive';
      case STORAGE_TYPE.BOX:
        return 'Box';
      case STORAGE_TYPE.DROPBOX:
        return 'Dropbox';
      case STORAGE_TYPE.FTP:
      case STORAGE_TYPE.SFTP:
      default:
        return 'My Location';
    }
  }

  getUniqueNewLocationNameByType(type?: STORAGE_TYPE | string): string {
    return uniqueNewName(
      this.getNewLocationBasenameByType(type),
      this.locations.map(path(['name', 'value']))
    );
  }

  startNewLocation() {
    const newName = this.getUniqueNewLocationNameByType();
    const newId = NEW_LOCATION_ID;
    this.newLocation.set({
      id: newId,
      name: newName,
    });
    this.selectLocation(newId);
  }

  resetNewLocation() {
    this.newLocation.set(null);
  }

  startLoading() {
    this.loading.set(true);
  }

  stopLoading() {
    this.loading.set(false);
  }

  createModelFromJson(type: STORAGE_TYPE, location: StorageResponseType) {
    return createModel(type, location, this.saveLocation);
  }

  switchLocationType(id: string, newType: STORAGE_TYPE) {
    this.locations.replace(
      this.locations.map(location => {
        if (location.id === id) {
          const currentOptions = location.options;
          const json = {
            ...location.json,
            [STORAGE_PROP_NAME_BY_TYPE[newType]]: currentOptions,
          };

          const newLocationModel = this.createModelFromJson(
            newType,
            json
          ) as AnyLocationModel;
          this.saveLocation(id, newLocationModel.json);

          return newLocationModel;
        }

        return location;
      })
    );
  }

  selectLocation(id: string) {
    this.currentId.set(id);

    const props = this.locationIsNew(id) ? undefined : {locationId: id};

    router.go('fileTransfer', {}, props);
  }

  locationIsNew(id: string): boolean {
    return id === NEW_LOCATION_ID;
  }

  async fetchLocations(): Promise<void> {
    this.startLoading();

    return front.storage().get()
      .then((locations: StoragesResponse) => {
        const locationsCollection = locations.map(location => this.createModelFromJson(location.Type, location));
        this.locations.replace(locationsCollection);
      })
      .finally(() => {
        this.initialFetchFinished.set(true);
        this.stopLoading();
      });
  }

  handleClickCreateNewLocation = () => {
    this.startNewLocation();
  };

  async createNewUploadLocation(name: string): Promise<any> {
    this.startLoading();

    return front.storage().post({
      Name: name,
      [STORAGE_PROP_NAME.FTP]: {},
    })
      .then((resp: AjaxComplexSuccessfulResponseType<{ID: string}>) => {
        if (resp?.Status === ApiResponseStatus.Ok) {
          this.selectLocation(resp.ID);
        }

        return resp;
      })
      .then(this.fetchLocations)
      .finally(this.stopLoading);
  }

  handleSelectNewLocationType = (type: STORAGE_TYPE) => {
    const newLocationName = this.getUniqueNewLocationNameByType(type);

    switch (type) {
      case STORAGE_TYPE.FTP:
      case STORAGE_TYPE.SFTP:
        void this.createNewUploadLocation(newLocationName).then(() => this.resetNewLocation());
        break;
      default:
        this.openOAuth2(type, newLocationName);
    }
  };

  handleOAuthWindowOpen = () => {
    this.startLoading();
  };

  getLocationsIds(): string[] {
    return this.locations.map(prop('id'));
  }

  handleOAuthWindowClose = (response) => {
    if (response.error || response.loginCallbackIsNotFired) {
      this.stopLoading();
      return;
    }

    // Detect added location and set as current
    const oldStorageIds = this.getLocationsIds();
    void this.fetchLocations()
      .then(() => this.resetNewLocation())
      .then(() => {
        const newStorageIds = this.getLocationsIds();
        const diffStorageIds = difference(newStorageIds, oldStorageIds);
        if (diffStorageIds.length === 1) {
          this.selectLocation(diffStorageIds[0]!);
        }
      });
  };

  deleteLocation(id: string) {
    DeletePrompt.show({
      title: 'Are you sure you want to delete this shared location?',
      action: async () => this.deleteLocationAction(id),
    });
  }

  async deleteLocationAction(id: string): Promise<any> {
    this.startLoading();

    return front.storage(id).delete()
      .then(this.fetchLocations)
      .finally(this.stopLoading);
  }

  async saveLocation(id: string, json: Record<string, any>): Promise<any> {
    return front.storage(id).put(json);
  }

  async testConnection(id: string): Promise<any> {
    return front.storage(id).test().get();
  }

  renderEmptyPanel() {
    return (
      <div className="cr-upload-location__empty-panel">
        <h3 className="cr-upload-location__intro-title">
          Set up a shared network location to push your rendered files to.
        </h3>

        {this.renderCreateNewLocationButton()}
      </div>
    );
  }

  renderNewLocationListItem() {
    const {
      id,
      name,
    } = this.newLocation.get();
    const currentId = this.currentId.get();

    return (
      <div className="new-upload-locations-list">
        <LocationsListItem
          id={id}
          name={name}
          isCurrent={id === currentId}
          selectLocation={this.handleSelectNewLocation}
          deleteLocation={this.handleDeleteNewLocation}
        />
      </div>
    );
  }

  handleSelectNewLocation = (id: string) => {
    this.selectLocation(id);
  };

  handleDeleteNewLocation = () => {
    this.resetNewLocation();
  };

  handleClickReconnect = (type: STORAGE_TYPE, id: string) => {
    this.openOAuth2(type, id);
  };

  openOAuth2(type: STORAGE_TYPE, id: string) {
    const connectionEndpoint = front.connections().connect();

    switch (type) {
      case STORAGE_TYPE.GOOGLE_DRIVE:
        new GoogleDriveProvider({
          url: connectionEndpoint.google().storage(id).url(),
          onOpen: this.handleOAuthWindowOpen,
          onClose: this.handleOAuthWindowClose,
        }).open();
        break;
      case STORAGE_TYPE.BOX:
        new BoxProvider({
          url: connectionEndpoint.box().storage(id).url(),
          onOpen: this.handleOAuthWindowOpen,
          onClose: this.handleOAuthWindowClose,
        }).open();
        break;
      case STORAGE_TYPE.DROPBOX:
        new DropboxProvider({
          url: connectionEndpoint.dropbox().storage(id).url(),
          onOpen: this.handleOAuthWindowOpen,
          onClose: this.handleOAuthWindowClose,
        }).open();
        break;
      default:
        console.error('Unregistered storage type', type);
    }
  }

  renderPanel() {
    const currentId = this.currentId.get();
    const newLocation = this.newLocation.get();
    const newLocationIsCurrent = newLocation && newLocation.id === currentId;
    const currentLocation = this.locations.find(({id}) => id === currentId);

    return (
      <div className="cr-upload-location__panel">
        <div className="cr-upload-location__list">
          {this.renderCreateNewLocationButton()}

          {newLocation && this.renderNewLocationListItem()}

          <LocationsList
            locations={this.locations}
            currentId={this.currentId.get()}
            deleteLocation={this.deleteLocation}
            selectLocation={this.selectLocation}
          />
        </div>

        <div className="cr-upload-location__configuration">
          {this.renderConfigurationComponent(currentLocation, newLocationIsCurrent)}
        </div>
      </div>
    );
  }

  renderConfigurationComponent(currentLocation, newLocationIsCurrent: boolean) {
    if (newLocationIsCurrent) {
      return (
        <NewLocationTypeSelector
          onSelect={this.handleSelectNewLocationType}
        />
      );
    }

    if (currentLocation) {
      switch (currentLocation.type) {
        case STORAGE_TYPE.FTP:
        case STORAGE_TYPE.SFTP:
          return (
            <LocationConfiguration
              key={this.currentId.get()}
              switchLocationType={this.switchLocationType}
              currentLocation={currentLocation}
              uploadTypes={this.uploadTypes}
              testConnection={this.testConnection}
            />
          );
        case STORAGE_TYPE.BOX:
        case STORAGE_TYPE.DROPBOX:
        case STORAGE_TYPE.GOOGLE_DRIVE:
          return (
            <CloudStorageConfiguration
              key={this.currentId.get()}
              currentLocation={currentLocation}
              onClickReconnect={this.handleClickReconnect}
            />
          );
        default:
          return null;
      }
    }

    return null;
  }

  renderCreateNewLocationButton() {
    return (
      <button
        className="cr-btn-primary cr-upload-location__new-location-btn"
        type="button"
        onClick={this.handleClickCreateNewLocation}
      >
        + New shared location
      </button>
    );
  }

  renderLocationsPanel() {
    const hasStartedNewLocation = this.newLocation.get() !== null;
    const hasLocations = this.locations.length > 0 || hasStartedNewLocation;

    if (hasLocations) {
      return this.renderPanel();
    }

    return this.renderEmptyPanel();
  }

  render() {
    return (
      <div className="cr-upload-location">
        {this.initialFetchFinished.get() && this.renderLocationsPanel()}
      </div>
    );
  }
}

export const UploadLocations = observer(UploadLocationsComponent);
