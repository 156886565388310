import {useEffect, useState} from 'react';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {Cloud} from 'app/domain/cloud';

type State = {
  started: AnyDeviceModelType[];
  stopped: AnyDeviceModelType[];
};

type Statistic = {
  streaming: State & {selectedDestinations: boolean};
  recording: State;
  transcribing: State;
};

export function useBatchMultimedia(devices: AnyDeviceModelType[]): Statistic {
  const [state, setState] = useState(() => getDefaultState());

  useEffect(() => {
    const state = getDefaultState();

    devices.forEach((device) => {
      if (device.isOffline()) {
        return;
      }

      const model = device.getModelName();

      if (device.capabilities.streaming && isChannelOrWebcaster(model)) {
        if (device.isStreaming() || device.isStreamingStarted()) {
          state.streaming.started.push(device);
        } else {
          state.streaming.stopped.push(device);
        }

        const hasSelectedTargets =
          Boolean(device.getSelectedStreamingDestinationId()) ||
          device.getSelectedPublisherIds().length > 0;

        if (!state.streaming.selectedDestinations && hasSelectedTargets) {
          state.streaming.selectedDestinations = true;
        }
      }

      if (device.capabilities.recording && isChannelOrWebcaster(model)) {
        if (device.isRecording()) {
          state.recording.started.push(device);
        } else {
          state.recording.stopped.push(device);
        }
      }

      if (device.capabilities.transcribing) {
        if (device.isTranscribing()) {
          state.transcribing.started.push(device);
        } else {
          state.transcribing.stopped.push(device);
        }
      }
    });

    setState(state);
  }, [devices]);

  return state;
}

function isChannelOrWebcaster(model: Cloud.UnitModel) {
  return ModelService.isWebcaster(model) || ModelService.isChannel(model);
}

function getDefaultState(): Statistic {
  return {
    streaming: {started: [], stopped: [], selectedDestinations: false},
    recording: {started: [], stopped: []},
    transcribing: {started: [], stopped: []},
  };
}
