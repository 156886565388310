import React from 'react';
import classNames from 'classnames';
import {SettingsList} from 'app/components/sharedReactComponents/SettingsList';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  title: string;
  settings: any[];
  disabled: boolean;
  updateSettingAction: Callback;
}

export const OutputItem: React.FC<Props> = ({
  className,
  title,
  settings = [],
  disabled,
  children,
  updateSettingAction,
}) => {
  if (settings.length === 0 && !children) {
    return null;
  }

  return (
    <div className={classNames('transcribe-output-settings-item', className)}>
      <div className="transcribe-output-settings-item__title">
        {title}
      </div>

      <div className="transcribe-output-settings-item__content">
        <SettingsList
          settings={settings}
          disabled={disabled}
          updateSettingAction={updateSettingAction}
        />

        {children}
      </div>
    </div>
  );
};
