import EventEmitter from 'eventemitter3';

class DispatcherClass {
  private readonly _impl = new EventEmitter();
  private _currentMaxListeners = 64;

  _verifyListenersCount() {
    const eventsListeners = this._impl.eventNames()
      .map(name => ({
        count: this._impl.listenerCount(name),
        name,
      }))
      .sort((a, b) => b.count - a.count);

    const eventWithMaxListeners = eventsListeners.find(({count}) => count >= this._currentMaxListeners);

    if (eventWithMaxListeners) {
      this._currentMaxListeners *= 2;
      console.warn(`"${eventWithMaxListeners.name as string}" event listeners exceeded configured limit (${eventWithMaxListeners.count})`);
      console.warn('Overall events stats:');
      console.warn(JSON.stringify(eventsListeners, null, 2));
    }
  }

  on(eventName: string, listener: EventEmitter.ListenerFn) {
    this._verifyListenersCount();
    this._impl.on(eventName, listener);
  }

  removeListener(eventName: string, listener: EventEmitter.ListenerFn) {
    this._verifyListenersCount();
    this._impl.removeListener(eventName, listener);
  }

  emit(eventName: string, payload?: any) {
    this._impl.emit(eventName, payload);
  }
}

const Dispatcher = new DispatcherClass();

export {Dispatcher};
