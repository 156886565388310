import React from 'react';
import {Box, Link, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {EdgeProduct} from 'app/components/FleetManager/EdgeWelcome/products/EdgeProduct/EdgeProduct';
import {UnifyProduct} from 'app/components/FleetManager/EdgeWelcome/products/UnifyProduct/UnifyProduct';
import {ConnectProduct} from 'app/components/FleetManager/EdgeWelcome/products/ConnectProduct/ConnectProduct';

interface Props extends Sx {}

export function EdgeWelcome({sx}: Props) {
  return (
    <Stack sx={sx} alignItems="center">
      <Typography variant="h4" fontWeight={600} mb={5}>
        Choose how you want to start
      </Typography>

      <Box display="grid" gap={3} justifyContent="center" gridAutoFlow="column" mb={3}>
        <EdgeProduct />

        <UnifyProduct />

        <ConnectProduct />
      </Box>

      <Typography>
        Talk to one of our experts.{' '}
        <Link
          href="https://www.epiphan.com/request-a-demo/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book a demo
        </Link>
      </Typography>
    </Stack>
  );
}
