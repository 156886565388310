import {parseMasterAndChannelIds} from 'app/components/DeviceDetails/utils';
import {Schedule} from 'app/domain/schedule';
import {isNil} from 'app/util/isNil';

interface Args {
  channelId: string;
  event?: Schedule.Event;
}

export function useChannelEvent({channelId, event}: Args) {
  if (!event) {
    return;
  }

  const [masterId, channelIdx] = parseMasterAndChannelIds(channelId);

  const hasEvent = !isNil(event.devices.get(masterId)?.get(channelIdx));

  return hasEvent ? event : undefined;
}
