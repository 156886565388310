import React from 'react';
import {
  Box,
  Checkbox,
  ListItemText,
  ListSubheader,
  MenuItem,
  Radio,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import {
  Destination,
  DestinationType,
} from 'app/components/sharedReactComponents/Events/Editor/EventForm/types';
import {DataId, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';

export interface SelectArgs {
  destinationId: string;
  type: DestinationType;
}

interface Props extends Sx, DataId {
  selected: string[];
  publishers: Destination[];
  destinations: Destination[];
  disabled?: boolean;
  onSelect: (args: SelectArgs) => void;
}

export function DestinationPicker({
  dataId,
  sx,
  selected,
  destinations,
  publishers,
  disabled,
  onSelect,
}: Props) {
  const handleSelectPublisher = (id: string) => {
    onSelect({destinationId: id, type: 'publisher'});
  };

  const handleSelectDestination = (id: string) => {
    onSelect({destinationId: id, type: 'destination'});
  };

  const renderList = () => {
    const hasPublishers = publishers.length > 0;
    const hasDestinations = destinations.length > 0;

    if (!hasPublishers && !hasDestinations) {
      return <ListSubheader>No destinations</ListSubheader>;
    }

    const renderPublishers = () => {
      if (hasPublishers) {
        return [
          <ListSubheader key="publishers">Publishers</ListSubheader>,
          ...publishers.map((d) => (
            <MenuItem
              data-id={`publisher-${d.id}-button`}
              key={d.id}
              sx={{gap: 1}}
              value={d.id}
              onClick={() => handleSelectPublisher(d.id)}
            >
              <Checkbox sx={{px: 0}} checked={selected.includes(d.id)} />
              <ListItemText>{d.name}</ListItemText>
            </MenuItem>
          )),
        ];
      }

      return [];
    };

    const renderDestinations = () => {
      if (hasDestinations) {
        return [
          <ListSubheader key="destinations">Destinations</ListSubheader>,
          ...destinations.map((d) => (
            <MenuItem
              data-id={`destination-${d.id}-button`}
              key={d.id}
              sx={{gap: 1}}
              value={d.id}
              onClick={() => handleSelectDestination(d.id)}
            >
              <Radio sx={{px: 0}} checked={selected.includes(d.id)} />
              <ListItemText primaryTypographyProps={{noWrap: true}}>{d.name}</ListItemText>
            </MenuItem>
          )),
        ];
      }

      return [];
    };

    return [...renderPublishers(), ...renderDestinations()];
  };

  return (
    <Select
      data-id={dataId}
      sx={packSx({width: 140, minWidth: 140}, sx)}
      displayEmpty={true}
      multiple
      value={selected}
      disabled={disabled}
      variant="standard"
      MenuProps={{
        sx: {maxWidth: 350},
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      renderValue={(selected) => {
        const {length} = selected;

        if (length > 0) {
          return (
            <Stack direction="row" gap={0.5}>
              <Box
                component="span"
                px={0.5}
                borderRadius={1}
                sx={(t) => ({
                  bgcolor: t.palette.primary.main,
                  color: t.palette.primary.contrastText,
                })}
              >
                {length}
              </Box>
              <Typography component="span" color="primary">
                Destinations
              </Typography>
            </Stack>
          );
        }

        return <Typography>Destinations</Typography>;
      }}
    >
      {renderList()}
    </Select>
  );
}
