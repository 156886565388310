function getApiErrorMessage(error?: any): string {
  let message;

  if (error?.data) {
    const {data} = error;
    message = data.message || data.Error || data.error;
  }

  return message || '';
}

export {
  getApiErrorMessage,
};
