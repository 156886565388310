import React, {useRef} from 'react';
import classNames from 'classnames';
import {ClassName, DataId} from 'app/types/common';
import {drawAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/AudioMeter/utils';
import {useDimensions} from 'app/components/sharedReactComponents/DeviceAudioMeter/AudioMeter/useDimensions';
import {Canvas} from 'app/components/sharedReactComponents/Canvas';

interface Props extends ClassName, DataId {
  value?: number;
  height?: number;
  segmentsCount?: number;
  segmentsGap?: number;
  vertical?: boolean;
}

export const AudioMeter: React.VFC<Props> = ({
  className,
  value,
  height,
  segmentsCount = 22,
  segmentsGap = 4,
  vertical = false,
  dataId,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const dimensions = useDimensions(containerRef);

  return (
    <div
      ref={containerRef}
      className={classNames('audio-meter-core', className)}
      style={{
        height,
      }}
      data-id={dataId}
    >
      <Canvas width={dimensions.width} height={height ?? dimensions.height}>
        {(context, width, height) =>
          drawAudioMeter({
            value,
            context,
            height,
            width,
            segmentsCount,
            segmentsGap,
            vertical,
          })
        }
      </Canvas>
    </div>
  );
};
