import {Chain} from 'app/api/Chain';
import {Url} from 'app/api/util/Url';
import {Cache} from 'app/util/Cache';
import {Api} from 'app/api/Api';
import {AjaxErrorCallback} from 'app/api/types';

interface Params {
  name: string;
  version?: string;
  parent: Api;
  endpoints: Record<string, any>;
  cache?: Cache;
  onAjaxError?: AjaxErrorCallback;
}

export class Service extends Chain {
  name: string;
  version?: string;

  constructor({
    name,
    version,
    parent,
    endpoints,
    cache,
    onAjaxError,
  }: Params) {
    super({
      parent,
      endpoints,
      cache,
      onAjaxError,
    });

    this.name = name;
    this.version = version;
  }

  _getUrlPart({noTeam}: {noTeam?: boolean} = {}) {
    let url;

    if (this.version) {
      url = Url.join(this.name, 'api', `v${this.version}`);
    } else {
      url = Url.join(this.name);
    }

    if (!noTeam && (this.parent as Api).getCurrentTeamId()) {
      url = Url.join(url, 'team', (this.parent as Api).getCurrentTeamId());
    }

    return url;
  }
}
