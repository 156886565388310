import React, {useState} from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {UserRole, THEME, VARIANT, SIZE} from 'app/constants';
import {front} from 'app/api/CirrusApi';
import {emailValidator} from 'app/util/validators';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {FormControlLabel} from 'app/components/sharedReactComponents/FormControlLabel';
import {UserRoleSelector} from 'app/components/Teams/UsersPanel/UserRoleSelector';
import {ProgressButton, BUTTON_TYPE} from 'app/components/sharedReactComponents/ProgressButton';
import {ClassName} from 'app/types/common';
import {FORM_CONTROL_TYPE} from 'app/components/sharedReactComponents/FormControl/constants';

const initialFormData = {
  email: '',
  role: UserRole.Owner,
};

const SHOW_SUCCESS_SPLASH_TIMEOUT = 2000;

const SuccessSplash: React.VFC = () => (
  <div className="invite-user-form-success-splash">
    <div className="invite-user-form-success-splash__title">Invitation sent</div>

    <div className="invite-user-form-success-splash__icon">
      {Icons.check().size(SIZE.XXL).theme(THEME.SUCCESS).reactComponent()}
    </div>
  </div>
);

interface Props extends ClassName {
  teamId: string;
}

interface FormData {
  email: string;
  role: UserRole;
}

export const InviteUserForm = ({className, teamId}: Props) => {
  let showSuccessSplashTimeout: any = null;
  const usersEndpoint = front.teams(teamId).users;

  const [creating, setCreating] = useState<boolean>(false);
  const [showSuccessSplashFlag, setShowSuccessSplashFlag] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({...initialFormData});

  const handleSubmit = (e) => {
    e.preventDefault();

    setCreating(true);

    usersEndpoint()
      .post({
        Email: formData.email.trim(),
        Role: formData.role,
      })
      .then(() => showSuccessSplash())
      .then(() => resetForm())
      .finally(() => {
        setCreating(false);
      });
  };

  const resetForm = () => {
    setFormData({
      ...initialFormData,
    });
  };

  const showSuccessSplash = () => {
    setShowSuccessSplashFlag(true);

    clearTimeout(showSuccessSplashTimeout);

    showSuccessSplashTimeout = setTimeout(() => {
      setShowSuccessSplashFlag(false);
    }, SHOW_SUCCESS_SPLASH_TIMEOUT);
  };

  const handleChange = ({target: {name, value}}) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <form
      className={classNames('invite-user-form', className)}
      action=""
      autoComplete="off"
      noValidate={true}
      onSubmit={handleSubmit}
    >
      <div className="invite-user-form__title">Invite a new team member</div>

      <FormControl
        className="invite-user-form__row"
        label="Email address"
        type={FORM_CONTROL_TYPE.EMAIL}
        name="email"
        value={formData.email}
        placeholder="your@email.com"
        disabled={creating}
        required={true}
        onChange={handleChange}
      />

      <FormControlLabel
        className="invite-user-form__row invite-user-form__row--role"
        label="Role"
        control={
          <UserRoleSelector
            name="role"
            value={formData.role}
            disabled={creating}
            onChange={handleChange}
          />
        }
      />

      <ProgressButton
        type={BUTTON_TYPE.SUBMIT}
        theme={THEME.PRIMARY}
        variant={VARIANT.SOLID}
        className="invite-user-form__submit-button"
        disabled={!emailValidator.validate(formData.email)}
        loading={creating}
      >
        {Icons.send().label('Send invitation').reactComponent()}
      </ProgressButton>

      {showSuccessSplashFlag && <SuccessSplash />}
    </form>
  );
};
