import React, {useCallback, useState} from 'react';
import {StreamingServiceIcon} from 'app/components/StreamingServices/StreamingServiceIcon';
import {CommonStreamListItem} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStreamingPanel/DeviceStreamingDestinationList/CommonStreamListItem';
import {StreamingDestinationSettings} from 'app/components/sharedReactComponents/StreamingDestinationSettings';
import {DeleteStreamingDestinationPrompt} from 'app/components/libs/dialogs/DeleteStreamingDestinationPrompt';
import {isPromise} from 'app/util/isPromise';
import {Callback, ClassName} from 'app/types/common';
import {COMMON_STREAM_SELECT_INPUT_TYPE} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStreamingPanel/DeviceStreamingDestinationList/SelectInput';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {DeviceWarning} from 'app/components/DeviceDetails/Models/types';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Stack} from '@mui/material';

interface DestinationProps extends ClassName {
  disabled: boolean;
  offline: boolean;
  selected: boolean;
  selecting: boolean;
  editable: boolean;
  streamingDestination: AnyStreamingDestinationModelType;
  warnings: DeviceWarning[];
  deviceId: string;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  checkStreamingDestinationAvailableToSelect: Callback;
  onSelect: Callback;
  onDelete: Callback;
}

function DestinationItem({
  className,
  disabled,
  offline,
  selected,
  selecting,
  editable,
  streamingDestination,
  warnings,
  deviceId,
  checkStreamingDestinationAvailableToSelect,
  getDeviceById,
  onSelect,
  onDelete,
}: DestinationProps) {
  const [updating, setUpdating] = useState(false);

  const startStopAction = useCallback(() => {
    if (streamingDestination.isStreaming()) {
      return streamingDestination.stopStreamingAction(deviceId);
    }

    return streamingDestination.startStreamingAction(deviceId);
  }, [streamingDestination, deviceId]);

  const handleClickDelete = useCallback(() => {
    DeleteStreamingDestinationPrompt.show({
      streamingDestination,
      onDone: () => onDelete(streamingDestination),
    });
  }, [streamingDestination, onDelete]);

  const handleChange = useCallback(
    (data) => {
      setUpdating(true);

      streamingDestination.updateAction(data).finally(() => setUpdating(false));
    },
    [streamingDestination],
  );

  const handleNameChange = useCallback(
    ({target: {value}}) => {
      setUpdating(true);

      streamingDestination.updateName(value).finally(() => setUpdating(false));
    },
    [streamingDestination],
  );

  const started = streamingDestination.isStreaming();
  const streaming = streamingDestination.isStreaming();
  const startTime = streamingDestination.getStartTime();

  const selectedOnDeviceId = streamingDestination.getSelectedOnDeviceId();

  const availableOnThisDeviceType = checkStreamingDestinationAvailableToSelect(
    streamingDestination.getType(),
  );

  const icon = StreamingServiceIcon.getSmallIconByType(
    streamingDestination.getType(),
  ).reactComponent();

  return (
    <CommonStreamListItem
      className={className}
      deviceId={deviceId}
      selectedOnDeviceId={selectedOnDeviceId}
      id={streamingDestination.getId()}
      name={streamingDestination.getName()}
      icon={icon}
      offline={offline}
      selected={selected}
      disabled={disabled || availableOnThisDeviceType.value === false}
      disabledReasonMessage={availableOnThisDeviceType.reasonMessage}
      started={started}
      streaming={streaming}
      selecting={selecting}
      startTime={startTime}
      // TODO: try to figure out if is possible to get error state
      hasError={false}
      warnings={warnings}
      selectInputType={COMMON_STREAM_SELECT_INPUT_TYPE.RADIO}
      detailsComponent={
        <StreamingDestinationSettings
          streamingDestination={streamingDestination}
          disabled={streaming || !editable}
          updating={updating}
          onClickDelete={handleClickDelete}
          onChange={handleChange}
        />
      }
      startStopAction={startStopAction}
      getDeviceById={getDeviceById}
      onSelect={onSelect}
      onNameChange={handleNameChange}
    />
  );
}

interface Props extends ClassName {
  offline: boolean;
  started: boolean;
  editable: boolean;
  streamingDestinations: AnyStreamingDestinationModelType[];
  selectedStreamingDestinations: Set<string>;
  warnings: DeviceWarning[];
  deviceId: string;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  checkStreamingDestinationAvailableToSelect: Callback;
  onSelect: Callback;
  onDelete: Callback;
}

export function DeviceStreamingDestinationList({
  className,
  offline,
  started,
  editable,
  streamingDestinations,
  selectedStreamingDestinations,
  warnings,
  deviceId,
  checkStreamingDestinationAvailableToSelect,
  getDeviceById,
  onSelect,
  onDelete,
}: Props) {
  const [selectingId, setSelectingId] = useState(null);

  const handleSelect = useCallback(
    (event) => {
      const promise = onSelect(event);

      if (isPromise(promise)) {
        setSelectingId(event.target.name);
        promise.finally(() => setSelectingId(null));
      }
    },
    [onSelect],
  );

  return (
    <Stack gap={1}>
      {streamingDestinations.map((d) => {
        const destId = d.getId();
        const selected = selectedStreamingDestinations.has(destId);

        return (
          <DestinationItem
            key={d.getId()}
            className={className}
            disabled={started || selectingId !== null}
            offline={offline}
            selecting={selectingId === destId}
            selected={selected}
            editable={editable}
            streamingDestination={d}
            warnings={selected ? warnings : []}
            deviceId={deviceId}
            checkStreamingDestinationAvailableToSelect={checkStreamingDestinationAvailableToSelect}
            getDeviceById={getDeviceById}
            onSelect={handleSelect}
            onDelete={onDelete}
          />
        );
      })}
    </Stack>
  );
}
