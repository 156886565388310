import React, {useRef, useState} from 'react';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {VARIANT} from 'app/constants';
import Auth from '@aws-amplify/auth';
import {noop} from 'app/util/noop';
import {Callback} from 'app/types/common';
import {useComponentWillUnmount} from 'app/hooks/useComponentWillUnmount';

const LOCK_RESEND_BUTTON_TIMEOUT_MS = 5000;

function errorProcessor(error) {
  switch (error.code) {
    case 'LimitExceededException':
      return {
        name: 'common',
        message: 'Attempt limit exceeded, please try after some time',
      };
    default:
      return {
        name: 'common',
        message: error.message,
      };
  }
}

interface Props {
  username: string;
  onClick: Callback;
  onSuccess: Callback;
  onError: Callback;
}

export const ResendConfirmationEmailButton = ({
  username,
  onClick = noop,
  onSuccess = noop,
  onError = noop,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [locked, setLocked] = useState<boolean>(false);
  const lockResendButtonTimeout = useRef<any>(null);

  useComponentWillUnmount(() => clearTimeout(lockResendButtonTimeout.current));

  const handleClick = (): any => {
    setLoading(true);
    setLocked(true);

    onClick();

    Auth.resendSignUp(username)
      .then(() => {
        onSuccess();
      })
      .catch(error => {
        onError(errorProcessor(error));
      })
      .finally(() => {
        setLoading(false);

        lockResendButtonTimeout.current = setTimeout(() => {
          setLocked(false);
        }, LOCK_RESEND_BUTTON_TIMEOUT_MS);
      });
  };

  return (
    <ProgressButton
      variant={VARIANT.LINK}
      loading={loading}
      disabled={locked}
      dataId="resend_confirmation_email"
      onClick={handleClick}
    >
      Resend it
    </ProgressButton>
  );
};
