import React, {Fragment} from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';

enum STACK_DIRECTION {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

interface Props extends ClassName {
  children: React.ReactNode;
  separatorComponent?: React.ReactNode;
  direction?: STACK_DIRECTION;
}

export const Stack: React.FC<Props> = ({
  className,
  children,
  separatorComponent,
  direction = STACK_DIRECTION.VERTICAL,
}) => {
  if (React.Children.count(children) === 0) {
    return null;
  }

  let hasVisibleItems = false;

  React.Children.forEach(children, item => {
    if (React.isValidElement(item)) {
      hasVisibleItems = true;
    }
  });

  if (hasVisibleItems === false) {
    return null;
  }

  let firstItemAdded = false;

  return (
    <div className={classNames('stack', `stack--${direction}`, className)}>
      {flattenChildren(children).map((child: any, index) => { // rework child type
        if (React.isValidElement(child) === false) {
          return null;
        }

        if (separatorComponent && index > 0 && firstItemAdded) {
          return (
            <Fragment key={child.key}>
              <div className="stack__separator">
                {separatorComponent}
              </div>

              <div className="stack__item">
                {child}
              </div>
            </Fragment>
          );
        }

        firstItemAdded = true;

        return (
          <div
            key={child.key}
            className="stack__item"
          >
            {child}
          </div>
        );
      })}
    </div>
  );
};

const StackHorizontal: React.FC<Props> = (props) => (
  <Stack
    {...props}
    direction={STACK_DIRECTION.HORIZONTAL}
  />
);

export {
  STACK_DIRECTION,
  StackHorizontal,
};
