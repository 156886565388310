import React from 'react';
import {Button} from '@mui/material';
import {blue, deepPurple, purple} from '@mui/material/colors';
import {ProductCard} from 'app/components/FleetManager/EdgeWelcome/products/ProductCard/ProductCard';
import {Sx} from 'app/types/common';
import {router} from 'app/router/main';
import {packSx} from 'app/util/packSx/packSx';
import {buttonSx} from 'app/components/FleetManager/EdgeWelcome/products/buttonStyles';

interface Props extends Sx {}

export function ConnectProduct({sx}: Props) {
  return (
    <ProductCard
      sx={packSx(
        {
          backgroundImage: `linear-gradient(160deg, ${purple.A200} -15%, ${blue[800]} 45%, ${deepPurple[800]} 142%)`,
        },
        sx,
      )}
      product="Epiphan Connect"
      dataId="connect-card"
      title={
        <>
          Produce broadcast-
          <br />
          quality content with
          <br />
          Microsoft Teams & Zoom
        </>
      }
      image={
        <img
          src="/assets/img/welcome/connect.png"
          srcSet="/assets/img/welcome/connect@2x.png 2x"
          height="110"
          width="198"
          alt=""
        />
      }
      description={
        <>
          Extract your meeting
          <br />
          participants and transport
          <br />
          them into your branded
          <br />
          productions in Full HD.
        </>
      }
      link="https://www.epiphan.com/connect/"
      action={
        <Button
          data-id="go-connect-button"
          sx={buttonSx}
          href={router.url('meetings')}
          variant="outlined"
        >
          Start now
        </Button>
      }
    />
  );
}
