import React, {useCallback, useMemo} from 'react';
import {DataId, Sx} from 'app/types/common';
import {Box, Button, Divider, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import WifiIcon from '@mui/icons-material/Wifi';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CloseIcon from '@mui/icons-material/Close';
import {ChannelForm} from 'app/components/sharedReactComponents/Events/Editor/EventForm/ChannelForm/ChannelForm';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {
  ChannelAction,
  ChannelFields,
  Destination,
  DestinationType,
  MediaSummary,
} from 'app/components/sharedReactComponents/Events/Editor/EventForm/types';

interface Props extends Sx, DataId {
  device: PearlMasterDeviceModel;
  deviceState: Map<string, ChannelFields>;
  destinations: Destination[];
  removable?: boolean;
  setChannelAction: (
    deviceId: string,
    channelIdx: string,
    type: ChannelAction,
    value: boolean,
  ) => void;
  selectChannelDestination: (
    deviceId: string,
    channelIdx: string,
    type: DestinationType,
    destinationId: string,
  ) => void;
  onRemove: (deviceId: string) => void;
}

export function DeviceForm({
  dataId,
  sx,
  device,
  deviceState,
  destinations,
  removable = true,
  setChannelAction,
  selectChannelDestination,
  onRemove,
}: Props) {
  const deviceId = device.getId();
  const channels = device.getChanelModels();
  const hasChannels = channels.length > 0;
  const isOnline = device.isOnline();

  const summary = useMemo<MediaSummary>(() => {
    let [streaming, recording] = [0, 0];

    Array.from(deviceState.values()).forEach((s) => {
      if (s.recording) {
        recording += 1;
      }

      if (s.streaming) {
        streaming += 1;
      }
    });

    return {
      streaming,
      recording,
    };
  }, [deviceState]);

  const handleRemove = useCallback(() => {
    onRemove(deviceId);
  }, [deviceId, onRemove]);

  const handleMediaAction = useCallback(
    (channelIdx: string, type: ChannelAction, value: boolean) => {
      setChannelAction(deviceId, channelIdx, type, value);
    },
    [setChannelAction, deviceId],
  );

  const handleSelectDestination = useCallback(
    (channelIdx: string, type: DestinationType, destId: string) => {
      selectChannelDestination(deviceId, channelIdx, type, destId);
    },
    [selectChannelDestination, deviceId],
  );

  const renderSummary = () => {
    if (!isOnline) {
      return (
        <Typography data-id="device-offline" color={grey[500]}>
          Offline
        </Typography>
      );
    }

    if (!summary.recording && !summary.streaming) {
      return (
        <Typography data-id="no-actions-message" color={(t) => t.palette.error.main}>
          No selected actions
        </Typography>
      );
    }

    return (
      <Stack direction="row" alignItems="center" gap={2}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <WifiIcon sx={{transform: 'rotate(90deg)'}} fontSize="small" color="primary" />
          <Typography data-id="streaming-total">{summary.streaming}</Typography>
        </Stack>

        <Stack direction="row" alignItems="center" gap={0.5}>
          <FiberManualRecordIcon fontSize="small" color="error" />
          <Typography data-id="recording-total">{summary.recording}</Typography>
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack
      data-id={dataId}
      sx={sx}
      border={1}
      borderColor={grey[500]}
      bgcolor={grey[50]}
      borderRadius={1}
      px={4}
      py={2}
      gap={2}
      divider={<Divider flexItem />}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Stack
          direction="row"
          mr="auto"
          gap={2}
          divider={<Divider orientation="vertical" flexItem />}
          minWidth={0}
        >
          <Typography data-id="device-name" component="span" fontWeight={600} minWidth={0}>
            <OverflowTooltip>{device.getName()}</OverflowTooltip>
          </Typography>

          {renderSummary()}
        </Stack>

        {removable && (
          <Button
            sx={{flexShrink: 0}}
            data-id="remove-device"
            variant="text"
            color="error"
            startIcon={<CloseIcon />}
            onClick={handleRemove}
          >
            Remove
          </Button>
        )}
      </Stack>

      <Stack divider={<Divider sx={{borderStyle: 'dashed'}} flexItem />} gap={2}>
        {channels.map((c) => {
          const channelIdx = c.getChannelDeviceIdIndex();
          const map = deviceState.get(channelIdx);

          if (!map) {
            return null;
          }

          return (
            <ChannelForm
              key={channelIdx}
              channel={c}
              state={map}
              online={isOnline}
              destinations={destinations}
              selectDestination={handleSelectDestination}
              setMediaAction={handleMediaAction}
            />
          );
        })}

        {!hasChannels && (
          <Box p={3} bgcolor={grey[100]} border={1} borderRadius={1} borderColor={grey[500]}>
            <Typography color={grey[700]}>No created channels for this device</Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  );
}
