import React from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {Component} from 'app/types/common';
import {useSrtCount} from 'app/hooks/useSrtCount';
import {Entity} from 'app/store/models/connect/Meeting/Meeting';
import {MeetingSrtSummary} from 'app/components/sharedReactComponents/MeetingSrtSummary/MeetingSrtSummary';

interface Props extends Component {
  entities: Entity[];
  label?: string;
  message?: string;
}

export const MeetingCountColumn = observer(({className, entities, dataId}: Props) => {
  const count = useSrtCount(entities);
  const hasActiveStreams = Object.values(count).some((v) => v.value > 0);

  if (hasActiveStreams) {
    return <MeetingSrtSummary className={className} dataId={dataId} label="SRT:" count={count} />;
  }

  return (
    <div data-id={dataId} className={classNames('meeting-count-column ', className)}>
      SRT: no active connections
    </div>
  );
});
