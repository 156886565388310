import React from 'react';
import classNames from 'classnames';
import {Button} from 'app/components/sharedReactComponents/Button';
import {useToggle} from 'app/hooks/useToggle';
import {Caret, CARET_VARIANT} from 'app/components/sharedReactComponents/Caret';
import {SIZE} from 'app/constants';

interface Props {
  team: string;
}

export const PairingDetails = ({team}: Props) => {
  const [opened, setOpened] = useToggle(false);

  return (
    <div className={classNames('pairing-details')}>
      <div>To pair, you'll need to go through two steps:</div>
      <ol className="pairing-details__steps">
        <li>
          We will confirm your identity and verify if your Microsoft Teams organization account has
          administrative access.
        </li>

        <li>
          You will grant consent for the Epiphan Connect application to connect to meetings in your
          Microsoft Teams organization. You'll also grant approval for the team{' '}
          <strong>{team}</strong> to use Epiphan Connect in your Microsoft Teams organization.
        </li>
      </ol>
      <Button
        dataId="consenting_details"
        className={classNames(
          'pairing-details__toggle',
          opened && 'pairing-details__toggle--active',
        )}
        onClick={setOpened}
      >
        <Caret variant={CARET_VARIANT.THIN} size={SIZE.S} opened={opened} />
        <span>What am I consenting to?</span>
      </Button>
      {opened && (
        <div className="pairing-details__consent">
          By linking the team <strong>{team}</strong> to your Microsoft Teams organization, Epiphan
          Connect will be able to:
          <ul>
            <li>Join group meetings organized in your organization.</li>

            <li>
              Capture and extract the audio and video shared in the meeting including participant
              cameras, microphones and their screen when shared in the meeting.
            </li>

            <li>
              Read the name of the meeting through the chats APIs. This will not grant access to the
              contents or chat messages of your Microsoft organization.
            </li>
          </ul>
          <span className="pairing-details__security">
            If you need more information on how Epiphan secures your
            <br />
            data, view our{' '}
            <a
              data-id="security_policy_link"
              href="https://www.epiphan.com/download/TE-Security-and-data-privacy-in-Epiphan-Connect_01.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Security and Privacy document.
            </a>
          </span>
        </div>
      )}
      <div className="pairing-details__redirect">
        You'll be redirected to the Microsoft setup area to continue the connection. The pages will
        refresh several times during this process.
      </div>
    </div>
  );
};
