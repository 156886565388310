import {Store} from 'app/util/Store';

interface Params {
  storeKey: string;
  fixedColumns?: string[];
  defaultActiveColumns?: string[];
}

export class ColumnActiveManager {
  _store: Store;
  _fixedColumns: string[];
  _items: Set<string>;

  constructor({
    storeKey,
    fixedColumns = [],
    defaultActiveColumns = [],
  }: Params) {
    this._store = new Store({
      storeKey: `${storeKey}.Active`,
      defaultValue: defaultActiveColumns,
    });

    this._fixedColumns = fixedColumns;

    this._items = new Set<string>(this._load());
  }

  _load() {
    return this._fixedColumns.concat(this._store.load());
  }

  _save() {
    this._store.save(this._toJSON());
  }

  _toJSON(): any[] {
    return Array.from(this._items);
  }

  enable(id): void {
    this._items.add(id);
    this._save();
  }

  disable(id) {
    this._items.delete(id);
    this._save();
  }

  toggle(id: string, enable: boolean) {
    if (enable === true) {
      this.enable(id);
    } else {
      this.disable(id);
    }
  }

  getActiveIds() {
    return this._toJSON();
  }

  isActive(id: string) {
    return this._items.has(id);
  }
}
