import {Role} from 'app/models/PermissionsModel/Role';

export class Owner extends Role {
  constructor() {
    super('owner');

    this.permissions.devices.view = true;
    this.permissions.devices.reboot = true;
    this.permissions.teams.edit = true;
    this.permissions.teams.delete = true;
    this.permissions.teams.owner.edit = true;
    this.permissions.billing.view = true;
    this.permissions.billing.edit = true;
    this.permissions.presets.apply = true;
    this.permissions.streamingDestinations.edit = true;
    this.permissions.accountSettings.view = true;
    this.permissions.unify.manage = true;
    this.permissions.connect.pairing = true;
  }
}
