import React from 'react';
import {Button} from '@mui/material';
import {blue, indigo} from '@mui/material/colors';
import {ProductCard} from 'app/components/FleetManager/EdgeWelcome/products/ProductCard/ProductCard';
import {router} from 'app/router/main';
import {Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {buttonSx} from 'app/components/FleetManager/EdgeWelcome/products/buttonStyles';

interface Props extends Sx {}

export function EdgeProduct({sx}: Props) {
  return (
    <ProductCard
      sx={packSx(
        {
          backgroundImage: `radial-gradient(286% 166% at 27% -22%, ${blue[300]} 0%, ${blue[800]} 34%, ${indigo[900]} 100%)`,
        },
        sx,
      )}
      product="Epiphan Edge"
      dataId="edge-card"
      title={
        <>
          Connect and control a<br />
          device remotely
        </>
      }
      image={
        <img
          src="/assets/img/welcome/cloud.png"
          srcSet="/assets/img/welcome/cloud@2x.png 2x"
          height="101"
          width="214"
          alt=""
        />
      }
      description={
        <>
          Remotely configure and
          <br />
          manage your devices,
          <br />
          including batch operations
          <br />
          and CMS events.
        </>
      }
      link="https://www.epiphan.com/cloud/"
      action={
        <Button data-id="pair-button" sx={buttonSx} href={router.url('welcome')} variant="outlined">
          Pair device
        </Button>
      }
    />
  );
}
