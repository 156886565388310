import React, {useMemo, useRef} from 'react';
import {QRCode} from 'app/components/sharedReactComponents/QRCode';
import {CopyButton} from 'app/components/sharedReactComponents/CopyButton';
import {TranscribeDeviceUrl} from 'app/components/DeviceDetails/TranscriberDevice/DeviceUrl/TranscribeDeviceUrl';

interface Props {
  url: string;
}

export const TranscriberStreamUrl: React.VFC<Props> = ({
  url,
}) => {
  const qrCodeCanvasRef = useRef(null);

  const formattedUrl = useMemo(() => {
    let formattedUrl = url || '';

    // Add protocol to base URL
    if (formattedUrl.includes('//') === false) {
      formattedUrl = `https://${formattedUrl}`;
    }

    return formattedUrl;
  }, [url]);

  return (
    <div className="transcriber-stream-url">
      <TranscribeDeviceUrl
        url={formattedUrl}
        emptyUrl={url === null}
      />

      <div className="transcriber-stream-url__container">
        <QRCode
          data-id="transcriber_qr_code"
          canvasRef={qrCodeCanvasRef}
          value={formattedUrl}
        />

        <CopyButton
          className="transcriber-stream-url__copy-button"
          data-id="copy_code_button"
          valueCanvas={qrCodeCanvasRef}
        />
      </div>
    </div>
  );
};
