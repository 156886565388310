import React, {useCallback, useEffect, useState} from 'react';
import {Stack} from '@mui/material';
import {DevicePublisherModel} from 'app/models/Device/Publisher/DevicePublisherModel';
import {CommonStreamListItem} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStreamingPanel/DeviceStreamingDestinationList/CommonStreamListItem';
import {isPromise} from 'app/util/isPromise';
import {useMounted} from 'app/hooks/useIsMounted';
import {Callback, Sx} from 'app/types/common';
import {COMMON_STREAM_SELECT_INPUT_TYPE} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStreamingPanel/DeviceStreamingDestinationList/SelectInput';
import {DeviceWarning} from 'app/components/DeviceDetails/Models/types';
import {DeviceWarningId} from 'app/components/DeviceDetails/constants';

interface PublisherProps {
  disabled: boolean;
  offline: boolean;
  selected: boolean;
  publisher: DevicePublisherModel;
  warnings: DeviceWarning[];
  onSelect: Callback;
}

function PublisherItem({
  disabled,
  offline,
  selected,
  publisher,
  warnings,
  onSelect,
}: PublisherProps) {
  const [selecting, setSelecting] = useState(false);

  const isMounted = useMounted();

  const handleSelect = useCallback(
    (event) => {
      const promise = onSelect(event);

      if (isPromise(promise)) {
        setSelecting(true);
        promise.finally(() => {
          if (isMounted()) {
            setSelecting(false);
          }
        });
      }
    },
    [onSelect, isMounted],
  );

  const startStopAction = useCallback(() => {
    if (publisher.isStarted()) {
      return publisher.stopStreaming();
    }

    return publisher.startStreaming();
  }, [publisher]);

  const started = publisher.isStarted();
  const streaming = publisher.isStreaming();
  const startTime = publisher.getStartTime();
  const failed = publisher.isFailed();

  return (
    <CommonStreamListItem
      id={publisher.getId()}
      name={publisher.getName()}
      offline={offline}
      selected={selected}
      disabled={disabled}
      started={started}
      streaming={streaming}
      selecting={selecting}
      startTime={startTime!}
      hasError={failed}
      warnings={warnings}
      selectInputType={COMMON_STREAM_SELECT_INPUT_TYPE.CHECKBOX}
      startStopAction={startStopAction}
      onSelect={handleSelect}
    />
  );
}

interface Props extends Sx {
  offline: boolean;
  started: boolean;
  publishers: DevicePublisherModel[];
  selectedPublishers: Set<string>;
  warnings: DeviceWarning[];
  onSelect: Callback;
}

export function DevicePublisherList({
  sx,
  offline,
  started,
  publishers,
  selectedPublishers,
  warnings,
  onSelect,
}: Props) {
  const {getWarnings} = usePublisherWarnings(warnings);

  return (
    <Stack sx={sx} gap={1}>
      {publishers.map((p) => {
        const publisherId = p.getId();

        return (
          <PublisherItem
            key={publisherId}
            disabled={started}
            offline={offline}
            selected={selectedPublishers.has(publisherId)}
            publisher={p}
            warnings={getWarnings(publisherId)}
            onSelect={onSelect}
          />
        );
      })}
    </Stack>
  );
}

interface Return {
  getWarnings: (publisherId: string) => DeviceWarning[];
  hasWarnings: (publisherId: string) => boolean;
}

// TODO: to separated file
export function usePublisherWarnings(warnings: DeviceWarning[]): Return {
  const [map, setMap] = useState<Map<string, DeviceWarning[]>>(new Map());

  useEffect(() => {
    const filtered = warnings.filter((w) => w.id === DeviceWarningId.PublisherError);

    const result = filtered.reduce((acc, warning) => {
      const {data} = warning;

      if (data?.publisher_id) {
        const id = data.publisher_id;

        const array = acc.get(id);

        acc.set(id, array ? [...array, warning] : [warning]);
      }

      return acc;
    }, new Map<string, DeviceWarning[]>());

    setMap(result);
  }, [warnings]);

  const getWarnings = useCallback(
    (id: string) => {
      return map.get(id) ?? [];
    },
    [map],
  );

  return {
    getWarnings,
    hasWarnings: map.has.bind(map),
  };
}
