import React from 'react';
import {Prompt} from 'app/types/common';
import {Box, Dialog, DialogContent, Stack, Typography} from '@mui/material';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';

interface Props extends Prompt {
  onPair: Fn;
}

export function ZoomIntegrationDialog({open, onPair, onClose}: Props) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogClose onClose={onClose} />

      <DialogContent sx={{p: 7}}>
        <Box
          mb={3}
          component="img"
          src="/assets/img/logo/zoom-logo.png"
          srcSet="/assets/img/logo/zoom-logo@2x.png 2x"
          alt=""
        />

        <Typography mb={2} variant="h5" fontWeight="bold">
          You will be redirected to the Zoom authentication process
        </Typography>

        <Typography mb={0.5}>You'll need to sign in with your Zoom account.</Typography>

        <Typography mb={0.5}>
          Zoom might prompt you to give permissions to allow Epiphan Connect to see the basic
          information about your user account.
        </Typography>

        <Typography mb={0.5}>
          After the sign in process is complete, you will be able to configure Epiphan Connect to
          use this account to join meetings that require a signed-in user to join.
        </Typography>

        <Typography> You can remove this account at any time.</Typography>

        <Stack mt={4} direction="row">
          <Button
            dataId="continue-auth"
            variant={VARIANT.SOLID}
            theme={THEME.SUCCESS}
            onClick={onPair}
          >
            Continue
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
