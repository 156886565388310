import React, {useState} from 'react';
import {Button, Tooltip} from '@mui/material';
import {Sx} from 'app/types/common';
import {StartEventDialog} from 'app/components/sharedReactComponents/Events/dialogs/StartEventDialog/StartEventDialog';

const offlineTooltip = 'Device is offline';
const notNextTooltip = 'Ongoing or not next event for the device';

interface Props extends Sx {
  eventName: string;
  nextInSchedule: boolean;
  offline: boolean;
  onStart(): Promise<void>;
}

export function ScheduledControl({sx, eventName, offline, nextInSchedule, onStart}: Props) {
  const [dialog, setDialog] = useState(false);

  const handleStart = async () => {
    await onStart();
    setDialog(false);
  };

  return (
    <>
      <StartEventDialog
        open={dialog}
        name={eventName}
        onStart={handleStart}
        onClose={() => setDialog(false)}
      />

      <Tooltip title={offline ? offlineTooltip : !nextInSchedule ? notNextTooltip : undefined}>
        <span>
          <Button
            sx={sx}
            data-id="start-button"
            variant="contained"
            disabled={offline || !nextInSchedule}
            onClick={() => setDialog(true)}
          >
            Start
          </Button>
        </span>
      </Tooltip>
    </>
  );
}
