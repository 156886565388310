import React from 'react';
import {Box, Link, Stack} from '@mui/material';
import {Sx} from 'app/types/common';
import LockIcon from '@mui/icons-material/Lock';
import {palette} from 'app/themes/app';

export function SecureMessage({sx}: Sx) {
  return (
    <Box sx={sx}>
      <Stack direction="row" alignItems="center" gap={0.5} fontSize={12}>
        <LockIcon fontSize="small" sx={{color: palette.gray5}} />
        <Box sx={{color: palette.gray6}} component="span">
          Epiphan Connect keeps your data secure.{' '}
          <Link
            data-id="secure-policy-link"
            sx={{color: 'inherit', textDecorationColor: 'currentcolor'}}
            href="https://www.epiphan.com/download/TE-Security-and-data-privacy-in-Epiphan-Connect_01.pdf"
            target="_blank"
          >
            Learn More
          </Link>
        </Box>
      </Stack>
    </Box>
  );
}
