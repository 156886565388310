import React from 'react';
import {Sx} from 'app/types/common';
import {Edge} from 'app/domain/edge';
import {Box, Grid, Typography} from '@mui/material';
import {VideoInput} from 'app/components/DeviceDetails/Inputs/InputsPanel/VideoInput/VideoInput';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {AudioInput} from 'app/components/DeviceDetails/Inputs/InputsPanel/AudioInput/AudioInput';

interface Props extends Sx {
  device: PearlMasterDeviceModel;
  sources: Edge.Sources;
}

export function InputsPanel({sx, device, sources}: Props) {
  const hasVideo = sources.video.length > 0;
  const hasAudio = sources.audio.length > 0;

  return (
    <Box sx={sx}>
      <Typography fontWeight={600} variant="h6" mb={1}>
        Video inputs
      </Typography>

      {hasVideo ? (
        <Grid container={true} data-id="video-inputs" spacing={2.5}>
          {sources.video.map((video) => (
            <Grid key={video.id} item={true} xs={6}>
              <VideoInput dataId={video.id} device={device} input={video} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No video inputs</Typography>
      )}

      <Typography fontWeight={600} variant="h6" mt={3} mb={1}>
        Audio inputs
      </Typography>

      {hasAudio ? (
        <Grid container={true} data-id="audio-inputs" spacing={2.5}>
          {sources.audio.map((audio) => (
            <Grid key={audio.id} item={true} xs={6}>
              <AudioInput dataId={audio.id} device={device} input={audio} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No audio inputs</Typography>
      )}
    </Box>
  );
}
