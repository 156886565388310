import {front} from 'app/api/CirrusApi';
import {TeamContracts} from 'app/components/entities/team/models';

export class TeamApi {
  static async getMembers(teamId: string): Promise<TeamContracts.Member[]> {
    return front.teams(teamId).users().get();
  }

  static async removeMember(teamId: string, userId: string): Promise<void> {
    return front.teams(teamId).users(userId).delete();
  }

  static async setMemberRole(
    teamId: string,
    userId: string,
    role: 'admin' | 'operator',
  ): Promise<void> {
    return front
      .teams(teamId)
      .users(userId)
      .put({Role: role === 'admin' ? 'owner' : role});
  }

  static async transferOwnership(
    teamId: string,
    userId: string,
    savePayment: boolean,
  ): Promise<void> {
    return front.teams(teamId).users(userId).master().put({KeepCard: savePayment});
  }
}
