import {minutesToMilliseconds} from 'app/util/timeConverter';
import {TimeStampMilliseconds} from 'app/types/common';

const DEFAULT_GARBAGE_COLLECTION_TIMEOUT = minutesToMilliseconds(5);

export class Cache {
  protected expireMs: number;
  protected garbageCollectionTimeout: number;
  protected storage: Map<string, any> = new Map();
  protected garbageCollectionTimeoutId: number;

  constructor(expireMs, garbageCollectionTimeout = DEFAULT_GARBAGE_COLLECTION_TIMEOUT) {
    this.expireMs = expireMs;
    this.garbageCollectionTimeout = garbageCollectionTimeout;

    this.garbageCollectionTimeoutId = window.setTimeout(() => {
      this.garbageCollection();
    }, this.garbageCollectionTimeout);
  }

  get(key: string): any {
    if (this.storage.get(key) && this.isExpired(Date.now(), key)) {
      this.unset(key);
      return undefined;
    }

    if (this.storage.get(key)) {
      return this.storage.get(key).value;
    }

    return undefined;
  }

  set(key: string, value: any) {
    this.storage.set(key, {
      time: Date.now(),
      value,
    });
  }

  unset(key: string) {
    this.storage.delete(key);
  }

  getOrElse(key: string, elseValue: () => any): any {
    if (!this.storage.has(key) || this.isExpired(Date.now(), key)) {
      this.set(key, elseValue());
    }

    return this.get(key);
  }

  length(): number {
    return this.storage.size;
  }

  destroy() {
    window.clearTimeout(this.garbageCollectionTimeoutId);
  }

  private isExpired(now: TimeStampMilliseconds, key: string): boolean {
    return (now - this.storage.get(key).time) > this.expireMs;
  }

  private removeOldValues() {
    const now = Date.now();

    for (const [key] of this.storage) {
      if (this.isExpired(now, key)) {
        this.unset(key);
      }
    }
  }

  private garbageCollection() {
    this.removeOldValues();
    this.garbageCollectionTimeoutId = window.setTimeout(() => {
      this.garbageCollection();
    }, this.garbageCollectionTimeout);
  }
}
