import React from 'react';
import {Callback} from 'app/types/common';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {Icons} from 'app/util/icons';
import {proTier} from 'app/util/billing/utils';
import {router} from 'app/router/main';
import {pluralizeN} from 'app/components/helpers/commonHelpers';

interface Props {
  tierId: string;
  pairedCount?: number;
  maxDevices: number;
  canEditBilling: boolean;
  teamId?: string;
  onConfirm: Callback;
}

export function PairDeviceWarningMessage({
  tierId,
  pairedCount = 0,
  maxDevices,
  canEditBilling,
  onConfirm,
  teamId,
}: Props) {
  const showDeviceCount = pairedCount > 0;

  const changePlanUrl = (): string => {
    return teamId
      ? router.urlToTeamRoute(teamId, router.url('settingBillingUsage'))
      : router.url('settingBillingUsage');
  };

  const renderCountMessage = () => {
    return (
      <div className="pair-device-warning-message__paired-count">
        {showDeviceCount && (
          <div className="pair-device-warning-message__paired-count-item">
            {Icons.deviceCircuit()
              .label(`${pairedCount} Pearl ${pluralizeN('device', pairedCount)} paired`)
              .theme(THEME.WHITE)
              .reactComponent()}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="pair-device-warning-message">
      <div className="pair-device-warning-message__warning">
        <div className="pair-device-warning-message__main-message">
          Your team has used all {maxDevices} seat(s) configured in your plan
        </div>

        {renderCountMessage()}
      </div>

      <div className="pair-device-warning-message__description">
        {proTier(tierId) ? (
          <>
            Increase the number of Edge seats in your team billing plan,
            <br />
            or unpair any offline Pearl devices, then try again.
          </>
        ) : (
          <>Change plan or unpair unused or offline devices and try again.</>
        )}
      </div>

      {canEditBilling ? (
        <>
          {proTier(tierId) ? (
            <Button
              className="pair-device-warning-message__button"
              theme={THEME.PRIMARY}
              variant={VARIANT.SOLID}
              onClick={onConfirm}
            >
              I want to add an Edge seat now
            </Button>
          ) : (
            <Button
              className="pair-device-warning-message__button"
              theme={THEME.PRIMARY}
              variant={VARIANT.SOLID}
              href={changePlanUrl()}
            >
              Change plan
            </Button>
          )}
        </>
      ) : (
        <div className="pair-device-warning-message__permission-info">
          Contact team owner to upgrade license.
        </div>
      )}
    </div>
  );
}
