import React from 'react';
import {Modal} from 'app/components/sharedReactComponents/Modal/Modal';
import {UnexpectedError} from 'app/components/sharedReactComponents/Pairing/UnexpectedError/UnexpectedError';

interface Props {
  text?: string;
}

export const UnexpectedErrorPrompt = {
  show({text}: Props) {
    Modal.show({
      children: <UnexpectedError text={text} />,
    });
  },
};
