import React, {Component, createRef} from 'react';
import classNames from 'classnames';
import {LoadingWrapper} from 'app/components/sharedReactComponents/LoadingWrapper';
import {SIZE} from 'app/constants';
import {CheckboxBase} from 'app/components/sharedReactComponents/Checkbox/CheckboxBase';
import {Callback, ClassName, DataId} from 'app/types/common';

/**
 * @readonly
 * @enum {string}
 */
export enum CHECKBOX_THEME {
  DEFAULT = 'default',
  BLACK = 'black',
  SILVER_SAND = 'silver-sand',
}
// rework
interface Props extends ClassName, DataId {
  name?: string;
  value?: boolean;
  autoFocus?: boolean;
  loading?: boolean;
  focusable?: boolean;
  disabled?: boolean;
  hovered?: boolean;
  indeterminate?: boolean;
  highlightOnHover?: boolean;
  theme?: CHECKBOX_THEME;
  size?: SIZE;
  label?: React.ReactNode;
  onChange?: Callback;
  onBlur?: Callback;
  onClick?: Callback;
}

export class Checkbox extends Component<Props> {
  public inputRef = createRef<any>();

  componentDidMount() {
    this._updateIndeterminateState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.indeterminate !== prevProps.indeterminate) {
      this._updateIndeterminateState();
    }
  }

  _updateIndeterminateState() {
    this.inputRef.current.indeterminate = this.props.indeterminate;
  }

  render() {
    const {
      dataId,
      name,
      value,
      autoFocus,
      loading = false,
      focusable,
      disabled,
      hovered,
      indeterminate,
      className,
      theme,
      size,
      label,
      highlightOnHover,
      onClick,
      onChange,
      onBlur,
      ...elementProps
    } = this.props;

    // rework
    const tabIndex = focusable === false ? -1 : undefined;

    return (
      <LoadingWrapper loading={loading}>
        <label
          data-id={dataId}
          className={classNames(
            'checkbox',
            {
              'checkbox--black': theme === CHECKBOX_THEME.BLACK,
              'checkbox--silver-sand': theme === CHECKBOX_THEME.SILVER_SAND,
              'checkbox--labeled': Boolean(label),
              'checkbox--highlight-on-hover': Boolean(highlightOnHover),
              'checkbox--hovered': Boolean(hovered),
            },
            size && `checkbox--size-${size}`,
            className,
          )}
          tabIndex={tabIndex}
          onClick={onClick}
          {...elementProps}
        >
          <CheckboxBase
            ref={this.inputRef}
            name={name}
            tabIndex={tabIndex}
            autoFocus={autoFocus}
            value={value}
            disabled={disabled || loading}
            onChange={onChange}
            onBlur={onBlur}
          />
          <span />

          {label && <span className="checkbox__label">{label}</span>}
        </label>
      </LoadingWrapper>
    );
  }
}
