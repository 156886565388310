const STORAGE_KEY_NAME = 'oneClickDevicePairing';

const PairingDeviceIdStorage = {
  hasIncompletePairingDevice() {
    return Boolean(this.load());
  },

  /**
   * @param {Object} query
   * @param {string} query.deviceId
   */
  save(query: any) {
    window.sessionStorage.setItem(STORAGE_KEY_NAME, JSON.stringify(query));
  },

  reset() {
    window.sessionStorage.removeItem(STORAGE_KEY_NAME);
  },

  load() {
    try {
      const value = window.sessionStorage.getItem(STORAGE_KEY_NAME);
      if (value) {
        return JSON.parse(value).deviceId;
      }

      return null;
    } catch (_: unknown) {
      return null;
    }
  },
};

export {
  PairingDeviceIdStorage,
};
