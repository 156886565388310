import {useCallback, useEffect, useMemo, useState} from 'react';
import {differenceOfSets} from 'app/util/differenceOfSets';
import {isSetsEquals} from 'app/util/isSetsEquals';
import {toggleInSet} from 'app/util/toggleInSet';
import {unionOfSets} from 'app/util/unionOfSets';
import {Files} from 'app/components/entities/files';

type Return = {
  selected: Files.Recording[];
  selectable: Files.Recording[];
  allSelected: boolean;
  select: (items: string | string[]) => void;
  deselect: (items: string | string[]) => void;
  isSelected: (item: string) => boolean;
  toggle: (item: string) => void;
  deselectAll: () => void;
  selectAll: () => void;
};

export function useSelectFiles(recordings: Files.Recording[]): Return {
  const [selectedSet, setSelectedSet] = useState(new Set<string>());

  const selectable = useMemo(() => recordings.filter((r) => r.uploaded), [recordings]);

  const selected = useMemo(
    () => selectable.filter((r) => selectedSet.has(r.id)),
    [selectable, selectedSet],
  );

  const allSelected = useMemo(() => {
    if (!selectable.length) {
      return false;
    }

    return selectable.filter((r) => r.uploaded).every((r) => selectedSet.has(r.id));
  }, [selectedSet, selectable]);

  useEffect(() => {
    setSelectedSet((prev) => {
      const ids = new Set(selectable.map((r) => r.id));
      const actual = Array.from(prev.values()).filter((value) => ids.has(value));
      return new Set(actual);
    });
  }, [selectable]);

  const select = useCallback((items: string | string[]) => {
    setSelectedSet((prev) => {
      const setToAdd = itemsToSet(items);
      const next = unionOfSets(prev, setToAdd);

      return isSetsEquals(next, prev) ? prev : next;
    });
  }, []);

  const deselect = useCallback((items: string | string[]) => {
    setSelectedSet((prev) => {
      const setToRemove = itemsToSet(items);
      const next = differenceOfSets(prev, setToRemove);

      return isSetsEquals(next, prev) ? prev : next;
    });
  }, []);

  const toggle = useCallback((item: string) => {
    setSelectedSet((prev) => toggleInSet(prev, item));
  }, []);

  const isSelected = useCallback((item: string) => selectedSet.has(item), [selectedSet]);

  const selectAll = useCallback(() => {
    setSelectedSet(new Set(selectable.filter((r) => r.uploaded).map((r) => r.id)));
  }, [selectable]);

  const deselectAll = useCallback(() => {
    setSelectedSet(new Set());
  }, []);

  return {
    selectable,
    selected,
    allSelected,
    select,
    deselect,
    isSelected,
    toggle,
    deselectAll,
    selectAll,
  };
}

function itemsToSet(items: string | string[]): Set<string> {
  if (Array.isArray(items)) {
    return new Set(items);
  }

  return new Set([items]);
}
