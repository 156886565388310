import React, {Fragment, useCallback} from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {SIZE, SORT_DIRECTION} from 'app/constants';
import {Callback} from 'app/types/common';

interface SortArrowProps {
  sortDirection: SORT_DIRECTION;
}

const SortArrow: React.VFC<SortArrowProps> = ({sortDirection}) => {
  let icon;

  if (sortDirection === SORT_DIRECTION.DESC) {
    icon = Icons.arrowUp();
  } else {
    icon = Icons.arrowDown();
  }

  return (
    <Fragment>
      &nbsp;
      {icon.size(SIZE.S).reactComponent()}
    </Fragment>
  );
};

interface Props {
  sortDirection: SORT_DIRECTION;
  dataKey: string;
  disableSort?: boolean;
  sortBy: string;
  // Use only with `renderAsTable={false}`
  width: string | number;
  flexGrow?: number;
  renderAsTable: boolean;
  onSort: Callback;
}

export const TableHeaderCell: React.FC<Props> = ({
  children,
  width,
  flexGrow,
  renderAsTable,
  dataKey,
  sortDirection,
  disableSort = false,
  sortBy,
  onSort,
}) => {
  const handleClick = useCallback(() => {
    if (disableSort) {
      return;
    }

    let newSortDirection = sortDirection;
    if (sortBy === dataKey) {
      newSortDirection = sortDirection === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
    }

    onSort({
      sortBy: dataKey,
      sortDirection: newSortDirection
    });
  }, [dataKey, disableSort, onSort, sortBy, sortDirection]);

  const sortable = disableSort === false;
  const active = sortBy === dataKey;
  const showSortArrow = sortable && active;

  const CellComponent = renderAsTable ? 'th' : 'div';
  let style;
  if (!renderAsTable) {
    style = {
      flex: `${flexGrow || 0} 1 ${width || 100}px`,
    };
  }

  return (
    <CellComponent
      className={classNames('dashboard-table__header-cell', {
        'dashboard-table__header-cell--sortable': sortable,
        'dashboard-table__header-cell--active': active,
      })}
      data-id={`table_header_cell_${dataKey}`}
      style={style}
      onClick={handleClick}
    >
      <span>
        {children}
      </span>

      {showSortArrow && <SortArrow sortDirection={sortDirection}/>}
    </CellComponent>
  );
};
