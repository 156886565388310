import React, {useState} from 'react';
import {Stack} from '@mui/material';
import {
  StreamBatchActionControl,
  RecordBatchActionControl,
  TranscribeBatchActionControl,
} from 'app/components/FleetManager/BatchActionsPanel/BatchActionControl';
import {Sx} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {useBatchMultimedia} from 'app/components/features/edge/BatchDevices/MultimediaControls/useBatchMultimedia';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {EdgeContract} from 'app/contracts/edge';
import {LiveScryptDeviceModel} from 'app/components/DeviceDetails/Models/LiveScryptDeviceModel';

type DeviceWaning = {
  deviceName: string;
  message: string;
};

type MediaWarnings = {
  recording: DeviceWaning[];
  transcribing: DeviceWaning[];
};

type Props = Sx & {
  devices: AnyDeviceModelType[];
};

export function MultimediaControls({sx, devices}: Props) {
  const {streaming, recording, transcribing} = useBatchMultimedia(devices);

  const [warnings, setWarnings] = useState<MediaWarnings>(() => ({
    recording: [],
    transcribing: [],
  }));

  const handleStartStreaming = async () => {
    return Promise.all(streaming.stopped.map((device) => device.startStreaming()));
  };

  const handleStopStreaming = async () => {
    return Promise.all(streaming.started.map((device) => device.stopStreaming()));
  };

  const handleStartRecording = async () => {
    setWarnings((prev) => ({...prev, recording: []}));

    const response = await runTask('recording.start', recording.stopped);

    setWarnings((prev) => ({...prev, recording: collectWarnings(response, recording.stopped)}));
  };

  const handleStopRecording = async () => {
    setWarnings((prev) => ({...prev, recording: []}));

    const response = await runTask('recording.stop', recording.started);

    setWarnings((prev) => ({...prev, recording: collectWarnings(response, recording.started)}));
  };

  const handleStartTranscribing = async () => {
    return Promise.all(
      transcribing.stopped.map((device) => {
        if (device instanceof LiveScryptDeviceModel) {
          return device.startTranscribing();
        }

        return Promise.resolve();
      }),
    );
  };

  const handleStopTranscribing = async () => {
    return Promise.all(
      transcribing.stopped.map((device) => {
        if (device instanceof LiveScryptDeviceModel) {
          return device.stopTranscribing();
        }

        return Promise.resolve();
      }),
    );
  };

  return (
    <Stack sx={sx} direction="row" alignItems="center" gap={3}>
      <StreamBatchActionControl
        dataId="stream_batch_action_control"
        warnings={[]}
        canStart={streaming.stopped.length > 0 && streaming.selectedDestinations}
        canStop={streaming.started.length > 0}
        startAction={handleStartStreaming}
        stopAction={handleStopStreaming}
      />

      <RecordBatchActionControl
        dataId="record_batch_action_control"
        warnings={warnings.recording}
        canStart={recording.stopped.length > 0}
        canStop={recording.started.length > 0}
        startAction={handleStartRecording}
        stopAction={handleStopRecording}
      />

      <TranscribeBatchActionControl
        dataId="transcribe_batch_action_control"
        warnings={warnings.transcribing}
        canStart={transcribing.stopped.length > 0}
        canStop={transcribing.started.length > 0}
        startAction={handleStartTranscribing}
        stopAction={handleStopTranscribing}
      />
    </Stack>
  );
}

async function runTask(command: string, devices: AnyDeviceModelType[]) {
  const ids = devices.map((device) => device.getId());
  return DeviceApiService.sendBatchTask(command, ids);
}

function collectWarnings(response: EdgeContract.BatchResponse, devices: AnyDeviceModelType[]) {
  const map = new Map(devices.map((device) => [device.getId(), device]));

  return response.reduce<DeviceWaning[]>((acc, result) => {
    if (result.Error) {
      const deviceName = map.get(result.Device)?.getName() || 'Unknown device';

      const warning: DeviceWaning = {
        deviceName,
        message: result.Error,
      };

      return [...acc, warning];
    }

    return acc;
  }, []);
}
