import {useCallback, useEffect, useState} from 'react';
import {FilterSwitchGroupId} from 'app/components/FleetManager/useFilterSwitches';
import {stringComparator} from 'app/util/Sort';
import {toggleInSet} from 'app/util/toggleInSet';
import {FilterSwitch} from 'app/components/sharedReactComponents/FilterSelector/types';
import {Edge} from 'app/domain/edge';
import {isNil} from 'app/util/isNil';
import {containsSearchString, filterPresets} from 'app/components/FleetManager/utils';

export const deviceModelFilterGroups = [
  {
    id: FilterSwitchGroupId.Model,
    label: 'Device model',
  },
];

function createDeviceModelNameFilter(modelName: string): FilterSwitch {
  return {
    id: modelName,
    group: FilterSwitchGroupId.Model,
    label: modelName,
    callback: (preset: Edge.TeamPreset) => preset.device === modelName,
  };
}

interface Return {
  filtered: Edge.TeamPreset[];
  search: string;
  filters: FilterSwitch[];
  activeFilters: FilterSwitch[];
  activeSet: Set<string>;
  setSearch: (value: string) => void;
  toggleFilter: (id: string) => void;
  clearFilters: () => void;
}

export function useTeamPresetsFilters(presets?: Edge.TeamPreset[]): Return {
  const [filters, setFilters] = useState<FilterSwitch[]>([]);
  const [activeSet, setActiveSet] = useState<Set<string>>(new Set());
  const [activeFilters, setActiveFilters] = useState<FilterSwitch[]>([]);
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState<Edge.TeamPreset[]>(presets ?? []);

  useEffect(() => {
    const distinctModels = [...new Set(presets?.map(({device}) => device ?? '').filter((m) => m))];

    if (distinctModels.length) {
      const filters = distinctModels.sort(stringComparator).map(createDeviceModelNameFilter);
      return setFilters(filters);
    }

    setFilters([]);
  }, [presets]);

  useEffect(() => {
    setActiveFilters(filters.filter((f) => activeSet.has(f.id)));
  }, [activeSet, filters]);

  const trimmedSearch = search.trim();

  useEffect(() => {
    if (isNil(presets)) {
      setFiltered([]);
      return;
    }

    let result: Edge.TeamPreset[] = [...presets];

    if (trimmedSearch.length > 0) {
      result = result.filter((preset) => containsSearchString(preset.name, trimmedSearch));
    }

    if (activeFilters.length > 0) {
      result = filterPresets(result, activeFilters);
    }

    setFiltered(result);
  }, [presets, activeFilters, trimmedSearch]);

  const toggleFilter = useCallback((id: string) => {
    setActiveSet((prev) => {
      const copied = new Set(prev);
      return toggleInSet(copied, id);
    });
  }, []);

  const clearFilters = useCallback(() => {
    setActiveSet(new Set());
  }, []);

  return {
    search,
    filters,
    filtered,
    activeFilters,
    activeSet,
    setSearch,
    clearFilters,
    toggleFilter,
  };
}
