import React, {memo} from 'react';
import {Button} from 'app/components/sharedReactComponents/Button';
import {router} from 'app/router/main';
import {Icons} from 'app/util/icons';
import {THEME, VARIANT, SIZE} from 'app/constants';

interface Props {
  theme?: THEME;
  variant?: VARIANT;
}

export const PairDeviceButton: React.FC<Props> = memo(({theme, variant = VARIANT.LINK}) => {
  return (
    <Button
      dataId="pair_device_links"
      theme={theme}
      variant={variant}
      onClick={() => router.go('welcome')}
    >
      {Icons.chain().size(SIZE.S).label('Pair device').reactComponent()}
    </Button>
  );
});

PairDeviceButton.displayName = 'PairDeviceButton';
