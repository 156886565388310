import {useEffect} from 'react';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {useUnitAfuQuery} from 'app/components/entities/afu/hooks/useUnitAfuQuery';
import {Ws} from 'app/contracts/ws';

interface Args {
  deviceId: string;
  enabled: boolean;
  refetchInterval?: number;
}

export function useUnitAfu(args: Args) {
  const query = useUnitAfuQuery(args);

  const {deviceId, enabled} = args;
  const {refetch} = query;

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const onAfuChange = (message: Ws.AfuChange) => {
      if (message.Body.DeviceID === deviceId) {
        void refetch();
      }
    };

    WS.on(EVENT_TYPE.AFU_CHANGE, onAfuChange);

    return () => {
      WS.off(EVENT_TYPE.AFU_CHANGE, onAfuChange);
    };
  }, [enabled, deviceId, refetch]);

  return query;
}
