import {makeAutoObservable} from 'mobx';
import {SrtStream} from 'app/store/models/connect/SrtStream/SrtStream';

interface ReturnFeedArgs {
  meetingId: string;
  enabled: boolean;
  owner?: App.Connect.ReturnFeedOwner;
}

export class ReturnFeed {
  meetingId: string;
  stream: SrtStream;
  enabled: boolean;
  owner?: App.Connect.ReturnFeedOwner;

  constructor({enabled, meetingId, owner}: ReturnFeedArgs, stream: SrtStream) {
    this.meetingId = meetingId;
    this.stream = stream;
    this.enabled = enabled;
    this.owner = owner;
    makeAutoObservable(this);
  }
}
