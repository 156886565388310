export enum STORAGE_PROP_NAME {
  FTP = 'FTP',
  SFTP = 'SFTP',
  OAUTH2 = 'OAuth2',
}

export enum STORAGE_TYPE {
  FTP = 'ftp',
  SFTP = 'sftp',
  GOOGLE_DRIVE = 'google',
  BOX = 'box',
  DROPBOX = 'dropbox',
}

export const STORAGE_PROP_NAME_BY_TYPE = {
  [STORAGE_TYPE.FTP]: STORAGE_PROP_NAME.FTP,
  [STORAGE_TYPE.SFTP]: STORAGE_PROP_NAME.SFTP,
  [STORAGE_TYPE.GOOGLE_DRIVE]: STORAGE_PROP_NAME.OAUTH2,
  [STORAGE_TYPE.BOX]: STORAGE_PROP_NAME.OAUTH2,
  [STORAGE_TYPE.DROPBOX]: STORAGE_PROP_NAME.OAUTH2,
};
