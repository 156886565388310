import React, {useState} from 'react';
import {
  Box,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  tablePaginationClasses,
} from '@mui/material';
import {DataGrid, GridColDef, GridRenderCellParams, gridClasses} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import {Sx} from 'app/types/common';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {Edge} from 'app/domain/edge';
import {LiveScryptDeviceModel} from 'app/components/DeviceDetails/Models/LiveScryptDeviceModel';
import {downloadFile} from 'app/util/download';
import {FileExtension} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Archive/types';
import {DownloadSession} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Archive/DownloadSession/DownloadSession';
import {DeleteTranscribeSessionDialog} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Archive/dialogs/DeleteTranscribeSessionDialog/DeleteTranscribeSessionDialog';
import {useTranscribeSessionsQuery} from 'app/components/DeviceDetails/TranscriberDevice/Tabs/Archive/hooks/useTranscribeSessionsQuery';

const gridSx: SxProps<Theme> = {
  fontSize: 14,
  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
    outline: 'none',
  },
  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
    outline: 'none',
  },
  [`& .${tablePaginationClasses.selectLabel}, & .${tablePaginationClasses.displayedRows}`]: {
    mb: 0,
    fontSize: 14,
  },
  [`& .${tablePaginationClasses.select}`]: {fontSize: 14},
};

interface Props extends Sx {
  device: LiveScryptDeviceModel;
}

export function Archive({device, sx}: Props) {
  const deviceId = device.getId();
  const {sessionId, paused} = device.getTranscribingInfo();

  const [sessionToDelete, setSessionToDelete] = useState<string>('');
  const {isSuccess, data, refetch} = useTranscribeSessionsQuery({deviceId, enabled: true});

  const handleCloseDeleteDialog = () => {
    setSessionToDelete('');
  };

  const handleDelete = async () => {
    if (sessionToDelete) {
      await DeviceApiService.deleteTranscribeSession(deviceId, sessionToDelete);
      await refetch();
      handleCloseDeleteDialog();
    }
  };

  const columns: GridColDef<Edge.TranscribeSession>[] = [
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      renderCell: ({value, row}: GridRenderCellParams<Edge.TranscribeSession, string>) => {
        const isCurrentPaused = row.id === sessionId && paused;
        const disabled = isCurrentPaused || row.streaming;

        return (
          <Typography data-id="record-name" color={disabled ? 'text.disabled' : 'inherit'}>
            {value}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: ({row}: GridRenderCellParams<Edge.TranscribeSession>) => {
        const {id, name} = row;

        const handleDownload = (ext: FileExtension) => {
          downloadFile(
            DeviceApiService.getTranscriberSessionUrl(deviceId, id, ext),
            `${name}.${ext}`,
          );
        };

        const isCurrentPaused = row.id === sessionId && paused;
        const isDisabled = isCurrentPaused || row.streaming;

        return (
          <Stack direction="row" alignItems="center" gap={1}>
            <DownloadSession onDownload={handleDownload} />

            <Tooltip title="Delete">
              <IconButton
                data-id="delete-record-btn"
                color="error"
                disabled={isDisabled}
                onClick={() => setSessionToDelete(id)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box sx={sx}>
      <Typography fontWeight={600} variant="h5" mb={3}>
        Session
      </Typography>

      {isSuccess && (
        <>
          <DataGrid
            data-id="record-grid"
            sx={gridSx}
            slots={{
              noRowsOverlay: () => (
                <Stack
                  height="100%"
                  direction="row"
                  justifyContent="center"
                  gap={1}
                  alignItems="center"
                >
                  <DescriptionIcon fontSize="large" color="info" />
                  <Typography>There are currently no sessions</Typography>
                </Stack>
              ),
            }}
            disableColumnMenu={true}
            autoHeight={true}
            columns={columns}
            rowSelection={false}
            rows={data}
            initialState={{
              sorting: {
                sortModel: [{field: 'name', sort: 'desc'}],
              },
            }}
          />

          <DeleteTranscribeSessionDialog
            open={Boolean(sessionToDelete)}
            onDelete={handleDelete}
            onClose={handleCloseDeleteDialog}
          />
        </>
      )}
    </Box>
  );
}
