/* eslint-disable react/default-props-match-prop-types */
import React, {Component} from 'react';
import {Icons} from 'app/util/icons';
import {
  VARIANT,
  SIZE,
} from 'app/constants';
import {Dropdown} from 'app/components/sharedReactComponents/Dropdown';
import {Checkbox, CHECKBOX_THEME} from 'app/components/sharedReactComponents/Checkbox';
import {Tooltip, TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Callback} from 'app/types/common';
import {FleetManagerColumn} from 'app/components/FleetManager/types';
import {TableColumnType} from 'app/components/sharedReactComponents/Table/types';

function checkAllColumnsIsFixed(columns: Array<FleetManagerColumn | TableColumnType>, fixedColumnIds: string[]) {
  if (fixedColumnIds.length === 0) {
    return false;
  }

  const columnsIdSet = new Set(columns.map(({dataKey}) => dataKey));

  const fixedColumnsIdSet = new Set(fixedColumnIds);

  for (const columnId of columnsIdSet) {
    if (!fixedColumnsIdSet.has(columnId)) {
      return false;
    }
  }

  return true;
}

interface ItemProps {
  label: React.ReactNode;
  detailedLabel?: React.ReactNode;
  value: boolean;
  name: string;
  disabled: boolean;
  onChange: Callback;
}

const Item: React.VFC<ItemProps> = ({
  label,
  detailedLabel,
  value,
  name,
  disabled,
  onChange,
}) => {
  return (
    <li className="fleet-manager-columns-selector__option">
      <Checkbox
        value={value}
        name={name}
        label={detailedLabel ?? label}
        theme={CHECKBOX_THEME.BLACK}
        size={SIZE.S}
        disabled={disabled}
        onChange={onChange}
      />
    </li>
  );
};

interface Props {
  maxActiveColumns: number;
  columns: Array<FleetManagerColumn | TableColumnType>;
  fixedColumnIds: string[];
  isColumnActive: Callback;
  onChange: Callback;
}

export class ColumnsSelector extends Component<Props> {
  static defaultProps = {
    maxActiveColumns: 12,
    columns: [],
    fixedColumnIds: [],
  };

  handleChange = (e) => {
    this.props.onChange(e);
  };

  renderList() {
    const {
      maxActiveColumns,
      columns,
      isColumnActive,
      fixedColumnIds,
    } = this.props;

    const activeColumnsCount = columns.reduce((acc: number, column) => {
      if (isColumnActive(column.dataKey)) {
        return acc + 1;
      }

      return acc;
    }, 0);

    const activateAllowed = activeColumnsCount < maxActiveColumns;

    const columnsWithoutFixed = columns.filter(column => !fixedColumnIds.includes(column.dataKey));

    return (
      <ul className="fleet-manager-columns-selector__menu">
        {columnsWithoutFixed.map(column => (
          <Item
            key={column.dataKey}
            label={column.label}
            detailedLabel={column.detailedLabel}
            name={column.dataKey}
            value={isColumnActive(column.dataKey)}
            disabled={isColumnActive(column.dataKey) === false && activateAllowed === false}
            onChange={this.handleChange}
          />
        ))}
      </ul>
    );
  }

  render() {
    const {
      columns,
      fixedColumnIds,
    } = this.props;

    if (columns.length === 0) {
      return null;
    }

    if (checkAllColumnsIsFixed(columns, fixedColumnIds)) {
      return null;
    }

    return (
      <Dropdown
        content={this.renderList()}
        placement={TOOLTIP_PLACEMENT.BOTTOM_END}
        buttonOpenedClassName="fleet-manager-columns-selector__button--active"
        appendToBody={true}
        interactive={true}
      >
        <Button
          className="fleet-manager-columns-selector__button"
          variant={VARIANT.TEXT}
        >
          <Tooltip
            content="Column settings"
            delay={250}
          >
            {Icons.settings().size(SIZE.S).reactComponent()}
          </Tooltip>
        </Button>
      </Dropdown>
    );
  }
}
