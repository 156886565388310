/* eslint-disable @typescript-eslint/require-array-sort-compare */
import {useCallback, useState} from 'react';
import {equals} from 'ramda';
import {Callback} from 'app/types/common';

type Primitive = string | boolean | number;

export function usePrimitiveArray<T extends Primitive>(
  initial: T[] | Callback<T[]>,
): [arr: T[], setter: Callback<void, [T[]]>] {
  const [array, setArray] = useState<T[]>(initial);

  const setter = useCallback((values: T[]) => {
    setArray(prev => equalArrays(prev, values) ? prev : values);
  }, []);

  return [array, setter];
}

function equalArrays<T extends Primitive>(first: T[], second: T[]) {
  const a = [...first].sort();
  const b = [...second].sort();

  return equals(a, b);
}
