import React from 'react';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {ClassName, SelectOption} from 'app/types/common';
import {StreamSetting} from 'app/components/pages/MeetingDetails/StreamSettings/common/StreamSetting/StreamSetting';
import {useStrSettingsForm} from 'app/components/pages/MeetingDetails/StreamSettings/SrtSettingsFormContext';
import {SrtSettingsCallback} from 'app/components/pages/MeetingDetails/StreamSettings/types';
import {FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material';

const Formats: Array<SelectOption<App.Connect.AudioFormat>> = [
  {label: 'AAC 44.1 kHz', value: 'AAC_44100'},
  {label: 'AAC 48 kHz', value: 'AAC_48000'},
];

const Channels: Array<SelectOption<App.Connect.AudioMode>> = [
  {label: 'Mono', value: 'mono'},
  {label: 'Stereo', value: 'stereo'},
];

const Bitrates: Array<SelectOption<number>> = [
  {label: '64 kbps', value: 64},
  {label: '96 kbps', value: 96},
  {label: '112 kbps', value: 112},
  {label: '128 kbps', value: 128},
  {label: '160 kbps', value: 160},
  {label: '256 kbps', value: 256},
  {label: '320 kbps', value: 320},
];

interface Props extends ClassName, SrtSettingsCallback {
  settings: App.Connect.ParticipantStreamSettings;
}

const StreamAudioSettingsComponent = ({className, settings, onSync}: Props) => {
  const {setSettings} = useStrSettingsForm();

  const handleModeChange = async (e: SelectChangeEvent<App.Connect.AudioMode>) => {
    const mode = e.target.value as App.Connect.AudioMode;
    setSettings({
      audio: {
        ...settings.audio,
        mode,
      },
    });
    await onSync();
  };

  const handleFormatChange = async (e: SelectChangeEvent<App.Connect.AudioFormat>) => {
    const format = e.target.value as App.Connect.AudioFormat;
    setSettings({
      audio: {
        ...settings.audio,
        format,
      },
    });

    await onSync();
  };

  const handleBitrateChange = async (e: SelectChangeEvent<number>) => {
    const bitrate = e.target.value as number;

    setSettings({
      audio: {
        ...settings.audio,
        bitrate,
      },
    });

    await onSync();
  };

  return (
    <div className={classNames('stream-audio-settings', className)}>
      <StreamSetting className="" label="Audio format">
        <FormControl fullWidth={true}>
          <Select
            data-id="srt_audio_format"
            value={settings.audio.format}
            onChange={handleFormatChange}
          >
            {Formats.map((f) => (
              <MenuItem key={f.value} value={f.value}>
                {f.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StreamSetting>

      <StreamSetting label="Audio channels">
        <FormControl fullWidth={true}>
          <Select data-id="srt_audio_mode" value={settings.audio.mode} onChange={handleModeChange}>
            {Channels.map((c) => (
              <MenuItem key={c.value} value={c.value}>
                {c.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StreamSetting>

      <StreamSetting label="Audio bitrate">
        <FormControl fullWidth={true}>
          <Select
            data-id="srt_audio_bitrate"
            value={settings.audio.bitrate}
            onChange={handleBitrateChange}
          >
            {Bitrates.map((c) => (
              <MenuItem key={c.value} value={c.value}>
                {c.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StreamSetting>
    </div>
  );
};

export const StreamAudioSettings = observer(StreamAudioSettingsComponent);
