import {useMemo} from 'react';
import {ScheduleCategories} from 'app/components/sharedReactComponents/Events/types';
import {Schedule} from 'app/domain/schedule';
import {isCompleted, isOngoing, isScheduled} from 'app/domain/schedule/utils';

const eventsByCategories = (events: Schedule.Event[]): ScheduleCategories => {
  const result = events.reduce<ScheduleCategories>(
    (acc, event) => {
      const {status} = event;
      if (isOngoing(status)) return {...acc, ongoing: [...acc.ongoing, event]};

      if (isScheduled(status)) return {...acc, scheduled: [...acc.scheduled, event]};

      if (isCompleted(status)) return {...acc, completed: [...acc.completed, event]};

      return acc;
    },
    {ongoing: [], completed: [], scheduled: []},
  );

  result.completed.reverse();

  return result;
};

export function useScheduleCategories(events: Schedule.Event[]): ScheduleCategories {
  return useMemo(() => eventsByCategories(events), [events]);
}
