import React, {useCallback} from 'react';
import classNames from 'classnames';
import {Button} from 'app/components/sharedReactComponents/Button';
import {WAI_ARIA_ROLE} from 'app/constants/waiAriaRole';
import {Callback} from 'app/types/common';

interface Props {
  value: string;
  label: React.ReactNode;
  visible?: boolean;
  selected?: boolean;
  disabled?: boolean;
  onClick?: Callback;
  onChange?: Callback;
}

export const Tab: React.FC<Props> = ({
  value,
  label,
  visible,
  selected,
  disabled,
  onClick,
  onChange,
  ...elementProps
}) => {
  const handleClick = useCallback(() => {
    const event = {
      target: {
        value,
      },
    };

    if (onClick) {
      onClick(event);
    }

    if (onChange) {
      onChange(event);
    }
  }, [value, onClick, onChange]);

  if (visible === false) {
    return null;
  }

  return (
    <Button
      className={classNames('tabs__tab', selected && 'tabs__tab--selected')}
      role={WAI_ARIA_ROLE.TAB}
      disabled={disabled}
      onClick={handleClick}
      {...elementProps}
    >
      {label}
    </Button>
  );
};
