import {useQuery} from '@tanstack/react-query';
import {UserApiService} from 'app/services/api/user/UserApiService';

export function useAgreementQuery() {
  return useQuery({
    queryKey: ['agreement'],
    queryFn: async () => {
      return UserApiService.getAgreement();
    },
  });
}
