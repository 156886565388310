import React from 'react';
import {SIZE, THEME} from 'app/constants';
import {StartTranscriptionButton} from 'app/components/sharedReactComponents/StartStopTranscriptionButton/StartTranscriptionButton';
import {StopTranscriptionButton} from 'app/components/sharedReactComponents/StartStopTranscriptionButton/StopTranscriptionButton';
import {TranscribingStatus} from 'app/components/DeviceDetails/constants';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  deviceId: string;
  status: TranscribingStatus;
  disabled: boolean;
  teamCanTranscribing: boolean;
  size: SIZE;
  startTheme?: THEME;
  stopTheme?: THEME;
  action: Callback;
}

export const StartStopTranscriptionButton: React.VFC<Props> = ({
  className,
  status,
  disabled,
  teamCanTranscribing,
  size,
  deviceId,
  startTheme,
  stopTheme,
  action,
}) => {
  if (status === TranscribingStatus.Started) {
    return (
      <StopTranscriptionButton
        className={className}
        data-id="stop_transcription_button"
        size={size}
        theme={stopTheme}
        deviceId={deviceId}
        disabled={disabled}
        teamCanTranscribing={teamCanTranscribing}
        action={action}
      />
    );
  }

  return (
    <StartTranscriptionButton
      className={className}
      data-id="start_transcription_button"
      size={size}
      theme={startTheme}
      deviceId={deviceId}
      disabled={disabled}
      teamCanTranscribing={teamCanTranscribing}
      action={action}
    >
      {status === TranscribingStatus.Paused ? 'Resume transcription' : 'Start transcription'}
    </StartTranscriptionButton>
  );
};
