import {StyleSx} from 'app/types/common';

export const root: StyleSx = {
  '&:focus': {
    outline: 'none',
    boxShadow: ({palette: {info}}) => `0px 0px 3px 1px ${info.main}`,
  },
};

export const selected: StyleSx = ({palette: {primary, info}}) => ({
  borderColor: 'primary.main',
  boxShadow: `0px 0px 0px 1px ${primary.main}`,
  '&:focus': {
    boxShadow: `0px 0px 0px 1px ${primary.main}, 0px 0px 4px 1px ${info.main}`,
  },
});

export const cardStyles = {root, selected};
