import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {resolveComponentProps} from '@mui/base/utils';
import {
  DateCalendar,
  DateCalendarProps,
  DateTimePickerProps,
  DigitalClock,
  DigitalClockProps,
} from '@mui/x-date-pickers';
import {DateOrTimeViewWithMeridiem} from '@mui/x-date-pickers/internals/models';
import {isDatePickerView} from '@mui/x-date-pickers/internals/utils/date-utils';
import {packSx} from 'app/util/packSx/packSx';
import {VIEW_HEIGHT} from '@mui/x-date-pickers/internals/constants/dimensions';
import {Box, Divider} from '@mui/material';
import {isNil} from 'app/util/isNil';

export interface DateTimeViewRendererProps<TDate>
  extends Omit<
      DateCalendarProps<TDate> & DigitalClockProps<TDate>,
      | 'views'
      | 'openTo'
      | 'view'
      | 'onViewChange'
      | 'focusedView'
      | 'components'
      | 'componentsProps'
      | 'slots'
      | 'slotProps'
      | 'timeStep'
    >,
    Pick<
      DateTimePickerProps<TDate>,
      'components' | 'componentsProps' | 'slots' | 'slotProps' | 'timeSteps'
    > {
  view: DateOrTimeViewWithMeridiem;
  views: readonly DateOrTimeViewWithMeridiem[];
  focusedView: DateOrTimeViewWithMeridiem | null;
  timeViewsCount?: number;
  onViewChange?: (view: DateOrTimeViewWithMeridiem) => void;
}

export const renderDesktopDateTimeView = <TDate extends unknown>({
  sx,
  view,
  views,
  focusedView,
  value,
  defaultValue,
  className,
  classes,
  disablePast,
  disableFuture,
  minDate,
  minTime,
  maxDate,
  maxTime,
  reduceAnimations,
  minutesStep,
  ampm,
  monthsPerRow,
  yearsPerRow,
  defaultCalendarMonth,
  slots,
  slotProps,
  loading,
  disableHighlightToday,
  readOnly,
  disabled,
  showDaysOutsideCurrentMonth,
  disableIgnoringDatePartForTimeValidation,
  skipDisabled,
  timeViewsCount,
  autoFocus,
  fixedWeekNumber,
  displayWeekNumber,
  timeSteps,
  renderLoading,
  dayOfWeekFormatter,
  onMonthChange,
  onYearChange,
  shouldDisableDate,
  shouldDisableMonth,
  shouldDisableYear,
  shouldDisableTime,
  onChange,
  onViewChange,
  onFocusedViewChange,
}: DateTimeViewRendererProps<TDate>) => {
  const isActionBarVisible = !!resolveComponentProps(slotProps?.actionBar, {} as any)?.actions
    ?.length;

  return (
    <React.Fragment>
      <Box display="flex" margin="0 auto">
        <DateCalendar
          sx={sx}
          view={isDatePickerView(view) ? view : 'day'}
          views={views.filter(isDatePickerView)}
          focusedView={focusedView && isDatePickerView(focusedView) ? focusedView : null}
          value={value}
          defaultValue={defaultValue}
          className={className}
          classes={classes}
          disableFuture={disableFuture}
          disablePast={disablePast}
          minDate={minDate}
          maxDate={maxDate}
          reduceAnimations={reduceAnimations}
          monthsPerRow={monthsPerRow}
          yearsPerRow={yearsPerRow}
          slots={slots}
          slotProps={slotProps}
          loading={loading}
          disableHighlightToday={disableHighlightToday}
          defaultCalendarMonth={defaultCalendarMonth}
          showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
          autoFocus={autoFocus}
          fixedWeekNumber={fixedWeekNumber}
          displayWeekNumber={displayWeekNumber}
          readOnly={readOnly}
          disabled={disabled}
          dayOfWeekFormatter={dayOfWeekFormatter}
          renderLoading={renderLoading}
          shouldDisableDate={shouldDisableDate}
          shouldDisableMonth={shouldDisableMonth}
          shouldDisableYear={shouldDisableYear}
          onChange={onChange}
          onFocusedViewChange={onFocusedViewChange}
          onMonthChange={onMonthChange}
          onYearChange={onYearChange}
          onViewChange={onViewChange}
        />
        {!isNil(timeViewsCount) && timeViewsCount > 0 && (
          <React.Fragment>
            <Divider orientation="vertical" />
            <DigitalClock
              sx={packSx({borderBottom: 0, width: 'auto', maxHeight: VIEW_HEIGHT}, sx)}
              view={view === 'hours' ? view : 'hours'}
              views={views.includes('hours') ? ['hours'] : []}
              value={value}
              defaultValue={defaultValue}
              ampm={ampm}
              slots={slots}
              slotProps={{digitalClockItem: slotProps?.digitalClockSectionItem}}
              className={className}
              classes={classes}
              disableFuture={disableFuture}
              disablePast={disablePast}
              minTime={minTime}
              maxTime={maxTime}
              disabled={disabled}
              readOnly={readOnly}
              autoFocus={autoFocus}
              disableIgnoringDatePartForTimeValidation={disableIgnoringDatePartForTimeValidation}
              timeStep={timeSteps?.minutes}
              minutesStep={minutesStep}
              skipDisabled={skipDisabled}
              shouldDisableTime={shouldDisableTime}
              onChange={onChange}
              onViewChange={onViewChange}
              onFocusedViewChange={onFocusedViewChange}
            />
          </React.Fragment>
        )}
      </Box>
      {isActionBarVisible && <Divider />}
    </React.Fragment>
  );
};
