import {useEffect, useState} from 'react';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DeviceWarning} from 'app/components/DeviceDetails/Models/types';

interface State {
  publisher: DeviceWarning[];
  destinations: DeviceWarning[];
}

export function useDeviceWarnings(device: AnyDeviceModelType): State {
  const [state, setState] = useState<State>({destinations: [], publisher: []});

  useEffect(() => {
    setState({
      destinations: device.getStreamingDestinationWarnings(),
      publisher: device.getSelectedPublisherWarnings(),
    });
  }, [device]);

  return state;
}
