import React, {useCallback} from 'react';
import {Box} from '@mui/material';
import classNames from 'classnames';
import {Button} from 'app/components/sharedReactComponents/Button';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {Icons} from 'app/util/icons';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {Callback, ClassName, Sx} from 'app/types/common';
import {FilterSwitch} from 'app/components/sharedReactComponents/FilterSelector/types';

interface ItemProps {
  id: string;
  label: React.ReactNode;
  onDelete: Callback;
}

const Item: React.FC<ItemProps> = ({id, label, onDelete}) => {
  const handleClickDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  return (
    <div className="fleet-manager-active-filters__chip">
      <span className="fleet-manager-active-filters__chip-label">
        <OverflowTooltip>{label}</OverflowTooltip>
      </span>

      <Button
        dataId="remove_filter_item"
        className="fleet-manager-active-filters__chip-delete-button"
        onClick={handleClickDelete}
      >
        {Icons.remove().size(SIZE.S).reactComponent()}
      </Button>
    </div>
  );
};

interface ListProps {
  filterSwitches: FilterSwitch[];
  onDelete: Callback;
}

const List: React.FC<ListProps> = ({filterSwitches, onDelete}) => {
  return (
    <div className="fleet-manager-active-filters__chips">
      {filterSwitches.map((filterSwitch) => (
        <Item
          key={filterSwitch.id}
          id={filterSwitch.id}
          label={filterSwitch.label}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};

interface Props extends ClassName, Sx {
  filterSwitches: FilterSwitch[];
  activeFilterSwitches: Set<string>;
  onClickFilterSwitcher: Callback;
  onClickClearAll: Callback;
}
export const ActiveFilters = ({
  sx,
  className,
  filterSwitches,
  activeFilterSwitches,
  onClickFilterSwitcher,
  onClickClearAll,
}: Props) => {
  const enabledFilterSwitches = filterSwitches.filter(({id}) => activeFilterSwitches.has(id));

  if (enabledFilterSwitches.length === 0) {
    return null;
  }

  return (
    <Box sx={sx} className={classNames('fleet-manager-active-filters', className)}>
      <div className="fleet-manager-active-filters__label">Filtering by:</div>

      <List filterSwitches={enabledFilterSwitches} onDelete={onClickFilterSwitcher} />

      <Button
        dataId="clear_active_filters"
        className="fleet-manager-active-filters__clear-button"
        theme={THEME.SECONDARY}
        variant={VARIANT.LINK_UNDERLINE}
        onClick={onClickClearAll}
      >
        Clear all
      </Button>
    </Box>
  );
};
