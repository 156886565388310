import {useMeetingsSocket} from 'app/hooks/Meeting/useMeetingsSocket';
import {useMeetingsStore} from 'app/store/hooks';
import {
  useCallsQuery,
  useMeetingsQuery,
} from 'app/components/MeetingsManager/hooks/useMeetingsQuery';
import {useCallback, useEffect, useState} from 'react';
import {WS} from 'app/api/WebSocket/WS';
import {ConnectMapper} from 'app/util/mappers/ConnectMapper/ConnectMapper';
import {WSTeamsCallUpdated} from 'app/api/WebSocket/types';
import {Meeting} from 'app/store/models/connect/Meeting/Meeting';

function useRegularMeetings(enabled: boolean) {
  const {meetings, setMeetings} = useMeetingsStore();

  const meetingsQuery = useMeetingsQuery(enabled);

  useEffect(() => {
    if (!enabled || meetingsQuery.isInitialLoading) {
      setMeetings([]);
      return;
    }

    setMeetings(meetingsQuery.data ?? []);
  }, [enabled, meetingsQuery.isInitialLoading, setMeetings, meetingsQuery.data]);

  useMeetingsSocket(enabled && meetingsQuery.isSuccess);

  return {
    meetings,
    meetingsQuery,
  };
}

function useHostedMeetings(enabled: boolean) {
  const {calls, setCalls, removeCall, setCall} = useMeetingsStore();
  const callsQuery = useCallsQuery(enabled);

  useEffect(() => {
    if (!enabled || callsQuery.isInitialLoading) {
      setCalls([]);
      return;
    }

    setCalls(callsQuery.data ?? []);
  }, [callsQuery.data, enabled, setCalls, callsQuery.isInitialLoading]);

  const handleTeamsCallWs = useCallback(
    (data: WSTeamsCallUpdated) => {
      const call = ConnectMapper.mapCall(data.Body);
      if (call.deletedAt) {
        removeCall(call.id);
      } else {
        setCall(call);
      }
    },
    [removeCall, setCall],
  );

  useEffect(() => {
    if (callsQuery.isInitialLoading || !enabled) {
      return;
    }

    WS.onTeamsCallUpdate(handleTeamsCallWs);

    return () => {
      WS.onTeamsCallUpdate(handleTeamsCallWs);
    };
  }, [callsQuery.isInitialLoading, enabled, handleTeamsCallWs]);

  return {
    calls,
    callsQuery,
  };
}

function useConnectItems(meetings: Meeting[], calls: App.Connect.Call[]) {
  const [items, setItems] = useState<Array<App.Connect.Call | Meeting>>([]);

  useEffect(() => {
    const displayedCalls = calls.filter(
      (c) => !meetings.some((m) => m.platform === 'teams' && c.id === m.host),
    );
    setItems([...displayedCalls, ...meetings]);
  }, [calls, meetings]);

  return items;
}

interface Props {
  enabled: boolean;
}

export function useMeetings({enabled}: Props) {
  const {meetings, meetingsQuery} = useRegularMeetings(enabled);
  const {calls, callsQuery} = useHostedMeetings(enabled);
  const items = useConnectItems(meetings, calls);
  return {items, callsQuery, meetingsQuery};
}
