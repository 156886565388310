import React from 'react';
import {alpha, Box, Divider, Stack} from '@mui/material';
import {Component} from 'app/types/common';
import {freeTier} from 'app/util/billing/utils';
import {backgrounds} from 'app/themes/app';
import {Icons} from 'app/util/icons';
import {THEME} from 'app/constants';
import {Billing} from 'app/domain/billing';

type EdgeFeature =
  | 'dashboard'
  | 'media-actions'
  | 'monitoring'
  | 'remote'
  | 'batch-actins'
  | 'presets'
  | 'live'
  | 'alerts'
  | 'events';

const edgeFeatures: Array<Billing.Feature<EdgeFeature>> = [
  {id: 'dashboard', name: 'Dashboard for system monitoring and management'},
  {id: 'media-actions', name: 'Centralized streaming and recording'},

  {id: 'monitoring', name: 'Channel and input monitoring'},
  {
    id: 'remote',
    name: 'Remote access, configuration, and control for streaming, recording, and production',
  },
  {id: 'batch-actins', name: 'Device grouping and batch actions for one-click fleet management'},
  {id: 'events', name: 'Events scheduling'},
  {id: 'presets', name: 'Create and manage presets from anywhere'},
  {id: 'live', name: 'Live video monitoring'},
  {id: 'alerts', name: 'Email alerts to solve issues'},
];

const freeFeatures = new Set<EdgeFeature>(['dashboard', 'media-actions', 'monitoring']);
const premiumFeatures = new Set<EdgeFeature>(edgeFeatures.map((f) => f.id));

interface Props extends Component {
  tiers: App.Billing.Cloud.Tier[];
  currentTier: string;
}

export function EdgeFeatures({tiers, currentTier}: Props) {
  return (
    <Stack>
      {edgeFeatures.map((feature) => (
        <Stack
          key={feature.id}
          direction="row"
          gap={1}
          divider={<Divider sx={{borderStyle: 'dashed'}} orientation="vertical" flexItem />}
          sx={{
            '&:last-child > div': {
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
            },
          }}
        >
          <Feature name={feature.name} />

          {tiers.map((p) => (
            <CloudTierFeature
              key={`${p.id}_${feature.id}`}
              tier={p}
              featureId={feature.id}
              current={p.id === currentTier}
            />
          ))}
        </Stack>
      ))}
    </Stack>
  );
}

interface TierFeatureProps {
  featureId: EdgeFeature;
  tier: App.Billing.Cloud.Tier;
  current: boolean;
}

function CloudTierFeature({tier, featureId, current}: TierFeatureProps) {
  const isFree = freeTier(tier.id);

  const checkFeature = () => {
    if (isFree) {
      return freeFeatures.has(featureId);
    }
    return premiumFeatures.has(featureId);
  };

  const isAvailable = checkFeature();

  return (
    <Stack
      direction="row"
      alignItems="stretch"
      sx={{
        px: 2,
        flexBasis: !isFree && !tier.isDeleted ? '300px' : '250px',
        flexGrow: 0,
        flexShrink: 0,
        ...(current ? {backgroundColor: backgrounds.blue} : {}),
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          flexGrow: 1,
          py: 1,
          borderTop: '1px dashed',
          borderColor: alpha('#000', 0.12),
        }}
      >
        {isAvailable ? Icons.checkmark().theme(THEME.PRIMARY).reactComponent() : null}
      </Stack>
    </Stack>
  );
}

interface FeatureProps {
  name: string;
}

function Feature({name}: FeatureProps) {
  return (
    <Box
      sx={{
        minWidth: '340px',
        flexGrow: 1,
        py: 1,
        lineHeight: 1.7,
        borderTop: '1px dashed',
        borderColor: alpha('#000', 0.12),
      }}
    >
      {name}
    </Box>
  );
}
