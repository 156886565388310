import {ApiResponseNumeralStatus, ApiResponseStatus} from 'app/data/Status';
import {isNil} from 'app/util/isNil';

export type Endpoints = Record<string, EndpointData | Record<string, EndpointData>>;
export type EndpointData = {
  __noteam__?: boolean;
  __url__?: string;
  __nocache__?: boolean;
  __protocol__?: string;
} & Record<string, any>;

export type AbortCallback = () => void;
export type GetAbortCallback = (abort: AbortCallback) => void;

export type AjaxErrorCallback = (error) => void;

export type AjaxComplexSuccessfulResponseType<T = {}> = T & {
  Status: ApiResponseStatus;
  StatusCode: ApiResponseNumeralStatus;
};

export type ErrorResponse = {
  data: {
    Status: 'error';
    StatusCode: number;
    Error?: string;
    ErrorData?: Record<string, any>;
  };
};

export type ErrorRes = {
  Status: 'error';
  StatusCode: number;
  Error: string;
};

export type ErrorResWithData = {
  Status: 'error';
  StatusCode: number;
  ErrorData: Record<string, any>;
};

export function isErrorResponse(e: unknown): e is ErrorResponse {
  const response = e as ErrorResponse;
  return Boolean(response.data?.Error) || Boolean(response.data?.ErrorData);
}

export function getErrorContents(e: ErrorResponse): string {
  if (e.data.ErrorData) {
    return Object.values(e.data.ErrorData).join(' ');
  }

  if (e.data.Error) {
    return e.data.Error;
  }

  return '';
}

export function isError(e: unknown): e is ErrorRes {
  const casted = e as ErrorRes;
  return !isNil(casted.Status) && !isNil(casted.StatusCode) && !isNil(casted.Error);
}

export function isErrorWithData(e: unknown): e is ErrorResWithData {
  const casted = e as ErrorResWithData;
  return !isNil(casted.Status) && !isNil(casted.StatusCode) && !isNil(casted.ErrorData);
}
