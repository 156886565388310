import React from 'react';
import classNames from 'classnames';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {Icons} from 'app/util/icons';
import {SIZE, THEME} from 'app/constants';
import {TooltipMultipleMessages} from 'app/components/sharedReactComponents/Tooltip/TooltipMultipleMessages';
import {Callback, ClassName, WarningBaseType} from 'app/types/common';

type Warning = WarningBaseType & {
  deviceName: string;
};

interface ControlProps {
  warnings: Warning[];
  canStart: boolean;
  canStop: boolean;
  startAction: Callback;
  stopAction: Callback;
  dataId?: string;
}

export function StreamBatchActionControl({
  warnings,
  canStart,
  canStop,
  startAction,
  stopAction,
  dataId,
}: ControlProps) {
  return (
    <BatchActionControl
      className="batch-action-control--stream"
      dataId={dataId}
      label="Stream"
      startIcon={Icons.streaming().size(SIZE.S).reactComponent()}
      startTooltipMessage="Start Streaming"
      stopTooltipMessage="Stop Streaming"
      warnings={warnings}
      canStart={canStart}
      canStop={canStop}
      startAction={startAction}
      stopAction={stopAction}
    />
  );
}

export function RecordBatchActionControl({
  warnings,
  canStart,
  canStop,
  startAction,
  stopAction,
  dataId,
}: ControlProps) {
  return (
    <BatchActionControl
      className="batch-action-control--record"
      dataId={dataId}
      label="Record"
      startIcon={Icons.record().size(SIZE.S).reactComponent()}
      startTooltipMessage="Start Recording"
      stopTooltipMessage="Stop Recording"
      warnings={warnings}
      canStart={canStart}
      canStop={canStop}
      startAction={startAction}
      stopAction={stopAction}
    />
  );
}

export function TranscribeBatchActionControl({
  warnings,
  canStart,
  canStop,
  startAction,
  stopAction,
  dataId,
}: ControlProps) {
  return (
    <BatchActionControl
      className="batch-action-control--transcribe"
      dataId={dataId}
      label="Transcribe"
      startIcon={Icons.liveScrypt().size(SIZE.S).reactComponent()}
      startTooltipMessage="Start Transcribing"
      stopTooltipMessage="Stop Transcribing"
      warnings={warnings}
      canStart={canStart}
      canStop={canStop}
      startAction={startAction}
      stopAction={stopAction}
    />
  );
}

interface ActionProps {
  title: string;
  // children: node;
  disabled: boolean;
  action: Callback;
}

const ActionButton: React.FC<ActionProps> = ({
  title,
  children,
  disabled,
  action,
  ...elementProps
}) => {
  return (
    <ProgressButton title={title} disabled={disabled} onClick={action} {...elementProps}>
      {children}
    </ProgressButton>
  );
};

interface Props extends ClassName, ControlProps {
  label: string;
  startIcon: React.ReactNode;
  startTooltipMessage: string;
  stopTooltipMessage: string;
  dataId?: string;
}

function BatchActionControl({
  className,
  label,
  startIcon,
  startTooltipMessage,
  stopTooltipMessage,
  warnings,
  canStart,
  canStop,
  startAction,
  stopAction,
  dataId,
}: Props) {
  return (
    <div
      className={classNames(
        'batch-action-control',
        {
          'batch-action-control--enabled': canStart || canStop,
        },
        className,
      )}
      data-id={dataId}
    >
      <div className="batch-action-control__label">{label}</div>

      <div className="batch-action-control__action-item">
        <ActionButton
          data-id="batch_action_control_start_button"
          title={startTooltipMessage}
          disabled={canStart === false}
          action={startAction}
        >
          {startIcon}
        </ActionButton>

        {warnings.length > 0 && (
          <TooltipMultipleMessages
            messages={warnings.map(({deviceName, message}) => ({
              message: `${deviceName}: ${message}`,
            }))}
            theme={THEME.DANGER}
            preformatted={true}
          >
            <span className="batch-action-control__warning-indicator">
              {Icons.warning().size(SIZE.S).reactComponent()}
            </span>
          </TooltipMultipleMessages>
        )}
      </div>

      <div className="batch-action-control__action-item">
        <ActionButton
          data-id="batch_action_control_stop_button"
          title={stopTooltipMessage}
          disabled={canStop === false}
          action={stopAction}
        >
          {Icons.stop().size(SIZE.S).reactComponent()}
        </ActionButton>
      </div>
    </div>
  );
}
