import React, {useState} from 'react';
import {CouponForm} from 'app/components/BillingManager/dialogs/EnterCouponDialog/EnterCoupon/CouponForm/CouponForm';
import {ActivatedCoupon} from 'app/components/BillingManager/dialogs/EnterCouponDialog/EnterCoupon/ActivatedCoupon/ActivatedCoupon';
import {Callback} from 'app/types/common';

type Props = React.ComponentProps<typeof CouponForm> & {
  onClose: Callback;
};

type View = 'edit' | 'activated';

export function EnterCoupon({
  coupon,
  hasConnect,
  method,
  onCouponChange,
  onValidate,
  onActivate,
  onClose,
}: Props) {
  const [view, setView] = useState<View>('edit');

  const handleActivate = async () => {
    await onActivate();
    setView('activated');
  };

  if (view === 'edit') {
    return (
      <CouponForm
        coupon={coupon}
        hasConnect={hasConnect}
        method={method}
        onActivate={handleActivate}
        onValidate={onValidate}
        onCouponChange={onCouponChange}
      />
    );
  }

  if (coupon.instance) {
    return <ActivatedCoupon coupon={coupon.instance} onClose={onClose} />;
  }

  return null;
}
