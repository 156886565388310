/* eslint-disable no-bitwise */

// Convert from UTF-16, forcing the use of byte-mode encoding in our QR Code.
// This allows us to encode Hanji, Kanji, emoji, etc. Ideally we'd do more
// detection and not resort to byte-mode if possible, but we're trading off
// a smaller library for a smaller amount of data we can potentially encode.
// Based on http://jonisalonen.com/2012/from-utf-16-to-utf-8-in-javascript/
import {isNil} from 'app/util/isNil';
import {DEFAULT_IMG_SCALE, MARGIN_SIZE} from 'app/components/sharedReactComponents/QRCode/constants';
import {Excavation, ImageParams} from 'app/components/sharedReactComponents/QRCode/types';

function convertStr(str) {
  let out = '';

  for (let i = 0; i < str.length; i += 1) {
    let charcode = str.charCodeAt(i);

    if (charcode < 0x0080) {
      out += String.fromCharCode(charcode);
    } else if (charcode < 0x0800) {
      out += String.fromCharCode(0xc0 | (charcode >> 6));
      out += String.fromCharCode(0x80 | (charcode & 0x3f));
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      out += String.fromCharCode(0xe0 | (charcode >> 12));
      out += String.fromCharCode(0x80 | ((charcode >> 6) & 0x3f));
      out += String.fromCharCode(0x80 | (charcode & 0x3f));
    } else {
      // This is a surrogate pair, so we'll reconsitute the pieces and work from that
      i += 1;
      charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
      out += String.fromCharCode(0xf0 | (charcode >> 18));
      out += String.fromCharCode(0x80 | ((charcode >> 12) & 0x3f));
      out += String.fromCharCode(0x80 | ((charcode >> 6) & 0x3f));
      out += String.fromCharCode(0x80 | (charcode & 0x3f));
    }
  }

  return out;
}

// We could just do this in generatePath, except that we want to support
// non-Path2D canvas, so we need to keep it an explicit step.
function excavateModules(modules: any[], excavation: Excavation) {
  return modules.map((row, y) => {
    if (y < excavation.y || y >= excavation.y + excavation.h) {
      return row;
    }

    return row.map((cell, x) => {
      if (x < excavation.x || x >= excavation.x + excavation.w) {
        return cell;
      }

      return false;
    });
  });
}

function getImageSettings(props: ImageParams, cells: any[]): Excavation | null {
  const {
    imageSettings,
    size,
    includeMargin,
  } = props;

  if (isNil(imageSettings)) {
    return null;
  }

  const margin = includeMargin ? MARGIN_SIZE : 0;
  const numCells = cells.length + (margin * 2);
  const defaultSize = Math.floor(size * DEFAULT_IMG_SCALE);
  const scale = numCells / size;
  const w = (imageSettings.width || defaultSize) * scale;
  const h = (imageSettings.height || defaultSize) * scale;
  const x = isNil(imageSettings.x) ? (cells.length / 2) - (w / 2) : imageSettings.x * scale;
  const y = isNil(imageSettings.y) ? (cells.length / 2) - (h / 2) : imageSettings.y * scale;

  let excavation: any = null;
  if (imageSettings.excavate) {
    const floorX = Math.floor(x);
    const floorY = Math.floor(y);
    const ceilW = Math.ceil(w + x - floorX);
    const ceilH = Math.ceil(h + y - floorY);

    excavation = {
      x: floorX,
      y: floorY,
      w: ceilW,
      h: ceilH,
    };
  }

  return {
    x,
    y,
    h,
    w,
    excavation,
  };
}

export {
  convertStr,
  excavateModules,
  getImageSettings,
};
