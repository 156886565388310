import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {Icons} from 'app/util/icons';
import {backgrounds} from 'app/themes/app';

interface Props extends Sx {
  custom: boolean;
}

export function EnterpriseFeatures({sx, custom}: Props) {
  return (
    <Stack
      sx={sx}
      bgcolor={custom ? backgrounds.blue : undefined}
      alignItems="center"
      minWidth={260}
      width={260}
      flexGrow={0}
      flexShrink={0}
      p={4}
      gap={4}
    >
      <Typography>Everything from the periodic pricing plans plus:</Typography>

      <Stack gap={3}>
        <Stack direction="row" gap={1.5}>
          <Box>{Icons.user().reactComponent()}</Box>
          <Typography>Dedicated account manager.</Typography>
        </Stack>

        <Stack direction="row" gap={1.5}>
          <Box>{Icons.account().reactComponent()}</Box>
          <Typography>Customizable contract.</Typography>
        </Stack>

        <Stack direction="row" gap={1.5}>
          <Box>{Icons.savings().reactComponent()}</Box>
          <Typography>
            Buy hours in advance to get steeply discounted rates and simplified billing.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
