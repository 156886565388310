import React, {useRef, useState} from 'react';
import {Collapse, Link, List, ListItemButton, Popover, listItemButtonClasses} from '@mui/material';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import {Component, Sx} from 'app/types/common';
import {MainRoutes, router} from 'app/router/main';
import {palette} from 'app/themes/app';
import {packSx} from 'app/util/packSx/packSx';
import {Icons} from 'app/util/icons';
import {NavText} from 'app/components/Navigation/shared/NavText';
import {TOOLTIP_PLACEMENT, Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {RouteDefinition} from 'app/components/Navigation/types';
import {GroupPortal} from 'app/components/Navigation/EdgeNav/GroupPortal/GroupPortal';
import {PORTAL_ELEMENT_ID} from 'app/constants/portalElementId';
import {NavSection} from 'app/components/Navigation/hooks/useNavRoutes';
import {LegacyIcon} from 'app/components/sharedReactComponents/LegacyIcon/LegacyIcon';
import {SectionButton} from 'app/components/Navigation/shared/SectionButton/SectionButton';

interface Props extends Sx {
  open: boolean;
  route: MainRoutes;
  routes: RouteDefinition[];
  compact?: boolean;
  onSetSection: Fn<void, [section: NavSection]>;
}

const navIcons = new Map<MainRoutes, React.ReactNode>([
  ['devices', <LegacyIcon fontSize={22}>{Icons.devices().reactComponent()}</LegacyIcon>],
  [
    'transcribeDevices',
    <LegacyIcon fontSize={22}>{Icons.liveScrypt().reactComponent()}</LegacyIcon>,
  ],
  ['events', <LegacyIcon fontSize={22}>{Icons.cms().reactComponent()}</LegacyIcon>],
  ['recordings', <VideoFileIcon sx={{fontSize: 22}} />],
]);

export function EdgeNav({sx, compact, open, route, routes, onSetSection}: Props) {
  const [menu, setMenu] = useState(() => false);

  const anchorRef = useRef<HTMLDivElement | null>(null);

  const handleRoute = () => {
    if (compact) {
      setMenu(false);
    }
  };

  const renderList = () => {
    return (
      <List data-id="edge-routes" disablePadding={true}>
        {routes.map((r) => {
          if (!r.access) {
            return false;
          }

          return (
            <React.Fragment key={r.route}>
              <NavigationItem
                dataId={`route-${r.route}`}
                key={r.route}
                href={router.url(r.route)}
                label={r.label}
                icon={navIcons.get(r.route)}
                selected={route === r.route}
                onClick={handleRoute}
              />
              {r.route === 'devices' && (
                <GroupPortal key="edge-group" id={PORTAL_ELEMENT_ID.NAVBAR_DEVICE_GROUP_FILTER} />
              )}
            </React.Fragment>
          );
        })}
      </List>
    );
  };

  const renderSection = () => {
    if (compact) {
      return (
        <Popover
          open={menu}
          PaperProps={{
            sx: {
              zIndex: 10000,
              py: 0.5,
              bgcolor: palette.dark1,
              maxWidth: 350,
              minWidth: 120,
            },
          }}
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'center',
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'center',
          }}
          onClose={() => setMenu(false)}
        >
          {renderList()}
        </Popover>
      );
    }

    return <Collapse in={open}>{renderList()}</Collapse>;
  };

  const toggleSection = () => {
    if (open) {
      onSetSection(undefined);
    } else {
      onSetSection('edge');
    }

    setMenu((o) => !o);
  };

  return (
    <List disablePadding={true} sx={packSx({bgcolor: open ? palette.dark1 : 'transparent'}, sx)}>
      <Tooltip placement={TOOLTIP_PLACEMENT.RIGHT} content={compact ? 'Edge' : undefined}>
        <SectionButton
          dataId="edge-section"
          ref={anchorRef}
          label="Edge"
          expanded={open}
          icon={
            <LegacyIcon color={palette.green} fontSize={22}>
              {Icons.edge().reactComponent()}
            </LegacyIcon>
          }
          compact={compact}
          onClick={toggleSection}
        />
      </Tooltip>

      {renderSection()}
    </List>
  );
}

interface NavigationItemProps extends Component, Sx {
  href: string;
  label: string;
  selected?: boolean;
  icon?: React.ReactNode;
  onClick(): void;
}

function NavigationItem({sx, dataId, label, href, icon, selected, onClick}: NavigationItemProps) {
  return (
    <ListItemButton
      data-id={dataId}
      sx={packSx(
        {
          gap: 1.5,
          color: palette.darkerGray,
          textDecoration: 'none',
          outline: 'none',

          [`&:hover, &:focus, &.${listItemButtonClasses.focusVisible}`]: {
            color: palette.white,
            bgcolor: palette.dark1,
            textDecoration: 'none',
            outline: 'none',
          },

          [`&.${listItemButtonClasses.selected}`]: {
            textDecoration: 'none',
            outline: 'none',
            color: palette.white,
            bgcolor: palette.dark1,

            [`&:hover, &:focus, &.${listItemButtonClasses.focusVisible}`]: {
              bgcolor: palette.dark1,
            },
          },
        },
        sx,
      )}
      href={href}
      LinkComponent={Link}
      selected={selected}
      onClick={onClick}
    >
      {icon}
      <NavText value={label} />
    </ListItemButton>
  );
}
