import React from 'react';
import {grey} from '@mui/material/colors';
import {Button, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Alerts} from 'app/components/entities/alerts';

interface Props extends Sx {
  alertGroup: Alerts.Group;
  hasUnchecked: boolean;
  onHover: (type?: Alerts.AlertType) => void;
  onToggle: (type: Alerts.AlertType, value: boolean) => void;
}

export function AlertName({sx, alertGroup, hasUnchecked, onToggle, onHover}: Props) {
  return (
    <Stack
      sx={packSx(
        {'&:hover > button': {visibility: 'visible'}, '&:nth-of-type(odd)': {bgcolor: grey[100]}},
        sx,
      )}
      pt={1}
      width={60}
      height={100}
      alignItems="center"
      key={alertGroup.type}
      gap={0.25}
    >
      <Tooltip
        content={
          <>
            <strong>{alertGroup.name}</strong>
            <p>{alertGroup.description}</p>
          </>
        }
        delay={300}
      >
        <Typography
          sx={{writingMode: 'vertical-rl', rotate: '180deg'}}
          textAlign="center"
          flexGrow={1}
          lineHeight={1.2}
        >
          {alertGroup.name}
        </Typography>
      </Tooltip>

      <Button
        data-id="select-type-btn"
        sx={{p: 0, minWidth: 60, alignSelf: 'stretch', visibility: 'hidden'}}
        variant="text"
        size="small"
        onClick={() => onToggle(alertGroup.type, hasUnchecked)}
        onMouseEnter={() => onHover(alertGroup.type)}
        onMouseOut={() => onHover()}
      >
        {hasUnchecked ? 'Select' : 'Unselect'}
      </Button>
    </Stack>
  );
}
