/**
 * @readonly
 * @enum {string}
 */
enum FORM_CONTROL_TYPE {
  TEXT = 'text',
  PASSWORD = 'password',
  EMAIL = 'email',
  URL = 'url',
  TEXTAREA = 'textarea',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  SWITCHER = 'switcher',
  NUMBER = 'number',
  COLOR = 'color',
}

export {
  FORM_CONTROL_TYPE,
};
