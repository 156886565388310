import React, {ReactNode} from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {GoBackButton} from 'app/components/sharedReactComponents/PageHeader/GoBackButton';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  backUrl?: string;
  backText?: string;
  title?: ReactNode;
  centerMessage?: ReactNode;
  buttons?: ReactNode;
}

const PageHeaderComponent: React.VFC<Props> = ({
  className,
  backUrl,
  backText,
  title,
  centerMessage,
  buttons,
}) => {
  return (
    <div className={classNames('cr-header', className)}>
      <div className="cr-header__container">
        <div className="cr-header__content container">
          {backUrl && backText && <GoBackButton url={backUrl} text={backText} />}

          <div className="cr-header__main">
            <div className="cr-header__title" data-id="header_title">
              {title}
            </div>
          </div>

          {centerMessage && <div className="cr-header__center-message">{centerMessage}</div>}

          <div className="cr-header__buttons">{buttons}</div>
        </div>
      </div>
    </div>
  );
};

export const PageHeader = observer(PageHeaderComponent);
