import {
  comparer,
  reaction,
} from 'mobx';
import {
  set,
  view,
} from 'ramda';
import {debounce} from 'throttle-debounce';

// Don't send `Password` if it not changed
export const makeSaveLocationApiReaction = (expression, id: string, passwordPathLens, saveLocationApi) => {
  let withPassword = false;

  return reaction(
    expression,
    debounce(500, (json: any) => {
      const jsonData = withPassword ? json : set(passwordPathLens, undefined, json);
      saveLocationApi(id, jsonData);

      withPassword = false;
    }),
    {
      equals: (a, b) => {
        withPassword = withPassword || view(passwordPathLens, a) !== view(passwordPathLens, b);
        return comparer.default(a, b);
      }
    }
  );
};
