import {front} from 'app/api/CirrusApi';
import {UpdateMode} from 'app/components/sharedReactComponents/Events/Editor/EventForm/types';
import {ScheduleContract} from 'app/contracts/schedule';
import {Device} from 'app/components/DeviceDetails/Models/types';

export class ScheduleApiService {
  static async refreshDeviceSchedule(id: string): Promise<void> {
    return front.cms().v2().schedule().devices(id).refresh().post();
  }

  static async refreshTeamSchedule(): Promise<void> {
    return front.cms().v2().schedule().refresh().post();
  }

  static async startEvent(id: string): Promise<void> {
    return front.cms().v2().schedule().events(id).action().start().post();
  }

  static async stopEvent(id: string): Promise<void> {
    return front.cms().v2().schedule().events(id).action().stop().post();
  }

  static async pauseEvent(id: string): Promise<void> {
    return front.cms().v2().schedule().events(id).action().pause().post();
  }

  static async resumeEvent(id: string): Promise<void> {
    return front.cms().v2().schedule().events(id).action().resume().post();
  }

  static async extendEvent(id: string): Promise<void> {
    return front.cms().v2().schedule().events(id).action().extend().post();
  }

  static async confirmEvent(eventId: string, deviceId: string): Promise<void> {
    return front.cms().v2().schedule().events(eventId).confirm().devices(deviceId).post();
  }

  static async updateEvent(
    eventId: string,
    r: ScheduleContract.EventRule,
    mode: UpdateMode,
  ): Promise<void> {
    return front.cms().v2().schedule().events(eventId, {scope: mode}).put(r);
  }

  static async createEvent(p: ScheduleContract.EventRule): Promise<void> {
    return front.cms().v2().schedule().events().post(p);
  }

  static async getTeamSchedule(
    since: TimeStampMilliseconds,
    until: TimeStampMilliseconds,
  ): Promise<ScheduleContract.TeamSchedule> {
    return front.cms().v2().schedule().events().get({since, until});
  }

  static async getDeviceSchedule(
    deviceId: string,
    since: TimeStampMilliseconds,
    until: TimeStampMilliseconds,
  ): Promise<ScheduleContract.DeviceSchedule> {
    return front.cms().v2().schedule().devices(deviceId).events().get({since, until});
  }

  static async getEventDetails(id: string): Promise<ScheduleContract.DetailedEvent> {
    return front.cms().v2().schedule().events(id).get();
  }

  static async deleteEvent(id: string, mode: UpdateMode): Promise<void> {
    return front.cms().v2().schedule().events(id, {scope: mode}).delete();
  }

  static async getEpiphanCmsDevices(): Promise<Device[]> {
    return front.cms().v2().schedule().devices().get({cms_name: 'chrono'});
  }

  static async getDeviceActualEvent(deviceId: string): Promise<ScheduleContract.Event | undefined> {
    return front.cms().v2().schedule().devices(deviceId).current_event().get();
  }

  static async getTeamActualEvents(): Promise<Record<string, ScheduleContract.Event>> {
    return front.cms().v2().schedule().current_events().get();
  }
}

export const scheduleApiService = new ScheduleApiService();
