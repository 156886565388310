import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';
import {UpdateMode} from 'app/components/sharedReactComponents/Events/Editor/EventForm/types';

interface Props extends Prompt {
  open: boolean;
  onConfirm: Fn<Promise<void>, [mode: UpdateMode]>;
}

export function UpdateRecurrentRuleDialog({open, onConfirm, onClose}: Props) {
  const [mode, setMode] = useState<UpdateMode>('this');

  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value as UpdateMode;
    setMode(newValue);
  };

  useEffect(() => {
    if (!open) {
      setMode('this');
    }
  }, [open]);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await onConfirm(mode);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true} onClose={onClose}>
      <DialogTitle>Select update option</DialogTitle>

      <DialogContent sx={{display: 'flex', justifyContent: 'center', '& label': {mb: 0}}}>
        <RadioGroup value={mode} onChange={handleChange}>
          <FormControlLabel
            sx={{display: 'block'}}
            value="this"
            control={<Radio data-id="this-radio" />}
            label="This event"
          />
          <FormControlLabel
            sx={{display: 'block'}}
            value="forward"
            control={<Radio data-id="forward-radio" />}
            label="This event and following"
          />
        </RadioGroup>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="confirm-update-button"
          color="secondary"
          variant="contained"
          loading={loading}
          onClick={handleConfirm}
        >
          Confirm
        </LoadingButton>

        <Button data-id="cancel-update-button" variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
