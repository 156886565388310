import {useQuery} from '@tanstack/react-query';
import {AlertsApi, AlertsMapper} from 'app/components/entities/alerts';

export function useTeamAlertsQuery() {
  return useQuery({
    queryKey: ['alerts'],
    queryFn: async () => {
      const response = await AlertsApi.getTeamAlerts();
      return response.map(AlertsMapper.mapAlert);
    },
  });
}
