import {front} from 'app/api/CirrusApi';
import {apiClient} from 'app/components/shared';

export class DeviceGroupApiService {
  static async moveToGroup(teamId: string, groupId: string, deviceId: string): Promise<void> {
    return apiClient.post(`front/api/v1t/team/${teamId}/groups/${groupId}/${deviceId}`);
  }

  static async removeFromGroup(teamId: string, groupId: string, deviceId: string): Promise<void> {
    return apiClient.delete(`front/api/v1t/team/${teamId}/groups/${groupId}/${deviceId}`);
  }

  static async createGroup(teamId: string, name: string): Promise<void> {
    return apiClient.post(`/front/api/v1t/team/${teamId}/groups`, {name});
  }

  static async renameGroup(teamId: string, groupId: string, name: string): Promise<void> {
    return apiClient.post(`front/api/v1t/team/${teamId}/groups/${groupId}`, {name});
  }

  static async deleteGroup(teamId: string, groupId: string): Promise<void> {
    return apiClient.delete(`front/api/v1t/team/${teamId}/groups/${groupId}`);
  }

  static async batchDelete(groups: string[]): Promise<void> {
    return front.groups().batch().remove().post({groups});
  }

  static async batchRemoveDevices(devices: string[]): Promise<void> {
    return front.groups().batch().ungroup().post({devices});
  }

  static async batchMoveToGroup(groupId: string, devices: string[]): Promise<void> {
    return front.groups(groupId).devices().move().post({devices});
  }
}
