import React from 'react';
import classNames from 'classnames';
import {Footer} from 'app/components/sharedReactComponents/StreamingDestinationSettings/Footer';
import {StreamingDestinationSettingsForm} from 'app/components/sharedReactComponents/StreamingDestinationSettings/StreamingDestinationSettingsForm';
import {Callback, ClassName} from 'app/types/common';
import {StreamingDestinationControl} from 'app/components/sharedReactComponents/StreamingDestinationSettings/types';

interface Props extends ClassName {
  streamingDestination: any;
  updating: boolean;
  deleting?: boolean;
  disabled: boolean;
  onChange: Callback;
  onClickDelete: Callback;
}

export const RTMPStreamingDestinationSettings = ({
  className,
  streamingDestination,
  updating,
  deleting,
  disabled,
  onClickDelete,
  onChange,
}: Props) => {
  const getProcessedFormData = () => ({
    name: streamingDestination.getNameSetting(),
    url: streamingDestination.getUrlSetting(),
    key: streamingDestination.getKeySetting() || '',
  });

  const handleSaveForm = (formData) => {
    onChange(formData);
  };

  const controls: StreamingDestinationControl[] = [
    {
      label: 'RTMP destination name',
      name: 'name',
      required: true,
    },
    {
      label: 'RTMP url',
      name: 'url',
      required: true,
    },
    {
      label: 'RTMP streaming key',
      name: 'key',
    },
  ];

  return (
    <div className={classNames('streaming-destination-settings', className)}>
      <StreamingDestinationSettingsForm
        formData={getProcessedFormData()}
        controls={controls}
        disabled={disabled}
        loading={updating}
        onSave={handleSaveForm}
      />

      <Footer
        deleteButtonText="Delete rtmp destination"
        deleting={deleting}
        disabled={disabled}
        onClickDelete={onClickDelete}
      />
    </div>
  );
};
