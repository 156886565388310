import React from 'react';
import {Box, Stack, SxProps, Typography} from '@mui/material';
import {Component} from 'app/types/common';
import {backgrounds} from 'app/themes/app';

interface BaseProps extends React.PropsWithChildren<Component> {}
type Components = 'root' | 'title' | 'price' | 'description' | 'actions';

const styles: Record<Components, SxProps> = {
  root: {
    px: 4,
    pt: 2,
    pb: 5,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    minWidth: 260,
    height: 380,
    flexGrow: 0,
    flexShrink: 0,
  },
  title: {mb: 2, textTransform: 'uppercase', fontWeight: 'bold'},
  price: {mb: 2, fontWeight: 'bold', fontSize: '16px'},
  description: {textAlign: 'center'},
  actions: {mt: 'auto', textAlign: 'center'},
};

function Title({children}: BaseProps) {
  return (
    <Typography
      data-id="tier-name"
      component="div"
      variant="h6"
      sx={styles.title}
      textAlign="center"
    >
      {children}
    </Typography>
  );
}

interface PriceProps extends BaseProps {
  value: number;
  interval: string;
  perDevice?: boolean;
}

function Price({dataId, value, interval, perDevice}: Omit<PriceProps, 'children'>) {
  return (
    <Box data-id={dataId} sx={styles.price}>
      <Typography data-id="tier-price" component="span" sx={{fontSize: '28px'}}>
        ${value}
      </Typography>{' '}
      {perDevice ? 'per device unit' : ''} / <span data-id="interval-size">{interval}</span>
    </Box>
  );
}

function Description({children}: BaseProps) {
  return <Box sx={styles.description}>{children}</Box>;
}

function Actions({children}: BaseProps) {
  return <Box sx={styles.actions}>{children}</Box>;
}

interface Props extends BaseProps {
  sx?: SxProps;
  current?: boolean;
}

export function TierCard({children, dataId, sx, current = false}: Props) {
  return (
    <Stack
      data-id={dataId}
      alignItems="center"
      sx={[
        styles.root,
        current ? {backgroundColor: backgrounds.blue} : {},
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Stack>
  );
}

TierCard.Title = Title;
TierCard.Price = Price;
TierCard.Description = Description;
TierCard.Actions = Actions;
