import React, {useState} from 'react';
import {Box, Checkbox, CircularProgress, FormControlLabel, Stack, Typography} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import {grey} from '@mui/material/colors';
import {Sx} from 'app/types/common';
import {LegacyIcon} from 'app/components/sharedReactComponents/LegacyIcon/LegacyIcon';
import {Icons} from 'app/util/icons';
import {palette} from 'app/themes/app';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {RemoveAccountDialog} from 'app/components/Settings/ConnectSettings/dialogs/RemoveAccountDialog/RemoveAccountDialog';
import {isErrorResponse} from 'app/api/types';
import {Notifications} from 'app/components/Notifications';

interface Props extends Sx {
  userId: string;
  teamName: string;
  canManage: boolean;
  account: App.Connect.Auth.ZoomAccount;
  onShare: Fn<Promise<any>, [id: string, share: boolean]>;
  onSignOut: Fn<Promise<any>, [id: string]>;
}

export function ZoomAccount({sx, userId, teamName, canManage, account, onShare, onSignOut}: Props) {
  const [updating, setUpdating] = useState(false);
  const [hasPicture, setHasPicture] = useState(true);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const openDelete = () => {
    setDeleteDialog(true);
  };

  const closeDelete = () => {
    setDeleteDialog(false);
  };

  const handleSignOut = async () => {
    try {
      await onSignOut(account.id);
      closeDelete();
    } catch (e: unknown) {
      if (isErrorResponse(e)) {
        Notifications.addErrorNotification(e.data.Error ?? 'Cannot delete Zoom identity');
      }
    }
  };

  const handleShare = async () => {
    if (updating) {
      return;
    }

    try {
      setUpdating(true);
      await onShare(account.id, !account.shared);
    } finally {
      setUpdating(false);
    }
  };

  const handleUrlErr = () => {
    setHasPicture(false);
  };

  const isOwner = account.owner === userId;

  const allowSwitch = account.shared ? canManage || isOwner : isOwner;

  return (
    <Stack
      sx={sx}
      direction="row"
      alignItems="flex-start"
      gap={2}
      p={4}
      borderRadius={1}
      border={1}
      bgcolor={grey[100]}
      borderColor={grey[400]}
    >
      {hasPicture ? (
        <Box
          component="img"
          borderRadius={1}
          height={72}
          width={72}
          src={account.picture}
          onError={handleUrlErr}
        />
      ) : (
        <LegacyIcon fontSize={72}>{Icons.zoom().reactComponent()}</LegacyIcon>
      )}

      <Box>
        <Typography mb={2} fontWeight="bold" fontSize={20}>
          {account.name}
        </Typography>

        <Stack mb={1} direction="row" gap={0.5} alignItems="center">
          {account.ready ? (
            <>
              <CheckIcon sx={{color: palette.green}} />
              <Typography>Connected</Typography>
            </>
          ) : (
            <>
              <WarningIcon sx={{color: palette.red}} />
              <Typography color={palette.red}>Not available</Typography>
            </>
          )}
        </Stack>

        <Stack direction="row" alignItems="center">
          <FormControlLabel
            sx={{mb: 0}}
            disabled={!allowSwitch}
            label={`Share with team: ${teamName}`}
            control={<Checkbox checked={account.shared} onChange={handleShare} />}
          />

          {updating && <CircularProgress size={16} />}
        </Stack>
      </Box>

      <RemoveAccountDialog
        open={deleteDialog}
        name={account.name}
        platform="zoom"
        onRemove={handleSignOut}
        onClose={closeDelete}
      />

      {canManage && (
        <Box ml="auto">
          <Button
            dataId="sign-out-btn"
            variant={VARIANT.OUTLINE}
            theme={THEME.DANGER}
            onClick={openDelete}
          >
            Sign out
          </Button>
        </Box>
      )}
    </Stack>
  );
}
