import {USER_GUIDE_ADMIN_PANEL_URL} from 'app/components/DeviceDetails/ChannelsTab/constants';
import {DeviceModel} from 'app/components/DeviceDetails/constants';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';

const userGuideAdminUrlByDevice = (model: DeviceModel): USER_GUIDE_ADMIN_PANEL_URL | null => {
  if (ModelService.isPearl2(model)) {
    return USER_GUIDE_ADMIN_PANEL_URL.PEARL_2;
  }

  if (ModelService.isMini(model)) {
    return USER_GUIDE_ADMIN_PANEL_URL.PEARL_MINI;
  }

  return null;
};

export {userGuideAdminUrlByDevice};
