import React, {Component} from 'react';
import classNames from 'classnames';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {VARIANT} from 'app/constants';
import {CopyButton} from 'app/components/sharedReactComponents/CopyButton';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  token: string;
}

export class TokensPanelListItemTokenRow extends Component<Props> {
  render() {
    const {
      className,
      token,
    } = this.props;

    return (
      <div className={classNames('tokens-panel-list-item__content', className)}>
        <div className="tokens-panel-list-item__input-col">
          <FormControl
            // rework name
            name="token-input"
            className="tokens-panel-list-item__input"
            value={token}
            readOnly={true}
            fullWidth={true}
          />
        </div>

        <div className="tokens-panel-list-item__actions">
          <CopyButton
            className="tokens-panel-list-item__action"
            variant={VARIANT.LINK}
            value={token}
            text="Copy token"
          />
        </div>
      </div>
    );
  }
}
