import React from 'react';
import classNames from 'classnames';
import {TableRowCell} from 'app/components/Dashboard/Devices/TableView/TableRowCell';
import {ClassName} from 'app/types/common';
import {TableColumnType} from 'app/components/sharedReactComponents/Table/types';

interface Props extends ClassName {
  cells: TableColumnType[];
  data: any;
  renderAsTable: boolean;
}

const TableRow: React.VFC<Props> = ({
  className,
  cells,
  data,
  renderAsTable,
}) => {
  const RowComponent = renderAsTable ? 'tr' : 'div';

  return (
    <RowComponent
      className={classNames('dashboard-table__row', {
        'dashboard-table__row--selected': data.selected,
      }, className)}
      data-id="table_body_row"
    >
      {cells.map((cell, index) => (
        <TableRowCell
          key={cell.dataKey}
          cell={cell}
          rowData={data}
          rowIndex={index}
          renderAsTable={renderAsTable}
        />
      ))}
    </RowComponent>
  );
};

export {
  TableRow,
};
