/**
 * ```typescript
 * isSetsEquals(new Set([1, 2]), new Set([1, 2])); //=> true
 * isSetsEquals(new Set([3]), new Set([4])); //=> false
 * ```
 */
export function isSetsEquals(set1: Set<any>, set2: Set<any>): boolean {
  if (set1.size !== set2.size) {
    return false;
  }

  const compareSet = new Set([...set1, ...set2]);

  return compareSet.size === set1.size;
}
