import React, {useState} from 'react';
import {Box, Button, Link, Menu, MenuItem, Skeleton} from '@mui/material';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {Schedule} from 'app/domain/schedule';
import {router} from 'app/router/main';
import {StyleSx, Sx} from 'app/types/common';
import {isNil} from 'app/util/isNil';
import {DevicePicture} from 'app/components/sharedReactComponents/DevicePicture/DevicePicture';
import {packSx} from 'app/util/packSx/packSx';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';

interface Props extends Sx {
  event: Schedule.Event;
  devices?: Map<string, PearlMasterDeviceModel>;
}

const buttonSx: StyleSx = {py: 0};
const pictureSx: StyleSx = {height: 20, flexShrink: 0};
const menuSx: StyleSx = {maxWidth: 250};
const menuItemSx: StyleSx = {gap: 0.5};
const menuLinkSx: StyleSx = {gap: 0.5};
const deviceLinkSx: StyleSx = {display: 'block'};

export function DeviceLink({sx, event, devices}: Props) {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const isSingleDevice = event.devices.size === 1;

  if (isSingleDevice) {
    const deviceId = [...event.devices.keys()][0];

    if (!deviceId) {
      return null;
    }

    const device = devices?.get(deviceId);

    if (!device) {
      return <Skeleton width={80} variant="text" />;
    }

    return (
      <Link
        data-id="device-link"
        sx={packSx(deviceLinkSx, sx)}
        href={router.url('deviceDetails', {deviceId: device.getId()}, {activeTab: 'events'})}
      >
        <OverflowTooltip rowsNumber={1}>{device.getName()}</OverflowTooltip>
      </Link>
    );
  }

  const handleOpen = (e: React.SyntheticEvent<HTMLElement>) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  return (
    <Box sx={sx}>
      <Button
        sx={buttonSx}
        data-id="device-link-menu"
        onClick={handleOpen}
        variant="text"
        color="primary"
      >
        {event.devices.size} devices
      </Button>

      <Menu sx={menuSx} open={!isNil(menuAnchor)} anchorEl={menuAnchor} onClose={handleClose}>
        {Array.from(event.devices.keys()).map((id) => {
          const instance = devices?.get(id);

          if (!instance) {
            return (
              <Box px={2} py={1}>
                <Skeleton variant="rounded" height={20} />
              </Box>
            );
          }

          return (
            <MenuItem key={id} sx={menuItemSx}>
              <DevicePicture sx={pictureSx} model={instance.getModelName()} />

              <Link
                sx={menuLinkSx}
                data-id="device-link"
                href={router.url('deviceDetails', {deviceId: id}, {activeTab: 'events'})}
              >
                <OverflowTooltip rowsNumber={1}>{instance.getName()}</OverflowTooltip>
              </Link>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}
