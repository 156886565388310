import {makeAutoObservable} from 'mobx';
import {SrtStream} from 'app/store/models/connect/SrtStream/SrtStream';

export interface ParticipantArgs {
  id: string;
  name: string;
  video: boolean;
  audio: boolean;
  individualAudio: boolean;
  share: boolean;
  type: App.Connect.ParticipantType;
  state: App.Connect.ParticipantState;
  version?: string;
}

export class Participant {
  id: string;
  name: string;
  video: boolean;
  audio: boolean;
  state: App.Connect.ParticipantState;
  type: App.Connect.ParticipantType;
  stream: SrtStream;
  share: boolean;
  individualAudio: boolean;

  constructor(participant: ParticipantArgs, stream: SrtStream) {
    this.id = participant.id;
    this.name = participant.name;
    this.audio = participant.audio;
    this.individualAudio = participant.individualAudio;
    this.video = participant.video;
    this.type = participant.type;
    this.share = participant.share;
    this.state = participant.state;
    this.stream = stream;
    makeAutoObservable(this);
  }
}
