import React from 'react';
import {Box, Divider, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import ConnectFeatures from 'app/components/BillingManager/dialogs/ConnectTiersDialog/ConnectFeatures/ConnectFeatures';
import ConnectTier from 'app/components/BillingManager/dialogs/ConnectTiersDialog/PremiumTier/ConnectTier';
import {EnterpriseTier} from 'app/components/BillingManager/dialogs/shared/EnterpriseTier/EnterpriseTier';
import {palette} from 'app/themes/app';

type PremiumProps = React.ComponentProps<typeof ConnectTier>;

type Props = Omit<PremiumProps, 'tier' | 'current'> & {
  currentId?: string;
  tiers: App.Billing.Connect.Tier[];
  expireDate?: TimeStampSeconds;
};

export function ConnectTiers({
  currentId,
  custom,
  hasCustomer,
  resumable,
  tiers,
  expireDate,
  onSelectTier,
  onEditPayment,
}: Props) {
  return (
    <Box sx={{display: 'inline-block', px: 8, py: 5}}>
      <Stack
        gap={1}
        position="relative"
        direction="row"
        borderBottom={`1px dashed ${grey[300]}`}
        divider={<Divider sx={{borderStyle: 'dashed'}} orientation="vertical" flexItem />}
      >
        <Stack sx={{minWidth: 340, flexGrow: 1}}>
          <Typography variant="h5" fontWeight={600} mb={2}>
            Choose your <br /> Epiphan Connect plan
          </Typography>

          {!hasCustomer && (
            <Typography color={palette.darkerGray}>
              You need to add your billing details
              <br /> before confirming the subscription.
            </Typography>
          )}

          <Typography mt="auto" mb={1.5} fontWeight={600}>
            Features
          </Typography>
        </Stack>

        {tiers.map((t) => (
          <ConnectTier
            dataId={t.id}
            key={t.id}
            current={t.id === currentId}
            tier={t}
            hasCustomer={hasCustomer}
            custom={custom}
            resumable={resumable}
            onSelectTier={onSelectTier}
            onEditPayment={onEditPayment}
          />
        ))}

        <EnterpriseTier current={custom} expireDate={expireDate} />
      </Stack>

      <ConnectFeatures currentId={currentId} tiers={tiers} custom={custom} />
    </Box>
  );
}
