import {DeviceModel} from 'app/components/DeviceDetails/Models/DeviceModel';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {DeviceWarning} from 'app/components/DeviceDetails/Models/types';

export class PearlMasterDeviceModel extends DeviceModel {
  /**
   * @deprecated
   */
  getChannels() {
    return this.device.Child ?? [];
  }

  /**
   * @deprecated
   */
  getChanelModels(): PearlSlotDeviceModel[] {
    const masterDeviceName = this.getName();

    return this.getChannels().map((channelDevice) => {
      const device = new PearlSlotDeviceModel(channelDevice, {
        masterDeviceName,
        masterCapabilities: this.getCapabilitiesRaw(),
        masterModelName: this.getModelName(),
      });

      return device;
    });
  }

  /**
   * @deprecated use deviceChannels[channelId] to get channel
   */
  getChannelDeviceById(id: string): PearlSlotDeviceModel | undefined {
    return this.getChanelModels().find((channelDevice) => channelDevice.getId() === id);
  }

  getChannelByIndex(idx: string): PearlSlotDeviceModel | undefined {
    return this.getChanelModels().find(
      (channelDevice) => channelDevice.getChannelDeviceIdIndex() === idx,
    );
  }

  /**
   * @deprecated
   */
  hasChannels(): boolean {
    return this.getChannels().length > 0;
  }

  /**
   * Pearl device and their channel-devices warnings collection
   */
  getWarnings(): DeviceWarning[] {
    const warnings: DeviceWarning[] = [];
    const channelDevices = this.getChanelModels();
    const channelDevicesMap: Map<string, PearlSlotDeviceModel> = new Map();

    channelDevices.forEach((channelDevice) => {
      channelDevicesMap.set(channelDevice.getChannelDeviceIdIndex(), channelDevice);
    });

    super.getWarnings().forEach((deviceWarning) => {
      const {id, message, channel_id: channelId} = deviceWarning;

      if (!channelId) {
        warnings.push(deviceWarning);
      } else {
        // Old devices
        const channelDevice = channelDevicesMap.get(channelId);

        if (channelDevice) {
          warnings.push({
            ...deviceWarning,
            id: `${id}_${channelId}`,
            message: `Channel '${channelDevice.getName()}': ${message}`,
          });
        }
      }
    });

    channelDevices.forEach((channelDevice) => {
      channelDevice.getWarnings().forEach((channelWarning) => {
        warnings.push(channelWarning);
      });
    });

    return warnings;
  }

  /**
   * Is some of channel-device is streaming
   */
  isStreaming(): boolean {
    if (this.isOffline()) {
      return false;
    }

    return this.getChanelModels().some((channelDevice) => channelDevice.isStreaming());
  }

  isStreamingStarted(): boolean {
    if (this.isOffline()) {
      return false;
    }

    return this.getChanelModels().some((channelDevice) => channelDevice.isStreamingStarted());
  }

  /**
   * Is some of channel-device is recording
   * @deprecated
   */
  isRecording(): boolean {
    if (this.isOffline()) {
      return false;
    }

    return this.getChanelModels().some((channelDevice) => channelDevice.isRecording());
  }

  get createdAt() {
    return this.device.UnifyMeta?.CreatedAt;
  }

  get updatedAt() {
    return this.device.UnifyMeta?.UpdatedAt;
  }
}
