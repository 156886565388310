import React, {useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {Button, Dialog, DialogActions, DialogTitle} from '@mui/material';
import {Prompt} from 'app/types/common';

interface Props extends Prompt {
  name: string;
  onConfirm(): Promise<void>;
}

export function ConfirmEventDialog({open, name, onConfirm, onClose}: Props) {
  const [loading, setLoading] = useState(false);

  const handleStop = async () => {
    try {
      setLoading(true);
      await onConfirm();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true} onClose={onClose}>
      <DialogTitle>
        Confirm <b>{name}</b>
      </DialogTitle>

      <DialogActions>
        <LoadingButton
          data-id="confirm-confirm-button"
          variant="contained"
          color="primary"
          loading={loading}
          onClick={handleStop}
        >
          Confirm
        </LoadingButton>

        <Button data-id="cancel-confirm-button" variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
