import React from 'react';
import {TranscribeDeviceDetails} from 'app/components/FleetManager/DeviceCard/ChildCard/TranscribeDeviceDetails';
import {Channels} from 'app/components/FleetManager/DeviceCard/Channels';
import {WebcasterDeviceDetails} from 'app/components/FleetManager/DeviceCard/ChildCard/WebcasterDeviceDetails';
import {DemoDeviceDetails} from 'app/components/FleetManager/DeviceCard/ChildCard/DemoDeviceDetails';
import {DeviceModel} from 'app/components/DeviceDetails/constants';
import {Callback} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';

interface Props {
  device: AnyDeviceModelType;
  streamingDestinations: AnyStreamingDestinationModelType[];
  selectable: boolean;
  role: AnyUserRoleModel;
  hasEvent?: boolean;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  checkIsDeviceSelected: Callback;
  toggleDeviceSelection: Callback;
  onChangeStreamingDestination: Callback;
}

export const DeviceDetails = React.memo<Props>(
  ({
    device,
    streamingDestinations,
    selectable,
    hasEvent,
    getDeviceById,
    role: currentUserRole,
    checkIsDeviceSelected,
    toggleDeviceSelection,
    onChangeStreamingDestination,
  }: Props) => {
    if (ModelService.isMultiChannel(device.getModelName())) {
      return (
        <Channels
          device={device as PearlMasterDeviceModel}
          streamingDestinations={streamingDestinations}
          selectable={selectable}
          getDeviceById={getDeviceById}
          currentUserRole={currentUserRole}
          hasEvent={hasEvent}
          checkIsDeviceSelected={checkIsDeviceSelected}
          toggleDeviceSelection={toggleDeviceSelection}
          onChangeStreamingDestination={onChangeStreamingDestination}
        />
      );
    }

    switch (device.getModelName()) {
      case DeviceModel.LiveScrypt:
        return (
          <TranscribeDeviceDetails
            device={device}
            currentUserRole={currentUserRole}
            checkIsDeviceSelected={checkIsDeviceSelected}
          />
        );
      case DeviceModel.Webcaster:
        return (
          <WebcasterDeviceDetails
            device={device}
            streamingDestinations={streamingDestinations}
            currentUserRole={currentUserRole}
            getDeviceById={getDeviceById}
            checkIsDeviceSelected={checkIsDeviceSelected}
            onChangeStreamingDestination={onChangeStreamingDestination}
          />
        );
      case DeviceModel.Demo:
        return (
          <DemoDeviceDetails
            device={device}
            currentUserRole={currentUserRole}
            checkIsDeviceSelected={checkIsDeviceSelected}
          />
        );
      default:
        return null;
    }
  },
);
