import React from 'react';
import {filesize} from 'filesize';
import {Box, Card, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {Sx} from 'app/types/common';
import {Edge} from 'app/domain/edge';
import {METER_THEME, Meter} from 'app/components/sharedReactComponents/Meter';
import {packSx} from 'app/util/packSx/packSx';

interface Props extends Sx {
  storage: Edge.Storage;
}

export function StoragePanel({sx, storage}: Props) {
  const {free, total, used} = storage;
  const value = (used / total) * 100;
  const isWarning = value >= 90;

  return (
    <Card
      sx={packSx(
        {p: 2, display: 'flex', flexDirection: 'column'},
        isWarning && {borderColor: 'error.main'},
        sx,
      )}
      variant="outlined"
    >
      <Typography variant="h6" color={grey[600]} mb={0.5}>
        Storage available
      </Typography>

      <Typography
        data-id="storage_available"
        fontSize={24}
        color={isWarning ? 'error.main' : 'text.primary'}
      >
        {filesize(free, {round: 1})}
      </Typography>

      <Box mt={2}>
        <Meter
          value={value}
          theme={isWarning ? METER_THEME.RED : METER_THEME.DEFAULT}
          disabled={false}
        />
      </Box>

      <Typography mt={1.5} data-id="device_storage_meter_label" color={grey[600]}>
        {filesize(used, {round: 1})} of {filesize(total, {round: 1})} used
      </Typography>
    </Card>
  );
}
