export type SortType =
  | 'name-asc'
  | 'name-desc'
  | 'size-asc'
  | 'size-desc'
  | 'date-asc'
  | 'date-desc';

export const sortOptions: Array<{value: SortType; label: string}> = [
  {value: 'name-asc', label: 'Name (A-Z)'},
  {value: 'name-desc', label: 'Name (Z-A)'},
  {value: 'date-asc', label: 'Oldest'},
  {value: 'date-desc', label: 'Newest'},
  {value: 'size-asc', label: 'Smallest'},
  {value: 'size-desc', label: 'Largest'},
];
