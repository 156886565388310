import React, {useState} from 'react';
import {Sx} from 'app/types/common';
import {Box, Stack, Typography} from '@mui/material';
import {Check, CheckboxesListLock} from 'app/components/sharedReactComponents/CheckboxesListLock';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {Button} from 'app/components/sharedReactComponents/Button';
import {formatAbbreviatedDateWithYear} from 'app/util/time';
import {palette} from 'app/themes/app';

const checks: Check[] = [
  {name: 'unify', label: 'All Unify projects will be deleted'},
  {name: 'features', label: 'All content and recordings associated with Unify projects will be deleted'},
];

interface Props extends Sx {
  endDate?: TimeStampSeconds;
  onConfirm: Fn;
  onCancel: Fn;
}

export function UnsubscribeFromUnify({sx, endDate, onCancel, onConfirm}: Props) {
  const [enabled, setEnabled] = useState(false);

  const handleCheck = (value: boolean) => {
    setEnabled(value);
  };

  return (
    <Stack sx={sx} alignItems="center">
      <Typography variant="h5" fontWeight={600} component="span" mb={2}>
        You are about to stop your Epiphan Unify subscription
      </Typography>

      <Typography fontSize={18} fontWeight={600}>
        All Unify data and projects will be deleted.
      </Typography>

      <Box my={8}>
        <Typography mb={2}>Click on the checkboxes to confirm:</Typography>
        <CheckboxesListLock checks={checks} size={SIZE.S} onChange={handleCheck} />
      </Box>

      {endDate && (
        <Typography fontSize={18} fontWeight={600} sx={{color: palette.blue}} mb={8}>
          You will get the last invoice on {formatAbbreviatedDateWithYear(endDate)}.
        </Typography>
      )}

      <Stack sx={{'& > button': {minWidth: 150}}} direction="row" alignItems="center" gap={2}>
        <ProgressButton
          dataId="accept-confirm-btn"
          variant={VARIANT.SOLID}
          theme={THEME.DANGER}
          disabled={!enabled}
          onClick={onConfirm}
          autoFocus={true}
        >
          Stop Unify subscription
        </ProgressButton>

        <Button
          dataId="cancel-confirm-btn"
          theme={THEME.SECONDARY}
          variant={VARIANT.OUTLINE}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}
