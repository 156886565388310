import {Callback} from 'app/types/common';
import {useCallback, useEffect, useRef, useState} from 'react';
import {toggleInSet} from 'app/util/toggleInSet';
import {CollapsedFilterSwitchGroupStore} from 'app/components/FleetManager/CollapsedFilterSwitchGroupStore';

type Return = {
  collapsedFilterSwitchGroups: Set<string>;
  toggleFilterSwitchGroupCollapse: Callback<string>;
};

type CollapseFilterSwitchesParams = {
  teamId: string;
  userId: string;
  storeKey: string;
};

function useCollapseFilterSwitches({
  teamId,
  userId,
  storeKey,
}: CollapseFilterSwitchesParams): Return {
  const store = useRef<CollapsedFilterSwitchGroupStore>();

  if (!store.current) {
    store.current = new CollapsedFilterSwitchGroupStore({
      teamId,
      userId,
      storeKey,
    });
  }

  const [collapsed, setCollapsed] = useState<Set<string>>(store.current.load());

  useEffect(() => {
    store.current?.save(collapsed);
  }, [collapsed]);

  const toggleFilterSwitchGroupCollapse = useCallback((id: string) => {
    setCollapsed(collapsed => toggleInSet(collapsed, id));
  }, []) as Callback<string>;

  return {
    collapsedFilterSwitchGroups: collapsed,
    toggleFilterSwitchGroupCollapse,
  };
}

export {
  useCollapseFilterSwitches,
};
