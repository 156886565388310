import {makeAutoObservable} from 'mobx';

export class SrtSettingsForm {
  settings: App.Connect.StreamSettings;
  errors?: App.Connect.ValidationErrors;

  synchronization = false;
  invalid = false;

  constructor(settings: App.Connect.StreamSettings) {
    this.settings = settings;
    makeAutoObservable(this, {}, {autoBind: true});
  }

  setSettings(settings: Partial<App.Connect.StreamSettings>) {
    this.settings = {
      ...this.settings,
      ...settings,
    };
  }

  setSynchronization(value: boolean) {
    this.synchronization = value;
  }

  setInvalid(value: boolean) {
    this.invalid = value;
  }

  setError(errors?: App.Connect.ValidationErrors) {
    this.errors = errors;
  }
}
