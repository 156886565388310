import {DeviceWarning} from 'app/components/DeviceDetails/Models/types';
import {WarningSeverity} from 'app/constants/deviceWarningSeverity';

export function getDeviceWarningsStats(warnings: DeviceWarning[]): {
  withErrors: boolean;
  withWarnings: boolean;
} {
  let withErrors = false;
  let withWarnings = false;

  // eslint-disable-next-line no-restricted-syntax
  for (const warning of warnings) {
    if (warning.severity === WarningSeverity.Error) {
      withErrors = true;
    } else if (warning.severity === WarningSeverity.Warning) {
      withWarnings = true;
    }

    if (withErrors && withWarnings) {
      return {withErrors, withWarnings};
    }
  }

  return {withErrors, withWarnings};
}
