import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {Row} from 'app/components/sharedReactComponents/StreamingDestinationSettings/Row';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {FORM_CONTROL_TYPE} from 'app/components/sharedReactComponents/FormControl/constants';
import {Checkbox} from 'app/components/sharedReactComponents/Checkbox';
import {LoadingWrapper} from 'app/components/sharedReactComponents/LoadingWrapper';
import {Callback, ClassName} from 'app/types/common';
import {KeyboardCode} from 'app/constants';
import {StreamingDestinationControl} from 'app/components/sharedReactComponents/StreamingDestinationSettings/types';

interface Props extends ClassName {
  formData: any;
  controls: StreamingDestinationControl[];
  disabled: boolean;
  loading: boolean;
  onSave: Callback;
}

export const StreamingDestinationSettingsForm = ({
  className,
  formData,
  controls,
  disabled,
  loading,
  onSave,
}: Props) => {
  const [currentFormData, setCurrentFormData] = useState<any>(formData);
  const state = useRef({
    touched: false,
  });

  useEffect(() => {
    if (loading || state.current.touched) {
      return;
    }

    setCurrentFormData(formData);
  }, [formData]);

  const handleChange = ({target: {name, value}}) => {
    state.current.touched = true;
    const control = controls.find(control => control.name === name);
    const resetData = control && typeof control.resetOnChange === 'function' ? control.resetOnChange(currentFormData) : {};

    setCurrentFormData({
      ...currentFormData,
      ...resetData,
      [name]: value,
    });
  };

  const handleSaveChanges = (name: string, value: string) => {
    state.current.touched = false;
    const notValidData: boolean = controls.some((control) => {
      const currentValue: string = control.name ? currentFormData[control?.name] : '';
      return isNotValidData(control.required ?? false, currentValue);
    });

    if (notValidData) {
      return;
    }

    const prevValue = formData[name];

    if (value !== prevValue) {
      onSave(currentFormData);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    handleSaveChanges(name, value);
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.code === KeyboardCode.Enter) {
      const {name, value} = event.target as HTMLInputElement;
      handleSaveChanges(name, value);
    }
  };

  const isNotValidData = (required: boolean, value: string): boolean => {
    return required && value.trim() === '';
  };

  const renderControl = (control) => {
    const value = typeof control.value === 'undefined' ? currentFormData[control.name] : control.value;

    const {
      componentRenderer,
      component,
      resetOnChange,
      required,
      ...restControl
    } = control;

    const props = {
      ...restControl,
      value,
      disabled,
      onChange: handleChange,
      onBlur: handleBlur,
      onKeyUp: handleKeyUp,
    };

    const error = isNotValidData(required, value);

    if (componentRenderer) {
      return componentRenderer(props, currentFormData);
    }

    if (component) {
      return React.createElement(component, props);
    }

    switch (control.type) {
      case FORM_CONTROL_TYPE.CHECKBOX:
        return (
          <Checkbox {...props} data-id="streaming_destination_settings_checkbox"/>
        );
      default:
        return (
          <FormControl
            {...props}
            error={error}
            data-id="streaming_destination_settings_input"
            fullWidth={true}
          />
        );
    }
  };

  return (
    <LoadingWrapper loading={loading}>
      <div className={classNames('streaming-destination-settings__form', className)}>
        {controls.map(control => {
          if (control.isVisible && !control.isVisible(currentFormData)) {
            return null;
          }

          return (
            <Row key={control.name ?? control.label}>
              {renderControl(control)}
            </Row>
          );
        })}
      </div>
    </LoadingWrapper>
  );
};
