/* eslint-disable max-classes-per-file */
import React from 'react';
import {SIZE, THEME} from 'app/constants';
import {isNil} from 'app/util/isNil';
import {Callback} from 'app/types/common';
import accountBoxIcon from '../../assets/img/svg-icons/svg-account-box.svg';
import addPaymentMethodIcon from '../../assets/img/svg-icons/svg-add-payment-method.svg';
import archiveIcon from '../../assets/img/svg-icons/svg-archive.svg';
import arrowDownIcon from '../../assets/img/svg-icons/svg-arrow-down.svg';
import arrowLeftIcon from '../../assets/img/svg-icons/svg-arrow-left.svg';
import arrowLightRight from '../../assets/img/svg-icons/svg-arrow-light-right.svg';
import arrowRightIcon from '../../assets/img/svg-icons/svg-arrow-right.svg';
import arrowTwoWayIcon from '../../assets/img/svg-icons/svg-arrow-two-way.svg';
import arrowUpIcon from '../../assets/img/svg-icons/svg-arrow-up.svg';
import attention from '../../assets/img/svg-icons/svg-attention.svg';
import audioControlIcon from '../../assets/img/svg-icons/svg-audio-control.svg';
import backIcon from '../../assets/img/svg-icons/svg-back.svg';
import backwardIcon from '../../assets/img/svg-icons/svg-arrow-fat-left.svg';
import bellIcon from '../../assets/img/svg-icons/svg-bell.svg';
import bracketLeftIcon from '../../assets/img/svg-icons/svg-bracket-left.svg';
import bracketRightIcon from '../../assets/img/svg-icons/svg-bracket-right.svg';
import calendarIcon from '../../assets/img/svg-icons/svg-calendar.svg';
import caretThinIcon from '../../assets/img/svg-icons/svg-caret-thin.svg';
import caretTriangleIcon from '../../assets/img/svg-icons/svg-caret-triangle.svg';
import chainIcon from '../../assets/img/svg-icons/svg-chain.svg';
import checkCircleIcon from '../../assets/img/svg-icons/svg-check-circle.svg';
import checkboxCheckedIcon from '../../assets/img/svg-icons/svg-checkbox-checked.svg';
import checkboxUncheckedIcon from '../../assets/img/svg-icons/svg-checkbox-unchecked.svg';
import checkmarkIcon from '../../assets/img/svg-icons/svg-checkmark.svg';
import closeIcon from '../../assets/img/svg-icons/svg-close.svg';
import creditCard from '../../assets/img/svg-icons/svg-credit-card.svg';
import coupon from '../../assets/img/svg-icons/svg-coupon.svg';
import copyIcon from '../../assets/img/svg-icons/svg-copy.svg';
import deviceCircuitIcon from '../../assets/img/svg-icons/svg-device-circuit.svg';
import devicePresetLastAppliedIcon from '../../assets/img/svg-icons/svg-device-preset-last-applied.svg';
import devicesIcon from '../../assets/img/svg-icons/svg-devices.svg';
import downIcon from '../../assets/img/svg-icons/svg-arrow-fat-down.svg';
import downloadIcon from '../../assets/img/svg-icons/svg-download.svg';
import downloadSimpleIcon from '../../assets/img/svg-icons/svg-download-simple.svg';
import editIcon from '../../assets/img/svg-icons/svg-edit.svg';
import effectsIcon from '../../assets/img/svg-icons/svg-effects.svg';
import ellipsisIcon from '../../assets/img/svg-icons/svg-ellipsis.svg';
import exclamationIcon from '../../assets/img/svg-icons/svg-exclamation.svg';
import fairIcon from '../../assets/img/svg-icons/svg-fair.svg';
import facebookIcon from '../../assets/img/svg-icons/svg-facebook.svg';
import filterIcon from '../../assets/img/svg-icons/svg-filter.svg';
import fleetIcon from '../../assets/img/svg-icons/svg-fleet.svg';
import forwardIcon from '../../assets/img/svg-icons/svg-arrow-fat-right.svg';
import fullscreenIcon from '../../assets/img/svg-icons/svg-fullscreen.svg';
import gridViewIcon from '../../assets/img/svg-icons/svg-grid-view.svg';
import groupFolderIcon from '../../assets/img/svg-icons/svg-group-folder.svg';
import groupFolderStrokeIcon from '../../assets/img/svg-icons/svg-group-folder-stroke.svg';
import groupMoveIcon from '../../assets/img/svg-icons/svg-group-move.svg';
import groupRemoveIcon from '../../assets/img/svg-icons/svg-group-remove.svg';
import helpCircleIcon from '../../assets/img/svg-icons/svg-help-circle.svg';
import homeIcon from '../../assets/img/svg-icons/svg-home.svg';
import infinityIcon from '../../assets/img/svg-icons/svg-infinity.svg';
import infoIcon from '../../assets/img/svg-icons/svg-info.svg';
import inviteIcon from '../../assets/img/svg-icons/svg-invite.svg';
import keyframeIcon from '../../assets/img/svg-icons/svg-keyframe.svg';
import layoutIcon from '../../assets/img/svg-icons/svg-layout.svg';
import lightningIcon from '../../assets/img/svg-icons/svg-lightning.svg';
import linkIcon from '../../assets/img/svg-icons/svg-link.svg';
import listViewIcon from '../../assets/img/svg-icons/svg-list-view.svg';
import liveScryptIcon from '../../assets/img/svg-icons/svg-livescrypt.svg';
import loadingDotsIcon from '../../assets/img/svg-icons/svg-loading-dots.svg';
import lockIcon from '../../assets/img/svg-icons/svg-lock.svg';
import logoutIcon from '../../assets/img/svg-icons/svg-logout.svg';
import moneyIcon from '../../assets/img/svg-icons/svg-money.svg';
import movieIcon from '../../assets/img/svg-icons/svg-movie.svg';
import notificationsIcon from '../../assets/img/svg-icons/svg-notifications.svg';
import pauseIcon from '../../assets/img/svg-icons/svg-pause.svg';
import playIcon from '../../assets/img/svg-icons/svg-play.svg';
import plusCircleIcon from '../../assets/img/svg-icons/svg-plus-circle.svg';
import plusIcon from '../../assets/img/svg-icons/svg-plus.svg';
import postIcon from '../../assets/img/svg-icons/svg-post.svg';
import powerOffIcon from '../../assets/img/svg-icons/svg-power-off.svg';
import preferencesIcon from '../../assets/img/svg-icons/svg-preferences.svg';
import prepostrollIcon from '../../assets/img/svg-icons/svg-prepostroll.svg';
import presetIcon from '../../assets/img/svg-icons/svg-preset.svg';
import recordIcon from '../../assets/img/svg-icons/svg-record.svg';
import recordEventIcon from '../../assets/img/svg-icons/svg-event-record.svg';
import removeIcon from '../../assets/img/svg-icons/svg-remove.svg';
import renderIcon from '../../assets/img/svg-icons/svg-render.svg';
import requiredIcon from '../../assets/img/svg-icons/svg-required.svg';
import resizerIcon from '../../assets/img/svg-icons/svg-resizer.svg';
import retryIcon from '../../assets/img/svg-icons/svg-retry.svg';
import saveIcon from '../../assets/img/svg-icons/svg-save.svg';
import searchIcon from '../../assets/img/svg-icons/svg-search.svg';
import searchMinusIcon from '../../assets/img/svg-icons/svg-search-minus.svg';
import searchPlusIcon from '../../assets/img/svg-icons/svg-search-plus.svg';
import selectPresetIcon from '../../assets/img/svg-icons/svg-select-preset.svg';
import sendIcon from '../../assets/img/svg-icons/svg-send.svg';
import settingsIcon from '../../assets/img/svg-icons/svg-settings.svg';
import shareIcon from '../../assets/img/svg-icons/svg-share.svg';
import slidersIcon from '../../assets/img/svg-icons/svg-sliders.svg';
import spinnerIcon from '../../assets/img/svg-icons/svg-spinner.svg';
import starActiveIcon from '../../assets/img/svg-icons/svg-star-active.svg';
import srtIcon from '../../assets/img/svg-icons/svg-srt.svg';
import starIcon from '../../assets/img/svg-icons/svg-star.svg';
import stopIcon from '../../assets/img/svg-icons/svg-stop.svg';
import streamingIcon from '../../assets/img/svg-icons/svg-streaming.svg';
import streamingEventIcon from '../../assets/img/svg-icons/svg-event-streaming.svg';
import subscriptionsIcon from '../../assets/img/svg-icons/svg-subscriptions.svg';
import switchOffIcon from '../../assets/img/svg-icons/svg-switch-off.svg';
import switchOnIcon from '../../assets/img/svg-icons/svg-switch-on.svg';
import teamsIcon from '../../assets/img/svg-icons/svg-teams.svg';
import timerIcon from '../../assets/img/svg-icons/svg-timer.svg';
import trashIcon from '../../assets/img/svg-icons/svg-trash.svg';
import twitterIcon from '../../assets/img/svg-icons/svg-twitter.svg';
import unlinkIcon from '../../assets/img/svg-icons/svg-unlink.svg';
import updateFirmwareIcon from '../../assets/img/svg-icons/svg-update-firmware.svg';
import uploadIcon from '../../assets/img/svg-icons/svg-upload.svg';
import urlGoArrowIcon from '../../assets/img/svg-icons/svg-url-go-arrow.svg';
import userIcon from '../../assets/img/svg-icons/svg-user.svg';
import videocameraIcon from '../../assets/img/svg-icons/svg-videocamera.svg';
import volumeIcon from '../../assets/img/svg-icons/svg-volume.svg';
import videoFileIcon from '../../assets/img/svg-icons/svg-video-file.svg';
import warningIcon from '../../assets/img/svg-icons/svg-warning.svg';
import youtubeIcon from '../../assets/img/svg-icons/svg-youtube.svg';
import facebookStreamingServiceIcon from '../../assets/img/streaming-service-icons/facebook.svg';
import facebookStreamingServiceSmallIcon from '../../assets/img/streaming-service-icons/facebook-small.svg';
import youtubeStreamingServiceIcon from '../../assets/img/streaming-service-icons/youtube.svg';
import youtubeStreamingServiceSmallIcon from '../../assets/img/streaming-service-icons/youtube-small.svg';
import twitchStreamingServiceIcon from '../../assets/img/streaming-service-icons/twitch.svg';
import twitchStreamingServiceSmallIcon from '../../assets/img/streaming-service-icons/twitch-small.svg';
import rtmpStreamingServiceIcon from '../../assets/img/streaming-service-icons/rtmp.svg';
import rtmpStreamingServiceSmallIcon from '../../assets/img/streaming-service-icons/rtmp-small.svg';
import batchForStreamingUpgradeToProIcon from '../../assets/img/upgrade-to-pro-icons/batch-for-streaming.svg';
import deviceGroupingUpgradeToProIcon from '../../assets/img/upgrade-to-pro-icons/device-grouping.svg';
import highDefinitionVideoUpgradeToProIcon from '../../assets/img/upgrade-to-pro-icons/high-definition-video.svg';
import devicePresetAfuIcon from '../../assets/img/device-preset-sections/afu.svg';
import devicePresetEpiphanCloudIcon from '../../assets/img/device-preset-sections/epiphan_cloud.svg';
import devicePresetChannelsIcon from '../../assets/img/device-preset-sections/channels.svg';
import devicePresetCmsIcon from '../../assets/img/device-preset-sections/cms.svg';
import devicePresetDisplaysIcon from '../../assets/img/device-preset-sections/displays.svg';
import devicePresetEdidIcon from '../../assets/img/device-preset-sections/edid.svg';
import devicePresetFrontScreenIcon from '../../assets/img/device-preset-sections/frontscreen.svg';
import devicePresetNetworkIcon from '../../assets/img/device-preset-sections/network.svg';
import devicePresetSourcesIcon from '../../assets/img/device-preset-sections/sources.svg';
import devicePresetSystemIcon from '../../assets/img/device-preset-sections/system.svg';
import teamPresetsFreePlanPlaceholderFileIcon from '../../assets/img/team-presets-free-plan-placeholder/file.svg';
import eventIcon from '../../assets/img/svg-icons/svg-device-event.svg';
import eventNavIcon from '../../assets/img/svg-icons/svg-event-nav.svg';
import eventFreePlanIcon from '../../assets/img/svg-icons/svg-event-card-free-plan.svg';
import eventCMSTurnedOffIcon from '../../assets/img/svg-icons/svg-events-card-cms-is-off.svg';
import eventNoDevices from '../../assets/img/svg-icons/svg-events-card-no-devices.svg';
import virtualDevice from '../../assets/img/svg-icons/svg-virtual-device.svg';
import guideIcon from '../../assets/img/virtual-device-icons/guide-icon.svg';
import virtualDeviceIcon from '../../assets/img/virtual-device-icons/virtual-device-icon.svg';
import projectIconColored from '../../assets/img/svg-icons/svg-project-icon-colored.svg';
import msTeamsIcon from '../../assets/img/svg-icons/svg-ms-teams.svg';
import videoOnIcon from '../../assets/img/svg-icons/svg-video-on.svg';
import videoOffIcon from '../../assets/img/svg-icons/svg-video-off.svg';
import microOnIcon from '../../assets/img/svg-icons/svg-microphone-on.svg';
import microOffIcon from '../../assets/img/svg-icons/svg-microphone-off.svg';
import optionsIcon from '../../assets/img/svg-icons/svg-options.svg';
import disconnectedSourceIcon from '../../assets/img/svg-icons/svg-disconnected-source.svg';
import zoomIcon from '../../assets/img/svg-icons/svg-zoom.svg';
import accountIcon from '../../assets/img/svg-icons/account.svg';
import savingsIcon from '../../assets/img/svg-icons/savings.svg';
import edgeIcon from '../../assets/img/svg-icons/edge-icon.svg';
import unifyIcon from '../../assets/img/svg-icons/unify-icon.svg';
import connectIcon from '../../assets/img/svg-icons/connect-icon.svg';

export type TextPlacement = 'left' | 'right';

class Icon {
  className: string;
  labelText = '';
  testAttr?: string;
  labelPlacement: TextPlacement = 'right';
  cb?: Callback;

  constructor(className) {
    this.className = className;

    if (this.className === undefined) {
      throw new Error('Class name is required');
    }

    this.className = `cr-icon ${this.className}`;
  }

  class(arg: string | string[]) {
    const names = Array.isArray(arg) ? arg : [arg];

    for (const name of names) {
      this.className = `${this.className} ${name}`.trim();
    }

    return this;
  }

  dataId(value: string) {
    this.testAttr = value;
  }

  onclick(cb) {
    this.cb = cb;

    return this;
  }

  /**
   * @param {string} [text]
   * @return {Icon}
   */
  label(text: string, placement: TextPlacement = 'right') {
    if (isNil(text) === false) {
      if (placement === 'left') {
        this.class('cr-icon-left-labeled');
      } else {
        this.class('cr-icon-labeled');
      }

      this.labelText = text;
      this.labelPlacement = placement;
    }

    return this;
  }

  reactComponent() {
    if (this.labelText === '') {
      return React.createElement('i', {
        className: this.className,
        'data-id': this.testAttr,
        onClick: this.cb,
      });
    }

    return React.createElement('span', null, [
      React.createElement('i', {
        className: this.className,
        'data-id': this.testAttr,
        onClick: this.cb,
      }),
      React.createElement('span', {className: 'cr-icon-label'}, this.labelText),
    ]);
  }

  size(size?: SIZE) {
    switch (size) {
      case SIZE.XS:
        return this.class('cr-icon--size-xs');
      case SIZE.S:
        return this.class('cr-icon--size-s');
      case SIZE.M:
        return this.class('cr-icon--size-m');
      case SIZE.L:
        return this.class('cr-icon--size-l');
      case SIZE.XL:
        return this.class('cr-icon--size-xl');
      case SIZE.XXL:
        return this.class('cr-icon--size-xxl');
      default:
        return this;
    }
  }

  theme(theme: THEME) {
    switch (theme) {
      case THEME.PRIMARY:
        return this.class('cr-icon--primary');
      case THEME.SUCCESS:
        return this.class('cr-icon--success');
      case THEME.WARNING:
        return this.class('cr-icon--warning');
      case THEME.DANGER:
        return this.class('cr-icon--danger');
      case THEME.SECONDARY:
        return this.class('cr-icon--secondary');
      default:
        return this;
    }
  }
}

interface Svg {
  id: string;
}

export class SvgIcon extends Icon {
  svg: Svg;

  constructor(className: string, svg) {
    super(className);

    this.svg = svg;
    this.className = `cr-icon svg-icon ${className}`;
  }

  reactComponent() {
    const icon = React.createElement(
      'div',
      {className: this.className, onClick: this.cb},
      React.createElement(
        'svg',
        {className: 'svg-icon__cnt', 'data-id': this.testAttr},
        React.createElement('use', {xlinkHref: `#${this.svg.id}`}),
      ),
    );

    if (this.labelText === '') {
      return icon;
    }

    if (this.labelPlacement === 'left') {
      return React.createElement('span', {className: 'cr-icon__wrapper'}, [
        React.createElement(
          'span',
          {className: 'cr-icon-label', key: 'icon-label'},
          this.labelText,
        ),
        React.cloneElement(icon, {key: 'icon'}),
      ]);
    }

    return React.createElement('span', {className: 'cr-icon__wrapper'}, [
      React.cloneElement(icon, {key: 'icon'}),
      React.createElement('span', {className: 'cr-icon-label', key: 'icon-label'}, this.labelText),
    ]);
  }
}

const Icons = {
  // Not unused
  accountBox() {
    return new SvgIcon('', accountBoxIcon);
  },

  addPaymentMethod() {
    return new SvgIcon('', addPaymentMethodIcon);
  },

  archive() {
    return new SvgIcon('', archiveIcon);
  },

  arrowDown() {
    return new SvgIcon('', arrowDownIcon);
  },

  arrowLeft() {
    return new SvgIcon('', arrowLeftIcon);
  },

  arrowLightRight() {
    return new SvgIcon('', arrowLightRight);
  },

  arrowRight() {
    return new SvgIcon('', arrowRightIcon);
  },

  arrowTwoWay() {
    return new SvgIcon('', arrowTwoWayIcon);
  },

  arrowUp() {
    return new SvgIcon('', arrowUpIcon);
  },

  attention() {
    return new SvgIcon('', attention);
  },

  audioControl() {
    return new SvgIcon('', audioControlIcon);
  },

  backward() {
    return new SvgIcon('', backwardIcon);
  },

  backLeft() {
    return new SvgIcon('', backIcon);
  },

  bell() {
    return new SvgIcon('', bellIcon);
  },

  bracketLeft() {
    return new SvgIcon('', bracketLeftIcon);
  },

  bracketRight() {
    return new SvgIcon('', bracketRightIcon);
  },

  calendar() {
    return new SvgIcon('', calendarIcon);
  },

  caretThin() {
    return new SvgIcon('', caretThinIcon);
  },

  caretTriangle() {
    return new SvgIcon('', caretTriangleIcon);
  },

  chain() {
    return new SvgIcon('', chainIcon);
  },

  check() {
    return new SvgIcon('', checkmarkIcon);
  },

  checkCircle() {
    return new SvgIcon('', checkCircleIcon);
  },

  checkboxChecked() {
    return new SvgIcon('', checkboxCheckedIcon);
  },

  checkboxUnchecked() {
    return new SvgIcon('', checkboxUncheckedIcon);
  },

  checkmark() {
    return new SvgIcon('', checkmarkIcon);
  },

  close() {
    return new SvgIcon('', closeIcon);
  },

  creditCard() {
    return new SvgIcon('', creditCard);
  },

  cross() {
    return new SvgIcon('', removeIcon);
  },

  coupon() {
    return new SvgIcon('', coupon);
  },

  copy() {
    return new SvgIcon('', copyIcon);
  },

  deviceCircuit() {
    return new SvgIcon('', deviceCircuitIcon);
  },

  devicePresetLastApplied() {
    return new SvgIcon('', devicePresetLastAppliedIcon);
  },

  devices() {
    return new SvgIcon('', devicesIcon);
  },

  down() {
    return new SvgIcon('', downIcon);
  },

  download() {
    return new SvgIcon('', downloadIcon);
  },

  downloadSimple() {
    return new SvgIcon('', downloadSimpleIcon);
  },

  edit() {
    return new SvgIcon('', editIcon);
  },

  effects() {
    return new SvgIcon('', effectsIcon);
  },

  ellipsis() {
    return new SvgIcon('', ellipsisIcon);
  },

  exclamation() {
    return new SvgIcon('', exclamationIcon);
  },

  fair() {
    return new SvgIcon('', fairIcon);
  },

  // Not unused
  facebook() {
    return new SvgIcon('', facebookIcon);
  },

  // Not unused
  filter() {
    return new SvgIcon('', filterIcon);
  },

  fleet() {
    return new SvgIcon('', fleetIcon);
  },

  forward() {
    return new SvgIcon('', forwardIcon);
  },

  fullscreen() {
    return new SvgIcon('', fullscreenIcon);
  },

  gridView() {
    return new SvgIcon('', gridViewIcon);
  },

  groupFolder() {
    return new SvgIcon('', groupFolderIcon);
  },

  groupFolderStroke() {
    return new SvgIcon('', groupFolderStrokeIcon);
  },

  groupMove() {
    return new SvgIcon('', groupMoveIcon);
  },

  groupRemove() {
    return new SvgIcon('', groupRemoveIcon);
  },

  helpCircle() {
    return new SvgIcon('', helpCircleIcon);
  },

  infinity() {
    return new SvgIcon('', infinityIcon);
  },

  home() {
    return new SvgIcon('', homeIcon);
  },

  info() {
    return new SvgIcon('', infoIcon);
  },

  invite() {
    return new SvgIcon('', inviteIcon);
  },

  keyframe() {
    return new SvgIcon('', keyframeIcon);
  },

  layout() {
    return new SvgIcon('', layoutIcon);
  },

  lightning() {
    return new SvgIcon('', lightningIcon);
  },

  link() {
    return new SvgIcon('', linkIcon);
  },

  listView() {
    return new SvgIcon('', listViewIcon);
  },

  liveScrypt() {
    return new SvgIcon('', liveScryptIcon);
  },

  loadingDots() {
    return new SvgIcon('', loadingDotsIcon);
  },

  lock() {
    return new SvgIcon('', lockIcon);
  },

  logout() {
    return new SvgIcon('', logoutIcon);
  },

  money() {
    return new SvgIcon('', moneyIcon);
  },

  movie() {
    return new SvgIcon('', movieIcon);
  },

  notifications() {
    return new SvgIcon('', notificationsIcon);
  },

  pause() {
    return new SvgIcon('', pauseIcon);
  },

  play() {
    return new SvgIcon('', playIcon);
  },

  // Not unused
  plus() {
    return new SvgIcon('', plusIcon);
  },

  plusCircle() {
    return new SvgIcon('', plusCircleIcon);
  },

  post() {
    return new SvgIcon('', postIcon);
  },

  powerOff() {
    return new SvgIcon('', powerOffIcon);
  },

  preferences() {
    return new SvgIcon('', preferencesIcon);
  },

  prepostroll() {
    return new SvgIcon('', prepostrollIcon);
  },

  preset() {
    return new SvgIcon('', presetIcon);
  },

  record() {
    return new SvgIcon('', recordIcon);
  },

  recordEvent() {
    return new SvgIcon('', recordEventIcon);
  },

  remove() {
    return new SvgIcon('', removeIcon);
  },

  render() {
    return new SvgIcon('', renderIcon);
  },

  // Not unused
  required() {
    return new SvgIcon('', requiredIcon);
  },

  // Not unused
  resizer() {
    return new SvgIcon('', resizerIcon);
  },

  retry() {
    return new SvgIcon('', retryIcon);
  },

  save() {
    return new SvgIcon('', saveIcon);
  },

  search() {
    return new SvgIcon('', searchIcon);
  },

  searchMinus() {
    return new SvgIcon('', searchMinusIcon);
  },

  searchPlus() {
    return new SvgIcon('', searchPlusIcon);
  },

  selectPreset() {
    return new SvgIcon('', selectPresetIcon);
  },

  // Not unused
  send() {
    return new SvgIcon('', sendIcon);
  },

  // Not unused
  settings() {
    return new SvgIcon('', settingsIcon);
  },

  // Not unused
  share() {
    return new SvgIcon('', shareIcon);
  },

  // Not unused
  sliders() {
    return new SvgIcon('', slidersIcon);
  },

  spinner() {
    return new SvgIcon('cr-animation-rotate', spinnerIcon);
  },

  srt() {
    return new SvgIcon('', srtIcon);
  },

  // Not unused
  star() {
    return new SvgIcon('', starIcon);
  },

  // Not unused
  starActive() {
    return new SvgIcon('', starActiveIcon);
  },

  stop() {
    return new SvgIcon('', stopIcon);
  },

  streaming() {
    return new SvgIcon('', streamingIcon);
  },

  streamingEvent() {
    return new SvgIcon('', streamingEventIcon);
  },

  subscriptions() {
    return new SvgIcon('', subscriptionsIcon);
  },

  // Not unused
  switchOff() {
    return new SvgIcon('svg-icon--wide', switchOffIcon);
  },

  // Not unused
  switchOn() {
    return new SvgIcon('svg-icon--wide', switchOnIcon);
  },

  teams() {
    return new SvgIcon('', teamsIcon);
  },

  timer() {
    return new SvgIcon('', timerIcon);
  },

  trash() {
    return new SvgIcon('', trashIcon);
  },

  // Not unused
  twitter() {
    return new SvgIcon('', twitterIcon);
  },

  unlink() {
    return new SvgIcon('', unlinkIcon);
  },

  updateFirmware() {
    return new SvgIcon('', updateFirmwareIcon);
  },

  upload() {
    return new SvgIcon('', uploadIcon);
  },

  urlGoArrow() {
    return new SvgIcon('', urlGoArrowIcon);
  },

  urlGoBackArrow() {
    return new SvgIcon('cr-icon--reflected-horizontal', urlGoArrowIcon);
  },

  // Not unused
  user() {
    return new SvgIcon('', userIcon);
  },

  users() {
    return new SvgIcon('', userIcon);
  },

  videocamera() {
    return new SvgIcon('', videocameraIcon);
  },

  volume() {
    return new SvgIcon('', volumeIcon);
  },

  // Not unused
  videoFile() {
    return new SvgIcon('', videoFileIcon);
  },

  warning() {
    return new SvgIcon('', warningIcon);
  },

  // Not unused
  youtube() {
    return new SvgIcon('', youtubeIcon);
  },

  // Not unused
  facebookStreamingService() {
    return new SvgIcon('', facebookStreamingServiceIcon);
  },

  facebookStreamingServiceSmall() {
    return new SvgIcon('', facebookStreamingServiceSmallIcon);
  },

  // Not unused
  youtubeStreamingService() {
    return new SvgIcon('', youtubeStreamingServiceIcon);
  },

  youtubeStreamingServiceSmall() {
    return new SvgIcon('', youtubeStreamingServiceSmallIcon);
  },

  // Not unused
  twitchStreamingService() {
    return new SvgIcon('', twitchStreamingServiceIcon);
  },

  twitchStreamingServiceSmall() {
    return new SvgIcon('', twitchStreamingServiceSmallIcon);
  },

  // Not unused
  rtmpStreamingService() {
    return new SvgIcon('', rtmpStreamingServiceIcon);
  },

  rtmpStreamingServiceSmall() {
    return new SvgIcon('', rtmpStreamingServiceSmallIcon);
  },

  batchForStreamingUpgradeToPro() {
    return new SvgIcon('', batchForStreamingUpgradeToProIcon);
  },

  deviceGroupingUpgradeToPro() {
    return new SvgIcon('', deviceGroupingUpgradeToProIcon);
  },

  highDefinitionVideoUpgradeToPro() {
    return new SvgIcon('', highDefinitionVideoUpgradeToProIcon);
  },

  devicePresetAfu() {
    return new SvgIcon('', devicePresetAfuIcon);
  },

  devicePresetEpiphanCloud() {
    return new SvgIcon('', devicePresetEpiphanCloudIcon);
  },

  devicePresetChannels() {
    return new SvgIcon('', devicePresetChannelsIcon);
  },

  devicePresetCms() {
    return new SvgIcon('', devicePresetCmsIcon);
  },

  devicePresetDisplays() {
    return new SvgIcon('', devicePresetDisplaysIcon);
  },

  devicePresetEdid() {
    return new SvgIcon('', devicePresetEdidIcon);
  },

  devicePresetFrontScreen() {
    return new SvgIcon('', devicePresetFrontScreenIcon);
  },

  devicePresetNetwork() {
    return new SvgIcon('', devicePresetNetworkIcon);
  },

  devicePresetSources() {
    return new SvgIcon('', devicePresetSourcesIcon);
  },

  devicePresetSystem() {
    return new SvgIcon('', devicePresetSystemIcon);
  },

  teamPresetsFreePlanPlaceholderFile() {
    return new SvgIcon('', teamPresetsFreePlanPlaceholderFileIcon);
  },
  cms() {
    return new SvgIcon('', eventNavIcon);
  },
  events() {
    return new SvgIcon('', eventIcon);
  },
  eventFreePlan() {
    return new SvgIcon('', eventFreePlanIcon);
  },
  eventCMSIsTurnedOff() {
    return new SvgIcon('', eventCMSTurnedOffIcon);
  },
  eventNoDevices() {
    return new SvgIcon('', eventNoDevices);
  },
  virtualDevice() {
    return new SvgIcon('', virtualDevice);
  },
  guideIcon() {
    return new SvgIcon('', guideIcon);
  },
  virtualDeviceIcon() {
    return new SvgIcon('', virtualDeviceIcon);
  },

  projectIconColored() {
    return new SvgIcon('', projectIconColored);
  },
  microsoftTeams() {
    return new SvgIcon('', msTeamsIcon);
  },
  videoOn() {
    return new SvgIcon('', videoOnIcon);
  },
  videoOff() {
    return new SvgIcon('', videoOffIcon);
  },
  microOn() {
    return new SvgIcon('', microOnIcon);
  },
  microOff() {
    return new SvgIcon('', microOffIcon);
  },
  options() {
    return new SvgIcon('', optionsIcon);
  },
  disconnectedSource() {
    return new SvgIcon('', disconnectedSourceIcon);
  },
  zoom() {
    return new SvgIcon('', zoomIcon);
  },
  account() {
    return new SvgIcon('', accountIcon);
  },

  savings() {
    return new SvgIcon('', savingsIcon);
  },
  edge() {
    return new SvgIcon('', edgeIcon);
  },
  connect() {
    return new SvgIcon('', connectIcon);
  },
  unify() {
    return new SvgIcon('', unifyIcon);
  },
};

export {Icons};
