import React from 'react';
import {Button, Stack, Typography} from '@mui/material';
import {Callback, Sx} from 'app/types/common';

const defaultMessage = 'Filter applied, but no result found';
const defaultButtonText = 'Reset filter';

interface Props extends Sx {
  message?: string;
  buttonText?: string;
  handleClear: Callback;
}

export function EmptyFilterMessage({
  sx,
  message = defaultMessage,
  buttonText = defaultButtonText,
  handleClear,
}: Props) {
  return (
    <Stack sx={sx} direction="row" justifyContent="center" alignItems="center" gap={2}>
      <Typography>{message}</Typography>

      <Button data-id="clear-btn" variant="outlined" disableRipple={false} onClick={handleClear}>
        {buttonText}
      </Button>
    </Stack>
  );
}
