import React from 'react';
import {Sx} from 'app/types/common';
import {Box, Link, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {LegacyIcon} from 'app/components/sharedReactComponents/LegacyIcon/LegacyIcon';
import {Icons} from 'app/util/icons';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';

interface Props extends Sx {
  canManage: boolean;
  onAdd: Fn;
}

export function AddZoomAccount({sx, canManage, onAdd}: Props) {
  return (
    <Stack
      sx={sx}
      direction="row"
      alignItems="flex-start"
      gap={2}
      p={4}
      borderRadius={1}
      border={1}
      bgcolor={grey[100]}
      borderColor={grey[400]}
    >
      <LegacyIcon fontSize={72}>{Icons.zoom().reactComponent()}</LegacyIcon>
      <Box>
        <Typography mb={1}>
          To join a Zoom meeting that requires all participants to be authenticated, you must add a
          valid Zoom account for Epiphan Connect to use.{' '}
        </Typography>

        <Typography mb={2}>
          <Link
            href="https://www.epiphan.com/userguides/connect/Content/Connect/Authorize-MS-Teams-to-use-Connect.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </Link>{' '}
          about how to add an authenticated Zoom account.
        </Typography>

        {canManage && (
          <Button
            dataId="connect-zoom"
            variant={VARIANT.SOLID}
            theme={THEME.SUCCESS}
            onClick={onAdd}
          >
            Add new account
          </Button>
        )}
      </Box>
    </Stack>
  );
}
