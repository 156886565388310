export function formatMaintenanceTimestamp(timestamp: number) {
  const date = new Date(timestamp * 1000);

  return date.toLocaleDateString('en', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  });
}
