import React, {useState} from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';
import {useMounted} from 'app/hooks/useIsMounted';

interface Props extends Prompt {
  onConfirm: () => Promise<void>;
}

export function UnsubscribeDialog({open, onConfirm, onClose}: Props) {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <Unsubscribe onConfirm={onConfirm} onCancel={onClose} />
    </Dialog>
  );
}

type UnsubscribeProps = {
  onConfirm: () => Promise<void>;
  onCancel: () => void;
};

function Unsubscribe({onConfirm, onCancel}: UnsubscribeProps) {
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const mounted = useMounted();

  const handleConfirm = async () => {
    setLoading(true);

    try {
      await onConfirm();
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <DialogTitle>Are you sure you want to unsubscribe from file management?s</DialogTitle>

      <DialogContent>
        <Stack alignItems="center">
          <Typography textAlign="justify">
            If you unsubscribe from our service,{' '}
            <strong>all your files will be permanently deleted</strong>. This action cannot be
            undone. Please ensure you have backed up any important data before proceeding.
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                data-id="delete-checkbox"
                checked={confirmed}
                onChange={(e) => setConfirmed(e.target.checked)}
              />
            }
            label="Delete all files associated with the team"
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="unsubscribe-btn"
          variant="contained"
          color="error"
          disabled={!confirmed}
          loading={loading}
          onClick={handleConfirm}
        >
          Unsubscribe
        </LoadingButton>

        <Button
          data-id="cancel-btn"
          variant="outlined"
          color="info"
          disabled={loading}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}
