import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {throttle} from 'throttle-debounce';
import {PresetApiService} from 'app/services/api/preset/PresetApiService';
import {WS} from 'app/api/WebSocket/WS';
import {stringComparator} from 'app/util/Sort';
import {EdgeMapper} from 'app/util/mappers/edge/EdgeMapper';

interface Args {
  enabled: boolean;
}
export function useTeamPresets({enabled}: Args) {
  const query = useQuery({
    queryKey: ['team-presets'],
    queryFn: async () => {
      const result = await PresetApiService.getTeamPresets();
      return result.map(EdgeMapper.mapTeamPreset).sort((a, b) => stringComparator(a.name, b.name));
    },
    enabled,
  });

  const {refetch} = query;

  useEffect(() => {
    const socketHandler = async () => refetch();

    const throttled = throttle(5000, socketHandler);

    WS.onTeamPresetChange(throttled);

    return () => {
      WS.offTeamPresetChange(throttled);
      throttled.cancel();
    };
  }, [refetch]);

  return query;
}
