import React, {useCallback, useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import classNames from 'classnames';
import {useStreamingDestinations} from 'app/components/FleetManager/useStreamingDestinations';
import {ChannelsList} from 'app/components/DeviceDetails/ChannelsTab/ChannelsList';
import {userGuideAdminUrlByDevice} from 'app/components/DeviceDetails/ChannelsTab/utils';
import {ClassName} from 'app/types/common';
import {Schedule} from 'app/domain/schedule';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {PearlSlotDeviceModel} from 'app/components/DeviceDetails/Models/PearlSlotDeviceModel';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

interface Props extends ClassName {
  device: PearlMasterDeviceModel;
  actualEvent?: Schedule.Event;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
}

export function ChannelsTab({className, device, actualEvent, getDeviceById}: Props) {
  const {streamingDestinations, reloadStreamingDestinations} = useStreamingDestinations();
  const handleChangeStreamingDestination = useCallback(() => {
    reloadStreamingDestinations();
  }, [reloadStreamingDestinations]);

  const [channelDevices, setChannelDevices] = useState<PearlSlotDeviceModel[]>([]);
  const userGuideUrl = userGuideAdminUrlByDevice(device.getModelName());
  const noChannelDevices = channelDevices.length === 0;

  useEffect(() => {
    const channels: PearlSlotDeviceModel[] =
      device instanceof PearlMasterDeviceModel ? device.getChanelModels() : [];
    setChannelDevices(channels);
  }, [device]);

  return (
    <div className={classNames('channels-tab', className)}>
      <Typography fontWeight={600} variant="h5" mb={3}>
        Channels
      </Typography>

      {noChannelDevices ? (
        <div className="channels-tab__no-channels">
          <div className="channels-tab__no-channels-title">
            There are no channels created for this device.
          </div>

          <div className="channels-tab__no-channels-description">
            Please log in to your device's admin panel to create a channel.
          </div>

          {userGuideUrl !== null && (
            <a
              className="channels-tab__no-channels-link"
              href={userGuideUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              How to connect to Pearl's admin panel?
            </a>
          )}
        </div>
      ) : (
        <ChannelsList
          masterDevice={device}
          channelDevices={channelDevices}
          streamingDestinations={streamingDestinations}
          actualEvent={actualEvent}
          getDeviceById={getDeviceById}
          onChangeStreamingDestination={handleChangeStreamingDestination}
        />
      )}
    </div>
  );
}
