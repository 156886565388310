import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {Schedule} from 'app/domain/schedule';
import {ScheduleApiService} from 'app/services/api/schedule/ScheduleApiService';
import {ScheduleMapper} from 'app/util/mappers/ScheduleMapper';

type Args = {
  enabled: boolean;
  teamId: string;
};

export function useActualEvents({enabled, teamId}: Args): Map<string, Schedule.Event> | undefined {
  const {data: events, refetch} = useQuery({
    queryKey: ['team-actual-events', teamId],
    queryFn: async () => {
      const contract = await ScheduleApiService.getTeamActualEvents();
      const result = new Map<string, Schedule.Event>();

      Object.entries(contract).forEach((p) => {
        const [deviceId, c] = p;
        result.set(deviceId, ScheduleMapper.mapEvent(c));
      });

      return result;
    },
    enabled,
  });

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const onScheduleChange = async () => {
      await refetch();
    };

    WS.on(EVENT_TYPE.EVENT_SCHEDULE_CHANGED, onScheduleChange);
    WS.on(EVENT_TYPE.CHRONO_EVENT_CHANGED, onScheduleChange);

    return () => {
      WS.off(EVENT_TYPE.EVENT_SCHEDULE_CHANGED, onScheduleChange);
      WS.off(EVENT_TYPE.CHRONO_EVENT_CHANGED, onScheduleChange);
    };
  }, [enabled, teamId, refetch]);

  return events;
}
