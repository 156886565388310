import React, {KeyboardEvent} from 'react';
import {KeyboardCode, SIZE} from 'app/constants';
import classNames from 'classnames';
import {DisabledButtonWrapper} from 'app/components/Settings/StreamingDestinations/NewStreamingDestinationTypeSelector/DisabledButtonWrapper';
import {StreamingServiceIcon} from 'app/components/StreamingServices/StreamingServiceIcon';
import {Callback, ClassName} from 'app/types/common';

const getReactIcon = (type) => StreamingServiceIcon.getIconByType(type).size(SIZE.XL).reactComponent();

interface Props extends ClassName {
  service: any;
  createServiceAction: Callback;
}

export const TileItem: React.FC<Props> = ({
  className,
  service,
  createServiceAction,
}) => {
  const handleClick = () => {
    if (service.isDisabled()) {
      return;
    }

    createServiceAction(service.getType());
  };

  const disabled = service.isDisabled();

  if (disabled) {
    return null;
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLLIElement>) => {
    if (event.code === KeyboardCode.Enter || event.code === KeyboardCode.Space) {
      event.preventDefault();
      handleClick();
    }
  };

  return (
    <DisabledButtonWrapper disabled={disabled}>
      <li
        className={classNames(
          'new-streaming-destination-type-selector-tiles__item',
          `new-streaming-destination-type-selector-tiles__item--${service.getType() as string}`,
          {
            'new-streaming-destination-type-selector-tiles__item--disabled': disabled,
          },
          className
        )}
        data-id="add_streaming_destination_button"
        tabIndex={disabled ? undefined : 0}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <div className="new-streaming-destination-type-selector-tiles__icon">
          {getReactIcon(service.getType())}
        </div>

        <div className="new-streaming-destination-type-selector-tiles__name">
          {service.getName()}
        </div>
      </li>
    </DisabledButtonWrapper>
  );
};
