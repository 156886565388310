import React from 'react';
import {Box, Link, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {palette} from 'app/themes/app';
import {TeamsTenant} from 'app/components/Settings/ConnectSettings/TeamsTenants/TeamsTenant/TeamsTenant';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {MeetingsApiService} from 'app/services/api/meetings/MeetingsApiService';
import {PairTeamsTenant} from 'app/components/Settings/ConnectSettings/TeamsTenants/PairTeamsTenant/PairTeamsTenant';

interface Props extends Sx {
  tenants: any[];
  canManage: boolean;
  reload: Fn<Promise<any>>;
  onPair: Fn;
}

export default function TeamsAccounts({sx, tenants, canManage, reload, onPair}: Props) {
  const handleUnpair = async (id: string) => {
    await MeetingsApiService.disconnectTenant(id);
    await reload();
  };

  return (
    <Box sx={sx}>
      <Typography mb={1} variant="h6">
        To create new meetings from Epiphan Connect:
      </Typography>

      <Typography mb={5} fontWeight="bold" color={palette.darkerGray}>
        You can create a new meeting without pairing an organization by clicking on “create a
        meeting” in the “New Meeting” pop-up in Epiphan Connect. You must share the meeting link
        with the meeting participants.
      </Typography>

      <Typography mb={1} fontWeight="bold" variant="h6">
        To join existing meetings:
      </Typography>

      <Box>
        <Typography mb={2} color={palette.darkerGray}>
          You need to pair an organization to join existing Microsoft Teams meetings.
          <Link
            href="https://www.epiphan.com/userguides/connect/Content/Connect/Authorize-MS-Teams-to-use-Connect.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more.
          </Link>
        </Typography>
      </Box>

      <Typography mb={2}>Paired organizations</Typography>

      {tenants.length === 0 ? (
        <PairTeamsTenant canManage={canManage} onPair={onPair} />
      ) : (
        <Stack gap={2} mb={2}>
          {tenants.map((tenant) => (
            <TeamsTenant
              key={tenant.id}
              canManage={canManage}
              tenant={tenant}
              onUnpair={handleUnpair}
            />
          ))}

          {canManage && (
            <Box>
              <Button
                dataId="connect-teams"
                variant={VARIANT.SOLID}
                theme={THEME.SUCCESS}
                onClick={onPair}
              >
                Add new account
              </Button>
            </Box>
          )}
        </Stack>
      )}
    </Box>
  );
}
