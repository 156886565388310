import React from 'react';
import {Sx} from 'app/types/common';
import {Chip} from '@mui/material';
import {packSx} from 'app/util/packSx/packSx';
import {grey} from '@mui/material/colors';

interface Props extends Sx {}

export function PausedBadge({sx}: Props) {
  return (
    <Chip
      sx={packSx({color: 'white', height: 'auto', bgcolor: grey[900]}, sx)}
      label="Paused"
      variant="filled"
      size="small"
    />
  );
}
