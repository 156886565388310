import React, {Fragment} from 'react';

interface Props {
  value: string;
}

export const TabPanels: React.FC<Props> = ({
  value,
  children: childrenProp,
}) => {
  const children = React.Children.map(childrenProp, (child: any) => {
    if (
      React.isValidElement(child) === false ||
      value !== child.props.value
    ) {
      return null;
    }

    return child;
  });

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};
