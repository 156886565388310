import React, {useState} from 'react';
import {Button, Stack, Typography} from '@mui/material';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {Sx} from 'app/types/common';
import {
  Afu,
  AfuApi,
  CmsAfuMessage,
  DisableEdgeAfuDialog,
  EnableEdgeAfuDialog,
  isCmsAfu,
} from 'app/components/entities/afu';

interface Props extends Sx {
  afu: Afu.Configuration;
  deviceId: string;
}

export function EdgeAfuSwitcher({sx, deviceId, afu}: Props) {
  const cmsConnected = isCmsAfu(afu.protocol);
  const isEdgeProtocol = afu.protocol === 'edge';
  const enabled = afu.state !== 'disabled';

  const [enableDialog, setEnableDialog] = useState(false);
  const [disableDialog, setDisableDialog] = useState(false);

  const handleEnable = async () => {
    await AfuApi.setProtocol(deviceId, 'edge');
    setEnableDialog(false);
  };

  const handleDisable = async () => {
    await AfuApi.setProtocol(deviceId, '');
    setDisableDialog(false);
  };

  const active = isEdgeProtocol && enabled;

  if (cmsConnected) {
    return <CmsAfuMessage sx={sx} />;
  }

  return (
    <>
      <Stack sx={sx} direction="row" alignItems="center" gap={1}>
        <Typography color="text.secondary">
          Epiphan Edge File Management is {active ? 'set' : 'not selected'} as your Automatic File
          Upload destination on this device.
        </Typography>

        {active ? (
          <Button
            startIcon={<CloudOffIcon />}
            variant="text"
            color="primary"
            onClick={() => setDisableDialog(true)}
          >
            Disable
          </Button>
        ) : (
          <Button
            startIcon={<CloudUploadOutlinedIcon />}
            variant="text"
            color="primary"
            onClick={() => setEnableDialog(true)}
          >
            Enable
          </Button>
        )}
      </Stack>

      <EnableEdgeAfuDialog
        open={enableDialog}
        onEnable={handleEnable}
        onClose={() => setEnableDialog(false)}
      />

      <DisableEdgeAfuDialog
        open={disableDialog}
        onDisable={handleDisable}
        onClose={() => setDisableDialog(false)}
      />
    </>
  );
}
