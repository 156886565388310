import {Callback} from 'app/types/common';

export interface FilterSwitch<Group = string> {
  id: string;
  group?: Group;
  label: string;
  disabled?: boolean;
  callback?: Callback<boolean>;
  callbackForGroup?: Callback<boolean>;
  isVisible?: Callback<boolean>;
}

export enum FilterSwitchGroupType {
  Radio = 'radio',
  Checkbox = 'checkbox',
}

export interface FilterSwitchGroup<T extends string = string> {
  id: T;
  label: string;
  type?: FilterSwitchGroupType;
  defaultValue?: string;
}
