import {front} from 'app/api/CirrusApi';
import {FilesBillingContracts} from 'app/components/entities/billing/files';

export class FilesBillingApi {
  static async getPrices(): Promise<FilesBillingContracts.Price[]> {
    return front.billing().v2().files().prices().get();
  }

  static async getPlan(teamId: string): Promise<FilesBillingContracts.Plan | null> {
    return front.teams(teamId).billing().v2().files().plan().get();
  }

  static async subscribe(teamId: string, priceId: string): Promise<void> {
    return front.teams(teamId).billing().v2().files().plan().post({price_id: priceId});
  }

  static async unsubscribe(teamId: string): Promise<void> {
    return front.teams(teamId).billing().v2().files().plan().delete();
  }

  static async getHistory(teamId: string): Promise<FilesBillingContracts.History> {
    return front.teams(teamId).billing().v2().files().invoice().history().get();
  }

  static getInvoiceUrl(teamId: string, invoiceId: string): string {
    return front.teams(teamId).billing().v2().invoice(invoiceId).pdf().url();
  }
}
