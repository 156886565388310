const DEVICE_DETAILS_PAGE_TAB_NAME = {
  STATUS: 'status',
  CHANNELS: 'channels',
  INPUTS: 'inputs',
  ALERTS: 'alerts',
  PRESETS: 'presets',
  OUTPUT: 'output',
  AUDIO: 'audio',
  TRANSCRIPTION: 'transcription',
  SESSION: 'session',
  EVENTS: 'events',

  // Channel
  RECORDING_STREAMING: 'recording_streaming',
  ENCODING_SETTINGS: 'encoding_settings',
};

const DEVICE_DETAILS_PAGE_DEFAULT_ACTIVE_TAB = DEVICE_DETAILS_PAGE_TAB_NAME.STATUS;
const TRANSCRIBE_DEVICE_DETAILS_PAGE_SETTINGS_TAB = DEVICE_DETAILS_PAGE_TAB_NAME.OUTPUT;

// new enums
enum DeviceCapability {
  AudioLevels = 'audio_levels',
  ChannelPreviews = 'channel_previews',
  Cms = 'cms',
  Presets = 'presets',
  Properties = 'properties',
  SourcePreviews = 'source_previews',
  WsApi = 'wsapi',
  /** Can handle `recording.start/stop` */
  Recording = 'recording',
  /** Can handle `afu.start/stop` */
  Uploading = 'uploading',
  /** Can handle `rtmp.start/stop` */
  Streaming = 'streaming',
  /** Supports `DesiredState` */
  States = 'states',
  StateAssetsInfo = 'state_assetsinfo',
  StateRecording = 'state_recording',
  StateScheduling = 'state_scheduling',
  StateStreaming = 'state_streaming',
  StateTeamInfo = 'state_teaminfo',
  StateTranscribing = 'state_transcribing',
  StateTranscribingConf = 'state_transcribing_conf',
  StateUploading = 'state_uploading',
  /** Has button that can be assigned to actions */
  HasActionButton1 = 'has_action_button1',
  /** Has remotely accessible web UI */
  RemoteLogin = 'remote_login',
  Reboot = 'reboot',
  ChronoCms = 'epiphan_cms',
}

enum DeviceCommand {
  Reboot = 'reboot',
  FootageClear = 'footage.clear',
  FirmwareUpdate = 'firmware.update',
  PublisherConfigure = 'publisher.configure',
  Recording = 'recording',
  Uploading = 'afu',
  PublisherStart = 'publisher.start',
  PublisherStop = 'publisher.stop',
  LevelsStart = 'levels.start',
  SourcePreviewsStart = 'source_previews.start',
}
/** @deprecated replace by Cloud.UnitModel */
enum DeviceModel {
  Pearl2 = 'Pearl-2',
  PearlNano = 'Pearl Nano',
  PearlMini = 'Pearl Mini',
  PearlNexus = 'Pearl Nexus',
  PearlVirtual = 'Pearl Virtual',
  LiveScrypt = 'LiveScrypt',
  Unify = 'Epiphan Unify Project',
  Webcaster = 'Webcaster',
  Channel = 'Channel',
  Demo = 'Demo',
}

// TODO: rework after Vadim answered
enum FirmwareUpdateStatus {
  Available = 'available',
  Downloading = 'downloading',
  Progress = 'progress',
}

enum DesiredStateType {
  Recording = 'recording',
  Streaming = 'streaming',
  Transcribing = 'transcribing',
}

enum DeviceWarningId {
  PublisherError = 'publisher_error',
  StorageFull = 'storage_full',
  StreamingDestinationError = 'streaming_destination_error',
}

enum HealthStatus {
  Good = 'good',
  Fair = 'fair',
  Bad = 'bad',
}

/**
 * @readonly
 * @enum {string}
 */
const DEVICE_HEALTH_STATUS_NAME = {
  [HealthStatus.Good]: 'Good',
  [HealthStatus.Fair]: 'Fair',
  [HealthStatus.Bad]: 'Bad',
};

enum TranscribingStatus {
  Started = 'started',
  Paused = 'paused',
  Stopped = 'stopped',
}

export {
  DEVICE_HEALTH_STATUS_NAME,
  DEVICE_DETAILS_PAGE_TAB_NAME,
  DEVICE_DETAILS_PAGE_DEFAULT_ACTIVE_TAB,
  TRANSCRIBE_DEVICE_DETAILS_PAGE_SETTINGS_TAB,
  // new enums
  DeviceCapability,
  DeviceCommand,
  DeviceModel,
  FirmwareUpdateStatus,
  DesiredStateType,
  DeviceWarningId,
  HealthStatus,
  TranscribingStatus,
};
