import {useEffect, useRef} from 'react';
import {RepeatedCall} from 'app/util/RepeatedCall';
import {secondsToMilliseconds} from 'app/util/timeConverter';
import {Callback} from 'app/types/common';

/**
 * Send device command every 30 seconds to continue receive WebSocket data
 * @type {number}
 */
const REPEATED_COMMAND_TIMEOUT_MS = secondsToMilliseconds(30);

type CommandProp = Callback<Promise<any>>;

export function useDeviceStatusStartCommand({
  command,
  disabled,
}: {
  command: CommandProp;
  disabled: boolean;
}) {
  const commandRef = useRef<CommandProp>(command);

  commandRef.current = command;

  useEffect(() => {
    if (disabled) {
      return;
    }

    const repeatedCall = new RepeatedCall({
      call: async () => commandRef.current(),
      initialTimeoutMs: REPEATED_COMMAND_TIMEOUT_MS,
    });

    void repeatedCall.start(true);

    return () => {
      repeatedCall.stop();
    };
  }, [disabled]);
}
