import React from 'react';
import {LinearProgress} from '@mui/material';
import {DataId, Sx} from 'app/types/common';

interface Props extends Sx, DataId {
  value: number;
}

export function UnifyConnection({sx, dataId, value}: Props) {
  const isWarning = value >= 80;

  return (
    <LinearProgress
      sx={sx}
      data-id={dataId}
      color={isWarning ? 'warning' : 'primary'}
      value={value}
      variant="determinate"
    />
  );
}
