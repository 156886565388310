import {EdgeConstants} from 'app/domain/edge';

export interface Group {
  id: string;
  name: string;
}

export type SourceState = 'active' | 'inactive';

export interface VideoSource {
  id: string;
  name: string;
  hasSignal: boolean;
  isActive: boolean;
  realName: string;
  frameRate: string;
  fps?: number;
  resolution?: string;
}

export interface AudioSource {
  id: string;
  name: string;
  realName: string;
  isActive: boolean;
}

export interface Sources {
  video: VideoSource[];
  audio: AudioSource[];
}

export interface System {
  uptime: TimeStampSeconds;
  load?: number;
  temperature?: number;
}

export interface Storage {
  free: number;
  total: number;
  used: number;
}

export type AfuState = 'disabled' | 'error' | 'idle' | 'uploading';

interface AfuQueue {
  files?: number;
  size?: number;
}

export interface Afu {
  state: AfuState;
  speed?: number;
  queue?: AfuQueue;
  error?: {message?: string};
}

export interface TranscribeSession {
  id: string;
  name: string;
  streaming: boolean;
  startedAt: TimeStampSeconds;
  stoppedAt: TimeStampSeconds;
}

export type UnitModel =
  | 'Pearl-2'
  | 'Pearl Mini'
  | 'Pearl Nano'
  | 'Pearl Virtual'
  | 'Epiphan Unify Project';

export type PresetSection = typeof EdgeConstants.PresetSections[number];

export interface UnitPreset {
  hash: string;
  name: string;
  sections: PresetSection[];
  size: number;
  description?: string;
}

export interface TeamPreset extends UnitPreset {
  id: string;
  createdAt: TimeStampSeconds;
  device: UnitModel;
}

export type Preset = UnitPreset | TeamPreset;

export function isTeamPreset(preset: Preset): preset is TeamPreset {
  return 'id' in preset;
}

export type Paired = {
  devices: number;
  lvs: number;
  projects: number;
};

export type DeviceStatus = 'online' | 'offline' | 'rebooting' | 'upgrading' | 'pairing' | 'holding_legacy';

export type Firmware = {
  current: string;
  available?: string;
  update?: {
    status: 'downloading' | 'progress' | 'available';
  };
};

export type WarningSeverity = 'warning' | 'error';

export type WarningId =
  | 'encoder_framerate_mismatch'
  | 'scheduled_storage_state'
  | 'scheduled_channel'
  | 'scheduled_publisher'
  | 'scheduled_recording_state'
  | 'scheduled_streaming_state'
  | 'disk_space_error'
  | 'publisher_error'
  | 'source_no_signal'
  | 'channel_no_signal'
  | 'high_cpu_temp'
  | 'high_cpu_load'
  | 'storage_full'
  | 'streaming_destination_error'
  | (string & {});

export type Warning = {
  id: WarningId;
  hash: string;
  severity: WarningSeverity;
  label: string;
  message: string;
  channel_id: string;
  shortMessage?: string;
  data?: Record<string, any> & {publisher_id?: string; stream_id?: string};
};

type PublisherSettings = {id: 'name' | 'single-touch'};

export type PublisherStatus = {
  since?: number;
  started: boolean;
  state: 'started' | 'starting' | 'stopped' | 'error';
  description?: string;
};

export type Publisher = {
  id: string;
  settings: PublisherSettings[];
  status: PublisherStatus;
  type: 'dash' | 'hls' | 'mpegts-rtp' | 'mpegts-udp' | 'ndi' | 'rtmp' | 'rtp-udp' | 'rtsp' | 'srt';
};
