import React from 'react';
import {Table, TableBody, TableContainer, TableHead, TableRow} from '@mui/material';
import {Sx} from 'app/types/common';
import {formatAbbreviatedDateWithYear} from 'app/util/time';
import {InvoiceCell} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/shared/InvoiceCell/InvoiceCell';
import {InvoiceNumber} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/shared/InvoiceNumber/InvoiceNumber';
import {formatPrice} from 'app/components/shared';

interface Props extends Sx {
  teamId: string;
  history: App.Billing.Cloud.HistoryInvoice[];
  showUnify: boolean;
}

export function CloudHistory({sx, history, teamId, showUnify}: Props) {
  return (
    <TableContainer sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            <InvoiceCell>Date</InvoiceCell>
            <InvoiceCell>Number</InvoiceCell>
            <InvoiceCell align="right">Edge</InvoiceCell>
            <InvoiceCell align="right">LiveScrypt</InvoiceCell>
            {showUnify && <InvoiceCell align="right">Unify</InvoiceCell>}
            <InvoiceCell align="right">Total</InvoiceCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {history.map((h) => (
            <TableRow key={h.id} data-id={h.id}>
              <InvoiceCell data-id="invoice-date">
                {formatAbbreviatedDateWithYear(h.date)}
              </InvoiceCell>
              <InvoiceCell>
                <InvoiceNumber
                  dataId="invoice-number"
                  available={h.available}
                  id={h.id}
                  number={h.number}
                  teamId={teamId}
                />
              </InvoiceCell>
              <InvoiceCell data-id="invoice-edge" align="right">
                ${formatPrice(h.cloud)}
              </InvoiceCell>
              <InvoiceCell data-id="invoice-lvs" align="right">
                ${formatPrice(h.lvs)}
              </InvoiceCell>
              {showUnify && (
                <InvoiceCell data-id="invoice-unify" align="right">
                  ${formatPrice(h.unify)}
                </InvoiceCell>
              )}
              <InvoiceCell data-id="invoice-total" sx={{fontWeight: 'bold'}} align="right">
                ${formatPrice(h.total)}
              </InvoiceCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
