import {front} from 'app/api/CirrusApi';
import {StreamingDestinationOptions} from 'app/components/StreamingServices/StreamingDestinations/types';
import {Callback} from 'app/types/common';

class StreamApiService {
  getStreams = async (
    getAbort?: Callback
  ): Promise<StreamingDestinationOptions[]> => front.streams().get(undefined, {getAbort});
}

export const streamApiService = new StreamApiService();
