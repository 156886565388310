import {
  RefObject,
  useCallback,
  useState,
} from 'react';
import {useResizeObserver} from 'app/hooks/useResizeObserver';

export function useDimensions<T extends HTMLElement>(
  ref: RefObject<T>
) {
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  const handler = useCallback(
    (e: ResizeObserverEntry) => {
      const {height, width} = e.contentRect;

      setHeight(height);
      setWidth(width);
    },
    [],
  );

  useResizeObserver(ref, handler);

  return {height, width};
}
