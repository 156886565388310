import {Files} from 'app/components/entities/files/model';
import {stringComparator} from 'app/util/Sort';

export function sortByNameAsc(a: Files.Recording, b: Files.Recording): number {
  return stringComparator(a.name, b.name);
}

export function sortByNameDesc(a: Files.Recording, b: Files.Recording): number {
  return stringComparator(b.name, a.name);
}

export function sortBySizeAsc(a: Files.Recording, b: Files.Recording): number {
  return a.size - b.size;
}

export function sortBySizeDesc(a: Files.Recording, b: Files.Recording): number {
  return b.size - a.size;
}

export function sortByDateAcs(a: Files.Recording, b: Files.Recording): number {
  return a.startAt - b.startAt;
}

export function sortByDateDesc(a: Files.Recording, b: Files.Recording): number {
  return b.startAt - a.startAt;
}
