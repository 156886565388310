import React, {useMemo} from 'react';
import {Virtuoso} from 'react-virtuoso';
import {useQuery} from '@tanstack/react-query';
import {Button, Slide, Stack, TextField, Typography} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SearchIcon from '@mui/icons-material/Search';
import {Sx} from 'app/types/common';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {createDeviceModel} from 'app/components/DeviceDetails/Models/Fabric';
import {downloadFile} from 'app/util/download';
import {
  FilesApi,
  ListHeader,
  NoFiltered,
  NoRecordings,
  RecordingsSkeleton,
  SortSelector,
} from 'app/components/entities/files';
import {
  BatchRecordings,
  RecordingCard,
  TotalAndSize,
  useFilterAndSortFiles,
  useSelectRecordings,
  useTeamFiles,
} from 'app/components/features/files';
import {Notifications} from 'app/components/Notifications';

type Props = Sx & {
  teamId: string;
};

export function TeamFiles({sx, teamId}: Props) {
  const {data: recordings = [], isSuccess} = useTeamFiles({teamId, enabled: true});

  const deviceIds = useMemo(() => recordings?.map((r) => r.deviceId) ?? [], [recordings]);

  const {data: devices, isLoading: fetchingUnits} = useQuery({
    queryKey: ['files', 'devices', teamId, deviceIds],
    queryFn: async () => {
      const contract = await DeviceApiService.getUnits(deviceIds);
      const mapped = contract.map((d) => createDeviceModel(d));
      return new Map(mapped.map((m) => [m.getId(), m]));
    },
    enabled: deviceIds.length > 0,
  });

  const {filtered, search, sort, setSort, setSearch} = useFilterAndSortFiles({recordings});
  const {selected, selectable, allSelected, deselectAll, selectAll, isSelected, toggle} =
    useSelectRecordings(filtered);

  const handleSelectAll = () => {
    if (allSelected) {
      deselectAll();
      return;
    }

    selectAll();
  };

  const handleClear = () => {
    setSearch('');
    deselectAll();
  };

  const handleDelete = async (id: string) => {
    try {
      await FilesApi.deleteRecording(id);
    } catch (e) {
      Notifications.addErrorNotification('Failed to delete recording');
      throw e;
    }
  };

  const handleDownload = async (id: string, name: string) => {
    const url = FilesApi.getDownloadUrl(id);
    downloadFile(url, name);
  };

  const totalSelected = selected.length;

  const hasSelected = totalSelected > 0;
  const hasSelectable = selectable.length > 0;
  const hasRecordings = recordings.length > 0;
  const hasFiltered = filtered.length > 0;

  const renderList = () => {
    if (!isSuccess) {
      return <RecordingsSkeleton sx={{flex: 1, pt: 2}} />;
    }

    if (!hasRecordings) {
      return <NoRecordings sx={{flex: 1}} />;
    }

    if (!hasFiltered) {
      return <NoFiltered sx={{flex: 1}} onClear={handleClear} />;
    }

    return (
      <>
        <ListHeader sx={{mb: 0.5}} showUnit={true} />

        <Virtuoso
          data={filtered}
          useWindowScroll={true}
          itemContent={(_index, r) => {
            const {id, name} = r;

            return (
              <RecordingCard
                key={id}
                dataId={id}
                selected={isSelected(id)}
                recording={r}
                device={devices?.get(r.deviceId)}
                loadingDevice={fetchingUnits}
                showUnit={true}
                onSelect={() => toggle(id)}
                onDelete={() => handleDelete(id)}
                onDownload={() => handleDownload(id, name)}
              />
            );
          }}
        />
      </>
    );
  };

  return (
    <Stack sx={sx}>
      <Typography fontWeight={600} variant="h5" mb={0.5}>
        Recordings
      </Typography>

      <Typography mb={1} color="text.secondary">
        All recordings that have been uploaded to your team appear here
      </Typography>

      {hasRecordings && (
        <Stack mb={1} direction="row" alignItems="center" gap={1}>
          <Button
            data-id="select-all-btn"
            sx={{minWidth: 106}}
            variant="outlined"
            color="info"
            disabled={!hasSelectable}
            startIcon={
              allSelected ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />
            }
            onClick={handleSelectAll}
          >
            {allSelected ? 'Deselect' : 'Select all'}
          </Button>

          <TotalAndSize dataId="files-summary" files={recordings} />

          <TextField
            sx={{ml: 'auto', width: 240}}
            value={search}
            InputProps={{startAdornment: <SearchIcon />, placeholder: 'Search'}}
            inputProps={{'data-id': 'search-input'}}
            onChange={(event) => {
              setSearch(event.target.value);
              deselectAll();
            }}
          />

          <SortSelector sx={{minWidth: 148}} sort={sort} setSort={setSort} />
        </Stack>
      )}

      {renderList()}

      <Slide in={hasSelected} direction="up" unmountOnExit={true}>
        <BatchRecordings recordings={selected} onClose={deselectAll} />
      </Slide>
    </Stack>
  );
}
