import React from 'react';
import {Box, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {UnitInterfaces} from 'app/components/features/network';

interface Props extends Sx {
  deviceId: string;
}

export function UnitNetwork({sx, deviceId}: Props) {
  return (
    <Box sx={sx}>
      <Typography mb={1} fontWeight={600} variant="h5">
        Network
      </Typography>

      <Typography fontWeight={600} fontSize={16} mb={1}>
        Interfaces
      </Typography>

      <UnitInterfaces deviceId={deviceId} />
    </Box>
  );
}
