import React from 'react';
import {Button, Chip, Collapse, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {FilterSwitch} from 'app/components/sharedReactComponents/FilterSelector/types';

type Props = Sx & {
  filters: FilterSwitch[];
  onRemove: (id: string) => void;
  onRemoveAll: () => void;
};

export function ActiveFilters({sx, filters, onRemove, onRemoveAll}: Props) {
  const expanded = filters.length > 0;

  return (
    <Collapse sx={sx} in={expanded}>
      <Stack minWidth={0} direction="row" alignItems="baseline" gap={1}>
        <Typography variant="body2" flexShrink={0}>
          Filtering by:
        </Typography>

        <Stack direction="row" alignItems="baseline" gap={0.5} flexWrap="wrap">
          {filters.map((filter) => (
            <Chip
              data-id={`filter-chip-${filter.id}`}
              key={filter.id}
              size="small"
              label={filter.label}
              onDelete={() => onRemove(filter.id)}
            />
          ))}
        </Stack>

        <Button
          data-id="clear-active-filters=btn"
          size="small"
          variant="text"
          color="info"
          onClick={onRemoveAll}
        >
          Clear all
        </Button>
      </Stack>
    </Collapse>
  );
}
