import {useEffect, useState} from 'react';
import {loadStripe, Stripe} from '@stripe/stripe-js';

export function useStripeConnection(): Stripe | null {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    const init = async () => {
      try {
        const response = await loadStripe(CIRRUS_STRIPE_PUBLIC_KEY);
        setStripe(response);
      } catch (e: unknown) {
        console.error('Stripe loading error', e);
      }
    };

    void init();
  }, []);

  return stripe;
}
