import React from 'react';
import {Stack} from '@mui/material';
import {EventsCount} from 'app/components/sharedReactComponents/Events/shared/EventsCount/EventsCount';
import {DataId, Sx} from 'app/types/common';
import {EventCategory} from 'app/components/sharedReactComponents/Events/types';
import {isNil} from 'app/util/isNil';

type Props = Sx & DataId & {[K in EventCategory]?: number};

export function CategoryCount({dataId, sx, completed, ongoing, scheduled}: Props) {
  return (
    <Stack data-id={dataId} sx={sx} direction="row" alignItems="center" gap={0.5}>
      {!isNil(ongoing) && ongoing > 0 && (
        <EventsCount dataId="ongoing-count" category="ongoing" count={ongoing} />
      )}

      {!isNil(scheduled) && scheduled > 0 && (
        <EventsCount dataId="scheduled-count" category="scheduled" count={scheduled} />
      )}

      {!isNil(completed) && completed > 0 && (
        <EventsCount dataId="completed-count" category="completed" count={completed} />
      )}
    </Stack>
  );
}
