import React from 'react';
import {Dialog} from '@mui/material';
import {Prompt} from 'app/types/common';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {EditTeamPayment} from 'app/components/BillingManager/dialogs/EditTeamPaymentDialog/EditTeamPayment';

interface Props extends React.ComponentProps<typeof EditTeamPayment>, Prompt {}

export function EditTeamPaymentDialog({open, onClose, ...props}: Props) {
  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm" onClose={onClose}>
      <DialogClose onClose={onClose} />

      <EditTeamPayment {...props} />
    </Dialog>
  );
}
