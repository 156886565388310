import React from 'react';
import classNames from 'classnames';
import {Dropdown} from 'app/components/sharedReactComponents/Dropdown';
import {DropdownSelectButton} from 'app/components/sharedReactComponents/DropdownSelect/DropdownSelectButton';
import {SIZE} from 'app/constants';
import {Icons} from 'app/util/icons';
import {FilterSelectorList} from 'app/components/sharedReactComponents/FilterSelector/FilterSelectorList';
import {Callback, ClassName} from 'app/types/common';
import {TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';
import {
  FilterSwitch,
  FilterSwitchGroup,
} from 'app/components/sharedReactComponents/FilterSelector/types';
import {noop} from 'app/util/noop';

interface Props extends ClassName {
  filterSwitches: FilterSwitch[];
  filterSwitchGroups: FilterSwitchGroup[];
  activeFilterSwitches: Set<string>;
  collapsibleFilterSwitchGroups?: Set<string>;
  collapsedFilterSwitchGroups?: Set<string>;
  searchableFilterSwitchGroups?: Set<string>;
  placement?: TOOLTIP_PLACEMENT;
  onClickFilterSwitcher: Callback;
  onToggleFilterSwitchGroupCollapse?: Callback;
}

export const FilterSelector: React.FC<Props> = ({
  className,
  filterSwitches,
  filterSwitchGroups,
  activeFilterSwitches,
  collapsibleFilterSwitchGroups = new Set(),
  collapsedFilterSwitchGroups = new Set(),
  searchableFilterSwitchGroups = new Set(),
  placement = TOOLTIP_PLACEMENT.TOP_END,
  onClickFilterSwitcher,
  onToggleFilterSwitchGroupCollapse = noop,
}) => {
  const availableFilterSwitches = filterSwitches.filter(filterSwitch => {
    if (typeof filterSwitch.isVisible === 'function') {
      return filterSwitch.isVisible();
    }

    return true;
  });

  if (availableFilterSwitches.length === 0) {
    return null;
  }

  return (
    <div className={classNames('filter-selector', className)}>
      <Dropdown
        content={(
          <FilterSelectorList
            filterSwitches={availableFilterSwitches}
            filterSwitchGroups={filterSwitchGroups}
            activeFilterSwitches={activeFilterSwitches}
            collapsibleFilterSwitchGroups={collapsibleFilterSwitchGroups}
            collapsedFilterSwitchGroups={collapsedFilterSwitchGroups}
            searchableFilterSwitchGroups={searchableFilterSwitchGroups}
            onClickFilterSwitcher={onClickFilterSwitcher}
            onToggleFilterSwitchGroupCollapse={onToggleFilterSwitchGroupCollapse}
          />
        )}
        buttonOpenedClassName="filter-selector__button--active"
        placement={placement}
        interactive={true}
      >
        <DropdownSelectButton
          size={SIZE.S}
          fullWidth={false}
          data-id="filter_selector_button"
        >
          {Icons.filter().label('Filters').size(SIZE.S).reactComponent()}
        </DropdownSelectButton>
      </Dropdown>
    </div>
  );
};
