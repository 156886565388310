import React, {useEffect, useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {CircularProgress, Drawer, Stack, Typography, capitalize} from '@mui/material';
import {Prompt} from 'app/types/common';
import {EventForm} from 'app/components/sharedReactComponents/Events/Editor/EventForm/EventForm';
import {isNil} from 'app/util/isNil';
import {useStreamDestinations} from 'app/hooks/Device/useStreamDestinations';
import {
  ChannelFields,
  Destination,
  EventConfig,
  EventFormFields,
  UpdateMode,
} from 'app/components/sharedReactComponents/Events/Editor/EventForm/types';
import {stringComparator} from 'app/util/Sort';
import {useEpiphanCmsDevices} from 'app/components/sharedReactComponents/Events/hooks/useEpiphanCmsDevices';
import {ScheduleApiService} from 'app/services/api/schedule/ScheduleApiService';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {useMounted} from 'app/hooks/useIsMounted';
import {ScheduleMapper} from 'app/util/mappers/ScheduleMapper';
import {ScheduleContract} from 'app/contracts/schedule';
import {UpdateRecurrentRuleDialog} from 'app/components/sharedReactComponents/Events/dialogs/UpdateRecurrentRuleDialog/UpdateRecurrentRuleDialog';
import {Notifications} from 'app/components/Notifications';
import {isErrorResponse} from 'app/api/types';

interface Props extends Prompt {
  open: boolean;
  teamId: string;
  eventId?: string;
  device?: PearlMasterDeviceModel;
  allowSelectDevices: boolean;
}

export function EventEditor({teamId, open, eventId, device, allowSelectDevices, onClose}: Props) {
  const mounted = useMounted();

  const [formConfig, setFormConfig] = useState<EventConfig | undefined>();
  const [recurrent, setRecurrent] = useState(false);

  const [updateDialog, setUpdateDialog] = useState(false);

  const devicesQuery = useEpiphanCmsDevices({teamId, enabled: open});
  const destinationQuery = useStreamDestinations({teamId, enabled: open});

  const hasConfig = !isNil(formConfig);

  const openUpdateDialog = () => setUpdateDialog(true);
  const closeUpdateDialog = () => setUpdateDialog(false);

  useEffect(() => {
    if (isNil(eventId)) {
      setFormConfig(undefined);
      setRecurrent(false);
      return;
    }

    const getEventRule = async (id: string) => {
      try {
        await Promise.resolve('done');
        const res = await ScheduleApiService.getEventDetails(id);

        if (mounted()) {
          const mapped = ScheduleMapper.mapToEventConfig(res);
          setRecurrent(!isNil(res.recurrence_info));
          setFormConfig(mapped);
        }
      } catch {
        if (mounted()) {
          setFormConfig(undefined);
          setRecurrent(false);
        }
      }
    };

    if (open) {
      if (eventId) {
        void getEventRule(eventId);
        return;
      }

      if (device) {
        setFormConfig({event: getDefaultEventFields(), devices: getDefaultDevicesConfig([device])});
      } else {
        setFormConfig({event: getDefaultEventFields(), devices: new Map()});
      }
    } else {
      setFormConfig(undefined);
      setRecurrent(false);
    }
  }, [open, eventId, device, mounted]);

  const isReady = devicesQuery.isSuccess && destinationQuery.isSuccess && hasConfig;

  const destinations = useMemo<Destination[]>(() => {
    if (destinationQuery.data) {
      return destinationQuery.data
        .map<Destination>((d) => ({
          id: d.id,
          name: d.name,
        }))
        .sort((a, b) => stringComparator(a.name, b.name));
    }

    return [];
  }, [destinationQuery.data]);

  const handleCreate = async (contract: ScheduleContract.EventRule) => {
    try {
      await ScheduleApiService.createEvent(contract);
    } catch (e: unknown) {
      if (isErrorResponse(e) && e.data.Error) {
        Notifications.addErrorNotification(capitalize(e.data.Error));
      } else {
        Notifications.addErrorNotification('Failed to create event');
      }

      throw e;
    }
  };

  const handleUpdate = async (
    eventId: string,
    contract: ScheduleContract.EventRule,
    mode: UpdateMode,
  ) => {
    try {
      await ScheduleApiService.updateEvent(eventId, contract, mode);
    } catch (e: any) {
      if (isErrorResponse(e) && e.data.Error) {
        Notifications.addErrorNotification(capitalize(e.data.Error));
      } else {
        Notifications.addErrorNotification('Failed to update event');
      }

      throw e;
    }
  };

  const handleForm = async (config: EventConfig) => {
    if (isNil(eventId) || !formConfig) {
      return;
    }

    const askForOption = recurrent && !hasRecurrenceChange(formConfig.event, config.event);

    if (askForOption) {
      setFormConfig(config);
      openUpdateDialog();
      return;
    }

    const contract = ScheduleMapper.toEventRule(config);

    if (eventId) {
      const hasRecurrence = config.event.recurrent;
      await handleUpdate(eventId, contract, hasRecurrence ? 'forward' : 'this');
    } else {
      await handleCreate(contract);
    }

    onClose();
  };

  const handleConfirmUpdate = async (mode: UpdateMode) => {
    if (isNil(formConfig) || isNil(eventId) || eventId === '') {
      return;
    }

    const contract = ScheduleMapper.toEventRule(formConfig);
    await handleUpdate(eventId, contract, mode);
    closeUpdateDialog();
    onClose();
  };

  return (
    <Drawer open={open} anchor="right" PaperProps={{sx: {width: 760}}} onClose={onClose}>
      {isReady ? (
        <>
          <UpdateRecurrentRuleDialog
            open={updateDialog}
            onConfirm={handleConfirmUpdate}
            onClose={closeUpdateDialog}
          />
          <EventForm
            connectedDevices={devicesQuery.data}
            initConfig={formConfig}
            destinations={destinations}
            configurable={allowSelectDevices}
            mode={eventId && eventId.length > 0 ? 'edit' : 'create'}
            onConfirm={handleForm}
            onClose={onClose}
          />
        </>
      ) : (
        <Stack flexGrow={1} direction="row" justifyContent="center" alignItems="center" gap={2}>
          <CircularProgress />

          <Typography variant="h6">Preparing</Typography>
        </Stack>
      )}
    </Drawer>
  );
}

function getDefaultEventFields(): EventFormFields {
  const timezone = dayjs.tz.guess();

  const start = dayjs().add(1, 'h').startOf('minute');

  return {
    title: 'New event',
    start: start.unix(),
    end: start.add(1, 'h').unix(),
    timezone,
    recurrent: false,
    recurrence: {
      until: {type: 'never', occurrences: 1, date: start.add(1, 'day').unix()},
      type: 'daily',
    },
  };
}

function getDefaultDevicesConfig(
  devices: PearlMasterDeviceModel[],
): Map<string, Map<string, ChannelFields>> {
  const result: Map<string, Map<string, ChannelFields>> = new Map();

  devices.forEach((d) => {
    const deviceId = d.getId();
    const channels = d.getChanelModels();
    const deviceConf = new Map<string, ChannelFields>();

    channels.forEach((c) => {
      const channelId = c.getChannelDeviceIdIndex();
      deviceConf.set(channelId, {
        destination: '',
        publishers: [],
        recording: true,
        streaming: false,
      });
    });

    result.set(deviceId, deviceConf);
  });
  return result;
}

function hasRecurrenceChange(origin: EventFormFields, target: EventFormFields): boolean {
  if (origin.recurrent !== target.recurrent) {
    return false;
  }

  return JSON.stringify(origin.recurrence) !== JSON.stringify(target.recurrence);
}
