import React from 'react';
import {TierCard} from 'app/components/BillingManager/dialogs/CloudTiersDialog/TierCard/TierCard';
import {Callback, Component} from 'app/types/common';
import {Icons} from 'app/util/icons';
import {THEME, VARIANT} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';
import {CurrentTierMark} from 'app/components/sharedReactComponents/CurrentTierMark/CurrentTierMark';

interface Props extends Component {
  current: boolean;
  tier: App.Billing.Cloud.Tier;
  interval: string;
  onCancelPremium: Callback;
}

export function FreeTier({current, tier, interval, onCancelPremium}: Props) {
  return (
    <TierCard current={current} sx={{minWidth: 250}}>
      <TierCard.Title>{tier.name}</TierCard.Title>

      <TierCard.Price value={tier.rate} interval={interval} perDevice={false} />

      <TierCard.Description>
        {Icons.infinity().theme(THEME.SECONDARY).reactComponent()} Unlimited devices <br />
        Limited functionality
      </TierCard.Description>

      <TierCard.Actions>
        {current ? (
          <CurrentTierMark />
        ) : (
          <Button
            dataId="cancel_premium_button"
            theme={THEME.INFO}
            variant={VARIANT.OUTLINE}
            onClick={onCancelPremium}
          >
            Cancel premium
          </Button>
        )}
      </TierCard.Actions>
    </TierCard>
  );
}
