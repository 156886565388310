import React, {forwardRef, useState} from 'react';
import {Box, ButtonBase, Skeleton, Stack, SxProps, Theme, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {Sx} from 'app/types/common';
import {EventDetails} from 'app/components/sharedReactComponents/Events/Calendar/EventsSchedule/DayEvents/EventDetails/EventDetails';
import {packSx} from 'app/util/packSx/packSx';
import {formatEventMedia} from 'app/components/sharedReactComponents/Events/utils';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {EventPopper} from 'app/components/sharedReactComponents/Events/Calendar/shared/EventPopper/EventPopper';
import {Schedule} from 'app/domain/schedule';
import {Icons} from 'app/util/icons';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {cardStyles} from 'app/components/sharedReactComponents/Events/styles';
import {isCompleted, isOngoing, isScheduled} from 'app/domain/schedule/utils';

const rootStyles: SxProps<Theme> = {
  borderRadius: 1,
  alignItems: 'stretch',
  overflow: 'hidden',
  bgcolor: 'white',
};

interface Props extends Sx {
  event: Schedule.Event;
  devices?: Map<string, PearlMasterDeviceModel>;
  placement?: 'vertical' | 'horizontal';
}

const CalendarEventRef = forwardRef<HTMLButtonElement, Props>(
  ({sx, event, devices, placement = 'horizontal'}, ref) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const deviceCount = event.devices.size;

    const ongoing = isOngoing(event.status);
    const scheduled = isScheduled(event.status);
    const completed = isCompleted(event.status);

    const renderDevices = () => {
      const renderNames = () => {
        if (deviceCount === 1) {
          const deviceId = Array.from(event.devices.keys()).at(0);
          const instance = deviceId ? devices?.get(deviceId) : undefined;

          if (!instance) {
            return <Skeleton width={50} variant="text" />;
          }

          return <OverflowTooltip>{instance.getName()}</OverflowTooltip>;
        }

        return `${deviceCount} ${pluralizeN('device', deviceCount)}`;
      };

      return (
        <Stack direction="row" alignItems="center" gap={1} minWidth={0} maxWidth="100%">
          <Box color={grey[500]}>{Icons.deviceCircuit().reactComponent()}</Box>

          <Typography data-id="event-devices" component="span" minWidth={0}>
            {renderNames()}
          </Typography>
        </Stack>
      );
    };

    return (
      <ButtonBase
        ref={ref}
        data-id={`calendar-event-${event.id}`}
        sx={packSx(rootStyles, sx)}
        onClick={handleOpen}
      >
        <Stack
          sx={packSx(
            cardStyles.root,
            ongoing && cardStyles.ongoing,
            scheduled && cardStyles.scheduled,
            completed && cardStyles.completed,
            {p: 0.5},
          )}
          flex={1}
          alignItems="flex-start"
          minWidth={0}
        >
          <Typography fontWeight={600} component="span" minWidth={0} maxWidth="100%" mb={0.5}>
            <OverflowTooltip dataId="event-title" rowsNumber={1}>
              {event.title}
            </OverflowTooltip>
          </Typography>

          <Typography
            fontSize={12}
            textAlign="left"
            minWidth={0}
            maxWidth="100%"
            component="span"
            mb={0.5}
          >
            <OverflowTooltip dataId="event-type">{formatEventMedia(event.media)}</OverflowTooltip>
          </Typography>

          {renderDevices()}
        </Stack>

        <EventPopper
          anchorEl={anchorEl}
          placement={placement === 'horizontal' ? 'right' : 'top'}
          setAnchor={setAnchorEl}
        >
          <EventDetails
            dataId={`calendar-event-detail-${event.id}`}
            event={event}
            devices={devices}
          />
        </EventPopper>
      </ButtonBase>
    );
  },
);
export const CalendarEvent = React.memo(CalendarEventRef);
