import React from 'react';
import {SettingsList} from 'app/components/sharedReactComponents/SettingsList';
import {DROPDOWN_SELECT_THEME} from 'app/components/sharedReactComponents/DropdownSelect/constants';
import {Callback} from 'app/types/common';
import {DeviceAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/DeviceAudioMeter';
import {isDisabledAudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/utils';
import {SettingDevices} from 'app/components/sharedReactComponents/SettingsList/types';

interface Props {
  source: string;
  channels: number;
  label: string;
  settings: any[];
  selectTheme?: DROPDOWN_SELECT_THEME;
  disabled: boolean;
  devices: SettingDevices;
  updateAction: Callback;
}

export const AudioSourceSettingsItem: React.VFC<Props> = ({
  source,
  channels,
  label,
  settings = [],
  selectTheme,
  disabled,
  devices,
  updateAction,
}) => {
  const id = devices.channel?.getId() ?? devices.master.getId();

  return (
    <div
      className="audio-input-setting"
      data-id="audio_input_setting"
    >
      <div
        className="audio-input-setting__title"
        data-id="audio_input_setting_title"
      >
        {label}
      </div>

      <div className="audio-input-setting__content">
        <DeviceAudioMeter
          className="audio-input-setting__audio-meter"
          id={id}
          disabled={isDisabledAudioMeter(devices.master)}
          source={source}
          mode={channels === 1 ? 'mono' : 'stereo'}
        />

        <SettingsList
          settings={settings}
          selectTheme={selectTheme}
          disabled={disabled}
          updateSettingAction={updateAction}
        />
      </div>
    </div>
  );
};
