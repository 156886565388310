import React from 'react';
import {
  StreamStatusSource,
  StreamStatusRow,
} from 'app/components/pages/MeetingDetails/StreamStatus/StreamStatusDetails/types';
import {StreamingParameters} from 'app/components/pages/MeetingDetails/StreamStatus/StreamStatusDetails/StreamingParameters/StreamingParameters';
import {StreamStatusTable} from 'app/components/pages/MeetingDetails/StreamStatus/StreamStatusDetails/StreamStatusTable/StreamStatusTable';

const videoRows: StreamStatusRow[] = [
  {
    dataKey: 'format',
    label: 'Format',
  },
  {
    dataKey: 'size',
    label: 'Frame size',
    cellRenderer: ({cellData}) => {
      if (cellData === 'HDTV_720') {
        return '1280x720';
      }

      if (cellData === 'HDTV_1080') {
        return '1920x1080';
      }

      return cellData;
    },
  },
  {
    dataKey: 'framerate',
    label: 'Frame rate',
    cellRenderer: ({cellData}) => `${cellData} fps`,
  },
  {
    dataKey: 'bitrate',
    label: 'Video bitrate',
    cellRenderer: ({cellData}) => {
      if (cellData) {
        return `${cellData} kbit/s`;
      }

      return '---';
    },
  },
];

const audioRows: StreamStatusRow[] = [
  {
    dataKey: 'format',
    label: 'Coding format',
    cellRenderer: ({cellData}) => {
      if (cellData === 'AAC_44100') {
        return 'AAC 44.1 kHz';
      }

      if (cellData === 'AAC_48000') {
        return 'AAC 48 kHz';
      }

      return cellData;
    },
  },
  {
    dataKey: 'mode',
    label: 'Type',
  },
];

interface Props {
  source: StreamStatusSource;
  platform: App.Connect.Platform;
  running: boolean;
  joined: boolean;
  streamStatus: App.Connect.StreamingStatus;
}

interface Labels {
  from: string;
  to: string;
}

const getLabels = (source: StreamStatusSource, platform: App.Connect.Platform): Labels => {
  if (source === 'participant') {
    return {
      from: platform === 'teams' ? 'Microsoft Teams' : 'Zoom',
      to: 'SRT Stream',
    };
  }

  return {
    from: 'SRT Stream',
    to: platform === 'teams' ? 'Microsoft Teams' : 'Zoom',
  };
};

export const StreamStatusDetails = ({streamStatus, joined, running, source, platform}: Props) => {
  const {from, to} = getLabels(source, platform);

  return (
    <div>
      <StreamStatusTable
        title="Video quality"
        platform={platform}
        rows={videoRows}
        from={from}
        to={to}
        receiving={streamStatus.video.receiving}
        sending={streamStatus.video.sending}
        running={running}
        joined={joined}
      />

      <StreamStatusTable
        title="Audio quality"
        platform={platform}
        rows={audioRows}
        from={from}
        to={to}
        receiving={streamStatus.audio.receiving}
        sending={streamStatus.audio.sending}
        running={running}
        joined={joined}
      />

      <StreamingParameters params={streamStatus.params} joined={joined} />
    </div>
  );
};
