import React from 'react';

interface Props {
  buttonsComponent: React.ReactNode;
}

export const Buttons: React.VFC<Props> = ({
  buttonsComponent,
}) => {
  return (
    <div className="dashboard-list-header__buttons">
      {buttonsComponent}
    </div>
  );
};
