import React from 'react';
import {Box, Button, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {LoadingButton} from '@mui/lab';

interface Props {
  devices: AnyDeviceModelType[];
  loading: boolean;
  onRemove: () => Promise<void>;
  onCancel: () => void;
}

export function RemoveFromGroup({devices, loading, onRemove, onCancel}: Props) {
  const singleDevice = devices.length === 1;

  return (
    <Box>
      <DialogTitle>Remove from group</DialogTitle>
      <DialogContent>
        <Typography textAlign="center">
          You are about to ungroup{' '}
          <Typography component="span" color="primary.main">
            {singleDevice ? `${devices[0]?.getName() ?? ''}` : `${devices.length} devices`}
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          data-id="confirm-remove-btn"
          loading={loading}
          variant="contained"
          color="error"
          disableRipple={false}
          onClick={onRemove}
        >
          Remove
        </LoadingButton>

        <Button
          data-id="cancel-remove-btn"
          variant="outlined"
          color="info"
          disableRipple={false}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
}
