import React, {useState} from 'react';
import {z} from 'zod';
import {Box, Button, Card, FormLabel, Stack, TextField, Typography, alpha} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Controller, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {blue} from '@mui/material/colors';
import {formatYYYYMMDD} from 'app/util/time';
import {Sx} from 'app/types/common';
import {ALLOWED_CHARS_REGEXP} from 'app/util/validators/DevicePresetNameValidator';
import {
  DEVICE_PRESET_SECTION_ICON,
  DEVICE_PRESET_SECTION_NAME,
} from 'app/constants/devicePresetSection';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {packSx} from 'app/util/packSx/packSx';
import {Edge} from 'app/domain/edge';
import {isNil} from 'app/util/isNil';
import {OverwritePresetDialog} from 'app/components/sharedReactComponents/DevicePresetForm/dialogs/OverwritePresetDialog';
import {Cloud} from 'app/domain/cloud';
import {getModelSections} from 'app/components/DeviceDetails/PresetsTab/utils';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {useMounted} from 'app/hooks/useIsMounted';

const PresetScheme = z.object({
  name: z
    .string()
    .trim()
    .min(1, {message: 'Name is required'})
    .refine((value) => /^[A-Za-z0-9_\-+ #()[\]]+$/.test(value), {
      message: 'Allowed symbols:  A-Z a-z 0-9 _-+ #()[]',
    }),
  description: z.string().trim().optional(),
  sections: z
    .array(z.string())
    .refine((s) => s.length > 0, {message: 'Select at least one configuration group'}),
});

export type PresetFields = z.infer<typeof PresetScheme>;

interface Props extends Sx {
  deviceName: string;
  model: Cloud.UnitModel;
  unitPresets: Edge.UnitPreset[];
  offline: boolean;
  onCancel: () => void;
  onCreate: (preset: PresetFields) => Promise<void>;
}

export function DevicePresetForm({
  sx,
  deviceName,
  model,
  unitPresets,
  offline,
  onCreate,
  onCancel,
}: Props) {
  const mounted = useMounted();

  const [loading, setLoading] = useState(false);
  const [presetToOverwrite, setPresetToOverwrite] = useState<PresetFields | undefined>(undefined);

  const sections = getModelSections(model);

  const disabled = loading || offline;

  const {
    control,
    formState: {errors, isValid},
    handleSubmit,
  } = useForm<PresetFields>({
    defaultValues: {name: generatePresetName(deviceName), sections},
    mode: 'onChange',
    resolver: zodResolver(PresetScheme),
  });

  const handleCreate = async (fields: PresetFields) => {
    try {
      setLoading(true);
      await onCreate({name: fields.name, sections: fields.sections});
      setPresetToOverwrite(undefined);
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  const handleFormSubmit = async (fields: PresetFields) => {
    if (unitPresets.some((localPreset) => localPreset.name === fields.name)) {
      setPresetToOverwrite(fields);
      return;
    }

    await handleCreate(fields);
  };

  const getErrorTooltip = () => {
    return Object.values(errors)[0]?.message;
  };

  const shouldOverwrite = !isNil(presetToOverwrite);

  return (
    <Card
      data-id="preset-form"
      component="form"
      sx={packSx({p: 1, bgcolor: alpha(blue[50], 0.3)}, sx)}
      variant="outlined"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} mb={2}>
        <Typography>
          Create team preset from the local {ModelService.isUnify(model) ? 'project' : 'device'}{' '}
          configuration
        </Typography>

        <Stack direction="row" alignItems="center" gap={1}>
          <Button
            data-id="cancel-preset-from-btn"
            variant="outlined"
            color="info"
            disabled={disabled}
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Tooltip content={getErrorTooltip()}>
            <span>
              <LoadingButton
                data-id="submit-preset-from-btn"
                type="submit"
                variant="contained"
                color="secondary"
                disabled={!isValid}
                loading={loading}
              >
                Create
              </LoadingButton>
            </span>
          </Tooltip>
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap={5}>
        <Box flex={1}>
          <FormLabel sx={{display: 'block', mb: 0.5}}>Preset name</FormLabel>

          <Controller
            name="name"
            control={control}
            disabled={disabled}
            render={({field, fieldState: {error}}) => (
              <TextField
                {...field}
                data-id="preset-name"
                variant="standard"
                disabled={disabled}
                fullWidth={true}
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
              />
            )}
          />
        </Box>

        <Box>
          <FormLabel sx={{display: 'block', mb: 0.5}}>Preset name</FormLabel>
          <Controller
            control={control}
            name="sections"
            disabled={disabled}
            render={({field: {value, onChange}}) => {
              const active = new Set([...value]);

              const handleChange = (section: Edge.PresetSection) => {
                if (active.has(section)) {
                  active.delete(section);
                } else {
                  active.add(section);
                }

                onChange([...active]);
              };

              return (
                <Stack component="fieldset" direction="row" alignItems="flex-start" gap={1}>
                  {sections.map((s) => {
                    const selected = active.has(s);

                    return (
                      <Tooltip key={s} content={DEVICE_PRESET_SECTION_NAME[s]}>
                        <Button
                          key={s}
                          data-id={`${s}-btn`}
                          sx={{p: 0.5, width: 36, height: 36, minWidth: 0}}
                          variant={selected ? 'contained' : 'outlined'}
                          color={selected ? 'primary' : 'info'}
                          disabled={disabled}
                          onClick={() => handleChange(s)}
                        >
                          {DEVICE_PRESET_SECTION_ICON[s].reactComponent()}
                        </Button>
                      </Tooltip>
                    );
                  })}
                </Stack>
              );
            }}
          />
        </Box>
      </Stack>

      {shouldOverwrite && (
        <OverwritePresetDialog
          open={shouldOverwrite}
          name={presetToOverwrite.name}
          loading={loading}
          onOverwrite={async () => handleCreate(presetToOverwrite)}
          onClose={() => setPresetToOverwrite(undefined)}
        />
      )}
    </Card>
  );

  function generatePresetName(deviceName = '') {
    const name = deviceName
      .split('')
      .map((char) => (ALLOWED_CHARS_REGEXP.test(char) ? char : '_'))
      .join('');
    return `${name} Config as of ${formatYYYYMMDD(Date.now() / 1000)}`;
  }
}
