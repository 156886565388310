import React from 'react';
import {Box} from '@mui/material';
import {Sx} from 'app/types/common';

export function PaymentBox({sx, children}: React.PropsWithChildren<Sx>) {
  return (
    <Box px={4} py={3} sx={sx} component="section" height={196}>
      {children}
    </Box>
  );
}
