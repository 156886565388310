import React from 'react';
import {Box, Link, Stack, Typography, typographyClasses} from '@mui/material';
import {blue, grey} from '@mui/material/colors';
import {Component, Sx} from 'app/types/common';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {router} from 'app/router/main';
import {packSx} from 'app/util/packSx/packSx';

interface Props extends Component, Sx {}

export const ReadyToConnect = ({sx}: Props) => {
  return (
    <Stack
      sx={packSx(
        {
          border: '1px solid',
          borderColor: '#e1e3e5',
          height: '520px',
          px: 7,
          position: 'relative',
        },
        sx,
      )}
      direction="row"
      alignItems="center"
      gap={10}
    >
      <Stack direction="row" gap={3}>
        {Icons.microsoftTeams().size(SIZE.XXL).reactComponent()}
        {Icons.zoom().size(SIZE.XXL).reactComponent()}
      </Stack>

      <Box>
        <Typography variant="h5" mb={2}>
          You are ready to create or connect to existing meetings.
        </Typography>

        <Box color={grey[600]} sx={{[`& > .${typographyClasses.root}`]: {color: 'inherit'}}}>
          <Typography mb={2}>Click on the ”New meeting” button above to start.</Typography>

          <Typography mb={2}>
            You can join a Zoom meeting or create a Microsoft Teams meeting and start producing your
            stream right now.
          </Typography>

          <Typography mb={2}>
            You can also connect to existing meetings from Microsoft, however you will need to{' '}
            <Link href={router.url('connectAccountSettings', undefined, {tab: 'teams'})}>
              pair your organization
            </Link>{' '}
            first.
          </Typography>

          <Typography>
            To Connect to a Zoom meeting that requires participants to be authenticated, you will
            need to first{' '}
            <Link href={router.url('connectAccountSettings', undefined, {tab: 'zoom'})}>
              add a Zoom account.
            </Link>
          </Typography>
        </Box>
      </Box>

      <ArrowUpwardIcon
        sx={{
          position: 'absolute',
          top: '40px',
          right: '80px',
          fontSize: '38px',
          color: blue[500],
        }}
      />
    </Stack>
  );
};
