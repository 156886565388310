import React, {useCallback} from 'react';
import {DEVICE_START_STOP_BUTTON_STATUS} from 'app/components/sharedReactComponents/ActionButtons/StartStopButton/constants';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Notifications} from 'app/components/Notifications';
import {StreamingButton} from 'app/components/sharedReactComponents/ActionButtons/StreamingButton';

interface Props {
  device: AnyDeviceModelType;
  disabled: boolean;
  hasEvent?: boolean;
}

export function DeviceStreamingButton({device, hasEvent = false, disabled}: Props) {
  const getStatus = useCallback(() => {
    if (device.hasStreamingError()) {
      return DEVICE_START_STOP_BUTTON_STATUS.ERROR;
    }

    if (device.isStreaming()) {
      return DEVICE_START_STOP_BUTTON_STATUS.STARTED;
    }

    if (device.isStreamingStarted()) {
      return DEVICE_START_STOP_BUTTON_STATUS.LOADING;
    }

    return DEVICE_START_STOP_BUTTON_STATUS.STOPPED;
  }, [device]);

  const action = useCallback(async () => {
    const streamingStarted = device.isStreamingStarted();

    try {
      if (streamingStarted) {
        await device.stopStreaming();
      } else {
        await device.startStreaming();
      }
    } catch (e: unknown) {
      Notifications.addErrorNotification(
        `Could not ${streamingStarted ? 'stop' : 'start'} streaming`,
      );
      throw e;
    }
  }, [device]);

  return (
    <StreamingButton
      disabled={disabled}
      hasEvent={hasEvent}
      statusGetter={getStatus}
      startStopAction={action}
      startTime={device.getStreamingStartTime() ?? undefined}
    />
  );
}
