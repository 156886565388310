import React from 'react';
import ZoomPasscode from 'app/components/dialogs/ZoomPasscodeDialog/ZoomPasscode';
import {Callback} from 'app/types/common';
import {Dialog} from '@mui/material';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';

type Props = React.ComponentProps<typeof ZoomPasscode> & {
  open: boolean;
  onClose: Callback;
};

export function ZoomPasscodeDialog({open, onClose, ...props}: Props) {
  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm" onClose={onClose}>
      <DialogClose onClose={onClose} />
      <ZoomPasscode {...props} />
    </Dialog>
  );
}
