import React, {Fragment} from 'react';
import {Box} from '@mui/material';
import classNames from 'classnames';
import {SourceStatusImage} from 'app/components/sharedReactComponents/StatusImage/SourceStatusImage';
import {ClassName, Sx} from 'app/types/common';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {Edge} from 'app/domain/edge';

interface Props extends Sx, ClassName {
  device: PearlMasterDeviceModel;
  source: Edge.VideoSource;
}

export function SourceThumbnail({sx, className, device, source}: Props) {
  return (
    <Box sx={sx} className={classNames('source-thumbnail', className)} data-id="source_thumbnail">
      {source.isActive ? (
        <Fragment>
          {source.hasSignal ? (
            <SourceStatusImage
              className="source-thumbnail__image"
              device={device}
              sourceId={source.id}
            />
          ) : (
            <div className="source-thumbnail__no-signal">No Signal</div>
          )}
        </Fragment>
      ) : (
        <div className="source-thumbnail__inactive">Inactive</div>
      )}
    </Box>
  );
}
