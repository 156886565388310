import React from 'react';
import {MenuItem, Select, Stack, Typography} from '@mui/material';
import {SortKey, SortOption} from 'app/components/Presets/types';
import {Sx} from 'app/types/common';

interface Props extends Sx {
  value: SortKey;
  options: SortOption[];
  onChange: (v: SortKey) => void;
}
export function SortBySelector({sx, value, options, onChange}: Props) {
  return (
    <Stack sx={sx} direction="row" alignItems="center" gap={1}>
      <Typography>Sort by:</Typography>

      <Select sx={{height: 33, minWidth: 120, py: 0.5}} variant="standard" value={value}>
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value} onClick={() => onChange(o.value)}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}
