import React from 'react';
import classNames from 'classnames';
import {ProgressButton, BUTTON_TYPE} from 'app/components/sharedReactComponents/ProgressButton';
import {
  SIZE,
  THEME,
  VARIANT,
} from 'app/constants';
import {ClassName} from 'app/types/common';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';

interface Props extends ClassName {
  disabled?: boolean;
  loading?: boolean;
  tooltip?: string;
}

export const PairDeviceSubmitButton: React.VFC<Props> = ({
  className,
  disabled,
  loading,
  tooltip
}) => {
  return (
    <Tooltip content={tooltip}>
      <span>
        <ProgressButton
          dataId="pair_device_button"
          className={classNames('pair-device-form__form-submit', className)}
          disabled={disabled}
          loading={loading}
          type={BUTTON_TYPE.SUBMIT}
          theme={THEME.PRIMARY}
          variant={VARIANT.SOLID}
          size={SIZE.L}
        >
          Pair device
        </ProgressButton>
      </span>
    </Tooltip>
  );
};
