import React, {PropsWithChildren} from 'react';
import {Stack, SxProps, Theme} from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import WifiIcon from '@mui/icons-material/Wifi';
import {grey} from '@mui/material/colors';
import {Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';

type Props = Sx & {
  active: boolean;
};

const iconStyles: SxProps<Theme> = {fontSize: 18, color: 'white'};

const Indicator = React.forwardRef<any, PropsWithChildren<Sx>>(({sx, children}, ref) => {
  return (
    <Stack ref={ref} sx={packSx({p: 0.75, bgcolor: grey[400], borderRadius: '50%'}, sx)}>
      {children}
    </Stack>
  );
});

export const StreamingIndicator = React.forwardRef<HTMLElement, Props>(({sx, active}, ref) => {
  return (
    <Indicator ref={ref} sx={packSx(active ? {bgcolor: 'primary.main'} : {}, sx)}>
      <WifiIcon sx={packSx(iconStyles, {rotate: '90deg'})} />
    </Indicator>
  );
});

export const RecordingIndicator = React.forwardRef<HTMLElement, Props>(({sx, active}, ref) => {
  return (
    <Indicator ref={ref} sx={packSx(active ? {bgcolor: 'error.main'} : {}, sx)}>
      <RadioButtonCheckedIcon sx={packSx(iconStyles, {rotate: '90deg'})} />
    </Indicator>
  );
});
