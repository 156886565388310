/* eslint-disable @typescript-eslint/member-ordering */
import {DeviceCommand} from 'app/components/DeviceDetails/constants';
import {CommandOptions} from 'app/components/DeviceDetails/Models/types';
import {DeviceCommandDispatcher} from 'app/util/CommandDispatcher';
import {deviceApiService} from 'app/services/api/device/DeviceApiService';

class CommandService {
  private readonly send = async (
    id: string,
    command: DeviceCommand | string,
    options: CommandOptions = {},
  ) => {
    try {
      const res = await deviceApiService.sendCommand(id, command, options);
      return res;
    } catch {
      return undefined;
    }
  };

  private readonly stop = async (
    id: string,
    command: DeviceCommand | string,
  ) => {
    const res = await this.send(id, `${command}.stop`);
    return res;
  };

  private readonly start = async (
    id: string,
    command: DeviceCommand | string,
  ) => {
    const res = await this.send(id, `${command}.start`);
    return res;
  };

  reboot = async (id: string) => {
    const res = await this.send(id, DeviceCommand.Reboot);
    return res;
  };

  clearFootage = async (id: string) => {
    const res = await this.send(id, DeviceCommand.FootageClear);
    return res;
  };

  updateFirmware = async (id: string) => {
    const res = await this.send(id, DeviceCommand.FirmwareUpdate);
    return res;
  };

  audioLevels = async (id: string) => {
    try {
      const res = await DeviceCommandDispatcher.audioLevels(id);
      return res;
    } catch {
      return undefined;
    }
  };

  sourcePreviews = async (id: string) => {
    try {
      const res = await DeviceCommandDispatcher.sourcePreviews(id);
      return res;
    } catch {
      return undefined;
    }
  };

  channelPreviews = async (id: string) => {
    try {
      const res = await DeviceCommandDispatcher.channelPreviews(id);
      return res;
    } catch {
      return undefined;
    }
  };

  startRecording = async (id: string) => {
    const res = await this.start(id, DeviceCommand.Recording);
    return res;
  };

  stopRecording = async (id: string) => {
    const res = await this.stop(id, DeviceCommand.Recording);
    return res;
  };

  startUploading = async (id: string) => {
    const res = await this.start(id, DeviceCommand.Uploading);
    return res;
  };

  stopUploading = async (id: string) => {
    const res = await this.stop(id, DeviceCommand.Uploading);
    return res;
  };

  selectPublisher = async (id: string, value: boolean) => {
    const options: CommandOptions = {
      params: {
        id,
        config: {
          'single-touch': value,
        }
      }
    };

    const res = await this.send(id, DeviceCommand.PublisherConfigure, options);
    return res;
  };

  startPublishers = async (id: string, ids: string[]) => {
    const res = await this.send(id, DeviceCommand.PublisherStart, {
      params: {
        publisher_ids: ids,
      }
    });

    return res;
  };

  stopPublishers = async (id: string, ids: string[]) => {
    const res = await this.send(id, DeviceCommand.PublisherStop, {
      params: {
        publisher_ids: ids,
      }
    });

    return res;
  };
}

export const commandService = new CommandService();
