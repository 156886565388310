import React from 'react';
import {Typography} from '@mui/material';
import {palette} from 'app/themes/app';
import {Sx} from 'app/types/common';
import {PaymentBox} from 'app/components/Settings/BillingAndUsage/PaymentInformation/PaymentBox/PaymentBox';

interface Props extends Sx {
  balance: number;
  cardPayment: boolean;
}

export function Credits({sx, balance, cardPayment}: Props) {
  const disabled = balance === 0;

  const renderDescription = () => {
    if (balance === 0) {
      return 'Enter a voucher to recharge';
    }

    if (cardPayment) {
      return 'After the credits are spent, the credit card will be charged';
    }

    return 'After the credits are spent, a regular invoice will be issued';
  };

  return (
    <PaymentBox sx={sx}>
      <Typography mb={2} fontSize={16} fontWeight={600}>
        Epiphan credits
      </Typography>

      <Typography
        data-id="customer-balance"
        fontWeight={600}
        fontSize={28}
        mb={0.5}
        sx={disabled ? {color: palette.gray} : {}}
      >
        ${balance.toFixed(2)}
      </Typography>

      <Typography data-id="balance-msg">{renderDescription()}</Typography>
    </PaymentBox>
  );
}
