import React, {RefObject} from 'react';
import {Box, Stack, Typography} from '@mui/material';
import classNames from 'classnames';
import {Panel, PANEL_THEME} from 'app/components/sharedReactComponents/Panel';
import {Icons} from 'app/util/icons';
import {DevicePublisherList} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStreamingPanel/DeviceStreamingDestinationList/DevicePublisherList';
import {DeviceStreamingDestinationList} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStreamingPanel/DeviceStreamingDestinationList/DeviceStreamingDestinationList';
import {StreamingDestinationListTitle} from 'app/components/sharedReactComponents/StreamingDestinationListTitle';
import {THEME, VARIANT, SIZE} from 'app/constants';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {DeviceStatusStreamingIcon} from 'app/components/sharedReactComponents/DeviceStatusIcons';
import {CreateStreamingDestinationButton} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStreamingPanel/CreateStreamingDestinationButton';
import {Callback, ClassName} from 'app/types/common';
import {DevicePublisherModel} from 'app/models/Device/Publisher/DevicePublisherModel';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DeviceWarning} from 'app/components/DeviceDetails/Models/types';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';

interface Props extends ClassName {
  panelRef?: RefObject<HTMLDivElement>;
  deviceId: string;
  streamingCount: number;
  warningsCount: number;
  publishers: DevicePublisherModel[];
  selectedPublishers?: Set<string>;
  selectedStreamingDestinations?: Set<any>;
  publisherWarnings: DeviceWarning[];
  streamingDestinations: AnyStreamingDestinationModelType[];
  streamingDestinationWarnings: DeviceWarning[];
  offline: boolean;
  started: boolean;
  selecting: boolean;
  editable: boolean;
  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  checkStreamingDestinationAvailableToSelect: Callback;
  onPublisherSelect: Callback;
  onStreamingDestinationSelect: Callback;
  onClickStartSelectedStreaming: Callback;
  onClickStopAllStreaming: Callback;
  onStreamingDestinationCreate: Callback;
  onStreamingDestinationDelete: Callback;
}

export function DeviceStreamingPanel({
  className,
  panelRef,
  deviceId,
  streamingCount,
  warningsCount,
  publishers,
  streamingDestinations,
  publisherWarnings,
  selectedPublishers = new Set(),
  selectedStreamingDestinations = new Set(),
  streamingDestinationWarnings,
  offline,
  started,
  selecting,
  editable,
  checkStreamingDestinationAvailableToSelect,
  getDeviceById,
  onPublisherSelect,
  onStreamingDestinationSelect,
  onClickStartSelectedStreaming,
  onClickStopAllStreaming,
  onStreamingDestinationCreate,
  onStreamingDestinationDelete,
}: Props) {
  const hasItems = publishers.length > 0 || streamingDestinations.length > 0;

  const selectedDestinationsCount = selectedPublishers.size + selectedStreamingDestinations.size;
  const isStarted = !offline && started;

  const startStreamingDisabled =
    offline || isStarted || selecting || selectedDestinationsCount === 0;

  return (
    <Panel
      ref={panelRef}
      className={classNames('device-streaming-panel', className)}
      title={
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          Streaming
          {!offline && (
            <div className="device-streaming-panel__statuses">
              {streamingCount > 0 && (
                <div
                  className="device-streaming-panel__status device-streaming-panel__status--streaming"
                  data-id="device_streaming_panel_streaming_indicator"
                >
                  <div className="device-streaming-panel__status-icon">
                    <DeviceStatusStreamingIcon />
                  </div>

                  {streamingCount}
                </div>
              )}

              {warningsCount > 0 && (
                <div
                  className="device-streaming-panel__status device-streaming-panel__status--warnings"
                  data-id="device_streaming_panel_warnings_indicator"
                >
                  <div className="device-streaming-panel__status-icon">
                    {Icons.warning().reactComponent()}
                  </div>

                  {warningsCount}
                </div>
              )}
            </div>
          )}
        </Stack>
      }
      theme={PANEL_THEME.DEVICE_DETAILS_ACTIONS}
    >
      {hasItems && (
        <Box mb={3.5}>
          <Stack gap={3}>
            {publishers.length > 0 && (
              <DevicePublisherList
                offline={offline}
                started={isStarted}
                publishers={publishers}
                selectedPublishers={selectedPublishers}
                warnings={publisherWarnings}
                onSelect={onPublisherSelect}
              />
            )}

            {streamingDestinations.length > 0 && (
              <div>
                <StreamingDestinationListTitle sx={{mb: 1}} />

                <DeviceStreamingDestinationList
                  offline={offline}
                  started={isStarted}
                  editable={editable}
                  streamingDestinations={streamingDestinations}
                  selectedStreamingDestinations={selectedStreamingDestinations}
                  warnings={streamingDestinationWarnings}
                  deviceId={deviceId}
                  checkStreamingDestinationAvailableToSelect={
                    checkStreamingDestinationAvailableToSelect
                  }
                  getDeviceById={getDeviceById}
                  onSelect={onStreamingDestinationSelect}
                  onDelete={onStreamingDestinationDelete}
                />
              </div>
            )}
          </Stack>
        </Box>
      )}

      <Stack direction="row" alignItems="center">
        {hasItems ? (
          <Stack direction="row" gap={1}>
            <ProgressButton
              dataId="device_streaming_panel_start_streams_button"
              theme={THEME.INFO}
              variant={VARIANT.SOLID}
              disabled={startStreamingDisabled}
              onClick={onClickStartSelectedStreaming}
            >
              {Icons.streaming().size(SIZE.S).reactComponent()}
              Start
              {selectedDestinationsCount > 0
                ? ` ${selectedDestinationsCount} ${pluralizeN('stream', selectedDestinationsCount)}`
                : ' streaming'}
            </ProgressButton>

            {isStarted && (
              <ProgressButton
                className="device-streaming-panel__stop-all-button"
                dataId="device_streaming_panel_stop_streams_button"
                onClick={onClickStopAllStreaming}
              >
                {Icons.stop().label('Stop all').reactComponent()}
              </ProgressButton>
            )}
          </Stack>
        ) : (
          <Stack direction="row" py={3} data-id="device_streaming_panel_empty_message">
            <Box
              component="img"
              mr={3}
              src="/assets/img/device-streaming-panel/no-streaming-destinations.png"
              srcSet="/assets/img/device-streaming-panel/no-streaming-destinations@2x.png 2x"
              alt=""
            />

            <div>
              <Typography fontSize={16} fontWeight={600} color="primary.main">
                You don't have streaming destinations created.
              </Typography>

              <Typography>Create a streaming destination now and start streaming.</Typography>
            </div>
          </Stack>
        )}

        <CreateStreamingDestinationButton
          className="device-streaming-panel__add-destination-button"
          disabled={!editable}
          onStreamingDestinationCreate={onStreamingDestinationCreate}
        />
      </Stack>
    </Panel>
  );
}
