import {BaseBilling} from 'app/components/entities/billing/base/model';
import {pluralizeN} from 'app/components/helpers/commonHelpers';

export function formatBillingPeriod({count, interval}: BaseBilling.Config): string {
  return count === 1 ? `per ${interval}` : `every ${count}  ${pluralizeN(interval, count)}`;
}

export function formatPrice(amount: number, fraction = 0): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  }).format(amount);
}
