import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {Icons} from 'app/util/icons';
import {Sx} from 'app/types/common';
import {Schedule} from 'app/domain/schedule';
import {
  getFirstDayOfPeriod,
  getLastDayOfPeriod,
} from 'app/components/sharedReactComponents/Events/Calendar/utils';

type Props = Sx & {
  period: Schedule.Period;
};

export function NoEvents({sx, period}: Props) {
  const firstDay = getFirstDayOfPeriod(period);
  const lastDay = getLastDayOfPeriod(period);

  const begin = firstDay.format('MMM D');
  const end = lastDay.format('MMM D');

  return (
    <Stack
      data-id="no-events-card"
      sx={sx}
      px={5}
      py={4}
      borderRadius={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      bgcolor={grey[100]}
    >
      <Box>
        <Typography fontWeight={600}>
          None events in period {begin} - {end}, {lastDay.format('YYYY')}
        </Typography>
      </Box>
      <Box sx={{'& .svg-icon': {width: 64, height: 64, color: grey[500]}}}>
        {Icons.eventNoDevices().reactComponent()}
      </Box>
    </Stack>
  );
}
