import React from 'react';
import {Box, Link, Typography} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {Sx} from 'app/types/common';
import {Subscription} from 'app/components/BillingManager/dialogs/shared/features';

interface Props extends Sx {
  feature: App.Billing.Feature;
  type: Subscription;
  limit: number;
}

export function FeatureAccess({sx, feature, limit, type}: Props) {
  const unify = type === 'unify';
  const connect = type === 'connect';

  const renderContent = (): React.ReactNode => {
    switch (feature.id) {
      case 'srt':
      case 'channels': {
        return unify ? 'Up to 9' : false;
      }

      case 'projects': {
        return unify ? 'Unlimited' : false;
      }

      case '4k':
      case 'remote':
      case 'layouts':
      case 'playback':
      case 'web-source': {
        return unify;
      }

      case 'first-quarter': {
        return connect ? 'Free' : false;
      }

      case 'isolated':
      case 'primary-speaker':
      case 'platforms':
      case 'return-feed':
      case 'easy-connect':
      case 'screen-share': {
        return connect;
      }

      case 'limit': {
        return connect ? 'Unlimited' : false;
        break;
      }

      case 'extractions': {
        return connect ? (
          <React.Fragment>
            <Typography textAlign="center"> Up to {limit}</Typography>
          </React.Fragment>
        ) : (
          false
        );
      }

      case 'platform-input': {
        return unify ? (
          <React.Fragment>
            <Typography textAlign="center">
              Requires{' '}
              <Link href="https://www.epiphan.com/connect" target="_blank" rel="noreferrer">
                Epiphan Connect
              </Link>{' '}
              subscription
            </Typography>
          </React.Fragment>
        ) : (
          false
        );
      }

      default:
        return '';
    }
  };

  return <FeatureValue sx={sx} value={renderContent()} />;
}

interface FeatureValueProps extends Sx {
  value: React.ReactNode;
}

function FeatureValue({sx, value}: FeatureValueProps) {
  if (typeof value === 'boolean') {
    return value ? <CheckIcon sx={sx} color="secondary" /> : <CloseIcon sx={sx} color="info" />;
  }

  return <Box sx={sx}>{value}</Box>;
}
