import React from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {Button} from 'app/components/sharedReactComponents/Button';
import {DevicesAlertsSidebar} from 'app/components/DevicesAlerts/DevicesAlertsSidebar';
import {ClassName} from 'app/types/common';
import {TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';
import {Dropdown} from 'app/components/sharedReactComponents/Dropdown';
import {AlertsApi, useUnreadAlerts} from 'app/components/entities/alerts';

export function DevicesAlertsCounter({className}: ClassName) {
  const query = useUnreadAlerts();

  const alerts = query.data ?? [];
  const count = alerts.length;

  const handleAcknowledgeSince = async (timestamp: number) => {
    await AlertsApi.acknowledgeSince(timestamp);
    void query.refetch();
  };

  return (
    <Dropdown
      content={<DevicesAlertsSidebar alerts={alerts} acknowledgeSince={handleAcknowledgeSince} />}
      placement={TOOLTIP_PLACEMENT.BOTTOM_END}
      appendToBody={true}
      interactive={true}
      maxWidth={470}
    >
      <Button
        className={classNames('devices-alerts-counter', className)}
        dataId="devices_alerts_counter_button"
      >
        {Icons.notifications().class('add-payment-method-message__icon').reactComponent()}

        {count > 0 && <div className="devices-alerts-counter__dot" />}

        <span className="devices-alerts-counter__value">{count}</span>
      </Button>
    </Dropdown>
  );
}
