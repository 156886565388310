import {useQuery} from '@tanstack/react-query';
import {NetworkApi, NetworkMapper} from 'app/components/entities/network';

export function useUnitInterfacesQuery(id: string) {
  return useQuery({
    queryKey: ['unit', 'network', id],
    queryFn: async () => {
      const res = await NetworkApi.getInterfaces(id);
      return res.map(NetworkMapper.mapInterface);
    },
  });
}
