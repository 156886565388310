import {UserTeamStore} from 'app/util/UserTeamStore';

interface CustomerStorageArgs<Value extends any = any> {
  defaultValue: Value;
  key: string;
  teamId: string;
  userId: string;
}

export class CustomerStorage<Value extends any = any> {
  private readonly storage: UserTeamStore;

  constructor({defaultValue, key, teamId, userId}: CustomerStorageArgs<Value>) {
    this.storage = new UserTeamStore({
      defaultValue,
      storeKey: key,
      teamId,
      userId,
    });
  }

  getValue() {
    return this.storage.load<Value>();
  }

  setValue(value?: Value) {
    this.storage.save<Value | undefined>(value);
  }
}
