import React, {useState} from 'react';
import classNames from 'classnames';
import {
  AudioMuteButton,
  StreamSettingsButton,
  StreamStartButton,
  StreamStopButton,
  VideoMuteButton,
} from 'app/components/pages/MeetingDetails/shared/StreamButtons/StreamButtons';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Callback, ClassName} from 'app/types/common';
import {StopSrtPrompt} from 'app/components/MeetingsManager/modals/StopSrtPrompt/StopSrtPrompt';
import {isErrorResponse, getErrorContents} from 'app/api/types';
import {Notifications} from 'app/components/Notifications';

interface Props extends ClassName {
  name: string;
  audio: boolean;
  video: boolean;
  view: 'start' | 'stop';
  disabledMute: boolean;
  disabledStart: boolean;
  disabledStop: boolean;
  onStart: Callback;
  onStatus: Callback;
  onStop: Callback<Promise<void>>;
  onMute: Callback<Promise<void>, [App.Connect.StreamMute]>;
}

const maxSrtMessage = (
  <span>
    You have reached the maximum number
    <br />
    of SRT connections. Remove at least one
    <br />
    to create a new SRT Connection.
  </span>
);

export const ParticipantActions = ({
  className,
  name,
  view,
  audio,
  video,
  disabledMute,
  disabledStart,
  disabledStop,
  onMute,
  onStart,
  onStop,
  onStatus,
}: Props) => {
  const tip = disabledStart ? maxSrtMessage : undefined;

  const [muting, setMuting] = useState({audio: false, video: false});

  const handleMuteAudio = async () => {
    try {
      setMuting((prev) => ({...prev, audio: true}));
      const mute: App.Connect.StreamMute = {video, audio: !audio};
      await onMute(mute);
    } catch (e: unknown) {
      const reason = isErrorResponse(e) && getErrorContents(e);
      Notifications.addErrorNotification(`Could not mute srt${reason ? ': '.concat(reason) : ''}`);
    } finally {
      setMuting((prev) => ({...prev, audio: false}));
    }
  };

  const handleMuteVideo = async () => {
    try {
      setMuting((prev) => ({...prev, video: true}));
      const mute: App.Connect.StreamMute = {video: !video, audio};
      await onMute(mute);
    } catch (e: unknown) {
      const reason = isErrorResponse(e) && getErrorContents(e);
      Notifications.addErrorNotification(`Could not mute srt${reason ? ': '.concat(reason) : ''}`);
    } finally {
      setMuting((prev) => ({...prev, video: false}));
    }
  };

  const handleStop = async () => {
    StopSrtPrompt.show({
      name,
      onDone: async () => {
        try {
          await onStop();
        } catch (e: unknown) {
          const reason = isErrorResponse(e) && getErrorContents(e);
          Notifications.addErrorNotification(
            `Could not stop srt${reason ? ': '.concat(reason) : ''}`,
          );
        }
      },
    });
  };

  return (
    <div className={classNames('participant-actions', className)}>
      {view === 'stop' ? (
        <>
          <VideoMuteButton
            className="participant-actions__item"
            dataId="mute_video_stream_btn"
            muted={video}
            disabled={disabledMute || muting.video}
            onAction={handleMuteVideo}
          />

          <AudioMuteButton
            className="participant-actions__item"
            dataId="mute_audio_stream_btn"
            disabled={disabledMute || muting.audio}
            muted={audio}
            onAction={handleMuteAudio}
          />

          <StreamSettingsButton
            className="participant-actions__item"
            dataId="stream_settings_btn"
            onAction={onStatus}
          />

          <StreamStopButton
            className="participant-actions__item"
            dataId="stream_stop_btn"
            onAction={handleStop}
            disabled={disabledStop}
          />
        </>
      ) : (
        <Tooltip content={tip}>
          <span>
            <StreamStartButton
              dataId="stream_start_btn"
              disabled={disabledStart}
              onAction={onStart}
            />
          </span>
        </Tooltip>
      )}
    </div>
  );
};
