import React, {useEffect, useState} from 'react';
import {AgreementDialog, useAgreementQuery} from 'app/components/entities/profile';

export function UserAgreement() {
  const {data} = useAgreementQuery();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (data) {
      setOpen(true);
    }
  }, [data]);

  if (!data) {
    return null;
  }
  return (
    <AgreementDialog
      open={open}
      content={data.Agreement}
      id={data.AgreementID}
      time={data.AgreementTime}
      onClose={() => setOpen(false)}
    />
  );
}
