import React from 'react';
import {ClassName} from 'app/types/common';
import {SIZE, THEME} from 'app/constants';
import {Icons} from 'app/util/icons';
import classNames from 'classnames';

interface Props extends ClassName {
  count: number;
}

export const MeetingParticipantsCount = ({className, count}: Props) => {
  return (
    <div
      className={classNames('meeting-participants-count', className)}
      data-id="meeting_participants_count"
    >
      {Icons.user().size(SIZE.S).theme(THEME.PRIMARY).label(`${count}`).reactComponent()}
    </div>
  );
};
