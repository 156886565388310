import {useQuery} from '@tanstack/react-query';
import {MaintenanceApi} from 'app/components/entities/maintenance/api/MaintenanceApi';

export function useMaintenanceQuery() {
  return useQuery({
    queryKey: ['maintenance'],
    queryFn: async () => {
      const response = await MaintenanceApi.getMaintenance();
      return response[0] ?? null;
    },
    refetchInterval: 1000 * 60,
  });
}
