import React from 'react';
import dayjs from 'dayjs';
import {Button, IconButton, Stack, Typography, alpha} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {grey} from '@mui/material/colors';
import {Sx} from 'app/types/common';
import {isPassed, isToday} from 'app/components/sharedReactComponents/Events/Calendar/utils';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {EventIcon} from 'app/components/sharedReactComponents/Events/shared/EventIcon/EventIcon';

interface Props extends Sx {
  day: number;
  selected: boolean;
  count: number;
  onClick: () => void;
  onClose: () => void;
}

export function Weekday({sx, day, selected, count, onClick, onClose}: Props) {
  const today = isToday(day);
  const passed = isPassed(day);

  const instance = dayjs.unix(day);

  return (
    <Stack
      sx={sx}
      minWidth={80}
      position="relative"
      direction="row"
      bgcolor={(t) =>
        selected ? alpha(t.palette.primary.main, t.palette.action.selectedOpacity) : 'transparent'
      }
    >
      <Button
        data-id={`weekday-${day}`}
        sx={{p: 0, flexGrow: 1, alignItems: 'stretch'}}
        variant="text"
        disableRipple={false}
        onClick={onClick}
      >
        <Stack
          color={() => {
            if (today) {
              return 'primary.main';
            }

            if (passed) {
              return grey[500];
            }

            return 'text.primary';
          }}
        >
          <Stack flexGrow={1} justifyContent="center">
            <Typography data-id="weekday-name">{instance.format('ddd')}</Typography>
            <Typography data-id="weekday-date" variant="h5">
              {instance.format('D')}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="center" alignItems="center" height={24} gap={1}>
            {count > 0 && (
              <>
                <EventIcon sx={{color: passed ? grey[300] : grey[500]}} type="ongoing" />

                <Typography data-id="day-total" fontSize={12}>
                  {count} {`${pluralizeN('event', count)}`}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Button>

      {selected && (
        <IconButton
          data-id="close-day-btn"
          sx={{position: 'absolute', right: 0, top: 0}}
          disableRipple={false}
          onClick={onClose}
        >
          <CloseIcon sx={{fontSize: 14}} color="primary" />
        </IconButton>
      )}
    </Stack>
  );
}
