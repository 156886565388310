import React, {Component} from 'react';
import classNames from 'classnames';
import {DeletePrompt} from 'app/components/sharedReactComponents/Modal/DeletePrompt';
import moment from 'moment';
import {
  KeyboardCode,
  VARIANT,
} from 'app/constants';
import {TOKEN_ERROR} from 'app/components/Settings/ApiTokens/TokensPanel/constants';
import {
  getErrorTextByError,
  normalizeTokenName,
} from 'app/components/Settings/ApiTokens/TokensPanel/utils';
import {Icons} from 'app/util/icons';
import {TokensPanelListItemTokenRow} from 'app/components/Settings/ApiTokens/TokensPanel/TokensPanelList/TokensPanelListItemTokenRow';
import {FormControl} from 'app/components/sharedReactComponents/FormControl';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Callback, ClassName} from 'app/types/common';
import {Token} from 'app/components/Settings/ApiTokens/TokensPanel/types';

const CREATED_DATE_FORMAT = 'hh:mm, YYYY-MM-DD';
const CREATED_DATETIME_SOURCE_FORMAT = 'YYYY-MM-DDThh:mm';

function selectTokenName(props) {
  return props.token.Name;
}

interface Props extends ClassName {
  token: Token;
  endpoint: Callback;
  isUniqueTokenName: Callback;
  onUpdate: Callback;
  onDelete: Callback;
}

interface State {
  name: string;
  error: any;
  updating: boolean;
}

export class TokensPanelListItem extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      name: selectTokenName(props),
      error: null,
      updating: false,
    };
  }

  componentDidUpdate(prevProps) {
    const name = selectTokenName(this.props);

    if (name !== selectTokenName(prevProps)) {
      this.setState({
        name,
        error: null,
      });
    }
  }

  handleClickDelete = () => {
    const {
      token,
      endpoint,
      onDelete,
    } = this.props;

    DeletePrompt.show({
      title: 'Are you sure you want to delete this token?',
      action: () => {
        return endpoint(token.ID).delete()
          .then(() => {
            onDelete(token.ID);
          });
      },
    });
  };

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value,
      error: null,
    });
  };

  handleNameFocus = () => {
    this.setState({error: this.getNewNameError()});
  };

  handleNameBlur = () => {
    this.saveName();
  };

  handleNameKeyUp = (e: React.KeyboardEvent) => {
    if (e.code === KeyboardCode.Enter) {
      this.saveName();
    }
  };

  getNewNameError() {
    const newName = normalizeTokenName(this.state.name);
    const {
      token,
      isUniqueTokenName,
    } = this.props;

    if (newName === '') {
      return {type: TOKEN_ERROR.EMPTY_NAME};
    }

    if (!isUniqueTokenName(newName, token.ID)) {
      return {type: TOKEN_ERROR.UNIQUE_NAME};
    }

    return null;
  }

  saveName() {
    const newName = normalizeTokenName(this.state.name);
    const {
      token,
      endpoint,
      onUpdate,
    } = this.props;

    const error = this.getNewNameError();

    if (error) {
      this.setState({error});
    } else if (token.Name !== newName) {
      this.setState({updating: true});

      endpoint(token.ID).put({Name: newName})
        .then(() => {
          onUpdate(token.ID, newName);
        })
        .catch((err) => {
          this.setState({
            error: {
              type: TOKEN_ERROR.RENAME_FAIL,
              data: err.data.Error,
            }
          });
        })
        .finally(() => {
          this.setState({updating: false});
        });
    }
  }

  render() {
    const {
      className,
      token,
    } = this.props;
    const {
      name,
      error,
      updating,
    } = this.state;

    const creationTime = moment.unix(token.CreationTime).utc();
    const formattedDate = creationTime.format(CREATED_DATE_FORMAT);
    const formattedDateSource = creationTime.format(CREATED_DATETIME_SOURCE_FORMAT);
    const isNewToken = Boolean(token.Token);

    const hasError = Boolean(error);

    return (
      <div
        className={classNames('tokens-panel-list-item', className, {
          'tokens-panel-list-item--new': isNewToken,
        })}
      >
        <div className="tokens-panel-list-item__create-date">
          {'Created '}

          <time dateTime={formattedDateSource}>
            {formattedDate}
          </time>
        </div>

        <div className="tokens-panel-list-item__content">
          <div className="tokens-panel-list-item__input-col">
            <FormControl
              // rework name if it's useless here
              name={name}
              value={name}
              helperText={hasError && getErrorTextByError(error)}
              error={hasError}
              endAdornment={updating && Icons.spinner().reactComponent()}
              disabled={updating}
              fullWidth={true}
              onChange={this.handleNameChange}
              onFocus={this.handleNameFocus}
              onBlur={this.handleNameBlur}
              onKeyUp={this.handleNameKeyUp}
            />
          </div>

          <div className="tokens-panel-list-item__actions">
            <Button
              className="tokens-panel-list-item__action"
              variant={VARIANT.LINK}
              onClick={this.handleClickDelete}
            >
              Delete token
            </Button>
          </div>
        </div>

        {/* rework !-operator below */}
        {isNewToken && <TokensPanelListItemTokenRow token={token.Token!}/>}
      </div>
    );
  }
}
