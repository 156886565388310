import React from 'react';
import {Box} from '@mui/material';
import {Component, Sx} from 'app/types/common';
import {EntityCard} from 'app/components/MeetingsManager/cards/EntityCard/EntityCard';
import {ContextMenuItem} from 'app/components/sharedReactComponents/ContextMenu';
import {Icons} from 'app/util/icons';
import {palette} from 'app/themes/app';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';
import moment from 'moment';
import {useConnectAction} from 'app/components/MeetingsManager/ConnectManager';

interface Props extends Component, Sx {
  call: App.Connect.Call;
  onDelete: Fn<void, [c: App.Connect.Call]>;
}

export const CallCard = ({dataId, sx, call, onDelete}: Props) => {
  const handleDelete = () => onDelete(call);

  const items: ContextMenuItem[] = [
    {
      label: 'Delete',
      danger: true,
      icon: Icons.trash().reactComponent(),
      action: handleDelete,
    },
  ];

  const {connectHosted} = useConnectAction();

  const handleConnect = () => {
    connectHosted({id: call.id, url: call.url});
  };

  return (
    <EntityCard dataId={dataId} sx={sx}>
      <EntityCard.Icon platform="teams" />

      <EntityCard.Name value={call.name} url={call.url} disabled={true} selfHosted={true} />

      <Box sx={{gridColumn: 'col 1 / col 2'}}>
        <Box sx={{color: palette.gray2}} data-id="meeting_status_text">
          Meeting available
        </Box>

        <Box sx={{color: palette.darkerGray, fontSize: '12px'}}>
          Link expires <span data-id="expiration-date">{formatTime(call.endAt)}</span>
        </Box>
      </Box>

      <Box sx={{gridColumn: 'col 2 / col 3', color: '#bdbdbd', fontSize: '12px'}}>
        Not available
      </Box>

      <Box sx={{gridColumn: 'col 3 / col 4'}}>
        <Button
          data-id="connect-hosted-btn"
          variant={VARIANT.OUTLINE}
          theme={THEME.SUCCESS}
          size={SIZE.S}
          onClick={handleConnect}
        >
          Start Connect
        </Button>
      </Box>

      <EntityCard.Menu items={items} />
    </EntityCard>
  );
};

function formatTime(timestamp: TimeStampSeconds): string {
  return moment.unix(timestamp).format('MMM D, ha');
}
