import React, {useState} from 'react';
import {Dialog} from '@mui/material';
import {Prompt} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {RemoveFromGroup} from 'app/components/dialogs/RemoveFromGroupDialog/RemoveFromGroup';

type Props = Prompt & {
  devices: AnyDeviceModelType[];
  onRemove: () => Promise<void>;
};

export function RemoveFromGroupDialog({open, devices, onRemove, onClose}: Props) {
  const [loading, setLoading] = useState(false);

  const handleRemove = async () => {
    try {
      setLoading(true);
      await onRemove();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="xs" keepMounted={false} onClose={onClose}>
      <RemoveFromGroup
        devices={devices}
        loading={loading}
        onRemove={handleRemove}
        onCancel={onClose}
      />
    </Dialog>
  );
}
