export function formatTimeUnit(value: string): string {
  switch (value) {
    case 'day':
      return 'daily';

    case 'week':
      return 'weekly';

    case 'month':
      return 'monthly';

    case 'year':
      return 'annual';

    default:
      return '';
  }
}
