import {useMemo} from 'react';
import {Participant} from 'app/store/models/connect/Participant/Participant';
import {Entity} from 'app/store/models/connect/Meeting/Meeting';

type SummaryItem = {
  value: number;
  tooltip?: string;
};

export type SrtSummaryCounts = {
  connecting: SummaryItem;
  connected: SummaryItem;
  error: SummaryItem;
  disconnected: SummaryItem;
};

function countStreams(entities: Entity[]): SrtSummaryCounts {
  const state: SrtSummaryCounts = {
    connecting: {value: 0},
    connected: {value: 0},
    error: {value: 0},
    disconnected: {value: 0},
  };

  entities.forEach((p) => {
    const {connected, disconnected, failed, started, starting, stopping} = p.stream;

    if (isParticipant(p) && p.state === 'left-with-stream') {
      state.disconnected.value += 1;
      return;
    }

    if (starting || started || stopping || disconnected) {
      state.connecting.value += 1;
      return;
    }

    if (connected) {
      state.connected.value += 1;
      return;
    }

    if (failed) {
      state.error.value += 1;
      const err = p.stream.error?.message;
      const name = p instanceof Participant ? p.name : 'Return feed';
      const row = err ? `${name} - ${err}\n` : '';

      if (state.error.tooltip) {
        state.error.tooltip += row;
      } else {
        state.error.tooltip = row;
      }
    }
  });

  return state;
}

export function useSrtCount(participants: Entity[]): SrtSummaryCounts {
  const state = useMemo(() => countStreams(participants), [participants]);
  return state;
}

export function isParticipant(e: Entity): e is Participant {
  return e instanceof Participant;
}
