import React from 'react';
import {Box, CSSObject} from '@mui/material';
import {Callback, Component, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {palette} from 'app/themes/app';
import {EntityName} from 'app/components/MeetingsManager/cards/EntityCard/EntityName/EntityName';
import {EntityMenu} from 'app/components/MeetingsManager/cards/EntityCard/EntityMenu/EntityMenu';
import {Icons} from 'app/util/icons';
import {styles} from 'app/components/MeetingsManager/styles';

const root: CSSObject = {
  borderRadius: 1,
  p: 1,
  border: '1px solid',
  borderColor: palette.grayMedium,
};

interface Props extends React.PropsWithChildren<Component>, Sx {
  onClick?: Callback;
}

export const EntityCard = ({dataId, sx, children, onClick}: Props) => {
  return (
    <Box data-id={dataId} sx={packSx(root, styles.grid, sx)} onClick={onClick}>
      {children}
    </Box>
  );
};

interface IconProps extends Component {
  platform: App.Connect.Platform;
}

function Icon({dataId, platform}: IconProps) {
  return (
    <Box data-id={dataId} sx={{gridColumn: 'icon 1 / icon 2'}}>
      {(platform === 'teams' ? Icons.microsoftTeams() : Icons.zoom()).reactComponent()}
    </Box>
  );
}

EntityCard.Icon = Icon;
EntityCard.Name = EntityName;
EntityCard.Menu = EntityMenu;
