/* eslint-disable react/default-props-match-prop-types */
import React, {Component, createRef, Fragment} from 'react';
import {Dropdown} from 'app/components/sharedReactComponents/Dropdown';
import {DropdownSelectOption} from 'app/components/sharedReactComponents/DropdownSelect/DropdownSelectOption';
import {DropdownSelectSeparator} from 'app/components/sharedReactComponents/DropdownSelect/DropdownSelectSeparator';
import {DropdownSelectNoOptionsMessage} from 'app/components/sharedReactComponents/DropdownSelect/DropdownSelectNoOptionsMessage';
import {DropdownSelectGroupOption} from 'app/components/sharedReactComponents/DropdownSelect/DropdownSelectGroupOption';
import {
  defaultGetOptionLabel,
  defaultGetOptionValue,
  defaultGetOptionTitle,
  defaultGetOptionOptions,
  curriedProcessOptionsList,
} from 'app/components/sharedReactComponents/DropdownSelect/utils';
import {DropdownSelectButton} from 'app/components/sharedReactComponents/DropdownSelect/DropdownSelectButton';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {DROPDOWN_SELECT_THEME} from 'app/components/sharedReactComponents/DropdownSelect/constants';
import {SIZE} from 'app/constants';
import {WAI_ARIA_ROLE} from 'app/constants/waiAriaRole';
import {isNil} from 'app/util/isNil';
import {Callback} from 'app/types/common';
import {TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';

type Options = {
  label?: React.ReactNode;
  value?: number | string;
  icon?: React.ReactNode;
  title?: string;
  visible?: boolean;
  disabled?: boolean;
  options?: any[];
};

export interface SelectProps {
  options: Array<(Options | Record<string, any>)>;
  theme?: DROPDOWN_SELECT_THEME;
  appendToBody?: boolean;
  placement?: TOOLTIP_PLACEMENT;

  buttonComponent?: React.ReactNode;
  noOptionsMessage?: React.ReactNode;
  menuFooterComponent?: React.ReactNode;

  getOptionLabel?: Callback;
  getOptionValue?: Callback;
  getOptionTitle?: Callback;
  getOptionOptions?: Callback;
}

interface Props extends SelectProps {
  name?: string;
  value?: number | string | boolean;
  placeholder: any;
  clearable: boolean;
  disabled?: boolean;
  onChange?: Callback;
  onBlur?: Callback;
  loading?: boolean;
  indeterminate?: boolean;
  closeOnItemClick: boolean;
  buttonComponentRenderer?: Callback;

  // rework - added default to avoid errors
  size: SIZE;
  // rework - have no default
  fullWidth?: boolean;
  quietLoading?: boolean;
  onOpen?: Callback;
}

export class DropdownSelect extends Component<Props> {
  static defaultProps = {
    size: SIZE.M,
    placeholder: 'Select...',
    clearable: false,
    closeOnItemClick: true,
    options: [],
    getOptionLabel: defaultGetOptionLabel,
    getOptionValue: defaultGetOptionValue,
    getOptionTitle: defaultGetOptionTitle,
    getOptionOptions: defaultGetOptionOptions,
  };

  public dropdownRef = createRef<Dropdown>();
  public processedOptions: any;
  public optionsListProcessor: any;

  constructor(props: Props) {
    super(props);

    this.processedOptions = {
      withIcons: false,
      processedOptions: null,
      selectedOption: null,
    };

    this.optionsListProcessor = null;
    this.updateOptionsListProcessor(props, true);
  }

  _needsToUpdateOptionsListProcessor(nextProps, currentProps) {
    const getterNames = [
      'getOptionLabel',
      'getOptionValue',
      'getOptionTitle',
      'getOptionOptions',
    ];

    for (const getterName of getterNames) {
      if (nextProps[getterName] !== currentProps[getterName]) {
        return true;
      }
    }

    return false;
  }

  updateOptionsListProcessor(nextProps, force = false) {
    if (!force) {
      if (this._needsToUpdateOptionsListProcessor(nextProps, this.props)) {
        return;
      }
    }

    const {
      getOptionLabel,
      getOptionValue,
      getOptionTitle,
      getOptionOptions,
    } = nextProps;

    this.optionsListProcessor = curriedProcessOptionsList({
      getOptionLabel,
      getOptionValue,
      getOptionTitle,
      getOptionOptions,
    });
  }

  componentDidUpdate() {
    this.updateOptionsListProcessor(this.props);
  }

  handleClickOption = (value) => {
    const {
      name,
      closeOnItemClick,
      onChange,
    } = this.props;

    if (closeOnItemClick) {
      this.dropdownRef.current?.clickButton();
    }

    onChange?.({target: {name, value}});
  };

  handleClickClearButton = (e) => {
    e.stopPropagation();

    const {
      name,
      onChange,
    } = this.props;

    onChange?.({target: {name, value: null}});
  };

  processOptionsList() {
    const {
      options,
      value,
      indeterminate,
    } = this.props;

    return this.optionsListProcessor(options, indeterminate ? null : value);
  }

  renderList(processedOptions: any[], selectedOptionValue, withIcons) {
    return processedOptions.reduce((acc, props, index) => {
      const {
        label,
        value,
        icon,
        options,
        title,
        separator,
        visible = true,
        disabled,
      } = props;

      if (visible === false) {
        return acc;
      }

      if (separator) {
        acc.push(
          <DropdownSelectSeparator key={`separator-${index}`}/>
        );
        return acc;
      }

      if (options) {
        acc.push(
          <DropdownSelectGroupOption
            key={label}
            label={label}
          >
            {this.renderList(options, selectedOptionValue, withIcons)}
          </DropdownSelectGroupOption>
        );

        return acc;
      }

      acc.push(
        <DropdownSelectOption
          key={value}
          label={label}
          value={value}
          icon={icon}
          title={title}
          withIcon={withIcons}
          disabled={disabled}
          active={value === selectedOptionValue}
          onClick={this.handleClickOption}
        />
      );

      return acc;
    }, []);
  }

  renderNoOptionsMessage() {
    return (
      <DropdownSelectNoOptionsMessage>
        {this.props.noOptionsMessage}
      </DropdownSelectNoOptionsMessage>
    );
  }

  renderDropdownMenu(processedOptions, selectedOptionValue, withIcons) {
    return (
      <div className="dropdown-select__scrollbars">
        <ul
          className="dropdown-select__list"
          role={WAI_ARIA_ROLE.MENU}
        >
          {this.renderList(processedOptions, selectedOptionValue, withIcons)}
        </ul>
      </div>
    );
  }

  renderValueName(selectedOption): React.ReactNode {
    const {
      placeholder,
      indeterminate,
    } = this.props;

    if (indeterminate) {
      return (
        <span className="dropdown-select__name">
          —
        </span>
      );
    }

    if (selectedOption === null) {
      return (
        <span className="dropdown-select__placeholder">
          {placeholder}
        </span>
      );
    }

    return (
      <Fragment>
        {selectedOption.icon && (
          <span className="dropdown-select__icon">
            {selectedOption.icon}
          </span>
        )}

        <OverflowTooltip
          className="dropdown-select__name"
          component="span"
          async={true}
        >
          {selectedOption.label}
        </OverflowTooltip>
      </Fragment>
    );
  }

  renderButton({
    ref,
    selectedOption,
    fullWidth,
    opened,
    ...renderProps
  }) {
    const {
      theme,
      size,
      clearable,
      disabled,
      loading,
      quietLoading,
      buttonComponentRenderer,
      ...rest
    } = this.props;

    if (buttonComponentRenderer) {
      return buttonComponentRenderer({
        ref,
        selectedOption,
      });
    }

    const {
      options,
      appendToBody,
      buttonComponent,
      noOptionsMessage,
      menuFooterComponent,
      getOptionLabel,
      getOptionValue,
      getOptionTitle,
      getOptionOptions,
      name,
      value,
      placeholder,
      onChange,
      onBlur,
      indeterminate,
      closeOnItemClick,
      onOpen,
      ...elementProps
    } = rest;

    return (
      <DropdownSelectButton
        ref={ref}
        className="dropdown-select__button"
        // rework
        theme={theme}
        size={size}
        fullWidth={fullWidth}
        showClearButton={clearable && !isNil(selectedOption)}
        opened={opened}
        disabled={disabled}
        loading={loading}
        quietLoading={quietLoading}
        onClickClear={this.handleClickClearButton}
        {...renderProps}
        {...elementProps}
      >
        {this.renderValueName(selectedOption)}
      </DropdownSelectButton>
    );
  }

  // To prevent dropdown button lost focus on select menu item
  handleMenuMouseDown = (e) => {
    if (e.button !== 0) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
  };

  handleBlurButton = () => {
    const {
      name,
      value,
      onBlur,
    } = this.props;

    if (onBlur) {
      onBlur({target: {name, value}});
    }
  };

  renderMenu = () => {
    const {
      value,
      menuFooterComponent,
    } = this.props;

    const {
      withIcons,
      processedOptions,
    } = this.processedOptions;

    if (processedOptions.length === 0) {
      return this.renderNoOptionsMessage();
    }

    return (
      <div
        className="dropdown-select__content"
        onMouseDown={this.handleMenuMouseDown}
      >
        {this.renderDropdownMenu(processedOptions, value, withIcons)}

        {menuFooterComponent && (
          <div className="dropdown-select__footer">
            {menuFooterComponent}
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      fullWidth,
      disabled,
      loading,
      appendToBody,
      placement,
      onOpen,
    } = this.props;

    this.processedOptions = this.processOptionsList();

    const {
      selectedOption,
    } = this.processedOptions;

    return (
      <Dropdown
        ref={this.dropdownRef}
        content={this.renderMenu}
        fullWidth={fullWidth}
        disabled={disabled || loading}
        appendToBody={appendToBody}
        interactive={true}
        placement={placement}
        onBlur={this.handleBlurButton}
        onOpen={onOpen}
      >
        {({
          ref,
          fullWidth,
          opened,
          ...rest
        }) => this.renderButton({
          ref,
          selectedOption,
          fullWidth,
          opened,
          ...rest
        })}
      </Dropdown>
    );
  }
}
