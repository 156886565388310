import React from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';

export const Row: React.FC<ClassName> = ({
  className,
  children,
}) => {
  return (
    <div className={classNames('streaming-destination-settings-row', className)}>
      {children}
    </div>
  );
};
