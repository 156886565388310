import React, {useCallback} from 'react';
import classNames from 'classnames';
import {Button} from 'app/components/sharedReactComponents/Button';
import {SIZE} from 'app/constants';
import {Caret, CARET_VARIANT} from 'app/components/sharedReactComponents/Caret';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  opened: boolean;
  size?: SIZE;
  onClick: Callback;
}

export const DetailsToggler: React.FC<Props> = ({
  className,
  opened,
  size,
  onClick,
  ...elementProps
}) => {
  const handleClick = useCallback(event => {
    event.stopPropagation();

    if (typeof onClick === 'function') {
      onClick();
    }
  }, [onClick]);

  return (
    <Button
      className={classNames('details-toggler', className)}
      title={`${opened ? 'Close' : 'Open'} details`}
      onClick={handleClick}
      {...elementProps}
    >
      <Caret
        className="details-toggler__caret"
        // rework remove !
        size={size}
        opened={opened}
        variant={CARET_VARIANT.THIN}
      />
    </Button>
  );
};
