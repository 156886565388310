import React from 'react';
import {
  THEME,
  VARIANT,
} from 'app/constants';
import {TranscriptionButtonComponent, TranscriptionButtonProps} from 'app/components/sharedReactComponents/StartStopTranscriptionButton/TranscriptionButtonComponent';

export const StopTranscriptionButton: React.FC<TranscriptionButtonProps> = ({
  theme = THEME.DANGER,
  variant = VARIANT.SOLID,
  children = 'Stop transcription',
  ...props
}) => {
  return (
    <TranscriptionButtonComponent
      theme={theme}
      variant={variant}
      {...props}
    >
      {children}
    </TranscriptionButtonComponent>
  );
};
