import {isParticipantStreamSettings} from 'app/types/guards/connect';

type SuccessResult = {result: true};
type ErrorResult = {
  result: false;
  error: string;
};

type ValidationResult = SuccessResult | ErrorResult;

function validateNotEmpty(value: string | number, error: string): ValidationResult {
  if (typeof value === 'number') {
    return value > 0 ? {result: true} : {result: false, error};
  }

  return value.length > 0 ? {result: true} : {result: false, error};
}

export function validateStreamSettings(settings: App.Connect.StreamSettings): ValidationResult {
  const checks: ValidationResult[] = [];

  if (isParticipantStreamSettings(settings) && settings.integration) {
    checks.push(validateNotEmpty(settings.cloud.deviceId, 'Device is not selected'));
    checks.push(validateNotEmpty(settings.cloud.sourceId, 'Input is not selected'));
  }

  const failed = checks.filter((c) => !c.result) as ErrorResult[];

  if (failed.length > 0) {
    return {result: false, error: failed.map((f) => f.error).join('. ')};
  }

  return {result: true};
}

export function getValidNumber(input: string, limit: number, fallback: number): number {
  const casted = Number(input);

  if (Number.isSafeInteger(casted) && casted <= limit) {
    return casted;
  }

  return fallback;
}
