import React from 'react';
import {observer} from 'mobx-react';
import {StreamStatusHeader} from 'app/components/pages/MeetingDetails/StreamStatus/StreamStatusHeader/StreamStatusHeader';
import {useStreamStatus} from 'app/components/pages/MeetingDetails/StreamStatus/useStreamStatus';
import {LoadingPlaceholder} from 'app/components/sharedReactComponents/LoadingPlaceholder';
import {Meeting} from 'app/store/models/connect/Meeting/Meeting';
import {StreamStatusDetails} from 'app/components/pages/MeetingDetails/StreamStatus/StreamStatusDetails/StreamStatusDetails';
import {SrtStream} from 'app/store/models/connect/SrtStream/SrtStream';
import {Callback} from 'app/types/common';
import {isNil} from 'app/util/isNil';
import {Stack} from '@mui/material';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {palette} from 'app/themes/app';

interface Props {
  meeting: Meeting;
  participantId: string;
  name: string;
  state: App.Connect.ParticipantState;
  type: App.Connect.MemberType;
  share: boolean;
  video: boolean;
  audio: boolean;
  stream: SrtStream;
  onMute: Callback<Promise<void>, [App.Connect.StreamMute]>;
  onStop: Callback<Promise<void>>;
}

export const StreamStatus = observer(
  ({
    meeting,
    name,
    participantId,
    state,
    stream,
    type,
    audio,
    share,
    video,
    onMute,
    onStop,
  }: Props) => {
    const {loading, status} = useStreamStatus({
      meetingId: meeting.id,
      participantId,
      disabled: !stream.running,
    });

    const renderDetails = () => {
      if (isNil(status)) {
        return null;
      }

      if (loading) {
        return <LoadingPlaceholder />;
      }

      return (
        <StreamStatusDetails
          source={participantId ? 'participant' : 'return-feed'}
          running={stream.running}
          joined={state === 'joined'}
          streamStatus={status}
          platform={meeting.platform}
        />
      );
    };

    const showConfigMessage = !isNil(status) && !loading;

    return (
      <div className="stream-status" data-id="srt_status_panel">
        <StreamStatusHeader
          name={name}
          type={type}
          audio={audio}
          video={video}
          share={share}
          disconnected={state === 'left-with-stream'}
          stream={stream}
          callerUrl={status?.callerAddress ?? ''}
          onMute={onMute}
          onStop={onStop}
        />

        {renderDetails()}

        {showConfigMessage && (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            fontSize={12}
            mt={2}
            color={palette.gray3}
          >
            {Icons.info().size(SIZE.S).reactComponent()}
            <span>To change audio and video settings, you'll need to stop the SRT connection</span>
          </Stack>
        )}
      </div>
    );
  },
);
