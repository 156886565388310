import React, {useEffect, useState} from 'react';
import {pairRealDevice} from 'app/components/PairDevice/actions';
import {cancelDevicePairingAction} from 'app/components/OneClickDevicePairing/utils';
import {OneClickDevicePairingLoading} from 'app/components/OneClickDevicePairing/OneClickDevicePairingLoading';
import {PairDeviceContainer} from 'app/components/sharedReactComponents/PairDeviceContainer';
import {OneClickPairDeviceForm} from 'app/components/OneClickDevicePairing/PairDeviceForm';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {useTeamBilling} from 'app/components/BillingManager/hooks/useTeamBilling';
import {useCloudEntities} from 'app/components/entities/cloud';

interface Props {
  deviceId: string;
  teamId: string;
  groupsAccess: boolean;
}

export function OneTeam({deviceId, teamId, groupsAccess}: Props) {
  const {
    user: {role},
  } = useCloudEntities();

  const {cloud} = useTeamBilling();

  const [loading, setLoading] = useState(true);
  const [pairedDevicesCount, setPairedDevicesCount] = useState(0);
  const [confirmedWarningMessage, setConfirmedWarningMessage] = useState(false);

  const handleFormSubmit = async ({deviceGroupId}: {deviceGroupId?: string}) =>
    pairRealDevice({
      deviceId,
      deviceGroupId,
      currentTeamId: teamId,
      currentUserRole: role,
      onError: cancelDevicePairingAction,
    });

  useEffect(() => {
    const pair = async () => {
      if (cloud.free) {
        await pairRealDevice({
          deviceId,
          currentTeamId: teamId,
          currentUserRole: role,
          onError: cancelDevicePairingAction,
        });
      } else {
        const deviceNumbersResponse = await DeviceApiService.getPairedSummary();
        const pairedDevicesCount = deviceNumbersResponse.count;

        const maxDevices = cloud.tier.seats > 0 ? cloud.tier.seats : Number.MIN_SAFE_INTEGER;

        if (pairedDevicesCount < maxDevices) {
          await pairRealDevice({
            deviceId,
            currentTeamId: teamId,
            currentUserRole: role,
            onError: cancelDevicePairingAction,
          });
        } else {
          setPairedDevicesCount(pairedDevicesCount);
          setLoading(false);
        }
      }
    };

    void pair();
  }, [deviceId, cloud, teamId, role]);

  if (loading) {
    return <OneClickDevicePairingLoading />;
  }

  return (
    <PairDeviceContainer
      pairedCount={pairedDevicesCount}
      loading={false}
      teamTier={cloud.tier}
      seatMonthPrice={cloud.prices.seat}
      isUnlimitedProPlan={cloud.unlimited}
      confirmedWarningMessage={confirmedWarningMessage}
      canEditBilling={true}
      onConfirmMessage={() => setConfirmedWarningMessage(true)}
    >
      <OneClickPairDeviceForm
        teamId={teamId}
        hasGroups={groupsAccess}
        showCancelButton={confirmedWarningMessage}
        onCancel={() => setConfirmedWarningMessage(false)}
        onSubmit={handleFormSubmit}
      />
    </PairDeviceContainer>
  );
}
