import React, {Component} from 'react';
import classNames from 'classnames';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  note: Callback | React.ReactNode;
}
export class PropsListItemNote extends Component<Props> {
  render() {
    const {
      className,
      note,
    } = this.props;

    return (
      <div className={classNames('props-list__item-note', className)}>
        {typeof note === 'function' ? note() : note}
      </div>
    );
  }
}
