import React from 'react';
import {Box, Divider, Stack, Typography} from '@mui/material';
import {Button} from 'app/components/sharedReactComponents/Button';
import {VARIANT} from 'app/constants';
import {Sx} from 'app/types/common';
import {Icons} from 'app/util/icons';
import {Payment} from 'app/models/Payment';
import {palette} from 'app/themes/app';
import {Credits} from 'app/components/Settings/BillingAndUsage/PaymentInformation/Credits/Credits';
import {PaymentMethod} from 'app/components/Settings/BillingAndUsage/PaymentInformation/PaymentMethod/PaymentMethod';

interface Props extends Sx {
  payment: Payment;
  onEnterCoupon: Fn<void, [coupon: string]>;
  onEditPayment: Fn;
  onCancelRequest: Fn;
}

export function PaymentInformation({
  sx,
  payment,
  onEnterCoupon,
  onEditPayment,
  onCancelRequest,
}: Props) {
  return (
    <Box sx={sx}>
      <Stack direction="row" gap={3} alignItems="center" mb={2}>
        <Typography variant="h5" fontWeight={600}>
          Payment information
        </Typography>

        <Button
          dataId="enter_coupon_button"
          variant={VARIANT.LINK}
          onClick={() => onEnterCoupon('')}
        >
          {Icons.coupon().label('Enter a voucher code').reactComponent()}
        </Button>
      </Stack>

      <Stack
        direction="row"
        sx={{
          borderWidth: 1,
          borderRadius: 2,
          borderStyle: 'solid',
          borderColor: palette.grayMedium,
        }}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <PaymentMethod
          sx={{flex: 1}}
          payment={payment}
          onEditPayment={onEditPayment}
          onCancelPending={onCancelRequest}
        />

        <Credits sx={{flex: 1}} balance={payment.balance} cardPayment={payment.ccPayment} />
      </Stack>
    </Box>
  );
}
