import React from 'react';
import {IconButton, Paper, Stack, Tooltip, Typography} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {CopyButton} from 'app/components/shared';
import {Network} from 'app/components/entities/network';

const rootSx: StyleSx = {p: 1.5};

interface Props extends Sx {
  int: Network.Interface;
}

export function UnitInterface({sx, int}: Props) {
  return (
    <Paper data-id={int.id} sx={packSx(rootSx, sx)} variant="outlined">
      <Stack gap={0.5} direction="row" mb={0.5} alignItems="center">
        <FiberManualRecordIcon fontSize="small" color={int.active ? 'success' : 'error'} />

        <Typography fontWeight={600} fontSize={16}>
          {int.name}
        </Typography>

        {int.primary && (
          <Typography ml="auto" variant="caption">
            Primary
          </Typography>
        )}
      </Stack>

      <Typography>Status: {formatStatus(int.status)}</Typography>

      {int.ip && (
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography data-id="ip-address">IP address: {int.ip}</Typography>
          <CopyItem value={int.ip} />
        </Stack>
      )}

      {int.mac && (
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography data-id="mac-address">MAC address: {int.mac}</Typography>
          <CopyItem value={int.mac} />
        </Stack>
      )}
    </Paper>
  );
}

function CopyItem({value}: {value: string}) {
  return (
    <CopyButton value={value}>
      {({copy, copied}) => (
        <Tooltip title={copied ? 'Copied' : 'Copy'}>
          <IconButton data-id="copy-icon-btn" size="small" onClick={copy}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </CopyButton>
  );
}

function formatStatus(status: Network.InterfaceStatus): string {
  if (status === 'active') {
    return 'Active';
  }

  if (status === 'usb_unplugged') {
    return 'No USB-Ethernet adapter';
  }

  if (status === 'cable_disconnected') {
    return 'No cable connected';
  }

  if (status === 'not_configured') {
    return 'Not configured';
  }

  return 'Disabled';
}
