import {Edge} from 'app/domain/edge';
import {
  DevicePublisherModel,
  PublisherOptions,
} from 'app/models/Device/Publisher/DevicePublisherModel';

/**
 * @param {object} json
 * @param {object} [options]
 * @return {DevicePublisherModel}
 */
function createDevicePublisherModel(json: Edge.Publisher, options: PublisherOptions) {
  return new DevicePublisherModel(json, options);
}

export {createDevicePublisherModel};
