import React, {useMemo, useState} from 'react';
import {Box, Button, Dialog, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';
import {Edge} from 'app/domain/edge';
import {SelectPresetCard} from 'app/components/sharedReactComponents/DevicePresetCard/SelectPresetCard/SelectPresetCard';
import {useMounted} from 'app/hooks/useIsMounted';
import {Cloud} from 'app/domain/cloud';

type Props = {
  loading: boolean;
  model: Cloud.UnitModel;
  unitCount: number;
  presets: Edge.TeamPreset[];
  rebootWarning: boolean;
  onApply: (preset: Edge.TeamPreset) => Promise<void>;
  onClose: () => void;
};

function ApplyPresetToDevices({
  loading,
  presets,
  rebootWarning,
  model,
  unitCount,
  onApply,
  onClose,
}: Props) {
  const [selected, setSelected] = useState('');

  const modelPresets = useMemo(() => {
    return presets.filter((p) => p.device === model);
  }, [model, presets]);

  const handleApply = async () => {
    const preset = presets.find((p) => p.id === selected);

    if (preset) {
      await onApply(preset);
    }
  };

  const hasPresets = modelPresets.length > 0;

  const loadingRequired = useMemo(() => {
    const preset = modelPresets.find((p) => p.id === selected);

    if (!preset) {
      return false;
    }

    return preset.sections.filter((s) => s === 'network' || s === 'system').length > 0;
  }, [modelPresets, selected]);

  const showWarning = rebootWarning && loadingRequired;

  return (
    <Box p={3}>
      <Typography textAlign="center" variant="h5" mb={2}>
        Select preset to apply
      </Typography>

      {hasPresets ? (
        <>
          <Typography>
            Select{' '}
            <Typography color="secondary" component="span">
              {model}
            </Typography>{' '}
            preset
          </Typography>

          <Stack mt={1} gap={1}>
            {modelPresets.map((p) => (
              <SelectPresetCard
                dataId={p.id}
                key={p.id}
                preset={p}
                selected={p.id === selected}
                onSelect={() => setSelected(p.id)}
              />
            ))}
          </Stack>
        </>
      ) : (
        <Typography mt={1} color="text.disabled" textAlign="center">
          There are no{' '}
          <Typography color="secondary" component="span">
            {model}
          </Typography>{' '}
          configuration presets in the team library
        </Typography>
      )}

      <Box mt={0.5} height={24}>
        {showWarning && (
          <Typography color="warning.main">
            <strong>{unitCount > 1 ? `${unitCount} devices` : 'Device '} will be rebooted</strong>{' '}
            and temporarily go offline while applying preset
          </Typography>
        )}
      </Box>

      <Stack mt={2} direction="row" justifyContent="center" alignItems="center" gap={1}>
        <LoadingButton
          data-id="apply-preset-btn"
          loading={loading}
          variant="contained"
          color="secondary"
          disabled={!selected}
          disableRipple={false}
          onClick={handleApply}
        >
          Apply
        </LoadingButton>

        <Button
          data-id="cancel-preset-btn"
          variant="outlined"
          color="info"
          disableRipple={false}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Stack>
    </Box>
  );
}

type PromptProps = Prompt & Omit<Props, 'loading'>;

export function ApplyPresetToDevicesDialog({
  open,
  presets,
  rebootWarning,
  model,
  unitCount,
  onApply,
  onClose,
}: PromptProps) {
  const [loading, setLoading] = useState(false);
  const mounted = useMounted();

  const handleApply = async (preset: Edge.TeamPreset) => {
    if (preset) {
      try {
        setLoading(true);
        await onApply(preset);
      } finally {
        if (mounted()) {
          setLoading(false);
        }
      }
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true} onClose={onClose}>
      <ApplyPresetToDevices
        presets={presets}
        loading={loading}
        rebootWarning={rebootWarning}
        model={model}
        unitCount={unitCount}
        onApply={handleApply}
        onClose={onClose}
      />
    </Dialog>
  );
}
