import {useMemo} from 'react';
import dayjs from 'dayjs';
import {EventCategory} from 'app/components/sharedReactComponents/Events/types';
import {TimeStampMilliseconds, TimeStampSeconds} from 'app/types/common';
import {Schedule} from 'app/domain/schedule';

type GroupedByDay = Exclude<EventCategory, 'ongoing'>;

export type EventDayGroup = {
  title: string;
  events: Schedule.Event[];
};

type Return = Record<GroupedByDay, EventDayGroup[]>;

export function useScheduleDayGroups(
  scheduled?: Schedule.Event[],
  completed?: Schedule.Event[],
): Return {
  return useMemo<Return>(() => {
    const result: Return = {
      scheduled: getGroups(scheduled ?? []),
      completed: getGroups(completed ?? []),
    };

    return result;
  }, [scheduled, completed]);
}

function getHelperTimestamps(t: TimeStampMilliseconds) {
  const current = dayjs.unix(t);

  const now = dayjs();
  const today = now.startOf('day');
  const yesterday = today.subtract(1, 'day');
  const tomorrow = today.add(1, 'day');

  return {
    current,
    now,
    yesterday,
    tomorrow,
  };
}

function getGroupName(timestamp: TimeStampSeconds): string {
  const {current, now, tomorrow, yesterday} = getHelperTimestamps(timestamp);
  const name = dayjs.unix(timestamp).format('ll, dddd');

  if (current.isSame(tomorrow, 'day')) {
    return `Tomorrow, ${name}`;
  }

  if (current.isSame(yesterday, 'day')) {
    return `Yesterday, ${name}`;
  }

  if (current.isSame(now, 'day')) {
    return `Today, ${name}`;
  }

  return name;
}

function getGroups(events: Schedule.Event[]): EventDayGroup[] {
  const map = new Map<number, Schedule.Event[]>();

  events.forEach((event) => {
    const timestamp = dayjs.unix(event.start).startOf('day').unix();

    const groupEvents = map.get(timestamp);

    if (groupEvents) {
      groupEvents.push(event);
    } else {
      map.set(timestamp, [event]);
    }
  });

  const groups: EventDayGroup[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const [timestamp, items] of map) {
    groups.push({
      title: getGroupName(timestamp),
      events: items,
    });
  }

  return groups;
}
