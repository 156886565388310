import React, {useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogProps, DialogTitle} from '@mui/material';
import {Prompt} from 'app/types/common';
import {LoadingButton} from '@mui/lab';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {NamesList} from 'app/components/sharedReactComponents/ItemsEnumeration';
import {useMounted} from 'app/hooks/useIsMounted';

type Props = {
  loading: boolean;
  names: string[];
  onReboot: () => Promise<void>;
  onClose: () => void;
};

function RebootDevices({loading, names, onReboot, onClose}: Props) {
  return (
    <Box>
      <DialogTitle>
        Reboot <NamesList variant="inherit" color="primary.main" names={names} max={3} />{' '}
        {pluralizeN('device', names.length)}
      </DialogTitle>

      <DialogActions>
        <LoadingButton
          data-id="confirm-reboot-btn"
          loading={loading}
          variant="contained"
          color="secondary"
          disableRipple={false}
          onClick={onReboot}
        >
          Reboot
        </LoadingButton>

        <Button
          data-id="cancel-reboot-btn"
          variant="outlined"
          color="info"
          disableRipple={false}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
}

type PromptProps = Prompt &
  Omit<Props, 'loading'> &
  Pick<DialogProps, 'maxWidth' | 'fullWidth' | 'keepMounted'>;

export function RebootDevicesDialog({open, names, onReboot, onClose, ...props}: PromptProps) {
  const mounted = useMounted();
  const [loading, setLoading] = useState(false);

  const handleReboot = async () => {
    try {
      setLoading(true);
      await onReboot();
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{sx: {minWidth: 400}}} {...props}>
      <RebootDevices loading={loading} names={names} onReboot={handleReboot} onClose={onClose} />
    </Dialog>
  );
}
