import React, {VFC} from 'react';
import {PageHeader} from 'app/components/sharedReactComponents/PageHeader';
import {TokensPanel} from 'app/components/Settings/ApiTokens/TokensPanel';

interface Props {
  teamId: string;
}

export const ApiTokens: VFC<Props> = ({teamId}) => (
  <div>
    <PageHeader
      title="API tokens"
    />

    <div className="container api-tokens__container">
      <TokensPanel
        className="api-tokens__tokens"
        teamId={teamId}
      />
    </div>
  </div>
);
