import React from 'react';
import classNames from 'classnames';
import {Callback, ClassName, DataId} from 'app/types/common';
import {Button} from 'app/components/sharedReactComponents/Button';
import {SIZE} from 'app/constants';

interface Props extends ClassName, DataId {
  icon?: React.ReactNode;
  summary: number;
  label: React.ReactNode;
  title: string;
  onClick: Callback;
}

export const SummaryButton = ({className, dataId, icon, label, summary, title, onClick}: Props) => {
  if (!summary) {
    return null;
  }

  return (
    <Button
      className={classNames('summary-button', className)}
      title={title}
      size={SIZE.S}
      dataId={dataId}
      onClick={onClick}
    >
      {icon && <span className="summary-button__icon">{icon}</span>}

      <span>{label}</span>
    </Button>
  );
};
