import React, {ComponentProps, useState} from 'react';
import {FileRejection} from 'react-dropzone';
import axios from 'axios';
import {Sx} from 'app/types/common';
import {ApiResponseNumeralStatus} from 'app/data/Status';
import {PresetApiService} from 'app/services/api/preset/PresetApiService';
import {isErrorResponse} from 'app/api/types';
import {PresetUploader} from 'app/components/sharedReactComponents/PresetUploader/PresetUploader/PresetUploader';

const ERROR_MESSAGE = {
  DEFAULT: 'File format is not recognized. Please select a preset file.',
  ALREADY_UPLOADED: 'This preset has already been loaded',
} as const;

type UploaderProps = Pick<ComponentProps<typeof PresetUploader>, 'size'>;

interface Props extends Sx, UploaderProps {
  onUpload: (id: string) => void;
  onAlreadyUploaded?: (id: string) => void;
}

export function DevicePresetUploader({sx, size, onUpload, onAlreadyUploaded}: Props) {
  const [fileName, setFileName] = useState('');
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const handleDropFile = async (files: File[]) => {
    const file = files[0];

    if (!file) {
      return;
    }

    setErrorMessage('');
    setProgress(0);
    setFileName(file.name);

    const formData = new FormData();

    formData.append('file', file);

    try {
      const response = await PresetApiService.uploadPresetFile(formData, {
        onUploadProgress: (event: ProgressEvent) => {
          if (event.lengthComputable) {
            const value = event.loaded / event.total;
            setProgress(Math.floor(value * 100));
          }
        },
      });

      setFileName('');
      setProgress(0);
      setErrorMessage('');

      onUpload(response.id);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const {response} = error;

        if (response?.status === ApiResponseNumeralStatus.Conflict) {
          setErrorMessage(ERROR_MESSAGE.ALREADY_UPLOADED);

          if (isErrorResponse(response) && typeof response.data.ErrorData?.id === 'string') {
            onAlreadyUploaded?.(response.data.ErrorData?.id);
          }
        } else {
          setErrorMessage(ERROR_MESSAGE.DEFAULT);
        }
      }
    }
  };

  const handleClickReset = () => {
    setFileName('');
    setProgress(0);
    setErrorMessage('');
  };

  const handleDropRejected = (fileRejections: FileRejection[]) => {
    const rejection = fileRejections[0];

    if (rejection) {
      if (rejection && rejection.errors.length > 0) {
        setFileName(rejection.file.name);
        setErrorMessage(ERROR_MESSAGE.DEFAULT);
      }
    }
  };

  return (
    <PresetUploader
      sx={sx}
      fileName={fileName}
      errorMessage={errorMessage}
      progress={progress}
      size={size}
      onDropFile={handleDropFile}
      onDropRejected={handleDropRejected}
      onClickReset={handleClickReset}
    />
  );
}
