import React from 'react';
import {Component, Sx} from 'app/types/common';
import {Box, Typography} from '@mui/material';

interface Props extends Component, Sx {
  value: string;
  message: React.ReactNode;
}

export function Price({sx, value, message, dataId}: Props) {
  return (
    <Box data-id={dataId} sx={sx} component="span">
      <Typography data-id="price-value" variant="h5" fontWeight={600} component="span">
        {value}
      </Typography>
      <Typography data-id="price-message" fontSize="16px" fontWeight={600} component="span">
        {message}
      </Typography>
    </Box>
  );
}
