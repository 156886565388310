import React from 'react';
import {FileRejection, useDropzone} from 'react-dropzone';
import {Box, Button, LinearProgress, Stack, SxProps, Theme, Typography, alpha} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {blue, grey, red} from '@mui/material/colors';
import {Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {LegacyIcon} from 'app/components/sharedReactComponents/LegacyIcon/LegacyIcon';
import {Icons} from 'app/util/icons';

const rootSx: SxProps<Theme> = {
  p: 1,
  border: 1,
  borderStyle: 'dashed',
  borderRadius: 1,
  borderColor: grey[500],
  cursor: 'pointer',
  outline: 'none',
  '&:focus': {boxShadow: `0 0 0 2px ${alpha(grey[500], 0.4)}`},
};

interface Props extends Sx {
  fileName: string;
  errorMessage: string;
  progress: number;
  size: 'small' | 'large';
  onDropFile: (files: File[]) => void;
  onDropRejected: (rejected: FileRejection[]) => void;
  onClickReset: () => void;
}

export function PresetUploader({
  fileName,
  errorMessage,
  progress,
  size,
  onDropFile,
  onClickReset,
  onDropRejected,
}: Props) {
  const {open, getRootProps, getInputProps} = useDropzone({
    accept: {'text/plain': ['.preset']},
    multiple: false,
    noDragEventsBubbling: true,
    onDrop: onDropFile,
    onDropRejected: onDropRejected,
  });

  const renderName = () => {
    if (fileName) {
      if (errorMessage) {
        return (
          <Box color="error.main">
            <Typography textAlign="center">
              Error uploading file{' '}
              <Typography component="span" fontWeight={600}>
                <OverflowTooltip component="span">{fileName}</OverflowTooltip>
              </Typography>
            </Typography>

            <Typography textAlign="center">{errorMessage}</Typography>
          </Box>
        );
      }

      return (
        <Stack direction="row" alignItems="center" gap={1}>
          <LegacyIcon>{Icons.preset().reactComponent()}</LegacyIcon>
          <Typography component="span" fontWeight={600}>
            <OverflowTooltip component="span">{fileName}</OverflowTooltip>
          </Typography>{' '}
        </Stack>
      );
    }

    return (
      <Typography component="span">
        Drop configuration preset file here or{' '}
        <Typography component="span" color="primary.main">
          click to select it
        </Typography>
      </Typography>
    );
  };

  const renderActions = () => {
    if (errorMessage) {
      return (
        <Button size="large" variant="outlined" color="error" onClick={onClickReset}>
          Upload another file
        </Button>
      );
    }

    if (fileName) {
      return (
        <Stack direction="row" alignItems="center" gap={2}>
          <LinearProgress
            sx={{width: 110}}
            color="primary"
            variant="determinate"
            value={progress}
          />
          <Typography>Loading...</Typography>
        </Stack>
      );
    }

    return null;
  };

  return (
    <Stack
      data-id="preset_uploader"
      sx={packSx(rootSx, {
        minHeight: size === 'small' ? 60 : 130,
        bgcolor: alpha(errorMessage ? red[50] : blue[50], 0.3),
      })}
      component="section"
      {...getRootProps()}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      onClick={open}
    >
      <input {...getInputProps()} />

      <Stack flex={1} direction="row" gap={1}>
        <AddIcon color="primary" />
        <Typography color={grey[600]}>Add new preset</Typography>
      </Stack>

      <Box>{renderName()}</Box>

      <Stack direction="row" justifyContent="flex-end" alignItems="center" flex={1}>
        {renderActions()}
      </Stack>
    </Stack>
  );
}
