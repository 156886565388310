/* eslint-disable react/default-props-match-prop-types */
import React, {Component} from 'react';
import classNames from 'classnames';
import {List} from 'app/components/Settings/StreamingDestinations/NewStreamingDestinationTypeSelector/List';
import {Tiles} from 'app/components/Settings/StreamingDestinations/NewStreamingDestinationTypeSelector/Tiles';
import {NEW_STREAMING_DESTINATION_TYPE_SELECTOR_VARIANT} from 'app/components/Settings/StreamingDestinations/NewStreamingDestinationTypeSelector/constants';
import {streamingServiceComparator} from 'app/util/comparators/streamingServiceComparator';
import {Callback, ClassName} from 'app/types/common';
import {streamingServicesRepository} from 'app/components/StreamingServices';

interface Props extends ClassName {
  variant: NEW_STREAMING_DESTINATION_TYPE_SELECTOR_VARIANT;
  createServiceAction: Callback;
}

export class NewStreamingDestinationTypeSelector extends Component<Props> {
  static defaultProps = {
    variant: NEW_STREAMING_DESTINATION_TYPE_SELECTOR_VARIANT.LIST,
  };

  // Enabled services first
  _services = streamingServicesRepository
    .getAvailableToCreateServices()
    .sort(streamingServiceComparator);

  render() {
    const {className, variant, createServiceAction} = this.props;

    // TODO: refactor Tiles and List components and create universal component for these
    return (
      <div className={classNames('new-streaming-destination-type-selector', className)}>
        <div className="new-streaming-destination-type-selector__title">
          Add new streaming destination
        </div>

        {variant === NEW_STREAMING_DESTINATION_TYPE_SELECTOR_VARIANT.TILE ? (
          <Tiles
            className="new-streaming-destination-type-selector__tile"
            services={this._services}
            createServiceAction={createServiceAction}
          />
        ) : (
          <List
            className="new-streaming-destination-type-selector__list"
            services={this._services}
            createServiceAction={createServiceAction}
          />
        )}
      </div>
    );
  }
}
