import React from 'react';
import {Form} from 'app/components/Login/LoginForm/Form';
import {AUTH_STATE} from 'app/components/Login/LoginForm/constants';
import Auth from '@aws-amplify/auth';
import {Callback} from 'app/types/common';
import {noop} from 'app/util/noop';

function errorProcessor(error) {
  switch (error.code) {
    case 'UserNotFoundException':
      return {
        name: 'email',
        message: 'User not found',
      };
    case 'LimitExceededException':
      return {
        name: 'common',
        message: 'Attempt limit exceeded, please try after some time'
      };
    default:
      return {
        name: 'common',
        message: error.message,
      };
  }
}

interface Props {
  authState: AUTH_STATE;
  switchStateToResetPassword: Callback;
}

// rework
export const ForgotPassword = ({
  authState,
  switchStateToResetPassword,
}: Props) => {
  const fields = [
    {
      name: 'email',
      label: 'Your email',
      type: 'email',
      required: true,
    },
  ];

  const submitAction = async (data) => {
    return Auth.forgotPassword(data.email)
      .then(() => {
        switchStateToResetPassword(data.email);
      });
  };

  if (authState !== AUTH_STATE.FORGOT_PASSWORD) {
    return null;
  }

  return (
    <Form
      fields={fields}
      buttonText="Send Code"
      descriptionComponent="Enter your email address to reset your password. If we have an account registered to your email, an email will be sent with further instructions."
      errorProcessor={errorProcessor}
      submitAction={submitAction}
      // rework
      onSubmit={noop}
    />
  );
};
