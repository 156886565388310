import React, {Fragment} from 'react';
import {Form} from 'app/components/Login/LoginForm/Form';
import Auth from '@aws-amplify/auth';
import {
  AUTH_STATE,
  PASSWORD_REQUIREMENTS_MESSAGE,
  PASSWORD_MIN_LENGTH,
} from 'app/components/Login/LoginForm/constants';
import {Separator} from 'app/components/Login/LoginForm/Separator';
import {FORM_CONTROL_AUTOCOMPLETE} from 'app/constants/formControlAutocomplete';
import {Callback} from 'app/types/common';

function errorProcessor(error) {
  if (typeof error === 'string') {
    return {
      name: 'common',
      message: error,
    };
  }

  switch (error.code) {
    case 'InvalidParameterException':
      if (/password/.test(error.message)) {
        return {
          name: 'password',
        };
      }

      return {
        name: 'username',
      };
    case 'InvalidPasswordException':
      return {
        name: 'password',
      };
    case 'UsernameExistsException':
      return {
        name: 'username',
        message: 'An account with the given email already exists',
      };
    default:
      return {
        name: 'common',
        message: error.message,
      };
  }
}

interface Props {
  authState: AUTH_STATE;
  switchStateToConfirmSignUp: Callback;
}

export const SignUp = ({
  authState,
  switchStateToConfirmSignUp,
}: Props) => {
  const fields = [
    {
      name: '__name_group__',
      controls: [
        {
          name: 'name',
          label: 'First name',
          autoComplete: FORM_CONTROL_AUTOCOMPLETE.GIVEN_NAME,
        },
        {
          name: 'family_name',
          label: 'Last name',
          autoComplete: FORM_CONTROL_AUTOCOMPLETE.FAMILY_NAME,
        },
      ],
    },
    {
      name: 'username',
      label: 'Email',
      autoComplete: FORM_CONTROL_AUTOCOMPLETE.USERNAME,
      type: 'email',
      required: true,
    },
    {
      name: 'password',
      label: 'Password',
      autoComplete: FORM_CONTROL_AUTOCOMPLETE.NEW_PASSWORD,
      type: 'password',
      minLength: PASSWORD_MIN_LENGTH,
      helperText: PASSWORD_REQUIREMENTS_MESSAGE,
      required: true,
    },
  ];

  const submitAction = async (data) => {
    const attributes: any = {
      email: data.username,
    };

    if (data.name) {
      attributes.name = data.name;
    }

    if (data.family_name) {
      // eslint-disable-next-line camelcase
      attributes.family_name = data.family_name;
    }

    return Auth.signUp({
      username: data.username,
      password: data.password,
      attributes,
      validationData: [],
    })
      .then((data: any) => {
        switchStateToConfirmSignUp(data.user.username);
      });
  };

  if (authState !== AUTH_STATE.SIGN_UP) {
    return null;
  }

  return (
    <Fragment>
      <Separator/>

      <Form
        fields={fields}
        buttonText="Sign up"
        errorProcessor={errorProcessor}
        submitAction={submitAction}
        onSubmit={() => ({})}
      />
    </Fragment>
  );
};
