import {WSDeviceAlsDto} from 'app/api/WebSocket/types';
import {WS} from 'app/api/WebSocket/WS';
import {useDeviceId} from 'app/hooks/Device/useDeviceId';
import {useDeviceStatusStartCommand} from 'app/hooks/Device/useDeviceStatusStartCommand';
import {DeviceCommandDispatcher} from 'app/util/CommandDispatcher';
import {secondsToMilliseconds} from 'app/util/timeConverter';
import {useEffect, useRef, useState} from 'react';

interface AudioLevels {
  valueL?: number;
  valueR?: number;
}

/**
 * id: {masterId}-{channelIdx}?
 */
interface Params {
  id: string;
  disabled: boolean;
  sourceId?: string;
}

const sourceGetter = (
  source?: string,
  channelIdx?: string,
) => source ? source : channelIdx ? `CHANNEL${channelIdx}` : 'master';

const RESET_ON_IDLE_MS = secondsToMilliseconds(5);

export function useDeviceAudioLevels({
  id,
  sourceId,
  disabled,
}: Params): AudioLevels {
  const [master, channelIdx] = useDeviceId(id);
  const [state, setState] = useState<AudioLevels>({});
  const [source, setSource] = useState(() => sourceGetter(sourceId, channelIdx));
  const idleTimeout = useRef<number>();

  useDeviceStatusStartCommand({
    command: async () => DeviceCommandDispatcher.audioLevels(master),
    disabled,
  });

  useEffect(() => {
    setSource(sourceGetter(sourceId, channelIdx));
  }, [sourceId, channelIdx]);

  useEffect(() => {
    if (disabled) {
      return;
    }

    function handleDeviceALS(socketData: WSDeviceAlsDto) {
      const data = socketData.Body.Data || {};

      const [valueL, valueR] = data[source] ?? [];

      clearTimeout(idleTimeout.current);

      idleTimeout.current = window.setTimeout(() => {
        setState({});
      }, RESET_ON_IDLE_MS);

      setState({
        valueL,
        valueR,
      });
    }

    WS.onDeviceALS(handleDeviceALS, master);

    return () => {
      clearTimeout(idleTimeout.current);
      setState({});
      WS.offDeviceALS(handleDeviceALS, master);
    };
  }, [disabled, source, master]);

  return state;
}
