import React from 'react';
import {Modal} from 'app/components/sharedReactComponents/Modal/Modal';
import {ErrorModal} from 'app/components/sharedReactComponents/Modal/ErrorModal';
import {PseudoLink} from 'app/components/sharedReactComponents/PseudoLink';
import {router} from 'app/router/main';
import {AnyUserRoleModel} from 'app/models/PermissionsModel/types';
import {Callback} from 'app/types/common';

export const PairingFailedBillingPlanErrorModal = {
  show({
    currentUserRole,
    onClose,
  }: {
    currentUserRole: AnyUserRoleModel;
    onClose?: Callback;
  }) {
    let fireOnClose = true;

    ErrorModal.show({
      title: 'Pairing failed',
      message: (
        <div>
          The maximum number of paired devices supported for this team has been reached.<br/>
          Unpair any unused or offline devices and try again - or

          {' '}

          {currentUserRole.canEditBilling() ? (
            <PseudoLink
              onClick={() => {
                fireOnClose = false;
                Modal.hide();
                router.go('settingBillingUsage');
              }}
            >
              upgrade your team plan.
            </PseudoLink>
          ) : (
            <span>upgrade your team plan.</span>
          )}
        </div>
      ),
      onClose: () => {
        if (fireOnClose && onClose) {
          onClose();
        }
      },
    });
  },
};
