import {isNil} from 'app/util/isNil';

/**
 * Check if something is a promise
 *
 * ```typescript
 * isPromise(new Promise(() => {}); //=> true
 * isPromise(() => {}); //=> false
 * ```
 */
export function isPromise(value?: any): boolean {
  return !isNil(value) && typeof value.then === 'function';
}
