import React, {ReactNode} from 'react';
import {
  Modal,
  ModalContainer,
} from 'app/components/sharedReactComponents/Modal/Modal';
import {SIZE} from 'app/constants';

export const ModalNotification = {
  show({
    message,
  }: {
    message: ReactNode;
  }) {
    Modal.show({
      className: 'modal-notification',
      showCloseButton: false,
      maxWidth: SIZE.XXL,
      children: (
        <ModalContainer>
          <div className="modal-notification__message">
            {message}
          </div>
        </ModalContainer>
      )
    });
  }
};
