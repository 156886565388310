import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {AddZoomAccount} from 'app/components/Settings/ConnectSettings/ZoomAccounts/AddZoomAccount/AddZoomAccount';
import {ZoomAccount} from 'app/components/Settings/ConnectSettings/ZoomAccounts/ZoomAccount/ZoomAccount';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {MeetingsApiService} from 'app/services/api/meetings/MeetingsApiService';

interface Props extends Sx {
  accounts: App.Connect.Auth.ZoomAccount[];
  teamName: string;
  userId: string;
  canManage: boolean;
  reload: Fn<Promise<any>>;
  onPair: Fn;
}

export function ZoomAccounts({sx, userId, accounts, teamName, canManage, reload, onPair}: Props) {
  const hasAccounts = accounts.length > 0;

  const handleShare = async (id: string, share: boolean) => {
    await MeetingsApiService.shareZoom(id, {share_account: share});
    await reload();
  };

  const handleSignOut = async (id: string) => {
    await MeetingsApiService.signOutZoom(id);
    await reload();
  };

  const renderContent = () => {
    if (hasAccounts) {
      return (
        <Stack gap={2} mb={2}>
          {accounts.map((a) => (
            <ZoomAccount
              key={a.id}
              account={a}
              userId={userId}
              canManage={canManage}
              teamName={teamName}
              onShare={handleShare}
              onSignOut={handleSignOut}
            />
          ))}
          {canManage && (
            <Box>
              <Button
                dataId="connect-zoom"
                variant={VARIANT.SOLID}
                theme={THEME.SUCCESS}
                onClick={onPair}
              >
                Add new account
              </Button>
            </Box>
          )}
        </Stack>
      );
    }

    return <AddZoomAccount sx={{mt: 2}} canManage={canManage} onAdd={onPair} />;
  };

  return (
    <Box sx={sx}>
      <Typography fontWeight="bold" fontSize={18} mb={1}>
        Zoom accounts
      </Typography>

      {hasAccounts && (
        <Typography mb={3}>
          To join a Zoom meeting that requires all participants to be authenticated, you must add a
          valid <br /> Zoom account for Epiphan Connect to use.
        </Typography>
      )}

      {renderContent()}
    </Box>
  );
}
