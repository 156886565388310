import React from 'react';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';

interface Props {
  size: SIZE;
}

export const StatusImageLoading: React.FC<Props> = ({size}) => (
  <div className="status-image-loading">
    {Icons.spinner().size(size).reactComponent()}
  </div>
);
