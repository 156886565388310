import {useEffect, useState} from 'react';
import {useCloudEntities} from 'app/components/entities/cloud';
import {useCurrentTeamStore} from 'app/store/hooks';

interface Args {
  enabled: boolean;
}

export function useAppcuesTracker({enabled}: Args) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const script = document.createElement('script');
    script.src = '//fast.appcues.com/128060.js';

    window.AppcuesSettings = {
      enableURLDetection: true,
    };

    script.onload = () => {
      setReady(true);
    };

    document.body.appendChild(script);

    return () => {
      setReady(false);
      document.body.removeChild(script);
    };
  }, [enabled]);

  const team = useCurrentTeamStore();
  const {user} = useCloudEntities();

  const userId = user.id;
  const userEmail = user.email;
  const role = user.role.name;

  const teamId = team.id;

  useEffect(() => {
    if (ready) {
      window.Appcues.group(teamId, {});

      window.Appcues.identify(userId, {
        userEmail,
        teamId,
        role,
      });

      return () => {
        window.Appcues.reset();
      };
    }
  }, [ready, teamId, userId, userEmail, role]);
}
