import {useCallback, useMemo, useState} from 'react';
import {containsSearchString} from 'app/components/FleetManager/utils';
import {FilterSwitch} from 'app/components/sharedReactComponents/FilterSelector/types';

interface Props {
  filterSwitches: FilterSwitch[];
  searchable: boolean;
}

function useFilterSelectorGroupSearch({
  filterSwitches,
  searchable,
}: Props) {
  const [searchText, setSearchText] = useState('');

  const onSearch = useCallback(({target: {value}}) => {
    setSearchText(value);
  }, []);

  const searchedFilterSwitches = useMemo(() => {
    if (searchable !== true || searchText === '') {
      return filterSwitches;
    }

    return filterSwitches.filter(filterSwitch => containsSearchString(filterSwitch.label, searchText));
  }, [searchable, searchText, filterSwitches]);

  return {
    searchText,
    onSearch,
    searchedFilterSwitches,
  };
}

export {
  useFilterSelectorGroupSearch,
};
