import {BillingApiService} from 'app/services/api/billing/BillingApiService';
import {useQuery} from '@tanstack/react-query';
import {BillingMapper} from 'app/services/mappers/BillingMapper';

interface Args {
  enabled: boolean;
  teamId: string;
}

export function useConnectReport({enabled, teamId}: Args) {
  return useQuery({
    queryKey: ['connect-report', teamId],
    queryFn: () => BillingApiService.getConnectSubscriptionReport(teamId),
    enabled,
    select: BillingMapper.mapConnectReport,
  });
}
