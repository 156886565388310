import React, {forwardRef, useCallback, useImperativeHandle, useRef} from 'react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import type {Instance} from 'tippy.js';
import {THEME} from 'app/constants';
import {isString} from 'app/util/typeGuards';

/**
 * {@link https://atomiks.github.io/tippyjs/v6/all-props/#placement}
 * @enum {string}
 */
export enum TOOLTIP_PLACEMENT {
  TOP = 'top',
  TOP_END = 'top-end',
  TOP_START = 'top-start',
  RIGHT = 'right',
  LEFT = 'left',
  RIGHT_START = 'right-start',
  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
}

interface Props {
  content?: React.ReactNode | React.ReactNode[];
  // Enable tooltip pointer events. Use with `trigger="click"`
  interactive?: boolean;
  trigger?: string;
  children: any;

  // Show/Hide delay
  delay?: any;
  placement?: TOOLTIP_PLACEMENT;
  theme?: THEME;
  preformatted?: boolean;
}

export const Tooltip = forwardRef<any, Props>(
  (
    {
      content,
      interactive = false,
      children,
      trigger = 'mouseenter focus',
      delay,
      placement = TOOLTIP_PLACEMENT.TOP,
      theme,
      preformatted = false,
    },
    ref,
  ) => {
    const tooltipRef = useRef<Instance>();

    useImperativeHandle(ref, () => ({
      show() {
        if (tooltipRef.current) {
          tooltipRef.current.show();
        }
      },

      hide() {
        if (tooltipRef.current) {
          tooltipRef.current.hide();
        }
      },
    }));

    const handleTooltipCreate = useCallback((ref: Instance) => {
      tooltipRef.current = ref;
    }, []);

    if (!content) {
      return children;
    }

    return (
      <Tippy
        content={content}
        theme={classNames('cr', {
          [`${theme}`]: Boolean(theme),
          interactive,
          preformatted,
        })}
        animation="shift-away"
        duration={200}
        delay={delay}
        trigger={trigger}
        placement={placement}
        ignoreAttributes={true}
        arrow={true}
        onCreate={handleTooltipCreate}
      >
        {isString(children) ? <span>{children}</span> : children}
      </Tippy>
    );
  },
);
