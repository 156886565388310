import React, {useEffect, useState} from 'react';
import {Prompt} from 'app/types/common';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {UpdateMode} from 'app/components/sharedReactComponents/Events/Editor/EventForm/types';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';

interface Props extends Prompt {
  name: string;
  recurrent: boolean;
  onDelete: Fn<Promise<void>, [mode: UpdateMode]>;
}

export function DeleteEventDialog({open, recurrent, name, onClose, onDelete}: Props) {
  const [mode, setMode] = useState<UpdateMode>('this');

  const [loading, setLoading] = useState(false);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMode(event.target.value as UpdateMode);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await onDelete(recurrent ? mode : 'this');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setMode('this');
    }
  }, [open]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true} onClose={onClose}>
      <DialogClose onClose={onClose} />
      <DialogTitle>
        Delete: <strong>{name}</strong>?
      </DialogTitle>

      {recurrent && (
        <DialogContent sx={{display: 'flex', justifyContent: 'center', '& label': {mb: 0}}}>
          <FormControl>
            <FormLabel>Select delete option</FormLabel>
            <RadioGroup value={mode} onChange={handleSelect}>
              <FormControlLabel
                value="this"
                control={<Radio data-id="this-radio" />}
                label="This"
              />
              <FormControlLabel
                value="forward"
                control={<Radio data-id="forward-radio" />}
                label="This and following"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
      )}

      <DialogActions>
        <LoadingButton
          data-id="confirm-delete-button"
          variant="contained"
          color="error"
          loading={loading}
          onClick={handleDelete}
        >
          Delete
        </LoadingButton>
        <Button data-id="cancel-delete-button" variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
