import React from 'react';
import {STREAMING_SERVICE_TYPE} from 'app/components/StreamingServices/constants';
import {FacebookStreamingDestinationSettings} from 'app/components/sharedReactComponents/StreamingDestinationSettings/FacebookStreamingDestinationSettings';
import {YoutubeStreamingDestinationSettings} from 'app/components/sharedReactComponents/StreamingDestinationSettings/YoutubeStreamingDestinationSettings';
import {RTMPStreamingDestinationSettings} from 'app/components/sharedReactComponents/StreamingDestinationSettings/RTMPStreamingDestinationSettings';
import {Callback} from 'app/types/common';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {streamingServicesRepository} from 'app/components/StreamingServices';

interface Props {
  streamingDestination: AnyStreamingDestinationModelType;
  updating: boolean;
  deleting?: boolean;
  disabled: boolean;
  onChange: Callback;
  onClickDelete: Callback;
}

export const StreamingDestinationSettings: React.FC<Props> = (props) => {
  const {streamingDestination} = props;

  switch (streamingDestination.getType()) {
    case STREAMING_SERVICE_TYPE.FACEBOOK:
      return <FacebookStreamingDestinationSettings {...props}/>;
    case STREAMING_SERVICE_TYPE.YOUTUBE:
      return <YoutubeStreamingDestinationSettings {...props}/>;
    case STREAMING_SERVICE_TYPE.TWITCH:
      return <>`Settings of ${streamingServicesRepository.getServiceByType(streamingDestination.getType())?.getName()}`</>;
    case STREAMING_SERVICE_TYPE.RTMP:
    case STREAMING_SERVICE_TYPE.RTMPS:
      return <RTMPStreamingDestinationSettings {...props}/>;
    default:
      return null;
  }
};
