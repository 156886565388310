import React, {useState} from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {StyleSx, Sx} from 'app/types/common';
import {Edge} from 'app/domain/edge';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {pluralizeN} from 'app/components/helpers/commonHelpers';

const titleSx: StyleSx = {overflowWrap: 'break-word', wordBreak: 'break-all'};

interface Props extends Sx {
  devices: AnyDeviceModelType[];
  groups: Edge.Group[];
  loading: boolean;
  showRemove?: boolean;
  onMove: (groupId: string) => Promise<void>;
  onRemove?: () => Promise<void>;
  onCancel: () => void;
}

export function MoveToGroup({
  sx,
  devices,
  loading,
  groups,
  showRemove,
  onMove,
  onRemove,
  onCancel,
}: Props) {
  const [selected, setSelected] = useState('');

  const hasOptions = groups.length > 0;
  const totalDevices = devices.length;

  const renderNames = () => {
    return (
      <Typography component="span" sx={titleSx} color="primary.main">
        {totalDevices > 1 ? totalDevices : devices[0]?.getName() ?? ''}
      </Typography>
    );
  };

  return (
    <Box sx={sx}>
      <DialogTitle>Move to group</DialogTitle>

      <DialogContent>
        <Typography mb={1} textAlign="center">
          Move {renderNames()} {pluralizeN('device', devices.length)}
        </Typography>

        <FormLabel sx={{display: 'block', mb: 0.5}}>Group name</FormLabel>
        <Select
          fullWidth={true}
          data-id="team-select"
          displayEmpty={true}
          disabled={loading}
          value={selected}
          MenuProps={{sx: {maxWidth: 364}}}
          renderValue={(value) => {
            if (!value) {
              return <Typography color="text.disabled">Select group</Typography>;
            }

            return groups.find((g) => g.id === value)?.name ?? '';
          }}
        >
          {!hasOptions && (
            <MenuItem key="no-options" value="" disabled={true}>
              No options
            </MenuItem>
          )}
          {groups.map((group) => (
            <MenuItem key={group.id} value={group.id} onClick={() => setSelected(group.id)}>
              <Typography variant="inherit" noWrap={true}>
                {group.name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </DialogContent>

      <DialogActions>
        {showRemove && (
          <LoadingButton
            data-id="remove-from-group-btn"
            sx={{mr: 'auto'}}
            disableRipple={false}
            color="error"
            variant="outlined"
            loading={loading}
            onClick={onRemove}
          >
            Remove from group
          </LoadingButton>
        )}

        <Stack direction="row" justifyContent="center" gap={1}>
          <LoadingButton
            data-id="confirm-move-btn"
            color="secondary"
            variant="contained"
            loading={loading}
            disabled={!selected}
            disableRipple={false}
            onClick={() => onMove(selected)}
          >
            Move
          </LoadingButton>

          <Button
            data-id="cancel-move-btn"
            disableRipple={false}
            color="info"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Box>
  );
}
