import {Dialog} from '@mui/material';
import {ZoomIdentity} from 'app/components/MeetingsManager/dialogs/ZoomIdentityDialog/ZoomIdentity/ZoomIdentity';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {Prompt} from 'app/types/common';
import React from 'react';

interface Props extends Prompt, React.ComponentProps<typeof ZoomIdentity> {}

export function ZoomIdentityDialog({open, args, accounts, onApply, onClose}: Props) {
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <DialogClose onClose={onClose} />

      <ZoomIdentity args={args} accounts={accounts} onApply={onApply} />
    </Dialog>
  );
}
