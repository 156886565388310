import React, {forwardRef} from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';

/**
 * @readonly
 * @enum {string}
 */
enum LONG_NAME_WRAPPER_TYPE {
  BREAK_ALL = 'break-all',
  BREAK_WORD = 'break-word',
}

interface Props extends ClassName {
  type: LONG_NAME_WRAPPER_TYPE;
  children: any;
}

const LongNameWrapper = forwardRef<any, Props>(({
  className,
  children,
  type,
}, ref) => (
  <span
    ref={ref}
    className={classNames('long-name-wrapper', {
      [`long-name-wrapper--${type}`]: Boolean(type),
    }, className)}
  >
    {children}
  </span>
));

LongNameWrapper.displayName = 'LongNameWrapper';

// eslint-disable-next-line @typescript-eslint/ban-types
type ForwardProps = Omit<Props, 'type'>;

const LongNameBreakAllWrapper = forwardRef<any, ForwardProps>((props, ref) => (
  <LongNameWrapper
    ref={ref}
    {...props}
    type={LONG_NAME_WRAPPER_TYPE.BREAK_ALL}
  />
));

LongNameBreakAllWrapper.displayName = 'LongNameBreakAllWrapper';

const LongNameBreakWordWrapper = forwardRef<any, ForwardProps>((props, ref) => (
  <LongNameWrapper
    ref={ref}
    {...props}
    type={LONG_NAME_WRAPPER_TYPE.BREAK_WORD}
  />
));

LongNameBreakWordWrapper.displayName = 'LongNameBreakWordWrapper';

export {
  LongNameBreakAllWrapper,
  LongNameBreakWordWrapper,
};
