import {LVS_TYPES} from 'app/components/Settings/Alerts/AlertSettings/constants';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {Alerts} from 'app/components/entities/alerts';

export function isAlertDisabled(setting: Alerts.Settings, groupType: Alerts.AlertType): boolean {
  if (ModelService.isLivescrypt(setting.deviceModel)) {
    return !LVS_TYPES.includes(groupType);
  }

  return false;
}
