import React from 'react';
import {Sx} from 'app/types/common';
import {ProductCard} from 'app/components/Settings/BillingAndUsage/cards/ProductCard/ProductCard';
import {Box, Link, Stack, Typography} from '@mui/material';
import {isNil} from 'app/util/isNil';
import {palette} from 'app/themes/app';
import {THEME, VARIANT} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';
import {UnitsCount} from 'app/components/Settings/BillingAndUsage/cards/shared/UnitsCount/UnitsCount';
import {formatTimeUnit} from 'app/components/BillingManager/dialogs/shared/utils';
import {formatPrice} from 'app/util/prices';

interface Props extends Sx {
  plan?: App.Billing.Unify.Plan;
  total: number;
  onManage: Fn;
}

export function UnifyCard({sx, plan, total, onManage}: Props) {
  const disabled = isNil(plan);

  const renderName = () => {
    if (disabled) {
      return false;
    }

    let text = `${plan.tier.name} plan active`;

    if (plan.enterprise) {
      text = 'Enterprise plan active';
    }

    return (
      <Typography
        data-id="plan-name"
        mb={0.5}
        fontSize={20}
        fontWeight={600}
        component="span"
        color={palette.blue}
      >
        {text}
      </Typography>
    );
  };

  const renderDescription = () => {
    if (disabled) {
      return (
        <Box>
          <Typography data-id="plan-ad" mb={0.5} color={palette.dark}>
            Video production in the cloud. The ultimate flexibility to record, switch, mix, and
            restream content from anywhere
          </Typography>

          <Link
            data-id="unify-link"
            href="https://www.epiphan.com/unify"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </Link>
        </Box>
      );
    }

    if (plan.enterprise) {
      return (
        <Typography data-id="plan-description">Custom reserved capacity plan active</Typography>
      );
    }

    const {tier} = plan;

    return (
      <Box>
        <Typography data-id="plan-fee" color={palette.darkerGray} mb={0.5}>
          No {formatTimeUnit(tier.interval.dimension)} fee
        </Typography>
        <Typography data-id="plan-usage" mb={0.5}>
          ${formatPrice(tier.hourlyRate)} per each project's active hour
        </Typography>
      </Box>
    );
  };

  return (
    <ProductCard
      dataId="unify-card"
      sx={sx}
      title="Epiphan Unify"
      body={
        <Stack flex={1}>
          {renderName()}
          {renderDescription()}
        </Stack>
      }
      action={
        <Button
          dataId="manage-unify"
          theme={THEME.INFO}
          variant={VARIANT.OUTLINE}
          onClick={onManage}
        >
          {disabled ? 'Subscribe' : 'Manage'}
        </Button>
      }
      footer={<UnitsCount count={total} type="unify" disabled={disabled} />}
      disabled={disabled}
    />
  );
}
