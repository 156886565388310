import React, {useRef, useState} from 'react';
import {useCopyToClipboard} from 'react-use';
import {Button, ButtonProps, Tooltip} from '@mui/material';
import {useMounted} from 'app/hooks/useIsMounted';
import {DataId} from 'app/types/common';

type Props = DataId &
  ButtonProps & {
    value: string;
    tooltip?: string;
  };

export function CopyButton({dataId, sx, value, tooltip, ...props}: Props) {
  const [userTooltip, setUserTooltip] = useState(false);
  const [copyTooltip, setCopyTooltip] = useState(false);

  const tooltipTimeout = useRef<number | null>(null);

  const mounted = useMounted();
  const [, copyToClipboard] = useCopyToClipboard();

  const handleClick = () => {
    copyToClipboard(value);
    setCopyTooltip(true);

    if (tooltipTimeout.current && mounted()) {
      clearTimeout(tooltipTimeout.current);
    }

    tooltipTimeout.current = window.setTimeout(() => {
      if (mounted()) {
        setCopyTooltip(false);
      }
    }, 500);
  };

  const renderTitle = () => {
    if (copyTooltip) {
      return 'Copied!';
    }

    if (tooltip) {
      return tooltip;
    }

    return null;
  };

  return (
    <Tooltip
      open={userTooltip}
      title={renderTitle()}
      onOpen={() => setUserTooltip(true)}
      onClose={() => setUserTooltip(false)}
    >
      <Button data-id={dataId} sx={sx} {...props} onClick={handleClick}>
        {value}
      </Button>
    </Tooltip>
  );
}
