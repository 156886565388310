const MARGIN_SIZE = 4;

// This is *very* rough estimate of max amount of QRCode allowed to be covered.
// It is "wrong" in a lot of ways (area is a terrible way to estimate, it
// really should be number of modules covered), but if for some reason we don't
// get an explicit height or width, I'd rather default to something than throw.
const DEFAULT_IMG_SCALE = 0.1;

enum QR_CODE_LEVEL {
  L = 'L',
  M = 'M',
  Q = 'Q',
  H = 'H',
}

export {
  MARGIN_SIZE,
  DEFAULT_IMG_SCALE,
  QR_CODE_LEVEL,
};
