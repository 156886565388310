/**
 * Returns a list of numbers.
 *
 * ```typescript
 * // Integers from zero to `length` minus one
 * createRange(3); //=> [0, 1, 2]
 * // Integers from `start` to `end`
 * createRange(2, 5); //=> [2, 3, 4, 5]
 * ```
 */
function createRange(startOrLength: number, end?: number): number[] {
  let rangeStart = 0;
  let rangeEnd = startOrLength - 1;

  if (end !== undefined) {
    rangeStart = startOrLength;
    rangeEnd = end;
  }

  return Array.from({length: rangeEnd - rangeStart + 1}, (_, i) => i + rangeStart);
}

export {
  createRange,
};
