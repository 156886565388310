import {front} from 'app/api/CirrusApi';
import {TranscriberConfigDto} from 'app/store/dto/device/Device';

class TranscriberApiService {
  setUserDefinedUrl = async (
    id: string,
    url: string,
  ) => front.devices(id).transcribe().id().post({id: url});

  clearSession = async (
    id: string,
    session: string,
  ) => front.transcribing(id).sessions(session).clear().post();

  updateConfig = async (
    id: string,
    config: TranscriberConfigDto,
  ) => front.devices(id).transcribe().configuration().put(config);

  start = async (id: string) => front.devices(id).transcribe().start().post();

  stop = async (id: string) => front.devices(id).transcribe().stop().post();

  pause = async (id: string) => front.devices(id).transcribe().pause().post();

  resume = async (id: string) => front.devices(id).transcribe().resume().post();
}

export const transcriberApiService = new TranscriberApiService();
