import {useQuery} from '@tanstack/react-query';
import {Cloud} from 'app/domain/cloud';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {stringComparator} from 'app/util/Sort';
import {CloudMapper} from 'app/util/mappers/CloudMapper/CloudMapper';

interface Args {
  teamId: string;
  enabled: boolean;
}

export function useStreamDestinations({enabled, teamId}: Args) {
  const query = useQuery({
    queryKey: ['stream-destinations', teamId],
    queryFn: async () => {
      const contract = await DeviceApiService.getDestinations();

      return contract
        .reduce<Cloud.StreamingDestination[]>((acc, c) => {
          const mapped = CloudMapper.mapDestination(c);
          return mapped ? [...acc, mapped] : acc;
        }, [])
        .sort((a, b) => stringComparator(a.name, b.name));
    },
    enabled,
  });

  return query;
}
