import React, {createContext, Reducer, useCallback, useContext, useMemo, useReducer} from 'react';
import {GeneratePairingLinkDialog} from 'app/components/dialogs/GeneratePairingLinkDialog/GeneratePairingLinkDialog';
import {PairActionSelectorDialog} from 'app/components/dialogs/PairActionSelectorDialog/PairActionSelectorDialog';
import {MeetingsApiService} from 'app/services/api/meetings/MeetingsApiService';
import {front} from 'app/api/CirrusApi';
import {Location} from 'app/router/Location';
import {ZoomIntegrationDialog} from 'app/components/ConnectPlatformsIntegration/dialogs/ZoomIntegrationDialog/ZoomIntegrationDialog';
import {PairTeamsTenantDialog} from 'app/components/dialogs/PairTeamsTenantDialog/PairTeamsTenantDialog';

type View = 'none' | 'select' | 'teams-admin' | 'teams-not-admin' | 'zoom';

interface State {
  view: View;
  backUrl: string;
}

type Type = 'select' | 'admin-pair' | 'user-pair' | 'zoom' | 'close';

interface ActionType<T extends Type> {
  type: T;
}

interface ActionPayload<T extends Type, P extends any> extends ActionType<T> {
  payload: P;
}

type SelectAction = ActionPayload<'select', string>;
type CloseAction = ActionType<'close'>;
type AdminAction = ActionType<'admin-pair'>;
type NotAdminAction = ActionType<'user-pair'>;
type ZoomAction = ActionPayload<'zoom', string>;

type Action = SelectAction | CloseAction | AdminAction | NotAdminAction | ZoomAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'select': {
      return {view: 'select', backUrl: action.payload};
    }
    case 'admin-pair': {
      return {...state, view: 'teams-admin'};
    }

    case 'user-pair': {
      return {...state, view: 'teams-not-admin'};
    }

    case 'close': {
      return {view: 'none', backUrl: ''};
    }

    case 'zoom': {
      return {view: 'zoom', backUrl: action.payload};
    }

    default:
      return state;
  }
}

type ContextValue = {
  startTeams: Fn<void, [string]>;
  startZoom: Fn<void, [string]>;
};
const ConnectIntegrationContext = createContext<ContextValue | undefined>(undefined);

interface Props {
  teamName: string;
  enabled: boolean;
}

export function ConnectPlatformsIntegration({
  teamName,
  enabled,
  children,
}: React.PropsWithChildren<Props>) {
  const [state, dispatch] = useReducer<Reducer<State, Action>, State>(
    reducer,
    {
      view: 'none',
      backUrl: '',
    },
    (state) => state,
  );

  const startTeams = useCallback(
    (backUrl: string) => {
      if (enabled) {
        dispatch({type: 'select', payload: backUrl});
      }
    },
    [enabled],
  );

  const startZoom = useCallback(
    (returnUrl: string) => {
      if (enabled) {
        dispatch({type: 'zoom', payload: returnUrl});
      }
    },
    [enabled],
  );

  const handleClose = useCallback(() => {
    dispatch({type: 'close'});
  }, []);

  const selectAdmin = useCallback(() => {
    dispatch({type: 'admin-pair'});
  }, []);

  const selectNotAdmin = useCallback(() => {
    dispatch({type: 'user-pair'});
  }, []);

  const handleAdminPair = useCallback(() => {
    const url = front.econnect().pair().ms().tenant({redirect_url: state.backUrl}).url() as string;
    Location.assign(url);
  }, [state.backUrl]);

  const handleZoom = useCallback(() => {
    const url = front.econnect().pair().zoom().user({redirect_url: state.backUrl}).url() as string;
    Location.assign(url);
  }, [state.backUrl]);

  const contextValue = useMemo<ContextValue>(
    () => ({
      startTeams,
      startZoom,
    }),
    [startZoom, startTeams],
  );

  return (
    <ConnectIntegrationContext.Provider value={contextValue}>
      <PairActionSelectorDialog
        open={state.view === 'select'}
        onGenerateLink={selectNotAdmin}
        onPair={selectAdmin}
        onClose={handleClose}
      />

      <GeneratePairingLinkDialog
        open={state.view === 'teams-not-admin'}
        generateLink={MeetingsApiService.getLink}
        onClose={handleClose}
      />

      <PairTeamsTenantDialog
        open={state.view === 'teams-admin'}
        team={teamName}
        onPair={handleAdminPair}
        onClose={handleClose}
      />

      <ZoomIntegrationDialog
        open={state.view === 'zoom'}
        onPair={handleZoom}
        onClose={handleClose}
      />
      {children}
    </ConnectIntegrationContext.Provider>
  );
}

export function useConnectIntegration() {
  const runPairing = useContext(ConnectIntegrationContext);
  if (!runPairing) {
    throw new Error('Out of Pairing Tenant Context');
  }

  return runPairing;
}
