import React from 'react';
import {Box, Typography} from '@mui/material';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {gridSx} from 'app/components/entities/billing/files/ui/grid.styles';

const rootSx: StyleSx = {
  px: 1,
  py: 1.5,
  fontWeight: 600,
};

interface Props extends Sx {}

export function ArchiveHeader({sx}: Props) {
  return (
    <Box sx={packSx(rootSx, gridSx, sx)}>
      <Typography variant="inherit">Date</Typography>

      <Typography variant="inherit">Number</Typography>

      <Typography variant="inherit" justifySelf="end">
        Stored
      </Typography>

      <Typography variant="inherit" justifySelf="end">
        Storage fee
      </Typography>

      <Typography variant="inherit" justifySelf="end">
        Transferred
      </Typography>

      <Typography variant="inherit" justifySelf="end">
        Transfer fee
      </Typography>

      <Typography variant="inherit" justifySelf="end">
        Total
      </Typography>
    </Box>
  );
}
