import React, {useCallback} from 'react';
import {Component} from 'app/types/common';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {debounce} from 'throttle-debounce';
import {palette} from 'app/themes/app';
import {capitalize} from 'app/components/helpers/commonHelpers';
import {Icons} from 'app/util/icons';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {SIZE, THEME, VARIANT} from 'app/constants';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {CouponState} from 'app/components/BillingManager/hooks/useCoupon';
import {
  Coupon,
  DiscountCoupon,
  isDiscountCoupon,
  isIbbCoupon,
} from 'app/components/BillingManager/Coupon';
import {isNil} from 'app/util/isNil';
import {Notifications} from 'app/components/Notifications';

interface Props {
  coupon: CouponState;
  hasConnect: boolean;
  method: App.Billing.PaymentMethod;
  onCouponChange: Fn<void, [string]>;
  onValidate: Fn<Promise<void>, [string]>;
  onActivate: Fn<Promise<void>>;
}

export function CouponForm({
  coupon,
  hasConnect,
  method,
  onCouponChange,
  onValidate,
  onActivate,
}: Props) {
  const handleValidate = useCallback(debounce(400, onValidate), [onValidate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    onCouponChange(value);

    if (value.length > 0) {
      handleValidate(value);
    }
  };

  const isInvoiceCoupon = coupon.instance ? isIbbCoupon(coupon.instance) : false;
  const inConnectCoupon = coupon.instance instanceof DiscountCoupon && coupon.instance.isConnect;
  const isCCPayment = method === 'credit card';
  const isPendingInvoicePayment = method === 'pending invoice';

  const canActivate = !isNil(coupon.instance) && (isInvoiceCoupon || !isPendingInvoicePayment);

  const forceConnectSub = canActivate && inConnectCoupon && !hasConnect;
  const forcePaymentSwitch = canActivate && isInvoiceCoupon && isCCPayment;

  const handleActivate = async () => {
    try {
      await onActivate();
    } catch {
      Notifications.addErrorNotification('Could not activate coupon');
    }
  };

  const renderSummary = (coupon: Coupon) => {
    const type = isDiscountCoupon(coupon) ? capitalize(coupon.domain) : 'Cloud';
    const duration = isDiscountCoupon(coupon) ? coupon.duration : '';

    return (
      <Typography fontSize={16} fontWeight={600}>
        Voucher value for <Value>{type}</Value> <Value>{coupon.formatted}</Value>{' '}
        {duration && (
          <Tooltip content={duration}>
            {Icons.info().theme(THEME.PRIMARY).size(SIZE.S).reactComponent()}
          </Tooltip>
        )}
      </Typography>
    );
  };

  return (
    <Box>
      <DialogTitle variant="h5">Activate voucher</DialogTitle>

      <DialogContent>
        <Stack alignItems="center" sx={{minHeight: '230px'}}>
          <Typography sx={{mb: 3, textAlign: 'center'}}>
            You will get credits on your account that you can spend <br />
            on the premium plan, connect subscription or for using livescrypt devices.
          </Typography>

          <TextField
            sx={{width: '310px'}}
            variant="standard"
            placeholder="Enter a voucher code"
            value={coupon.value}
            autoFocus={true}
            inputProps={{'data-id': 'voucher-input'}}
            InputProps={{readOnly: coupon.checking}}
            error={coupon.invalid}
            helperText={
              coupon.invalid ? 'Validation failed. Make sure the coupon code is correct.' : ''
            }
            onChange={handleChange}
          />

          {coupon.instance && (
            <>
              <Stack mt={2} direction="row" justifyContent="center" alignItems="center" gap={1}>
                <Box
                  component="img"
                  src={`/assets/img/billing-icons/coupon-${
                    canActivate ? '' : 'not-'
                  }ready-to-apply.svg`}
                  height={34}
                  width={42}
                  alt=""
                />

                {renderSummary(coupon.instance)}
              </Stack>

              {forceConnectSub && (
                <Typography mt={1} fontWeight={600} textAlign="center" color={palette.green}>
                  By activating this Epiphan Connect coupon you will be subscribed to
                  <br /> Epiphan Connect Pro plan
                </Typography>
              )}

              {forcePaymentSwitch && (
                <Typography mt={1} textAlign="center">
                  On activation of this voucher, your account will be switched to invoice-based
                  billing
                </Typography>
              )}

              {!isInvoiceCoupon && isPendingInvoicePayment && (
                <Typography mt={1} textAlign="center" color={palette.red}>
                  You will be able to activate this voucher
                  <br />
                  when your request for invoice-based billing is approved.
                </Typography>
              )}
            </>
          )}
        </Stack>
      </DialogContent>

      {canActivate && (
        <DialogActions>
          <ProgressButton
            dataId="activate_button"
            theme={THEME.PRIMARY}
            variant={VARIANT.SOLID}
            disabled={coupon.checking}
            onClick={handleActivate}
          >
            Activate
          </ProgressButton>
        </DialogActions>
      )}
    </Box>
  );
}

function Value({dataId, children}: React.PropsWithChildren<Component>) {
  return (
    <Box data-id={dataId} component="span" sx={{color: palette.green}}>
      {children}
    </Box>
  );
}
