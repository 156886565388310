import {BaseStreamingDestinationModel} from 'app/components/StreamingServices/StreamingDestinations/BaseStreamingDestinationModel';
import {STREAMING_SERVICE_PROP_NAME} from 'app/components/StreamingServices/constants';
import {StreamingDestinationRTMPSettings} from 'app/components/StreamingServices/StreamingDestinations/types';

class RTMPStreamingDestinationModel extends BaseStreamingDestinationModel {
  getNameSetting() {
    return this.getOptions().name;
  }

  getKeySetting() {
    return this.getOptions().key;
  }

  getUrlSetting() {
    return this.getOptions().url;
  }

  protected processOptionsData(json) {
    const options = json[this.getOptionsPropName()];

    return {
      name: options.DestName,
      key: options.StreamingKey,
      url: options.URL,
    };
  }

  protected getOptionsPropName() {
    return STREAMING_SERVICE_PROP_NAME.RTMP;
  }

  protected optionsToJSON({
    name,
    key,
    url,
  }): StreamingDestinationRTMPSettings {
    return {
      DestName: name,
      StreamingKey: key,
      URL: url,
    };
  }
}

export {RTMPStreamingDestinationModel};
