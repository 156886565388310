import React from 'react';
import {Sx} from 'app/types/common';
import {Box, Typography} from '@mui/material';
import {useConnectReport} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/ConnectUsageAndInvoices/useConnectUsageAndInvoices';
import {isNil} from 'app/util/isNil';
import {ConnectInvoice} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/ConnectUsageAndInvoices/ConnectInvoice/ConnectInvoice';
import {ConnectHistory} from 'app/components/Settings/BillingAndUsage/UsageAndInvoices/ConnectUsageAndInvoices/ConnectHistory/ConnectHistory';
import {palette} from 'app/themes/app';
import {InvoicesSkeleton} from 'app/components/entities/billing/base';

interface Props extends Sx {
  teamId: string;
  subscription?: App.Billing.Connect.Plan;
}

export function ConnectUsageAndInvoices({sx, teamId, subscription}: Props) {
  const {isInitialLoading, data} = useConnectReport({teamId, enabled: true});

  const loading = isInitialLoading || isNil(data);

  if (loading) {
    return <InvoicesSkeleton sx={sx} />;
  }

  return (
    <Box sx={sx}>
      {data.invoice && subscription && (
        <ConnectInvoice
          dataId="connect-invoice"
          sx={{mb: 3}}
          name={subscription.tier.name}
          invoice={data.invoice}
          period={subscription.period}
        />
      )}

      <Typography fontWeight={600} fontSize={16} mb={1}>
        Invoice history
      </Typography>

      {data.history.length > 0 ? (
        <ConnectHistory teamId={teamId} history={data.history} />
      ) : (
        <Typography fontWeight={600} textAlign="center" color={palette.darkerGray} my={2}>
          No previous invoices
        </Typography>
      )}
    </Box>
  );
}
