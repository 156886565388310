import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';

interface Props extends Prompt {
  onEnable: () => Promise<void>;
}

export function EnableEdgeAfuDialog({open, onEnable, onClose}: Props) {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await onEnable();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        Enable Epiphan Edge File Management as your Automatic File Upload destination
      </DialogTitle>

      <DialogContent>
        <Typography textAlign="center">
          This will disable any Automatic File Uploading currently in progress on your device.
        </Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          variant="contained"
          color="secondary"
          loading={loading}
          onClick={handleConfirm}
        >
          Enable
        </LoadingButton>

        <Button variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
