import React, {useEffect, useMemo, useState} from 'react';
import {useLocalStorage} from 'react-use';
import {
  ButtonBase,
  Drawer,
  IconButton,
  Stack,
  Typography,
  alpha,
  buttonBaseClasses,
} from '@mui/material';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import CloseIcon from '@mui/icons-material/Close';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {pluralizeN} from 'app/components/helpers/commonHelpers';
import {useNotifications} from 'app/components/entities/notifications';
import {OwnershipNotification} from 'app/components/features/notifications';

const rootSx: StyleSx = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: 1,
  gap: 1.5,
  p: 1,
  bgcolor: 'transparent',
  [`&:hover, &.${buttonBaseClasses.focusVisible}`]: {bgcolor: '#222836'},
};
const unreadSx: StyleSx = {
  bgcolor: '#ff0048',
  [`&:hover, &.${buttonBaseClasses.focusVisible}`]: {bgcolor: alpha('#ff0048', 0.9)},
};

interface Props extends Sx {
  teamId: string;
  userId: string;
  compact: boolean;
}

export function UserNotifications({sx, teamId, userId, compact}: Props) {
  const [open, setOpen] = useState(false);

  const {data, refetch} = useNotifications();

  const [tokens = {}, setTokens] = useLocalStorage<Record<string, Record<string, string[]>>>(
    'Notifications',
    {},
  );

  const total = data?.length ?? 0;

  const hasUnread = useMemo(() => {
    if (!data) {
      return false;
    }

    const read = new Set(tokens[teamId]?.[userId] ?? []);
    return data.some(({token}) => !read.has(token));
  }, [tokens, data, teamId, userId]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!data.length) {
      setOpen(false);
    }
  }, [data]);

  if (!data) {
    return null;
  }

  const handleOpen = () => {
    setOpen(true);

    const newList = data?.map(({token}) => token) ?? [];

    setTokens((prev) => {
      if (!prev) {
        if (data) {
          return {
            [teamId]: {
              [userId]: newList,
            },
          };
        }

        return prev;
      }

      const team = prev[teamId] ?? {};

      return {
        ...prev,
        [teamId]: {...team, [userId]: newList},
      };
    });
  };

  const hasItems = data.length > 0;

  return (
    <>
      {hasItems && (
        <ButtonBase
          sx={packSx(rootSx, hasUnread && unreadSx, sx)}
          data-id="nav-notifications"
          onClick={handleOpen}
        >
          <AnnouncementIcon />
          {!compact && (
            <Typography data-id="nav-notifications-count">
              {total} {pluralizeN('Notification', total)}{' '}
            </Typography>
          )}
        </ButtonBase>
      )}

      <Drawer
        open={open}
        PaperProps={{sx: {width: 350}}}
        anchor="right"
        onClose={() => setOpen(false)}
      >
        <Stack m={2} direction="row" alignItems="center">
          <Typography variant="h5" fontWeight={600}>
            Notifications
          </Typography>

          <IconButton sx={{ml: 'auto'}} onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Stack sx={sx} gap={1} overflow="auto" px={2} mb={2}>
          {data.map((item) => (
            <OwnershipNotification
              key={item.token}
              notification={item}
              onReload={async () => {
                await refetch();
              }}
            />
          ))}
        </Stack>
      </Drawer>
    </>
  );
}
