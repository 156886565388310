import React from 'react';
import classNames from 'classnames';
import {ListItem} from 'app/components/Settings/StreamingDestinations/NewStreamingDestinationTypeSelector/List/ListItem';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  services: any[];
  createServiceAction: Callback;
}

export const List: React.FC<Props> = ({
  className,
  services,
  createServiceAction,
}) => {
  return (
    <section className={classNames('new-streaming-destination-type-selector-list', className)}>
      {services.map(service => (
        <ListItem
          key={service.getType()}
          service={service}
          createServiceAction={createServiceAction}
        />
      ))}
    </section>
  );
};
