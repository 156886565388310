import React, {PropsWithChildren} from 'react';
import {ClassName, DataId, Sx} from 'app/types/common';
import {Caret, CARET_VARIANT} from 'app/components/sharedReactComponents/Caret';
import {useToggle} from 'app/hooks/useToggle';
import {SIZE, VARIANT} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Box, Stack, Typography} from '@mui/material';
import {packSx} from 'app/util/packSx/packSx';

interface Props extends PropsWithChildren<ClassName>, DataId, Sx {
  title: string;
  initState?: boolean;
  collapsible?: boolean;
}

export const CollapsiblePanel = ({
  sx,
  title,
  collapsible = true,
  initState,
  dataId,
  children,
}: Props) => {
  const [opened, toggleOpened] = useToggle(collapsible ? initState : true);

  const handleToggle = () => {
    if (collapsible) {
      toggleOpened();
    }
  };

  return (
    <Box sx={packSx({'& > button': {padding: '2px 0px'}}, sx)}>
      <Button data-id={dataId} variant={VARIANT.TEXT} onClick={handleToggle}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography fontWeight={600}>{title}</Typography>
          {collapsible && <Caret variant={CARET_VARIANT.THIN} size={SIZE.S} opened={opened} />}
        </Stack>
      </Button>

      {opened && <div>{children}</div>}
    </Box>
  );
};
