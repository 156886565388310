import {MainRoutes} from 'app/router/main';

export class Role {
  name: string;

  constructor(role: string) {
    this.name = role;
  }

  permissions = {
    devices: {
      view: false,
      reboot: false,
    },
    unify: {
      manage: false,
    },
    teams: {
      edit: false,
      delete: false,
      owner: {
        edit: false,
      },
    },
    billing: {
      view: false,
      edit: false,
    },
    accountSettings: {
      view: false,
    },
    presets: {
      apply: false,
    },
    streamingDestinations: {
      edit: false,
    },
    connect: {
      pairing: false,
      connecting: false,
      settings: true,
      srt: true,
    },
  };

  canAccessRoute(route: MainRoutes) {
    switch (route) {
      case 'devices':
      case 'deviceDetails':
      case 'welcome':
      case 'oneClickDevicePairing':
      case 'transcribeDevices':
      case 'transcribeDeviceDetails':
      case 'projects':
      case 'projectDetails':
      case 'meetings':
      case 'meetingDetails':
        return this.permissions.devices.view;
      case 'settingBillingUsage':
        return this.permissions.billing.view;
      case 'fileTransfer':
      case 'apiTokens':
        return false;
      default:
        return true;
    }
  }

  canViewDevices() {
    return this.permissions.devices.view;
  }

  canEditTeams() {
    return this.permissions.teams.edit;
  }

  canViewBilling() {
    return this.permissions.billing.view;
  }

  canEditBilling() {
    return this.permissions.billing.edit;
  }

  canEditTeamOwner() {
    return this.permissions.teams.owner.edit;
  }

  canDeleteTeams() {
    return this.permissions.teams.delete;
  }

  canRebootDevices() {
    return this.permissions.devices.reboot;
  }

  canViewAccountSettings() {
    return this.permissions.accountSettings.view;
  }

  canApplyPresets() {
    return this.permissions.presets.apply;
  }

  canEditStreamingDestinations() {
    return this.permissions.streamingDestinations.edit;
  }

  canManageUnify() {
    return this.permissions.unify.manage;
  }

  canPairConnectAuth() {
    return this.permissions.connect.pairing;
  }
}
