import React, {Fragment} from 'react';
import {PropsList, PROPS_LIST_THEME} from 'app/components/sharedReactComponents/PropsList';
import {
  getDeviceSettingValue,
  getDeviceSettingValueFormatted,
} from 'app/models/Device/Settings/utils';
import {ClassName} from 'app/types/common';
import {useDeviceSettingsCollection} from 'app/hooks/Device/useDeviceSettingsCollection';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';

interface Props extends ClassName {
  device: AnyDeviceModelType;
}

export const DeviceRecordingPropsList: React.VFC<Props> = ({
  className,
  device,
}) => {
  const deviceSettingsCollection = useDeviceSettingsCollection(device);

  if (!deviceSettingsCollection) {
    return null;
  }

  return (
    <PropsList
      className={className}
      data-id="channel_recorder_properties"
      data={{
        format: getDeviceSettingValueFormatted(deviceSettingsCollection.getRecorderFileFormatSetting()),
        timeLimit: getDeviceSettingValueFormatted(deviceSettingsCollection.getRecorderTimeLimitSetting()),
        sizeLimit: getDeviceSettingValueFormatted(deviceSettingsCollection.getRecorderSizeLimitSetting()),
        afu: getDeviceSettingValue(deviceSettingsCollection.getRecorderAfuEnabledSetting()) ? 'On' : 'Off',
      }}
      rows={[
        {
          dataKey: 'format',
          label: 'Format',
        },
        {
          dataKey: 'limits',
          label: 'File limit',
          cellRenderer: ({rowData}) => {
            const {
              timeLimit,
              sizeLimit,
            } = rowData;

            if (!timeLimit && !sizeLimit) {
              return null;
            }

            return (
              <Fragment>
                {timeLimit} / {sizeLimit}
              </Fragment>
            );
          },
        },
        {
          dataKey: 'afu',
          label: 'Automatic file upload',
        }
      ]}
      theme={PROPS_LIST_THEME.SLIM_HORIZONTAL}
    />
  );
};
