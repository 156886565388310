import React, {useCallback} from 'react';
import {Icons} from 'app/util/icons';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {StorageIcon} from 'app/components/UploadLocations/UploadLocationIcons/StorageIcon';
import {SIZE} from 'app/constants';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {Callback} from 'app/types/common';
import {STORAGE_TYPE} from 'app/components/UploadLocations/constants';

interface ItemProps {
  id: string;
  name: string;
  type?: STORAGE_TYPE;
  isCurrent: boolean;
  deleteLocation: Callback;
  selectLocation: Callback;
}

const LocationsListItem: React.FC<ItemProps> = observer(
  ({
    id,
    name,
    type,
    isCurrent,
    deleteLocation,
    selectLocation,
  }) => {
    const handleClickLocation = useCallback(() => {
      selectLocation(id);
    }, [selectLocation, id]);

    const handleClickDeleteLocation = useCallback((e) => {
      deleteLocation(id);
      e.stopPropagation();
    }, [deleteLocation, id]);

    return (
      <div
        className={classNames('upload-locations-item', {
          'upload-locations-item--selected': isCurrent
        })}
        onClick={handleClickLocation}
      >
        <div className="upload-locations-item__icon">
          <StorageIcon type={type}/>
        </div>

        <div className="upload-locations-item__name">
          <OverflowTooltip>
            {name}
          </OverflowTooltip>
        </div>

        <div
          className="upload-locations-item__delete"
          onClick={handleClickDeleteLocation}
        >
          {Icons.trash().size(SIZE.S).reactComponent()}
        </div>
      </div>
    );
  }
);

interface Props {
  currentId: string;
  locations: any[];
  deleteLocation: Callback;
  selectLocation: Callback;
}

const LocationsList: React.FC<Props> = observer(({
  currentId,
  locations,
  deleteLocation,
  selectLocation,
}) => {
  return (
    <section className="upload-locations-list">
      {locations.map(location => {
        const {
          id,
          name,
          type,
        } = location;
        const isCurrent = id === currentId;

        return (
          <LocationsListItem
            key={id}
            id={id}
            type={type}
            name={name.value}
            isCurrent={isCurrent}
            deleteLocation={deleteLocation}
            selectLocation={selectLocation}
          />
        );
      })}
    </section>
  );
});

export {LocationsList, LocationsListItem};
