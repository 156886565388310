import React, {useState} from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Button,
  Card,
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {grey} from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import {packSx} from 'app/util/packSx/packSx';
import {Edge} from 'app/domain/edge';
import {PresetSections} from 'app/components/sharedReactComponents/DevicePresetCard/PresetSections/PresetSections';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {isNil} from 'app/util/isNil';
import {EditableNameText} from 'app/components/sharedReactComponents/EditableNameText';
import {ApplyPresetDialog} from 'app/components/sharedReactComponents/DevicePresetCard/dialogs/ApplyPresetDialog/ApplyPresetDialog';
import {DeletePresetDialog} from 'app/components/sharedReactComponents/DevicePresetCard/dialogs/DeletePresetDialog/DeletePresetDialog';
import {FORM_CONTROL_TYPE} from 'app/components/sharedReactComponents/FormControl/constants';
import {devicePresetNameValidator, emptyStringValidator} from 'app/util/validators';
import {DataId, Sx} from 'app/types/common';
import {EdgeContract} from 'app/contracts/edge';
import {DevicePicture} from 'app/components/sharedReactComponents/DevicePicture/DevicePicture';

interface Props extends Sx, DataId {
  preset: Edge.Preset;
  showModel: boolean;
  offline: boolean;
  available: boolean;
  permitApply: boolean;
  permitDelete: boolean;
  deviceName?: string;
  savedAs?: string;
  onApply?: (p: Edge.Preset) => Promise<void>;
  onDelete: (p: Edge.Preset) => Promise<void>;
  onUpload?: (name: string) => Promise<void>;
  onUpdate?: (id: string, data: EdgeContract.UpdatePreset) => Promise<void>;
  onDownload?: (preset: Edge.Preset) => void;
}

export function PresetCard({
  dataId,
  sx,
  preset,
  showModel,
  available,
  offline,
  permitApply,
  permitDelete,
  deviceName,
  savedAs,
  onApply,
  onUpload,
  onDelete,
  onDownload,
  onUpdate,
}: Props) {
  const [expanded, setExpanded] = useState(false);

  const [applyDialog, setApplyDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [menuEl, setMenuEl] = useState<HTMLElement | undefined>();

  const isTeamPreset = Edge.isTeamPreset(preset);

  const isSaved = !isNil(savedAs);
  const isMenuOpen = !isNil(menuEl);
  const showApply = !isNil(deviceName) && !isNil(onApply);

  const uploadTip = isSaved
    ? `This preset already exists in the team as ${savedAs}`
    : 'Upload this preset to the team library';

  const applyTip = !available ? 'Available for Premium plan only' : undefined;

  const openMenu = (e: React.MouseEvent<HTMLElement>) => {
    setMenuEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuEl(undefined);
  };

  const handleApply = async () => {
    await onApply?.(preset);
    setApplyDialog(false);
  };

  const hasUpdate = !isNil(onUpdate);

  const handleUpdate = async ({target: {name, value}}: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasUpdate || !Edge.isTeamPreset(preset)) {
      return;
    }

    await onUpdate(preset.id, {
      name: preset.name,
      description: preset.description ?? '',
      [name]: value,
    });
  };

  const handleUpload = async () => {
    if (!onUpload) {
      return;
    }
    try {
      setUploading(true);
      await onUpload(preset.name);
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async () => {
    await onDelete(preset);
    setDeleteDialog(false);
  };

  const handleDownload = () => {
    onDownload?.(preset);
    closeMenu();
  };

  const handleDeleteOpen = () => {
    setDeleteDialog(true);
    closeMenu();
  };

  const disableApply = !available || !permitApply || offline;
  const disableMenu = !permitDelete && !isTeamPreset;

  return (
    <Card data-id={dataId} sx={packSx({p: 1, bgcolor: grey[50]}, sx)} variant="outlined">
      <Stack direction="row" alignItems="center" gap={1}>
        {showModel && isTeamPreset && (
          <Stack width={168} direction="row" alignItems="center">
            <DevicePicture
              sx={{height: 23, minWidth: 55, mr: 1, fontSize: 32}}
              model={preset.device}
            />
            <Typography>{preset.device}</Typography>
          </Stack>
        )}

        <Box flex={1} minWidth={200}>
          <EditableNameText
            dataId="editable_preset_name"
            name="name"
            value={preset.name}
            disabled={!hasUpdate}
            validators={[devicePresetNameValidator, emptyStringValidator]}
            onChange={handleUpdate}
          />
        </Box>

        {isTeamPreset && (
          <Tooltip content="Created at">
            <Typography data-id="created-at" sx={{minWidth: 100}}>
              {dayjs.unix(preset.createdAt).format('ll')}
            </Typography>
          </Tooltip>
        )}

        <PresetSections sx={{minWidth: 356}} sections={preset.sections} />

        <Stack
          minWidth={isTeamPreset ? 80 : 240}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
        >
          {!isTeamPreset && (
            <Tooltip content={uploadTip}>
              <span>
                <LoadingButton
                  data-id="upload-btn"
                  variant="outlined"
                  color="primary"
                  loading={uploading}
                  disabled={offline || isSaved}
                  disableRipple={false}
                  onClick={handleUpload}
                >
                  Upload
                </LoadingButton>
              </span>
            </Tooltip>
          )}

          {showApply && (
            <>
              <Tooltip content={applyTip}>
                <span>
                  <Button
                    data-id="apply-btn"
                    variant="contained"
                    color="secondary"
                    disabled={disableApply}
                    disableRipple={false}
                    onClick={() => setApplyDialog(true)}
                  >
                    Apply
                  </Button>
                </span>
              </Tooltip>

              <ApplyPresetDialog
                open={applyDialog}
                preset={preset}
                deviceName={deviceName}
                onApply={handleApply}
                onClose={() => setApplyDialog(false)}
              />
            </>
          )}

          <IconButton data-id="preset-menu" disabled={disableMenu} onClick={openMenu}>
            <MoreHorizIcon />
          </IconButton>

          <Menu open={isMenuOpen} anchorEl={menuEl} onClose={closeMenu}>
            {isTeamPreset && (
              <MenuItem onClick={handleDownload}>
                <ListItemIcon>
                  <DownloadIcon />
                </ListItemIcon>

                <ListItemText>Download</ListItemText>
              </MenuItem>
            )}

            <MenuItem disabled={!permitDelete} onClick={handleDeleteOpen}>
              <ListItemIcon>
                <DeleteIcon color="error" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>

          <IconButton
            data-id="preset-toggler"
            disabled={!isTeamPreset}
            disableRipple={false}
            onClick={() => setExpanded((p) => !p)}
          >
            <ExpandMoreIcon
              sx={{transform: `rotate(${expanded ? 180 : 0}deg)`, transition: 'transform .3s'}}
            />
          </IconButton>

          <DeletePresetDialog
            open={deleteDialog}
            name={preset.name}
            onDelete={handleDelete}
            onClose={() => setDeleteDialog(false)}
          />
        </Stack>
      </Stack>

      {isTeamPreset && (
        <Collapse in={expanded}>
          <Box pt={2} pb={1}>
            <EditableNameText
              dataId="preset-description"
              name="description"
              value={preset.description ?? ''}
              placeholder="Add description"
              type={FORM_CONTROL_TYPE.TEXTAREA}
              disabled={!hasUpdate}
              onChange={handleUpdate}
            />
          </Box>
        </Collapse>
      )}
    </Card>
  );
}
