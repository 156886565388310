import React, {useState} from 'react';
import {filesize} from 'filesize';
import {Button, Divider, Popover, Stack, Tooltip, Typography} from '@mui/material';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import {packSx} from 'app/util/packSx/packSx';
import {StyleSx, Sx} from 'app/types/common';
import {isNil} from 'app/util/isNil';
import {Edge} from 'app/domain/edge';
import {capitalize, pluralizeN} from 'app/components/helpers/commonHelpers';

const rootSx: StyleSx = {py: 0.5, px: 1, gap: 0.5, minWidth: 68, justifyContent: 'flex-start'};
const paperSx: StyleSx = {p: 1, minWidth: 132, maxWidth: 280};

type Props = Sx & {
  afu: Edge.Afu;
};

export function DeviceUploading({sx, afu}: Props) {
  const [menu, setMenu] = useState<HTMLElement | null>(null);

  const renderQueue = () => {
    const {queue, state} = afu;

    if (state === 'disabled') {
      return null;
    }

    if (!isNil(queue) && !isNil(queue.files) && !isNil(queue.size)) {
      return (
        <Typography data-id="queue-size">
          {filesize(queue.size, {round: 1})} in {queue.files} {pluralizeN('file', queue.files)}
        </Typography>
      );
    }

    return <Typography data-id="queue-size">No files in queue</Typography>;
  };

  const renderButtonValue = () => {
    if (afu.queue && !isNil(afu.queue.files)) {
      return `${afu.queue.files} ${pluralizeN('file', afu.queue.files)}`;
    }

    return 'Up to date';
  };

  return (
    <>
      <Tooltip title="Automatic file upload">
        <Button
          data-id="upload-indicators"
          sx={packSx(rootSx, sx)}
          variant="outlined"
          color="info"
          disableRipple={false}
          onClick={(e) => setMenu(e.currentTarget)}
        >
          <CloudDoneIcon />

          <Typography data-id="device_card_transferQueue" noWrap={true}>
            {renderButtonValue()}
          </Typography>
        </Button>
      </Tooltip>

      <Popover
        anchorEl={menu}
        open={!isNil(menu)}
        slotProps={{paper: {sx: paperSx}}}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        onClose={() => setMenu(null)}
      >
        <Typography mb={0.25} fontWeight={600}>
          Automatic file upload
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          divider={<Divider orientation="vertical" flexItem={true} />}
        >
          <Typography data-id="queue-state" component="span" color={getStateColor(afu.state)}>
            {formatState(afu.state)}
          </Typography>

          {renderQueue()}
        </Stack>

        {afu.error && (
          <Typography data-id="queue-message" mt={0.25} color="error.main">
            {afu.error.message}
          </Typography>
        )}
      </Popover>
    </>
  );
}

function formatState(state: Edge.AfuState): string {
  if (state === 'idle') {
    return 'Ready';
  }

  return capitalize(state);
}

function getStateColor(state: Edge.AfuState): string {
  if (state === 'idle') return 'secondary.main';

  if (state === 'error') return 'error.main';

  if (state === 'uploading') return 'primary.main';

  return 'text.disabled';
}
