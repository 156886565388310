import {useQuery} from '@tanstack/react-query';
import {AlertsApi, AlertsMapper} from 'app/components/entities/alerts';

export function useUnreadAlertsQuery() {
  return useQuery({
    queryKey: ['alerts', 'unread'],
    queryFn: async () => {
      const response = await AlertsApi.getUnreadAlerts();
      return response.map(AlertsMapper.mapAlert);
    },
  });
}
