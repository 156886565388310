import React from 'react';
import {Box, ButtonBase, Tooltip, Typography, lighten} from '@mui/material';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import {StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {Maintenance, formatMaintenanceTimestamp} from 'app/components/entities/maintenance';

const rootSx: StyleSx = {
  py: 1,
  borderRadius: 1,
  bgcolor: (t) => lighten(t.palette.warning.light, 0.9),
};

interface Props extends Sx {
  maintenance: Maintenance.Entity;
  onExpand: () => void;
}

export function CompactMaintenance({sx, maintenance, onExpand}: Props) {
  return (
    <Tooltip
      placement="right"
      title={
        <Box>
          <Typography component="strong">
            {maintenance.summary} {formatMaintenanceTimestamp(maintenance.start)}
          </Typography>
        </Box>
      }
    >
      <ButtonBase sx={packSx(rootSx, sx)} onClick={onExpand}>
        <BuildCircleIcon color="warning" />
      </ButtonBase>
    </Tooltip>
  );
}
