import {useEffect} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {ScheduleApiService} from 'app/services/api/schedule/ScheduleApiService';
import {ScheduleMapper} from 'app/util/mappers/ScheduleMapper';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';
import {Ws} from 'app/contracts/ws';
import {Schedule} from 'app/domain/schedule';

interface Args {
  enabled: boolean;
  deviceId: string;
}

export function useActualEvent({deviceId, enabled}: Args): Schedule.Event | undefined {
  const queryClient = useQueryClient();

  const {data: event} = useQuery({
    queryKey: ['device-actual-event', deviceId],
    queryFn: async () => {
      try {
        const contract = await ScheduleApiService.getDeviceActualEvent(deviceId);

        if (!contract) {
          return null;
        }

        return ScheduleMapper.mapEvent(contract);
      } catch {
        return null;
      }
    },
  });

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const onScheduleChange = async (data: Ws.ScheduleChange) => {
      if (data.Body.DeviceID === deviceId) {
        await queryClient.invalidateQueries({queryKey: ['device-actual-event', deviceId]});
      }
    };

    const onEventChange = async (data: Ws.ScheduleEventChange) => {
      const ids = new Set(Object.keys(data.Body.devices));

      if (ids.has(deviceId)) {
        await queryClient.invalidateQueries({queryKey: ['device-actual-event', deviceId]});
      }
    };

    WS.on(EVENT_TYPE.EVENT_SCHEDULE_CHANGED, onScheduleChange);
    WS.on(EVENT_TYPE.CHRONO_EVENT_CHANGED, onEventChange);

    return () => {
      WS.off(EVENT_TYPE.EVENT_SCHEDULE_CHANGED, onScheduleChange);
      WS.off(EVENT_TYPE.CHRONO_EVENT_CHANGED, onEventChange);
    };
  }, [enabled, deviceId, queryClient]);

  return event ?? undefined;
}
