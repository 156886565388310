import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {useUnitInterfaces} from 'app/components/entities/network';
import {Sx} from 'app/types/common';
import {UnitInterface} from 'app/components/features/network/ui/UnitInterface/UnitInterface';

interface Props extends Sx {
  deviceId: string;
}

export function UnitInterfaces({sx, deviceId}: Props) {
  const {data: interfaces} = useUnitInterfaces(deviceId);

  if (!interfaces) {
    return (
      <Box sx={sx}>
        <Typography>Loading interfaces</Typography>
      </Box>
    );
  }

  return (
    <Stack sx={sx} direction="row" gap={2}>
      {interfaces.map((i) => (
        <UnitInterface key={i.id} int={i} />
      ))}
    </Stack>
  );
}
