import {BaseProvider} from 'app/util/OAuthProvider/BaseProvider';
import {OAuthProviderOptions} from 'app/util/OAuthProvider/types';

class DropboxProvider extends BaseProvider {
  constructor(options: OAuthProviderOptions) {
    super(options);

    this.name = 'Dropbox';
  }
}

export {DropboxProvider};
