/* eslint-disable @typescript-eslint/member-ordering */
import {BaseStreamingDestinationModel} from 'app/components/StreamingServices/StreamingDestinations/BaseStreamingDestinationModel';
import {STREAMING_SERVICE_PROP_NAME} from 'app/components/StreamingServices/constants';

class OAuth2StreamingDestinationModel extends BaseStreamingDestinationModel {
  protected processOptionsData(json) {
    const options = json[this.getOptionsPropName()];

    return {
      error: options.Error,
      errorTime: options.ErrorTime,
      externalID: options.ExternalID,
      login: options.Login,
      rToken: options.RToken,
      service: options.Service,
      token: options.Token,
      userName: options.UserName,
    };
  }

  protected processSettingsData(json): Record<string, any> {
    const settings = json[this.getSettingsPropName()];

    if (!settings) {
      return {};
    }

    return {
      title: settings.Title,
      description: settings.Description,
      privacy: settings.Privacy,
      publishDestination: settings.PublishDestination,
      publishDestinationID: settings.PublishDestinationID,
    };
  }

  protected getOptionsPropName() {
    return STREAMING_SERVICE_PROP_NAME.OAUTH2;
  }

  getUserNameSetting() {
    return this.getOptions().userName;
  }

  getUserPictureSetting() {
    // TODO: Update fake data
    return this.getSettings().userPicture || '';
  }

  getTitleSetting() {
    return this.getSettings().title || '';
  }

  getDescriptionSetting() {
    return this.getSettings().description || '';
  }

  getPrivacySetting() {
    return this.getSettings().privacy;
  }

  getDestinationSetting() {
    return this.getSettings().publishDestination;
  }

  getDestinationIDSetting(): string | undefined {
    return this.getSettings().publishDestinationID || undefined;
  }

  protected optionsToJSON({
    error,
    errorTime,
    externalID,
    login,
    rToken,
    service,
    token,
    userName,
  }) {
    return {
      Error: error,
      ErrorTime: errorTime,
      ExternalID: externalID,
      Login: login,
      RToken: rToken,
      Service: service,
      Token: token,
      UserName: userName,
    };
  }

  protected settingsToJSON({
    title,
    description,
    privacy,
    publishDestination,
    publishDestinationID,
  }: Record<string, any>) {
    return {
      Title: title,
      Description: description,
      Privacy: privacy,
      PublishDestination: publishDestination,
      PublishDestinationID: publishDestinationID,
    };
  }
}

export {OAuth2StreamingDestinationModel};
