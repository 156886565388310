import React from 'react';
import {Stack, StackProps} from '@mui/material';
import {packSx} from 'app/util/packSx/packSx';

export function FeatureBox({children, sx, ...props}: React.PropsWithChildren<StackProps>) {
  return (
    <Stack
      sx={packSx({flexShrink: 0, flexGrow: 0, height: 50}, sx)}
      alignItems="center"
      direction="row"
      {...props}
    >
      {children}
    </Stack>
  );
}
