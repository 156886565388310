import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {collectDevicesStatisticsData} from 'app/components/FleetManager/utils';
import {useState, useEffect} from 'react';

export function useDevicesSummary(devices: AnyDeviceModelType[]): State {
  const [state, setState] = useState<State>(() => ({
    active: 0,
    offline: 0,
    online: 0,
    recording: 0,
    streaming: 0,
  }));

  useEffect(() => {
    setState(collectDevicesStatisticsData(devices));
  }, [devices]);

  return state;
}

interface State {
  online: number;
  offline: number;
  recording: number;
  streaming: number;
  active: number;
}
