import React from 'react';
import classNames from 'classnames';
import {
  ClassName,
  Callback,
} from 'app/types/common';
import {Icons} from 'app/util/icons';
import {PreviewUpdateStrategy} from 'app/hooks/Device/Preview/types';
import {Preview} from 'app/components/sharedReactComponents/Preview/Preview';
import {SIZE} from 'app/constants';

interface Props extends ClassName {
  id: string;
  updateStrategy: PreviewUpdateStrategy;
  detailsUrl?: string;
  size?: SIZE;
  onClick?: Callback;
}

export const Thumbnail: React.VFC<Props> = ({
  className,
  id,
  updateStrategy,
  detailsUrl,
  size,
  onClick,
}) => {
  return (
    <div
      className={classNames('thumbnail', className)}
      data-id="thumbnail"
    >
      <Preview
        className="thumbnail__image"
        id={id}
        size={size}
        updateStrategy={updateStrategy}
        dataId="thumbnail_image"
      />

      <a
        className="thumbnail__activate-btn"
        data-id="thumbnail_details_button"
        href={detailsUrl}
        onClick={onClick}
      >
        {Icons.info().label('Details').reactComponent()}
      </a>
    </div>
  );
};
