import {useQuery} from '@tanstack/react-query';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {EdgeMapper} from 'app/util/mappers/edge/EdgeMapper';

interface Args {
  deviceId: string;
  enabled: boolean;
  staleTime?: number;
  refetchInterval?: number;
}

export function useDeviceStorage({deviceId, enabled, staleTime, refetchInterval}: Args) {
  return useQuery({
    queryKey: [`unit-storage`, deviceId],
    queryFn: async () => {
      const response = await DeviceApiService.getStorage(deviceId);

      if (response) {
        return EdgeMapper.mapStorage(response);
      }

      return null;
    },
    refetchInterval,
    staleTime,
    enabled,
  });
}
