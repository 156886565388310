import {Api} from 'app/api/Api';

/*
 * Workaround for tests to work because their code built not in webpack
 * and uses env variables directly
 */
function getBaseUrl(): string {
  if (typeof CIRRUS_API_BASE_URL !== 'undefined') {
    return CIRRUS_API_BASE_URL;
  }

  return process.env.CIRRUS_API_BASE_URL!;
}

const cirrusDefaultTeamApi = new Api({
  baseUrl: getBaseUrl(),
});

cirrusDefaultTeamApi.addCachedService('front', '1', {
  agreement: {
    __noteam__: true
  },
  users: {
    me: {
      __noteam__: true
    },
  },
  teams: {
    __noteam__: true
  },
});

export {
  cirrusDefaultTeamApi,
};
