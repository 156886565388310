import React from 'react';
import {Skeleton} from '@mui/material';
import {Sx} from 'app/types/common';
import {DeviceUploading} from 'app/components/features/edge/EdgeDevice/UploadingIndicator/DeviceUploading/DeviceUploading';
import {useUnitAfu} from 'app/components/entities/afu';

type Props = Sx & {
  deviceId: string;
  enabled: boolean;
};

export function UploadingIndicator({sx, deviceId, enabled}: Props) {
  const query = useUnitAfu({deviceId, enabled, refetchInterval: 30 * 1000});

  if (enabled && query.isLoading) {
    return <Skeleton sx={sx} variant="rounded" height={31} width={70} />;
  }

  if (query.isSuccess && query.data) {
    return <DeviceUploading sx={sx} afu={query.data} />;
  }

  return null;
}
