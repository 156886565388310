import React from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {SIZE} from 'app/constants';
import {ClassName} from 'app/types/common';

interface DeviceStatusIconProps extends ClassName {
  icon: any;
  label: string;
  labeled?: boolean;
}

const DeviceStatusIcon: React.FC<DeviceStatusIconProps> = ({
  className,
  icon,
  label,
  labeled,
  ...elementProps
}) => {
  return (
    <div className={classNames('device-status-icon', className)} {...elementProps}>
      <span className="device-status-icon__icon">{icon.size(SIZE.S).reactComponent()}</span>

      {labeled && <span className="device-status-icon__label">{label}</span>}
    </div>
  );
};

interface Props extends ClassName {
  labeled?: boolean;
}

const DeviceStatusStreamingIcon: React.FC<Props> = ({className, labeled}) => {
  return (
    <DeviceStatusIcon
      className={classNames('device-status-icon--streaming', className)}
      data-id="device_status_icon_streaming"
      icon={Icons.streaming()}
      label="Streaming"
      labeled={labeled}
    />
  );
};

const DeviceStatusRecordingIcon: React.FC<Props> = ({className, labeled}) => {
  return (
    <DeviceStatusIcon
      className={classNames('device-status-icon--recording', className)}
      data-id="device_status_icon_recording"
      icon={Icons.record()}
      label="Recording"
      labeled={labeled}
    />
  );
};

const DeviceStatusTranscribingIcon: React.FC<Props> = ({className, labeled}) => {
  return (
    <DeviceStatusIcon
      className={classNames('device-status-icon--transcribing', className)}
      data-id="device_status_icon_transcribing"
      icon={Icons.liveScrypt()}
      label="Transcribing"
      labeled={labeled}
    />
  );
};

export {DeviceStatusStreamingIcon, DeviceStatusRecordingIcon, DeviceStatusTranscribingIcon};
