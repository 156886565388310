import React from 'react';
import classNames from 'classnames';
import {Component} from 'app/types/common';
import {THEME} from 'app/constants';
import {CopyButton} from 'app/components/sharedReactComponents/CopyButton';

interface Props extends Component {
  value: string | number;
}

export const CopyText = ({className, value, dataId}: Props) => {
  return (
    <div className={classNames('copy-text', className)} data-id={dataId}>
      <span className="copy-text__value">{value}</span>

      <CopyButton className="copy-text__copy" theme={THEME.SUCCESS} text="" value={value} />
    </div>
  );
};
