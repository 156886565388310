import {BaseDeviceSettingModel} from 'app/models/Device/Settings/BaseDeviceSettingModel';
import {DeviceSettingsCollection} from 'app/models/Device/Settings/DeviceSettingsCollection';
import {DeviceSetting} from 'app/components/DeviceDetails/Models/types';

export class GroupDeviceSettingModel extends BaseDeviceSettingModel {
  #settingsCollection: DeviceSettingsCollection | null = null;

  _getSettings() {
    // rework as any cast
    return (this._setting as any).items || [];
  }

  hasSettings() {
    return this._getSettings().length > 0;
  }

  getSettings(): DeviceSetting[] {
    return this._getSettings();
  }

  /**
   * @param {string} id
   * @return {BaseDeviceSettingModel|undefined}
   */
  getSettingModelById(id) {
    if (this.#settingsCollection === null) {
      this.#settingsCollection = new DeviceSettingsCollection({settings: this.getSettings()});
    }

    return this.#settingsCollection.getSettingModelById(id);
  }
}
