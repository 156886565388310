import React from 'react';
import {FilterSelectorGroup} from 'app/components/sharedReactComponents/FilterSelector/FilterSelectorGroup';
import {Callback} from 'app/types/common';
import {
  FilterSwitch,
  FilterSwitchGroup,
} from 'app/components/sharedReactComponents/FilterSelector/types';

interface Props {
  filterSwitches: FilterSwitch[];
  filterSwitchGroups: FilterSwitchGroup[];
  activeFilterSwitches: Set<string>;
  collapsibleFilterSwitchGroups: Set<string>;
  collapsedFilterSwitchGroups: Set<string>;
  searchableFilterSwitchGroups: Set<string>;
  onClickFilterSwitcher: Callback;
  onToggleFilterSwitchGroupCollapse: Callback;
}

type NonEmptyFilterSwitchGroup = {
  filterSwitchGroup: FilterSwitchGroup;
  filterSwitches: FilterSwitch[];
};

export const FilterSelectorList: React.FC<Props> = ({
  filterSwitches,
  filterSwitchGroups,
  activeFilterSwitches,
  collapsibleFilterSwitchGroups,
  collapsedFilterSwitchGroups,
  searchableFilterSwitchGroups,
  onClickFilterSwitcher,
  onToggleFilterSwitchGroupCollapse,
}) => {
  const filterSwitchesGroupedMap: Record<string, FilterSwitch[]> = filterSwitches.reduce((acc, filterSwitch) => {
    const {group} = filterSwitch;

    if (!group) {
      return acc;
    }

    const filterSwitchesGroup = acc[group];

    if (filterSwitchesGroup) {
      filterSwitchesGroup.push(filterSwitch);
    } else {
      acc[group] = [filterSwitch];
    }

    return acc;
  }, {});

  const nonEmptyFilterSwitchGroups: NonEmptyFilterSwitchGroup[] = [];

  for (const filterSwitchGroup of filterSwitchGroups) {
    const filterSwitches = filterSwitchesGroupedMap[filterSwitchGroup.id];
    if (filterSwitches) {
      nonEmptyFilterSwitchGroups.push({
        filterSwitchGroup,
        filterSwitches,
      });
    }
  }

  const singleGroup = nonEmptyFilterSwitchGroups.length === 1;

  return (
    <div className="filter-selector__menu">
      {nonEmptyFilterSwitchGroups.map(({filterSwitchGroup, filterSwitches}) => (
        <FilterSelectorGroup
          key={filterSwitchGroup.id}
          filterSwitchGroup={filterSwitchGroup}
          filterSwitches={filterSwitches}
          activeFilterSwitches={activeFilterSwitches}
          collapsible={singleGroup ? false : collapsibleFilterSwitchGroups.has(filterSwitchGroup.id)}
          collapsed={collapsedFilterSwitchGroups.has(filterSwitchGroup.id)}
          searchable={searchableFilterSwitchGroups.has(filterSwitchGroup.id)}
          onClickFilterSwitcher={onClickFilterSwitcher}
          onToggleFilterSwitchGroupCollapse={onToggleFilterSwitchGroupCollapse}
        />
      ))}
    </div>
  );
};
