import React from 'react';
import classNames from 'classnames';
import {PropsListItem} from 'app/components/sharedReactComponents/PropsList/PropsListItem';
import {PropsListLabel} from 'app/components/sharedReactComponents/PropsList/PropsListLabel';
import {PropsListValue} from 'app/components/sharedReactComponents/PropsList/PropsListValue';
import {PropsListItemNote} from 'app/components/sharedReactComponents/PropsList/PropsListItemNote';
import {isNil} from 'app/util/isNil';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {Callback, ClassName} from 'app/types/common';

/**
 * @readonly
 * @enum {string}
 */
export enum PROPS_LIST_THEME {
  ADMIN = 'admin',
  DEFAULT = 'default',
  BOLD = 'bold',
  SLIM_VERTICAL = 'slim-vertical',
  SLIM_HORIZONTAL = 'slim-horizontal',
}

export interface Row {
  dataKey: string;
  label: string | React.ReactNode;
  value?: number | string | React.ReactNode;
  cellRenderer?: Callback;
  note?: Callback | React.ReactNode;
  visible?: boolean;
}

function processValue(value, defaultValue) {
  if (!isNil(value)) {
    return value;
  }

  return defaultValue;
}

interface ItemProps {
  data: any;
  row: Row;
  defaultValue: string;
}

const Item: React.FC<ItemProps> = ({
  data,
  row,
  defaultValue,
}) => {
  const {
    dataKey,
    label,
    value: rowValue,
    cellRenderer,
    note,
    visible,
  } = row;

  if (visible === false) {
    return null;
  }

  let value;
  if (cellRenderer) {
    try {
      value = cellRenderer({
        cellData: data[dataKey],
        rowData: data,
      });
    } catch (error: unknown) {
      value = (
        <Tooltip content={(error as Error).message}>
          <span style={{color: 'red'}}>
            error
          </span>
        </Tooltip>
      );
    }
  } else if (isNil(rowValue) === false) {
    value = rowValue;
  } else {
    value = data[dataKey];
  }

  return (
    <PropsListItem data-id={`props_list_item_${dataKey}`}>
      <PropsListLabel>
        {label}
      </PropsListLabel>

      <PropsListValue>
        {processValue(value, defaultValue)}
      </PropsListValue>

      {note && (
        <PropsListItemNote note={note}/>
      )}
    </PropsListItem>
  );
};

interface Props extends ClassName {
  data?: Record<string, any>;
  rows?: Row[];
  defaultValue?: string;
  theme?: PROPS_LIST_THEME;
}

export const PropsList: React.VFC<Props> = ({
  className,
  data = {},
  rows = [],
  defaultValue = 'N/A',
  theme = PROPS_LIST_THEME.DEFAULT,
  ...elementProps
}) => {
  return (
    <ul
      className={classNames('props-list', {
        [`props-list--theme-${theme}`]: Boolean(theme),
      }, className)}
      {...elementProps}
    >
      {rows.map((row, index) => (
        <Item
          key={isNil(row.dataKey) ? index : row.dataKey}
          data={data}
          row={row}
          defaultValue={defaultValue}
        />
      ))}
    </ul>
  );
};
