import dayjs from 'dayjs';
import moment, {DurationFormatSettings, Moment} from 'moment';
import 'moment-duration-format';
import {TimeStampSeconds} from 'app/types/common';
import {secondsToMilliseconds} from 'app/util/timeConverter';

enum PlayerTimeFormat {
  Minutes = 'mm:ss',
  Hours = 'H:mm:ss',
  Days = 'D H:mm:ss',
}

const DATE_FORMAT = 'D-MMM-YYYY';
const FULL_DATE_FORMAT = 'h:mm:ss A, D-MMM-YYYY';
const STARTED_AT_DATE_FORMAT = 'h:mm A, D-MMM-YYYY';

const formatDuration = (
  seconds: number | moment.Duration,
  format: string,
  trim: false | string = 'left',
): string => {
  return moment.duration(seconds, 'seconds').format(format, {
    trim,
    trunc: true,
  });
};

const formatHHMM = (seconds: number, delimiter = ':'): string =>
  formatDuration(seconds, `HH${delimiter}mm`, false);

const formatHHMMSS = (seconds: number, delimiter = ':'): string =>
  formatDuration(seconds, `HH${delimiter}mm${delimiter}ss`, false);

const formatPlayback = (seconds: number): string => {
  const duration = moment.duration(seconds, 'seconds');
  let format = PlayerTimeFormat.Minutes;

  if (duration.asHours() >= 1) {
    format = PlayerTimeFormat.Hours;
  }

  if (duration.asDays() >= 1) {
    format = PlayerTimeFormat.Days;
  }

  return formatDuration(seconds, format, false);
};

const formatMaintenanceNotification = (time: string): string => {
  const momentDate = moment(time);
  return momentDate.isValid() ? momentDate.format('LLL') : '';
};

/**
 * ```typescript
 * formatDate(1567437911.459); //=> "2-Sep-2019"
 * ```
 */
const formatDate = (seconds: number): string => moment.unix(seconds).format(DATE_FORMAT);

/**
 * ```typescript
 * formatAbbreviatedDate(1629894677); //=> "Aug 25"
 * ```
 */
const formatAbbreviatedDate = (timestamp: TimeStampSeconds): string =>
  moment.unix(timestamp).format('MMM DD');

/**
 * ```typescript
 * formatAbbreviatedDateWithYear(1629894677); //=> "Aug 25, 2021"
 * ```
 */
const formatAbbreviatedDateWithYear = (timestamp: TimeStampSeconds): string =>
  moment.unix(timestamp).format('MMM DD, YYYY');

const formatDateTime = (seconds: number, formatString: string): string =>
  moment.unix(seconds).format(formatString);

/**
 * ```typescript
 * formatFullDate(1567437911.459); //=> "6:25:11 PM, 2-Sep-2019"
 * ```
 */
const formatFullDate = (seconds: number): string => moment.unix(seconds).format(FULL_DATE_FORMAT);

/**
 * ```typescript
 * formatStartedAtDate(1567437911.459); //=> "6:25 PM, 2-Sep-2019"
 * ```
 */
const formatStartedAtDate = (seconds: number): string =>
  moment.unix(seconds).format(STARTED_AT_DATE_FORMAT);

/**
 * ```typescript
 * formatTimeOfDay(1567437911.459); //=> "06:25"
 * ```
 */
const formatTimeOfDay = (seconds: number): string => moment.unix(seconds).format('hh:mm');

/**
 * ```typescript
 * formatTwentyFourTimeOfDay(1567437911.459); //=> "18:25"
 * ```
 */
const formatTwentyFourTimeOfDay = (timestamp: TimeStampSeconds): string =>
  moment.unix(timestamp).format('HH:mm');

/**
 * ```typescript
 * formatFullTimeOfDay(1567437911.459); //=> "06:25 PM"
 * ```
 */
const formatFullTimeOfDay = (seconds: number): string => moment.unix(seconds).format('hh:mm A');

/**
 * ```typescript
 * formatYYYYMMDD(1567437911.459); //=> "2019-09-02"
 * ```
 */
const formatYYYYMMDD = (seconds: number): string => moment.unix(seconds).format('YYYY-MM-DD');

/**
 * ```typescript
 * formatDatetimeSource(1567437911.459); //=> "2019-09-02T06:25"
 * ```
 */
const formatDatetimeSource = (seconds: number): string =>
  moment.unix(seconds).format('YYYY-MM-DDThh:mm');

enum UptimeFormat {
  Seconds = 'ss [seconds]',
  Minutes = 'mm:ss',
  Hours = 'hh:mm:ss',
  Days = 'd[\u00A0][days] hh:mm:ss',
}

/**
 * @param seconds start timestamp in seconds
 * @param currentSeconds current timestamp in seconds
 */
const formatUptime = (seconds: number, currentSeconds?: number): string => {
  const currentTimeStamp =
    typeof currentSeconds === 'number' ? moment.unix(currentSeconds) : moment();
  const duration = currentTimeStamp.diff(moment.unix(seconds));
  const durationSeconds = moment.duration(duration, 'milliseconds');

  let format = UptimeFormat.Seconds;

  if (durationSeconds.asMinutes() >= 1) {
    format = UptimeFormat.Minutes;
  }

  if (durationSeconds.asHours() >= 1) {
    format = UptimeFormat.Hours;
  }

  if (durationSeconds.asDays() >= 1) {
    format = UptimeFormat.Days;
  }

  return formatDuration(durationSeconds, format, false);
};

/**
 * ```typescript
 * timeFromDate(1629800177, 1629894677); //=> "a day"
 * ```
 */
const timeFromDate = (timestampStart: TimeStampSeconds, timestampEnd: TimeStampSeconds): string => {
  return moment(formatYYYYMMDD(timestampEnd)).from(formatYYYYMMDD(timestampStart), true);
};

/**
 * ```typescript
 * formatDayFromNow('2021-03-25'); //=> "Today"
 * formatDayFromNow('2021-03-24'); //=> "Yesterday"
 * formatDayFromNow('2021-03-23'); //=> "2 days ago"
 * ```
 */
const formatDayFromNow = (date: string): string => {
  const momentDate = moment(date);
  const today = moment().startOf('day');
  const yesterday = moment().add(-1, 'days').startOf('day');

  if (momentDate.isSame(today, 'day')) {
    return 'Today';
  }

  if (momentDate.isSame(yesterday, 'day')) {
    return 'Yesterday';
  }

  return momentDate.from(today);
};

/**
 * ```typescript
 * formatDateFromNow('2021-03-25'); //=> "Today, Mar 25"
 * formatDateFromNow('2021-03-24'); //=> "Yesterday, Mar 24"
 * formatDateFromNow('2021-03-23'); //=> "2 days ago, Mar 23"
 * ```
 */
const formatDateFromNow = (date: string): string =>
  `${formatDayFromNow(date)}, ${moment(date).format('MMM D')}`;

export function timestampFromNow(timestamp: number): string {
  const now = dayjs();
  const instance = dayjs.unix(timestamp);

  let day = instance.fromNow();

  if (now.isSame(instance, 'day')) {
    day = 'today';
  } else if (now.subtract(1, 'day').isSame(instance, 'day')) {
    day = 'yesterday';
  }

  return `${day}, ${instance.format('ll')}`;
}

/**
 * ```typescript
 * timeHHMMToMilliseconds('22:31'); //=> 81060000
 * ```
 */
const timeHHMMToMilliseconds = (time: string): number => moment.duration(time).asMilliseconds();

/**
 * ```typescript
 * dateYYYYMMDDToMilliseconds('2017-09-03'); //=> 1504386000000
 * ```
 */
const dateYYYYMMDDToMilliseconds = (date: string): number =>
  secondsToMilliseconds(moment(date, 'YYYY-MM-DD', true).unix());

/**
 * ```typescript
 * isValidYYYYMMDD('2017-09-03'); //=> true
 * ```
 */
const isValidYYYYMMDD = (date: string): boolean => moment(date, 'YYYY-MM-DD', true).isValid();

/**
 * ```typescript
 * getTimeToDate(moment().add({m: 22, s: 13})) => 00:22:13
 * getTimeToDate(moment().add({h: 2, m: 22})) => 2 hours 22 mins
 * getTimeToDate(moment().add({h: 5, m: 22})) => 5 hours
 * getTimeToDate(moment().add({d: 3, h: 5, m: 22})) => 3 days 5 hours
 * ```
 */
const getTimeToDate = (
  timestamp: TimeStampSeconds,
  settings: DurationFormatSettings = {},
): string => {
  const diff = moment.unix(timestamp).diff(moment());
  const duration = moment.duration(diff).abs();

  if (duration.asHours() < 1) {
    return duration.format('hh:mm:ss', {trim: false});
  }

  if (duration.asHours() < 4) {
    return duration.format('h [hours] m [mins]', {trunc: true});
  }

  if (duration.asHours() < 24) {
    return duration.format('h [hours]', {trunc: true});
  }

  return duration.format('d [days] h [hours]', {...settings, trunc: true});
};

export function counterFormat(timestamp: TimeStampSeconds) {
  const diff = moment.unix(timestamp).diff(moment());
  const duration = moment.duration(diff).abs();

  return duration.format('hh:mm:ss', {trim: false});
}

const isSameDay = (first: Moment, second: Moment): boolean => {
  return first.isSame(second, 'days');
};

const isPassedTimestamp = (timestamp: TimeStampSeconds) => {
  return moment.unix(timestamp).isBefore(moment(), 'seconds');
};

const isAfterDay = (timestampStart: TimeStampSeconds, timestampEnd: TimeStampSeconds): boolean => {
  return moment.unix(timestampEnd).isAfter(moment.unix(timestampStart), 'day');
};

/**
 * ```typescript
 * getCountdownToTimestamp((Date.now() / 1000) + 3600); //=> "01:00:00"
 * ```
 */
const getCountdownToTimestamp = (timestamp: TimeStampSeconds) => {
  const target = moment.unix(timestamp);
  return formatHHMMSS(target.diff(moment(), 'seconds'));
};

/**
 * ```typescript
 * dateTimeStampStringToSeconds("2021-12-02T20:40:51Z"); //=> 1638477651
 * ```
 */
const dateTimeStampStringToSeconds = (date: string): TimeStampSeconds => moment(date).unix();

export {
  formatHHMM,
  formatHHMMSS,
  formatDate,
  formatDateTime,
  formatPlayback,
  formatFullDate,
  formatStartedAtDate,
  formatTimeOfDay,
  formatAbbreviatedDate,
  formatAbbreviatedDateWithYear,
  formatFullTimeOfDay,
  formatTwentyFourTimeOfDay,
  formatYYYYMMDD,
  formatDatetimeSource,
  formatUptime,
  timeFromDate,
  formatDayFromNow,
  formatDateFromNow,
  formatMaintenanceNotification,
  timeHHMMToMilliseconds,
  dateYYYYMMDDToMilliseconds,
  isValidYYYYMMDD,
  getTimeToDate,
  isSameDay,
  isPassedTimestamp,
  isAfterDay,
  getCountdownToTimestamp,
  dateTimeStampStringToSeconds,
};
