import React, {PropsWithChildren} from 'react';
import classNames from 'classnames';
import {Icons} from 'app/util/icons';
import {THEME} from 'app/constants';
import {ClassName} from 'app/types/common';

interface Props extends PropsWithChildren<ClassName> {
  warning: boolean;
  title: string;
}

export const StreamStatusContainer = ({className, title, warning, children}: Props) => {
  return (
    <div className={classNames('stream-status-container', className)}>
      <div className="stream-status-container__header">
        <span className="stream-status-container__title">{title}</span>
        {warning && Icons.warning().theme(THEME.DANGER).reactComponent()}
      </div>
      {children}
    </div>
  );
};
