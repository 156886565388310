import React from 'react';
import {Box, Dialog, Stack, Typography} from '@mui/material';
import {Prompt} from 'app/types/common';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {ProgressButton} from 'app/components/sharedReactComponents/ProgressButton';
import {THEME, VARIANT} from 'app/constants';
import {Button} from 'app/components/sharedReactComponents/Button';

interface Props extends Prompt {
  name: string;
  platform: App.Connect.Platform;
  onRemove: Fn;
}

export function RemoveAccountDialog({open, name, platform, onRemove, onClose}: Props) {
  const isZoom = platform === 'zoom';
  const img = isZoom ? 'zoom-logo' : 'teams-logo';

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogClose onClose={onClose} />
      <Box p={4}>
        <Box
          mb={3}
          component="img"
          src={`/assets/img/logo/${img}.png`}
          srcSet={`/assets/img/logo/${img}@2x.png 2x`}
          alt=""
        />

        <Typography variant="h5" mb={5}>
          Do you want to {isZoom ? 'sign out from' : 'unpair'} <strong>{name}</strong>
        </Typography>

        <Stack direction="row" gap={2} justifyContent="center">
          <ProgressButton
            dataId="confirm-remove-btn"
            variant={VARIANT.SOLID}
            theme={THEME.DANGER}
            autoFocus={true}
            onClick={onRemove}
          >
            Confirm
          </ProgressButton>

          <Button
            dataId="cancel-remove-btn"
            theme={THEME.SECONDARY}
            variant={VARIANT.OUTLINE}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
