import {isNil} from 'app/util/isNil';

export function arrayToMap<
  Entity extends Record<string, any>,
  Key extends keyof Entity
>(
  arr: Entity[],
  key: Key,
): Map<Entity[Key], Entity> {
  const pairs = arr.map<[Entity[Key], Entity]>((entity) => {
    return [entity[key], entity];
  });

  return new Map(pairs.filter(([key]) => !isNil(key)));
}
