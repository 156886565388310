import React from 'react';
import {Stack} from '@mui/material';
import {DeviceStatusIndicator} from 'app/components/FleetManager/DeviceCard/StatusList/DeviceStatusIndicator';
import {FleetManagerColumnId} from 'app/components/FleetManager/constants';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {FleetManagerColumn} from 'app/components/FleetManager/types';
import {RemoteLoginButton} from 'app/components/sharedReactComponents/RemoteLogin';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {formatFullDate} from 'app/util/time';
import {format} from 'app/util/decimal';
import {isNil} from 'app/util/isNil';
import {DeviceHealthIndicator} from 'app/components/sharedReactComponents/DeviceHealthIndicator/DeviceHealthIndicator';
import {UnifySwitcher} from 'app/components/sharedReactComponents/UnifySwitcher/UnifySwitcher';

const modelCell = (): FleetManagerColumn => ({
  dataKey: FleetManagerColumnId.Model,
  width: 108,
  label: 'Model',
  cellRenderer: ({device}) => device.getModelName(),
});

const statusCell = (props?: Partial<FleetManagerColumn>): FleetManagerColumn => ({
  dataKey: FleetManagerColumnId.Status,
  width: 82,
  label: 'Status',
  cellRenderer: ({device, progress}) => (
    <DeviceStatusIndicator device={device} progress={progress} />
  ),
  ...props,
});

const ipAddressCell = (): FleetManagerColumn => ({
  dataKey: FleetManagerColumnId.IpAddress,
  width: 100,
  label: 'IP address',
  cellRenderer: ({device}) => device.getIPAddress(),
});

const serialNumberCell = (): FleetManagerColumn => ({
  dataKey: FleetManagerColumnId.SerialNumber,
  width: 100,
  label: 'Serial number',
  cellRenderer: ({device}) => device.getSerialNumber(),
});

const hostNameCell = (): FleetManagerColumn => ({
  dataKey: FleetManagerColumnId.HostName,
  width: 170,
  label: 'Host',
  detailedLabel: 'Host name',
  cellRenderer: ({device}) => device.getHostName(),
});

const firmwareCell = (): FleetManagerColumn => ({
  dataKey: FleetManagerColumnId.FirmwareVersion,
  width: 100,
  label: 'Firmware',
  cellRenderer: ({device}) => device.getFirmwareVersion(),
});

const systemLoadCell = (): FleetManagerColumn => ({
  dataKey: FleetManagerColumnId.SystemLoad,
  width: 62,
  label: 'Sys. load',
  detailedLabel: 'System load',
  cellRenderer: ({system}) => {
    return system?.load ? `${system.load}%` : '';
  },
});

const temperatureCell = (): FleetManagerColumn => ({
  dataKey: FleetManagerColumnId.Temperature,
  width: 59,
  label: 'Temp.',
  detailedLabel: 'Temperature',
  cellRenderer: ({system}) => {
    return system?.temperature ? `${system.temperature}°C` : '';
  },
});

const uptimeCell = (): FleetManagerColumn => ({
  dataKey: FleetManagerColumnId.Uptime,
  width: 68,
  label: 'Uptime',
  detailedLabel: 'Device uptime',
  cellRenderer: ({device, system}) => {
    const uptimeFormatted = device.getUptimeFormatted(system?.uptime);

    if (!uptimeFormatted) {
      return null;
    }

    return (
      <Tooltip
        content={system?.uptime ? `Since: ${formatFullDate(system.uptime)}` : null}
        delay={250}
      >
        {uptimeFormatted}
      </Tooltip>
    );
  },
});

const storageCell = (): FleetManagerColumn => ({
  dataKey: FleetManagerColumnId.Storage,
  width: 89,
  label: 'Storage',
  detailedLabel: 'Storage: Free space',
  cellRenderer: ({storage}) => {
    if (storage) {
      return format(storage.free, 0.1, 1);
    }

    return null;
  },
});

const transferQueueCell = (): FleetManagerColumn => ({
  dataKey: FleetManagerColumnId.TransferQueue,
  width: 89,
  label: 'Queue',
  detailedLabel: 'Transfer queue',
  cellRenderer: ({afu}) => {
    if (isNil(afu) || afu.state === 'disabled') {
      return '';
    }

    return format(afu.queue?.size ?? 0, 0.1, 1);
  },
});

const healthCell = (props?: Partial<FleetManagerColumn>): FleetManagerColumn => ({
  dataKey: FleetManagerColumnId.Health,
  width: 68,
  label: 'Health',
  detailedLabel: 'Device health',
  cellRenderer: ({device}) => (
    <DeviceHealthIndicator
      warnings={device.getWarnings()}
      offline={device.isOffline()}
      loading={device.isStarting()}
    />
  ),
  ...props,
});

const accessCell = (): FleetManagerColumn => ({
  dataKey: FleetManagerColumnId.Access,
  width: 158,
  label: 'Access',
  cellRenderer: ({device, role}) => {
    const model = device.getModelName();

    if (ModelService.isUnify(model) && device.isDown()) {
      return (
        <UnifySwitcher
          project={device}
          action="start"
          size="small"
          disabled={!role.canManageUnify()}
        />
      );
    }

    if (!device.capabilities.login) {
      return null;
    }

    const showSwitcher = !ModelService.isLivescrypt(model) && !ModelService.isNano(model);

    const access = device.capabilities.login;

    const paymentAccess = role.canEditBilling();

    const deviceId = device.getId();
    const offline = device.isOffline();

    return (
      <Stack direction="row" alignItems="center" gap={0.5}>
        <RemoteLoginButton
          type="admin"
          premium={access}
          paymentAccess={paymentAccess}
          deviceId={deviceId}
          disabled={offline}
          size="small"
        />

        {showSwitcher && (
          <RemoteLoginButton
            type="switcher"
            deviceId={deviceId}
            premium={access}
            paymentAccess={paymentAccess}
            disabled={offline}
            size="small"
          />
        )}
      </Stack>
    );
  },
});

export {
  modelCell,
  statusCell,
  ipAddressCell,
  serialNumberCell,
  firmwareCell,
  systemLoadCell,
  temperatureCell,
  uptimeCell,
  storageCell,
  transferQueueCell,
  healthCell,
  accessCell,
  hostNameCell,
};
