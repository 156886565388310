import React from 'react';
import {PROPS_LIST_THEME, PropsList} from 'app/components/sharedReactComponents/PropsList';
import {Icons} from 'app/util/icons';
import {THEME} from 'app/constants';
import {StreamStatusContainer} from 'app/components/pages/MeetingDetails/StreamStatus/StreamStatusDetails/StreamStatusContainer/StreamStatusContainer';

interface Props {
  params: App.Connect.StreamingParams;
  joined: boolean;
}

interface CellProps {
  cellData: number | string;
}

const latencyRenderer = ({cellData}: CellProps) => (
  <span data-id="srt_status_latency">{cellData} ms</span>
);
const packetsLostRenderer = ({cellData}: CellProps) => (
  <span data-id="srt_status_packets_lost">{cellData} %</span>
);

export const StreamingParameters = ({params, joined}: Props) => {
  const renderLabel = (label: string) => (
    <span>{Icons.srt().theme(THEME.SECONDARY).label(label).reactComponent()}</span>
  );

  const {latency, packetsLost} = params;

  return (
    <StreamStatusContainer
      className="stream-status-params"
      title="Streaming parameters"
      warning={!joined}
    >
      <PropsList
        className="stream-status-params__list"
        theme={PROPS_LIST_THEME.BOLD}
        data={{
          latency,
          packetsLost,
        }}
        rows={[
          {
            dataKey: 'latency',
            label: renderLabel('Latency'),
            cellRenderer: latencyRenderer,
          },
          {
            dataKey: 'packetsLost',
            label: renderLabel('Packets lost'),
            cellRenderer: packetsLostRenderer,
          },
        ]}
      />
    </StreamStatusContainer>
  );
};
