import {useCallback, useState} from 'react';
import {toggleInSet} from 'app/util/toggleInSet';
import {isSetsEquals} from 'app/util/isSetsEquals';
import {differenceOfSets} from 'app/util/differenceOfSets';
import {unionOfSets} from 'app/util/unionOfSets';

type SelectType = string | string[];

function argsToSet(ids?: SelectType): Set<string> {
  if (typeof ids === 'string') {
    return new Set<string>([ids]);
  }

  if (Array.isArray(ids)) {
    return new Set<string>(ids);
  }

  return new Set();
}

export function useSelectable() {
  const [selected, setSelected] = useState(new Set());

  const checkIsItemSelected = useCallback((id: string) => selected.has(id), [selected]);

  const toggleSelection = useCallback((id: string) => {
    setSelected(selected => toggleInSet(selected, id));
  }, []);

  const select = useCallback((ids: SelectType) => {
    setSelected(selected => {
      const idsSet = argsToSet(ids);
      const nextSelected = unionOfSets(selected, idsSet);

      if (isSetsEquals(nextSelected, selected)) {
        return selected;
      }

      return nextSelected;
    });
  }, []);

  const deselect = useCallback((ids: SelectType) => {
    setSelected(selected => {
      const idsSet = argsToSet(ids);
      const nextSelected = differenceOfSets(selected, idsSet);

      if (isSetsEquals(nextSelected, selected)) {
        return selected;
      }

      return nextSelected;
    });
  }, []);

  const resetSelection = useCallback((ids?: SelectType) => setSelected(argsToSet(ids)), []);

  return {
    checkIsItemSelected,
    select,
    deselect,
    toggleSelection,
    resetSelection,
  };
}
