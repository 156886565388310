import React from 'react';
import classNames from 'classnames';
import {ClassName} from 'app/types/common';
import {AudioMeter} from 'app/components/sharedReactComponents/DeviceAudioMeter/AudioMeter/AudioMeter';

interface Props extends ClassName {
  value?: number;
  segmentsCount?: number;
  segmentsGap?: number;
  vertical?: boolean;
}

export const AudioMeterMono: React.VFC<Props> = ({
  className,
  value,
  segmentsCount,
  segmentsGap,
  vertical,
}) => {
  return (
    <div className={classNames('mono-audio-meter', className)}>
      <AudioMeter
        dataId="audio_meter_mono"
        value={value}
        segmentsCount={segmentsCount}
        segmentsGap={segmentsGap}
        vertical={vertical}
      />
    </div>
  );
};
