import {front} from 'app/api/CirrusApi';
import {Notifications} from 'app/components/Notifications';
import {
  STREAMING_SERVICE_PROP_NAME,
  STREAMING_SERVICE_SETTINGS_PROP_NAME,
  STREAMING_SERVICE_TYPE,
} from 'app/components/StreamingServices/constants';
import {StreamingDestinationOptions} from 'app/components/StreamingServices/StreamingDestinations/types';
import {DeviceActiveStreamingInfo} from 'app/components/DeviceDetails/Models/types';

class BaseStreamingDestinationModel {
  protected data: Record<string, any> = {};
  protected options: Record<string, any> = {};
  protected settings: Record<string, any> = {};
  protected streamingInfo: DeviceActiveStreamingInfo | Record<string, any> = {};
  protected endpoint: any;

  constructor(json: StreamingDestinationOptions) {
    this.updateFromJSON(json);

    this.endpoint = front.streams(this.getId());
  }

  toJSON(data = {}) {
    const json = this.dataToJSON({
      ...this.data,
      ...data,
    });

    if (this.getOptionsPropName()) {
      json[this.getOptionsPropName()] = this.optionsToJSON({
        ...this.getOptions(),
        ...data,
      });
    }

    if (this.getSettingsPropName()) {
      json[this.getSettingsPropName()] = this.settingsToJSON({
        ...this.getSettings(),
        ...data,
      });
    }

    return json;
  }

  isDeleted(): boolean {
    return this.data.deleted;
  }

  getLastModified() {
    return this.data.lastModified;
  }

  getName(): string {
    return this.data.name;
  }

  async updateName(name: string) {
    return this.updateAction({name});
  }

  getId(): string {
    return this.data.id;
  }

  getType(): STREAMING_SERVICE_TYPE {
    return this.data.type;
  }

  getOptions() {
    return this.options;
  }

  getSettings() {
    return this.settings;
  }

  getStartTime() {
    return this.getStreamingInfo().startTime || null;
  }

  getStreamingURL() {
    return this.getStreamingInfo().url || null;
  }

  async deleteAction() {
    return this.endpoint.delete();
  }

  async updateAction(data): Promise<any> {
    const newData = this.toJSON(data);

    return this.endpoint.put(newData).then(() => {
      // TODO: May be from `response`?
      this.updateFromJSON(newData);
    });
  }

  isStreaming() {
    return this.data.streaming;
  }

  /**
   * Data from DeviceModel.getActiveStreamingInfo()
   */
  setStreamingInfo(data: DeviceActiveStreamingInfo) {
    this.streamingInfo = data;
  }

  getSelectedOnDeviceId() {
    return this.getStreamingDeviceId();
  }

  getStreamingDeviceId() {
    return this.data.lockByDevice || null;
  }

  async startStreamingAction(deviceId: string) {
    return this.endpoint
      .start()
      .post({DeviceID: deviceId})
      .catch(async (error) => {
        let errorMessage = "Can't start streaming";

        const errorType = error?.data?.Error as string;
        if (errorType) {
          // Check error:
          // "Failed load StreamNow settings: Failed find StreamNow broadcast: googleapi: Error 403: The user is not enabled for live-streaming., liveStreamingNotEnabled"
          if (errorType.includes('googleapi') && errorType.includes('liveStreamingNotEnabled')) {
            errorMessage = 'Enable "Live Streaming" in your YouTube account.';
          }
        }

        Notifications.addErrorNotification(
          `Streaming start error: ${errorMessage} "${this.getName()}"`,
        );

        return Promise.reject(error);
      });
  }

  async stopStreamingAction(deviceId: string) {
    return this.endpoint
      .stop()
      .post({DeviceID: deviceId})
      .catch(async (error) => {
        Notifications.addErrorNotification(
          `Streaming stop error: Can't stop streaming "${this.getName()}"`,
        );

        return Promise.reject(error);
      });
  }

  async attachToDevice(deviceId: string) {
    return this.endpoint.attach().post({DeviceID: deviceId});
  }

  protected getOptionsPropName(): STREAMING_SERVICE_PROP_NAME | string {
    return '';
  }

  protected getSettingsPropName(): STREAMING_SERVICE_SETTINGS_PROP_NAME | string {
    return '';
  }

  protected processOptionsData(json: StreamingDestinationOptions): Record<string, any> {
    return {
      name: json.Name,
    };
  }

  protected processSettingsData(_json: StreamingDestinationOptions): Record<string, any> {
    return {};
  }

  protected optionsToJSON(_options: Record<string, any>): Record<string, any> {
    return {};
  }

  protected settingsToJSON(_settings: Record<string, any>): Record<string, any> {
    return {};
  }

  private updateFromJSON(json: StreamingDestinationOptions) {
    this.data = this.processData(json);
    this.options = this.processOptionsData(json);
    this.settings = this.processSettingsData(json);
  }

  private processData({
    CurrentlyStreaming = false,
    IsDeleted = false,
    LastModified = 0,
    Name = '',
    StreamID = '',
    Type,
    LockByDevice = '',
  }: StreamingDestinationOptions) {
    return {
      streaming: CurrentlyStreaming,
      deleted: IsDeleted,
      lastModified: LastModified,
      name: Name,
      id: StreamID,
      type: Type || '',
      lockByDevice: LockByDevice,
    };
  }

  private dataToJSON({
    streaming,
    deleted,
    lastModified,
    name,
    id,
    type,
    lockByDevice,
  }: Record<string, any>): StreamingDestinationOptions {
    return {
      CurrentlyStreaming: streaming,
      IsDeleted: deleted,
      LastModified: lastModified,
      Name: name,
      StreamID: id,
      Type: type,
      LockByDevice: lockByDevice,
    };
  }

  private getStreamingInfo() {
    return this.streamingInfo;
  }
}

export {BaseStreamingDestinationModel};
