import React from 'react';
import classNames from 'classnames';
import {Button} from 'app/components/sharedReactComponents/Button';
import {Icons} from 'app/util/icons';
import {PREVIEW_MODE} from 'app/components/DeviceDetails/ChannelDetailsTab/constants';
import {SIZE} from 'app/constants';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {FrameRate} from 'app/components/DeviceDetails/ChannelDetailsTab/DeviceStatusPreview/FrameRate';
import {Callback, ClassName} from 'app/types/common';

interface Props extends ClassName {
  muted: boolean;
  previewMode: PREVIEW_MODE;
  available: boolean;
  switcher: boolean;
  switcherTip?: string;
  onMuteClick: Callback;
  onPreviewModeChange: Callback;
}

export const DeviceStatusPreviewControls: React.VFC<Props> = ({
  className,
  muted,
  available,
  previewMode,
  switcher,
  switcherTip = '',
  onMuteClick,
  onPreviewModeChange,
}) => {
  const disabled = previewMode === PREVIEW_MODE.OFFLINE;
  const muteButtonDisabled = disabled || previewMode !== PREVIEW_MODE.LIVE_HD;
  const title = muted ? 'Unmute' : 'Mute';

  return (
    <div className={classNames('device-status-preview-controls', className)}>
      <Button
        className={classNames(
          'device-status-preview-controls__mute-button',
          muted && 'device-status-preview-controls__mute-button--muted',
        )}
        dataId="device_preview_mute_button"
        title={muteButtonDisabled ? undefined : title}
        size={SIZE.S}
        disabled={muteButtonDisabled}
        onClick={onMuteClick}
      >
        {Icons.volume().reactComponent()}
      </Button>

      <span className="device-status-preview-controls__separator" />

      <Tooltip
        content={
          !available ? (
            <div>
              Full frame rate and audio are <br />
              only available on premium plans
            </div>
          ) : undefined
        }
        delay={250}
      >
        <FrameRate
          previewMode={previewMode}
          disabled={disabled || !available || !switcher}
          tooltip={switcherTip}
          onPreviewModeChange={onPreviewModeChange}
        />
      </Tooltip>
    </div>
  );
};
