import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {Sx} from 'app/types/common';
import {grey} from '@mui/material/colors';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {
  formatEventMedia,
  formatEventFullTime,
} from 'app/components/sharedReactComponents/Events/utils';
import {Schedule} from 'app/domain/schedule';
import {EventStatus} from 'app/components/sharedReactComponents/Events/shared/EventStatus/EventStatus';

interface Props extends Sx {
  event: Schedule.Event;
}

export function EventDefinition({sx, event}: Props) {
  return (
    <Box sx={sx}>
      <Stack mb={0.5} direction="row" alignItems="center" justifyContent="space-between" gap={1}>
        <Typography data-id="event-title" minWidth={0} flex={1} fontWeight={600} component="span">
          <OverflowTooltip rowsNumber={1}>{event.title}</OverflowTooltip>
        </Typography>

        <Typography data-id="event-type">{formatEventMedia(event.media)}</Typography>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
        <Typography data-id="event-datetime" color={grey[700]}>
          {formatEventFullTime(event.start, event.end)}
        </Typography>

        <EventStatus dataId="event-status" event={event} />
      </Stack>
    </Box>
  );
}
