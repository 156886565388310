import {Schedule} from 'app/domain/schedule';

type Permissions = {
  start: boolean;
  stop: boolean;
  pause: boolean;
  extend: boolean;
};

export const defaultPermissions: Permissions = {
  start: false,
  stop: false,
  pause: false,
  extend: false,
};

const panopto: Permissions = {
  start: true,
  stop: true,
  pause: true,
  extend: true,
};

const chrono: Permissions = {
  start: true,
  stop: true,
  pause: false,
  extend: true,
};

const kaltura: Permissions = {
  start: true,
  stop: true,
  pause: false,
  extend: true,
};

const yuja: Permissions = {
  start: true,
  stop: true,
  pause: false,
  extend: true,
};

const opencast: Permissions = {
  start: true,
  stop: true,
  pause: true,
  extend: true,
};

export const permissions = new Map<Schedule.Cms, Permissions>([
  ['kaltura', kaltura],
  ['panopto', panopto],
  ['yuja', yuja],
  ['chrono', chrono],
  ['opencast', opencast],
]);
