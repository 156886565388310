import React from 'react';
import {useDeviceSourcePreview} from 'app/hooks/Device/Preview/useDeviceSourcePreview';
import {ClassName} from 'app/types/common';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';

interface Props extends ClassName {
  device: PearlMasterDeviceModel;
  sourceId: string;
}

export const SourceStatusImage: React.VFC<Props> = ({
  className,
  device,
  sourceId,
}) => {
  const imageSrc = useDeviceSourcePreview({
    device,
    sourceId,
  });

  return (
    <img
      className={className}
      src={imageSrc}
      alt=""
    />
  );
};
