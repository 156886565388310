import React from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {lightTheme} from 'app/themes/app';

export function MainTheme({children}: React.PropsWithChildren<{}>) {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
