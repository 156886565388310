enum DEVICE_PUBLISHER_TYPE {
  DASH = 'dash',
  HLS = 'hls',
  MPEGTS_RTP = 'mpegts-rtp',
  MPEGTS_UDP = 'mpegts-udp',
  NDI = 'ndi',
  RTMP = 'rtmp',
  RTP_UDP = 'rtp-udp',
  RTSP = 'rtsp',
  SRT = 'srt',
}

enum DEVICE_PUBLISHER_SETTING_ID {
  NAME = 'name',
  SINGLE_TOUCH = 'single-touch',
}

enum DEVICE_PUBLISHER_STATE {
  STARTED = 'started',
  STARTING = 'starting',
  STOPPED = 'stopped',
  ERROR = 'error',
}

/* new enums */
enum PublisherType {
  Dash = 'dash',
  Hls = 'hls',
  MpegtsRtp = 'mpegts-rtp',
  MpegtsUdp = 'mpegts-udp',
  Ndi = 'ndi',
  Rtmp = 'rtmp',
  RtpUdp = 'rtp-udp',
  Rtsp = 'rtsp',
  Srt = 'srt',
}

enum PublisherState {
  Started = 'started',
  Starting = 'starting',
  Stopped = 'stopped',
  Error = 'error',
}

enum PublisherSettingId {
  Name = 'name',
  SingleTouch = 'single-touch',
}

export {
  DEVICE_PUBLISHER_TYPE,
  DEVICE_PUBLISHER_SETTING_ID,
  DEVICE_PUBLISHER_STATE,
  /* new enums */
  PublisherType,
  PublisherState,
  PublisherSettingId,
};
