import {useCallback, useEffect, useState} from 'react';
import {Schedule} from 'app/domain/schedule';
import {isOngoing, isScheduled} from 'app/domain/schedule/utils';
import {parseMasterAndChannelIds} from 'app/components/DeviceDetails/utils';

interface Args {
  events: Schedule.Event[];
  enabled: boolean;
}

interface Return {
  activeDevices: Set<string>;
  getActualEvent: (id: string) => Schedule.Event | undefined;
  countOngoingEvents: (ids: string[]) => number;
  checkStartIsAllowed: (event: Schedule.Event) => boolean;
}

export function useScheduleSnapshot({events, enabled}: Args): Return {
  const [ongoing, setOngoing] = useState(new Map<string, Schedule.Event>());
  const [scheduled, setScheduled] = useState(new Map<string, Schedule.Event>());

  const [activeDevices, setActiveDevices] = useState(new Set<string>());

  useEffect(() => {
    if (!enabled) {
      setOngoing(new Map());
      return;
    }

    const filtered = events.filter((e) => isOngoing(e.status));
    const map = new Map<string, Schedule.Event>();

    filtered.forEach((event) => {
      const devices = Array.from(event.devices.keys());

      devices.forEach((id) => {
        const value = map.get(id);

        if (!value) {
          map.set(id, event);
        }
      });
    });

    setOngoing(map);
  }, [events, enabled]);

  useEffect(() => {
    if (!enabled) {
      setScheduled(new Map());
      return;
    }

    const filtered = events.filter((e) => isScheduled(e.status));
    const map = new Map<string, Schedule.Event>();

    filtered.forEach((event) => {
      const devices = Array.from(event.devices.keys());

      devices.forEach((id) => {
        const value = map.get(id);

        if (!value) {
          map.set(id, event);
        }
      });
    });

    setScheduled(map);
  }, [events, enabled]);

  const getActualEvent = useCallback(
    (deviceId: string, option?: Options) => {
      if (!enabled) {
        return;
      }

      const [master, channelIdx] = parseMasterAndChannelIds(deviceId);

      const kind = option?.kind;

      switch (kind) {
        case 'ongoing': {
          const event = ongoing.get(master);

          if (channelIdx) {
            if (!event) {
              return;
            }

            if (isChannelEvent(event, master, channelIdx)) {
              return event;
            }

            return;
          }

          return event;
        }

        case 'scheduled': {
          const event = scheduled.get(master);

          if (channelIdx) {
            if (!event) {
              return;
            }

            if (isChannelEvent(event, master, channelIdx)) {
              return event;
            }

            return;
          }

          return event;
        }

        default: {
          let event = ongoing.get(master);

          if (!event) {
            event = scheduled.get(master);
          }

          if (channelIdx) {
            if (!event) {
              return;
            }

            if (isChannelEvent(event, master, channelIdx)) {
              return event;
            }

            return;
          }

          return event;
        }
      }
    },
    [ongoing, scheduled, enabled],
  );

  useEffect(() => {
    if (!enabled) {
      setActiveDevices(new Set());
      return;
    }

    setActiveDevices(new Set([...ongoing.keys()]));
  }, [enabled, ongoing]);

  const countOngoingEvents = useCallback(
    (ids: string[] = []) => {
      if (!enabled) {
        return 0;
      }

      const uniqueEvents = new Set<string>();

      Array.from(ongoing.keys())
        .filter((deviceId) => ids.includes(deviceId))
        .forEach((deviceId) => {
          const event = ongoing.get(deviceId);

          if (event) {
            uniqueEvents.add(event.id);
          }
        });

      return uniqueEvents.size;
    },
    [enabled, ongoing],
  );

  const checkStartIsAllowed = useCallback(
    (event: Schedule.Event) => {
      const eventDevices = [...event.devices.keys()];

      let allowStart = false;

      for (let i = 0; i < eventDevices.length; i += 1) {
        const deviceId = eventDevices[i];

        if (deviceId) {
          const nextScheduled = scheduled.get(deviceId);

          if (ongoing.has(deviceId) || !nextScheduled || nextScheduled.id !== event.id) {
            allowStart = false;
            break;
          }

          allowStart = true;
        }
      }

      return allowStart;
    },
    [ongoing, scheduled],
  );

  return {activeDevices, getActualEvent, countOngoingEvents, checkStartIsAllowed};
}

interface Options {
  kind: 'ongoing' | 'scheduled';
}

function isChannelEvent(event: Schedule.Event, masterId: string, channelIdx: string): boolean {
  const deviceProfile = event.devices.get(masterId);

  if (!deviceProfile) {
    return false;
  }

  const channelProfile = deviceProfile.get(channelIdx);

  if (channelProfile?.recording || channelProfile?.streaming) {
    return true;
  }

  return false;
}
