import {Edge} from 'app/domain/edge';
import {Icons, SvgIcon} from 'app/util/icons';

const DEVICE_PRESET_SECTION_NAME: Record<Exclude<Edge.PresetSection, 'all'>, string> = {
  afu: 'Automatic File Upload',
  channels: 'Channels',
  cms: 'Content Management System',
  displays: 'Output ports',
  edid: 'EDID',
  avstudio: 'Epiphan Cloud',
  frontscreen: 'Touch screen',
  network: 'Network',
  sources: 'Inputs',
  system: 'System',
};

const SORTED_DEVICE_PRESET_SECTION_IDS: Edge.PresetSection[] = [
  'system',
  'network',
  'sources',
  'edid',
  'channels',
  'afu',
  'cms',
  'avstudio',
  'frontscreen',
  'displays',
];

const DEVICE_PRESET_SECTION_ICON: Record<Exclude<Edge.PresetSection, 'all'>, SvgIcon> = {
  afu: Icons.devicePresetAfu(),
  channels: Icons.devicePresetChannels(),
  cms: Icons.devicePresetCms(),
  displays: Icons.devicePresetDisplays(),
  edid: Icons.devicePresetEdid(),
  avstudio: Icons.devicePresetEpiphanCloud(),
  frontscreen: Icons.devicePresetFrontScreen(),
  network: Icons.devicePresetNetwork(),
  sources: Icons.devicePresetSources(),
  system: Icons.devicePresetSystem(),
};

const UNIFY_PRESET_SECTIONS: Edge.PresetSection[] = ['sources', 'channels', 'afu'];

const ALL_PRESET_SECTIONS: Edge.PresetSection[] = [
  'system',
  'network',
  'sources',
  'edid',
  'channels',
  'afu',
  'cms',
  'avstudio',
  'frontscreen',
  'displays',
];

export {
  DEVICE_PRESET_SECTION_NAME,
  DEVICE_PRESET_SECTION_ICON,
  SORTED_DEVICE_PRESET_SECTION_IDS,
  ALL_PRESET_SECTIONS,
  UNIFY_PRESET_SECTIONS,
};
