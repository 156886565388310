import React, {useEffect, useMemo} from 'react';
import {observer} from 'mobx-react';
import {InitializationPage} from 'app/components/InitializationPage/InitializationPage';
import {isNil} from 'app/util/isNil';
import {useCurrentTeamStore} from 'app/store/hooks';
import {useTeamsQuery} from 'app/hooks/useTeamsQuery';
import {CloudContext, CloudState} from 'app/components/entities/cloud/CloudContext';
import {UserAgreement, useProfile} from 'app/components/features/profile';
import {WS} from 'app/api/WebSocket/WS';
import {EVENT_TYPE} from 'app/api/WebSocket/constants';

function CloudUserRoot({children}: React.PropsWithChildren<{}>) {
  const currentTeamStore = useCurrentTeamStore();
  const enabled = currentTeamStore.initialized;
  const reloadTeam = currentTeamStore.reload;

  const teams = useTeamsQuery();
  const user = useProfile(enabled);

  useEffect(() => {
    const onTeamChange = () => {
      reloadTeam();
    };

    WS.on(EVENT_TYPE.TEAM_CHANGE, onTeamChange);

    return () => {
      WS.off(EVENT_TYPE.TEAM_CHANGE, onTeamChange);
    };
  }, [enabled, reloadTeam]);

  const state = useMemo<CloudState | undefined>(() => {
    if (!user || !teams.data) {
      return undefined;
    }

    return {teams: teams.data, user};
  }, [user, teams.data]);

  if (isNil(state)) {
    return <InitializationPage />;
  }

  const showAgreement = !state.user.checks.agreement;

  return (
    <CloudContext.Provider value={state}>
      {showAgreement && <UserAgreement />}
      {children}
    </CloudContext.Provider>
  );
}

export const CloudUser = observer(CloudUserRoot);
