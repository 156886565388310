import {Callback} from 'app/types/common';
import {isNil} from 'app/util/isNil';

type ColorLevels = [
  fair: number,
  bad: number
];

const Colors = {
  Disabled: '#e1e3e5',
  Green: '#8cbe3f',
  Orange: '#ffc800',
  Red: '#ff0048'
} as const;

export const getSegmentColor = (
  segment: number,
  active: boolean,
  levels: ColorLevels,
): string => {
  const [fair, bad] = levels;

  let color: string = Colors.Disabled;

  if (active) {
    color = Colors.Green;

    if (segment >= fair) {
      color = Colors.Orange;
    }

    if (segment >= bad) {
      color = Colors.Red;
    }
  }

  return color;
};

type Measure = 'width' | 'height';

type SegmentMeasure<T extends Measure> =
  | `${T}`
  | `segment${Capitalize<T>}`
  | `segment${Capitalize<T>}WithGap`;

type Height = Record<SegmentMeasure<'height'>, number>;
type Width = Record<SegmentMeasure<'width'>, number>;

interface DrawSegmentArgs {
  context: CanvasRenderingContext2D;
  segment: number;
  active?: boolean;
  vertical: boolean;
  levels: ColorLevels;
  w: Width;
  h: Height;
}

export const drawSegment = ({
  context,
  segment,
  levels,
  vertical,
  h,
  w,
  active = false,
}: DrawSegmentArgs): void => {
  const {
    width,
    segmentWidth,
    segmentWidthWithGap,
  } = w;

  const {
    height,
    segmentHeight,
    segmentHeightWithGap
  } = h;

  const color = getSegmentColor(segment, active, levels);
  context.fillStyle = color;

  if (vertical) {
    context.fillRect(0, height - segmentHeight - (segment * segmentHeightWithGap), width, segmentHeight);
  } else {
    context.fillRect(segment * segmentWidthWithGap, 0, segmentWidth, height);
  }
};

interface DrawCanvasArgs {
  value?: number;
  context: CanvasRenderingContext2D;
  width: number;
  height: number;
  vertical: boolean;
  segmentsGap: number;
  segmentsCount: number;
}

const Thresholds = {
  start: -60,
  fair: -18,
  bad: -9,
  end: -0,
} as const;

/**
 * {@link http://www.playdotsound.com/portfolio-item/decibel-db-to-float-value-calculator-making-sense-of-linear-values-in-audio-tools/}
 * {@link https://github.com/tomnomnom/vumeter}
 * {@link https://github.com/LinusU/vu-meter}
 * @param {number} value
 * @param {number} segmentsCount
 * @returns {number}
 */
function processValue(value: number, segmentsCount: number): number {
  const startThreshold = Thresholds.start;
  const endThreshold = Thresholds.end;

  const processed = Math.max(Math.min(value, endThreshold), startThreshold);

  const aspect = (Math.abs(startThreshold) - Math.abs(endThreshold)) / segmentsCount;

  let truncFunc: Callback<number>;
  let addVal = 0;

  if (segmentsCount <= 60) {
    truncFunc = Math.trunc;
    addVal = 1;
  } else {
    truncFunc = (num) => Math.ceil(num * 2) / 2;
    addVal = 0.5;
  }

  let val = truncFunc(processed);

  if (processed === val && processed < 0) {
    val += addVal;
  }

  return segmentsCount - Math.trunc(Math.abs(val) / aspect) - 1;
}

export const drawAudioMeter = ({
  value,
  context,
  width,
  height,
  vertical,
  segmentsGap,
  segmentsCount,
}: DrawCanvasArgs): void => {
  const containerWidthWithLastGap = width + segmentsGap;
  const segmentWidthWithGap = Math.round(containerWidthWithLastGap / segmentsCount);
  const segmentWidth = segmentWidthWithGap - segmentsGap;

  const containerHeightWithLastGap = height + segmentsGap;
  const segmentHeightWithGap = Math.round(containerHeightWithLastGap / segmentsCount);
  const segmentHeight = segmentHeightWithGap - segmentsGap;
  const processedValue = processValue(value ?? 0, segmentsCount);
  const levels: ColorLevels = [
    processValue(Thresholds.fair, segmentsCount),
    processValue(Thresholds.bad, segmentsCount),
  ];

  for (let segment = 0; segment < segmentsCount; segment++) {
    const params: DrawSegmentArgs = {
      context,
      segment,
      levels,
      vertical,
      active: isNil(value) ? false : segment <= processedValue,
      h: {
        height,
        segmentHeight,
        segmentHeightWithGap,
      },
      w: {
        width,
        segmentWidth,
        segmentWidthWithGap
      },
    };

    drawSegment(params);
  }
};
