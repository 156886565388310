import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogTitle, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';
import {useMounted} from 'app/hooks/useIsMounted';

interface Props extends Prompt {
  name: string;
  onDelete: () => Promise<void>;
}

export function DeletePresetDialog({open, name, onDelete, onClose}: Props) {
  const [loading, setLoading] = useState(false);
  const mounted = useMounted();

  const handleDelete = async () => {
    try {
      setLoading(true);
      await onDelete();
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Delete{' '}
        <Typography variant="inherit" color="primary.main" component="span">
          {name}
        </Typography>{' '}
        preset
      </DialogTitle>
      <DialogActions>
        <LoadingButton loading={loading} color="error" variant="contained" onClick={handleDelete}>
          Delete
        </LoadingButton>
        <Button variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
