import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';

interface Props extends Prompt {
  onDisable: () => Promise<void>;
}

export function DisableEdgeAfuDialog({open, onDisable, onClose}: Props) {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await onDisable();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        Disable Epiphan Edge File Management as your Automatic File Upload destination
      </DialogTitle>

      <DialogContent>
        <Typography textAlign="center">
          This will disable any Automatic File Uploading to Epiphan Edge currently in progress on
          your device.
        </Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant="contained" color="error" loading={loading} onClick={handleConfirm}>
          Disable
        </LoadingButton>

        <Button variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
