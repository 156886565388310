import React, {useState} from 'react';
import {Box, Stack, Typography, Button} from '@mui/material';
import classNames from 'classnames';
import {blue, grey} from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {ClassName} from 'app/types/common';
import {UnifyManagerSummary} from 'app/components/UnifyProjects/UnifyManager/UnifyManagerSummary/UnifyManagerSummary';
import {noop} from 'app/util/noop';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {DeviceCard} from 'app/components/FleetManager/DeviceCard/DeviceCard';
import {ColumnsHeader} from 'app/components/FleetManager/ColumnsHeader';
import {useUnifyMangerColumns} from 'app/components/UnifyProjects/hooks/useUnifyManagerColumns';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {useUnifySelection} from 'app/components/UnifyProjects/hooks/useUnifySelection';
import {BatchActionsPanel} from 'app/components/FleetManager/BatchActionsPanel';
import {areAllProjectsSelected} from 'app/components/UnifyProjects/utils';
import {useTeamPresets} from 'app/hooks/Device/Preset/usePresets';
import {MAX_OPENED_DEVICE_CARDS} from 'app/components/FleetManager/constants';
import {palette} from 'app/themes/app';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {Schedule} from 'app/domain/schedule';
import {Edge} from 'app/domain/edge';
import {PresetApiService} from 'app/services/api/preset/PresetApiService';
import {Notifications} from 'app/components/Notifications';
import {UserRole} from 'app/models/PermissionsModel/types';
import {TeamCapabilities} from 'app/models/Capabilities/TeamCapabilities';
import {CreateProjectDialog} from 'app/components/dialogs/CreateProjectDialog/CreateProjectDialog';

interface Props extends ClassName {
  teamId: string;
  role: UserRole;
  projects: AnyDeviceModelType[];
  actualEvents?: Map<string, Schedule.Event>;
  price: number;
  streamingDestinations: AnyStreamingDestinationModelType[];
  hasPremium: boolean;
  teamCapabilities: TeamCapabilities;
  getById: (di: string) => AnyDeviceModelType | undefined;
  onReload: () => Promise<void>;
  onChangeStreamingDestination: (...args: any) => any;
}

export function UnifyManager({
  className,
  teamId,
  role,
  projects,
  actualEvents,
  hasPremium,
  streamingDestinations,
  price,
  teamCapabilities,
  onChangeStreamingDestination,
  onReload,
  getById,
}: Props) {
  const [createDialog, setCreateDialog] = useState(false);

  const {columns, fixedColumns, arrangedColumns, isColumnActive, onChange, onChangeArrangement} =
    useUnifyMangerColumns();

  const {
    selected,
    toggleSelection,
    resetDeviceSelection,
    checkIsProjectSelected,
    onSelectActive,
    onSelectAll,
    onSelectEvents,
    onSelectOnline,
    onSelectRecording,
    onSelectStreaming,
    onSelectSuspended,
  } = useUnifySelection(projects, actualEvents);

  const {data: presets = []} = useTeamPresets({enabled: true});

  // Single handlers

  const handleStart = async (projectId: string) => {
    try {
      await DeviceApiService.startProject(projectId);
    } catch {
      Notifications.addErrorNotification(`Failed to start project`);
    }
  };

  const handleStop = async (projectId: string) => {
    try {
      await DeviceApiService.stopProject(projectId);
    } catch {
      Notifications.addErrorNotification(`Failed to stop project`);
    }
  };

  const handleApplyPreset = async (p: Edge.TeamPreset, deviceId: string) => {
    if (!hasPremium) {
      return;
    }

    try {
      await PresetApiService.applyTeamPreset(deviceId, p);
      await onReload();
    } catch {
      Notifications.addErrorNotification('Failed to apply preset to device');
    }
  };

  const handleDelete = async (projectId: string) => {
    try {
      await DeviceApiService.deleteProject(projectId, teamId);
      await onReload();
    } catch {
      Notifications.addErrorNotification(`Failed to delete project`);
    }
  };

  // Batch handlers

  const handleBatchStart = async (projectIds: string[]) => {
    await Promise.allSettled(projectIds.map((id) => DeviceApiService.startProject(id)));
  };

  const handleBatchStop = async (projectIds: string[]) => {
    await Promise.allSettled(projectIds.map((id) => DeviceApiService.stopProject(id)));
  };

  const handleBatchApplyPreset = async (p: Edge.TeamPreset, devices: string[]) => {
    if (!hasPremium) {
      return;
    }

    const results = await Promise.allSettled(
      devices.map((deviceId) => handleApplyPreset(p, deviceId)),
    );

    const failed = results.filter((r) => r.status === 'rejected').length;

    if (failed > 0) {
      Notifications.addErrorNotification(`Could not apply preset to ${failed} project(s)`);
    }

    await onReload();
  };

  const handleBatchDelete = async (projectIds: string[]) => {
    const results = await Promise.allSettled(
      projectIds.map((projectId) => DeviceApiService.deleteProject(projectId, teamId)),
    );

    const failedCount = results.filter((r) => r.status === 'rejected').length;

    if (failedCount > 0) {
      Notifications.addErrorNotification(`Failed to delete ${failedCount} project(s)`);
    }

    await onReload();
  };

  const handleCreate = async (name: string) => {
    await DeviceApiService.createUnify(teamId, {name});
    setCreateDialog(false);
  };

  const renderBody = () => {
    if (projects.length === 0) {
      return (
        <Box pl={4} width={1024}>
          <Stack
            direction="row"
            p={4}
            borderRadius={2}
            border={1}
            borderColor={grey[300]}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color={palette.darkerGray}>
              No projects. Start by creating new Unify project
            </Typography>

            <ArrowUpwardIcon
              sx={{
                fontSize: '38px',
                color: blue[500],
              }}
            />
          </Stack>
        </Box>
      );
    }

    return (
      <React.Fragment>
        <UnifyManagerSummary
          className="unify-manager__summary container"
          projects={projects}
          actualEvents={actualEvents}
          allSelected={areAllProjectsSelected(projects, checkIsProjectSelected)}
          onSelectActive={onSelectActive}
          onSelectEvents={onSelectEvents}
          onSelectOnline={onSelectOnline}
          onSelectRecording={onSelectRecording}
          onSelectStreaming={onSelectStreaming}
          onSelectSuspended={onSelectSuspended}
          onSelectAll={onSelectAll}
        />

        <ColumnsHeader
          columns={columns}
          activeColumns={arrangedColumns}
          fixedColumnIds={fixedColumns}
          isColumnActive={isColumnActive}
          withGroups={false}
          onChange={onChange}
          onChangeArrangement={onChangeArrangement}
        />

        <div className="unify-manager__list container">
          <div>
            {projects.map((project) => {
              const projectId = project.getId();

              return (
                <DeviceCard
                  key={projectId}
                  className="unify-manager__project"
                  device={project}
                  role={role}
                  columns={arrangedColumns}
                  streamingDestinations={streamingDestinations}
                  selectable={true}
                  groups={[]}
                  presets={presets}
                  onMoveToGroup={async () => {}}
                  onRemoveFromGroup={async () => {}}
                  onApplyPreset={(preset) => handleApplyPreset(preset, projectId)}
                  onStartProject={() => handleStart(projectId)}
                  onStopProject={() => handleStop(projectId)}
                  onDelete={() => handleDelete(projectId)}
                  //! retype
                  opened={projects.length < MAX_OPENED_DEVICE_CARDS}
                  toggleDeviceSelection={toggleSelection}
                  checkIsDeviceSelected={checkIsProjectSelected}
                  getDeviceById={getById}
                  onChangeStreamingDestination={onChangeStreamingDestination}
                />
              );
            })}
          </div>
        </div>

        {selected.length > 0 && (
          <BatchActionsPanel
            className="unify-manager__batch-panel"
            role={role}
            isUnify={true}
            devices={selected}
            deviceGroups={[]}
            groups={[]}
            presets={presets}
            groupsAllowed={false}
            teamCapabilities={teamCapabilities}
            onMoveToGroup={async () => {}}
            onRemoveFromGroup={async () => {}}
            onStartProjects={handleBatchStart}
            onStopProjects={handleBatchStop}
            onApplyPreset={handleBatchApplyPreset}
            onDelete={handleBatchDelete}
            onDeleteGroups={noop}
            //! retype
            unpairAction={noop}
            deselectAll={resetDeviceSelection}
            onActionDone={onReload}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <div className={classNames('unify-manager', className)}>
      <Box pl={4} width={1024}>
        <Stack mb={3} direction="row" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography fontSize={24} mb={1} fontWeight={600}>
              All Unify projects
            </Typography>
            <Typography color={palette.darkerGray}>
              All Epiphan Unify projects are listed below. Click Admin Login to open and modify the
              project.
            </Typography>
          </Box>

          <Button
            data-id="create-project-btn"
            startIcon={<AddIcon />}
            variant="contained"
            color="secondary"
            onClick={() => setCreateDialog(true)}
          >
            New project
          </Button>
        </Stack>
      </Box>

      <CreateProjectDialog
        open={createDialog}
        price={price}
        onCreate={handleCreate}
        onClose={() => setCreateDialog(false)}
      />

      {renderBody()}
    </div>
  );
}
