import {useQuery} from '@tanstack/react-query';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {EdgeMapper} from 'app/util/mappers/edge/EdgeMapper';

interface Args {
  deviceId: string;
  enabled: boolean;
}

export function useTranscribeSessionsQuery({deviceId, enabled}: Args) {
  return useQuery({
    queryKey: ['device-settings', deviceId],
    queryFn: async () => {
      const response = await DeviceApiService.getTranscriberSessions(deviceId);

      if (response) {
        return response.data.map(EdgeMapper.mapTranscribeSection);
      }

      return [];
    },
    refetchInterval: 5000,
    enabled,
  });
}
