import {Store, StoreProps} from 'app/util/Store';

interface UserTeamStoreProps extends StoreProps {
  teamId: string;
  userId: string;
}

class UserTeamStore {
  private readonly store: Store;
  private readonly defaultValue: string;
  private readonly teamId: string;
  private readonly userId: string;

  constructor({
    defaultValue,
    storeKey,
    teamId,
    userId,
  }: UserTeamStoreProps) {
    this.store = new Store({
      storeKey,
      defaultValue: {},
    });
    this.defaultValue = defaultValue;
    this.teamId = teamId;
    this.userId = userId;
  }

  load<T = any>(): T {
    const teams = this.store.load();
    const data = teams?.[this.teamId]?.[this.userId];

    return data ?? this.defaultValue;
  }

  save<T = any>(data: T) {
    const teams = this.store.load();

    if (teams[this.teamId] === undefined) {
      teams[this.teamId] = {};
    }

    teams[this.teamId][this.userId] = data;

    this.store.save(teams);
  }
}

export {
  UserTeamStore,
};
