import {booleanComparator} from 'app/util/comparators/booleanComparator';
import {numberComparator} from 'app/util/comparators/numberComparator';
import {stringComparator} from 'app/util/Sort';

export function valueComparator(a: any, b: any): number {
  if (a === b) {
    return 0;
  }

  if (a === null || typeof a === 'undefined') {
    return 1;
  }

  if (b === null || typeof b === 'undefined') {
    return -1;
  }

  switch (typeof a) {
    case 'boolean':
      return booleanComparator(a, b);
    case 'number':
      return numberComparator(a, b);
    case 'string':
      return stringComparator(a, b as string);
    default:
      return 0;
  }
}
