import React from 'react';
import {Sx} from 'app/types/common';
import {Divider, Grid, Link, Stack} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {Schedule} from 'app/domain/schedule';
import {EventChannel} from 'app/components/sharedReactComponents/Events/List/EventCard/EventChannel/EventChannel';
import {DevicePicture} from 'app/components/sharedReactComponents/DevicePicture/DevicePicture';
import {router} from 'app/router/main';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {packSx} from 'app/util/packSx/packSx';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';

interface Props extends Sx {
  profile: Map<string, Schedule.ChannelProfile>;
  device: PearlMasterDeviceModel;
  showDevice: boolean;
  showPreview: boolean;
  active: boolean;
}

export function EventDevice({sx, profile, device, showDevice, active, showPreview}: Props) {
  const offline = device.isOffline();

  return (
    <Stack
      sx={sx}
      direction="row"
      gap={1}
      divider={<Divider orientation="vertical" flexItem={true} />}
    >
      {showDevice && (
        <Stack direction="row" alignItems="center" gap={1} flex={1}>
          <DevicePicture
            sx={packSx({height: 20, flexShrink: 0}, offline && {opacity: 0.5})}
            model={device.getModelName() as App.Cloud.DeviceModel}
          />

          <Link
            data-id="device-link"
            sx={packSx({minWidth: 0}, offline && {opacity: 0.5})}
            href={router.url('deviceDetails', {deviceId: device.getId()})}
          >
            <OverflowTooltip rowsNumber={2}>{device.getName()}</OverflowTooltip>
          </Link>

          {offline && <WarningIcon color="error" />}
        </Stack>
      )}

      <Grid sx={{flex: 3}} container={true} spacing={1}>
        {Array.from(profile.entries()).map(([idx, c]) => {
          const channel = device.getChannelByIndex(idx);

          if (!channel) {
            return;
          }

          return (
            <Grid key={idx} item={true} xs={4}>
              <EventChannel
                master={device}
                channel={channel}
                preview={showPreview}
                active={active}
                recording={c.recording}
                streaming={c.streaming}
              />
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
}
