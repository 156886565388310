import {Box} from '@mui/material';
import React, {useMemo, useState} from 'react';
import {ContextMenu} from 'app/components/sharedReactComponents/ContextMenu';
import {Icons} from 'app/util/icons';
import {BatchActionButton} from 'app/components/FleetManager/BatchActionsPanel/BatchActionButton';
import {isBatchActionPanelNarrow} from 'app/components/FleetManager/BatchActionsPanel/utils';
import {SIZE} from 'app/constants';
import {ClassName, Sx} from 'app/types/common';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {Edge} from 'app/domain/edge';
import {useMounted} from 'app/hooks/useIsMounted';
import {MoveToGroupDialog} from 'app/components/dialogs/MoveToGroupDialog/MoveToGroupDialog';
import {RemoveFromGroupDialog} from 'app/components/dialogs/RemoveFromGroupDialog/RemoveFromGroupDialog';
import {DeleteGroupsDialog} from 'app/components/dialogs/DeleteGroupsDialog/DeleteGroupsDialog';

interface Props extends ClassName, Sx {
  selectedDevices: AnyDeviceModelType[];
  selectedGroups: Edge.Group[];
  groups: Edge.Group[];
  onMoveToGroup: (groupId: string) => Promise<void>;
  onRemoveFromGroup: (devices: AnyDeviceModelType[]) => Promise<void>;
  onDelete: () => Promise<void>;
}

export function BatchActionDeviceGroup({
  sx,
  className,
  selectedDevices,
  selectedGroups,
  groups,
  onMoveToGroup,
  onRemoveFromGroup,
  onDelete,
}: Props) {
  const [moveToGroupDialog, setMoveToGroupDialog] = useState(false);
  const [removeFromGroupDialog, setRemoveFromGroupDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const mounted = useMounted();

  const {masterDevices, groupedDevices, availableGroups} = useMemo(() => {
    const masterDevices = selectedDevices.filter(
      (device) => !ModelService.isChannel(device.getModelName()),
    );

    const groupedDevices = masterDevices.filter((device) => Boolean(device.getGroupId()));
    const currentGroups = new Set(groupedDevices.map((d) => d.getGroupId()));
    const availableGroups = groups.filter((g) => !currentGroups.has(g.id));

    return {masterDevices, groupedDevices, availableGroups};
  }, [selectedDevices, groups]);

  const hasGrouped = groupedDevices.length > 0;

  const handleMoveToGroup = async (groupId: string) => {
    await onMoveToGroup(groupId);

    if (mounted()) {
      setMoveToGroupDialog(false);
    }
  };

  const handleRemoveFromGroup = async () => {
    if (!hasGrouped) {
      return;
    }

    await onRemoveFromGroup(groupedDevices);

    if (mounted()) {
      setRemoveFromGroupDialog(false);
    }
  };

  const handleDelete = async () => {
    await onDelete();

    if (mounted()) {
      setDeleteDialog(false);
    }
  };

  return (
    <Box sx={sx} className={className}>
      <ContextMenu
        items={[
          {
            label: 'Delete Groups',
            itemDataId: 'batch_action_group_delete_groups_action',
            icon: Icons.trash().reactComponent(),
            danger: true,
            disabled: !selectedGroups.length,
            action: () => setDeleteDialog(true),
          },
          {separator: true},
          {
            label: 'Move to group',
            itemDataId: 'batch_action_group_move_group_action',
            icon: Icons.groupMove().reactComponent(),
            disabled: !masterDevices.length,
            action: () => setMoveToGroupDialog(true),
          },
          {
            label: 'Remove from group',
            itemDataId: 'batch_action_group_remove_group_action',
            icon: Icons.groupRemove().reactComponent(),
            disabled: !hasGrouped,
            action: () => setRemoveFromGroupDialog(true),
          },
        ]}
      >
        <BatchActionButton
          data-id="batch_action_group_button"
          title={isBatchActionPanelNarrow() && 'Group'}
        >
          {Icons.groupFolderStroke().label('Group').size(SIZE.S).reactComponent()}
        </BatchActionButton>
      </ContextMenu>

      <MoveToGroupDialog
        open={moveToGroupDialog}
        devices={[]}
        groups={availableGroups}
        onMove={handleMoveToGroup}
        onClose={() => setMoveToGroupDialog(false)}
      />

      <RemoveFromGroupDialog
        open={removeFromGroupDialog}
        devices={groupedDevices}
        onRemove={handleRemoveFromGroup}
        onClose={() => setRemoveFromGroupDialog(false)}
      />

      <DeleteGroupsDialog
        open={deleteDialog}
        names={selectedGroups.map((g) => g.name)}
        onDelete={handleDelete}
        onClose={() => setDeleteDialog(false)}
      />
    </Box>
  );
}
