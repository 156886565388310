import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {Prompt} from 'app/types/common';
import {DialogClose} from 'app/components/sharedReactComponents/DialogClose/DialogClose';
import {LoadingButton} from '@mui/lab';

interface Props extends Prompt {
  loading: boolean;
  name: string;
  onOverwrite: () => void;
}

export function OverwritePresetDialog({open, loading, name, onOverwrite, onClose}: Props) {
  return (
    <Dialog maxWidth="sm" fullWidth={true} open={open}>
      <DialogClose onClose={onClose} />

      <DialogTitle>
        Configuration preset{' '}
        <Typography component="span" variant="inherit" color="primary.main">
          {name}
        </Typography>{' '}
        already exist
      </DialogTitle>

      <DialogContent>
        <Typography textAlign="center">Do you want to overwrite it?</Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="confirm-overwrite-btn"
          loading={loading}
          variant="contained"
          color="secondary"
          onClick={onOverwrite}
        >
          Yes
        </LoadingButton>

        <Button data-id="cancel-overwrite-btn" variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
