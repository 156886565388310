import {BaseDeviceSettingModel} from 'app/models/Device/Settings/BaseDeviceSettingModel';
import {isNil} from 'app/util/isNil';
import {EnumTypeOptions} from 'app/components/DeviceDetails/Models/types';

export class EnumDeviceSettingModel extends BaseDeviceSettingModel {
  _formatValue() {
    let valueFormatted = this.getValue();

    const activeOption = (this.getOptions() as EnumTypeOptions).items.find(({value}) => value === this.getValue());
    if (isNil(activeOption) === false) {
      const label = activeOption?.title;
      if (!isNil(label)) {
        valueFormatted = label;
      }
    }

    return valueFormatted;
  }
}
