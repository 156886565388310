import {FacebookStreamingService} from 'app/components/StreamingServices/FacebookStreamingService';
import {YoutubeStreamingService} from 'app/components/StreamingServices/YoutubeStreamingService';
import {TwitchStreamingService} from 'app/components/StreamingServices/TwitchStreamingService';
import {RTMPStreamingService} from 'app/components/StreamingServices/RTMPStreamingService';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {STREAMING_SERVICE_TYPE} from 'app/components/StreamingServices/constants';
import {StreamingDestinationOptions} from 'app/components/StreamingServices/StreamingDestinations/types';

type StreamingServiceType =
  | FacebookStreamingService
  | YoutubeStreamingService
  | TwitchStreamingService
  | RTMPStreamingService;

class StreamingServicesRepository {
  private readonly registeredServices = new Map<string, StreamingServiceType>();

  constructor() {
    this.registerService(new FacebookStreamingService());
    this.registerService(new YoutubeStreamingService());
    this.registerService(new TwitchStreamingService());
    this.registerService(new RTMPStreamingService());
  }

  registerService(service: StreamingServiceType) {
    this.registeredServices.set(service.getType() ?? '', service);
  }

  getAvailableToCreateServices(): StreamingServiceType[] {
    return Array.from(this.registeredServices.values()).filter(service => service.isAvailableToCreate());
  }

  isAvailableToCreateService(serviceType: STREAMING_SERVICE_TYPE): boolean {
    const service = this.getServiceByType(serviceType);

    return Boolean(service?.isAvailableToCreate());
  }

  getServiceByType(serviceType: STREAMING_SERVICE_TYPE) {
    return this.registeredServices.get(serviceType);
  }

  createStreamingDestinationModel(json: StreamingDestinationOptions): AnyStreamingDestinationModelType | undefined {
    const serviceType = json.Type;
    const service = this.getServiceByType(serviceType);

    if (service) {
      return service.createStreamingDestinationModel(json);
    }
  }
}

const streamingServicesRepository = new StreamingServicesRepository();

export {
  streamingServicesRepository,
};
