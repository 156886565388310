import {CloudContract} from 'app/contracts/cloud';
import {Cloud} from 'app/domain/cloud';
import {TeamUser} from 'app/models/User/TeamUser';
import {Owner} from 'app/models/PermissionsModel/Owner';
import {Admin} from 'app/models/PermissionsModel/Admin';
import {Editor} from 'app/models/PermissionsModel/Editor';
import {Operator} from 'app/models/PermissionsModel/Operator';
import {Edge} from 'app/domain/edge';
import {AppTeam} from 'app/models/AppTeam/AppTeam';

function mapRtmpDestination(c: CloudContract.RtmpDestination): Cloud.RtmpDestination {
  return {
    id: c.StreamID,
    name: c.Name,
    type: c.Type,
    active: c.CurrentlyStreaming,
    ownedBy: c.LockByDevice,
    deleted: c.IsDeleted,
    lastModified: c.LastModified,
    rtmp: {
      key: c.RTMP.StreamingKey,
      name: c.RTMP.DestName,
      url: c.RTMP.URL,
    },
  };
}

function mapGoogleDestination(c: CloudContract.GoogleDestination): Cloud.GoogleDestination {
  const auth = c.OAuth2;

  return {
    id: c.StreamID,
    name: c.Name,
    type: c.Type,
    active: c.CurrentlyStreaming,
    ownedBy: c.LockByDevice,
    deleted: c.IsDeleted,
    lastModified: c.LastModified,
    auth: {
      service: 'google',
      login: auth.Login,
      userName: auth.UserName,
      externalId: auth.ExternalID,
      error: auth.Error,
      errorTime: auth.ErrorTime,
    },
  };
}

function mapDestination(
  c: CloudContract.StreamDestination,
): Cloud.StreamingDestination | undefined {
  if (c.Type === 'rtmp' || c.Type === 'rtmps') {
    return mapRtmpDestination(c);
  }

  if (c.Type === 'google') {
    return mapGoogleDestination(c);
  }

  return undefined;
}

function mapUnitCapabilities(c: CloudContract.UnitCapabilities): Cloud.UnitCapabilities {
  return {
    audioLevels: c.audio_levels.enabled,
    batch: c.batch_selection.enabled,
    epiphanCms: c.epiphan_cms.enabled,
    files: c.file_management.enabled,
    hls: c.start_hls_stream.enabled,
    groups: c.use_groups.enabled,
    login: c.remote_login.enabled,
    presets: c.use_preset.enabled,
    reboot: c.reboot.enabled,
    recording: c.recording.enabled,
    schedule: c.use_cms.enabled,
    streaming: c.streaming.enabled,
    transcribing: c.transcribing.enabled,
  };
}

function mapUserRole(role: CloudContract.RoleType, maser: boolean) {
  if (role === 'owner') {
    if (maser) {
      return new Owner();
    }

    return new Admin();
  }

  if (role === 'editor') {
    return new Editor();
  }

  return new Operator();
}

function mapApplicationUser(c: CloudContract.User): TeamUser {
  return new TeamUser({
    id: c.ID,
    name: c.Name,
    email: c.Email,
    privateTeam: c.UserPrivateTeam,
    teamId: c.CurrentTeam,
    teamName: c.TeamName,
    role: mapUserRole(c.CurrentRole, c.IsMaster),
    picture: c.Picture,
    checks: {
      agreement: c.IsAgreementConfirmed,
      policy: c.IsPolicyConfirmed,
    },
  });
}

function mapPairedSummary(c: CloudContract.PairedSummary): Edge.Paired {
  const {count, livescrypt, vpearl} = c;
  return {devices: count, lvs: livescrypt, projects: vpearl};
}

function mapTeam(c: CloudContract.Team): AppTeam {
  return new AppTeam({
    id: c.TeamID,
    name: c.TeamName,
    role: mapUserRole(c.UserRole, c.IsMaster),
  });
}

export const CloudMapper = {
  mapDestination,
  mapUnitCapabilities,
  mapApplicationUser,
  mapPairedSummary,
  mapTeam,
  mapUserRole,
};
