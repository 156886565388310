import {useQuery} from '@tanstack/react-query';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {CloudMapper} from 'app/util/mappers/CloudMapper/CloudMapper';

interface Args {
  teamId: string;
}

export function usePairedQuery({teamId}: Args) {
  return useQuery({
    queryKey: ['device-count', teamId],
    queryFn: async () => {
      const result = await DeviceApiService.getPairedSummary();
      return CloudMapper.mapPairedSummary(result);
    },
  });
}
